import { isMobile } from 'react-device-detect';
import SidebarDesktop from './SidebarDesktop';
import SidebarMobile from './SidebarMobile';

export default function Sidebar({ isLoaded }) {
  console.log('🚀 ~ isMobile:', isMobile);
  const renderContent = () => {
    if (isMobile) {
      return <SidebarMobile isLoaded={isLoaded} />;
    }
    return <SidebarDesktop isLoaded={isLoaded} />;
  };
  return renderContent();
}

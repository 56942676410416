// /* eslint-disable */

import React from 'react';
import BaseMainTitle from '../../../components/BaseMainTitle';
import LocationForm from '../../../components/Form/Location/LocationForm';
import usePost from '../../../hooks/usePost';
import BZHelmet from '../../../utils/BZHelmet';

export default function LocationAdd() {
  const { mutate: submitLocation } = usePost({ url: 'locations', validate: ['locations'], navTo: '../location' });

  const onSubmitLocation = async (data) => {
    await submitLocation(data);
  };
  return (
    <>
      <BZHelmet title="Location Add" description="" content="" />
      <div>
        <BaseMainTitle title="Location Add " />
        <div className="p-5 ">
          <div className="bg-white border p-5 shadow rounded">
            <LocationForm onFormSubmit={onSubmitLocation} />
          </div>
        </div>
      </div>
    </>
  );
}

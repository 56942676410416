import EmpOrgChart from '@/pages/HR/Department/EmpOrgChart';
import clsxm from '@/utils/clsxm';
import OrganizationChart from '@dabeng/react-orgchart';
import { ExpandMore } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Tab } from '@mui/material';
import { Box } from '@mui/system';
import Highcharts from 'highcharts';
import HighchartsOrganization from 'highcharts/modules/organization';
import HighchartsSankey from 'highcharts/modules/sankey';
import _ from 'lodash';
import { useState } from 'react';
import { TreeNode } from 'react-organizational-chart';

function getHighestLevel(data) {
  return _.max(_.map(_.flattenDeep(data), 'level'));
}

if (typeof Highcharts === 'object') {
  HighchartsSankey(Highcharts);
  HighchartsOrganization(Highcharts);
}

const NestedTree = ({ data }) => {
  if (!data) {
    return null;
  }

  const renderNode = (node) => (
    <TreeNode key={node.id} label={<div>{node.id}</div>}>
      {node.children && node.children.map((child) => renderNode(child))}
    </TreeNode>
  );

  return renderNode(data);
};

export default function DeptOrgCharts({ customTable, orgnData, data }) {
  // console.log('🚀 ~ DeptOrgCharts ~ orgnData:', JSON.stringify(orgnData));
  const [value, setValue] = useState('2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const nodes = [];
  const links = [];

  function extractNodesAndLinks(data, parentId) {
    data.forEach((item) => {
      const nodeId = item.id.toString();
      nodes.push({
        id: nodeId,
        name: item.name,
        level: item.level,
      });

      if (parentId) {
        links.push({
          from: parentId,
          to: nodeId,
        });
      }

      if (item.children) {
        extractNodesAndLinks(item.children, nodeId);
      }
    });
  }

  extractNodesAndLinks(orgnData, null);

  // Creating chartOptions

  const dataRc = links.map((link) => [link.from, link.to].reverse());

  const idToNameMap = _.keyBy(nodes, 'id');

  // Replace the IDs with names using the mapping
  const result = dataRc.map((pair) => pair.map((id) => idToNameMap[id]));

  const levelStyle = [
    'bg-[#FC581D] absolute top-0 left-0 w-full h-full flex items-center justify-center text-black',
    'bg-[#FC581D] text-white absolute top-0 left-0 w-full h-full flex items-center justify-center text-black',
    'bg-[#F4F1EC] absolute top-0 left-0 w-full h-full flex items-center justify-center text-black',
    'bg-[#E2E2E2] absolute top-0 left-0 w-full h-full flex items-center justify-center text-black',
  ];

  function convertOldToNew(oldData) {
    return oldData.map(([position, reportTo]) => {
      return [
        {
          v: position.name,
          f: `${position.name}<div class="${
            position?.level > 3 ? 'bg-[#E2E2E2] absolute top-0 left-0 w-full h-full flex items-center justify-center text-black' : levelStyle[position?.level]
          }">${position.name}</div>`,
        },
        reportTo.name,
        '',
      ];
    });
  }

  const newData = convertOldToNew(result);

  const calculateLevelHeight = (getHighestLevel(nodes) + 1) * 85;

  const levelStyleSimp = ['bg-[#2C6D47] !text-white', 'bg-[#FC581D] text-white  ', 'bg-[#F4F1EC]  text-black', 'bg-[#E2E2E2]  text-black'];

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore className="text-white" />} aria-controls="panel1a-content" id="panel1a-header" className="bg-blue-600 text-white mt-3">
        <div>{data.business_unit}</div>
      </AccordionSummary>
      <AccordionDetails className="py-5 relative w-full  px-4 py-6 border rounded flex flex-col gap-y-5">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="List" value="1" />
              <Tab label="Department Organization Chart" value="2" />
              <Tab label="Employees Organization Chart" value="3" />
            </TabList>
          </Box>
          <TabPanel className="!p-0" value="1">
            {customTable}
          </TabPanel>
          <TabPanel className="!p-0 !h-full" value="2">
            {/* <HighchartsReact
              //  ref={chartComponentRef}
              highcharts={Highcharts}
              options={chartOptions}
            /> */}

            {/* <Chart chartType="OrgChart" data={finaleOrgDeptChart} options={options} width="100%" height="400px" /> */}

            <div style={{ height: `${calculateLevelHeight}px` }}>
              <OrganizationChart
                datasource={orgnData[0]}
                pan={true}
                collapsible={false}
                zoom={true}
                NodeTemplate={({ nodeData }) => {
                  return (
                    <div
                      className={clsxm(
                        'w-[16rem]  bg-[#E2E2E2]  text-black p-[1rem] rounded flex space-x-[1rem] items-center justify-center text-sm',
                        levelStyleSimp[nodeData.level]
                      )}
                    >
                      <div className="">{nodeData?.name}</div>
                    </div>
                  );
                }}
              />
            </div>
          </TabPanel>
          <TabPanel className="!p-0 !h-full" value="3">
            <EmpOrgChart buData={{ name: data?.business_unit_name, id: data?.business_unit_id }} />
          </TabPanel>
        </TabContext>
      </AccordionDetails>
    </Accordion>
  );
}

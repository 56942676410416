import React from 'react';

export default function ForceRerender({ condition, component }) {
  return (
    <div>
      {!condition && <div className="">{component}</div>}
      {condition && <div className="">{component}</div>}
    </div>
  );
}

/* eslint-disable */

import React from 'react';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import TableApplication from './TableApplication';

export default function Application() {
  return (
    <div>
      <BZHelmet title="Application List" description="" />
      <BaseMainTitle title="Application List" />
      <div className="p-5">
        <TableApplication />
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { Box, ButtonBase, Card, Fade, Grid, Popover, Typography } from '@mui/material';
import { ArrowForwardIos, Article, Image, MoreVert } from '@mui/icons-material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

export default function CardFile({ data, onClickPreview, projectId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorAction, setAnchorAction] = useState(false);

  const { comment, created_at: createdAt, file_attached: file } = data;
  const fileExt = file.substring(file.lastIndexOf('.') + 1);
  const fName = file.substring(file.lastIndexOf('/') + 1);
  const fileName = fName.substring(0, fName.indexOf('.'));

  const isImage = ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp'].includes(fileExt.toLowerCase());
  const openAction = Boolean(anchorAction);
  const idAction = openAction ? 'simple-popover' : undefined;

  const handleClickAction = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorAction(null);
  };

  return (
    <Grid key={file} className="relative w-1/2">
      <Box className="p-2" onClick={onClickPreview}>
        <Card variant="outlined" className="p-2">
          <Box className="flex mb-2">
            {isImage ? <Image className="w-10 h-10" /> : <Article className="w-10 h-10" />}
            <Box className="flex flex-col ml-2">
              <Typography>{fileName}</Typography>
              <Typography className="line-clamp-1 text-sm">
                {comment.creator} - {format(new Date(createdAt), 'MMM, dd')}
              </Typography>
            </Box>
            <MoreVert onClick={handleClickAction} className="absolute top-4 right-4 z-10" />
          </Box>
          {isImage ? <img src={file} alt={fileName} className="w-full h-36 object-contain" loading="lazy" /> : null}
        </Card>
      </Box>
      <Popover
        id={idAction}
        open={openAction}
        anchorEl={anchorAction}
        onClose={handleCloseAction}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ transform: 'translate(0px, 4px)' }}
      >
        <Fade in={openAction}>
          <Box style={{ minWidth: '180px', color: '#fff' }} className="relative flex flex-col p-2 space-y-2 rounded-lg bg-[#ffffff]">
            <ButtonBase
              className="px-2 flex justify-start text-black relative"
              endIcon={<ArrowForwardIos className="w-4 h-4 -mr-4 absolute top-2.5 right-4" />}
              onClick={() => {
                dispatch({ type: 'sidebarTask/setActiveTabs', payload: 1 });
                navigate(`/task/project/${projectId}/${comment.topic}`, { replace: false });
              }}
            >
              View Detail
            </ButtonBase>
          </Box>
        </Fade>
      </Popover>
    </Grid>
  );
}

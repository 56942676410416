import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';

import { fetchTopicAttachments } from '../../../../hooks/useTopicsData';
import CardFile from './CardFile';
import AssetsViewer from '../../../../components/ui/AssetsViewer/AssetsViewer';
import DetailContext from '../../Projects/DetailContext';

const renderHtmlFilter = (name, control, options, placeholder, styles) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Select
          name={name}
          className={styles}
          {...field}
          isClearable
          options={options}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default function Files() {
  const { control, handleSubmit } = useForm();
  const { projectId } = useParams();
  const location = useLocation();
  const detailContext = useContext(DetailContext);
  // const qC = useQueryClient();

  const [filters, setFilters] = useState({ comment__topic__project: projectId, max_size: true });
  const [isPopoverAttachments, setPopoverAttachments] = useState(false);
  const [filesAttachments, setFilesAttachments] = useState([]);

  const { data, isFetching, isFetched } = useQuery(['topicAttachments', { params: filters }], fetchTopicAttachments);
  const attachments = data?.data?.results;

  const locationRef = useRef();

  const applyFilter = (filterData) => {
    const { topic, types, statuses } = filterData;
    const currState = { ...filters };

    if (topic) {
      currState.comment__topic = topic.id;
    } else {
      delete currState.comment__topic;
    }

    if (types) {
      currState.comment__topic__topic_type = types.id;
    } else {
      delete currState.comment__topic__topic_type;
    }

    if (statuses) {
      currState.comment__topic__topic_section = statuses.id;
    } else {
      delete currState.comment__topic__topic_status;
    }
    setFilters(currState);
  };

  useEffect(() => {
    if (locationRef.current !== location.pathname) {
      locationRef.current = location.pathname;
      setFilters((prevState) => {
        return { ...prevState, comment__topic__project: projectId };
      });
      // qC.invalidateQueries('topicAttachments');
    }
  }, [setFilters, projectId, location]);

  return (
    <>
      <div className="w-full h-[calc(100vh_-_140px)] overflow-y-auto flex flex-col px-6 py-6">
        <Typography>Filter:</Typography>
        <form onSubmit={handleSubmit(applyFilter)} className="w-full flex space-x-2 mb-4">
          {renderHtmlFilter('topic', control, detailContext.topics, 'Topic', 'w-1/4')}
          {renderHtmlFilter('statuses', control, detailContext.tasks, 'Statuses', 'w-1/5')}
          {renderHtmlFilter('types', control, detailContext.types, 'Types', 'w-1/5')}
          <button className="flex ml-auto rounded-xl bg-[#2C6D47] text-white px-5 py-1" type="submit">
            Apply
          </button>
        </form>
        {!isFetching ? (
          <Grid container direction="row" spacing={1}>
            {isFetched && attachments?.length ? (
              attachments.map((att) => {
                return (
                  <CardFile
                    key={`att-${att.id}`}
                    data={att}
                    projectId={projectId}
                    onClickPreview={() => {
                      setPopoverAttachments(true);
                      setFilesAttachments([att]);
                    }}
                  />
                );
              })
            ) : (
              <p className="w-full text-center mt-12">no data</p>
            )}
          </Grid>
        ) : (
          <CircularProgress className="m-auto" />
        )}
      </div>
      <AssetsViewer openAttachments={isPopoverAttachments} assets={filesAttachments} handleCloseAttachments={() => setPopoverAttachments(false)} />
    </>
  );
}

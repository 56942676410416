import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TextField, Button } from '@mui/material';
import Input from '@/pages/CRM/redesigning/InputContainerRedesign';
import POSApi from '@/api/pos/posApi';
import Swal from 'sweetalert2';
import { handleModal } from '@/store/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';

const schema = yup.object().shape({
  name: yup.string().nullable(),
  start_time: yup.string().nullable(),
  end_time: yup
    .string()
    .nullable()
    .test('is-greater', 'End time must be after start time', function (value) {
      const { start_time } = this.parent;
      if (start_time && value) {
        return start_time < value;
      }
      return true;
    }),
  order_cut_off_duration: yup
    .string()
    .nullable()
    .test(
      'is-less-or-equal',
      'Order cut-off duration must be less than or equal to cancel cut-off duration',
      function (value) {
        const { cancel_cut_off_duration } = this.parent;
        if (value && cancel_cut_off_duration) {
          return value <= cancel_cut_off_duration;
        }
        return true;
      }
    ),
  cancel_cut_off_duration: yup.string().nullable(),
});

function OutletServingTimeForm({getAllServingTimes, outletId}) {
  const clientId = useSelector((state) => state.client.activeClient);
  const servingTimeData = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();
  const mode = servingTimeData?.mode || "add";

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (mode === "edit" && servingTimeData?.data) {
      setValue("name", servingTimeData.data.name || "");
      setValue("start_time", servingTimeData.data.start_time || "");
      setValue("end_time", servingTimeData.data.end_time || "");
      setValue("order_cut_off_duration", servingTimeData.data.order_cut_off_duration || "");
      setValue("cancel_cut_off_duration", servingTimeData.data.cancel_cut_off_duration || "");
    }
  }, [mode, servingTimeData, setValue]);

  const onSubmit = async (data) => {
    const { addNewServingTime, updateServingTime } = POSApi();
    const formattedData = {
      client: clientId,
      outlet: outletId,
      name: data.name || null,
      start_time: data.start_time || null,
      end_time: data.end_time || null,
      order_cut_off_duration: data.order_cut_off_duration || null,
      cancel_cut_off_duration: data.cancel_cut_off_duration || null,
    };

    try {
      if (mode === "add") {
        await addNewServingTime(formattedData);
        getAllServingTimes(clientId);
        dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
        Swal.fire({
          title: 'Success!',
          text: 'Successfully added new Serving Time!',
          icon: 'success',
        });
      } else if (mode === "edit") {
        await updateServingTime(servingTimeData.data.id, formattedData);
        getAllServingTimes(clientId);
        dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
        Swal.fire({
          title: 'Success!',
          text: 'Successfully updated Serving Time!',
          icon: 'success',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while processing your request. Please try again later.',
        icon: 'error',
      });
      console.error('Error submitting serving time:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className={`w-full h-fit col-span-2`}>
        <p className={`font-semibold`}>Serving Time Name</p>
        <Input control={control} label="Name" name="name" type="text" errors={errors.name} />
        {/* <p className="text-red-500">{errors.name?.message}</p> */}
      </div>

      <div className={`w-full h-fit col-span-2`}>
        <p className={`font-semibold`}>Start Time</p>
        <Input control={control} label="Start Time" name="start_time" type="time" errors={errors.start_time} />
        {/* <p className="text-red-500">{errors.start_time?.message}</p> */}
      </div>

      <div className={`w-full h-fit col-span-2`}>
        <p className={`font-semibold`}>End Time</p>
        <Input control={control} label="End Time" name="end_time" type="time" errors={errors.end_time} />
        {/* <p className="text-red-500">{errors.end_time?.message}</p> */}
      </div>

      <div className={`w-full h-fit col-span-2`}>
        <p className={`font-semibold`}>Order Cut-Off Duration</p>
        <Input control={control} label="Order Cut-Off Duration" name="order_cut_off_duration" type="text" errors={errors.order_cut_off_duration} />
        {/* <p className="text-red-500">{errors.order_cut_off_duration?.message}</p> */}
      </div>

      <div className={`w-full h-fit col-span-2`}>
        <p className={`font-semibold`}>Cancel Cut-Off Duration</p>
        <Input control={control} label="Cancel Cut-Off Duration" name="cancel_cut_off_duration" type="text" errors={errors.cancel_cut_off_duration} />
        {/* <p className="text-red-500">{errors.cancel_cut_off_duration?.message}</p> */}
      </div>

      <div className="w-full flex justify-end">
        <button
          className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export default OutletServingTimeForm;
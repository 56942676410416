import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, ButtonBase, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { Draggable, DragDropContext, Droppable } from '../../../../utils/Task/DragDrop';
import ModalAccountType from '../../../../components/Modal/Accounting/Settings/AccountType';
import BasePromptDialog from '../../../../components/ui/Dialog/BasePromptDialog';

import AccountingSettingsContext from '../AccountingSettingsContext';
import accountingApi from '../../../../api/accounting/accounting';

function Row(props) {
  const { row, onEditClick, onDeleteClick, config } = props;
  const { provided } = config;

  return (
    <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell component="th" scope="row">
        {row.account_group?.code || '-'}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.code}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell component="th" scope="row">
        <Tooltip title={row.description}>
          <span className="truncate">{row.description}</span>
        </Tooltip>
      </TableCell>
      <TableCell component="th" scope="row">
        {String(row.is_sign_reversed).toUpperCase()}
      </TableCell>
      <TableCell align="center">
        <Box className="w-full flex space-x-2 justify-center">
          <ButtonBase className="bz-btn-tb" onClick={() => onEditClick(row)}>
            <Edit fontSize="24" />
          </ButtonBase>
          <ButtonBase className="bz-btn-tb" onClick={() => onDeleteClick(row)}>
            <Delete fontSize="24" />
          </ButtonBase>
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default function TabAccountType() {
  const asContext = useContext(AccountingSettingsContext);
  const cl = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const client = activeUser.is_superuser ? activeClient : cl;

  const [accAccounts, setAccAccounts] = useState(false);
  const [isModalAccAccounts, setModalAccAccounts] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [modalData, setModalData] = useState('');
  const [isModalDelete, setModalDelete] = useState(false);

  // pagination
  const [rowsPerPage] = useState(10);
  const [countPage, setCountPage] = useState(1);
  const [page, setPage] = useState(1);

  const getAccountList = useCallback(
    (params = {}) => {
      const { getAccountTypes } = accountingApi();
      getAccountTypes({...params, client, ordering: 'index'})
        .then((res) => {
          if (res.status === 200 && res.data.results) {
            setAccAccounts(res.data.results);
            setCountPage(Math.ceil(res.data.count / rowsPerPage));
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [rowsPerPage]
  );
  // changeAccountTypes
  const updateAccountTypes = useCallback(
    (data) => {
      const { changeAccountTypes } = accountingApi();
      changeAccountTypes('update_indexes', data)
        .then((res) => {
          if (res.status === 200 || res.data) {
            getAccountList();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [getAccountList]
  );

  const handleChangePage = (event, value) => {
    if (value > page) {
      getAccountList({ page: value });
    } else {
      getAccountList({ page: value });
    }
    setPage(value);
  };

  const handleActionSubmit = (mode, data) => {
    if (data) {
      const { addAccountType, updateAccountType } = accountingApi();
      if (mode === 'create') {
        addAccountType(data)
          .then((res) => {
            if (res.status === 201 || res.data) {
              getAccountList();
              asContext.refetchAccT();
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
      if (mode === 'update') {
        updateAccountType(modalData.id, data)
          .then((res) => {
            if (res.status === 201 || res.data) {
              getAccountList();
              asContext.refetchAccT();
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    }
    setModalData(false);
  };

  const onEditClick = (data) => {
    setModalData(data);
    setModalType('update');
    setModalAccAccounts(true);
  };

  const onDeleteClick = (data) => {
    setModalData(data);
    setModalDelete(true);
  };

  const onConfirmDelete = () => {
    const { deleteAccountType } = accountingApi();

    if (modalData && modalData.id) {
      deleteAccountType(modalData.id)
        .then((res) => {
          if (res.status === 204) {
            getAccountList();
            asContext.refetchAccT();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  useEffect(() => {
    if (!accAccounts) {
      getAccountList();
    }
  }, [accAccounts, getAccountList]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    const accIndex = accAccounts.map((acc) => acc.id);
    const newAccIndex = reorder(accIndex, source.index, destination.index);
    updateAccountTypes({ account_type_ids: newAccIndex });
    const newOrder = reorder(accAccounts, source.index, destination.index);
    setAccAccounts(newOrder);
  };

  return (
    <div>
      <div className="w-full flex px-4 pt-4">
        <button
          type="button"
          className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 ml-auto"
          style={{ backgroundColor: '#2C6D47' }}
          onClick={() => {
            setModalType('create');
            setModalAccAccounts(true);
          }}
        >
          <p className="text-white">Add New Account</p>
        </button>
        {/* <Button
          variant="contained"
          className="ml-auto"
          onClick={() => {
            setModalType('create');
            setModalAccAccounts(true);
          }}
        >
          Add New Account
        </Button> */}
      </div>
      <div className="m-auto max-w-[906px] w-[70%] flex flex-col p-4 justify-center overflow-hidden">
        <TableContainer component={Paper} style={{ width: 'auto' }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Group</TableCell>
                <TableCell align="left">Code</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Reverse Sign</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={onDragEnd} className="overflow-y-auto">
              <Droppable droppableId="row-droppable" type="row" direction="vertical">
                {(provided) => (
                  <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                    {accAccounts &&
                      accAccounts.length > 0 &&
                      accAccounts.map((row, i) => (
                        <Draggable key={row.id} draggableId={String(row.id)} index={i}>
                          {(provide, snapshot) => (
                            <Row
                              config={{ provided: provide, snapshot }}
                              key={`${row + i}`}
                              row={row}
                              index={i}
                              onEditClick={() => onEditClick(row, i)}
                              onDeleteClick={() => onDeleteClick(row, i)}
                            />
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                    {accAccounts && accAccounts.length === 0 && (
                      <TableRow className="p-4">
                        <TableCell component="th" scope="row" colSpan={6} className='text-center'>
                          <span>No rows</span>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
          {accAccounts && accAccounts.length > 0 && (
            <div className="w-full flex justify-end p-4">
              <Stack spacing={2}>
                <Pagination count={countPage} page={page} onChange={handleChangePage} />
              </Stack>
            </div>
          )}
        </TableContainer>
      </div>
      <ModalAccountType
        open={isModalAccAccounts}
        close={() => {
          setModalAccAccounts(false);
          setModalType(false);
          setModalData(false);
        }}
        action={handleActionSubmit}
        type={modalType}
        data={modalData}
      />
      <BasePromptDialog
        open={isModalDelete}
        title="Delete"
        content={modalData?.name}
        action={onConfirmDelete}
        close={() => {
          setModalDelete(false);
          setTimeout(() => {
            setModalData(false);
          }, 300);
        }}
      />
    </div>
  );
}

// /* eslint-disable */

import SortableTree from '@nosferatu500/react-sortable-tree';
// import '@nosferatu500/react-sortable-tree/style.css';
import { Button } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import React, { useCallback, useLayoutEffect, useState } from 'react';

// import { useBuildMenu } from '../../../hooks/useMenusData';
import { shortText } from '../../../lib/StringHelper';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import { handleModal } from '../../../store/slices/modalSlice';
import MenuDetailForm from '../../../components/Form/Menu/MenuDetailForm';
import MenuForm from '../../../components/Form/Menu/MenuForm';
import assetApi from '../../../api/content/asset';
import BaseBackButton from '../../../components/Button/BaseBackButton';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import useGet from '../../../hooks/useGet';
import useDelete from '../../../hooks/useDelete';
import usePost from '../../../hooks/usePost';
import usePatchOrPut from '../../../hooks/usePatchOrPut';

export default function MenuDetail() {
  const [treeData, setTreeData] = useState([]);
  const [menuName, setMenuName] = useState(false);
  const [menuItemData, setMenuItemData] = useState(null);
  const [menuAssets, setMenuAssets] = useState(false);
  const [isInitialize, setIsInitialize] = useState(false);

  const { menuId } = useParams();
  const dispatch = useDispatch();
  const activeClient = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);

  useGet({
    url: `menus/${menuId}/`,
    validate: 'menu',
    onSuccess: (e) => {
      setTreeData(e.data.items);
      setMenuName(e.data);
    },
  });

  // useDelete with direct params

  const destroyMenuItems = useDelete({
    confText: `modals.menu_item_delete.confirmation_text`,
    confTitle: `modals.menu_item_delete.title`,
    success: `Menu Item deleted`,
    validate: ['menu'],
  });

  const onDestroyMenuItem = (id) => {
    destroyMenuItems(`menu_items/${id}`);
  };

  // useDelete with direct params

  const { mutate: buildMenu } = usePatchOrPut({ url: `menus/${menuId}/define_structure`, validate: ['menu', 'menus'], method: 'put' });

  function generateNprops(node) {
    const html = (
      <div title={node.title} className="">
        <span className="float-left">{`${shortText(node.title, 20)} `}</span>
        <div className="flex  right-0 absolute">
          <Button
            title="Add child"
            className="min-w-0 mx-1 w-6 h-6 p-0"
            size="small"
            variant="contained"
            color="success"
            onClick={() => dispatch(handleModal({ modalId: node.id, componentName: 'addChildMenuDetail' }))}
          >
            <Add fontSize="small" />
          </Button>
          <Button
            title="Update this item"
            className="min-w-0 mx-1 w-6 h-6 p-0"
            size="small"
            variant="contained"
            onClick={() => dispatch(handleModal({ modalId: node.id, componentName: 'editMenuDetail' }))}
          >
            <Edit fontSize="small" />
          </Button>

          <Button
            // disabled={node.parent === undefined}
            title="Delete this item"
            className="min-w-0 mx-1 w-6 h-6 p-0"
            size="small"
            variant="contained"
            color="error"
            onClick={() => onDestroyMenuItem(node.id)}
          >
            <Delete fontSize="small" />
          </Button>
        </div>
      </div>
    );
    return html;
  }

  const { mutate: updateMenu } = usePatchOrPut({
    url: `menus/${menuId}`,
    validate: ['menu'],
  });

  const onUpdateMenu = async (data) => {
    await updateMenu(data);
  };

  const { mutate: postMenu } = usePost({ url: 'menu_items', validate: ['menu'] });

  const onPostMenu = async (data) => {
    await postMenu(data);
  };

  useGet({
    url: `menu_items/${modal.modalId}`,
    validate: 'menuItem',
    onSuccess: (e) => {
      setMenuItemData(e?.data);
    },
    isEnabled: modal.componentName === 'editMenuDetail',
  });

  const { mutate: updateMenuItems } = usePatchOrPut({ url: `menu_items/${modal.modalId}`, validate: ['menuItem'] });

  const onUpdateMenuItem = async (data) => {
    await updateMenuItems(data);
  };

  const getAssetList = useCallback(
    (params = {}) => {
      const { getAssets } = assetApi();
      if (activeClient) {
        getAssets({ client: activeClient, max_size: true, ...params })
          .then((res) => {
            if (res.status === 200 || res.data.results) {
              setMenuAssets(res.data.results);
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    },
    [activeClient]
  );

  useLayoutEffect(() => {
    if (!menuAssets && !isInitialize) {
      setIsInitialize(true);
      getAssetList();
    }
  }, [menuAssets, isInitialize, getAssetList]);

  return (
    <>
      <BZHelmet title="Menu Detail" description="" content="" />
      <div className="">
        <BaseMainTitle>
          <div className="flex items-center ">
            <div className="text-lg font-semibold">{`Menu : ${menuName?.name}`}</div>
            <Button
              title="Update this item"
              className="bz-btn-tb min-w-0 mx-1 w-6 h-6 p-0 bg-bz-green hover:bg-bz-green"
              size="small"
              variant=""
              onClick={() => dispatch(handleModal({ modalId: '', componentName: 'editMenuName' }))}
            >
              <Edit fontSize="small" className='text-white' />
            </Button>
          </div>
        </BaseMainTitle>
        <div className="p-5 h-full">
          <div className=" bg-white border p-5 shadow rounded h-[calc(100%+15rem)] relative">
            <div className="bg-gray-100 w-full py-5 px-3 mb-5 rounded flex items-center">
              <BaseBackButton />
              <div className="ml-auto flex gap-x-3">
                <Button onClick={() => buildMenu()} className="ml-auto flex bz-btn" type="submit" variant="filled">
                  BUILD MENU STRUCTURE
                </Button>
                <Button
                  onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addMenuDetail' }))}
                  className="ml-auto flex bz-btn"
                  type="submit"
                  variant="filled"
                >
                  ADD MENU ITEM
                </Button>
              </div>
            </div>
            <div
              style={{
                height: '100vh',
                width: '100%',
                position: 'relative',
              }}
            >
              <SortableTree
                treeData={treeData}
                onChange={(newTreeData) => setTreeData(newTreeData)}
                onMoveNode={(newData) => onUpdateMenu({ items: newData.treeData })}
                slideRegionSize={0}
                generateNodeProps={({ node }) => ({ title: generateNprops(node) })}
              />
            </div>
          </div>
          <ModalWrapper componentName="editMenuName" header={`Edit ${menuName?.name}`} maxWidth="sm">
            <MenuForm defaultValues={menuName} key={menuName.id} onFormSubmit={onUpdateMenu} />
          </ModalWrapper>
          <ModalWrapper componentName="addChildMenuDetail" header="Add Child Menu Item" maxWidth="sm">
            <MenuDetailForm onFormSubmit={onPostMenu} menuAssets={menuAssets} getAssetList={getAssetList} />
          </ModalWrapper>
          <ModalWrapper componentName="editMenuDetail" header="Edit Menu Item" maxWidth="sm">
            <MenuDetailForm defaultValues={menuItemData} key={menuItemData?.id} onFormSubmit={onUpdateMenuItem} menuAssets={menuAssets} getAssetList={getAssetList} />
          </ModalWrapper>
          <ModalWrapper componentName="addMenuDetail" header="Add Menu Item" maxWidth="sm">
            <MenuDetailForm onFormSubmit={onPostMenu} menuAssets={menuAssets} />
          </ModalWrapper>
        </div>
      </div>
    </>
  );
}

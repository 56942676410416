/* eslint-disable  */
import React, { useEffect } from 'react';
import { Autocomplete, TextField, Box, Avatar } from '@mui/material';

export default function SelectAutocomplete({ options, optionReset, onChange, placeHolder, label, defaultValue, variant, avatar, disabled, errors }) {
  const newOption = options;

  useEffect(() => {
    if (options?.length > 0 && optionReset) {
      newOption.push(optionReset);
    }
  }, [newOption, options?.length, optionReset]);

  return (
    <>
      {defaultValue && (
        <div>
          {avatar ? (
            <Autocomplete
              id=""
              // sx={{ height: 28 }}
              options={newOption}
              autoHighlight
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, newValue) => {
                return option.id === newValue.id;
              }}
              onChange={onChange}
              defaultValue={defaultValue}
              blurOnSelect
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  <Avatar alt="Remy Sharp" src={option.avatar} />
                  <span className="">{option.name}</span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeHolder}
                  variant={variant || 'standard'}
                  sx={{
                    '& > div': {
                      padding: 0,
                      border: 'none',
                      outline: 'none',
                    },
                  }}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          ) : (
            <Autocomplete
              id=""
              // sx={{ height: 28 }}
              options={newOption}
              autoHighlight
              disabled={disabled}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, newValue) => {
                return option.id === newValue.id;
              }}
              onChange={onChange}
              defaultValue={defaultValue}
              blurOnSelect
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  <span className="">{option.name}</span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeHolder}
                  variant={variant || 'standard'}
                  sx={{
                    '& > div': {
                      padding: 0,
                      border: 'none',
                      outline: 'none',
                    },
                  }}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          )}
        </div>
      )}

      {!defaultValue && (
        <div>
          {avatar ? (
            <Autocomplete
              id=""
              // sx={{ height: 28 }}
              options={newOption ?? []}
              autoHighlight
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, newValue) => {
                return option.id === newValue.id;
              }}
              onChange={onChange}
              // defaultValue={defaultValue}
              blurOnSelect
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  <Avatar alt="Remy Sharp" src={option.avatar} />
                  <span className="">{option.name}</span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeHolder}
                  variant={variant || 'standard'}
                  sx={{
                    '& > div': {
                      padding: 0,
                      border: 'none',
                      outline: 'none',
                    },
                  }}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          ) : (
            <Autocomplete
              id=""
              // sx={{ height: 28 }}
              options={newOption ?? []}
              autoHighlight
              disabled={disabled}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, newValue) => {
                return option.id === newValue.id;
              }}
              onChange={onChange}
              // defaultValue={defaultValue}
              blurOnSelect
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  <span className="">{option.name}</span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // key={option.value}
                  placeholder={placeHolder}
                  variant={variant || 'standard'}
                  sx={{
                    '& > div': {
                      padding: 0,
                      border: 'none',
                      outline: 'none',
                    },
                  }}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          )}
        </div>
      )}
      {errors &&
        (Array.isArray(errors[label.toLowerCase()]?.message) ? (
          errors[label]?.message.map((m) => <span className="text-red-600">{m.toLowerCase()}</span>)
        ) : (
          <span className="text-red-600">{errors[label]?.message.replace(label, label?.toLowerCase() || label.toLowerCase())}</span>
        ))}
    </>
  );
}

/* eslint-disable */


import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useSnackbar } from 'notistack';
import request from '../helper/axiosUtil';
import { handleModal } from '../store/slices/modalSlice';

export const fetchTemplates = async ({ queryKey }) => {
  const [, { templateId }] = queryKey;
  return request({ url: `/templates/${templateId}` });
};

export const fetchPage = async ({ queryKey }) => {
  const [, { pageId }] = queryKey;
  return request({ url: `/pages/${pageId}` });
};

const updatePage = (id, data) => {
  return request({ url: `/pages/${id}/`, method: 'patch', data });
};

export const useUpdatePage = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((data) => updatePage(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['page', newData.id]);
      const previousData = queryClient.getQueryData(['page', newData.id]);
      queryClient.setQueryData(['page', newData.id], newData);
      return { previousData, newData };
    },
    onSuccess: (e) => {
      // Invalidates cache and refetch
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      // queryClient.invalidateQueries('AssetCategories');
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['page', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['page', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};



export const fetchTemplateStructure = async ({ queryKey }) => {
  const [, { templateId, languageId }] = queryKey;
  return request({ url: `templates/${templateId}/get_template_structure/?language=${languageId}` });
};
export const fetchWidgets = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({
    url: `widgets/?client=${clientId}&max_size=true&category=1&internal=false&max_size=true`
  });
};


export const fetchWidgetsAll = async ({ queryKey }) => {
  const [, { pageSize, page, search, template, clientId }] = queryKey;
  return request({
    url: `/widgets/?page_size=${pageSize}&page=${page}&search=${search}&template=${template}`
  });
};

export const fetchWidgetsAllByClient = async ({ queryKey }) => {
  const [, { pageSize, page, search,  clientId }] = queryKey;
  return request({
    url: `/widgets/?page_size=${pageSize}&page=${page}&search=${search}&client=${clientId}`
  });
};

export const fetchWidgetsExternal = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({
    url: `widgets/?client=${clientId}&is_internal=false&category=1&status=Public&max_size=true`
  });
};

export const fetchWidgetsExternalArticleJob = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({
    url: `widgets/?client=${clientId}&is_internal=false&category=&status=Public&max_size=true`
  });
};


export const fetchWidgetsSetings = async ({ queryKey }) => {
  const [, { pageSize, page, search, getExternalWidget, getInternalWidget, isInternal }] = queryKey;
  return request({
    url: `/widgets/?page_size=${pageSize}&page=${page}&search=${search}&client=${getExternalWidget}&template=${getInternalWidget}&is_internal=${isInternal}`
  });
};


export const fetchMetaData = async ({ queryKey }) => {
  const [, { sectionId }] = queryKey;
  return request({
    url: `section_catalogs/${sectionId}/`
  });
};

export const fetchSectionList = async ({ queryKey }) => {
  const [, { page, clientId }] = queryKey;
  return request({
    url: `/section_catalogs/?folder_exists=True&page_size=9&page=${page}&category=&client=${clientId}`
  });
};

export const fetchSection = async ({ queryKey }) => {
  const [, { sectionId }] = queryKey;
  return request({
    url: `/sections/${sectionId}/`
  });
};

export const fetchWidget = async ({ queryKey }) => {
  const [, { widgetId }] = queryKey;
  return request({
    url: `/widgets/${widgetId}/`
  });
};

export const fetchWidgetDetail = async ({ queryKey }) => {
  const [, { widgetId, sectionId }] = queryKey;
  return request({
    url: `/widgets/${widgetId}/?section=${sectionId}`
  });
};

export const fetchSectionWidgetDetail = async ({ queryKey }) => {
  const [, { sectionWidgetId, sectionWidgetIdExternal }] = queryKey;
  return request({
    url: `/section_widgets/${sectionWidgetId || sectionWidgetIdExternal}/`
  });
};


export const fetchTextElement = async ({ queryKey }) => {
  const [, { widgetId }] = queryKey;
  return request({
    url: `/text_elements/${widgetId}/`
  });
};

export const fetchTextElementDetail = async ({ queryKey }) => {
  const [, { textElementId }] = queryKey;
  return request({
    url: `/text_elements/${textElementId}/`
  });
};

export const createTextElement = async (data) => request({
  url: '/text_elements/',
  method: 'post',
  data,
  headers: { 'Content-Type': 'application/json' },
});

export const deleteTextElement = async (id) => request({
  url: `/text_elements/${id} `,
  method: 'delete',
  headers: { 'Content-Type': 'application/json' },
});

export const pairAssetsWidgets = async ({ queryKey }) => {
  const [, { widgetId }] = queryKey;
  return request({
    url: `/widgets/${widgetId}/pair_assets/`
  });
};




export const pairSectionWidget = async (data) => request({
  url: '/section_widgets/',
  method: 'post',
  data,
  headers: { 'Content-Type': 'application/json' },
});


const pairSection = async (data) =>
  request({
    url: '/sections/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const usePairSection = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(pairSection, {
    /** Optimistic Update Start */
    onSuccess: (e) => {
      // Invalidates cache and refetch
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      // queryClient.invalidateQueries('sections');
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('pageSection', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('pageSection');
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const updateSection = (id, data) => {
  return request({ url: `/sections/${id}/`, method: 'patch', data });
};

export const useUpdateSection = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((data) => updateSection(id, data), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries(['sections', newData.id]);
      const previousData = queryClient.getQueryData(['sections', newData.id]);
      queryClient.setQueryData(['sections', newData.id], newData);
      return { previousData, newData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['sections', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['sections', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};

const updateSectionWidget = (id, data) => {
  return request({ url: `/section_widgets/${id}/`, method: 'put', data });
};

export const useUpdateSectionWidget = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((data) => updateSectionWidget(id, data), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries(['section', newData.id]);
      const previousData = queryClient.getQueryData(['section', newData.id]);
      queryClient.setQueryData(['section', newData.id], newData);
      return { previousData, newData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['section', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['section', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};






export const unpairSectionWidgets = async (data) => request({
  url: '/section_widget_unpair/',
  method: 'delete',
  data,
  headers: { 'Content-Type': 'application/json' },
});



export const updateIndexSection = async (data) => request({
  url: '/sections/index_update/',
  method: 'patch',
  data,
  // headers: { 'Content-Type': 'application/json' },
});


export const generateMeta = async (id) => request({
  url: `sections/${id}/generate_metadata/`,
  method: 'post',
  // headers: { 'Content-Type': 'application/json' },
});


export const createPage = async (data) => request({
  url: '/pages/',
  method: 'post',
  data,
  headers: { 'Content-Type': 'application/json' },
});


export const deleteSection = async (data) => request({
  url: '/sections/bulk_delete/',
  method: 'patch',
  data,
  headers: { 'Content-Type': 'application/json' },
});

export const deleteSectionWidgets = async (data) => request({
  url: '/sections/bulk_delete/',
  method: 'delete',
  data,
  headers: { 'Content-Type': 'application/json' },
});

export const deleteWidgets = async (data) => request({
  url: '/widgets/delete_widgets/',
  method: 'delete',
  data,
  headers: { 'Content-Type': 'application/json' },
});


export const deletePages = async (id) =>
  request({
    url: `/pages/${id}/`,
    method: 'delete',
  });


const fetchStyles = async () => {
  return request({ url: '/styles' });
};

export const useStylesData = () => {
  return useQuery(['Styles'], fetchStyles)
};


const updateContentOrder = (id, data) => {
  return request({ url: `/widgets/${id}/update_widgetarticle_index/`, method: 'patch', data });
};

export const useUpdateContentOrder = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((data) => updateContentOrder(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['widget', newData.id]);
      const previousData = queryClient.getQueryData(['widget', newData.id]);
      queryClient.setQueryData(['widget', newData.id], newData);
      return { previousData, newData };
    },
    onSuccess: (e) => {
      // Invalidates cache and refetch
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      // queryClient.invalidateQueries('AssetCategories');
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['widget', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['widget', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};


const updateTextOrder = (id, data) => {
  return request({ url: `/widgets/${id}/update_text_element_indexes/`, method: 'put', data });
};

export const useUpdateTextOrder = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((data) => updateTextOrder(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['widget', newData.id]);
      const previousData = queryClient.getQueryData(['widget', newData.id]);
      queryClient.setQueryData(['widget', newData.id], newData);
      return { previousData, newData };
    },
    onSuccess: (e) => {
      // Invalidates cache and refetch
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      // queryClient.invalidateQueries('AssetCategories');
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['widget', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['widget', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};

const updateMediaOrder = (id, data) => {
  return request({ url: `/widgets/${id}/update_widgetasset_index/`, method: 'patch', data });
};

export const useUpdateMediaOrder = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((data) => updateMediaOrder(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['widget', newData.id]);
      const previousData = queryClient.getQueryData(['widget', newData.id]);
      queryClient.setQueryData(['widget', newData.id], newData);
      return { previousData, newData };
    },
    onSuccess: (e) => {
      // Invalidates cache and refetch
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      // queryClient.invalidateQueries('AssetCategories');
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['widget', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['widget', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};



const updateSectionWidgetOrder = (id, data) => {
  return request({ url: `/sections/${id}/update_sectionwidget_indexes/`, method: 'patch', data });
};

export const useUpdateSectionWidgetOrder = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((data) => updateSectionWidgetOrder(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['section', newData.id]);
      const previousData = queryClient.getQueryData(['section', newData.id]);
      queryClient.setQueryData(['section', newData.id], newData);
      return { previousData, newData };
    },
    onSuccess: (e) => {
      console.log(e)
      // Invalidates cache and refetch
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      // queryClient.invalidateQueries('AssetCategories');
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['section', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['section', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};



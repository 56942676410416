import { Box, Button, ButtonBase, Fade, Popover } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { logWorkingHours } from '../../../../store/slices/task/taskSlice';
import getTimeZone from '../../../../utils/Task/getTimeZone';

const pad = (val) => {
  const valString = val.toString();
  if (valString.length < 2) {
    return `0${valString}`;
  }
  return valString;
};

function ContentHTML({ title, content }) {
  return (
    <div className="flex">
      <span className="w-16">{title}</span>
      <span className="w-[calc(100%_-_64px)] line-clamp-3">{content}</span>
    </div>
  );
}

export default function TimerTask({ type = 1, children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const runningTask = useSelector((state) => state.task.runningTask);
  const [timer, setTimer] = useState(null);
  const [anchorTask, setAnchorTask] = useState(null);

  useEffect(() => {
    if (!timer && runningTask?.time_in) {
      const startTimer = new Date(runningTask.time_in).getTime();
      const currTimer = new Date().getTime();
      setTimer(Math.floor(Math.abs(currTimer - startTimer) / 1000));
    }
  }, [timer, runningTask]);

  useEffect(() => {
    let interval = null;
    if (timer >= 0) {
      interval = setInterval(() => {
        setTimer((prevState) => prevState + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer, runningTask]);

  const handleClickTask = (event) => {
    setAnchorTask(event.currentTarget);
  };

  const openTask = Boolean(anchorTask);
  const idTask = openTask ? 'simple-popover' : undefined;

  const handleGoToTask = (param) => {
    dispatch({ type: 'task/setTriggerWarning', payload: false });
    if (param) {
      navigate(param, { replace: false });
      dispatch({ type: 'task/setTriggerRedraw', payload: true });
      setAnchorTask(false);
    }
  };

  const handleAction = () => {
    if (runningTask) {
      const dataLog = { topic: runningTask.task?.id };

      const activeTask = { project: { ...runningTask.project }, task: { ...runningTask.task } };
      const currDate = new Date();
      const currTimezone = getTimeZone();
      const tz = {
        name: currTimezone.name,
        utc_offset: currTimezone.offset,
      };
      dataLog.timezone = tz;

      dataLog.time_out = currDate.toISOString();
      dispatch(
        logWorkingHours({
          dataLog: { request: dataLog, state: activeTask },
          id: runningTask?.id,
          params: {},
          action: 'patch',
          trigger: false,
          // callback: () => {
          //   dispatch({ type: 'task/setTriggerDrawer', payload: true });
          //   dispatch({ type: 'task/setTriggerWarning', payload: false });
          // },
        })
      );
    }
  };

  let hours = 0;
  let minutes = 0;
  let days = 0;
  if (runningTask) {
    hours = Math.floor(timer / 3600);
    minutes = Math.floor((timer % 3600) / 60);
    days = Math.floor(hours / 24);
    if (days > 0) {
      hours = Math.abs(days * 24 - hours);
      days = `${days} ${days === 1 ? 'day' : 'days'}`;
    } else {
      days = false;
    }
  }

  return (
    <>
      {runningTask && type === 1 && (
        <span className="text-xs font-bold">
          {!!days && `${days}`} &ensp; {pad(hours)} : {pad(minutes)} : {pad(Math.floor(timer % 60))}
        </span>
      )}
      {runningTask && type === 2 && (
        <ButtonBase onClick={handleClickTask} className="flex flex-col">
          {children}
          <span className="text-xs font-bold">
            {!!days && `${days}`} &ensp; {pad(hours)} : {pad(minutes)} : {pad(Math.floor(timer % 60))}
          </span>
        </ButtonBase>
      )}
      <Popover
        id={idTask}
        open={openTask}
        anchorEl={anchorTask}
        onClose={() => setAnchorTask(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Fade in={openTask}>
          {runningTask?.time_in && (
            <Box className="w-96 flex relative flex-col py-4 space-y-2 rounded-lg bg-[#ffffff]">
              <div className="pl-6 pr-4 flex flex-col">
                <span className="font-bold mb-2">Running task</span>
                <div className="flex-col space-y-1">
                  <ContentHTML title="Project:" content={runningTask.project.name} />
                  <ContentHTML title="Task:" content={runningTask.task.name} />
                  <ContentHTML title="Start:" content={format(new Date(runningTask.time_in), 'dd-MMMM-yyyy HH:mm:ss')} />
                </div>
              </div>
              <div style={{ borderBottom: 'var(--border)', borderWidth: 1 }} />
              <div className="flex justify-between pl-6 pr-4">
                <Button
                  onClick={() => handleGoToTask(`/task/project/${runningTask?.project?.id}/${runningTask?.task?.id}`)}
                  variant="contained"
                  size="small"
                  className="text-xs h-8 font-bold"
                >
                  Go to Task
                </Button>
                <Button onClick={handleAction} variant="contained" size="small" className="text-xs h-8 font-bold">
                  Stop
                </Button>
              </div>
            </Box>
          )}
        </Fade>
      </Popover>
    </>
  );
}

import { Edit } from '@mui/icons-material';
import { Button, ButtonBase, IconButton } from '@mui/material';
import React from 'react';

export default function BaseEditButton({ onClick, text = 'Delete Selected', variant = 'icon', disabled, ...props }) {
  return variant === 'text' ? (
    <Button disabled={disabled} onClick={onClick} className="h-fit" type="button" variant="contained" color="primary">
      {text}
    </Button>
  ) : (
    <ButtonBase className="bz-btn-tb" onClick={onClick} disabled={disabled} aria-label="edit" {...props}>
      <Edit />
    </ButtonBase>
  );
}

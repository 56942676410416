// /* eslint-disable */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { DeleteForever } from '@mui/icons-material';
import { Pagination, CircularProgress } from '@mui/material';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
import { getSelectOptionWithKey } from '../../../lib/OptionsInputHelper';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import useGet from '../../../hooks/useGet';
import useDelete from '../../../hooks/useDelete';
import BaseApplyButton from '../../../components/Button/BaseApplyButton';
import BaseSearchButton from '../../../components/Button/BaseSearchButton';
import RenderResults from '../../../components/RenderResults';
import MuiSelectSingleItem from '../../../components/Input/MuiSelectSingleItem';
import BZButton from '../../../components/Button/BZButton';

const containerStyle = {
  width: '100%',
  height: '230px',
};

const lib = ['drawing', 'places'];

export default function Location() {
  const { control, watch } = useForm({});
  const [dataLocation, setDataLocation] = useState([]);
  const clientId = useSelector((state) => state.client.activeClient);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const [isDataLoad, setIsDataLoad] = useState(true);
  const [countLocation, setCountLocation] = useState(0);

  const pageSize = 10;
  const count = Math.ceil(countLocation / pageSize);

  const bUnitId = watch('business_unit') ? watch('business_unit') : '';

  const { data: bUnit } = useGet({ url: `accounting/business_units/?client=${clientId}&business_unit=`, validate: 'business_unit' });

  const { isFetching } = useGet({
    url: `locations`,
    queryString: {
      search,
      client: clientId,
      page,
      business_unit: bUnitId,
      page_size: pageSize,
    },
    validate: 'locations',
    onSuccess: (e) => {
      setDataLocation(e?.data?.results);
      setCountLocation(e.data.count);
    },
    isEnabled: isDataLoad,
    // isEnabled: Boolean(bUnitId) || Boolean(search),
  });

  const handleChange = (e, p) => {
    setIsDataLoad(false);
    setTimeout(() => {
      setIsDataLoad(true);
    }, 100);
    setPage(p);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
    libraries: lib,
  });

  const onSearchLocation = (event) => {
    setSearch(event.target.value);
  };

  // useDelete isSubUrl true
  const onDestroyLocation = useDelete({
    confText: `modals.location_delete.confirmation_text`,
    confTitle: `modals.location_delete.title`,
    success: 'Locations deleted',
    validate: ['locations'],
  });
  // useDelete isSubUrl true

  return (
    <>
      <BZHelmet title="Location List" description="" content="" />
      <div>
        <BaseMainTitle title="Location List" />
        <div className="p-5">
          <div className="">
            <div className="bg-gray-100 w-full flex py-5 px-3 items-center">
              <div className="ml-auto flex gap-x-3">
                <Link to="/location/add">
                  <BZButton variant="secondary">Add location</BZButton>
                </Link>
              </div>
            </div>
            <div className="py-4 my-4 border-y-2  flex gap-x-3 items-center">
              <BaseSearchButton onChange={onSearchLocation} setPage={setPage} setIsDataLoad={setIsDataLoad} />
              <div className="w-[239px]">
                <MuiSelectSingleItem name="business_unit" label="Select Business Unit" control={control} options={getSelectOptionWithKey(bUnit?.data?.results)} />
              </div>
              <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-3 w-full py-5">
            {!isFetching && isLoaded ? (
              <RenderResults
                condition={dataLocation.length > 0}
                component={dataLocation?.map((data) => (
                  <div key={data?.id} className="flex w-full bg-white shadow-xl rounded">
                    <div className="w-4/12">
                      <GoogleMap mapContainerStyle={containerStyle} center={{ lat: parseFloat(data?.latitude), lng: parseFloat(data?.longitude) }} zoom={10}>
                        {/* Child components, such as markers, info windows, etc. */}
                        <Marker position={{ lat: parseFloat(data?.latitude), lng: parseFloat(data?.longitude) }} />
                      </GoogleMap>
                    </div>
                    <div className="w-8/12">
                      <div className="bg-bz-green w-full py-1 px-3 relative flex items-center justify-between">
                        <p className="text-white text-lg font-semibold">{data?.name}</p>
                        <button type="button" onClick={() => onDestroyLocation(`locations/${data?.id}`)} className=" text-white ">
                          <DeleteForever />
                        </button>
                      </div>
                      <Link to={`/location/detail/${data.id}`}>
                        <div className="p-3">
                          <p className="text-bz-green">{data?.name}</p>
                          <p className="">{data?.address}</p>
                          <p className="">Phone :</p>
                          <p className="">Email :</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
          <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChange} />
        </div>
      </div>
    </>
  );
}

export function getSelectOptionNoKey(data) {
  const selectData = [];
  data?.map((item) => {
    return selectData.push({
      name: item,
      value: item,
    });
  });
  return selectData;
}

export function getSelectOptionWithKey(data) {
  const selectData = [];
  data?.map((item) => {
    return selectData.push({
      name: item.code ? item.code : item.name,
      value: item.id,
    });
  });
  return selectData;
}

export function generateSelectDefaultWithKey(data, option) {
  const selected = option?.filter((o) => o.value === data);
  return selected[0];
}

export function getSelectOptionWithIcon(data) {
  const selectData = [];
  data?.map((item) => {
    return selectData.push({
      label: item.name,
      value: item.id,
      avatar: item.avatar,
    });
  });
  return selectData;
}

export function generateSelectObjectDefault(data) {
  return {
    label: data?.name,
    value: data?.id,
    avatar: data?.avatar,
  };
}

export function generateSelectListDefault(data) {
  return data?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
}

export function getOnChangeSelect(newValue) {
  const changeInput = newValue;
  return changeInput?.value;
}

// New method here

export function getDefaultSelectObject(data, option) {
  const selected = option?.filter((o) => o.value.toString() === data?.toString());
  return selected ? selected[0] : '';
}

export function getOptionSelectWithKey(data) {
  const selectData = [];
  data?.map((item) => {
    return selectData.push({
      label: item.name,
      value: item.id,
    });
  });
  return selectData;
}

// New method here

// import request from '../../helper/axiosUtil';

// export const fetchTeams = async ({ queryKey }) => {
//   const [, { clientId }] = queryKey;
//   return request({ url: `/project-management/teams/?client=${clientId}` });
// };

// export const createTeams = (data) => request({
//   url: '/project-management/teams/',
//   method: 'post',
//   data,
//   headers: { 'Content-Type': 'application/json' },
// });

// export const updateTeams = async (id, data) => request({
//   url: `/project-management/teams/${id}/`,
//   method: 'patch',
//   data,
//   headers: { 'Content-Type': 'application/json' },
// });

// export const deleteTeams = async (id) => request({
//   url: `/project-management/teams/${id}/`,
//   method: 'delete',
// });

import useTokenBearer from '@/hooks/useTokenBearer';
import axios from 'axios';
import Cookies from 'js-cookie';

const baseURL = import.meta.env.VITE_API_URL;
const token = Cookies.get('bzKey');
// const client = Cookies.get('bzaClient');
const axiosInstance = axios.create({
  baseURL,
  headers: {
    Authorization: `${useTokenBearer()} ${token}`,
    'Content-Type': 'application/json',
  },
});

function team() {
  async function create(data = {}) {
    const method = 'post';
    const url = `${baseURL}project-management/teams/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }
  async function update(id, data = {}) {
    const method = 'patch';
    const url = `${baseURL}project-management/teams/${id}/`;
    const response = await axiosInstance({
      method,
      id,
      url,
      data,
    });
    return response;
  }
  async function destroy(id) {
    const method = 'delete';
    const url = `${baseURL}project-management/teams/${id}/`;
    const response = await axiosInstance({
      method,
      id,
      url,
    });
    return response;
  }
  async function getUsersTeam(clientId, params) {
    const method = 'get';
    const url = `${baseURL}users/?client=${clientId}`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function getTeam(teamId) {
    const method = 'get';
    const url = `${baseURL}project-management/teams/?client=${teamId}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function getTeamDetail(teamId, params, extraActions) {
    const method = 'get';
    let url = `${baseURL}project-management/teams/${teamId}/`;
    if (extraActions) {
      url = `${baseURL}project-management/teams/${teamId}/${extraActions}`;
    }
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function deleteTeamUsers(data) {
    const method = 'delete';
    const url = `${baseURL}project-management/team_users/delete_bulk/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }
  async function inviteTeams(teamId, data) {
    const method = 'post';
    const url = `${baseURL}project-management/teams/${teamId}/invite_user/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  return {
    create,
    update,
    getUsersTeam,
    getTeam,
    destroy,
    getTeamDetail,
    deleteTeamUsers,
    inviteTeams,
  };
}

export default team;

import React, { Suspense, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, ButtonBase, CircularProgress } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';

import { handleModal } from '../../../store/slices/modalSlice';
import ConfirmDialog from '../../../components/Modal/CRM/ConfirmDialog';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import AttributeGroup from '../../../components/Modal/CRM/AttributeGroup';
import crmApi from '../../../api/CRM/CRMCustomers';

const { getCRMProfileGroups, actionCRMProfileGroups } = crmApi();
export default function TableAttributeGroups() {
  const dispatch = useDispatch();
  const activeClient = useSelector((state) => state.client.activeClient);
  const isModal = useSelector((state) => state.modal);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmOpen, setConfirmOpen] = useState(null);
  const [groupSelected, setSelectedGroup] = useState(null);

  const {
    data: dataGroup,
    isFetching: isFetchingGroup,
    refetch,
  } = useQuery({
    queryKey: ['crm-project-profile-groups', activeClient],
    queryFn: () => getCRMProfileGroups({ profile__client: activeClient, max_size: true }),
    refetchOnWindowFocus: false,
  });

  const openModalAttribute = () => {
    dispatch(handleModal({ modalId: '', componentName: 'AttributeGroup' }));
  };

  const onDeleteClick = (param) => {
    actionCRMProfileGroups(param.id, 'delete')
      .then(() => refetch())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!isModal.isOpen && groupSelected) {
      setSelectedGroup(null);
    }
  }, [isModal.isOpen, groupSelected]);

  const renderAction = (dt) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase
          className="bz-btn-tb"
          onClick={() => {
            setSelectedGroup(dt);
            openModalAttribute();
          }}
        >
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => setConfirmOpen(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'is_displayed',
      headerName: 'Show',
      flex: 1,
    },
    {
      field: 'index',
      headerName: 'Index',
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => renderAction(params.row),
    },
  ];

  return (
    <Suspense fallback={<CircularProgress />}>
      <button type="button" className="flex ml-auto gap-x-2 items-center text-center btn rounded border-0 mb-4 px-4 h-10 bg-[#2C6D47]" onClick={openModalAttribute}>
        <AddIcon className="text-white" />
        <p className="text-white">Add Group</p>
      </button>
      <DataGrid
        autoHeight
        rows={dataGroup || []}
        columns={columns}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        rowsPerPageOptions={[10, 20, 50]}
        loading={isFetchingGroup}
      />
      <ConfirmDialog title="Delete Group" open={!!confirmOpen} setOpen={setConfirmOpen} onConfirm={() => onDeleteClick(confirmOpen)}>
        Are you sure you want to delete this group?
      </ConfirmDialog>
      <ModalWrapper componentName="AttributeGroup" header={`${!groupSelected ? 'Add New' : 'Update'} Group`} maxWidth="sm">
        <AttributeGroup groupSelected={groupSelected} refetch={refetch} />
      </ModalWrapper>
    </Suspense>
  );
}

import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';

export default function DatePickerField({ control, name, defaultValue, className, inputFormat, mask, errors, ...rest }) {
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || null}
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={className}
              inputFormat={inputFormat || 'dd/MM/yyyy'}
              mask={mask || '____-__-__'}
              onChange={onChange}
              value={value}
              renderInput={(params) => <TextField onChange={onChange} {...params} {...rest} />}
            />
          </LocalizationProvider>
        )}
      />
      {errors && <div className="text-[#d32f2f] text-[0.75rem] mt-[4px] border-[#d32f2f]">{errors?.message}</div>}
    </>
  );
}

export function DatePickerFieldRegister({ name, label, register = {}, errors, watch, ...props }) {
  const [date, setDate] = useState(null);
  const isError = errors && name in errors;

  const defaultValue = watch(name);
  useEffect(() => {
    if (!date && defaultValue) {
      setDate(defaultValue);
    }
  }, [date, defaultValue]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        name={name}
        // label={label}
        value={date}
        onChange={(newValue) => {
          props.setValue(name, newValue);
          setDate(newValue);
        }}
        closeOnSelect
        renderInput={(params) => <TextField size="small" error={!isError} {...params} register={register} />}
      />
      {props?.showErrorMessage && isError && <div className="text-[#d32f2f] text-[0.75rem] mt-[4px] border-[#d32f2f]">{errors?.message}</div>}
    </LocalizationProvider>
  );
}

/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import usePostExtra from '../../hooks/usePostExtra';
import { resetAsset } from '../../store/slices/assetCrudSlice';
import renderError from '../../utils/Error/renderError';
import BZHelmet from '../../utils/BZHelmet';
import BaseMainTitle from '../../components/BaseMainTitle';
import ApplicantForm from '../../components/Form/Job/ApplicantForm';

export default function ApplicantAdd() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [resIdApplicant, setResIdapplicant] = useState(null);

  useEffect(() => {
    dispatch(resetAsset());
  }, [dispatch]);

  const { mutate: postApplicant } = usePostExtra({
    url: 'applicants',
    validate: ['applicants'],
    onSuccess: (e) => {
      if (e.request.status === 201) {
        setResIdapplicant(e?.data?.id);
        enqueueSnackbar('success', { variant: 'success' });
        navigate(`/hr/recruitment/applicant/show/${e?.data?.id}`, { replace: true });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
        renderError({ data: JSON.parse(e.request.response) });
      }
    },
  });

  const onPostApplicant = async (data) => {
    await postApplicant(data);
  };
  return (
    <>
      <BZHelmet title="Applicant Add" description="" content="" />
      <div>
        <BaseMainTitle title="Applicant Add " />
        <div className="p-5 ">
          <div className="bg-white border p-5 shadow rounded">
            <ApplicantForm onFormSubmit={onPostApplicant} resIdApplicant={resIdApplicant} setResIdapplicant={setResIdapplicant} cancel="/hr/recruitment/applicants" />
          </div>
        </div>
      </div>
    </>
  );
}

import { Box, Tab, Tabs } from '@mui/material';
import React, { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import NotificationContext from './NotificationContext';
import DetailContext from '../../../Projects/DetailContext';
import ProjectNotification from './ProjectNotification';
import UserNotification from './UserNotification';
import notificationApi from '../../../../../api/task/notification';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.defaultProps = { children: <div /> };

function a11yProps(index) {
  return {
    id: `accounting-tab-${index}`,
    'aria-controls': `accounting-tabpanel-${index}`,
  };
}

const tabItems = ['Project', 'User'];

export default function Notification() {
  const activeUser = useSelector((state) => state.auth.user);
  const detailContext = useContext(DetailContext);
  const { projectId: project } = detailContext;

  const [activeTab, setActiveTab] = useState(0);
  const [isInitialize, setIsInitialize] = useState(false);
  const [notifSubs, setNotifSubs] = useState(false);
  const [userSubs, setUserSubs] = useState(false);
  const [notifTrigger, setNotifTrigger] = useState(false);

  const onTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getNotifSubs = useCallback(
    (setter, params = { project, max_size: true }) => {
      const { getNotificationSubscription } = notificationApi();
      return new Promise((resolve, reject) => {
        getNotificationSubscription(params)
          .then((res) => {
            if (res.status === 200 || res.data.results) {
              if (setter && setter === 'set') {
                setNotifSubs(res.data.results);
              } else {
                resolve(res.data.results);
              }
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [project]
  );

  const getUserSubs = useCallback(
    (setter, params = { user: activeUser.id, max_size: true }) => {
      const { getUserNotificationSubscription } = notificationApi();
      return new Promise((resolve, reject) => {
        getUserNotificationSubscription(params)
          .then((res) => {
            if (res.status === 200 || res.data.results) {
              if (setter && setter === 'set') {
                setUserSubs(res.data.results);
              } else {
                resolve(res.data.results);
              }
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [activeUser]
  );

  const getNotifTrigger = useCallback((setter, params = {}) => {
    const { getNotificationTriggers } = notificationApi();
    return new Promise((resolve, reject) => {
      getNotificationTriggers(params)
        .then((res) => {
          if (res.status === 200 || res.data.results) {
            if (setter && setter === 'set') {
              setNotifTrigger(res.data.results);
            } else {
              resolve(res.data.results);
            }
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);

  const getAllData = useCallback(async () => {
    setIsInitialize(true);
    const [a, b, c] = await Promise.all([getNotifSubs(), getUserSubs(), getNotifTrigger()]);
    if (a && b && c) {
      setNotifSubs(a);
      setUserSubs(b);
      setNotifTrigger(c);
    }
  }, [getNotifSubs, getUserSubs, getNotifTrigger]);

  useLayoutEffect(() => {
    if (!isInitialize) {
      getAllData();
    }
  });

  const context = useMemo(
    () => ({
      notifSubs,
      userSubs,
      notifTrigger,
      getNotifSubs,
      getUserSubs,
    }),
    [notifSubs, userSubs, notifTrigger, getNotifSubs, getUserSubs]
  );

  return (
    <NotificationContext.Provider value={context}>
      <div className="w-full h-full flex flex-row">
        <Tabs orientation="vertical" value={activeTab} onChange={onTabChange} aria-label="task board notification tabs" className="bz-tabs">
          {tabItems && tabItems.length > 0 && tabItems.map((tb, index) => <Tab key={tb} className="p-4" label={tb} {...a11yProps(index)} />)}
        </Tabs>
        <TabPanel value={activeTab} index={0} className="w-full">
          <ProjectNotification />
        </TabPanel>
        <TabPanel value={activeTab} index={1} className="w-full">
          <UserNotification />
        </TabPanel>
      </div>
    </NotificationContext.Provider>
  );
}

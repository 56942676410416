/* eslint-disable */

import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import BaseMainTitle from '../../../components/BaseMainTitle';
import useGet from '../../../hooks/useGet';
import BZHelmet from '../../../utils/BZHelmet';
import TableCategory from './TableCategory';

export default function AssetCategory() {
  const clientId = useSelector((state) => state.client.activeClient);

  const [dataCategory, setDataCategory] = useState([]);
  const [isDataLoad, setIsDataLoad] = useState(false);

  useGet({
    url: `category_assets/?max_size=true&client=${clientId}`,
    validate: 'AssetCategories',
    onSuccess: (e) => {
      // console.log('e', e)
      setDataCategory(e?.data?.results);
      setIsDataLoad(true);
    },
  });

  const data = useMemo(() => dataCategory, [dataCategory]);

  return (
    <div>
      <BZHelmet title="Asset Categories" description="bz publish Asset categories" />
      <BaseMainTitle title="Asset Category" />
      <div className="p-5">
        <TableCategory dataApi={data} isDataLoad={isDataLoad} setIsDataLoad={setIsDataLoad} />
      </div>
    </div>
  );
}

/* eslint-disable */

import React, { useState } from 'react';
import { AttachFile, Cancel, Edit } from '@mui/icons-material';
import BZHelmet from '../../utils/BZHelmet';
import BaseMainTitle from '../../components/BaseMainTitle';
import ApplicantFormDisable from '../../components/Form/Job/ApplicantFormDisable';
import BaseBackButton from '../../components/Button/BaseBackButton';
import useGet from '../../hooks/useGet';
import ApplicantForm from '../../components/Form/Job/ApplicantForm';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import Card from '@mui/material/Card';
// import DownloadLink from 'react-download-link';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useParams } from 'react-router';
import { Avatar, Box, CardHeader, Tab, Typography, CardContent } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Link } from 'react-router-dom';

function getFileNameAndType(url) {
  return url?.substring(url?.lastIndexOf('/') + 1);
}

export default function ApplicantDetail({ isModal, cancel }) {
  const { applicantId } = useParams();

  const [isApplicantData, setIsApplicantData] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useGet({
    url: `applicants/${applicantId || isModal}/?language=1`,
    validate: 'applicant',
    onSuccess: (e) => {
      setIsApplicantData(e?.data);
    },
  });

  const { mutate: updateApplicant } = usePatchOrPut({ url: `applicants/${applicantId || isModal}`, validate: ['applicant'] });

  const onUpdateApplicant = async (data) => {
    await updateApplicant(data);
  };

  let personalInfo;
  let jobHistory;
  let jobInfo;
  if (isApplicantData) {
    personalInfo = [
      // {
      //   name: 'Employment ID',
      //   value: isApplicantData?.employment_id,
      // },
      {
        name: 'Gender',
        value: isApplicantData?.gender,
      },
      // {
      //   name: 'Marital Status',
      //   value: isApplicantData?.marital_status,
      // },
      {
        name: 'Address',
        value: isApplicantData?.address,
      },
      {
        name: 'Birth Date',
        value: isApplicantData?.birth_date,
      },
      {
        name: 'Spoken Languages',
        value: isApplicantData?.spoken_languages.map((e) => e.name)?.join(', '),
      },

      {
        name: 'Skills',
        value: isApplicantData?.skills?.join(', '),
      },
      // {
      //   name: 'Attachments',
      //   value: isApplicantData?.attachments?.name?.join(', '),
      // },
    ];

    jobHistory = [
      {
        id: 1,
        name: 'Position',
        value: isApplicantData?.experiences?.map((e) => e.position),
      },
      // {
      //   id: 2,
      //   name: 'Employment type',
      //   value: isApplicantData?.experiences?.map((e) => e.employment_type),
      // },
      // {
      //   id: 3,
      //   name: 'Department',
      //   value: isApplicantData?.experiences?.map((e) => e.department?.name),
      // },
      // {
      //   id: 4,
      //   name: 'Business unit',
      //   value: isApplicantData?.experiences?.map((e) => e.department?.business_unit?.name),
      // },
      {
        id: 5,
        name: 'Start date',
        value: isApplicantData?.experiences?.map((e) => e.start_date),
      },
      {
        id: 6,
        name: 'End date',
        value: isApplicantData?.experiences?.map((e) => e.end_date),
      },
    ];
    jobInfo = jobHistory.filter((e) => e.id < 5);
  }
  console.log('Personal Info', jobInfo);
  // personalInfo.map((e) => console.log('name', e.name));

  return (
    <>
      <BZHelmet title={isApplicantData?.name ?? ' Applicant'} description="" content="" />
      <div>
        <BaseMainTitle title="Applicant Detail :" />
        <div className="p-5 ">
          <div className="">
            {isApplicantData && (
              <div className="">
                <Card>
                  <CardHeader
                    className="bg-indigo-300 m-5"
                    avatar={
                      <Avatar sx={{ bgcolor: blueGrey[500], height: 110, width: 110 }} aria-label="recipe">
                        {isApplicantData?.avatar ? (
                          <img src={isApplicantData?.avatar} alt="profile" className="object-cover h-[150px] w-[150px]" />
                        ) : (
                          <AccountCircleIcon className="object-cover h-[165px] w-[165px]" />
                        )}
                      </Avatar>
                    }
                    title={
                      <div className="flex justify-between mb-5">
                        <Typography variant="h6">{isApplicantData?.user?.name}</Typography>
                        <div className="flex space-x-3">
                          <Link to="/hr/recruitment/applicants">
                            <ArrowBackIcon />
                          </Link>
                          <Link to={`/hr/recruitment/applicant/detail/${applicantId}`}>
                            <Edit />
                          </Link>
                        </div>
                      </div>
                    }
                    subheader={
                      <div>
                        <div className="flex justify-start mr-6 gap-x-10">
                          {/* <div className="flex font-bold pb-3 mr-[100px]">
                            <div className="mr-10">
                              <p>Status</p>
                              <p>Hired date</p>{' '}
                            </div>
                            <div>
                              {isApplicantData?.is_active ? (
                                <div className={isApplicantData?.is_active ? 'bg-[#bef2be] font-bold rounded px-1 mr-2' : 'bg-[#f6baba] font-bold rounded px-1 mr-5'}>active</div>
                              ) : (
                                <div className={isApplicantData?.is_active ? 'bg-[#bef2be] font-bold rounded px-1 mr-2' : 'bg-[#f6baba] font-bold rounded px-1 mr-5'}>
                                  non active
                                </div>
                              )}
                              <div className="font-bold">{isApplicantData?.hired_date}</div>
                            </div>
                          </div> */}
                          <div>
                            <PhoneIcon className="mr-2" /> {isApplicantData?.phone || '-'} <br />
                            <MailOutlineIcon className="mr-2" />
                            {isApplicantData?.user?.email || '-'}
                          </div>
                          <div className="">
                            {isApplicantData?.resume ? (
                              <a href={isApplicantData?.resume} className="" target="_blank">
                                <div className="flex space-x-1 items-center">
                                  <AttachFile />
                                  <div className="">{getFileNameAndType(isApplicantData?.resume)}</div>
                                </div>
                              </a>
                            ) : (
                              <div className="flex space-x-1 items-center">
                                {' '}
                                <AttachFile />
                                <div className="">-</div>{' '}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  />
                  <CardContent className="mt-2">
                    <TabContext value={value}>
                      <TabList onChange={handleChange} TabIndicatorProps={{ style: { background: '#DAA520' } }} aria-label="lab API tabs example">
                        <Tab value="1" label="Details" />
                        <Tab value="2" label="Experiences" />
                        <Tab value="3" label="Educations" />
                      </TabList>
                      <TabPanel value="1">
                        <Typography variant="body1" className="border-b pb-2 mb-3 font-bold">
                          PERSONAL INFORMATION
                        </Typography>
                        {personalInfo && (
                          <div className="personal-info grid grid-cols-2 w-[60%]">
                            {personalInfo.map((e) => (
                              <div className="mb-3" key={e.name}>
                                <p>{e.name}</p>
                                <p className="font-bold">{e.value || '-'}</p>
                              </div>
                            ))}
                          </div>
                        )}
                        {/* <Typography variant="body1" className="border-b pb-2 mb-3 mt-10 font-bold">
                    JOB INFORMATION
                  </Typography>
                  {jobInfo && (
                    <div className="job-info grid grid-cols-2 w-[60%]">
                      {jobInfo.map((e) => (
                        <div className="mb-3" key={e.name}>
                          <p>{e.name}</p>
                          <p className="font-bold">{e.value[0] || '-'}</p>
                        </div>
                      ))}
                    </div>
                  )} */}
                      </TabPanel>
                      <TabPanel value="2">
                        {isApplicantData?.experiences?.map((e) => (
                          <div
                            key={e.id}
                            className={['job-history m-2 p-3 flex flex-col justify-between  w-[380px]', e.end_date === null ? 'bg-blue-100' : 'bg-neutral-300'].join(' ')}
                          >
                            <div className="mb-3">
                              <p>Position</p>
                              <p className="font-bold">{e?.title || '-'}</p>
                            </div>
                            <div className="mb-3">
                              <p>Company</p>
                              <p className="font-bold">{e?.company || '-'}</p>
                            </div>
                            <div className="mb-3">
                              <p>Description</p>
                              <p className="font-bold">{e?.description || '-'}</p>
                            </div>

                            <div className="p-3 bg-white rounded-lg">
                              <div className="flex gap-3 justify-between ">
                                <div className="mb-3">
                                  <p>Start date</p>
                                  <p className="font-bold">{e?.start_date || '-'}</p>
                                </div>
                                <div className="mb-3">
                                  <p>End date</p>
                                  <p className="font-bold">{e?.end_date || '-'}</p>
                                </div>
                              </div>
                              <div className="mb-3">
                                <p>Duration</p>
                                <p className="font-bold">{e?.duration || '-'}</p>
                              </div>
                            </div>
                          </div>
                        )) || 'No history yet'}
                      </TabPanel>
                      <TabPanel value="3">
                        {isApplicantData?.educations?.map((e) => (
                          <div
                            key={e.id}
                            className={['job-history m-2 p-3 flex flex-col justify-between  w-[380px]', e.end_date === null ? 'bg-blue-100' : 'bg-neutral-300'].join(' ')}
                          >
                            <div>
                              <div className="mb-3">
                                <p>Major</p>
                                <p className="font-bold">{e?.title || '-'}</p>
                              </div>
                              <div className="mb-3">
                                <p>School</p>
                                <p className="font-bold">{e?.school || '-'}</p>
                              </div>
                              <div className="mb-3">
                                <p>Description</p>
                                <p className="font-bold">{e?.description || '-'}</p>
                              </div>

                              <div className="p-3 bg-white rounded-lg">
                                <div className="flex gap-3 justify-between ">
                                  <div className="mb-3">
                                    <p>Start date</p>
                                    <p className="font-bold">{e?.start_date || '-'}</p>
                                  </div>
                                  <div className="mb-3">
                                    <p>End date</p>
                                    <p className="font-bold">{e?.end_date || '-'}</p>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <p>Duration</p>
                                  <p className="font-bold">{e?.duration || '-'}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )) || 'No history yet'}
                      </TabPanel>
                    </TabContext>
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const productListFilter = createSlice({
  name: "productListFilter",
  initialState: {
    search: '',
    status: 'active',
    collection: '',
    category: '',
    sellable: false,
    internal: false,
    page: 1,
    rowsPerPage: 15,
  },
  reducers: {
    setFilterValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetProductListState: (state) => {
      Object.assign(state, initialState);
    },
  }
})

export const { setFilterValue, resetProductListState } = productListFilter.actions;
export default productListFilter;
import { createTheme } from '@mui/material/styles';

const sidebarTheme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            px: 1,
            py: 0.25,
            padding: 0,
            border: 0,
            borderRadius: 1,
            background: '#1d1d1d',
            color: '#c2c7d0',
            boxShadow: 'none',
            '&.Mui-expanded': {
              margin: 0,
              padding: 0,
            },
          }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 1,
            height: '48px',
            '&.Mui-expanded': {
              color: '#ffffff',
              background: 'rgb(52, 52, 52)',
              minHeight: '48px',
              height: '48px',
            },
          }),
        expandIconWrapper: ({ theme }) =>
          theme.unstable_sx({
            background: '#1d1d1d',
            color: '#c2c7d0',
            '&.Mui-expanded': {
              color: '#ffffff',
              background: 'rgb(52, 52, 52)',
            },
          }),
      },
    },
    MuiAccordionDetails: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({ padding: '8px 0px 16px' }) } },
  },
});

export default sidebarTheme;

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import POSApi from '@/api/pos/posApi';
import { fetchData } from '@/utils/fetchDataFunc';
import { TextField, Box, CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
import { DatePickerFieldWithoutController } from '@/components/Input/DatePickerField';
import { DataGrid } from '@mui/x-data-grid';
import { utils, writeFileXLSX } from 'xlsx';
import moment from 'moment';
import ModalWrapper from '@/components/ui/ModalWrapper';
import { handleModal } from '@/store/slices/modalSlice';
import ShiftSummary from '@/components/Form/Sales/ShiftSummary';

function OutletDetailShiftReportTab() {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const { outletId } = useParams();
  const dispatch = useDispatch();

  const [startPeriod, setStartPeriod] = useState(null);
  const [endPeriod, setEndPeriod] = useState(null);
  const [isLoadingGetReport, setIsLoadingGetReport] = useState(false);
  const [report, setReport] = useState(null);
  console.log({report});
  

  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    getShiftReport();
  }, [outletId]);

  const onFilterStartDate = (event, key) => {
    const parsedDate = new Date(event);

    if (event === null) {
      return key === 'startPeriod' ? setStartPeriod(event) : setEndPeriod(event);
    }

    if (parsedDate.getFullYear() >= 2010 && !isNaN(parsedDate)) {
      return key === 'startPeriod' ? setStartPeriod(event) : setEndPeriod(event);
    } else {
      return;
    }
  };

  const getShiftReport = async () => {
    const { getOutletShiftReports } = POSApi();
    const newDt = { outlet: +outletId, ordering: '-started_at', max_size: true };

    if (startPeriod) {
      newDt.start = moment(startPeriod).format('YYYY-MM-DD');
    }

    if (endPeriod) {
      newDt.end = moment(endPeriod).format('YYYY-MM-DD');
    }

    try {
      setIsLoadingGetReport(true);

      await getOutletShiftReports(newDt).then((rp) => {
        if (rp.status === 200) {
          const { data } = rp;

          const reportData = data.map((dt) => {
            const { started_at, closed_at, ...rest } = dt;

            return {
              ...rest,
              started_at: moment(started_at).format('YYYY-MM-DD HH:mm'),
              closed_at: moment(closed_at).format('YYYY-MM-DD HH:mm'),
            };
          });

          setReport(reportData);
        }
      });
    } catch (error) {
      console.log(error);
      console.error(error);
    } finally {
      setIsLoadingGetReport(false);
    }
  };

  const formatNominal = (value) => {
    if (value === null || value === undefined) {
      return '';
    }

    const integerValue = Math.floor(Number(value));

    const formattedValue = integerValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedValue;
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Shift Id',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        return (
          <div
            className="w-fit cursor-pointer text-[#2C6D47] font-semibold"
            onClick={() =>
              dispatch(
                handleModal({
                  modalId: clientId,
                  componentName: 'shiftSummary',
                  modalData: {
                    outletId,
                    mode: 'edit',
                    shiftId: params.id || null,
                  },
                })
              )
            }
          >
            {params.row.id || ''}
          </div>
        );
      },
    },
    {
      field: 'started_at',
      headerName: 'Shift Started',
      headerAlign: 'left',
      align: 'left',
      width: 150,
      renderCell: (params) => params.row.started_at || '',
    },
    {
      field: 'closed_at',
      headerName: 'Shift Closed',
      headerAlign: 'left',
      align: 'left',
      width: 150,
      renderCell: (params) => (
        <p>{params.row.closed_at === "Invalid date" ? "Currently Running" : params.row.closed_at}</p>
      ),
    },
    {
      field: 'operator__employment_id',
      headerName: 'Operator',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => params.row.operator__employment_id || '',
    },
    {
      field: 'total_sales',
      headerName: 'Total Sales',
      headerAlign: 'left',
      align: 'right',
      width: 150,
      renderCell: (params) => (
        <p className={params.row.total_sales < 0 && "text-red-500"}>{formatNominal(params.row.total_sales) || ""}</p>
      ),
    },
    {
      field: 'total_others',
      headerName: 'Total Others',
      headerAlign: 'left',
      align: 'right',
      width: 150,
      renderCell: (params) => (
        <p className={params.row.total_others < 0 && "text-red-500"}>{formatNominal(params.row.total_others) || ""}</p>
      ),
    },
    {
      field: 'total_voids',
      headerName: 'Total Void',
      headerAlign: 'left',
      align: 'right',
      width: 150,
      renderCell: (params) => (
        <p className={params.row.total_voids < 0 && "text-red-500"}>{formatNominal(params.row.total_voids) || ""}</p>
      ),
    },
    // {
    //   field: 'total_void',
    //   headerName: 'Total Void',
    //   headerAlign: 'left',
    //   align: 'right',
    //   renderCell: (params) => params.row.operator?.employment_id || '',
    // },
    {
      field: 'starting_cash',
      headerName: 'Starting Cash',
      headerAlign: 'left',
      align: 'right',
      width: 150,
      renderCell: (params) => (
        <p className={params.row.starting_cash < 0 && "text-red-500"}>{formatNominal(params.row.starting_cash) || ""}</p>
      ),
    },
    {
      field: 'ending_cash',
      headerName: 'Ending Cash',
      headerAlign: 'left',
      align: 'right',
      width: 150,
      renderCell: (params) => (
        <p className={params.row.drawer_cash < 0 && "text-red-500"}>{formatNominal(params.row.drawer_cash) || ""}</p>
      ),
    },
  ];

  const exportExcel = () => {
    const columnOrder = ['id', 'started_at', 'closed_at', 'operator__employment_id', 'total_sales', 'total_others', 'total_voids', 'starting_cash', 'drawer_cash'];

    const processedReport = report.map(({ dt, ...rest }) => {
      const newObj = {};

      columnOrder.forEach((key) => {
        const newKey = key !== 'drawer_cash' ? key.replace(/_/g, ' ') : 'Ending Cash';

        if (Array.isArray(rest[key])) {
          newObj[newKey] = rest[key].join(', ');
        } else {
          if(newKey === "closed at" && rest[key] === "Invalid date") {
            newObj[newKey] = "Currently Running";
          } else {
            newObj[newKey] = rest[key];
          }
        }
      });

      return newObj;
    });

    const ws = utils.json_to_sheet(processedReport);

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');

    writeFileXLSX(wb, `shift-report.xlsx`);
  };

  return (
    <div className="space-y-8">
      <form className="w-full relative flex gap-4">
        <div className="w-full flex justify-between gap-4">
          <div className="w-fit flex gap-4">
            <div className="w-fit">
              <p className="font-bold mb-[5px]">Start Period</p>
              <DatePickerFieldWithoutController
                value={startPeriod}
                onChange={(newValue) => onFilterStartDate(newValue, 'startPeriod')}
                renderInput={(params) => {
                  return <TextField sx={{ width: 'auto' }} size="small" {...params} />;
                }}
                componentsProps={{ actionBar: { actions: ['clear'] } }}
                minDate={new Date(2010, 0, 1)}
              />
            </div>
            <div className="w-fit">
              <p className="font-bold mb-[5px]">End Period</p>
              <DatePickerFieldWithoutController
                value={endPeriod}
                onChange={(newValue) => onFilterStartDate(newValue, 'endPeriod')}
                renderInput={(params) => {
                  return <TextField sx={{ width: 'auto' }} size="small" {...params} />;
                }}
                componentsProps={{ actionBar: { actions: ['clear'] } }}
                minDate={new Date(2010, 0, 1)}
              />
            </div>
            <button
              className={`
                flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white h-[40px] mt-auto bg-[#2C6D47] cursor-pointer whitespace-nowrap
              `}
              name="generateReport"
              type="button"
              onClick={getShiftReport}
            >
              Search
            </button>
          </div>
          {report && !isLoadingGetReport && (
            <button
              className={`
                  flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white h-[40px] mt-auto bg-[#2C6D47] cursor-pointer whitespace-nowrap
                `}
              name="exportToExcel"
              type="button"
              onClick={exportExcel}
            >
              Excel Export
            </button>
          )}
        </div>
      </form>
      {isLoadingGetReport && <CircularProgress />}
      {report && !isLoadingGetReport && (
        <Box className="w-full h-fit overflow-x-auto">
          <DataGrid
            autoHeight
            rows={report}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: rowsPerPage,
                },
              },
            }}
            onPageSizeChange={(newPageSize) => setRowsPerPage(Number(newPageSize))}
            pageSizeOptions={[20, 50, 100]}
            disableRowSelectionOnClick
            className="m-auto w-fit"
          />
        </Box>
      )}
      <ModalWrapper componentName="shiftSummary" header="Shift summary" maxWidth="md">
        <ShiftSummary />
      </ModalWrapper>
    </div>
  );
}

export default OutletDetailShiftReportTab;

import useGet from '@/hooks/useGet';
import { ArrowForwardIos, Article, Image, MoreVert } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, ButtonBase, Card, CircularProgress, Fade, Grid, Popover, Typography } from '@mui/material';
import { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
function TableFile() {
  const activeClient = useSelector((state) => state.client.activeClient);
  const { data: attachments, isFetching, isFetched } = useGet({
    url: `project-management/project_files`,
    queryString: {
      project_profile_client: activeClient,
      max_size: true,
    },
    validate: 'categoriesArticle',

  });

  return (
    <Suspense fallback={<CircularProgress />}>
      {!isFetching ? (
        <Grid container direction="row" spacing={1}>
          {isFetched && attachments?.data?.results?.length ? (
            attachments?.data?.results.map((att) => {
              return (
                <CardFile
                  key={`att-${att.id}`}
                  data={att}
                  projectId={att.project}
                  onClickPreview={() => {
                    // setPopoverAttachments(true);
                    // setFilesAttachments([att]);
                  }}
                />
              );
            })
          ) : (
            <p className="w-full text-center mt-12">no data</p>
          )}
        </Grid>
      ) : (
        <CircularProgress className="m-auto" />
      )}
    </Suspense>
  )
}

export default TableFile

function CardFile({ data, onClickPreview }) {

  const [anchorAction, setAnchorAction] = useState(false);

  const { file, category, country, project, upload_by, updated_on } = data;
  const fileExt = file.substring(file.lastIndexOf('.') + 1);
  const fName = file.substring(file.lastIndexOf('/') + 1);
  const fileName = fName.substring(0, fName.indexOf('.'));

  const isImage = ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp'].includes(fileExt.toLowerCase());
  const openAction = Boolean(anchorAction);
  const idAction = openAction ? 'simple-popover' : undefined;

  const handleClickAction = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorAction(null);
  };

  return (<>
    <Grid key={file} className="relative w-1/2">
      <Box className="p-2" onClick={onClickPreview}>
        <Card variant="outlined" className="p-2 relative">
          <Box className="flex mb-2">
            {isImage ? <Image className="w-10 h-10" /> : <Article className="w-10 h-10" />}
            <Box className="flex flex-col ml-2">
              <Typography>{fileName}</Typography>
            </Box>
            <MoreVert onClick={handleClickAction} className="absolute top-4 right-4 z-10" />
          </Box>
          {isImage ? <img src={file} alt={fileName} className="w-full h-36 object-contain" loading="lazy" /> : null}
          {!isImage && <div className='h-4' />}
          <a href={`${file}`} target='_blank' download={`${file}`} className={` bottom-2 right-4 absolute hover:text-blue-400`}><DownloadIcon /></a>
        </Card>
      </Box>
      <Popover
        id={idAction}
        open={openAction}
        anchorEl={anchorAction}
        onClose={handleCloseAction}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ transform: 'translate(0px, 4px)' }}
      >
        <Fade in={openAction}>
          <Box style={{ minWidth: '180px', color: '#fff' }} className="relative flex flex-col p-2 space-y-2 rounded-lg bg-[#ffffff]">
            <ButtonBase
              className="px-2 flex justify-start text-black relative"
              endIcon={<ArrowForwardIos className="w-4 h-4 -mr-4 absolute top-2.5 right-4" />}
              onClick={() => {
                // dispatch({ type: 'sidebarTask/setActiveTabs', payload: 1 });
                // navigate(`/task/project/${projectId}/${comment.topic}`, { replace: false });
              }}
            >
              <div className='text-left'>
                <p>
                  Filename : {fileName}
                </p>
                <p  >
                  Type : {fileExt}
                </p>
                <p  >
                  Uploaded / updated by : {upload_by?.name}
                </p>
                <p  >
                  Project Name: {project?.name}
                </p>
                <p  >
                  Project Profile: {project?.profile_name}
                </p>
                <p  >
                  Country: {country?.name}
                </p>
                <p  >
                  Updated Timestamp : {updated_on}
                </p>
              </div>
            </ButtonBase>
          </Box>
        </Fade>
      </Popover>
    </Grid>
  </>
  );
}

/* eslint-disable */
import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import BaseMainTitle from '../../../components/BaseMainTitle';
import { fetchWhistleblowers, useWhistleblowersData } from '../../../hooks/useWhistleblowerData';
import BZHelmet from '../../../utils/BZHelmet';
import TableReport from './TableReport';

export default function ReportWistle() {
  const clientId = useSelector((state) => state.client.activeClient);

  const [dataReport, setDataReport] = useState([]);
  const [isDataLoad, setIsDataLoad] = useState(false);
  useQuery(['Whistleblowers', { clientId }], fetchWhistleblowers, {
    onSuccess: (e) => {
      setDataReport(e?.data?.results);
      setIsDataLoad(true);
    },
  });

  const data = useMemo(() => dataReport, [dataReport]);

  return (
    <div>
      <BZHelmet title="Whistleblowers" description="bz publish Whistleblowers" />
      <BaseMainTitle title="Whistleblowers" />
      <div className="p-5">
        <TableReport dataApi={data ?? []} isDataLoad={isDataLoad} setIsDataLoad={setIsDataLoad} />
      </div>
    </div>
  );
}

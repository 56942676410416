import axiosInstances from '../../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function report() {
  const { axiosInstance } = axiosInstances();

  async function getFinancialYears(params) {
    const method = 'get';
    const url = `${baseURL}accounting/financial_years/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function getAccountingReports(params) {
    const method = 'get';
    const url = `${baseURL}accounting/reports/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function getDetailAccountingReports(idAccReport, data, extraActions = false, params = {}) {
    const method = 'post';
    let url = `${baseURL}accounting/reports/${idAccReport}/`;
    if (extraActions) {
      url = `${baseURL}accounting/reports/${idAccReport}/${extraActions}/`;
    }
    const response = await axiosInstance({
      method,
      url,
      data,
      params,
    });
    return response;
  }
  // LEDGERS
  async function getLedgers(params, id = "") {
    const method = 'get';
    let url = `${baseURL}accounting/ledgers/`;
    if (id) {
      url += `${id}/`
    }
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getBalanceReport(id, data, params) {
    const method = 'post';
    const url = `${baseURL}accounting/reports/${id}/get_balance/`;
    const response = await axiosInstance({
      method,
      url,
      data,
      params,
    });
    return response;
  }

  async function getAccountingReport(id, data, params) {
    const method = 'post';
    const url = `${baseURL}accounting/financial_years/${id}/get_report/`;
    const response = await axiosInstance({
      method,
      url,
      data,
      params,
    });
    return response;
  }

  return { getFinancialYears, getAccountingReports, getDetailAccountingReports, getLedgers, getBalanceReport, getAccountingReport };
}

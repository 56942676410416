/* eslint-disable */

import React from 'react';
import { useParams } from 'react-router';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useSelector, useDispatch } from 'react-redux';
import BaseMainTitle from '../../components/BaseMainTitle';
import JobPosterForm from '../../components/Form/Job/JobPosterForm';
import { useJobPosterDetail, useUpdateJobPosters } from '../../hooks/useJobPostersData';
import useGet from '../../hooks/useGet';
import BZHelmet from '../../utils/BZHelmet';
import TeamTab from './TeamTab';
import LocationTab from './LocationTab';
import CompanyTab from './CompanyTab';
import KeywordTab from './KeywordTab';
import CurrenciesTab from './CurrenciesTab';
import BaseBackButton from '../../components/Button/BaseBackButton';

export default function JobDetail() {
  const { jobPosterId } = useParams();
  // const activeClient = useSelector((state) => state.client.activeClient);
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: jobDetail, isFetching } = useGet({ url: `job_posters/${jobPosterId}`, validate: 'JobPoster' });

  const { mutate: updateJob } = useUpdateJobPosters(jobPosterId, jobDetail?.data);
  
  return (
    <>
      <BZHelmet title="Job Detail" description="" content="" />
      <div>
        <BaseMainTitle title="Job Detail :" />
        <div className="p-5 ">
          <BaseBackButton className="shadow p-1 rounded w-fit bg-white flex mb-3" />
          <div className="bg-white border p-5 shadow rounded">
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Job Poster" value="1" />
                    <Tab label="Team" value="2" />
                    {/* <Tab label="Location" value="3" />
                    <Tab label="Company" value="4" /> */}
                    <Tab label="Keyword" value="3" />
                    <Tab label="Currencies" value="4" />
                  </TabList>
                </Box>
                <TabPanel className="!p-0" value="1">
                  <JobPosterForm isStageAccordion defaultValues={jobDetail?.data} key={jobDetail?.data?.id} onFormSubmit={updateJob} isFetching={isFetching} />
                </TabPanel>
                <TabPanel className="!p-0" value="2">
                  <TeamTab />
                </TabPanel>
                {/* <TabPanel className="!p-0" value="3">
                  <LocationTab />
                </TabPanel>
                <TabPanel className="!p-0" value="4">
                  <CompanyTab />
                </TabPanel> */}
                <TabPanel className="!p-0" value="3">
                  <KeywordTab />
                </TabPanel>
                <TabPanel className="!p-0" value="4">
                  <CurrenciesTab />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

/* eslint-disable */

import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import request from '../helper/axiosUtil';
import { resetAsset } from '../store/slices/assetCrudSlice';
import { toastError, toastLoading, toastSuccess } from '../constants/ToastConfig';
import { toast } from 'react-toastify';
import renderError from '../utils/Error/renderError';
import { useNavigate } from 'react-router';
const MySwal = withReactContent(Swal);

export default function useDelete({ id, url, confText, confTitle, success, validate, bodyData, method = 'delete', isSubUrl = true, extra, navTo, extraAction }) {
  const qClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const getUrl = isSubUrl ?
  const destroy = async (data) => {
    return request({
      url: `/${isSubUrl ? data : url}/`,
      method,
      data: bodyData || data.bodyData,
    });
  };

  let onToast;
  const useDeleteOn = () => {
    return useMutation(destroy, {
      // onMutate: () => {
      //   onToast = toast.loading('Loading  ', toastLoading);
      // },
      onSuccess: (_data) => {
        onToast = new Promise((resolve, reject) => {
          if ([200, 204].includes(_data.status)) {
            setTimeout(() => {
              resolve('Success');
              if (Boolean(extraAction)) {
                extraAction();
              }
            }, 500);
          } else {
            const error = new Error('Request failed');
            error.data = JSON.parse(e.request.response);
            setTimeout(() => {
              renderError({ data: JSON.parse(e.request.response) });
              reject(error);
            }, 500);
          }
        });

        toast.promise(onToast, {
          pending: {
            render() {
              return 'Loading';
            },
            ...toastLoading,
          },
          success: {
            render({ data }) {
              return ` ${data}`;
            },
            ...toastSuccess,
          },
          error: {
            render() {
              return 'Error';
            },
            ...toastError,
          },
        });
      },
      onSettled: () => {
        validate?.forEach((val) => qClient.invalidateQueries(val));
        dispatch(resetAsset());
        if (navTo) {
          navigate(navTo);
        }
      },
      onError: (err) => {
        console.log('err', err);
      },
    });
  };

  const { mutate: onDelete } = useDeleteOn(id);

  return async (idData, idx) => {
    await MySwal.fire({
      title: t(confTitle),
      html: t(confText),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Delete`,
      denyButtonText: `Don't save`,
      confirmButtonColor: '#C62828',
      cancelButtonColor: '#8bc34a',
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        onDelete(idData);
        extra && extra(idx);
      } else {
        ('');
      }
    });
  };
}

/* eslint-disable */
import React from 'react';
import { DragDropContext as DragDropContext_, Droppable as Droppable_, Draggable as Draggable_ } from 'react-beautiful-dnd';

// Maps

const draggableMap = {};
const droppableMap = {};

// Draggable

export class Draggable extends React.Component {
  callHandler = (handler, result) => {
    return this.props[handler] && this.props[handler](result);
  };

  componentDidMount() {
    const { draggableId } = this.props;
    draggableMap[draggableId] = this;
  }

  componentWillUnmount() {
    const { draggableId } = this.props;

    delete draggableMap[draggableId];
  }

  getPayload = () => this.props.payload;

  render() {
    return <Draggable_ {...this.props} />;
  }
}

// Droppable

export class Droppable extends React.Component {
  callHandler = (handler, result) => {
    return this.props[handler] && this.props[handler](result);
  };

  componentDidMount() {
    const { droppableId } = this.props;
    droppableMap[droppableId] = this;
  }

  componentWillUnmount() {
    const { droppableId } = this.props;
    delete droppableMap[droppableId];
  }

  getPayload = () => {
    const { payload } = this.props;
    return payload;
  };

  render() {
    return <Droppable_ {...this.props} />;
  }
}

// DragDropContext

export class DragDropContext extends React.Component {
  callHandler = (droppableId, handler, result) => {
    return droppableMap[droppableId] && droppableMap[droppableId].callHandler(handler, result);
  };

  callHandlerOnDraggable = (draggableId, handler, result) => {
    return draggableMap[draggableId] && draggableMap[draggableId].callHandler(handler, result);
  };

  getPayload = (droppableId) => {
    return droppableMap[droppableId] && droppableMap[droppableId].getPayload();
  };

  getDraggablePayload = (draggableId) => {
    return draggableMap[draggableId] && draggableMap[draggableId].getPayload();
  };

  handleEvent = (handler) => (result) => {
    const { source, destination, draggableId } = result;

    if (source) {
      source.payload = this.getPayload(source.droppableId);
    }
    if (destination) {
      destination.payload = this.getPayload(destination.droppableId);
    }

    // eslint-disable-next-line no-param-reassign
    result.payload = this.getDraggablePayload(draggableId);

    this.callHandlerOnDraggable(draggableId, handler, result);

    if (destination) {
      this.callHandler(destination.droppableId, handler, result);
    }

    if (!destination || destination.droppableId !== source.droppableId) {
      this.callHandler(source.droppableId, handler, result);
    }

    return this.props[handler] && this.props[handler](result);
  };

  render() {
    const newProps = {
      ...this.props,
      onBeforeDragStart: this.handleEvent('onBeforeDragStart'),
      onDragStart: this.handleEvent('onDragStart'),
      onDragUpdate: this.handleEvent('onDragUpdate'),
      onDragEnd: this.handleEvent('onDragEnd'),
    };
    return <DragDropContext_ {...newProps} />;
  }
}

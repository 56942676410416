// /* eslint-disable */

import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import useDelete from '../../../hooks/useDelete';
import { handleUnSelect } from '../../../store/slices/assetCrudSlice';

const MySwal = withReactContent(Swal);

export default function UnpairAssetButton({ isApi, url, method = 'patch', validate, variant = 'outlined', color = 'primary', disabled = false }) {
  const bulkDelete = useSelector((state) => state.assetCrud.bulkDelete);
  const assetPair = useSelector((state) => state.assetCrud.selected);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onUnPairAssetsNonApi = async () => {
    await MySwal.fire({
      title: t('modals.asset_delete.title'),
      html: t('modals.asset_delete.confirmation_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Delete`,
      denyButtonText: `Don't save`,
      confirmButtonColor: '#C62828',
      cancelButtonColor: '#8bc34a',
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        bulkDelete.forEach((un) => dispatch(handleUnSelect(JSON.stringify({ id: un }))));
        MySwal.fire({
          html: t('Assets deleted'),
          icon: 'success',
        });
      }
    });
  };

  const onDestroyAsset = useDelete({
    url,
    confText: `modals.asset_delete.confirmation_text`,
    confTitle: `modals.asset_delete.title`,
    success: 'Assets deleted',
    validate,
    bodyData: { assets: assetPair },
    method,
    isSubUrl: false,
  });

  return (
    <Button onClick={isApi ? () => onDestroyAsset() : () => onUnPairAssetsNonApi()} className="flex" type="button" variant={variant} color={color} disabled={disabled}>
      REMOVE SELECTED ASSETS
    </Button>
  );
}

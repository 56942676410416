// /* eslint-disable */

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationMenuDetail = yup
  .object()
  .shape({ item_languages: yup.array().of(yup.object().shape({ name: yup.string().required('Name is required') })) })
  .required();

const schemaMenuDetail = yupResolver(validationMenuDetail);

export default schemaMenuDetail;

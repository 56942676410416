import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';

const dayName = (num) => {
  switch (num) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '-';
  }
};

const readCron = (cronStr) => {
  const cron = cronStr.split(' ');
  const offset = new Date().getTimezoneOffset() / 60;

  const WEEKLY = 'Weekly';
  const DAILY = 'Daily';

  const periodicity = cron[4] === '*' ? DAILY : WEEKLY;
  let day = periodicity === WEEKLY ? parseInt(cron[4], 10) : undefined;
  let at = parseInt(cron[1], 10) - offset;

  if (at > 23) {
    at -= 24;

    if (periodicity === WEEKLY) {
      day += 1;
    }
  }

  if (at < 0) {
    at += 24;

    if (periodicity === WEEKLY) {
      day -= 1;
    }
  }

  if (day > 6) {
    day -= 7;
  }

  if (day < 0) {
    day += 7;
  }

  return {
    periodicity,
    at,
    day: day === undefined ? '-' : day,
  };
};

const cellTooltip = (params) => (
  <Tooltip className="truncate" title={params.value}>
    <p>{params.value}</p>
  </Tooltip>
);

function SiteSchedule({ rows, deleteSchedule, ...props }) {
  const timezoneOffset = () => {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    return offset / 60;
  };

  const getTimezone = () => {
    const timezone = timezoneOffset() * -1;
    return timezone >= 0 ? `+${timezone}` : timezone;
  };

  const formattedRows = rows.map((row) => {
    const { day, at, periodicity } = readCron(row.cron);

    return {
      id: row.id,
      site: row.site.name,
      periodicity,
      day,
      at,
      pages: row.pages.map((e) => e.name).join(', '),
      action: row.id,
    };
  });

  const columns = [
    { field: 'site', headerName: 'Site', width: 150 },
    { field: 'periodicity', headerName: 'Periodicity', flex: 1, minWidth: 120 },
    { field: 'day', headerName: 'Day', flex: 1, minWidth: 120, valueGetter: (params) => dayName(params.value) },
    { field: 'at', headerName: 'At', flex: 1, minWidth: 200, valueFormatter: (params) => `${_.padStart(params.value, 2, '0')}:00 (UTC ${getTimezone()})` },
    { field: 'pages', headerName: 'Pages', flex: 1, minWidth: 200, renderCell: cellTooltip },
    {
      field: 'action',
      headerName: '',
      minWidth: 50,
      align: 'center',
      renderCell: (params) => (
        <IconButton onClick={() => deleteSchedule(params.value)} color="error" size="small">
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div style={{ height: 650, width: '100%' }}>
      <DataGrid rows={formattedRows} columns={columns} pageSize={10} rowsPerPageOptions={[10]} {...props} />
    </div>
  );
}

export default SiteSchedule;

import React, { useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import projectApi from '../../../../api/task/project';
import getTimeZone from '../../../../utils/Task/getTimeZone';

export default function ModalTaskNewRecord({ show, activeTask, callback, handleClose }) {
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(false);

  const onUpdate = () => {
    const tIn = new Date(startTime);
    const tOut = new Date(endTime);
    const currTimezone = getTimeZone();

    const dataLog = {
      topic: activeTask.id,
      time_in: tIn.toISOString(),
      time_out: tOut.toISOString(),
    };
    dataLog.timezone = {
      name: currTimezone.name,
      utc_offset: currTimezone.offset,
    };

    const { logWorkHours } = projectApi();
    logWorkHours(dataLog, null, {}, 'post')
      .then((res) => {
        if (res.data) {
          callback();
          handleClose();
        }
      })
      .catch((err) => console.log(err.response));
  };

  const getSpentTime = useMemo(() => {
    if (startTime && endTime) {
      return Math.ceil((new Date(endTime).getTime() - new Date(startTime).getTime()) / 60000);
    }
    return 0;
  }, [startTime, endTime]);

  return (
    <Dialog
      open={!!show}
      onClose={handleClose}
      sx={{ '& > .MuiDialog-container > .MuiPaper-root': { width: 567, position: 'absolute', top: '20vh', height: 'auto', overflowY: 'auto' } }}
    >
      <DialogTitle>{activeTask.name}</DialogTitle>
      <DialogContent dividers>
        <Box className="flex flex-col mt-2 space-y-2">
          <span>Start Time</span>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              ampm={false}
              ampmInClock={false}
              maxDate={() => {
                const tOut = new Date();
                console.log('max', new Date(tOut.getTime + 1));
                return new Date(tOut.getTime);
              }}
              value={startTime}
              onChange={(newValue) => {
                const tIn = new Date(newValue).getTime();
                const tOut = new Date().getTime();
                if (tIn > tOut) {
                  return;
                }
                setStartTime(newValue);
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box className="flex flex-col mt-2 space-y-2">
          <span>End Time</span>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              ampm={false}
              ampmInClock={false}
              minDate={() => {
                const tOut = new Date(startTime);
                return new Date(tOut.getTime);
              }}
              value={endTime || ''}
              onChange={(newValue) => {
                const tIn = new Date(startTime).getTime();
                const tOut = new Date(newValue).getTime();
                if (tIn > tOut) {
                  return;
                }
                setEndTime(newValue);
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
              disabled={!startTime}
            />
          </LocalizationProvider>
        </Box>
        <Box className="flex flex-row justify-between">
          <div className="w-50">
            <span>Spent Time: {startTime && endTime ? `${getSpentTime} ${getSpentTime > 1 ? 'mins' : 'min'}` : '-'}</span>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} size="small">
          Cancel
        </Button>
        <Button variant="contained" onClick={onUpdate} size="small">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// import { format } from 'date-fns';
import axiosInstances from '../../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

function transactions() {
  const { axiosInstance, axiosInstanceF } = axiosInstances();
  async function getTransactions(params) {
    const method = 'get';
    const url = `${baseURL}accounting/transactions/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function addTransaction(trans) {
    const data = new FormData();

    data.append('ledger', 1);
    // data.append('number', format(new Date(trans.date), 'yyyyMMdd'));
    data.append('number', trans.journalNumber);
    data.append('date', new Date(trans.date).toISOString());

    data.append('description', trans.description);

    if (trans.line_details && trans.line_details.length) {
      trans.line_details.forEach((dt, index) => {
        if (dt.data) {
          data.append(`line_details[${index}]account`, dt.data?.account?.id);
          data.append(`line_details[${index}]cost_center`, dt.data?.center?.id);
          if (dt.data?.counterpart?.id) {
            data.append(`line_details[${index}]counterpart`, dt.data?.counterpart?.id);
          }
          if (dt.data?.project_id?.id) {
            data.append(`line_details[${index}]project_id`, dt.data?.project_id?.id);
          }
          data.append(`line_details[${index}]accounting_period`, dt.data?.accper?.accounting_period?.id || null);
          data.append(`line_details[${index}]ledger_currency`, dt.data?.currency?.id);
          data.append(`line_details[${index}]reconciliation_reference`, dt.data?.recref || ``);
          data.append(`line_details[${index}]is_reconciled`, !!dt.data?.recref);
          data.append(`line_details[${index}]debit`, dt.debit);
          data.append(`line_details[${index}]credit`, dt.credit);
          data.append(`line_details[${index}]code`, dt.line);
          data.append(`line_details[${index}]base_currency`, dt.baseCurrency);
          data.append(`line_details[${index}]base_currency_rate`, dt.rate);
          data.append(`line_details[${index}]base_amount`, dt.balance_amount);
          data.append(`line_details[${index}]transaction_amount`, dt.transaction_amount);
        }
      });
    }

    if (trans.attachments && trans.attachments.length) {
      trans.attachments.forEach((file, index) => {
        data.append(`supporting_documents[${index}]code`, trans.journalNumber);
        data.append(`supporting_documents[${index}]file_upload`, file);
      });
    }

    const method = 'post';
    const url = `${baseURL}accounting/transactions/`;
    const response = await axiosInstanceF({
      method,
      url,
      data,
    });
    return response;
  }
  async function updateTransaction(transId, trans) {
    const data = new FormData();

    // data.append('ledger', 1);
    data.append('number', trans.number);
    data.append('date', new Date(trans.date).toISOString());
    data.append('description', trans.description);

    if (trans.attachments && trans.attachments.length) {
      trans.attachments.forEach((file, index) => {
        data.append(`supporting_documents[${index}]code`, trans.number);
        data.append(`supporting_documents[${index}]file_upload`, 'file_upload' in file ? file.file_upload : file);
      });
    }

    const method = 'patch';
    const url = `${baseURL}accounting/transactions/${transId}/`;
    const response = await axiosInstanceF({
      method,
      url,
      data,
    });
    return response;
  }
  async function getTransactionDetail(id, params) {
    const method = 'get';
    const url = `${baseURL}accounting/transactions/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function postAttachmentTransaction(attachment, trans) {
    const data = new FormData();
    data.append(`transaction`, trans.transaction);
    data.append(`code`, trans.number);
    data.append(`file_upload`, attachment);

    const method = 'post';
    const url = `${baseURL}accounting/supporting_documents/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }
  async function importTransactions(id, file, params) {
    const data = new FormData();
    data.append(`file`, file);

    const method = 'post';
    let url = `${baseURL}accounting/ledgers/${id}/import_transactions/`;
    const response = await axiosInstance({
      method,
      url,
      data,
      params,
    });
    return response;
  }
  async function deleteAttachmentTransaction(attachmentId) {
    const method = 'delete';
    const url = `${baseURL}accounting/supporting_documents/${attachmentId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  return { getTransactions, getTransactionDetail, addTransaction, updateTransaction, postAttachmentTransaction, importTransactions, deleteAttachmentTransaction };
}

export default transactions;

/* eslint-disable */
import DeptOrgCharts from '@/pages/HR/Department/DeptOrgCharts';
import { Delete, DeleteForever, Edit, GridView, Search, ViewList } from '@mui/icons-material';
import { Button, ButtonBase, InputBase, TableCell, TableRow, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TreeNode } from 'react-organizational-chart';
import { useDispatch, useSelector } from 'react-redux';
import BaseMainTitle from '../../../components/BaseMainTitle';
import DepartmentForm from '../../../components/Form/Whistleblower/DepartmentForm';
import InputContainer from '../../../components/Input/InputContainer';
import ReactSelectSingle from '../../../components/Input/ReactSelectSingle';
import CustomTable from '../../../components/Table/CustomTable';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import useDebounce from '../../../hooks/useDebounce';
import useDelete from '../../../hooks/useDelete';
import useGet from '../../../hooks/useGet';
import { fetchFilterDepartment } from '../../../hooks/useNewHrData';
import usePost from '../../../hooks/usePost';
import { useDepartmentDetail, useUpdateDepartment } from '../../../hooks/useWhistleblowerData';
import { handleModal } from '../../../store/slices/modalSlice';
import BZHelmet from '../../../utils/BZHelmet';

const headCellsDepartment = [
  {
    id: 'business_unit',
    numeric: false,
    disablePadding: false,
    label: 'Business Unit',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Department',
  },
  // {
  //   id: 'business_unit',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Business Unit',
  // },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'Location',
  },
  {
    id: 'manager',
    numeric: false,
    disablePadding: false,
    label: 'Manager',
  },
  {
    id: 'team-leader',
    numeric: false,
    disablePadding: false,
    label: 'Team Leader',
  },
  {
    id: 'USER',
    numeric: false,
    disablePadding: false,
    label: 'PIC User',
  },
  {
    id: 'parent',
    numeric: false,
    disablePadding: false,
    label: 'Parent',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    headerAlign: 'center',
    label: 'Action',
  },
];

function ActionCell(props) {
  const { id } = props;
  const dispatch = useDispatch();

  const destroyDepartment = useDelete({
    confText: `Are you sure want to delete this Departments ?`,
    confTitle: `Confirm Delete Departments`,
    success: 'Department deleted',
    validate: ['Departments'],
  });

  const removeDepartment = () => {
    destroyDepartment(`employee-management/departments/${id}`);
  };

  return (
    <div className="w-full flex space-x-2 justify-center">
      <ButtonBase onClick={() => dispatch(handleModal({ modalId: id, componentName: 'editDepartment' }))} className="bz-btn-tb" type="button" variant="">
        <Edit fontSize="24" />
      </ButtonBase>
      <ButtonBase className="bz-btn-tb" onClick={() => removeDepartment()}>
        <Delete fontSize="24" />
      </ButtonBase>
    </div>
  );
}

export default function DepartmentList() {
  const { control, watch, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const unitId = watch('business_unit');

  const dispatch = useDispatch();

  const [dataDepartment, setDataDepartment] = useState([]);
  const [unitData, setunitData] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [isGrid, setIsGrid] = useState(true);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const searchInput = watch('search');

  const debouncedSearchInput = useDebounce(searchInput, 500);
  // const { data: unitData, isSuccess: isUnitLoded } = useBusinessunitData(clientId);

  useGet({
    url: `accounting/business_units`,
    queryString: {
      client: clientId,
      max_size: true,
      search: '',
    },
    validate: 'BusinessUnitsDepartment',
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setunitData(listData);
    },
  });

  let isAutoFetchingDepartment;
  if (modal.componentName === 'editDepartment') {
    isAutoFetchingDepartment = true;
  } else isAutoFetchingDepartment = false;

  const departmentId = modal.modalId;

  const { data: DepartmentDetail } = useDepartmentDetail(departmentId, isAutoFetchingDepartment);
  const { mutate: updateDepartment } = useUpdateDepartment(departmentId, DepartmentDetail?.data);

  const { mutate: postDepartment } = usePost({ url: 'employee-management/departments', validate: ['Departments'] });

  const onPostDepartment = async (postData) => {
    await postDepartment(postData);
  };

  const optionUnit = unitData?.map((item) => ({
    label: `${item?.name} ${item?.code ? `(${item.code})` : ''}`,
    value: item?.id,
  }));

  const sortAlphaUnit = optionUnit?.sort((a, b) => a.label.normalize().localeCompare(b.label.normalize()));

  const queryKey = isGrid
    ? {
        client: clientId,
        business_unit: unitId,
        search: debouncedSearchInput,
        page,
        page_size: rowsPerPage,
      }
    : {
        client: clientId,
        max_size: true,
      };

  const { isFetching } = useQuery(['Departments', { queryKey }], fetchFilterDepartment, {
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setCount(e?.data?.count);
      setDataDepartment(listData);
    },
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => dataDepartment, [dataDepartment]);

  const groupedData = _.groupBy(data, 'business_unit.id');
  const groupedDataArray = _.map(groupedData, (value, key) => ({
    id: Number(key),
    business_unit: value[0]?.business_unit?.name,
    business_unit_id: value[0]?.business_unit?.id,
    business_unit_name: value[0]?.business_unit?.name,
    items: value,
  }));

  const tabelAction = (
    <div className="flex justify-between items-center w-full">
      <div className="w-full">
        <div className="  py-5 px-3 border-b-2 mb-5 flex w-full items-center justify-between">
          <div className="grid grid-cols-2 max-w-[500px] w-full gap-[16px]">
            <div className={'bg-white '}>
              <div className="mb-[10px] text-[14px] font-[600] leading-[20px] capitalize">Search</div>
              <Controller
                name="search"
                control={control}
                defaultValue=""
                render={({ field }) => {
                  return (
                    <InputBase
                      size="small"
                      // placeholder="search"
                      endAdornment={<Search color="disabled" />}
                      className={'border border-gray-300 rounded-[11px] px-4 bg-white h-[48px] w-full'}
                      inputProps={{
                        onKeyPress: (event) => {
                          if (event.key === 'Enter') {
                            // keyPress;
                            // || handleApply();
                          }
                        },
                      }}
                      {...field}
                    />
                  );
                }}
              />
            </div>
            {sortAlphaUnit && (
              <ReactSelectSingle
                label={'Business Unit'}
                floatLabel={false}
                name="business_unit"
                control={control}
                // defaultValue={sortAlphaUnit && sortAlphaUnit.length > 0 && { label: sortAlphaUnit[0]?.label, value: sortAlphaUnit[0]?.value }}
                options={sortAlphaUnit}
                placeholder="Select Business Unit"
              />
            )}{' '}
          </div>
          <div className="flex justify-end">
            <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addDepartment' }))} className="bz-btn" type="button" variant="filled">
              Add Department
            </Button>
          </div>
        </div>

        <div onClick={() => setPage(1)} className="flex space-x-2 justify-end mdf:justify-normal items-end cursor-pointer ml-auto mb-[16px]">
          <Tooltip title={'Show Grouped By Business Unit'}>
            <GridView
              onClick={() => {
                setIsGrid(false);
                setValue('business_unit', null);
              }}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );

  function TableCells(props) {
    const { row, labelId, ariaChecked, selected } = props;

    return (
      <TableRow hover tabIndex={-1} key={row.name + row.id}>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row?.business_unit?.name || '-'}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.name}
        </TableCell>{' '}
        <TableCell component="th" id={labelId} scope="row" padding="normal" size="small" className="max-w-[200px]">
          {row?.locations && row?.locations.length > 0 ? (
            <div>
              <div className="font-bold"> {row?.locations[0]?.name || '-'}</div>
              {row?.locations[0]?.address || '-'}
            </div>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.manager ? `${row.manager.first_name} ${row.manager.last_name}` : '-'}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.team_leader ? `${row.team_leader.first_name} ${row.team_leader.last_name}` : '-'}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.user?.name || '-'}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.parent ? `${row.parent.name} ` : '-'}
        </TableCell>
        <TableCell>
          <ActionCell
            id={row.id}
            // onClick={() => removeEmployee(row.id)}
          />
        </TableCell>
      </TableRow>
    );
  }

  function buildNestedStructure(data, parent = null, level = 1) {
    const children = data.filter((item) => item.parent?.id == parent);

    if (children.length === 0) {
      return null;
    }

    return children.map((child) => ({
      ...child,
      level: level,
      children: buildNestedStructure(data, child.id, level + 1),
    }));
  }

  const NestedTree = ({ data }) => {
    if (!data) {
      return null;
    }

    return data.children ? (
      data.children.map((child) => (
        <TreeNode key={child.id} label={<div>{data.name}</div>}>
          <NestedTree data={child} />
        </TreeNode>
      ))
    ) : (
      <TreeNode label={<div>{data.name}</div>} />
    );
  };

  return (
    <div>
      <BZHelmet title="Department" description="bz publish  Employee" />
      <BaseMainTitle title="Department" />
      <div className="p-5">
        {isGrid ? (
          <div className="">
            {tabelAction}

            <CustomTable
              dataApi={data}
              setIsDataLoad={setIsDataLoad}
              isFetching={!isFetching}
              headCells={headCellsDepartment}
              ActionCell={ActionCell}
              TableCells={TableCells}
              page={page - 1}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={count}
            />
          </div>
        ) : (
          <div className="">
            <div onClick={() => setPage(1)} className="flex w-full justify-end mb-1 cursor-pointer ">
              <Tooltip title={'Show List'}>
                <ViewList
                  onClick={() => {
                    setIsGrid(true);
                    setValue('business_unit', null);
                  }}
                />
              </Tooltip>
            </div>
            {groupedDataArray?.map((data, idx) => {
              const orgnData = buildNestedStructure(data.items);

              return (
                <DeptOrgCharts
                  data={data}
                  orgnData={[{ id: idx + 1000, name: data?.business_unit, title: data.business_unit, level: 0, children: orgnData }]}
                  customTable={
                    <CustomTable
                      dataApi={data?.items}
                      setIsDataLoad={setIsDataLoad}
                      isFetching={!isFetching}
                      headCells={headCellsDepartment}
                      ActionCell={ActionCell}
                      TableCells={TableCells}
                      page={page - 1}
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                      count={count}
                      tablePagination={false}
                    />
                  }
                />
              );
            })}
          </div>
        )}
      </div>
      <ModalWrapper componentName="editDepartment" header="Edit Department" maxWidth="sm">
        <DepartmentForm onFormSubmit={updateDepartment} defaultValues={DepartmentDetail?.data} key={DepartmentDetail?.data?.id} unitData={unitData} reload={setIsDataLoad} />
      </ModalWrapper>
      <ModalWrapper componentName="addDepartment" header="Create Department" maxWidth="sm">
        <DepartmentForm onFormSubmit={onPostDepartment} unitData={unitData} />
      </ModalWrapper>
    </div>
  );
}

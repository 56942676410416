/* eslint-disable */

import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { DevTool } from '@hookform/devtools';
import { fetchEmployeeDetail, fetchEmployeeTeam, useUpdateEmployee } from '../../../hooks/useWhistleblowerData';
import { useQuery } from '@tanstack/react-query';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';

function TeamForm({ defaultValues }) {
  const { control, handleSubmit, watch } = useForm({ defaultValues });
  const dispatch = useDispatch();
  console.log(defaultValues);
  const clientId = useSelector((state) => state.client.activeClient);
  const employeeId = watch('employee');

  const [dataEmployee, setDataEmployee] = useState([]);
  const [isDataLoad, setIsDataLoad] = useState(false);
  const isTeam = false;
  useQuery(['EmployeesTeam', { clientId, isTeam }], fetchEmployeeTeam, {
    onSuccess: (e) => {
      setDataEmployee(e?.data?.results);
      setIsDataLoad(true);
    },
  });

  const { data: EmployeeDetail } = useQuery(['Employee', { employeeId }], fetchEmployeeDetail);

  console.log(EmployeeDetail);

  const { mutate: updateEmployee } = useUpdateEmployee(employeeId, EmployeeDetail?.data);

  const onSubmit = ({}) => {
    updateEmployee({
      is_whistleblower_committee: true,
    });
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  const optionsTeam = dataEmployee?.map((item) => ({
    label: `${item?.first_name} ${item?.last_name}`,
    value: item?.id,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <div className="w-full">
          <Controller
            name="employee"
            control={control}
            render={({ field: { onChange } }) => <ReactSelectMultiple options={optionsTeam} placeHolder="Select Employee" onChange={(v) => onChange(v.value)} />}
          />
        </div>
        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="flex" variant="outlined">
            Cancel
          </Button>
          <Button className=" flex" type="submit" variant="outlined">
            {defaultValues ? 'Update Team' : 'Add Member'}
          </Button>
        </div>
      </div>
      <DevTool control={control} />
    </form>
  );
}
export default TeamForm;

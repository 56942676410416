import { TextField } from '@mui/material';
import React from 'react';

export default function TextAutoComplete({ params, label, placeholder }) {
  return (
    <TextField
      {...params}
      label={label || ''}
      placeholder={placeholder || ''}
      sx={{
        height: 32,
        '& > div': {
          padding: '0px 6px 0px 6px !important',
          outline: 'none',
        },
        '& > .MuiInputLabel-root': {
          top: 0,
          fontSize: 14,
        },
        '& > .Mui-focused': { top: 0 },
        '& > .MuiFormLabel-filled': { top: 0 },
        '& > .MuiOutlinedInput-root': {
          fontSize: 14,
          '& > .MuiAutocomplete-input': { padding: '7.5px 48px 7.5px 6px' },
        },
      }}
      InputLabelProps={{ shrink: true }}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
    />
  );
}

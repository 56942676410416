/* eslint-disable */

import qs from 'qs';
import request from '../helper/axiosUtil';

export const fetchFilterDepartment = async ({ queryKey }) => {
  const transQuery = queryKey[1]?.queryKey;
  const url = `/employee-management/departments/${qs.stringify(transQuery, { addQueryPrefix: true })}`;
  return request({ url });
};

export const fetchFilterBunit = async ({ queryKey }) => {
  const transQuery = queryKey[1]?.queryKey;
  const url = `/accounting/business_units/${qs.stringify(transQuery, { addQueryPrefix: true })}`;
  return request({ url });
};

export const fetchFilterBunit2 = async ({ queryKey }) => {
  const transQuery = queryKey[1]?.queryKey;
  console.log('transQuery ', transQuery);
  const url = `/accounting/business_units/list_with_locations/${qs.stringify(transQuery, { addQueryPrefix: true })}`;
  return request({ url });
};

export const fetchFilterEmployee = async ({ queryKey }) => {
  const transQuery = queryKey[1]?.queryKey;
  const url = `/employee-management/employees/${qs.stringify(transQuery, { addQueryPrefix: true })}`;
  return request({ url });
};

export const fetchFilterJob = async ({ queryKey }) => {
  const transQuery = queryKey[1]?.queryKeyJob;
  const url = `/job_posters/${qs.stringify(transQuery, { addQueryPrefix: true, arrayFormat: 'repeat' })})}`;
  return request({ url });
};

export const fetchFilterDepartmentNew = async ({ queryKey }) => {
  const transQuery = queryKey[1];
  const url = `/employee-management/departments/${qs.stringify(transQuery, { addQueryPrefix: true })}`;
  return request({ url });
};

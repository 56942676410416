/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, Divider, Fade, FormControl, FormControlLabel, Input, Modal, Stack, TextField, Typography, IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from 'sweetalert';
import * as yup from 'yup';
import InputContainerJP from '../../Input/InputContainerJP';
import DatePickerField from '../../Input/DatePickerField';
import moment from "moment";
import CRMApi from '../../../api/CRM/CRMCustomers';
import { handleModal } from '../../../store/slices/modalSlice';

const schema = yup.object().shape({
  context: yup.string().max(80).required(),
  date: yup.date().default(() => new Date()).required(),
  reason: yup.string().required(),
  contacted_person: yup.string().required(),
  result: yup.string().required(),
});

function AddNote({status, memberId, reload, noteId}) {
  const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const { addNote, getNoteDetail, updateNote, deleteNote } = CRMApi();
  const [submittedData, setSubmittedData] = useState(null);
  const [data, setData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const FetchData = async () => {
      try {
        const sectionResponse = await getNoteDetail(noteId);
        const sectionData = await sectionResponse.data;
        setData(sectionData)
        setValue('context', sectionData.context);
        setValue('date', sectionData.date);
        setValue('reason', sectionData.reason);
        setValue('contacted_person', sectionData.contacted_person);
        setValue('result', sectionData.result);
      } catch (error) {
        console.log(error);
      }
    };

    if(status === "edit") {
      FetchData();
    }
  }, []);

  const onSubmit = (data) => {
    const newData = {
      member: parseInt(memberId, 10),
      ...data,
      date: moment(data.date).format('YYYY-MM-DD'),
    }

    if(status === "add") {
      addNote(newData)
        .then((res) => {
          if (res.status === 201 || res.data) {
            dispatch(handleModal({ modalId: '', componentName:'' }));
            reload(true)
            swal(
              "Success !",
              "You sucessfully add new note !",
              "success"
            );
          }
        })
        .catch((err) => { q
          console.error('err', err);
        });
    }else {
      updateNote(noteId, newData)
        .then((res) => {
          if (res.status === 201 || res.data) {
            dispatch(handleModal({ modalId: '', componentName:'' }));
            reload(true)
            swal(
              "Success !",
              "You sucessfully update note !",
              "success"
            );
          }
        })
        .catch((err) => {
          console.error('err', err);
        });
    }
    return setSubmittedData(data)
  }

  const deleteHistory = () => {
    if (noteId) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this note!",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            deleteNote(noteId)
              .then(res => {
                dispatch(handleModal({ modalId: '', componentName:'' }));
                reload(true)
                swal(
                  "Success !",
                  "You sucessfully deleted note !",
                  "success"
                );
            })
            }
        });
    }
  }

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          <div>
            <InputLabel>Context</InputLabel>
            <InputContainerJP
              name="context"
              control={control}
              label="Context"
              errors={errors}
              register={register}
              defaultValue={data.context}
            />
          </div>
          <div>
            <InputLabel>Date</InputLabel>
            <DatePickerField
              name="date"
              control={control}
              // label="Date"
              size="small"
              fullWidth
              sx={{ marginBottom: '8px' }}
              defaultValue={data ? data.date : null}
              errors={errors}
            />
          </div>
          <div>
            <InputLabel>Reason</InputLabel>
            <InputContainerJP
              name="reason"
              control={control}
              label="Reason"
              errors={errors}
              register={register}
              defaultValue={data.reason}
            />
          </div>
          <div>
            <InputLabel>Contacted Person</InputLabel>
            <InputContainerJP
              name="contacted_person"
              control={control}
              label="Contacted Person"
              errors={errors}
              register={register}
              defaultValue={data.contacted_person}
            />
          </div>
          <div>
            <InputLabel>Result</InputLabel>
            <InputContainerJP
              name="result"
              control={control}
              label="Result"
              errors={errors}
              register={register}
              defaultValue={data.result}
            />
          </div>
          <div className="flex justify-between pt-4 border-t">
            {
              status === "edit" && (
                <>
                  <IconButton aria-label="delete" size="small" onClick={deleteHistory}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </>
              )
            }
            <button className="flex h-[40px] ml-auto gap-x-2 items-center bg-[#2C6D47] text-white text-center btn btn-primary rounded-xl border-0 px-4" type="submit" variant="contained">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddNote;

/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Select, MenuItem, InputBase, Tabs, Tab, Box, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { DevTool } from '@hookform/devtools';
import { ExpandMore, Delete, ModeEdit } from '@mui/icons-material';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InputContainer from '../../Input/InputContainer';
import DatePickerField from '../../Input/DatePickerField';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';
import ModalWrapper from '../../ui/ModalWrapper';
import { handleModal } from '../../../store/slices/modalSlice';
import {
  useAddCompany,
  useAddEducation,
  useAddJobType,
  useAddKeyword,
  useAddPosition,
  useAddWidgetJob,
  useAuthorsData,
  useCompaniesData,
  useCompanyDetail,
  useCurrenciesData,
  useDestroyCompany,
  useDestroyEducation,
  useDestroyJobType,
  useDestroyKeyword,
  useDestroyPosition,
  useDestroyWidget,
  useEducationDetail,
  useEducationsData,
  useJobTypeDetail,
  useJobTypesData,
  useKeywordsData,
  useLocationsData,
  usePositionDetail,
  usePositionsData,
  useUpdateJobCompany,
  useUpdateJobEducation,
  useUpdateJobJobType,
  useUpdateJobPosition,
  useUpdateWidgetJob,
  useWidgetDetail,
  useWidgetJobData,
} from '../../../hooks/useJobPostersData';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { generateSelectListDefault, getSelectOptionWithKey } from '../../../lib/OptionsInputHelper';
import MuiSelectSingleItem from '../../Input/MuiSelectSingleItem';
import SelectMultiItem from '../../Input/SelectMultiItem';
import JobPositionForm from './JobPositionForm';
import AssetCardSingle from '../../../pages/Content/Asset/AssetCardSingle';
import AssetCard from '../../../pages/Content/Asset/AssetCard';
import JobCompanyForm from './JobCompanyForm';
import { useParams } from 'react-router';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import request from '../../../helper/axiosUtil';
import LocationForm from '../Location/LocationForm';
import { useSnackbar } from 'notistack';
import JobKeywordForm from './JobKeywordForm';
import JobTypeForm from './JobTypeForm';
import JobEducationForm from './JobEducationForm';
import editorConfig from '../../../helper/editorConfig';
import useGet from '../../../hooks/useGet';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';
import usePost from '../../../hooks/usePost';
import Stages from '../../../pages/Job/Stages';
import StagesNew from '../../../pages/Job/StagesNew';
import getDefaultValues from '../../../helper/getDefaultValues';
import FieldArrKey from '../Article/FieldArrKey';
import ReactSelectCreateable from '../../Input/ReactSelectCreateable';
// import AssetCar

const optionStatus = [
  {
    name: 'Published',
    id: 'Published',
  },
  {
    name: 'Drafted',
    id: 'Drafted',
  },
  {
    name: 'Closed',
    id: 'Closed',
  },
];

const schema = yup.object().shape({
  max_salary: yup.number().nullable().min(yup.ref('min_salary')),
  min_salary: yup.number().nullable().max(yup.ref('max_salary')),
});

export default function JobPosterForm({ defaultValues, onFormSubmit, isStageAccordion, isFetching }) {
  // console.log('defaultValues ', defaultValues)
  const defWidget = defaultValues?.article?.widgets?.map((wid) => wid.id);
  const defKeyw = defaultValues?.keywords?.map((key) => key.id);
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);
  const site = useSelector((state) => state.client.activeDetailClient.sites[0].id);
  const modal = useSelector((state) => state.modal);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    values: {
      article: { article_languages: getDefaultValues(clientLanguage, defaultValues?.article?.article_languages) },
      position: defaultValues?.position,
      location: defaultValues?.article?.location?.id,
      company: defaultValues?.company,
      // education: defaultValues?.education,
      min_salary: defaultValues?.min_salary,
      max_salary: defaultValues?.max_salary,
      currency: defaultValues?.currency,
      post_date: defaultValues?.post_date,
      due_date: defaultValues?.due_date,
      job_type: defaultValues?.job_type,
      status: defaultValues?.article?.status,
      author: defaultValues?.article?.author?.id,
      widgets: defWidget,
      keywords: defaultValues?.keywords?.map((key) => key),
      vacancies: defaultValues?.vacancies,
      name: defaultValues?.article?.name,
      business_unit: defaultValues?.business_unit?.id,
      departments: defaultValues?.departments?.map((dep) => dep.id),
    },
    resolver: yupResolver(schema),
  });

  const { jobPosterId } = useParams();
  const qClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const devLang = [];

  defaultValues?.article?.article_languages?.map((l) => {
    devLang.push(l);
    return devLang;
  });

  const dispatch = useDispatch();

  const [valueTab, setValueTab] = useState(0);
  const [position, setPosition] = useState('');
  const [chooseB_unit, setChooseB_unit] = useState(0);
  const [disabledDepartment, isDisableDepartment] = useState(false);
  const [disabledLocation, isDisableLocation] = useState(false);

  const idPosition = watch('position');
  const idCompany = watch('company');
  const idEducation = watch('education');
  const locationId = watch('location');
  const idJobType = watch('job_type');
  const businessUnit = watch('business_unit') || '';
  const idDepartment = watch('department') || '';
  const curren = watch('currency') || '';

  // console.log('idDepartment ', idDepartment);
  // console.log('locationId ', locationId);
  // console.log('curren ', curren);
  // console.log('businessUnit ', businessUnit);

  const clientId = useSelector((state) => state.client.activeClient);
  // const { data: widgetList } = useWidgetJobData(clientId);

  const { data: widgetList } = useGet({ url: `widgets/?client=${clientId}&max_size=true&category=5&internal=False`, validate: 'WidgetJobs' });
  const { data: b_unit } = useGet({ url: `accounting/business_units/?client=${clientId}&business_unit=&max_size=true`, validate: 'business_unit' });
  const { data: departments, refetch: departmentsRefetch } = useGet({
    url: `employee-management/departments/?client=${clientId}&business_unit=${businessUnit}&max_size=true`,
    validate: 'department',
    isEnabled: Boolean(businessUnit),
  });
  const { data: locations, refetch: locationsRefetch } = useGet({
    url: `locations/?max_size=true&department=${idDepartment}`,
    validate: 'locations',
    isEnabled: Boolean(idDepartment),
  });

  useEffect(() => {
    departmentsRefetch();
  }, [businessUnit]);

  useEffect(() => {
    if (idDepartment !== '') {
      locationsRefetch();
    }
  }, [idDepartment]);

  const { data: companies } = useCompaniesData(clientId);
  const { data: positions } = usePositionsData(clientId);
  // const { data: keywords } = useKeywordsData(site);

  const { data: keywords } = useGet({
    url: `job_posters/list_keywords`,
    queryString: {
      user__client: clientId,
      // max_size: true,
    },
    validate: 'jobKeywords',
  });
  console.log('🚀 ~ file: JobPosterForm.jsx:209 ~ JobPosterForm ~ keywords:', keywords);

  // const { data: currencies, refetch: refecthCurrencies } = useCurrenciesData(businessUnit);
  const { data: currencies, refetch: refecthCurrencies } = useGet({
    url: `accounting/business_units/${businessUnit ? businessUnit : 1}`,
    validate: 'currencies',
    isEnabled: Boolean(businessUnit),
  });
  const { data: jobTypes } = useJobTypesData();
  const { data: authors } = useAuthorsData(site);
  const lang = 'en';

  const { data: educations } = useEducationsData(clientId);

  const { mutate: addPosition } = useAddPosition();

  const [assetSelectedData, setAssetSelectedData] = useState([]);
  const [assetEditSelected, setAssetEditSelected] = useState([]);

  useEffect(() => {
    if (businessUnit !== '') {
      isDisableDepartment(true);
      refecthCurrencies();
    } else {
      isDisableDepartment(false);
      reset({
        department: '',
        location: '',
        currency: '',
      });
    }

    if (idDepartment !== '') {
      isDisableLocation(true);
    } else {
      isDisableLocation(false);
    }
  }, [idDepartment, businessUnit]);

  useEffect(() => {
    if (defaultValues) {
      setAssetSelectedData(defaultValues?.article?.article_assets);
    }
  }, [defaultValues]);

  const removePosition = useDestroyPosition();
  const removeCompany = useDestroyCompany();
  const removeEducation = useDestroyEducation();
  const removeJobType = useDestroyJobType();

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangeAsset = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(assetSelectedData);
      newSelection.push(parseInt(value, 10));
      setAssetSelectedData(newSelection);
    } else {
      const newSelection = assetSelectedData;
      const newAssets = newSelection.filter((sub) => parseInt(sub, 10) !== parseInt(value, 10));
      setAssetSelectedData(newAssets);
    }
  };

  const handleChangeAssetEdit = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(assetEditSelected);
      newSelection.push(parseInt(value, 10));
      setAssetEditSelected(newSelection);
    } else {
      const newSelection = assetEditSelected;
      const newAssets = newSelection.filter((sub) => parseInt(sub, 10) !== parseInt(value, 10));
      setAssetEditSelected(newAssets);
    }
  };

  const onSubmit = (data) => {
    console.log('🚀 ~ file: JobPosterForm.jsx:279 ~ onSubmit ~ data:', data?.article?.article_languages);
    // console.log('🚀 ~ file: JobPosterForm.jsx:220 ~ onSubmit ~ data:', data);
    const due_date = data.due_date ? new Date(data.due_date).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10);

    const post_date = data.post_date ? new Date(data.post_date).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10);

    onFormSubmit({
      article: {
        article_assets: assetSelectedData,
        article_languages: data?.article?.article_languages,
        client: clientId,
        data: data.date,
        location: data.location,
        // title: '',
        status: data.status,
        widgets: data.widgets,
        author: data.author,
        name: data.name,
      },
      company: data.company,
      education: data.education,
      company_name: '',
      currency: data.currency,
      job_type: data.job_type,
      keywords: data.keywords,
      due_date,
      post_date,
      position: data.position,
      max_salary: data.max_salary,
      min_salary: data.min_salary,
      vacancies: data.vacancies,
      business_unit: data.business_unit,
      departments: data?.departments,
    });
    // reset();
  };

  const optionCollection = widgetList?.data?.results?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const optionsAuthor = authors?.data?.results?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const optionKeywords = keywords?.data?.map((item) => ({
    label: item,
    value: item,
  }));

  const defKeywords = defaultValues?.keywords?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const collectionSelected = defWidget;
  const resultsCollection = optionCollection?.filter(({ value: id1 }) => collectionSelected?.some((id2) => id2 === id1));

  const { data: companyDetail } = useGet({ url: `companies/${idCompany}`, validate: 'company', isEnabled: Boolean(idCompany) });
  const { data: educationDetail } = useGet({ url: `educations/${idEducation}`, validate: 'company', isEnabled: Boolean(idEducation) });
  const { data: jobTypeDetail } = useGet({ url: `job_types/${idJobType}`, validate: 'JobType', isEnabled: Boolean(idJobType) });

  const { mutate: addCompanyJob } = useAddCompany();
  const { mutate: addEducationJob } = useAddEducation();
  const { mutate: addJobType } = useAddJobType();

  const { mutate: addJobKeyword } = useAddKeyword();

  const { mutate: updateJobCompany } = useUpdateJobCompany(idCompany, companyDetail?.data);
  const onUpdateJobCompany = async (data) => {
    await updateJobCompany(data);
  };

  const { mutate: updateJobEducation } = useUpdateJobEducation(idEducation, educationDetail?.data);
  const onUpdateJobEducation = async (data) => {
    await updateJobEducation(data);
  };

  const { mutate: updateJobType } = useUpdateJobJobType(idJobType, jobTypeDetail?.data);
  const onUpdateJobType = async (data) => {
    await updateJobType(data);
  };

  const pairAssets = async (data) =>
    request({
      url: `/articles/${defaultValues?.article?.id}/pair_assets/`,
      method: 'patch',
      data,
      headers: { 'Content-Type': 'application/json' },
    });

  const { mutateAsync: pairingAssets } = useMutation(pairAssets, {
    onSuccess: () => {
      qClient.invalidateQueries('WidgetJobs');
      dispatch(handleModal({ modalId: '' }));
    },
  });

  const onPairAssets = async (data) => {
    await pairingAssets(data);
  };

  const unPairAssets = async (data) =>
    request({
      url: `/articles/${defaultValues?.article?.id}/unpair_assets/`,
      method: 'patch',
      data,
      headers: { 'Content-Type': 'application/json' },
    });

  const { mutateAsync: unpairingAssets } = useMutation(unPairAssets, {
    onSuccess: () => {
      qClient.invalidateQueries('WidgetJobs');
    },
  });

  const onUnPairAssets = async (data) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        unpairingAssets(data);
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else {
        swal('Your imaginary file is safe!');
      }
    });
  };

  const { mutate: submitLocation } = usePost({ url: 'locations', validate: ['locations'] });

  const onSubmitLocation = async (data) => {
    await submitLocation(data);
  };

  const { data: locationDetail } = useGet({
    url: `locations/${locationId}`,
    validate: 'location',
    isEnabled: Boolean(modal.modalId),
  });
  // console.log('🚀 ~ file: JobPosterForm.jsx:387 ~ JobPosterForm ~ locationDetail', locationDetail);

  const updatelocations = async (data) => request({ url: `/locations/${locationId}/`, method: 'patch', data });

  const { mutateAsync: updatelocation } = useMutation(updatelocations, {
    onSuccess: (e) => {
      setIsLoaded(false);
      qClient.invalidateQueries('location');
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onUpdatelocation = async (data) => {
    await updatelocation(data);
  };

  const onUnPairAssetsNonApi = async () => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const filteredPair = assetSelectedData?.filter((item) => !assetEditSelected?.includes(item));
        setAssetSelectedData(filteredPair);
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else {
        swal('Your imaginary file is safe!');
      }
    });
  };

  useEffect(() => {
    clientLanguage?.forEach((lang, index) => setValue(`article[article_languages[${index}][language]]`, lang.language.id));
  }, [clientLanguage, setValue]);

  console.log('🚀 ~ file: JobPosterForm.jsx:465 ~ useEffect ~ clientLanguage:', clientLanguage);
  return (
    <>
      {isStageAccordion && (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMore className="text-white" />} aria-controls="panel1a-content" id="panel1a-header" className="bg-blue-600 text-white mt-3">
            <div>Job Stage</div>
          </AccordionSummary>
          <AccordionDetails className="py-5">
            <div className="">
              <StagesNew renderState={defaultValues} url="application_stages" validate="applicationStages" />
            </div>
          </AccordionDetails>
        </Accordion>
      )}
      {!isFetching && (
        <div className="">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMore className="text-white" />} aria-controls="panel1a-content" id="panel1a-header" className="bg-blue-600 text-white mt-3">
                <div>Job Info</div>
              </AccordionSummary>
              <AccordionDetails className="py-5">
                <div className="grid grid-cols-2 gap-4">
                  <div className="w-full">
                    <div className="flex items-center w-full justify-between pb-2">
                      <div>Business Unit</div>
                    </div>
                    <div className="w-full flex mt-5">
                      <MuiSelectSingleItem
                        name="business_unit"
                        label="Select Business Unit"
                        control={control}
                        options={getSelectOptionWithKey(b_unit?.data?.results)}
                        // defaultValue={generateSelectDefaultWithKey(defaultValues?.category, getSelectOptionWithKey(widgetTypes?.data?.results))}
                      />
                      {/* <div className="flex">
                    <Button
                      onClick={() => dispatch(handleModal({ modalId: idCompany, componentName: 'EditJobCompany' }))}
                      className="ml-auto flex bg-gray-600 w-fit text-white h-fit text-sm"
                      type="button"
                      variant="filled"
                    >
                      <ModeEdit className="w-5 h-5" />
                    </Button>
                    <Button onClick={() => removeCompany(idCompany)} className="ml-auto flex bg-gray-600 w-fit text-white h-fit text-sm" type="button" variant="filled">
                      <Delete className="w-5 h-5" />
                    </Button>
                  </div> */}
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex items-center w-full justify-between pb-2">
                      <div>Department</div>
                    </div>
                    <div className="w-full bg-white  mt-5">
                      <Controller
                        name="departments"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <ReactSelectMultiple
                            // options={getSelectOptionWithKey(departments?.data?.results)}
                            options={departments?.data?.results?.map((item) => ({
                              label: item?.name,
                              value: item?.id,
                            }))}
                            defaultValue={generateSelectListDefault(defaultValues?.departments)}
                            placeholder="Select Departments"
                            // errors={errors.widgets}
                            isMulti
                            closeMenuOnSelect
                            onChange={(v) => onChange(v?.map((val) => val.value))}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="w-full"></div>
                </div>
                <div className="grid grid-cols-6 gap-4 my-8">
                  <div className="w-full">
                    <div className="flex items-center w-full justify-between ">
                      <div>Min Salary</div>
                    </div>
                    <div className="w-full flex mt-7">
                      <div className="w-full -mt-5">
                        <InputContainer name="min_salary" control={control} label="Min Salary" type="number" errors={errors.min_salary} />
                      </div>
                    </div>
                  </div>
                  <div className="w-full col-span-2">
                    <div className="flex items-center w-full justify-between">
                      <div>Max Salary</div>
                    </div>
                    <div className="flex gap-x-4">
                      <div className="w-full flex mt-7">
                        <div className="w-full -mt-5">
                          <InputContainer name="max_salary" control={control} label="Max Salary" type="number" errors={errors.max_salary} />
                        </div>
                      </div>
                      <div className="w-full flex mt-7">
                        <div className="w-full">
                          <MuiSelectSingleItem
                            name="currency"
                            label="Select Currency"
                            control={control}
                            options={getSelectOptionWithKey(currencies?.data?.ledger_currencies)}
                            disabled={!disabledDepartment}
                            // defaultValue={generateSelectDefaultWithKey(defaultValues?.category, getSelectOptionWithKey(widgetTypes?.data?.results))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full col-span-2">
                    <div className="flex items-center w-full justify-between">
                      <div>Job Type</div>
                      <div>
                        {' '}
                        <Button
                          onClick={() => dispatch(handleModal({ modalId: '', componentName: 'AddJobType' }))}
                          className="ml-auto bz-btn"
                          type="button"
                          variant="filled"
                          size="small"
                        >
                          Add Job Type
                        </Button>
                      </div>
                    </div>
                    <div className="w-full flex mt-5 gap-x-2">
                      <MuiSelectSingleItem
                        name="job_type"
                        label="Select Job Types"
                        control={control}
                        options={getSelectOptionWithKey(jobTypes?.data?.results)}
                        // defaultValue={generateSelectDefaultWithKey(defaultValues?.category, getSelectOptionWithKey(widgetTypes?.data?.results))}
                      />
                      <div className="flex gap-x-2">
                        <Button
                          onClick={() => dispatch(handleModal({ modalId: idJobType, componentName: 'EditJobType' }))}
                          className="ml-auto flex bg-gray-600 w-fit text-white h-fit text-sm"
                          type="button"
                          variant="filled"
                        >
                          <ModeEdit className="w-5 h-5" />
                        </Button>
                        <Button onClick={() => removeJobType(idJobType)} className="ml-auto flex bg-gray-600 w-fit text-white h-fit text-sm" type="button" variant="filled">
                          <Delete className="w-5 h-5" />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex items-center w-full justify-between ">
                      <div>Vacancies</div>
                    </div>
                    <div className="w-full mt-2">
                      <InputContainer
                        name="vacancies"
                        control={control}
                        label="Vacancies"
                        type="number"
                        // errors={errors.name}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4">
                  {/* <div className="w-full">
                <div className="flex items-center w-full justify-between">
                  <div>Education</div>
                  <div>
                    {' '}
                    <Button
                      onClick={() => dispatch(handleModal({ modalId: '', componentName: 'AddJobEducation' }))}
                      className="ml-auto flex bg-teal-600 text-white h-fit text-sm"
                      type="button"
                      variant="filled"
                      size="small"
                    >
                      Add Education
                    </Button>
                  </div>
                </div>
                <div className="w-full flex mt-5">
                  <MuiSelectSingleItem
                    name="education"
                    label="Select Education"
                    control={control}
                    options={getSelectOptionWithKey(educations?.data?.results)}
                    // defaultValue={generateSelectDefaultWithKey(defaultValues?.category, getSelectOptionWithKey(widgetTypes?.data?.results))}
                  />
                  <div className="flex">
                    <Button
                      onClick={() => dispatch(handleModal({ modalId: idEducation, componentName: 'EditJobEducation' }))}
                      className="ml-auto flex bg-gray-600 w-fit text-white h-fit text-sm"
                      type="button"
                      variant="filled"
                    >
                      <ModeEdit className="w-5 h-5" />
                    </Button>
                    <Button onClick={() => removeEducation(idEducation)} className="ml-auto flex bg-gray-600 w-fit text-white h-fit text-sm" type="button" variant="filled">
                      <Delete className="w-5 h-5" />
                    </Button>
                  </div>
                </div>
              </div> */}
                  <div className="w-full">
                    <div className="flex items-center w-full justify-between">
                      <div>Position</div>
                      {/* <div>
                    {' '}
                    <Button
                      onClick={() => dispatch(handleModal({ modalId: '', componentName: 'AddJobPosition' }))}
                      className="ml-auto flex bg-teal-600 text-white h-fit text-sm"
                      type="button"
                      variant="filled"
                      size="small"
                    >
                      Add Position
                    </Button>
                  </div> */}
                    </div>
                    <div className="w-full mt-3">
                      <InputContainer name="position" control={control} label="position" />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex items-center w-full justify-between">
                      <div>Post Date</div>
                    </div>
                    <div className="w-full mt-2">
                      <DatePickerField
                        sx={{ marginBottom: '20px', marginTop: '20px' }}
                        control={control}
                        name="post_date"
                        label="Post Date"
                        // error={!!errors.datePicker}
                        // helperText={errors.datePicker?.message}
                        size="small"
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex items-center w-full justify-between">
                      <div>Due Date</div>
                    </div>
                    <div className="w-full mt-2">
                      <DatePickerField
                        sx={{ marginBottom: '20px', marginTop: '20px' }}
                        control={control}
                        name="due_date"
                        label="Due Date"
                        // error={!!errors.datePicker}
                        // helperText={errors.datePicker?.message}
                        size="small"
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex items-center w-full justify-between">
                      <div>Keyword</div>
                    </div>
                    <div className="w-full bg-white mt-5">
                      <ReactSelectCreateable
                        name="keywords"
                        control={control}
                        // customDelete
                        // actionDelete={removeKeyword}
                        options={optionKeywords}
                        defaultValue={generateSelectListDefault(
                          defaultValues?.keywords?.map((sk) => ({
                            name: sk,
                            id: sk,
                          }))
                        )}
                        placeholder="Select Keyword"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4"></div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore className="text-white" />} aria-controls="panel1a-content" id="panel1a-header" className="bg-blue-600 text-white mt-3">
                <div>Job Description</div>
              </AccordionSummary>
              <AccordionDetails className="py-5">
                <InputContainer name="name" control={control} label="Name" />
                <MuiCusotmTabs
                  optionsTab={clientLanguage.map((lang) => {
                    return {
                      name: lang?.language?.name,
                      id: lang?.language?.id,
                    };
                  })}
                  value={valueTab}
                  onChange={handleChangeTab}
                />
                {clientLanguage?.map((lang, index) => (
                  <MuiCustomTabPanel key={lang.id} value={valueTab} index={index}>
                    <div className=" ">
                      <InputContainer name={`article[article_languages[${index}][language]]`} hidden defaultValue={lang?.language.id} control={control} label="Language" />
                      <InputContainer name={`article[article_languages[${index}][title]]`} control={control} label="Title" />
                      <InputContainer name={`article[article_languages[${index}][summary]]`} control={control} label="Summary" />
                      <div className="typo-style">
                        <Controller
                          name={`article[article_languages[${index}][content]]`}
                          control={control}
                          render={({ field: { value } }) => (
                            <CKEditor
                              id="editor"
                              key={lang.id}
                              editor={Editor}
                              config={editorConfig}
                              data={value || ''}
                              onChange={(event, editor) => {
                                const editorData = editor.getData();
                                setValue(`article[article_languages[${index}][content]]`, editorData);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {/* <div className="">
                  <InputContainer name={`article[article_languages[${index}][description]]`} control={control} label="Description" />
                </div> */}

                    <div className="p-5 border-2 rounded my-5">
                      <div className="font-bold text-lg">SEO</div>
                      <div className="">
                        <InputContainer name={`article[article_languages[${index}][description]]`} control={control} label="Description" />
                      </div>
                      {/* <div className="">
                      <div className="font-bold">Keyword</div>
                      <div className="pt-5 w-full">
                        <FieldArrKey
                          index={lang?.language?.id}
                          defaultValues={defaultValues}
                          language={lang?.language.id}
                          // resIdArticle={resIdArticle}
                          // setResIdArticle={setResIdArticle}
                        />
                      </div>
                    </div> */}
                    </div>
                  </MuiCustomTabPanel>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore className="text-white" />} aria-controls="panel1a-content" id="panel1a-header" className="bg-blue-600 text-white mt-3">
                <div>Job Settings</div>
              </AccordionSummary>
              <AccordionDetails className="py-5">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div>Collection Job</div>
                    <div className="w-full bg-white mt-5">
                      <Controller
                        name="widgets"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <SelectMultiItem
                            options={optionCollection}
                            defaultValue={resultsCollection}
                            placeholder="Select Collection"
                            isMulti
                            onChange={(v) => onChange(v?.map((val) => val.value))}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div>Job Status</div>

                    <div className="w-full flex mt-5">
                      <MuiSelectSingleItem
                        name="status"
                        label="Select Status"
                        control={control}
                        options={getSelectOptionWithKey(optionStatus)}
                        // defaultValue={generateSelectDefaultWithKey(defaultValues?.category, getSelectOptionWithKey(widgetTypes?.data?.results))}
                      />
                    </div>
                  </div>
                  <div>
                    <div>Author</div>
                    <div className="w-full bg-white mt-5">
                      <MuiSelectSingleItem name="author" label="Select Author" control={control} options={getSelectOptionWithKey(authors?.data?.results)} />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore className="text-white" />} aria-controls="panel1a-content" id="panel1a-header" className="bg-blue-600 text-white mt-3">
                <div>Media</div>
              </AccordionSummary>
              <AccordionDetails className="py-5">
                <div className="">
                  <div className="ml-auto flex gap-x-3">
                    <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'AddAssetJob' }))} className="ml-auto flex" type="button" variant="outlined">
                      Add New Asset
                    </Button>

                    {defaultValues ? (
                      <Button onClick={() => onUnPairAssets({ assets: assetEditSelected })} className="flex" type="button" variant="outlined">
                        REMOVE SELECTED ASSETS
                      </Button>
                    ) : (
                      <Button onClick={() => onUnPairAssetsNonApi()} className="flex" type="button" variant="outlined">
                        REMOVE SELECTED ASSETS
                      </Button>
                    )}
                  </div>
                  <div className=" border-dashed min-h-[160px] border-4 p-3 gap-3 flex flex-wrap mt-10">
                    <AssetCardSingle assetsSelectData={assetSelectedData} assetComponent="assetPair" onChangeAsset={handleChangeAssetEdit} cardOnly />
                    {/* ))} */}
                  </div>
                  <ModalWrapper componentName="AddAssetJob" header="Select Asset" maxWidth="lg">
                    {defaultValues ? (
                      <AssetCardSingle onChangeAsset={handleChangeAssetEdit} onClick={() => onPairAssets({ assets: assetEditSelected })} />
                    ) : (
                      <AssetCardSingle onChangeAsset={handleChangeAsset} onClick={() => dispatch(handleModal({ modalId: '' }))} />
                    )}
                  </ModalWrapper>
                </div>
              </AccordionDetails>
            </Accordion>

            <Button className="ml-auto mt-5 flex bg-teal-600 text-white h-fit text-sm" type="submit" variant="filled" size="small">
              Submit
            </Button>
          </form>
          <DevTool control={control} />
          <ModalWrapper componentName="AddJobPosition" header="Add Position" maxWidth="sm">
            <JobPositionForm onFormSubmit={addPosition} />
          </ModalWrapper>

          <ModalWrapper componentName="AddJobCompany" header="Add Company" maxWidth="sm">
            <JobCompanyForm onFormSubmit={addCompanyJob} />
          </ModalWrapper>
          <ModalWrapper componentName="EditJobCompany" header="Edit Company" maxWidth="sm">
            <JobCompanyForm onFormSubmit={onUpdateJobCompany} defaultValues={companyDetail?.data} key={companyDetail?.data?.id} />
          </ModalWrapper>
          <ModalWrapper componentName="AddLocation" header="Add Location" maxWidth="lg">
            <LocationForm
              onFormSubmit={onSubmitLocation}
              departmentId={idDepartment}
              department={departments?.data?.results?.filter((v) => v.id === idDepartment)}
              b_unit={b_unit?.data?.results?.filter((v) => v.id === businessUnit)}
            />
          </ModalWrapper>
          <ModalWrapper componentName="EditLocation" header="Edit Location" maxWidth="lg">
            <LocationForm onFormSubmit={onUpdateJobCompany} defaultValues={locationDetail?.data} key={locationDetail?.data?.id} />
          </ModalWrapper>
          <ModalWrapper componentName="AddJobType" header="Add Job Type" maxWidth="sm">
            <JobTypeForm onFormSubmit={addJobType} />
          </ModalWrapper>
          <ModalWrapper componentName="EditJobType" header="Edit Job Type" maxWidth="sm">
            <JobTypeForm onFormSubmit={onUpdateJobType} defaultValues={jobTypeDetail?.data} key={jobTypeDetail?.data?.id} />
          </ModalWrapper>
          <ModalWrapper componentName="AddJobKeyword" header="Add Keyword" maxWidth="sm">
            <JobKeywordForm onFormSubmit={addJobKeyword} />
          </ModalWrapper>
          <ModalWrapper componentName="AddJobEducation" header="Add Education" maxWidth="sm">
            <JobEducationForm onFormSubmit={addEducationJob} />
          </ModalWrapper>
          <ModalWrapper componentName="EditJobEducation" header="Edit Education" maxWidth="sm">
            <JobEducationForm onFormSubmit={onUpdateJobEducation} defaultValues={educationDetail?.data} key={educationDetail?.data?.id} />
          </ModalWrapper>
        </div>
      )}
    </>
  );
}

import React, { Suspense, useState } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Skeleton, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import BaseMainTitle from '../../../components/BaseMainTitle';
import DndProfileAttribute from '../../../components/Form/CRM/DndProfileAttribute';
// import DndProfileSideDrawer from '../../../components/Form/CRM/DndProfileSideDrawer';
import BaseInputValidation from '../../../components/Input/Base/BaseInputValidation';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import Attribute from '../../../components/Modal/CRM/Attribute';
import DroppableElement from '../../../components/ui/CRM/DroppableElement';

import BZHelmet from '../../../utils/BZHelmet';
import crmApi from '../../../api/CRM/CRMCustomers';

const { createCRMProjectProfile, getCRMProjectAttribute } = crmApi();

const staticSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  // *
});

export default function NewProfile() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const activeClient = useSelector((state) => state.client.activeClient);
  // const activeSidePanel = useSelector((state) => state.crmProfile.activeSidePanel);
  const [attributeSelected, setSelectedAttribute] = useState(null);
  const [structure, setStructure] = useState({ group: [], attribute: [] });

  const {
    trigger,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(staticSchema),
    defaultValues: {},
  });

  const {
    data: dataAttribute,
    isFetching: isFetchingAttribute,
    refetch: refetchAttribute,
  } = useQuery({
    queryKey: ['crm-project-attributes', activeClient],
    queryFn: () => getCRMProjectAttribute({ client: activeClient, max_size: true }),
    refetchOnWindowFocus: false,
  });

  // eslint-disable-next-line no-unused-vars
  const onCloseHandler = () => {
    navigate('/crm/projects');
  };

  const generateAttributeWithGroup = (elements, layout, index = 0) => {
    return (
      elements.length > 0 &&
      elements.map((el, i) => {
        let profileAttributes = [];
        if (el.listElement && el.type === 'group') {
          profileAttributes = generateAttributeWithGroup(el.listElement, layout[i]?.activeLayout || [], i > 0 ? elements[i - 1].listElement?.length || el.listElement.length : i);
        }
        let objElement = null;
        if (el.type === 'group') {
          objElement = {
            name: el.name,
            index: i + 1,
            dimension: el.dimension,
            profile_attributes: profileAttributes || [],
          };
        }
        if (el.type === 'attribute') {
          objElement = {
            attribute: el.id,
            index: i + index,
            dimension: layout[i] ? [layout[i].x, layout[i].y, layout[i].w, layout[i].h] : el.dimension,
            column_index: index + (layout[i] ? layout[i].y + layout[i].h : el.dimension[1] + el.dimension[3]),
          };
          if (el.configuration) {
            objElement.configuration = {};
            Object.keys(el.configuration).forEach((c) => {
              objElement.configuration[c] = el.configuration[c];
            });
          }
        }
        return objElement;
      })
    );
  };

  const onSave = async (data) => {
    const isFieldValid = await trigger();
    if (!isFieldValid) return;
    const newGroups = generateAttributeWithGroup(data.listElement, data.activeLayout);
    if (newGroups) {
      newGroups.filter((g) => !!g);
    }
    const dataProfile = { name: getValues('name'), client: activeClient };
    if (newGroups) {
      dataProfile.groups = newGroups || [];
    }

    createCRMProjectProfile(dataProfile)
      .then(() => {
        enqueueSnackbar('Successfully create new profile', { variant: 'Success' });
        navigate('/crm/projects/profile');
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.response?.data?.details ? err.response?.data?.details[0] : 'Something went wrong', { variant: 'Error' });
      });
  };

  const onChangeStructure = (type, action, id, groupAttributes) => {
    if (!type && !action && !id) return;
    const currStructure = structure ? { ...structure } : null;
    let currType = currStructure[type] ? [...currStructure[type]] : [];
    let currAttributes = currStructure.attribute ? [...currStructure.attribute] : [];
    if (action === 'add') {
      if (currStructure && currType && currType.length) {
        currType.push(+id);
      } else {
        currType = [+id];
      }
    } else if (action === 'remove') {
      currType = currType.filter((t) => +t !== +id);
      if (type === 'group' && groupAttributes) {
        currAttributes = currAttributes.filter((el) => !groupAttributes.includes(+el));
      }
    }
    if (action === 'remove' && type === 'group' && groupAttributes) {
      setStructure({ ...structure, [type]: currType, attribute: currAttributes });
    } else {
      setStructure({ ...structure, [type]: currType });
    }
  };

  const minH = `${(structure?.group.length || 1) * 196}px`;
  return (
    <Suspense fallback={<CircularProgress />}>
      <BZHelmet title="New Profile" description="" content="" />
      <BaseMainTitle title="CRM Profile" />
      <section id="dnd-crm" className="w-full h-full p-5 relative">
        <div className="w-full h-full flex gap-4">
          <div className="w-auto max-w-[396px] flex flex-col pb-4 gap-4">
            <BaseInputValidation control={control} name="name" label="Profile Name" errors={errors} required />
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Groups</Typography>
              </AccordionSummary>
              <AccordionDetails className="h-auto max-h-[380px] overflow-x-hidden overflow-y-auto">
                <DroppableElement data={{ id: -1, index: -1, is_displayed: false, name: 'Group', profile: null }} label="Group" type="group" />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Attribute</Typography>
              </AccordionSummary>
              <AccordionDetails className="h-auto max-h-[380px] overflow-x-hidden overflow-y-auto">
                {/* eslint-disable-next-line no-nested-ternary */}
                {isFetchingAttribute ? (
                  <Skeleton />
                ) : dataAttribute?.length > 0 ? (
                  dataAttribute.map((attr) => {
                    return (
                      <DroppableElement
                        key={`attr-${attr.id}`}
                        structure={structure?.attribute}
                        data={attr}
                        label={attr.name}
                        type="attribute"
                        viewDetail={setSelectedAttribute}
                        modalName="Attribute"
                      />
                    );
                  })
                ) : (
                  'no attribute data'
                )}
              </AccordionDetails>
            </Accordion>
          </div>
          <div style={{ minHeight: minH }} className="w-full min-h-full h-full pt-2 pb-4 pl-2 bg-[#eee]">
            <DndProfileAttribute isParent onSave={onSave} changeStructure={onChangeStructure} />
          </div>
        </div>
        {/* <DndProfileSideDrawer open={!!activeSidePanel} /> */}
        <ModalWrapper componentName="Attribute" header={`${!attributeSelected ? 'Add New' : 'Update'} Attribute`} maxWidth="sm">
          <Attribute dataAttribute={dataAttribute} attributeSelected={attributeSelected} refetch={refetchAttribute} />
        </ModalWrapper>
      </section>
    </Suspense>
  );
}

/* eslint-disable */

import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ModeEdit } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import ModalWrapper from '../../components/ui/ModalWrapper';
import { handleModal } from '../../store/slices/modalSlice';
import SectionWidgetModal from './SectionWidgetModal';
import BaseMainTitle from '../../components/BaseMainTitle';
import BZHelmet from '../../utils/BZHelmet';
import ReactShortableWrapper from '../../components/ReactShortableWrapper';
import WidgetUpdateForm from '../../components/Form/Section/WidgetUpdateForm';
import usePost from '../../hooks/usePost';
import useGet from '../../hooks/useGet';
import useDelete from '../../hooks/useDelete';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import BaseDeleteButton from '../../components/Button/BaseDeleteButton';
import WidgetForm from '../../components/Form/Widget/WidgetForm';

export default function SectionDeveloper() {
  const { sectionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.modal);

  const [sectionDetail, setSectionDetail] = useState(null);
  const [listWidget, setListWidget] = useState([]);
  const [listDelWidget, setListDelWidget] = useState([]);

  const handleDeleteWidget = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(listDelWidget);
      newSelection.push(parseInt(value, 10));
      setListDelWidget(newSelection);
    } else {
      const newSelection = listDelWidget;
      const newSubs = newSelection.filter((sub) => parseInt(sub, 10) !== parseInt(value, 10));
      setListDelWidget(newSubs);
    }
  };

  useGet({
    url: `sections/${sectionId}/`,
    validate: 'section',
    onSuccess: (e) => {
      setSectionDetail(e.data);
      setListWidget(e?.data?.section_widgets);
    },
  });

  const { mutate: postCollection } = usePost({ url: 'widgets', validate: ['section'] });
  const onPostCollection = async (data) => {
    await postCollection(data);
  };

  const { data: sectionsWidgetData } = useGet({
    url: `section_widgets/${modal.modalId}`,
    validate: 'sectionWidget',
    isEnabled: modal.componentName === 'editWidget',
  });

  const { mutate: postSection } = usePost({ url: 'section_widgets', validate: ['section'] });

  const onPostSectionWidget = async (data) => {
    await postSection(data);
  };

  const onDestroySectionWidget = useDelete({
    url: `sections/${sectionId}/delete_sectionwidgets`,
    confText: `modals.widget_delete.confirmation_text`,
    confTitle: `modals.widget_delete.title`,
    success: 'Widget deleted',
    validate: ['section'],
    bodyData: { section_widget_ids: listDelWidget },
    isSubUrl: false,
  });

  const { mutate: updateSectionWidget } = usePatchOrPut({
    url: `section_widgets/${modal.modalId}`,
    validate: ['section'],
    method: 'put',
  });

  const { mutate: UpdateSectionWidgetOrder } = usePatchOrPut({ url: `sections/${sectionId}/update_sectionwidget_indexes`, validate: ['section'] });

  const onPatchOrder = useCallback(() => {
    UpdateSectionWidgetOrder({ section_widgets: listWidget?.map((w) => w.id) });
  }, [UpdateSectionWidgetOrder, listWidget]);

  return (
    <div>
      <BZHelmet title="Section Developer" description="bz publish section developer" />
      <BaseMainTitle title={`Section Name : ${sectionDetail?.name}`} />
      <div className="p-5">
        <div className=" bg-white border p-5 shadow rounded">
          <div className="bg-gray-100 w-full py-5 px-3 my-5 rounded">
            <div className="flex items-center">
              <div className="">
                <div>Menu : {sectionDetail?.menu_name}</div>
              </div>
              <div className="ml-auto flex gap-x-3">
                <BaseDeleteButton disabled={listDelWidget.length === 0} onClick={() => onDestroySectionWidget()} />

                <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'pairWidget' }))} className="bg-blue-600 text-white h-fit" type="submit" variant="filled">
                  Pair widget
                </Button>
                <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addWidget' }))} className="bg-blue-600 text-white h-fit" type="submit" variant="filled">
                  Add widget
                </Button>
              </div>
            </div>
          </div>
          <div className="space-y-5">
            <div className="">Widget List :</div>
            <ReactShortableWrapper onPatch={onPatchOrder} list={listWidget} setList={setListWidget} className="flex gap-4 flex-wrap bg-white  ">
              {/* <div className="grid grid-cols-6 gap-5"> */}
              {listWidget?.map((sec) => (
                <div key={sec.id} className="border  rounded relative w-[200px]">
                  <div className="w-full relative">
                    <div className=" gap-x-3 flex items-center bg-blue-700 p-2 rounded-t">
                      <input type="checkbox" onChange={handleDeleteWidget} name="lang" value={sec.id} className=" w-5 h-5" />
                      <button
                        className="text-white font-bold"
                        type="button"
                        onClick={() => navigate({ pathname: `/widget/detail/${sec.widget.id}/`, search: `?section_widget=${sec.section}` })}
                      >
                        {sec.name}
                      </button>
                      <button onClick={() => dispatch(handleModal({ modalId: sec.id, componentName: 'editWidget' }))} className="" type="button">
                        <ModeEdit className="w-5 h-5 text-white" />
                      </button>
                    </div>
                  </div>
                  <div className="h-20 relative cursor-move">
                    <div className="absolute bottom-0 flex space-x-3 p-3">
                      <p className="">Category :</p>

                      <p>{sec.widget.category_name}</p>
                    </div>
                  </div>
                </div>
              ))}
            </ReactShortableWrapper>
          </div>
        </div>
        <ModalWrapper componentName="pairWidget" header="Widget Pair" maxWidth="lg">
          <SectionWidgetModal onFormSubmit={onPostSectionWidget} />
        </ModalWrapper>
        <ModalWrapper componentName="editWidget" header="Update Widget" maxWidth="lg">
          <WidgetUpdateForm defaultValues={sectionsWidgetData?.data} key={sectionsWidgetData?.data.id} onFormSubmit={updateSectionWidget} />
        </ModalWrapper>
        <ModalWrapper componentName="addWidget" header="Add Section Widget" maxWidth="sm">
          <WidgetForm onFormSubmit={onPostCollection} />
        </ModalWrapper>
      </div>
    </div>
  );
}

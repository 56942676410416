/* eslint-disable */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import { Autocomplete, Avatar, Box, Button, ButtonBase, ClickAwayListener, MenuItem, OutlinedInput, Select, Skeleton, TextField } from '@mui/material';
import { Add, Article, AttachFile, Check, Close, Notifications, Person } from '@mui/icons-material/';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import BoardsContext from '../../../../../pages/Task/Boards/BoardsContext';
import DetailContext from '../../../../../pages/Task/Projects/DetailContext';
import DrawerContext from '../DrawerContext';

import Creatable from '../Creatable';
import AssetsViewer from '../../../AssetsViewer/AssetsViewer';
import Comment from '../Comment';
import ProfileDetail from '../../ProfileDetail';
import AttachmentFiles from '../AttachmentFiles';
import GetAvatar from '../../Content/GetAvatar';

import formatterInitialName from '../../../../../helper/formatterInitialName';
import userApi from '../../../../../api/user';
import projectApi from '../../../../../api/task/project';
import styles from '../DrawerTask.module.css';
import CustomUploadAdapterPlugin from '../../../../../utils/Editor/CustomUploadAdapterPlugin';

const variants = {
  closed: { minHeight: '80px' },
  open: { minHeight: '172px' },
};
const variantsBtn = {
  closed: { opacity: 0, y: 0 },
  open: { opacity: 1, y: 0, transition: { delay: 0.3 } },
};

const editorConfigs = {
  extraPlugins: [CustomUploadAdapterPlugin],
  toolbar: {
    items: [
      'heading',
      '|',
      'fontfamily',
      'fontsize',
      '|',
      'alignment',
      '|',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'strikethrough',
      'underline',
      'subscript',
      'superscript',
      '|',
      'link',
      '|',
      'outdent',
      'indent',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      '|',
      'code',
      'codeBlock',
      '|',
      'insertTable',
      '|',
      'uploadImage',
      'importFile',
      'blockQuote',
      '|',
      'undo',
      'redo',
    ],
  },
  fontColor: {
    colorPicker: {
      // Use 'hex' format for output instead of 'hsl'.
      format: 'hex',
    },
  },
  image: {
    styles: {
      options: ['inline', 'alignLeft', 'alignRight', 'alignCenter', 'alignBlockLeft', 'alignBlockRight', 'block', 'side'],
    },
    resizeOptions: [
      {
        name: 'resizeImage:original',
        label: 'Original',
        value: null,
      },
      {
        name: 'resizeImage:50',
        label: '50% page width',
        value: '50',
      },
      {
        name: 'resizeImage:75',
        label: '75% page width',
        value: '75',
      },
    ],
    toolbar: [
      'imageTextAlternative',
      'toggleImageCaption',
      'linkImage',
      '|',
      'imageStyle:inline',
      'imageStyle:wrapText',
      'imageStyle:breakText',
      'imageStyle:side',
      '|',
      'resizeImage',
    ],
    insert: {
      integrations: ['insertImageViaUrl'],
    },
  },
  link: {
    addTargetToExternalLinks: true,
    decorators: [
      {
        mode: 'manual',
        label: 'External Link',
        attributes: {
          target: '_blank',
        },
      },
    ],
  },
};

const getAssigneeAvatar = (task) => {
  const { formatter } = formatterInitialName();

  let avatar = <Person fontSize="small" color="disabled" />;
  if (task.assignee?.avatar) {
    avatar = <Avatar sx={{ width: 24, height: 24 }} src={task.assignee.avatar} />;
  } else if (task.assignee?.initial) {
    avatar = (
      <Avatar sx={{ width: 24, height: 24 }}>
        <span className="text-xs">{task.assignee?.initial}</span>
      </Avatar>
    );
  } else {
    avatar = <span className="text-xs">{formatter(task.assignee?.name)}</span>;
  }
  return avatar;
};

export default function TabTopicDetail() {
  const myContext = useContext(BoardsContext);
  const myContextDetail = useContext(DetailContext);
  const myContextDrawer = useContext(DrawerContext);
  const { formatter } = formatterInitialName();
  const activeUser = useSelector((state) => state.auth);
  const { idTask, getTopicDetails } = myContextDrawer;

  // const [isRequest, setIsRequest] = useState(false);
  const [task, setTask] = useState(false);
  const [labels, setLabels] = useState([]);
  const [topicTitle, setTopicTitle] = useState(false);
  const [dueDate, setDueDate] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(false);

  const [isFocusedComment, setIsFocusedComment] = useState(false);
  const [commentData, setIsCommentData] = useState('');
  const [filesComment, setFilesComment] = useState([]);
  const [filesAttachments, setFilesAttachments] = useState([]);
  const [anchorAssignee, setAnchorAssignee] = useState(null);

  const [anchorProfile, setAnchorProfile] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [isPopoverAttachments, setPopoverAttachments] = useState(false);
  const [tempInput, setTempInput] = useState({});

  const [isDescData, setIsDescData] = useState('');
  const handleChangeDesc = (value) => {

    // const { value } = event.target;
    setIsDescData(value);
  };

  // const descriptionRef = useRef(null);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: '*',
    onDrop: (acceptedFiles) => {
      let filteredFiles = acceptedFiles;
      if (filesComment.length) {
        const newFilteredFiles = [];
        acceptedFiles.forEach((file) => !filesComment.some((fl) => fl.name === file.name) && newFilteredFiles.push(file));
        filteredFiles = newFilteredFiles;
      }
      const newFiles = filteredFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
      setFilesComment((prevState) => {
        if (prevState.length) {
          return [...prevState, ...newFiles];
        }
        return newFiles;
      });
    },
  });
  const isCreator = useSelector((state) => state.auth.user?.id);
  const isUsername = useSelector((state) => state.auth.user?.name);

  const getTopicDetail = useCallback(() => {
    if (idTask) {
      getTopicDetails();
    }
  }, [idTask, getTopicDetails]);

  const getLabel = useCallback(() => {
    const { getLabels } = projectApi();
    getLabels({ project: myContextDetail.projectId, max_size: true })
      .then((res) => {
        if (res.status === 200) {
          setLabels(res.data.results);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [myContextDetail.projectId]);

  const postLabel = useCallback(
    (data) => {
      const { addLabel } = projectApi();
      addLabel({
        project: myContextDetail.projectId,
        name: data.label[0].toUpperCase() + data.label.substring(1),
        color: data.color,
      })
        .then(() => {
          getLabel();
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [myContextDetail.projectId, getLabel]
  );

  const getUserDetails = useCallback((idUser) => {
    const { getUserDetail } = userApi();
    getUserDetail(idUser)
      .then((res) => {
        if (res.status === 200) {
          setUserDetail(res.data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    const nTask = myContextDrawer.task;
    if (task && task.id !== nTask.id) {
      setTask(false);
    }
  }, [task, myContextDrawer.task]);

  useEffect(() => {
    const nTask = myContextDrawer.task;
    if (nTask && task !== nTask) {
      setLabels(myContextDrawer.labels);
      setTopicTitle(nTask.name);
      setIsDescData(nTask?.description);
      setDueDate(nTask.due_at);
      setSelectedLabels(nTask.labels);
      setSelectedStatus(nTask.section?.id);
      const attachments = [];
      nTask.comments.forEach((comment) => {
        if (comment.attachments.length) {
          comment.attachments.forEach((att) => {
            attachments.push(att);
          });
        }
      });
      setFilesAttachments(attachments);
      setTask(nTask);
    }
  }, [myContextDrawer.idTask, task, myContextDrawer.task, myContextDrawer.labels]);

  const handleTopicUpdate = useCallback(
    (data, isRecall = true, taskId) => {
      const { updateSectionTopic } = projectApi();

      updateSectionTopic(idTask || taskId, data)
        .then((res) => {
          if (res.status === 200) {
            if (isRecall) {
              getTopicDetail();
              myContext.getProject();
            }
            myContext.getProject();
            setTempInput({});
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [getTopicDetail, idTask, myContext]
  );

  const onBlurTopic = (event, stat) => {
    const value = event.target.value;

    if (value) {
      handleTopicUpdate(stat === 'title' ? { name: value } : { description: value });
    }
  };

  const onChangeTaskAssignee = (data) => {
    const userName = data;
    const user = myContextDetail?.users.find((u) => u.name === userName);

    const assignee = user ? user.id : null;

    handleTopicUpdate({ assignee });

    setAnchorAssignee(null);
  };

  const onChangeTaskStatus = (event) => {
    const sectionId = event.target.value;
    if (sectionId) {
      const selectedSection = myContextDetail.sections.filter((sec) => sec.id === sectionId);
      if (selectedSection && selectedSection.length) {
        const existTopic = task;

        const topicsDestination = [...selectedSection[0].topics];
        topicsDestination.push(existTopic);

        myContext.reorderTaskHandler(topicsDestination, sectionId, true);
        setSelectedStatus(sectionId);
      }
    }
  };

  const handleFocusComment = () => {
    setIsFocusedComment(true);
  };

  const handleChangeComment = (value) => {

    // const { value } = event.target;
    setIsCommentData(value);
  };

  const postComments = (data) => {
    const { createComments } = projectApi();

    createComments(data)
      .then((res) => {
        if (res.data) {
          setIsCommentData('');
          setIsFocusedComment(false);
          setFilesComment([]);

          if (Object.keys(tempInput).length > 0) {
            handleTopicUpdate(tempInput);
          } else {
            getTopicDetail();
            myContext.getProject();
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handlePostComment = () => {
    if (commentData || filesComment.length) {
      postComments({
        topic: idTask,
        creator: isCreator,
        detail: commentData,
        attachments: filesComment,
      });
    }
    setIsFocusedComment(false);
  };

  const handleRemoveAttachment = (name) => {
    setFilesComment(filesComment.filter((file) => file.name !== name));
  };

  const thumbsComment = filesComment.map((file) => {
    const { name, type } = file;
    const fileExt = name.substring(name.lastIndexOf('.') + 1);
    const fileType = type.substring(0, type.indexOf('/'));
    let html;
    const closeHtml = (
      <div className="absolute top-0 right-1 z-10 cursor-pointer" style={{ color: 'rgba(255, 255, 255, .5)' }}>
        <Close
          fontSize="small"
          color="inherit"
          onClick={() => handleRemoveAttachment(file.name)}
          className="bg-gray-700 bg-opacity-50 border border-gray-300 rounded-full shadow-sm"
        />
      </div>
    );
    if (type.includes('image') || fileType === 'image') {
      html = (
        <div key={file.name} className="relative bg-white p-1 border border-[#eaeaea] rounded-md h-16 min-w-[64px] max-w-[192px] flex items-center justify-center">
          {closeHtml}
          <img src={file.preview} className="w-auto h-full object-fill" alt="" loading="lazy" />
        </div>
      );
    } else {
      html = (
        <div key={file.name} className="relative bg-[#eaeaea] py-1 pl-2 pr-8 border border-[#eaeaea] rounded-md h-16 min-w-[64px] max-w-[192px] flex items-center justify-center">
          {closeHtml}
          <div className="flex space-x-1 items-center">
            <Article color="disabled" fontSize="large" />
            <div className="flex flex-col max-w-[112px]">
              <span className="relative overflow-hidden text-ellipsis text-sm whitespace-nowrap">{name}</span>
              <span className="text-xs text-gray-500">{`${fileExt} File`}</span>
            </div>
          </div>
        </div>
      );
    }
    return html;
  });

  const onTopicCompleted = () => {
    const dateTopic = new Date();
    handleTopicUpdate({ completed_at: task.completed_at ? null : dateTopic });
  };

  useEffect(() => {
    setIsFocusedComment(false);
  }, []);

  const dropZoneAttachments = useDropzone({
    // accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const { createComments } = projectApi();

      createComments({
        topic: idTask,
        creator: isCreator,
        detail: commentData,
        attachments: acceptedFiles,
      })
        .then((res) => {
          if (res.data) {
            getTopicDetail();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
  });

  const onClickAssignee = (event) => {
    setUserDetail(false);
    setAnchorProfile(null);
    setAnchorAssignee(event.currentTarget);
  };

  const openAssignee = Boolean(anchorAssignee);
  const idAssignee = openAssignee ? 'simple-popper' : undefined;

  const handleOnPaste = (e, destination) => {
    if (e && e.stopPropagation) e.stopPropagation();

    const { files } = e.clipboardData;
    if (files.length) {
      const arrFiles = [];
      for (let index = 0; index < files.length; index += 1) {
        if (destination) {
          arrFiles.push(files[index]);
        } else {
          arrFiles.push(Object.assign(files[index], { preview: URL.createObjectURL(files[index]) }));
        }
      }
      if (destination) {
        postComments({
          topic: idTask,
          creator: isCreator,
          detail: commentData,
          attachments: arrFiles,
        });
      } else {
        setFilesComment(arrFiles);
      }
    }
  };

  const openProfile = Boolean(anchorProfile);
  const idProfile = openProfile ? 'simple-popper' : undefined;

  return (
    <div className={['w-full h-full', styles.drawerCard].join(' ')} onPaste={(e) => handleOnPaste(e, 'global')}>
      {!task ? (
        <div className="flex flex-col items-start w-full pl-3 pr-6 space-y-2 mt-4">
          <Skeleton width="50%" />
          <Skeleton width="50%" />
          <Skeleton width="50%" />
          <Skeleton width="100%" />
        </div>
      ) : (
        <div className={styles.drawerCardContent}>
          <div className="pl-3 pr-6 mb-4 flex w-full">
            <Button className={[styles.drawerTaskComplete, task.completed_at && 'bg-green-200'].join(' ')} startIcon={<Check fontSize="12" />} onClick={onTopicCompleted}>
              {task.completed_at ? 'Completed' : 'Mark task as complete'}
            </Button>
          </div>
          <div className="flex items-start w-full pl-3 pr-6 mb-4">
            <div className="w-full">
              <TextField
                name=""
                id=""
                label=""
                className={styles.textTitle}
                defaultValue={topicTitle || ''}
                multiline
                onBlur={(e) => onBlurTopic(e, 'title')}
                onChange={(e) => setTempInput({ name: e.target.value })}
              />
            </div>
          </div>
          <div className="w-full pl-7 pr-6 space-y-4">
            <div className="flex w-full items-center">
              <div className="w-1/4 pr-2 text-left">
                <span className={styles.taskLabel}>Creator</span>
              </div>
              <div className="w-3/4 flex items-center">
                <GetAvatar data={task} onClick={() => {}} subject="creator" styles={{ width: 28, height: 28, marginRight: '.75rem' }} />
                <div className="flex flex-col">
                  <span className={styles.taskLabel}>{task.creator?.name}</span>
                  <span className={styles.taskLabel}>{format(new Date(task.created_at), 'MMM d, yyyy')}</span>
                </div>
              </div>
            </div>
            <div className="flex w-full">
              <div className="w-1/4 pr-2 text-left">
                <span className={styles.taskLabel}>Assignee</span>
              </div>
              <div className="w-3/4 flex space-x-1">
                <ButtonBase
                  aria-describedby={idAssignee}
                  onClick={onClickAssignee}
                  onMouseEnter={(event) => {
                    if (task.assignee) {
                      setAnchorProfile(event.currentTarget);
                      if (!userDetail) {
                        getUserDetails(task.assignee.id);
                      }
                    }
                  }}
                  onMouseLeave={() => {
                    setUserDetail(false);
                    setAnchorProfile(null);
                  }}
                >
                  <div className="border rounded-full border-dashed border-gray-300 w-7 h-7 flex justify-center items-center mr-3">{getAssigneeAvatar(task)}</div>
                  {!anchorAssignee && !task.assignee?.name && <span className={[styles.taskLabel, styles.taskLabelContent].join(' ')}>No assignee</span>}
                  {!anchorAssignee && task.assignee?.name && <span className={[styles.taskLabel, styles.taskLabelContent].join(' ')}>{task.assignee?.name}</span>}
                </ButtonBase>
                <ProfileDetail idProfile={idProfile} openProfile={openProfile} anchorProfile={anchorProfile} userDetail={userDetail} />
                {anchorAssignee && (
                  <Autocomplete
                    defaultValue={task.assignee}
                    name="drawerAssignee"
                    sx={{ width: 300, height: 28 }}
                    options={myContextDetail?.users || []}
                    autoHighlight
                    getOptionLabel={(option) => option.name || ''}
                    onBlur={() => setAnchorAssignee(null)}
                    // onChange={(event) => {
                    //   onChangeTaskAssignee(event);
                    // }}
                    onChange={(event, newValue) => {
                      // console.log('newValue ', newValue);
                      onChangeTaskAssignee(newValue?.name);
                    }}
                    blurOnSelect
                    clearIcon={<Close />}
                    renderOption={(props, option) => (
                      <Box key={option.name} component="li" {...props}>
                        <div className="w-6 h-6 border rounded-full text-xs flex justify-center items-center mr-2">
                          <span>{formatter(option.name)}</span>
                        </div>
                        <span className="text-ellipsis">{option.name}</span>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder={task.assignee?.name ? task.assignee.name : 'No assignee'}
                        variant="standard"
                        sx={{
                          '& > div': {
                            padding: 0,
                            border: 'none',
                            outline: 'none',
                          },
                        }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
            <div className="flex">
              <div className="w-1/4 pr-2 text-left">
                <span className={styles.taskLabel}>Due date</span>
              </div>
              <div className="w-3/4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={!dueDate ? task.due_at : dueDate}
                    onChange={(newValue) => {
                      handleTopicUpdate({ due_at: newValue });
                    }}
                    renderInput={(params) => <TextField className={styles.datePicker} {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="flex">
              <div className="w-1/4 pr-2 text-left">
                <span className={styles.taskLabel}>Project</span>
              </div>
              <div className="w-3/4">
                <span className={styles.taskLabel}>{task.project?.name}</span>
              </div>
            </div>
            <div className="flex">
              <div className="w-1/4 pr-2 text-left">
                <span className={styles.taskLabel}>Tags</span>
              </div>
              <div className="w-3/4 flex space-x-2">
                <div className="relative">
                  <Creatable labels={labels} selectedLabels={selectedLabels} postLabel={postLabel} handleTopicUpdate={handleTopicUpdate} />
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-1/4 pr-2 text-left">
                <span className={styles.taskLabel}>Status</span>
              </div>
              <div className="w-3/4 flex space-x-2">
                <Select
                  labelId="select-status-label"
                  id="select-status"
                  displayEmpty
                  value={selectedStatus}
                  onChange={onChangeTaskStatus}
                  input={<OutlinedInput />}
                  style={{ width: 250 }}
                  MenuProps={{ PaperProps: { style: { width: 250 } } }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem disabled value="">
                    <em>Status</em>
                  </MenuItem>
                  {myContextDetail.sections.map((sec, i) => (
                    <MenuItem key={`${sec.name + i}`} value={sec.id} className="px-4 py-2">
                      {sec.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="w-30 pr-2 text-left mb-1">
                <span className={styles.taskLabel}>Description</span>
              </div>

              <ClickAwayListener
                onClickAway={(e, val) => {
                  task.description !== isDescData ? handleTopicUpdate({ description: isDescData }, true, task?.id) : {};
                }}
              >
                <motion.div
                  className="w-full flex flex-col border rounded-xl p-2"
                  animate={isFocusedComment ? 'open' : 'closed'}
                  transition={{ type: 'tween' }}
                  variants={variants}
                  initial="closed"
                  id="descriptionEditor"
                >
                  <CKEditor
                    id="descriptionEditor"
                    key="descriptionEditor"
                    placeholder=""
                    className="ml-2 w-full border-0"
                    editor={Editor}
                    config={{
                      ...editorConfigs,
                      // toolbar: { items: [] },
                      placeholder: 'Add description',
                      minHeight: 172,
                    }}
                    data={task.description || ''}
                    onChange={(_, editor) => {
                      const editorData = editor.getData();
                      handleChangeDesc(editorData);
                      setTempInput({ description: editorData });
                    }}
                    onReady={(editor) => {
                      editor.editing?.view.change((writer) => {
                        writer.setStyle('min-height', '100px !important', editor.editing?.view.document.getRoot());
                        writer.setStyle('margin-top', '-2px', editor.editing?.view.document.getRoot());
                      });
                    }}
                  />
                </motion.div>
              </ClickAwayListener>
            </div>
          </div>
          <div className="pl-7 mt-4 mb-2 flex space-x-2 flex-wrap">
            {filesAttachments.length > 0 &&
              filesAttachments.map((file, index) => (
                <AttachmentFiles key={file?.file_attached} file={file} index={index} setPopoverAttachments={setPopoverAttachments} getTopicDetails={getTopicDetail} />
              ))}

            <div
              className="w-[60px] h-[60px] p-2 flex items-center justify-center border border-dashed border-gray-300 rounded-md cursor-pointer"
              {...dropZoneAttachments.getRootProps()}
              aria-hidden
            >
              <input {...dropZoneAttachments.getInputProps()} />
              <Add />
            </div>
          </div>

          <div className="w-full bg-[#F9F8F8] overflow-y-auto">
            {task.comments.length > 0 &&
              task.comments.map((comment, index) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  isUsername={isUsername}
                  getTopicDetails={getTopicDetail}
                  index={index}
                  setPopoverAttachments={setPopoverAttachments}
                  editorConfigs={editorConfigs}
                />
              ))}
          </div>
        </div>
      )}
      <div className={styles.drawerCardComment}>
        <div className="px-4 py-2 w-full flex flex-col">
          {!task ? (
            <Skeleton variant="rectangular" width="100%" height={32} />
          ) : (
            <>
              <div className="flex w-full" onPaste={(e) => handleOnPaste(e)}>
                <GetAvatar data={activeUser} onClick={() => {}} subject="user" styles={{ width: 32, height: 32, marginRight: '.5rem' }} />
                <motion.div
                  className="w-full flex flex-col border rounded-xl p-2"
                  animate={isFocusedComment ? 'open' : 'closed'}
                  transition={{ type: 'tween' }}
                  variants={variants}
                  initial="closed"
                >
                  <CKEditor
                    id="comment"
                    key="comment"
                    placeholder=""
                    className="ml-2 w-full border-0"
                    editor={Editor}
                    config={{
                      ...editorConfigs,
                      toolbar: { items: [] },
                      placeholder: 'Ask a question or post an updates',
                      minHeight: 172,
                    }}
                    data={commentData}
                    onFocus={(_, editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle('min-height', '172px !important', editor.editing.view.document.getRoot());
                      });
                      handleFocusComment();
                    }}
                    onBlur={(_, editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle('min-height', '100px !important', editor.editing.view.document.getRoot());
                      });
                    }}
                    onChange={(_, editor) => {
                      const editorData = editor.getData();
                      handleChangeComment(editorData);
                    }}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle('min-height', '100px !important', editor.editing.view.document.getRoot());
                        writer.setStyle('margin-top', '-2px', editor.editing.view.document.getRoot());
                      });
                    }}
                  />
                  <aside className="flex flex-wrap my-2 gap-2">{thumbsComment}</aside>
                  <motion.div className="w-full flex mt-auto justify-between" animate={isFocusedComment ? 'open' : 'closed'} transition={{ type: 'spring' }} variants={variantsBtn}>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <AttachFile />
                    </div>
                    <button className="h-fit flex items-center justify-center text-white p-[8px] bg-[#2C6D47] rounded-xl" onClick={handlePostComment}>
                      Comment
                    </button>
                  </motion.div>
                </motion.div>
              </div>
              <div className="w-full flex justify-between items-center py-2 pl-10">
                <div className="flex space-x-2 items-center">
                  <span>Collaborator</span>
                  <Avatar className="w-6 h-6">B</Avatar>
                  <Avatar className="w-6 h-6" />
                  <Add fontSize="14" />
                </div>
                <Button sx={{ color: '#2C6D47!important' }} startIcon={<Notifications fontSize="14" />}>
                  Leave task
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <AssetsViewer
        openAttachments={isPopoverAttachments || (String(isPopoverAttachments) === '0' && '0')}
        assets={filesAttachments}
        handleCloseAttachments={() => setPopoverAttachments(false)}
      />
    </div>
  );
}

/* eslint-disable */
import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Box, ButtonBase } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { Edit, Delete } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import DrawerContext from '../DrawerContext';
import DetailContext from '../../../../../pages/Task/Projects/DetailContext';
import getTimeZone from '../../../../../utils/Task/getTimeZone';
import styles from '../DrawerTask.module.css';
import ModalWrapper from '../../../../ui/ModalWrapper';
import { handleModal } from '../../../../../store/slices/modalSlice';
import ManualLogTime from '../../../../Form/Task/ManualLogTime';
import teamApi from '../../../../../api/task/team';
import store from '../../../../../store';
import apiProject from '../../../../../api/task/project';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

const tabTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '& .MuiList-root': {
              height: 124,
              overflowY: 'auto',
            },
          }),
      },
    },
  },
});

const sxStyle = {
  width: 'auto',
  height: 38,
  '& > div': {
    padding: '0px !important',
    outline: 'none',
  },
  '& .MuiOutlinedInput-input': { padding: '8px 12px !important' },
};

function formatDuration(totalMinutes) {
  const duration = moment.duration(totalMinutes, 'minutes');
  const formattedDuration = duration.format('HH:mm:ss');

  const splitTime = formattedDuration.split(':');
  const formatHour = splitTime.length === 3 ? splitTime[0] : splitTime.length === 4 ? splitTime[1] : 0;
  const formatMinute = splitTime.length === 3 ? splitTime[1] : splitTime.length === 4 ? splitTime[2] : splitTime[0];

  return ` ${formatHour.toString().padStart(2, '0')}:${formatMinute.toString().padStart(2, '0')}`;
}

function TimeFormat({ totalMinutes }) {
  return (
    <div>
      <p className="text-center"> {totalMinutes}</p>
      <p className="text-xs">{formatDuration(totalMinutes)}</p>
    </div>
  );
}

const days = [...Array(30)].map((_, i) => i + 1);
const months = [...Array(12)].map((_, i) => i + 1);
const sumAll = (arr) => {
  let daysInMonth = 0;
  const daysInDays = arr.d ? Number(arr.d) : 0;
  if (arr.m) {
    daysInMonth = Number(arr.m) * 30;
  }

  return `${daysInMonth + daysInDays}`;
};
const generateValues = (val) => {
  const isWhiteSpace = val.indexOf(' ') >= 0;
  const valTime = isWhiteSpace ? val.substring(val.lastIndexOf(' ') + 1) : val;
  const valDay = isWhiteSpace ? Number(val.split(' ')[0]) : 0;
  let valMonth;
  let valDays;
  let periods = 'time';
  valMonth = 0;
  valDays = valDay;
  if (valDay && valDay > 30) {
    const valDiv = valDay / 30;
    valMonth = Math.floor(valDiv);
    valDays = +valDay - valMonth * 30;
    periods = valMonth ? 'month' : 'day';
  } else if (valDay && valDay < 30) {
    periods = 'day';
  }
  return {
    period: periods,
    currValues: {
      m: valMonth.toString(),
      d: valDays.toString(),
      t: valTime.substring(0, valTime.indexOf(':') + 3),
    },
  };
};
const estimateDay = (val) => {
  const { d, m } = val;
  const totalDay = +m * 30 + +d;
  return {
    value: totalDay,
    label: +totalDay > 0 ? 'days' : 'day',
  };
};
const getCurrentEst = (val) => {
  const currM = val.m;
  const currD = val.d;
  const currT = val.t;
  const currDays = sumAll({ d: currD, m: currM });
  return Number(currDays) > 0 ? `${currDays} ${currT}:00` : `${currT}:00`;
};

function ContentSetting({ title, children }) {
  return (
    <div className="flex w-full items-center">
      <div className="w-1/4 pr-2 text-left">
        <span className={styles.taskLabel}>{title}</span>
      </div>
      <div className="w-3/4 flex items-center">{children}</div>
    </div>
  );
}

const initialValues = {
  m: '',
  d: '',
  t: '00.00',
};

const initialEstimations = {
  subtask: '',
  total: '',
};

export default function TabTopicSettings() {
  const myContextDrawer = useContext(DrawerContext);
  const myContextDetail = useContext(DetailContext);
  const activeUser = useSelector((state) => state.auth?.user);
  const dispatch = useDispatch();
  const teamList = myContextDetail?.teamList[0]?.team_users;
  // const activeUserRole = teamList.find((data) => data.user.id === activeUser.id);
  // const isOwner = activeUserRole?.role?.name === 'Owner'

  // console.log('myContextDrawer ', myContextDrawer);

  // eslint-disable-next-line no-unused-vars
  const { task, setIsAddTime } = myContextDrawer;

  const [isInitialized, setIsInitialized] = useState(false);
  const [period, setPeriod] = useState('time');
  const [values, setValues] = useState(initialValues);
  const [estimations, setEstimations] = useState(initialEstimations);
  const [taskId, setTaskId] = useState(false);
  const [reload, setReload] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [team, setTeam] = useState(false);
  const [activeUserRole, setActiveUserRole] = useState(null);
  const [isOwner, setIsOwner] = useState();

  const getProjectTeamDetail = useCallback(() => {
    const { getTeamDetail } = teamApi();
    getTeamDetail(myContextDetail?.projects.team?.id)
      .then((res) => {
        if (res.status === 200 || res.data) {
          setTeam(res.data);
          setActiveUserRole(res.data?.team_users?.find((data) => data.user.id === activeUser.id));
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.response?.status === 0) {
          store.dispatch({ type: 'auth/setLogout' });
          store.dispatch({ type: 'client/setActiveClient', payload: false });
          store.dispatch({ type: 'lang/setLanguageList', payload: false });
        }
      });
  }, [myContextDetail?.projects.team?.id]);

  useEffect(() => {
    if (myContextDetail?.projects.team) {
      getProjectTeamDetail();
    }
  }, [myContextDetail?.projects.team, getProjectTeamDetail]);

  useEffect(() => {
    if (activeUserRole !== null) {
      setIsOwner(activeUserRole?.role?.name === 'Owner');
    }
  }, [activeUserRole]);

  useEffect(() => {
    if (reload === true) {
      setTimeout(() => {
        myContextDrawer.getTopicDetails();
      }, 500);
    }
    setReload(false);
  }, [reload]);

  const handleChange = (param, value) => {
    setValues((prevState) => {
      return { ...prevState, [param]: value };
    });
  };

  const onChangeEstimation = () => {
    const { m, d, t } = values;
    if (t !== '00.00') {
      let est = `0 ${t}:00`;
      if (period === 'day') {
        est = `${d} ${t}:00`;
      }
      if (period === 'month') {
        est = `${+m * 30 + +d} ${t}:00`;
      }
      myContextDrawer.handleTopicUpdate({ estimation: est });
    }
  };

  const setDefaultValue = useCallback(
    (taskEstimation) => {
      const { self, dependencies, total: totalEstimation } = taskEstimation;

      const newValues = generateValues(self.estimation);
      const estSub = generateValues(dependencies.estimation);
      const estTotal = generateValues(totalEstimation.estimation);
      setTaskId(task.id);
      setPeriod(newValues.period);
      setValues(newValues.currValues);
      setEstimations({
        subtask: estSub.currValues,
        total: estTotal.currValues,
      });
    },
    [task.id]
  );

  useEffect(() => {
    const currEst = getCurrentEst(values);
    if (task.estimation && currEst !== task.estimation.total.estimation && !isInitialized) {
      setDefaultValue(task.estimation);
      setIsInitialized(true);
    }
  }, [task.estimation, values, isInitialized, taskId, task.id, setDefaultValue]);

  useEffect(() => {
    if (isInitialized && task.estimation) {
      const { dependencies, total: totalEstimation } = task.estimation;
      const { subtask, total } = estimations;
      const estSub = generateValues(dependencies.estimation);
      const estTotal = generateValues(totalEstimation.estimation);
      if (JSON.stringify(estSub.currValues) !== JSON.stringify(subtask) || JSON.stringify(estTotal.currValues) !== JSON.stringify(total)) {
        setEstimations({
          subtask: estSub.currValues,
          total: estTotal.currValues,
        });
      }
    }
  }, [task.estimation, estimations, values, isInitialized, taskId, task.id, setDefaultValue]);

  useEffect(() => {
    if (task.estimation && isInitialized && taskId !== task.id) {
      setDefaultValue(task.estimation);
    }
  }, [task.estimation, isInitialized, taskId, task.id, setDefaultValue]);

  const onActionLog = (param) => {
    const dataLog = { topic: myContextDrawer.idTask };
    const activeTask = {
      project: {
        name: myContextDrawer.task?.project?.name,
        id: myContextDrawer.task?.project?.id,
      },
      task: {
        name: myContextDrawer.task?.name,
        id: myContextDrawer.task?.id,
      },
    };
    const currDate = new Date();
    const currTimezone = getTimeZone();
    dataLog.timezone = {
      name: currTimezone.name,
      utc_offset: currTimezone.offset,
    };
    if (param === 'start') {
      if (myContextDetail.activeTask) {
        // set warning
        myContextDetail.trigger.setTriggerWarning({ ...activeTask, newTopic: myContextDrawer.idTask });
        return;
      }
      dataLog.time_in = currDate.toISOString();
      myContextDetail.logWorkingHours({ request: dataLog, state: activeTask }, false, {}, 'post');
    } else if (param === 'stop') {
      if (myContextDetail.activeTask) {
        dataLog.time_out = currDate.toISOString();
        myContextDetail.logWorkingHours({ request: dataLog, state: activeTask }, myContextDetail.activeTask.id, {}, 'patch');
        setReload(true);
      }
    }
  };

  const deleteTimeLog = (data) => {
    const { deleteLogTime } = apiProject();

    if (data) {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover!',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteLogTime(data.id).then((res) => {
            dispatch(handleModal({ modalId: '', componentName: '' }));
            setReload(true);
            swal('Success !', 'You sucessfully delete the logtime !', 'success');
          });
        }
      });
    }
  };

  const renderAction = (dt) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase
          className="bz-btn-tb"
          onClick={() => dispatch(handleModal({ modalId: '', componentName: 'editLogTimeManually', modalData: dt }))}
        >
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => deleteTimeLog(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const logColumns = [
    {
      field: 'assignee',
      headerName: 'Team Member',
      flex: 1,
      renderCell: (params) => <span className="truncate">{params.row.assignee?.name}</span>,
    },
    {
      field: 'time_in',
      headerName: 'From',
      renderCell: (params) => <span className="truncate">{params.row.time_in ? format(new Date(params.row.time_in), 'dd/MM/YYY HH:mm') : '-'}</span>,
      width: 144,
    },
    {
      field: 'time_out',
      headerName: 'To',
      renderCell: (params) => {
        return (
          <div className="relative w-full h-full flex items-center">
            <span className="truncate">{params.row.time_out ? format(new Date(params.row.time_out), 'dd/MM/YYY HH:mm') : '-'}</span>
            {params.row.timezone && params.row.timezone.utc_offset && <span className="truncate text-[10px] absolute bottom-0 right-0">{params.row.timezone.utc_offset}</span>}
          </div>
        );
      },
      width: 144,
    },
    {
      field: 'total_time',
      headerName: 'Total hours',
      renderCell: (params) => {
        const tTime = params.row.total_time;
        // const nTime = tTime.slice(0, tTime.lastIndexOf('.')).split(':');
        return <span className="truncate">{tTime && tTime.includes('.') ? tTime.slice(0, tTime.lastIndexOf('.')) : tTime || ''}</span>;
      },
      headerAlign: 'center',
      align: 'center',
      width: 112,
    },
    {
      field: 'billable_time',
      headerName: 'Billable Time',
      renderCell: (params) => {
        const tTime = params.row.billable_time;
        // const nTime = tTime.slice(0, tTime.lastIndexOf('.')).split(':');
        return <TimeFormat totalMinutes={params.row.billable_time || 0} />;
      },
      headerAlign: 'center',
      align: 'center',
      width: 112,
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => (activeUserRole?.role?.name === 'Owner' ? renderAction(params.row) : params.row.assignee?.id === activeUser?.id ? renderAction(params.row) : <></>),
    },
  ];

  const { subtask: estSubtask, total: estTotal } = estimations;
  const estVal = { value: 0, label: 'day' };
  let estimateSubtask = estVal;
  let estimateTotal = estVal;
  let estimateCurrTime = estVal;
  estimateSubtask = estSubtask ? estimateDay(estSubtask) : estVal;
  estimateTotal = estTotal ? estimateDay(estTotal) : estVal;

  if (isInitialized) {
    estimateCurrTime = estimateDay(values);
  }

  console.log(myContextDrawer.task);

  return (
    activeUserRole !== null && (
      <ThemeProvider theme={tabTheme}>
        <div className="w-full h-[calc(100vh-48px)] overflow-y-auto pl-3 pr-6 py-4">
          <div className="relative w-full pl-2 pr-0 space-y-4 flex flex-col">
            <div className="absolute top-0 right-0">
              <div className="flex space-x-4 items-center justify-end">
                <button
                  type="button"
                  className={`ml-auto flex items-center justify-center text-white p-[8px] ${
                    String(myContextDetail.activeTask?.topic) === String(myContextDrawer.idTask) ? 'bg-[#2c6d474d]' : 'bg-[#2C6D47]'
                  } rounded-xl`}
                  onClick={() => onActionLog('start')}
                  disabled={String(myContextDetail.activeTask?.topic) === String(myContextDrawer.idTask)}
                >
                  Start
                </button>
                <button
                  type="button"
                  className={`ml-auto flex items-center justify-center text-white p-[8px] ${
                    String(myContextDetail.activeTask?.topic) !== String(myContextDrawer.idTask) ? 'bg-[#fc581d4d]' : 'bg-[#FC581D]'
                  } rounded-xl`}
                  onClick={() => onActionLog('stop')}
                  disabled={String(myContextDetail.activeTask?.topic) !== String(myContextDrawer.idTask)}
                >
                  Stop
                </button>
              </div>
              <span className={styles.taskLabel}>{myContextDetail.activeTask?.time_in && `start at ${format(new Date(myContextDetail.activeTask.time_in), 'MMM d, hh:mm')}`}</span>
            </div>
            <ContentSetting title="Due Date">
              <span className={[styles.taskLabel, 'font-bold'].join(' ')}>{myContextDrawer.dueDate ? format(new Date(myContextDrawer.dueDate), 'MMM d, yyyy') : '-'}</span>
            </ContentSetting>
            <div className="flex w-full items-center">
              <div className="w-1/4 flex flex-col pr-2 text-left">
                <span className={styles.taskLabel}>Estimated Time</span>
                {task.estimation?.self?.estimation && <span className={styles.taskLabel}>{task.estimation.self.estimation}</span>}
              </div>
              <div className="w-3/4 flex">
                <div className="w-full flex flex-col space-y-4">
                  <div>
                    <FormControl sx={{ minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small">Period</InputLabel>
                      <Select labelId="demo-select-small" id="demo-select-small" value={period} label="period" onChange={(event) => setPeriod(event.target.value)} sx={sxStyle}>
                        <MenuItem value="time">Time</MenuItem>
                        <MenuItem value="day">Day</MenuItem>
                        <MenuItem value="month">Month</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="flex space-x-4">
                    {period === 'month' && (
                      <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel id="select-month">Month</InputLabel>
                        <Select labelId="select-month" id="select-month" value={values.m} label="month" onChange={(event) => handleChange('m', event.target.value)} sx={sxStyle}>
                          {months.map((_, i) => (
                            <MenuItem key={`${i + 1}`} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {['month', 'day'].includes(period) && (
                      <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small">Day</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={values.d}
                          label="day"
                          onChange={(event) => handleChange('d', event.target.value)}
                          sx={sxStyle}
                        >
                          {days.map((_, i) => (
                            <MenuItem key={`${i + 1}`} value={i + 1}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <TextField
                      id="time"
                      label="Time"
                      type="time"
                      onChange={(event) => handleChange('t', event.target.value)}
                      value={values.t}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ step: 300 }}
                      sx={sxStyle}
                    />
                  </div>
                  <button
                    type="button"
                    className="min-w-[140px] flex items-center justify-center text-white p-[8px] bg-[#2C6D47] rounded-xl"
                    onClick={onChangeEstimation}
                    disabled={(period === 'time' && values.t === '00.00') || (period === 'day' && values.d === '') || (period === 'month' && values.m === '')}
                  >
                    {task.estimation ? 'Update Estimated Time' : 'Set Estimated Time'}
                  </button>
                </div>
                <div className="w-full flex justify-end">
                  <button
                    className="h-fit flex items-center justify-center text-white p-[8px] bg-[#2C6D47] rounded-xl mt-1"
                    type="button"
                    onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addLogTimeManually' }))}
                  >
                    Add Manual
                  </button>
                </div>
              </div>
            </div>
            <ContentSetting title="Estimated Time">
              <div className="flex space-x-1">
                <span className={[styles.taskLabel, 'w-6 text-right'].join(' ')}>{estimateCurrTime.value}</span>
                <span className={[styles.taskLabel, 'w-8 text-left'].join(' ')}>{estimateCurrTime.label}</span>
              </div>
            </ContentSetting>
            <ContentSetting title="Estimated Time Subtask">
              <div className="flex space-x-1">
                <span className={[styles.taskLabel, 'w-6 text-right'].join(' ')}>{estimateSubtask.value}</span>
                <span className={[styles.taskLabel, 'w-8 text-left'].join(' ')}>{estimateSubtask.label}</span>
              </div>
            </ContentSetting>
            <ContentSetting title="Total Estimated Time">
              <div className="flex space-x-1">
                <span className={[styles.taskLabel, 'w-6 text-right'].join(' ')}>{estimateTotal.value}</span>
                <span className={[styles.taskLabel, 'w-8 text-left'].join(' ')}>{estimateTotal.label}</span>
              </div>
            </ContentSetting>
            {/* //TODO Enable this when we can assign user to assignee log  */}
            {/* <Button variant="contained" sx={{ fontSize: 12 }} className="w-auto ml-auto !mt-0" onClick={() => setIsAddTime(true)}>
            Add Time
          </Button> */}
            {myContextDrawer.task?.assignee_logs?.length > 0 && (
              <div>
                <DataGrid
                  autoHeight
                  rows={myContextDrawer.task.assignee_logs}
                  columns={logColumns}
                  pageSize={rowsPerPage}
                  onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50]}
                />
              </div>
            )}
          </div>
        </div>
        <ModalWrapper componentName="addLogTimeManually" header="Add Manual LogTime" maxWidth="sm">
          <ManualLogTime
            isOwner={isOwner}
            userList={myContextDetail?.users}
            topic={myContextDrawer.idTask}
            logWorkingHours={myContextDetail.logWorkingHours}
            myContextDrawer={myContextDrawer}
            reload={setReload}
            action="create"
          />
        </ModalWrapper>
        <ModalWrapper componentName="editLogTimeManually" header="Edit Manual LogTime" maxWidth="sm">
          <ManualLogTime
            isOwner={isOwner}
            userList={myContextDetail?.users}
            topic={myContextDrawer.idTask}
            logWorkingHours={myContextDetail.logWorkingHours}
            myContextDrawer={myContextDrawer}
            reload={setReload}
            action="edit"
          />
        </ModalWrapper>
      </ThemeProvider>
    )
  );
}

import React, { Suspense, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, ButtonBase, CircularProgress } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';

import ModalWrapper from '../../../components/ui/ModalWrapper';
import ConfirmDialog from '../../../components/Modal/CRM/ConfirmDialog';
import AttributeProfile from '../../../components/Modal/CRM/AttributeProfile';
// import { handleModal } from '../../../store/slices/modalSlice';
import crmApi from '../../../api/CRM/CRMCustomers';

const { actionCRMProjectDetailProfile, getCRMProjectProfiles } = crmApi();
export default function TableProfile() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const activeClient = useSelector((state) => state.client.activeClient);
  const isModal = useSelector((state) => state.modal);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmOpen, setConfirmOpen] = useState(null);
  const [attributeProfileSelected, setSelectedAttributeProfile] = useState(null);

  const {
    data: dataProfile,
    isFetching: isFetchingProfile,
    refetch,
  } = useQuery({
    queryKey: ['crm-project-profiles', activeClient],
    queryFn: () => getCRMProjectProfiles({ client: activeClient, max_size: true }),
    refetchOnWindowFocus: false,
  });

  // const openModalAttribute = () => {
  //   dispatch(handleModal({ modalId: '', componentName: 'AttributeProfile' }));
  // };

  const onDeleteClick = (param) => {
    actionCRMProjectDetailProfile(param.id, 'delete')
      .then(() => refetch())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!isModal.isOpen && attributeProfileSelected) {
      setSelectedAttributeProfile(null);
    }
  }, [isModal.isOpen, attributeProfileSelected]);

  const renderAction = (dt) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase
          className="bz-btn-tb"
          onClick={() => navigate(`/crm/profile/detail/${dt.id}`)}
          // onClick={() => {
          //   setSelectedAttributeProfile(dt);
          //   openModalAttribute();
          // }}
        >
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => setConfirmOpen(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'client',
      headerName: 'Client',
      flex: 1,
    },
    {
      field: 'is_default',
      headerName: 'Default',
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => renderAction(params.row),
    },
  ];

  return (
    <Suspense fallback={<CircularProgress />}>
      <button
        type="button"
        className="flex ml-auto gap-x-2 items-center text-center btn rounded border-0 mb-4 px-4 h-10 bg-[#2C6D47]"
        onClick={() => navigate(`/crm/profile/add`)}
        // onClick={openModalAttribute}
      >
        <AddIcon className="text-white" />
        <p className="text-white">Add Profile</p>
      </button>
      <DataGrid
        autoHeight
        rows={dataProfile || []}
        columns={columns}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        rowsPerPageOptions={[10, 20, 50]}
        loading={isFetchingProfile}
      />
      <ConfirmDialog title="Delete Profile" open={!!confirmOpen} setOpen={setConfirmOpen} onConfirm={() => onDeleteClick(confirmOpen)}>
        Are you sure you want to delete this profile?
      </ConfirmDialog>
      <ModalWrapper componentName="AttributeProfile" header={`${!attributeProfileSelected ? 'Add New' : 'Update'} Profile`} maxWidth="sm">
        <AttributeProfile profileSelected={attributeProfileSelected} refetch={refetch} />
      </ModalWrapper>
    </Suspense>
  );
}

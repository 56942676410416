import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Select, Table, TableBody, TableCell, TableHead, TableRow, Switch, IconButton, Collapse, TextareaAutosize, Button, TextField, CircularProgress, Typography, Stack, InputAdornment } from '@mui/material';
import BZHelmet from '@/utils/BZHelmet';
import BaseMainTitle from '@/components/BaseMainTitle';
import Input from '../CRM/redesigning/InputContainerRedesign';
import Swal from 'sweetalert2';
import _ from 'lodash';
import CustomSelectRedesign from '../CRM/redesigning/CustomSelectRedesign';
import { fetchData } from '@/utils/fetchDataFunc';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import HrApi from '@/api/HR/api';
import MuiSelectSingleItem from '@/components/Input/MuiSelectSingleItem';

function MarketManagerListNew() {
  const { control, handleSubmit, setValue, reset, resetField } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch();

  const [marketManagers, setMarketManagers] = useState([]);
  const [vatProfiles, setVatProfiles] = useState([]);
  const [count, setcount] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [mode, setMode] = useState('edit');
  const [selectedMarket, setSelectedMarket] = useState(null);
  
  const [selected, setSelected] = useState([]);

  const vatProfileList = useCallback((params) => fetchData(ProductManagementApi().getVatProfiles, params), [clientId]);

  const getVatProfileList = useCallback(
    async (param) => {
      const [vat] = await Promise.all([vatProfileList(param)]);

      if (vat) {
        setVatProfiles(vat?.results);
      }

      setIsFetching(false);
    },
    [vatProfileList]
  );

  useEffect(() => {
    getVatProfileList({ client: clientId });
  }, [clientId]);

  const departments = useCallback((params = { max_size: true, client: clientId, ordering: 'name' }) => fetchData(HrApi().getDepartment, params), [clientId]);

  const getAllOptions = useCallback(async () => {
    try {
      const [departmentList] = await Promise.all([departments()]);
      if (departmentList) {
        const { results } = departmentList;

        setDepartmentOptions(results.map((dt) => ({ label: `${dt.business_unit?.code ? dt.business_unit?.code + ' - ' + dt.name : dt.name}`, value: dt.id })));
        setIsLoadingOptions(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingOptions(false);
    }
  }, [departments]);

  useEffect(() => {
    getAllOptions();
  }, [getAllOptions]);

  const marketManagersList = useCallback((params) => fetchData(ProductManagementApi().getMarketManagers, params), [clientId]);

  const getMarketManagersList = useCallback(
    async (param) => {
      const [market] = await Promise.all([marketManagersList(param)]);
  
      if (market) {
        setMarketManagers(market?.results);

        if (!selectedMarket && market?.results?.length > 0) {
          setSelectedMarket(market?.results[0]);
          setValue("vat_profile", market?.results[0]?.vat_profile || "");
          setValue("vat_and_service_on_price", market?.results[0]?.vat_and_service_on_price || false);
        }
      }
  
      setIsFetching(false);
    },
    [marketManagersList, selectedMarket]
  );

  useEffect(() => {
    getMarketManagersList({ client: clientId });
  }, [clientId]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = () => {
    if (selected.length === marketManagers.length) {
      setSelected([]);
      return;
    } else {
      const newSelecteds = marketManagers?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
  };  

  
  const handleError = async (error) => {
    console.error(error);
    
    if (error.response && error.response.data) {
      const responseData = error.response.data;
  
      if (Array.isArray(responseData.errors) && responseData.errors.length > 0) {
        const firstErrorObj = responseData.errors[0];
        console.log({firstErrorObj});
        
  
        await Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: `${firstErrorObj.error}`,
        });
      } 
      else if (responseData.errors) {
        const firstError = responseData.errors[0];
        await Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error: ${firstError.error}`,
        });
      }
      else {
        await Swal.fire({
          icon: "error",
          title: "Unknown Error",
          text: "Something went wrong.",
        });
      }
    } else {
      await Swal.fire({
        icon: "error",
        title: "Unknown Error",
        text: "Something went wrong.",
      });
    }
  }
  
  const DeleteDatabyId = () => {
    const { deleteOneMarketManager, bulkDeleteMarketManager } = ProductManagementApi();
  
    if (selectedMarket && selected.includes(selectedMarket.id)) {
      setSelectedMarket(null);
      setValue("vat_profile", "");
      setValue("vat_and_service_on_price", false);
    }
  
    Swal.fire({
      title: 'Deleting...',
      icon: 'info',
      text: 'Please wait while we delete the market.',
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      if (selected.length > 1) {
        const idList = {
          "ids": selected
        }
  
        bulkDeleteMarketManager(idList)
          .then(() => {
            getMarketManagersList({ client: clientId });
            setSelected([]);
            Swal.fire({
              title: 'Success!',
              text: 'Successfully Deleted Markets!',
              icon: 'success',
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        deleteOneMarketManager(selected[0])
          .then(() => {
            getMarketManagersList({ client: clientId });
            setSelected([]);
            Swal.fire({
              title: 'Success!',
              text: 'Successfully Deleted Market!',
              icon: 'success',
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } catch (error) {
      handleError(error);
    }
  };
  

  const submitData = async (data) => {
    const { addNewMarketManager, updateMarketManager } = ProductManagementApi();
    const { vat_and_service_on_price, vat_profile, ...otherData } = selectedMarket;
  
    try {
      const newData = {
        client: clientId,
        vat_and_service_on_price: data.vat_and_service_on_price,
        vat_profile: data.vat_profile ? data.vat_profile : null,
        ...otherData,
      };

      Swal.fire({
        title: 'Processing...',
        icon: 'info',
        text: 'Please wait while we process your request.',
        didOpen: () => {
          Swal.showLoading();
        },
      });
      
  
      if (mode === 'edit') {
        await updateMarketManager(newData.id, newData);
        getMarketManagersList({ client: clientId });
        Swal.fire({
          title: "Success!",
          text: "Successfully Update Market!",
          icon: "success"
        })
      } else {
        await addNewMarketManager(newData);
        getMarketManagersList({ client: clientId }).then((marketData) => {
          console.log({marketData});
          
          if (marketData?.length) {
            setSelectedMarket(marketData[marketData.length - 1]);
            setValue("vat_profile", marketData[marketData.length - 1]?.vat_profile || "");
            setValue("vat_and_service_on_price", marketData[marketData.length - 1]?.vat_and_service_on_price || false);
          }
          setMode('edit')
        });
        Swal.fire({
          title: "Success!",
          text: "Successfully added new Market!",
          icon: "success"
        })
      }
    } catch (error) {
      handleError(error);
    }
  };
  

  return (
    <div className="w-full h-full bg-white">
      <BZHelmet title="Market Manager List" description="" content="" />
      <BaseMainTitle title="Market Manager List" />

      <div className="p-4">
        <div className="flex font-semibold capitalize">
          <div className="w-[30%] border-b-2 px-2 pb-1 flex items-center space-x-2">
            <Checkbox
              inputProps={{ "aria-label": "select all Market" }}
              indeterminate={
                selected.length > 0 &&
                selected.length < marketManagers.length
              }
              checked={
                marketManagers.length > 0 &&
                selected.length === marketManagers.length
              }
              onClick={handleSelectAllClick}
            />
            <span>Market List</span>
          </div>
          <div className="w-[70%] border-b-2 px-2 pb-1 flex items-center">
            <p>Detail</p>
          </div>
        </div>
        <div className="flex min-h-[65vh]">
          <div className="w-[30%] border-r-2 pt-4">
            <ul className="">
              {marketManagers.length > 0 &&
                marketManagers.map((market) => {
                  const isItemSelected = isSelected(market.id);
                  return (
                    <li key={market?.id} className="flex items-center space-x-2 p-2">
                        <Checkbox
                          color="primary"
                          onClick={(event) => handleClick(event, market.id)}
                          inputProps={{ "aria-labelledby": market.id }}
                          checked={isItemSelected}
                        />
                      <p 
                        className={`cursor-pointer ${selectedMarket?.id === market.id && "text-[#FC581D]"}`}
                        onClick={() => {
                          setSelectedMarket(market)
                          setValue("vat_profile", market?.vat_profile || "");
                          setValue("vat_and_service_on_price", market?.vat_and_service_on_price || false);
                        }}
                      >
                        {market?.market_name}
                      </p>
                    </li>
                )})}
            </ul>
            <div className="flex justify-end gap-2 mt-4 px-2">
              <button
                className={`
                  flex gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
                  ${selected.length ? 'bg-[#FC581D] cursor-pointer' : 'bg-[#FC581D26] cursor-default'}
                `}
                disabled={selected.length === 0}
                onClick={DeleteDatabyId}
              >
                Delete
              </button>
              <button 
                className="flex h-[40px] items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
                style={{ backgroundColor: '#2C6D47' }}
                onClick={() => {
                  setSelectedMarket(null)
                  setValue("vat_profile", "");
                  setValue("vat_and_service_on_price", false);
                  setMode("add")
                }}
              >
                Add
              </button>
            </div>
          </div>
          <div className="w-[70%]">
            <form className="space-y-4 w-[70%] px-2 pt-4" onSubmit={handleSubmit(submitData)}>
              <div className={`w-[60%] h-fit col-span-2`}>
                <p className={`font-semibold `}>Name</p>
                <TextField
                  className="w-full"
                  id="outlined-basic"
                  value={selectedMarket?.market_name || ''}
                  variant="outlined"
                  onChange={(e) => {
                    const { value } = e.target;
                    setSelectedMarket((market) => (market ? { ...market, market_name: value } : { market_name: value }));
                  }}
                />
              </div>
              <div className={`w-[60%] h-fit col-span-2`}>
                <p className={`font-semibold `}>Service Charge</p>
                <TextField
                  className="w-full"
                  id="outlined-basic"
                  value={selectedMarket?.service_charge || ''}
                  variant="outlined"
                  onChange={(e) => {
                    const { value } = e.target;
                    setSelectedMarket((market) => ({ ...market, service_charge: value }));
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </div>
              <div className="w-[60%] h-fit">
                <p className={`font-semibold mb-[4px]`}>VAT Profile</p>
                <MuiSelectSingleItem
                  name="vat_profile"
                  label="vat profile"
                  OptionLabel="VAT Profile"
                  control={control}
                  defaultValue={selectedMarket !== null && selectedMarket?.vat_profile || ""}
                  options={vatProfiles.map((vat) => ({ label: vat?.profile_name, value: vat?.id })) || []}
                  multiple={false}
                  menuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                  isInputLabel
                  disabled={false}
                />
              </div>
              <div className="flex flex-col gap-y-1 w-[60%]">
                <p className={`font-semibold "mb-[4px]"`}>VAT and Service Charge on prices</p>
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Typography>Excluding</Typography>
                  <Controller
                    name="vat_and_service_on_price"
                    control={control}
                    defaultValue={selectedMarket?.vat_and_service_on_price || false}
                    render={({ field }) => <Switch {...field} inputProps={{ 'aria-label': 'Switch Sellable' }} checked={field.value} onClick={field.onChange} />}
                  />
                  <Typography>Including</Typography>
                </Stack>
              </div>
              <div className="w-full h-fit">
                <p className="font-semibold mb-[5px]">Departments</p>
                <CustomSelectRedesign
                  className="mt-[4px] bg-white"
                  options={departmentOptions}
                  defaultValue={(selectedMarket?.departments || []).map((val) => departmentOptions.find((option) => option.value === val))}
                  isMulti
                  onChange={(selectedOptions) => {
                    const values = selectedOptions ? selectedOptions.map((option) => option.value) : [];
                    setSelectedMarket((market) => ({ ...market, departments: values }));
                  }}
                  isLoading={isLoadingOptions}
                />
                {/* <Controller
                  name="departments"
                  control={control}
                  defaultValue={selectedMarket && selectedMarket?.departments}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelectRedesign
                      options={departmentOptions}
                      defaultValue={value}
                      placeholder="Departments"
                      isMulti
                      onChange={(selectedOptions) => {
                        const values = selectedOptions ? selectedOptions.map((option) => option.value) : [];
                        setSelectedMarket((market) => ({ ...market, departments: values }));
                        onChange(values);
                      }}
                    />
                  )}
                /> */}
              </div>
              <button
                type="submit"
                name="submit contact"
                className="flex h-[40px] mt-7 ml-auto gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                style={{ backgroundColor: '#2C6D47' }}
              >
                <p className="text-white">{mode === "edit" ? 'Update' : 'Add New'}</p>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketManagerListNew;

import React, { useContext, useEffect, useState, useCallback, useLayoutEffect } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

import DetailContext from '../../Projects/DetailContext';
import projectApi from '../../../../api/task/project';
import LabelSetting from './LabelSetting';
import TopicTypeSetting from './TopicTypeSetting';
import TopicStatusSetting from './TopicStatusSetting';
import TopicPrioritySetting from './TopicPrioritySetting';
import Notification from './Notification/Notification';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.defaultProps = { children: <div /> };

function a11yProps(index) {
  return {
    id: `accounting-tab-${index}`,
    'aria-controls': `accounting-tabpanel-${index}`,
  };
}

const tabItems = ['Project Labels', 'Topic Types', 'Topic Status', 'Topic Priorities', 'Notification'];

export default function Settings() {
  const detailContext = useContext(DetailContext);
  const { projectId: project, dispatchProject } = detailContext;
  const [activeTab, setActiveTab] = useState(0);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isApiCall, setIsApiCall] = useState(true);
  const [labels, setLabels] = useState(false);
  const [topicTypes, setTopicTypes] = useState(false);
  const [topicStatuses, setTopicStatuses] = useState(false);
  const [topicPriorities, setTopicPriorities] = useState(false);
  const [topicSections, setTopicSections] = useState(false);

  const { getLabels, getTopicTypes, getTopicStatuses, getTopicPriorities, getTopicSections } = projectApi();

  const onTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getLabelList = useCallback(
    (type) => {
      return new Promise((resolve, reject) => {
        getLabels({ project, max_size: true })
          .then((res) => {
            if (res.status === 200 || res.data.results) {
              if (!type) {
                resolve(res.data.results);
              } else {
                setLabels(res.data.results);
                dispatchProject('GET');
              }
            }
          })
          .catch((err) => {
            console.log('err', err);
            reject(err);
          });
      });
    },
    [getLabels, project, dispatchProject]
  );

  const getTopicTypeList = useCallback(
    (type) => {
      return new Promise((resolve, reject) => {
        getTopicTypes({ project, max_size: true })
          .then((res) => {
            if (res.status === 200 || res.data.results) {
              if (!type) {
                resolve(res.data.results);
              } else {
                setTopicTypes(res.data.results);
                dispatchProject('TYPE');
              }
            }
          })
          .catch((err) => {
            console.log('err', err);
            reject(err);
          });
      });
    },
    [getTopicTypes, project, dispatchProject]
  );

  const getTopicStatusList = useCallback(
    (type) => {
      return new Promise((resolve, reject) => {
        getTopicStatuses({ project })
          .then((res) => {
            if (res.status === 200 || res.data.results) {
              if (!type) {
                resolve(res.data.results);
              } else {
                setTopicStatuses(res.data.results);
              }
            }
          })
          .catch((err) => {
            console.log('err', err);
            reject(err);
          });
      });
    },
    [getTopicStatuses, project]
  );

  const getTopicPrioritiesList = useCallback(
    (type) => {
      return new Promise((resolve, reject) => {
        getTopicPriorities({ project })
          .then((res) => {
            if (res.status === 200 || res.data.results) {
              if (!type) {
                resolve(res.data.results);
              } else {
                setTopicPriorities(res.data.results);
                dispatchProject('PRIORITY');
              }
            }
          })
          .catch((err) => {
            console.log('err', err);
            reject(err);
          });
      });
    },
    [getTopicPriorities, project, dispatchProject]
  );

  const getTopicSectionList = useCallback(
    (type) => {
      return new Promise((resolve, reject) => {
        getTopicSections({ project, ordering: 'index' })
          .then((res) => {
            if (res.status === 200 || res.data.results) {
              if (!type) {
                resolve(res.data.results);
              } else {
                setTopicSections(res.data.results);
                dispatchProject('GET');
              }
            }
          })
          .catch((err) => {
            console.log('err', err);
            reject(err);
          });
      });
    },
    [getTopicSections, project, dispatchProject]
  );

  const getDefaultData = useCallback(async () => {
    setIsApiCall(false);
    const defaultActions = [getLabelList(), getTopicTypeList(), getTopicStatusList(), getTopicPrioritiesList(), getTopicSectionList()];
    const [a, b, c, d, e] = await Promise.all(defaultActions);
    if (a && b && c && d && e) {
      setLabels(a);
      setTopicTypes(b);
      setTopicStatuses(c);
      setTopicPriorities(d);
      setTopicSections(e);
    }
  }, [getLabelList, getTopicTypeList, getTopicStatusList, getTopicPrioritiesList, getTopicSectionList]);

  useLayoutEffect(() => {
    if (!isLoaded && isApiCall) {
      getDefaultData();
    }
  }, [isLoaded, getDefaultData, isApiCall]);

  useEffect(() => {
    if (!isLoaded && labels && topicTypes && topicStatuses && topicPriorities && topicSections) {
      setIsLoaded(true);
    }
  }, [isLoaded, labels, topicTypes, topicStatuses, topicPriorities, topicSections]);

  return (
    <div className="w-full h-[calc(100vh_-_140px)] overflow-y-auto">
      {isLoaded && (
        <div className="w-full h-full px-6 py-6 space-y-3">
          <div className="border-b">
            <Tabs value={activeTab} onChange={onTabChange} aria-label="task board tabs" className="bz-tabs">
              {tabItems && tabItems.length > 0 && tabItems.map((tb, index) => <Tab key={tb} className="p-4" label={tb} {...a11yProps(index)} />)}
            </Tabs>
          </div>
          <TabPanel value={activeTab} index={0}>
            <LabelSetting data={labels} getLabels={() => getLabelList('set')} />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <TopicTypeSetting data={topicTypes} getTopicTypes={() => getTopicTypeList('set')} />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <TopicStatusSetting data={topicSections} getTopicStatuses={() => getTopicSectionList('set')} />
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            <TopicPrioritySetting data={topicPriorities} getTopicPriorities={() => getTopicPrioritiesList('set')} />
          </TabPanel>
          <TabPanel value={activeTab} index={4}>
            <Notification />
          </TabPanel>
        </div>
      )}
    </div>
  );
}

export function removeEmpty(arr) {
  const copy = arr?.map((obj) => {
    const copyObj = { ...obj }; // Create a copy of the object
    const keys = Object.keys(copyObj);
    for (let j = 0; j < keys.length; j += 1) {
      if (copyObj[keys[j]] === '' || copyObj[keys[j]] === null || copyObj[keys[j]] === undefined) {
        delete copyObj[keys[j]];
      }
    }
    return copyObj;
  });
  return copy?.filter((obj) => Object.keys(obj).length > 0);
}

export function filterWithProperty(array, property) {
  const filteredArray = [];
  let nullNameCount = 0;
  for (let i = 0; i < array.length; i += 1) {
    if (Object.prototype.hasOwnProperty.call(array[i], property)) {
      filteredArray.push(array[i]);
    } else {
      nullNameCount += 1;
      if (nullNameCount <= 1) {
        filteredArray.push(array[i]);
      }
    }
  }
  return filteredArray;
}

export function removeEmptyProperties(arr, property) {
  return arr.map((obj) => {
    const newObj = { ...obj };
    property.map((prop) => {
      if (newObj[prop] === '') delete newObj[prop];
      return newObj;
    });
    // if (newObj.name === '') delete newObj.name;
    return newObj;
  });
}

/* eslint-disable */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material/';
import { Close, Save } from '@mui/icons-material';
import { Responsive, WidthProvider } from "react-grid-layout";
import { Link } from 'react-router-dom';
import CardChart from '../../../components/Chart/aCardChart';
import { getStaticPeriod, standardizeInputDate } from '../../../lib/StaticPeriod';
import ClientDashboardApi from '../../../api/clientDashboard';
import styles from './RGLHome.module.css';
import 'react-grid-layout/css/styles.css'

const ResponsiveGridLayout  = WidthProvider(Responsive);

function Dashboard3() {
  const now = new Date();
  const today = moment();
  const { getDashboardData } = ClientDashboardApi();
  const clientId = useSelector((state) => state.client.activeClient);

  const [dashboardData, setDashboardData] = useState([]);
  const [gridLayout, setGridLayout] = useState([]);
  const [staticPeriod, setStaticPeriod] = useState('1');
  const [dataMerged, setDataMerged] = useState([]);
  const [period, setPeriod] = useState({ start_date: today.year(2017).format('YYYY-MM-DD'), end_date: today.format('YYYY-MM-DD'), isNow: true });

  useEffect(() => {
    const timezoneOffset = new Date().getTimezoneOffset();
    let data = {
      client: clientId,
      group: 9,
      // start_date: standardizeInputDate(period.start_date, timezoneOffset),
      // end_date: standardizeInputDate(period.end_date, timezoneOffset, period.isNow),
    };

    const getData = async () => {
      const response = await getDashboardData(data);
      const responseData = response;
      setDashboardData(responseData?.data.slice(0, 8));
    };

    getData();
  }, [clientId, period]);

  console.log({dashboardData});

  const generateDataGrid = () => {
    const newGridLayout = dashboardData.map((gr, i) => {
      const { dimension: d, profile_attributes: attr } = gr;
      const objGroup = {
        minH: 4,
        minW: 4,
        w: d[2] > 20 ? 20 : d[2],
        h: d[3],
        x: d[0],
        y: d[1],
        i: `${i}`,
        maxW: 20,
        moved: false,
        static: false,
      };
      if (attr?.length) {
        objGroup.gridLayout = attr.map((a, idx) => {
          const { dimension: dim } = a;
          return {
            minH: 2,
            minW: 4,
            w: dim[2] > 20 ? 20 : dim[2],
            h: dim[3],
            x: dim[0],
            y: dim[1],
            i: `${gr.name}`,
            moved: false,
            static: false,
            maxW: 20,
          };
        });
      }
      return objGroup;
    });

    return setGridLayout(newGridLayout);
  };

  useEffect(() => {
    if (dashboardData.length > 0) {
      generateDataGrid();
    }
  }, [dashboardData]);

  useEffect(() => {
    setPeriod(getStaticPeriod(staticPeriod));
  }, [staticPeriod]);

  const generateDOM = () => {
    return gridLayout.map((item, i) => {
      let lblText = item.i;
      let lblId = item.i;
      const isGroup = item.i;
      if (!isGroup) {
        const newI = item.i.slice(item.i.indexOf('|') + 1);
        const arrI = newI.split('|');
        lblText = arrI[arrI.length - 1];
        lblId = arrI[arrI.length - 2];
      } else {
        const arrI = item.i.split('|');
        lblText = arrI[arrI.length - 1];
        lblId = arrI[arrI.length - 2];
      }
      const isPlaceholder = lblText.includes('dropping-elem');
      if (isPlaceholder) lblText = 'item';

      const { data, name, configuration } = dashboardData[i];
      const { display_types } = configuration;

      const typeChart = display_types && display_types.filter((item) => item.includes('_chart'));
      const chartTypeCheck = display_types && display_types.some((item) => item.includes('_chart'));

      const newfinalData = {
        ...dashboardData[i],
        data: [],
      };

      if (typeof data[0] !== 'object') {
        const checkking = dashboardData && dashboardData?.filter((item) => data.includes(item.id));

        checkking.forEach((section, idx) => {
          const totalCount = section?.data && section?.data.length > 1 ? section?.data.reduce((acc, curr) => acc + curr.count, 0) : section?.data[0]?.count;
          newfinalData.data.push({ variable: section?.name, count: totalCount });
        });
      }

      return (
        <div key={`${i}`} className={["w-full h-full flex gap-5 relative", styles.reactGridItem, isGroup ? 'grid-group' : 'grid-attribute'].join(' ')}>
          {/* {!isPlaceholder && isGroup && <Close className="absolute top-0 right-0 z-20 cursor-pointer" onClick={() => onRemoveElement(item.i, lblId)} fontSize="12" />} */}
          {typeof data[0] === 'object' ? (
            <>
              {display_types && display_types.includes('list') && (
                <TableContainer className={`h-auto shadow-none ${chartTypeCheck ? 'min-w-3/6' : 'w-full'}`} component={Paper} sx={{ height: 'fit-content' }}>
                  <Table aria-label="simple table">
                    <TableHead className='handleDrag cursor-move'>
                      <TableRow>
                        <TableCell className="font-semibold text-base">{name}</TableCell>
                        <TableCell className="font-semibold text-base" align="right">
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="p-0" colSpan={2}>
                          {data?.map((report, idx) => (
                            <Accordion key={report.variable + idx} className="w-full">
                              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                <Typography>{report.variable}</Typography>
                                <Typography className="ml-auto">{report.count}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="h-full pb-4 ml-3 flex flex-col gap-y-2">
                                  {report?.members.map((member) => (
                                    <Link to={`/customers/detail/${member.member__id}`}>
                                      <div key={member.member__id} className="hover:text-sky-600">
                                        {member.member__name}
                                      </div>
                                    </Link>
                                  ))}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {chartTypeCheck && (
                  <CardChart
                    className="w-full h-full col-span-2 relative"
                    draggableHandle='handleDrag'
                    title={name}
                    labels={data?.map((item) => item.variable)}
                    data={data?.map((item) => item.count)}
                    typeChart={typeChart}
                  />
              )}
            </>
          ) : (
            newfinalData?.data.length > 1 && (
              <CardChart
                className="h-full w-full col-span-2 relative"
                draggableHandle='handleDrag'
                title={name}
                labels={newfinalData?.data.map((item) => item.variable)}
                data={newfinalData?.data.map((item) => item.count)}
                typeChart={typeChart}
              />
            )
          )}
        </div>
      );
    });
  };

  return gridLayout.length !== 0 && (
      <div className="w-full bg-white py-[30px] px-3">
        <ResponsiveGridLayout 
          compactType='vertical'
          autoSize  
          containerPadding={[20,20]}
          layouts={{lg:gridLayout}}
          cols={{ lg: 14, md: 14,  }}
          rowHeight={80}
          width={100}
          draggableHandle='.handleDrag'
        >
          {generateDOM()}
        </ResponsiveGridLayout >
      </div>
  )
}

export default Dashboard3
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueries, useQuery } from '@tanstack/react-query';
import { Box, Chip, CircularProgress, FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup } from '@mui/material';
import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';

import BZHelmet from '../../../utils/BZHelmet';
import report from '@/api/accounting/report';
import accounting from '@/api/accounting/accounting';

import styles from './Report.module.css';

const { getAccountingReport, getBalanceReport, getFinancialYears, getLedgers } = report();
const { getCostCenters, getAccountingPeriods } = accounting();

const nf = new Intl.NumberFormat('id-ID', {
  minimumFractionDigits: 0,
  roundingIncrement: 1,
});
const genTd = (content, style = '', config = { isBold: false, isTitle: false }) => {
  const { isBold, isTitle } = config;
  const fs = isBold ? '13px' : '14px';
  const fc = !isTitle && content?.toString().includes('-') ? 'color: red;' : 'color: black;';
  if (content) {
    const tdContent = isBold ? `<b>${content}</b>` : content;
    return `<td style="font-size: ${fs}; ${fc} ${style}">${tdContent}</td>`;
  }
  return `<td style="font-size: ${fs}; ${style}">&nbsp;</td>`;
};
const generateWarning = (col) => {
  return `<td colSpan=${4 + col} style="text-align: center; color: red"><b>Please check your transactions, assets and liabilities not in balance</b></td>`;
};
const getMonth = (month) => (month.toString().length !== 1 ? month : `0${month}`);

const sxToggle = { width: '100%', '&.Mui-selected': { background: 'rgb(44, 109, 71, 0.08)', color: '#2c6d47' } };
const mode = import.meta.env.VITE_MODE;

export default function ReportBalanceSheet() {
  const { enqueueSnackbar } = useSnackbar();
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeBusinessUnit = useSelector((state) => state.acc.activeBusinessUnit);

  const [selectedFinancialYears, setSelectedFinancialYears] = useState(1);
  const [selectedLedgerType, setSelectedLedgerType] = useState('');
  const [selectedCostCenters, setSelectedCostCenters] = useState([]);
  const [reportMode, setReportMode] = useState('Accumulative');

  const [warningControl, setWarningControl] = useState(false);

  const [dataTableView, setDataTableView] = useState(false);

  const queryResult = useQueries({
    queries: [
      {
        queryKey: ['financialYears', activeClient],
        queryFn: () => getFinancialYears({ client: activeClient.id, ordering: '-year' }),
        enabled: !!activeClient,
        onSuccess: (res) => {
          if (res?.data?.results?.length) {
            setSelectedFinancialYears(res.data.results[0].id);
          }
        },
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['costCenters', activeClient, activeBusinessUnit],
        queryFn: () => getCostCenters({ business_unit: activeBusinessUnit }),
        enabled: !!activeClient && !!activeBusinessUnit,
        onSuccess: (res) => {
          if (res?.data?.results?.length) {
            const cc = res.data.results.map((d) => d.id);
            setSelectedCostCenters(cc);
          }
        },
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['ledgers', activeClient, activeBusinessUnit],
        queryFn: () => getLedgers({ business_unit: activeBusinessUnit }),
        enabled: !!activeClient && !!activeBusinessUnit,
        onSuccess: (res) => {
          if (res?.data?.results?.length) {
            setSelectedLedgerType(res.data.results[0].id);
          }
        },
        refetchOnWindowFocus: false,
      },
    ],
  });

  const [financialYears, costCenters, ledgers] = queryResult;
  const { data: fyData } = financialYears;
  const { data: ccData } = costCenters;
  const { data: ledgersData } = ledgers;

  const isLoaded = queryResult.every((query) => query.isSuccess);

  const { data: accPer } = useQuery({
    queryKey: ['accountingPeriods', activeClient, selectedFinancialYears],
    enabled: !!activeClient && !!selectedFinancialYears,
    queryFn: () => getAccountingPeriods({ financial_year__client: activeClient.id, financial_year: selectedFinancialYears, max_size: true }),
  });

  const generateLine = (aPnlGroup, bAccType, cCategory, dPnlCategory, periods, style) => {
    const data = {
      id: Math.floor(Math.random() * 1000000000),
      aPnlGroup,
      bAccType,
      cCategory,
      dPnlCategory,
      ...periods,
      style,
    };
    return data;
  };

  const generateSum = (period, sums, isTwoVar, isDivider = true, operator = '-', multiplication = 1) => {
    const data = {};
    const dataTemp = {};
    period.forEach((per) => {
      let isSum;
      if (operator === '+') {
        isSum = sums.length > 0 ? sums.map((item) => item[per]).reduce((prev, curr) => Number(prev) + Number(curr)) : 0;
      } else {
        isSum = sums.length > 0 ? sums.map((item) => item[per]).reduce((prev, curr) => Number(prev) - Number(curr)) : 0;
      }
      if (isTwoVar) {
        dataTemp[per] = isSum;
      }
      if (isDivider) {
        data[per] = genTd(Math.sign(isSum) !== 0 ? nf.format(isSum * multiplication) : isSum, 'text-align: right; border-bottom: solid black', { isBold: true });
      }
    });
    return { data, dataTemp };
  };

  const { isFetching, refetch } = useQuery({
    queryKey: ['balance-reports', activeClient, selectedFinancialYears],
    enabled: !!activeClient && !!reportMode && !!selectedFinancialYears && !!selectedLedgerType && !!selectedCostCenters.length,
    queryFn: () =>
      getAccountingReport(selectedFinancialYears, {
        summation_type: reportMode === 'Accumulative' ? 'Balance Sheet' : 'Balance Movement',
        ledger: selectedLedgerType,
        cost_centers: selectedCostCenters,
      }),
    onSuccess: (res) => {
      const dataReport = reportMode === 'Accumulative' ? res.data.account_groups : res.data;

      const aP = accPer.data.results;
      const fY = fyData.data.results;
      const headPeriod = [`YTD-${fY.find((y) => y.id === selectedFinancialYears)?.year}`];
      aP.forEach((ap) => {
        headPeriod.push(`${ap.year}-${getMonth(ap.month)}`);
      });

      const emptyPeriod = {};
      const emptyPeriodNull = {};
      headPeriod.forEach((per, i) => {
        emptyPeriod[headPeriod[i]] = '';
        emptyPeriodNull[headPeriod[i]] = '0';
      });

      const reports = [];
      const realReports = [];

      const tdWithBb = genTd('', 'border-bottom: solid black');
      const dataControls = {};
      dataReport.forEach((dt, index) => {
        const tempSumResult = [];
        const totalAccTypes = dt.account_types.length - 1;
        const sumAccTypes = [];
        let multiplications = 1;

        dt.account_types.forEach((accType, idxAccType) => {
          const multiplication = accType.is_sign_reversed ? -1 : 1;
          const totalPnlBsCate = accType.pnl_bs_categories.length - 1;
          const tempSumSummary = [];

          accType.pnl_bs_categories.forEach((pnl, idx) => {
            const eoAT = { text: '', obj: {} };
            if (totalPnlBsCate === idx && totalAccTypes !== idxAccType) {
              eoAT.text = 'border-bottom: solid black';
              eoAT.obj = { borderBottom: 'solid black' };
            }

            const dataModified = {};
            const dataOriginal = {};
            headPeriod.forEach((per, i) => {
              if (i === 0) {
                const isBalance = reportMode === 'Accumulative' ? pnl.ytd_balance : pnl.ytd_sum;
                const balance = reportMode === 'Accumulative' ? isBalance?.balance : pnl.ytd_sum;
                dataModified[headPeriod[i]] = isBalance ? (balance !== 0 && Math.sign(balance) !== 0 ? nf.format(balance * multiplication) : balance || "0") : '0' || '';
                dataOriginal[headPeriod[i]] = isBalance ? balance : 0 || '';
              } else {
                const dtAcc = pnl.accounting_periods.filter((pn) => per === `${pn.year}-${getMonth(pn.month)}`);
                const key = reportMode === 'Accumulative' ? 'balance' : 'sum';
                const balance = dtAcc.length ? dtAcc[0][key] : 0;
                dataModified[headPeriod[i]] = dtAcc.length ? (Math.sign(balance) !== 0 ? nf.format(balance * multiplication) : balance || "0" || '0') : '0';
                dataOriginal[headPeriod[i]] = dtAcc.length ? balance || 0 : 0;
              }
            });

            const isBold = idx === 0;
            const pnlG = isBold ? dt.name : '';
            const accT = isBold ? accType.name || accType.code : '';
            const titleStyle = { isBold, isTitle: true };
            const contentStyle = { isBold, isTitle: false };
            if (idx < totalPnlBsCate) {
              // normal line
              tempSumSummary.push(generateLine(genTd(pnlG), genTd(accT), accT, genTd(pnl.name), { ...dataOriginal }));
              reports.push(
                generateLine(genTd(pnlG, '', titleStyle), genTd(accT, '', titleStyle), accT, genTd(pnl.name, '', { isBold: false, isTitle: true }), { ...dataModified })
              );
              realReports.push(dataOriginal);
            } else if (idx === totalPnlBsCate) {
              // last line
              reports.push(
                generateLine(
                  genTd(pnlG, eoAT.text, contentStyle),
                  genTd(accT, eoAT.text, titleStyle),
                  accT,
                  genTd(pnl.name, eoAT.text, { isBold: false, isTitle: true }),
                  { ...dataModified },
                  { style: eoAT.obj }
                )
              );
              realReports.push(dataOriginal);
              tempSumSummary.push(generateLine(genTd(pnlG), genTd(accT), accT, genTd(pnl.name), { ...dataOriginal }));
            }
            const dataGen = generateSum(headPeriod, tempSumSummary, true, totalPnlBsCate !== 0 || totalPnlBsCate === idx, '+', multiplication);
            // if (index === 0 && idx === totalPnlBsCate) {
            //   dataControls.assets = dataGen.dataTemp;
            // }

            tempSumResult.push(generateLine(genTd(), genTd(), accT, genTd('Total'), { ...dataGen.dataTemp }));
            if (idx === totalPnlBsCate && dataGen.data && Object.keys(dataGen.data).length) {
              sumAccTypes.push(dataGen.dataTemp);
              multiplications = multiplication * multiplications;
              reports.push(generateLine(tdWithBb, tdWithBb, accT, genTd('Total', 'border-bottom: solid black', { isBold: true }), { ...dataGen.data }));
              realReports.push(dataGen.dataTemp);
            }
          });
        });
        if (sumAccTypes.length && index === 0) {
          const total = {};
          sumAccTypes.forEach((a) => {
            Object.keys(a).forEach((k) => {
              total[k] = total[k] ? (total[k] += a[k]) : a[k];
            });
          });
          dataControls.assets = total;
          if (sumAccTypes.length > 1) {
            const lnSubTotal = generateLine('', '', `Total`, genTd('Total'), { ...total });
            const dataGen = generateSum(headPeriod, [lnSubTotal], true, true, '+', multiplications);
            reports.push(generateLine(tdWithBb, tdWithBb, '', genTd(`Total ${dt.name}`, 'border-bottom: solid black', { isBold: true }), { ...dataGen.data }));
            realReports.push(dataGen.dataTemp);
          }
        }
      });

      let isShowControlWarning = true;
      if (reportMode === 'Accumulative') {
        const dataReportTotal = res.data.total_equity;
        dataReportTotal.forEach((dt, index) => {
          const multiplication = dt.is_sign_reversed ? -1 : 1;
          const dataOriginal = {};
          const dataModified = {};
          headPeriod.forEach((per, i) => {
            if (i === 0) {
              dataModified[headPeriod[i]] = dt.ytd_balance ? (Math.sign(dt.ytd_balance) !== 0 ? nf.format(dt.ytd_balance * multiplication) : dt.ytd_balance) : '0' || '';
              dataOriginal[headPeriod[i]] = dt.ytd_balance ? dt.ytd_balance : '0' || '';
            } else {
              const dtAcc = dt.result.find((pn) => per === `${pn.year}-${getMonth(pn.month)}`);
              dataModified[headPeriod[i]] = dtAcc ? (Math.sign(dtAcc?.balance) !== 0 ? nf.format(dtAcc?.balance * multiplication) : dtAcc?.balance || '0') : '0';
              dataOriginal[headPeriod[i]] = dtAcc?.balance ? dtAcc?.balance : '0' || '';
            }
          });

          const isBold = ['0', '3', '4'].includes(String(index));
          const pnlG = isBold ? '' : '';
          let accT = '';
          const titleStyle = { isBold, isTitle: true };
          const contentStyle = { isBold, isTitle: false };
          const eoAT = { text: '', obj: {} };

          if (index === 3) {
            eoAT.text = 'border-bottom: solid black';
            eoAT.obj = { borderBottom: 'solid black' };
          }
          if (index === 0) {
            accT = 'Total Equity';
          }

          reports.push(
            // eslint-disable-next-line prettier/prettier, max-len
            generateLine(
              genTd(pnlG, eoAT.text, contentStyle),
              genTd(accT, eoAT.text, titleStyle),
              accT,
              ['3', '4'].includes(String(index)) ? genTd(dt.name, eoAT.text, titleStyle) : genTd(dt.name, eoAT.text, { isBold: false, isTitle: true }),
              { ...dataModified },
              { style: eoAT.obj }
            )
          );
          realReports.push(dataOriginal);
          if (index === dataReportTotal.length - 1) {
            dataControls.liabilitiesEq = dataOriginal;
          }
        });
        const controlData = [];
        const controlDataTb = {};
        headPeriod.forEach((per, i) => {
          const a = Math.abs(dataControls.assets[per]);
          const b = Math.abs(dataControls.liabilitiesEq[per]);
          const val = a === b ? '0' : a - b;
          controlData.push(val);
          controlDataTb[per] = val;
        });
        isShowControlWarning = controlData.every((c) => +c === 0);

        if (!isShowControlWarning) {
          reports.push(generateLine('', '', '', genTd('Control', '', { isBold: false, isTitle: true }), controlDataTb));
          realReports.push(emptyPeriod);
        }
      }

      const columns = [
        {
          field: 'aPnlGroup',
          headerName: 'Account Group',
          headerAlign: 'center',
          align: 'left',
          minWidth: 144,
        },
        {
          field: 'bAccType',
          headerName: 'Account Type',
          headerAlign: 'left',
          align: 'left',
          minWidth: 256,
        },
        {
          field: 'dPnlCategory',
          headerName: 'Name',
          headerAlign: 'left',
          align: 'left',
          minWidth: 256,
        },
      ];
      headPeriod.forEach((per) => {
        columns.push({
          field: per,
          headerName: per,
          headerAlign: 'center',
          align: 'right',
          minWidth: 144,
          renderCell: (params) => (params.row[per] ? nf.format(params.row[per]) : ''),
        });
      });
      if (reports.length && columns.length) {
        setDataTableView({
          columns,
          reports,
          realReports,
        });
        setWarningControl(!isShowControlWarning);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message || 'Error', { variant: 'error' });
      setDataTableView(false);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 0,
  });

  const onChangeFinancialYear = async (value) => {
    if (value) {
      setSelectedFinancialYears(value);
    }
  };
  const onReportModeChange = (_, newAlignment) => {
    setReportMode(newAlignment);
  };
  const onFilter = (data) => {
    if (data.costCenters) {
      setSelectedCostCenters(data.costCenters);
    }
  };

  return (
    <div>
      <BZHelmet title="Balance Sheet" description="bz publish balance sheet report" />
      <div className="w-full flex p-4 shadow-md">
        <h1 className="text-lg font-bold">Balance Sheet Reports</h1>
      </div>
      {isLoaded && (
        <Box className="w-full h-full p-8 relative space-y-8">
          <div className="flex gap-4 flex-wrap">
            <FormControl size="small">
              <InputLabel id="select-account">Financial Year</InputLabel>
              <Select
                sx={{ p: 0.5, border: 0, width: 112 }}
                labelId="select-financial-year"
                id="select-financial-year"
                name="financialYear"
                value={selectedFinancialYears}
                label="Financial year"
                placeholder="Financial year"
                onChange={(event) => {
                  onChangeFinancialYear(event.target.value);
                }}
                className="h-12"
              >
                <MenuItem value="">
                  <em>year</em>
                </MenuItem>
                {fyData?.data?.results?.map((acc) => (
                  <MenuItem key={acc.id} value={acc.id}>
                    {acc.year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-account">Cost Centers</InputLabel>
              <Select
                multiple
                sx={{ p: 0.5, border: 0, minWidth: 256 }}
                labelId="select-cost-center"
                id="select-cost-center"
                name="costCenter"
                value={selectedCostCenters}
                label="Cost Centers"
                placeholder="Cost Centers"
                onChange={(event) => {
                  setSelectedCostCenters(event.target.value);
                }}
                renderValue={(selected) => {
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} className="text-xs" label={ccData?.data?.results.filter((cc) => cc.id === value)[0].code} />
                      ))}
                    </Box>
                  );
                }}
                className="h-12"
              >
                <MenuItem value="">
                  <em>cost center</em>
                </MenuItem>
                {ccData?.data?.results?.map((acc) => (
                  <MenuItem key={acc.id} value={acc.id}>
                    {acc.code} - {acc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-ledger">Ledger</InputLabel>
              <Select
                sx={{ p: 0.5, border: 0, minWidth: 256 }}
                labelId="select-ledger-type"
                id="select-ledger-type"
                name="ledgerType"
                value={selectedLedgerType}
                label="Ledger Type"
                placeholder="Ledger Type"
                onChange={(event) => {
                  setSelectedLedgerType(event.target.value);
                }}
                className="h-12"
              >
                <MenuItem value="">
                  <em>type</em>
                </MenuItem>
                {ledgersData?.data?.results?.map((led) => (
                  <MenuItem key={led.id} value={led.id}>
                    {led.code} - {led.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ToggleButtonGroup value={reportMode} exclusive onChange={onReportModeChange} aria-label="Platform">
              <ToggleButton value="Accumulative" sx={sxToggle}>
                Accumulative
              </ToggleButton>
              <ToggleButton value="Movement" sx={sxToggle}>
                Movement
              </ToggleButton>
            </ToggleButtonGroup>
            <div className="flex items-center space-x-4">
              {selectedLedgerType && (
                <button
                  type="button"
                  className="flex h-full gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                  style={{ backgroundColor: '#2C6D47' }}
                  onClick={refetch}
                >
                  <p className="text-white">Generate Report</p>
                </button>
              )}
            </div>
          </div>
          {isFetching ? (
            <div className="w-auto absolute top-1/2 left-1/2">
              <CircularProgress size={48} className={!isFetching ? 'opacity-0' : ''} />
              <p className={[isFetching && 'animate-bounce', 'mt-4 -ml-4'].join(' ')}> {isFetching ? 'please wait' : 'no data found'}</p>
            </div>
          ) : (
            <div />
          )}

          {!isFetching && dataTableView && (
            <div className="w-full h-full flex justify-center">
              <table className={styles.pnlTable}>
                {/* <thead> */}
                <tbody>
                  <tr>
                    {dataTableView.columns.map((th) => (
                      <th key={th.headerName} style={{ borderBottom: 'solid', textAlign: 'center', fontSize: 14 }}>
                        {th.headerName}
                      </th>
                    ))}
                  </tr>
                  {/* </thead> */}
                  {/* <tbody> */}
                  {dataTableView.reports
                    .filter((_, index) => {
                      const realValue = dataTableView.realReports[index];
                      let isValidAllValue = index > 0 && Object.values(realValue).every((v) => +v === 0);
                      if (isValidAllValue && Object.values(realValue).every((v) => v === '')) {
                        isValidAllValue = false;
                      }
                      if (!isValidAllValue) return true;
                    })
                    .map((tr, index) => {
                      const col = dataTableView.columns;
                      return (
                        <>
                          <tr key={tr.id} className={styles.pntTblTr}>
                            {col.map((c, i) => {
                              let td = <td key={`${tr.id + c.field + i}`}>&nbsp;</td>;
                              const data = tr[c.field];
                              if (i <= 1 && data) {
                                td = parse(data);
                              } else if (i === 2 && data) {
                                td = parse(data);
                              } else {
                                let tdHtml = data;
                                if (tdHtml?.toString().includes('<b>')) {
                                  td = parse(data);
                                } else {
                                  const genTdHtml = (html, style = {}) => (
                                    <td key={`${tr.id + c.field + i}`} style={{ textAlign: 'right', fontSize: 14, ...style }}>
                                      {html}
                                    </td>
                                  );

                                  if (data) {
                                    let tdStyle = {};
                                    if (tr.style) {
                                      tdStyle = { ...tr.style.style };
                                    }
                                    const isNumber = typeof data === 'number';
                                    tdHtml = isNumber ? nf.format(data) === "-0" ? 0 : nf.format(data) : data;
                                    const isNeg = Math.sign(data);
                                    if ((isNumber && isNeg === -1 && tdHtml !== 0) || (!isNumber && data.includes('-') && !data.toString().includes('</td>'))) {
                                      const newStyle = Object.keys(tdStyle).length ? { color: 'red', ...tdStyle } : { color: 'red' };
                                      td = genTdHtml(tdHtml, newStyle);
                                    } else if (!isNumber && data.toString().includes('</td>')) {
                                      td = parse(data);
                                    } else {
                                      td = genTdHtml(tdHtml, tdStyle);
                                    }
                                  } else {
                                    td = genTdHtml('');
                                  }
                                }
                              }
                              return td;
                            })}
                          </tr>

                          {index === dataTableView.reports?.length - 1 && warningControl && <tr>{parse(generateWarning(accPer?.data.count || 0))}</tr>}
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
          {!isFetching && !dataTableView && <p className="w-full text-center py-4">No data</p>}
        </Box>
      )}
    </div>
  );
}

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable */

import * as React from 'react';
import PropTypes from 'prop-types';
import { InputBase, Box, ButtonBase, Button, CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Search, DeleteForever, Edit, ArrowBack, Visibility } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { handleModal } from '../../../store/slices/modalSlice';
import ArticleCategoryForm from '../../../components/Form/Article/ArticleCategoryForm';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import BaseBackButton from '../../../components/Button/BaseBackButton';
import { Link } from 'react-router-dom';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'whistleblower_name',
    numeric: false,
    disablePadding: true,
    label: 'Reporter',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Allegation',
  },
  {
    id: 'first_name',
    numeric: false,
    disablePadding: false,
    label: 'Reported Employees',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Reported Date',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    headerAlign: 'center',
    label: 'Action',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.headerAlign || (headCell.numeric ? 'right' : 'left')}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function ActionCell(props) {
  const { onClick, id } = props;
  const dispatch = useDispatch();

  return (
    <div className="w-full flex space-x-2 justify-center">
      <Link to={`/hr/whistleblower/report/${id}`} className="bz-btn-tb">
        <Visibility />
      </Link>
      {/* <ButtonBase className="bz-btn-tb" onClick={onClick}>
          <Delete />
        </ButtonBase> */}
    </div>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function EnhancedTable({ dataApi, isDataLoad, setIsDataLoad }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    if (isDataLoad) {
      setTableData(dataApi);
    }
  }, [dataApi, isDataLoad]);

  const onSearchCategory = (event) => {
    setPage(0);
    const val = event.target.value.toLowerCase();
    if (val) {
      const article = dataApi.filter((t) => {
        if (t.name.toLowerCase().includes(val) || t.id.toString().includes(val)) {
          return t;
        }
        return false;
      });
      if (article.length) {
        setTableData(article.slice(0, rowsPerPage));
      } else {
        setTableData([]);
      }
    } else {
      setTableData(dataApi);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty tableData?.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData?.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <div className="">
        <div className="bg-gray-100 w-full flex py-5 px-3  rounded items-center">
          <div className="my-3 flex gap-x-3  w-fit">
            <BaseBackButton />
            <InputBase
              size="small"
              placeholder="search"
              endAdornment={<Search color="disabled" />}
              onChange={onSearchCategory}
              className="border bg-white border-gray-300 rounded px-4"
            />
          </div>

          {/* <div className="ml-auto flex gap-x-3">
            <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addArtCategory' }))} className="bg-blue-600 text-white" type="button" variant="filled">
              ADD Category
            </Button>
          </div> */}
        </div>
      </div>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableData?.length}
            />{' '}
            {tableData?.length && tableData?.length > 0 ? (
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 tableData?.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(tableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover tabIndex={-1} key={row.allegation + row.id}>
                        <TableCell component="th" id={labelId} scope="row" padding="normal">
                          {row.whistleblower_name}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="normal">
                          {row.allegation}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="normal">
                          <div className="grid grid-cols-2">
                            {row?.reported_names?.map((reported) => (
                              <div key={reported} className="">
                                {reported}
                              </div>
                            ))}
                          </div>
                        </TableCell>

                        <TableCell align="left">
                          <div className="">{moment(row.timestamp).format('dddd, MMMM DD')}</div>
                        </TableCell>
                        <TableCell align="left">
                          <ActionCell id={row.id} onClick={() => removeCategory(row.id)} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody className="relative h-[10vh]">
                {!isDataLoad ? (
                  <CircularProgress />
                ) : (
                  <tr className=" absolute inset-0  flex items-center justify-center text-lg font-bold">
                    <td>No Results</td>
                  </tr>
                )}{' '}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 20, 25]}
          component="div"
          count={tableData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

/* eslint-disable */

import { Search } from '@mui/icons-material';
import { InputBase } from '@mui/material';
import React from 'react';

export default function BaseSearchButton({ setPage, setIsDataLoad, onChange, keyPress, fullWidth, defaultValue, label, handlePersistSearch }) {
  const handleApply = () => {
    setPage(1);
    setIsDataLoad(false);
    setTimeout(() => {
      setIsDataLoad(true);
    }, 500);
  };
  return (
    <div className={` bg-white ${fullWidth ? 'w-full' : ''} `}>
      {label && <div className="mb-[10px] text-[14px] font-[600] leading-[20px] capitalize">{label}</div>}
      <InputBase
        size="small"
        // placeholder="search"
        endAdornment={<Search color="disabled" />}
        defaultValue={defaultValue}
        onChange={(e) => {
          onChange(e);
          Boolean(handlePersistSearch) && handlePersistSearch(e);
        }}
        className={`border border-gray-300 rounded-[11px] px-4 bg-white h-[48px] ${fullWidth ? 'w-full' : ''}`}
        inputProps={{
          onKeyPress: (event) => {
            if (event.key === 'Enter') {
              keyPress || handleApply();
            }
          },
        }}
      />
    </div>
  );
}

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import BZHelmet from '@/utils/BZHelmet';
import BaseMainTitle from '@/components/BaseMainTitle';
import _ from 'lodash';
import POSApi from '@/api/pos/posApi';
import OutletDetailSettingsTab from './OutletDetailSettingsTab';
import OutletDetailDashboardTab from './OutletDetailDashboardTab';
import OutletDetailSalesTab from './OutletDetailSalesTab';
import OutletDetailCustomersTab from './OutletDetailCustomersTab';
import OutletDetailShiftReportTab from './OutletDetailShiftReportTab';

function OutletDetail() {
  const navigate = useNavigate();
  const { outletId } = useParams();

  const [pageTab, setPageTab] = useState(0);
  const [outletName, setOutletName] = useState('');

  // ALL BEHAVIORS
  const [mode, setMode] = useState('');
  useEffect(() => {
    if (outletId === undefined) {
      setMode('add');
    } else {
      setMode('edit');
    }
  }, [outletId]);

  const OutletDetail = useCallback(async () => {
    const { getOutletDetail } = POSApi();

    try {
      const getData = await getOutletDetail(outletId);
      const data = await getData.data;

      setOutletName(data?.name);
    } catch (error) {
      console.error(error);
    }
  }, [outletId]);

  useEffect(() => {
    OutletDetail();
  }, [outletId]);

  const TabButton = ({ val, name }) => {
    return (
      <button
        className={`flex h-[40px] gap-x-2 items-center text-center btn btn-primary border px-4 ${pageTab === val ? 'bg-[#2C6D47] text-white' : ' text-[#2C6D47] border-black'}`}
        type="text"
        name="Dashboard"
        onClick={() => setPageTab(val)}
      >
        {name}
      </button>
    );
  };

  return (
    <div className="w-full h-full">
      <BZHelmet title={mode === 'add' ? 'Add Outlet' : 'Outlet Detail'} description="" content="" />
      <BaseMainTitle title={mode === 'add' ? 'Add Outlet' : 'Outlet Detail'} />
      <div className="p-5 space-y-6">
        <div className="flex gap-4 items-center">
          <button
            className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
            type="text"
            name="Back to list"
            onClick={() => navigate('/pos/outlets')}
          >
            <ArrowBack />
            Back To List
          </button>
          <h1 className="font-semibold text-2xl">{outletName}</h1>
        </div>
        {mode === 'edit' && outletId !== undefined ? (
          <>
            <div className="flex space-x-1">
              <TabButton val={0} name="Dashboard" />
              <TabButton val={1} name="Shift Reports" />
              <TabButton val={2} name="Sales" />
              <TabButton val={3} name="Customers" />
              <TabButton val={4} name="Settings" />
            </div>
            {pageTab === 0 && <OutletDetailDashboardTab outletName={outletName} />}
            {pageTab === 1 && <OutletDetailShiftReportTab />}
            {pageTab === 2 && <OutletDetailSalesTab outletName={outletName} />}
            {pageTab === 3 && <OutletDetailCustomersTab />}
            {pageTab === 4 && <OutletDetailSettingsTab />}
          </>
        ) : (
          <OutletDetailSettingsTab />
        )}
      </div>
    </div>
  );
}

export default OutletDetail;

/* eslint-disable */

import React, { useState, useCallback } from 'react';
import update from 'immutability-helper';
import { Card } from './Card';
const style = {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '10px',
};

const styleMedia = {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(8, 1fr)',
  gap: '10px',
};
const ContentOrder = (props) => {
  const [cards, setCards] = useState(props.data);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards],
    props.orderChanges(cards)
  );

  // console.log(props);

  function getElmType(card, index) {
    const elmType = props.elmType;
    let switchCard;
    if (elmType === 'article') {
      return (switchCard = <Card key={card.id} index={index} id={card.id} text={card.article.name} moveCard={moveCard} />);
    }
    if (elmType === 'text') {
      return (switchCard = <Card key={card.id} index={index} id={card.id} text={card.name} moveCard={moveCard} />);
    }
    if (elmType === 'media') {
      return (switchCard = (
        <Card key={card.id} index={index} id={card.id} text={card.name} moveCard={moveCard} image={card.asset.thumbnail_file || card.asset.file_small || card.asset.asset_file} />
      ));
    }
    return switchCard;
  }

  const renderCard = (card, index) => {
    return getElmType(card, index);
  };
  return (
    <>
      <div style={props.elmType === 'media' ? styleMedia : style}>{cards.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};

export default ContentOrder;

import qs from 'qs';
import axiosInstances from '../../helper/axiosInstances';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const baseURL = import.meta.env.VITE_API_URL;

export default function ProductManagementApi() {
  const { axiosInstance, axiosInstanceF } = axiosInstances();

  async function getProductList(params) {
    const method = 'get';
    const url = `${baseURL}product-management/products/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getProductDetail(id) {
    const method = 'get';
    const url = `${baseURL}product-management/products/${id}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function addNewProduct(data) {
    const method = 'post';
    const url = `${baseURL}product-management/products/`;
    const response = await axiosInstanceF({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateProductDetail(id, data) {
    const method = 'put';
    const url = `${baseURL}product-management/products/${id}/`;
    const response = await axiosInstance({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteProductFiles(id, data) {
    const method = 'post';
    const url = `${baseURL}product-management/products/${id}/bulk_delete_files/`;
    const response = await axiosInstance({
      method,
      url,
      data: {
        file_ids: data,
      },
    });
    return response;
  }

  async function getCategories(params) {
    const method = 'get';
    const url = `${baseURL}product-management/categories/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getProductUnit(params) {
    const method = 'get';
    const url = `${baseURL}product-management/units/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getCollections(params) {
    const method = 'get';
    const url = `${baseURL}product-management/collections/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addNewCollection(data) {
    const method = 'post';
    const url = `${baseURL}product-management/collections/`;
    const response = await axiosInstance({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method,
      url,
      data,
    });
    return response;
  }

  async function updateCollectionDetail(id, data) {
    const method = 'put';
    const url = `${baseURL}product-management/collections/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteOneCollection(id) {
    const method = 'delete';
    const url = `${baseURL}product-management/collections/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function bulkDeleteCollections(data) {
    const method = 'post';
    const url = `${baseURL}product-management/collections/bulk_delete/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getCollectionDetail(id) {
    const method = 'get';
    const url = `${baseURL}product-management/collections/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getTypes() {
    const method = 'get';
    const url = `${baseURL}product-management/types/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getAllCategories(params) {
    const method = 'get';
    const url = `${baseURL}product-management/categories/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getCategoryDetail(id) {
    const method = 'get';
    const url = `${baseURL}product-management/categories/${id}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function deleteOnecategory(id) {
    const method = 'delete';
    const url = `${baseURL}product-management/categories/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function addNewCategory(data) {
    const method = 'post';
    const url = `${baseURL}product-management/categories/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateCategory(id, data) {
    const method = 'put';
    const url = `${baseURL}product-management/categories/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function exportProductListToExcel(params) {
    const method = 'get';
    const url = `${baseURL}product-management/products/download-excel/`;
    try {
      const response = await axiosInstance({
        method,
        url,
        params,
        responseType: 'arraybuffer', // Ensure the response is treated as a binary file
      });

      // Convert the response data to a workbook
      const data = new Uint8Array(response.data);
      const workbook = XLSX.read(data, { type: 'array' });

      // Convert the workbook to a binary string
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Create a Blob from the binary string
      const blob = new Blob([wbout], { type: 'application/octet-stream' });

      // Create a dynamic file name
      const date = new Date();
      const formattedDate = date.toISOString().split('T')[0];
      const fileName = `ProductList_${formattedDate}.xlsx`;

      // Save the file with the dynamic name
      saveAs(blob, fileName);
    } catch (error) {
      console.error('Error exporting product list to Excel:', error);
    }
  }

  async function getMarketManagers(params) {
    const method = 'get';
    const url = `${baseURL}product-management/market-managers/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addNewMarketManager(data) {
    const method = 'post';
    const url = `${baseURL}product-management/market-managers/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateMarketManager(id, data) {
    const method = 'put';
    const url = `${baseURL}product-management/market-managers/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteOneMarketManager(id) {
    const method = 'delete';
    const url = `${baseURL}product-management/market-managers/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function bulkDeleteMarketManager(data) {
    const method = 'post';
    const url = `${baseURL}product-management/market-managers/bulk-delete/`;
    const response = await axiosInstance({
      method,
      url,
      data
    });
    return response;
  }

  async function getVatProfiles(params) {
    const method = 'get';
    const url = `${baseURL}product-management/vat-profiles/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function updateVatProfileName(id, data) {
    const method = 'patch';
    const url = `${baseURL}product-management/vat-profiles/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function addNewVatProfile(data) {
    const method = 'post';
    const url = `${baseURL}product-management/vat-profiles/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function bulkDeleteVatProfiles(data) {
    const method = 'post';
    const url = `${baseURL}product-management/vat-profiles/bulk-delete/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getVatRates(params) {
    const method = 'get';
    const url = `${baseURL}product-management/vat-rates/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function updateVatRatesById(id, data) {
    const method = 'patch';
    const url = `${baseURL}product-management/vat-rates/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function bulkProcessVatRates(data) {
    const method = 'post';
    const url = `${baseURL}product-management/vat-rates/bulk-process/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function bulkDeleteVatRates(data) {
    const method = 'post';
    const url = `${baseURL}product-management/vat-rates/bulk-delete/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getProductCategoryVat(params) {
    const method = 'get';
    const url = `${baseURL}product-management/product-category-vat/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addNewProductCategoryVat(data) {
    const method = 'post';
    const url = `${baseURL}product-management/product-category-vat/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateProductCategoryVat(id, data) {
    const method = 'patch';
    const url = `${baseURL}product-management/product-category-vat/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getProductPricingManagers(params) {
    const method = 'get';
    const url = `${baseURL}product-management/pricing-managers/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function updateOnePricingManagers(id, data) {
    const method = 'put';
    const url = `${baseURL}product-management/pricing-managers/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function bulkProcessPricingManagers(data) {
    const method = 'post';
    const url = `${baseURL}product-management/pricing-managers/bulk-process/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function bulkDeletePricingManagers(data) {
    const method = 'delete';
    const url = `${baseURL}product-management/pricing-managers/bulk-delete/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getCostBreakDown(params) {
    const method = 'get';
    const url = `${baseURL}product-management/pricing-managers/get-cost-breakdown/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getPricingProjection(params) {
    const method = 'get';
    const url = `${baseURL}product-management/pricing-managers/get-projection/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getPricingManagerAddOns(params) {
    const method = 'get';
    const url = `${baseURL}product-management/pricing_manager_add_ons/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function BulkProcessPricingManagerAddOns(data) {
    const method = 'post';
    const url = `${baseURL}product-management/pricing_manager_add_ons/bulk-process/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  return {
    getProductList,
    getProductDetail,
    addNewProduct,
    updateProductDetail,
    deleteProductFiles,
    getCategories,
    getProductUnit,
    getCollections,
    deleteOneCollection,
    bulkDeleteCollections,
    getCollectionDetail,
    addNewCollection,
    updateCollectionDetail,
    getTypes,
    getAllCategories,
    getCategoryDetail,
    deleteOnecategory,
    addNewCategory,
    updateCategory,
    exportProductListToExcel,
    getMarketManagers,
    addNewMarketManager,
    updateMarketManager,
    deleteOneMarketManager,
    bulkDeleteMarketManager,
    getVatProfiles,
    updateVatProfileName,
    addNewVatProfile,
    bulkDeleteVatProfiles,
    getVatRates,
    updateVatRatesById,
    bulkProcessVatRates,
    bulkDeleteVatRates,
    getProductCategoryVat,
    addNewProductCategoryVat,
    updateProductCategoryVat,
    getProductPricingManagers,
    updateOnePricingManagers,
    bulkProcessPricingManagers,
    bulkDeletePricingManagers,
    getCostBreakDown,
    getPricingProjection,
    getPricingManagerAddOns,
    BulkProcessPricingManagerAddOns,
  };
}

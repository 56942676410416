// /* eslint-disable */

import { DevTool } from '@hookform/devtools';
import { Add, Clear } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import request from '../../../helper/axiosUtil';
import useDelete from '../../../hooks/useDelete';
import useGet from '../../../hooks/useGet';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
import ReactSelectEditable from '../../Input/ReactSelectEditable';
import usePost from '../../../hooks/usePost';

export default function FieldArrKey({ index, defaultValues, language, resIdArticle, setResIdArticle }) {
  const clientId = useSelector((state) => state.client.activeClient);
  const { data: listKeywords } = useGet({ url: `keyword_articles/?client=${clientId}&max_size=true`, validate: 'articleKeywords' });

  const transKey = defaultValues?.article_keywords?.map((item) => ({
    pairKeywordId: item?.id,
    keyword: item?.keyword?.id,
    article: item?.article,
    language: item?.language?.id,
  }));

  const groupedArray = transKey?.reduce((acc, cur) => {
    const key = cur.language;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(cur);
    return acc;
  }, {});

  const { control, watch } = useForm({ defaultValues: { keyword: groupedArray } });
  const { fields, append, remove } = useFieldArray({
    control,
    name: `keyword.${index}`,
    shouldUnregister: false,
  });

  const watchingKeyword = useWatch({
    name: `keyword.${index}`,
    control,
  });

  const removeKeyword = useDelete({
    confText: `modals.keyword_delete.confirmation_text`,
    confTitle: `modals.keyword_delete.title`,
    success: 'Keyword deleted',
    validate: ['articleKeywords', 'article'],
    extra: (e) => remove(e),
  });

  const handleRemoveKeyword = (data, idx) => {
    removeKeyword(data, idx);
  };

  const { mutate: addArtKeyword } = usePost({ url: 'article_keywords', validate: ['article', 'articleKeywords'] });
  // useAddArtKeyword();

  const useUpdatekey = useMutation((keys) => {
    return Promise.allSettled(
      keys.map((key) =>
        request({
          url: `/article_keywords/${key.pairKeywordId}/`,
          method: 'patch',
          data: { keyword: key.keyword },
        })
      )
    );
  });

  useEffect(() => {
    if (resIdArticle) {
      // Post pairingKeyword
      const postData = watchingKeyword?.filter((key) => {
        return key.keyword && !key.pairKeywordId;
      });
      if (postData && postData.length > 0) {
        addArtKeyword(
          postData?.map((val) => ({
            keyword: val.keyword,
            language: val?.language,
            article: defaultValues?.id || resIdArticle,
          }))
        );
      }
      // Post pairingKeyword end

      // Patch pairingKeyword
      const patchData = watchingKeyword?.filter((element) => {
        return Object.prototype.hasOwnProperty.call(element, 'pairKeywordId');
      });
      if (patchData && patchData.length > 0) {
        useUpdatekey.mutateAsync(patchData);
      }
      // Patch pairingKeyword end

      setResIdArticle(false);
    }
  }, [fields, resIdArticle, defaultValues, addArtKeyword, useUpdatekey, setResIdArticle, watchingKeyword]);

  return (
    <div className="flex">
      <ul className="flex flex-wrap items-center gap-x-10 gap-y-3">
        {fields?.map((item, idx) => (
          <li className="flex relative w-[300px] " key={item.id}>
            <ReactSelectEditable
              name={`keyword.${index}.${idx}.keyword`}
              control={control}
              options={getOptionSelectWithKey(listKeywords?.data?.results)}
              defaultValue={getDefaultSelectObject(watch(`keyword.${index}.${idx}.keyword`) || item?.keyword?.id, getOptionSelectWithKey(listKeywords?.data?.results))}
              // placeholder="Keyword"
            />

            {item.pairKeywordId ? (
              <button
                title="Delete Keyword"
                className="absolute bg-white -right-7  translate-y-1.5 z-10"
                type="button"
                onClick={() => handleRemoveKeyword(`article_keywords/${item?.pairKeywordId}`, idx)}
              >
                <Clear className="border-2 rounded-full text-red-400" />
              </button>
            ) : (
              <button title="Delete Keyword" className="absolute bg-white -right-7  translate-y-1.5 z-10" type="button" onClick={() => remove(idx)}>
                <Clear className="border-2 rounded-full text-red-400" />
              </button>
            )}
            {fields.length - 1 === idx && (
              <button className="absolute -right-14 top-1.5 w-fit" type="button" title="Add Keyword" onClick={() => append({ language, keyword: '' })}>
                <Add className="ml-5 rounded border-2 text-blue-400" />
              </button>
            )}
          </li>
        ))}
      </ul>
      {fields?.length === 0 && (
        <button className="pl-3" type="button" title="Add Keyword" onClick={() => append({ language, keyword: '' })}>
          <Add className="ml-5 rounded border-2 text-blue-400" />
        </button>
      )}
      <DevTool control={control} />
    </div>
  );
}

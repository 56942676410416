import { Button } from '@mui/material';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function BaseSubmitButton({ onClick, type = 'button', text = 'SUBMIT', disabled = true, size = 'medium', defaultValue = '', className="" }) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={twMerge("bz-btn secondary", className)}
      type={type}
      size={size}
      defaultValue={defaultValue}
    >
      {text}
    </Button>
  );
}

// /* eslint-disable */

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getUsers } from '../../store/slices/usersTeamSlice';
import { fetchUsers } from '../../hooks/useUsersData';
import TableUser from './TableUser';
import BaseMainTitle from '../../components/BaseMainTitle';
import BZHelmet from '../../utils/BZHelmet';

export default function User() {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);
  // const user = useSelector((state) => state.users);
  const [dataUser, setDataUser] = useState([]);
  const [isUsersLoad, setIsUsersLoad] = useState(false);
  useQuery(['users', { clientId }], fetchUsers, {
    onSuccess: (e) => {
      setDataUser(e?.data?.results);
      setIsUsersLoad(true);
    },
  });

  const data = useMemo(() => dataUser, [dataUser]);

  useEffect(() => {
    dispatch(getUsers(clientId));
  }, [dispatch, clientId]);
  return (
    <div>
      <BZHelmet title="Users" description="bz publish user list" />

      <BaseMainTitle title="User List" />
      <div className="p-5">
        <TableUser usersDataApi={data} isUsersLoad={isUsersLoad} setIsUsersLoad={setIsUsersLoad} />
      </div>
    </div>
  );
}

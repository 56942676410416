/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const cerrencyState = JSON.parse(localStorage.getItem('currency'));
const initialState = {
  source: cerrencyState?.source || { name: 'KP Rates', id: 'KP' },
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrSource: (state, action) => {
      const currState = state;
      const newPayload = action.payload;
      currState.source = newPayload;
      localStorage.setItem(JSON.stringify({ currency: { source: newPayload } }));
    },
  },
});

export const { setCurrSource } = currencySlice.actions;

export default currencySlice.reducer;

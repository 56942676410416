import useGet from '@/hooks/useGet';
import usePatchOrPutExtra from '@/hooks/usePatchOrPutExtra';
import usePostExtra from '@/hooks/usePostExtra';
import { Add } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { BiLogoTailwindCss } from 'react-icons/bi';
import { BsFiletypeJson } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { IoHelpBuoy, IoSettings, IoSettingsOutline } from 'react-icons/io5';
import { useParams } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TemplateForm from './TemplateForm';
import TemplateContext from './TemplateContext';
const MySwal = withReactContent(Swal);

function postFilesTemplateNew(dataTemplate) {
  const data = new FormData();

  if (dataTemplate?.context_zip_file) {
    data.append('context_zip_file', dataTemplate?.context_zip_file);
  }
  if (dataTemplate?.env_preview_file) {
    data.append('env_preview_file', dataTemplate?.env_preview_file);
  }
  if (dataTemplate?.env_publish_file) {
    data.append('env_publish_file', dataTemplate?.env_publish_file);
  }
  if (dataTemplate?.package_json_file) {
    data.append('package_json_file', dataTemplate?.package_json_file);
  }
  if (dataTemplate?.tailwind_config_file) {
    data.append('tailwind_config_file', dataTemplate?.tailwind_config_file);
  }

  return data;
}

function fetchTemplate(dataTemplate) {
  const data = new FormData();

  data.append('site', dataTemplate.site);
  data.append('name', dataTemplate.name);
  data.append('ga_code', dataTemplate.ga_code);
  data.append('use_tailwind', dataTemplate.use_tailwind);

  return data;
}

const TemplateTab = ({ templates, defaultValues }) => {
  const { clientId } = useParams();

  const [valueTab, setValueTab] = useContext(TemplateContext);
  const [isInitialize, setIsInitialize] = useState(false);
  // const [templates, setTemplates] = useState(false);
  const [files, setFiles] = useState(false);
  const [currSite, setCurrSite] = useState(false);
  const [isAllowFile, setIsAllowFile] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [modalData, setModalData] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [templateFileUpload, setTemplateFileUpload] = useState(null);

  const activeSite = defaultValues?.id;

  const { data: TempDetail } = useGet({
    url: `templates/${templates[valueTab]?.id}`,
    validate: `templates/${templates[valueTab]?.id}`,
    isEnabled: valueTab !== templates.length + 1,
  });
  console.log('🚀 ~ TempDetail ~ TempDetail:', TempDetail);

  const handleChange = (event, newValueTab) => {
    console.log('🚀 ~ handleChange ~ newValueTab:', newValueTab);
    setValueTab(newValueTab);
  };

  const { mutate: postTemplate } = usePostExtra({
    url: `templates`,
    validate: ['clientFiles', 'templates'],
    onSuccess: (res) => {
      if (res.request.status === 201) {
        setIsAllowFile(true);
      } else {
        setIsAllowFile(false);
        MySwal.fire({
          title: `Error`,
          html: `<div style='color:red'>Template Not Uploaded</div>`,
        });
        renderError(res);
      }
    },
  });

  const { mutate: postFilesTemplate } = usePostExtra({
    url: `clients/${clientId}/upload_files`,
    validate: ['clientFiles', 'templates'],
    onSuccess: (res) => {
      setTemplateFileUpload(null);
      setIsAllowFile(false);
      if (res.request.status === 200) {
        MySwal.fire({
          title: `Success`,
          html: `<div style='color:blue'>Template Uploaded</div>`,
        });
      } else {
        MySwal.fire({
          title: `Error`,
          html: `<div style='color:red'>Template Not Uploaded</div>`,
        });
      }
    },
  });

  const { mutate: patchTemplate } = usePatchOrPutExtra({
    url: `templates/${templates[valueTab]?.id}`,
    validate: ['clientFiles', 'templates'],
    onSuccess: (res) => {
      if (res.request.status === 200) {
        setIsAllowFile(true);
      } else {
        setIsAllowFile(false);
        MySwal.fire({
          title: `Error`,
          html: `<div style='color:red'>Template Not Uploaded</div>`,
        });
      }
    },
  });

  const handleActionSubmit = (mode, data) => {
    setTemplateFileUpload(data.files);
    MySwal.fire({
      title: `Uploading Template `,
      html: `<div>Please Wait</div>`,
      buttons: false,
      didOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: false,
    });
    if (templates[valueTab]?.id) {
      patchTemplate(fetchTemplate(data));
    } else postTemplate(fetchTemplate(data));
  };

  useEffect(() => {
    if (!isInitialize && activeSite) {
      setIsInitialize(true);
      setCurrSite(activeSite);
    }
  }, [isInitialize, activeSite]);

  useEffect(() => {
    if (!isInitialize && templates && currSite !== activeSite) {
      setIsInitialize(true);
      setCurrSite(activeSite);
    }
  }, [isInitialize, templates, currSite, activeSite]);

  useEffect(() => {
    if (templateFileUpload && isAllowFile) {
      postFilesTemplate(postFilesTemplateNew(templateFileUpload));
    }
  }, [templateFileUpload, isAllowFile, postFilesTemplate]);
  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={valueTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {templates?.map((st, idx) => (
                <Tab label={st.name} value={idx} />
              ))}
              <Tab icon={<Add />} iconPosition="start" label="ADD Tempplate" value={templates.length + 1} />
            </TabList>
          </Box>
          <TabPanel className="!p-0" value={valueTab}>
            {valueTab !== templates.length + 1 ? (
              <TemplateForm defaultValues={TempDetail?.data} key={TempDetail?.data?.id} action={handleActionSubmit} type={'update'} />
            ) : (
              <TemplateForm action={handleActionSubmit} />
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};
const TemplateTabBlank = ({ templates }) => {
  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={0}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList aria-label="lab API tabs example">
              <Tab icon={<Add />} iconPosition="start" label="Add Template" value={0} />
            </TabList>
          </Box>
          <TabPanel className="!p-0" value={0}>
            <TemplateForm />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default function TemplateList({ templates, defaultValues }) {
  const { clientId } = useParams();
  const [valueTab, setValueTab] = useState(0);
  const ContextTemplateValue = [valueTab, setValueTab];

  const { data: files } = useGet({
    url: `clients/${clientId}/read_files`,
    validate: 'clientFiles',
  });

  const fileIcon = {
    'package.json': <BsFiletypeJson className="w-[64px] h-[64px]" />,
    'tailwind.config.js': <BiLogoTailwindCss className="w-[64px] h-[64px]" />,
    '.env.preview': <IoSettingsOutline className="w-[64px] h-[64px]" />,
    '.env.publish': <IoSettings className="w-[64px] h-[64px]" />,
    context: <IoHelpBuoy className="w-[64px] h-[64px]" />,
  };
  return (
    <TemplateContext.Provider value={ContextTemplateValue}>
      <div className="mt-8">
        <div className="mb-4 text-lg font-semibold">Templates</div>
        <div className="mt-8">
          <div className="mb-4  font-semibold">File</div>
          <div className="grid grid-cols-3 gap-4">
            {files?.data &&
              files?.data.length > 0 &&
              files?.data.map((itm, i) => {
                const isExist = itm.content && (!Array.isArray(itm.content) || (Array.isArray(itm.content) && itm.content.length > 0));

                return (
                  <div className="flex flex-col gap-x-4 items-center justify-center relative">
                    <div className="relative">
                      <div className="absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2">
                        {!isExist ? <ImCross className="w-[24px] text-red-600 h-[24px]" /> : <FaCheck className="w-[24px] text-green-600 h-[24px]" />}
                      </div>
                      {fileIcon[itm.filename]}
                    </div>
                    <span>{itm.filename}</span>
                  </div>
                );
              })}
          </div>
        </div>
        {templates && templates.length > 0 ? (
          <div>
            <TemplateTab defaultValues={defaultValues} templates={templates} />
          </div>
        ) : (
          <div className="">
            <TemplateTabBlank />
          </div>
        )}
      </div>
    </TemplateContext.Provider>
  );
}

/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bulkDelete: [],
  bulkUnpair: [],
  selected: [],
  cropData: '',

};

const assetCrudSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    handleSelect: (state, action) => {
      const currState = state;
      const newPayload = JSON.parse(action.payload);
      currState?.selected.push(newPayload);
    },
    handleUnSelect: (state, action) => {
      const currState = state;
      const newPayload = JSON.parse(action.payload);
      const newData = currState?.selected.filter((asset) => {
        return asset.id.toString() !== newPayload.id.toString()
      })
      currState.selected = newData;
    },
    assetDelete: (state, action) => {
      const currState = state;
      currState?.bulkDelete.push(action.payload);
    },
    filterDelete: (state, action) => {
      const currState = state;
      const newData = currState?.bulkDelete.filter((asset) => asset !== action.payload);
      currState.bulkDelete = newData;
    },
    assetPair: (state, action) => {
      const currState = state;
      currState?.bulkUnpair.push(action.payload);
    },

    assetUnpair: (state, action) => {
      const currState = state;
      const newData = currState?.bulkUnpair.filter((asset) => asset !== action.payload);
      currState.bulkUnpair = newData;
    },
    resetAsset: () => {
      return {
        ...initialState
      }
    },
    assetCropData: (state, action) => {
      const currState = state;
      currState.cropData = { ...currState.cropData, ...action.payload };
    },
  },
});

export const { assetDelete, filterDelete, assetCropData, resetAsset, assetPair, assetUnpair, handleSelect, handleUnSelect } = assetCrudSlice.actions;

export default assetCrudSlice.reducer;

/* eslint-disable */
import Swal from 'sweetalert2';

export default function renderError(res) {
  const errArr = Object.keys(res.data);
  const htmlErrArr = [];
  for (let index = 0; index < errArr.length; index += 1) {
    const element = errArr[index];
    res.data[element].forEach((er) => {

      htmlErrArr.push({ field: element, content: typeof er === 'object' ? Object.entries(er) : er });
    });
  }

  // console.log(htmlErrArr);
  const htmlErr = htmlErrArr?.map((er, i) => {
    // console.log(er)
    return `<div key=${er.content} className="flex flex-col space-y-2">
    <span><b>${er.field}</b></span>
    <span>${Array.isArray(er.content) ? er.content.map((c) => c.join(' : ')) : er.content}</span>
    </div>`;
  });
  Swal.fire({ title: 'Error!', text: '', icon: 'error', html: htmlErr, confirmButtonText: 'Confirm' });
}

/* eslint-disable */

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, FormLabel, FormControl } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useParams } from 'react-router';
import InputContainer from '../../Input/InputContainer';
import SelectAutocomplete from '../../Input/SelectAutocomplete';
import { handleModal } from '../../../store/slices/modalSlice';
import useGet from '../../../hooks/useGet';

export default function CollectionForm({ defaultValues, onFormSubmit, developerMode, isWidget, isCollection }) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const [category, setCategory] = useState(null);
  const dispatch = useDispatch();

  const [valueWidget, setValueWidget] = React.useState(defaultValues?.is_internal ?? false);
  const [valueUrl, setValueUrl] = React.useState(false);

  const { data: widgetTypes } = useGet({
    url: `category_widgets`,
    validate: 'categoryWidgets',
  });

  const handleChangeWidget = (event) => {
    setValueWidget(event.target.value);
  };

  const handleChangeUrl = (event) => {
    setValueUrl(event.target.value);
  };

  const template = useSelector((state) => state.content.activeTemplate);

  const clientId = useSelector((state) => state.client.activeClient);
  const { sectionId } = useParams();

  const onSubmit = (data) => {
    onFormSubmit({ name: data.name, category: 1 ?? defaultValues?.category, client: clientId, is_internal: false, urls: [], section: sectionId ?? null });
    reset();
  };
  const options = widgetTypes?.data?.results?.map((item) => ({
    name: item?.name,
    value: item?.id,
  }));

  const devCat = defaultValues?.category;
  let categorySelect;
  if (devCat) {
    const name = options?.filter((u) => u.value === devCat);
    if (name) {
      categorySelect = name[0];
    }
  }

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  const onChangeCategory = (event) => {
    const categoryName = event.target.innerText;
    if (categoryName) {
      const categorySelected = options?.filter((u) => u.name === categoryName);
      if (categorySelected) {
        setCategory(categorySelected[0]?.value);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <InputContainer
          name="name"
          control={control}
          label="Name"
          // errors={errors.name}
        />

        {/* {categorySelect && ( */}
        <div className="">
          <SelectAutocomplete
            options={[]}
            // name="category"
            // control={control}
            disabled={developerMode ? false : true}
            placeHolder="Select Category"
            variant="outlined"
            defaultValue={{ name: 'Article', value: 1 }}
            onChange={(event) => {
              onChangeCategory(event);
            }}
          />
        </div>

        <div className="ml-auto flex gap-x-3 mt-7">
          <Button onClick={handleCancel} className="ml-auto flex" type="button" variant="outlined">
            Cancel
          </Button>
          <Button className="flex" type="submit" variant="outlined">
            <div>{defaultValues ? <div>{isWidget ? 'Update Widget' : 'Update Collection'}</div> : <div>{isWidget ? 'Add Widget' : 'Add Collection'}</div>}</div>
          </Button>
        </div>
      </div>
    </form>
  );
}

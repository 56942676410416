/* eslint-disable */
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { Controller } from 'react-hook-form';

export default function ReactSelectCreateable({
  options = [],
  defaultValue = null,
  getOptionLabel,
  getOptionValue,
  placeholder,
  isSearchable,
  errors,
  control,
  name,
  isClearable = false,
  // onCreateOption,
}) {
  return (
    <div className=" w-full">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <CreatableSelect
              className={`${errors && 'border-[1px] border-[#d32f2f] rounded-[4px]'}`}
              menuPosition="fixed"
              defaultValue={defaultValue}
              menuPlacement="auto"
              hideSelectedOptions={false}
              options={options}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onChange={(v) => onChange(v?.map((val) => val.value))}
              isSearchable={isSearchable}
              isClearable={isClearable}
              errors={Boolean(errors)}
              isDisable={false}
              placeholder={placeholder}
              renderValue={(selected) => selected.join(' ')}
              isMulti
              // onInputChange={() => console.log('create')}
            />
          );
        }}
      />

      {errors && <div className="text-[#d32f2f] text-[0.75rem] mt-[4px] border-[#d32f2f]">{errors?.message}</div>}
    </div>
  );
}

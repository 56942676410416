const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getDateFull = (date) => {
	if (!date) return '-'
	return new Intl.DateTimeFormat('en-US', {
		dateStyle: 'full',
		timeStyle: 'long',
		timeZone: tz,
		hourCycle: 'h24'
	}).format(new Date(date));
};

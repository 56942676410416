import request from '../helper/axiosUtil';

export const fetchTeams = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({ url: `/project-management/teams/?client=${clientId}&max_size=true` });
};

export const createTeams = (data) =>
  request({
    url: '/project-management/teams/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const updateTeams = async (id, data) =>
  request({
    url: `/project-management/teams/${id}/`,
    method: 'patch',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const deleteTeams = async (id) =>
  request({
    url: `/project-management/teams/${id}/`,
    method: 'delete',
  });

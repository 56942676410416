import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueries, useQuery } from '@tanstack/react-query';
import { Box, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Skeleton, Stack, TextField } from '@mui/material';
import { AttachFile, HighlightOff } from '@mui/icons-material';

import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDropzone } from 'react-dropzone';

import LineDetailTransaction from './LineDetailTransaction';
import LineTransaction from '@/components/Modal/Accounting/LineTransaction';
import TransactionContext from './TransactionContext';

import BZHelmet from '@/utils/BZHelmet';
import transactions from '@/api/accounting/transactions';
import accounting from '@/api/accounting/accounting';
import report from '@/api/accounting/report';

const { getTransactionDetail, updateTransaction, postAttachmentTransaction, deleteAttachmentTransaction } = transactions();
const { getAccountCodes, getCostCenters, getCounterparts, getCurrencies, getAccountingPeriodStatus, updateLineDetail, getProjectIds, getJournalSources } = accounting();
const { getLedgers } = report();

const getBalanceAmount = (type, debt, rate, cred, decimal) => {
  const cxr = Number(cred) * Number(rate).toFixed(5);
  const dxr = Number(debt) * Number(rate).toFixed(5);
  if (type.toLowerCase() === 'balance') {
    return Number((dxr - cxr).toFixed(decimal));
  }
  return Number((cxr - dxr).toFixed(decimal));
};
const getTransactionAmount = (type, debt, cred, decimal) => {
  const cxr = Number(cred);
  const dxr = Number(debt);
  if (type.toLowerCase() === 'balance') {
    return Number(dxr - cxr);
  }
  return Number(cxr - dxr);
};

const schema = yup.object().shape({
  number: yup.string().required('Journal Number is a required field'),
  date: yup.string().required('Date is a required field'),
});

const FormError = ({ text }) => {
  return <FormHelperText className="absolute -bottom-4 text-red-600">{text}</FormHelperText>;
};

const showThumbnailFile = (fileType, file) => {
  const imgTypes = ['jpg', 'jpeg', 'png', 'webp', 'svg'];
  let html = '';
  if (fileType.includes('image') || imgTypes.includes(fileType.toLowerCase())) {
    const fileUrl = 'preview' in file ? file.preview : file.file_upload;
    html = <img className="w-12 h-6 object-contain" src={fileUrl} alt="project icon" />;
  } else {
    html = (
      <div className="bg-gray-300 bg-opacity-10 px-1">
        <span>{file.name}</span>
      </div>
    );
  }
  return html;
};

export default function TransactionDetail() {
  const { enqueueSnackbar } = useSnackbar();
  const { transactionId } = useParams();
  const navigate = useNavigate();
  const client = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const usedClient = activeUser.is_superuser ? activeClient : client;
  const activeBusinessUnit = useSelector((state) => state.acc.activeBusinessUnit);
  const baseCurr = useSelector((state) => state.acc.activeBusinessUnitDetail?.base_currency?.id);

  const [isLineTransaction, setIsLineTransaction] = useState(false);
  const [lineDetails, setLineDetails] = useState([]);
  const [filesAttachment, setFilesAttachment] = useState([]);
  const [toFixDecimal, setToFixedDecimal] = useState(0);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
    reValidateMode: 'onChange',
  });

  const queryResult = useQueries({
    queries: [
      {
        queryKey: ['accountCodes', usedClient],
        queryFn: () => getAccountCodes({ client: usedClient, max_size: true, ordering: 'code' }),
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['costCenters', usedClient, activeBusinessUnit],
        queryFn: () => getCostCenters({ business_unit: activeBusinessUnit, ordering: 'code' }),
        enabled: !!usedClient && !!activeBusinessUnit,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['counterparts', usedClient],
        queryFn: () => getCounterparts({ client: usedClient, max_size: true, ordering: 'code' }),
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['accountingPeriods', usedClient],
        queryFn: () => getAccountingPeriodStatus({ status__in: ['Open', 'Re-opened'], ledger: 1, max_size: true }),
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['currencies', usedClient],
        queryFn: () => getCurrencies({ client: usedClient, max_size: true, ordering: 'name' }),
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['projectIds', usedClient],
        queryFn: () => getProjectIds({ client: usedClient, max_size: true, ordering: 'name' }),
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['journalSources', usedClient],
        queryFn: () => getJournalSources({ client: usedClient, max_size: true, ordering: 'name' }),
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
    ],
  });

  const [a, b, c, d, e, f, g] = queryResult;
  const { data: accData } = a;
  const { data: ccData } = b;
  const { data: cpData } = c;
  const { data: acpData } = d;
  const { data: currencyData } = e;
  const { data: pIdsData } = f;
  const { data: journalSData } = g;

  const isLoaded = queryResult.every((query) => query.isSuccess);

  useQuery({
    queryKey: ['ledgers', usedClient, activeBusinessUnit],
    enabled: !!usedClient && !!activeBusinessUnit,
    queryFn: () => getLedgers({ max_size: true, business_unit: activeBusinessUnit }),
    onSuccess: (res) => {
      if (res.data.results) {
        setToFixedDecimal(res.data.results[0].business_unit.decimal_digits);
      }
    },
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
  });

  const {
    isFetched,
    isFetching,
    data: dataTransaction,
    refetch,
  } = useQuery({
    queryKey: ['balance-reports', transactionId],
    enabled: !!transactionId && !!isLoaded,
    queryFn: () => getTransactionDetail(transactionId),
    onSuccess: (res) => {
      const dt = res.data;
      setValue('number', dt.number);
      setValue('date', dt.date);
      setValue('description', dt.description);
      setValue('source', dt.source?.name);

      let lnDetails = [];
      if (dt.line_details) {
        for (const t of dt.line_details) {
          // const accPerStatus = await getAccountingPeriodStatus({ accounting_period: t.accounting_period.id, ledger: dt.ledger.id });
          // const selectedAccPerS = accPerStatus?.data.results;
          const data = {
            account: t.account,
            center: t.cost_center,
            counterpart: t.counterpart,
            recref: t.reconciliation_reference,
            accper: t.accounting_period,
            currency: t.ledger_currency,
          };
          const res = {
            transaction: t.transaction,
            rndId: dt.id,
            id: t.id,
            credit: t.credit,
            debit: t.debit,
            line: t.code,
            rate: t.base_currency_rate,
            baseCurrency: t.base_currency.id,
            balance_amount: t.base_amount,
            transaction_amount: t.transaction_amount,
            project_id: t.project_id,
            status: true,
            // isClosed: selectedAccPerS?.length ? selectedAccPerS[0].status === 'Closed' : false,
            isClosed: 'accper_status' in t && t?.accper_status?.toLowerCase() === 'closed',
            data,
          };
          lnDetails.push(res);
        }
      }
      setLineDetails(lnDetails);
      setFilesAttachment(dt.supporting_documents);
    },
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 0,
  });

  const attachmentActions = (id, files, trans) => {
    return new Promise((resolve, reject) => {
      const actionRequest = id ? deleteAttachmentTransaction(id) : postAttachmentTransaction(files, trans);
      actionRequest
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const dt = dataTransaction?.data;
  const sumDebit = lineDetails.reduce((a, b) => a + parseFloat(b.debit), 0);
  const sumCredit = lineDetails.reduce((a, b) => a + parseFloat(b.credit), 0);
  const isLineDetailPassed = lineDetails && lineDetails.every((stat) => stat.status);
  const isLineDetailCode = lineDetails && lineDetails.every((stat) => !!stat.line);

  const aa = isLineDetailPassed && sumCredit === sumDebit;
  const bb = isLineDetailCode;

  let isSubmit;
  if (!aa || !bb) {
    isSubmit = true;
  } else if (aa && bb) {
    isSubmit = false;
  }

  const context = useMemo(
    () => ({
      accounts: accData?.data?.results,
      costCenters: ccData?.data?.results,
      counterparts: cpData?.data?.results,
      accPeriods: acpData?.data?.results,
      currencies: currencyData?.data?.results,
      baseCurr,
      projectIds: pIdsData?.data?.results,
    }),
    [accData, ccData, cpData, acpData, currencyData, baseCurr, pIdsData]
  );

  const { getRootProps, getInputProps } = useDropzone({
    // accept: '*',
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFilesAttachment((prevState) => {
        return [...prevState].concat(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
      });
    },
  });

  const handleGenerateLine = (lnTransaction) => {
    const { line, data, id, rate, status } = lnTransaction;

    const currLnTransaction = [...lineDetails];
    const currLn = currLnTransaction.filter((ln) => ln.id === id);
    if (currLn.length) {
      const ln = currLn[0];
      ln.line = line;
      ln.data = data;
      ln.rate = rate || ln.rate || '';
      ln.status = status;
      ln.transaction_amount = (+currLn[0].credit + +currLn[0].debit) * (data.account?.account_type?.is_sign_reversed ? -1 : 1);

      const isBalance = data?.account?.account_type?.account_group_type?.toLowerCase() === 'balance';
      const bAmount = isBalance ? Number(ln.debit) - Number(ln.credit) : Number(ln.credit) - Number(ln.debit);
      ln.transaction_amount = bAmount;
      ln.balance_amount = bAmount * (rate || ln.rate || 1);

      const newLnTransactions = lineDetails.map((obj) => {
        if (id === obj.id) {
          return ln;
        }
        return obj;
      });
      setLineDetails(newLnTransactions);
    }
  };

  const onChangeDC = useCallback(
    (type, index, value, ln) => {
      const currLnTransaction = [...ln];
      const selectedLnTransaction = currLnTransaction[index];

      const accType =
        selectedLnTransaction.data?.account?.account_type?.account_group_type ||
        accData?.data?.results?.find((acc) => acc.id === selectedLnTransaction.data?.account?.id)?.account_type?.account_group_type;
      const accGrType = accType;

      if (currLnTransaction) {
        if (type === 'Debit') {
          selectedLnTransaction.debit = value;
          selectedLnTransaction.transaction_amount = getTransactionAmount(accGrType, value, selectedLnTransaction.credit, toFixDecimal);
          selectedLnTransaction.balance_amount = getBalanceAmount(accGrType, value, selectedLnTransaction.rate, selectedLnTransaction.credit, toFixDecimal);
          setLineDetails(currLnTransaction);
        }
        if (type === 'Credit') {
          selectedLnTransaction.credit = value;
          selectedLnTransaction.transaction_amount = getTransactionAmount(accGrType, selectedLnTransaction.debit, value, toFixDecimal);
          selectedLnTransaction.balance_amount = getBalanceAmount(accGrType, selectedLnTransaction.debit, selectedLnTransaction.rate, value, toFixDecimal);
          setLineDetails(currLnTransaction);
        }
        if (type === 'Rate') {
          selectedLnTransaction.rate = value;
          selectedLnTransaction.balance_amount = getBalanceAmount(accGrType, selectedLnTransaction.debit, value, selectedLnTransaction.credit, toFixDecimal);
          setLineDetails(currLnTransaction);
        }
      }
    },
    [accData]
  );

  const onChangeLNDetail = (index, key, value) => {
    const currLnTransaction = [...lineDetails];
    const selectedLnTransaction = currLnTransaction[index];
    if (currLnTransaction) {
      selectedLnTransaction[key] = value;
      setLineDetails(currLnTransaction);
    }
  };

  const onUpdateLineDetail = (index) => {
    if (!lineDetails[index]) return;
    const d = lineDetails[index];

    updateLineDetail(d.id, {
      transaction: d.transaction,
      account: d.data.account.id,
      cost_center: d.data.center.id,
      counterpart: d.data.counterpart?.id,
      // bank_account: null,
      accounting_period: d.data.accper?.accounting_period.id || d.data.accper?.id,
      base_currency: d.baseCurrency,
      ledger_currency: d.data.currency.id,
      code: d.line,
      debit: d.debit,
      credit: d.credit,
      base_currency_rate: d.rate,
      base_amount: d.balance_amount,
      transaction_amount: d.transaction_amount,
      project_id: d.project_id?.id,
      reconciliation_reference: d.data.recref,
      is_reconciled: !!d.data.recref,
    })
      .then((res) => {
        if (res.status === 200 || res.data) {
          enqueueSnackbar('Line transaction updated' || 'Success', { variant: 'success' });
        }
      })
      .catch((err) => {
        const er = err.response;
        if (er && Object.keys(er.data).length) {
          Object.keys(er.data).forEach((e) => {
            if (er.data[e]?.length) {
              enqueueSnackbar(`${e} - ${er.data[e]}` || `${er.status} - ${er.statusText}` || 'Error', { variant: 'error' });
            }
          });
        }
      });
  };

  const onAttachmentUpdate = async (trans) => {
    return new Promise(async (resolve, reject) => {
      if (filesAttachment.length) {
        const allActions = [];
        const deletedFilesAttachment = filesAttachment.filter((f) => f.status === 'delete');
        const newFilesAttachment = filesAttachment.filter((f) => f.status !== 'delete' && !f.id);
        if (deletedFilesAttachment.length) {
          deletedFilesAttachment.forEach((d) => {
            allActions.push(attachmentActions(d.id));
          });
        }
        if (newFilesAttachment.length) {
          newFilesAttachment.forEach((d) => {
            allActions.push(attachmentActions(null, d, { transaction: trans.id, number: trans.number }));
          });
        }
        if (allActions.length) {
          const promises = await Promise.all([]);
          Promise.allSettled(promises).then((results) => {
            const isOk = results.every((r) => r.status === 'fulfilled');
            if (!isOk) {
              reject(false);
            }
            resolve(true);
          });
        }
      }
    });
  };
  const onActionHandler = async (trans) => {
    updateTransaction(transactionId, trans)
      .then(async (res) => {
        if (res.status === 200 || res.data) {
          if (filesAttachment.length) {
            const result = await onAttachmentUpdate(res.data);
            if (result) {
              setTimeout(() => {
                // Strange behaviour, without timeout the request was not up to date
                refetch();
              }, 500);
            }
          } else {
            refetch();
          }
          enqueueSnackbar('Transaction updated' || 'Success', { variant: 'success' });
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const onAttachmentRemove = (index) => {
    const currFiles = [...filesAttachment];
    const attachment = currFiles[index];
    if ('id' in attachment) {
      attachment.status = 'delete';
    } else {
      currFiles.splice(index, 1);
    }
    setFilesAttachment(currFiles);
  };

  const fACount = filesAttachment.filter((f) => f.status !== 'delete').length;
  return (
    <TransactionContext.Provider value={context}>
      <BZHelmet title="Transaction Details" description="bz publish transaction details" />
      <div className="relative w-full h-full p-8 overflow-y-auto">
        <h1 className="text-2xl font-bold mb-8">Transaction Details</h1>

        {isFetching ||
          (!isLoaded && (
            <div className="w-full h-[calc(100vh_-_72px)] overflow-y-auto flex flex-col space-y-2 p-4">
              <Skeleton className="w-1/2 w h-12" />
              <div className="w-full flex flex-row">
                <div className="w-3/4 flex flex-col mr-8">
                  <Skeleton className="h-12" />
                  <Skeleton className="h-12" />
                  <Skeleton className="h-12" />
                </div>
                <div className="w-1/4 flex flex-col">
                  <Skeleton className="w-full h-full" />
                </div>
              </div>
            </div>
          ))}

        {isLoaded && !isFetching && isFetched && (
          <>
            <div className="w-full flex space-x-4 border p-4 border-gray-300 rounded-lg">
              <div className="flex flex-col w-full space-y-4">
                <form id="transaction-form" onSubmit={handleSubmit(onActionHandler)} className="flex flex-col w-full space-y-4">
                  <div className="flex w-full space-x-2 justify-between">
                    <FormControl className="w-1/4">
                      <Controller
                        control={control}
                        name="date"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField
                              type="date"
                              value={format(value ? new Date(value) : new Date(), 'yyyy-MM-dd')}
                              size="small"
                              label="Date"
                              InputLabelProps={{ shrink: true }}
                              onChange={onChange}
                            />
                            <FormError text={errors.date?.message} />
                          </>
                        )}
                      />
                    </FormControl>
                    <FormControl className="w-1/4">
                      <Controller
                        control={control}
                        name="number"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField value={value} size="small" label="Journal Number" InputLabelProps={{ shrink: true }} onChange={onChange} />
                            <FormError text={errors.number?.message} />
                          </>
                        )}
                      />
                    </FormControl>
                    <FormControl className="w-1/4">
                      <Controller
                        control={control}
                        name="source"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField value={value} size="small" label="Source" InputLabelProps={{ shrink: true }} disabled />
                          </>
                        )}
                      />
                    </FormControl>
                    {/* <FormControl size="small">
                      <Controller
                        control={control}
                        name="source"
                        render={({ field: { onChange, value, ref } }) => (
                          <>
                            <InputLabel id="select-journal">Source*</InputLabel>
                            <Select
                              sx={{ p: 0.5, border: 0, minWidth: 256 }}
                              labelId="select-journal-source"
                              id="select-journal-source"
                              name="journalSource"
                              inputRef={ref}
                              value={value}
                              label="Source"
                              placeholder="Source"
                              onChange={onChange}
                              error={!!errors.source?.message}
                              className="h-10"
                              required
                            >
                              {journalSData?.data.results?.map((s) => (
                                <MenuItem key={s.id} value={s.id}>
                                  {s.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        )}
                      />
                    </FormControl> */}
                    <div className="flex w-1/4">
                      <Box className="relative flex w-full justify-center items-center border rounded-lg border-gray-300">
                        <div {...getRootProps({ className: 'dropzone w-full flex justify-center' })}>
                          {fACount > 0 ? (
                            <label htmlFor="icon-button-file">
                              <input {...getInputProps()} />
                              <div className="flex space-x-2 items-center justify-center overflow-x-auto">
                                {filesAttachment.map((file, i) => {
                                  let html;
                                  if ('status' in file && file.status === 'delete') {
                                    return html;
                                  } else if (file.id) {
                                    const f = file.file_upload;
                                    const fileName = f.substring(f.lastIndexOf('/') + 1);
                                    const fileExt = f.substring(f.lastIndexOf('.') + 1);
                                    file.name = fileName;
                                    html = showThumbnailFile(fileExt, file);
                                  } else {
                                    html = showThumbnailFile(file.type, file);
                                  }
                                  return (
                                    <div key={i} className="relative p-1 border border-gray-300 rounded-md">
                                      {html}
                                      <div
                                        className="absolute h-full top-0 right-0 bg-gray-100 flex items-center"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          onAttachmentRemove(i);
                                        }}
                                      >
                                        <HighlightOff className="" style={{ color: '#2C6D47' }} fontSize="small" />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </label>
                          ) : (
                            <label htmlFor="icon-button-file" className="flex justify-center items-center">
                              <input {...getInputProps()} />
                              <IconButton color="default" aria-label="upload attachment" component="span">
                                <AttachFile />
                              </IconButton>
                              <span className="text-gray-500">Attach File</span>
                            </label>
                          )}
                          {fACount > 0 && (
                            <span className="absolute -bottom-4 right-0 text-xs text-gray-700">
                              {fACount} {fACount === 1 ? 'file' : 'files'}
                            </span>
                          )}
                        </div>
                      </Box>
                    </div>
                  </div>
                  <div className="w-full">
                    {/* <TextField className="w-full" size="small" title="Description" placeholder="Description" label="Description" defaultValue={dt.description} disabled /> */}
                    <FormControl className="w-full">
                      <Controller
                        control={control}
                        name="description"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <TextField value={value} size="small" label="Description" InputLabelProps={{ shrink: true }} onChange={onChange} />
                            <FormError text={errors.description?.message} />
                          </>
                        )}
                      />
                    </FormControl>
                  </div>
                </form>

                {lineDetails &&
                  lineDetails.length > 0 &&
                  lineDetails.map((t, i) => {
                    return (
                      <LineDetailTransaction
                        key={t.id}
                        row={t}
                        index={i}
                        baseCurr={baseCurr}
                        onChangeDC={onChangeDC}
                        onUpdateLineDetail={onUpdateLineDetail}
                        setIsLineTransaction={setIsLineTransaction}
                        lnTransaction={lineDetails}
                        generateLine={handleGenerateLine}
                        onChangeLNDetail={onChangeLNDetail}
                        isSubmit={isSubmit}
                      />
                    );
                  })}
                <div className="relative flex w-full space-x-2">
                  <div className="w-2/6"></div>
                  <div className="flex w-4/6 space-x-4 justify-start">
                    <TextField className="w-1/5" value={sumDebit} size="small" label="Total Debit" inputProps={{ style: { textAlign: 'right', paddingRight: 30 } }} disabled />
                    <TextField className="w-1/5" value={sumCredit} size="small" label="Total Credit" inputProps={{ style: { textAlign: 'right', paddingRight: 30 } }} disabled />
                    {[0, 1, 2].map((d) => (
                      <div key={d} className="w-1/5" />
                    ))}
                    {dt.line_details.length && <div className="w-[33px]" />}
                  </div>
                </div>
              </div>
            </div>
            <Stack direction="row" spacing={2} className="mt-5 float-right">
              <button
                type="button"
                className="flex h-[40px] gap-x-2 items-center text-center rounded-xl border px-4 border-[#2C6D47] hover:!bg-[#2c6d470a]"
                onClick={() => navigate(-1)}
              >
                <p className="text-[#2C6D47]">Back</p>
              </button>
              <button
                type="submit"
                className={`flex h-[40px] ${!isSubmit ? 'bg-[#2C6D47]' : 'bg-[#2c6d4726]'} gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4`}
                disabled={isSubmit}
                form="transaction-form"
              >
                <p className="text-white">Submit</p>
              </button>
            </Stack>
            <LineTransaction
              open={isLineTransaction}
              close={() => setIsLineTransaction(false)}
              data={isLineTransaction?.id}
              transaction={lineDetails}
              action={handleGenerateLine}
            />
          </>
        )}
      </div>
    </TransactionContext.Provider>
  );
}

import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import HrApi from '@/api/HR/api';
import POSApi from '@/api/pos/posApi';
import SiteApi from '@/api/site';
import { fetchData } from '@/utils/fetchDataFunc';

import { TextField, CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
import { DatePickerFieldWithoutController } from '@/components/Input/DatePickerField';
import AccountingApi from '../../api/accounting/accounting';
import { DataGrid } from '@mui/x-data-grid';
import DashboardDesign from './DashboardDesign';
import moment from 'moment';
import DashboardHeatmap from './DashboardHeatmap';

function OutletDetailDashboardTab({ outletName }) {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const { outletId } = useParams();

  const periodOptions = [
    { name: 'Last 5 days', value: 'last 5 days' },
    { name: 'Last 7 days', value: 'last 7 days' },
    { name: 'Last 30 days', value: 'last 30 days' },
    { name: 'Last 3 months', value: 'last 3 months' },
    { name: 'Last 6 months', value: 'last 6 months' },
    { name: 'Year to date', value: 'year to date' },
    { name: 'Last 1 year', value: 'last 1 year' },
    { name: 'All time', value: 'all time' },
    { name: 'Custom', value: 'custom' },
  ];

  const truncateDateOptions = [
    { name: 'Daily', value: 'Date' },
    { name: 'Weekly', value: 'Week' },
    { name: 'Monthly', value: 'Month' },
    { name: 'Yearly', value: 'Year' },
  ];

  const dateNow = new Date();
  const startDate = new Date(dateNow);
  const [startPeriod, setStartPeriod] = useState(startDate.setDate(dateNow.getDate() - 30));
  const [endPeriod, setEndPeriod] = useState(dateNow);

  const [selectedPeriod, setSelectedPeriod] = useState('last 30 days');

  const [truncateDate, setTruncateDate] = useState('Week');
  const [isLoadingGetSummary, setIsLoadingGetSummary] = useState(false);
  const [allSummary, setAllSummary] = useState(null);
  const [allDepositSummary, setAllDepositSummary] = useState(null);
  const [allSummaryForHeatmap, setAllSummaryForHeatmap] = useState(null);
  const [truncateOptions, setTruncateOptions] = useState(truncateDateOptions);

  const groupCat = ['Transaction type', 'Payment type', 'Customer type'];

  const heatmapTab = ['On the spot', 'Customer App'];

  const generateSummary = async () => {
    const { getSalesSummary, getNonSalesSummary, getDepositAndTopUps, getDepositSummary } = POSApi();
    const newDt = { truncate_date_by: truncateDate };

    if (startPeriod) {
      newDt.start = moment(startPeriod).format('YYYY-MM-DD');
    }

    if (endPeriod) {
      newDt.end = moment(endPeriod).format('YYYY-MM-DD');
    }

    try {
      setIsLoadingGetSummary(true);

      const summaryPromises = groupCat.map(async (dt) => {
        newDt.group_by = dt;
        const rp = await getSalesSummary(outletId, newDt);

        if (rp.status === 200) {
          return { group: dt, data: rp.data };
        } else {
          console.warn(`getSalesSummary for group "${dt}" returned status ${rp.status}`);
          return null;
        }
      });

      const resolvedSummaryData = await Promise.all(summaryPromises);
      const filteredSummaryData = resolvedSummaryData.filter(Boolean);

      const rpNonSales = await getNonSalesSummary(outletId, newDt);
      const rpDepositAndTopups = await getDepositAndTopUps(outletId, newDt);
      const depositSummary = await getDepositSummary(outletId);

      if (rpNonSales.status === 200 && rpDepositAndTopups.status === 200 && depositSummary.status === 200) {
        const nonSalesData = { group: 'Non Sales', data: rpNonSales.data };
        const depositAndTopups = { group: 'Deposit And Topups', data: rpDepositAndTopups.data };

        setAllDepositSummary(depositSummary.data);
        setAllSummary([...filteredSummaryData, nonSalesData, depositAndTopups]);
      } else {
        console.warn(`getNonSalesSummary returned status ${rpNonSales.status}`);
      }
    } catch (error) {
      console.error('Error generating summary:', error);
    } finally {
      generateSummaryForHeatmap();
    }
  };

  const generateSummaryForHeatmap = async () => {
    const { getSalesHeatmap } = POSApi();
    const newDt = {};

    if (startPeriod) {
      newDt.start = moment(startPeriod).format('YYYY-MM-DD');
    }

    if (endPeriod) {
      newDt.end = moment(endPeriod).format('YYYY-MM-DD');
    }

    try {
      const summaryPromises = heatmapTab.map(async (dt) => {
        newDt.transaction_source = dt;
        const rp = await getSalesHeatmap(outletId, newDt);

        if (rp.status === 200) {
          return { group: dt, data: rp.data };
        } else {
          console.warn(`getSalesSummary for group "${dt}" returned status ${rp.status}`);
          return null;
        }
      });

      const resolvedSummaryData = await Promise.all(summaryPromises);

      const filteredSummaryData = resolvedSummaryData.filter(Boolean);

      setAllSummaryForHeatmap(filteredSummaryData);
    } catch (error) {
      console.error('Error generating summary:', error);
    } finally {
      setIsLoadingGetSummary(false);
    }
  };

  const onFilterStartDate = (event, key) => {
    const parsedDate = new Date(event);

    if (event === null) {
      return key === 'startPeriod' ? setStartPeriod(event) : setEndPeriod(event);
    }

    if (parsedDate.getFullYear() >= 2010 && !isNaN(parsedDate)) {
      return key === 'startPeriod' ? setStartPeriod(event) : setEndPeriod(event);
    } else {
      console.log('Invalid date or year is less than 2010, skipping update');
      return;
    }
  };

  const handleCustomPeriod = () => {
    const start = moment(startPeriod);
    const end = moment(endPeriod);
    const duration = end.diff(start, 'days');

    let filteredOptions;

    if (selectedPeriod === 'custom') {
      if (duration < 7) {
        filteredOptions = truncateDateOptions.filter((opt) => opt.value === 'Date');
      } else if (duration >= 7 && duration <= 30) {
        filteredOptions = truncateDateOptions.filter((opt) => opt.value === 'Week' || opt.value === 'Month');
      } else if (duration > 30 && duration < 365) {
        filteredOptions = truncateDateOptions.filter((opt) => opt.value === 'Month');
      } else if (duration >= 365) {
        filteredOptions = truncateDateOptions.filter((opt) => opt.value === 'Month' || opt.value === 'Year');
      }
    } else {
      filteredOptions = truncateDateOptions;
    }

    if (!filteredOptions.some((opt) => opt.value === truncateDate)) {
      setTruncateDate(null);
    }

    setTruncateOptions(filteredOptions);
  };

  useEffect(() => {
    if (truncateDate !== null) {
      generateSummary();
    }
  }, [truncateDate, selectedPeriod, truncateOptions]);

  useEffect(() => {
    if (selectedPeriod === 'custom') {
      handleCustomPeriod();
    } else {
      setTruncateOptions(truncateDateOptions);
    }
  }, [selectedPeriod, startPeriod, endPeriod]);

  const handlePeriodValue = (val) => {
    let dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    let startDate;

    switch (val) {
      case 'last 5 days':
        startDate = new Date(dateNow);
        startDate.setDate(dateNow.getDate() - 5);
        setTruncateDate('Date');
        break;
      case 'last 7 days':
        startDate = new Date(dateNow);
        startDate.setDate(dateNow.getDate() - 7);
        setTruncateDate('Date');
        break;
      case 'last 30 days':
        startDate = new Date(dateNow);
        startDate.setDate(dateNow.getDate() - 30);
        setTruncateDate('Week');
        break;
      case 'last 3 months':
        startDate = new Date(dateNow);
        startDate.setMonth(dateNow.getMonth() - 3);
        startDate.setDate(1);
        setTruncateDate('Month');
        break;
      case 'last 6 months':
        startDate = new Date(dateNow);
        startDate.setMonth(dateNow.getMonth() - 6);
        startDate.setDate(1);
        setTruncateDate('Month');
        break;
      case 'year to date':
        startDate = new Date(dateNow.getFullYear(), 0, 1);
        setTruncateDate('Month');
        break;
      case 'last 1 year':
        startDate = new Date(dateNow);
        startDate.setFullYear(dateNow.getFullYear() - 1);
        setTruncateDate('Month');
        break;
      case 'all time':
        startDate = null;
        dateNow = null;
        setTruncateDate('Month');
        break;
      case 'custom':
        handleCustomPeriod();
        break;
      case null:
        startDate = null;
        break;
      default:
        return;
    }

    if (val !== 'custom') {
      setStartPeriod(startDate);
    }
    setEndPeriod(dateNow);
    setSelectedPeriod(val);
  };

  return (
    <div className="space-y-8">
      <form className="w-full relative flex flex-wrap gap-4" onSubmit={handleSubmit(generateSummary)}>
        <div className="w-[15rem] h-fit ">
          <p className="font-semibold mb-[5px]">Period</p>
          <Controller
            name="period"
            control={control}
            defaultValue={selectedPeriod}
            render={({ field }) => {
              return (
                <SelectAutocomplete
                  variant="outlined"
                  options={periodOptions}
                  defaultValue={field.value || null}
                  onChange={(e, val) => {
                    field.onChange(val);
                    handlePeriodValue(val);
                  }}
                  isLoading={false}
                />
              );
            }}
          />
        </div>
        <div className="w-fit">
          <p className="font-bold mb-[5px]">Start Period</p>
          <DatePickerFieldWithoutController
            value={startPeriod}
            onChange={(newValue) => onFilterStartDate(newValue, 'startPeriod')}
            renderInput={(params) => {
              return <TextField sx={{ width: 'auto' }} size="small" {...params} />;
            }}
            componentsProps={{ actionBar: { actions: ['clear'] } }}
            disabled={selectedPeriod !== 'custom' && true}
            minDate={new Date(2010, 0, 1)}
          />
        </div>
        <div className="w-fit">
          <p className="font-bold mb-[5px]">End Period</p>
          <DatePickerFieldWithoutController
            value={endPeriod}
            onChange={(newValue) => onFilterStartDate(newValue, 'endPeriod')}
            renderInput={(params) => {
              return <TextField sx={{ width: 'auto' }} size="small" {...params} />;
            }}
            componentsProps={{ actionBar: { actions: ['clear'] } }}
            disabled={selectedPeriod !== 'custom' && true}
            minDate={startPeriod ? new Date(startPeriod) : null}
          />
        </div>
        <div className="w-[15rem] h-fit ">
          <p className="font-semibold mb-[5px]">Display In</p>
          <SelectAutocomplete
            variant="outlined"
            options={truncateOptions}
            defaultValue={truncateDate || null}
            onChange={(e, val) => {
              setTruncateDate(val);
            }}
            isLoading={false}
          />
        </div>
      </form>
      {!isLoadingGetSummary ? (
        <div className="space-y-8">
          {allSummary && allSummary.length > 0 && <DashboardDesign allSummary={allSummary} allDepositSummary={allDepositSummary} />}
          <div className="space-y-4 border-t pt-4">
            <h2 className="font-semibold text-lg">Activity Heatmap</h2>
            <DashboardHeatmap allSummary={allSummaryForHeatmap} />
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default OutletDetailDashboardTab;

import React, { useContext, useEffect, useState } from 'react';
import { Box, ButtonBase } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit, VisibilityOff } from '@mui/icons-material';

import DetailContext from '../../Projects/DetailContext';
import ModalTopicSection from '../../../../components/Modal/Task/ModalSetting/TopicSection';
import projectApi from '../../../../api/task/project';

export default function TopicStatusSetting() {
  const detailContext = useContext(DetailContext);
  const { sections, dispatchProject } = detailContext;

  const [rows, setRows] = useState(false);
  const [open, setOpen] = useState({
    status: false,
    mode: true,
    data: false,
  });

  const { addProjectSection, updateProjectSection, deleteProjectSection } = projectApi();
  const onCreateTopicStatus = (value) => {
    if (value) {
      addProjectSection(value)
        .then(() => {
          dispatchProject('GET');
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const onChangeTopicStatus = (value, id) => {
    if (id && value) {
      updateProjectSection(id, value)
        .then((res) => {
          if (res.status === 200 || res.data) {
            dispatchProject('GET');
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const onEditClick = (dataActive) => {
    setOpen({
      status: true,
      mode: false,
      data: dataActive,
    });
  };

  const onDeleteClick = (id) => {
    if (id) {
      deleteProjectSection(id)
        .then(() => {
          dispatchProject('GET');
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const renderAction = (dt) => {
    const isDisable = dt.topics.length > 0 ? 'cursor-not-allowed' : '';
    return (
      <Box>
        <ButtonBase className="bz-btn-tb" onClick={() => onEditClick(dt)}>
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className={['bz-btn-tb', isDisable].join(' ')} onClick={() => (!isDisable ? onDeleteClick(dt.id) : {})}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  useEffect(() => {
    if (!rows && sections) {
      setRows(sections);
    }
  }, [rows, sections]);

  useEffect(() => {
    if (rows && rows !== sections) {
      setRows(sections);
    }
  }, [rows, sections]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 304,
      renderCell: (params) => {
        const { name: nm, topics: tps, is_hidden: isHidden } = params.row;
        return (
          <span className={tps.length > 0 && 'text-gray-400'}>
            {nm} {isHidden ? <VisibilityOff className="ml-4" fontSize="14" /> : null}
          </span>
        );
      },
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      width: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderAction(params.row),
    },
  ];

  const handleClickOpen = () => {
    setOpen({
      status: true,
      mode: true,
      data: false,
    });
  };

  const handleClose = () => {
    setOpen({
      status: false,
      mode: true,
      data: false,
    });
  };

  return (
    <div className="w-full h-full flex flex-col justify-center">
      <Box className="w-[400px] h-full flex-flex-col space-y-4 m-auto">
        <button type="button" className="flex ml-auto rounded-xl bg-[#2C6D47] text-white px-5 py-2" onClick={handleClickOpen}>
          Create new Topic Status
        </button>
        <DataGrid autoHeight rows={rows || []} columns={columns} pageSize={10} rowsPerPageOptions={[10]} />
      </Box>
      <ModalTopicSection open={open.status} data={open} sections={rows} onClose={handleClose} onCreateTopicStatus={onCreateTopicStatus} onUpdateTopicStatus={onChangeTopicStatus} />
    </div>
  );
}

import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { createProjects } from '../../../hooks/useProjectsData';
import ProjectForm from '../../../components/Form/Task/ProjectForm';
import listUp from '../../../assets/Listup.png';
import listdown from '../../../assets/Listdown.png';
import BZHelmet from '../../../utils/BZHelmet';

export default function CreateBlank() {
  const qClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { mutateAsync: submitProject } = useMutation(createProjects, {
    onSuccess: (e) => {
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
        navigate(-1);
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onSubmitProject = async (data) => {
    await submitProject(data);
    qClient.invalidateQueries('projects');
  };
  return (
    <>
      <BZHelmet title="Create Project" description="bz publish task management create project" />
      <div className="w-full flex items-center justify-center h-[calc(100vh_-_80px)] ">
        <div className="drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] max-w-[900px] bg-[#101A5F]">
          <div className="">
            <img src={listUp} alt="" className="" />
          </div>
          <div className="max-w-[600px]  bg-white p-8 mx-auto rounded-lg">
            <h2 className="text-xl font-extrabold">New Project</h2>
            <ProjectForm onFormSubmit={onSubmitProject} cancel />
          </div>
          <img src={listdown} alt="" className="" />
        </div>
      </div>
    </>
  );
}

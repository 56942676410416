import { useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-google-charts';
import usePostExtra from '@/hooks/usePostExtra';
import HandleWatchingState from '@/helper/HandleWatchingState';
import BZHelmet from '@/utils/BZHelmet';
import BaseMainTitle from '@/components/BaseMainTitle';
import { useSelector } from 'react-redux';
import useGet from '@/hooks/useGet';
import clsxm from '@/utils/clsxm';
import _ from 'lodash';
import RatesTable from './RatesTable';
import { CircularProgress, TableCell, TableRow } from '@mui/material';

const Rates = [
  { name: 'BI Rates', id: 'BI' },
  { name: 'KP Rates', id: 'KP' },
];
const ChartType = [
  { name: 'Graph', id: 'Graph' },
  { name: 'Table', id: 'Table' },
];

function toFixedNumber(num) {
  return parseFloat(num.toFixed(2));
}
let dateFromDef = new Date();
let dateToDef = new Date();

export default function CurrencyRates() {
  const currencyState = useSelector((state) => state.currency);
  const clientId = useSelector((state) => state.client.activeClient);
  dateToDef.setMonth(dateToDef.getMonth() - 3);
  const formattedDateFromDef = dateFromDef.toISOString().split('T')[0];
  const formattedDate3mDef = dateToDef.toISOString().split('T')[0];

  const [source, setSource] = useState(Rates[1]);
  const [paramsDate, setParamsDate] = useState({ date_from: formattedDate3mDef, date_to: formattedDateFromDef });
  const [ratesChart, setRatesChart] = useState([]);
  const [ratesTable, setRatesTable] = useState([]);
  const [headCells, setHeadCells] = useState([]);
  const [isGraph, setIsGraph] = useState(ChartType[0]);

  const [noRates, setNoRates] = useState(true);
  // 1m,3m,6m,ytd,1y,2y,5y,all
  const [rangeDate, setRangeDate] = useState('3m');

  const { data: listUnitDta } = useGet({
    url: `accounting/business_units/list_names/?client=${clientId}&max_size=true`,
    validate: `accounting/business_units/list_names/?client=${clientId}&max_size=true`,
  });

  const [activeUnit, setActiveUnit] = useState(null);
  useEffect(() => {
    if (listUnitDta?.data && !activeUnit) {
      setActiveUnit(listUnitDta?.data[0]);
    }
  }, [listUnitDta, activeUnit]);

  function handleSelectRangeDate(item) {
    let dateFrom = new Date();
    let dateTo = new Date();

    switch (item) {
      case '1m':
        dateFrom.setMonth(dateFrom.getMonth() - 1);
        break;
      case '3m':
        dateFrom.setMonth(dateFrom.getMonth() - 3);
        break;
      case '6m':
        dateFrom.setMonth(dateFrom.getMonth() - 6);
        break;
      case 'ytd':
        dateFrom = new Date(dateTo.getFullYear(), 0, 1); // Start of the year
        break;
      case '1y':
        dateFrom.setFullYear(dateFrom.getFullYear() - 1);
        break;
      case '2y':
        dateFrom.setFullYear(dateFrom.getFullYear() - 2);
        break;
      case '5y':
        dateFrom.setFullYear(dateFrom.getFullYear() - 5);
        break;
      case 'all':
        setParamsDate({
          date_from: '',
          date_to: '',
        });
        break;
      default:
        filterPeriod = '';
    }

    const formattedDateFrom = dateFrom.toISOString().split('T')[0];
    const formattedDateTo = dateTo.toISOString().split('T')[0];

    if (item !== 'all') {
      setParamsDate({
        date_from: formattedDateFrom,
        date_to: formattedDateTo,
      });
    }
    setRangeDate(item);
  }

  const watchOptionRates = HandleWatchingState(JSON.stringify([source, paramsDate, activeUnit?.id]));
  const { mutate: postRates } = usePostExtra({
    url: `accounting/business_units/${activeUnit?.id}/get_currency_rate_report`,
    validate: [`accounting/business_units-${JSON.stringify([source, paramsDate, activeUnit?.id])}`],

    onSuccess: (res) => {
      if (res.status == 200) {
        setNoRates(false);

        setRatesChart(res.data);

        const date = res?.data?.[0].rates?.filter((rt, idx) => idx !== 0).map((rates) => ({ date: rates[0] }));
        const rtr = res?.data?.map((rt, idx) => rt?.rates.filter((ratesz, idx) => idx !== 0).map((rates) => ({ [rt?.conversion]: rates[1] })));

        const allArrays = [date, ...rtr].map((arr) => _.flatten(arr));

        const mergedArray = _.zip(...allArrays).map((item) => {
          return item.reduce((acc, obj) => ({ ...acc, ...obj }), {});
        });

        const collectHeadCells = [
          {
            id: 'date',
            numeric: false,
            disablePadding: true,
            label: 'Date',
          },
          res?.data?.map((rt) => ({ id: rt?.conversion, numeric: false, disablePadding: true, label: rt?.conversion })),
        ];

        setRatesTable(mergedArray);
        setHeadCells(_.flatten(collectHeadCells));
      } else {
        setNoRates(true);
        // renderError({ data: JSON.parse(e.request.response) });
      }
    },
  });

  const onPostRates = (data) => {
    let paramsData = {
      // source: source?.id,
      currency_from: 'null',
      currency_to: 'null',
    };
    if (paramsDate?.date_from && paramsDate?.date_to) {
      paramsData = { ...paramsData, date_from: paramsDate?.date_from, date_to: paramsDate?.date_to };
    }

    postRates(paramsData);
  };

  useEffect(() => {
    if (activeUnit?.id) {
      onPostRates();
    }
  }, [watchOptionRates, activeUnit?.id]);

  const data = useMemo(() => ratesTable, [ratesTable]);

  function TableCells(props) {
    const { row, labelId, ariaChecked, selected } = props;

    return (
      <TableRow hover role="checkbox" aria-checked={ariaChecked} tabIndex={-1} key={row.id} selected={selected}>
        <TableCell component="th" id={labelId} scope="row">
          {row.date}
        </TableCell>
        {ratesChart?.map((rts) => {
          return (
            <TableCell component="th" id={labelId} scope="row">
              {row[rts?.conversion] ? toFixedNumber(parseFloat(row[rts?.conversion])) : 0}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  return (
    <>
      <BZHelmet title="Currency Rates" description="" content="" />
      <div>
        <BaseMainTitle title="Currency Rates" />
        <div className="p-5 flex w-full border-b divide-x">
          {' '}
          <div className="w-[300px] ">Business Unit</div>
          <div className="w-full flex pl-2 space-x-2">
            {ChartType.map((ch) => {
              return (
                <div onClick={() => setIsGraph(ch)} className={clsxm('p-2 text-sm  hover:text-black cursor-pointer', isGraph == ch ? 'border-b-2' : 'text-bz-muted-fg')}>
                  {ch.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="p-5 flex w-full divide-x ">
          <div className="w-[300px] ">
            <div className="pr-5">
              {listUnitDta?.data?.map((unit) => {
                const { name, id } = unit;
                return (
                  <div
                    className={clsxm('p-2 text-sm rounded-lg hover:text-black cursor-pointer', activeUnit?.id == id ? 'bg-bz-muted' : 'text-bz-muted-fg')}
                    onClick={() => setActiveUnit(unit)}
                  >
                    {name}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full pl-2">
            {isGraph?.id === 'Graph' ? (
              <div>
                <div className="relative w-full ">
                  <div className="flex flex-wrap w-[250px] gap-x-2 text-sm mb-2 relative z-[99]">
                    {['1m', '3m', '6m', 'ytd', '1y', '2y', '5y', 'all'].map((item) => (
                      <div
                        key={item}
                        onClick={() => handleSelectRangeDate(item)}
                        className={`${item === rangeDate ? 'border-b-2 border-black' : ''} hover:cursor-pointer hover:bg-gray-100 p-[0.4px]`}
                      >
                        {item}
                      </div>
                    ))}
                  </div>

                  <div>
                    {!noRates ? (
                      <div className="grid grid-cols-2">
                        {ratesChart?.map((rate, index) => {
                          let data = [...rate.rates];

                          // Convert date format
                          data = data.map((rateItem, idx) => (idx === 0 ? rateItem : [new Date(rateItem[0]), rateItem[1] ? toFixedNumber(parseFloat(rateItem[1])) : 0]));

                          const chartOptions = {
                            title: `${rate.conversion} (${currencyState?.source?.name})`,
                            series: {
                              1: { curveType: 'function' },
                            },
                            legend: { position: 'none', title: 'test' },
                            chartArea: {
                              width: '75%',
                            },
                            width: '100%',
                            hAxis: {
                              title: 'Date',
                              format: 'yyyy-MM-dd',
                            },
                            vAxis: {
                              title: 'Rates',
                            },
                            pointSize: 2,
                          };

                          return <Chart className="border" key={index} chartType="LineChart" width="100%" height="400px" data={data} options={chartOptions} />;
                        })}
                      </div>
                    ) : (
                      <div>No Rates Found</div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <RatesTable dataApi={data} isFetching={false} headCells={headCells} TableCells={TableCells} page={1} rowsPerPage={10} count={100} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import _ from 'lodash';

function SitemapList({ rows = [], deleteSitemap, ...props }) {
  const columns = [
    { field: 'path', headerName: 'Path', flex: 1 },
    { field: 'type', headerName: 'Type', valueFormatter: (params) => _.capitalize(params.value) },
    {
      field: 'lastSubmitted',
      headerName: 'Submitted',
      width: 130,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }),
    },
    {
      field: 'lastDownloaded',
      headerName: 'Last read',
      width: 130,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }),
    },
    { field: 'isPending', headerName: 'Status', valueFormatter: (params) => (params.value ? 'Pending' : 'Success') },
    { field: 'errors', headerName: 'Errors' },
    { field: 'warnings', headerName: 'Warnings' },
    { field: 'discoveredUrl', headerName: 'Discovered URLs', width: 160 },
    {
      field: 'action',
      headerName: '',
      width: 55,
      align: 'center',
      renderCell: (params) => (
        <IconButton onClick={() => deleteSitemap(params.value)} color="error" size="small">
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const adjustedRows = rows.map((row, idx) => ({
    id: idx,
    path: row.path,
    type: row.type,
    lastSubmitted: row.lastSubmitted,
    lastDownloaded: row.lastDownloaded,
    isPending: row.isPending,
    errors: row.errors,
    warnings: row.warnings,
    discoveredUrl: row.contents?.[0]?.submitted || '-',
    action: row.path,
  }));

  return <DataGrid autoHeight columns={columns} rows={adjustedRows} pageSize={10} rowsPerPageOptions={[10]} {...props} />;
}

export default SitemapList;

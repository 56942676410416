import qs from 'qs';
import axiosInstances from '../../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function CRMCustomers() {
  const { axiosInstance } = axiosInstances();

  async function getCounts(params) {
    const method = 'get';
    const url = `${baseURL}procurement/vendors/get_counts/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getAllMembers(params) {
    const method = 'get';
    const url = `${baseURL}procurement/vendors/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getMemberDetail(id) {
    const method = 'get';
    const url = `${baseURL}procurement/vendors/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getAllContacts(params) {
    const method = 'get';
    const url = `${baseURL}procurement/contacts/`;
    const response = await axiosInstance({
      method,
      url,
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response;
  }

  async function getContactDetail(id) {
    const method = 'get';
    const url = `${baseURL}procurement/contacts/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function addContact(data) {
    const method = 'post';
    const url = `${baseURL}procurement/contacts/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateContact(id, data) {
    const method = 'patch';
    const url = `${baseURL}procurement/contacts/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getAttributeFilters(params) {
    const method = 'get';
    const url = `${baseURL}procurement/attribute_filters/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getAttributeCategories(params) {
    const method = 'get';
    const url = `${baseURL}procurement/attribute_categories/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getAttributeOptions(attributeId, customParams) {
    const method = 'get';
    const url = `${baseURL}procurement/attribute_options/`;
    const response = await axiosInstance({
      method,
      url,
      params: {
        attribute: attributeId,
        max_size: true,
        ordering: 'name',
        ...customParams,
      },
    });
    return response;
  }

  async function getAllvendor_attributes(params) {
    const method = 'get';
    const url = `${baseURL}procurement/vendor_attributes/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getCountries(params) {
    const method = 'get';
    const url = `${baseURL}countries/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getAllAssignedContacts(params) {
    const method = 'get';
    const url = `${baseURL}procurement/contact_titles/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getAllHistoriesByClientId(params) {
    const method = 'get';
    const url = `${baseURL}procurement/histories/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getAllHistoriesByMemberId(memberId, params) {
    const method = 'get';
    const url = `${baseURL}procurement/histories/`;
    const response = await axiosInstance({
      method,
      url,
      params: {
        member: memberId,
        ...params,
      },
    });
    return response;
  }

  async function getProjectById(params) {
    const method = 'get';
    const url = `${baseURL}project-management/projects/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getAllNotesByMemberId(memberId) {
    const method = 'get';
    const url = `${baseURL}procurement/notes/`;
    const response = await axiosInstance({
      method,
      url,
      params: { member: memberId },
    });
    return response;
  }

  async function getNoteDetail(noteId) {
    const method = 'get';
    const url = `${baseURL}procurement/notes/${noteId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function updateNote(noteId, data) {
    const method = 'patch';
    const url = `${baseURL}procurement/notes/${noteId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function addNote(data) {
    const method = 'post';
    const url = `${baseURL}procurement/notes/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteNote(noteId) {
    const method = 'delete';
    const url = `${baseURL}procurement/notes/${noteId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getAllMembersName(params) {
    const method = 'get';
    const url = `${baseURL}procurement/vendors/get_names/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function bulkDeleteMembers(ids) {
    const method = 'delete';
    const url = `${baseURL}procurement/vendors/bulk_delete/`;
    const response = await axiosInstance({
      method,
      url,
      data: { ids },
    });
    return response;
  }

  async function bulkDeleteContacts(ids) {
    const method = 'delete';
    const url = `${baseURL}procurement/contacts/bulk_delete/`;
    const response = await axiosInstance({
      method,
      url,
      data: { ids },
    });
    return response;
  }

  async function bulkUpdateAssignedContacts(data) {
    const method = 'patch';
    const url = `${baseURL}procurement/contacts/bulk_update/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteHistory(historyId) {
    const method = 'delete';
    const url = `${baseURL}procurement/histories/${historyId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function updateHistory(id, data) {
    const method = 'patch';
    const url = `${baseURL}procurement/histories/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getAllCustomerLocations(params) {
    const method = 'get';
    const url = `${baseURL}locations/get_member_locations/`;
    const response = await axiosInstance({
      method,
      url,
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response;
  }

  async function getAllLocations(params) {
    const method = 'get';
    const url = `${baseURL}locations/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addLocation(data) {
    const method = 'post';
    const url = `${baseURL}locations/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateLocation(id, data) {
    const method = 'patch';
    const url = `${baseURL}locations/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function bulkUpdateLocation(data) {
    const method = 'patch';
    const url = `${baseURL}locations/bulk_update/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function addMemberDetail(data) {
    const method = 'post';
    const url = `${baseURL}procurement/vendors/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateMemberDetail(id, data) {
    const method = 'put';
    const url = `${baseURL}procurement/vendors/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getDynamicReport(params, data) {
    const method = 'post';
    const url = `${baseURL}crm/members/get_dynamic_report/`;
    const response = await axiosInstance({
      method,
      url,
      params,
      data,
    });
    return response;
  }

  async function getReport(data) {
    const method = 'post';
    const url = `${baseURL}procurement/reports/run_reports/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function exportData(params) {
    const method = 'get';
    const url = `${baseURL}procurement/vendors/export/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function bulkUpdateMembersData(params, data) {
    const method = 'put';
    const url = `${baseURL}procurement/vendors/bulk_update/`;
    const response = await axiosInstance({
      method,
      url,
      params,
      data,
    });
    return response;
  }

  async function getCRMProjects(param) {
    const method = 'get';
    const url = `${baseURL}project-management/crm_projects/`;
    const response = await axiosInstance({
      method,
      url,
      params: param,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response?.data?.results;
  }

  async function getCRMProject(id, params) {
    const method = 'get';
    const url = `${baseURL}project-management/crm_projects/${id}`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function createCRMProject(data, method = 'post', projectId = null) {
    let url = `${baseURL}project-management/crm_projects/`;
    if (method === 'patch' && projectId) {
      url = `${baseURL}project-management/crm_projects/${projectId}/`;
    }
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function postCRMProjectComments(data) {
    const method = 'post';
    const url = `${baseURL}project-management/project_comments/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function postCRMProjectAttachments(attachment) {
    const data = new FormData();

    data.append(`project`, attachment.project);
    data.append(`category`, attachment.category);
    data.append(`file`, attachment.file);

    // IF Multiple
    // if (attachment.files && attachment.files.length) {
    //   attachment.files.forEach((file, index) => {
    //     data.append(`file[${index}]project`, attachment.project);
    //     data.append(`file[${index}]category`, attachment.category);
    //     data.append(`file[${index}]file`, file);
    //   });
    // }

    const method = 'post';
    const url = `${baseURL}project-management/project_files/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getDetailCRMProject(projectId) {
    const method = 'get';
    const url = `${baseURL}project-management/crm_projects/${projectId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response?.data;
  }

  async function deleteCRMProject(projectId) {
    const method = 'delete';
    const url = `${baseURL}project-management/crm_projects/${projectId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function deleteCRMProjectAttachment(attachmentId) {
    const method = 'delete';
    const url = `${baseURL}project-management/project_files/${attachmentId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getCRMProjectProfiles(params) {
    const method = 'get';
    const url = `${baseURL}project-management/project_profiles/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response?.data?.results;
  }
  async function createCRMProjectProfile(data = {}) {
    const method = 'post';
    const url = `${baseURL}project-management/project_profiles/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }
  async function actionCRMProjectDetailProfile(profileId, method = 'get', data = {}) {
    const url = `${baseURL}project-management/project_profiles/${profileId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getFileTypeCRMProject(params) {
    const method = 'get';
    const url = `${baseURL}project-management/file_categories/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response?.data?.results;
  }

  async function getCRMProfileAttribute(params) {
    const method = 'get';
    const url = `${baseURL}project-management/profile_vendor_attributes/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response?.data.results;
  }
  async function getCRMProjectAttribute(params) {
    const method = 'get';
    const url = `${baseURL}project-management/project_vendor_attributes/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response?.data.results;
  }
  async function createCRMProjectAttribute(data) {
    const method = 'post';
    const url = `${baseURL}project-management/project_vendor_attributes/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response?.data.results;
  }
  async function actionCRMProjectAttribute(attributeId, method = 'get', data = null) {
    const url = `${baseURL}project-management/project_vendor_attributes/${attributeId}/`;
    const objRequest = { method, url };
    if (data) {
      objRequest.data = data;
    }
    const response = await axiosInstance(objRequest);
    return response;
  }
  async function getCRMProfileGroups(params) {
    const method = 'get';
    const url = `${baseURL}project-management/profile_groups/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response?.data.results;
  }
  async function createCRMProfileGroups(data) {
    const method = 'post';
    const url = `${baseURL}project-management/profile_groups/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response?.data.results;
  }
  async function actionCRMProfileGroups(groupId, method = 'get', data = null) {
    const url = `${baseURL}project-management/profile_groups/${groupId}/`;
    const objRequest = { method, url };
    if (data) {
      objRequest.data = data;
    }
    const response = await axiosInstance(objRequest);
    return response;
  }
  async function deleteContact(id) {
    const method = 'delete';
    const url = `${baseURL}procurement/vendors/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function getCRMAttributeFilter(params) {
    const method = 'get';
    const url = `${baseURL}project-management/attribute_filters/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response?.data.results;
  }

  // for dynamic reports
  async function getAllCRMMethods() {
    const method = 'get';
    const url = `${baseURL}procurement/methods_procurement/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response?.data.results;
  }
  async function getAllCRMMethodsById(id) {
    const method = 'get';
    const url = `${baseURL}procurement/methods_procurement/${id}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response?.data;
  }
  async function getReportGroup(id) {
    const method = 'get';
    const url = `${baseURL}procurement/report_groups_procurement/?client=${id}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response?.data;
  }

  return {
    getCounts,
    getAllMembers,
    getMemberDetail,
    getAllContacts,
    getContactDetail,
    addContact,
    updateContact,
    deleteNote,
    bulkDeleteContacts,
    bulkUpdateAssignedContacts,
    getAttributeFilters,
    getAttributeCategories,
    getAttributeOptions,
    getAllvendor_attributes,
    getCountries,
    getAllAssignedContacts,
    getAllHistoriesByClientId,
    getAllHistoriesByMemberId,
    getProjectById,
    getAllNotesByMemberId,
    getNoteDetail,
    updateNote,
    addNote,
    getAllMembersName,
    bulkDeleteMembers,
    deleteHistory,
    updateHistory,
    getAllCustomerLocations,
    getAllLocations,
    addLocation,
    updateLocation,
    bulkUpdateLocation,
    addMemberDetail,
    updateMemberDetail,
    getDynamicReport,
    exportData,
    bulkUpdateMembersData,
    getCRMProjects,
    getCRMProject,
    createCRMProject,
    postCRMProjectComments,
    postCRMProjectAttachments,
    getDetailCRMProject,
    deleteCRMProject,
    deleteCRMProjectAttachment,
    getCRMProjectProfiles,
    getCRMProfileAttribute,
    getCRMProjectAttribute,
    createCRMProjectAttribute,
    actionCRMProjectAttribute,
    createCRMProjectProfile,
    actionCRMProjectDetailProfile,
    getCRMProfileGroups,
    createCRMProfileGroups,
    actionCRMProfileGroups,
    getFileTypeCRMProject,
    deleteContact,
    getCRMAttributeFilter,
    getReport,
    // for dynamic reports
    getAllCRMMethods,
    getAllCRMMethodsById,
    getReportGroup,
  };
}

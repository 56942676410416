import { Avatar, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { tooltipClasses } from '@mui/material/Tooltip';
// import LightTooltip from './LightTooltip';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

export default function GetAvatar({ data, onClick, isName = false, subject = 'assignee', styles = { width: 24, height: 24 } }) {
  const subj = subject || 'assignee';
  let avatar = (
    <Avatar
      sx={styles}
      onClick={(event) => {
        onClick(event);
      }}
    />
  );
  if (data && data[subj]?.avatar) {
    avatar = (
      <Avatar
        sx={styles}
        src={data[subj].avatar}
        className="border rounded-full border-gray-300"
        onClick={(event) => {
          onClick(event);
        }}
      />
    );
  } else if (data && data[subj]?.initial) {
    avatar = (
      <Avatar
        sx={styles}
        onClick={(event) => {
          onClick(event);
        }}
      >
        <span className="text-xs">{data[subj]?.initial}</span>
      </Avatar>
    );
  }
  let ava = avatar;
  if (isName) {
    ava = (
      <div className="flex space-x-2">
        {avatar}
        <span className="truncate">{data[subj]?.name}</span>
      </div>
    );
  }
  return (
    <LightTooltip title={data && data[subj] ? data[subj].name : ''} placement="bottom">
      {ava}
    </LightTooltip>
  );
}

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ButtonBase, Box } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

import ModalAccountGroup from '../../../../components/Modal/Accounting/Settings/AccountGroup';
import BasePromptDialog from '../../../../components/ui/Dialog/BasePromptDialog';

import AccountingSettingsContext from '../AccountingSettingsContext';
import accountingApi from '../../../../api/accounting/accounting';

const { getAccountGroups } = accountingApi();

export default function TabAccountGroup() {
  const asContext = useContext(AccountingSettingsContext);
  const dispatch = useDispatch();
  const accSettingsFilter = useSelector((state) => state.accountSettings.tableState);
  const cl = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const client = activeUser.is_superuser ? activeClient : cl;

  const [isModalAccGroups, setModalAccGroups] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [modalData, setModalData] = useState('');
  const [isModalDelete, setModalDelete] = useState(false);

  const tableState = useMemo(() => accSettingsFilter.accountGroup ? JSON.parse(accSettingsFilter.accountGroup) : {}, [accSettingsFilter])

  const {
    isFetching,
    isFetched,
    data: accG,
    refetch,
  } = useQuery({
    queryKey: ['account-group', client],
    enabled: !!client,
    queryFn: () => getAccountGroups({ account_type__client: client, max_size: true, ordering: 'code' }),
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
  });

  const accGroups = accG?.data?.results || [];

  const handleActionSubmit = (mode, data) => {
    if (data) {
      const { addAccountGroup, updateAccountGroup } = accountingApi();
      if (mode === 'create') {
        addAccountGroup(data)
          .then((res) => {
            if (res.status === 201 || res.data) {
              refetch();
              asContext.refetchAccG();
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
      if (mode === 'update') {
        updateAccountGroup(modalData.id, data)
          .then((res) => {
            if (res.status === 201 || res.data) {
              refetch();
              asContext.refetchAccG();
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    }
    setModalData(false);
  };

  const onEditClick = (data) => {
    setModalData(data);
    setModalType('update');
    setModalAccGroups(true);
  };

  const onDeleteClick = (data) => {
    setModalData(data);
    setModalDelete(true);
  };

  const onConfirmDelete = () => {
    const { deleteAccountGroup } = accountingApi();

    if (modalData && modalData.id) {
      deleteAccountGroup(modalData.id)
        .then((res) => {
          if (res.status === 204) {
            refetch();
            asContext.refetchAccG();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const renderAction = (dt) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase className="bz-btn-tb" onClick={() => onEditClick(dt)}>
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => onDeleteClick(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const columns = [
    {
      field: 'code',
      headerName: 'Code',
      minWidth: 256,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 256,
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 256,
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => renderAction(params.row),
    },
  ];

  return (
    <div>
      <div className="w-full flex px-4 pt-4">
        <button
          type="button"
          className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 ml-auto"
          style={{ backgroundColor: '#2C6D47' }}
          onClick={() => {
            setModalType('create');
            setModalAccGroups(true);
          }}
        >
          <p className="text-white">Add New Account Group</p>
        </button>
      </div>
      <div className="w-full flex flex-col p-4 justify-center overflow-hidden">
        {isFetched && (
          <Box className="w-full h-full px-4 pt-4 mb-4">
            <DataGrid
              autoHeight
              loading={isFetching}
              rows={accGroups || []}
              columns={columns}
              rowsPerPageOptions={[10, 20, 50]}
              className="m-auto max-w-[880px] w-[70%]"
              initialState={Object.keys(tableState).length ? { ...tableState, pagination: {...tableState.pagination}  } : { pagination: { page: 0, pageSize: 20 } }}
              onStateChange={(state) => {
                dispatch({ type: 'accountSettings/setTabState', payload: { type: 'accountGroup', value: JSON.stringify(state) } });
              }}
            />
          </Box>
        )}
      </div>
      <ModalAccountGroup
        open={isModalAccGroups}
        close={() => {
          setModalAccGroups(false);
          setModalType(false);
          setModalData(false);
        }}
        action={handleActionSubmit}
        type={modalType}
        data={modalData}
      />
      <BasePromptDialog
        open={isModalDelete}
        title="Delete"
        content={modalData?.name}
        action={onConfirmDelete}
        close={() => {
          setModalDelete(false);
          setTimeout(() => {
            setModalData(false);
          }, 300);
        }}
      />
    </div>
  );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import client from '../../api/client';

export const getActiveClientDetail = createAsyncThunk('client/setActiveDetailClient', async (clientId) => {
  const { getClientDetail } = client();
  const response = await getClientDetail(clientId);
  return response.data;
});

export const getAllClients = createAsyncThunk('client/getAllClients', async () => {
  const { getAllClient } = client();
  const response = await getAllClient();
  return response.data?.results || [];
});

const clientSlice = createSlice({
  name: 'client',
  initialState: {
    activeClient: Cookies.get('bzaClient') && Cookies.get('bzaClient') !== 'false' ? Cookies.get('bzaClient') : false,
    activeDetailClient: false,
    clientList: false,
    clientSites: false,
    clientBusinessUnit: [],
    clientInformation: [],
    clientSubscriptions: [],
    clientSiteLanguages: [],
    activeAdminLanguage: {
      id: 1,
      abbreviation: 'en',
      flag: 'GB',
      name: 'English',
    },
  },
  reducers: {
    setClientSites(state, action) {
      console.log(state, action.payload);
    },
    setActiveClient(state, action) {
      const currState = state;
      currState.activeClient = action.payload;
      Cookies.set('bzaClient', action.payload);
    },
    setClientList(state, action) {
      const currState = state;
      currState.clientList = action.payload;
    },
    setClientInformation(state, action) {
      console.log(state, action.payload);
    },
    setClientSubscriptions(state, action) {
      console.log(state, action.payload);
    },
    setActiveAdminLanguage(state, action) {
      const currState = state;
      currState.activeAdminLanguage = action.payload;
      localStorage.setItem('bzLang', action.payload.abbreviation.toLowerCase());
      localStorage.setItem('bzlangId', action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveClientDetail.fulfilled, (state, action) => {
        const currState = state;
        currState.activeDetailClient = action.payload;
        currState.clientSites = action.payload.sites;
        currState.clientInformation = action.payload;
        currState.clientBusinessUnit = action.payload.business_units;
        // currState.clientSubscriptions = action.payload.subscriptions;
        currState.clientSubscriptions = action.payload.subscriptions.map((sub) => sub.id);
        currState.clientSiteLanguages = action.payload.languages;
        if (!localStorage.getItem('bzLang') && !localStorage.getItem('bzlangId')) {
          const lg = action.payload.languages;
          if (lg.length > 0) {
            const lang = lg[0];
            currState.activeAdminLanguage = lang;
            localStorage.setItem('bzLang', lang.abbreviation.toLowerCase());
            localStorage.setItem('bzlangId', lang.id);
          }
        }
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        const currState = state;
        currState.clientList = action.payload;
      });
  },
});

export const clientActions = clientSlice.actions;
export default clientSlice;

import { useState, useEffect, useCallback } from 'react';

export default function HandleWatchingState(data) {
  const [status, setStatus] = useState(false);

  const handleWatch = useCallback(() => {
    if (data) {
      setStatus(false);
      setTimeout(() => {
        setStatus(true);
      }, 100);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      handleWatch();
    }
  }, [data, handleWatch]);
  return status;
}

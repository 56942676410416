/* eslint-disable */

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, FormControl, Select, MenuItem, InputBase } from '@mui/material';
import { DevTool } from '@hookform/devtools';

import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';
import MuiSelectSingleItem from '../../Input/MuiSelectSingleItem';

function JobCollectionForm({ defaultValues, onFormSubmit }) {
  const { control, handleSubmit } = useForm({ defaultValues });
  const clientId = useSelector((state) => state.client.activeClient);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    onFormSubmit({ name: data.name, category: 5, client: clientId, is_internal: false });
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <InputContainer name="name" control={control} label="Name" />
        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="flex" variant="outlined">
            Cancel
          </Button>
          <Button className=" flex" type="submit" variant="outlined">
            {defaultValues ? 'Update Job Collection' : 'Create Job Collection'}
          </Button>
        </div>
      </div>
      <DevTool control={control} />
    </form>
  );
}
export default JobCollectionForm;

export default function formatterInitialName() {
  const formatter = (name) => {
    if (name) {
      return name
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase();
    }
    return '';
  };
  return { formatter };
}

import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

export default function PageList({ columns, rows, ...props }) {
  return (
    <div style={{ height: 650, width: '100%' }}>
      <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection {...props} />
    </div>
  );
}

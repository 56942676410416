/* eslint-disable */

import React from 'react';
import { CardActionArea, CardActions, styled, Icon, Typography, CardMedia, CardContent, Card } from '@mui/material';
import { InsertPhoto, VideoCameraBack, Share, FilePresent, WarningAmberOutlined, ArrowForward } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { assetDelete, filterDelete, handleSelect, handleUnSelect } from '../../store/slices/assetCrudSlice';
import { useSelector } from 'react-redux';

const fileName = (fileName) => {
  const splitName = fileName?.split('/');
  const transName = splitName && splitName.length > 0 && splitName[splitName?.length - 1];
  return transName;
};

export default function AssetCardNew({ assetsData, assetComponent, assetQuality, setAssetQuality }) {
  const dispatch = useDispatch();
  const assetPair = useSelector((state) => state.assetCrud.selected);
  const myArray = assetPair;
  const latestElement = myArray.length === 1 ? myArray[0] : myArray[myArray.length - 1];

  const handleChange = (e) => {
    if (setAssetQuality) {
      setAssetQuality({ state: 'ori' });
    }
    const { value, checked } = e.target;
    if (checked) {
      dispatch(handleSelect(value));
    } else {
      dispatch(handleUnSelect(value));
    }
  };

  const handlePair = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(assetDelete(value));
    } else {
      dispatch(filterDelete(value));
    }
  };

  function assetType(value) {
    let type;
    if (value?.toString() === '1') {
      type = 'Image';
    }
    if (value?.toString() === '2') {
      type = 'External Link';
    }
    if (value?.toString() === '3') {
      type = '360';
    }
    if (value?.toString() === '4') {
      type = 'Video';
    }
    if (value?.toString() === '5') {
      type = 'File';
    }
    return type;
  }

  const FileIcon = styled(Icon)(() => ({
    fontSize: '126px',
    // height: '126px',
    margin: 'auto',
    color: '#F28C39',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  }));

  function assetPlaceholder(value) {
    // let icon;
    // if (value?.toString() === '1') {
    //   icon = <FileIcon component={InsertPhoto} />;
    // }
    // if (value?.toString() === '2') {
    //   icon = <FileIcon component={Share} />;
    // }
    // if (value?.toString() === '3') {
    //   icon = '360';
    // }
    // if (value?.toString() === '4') {
    //   icon = <FileIcon component={VideoCameraBack} />;
    // }
    // if (value?.toString() === '5') {
    //   icon = '';
    // }
    return <FileIcon component={WarningAmberOutlined} />;
  }

  function renderAsset(type, file, thumb) {
    let html;
    if (type?.toString() === '1' || type?.toString() === '3') {
      html = <CardMedia component="img" className="h-[126px] object-cover w-full" image={file} alt="Image" />;
    }
    if (type?.toString() === '5') {
      html = thumb ? <CardMedia component="img" className="h-[126px] object-cover w-full" image={thumb} alt="Image" /> : <FileIcon component={FilePresent} />;
    }
    if (type?.toString() === '2' || type?.toString() === '4') {
      html = (
        <video
          className=" h-[126px] object-cover w-full "
          style={{
            maxWidth: '100%',
            width: '120%',
            height: '',
            margin: '',
          }}
          playsInline
          loop
          muted
          controls
          alt="All the devices"
          src={file}
        />
      );
    }
    return html;
  }

  function renderCard() {
    let cardHtml;
    if (assetComponent === 'handleSelect') {
      cardHtml = (
        <div className="w-2/12">
          <Card className="w-full relative">
            <input type="checkbox" name="lang" value={assetsData.id} onChange={handlePair} className="absolute top-3 left-3 w-5 h-5" />
            <CardActions className="ml-8 gap-x-3 flex items-center">
              <Typography gutterBottom className="" component="div">
                {assetType(assetsData?.asset_type)}
              </Typography>
            </CardActions>
            <CardActionArea>
              {assetsData?.asset_file ? (
                <div className="">{renderAsset(assetsData?.asset_type, assetsData?.file_small || assetsData?.asset_file)}</div>
              ) : (
                <div className="">{assetPlaceholder(assetsData?.asset_type)}</div>
              )}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Name : {assetsData?.name || fileName(assetsData?.asset_file)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  ID : {assetsData?.id}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      );
    }
    if (assetComponent === 'assetPairEdit') {
      cardHtml = assetsData?.map((asset) => (
        <div key={asset?.id} className="w-2/12">
          <Card className="w-full relative">
            <input type="checkbox" name="lang" value={asset.id} onChange={handleChange} className="absolute top-3 left-3 w-5 h-5" />
            <CardActions className="ml-8 gap-x-3 flex items-center">
              <Typography gutterBottom className="" component="div">
                {assetType(asset?.asset_type)}
              </Typography>
            </CardActions>
            <CardActionArea>
              {asset?.asset_file ? (
                <div className="">{renderAsset(asset?.asset_type, asset?.file_small || asset?.asset_file)}</div>
              ) : (
                <div className="">{assetPlaceholder(asset?.asset_type)}</div>
              )}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Name : {asset?.name || fileName(asset?.asset_file)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  ID : {asset?.id}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      ));
    }
    if (assetComponent === 'assetPairMenu') {
      cardHtml = (
        <div className="w-3/12">
          <Card className="w-full relative">
            <input type="checkbox" name="lang" value={assetsData.id} onChange={handlePair} className="absolute top-3 left-3 w-5 h-5" />
            <CardActions className="ml-8 gap-x-3 flex items-center">
              <Typography gutterBottom className="" component="div">
                {assetType(assetsData?.asset_type?.id)}
              </Typography>
            </CardActions>
            <CardActionArea>
              {assetsData?.asset_file ? (
                <div className="">{renderAsset(assetsData?.asset_type?.id, assetsData?.file_small || assetsData?.asset_file)}</div>
              ) : (
                <div className="">{assetPlaceholder(assetsData?.asset_type)}</div>
              )}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Name : {assetsData?.name || fileName(assetsData?.asset_file)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  ID : {assetsData?.id}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      );
    }
    if (assetComponent === 'assetPairEditMenu') {
      cardHtml = assetsData?.map((asset) => (
        <div key={asset?.id} className="w-3/12">
          <Card className="w-full relative">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-3">
                <input type="checkbox" name="lang" value={asset.id} onChange={handleChange} className="absolute top-3 left-3 w-5 h-5" />
                <CardActions className="ml-8 gap-x-3 flex items-center">
                  <Typography gutterBottom className="" component="div">
                    {assetType(asset?.asset_type)}
                  </Typography>
                </CardActions>
              </div>
              <Link title={`Go to asset ${asset?.name}`} to={`/asset/detail/${asset?.id}`}>
                <div className="pr-3">
                  <ArrowForward />
                </div>
              </Link>
            </div>
            <CardActionArea>
              {asset?.asset_file ? (
                <div className="">{renderAsset(asset?.asset_type, asset?.file_small || asset?.asset_file)}</div>
              ) : (
                <div className="">{assetPlaceholder(asset?.asset_type)}</div>
              )}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Name : {asset?.name || fileName(asset?.asset_file)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  ID : {asset?.id}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      ));
    }
    if (assetComponent === 'widgetDetailAssets') {
      cardHtml = assetsData?.map((asset) => (
        <div key={asset?.asset?.id} className="w-full">
          <Card className="w-full relative">
            <input type="checkbox" name="lang" value={JSON.stringify(asset?.asset?.id)} onChange={handleChange} className="absolute top-3 left-3 w-5 h-5" />
            <CardActions className="ml-8 gap-x-3 flex items-center">
              <Typography gutterBottom className="" component="div">
                {assetType(asset?.asset?.asset_type)}
              </Typography>
            </CardActions>
            <CardActionArea>
              {asset?.asset?.asset_file ? (
                <div className="">{renderAsset(asset?.asset?.asset_type, asset?.asset?.thumbnail_file || asset?.asset?.file_small || asset?.asset?.asset_file)}</div>
              ) : (
                <div className="">{assetPlaceholder(asset?.asset?.asset_type)}</div>
              )}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Name : {asset?.asset?.name || fileName(asset?.asset?.asset_file)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  ID : {asset?.asset?.id}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      ));
    }
    if (!assetComponent) {
      cardHtml = (
        <div className="fhd:grid-cols-8 hd:grid-cols-6 lg:grid-cols-5 grid-cols-3 grid gap-3 w-full">
          {assetsData?.map((asset) => (
            <div key={asset?.id} className="w-full">
              <Card className="w-full relative">
                <input
                  type="checkbox"
                  name="lang"
                  value={JSON.stringify(asset)}
                  onChange={handleChange}
                  defaultChecked={assetPair.find((ds) => ds.toString() === asset.id.toString())}
                  className="absolute top-3 left-3 w-5 h-5"
                />
                <Link to={`/asset/detail/${asset?.id}`}>
                  <CardActions className="ml-8 gap-x-3 flex items-center">
                    <Typography gutterBottom className="" component="div">
                      {assetType(asset?.asset_type.id)}
                    </Typography>
                  </CardActions>
                  <CardActionArea>
                    {asset?.file_small || asset?.asset_file || asset?.asset_link ? (
                      <div className="">{renderAsset(asset?.asset_type.id, asset?.file_small || asset?.asset_file || asset?.asset_link, asset?.thumbnail)}</div>
                    ) : (
                      <div className="">{assetPlaceholder(asset?.asset_type.id)}</div>
                    )}
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        NAME : {asset?.name || fileName(asset?.asset_file)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        ID : {asset?.id}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </div>
          ))}
        </div>
      );
    }

    if (assetComponent === 'single') {
      cardHtml = (
        <div className="fhd:grid-cols-8 hd:grid-cols-6 lg:grid-cols-5 grid-cols-3 grid gap-3 w-full">
          {assetsData?.map((asset) => (
            <div key={asset?.id} className="w-full relative">
              {latestElement?.id === asset?.id && (
                <div className="absolute z-50  bg-white p-2 shadow-sm -top-14 border-blue-400 border">
                  <div className="text-xs font-bold">Select Quality</div>
                  <div className="flex space-x-2">
                    <div
                      onClick={() => setAssetQuality({ state: 'ori', file: latestElement.asset_file })}
                      className={`rounded ${assetQuality.state === 'ori' ? 'text-blue-400 font-bold' : ''}`}
                    >
                      Original
                    </div>
                    <div
                      onClick={() => setAssetQuality({ state: 'big', file: latestElement.file_big })}
                      className={`rounded ${assetQuality.state === 'big' ? 'text-blue-400 font-bold' : ''}`}
                    >
                      Big
                    </div>
                    <div
                      onClick={() => setAssetQuality({ state: 'med', file: latestElement.file_medium })}
                      className={`rounded ${assetQuality.state === 'med' ? 'text-blue-400 font-bold' : ''}`}
                    >
                      Medium
                    </div>
                    <div
                      onClick={() => setAssetQuality({ state: 'sm', file: latestElement.file_small })}
                      className={`rounded ${assetQuality.state === 'sm' ? 'text-blue-400 font-bold' : ''}`}
                    >
                      Small
                    </div>
                  </div>
                </div>
              )}
              <Card className="w-full relative">
                <input
                  type="radio"
                  name="lang"
                  value={JSON.stringify(asset)}
                  onChange={handleChange}
                  defaultChecked={assetPair.find((ds) => ds.toString() === asset.id.toString())}
                  className="absolute top-3 left-3 w-5 h-5"
                />
                <Link to={`/asset/detail/${asset?.id}`}>
                  <CardActions className="ml-8 gap-x-3 flex items-center">
                    <Typography gutterBottom className="" component="div">
                      {assetType(asset?.asset_type.id)}
                    </Typography>
                  </CardActions>
                  <CardActionArea>
                    {asset?.file_small || asset?.asset_file || asset?.asset_link ? (
                      <div className="">{renderAsset(asset?.asset_type.id, asset?.file_small || asset?.asset_file || asset?.asset_link, asset?.thumbnail)}</div>
                    ) : (
                      <div className="">{assetPlaceholder(asset?.asset_type.id)}</div>
                    )}
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        NAME : {asset?.name || fileName(asset?.asset_file)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        ID : {asset?.id}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </div>
          ))}
        </div>
      );
    }
    return cardHtml;
  }

  return renderCard();
}

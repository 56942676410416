/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
// import DownloadLink from 'react-download-link';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useParams } from 'react-router';
import { Avatar, Box, CardHeader, Tab, Typography, CardContent } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Link } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import api from '../../../api/HR/api';
import BZHelmet from '../../../utils/BZHelmet';
import BaseMainTitle from '../../BaseMainTitle';

export default function ImgMediaCard() {
  const [activeEmployee, setActiveEmployee] = useState('');
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { hrEmployeeId } = useParams();

  useEffect(() => {
    const { getEmployeeById } = api();
    getEmployeeById(hrEmployeeId)
      .then((res) => {
        setActiveEmployee(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [hrEmployeeId]);

  console.log('EmployeeDetail', activeEmployee);

  let personalInfo;
  let jobHistory;
  let jobInfo;
  if (activeEmployee) {
    personalInfo = [
      {
        name: 'Employment ID',
        value: activeEmployee.employment_id,
      },
      {
        name: 'Gender',
        value: activeEmployee.gender,
      },
      {
        name: 'Marital Status',
        value: activeEmployee.marital_status,
      },
      {
        name: 'Address',
        value: activeEmployee.address,
      },
      {
        name: 'Spoken Languages',
        value: activeEmployee.spoken_languages.map((e) => e.name)?.join(', '),
      },
      // {
      //   name: 'Educations',
      //   value: activeEmployee.educations?.join(', '),
      // },
      {
        name: 'Skills',
        value: activeEmployee.skills?.join(', '),
      },
      // {
      //   name: 'Attachments',
      //   value: activeEmployee.attachments?.name?.join(', '),
      // },
    ];

    jobHistory = [
      {
        id: 1,
        name: 'Position',
        value: activeEmployee.employment_histories?.map((e) => e.position),
      },
      {
        id: 2,
        name: 'Employment type',
        value: activeEmployee.employment_histories?.map((e) => e.employment_type),
      },
      {
        id: 3,
        name: 'Department',
        value: activeEmployee.employment_histories?.map((e) => e.department?.name),
      },
      {
        id: 4,
        name: 'Business unit',
        value: activeEmployee.employment_histories?.map((e) => e.department?.business_unit?.name),
      },
      {
        id: 5,
        name: 'Start date',
        value: activeEmployee.employment_histories?.map((e) => e.start_date),
      },
      {
        id: 6,
        name: 'End date',
        value: activeEmployee.employment_histories?.map((e) => e.end_date),
      },
    ];
    jobInfo = jobHistory.filter((e) => e.id < 5);
  }
  console.log('Personal Info', jobInfo);
  // personalInfo.map((e) => console.log('name', e.name));

  return (
    <>
      <BZHelmet title={activeEmployee?.first_name ?? ' Employee'} description="" content="" />
      <div>
        <BaseMainTitle title="Employee Detail :" />
        <div className="p-5 ">
          <div className="">
            {activeEmployee && (
              <div className="">
                <Box className="flex justify-between bg-[white] px-5 ">
                  <div className="flex space-x-3 items-center">
                    <div className="">Business Unit : </div>
                    <div className="border-[0.5px] px-2 border-black rounded">{activeEmployee.employment_histories[0]?.department.business_unit.name}</div>
                  </div>
                </Box>
                <Card>
                  <CardHeader
                    className="bg-indigo-300 m-5"
                    avatar={
                      <Avatar sx={{ bgcolor: blueGrey[500], height: 110, width: 110 }} aria-label="recipe">
                        {activeEmployee.avatar ? (
                          <img src={activeEmployee.avatar} alt="profile" className="object-cover h-[150px] w-[150px]" />
                        ) : (
                          <AccountCircleIcon className="object-cover h-[165px] w-[165px]" />
                        )}
                      </Avatar>
                    }
                    title={
                      <div className="flex justify-between mb-5">
                        <Typography variant="h6">
                          {activeEmployee.first_name} {activeEmployee.last_name}
                        </Typography>
                        <div className="flex space-x-3">
                          <Link to="/hr/employee">
                            <ArrowBackIcon />
                          </Link>
                          <Link to={`/hr/employee/${hrEmployeeId}/edit`}>
                            <Edit />
                          </Link>
                        </div>
                      </div>
                    }
                    subheader={
                      <div>
                        <div className="grid 3xl:grid-cols-3 grid-cols-2">
                          <div className="flex font-bold pb-3 ">
                            <div className="mr-10">
                              <p>Status</p>
                              <p>Hired date</p>{' '}
                            </div>
                            <div>
                              {activeEmployee.is_active ? (
                                <div className={activeEmployee.is_active ? 'bg-[#bef2be] font-bold rounded px-1 mr-2' : 'bg-[#f6baba] font-bold rounded px-1 mr-5'}>active</div>
                              ) : (
                                <div className={activeEmployee.is_active ? 'bg-[#bef2be] font-bold rounded px-1 mr-2' : 'bg-[#f6baba] font-bold rounded px-1 mr-5'}>non active</div>
                              )}
                              <div className="font-bold">{activeEmployee.hired_date}</div>
                            </div>
                          </div>
                          <div>
                            <PhoneIcon className="mr-2" /> {activeEmployee.phone || '-'} <br />
                            <MailOutlineIcon className="mr-2" />
                            {activeEmployee.email || '-'}
                          </div>
                          <div className="flex space-x-2">
                            <div className="font-bold w-[50px]">User :</div>
                            {activeEmployee?.user ? (
                              <div className="">
                                <div className="">{activeEmployee?.user?.name}</div>
                                <div className="">{activeEmployee?.user?.email}</div>
                              </div>
                            ) : (
                              '-'
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  />
                  <CardContent className="mt-2">
                    <TabContext value={value}>
                      <TabList onChange={handleChange} TabIndicatorProps={{ style: { background: '#DAA520' } }} aria-label="lab API tabs example">
                        <Tab value="1" label="Details" />
                        <Tab value="2" label="Employment History" />
                        <Tab value="3" label="Educations" />
                      </TabList>
                      <TabPanel value="1">
                        <Typography variant="body1" className="border-b pb-2 mb-3 font-bold">
                          PERSONAL INFORMATION
                        </Typography>
                        {personalInfo && (
                          <div className="personal-info grid grid-cols-2 w-[60%]">
                            {personalInfo.map((e) => (
                              <div className="mb-3" key={e.name}>
                                <p>{e.name}</p>
                                <p className="font-bold">{e.value || '-'}</p>
                              </div>
                            ))}
                            <div className="mb-3">
                              <p>Attachments</p>
                              {activeEmployee.attachments.length === 0
                                ? '-'
                                : activeEmployee.attachments.map((e) => (
                                    <div key={e.id}>
                                      <span className="bg-green-200 rounded px-1 py-0.5 mr-2">
                                        <a href={e.attachment} target="_blank" rel="noreferrer">
                                          {' '}
                                          <DownloadIcon className="h-4" />
                                          {e.name}
                                        </a>
                                        {/* <span>{e.name}</span> */}
                                      </span>
                                    </div>
                                  ))}
                            </div>
                          </div>
                        )}
                        <Typography variant="body1" className="border-b pb-2 mb-3 mt-10 font-bold">
                          JOB INFORMATION
                        </Typography>
                        {jobInfo && (
                          <div className="job-info grid grid-cols-2 w-[60%]">
                            {jobInfo.map((e) => (
                              <div className="mb-3" key={e.name}>
                                <p>{e.name}</p>
                                <p className="font-bold">{e.value[0] || '-'}</p>
                              </div>
                            ))}
                          </div>
                        )}
                      </TabPanel>
                      <TabPanel value="2">
                        {activeEmployee.employment_histories?.map((e) => (
                          <div key={e.id} className={['job-history m-2 p-3 flex justify-between  w-[380px]', e.end_date === null ? 'bg-blue-100' : 'bg-neutral-300'].join(' ')}>
                            <div>
                              <div className="mb-3">
                                <p>Position</p>
                                <p className="font-bold">{e.position || '-'}</p>
                              </div>
                              <div className="mb-3">
                                <p>Employment Type</p>
                                <p className="font-bold">{e.employment_type || '-'}</p>
                              </div>
                              <div className="mb-3">
                                <p>Department</p>
                                <p className="font-bold">{e.department?.name || '-'}</p>
                              </div>
                            </div>
                            <div>
                              <div className="mb-3">
                                <p>Business unit</p>
                                <p className="font-bold">{e.department?.business_unit?.name || '-'}</p>
                              </div>
                              <div className="mb-3">
                                <p>Start date</p>
                                <p className="font-bold">{e.start_date || '-'}</p>
                              </div>
                              <div className="mb-3">
                                <p>End date</p>
                                <p className="font-bold">{e.end_date || '-'}</p>
                              </div>
                            </div>
                          </div>
                        )) || 'No history yet'}
                      </TabPanel>
                      <TabPanel value="3">
                        {activeEmployee?.educations?.map((e) => (
                          <div
                            key={e.id}
                            className={['job-history m-2 p-3 flex flex-col justify-between  w-[380px]', e.end_date === null ? 'bg-blue-100' : 'bg-neutral-300'].join(' ')}
                          >
                            <div>
                              <div className="mb-3">
                                <p>Major</p>
                                <p className="font-bold">{e?.title || '-'}</p>
                              </div>
                              <div className="mb-3">
                                <p>School</p>
                                <p className="font-bold">{e?.school || '-'}</p>
                              </div>
                              <div className="mb-3">
                                <p>Description</p>
                                <p className="font-bold">{e?.description || '-'}</p>
                              </div>

                              <div className="p-3 bg-white rounded-lg">
                                <div className="flex gap-3 justify-between ">
                                  <div className="mb-3">
                                    <p>Start date</p>
                                    <p className="font-bold">{e?.start_date || '-'}</p>
                                  </div>
                                  <div className="mb-3">
                                    <p>End date</p>
                                    <p className="font-bold">{e?.end_date || '-'}</p>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <p>Duration</p>
                                  <p className="font-bold">{e?.duration || '-'}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )) || 'No history yet'}
                      </TabPanel>
                    </TabContext>
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { red, orange, green } from '@mui/material/colors';

function CircleMeter({ value, sx, ...props }) {
  if (value === undefined) {
    return null;
  }

  const shade = 500;
  let bgcolor = red[shade];
  if (value >= 50 && value < 90) {
    bgcolor = orange[shade];
  } else if (value >= 90 && value <= 100) {
    bgcolor = green[shade];
  }

  return (
    <Avatar sx={{ bgcolor, fontSize: 14, width: 32, height: 32, ...sx }} {...props}>
      {value}
    </Avatar>
  );
}

export default CircleMeter;

/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { Add, ModeEdit, Delete, Edit, Save, Cancel } from '@mui/icons-material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ReactShortableWrapper from '../../components/ReactShortableWrapper';
import ModalWrapper from '../../components/ui/ModalWrapper';
import ChangeNameStage from '../../components/Form/Recruitment/ChangeStageName';
import { handleModal } from '../../store/slices/modalSlice';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import { generateSelectListDefault, getDefaultSelectObject, getOptionSelectWithKey } from '../../lib/OptionsInputHelper';
import ReactSelectSingle from '../../components/Input/ReactSelectSingle';
import useGet from '../../hooks/useGet';
import request from '../../helper/axiosUtil';
import usePost from '../../hooks/usePost';
import useDelete from '../../hooks/useDelete';
import ReactSelectMultipleAsync from '../../components/Input/ReactSelectMultipleAsync';
import { ButtonBase } from '@mui/material';
import { twMerge } from 'tailwind-merge';

function filterArray(arr1, arr2) {
  return arr1?.filter((item) => arr2?.includes(item.id));
}

function deduplicateArrayObjects(array) {
  const uniqueArray = array.filter((obj, index, self) => index === self.findIndex((t) => t?.id === obj?.id));
  return uniqueArray;
}

function StagesNew({ url, validate }) {
  const { control, watch, setValue } = useForm({});
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const qClient = useQueryClient();

  const { jobPosterId } = useParams();
  const forceWatchStage = useWatch({ control });

  const [list, setListData] = useState([]);
  const [optionData, setOptionData] = useState([]);
  const [reload, setReload] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [Interview, setInterview] = useState([]);
  const [Offer, setOffer] = useState([]);
  const [Accepted, setAccepted] = useState([]);
  const [isCancel, setIsCancel] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  useGet({
    url: `${url}`,
    queryString: {
      job_poster: jobPosterId,
      client: clientId,
    },
    validate,
    onSuccess: (e) => {
      setIsRendered(false);
      const listData = e?.data?.results || e?.data;
      setIsLoaded(true);
      setIsRendered(true);
      setListData(e?.data?.results || e?.data);
      setOptionData([{ name: '-- No Selected --', id: '' }, ...listData]);
      setInterview(listData?.filter((v) => v.type === 'Interview'));
      setOffer(listData?.filter((v) => v.type === 'Offer'));
      setAccepted(listData?.filter((v) => v.type === 'Accepted'));
      const listIneterViewId = listData?.filter((v) => v.type === 'Interview');
      const listOfferId = listData?.filter((v) => v.type === 'Offer');
      const AcceptedId = listData?.filter((v) => v.type === 'Accepted');
      setValue(
        `interview`,
        listIneterViewId?.map((i) => i.id)
      );
      setValue(`offer`, listOfferId && listOfferId.length > 0 ? listOfferId[0]?.id : '');
      setValue(`accepted`, AcceptedId && AcceptedId.length > 0 ? AcceptedId[0]?.id : '');
    },
  });

  const ActivateStagesType = () => {
    setDisabled(false);
  };

  const UpdateApplicationStage = useMutation(
    (data) => {
      return request({
        url: `/${url}/${data?.id}/`,
        method: 'patch',
        data: data?.data,
      });
    },
    {
      onSettled: () => {
        qClient.invalidateQueries(validate);
      },
    }
  );

  const interviewStage = watch(`interview`);
  const offerStage = watch(`offer`);
  const acceptedStage = watch(`accepted`);

  const SaveEditStagesType = (interview, offer, accepted) => {
    try {
      if (interviewStage && interviewStage.length > 0) {
        interviewStage.forEach((val) => {
          UpdateApplicationStage.mutateAsync({ id: val, data: { type: 'Interview' } });
        });
      } else if (interview.length && interviewStage && interviewStage.length === 0) {
        interview.forEach((val) => {
          UpdateApplicationStage.mutateAsync({ id: val.id, data: { type: 'Default' } });
        });
      }

      if (offerStage) {
        UpdateApplicationStage.mutateAsync({ id: offerStage, data: { type: 'Offer' } });
      } else if (forceWatchStage?.interview?.includes(offer[0]?.id)) {
        UpdateApplicationStage.mutateAsync({ id: offer[0]?.id, data: { type: 'Interview' } });
      } else UpdateApplicationStage.mutateAsync({ id: offer[0]?.id, data: { type: 'Default' } });

      if (acceptedStage) {
        UpdateApplicationStage.mutateAsync({ id: acceptedStage, data: { type: 'Accepted' } });
      } else if (forceWatchStage?.interview?.includes(accepted[0]?.id)) {
        UpdateApplicationStage.mutateAsync({ id: accepted[0]?.id, data: { type: 'Interview' } });
      } else UpdateApplicationStage.mutateAsync({ id: accepted[0]?.id, data: { type: 'Default' } });
      setDisabled(true);

      return swal('Change Stages type Successfully!', { icon: 'success' }), setReload(true);
    } catch (error) {
      console.error('error ', error);
    }
  };

  const options = optionData && optionData?.length > 0 ? optionData : [];

  const { mutate: UpdateSectionWidgetOrder } = usePatchOrPut({
    url: `${url}/update_indexes/?job_poster=${jobPosterId}`,
    validate: [validate],
    method: 'put',
  });

  const onPatchOrder = useCallback(() => {
    UpdateSectionWidgetOrder({ application_stage_ids: list?.map((w) => w.id) });
  }, [UpdateSectionWidgetOrder, list]);

  const { mutate: postStage } = usePost({ url: `${url}/?job_poster=${jobPosterId}`, validate: [validate] });

  const destroyStage = useDelete({
    confText: `modals.stage_delete.confirmation_text`,
    confTitle: `modals.stage_delete.title`,
    success: 'Stage deleted',
    validate: [validate],
  });

  const onDestroyStage = (id) => {
    destroyStage(`${url}/${id}`);
  };

  const { mutate: updateStageName } = usePatchOrPut({ url: `${url}/${modal.modalId}`, validate: [validate, 'JobPoster'] });

  const isNewOption = deduplicateArrayObjects(optionData);

  function resetSingle() {
    let deletedVal;
    if (forceWatchStage?.offer && forceWatchStage?.interview?.includes(forceWatchStage?.offer)) {
      deletedVal = forceWatchStage?.interview.filter((int) => int !== forceWatchStage?.offer);
    }
    if (forceWatchStage?.accepted && forceWatchStage?.interview?.includes(forceWatchStage?.accepted)) {
      deletedVal = forceWatchStage?.interview.filter((int) => int !== forceWatchStage?.accepted);
    }

    return deletedVal;
  }

  return (
    <>
      <div className="space-y-5">
        <div className="flex space-x-4">
          <div className="grid grid-cols-3 gap-4 w-9/12">
            <div>
              <Controller
                name="interview"
                control={control}
                render={({ field: { onChange } }) => (
                  <ReactSelectMultipleAsync
                    options={isNewOption.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    isCancel={isCancel}
                    isRendered={isRendered}
                    setIsRendered={setIsRendered}
                    setIsCancel={setIsCancel}
                    defaultValue={generateSelectListDefault(filterArray(isNewOption, watch(`interview`)))}
                    currValue={forceWatchStage?.interview}
                    placeholder="Interview Stages"
                    resetThisReplaceValue={resetSingle()}
                    resetReplaceValue={() => {
                      if (forceWatchStage?.offer && forceWatchStage?.interview?.includes(forceWatchStage?.offer)) {
                        return setValue('offer', '');
                      }
                      if (forceWatchStage?.accepted && forceWatchStage?.interview?.includes(forceWatchStage?.accepted)) {
                        return setValue('accepted', '');
                      }
                    }}
                    isDisabled={disabled}
                    isMulti
                    onChange={(v) => onChange(v?.map((val) => val.value))}
                  />
                )}
              />
            </div>
            <ReactSelectSingle
              name="offer"
              control={control}
              isDisabled={disabled}
              options={getOptionSelectWithKey(isNewOption)}
              defaultValue={getDefaultSelectObject(watch(`offer`), getOptionSelectWithKey(isNewOption))}
              placeholder="Offer Stage"
              currValue={forceWatchStage?.offer}
              resetReplaceValue={() => {
                if (forceWatchStage?.offer === forceWatchStage?.accepted) {
                  setValue('accepted', null);
                }
              }}
            />
            <ReactSelectSingle
              name="accepted"
              control={control}
              isDisabled={disabled}
              options={getOptionSelectWithKey(isNewOption)}
              defaultValue={getDefaultSelectObject(watch(`accepted`), getOptionSelectWithKey(isNewOption))}
              placeholder="Accepted Stage"
              currValue={forceWatchStage?.accepted}
              resetReplaceValue={() => {
                if (forceWatchStage?.accepted === forceWatchStage?.offer) {
                  setValue('offer', null);
                }
              }}
            />
          </div>

          <div className="flex sapce-x-2 items-center">
            {' '}
            {!disabled && (
              <ButtonBase
                className="bz-btn bg-orange-500 mr-2 px-4 py-2"
                onClick={() => {
                  // ActivateStagesType(stagesList.id);
                  setValue(`interview`, Interview?.map((i) => i.id) || '');
                  setValue(`offer`, Offer[0]?.id);
                  setValue(`accepted`, Accepted[0]?.id);
                  setIsCancel(true);
                  setTimeout(() => {
                    setDisabled(true);
                  }, 200);
                }}
              >
                <div className="flex gap-x-2">
                  <Cancel />
                  <p>Cancel</p>
                </div>
              </ButtonBase>
            )}
            <ButtonBase
              className={twMerge(`bz-btn mr-2 px-4 py-2`)}
              onClick={() => (!disabled ? SaveEditStagesType(Interview, Offer, Accepted) : ActivateStagesType())}
            >
              {!disabled ? (
                <>
                  <Save />
                  <p>Save</p>
                </>
              ) : (
                <>
                  <Edit />
                  <p>Edit</p>
                </>
              )}
            </ButtonBase>
          </div>
        </div>
        <div className="flex flex-wrap gap-4">
          <div className="">
            <ReactShortableWrapper
              swap
              animation={150}
              ghostClass="blue-background-class"
              onPatch={onPatchOrder}
              list={list}
              setList={setListData}
              className="h-full flex gap-4 flex-wrap bg-white"
            >
              {list?.map((stagesList, idx) => (
                <div key={stagesList.id} className="border  rounded relative w-[200px] h-[120px]">
                  <div className="w-full relative bg-gray-400 p-2 rounded-t">
                    <div className="w-full gap-x-3 ml-auto flex justify-between">
                      <button className="text-white font-bold" type="button">
                        {`${idx + 1}. ${stagesList.name}`}
                      </button>
                      <button
                        className=""
                        type="button"
                        onClick={() =>
                          dispatch(
                            handleModal({
                              modalId: stagesList.id,
                              componentName: 'ChangeStageName',
                              modalData: { name: stagesList.name, list: stagesList, stageId: stagesList.id },
                            })
                          )
                        }
                      >
                        <ModeEdit className="w-5 h-5 text-white" />
                      </button>
                    </div>
                  </div>
                  <div className="absolute right-0 bottom-0">
                    {stagesList.type === 'Default' && (
                      <button type="button">
                        <Delete className="text-red-500" onClick={() => onDestroyStage(stagesList?.id)} />
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <div
                className={
                  options?.length <= 7
                    ? 'border-dashed border-2 border-black rounded relative w-[200px] h-[120px] cursor-pointer flex justify-center items-center '
                    : 'border-dashed border-2 border-zinc-300 rounded relative w-[200px] h-[120px] flex justify-center items-center pointer-events-none'
                }
                onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addStage' }))}
              >
                <Add sx={{ color: options?.length <= 7 ? '#000000' : '#d4d8d4' }} />
              </div>
            </ReactShortableWrapper>
          </div>
        </div>
      </div>
      <ModalWrapper componentName="ChangeStageName" header="Change Stage Name" maxWidth="sm">
        <ChangeNameStage reload={setReload} isApplicationStage onFormSubmit={updateStageName} />
      </ModalWrapper>
      <ModalWrapper componentName="addStage" header="Add Stage" maxWidth="sm">
        <ChangeNameStage reload={setReload} onFormSubmit={postStage} isApplicationStage />
      </ModalWrapper>
    </>
  );
}

export default StagesNew;

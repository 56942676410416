import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import { Autocomplete, Avatar, Box, Button, ButtonBase, Chip, InputBase, MenuItem, OutlinedInput, Select, Skeleton, TextField, Tooltip } from '@mui/material';
import { Add, Article, AttachFile, Check, Close, Link as LinkIcon, MoreHoriz, Notifications, Person, PlaylistAdd } from '@mui/icons-material/';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'notistack';

import Comment from '../../../components/ui/Task/DrawerTask/Comment';
import ProfileDetail from '../../../components/ui/Task/ProfileDetail';

import projectApi from '../../../api/task/project';
import userApi from '../../../api/user';
import formatterInitialName from '../../../helper/formatterInitialName';

import styles from './TaskDetail.module.css';
import BZHelmet from '../../../utils/BZHelmet';

const drawerTheme = createTheme({
  components: {
    MuiDrawer: { styleOverrides: {} },
    MuiBackdrop: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({ background: 'transparent' }) } },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => theme.unstable_sx({}),
        notchedOutline: {
          borderColor: 'rgba(0, 0, 0, 0.23) !important',
          borderWidth: '1px !important',
        },
      },
    },
  },
});

const variants = {
  closed: { minHeight: '80px' },
  open: { minHeight: '172px' },
};
const variantsBtn = {
  closed: { opacity: 0, y: 0 },
  open: { opacity: 1, y: 0, transition: { delay: 0.3 } },
};

export default function TaskDetail() {
  const { enqueueSnackbar } = useSnackbar();
  const { formatter } = formatterInitialName();
  const myParam = useParams();
  const idTask = myParam.taskId;
  const [task, setTask] = useState(false);
  const [users, setUsers] = useState(false);
  const [labels, setLabels] = useState([]);
  const [topicTitle, setTopicTitle] = useState(false);
  const [dueDate, setDueDate] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState(false);

  const [isFocusedComment, setIsFocusedComment] = useState(false);
  const [commentData, setIsCommentData] = useState('');
  const [filesComment, setFilesComment] = useState([]);
  const [filesAttachments, setFilesAttachments] = useState([]);
  const [anchorAssignee, setAnchorAssignee] = useState(null);

  const [anchorProfile, setAnchorProfile] = useState(null);
  const [userDetail, setUserDetail] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: '*',
    onDrop: (acceptedFiles) => {
      console.log('🚀 ~ file: DrawerTask.jsx ~ line 60 ~ DrawerTask ~ acceptedFiles', acceptedFiles);
      setFilesComment(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
    },
  });
  const isCreator = useSelector((state) => state.auth.user?.id);
  const isUsername = useSelector((state) => state.auth.user?.name);
  const userClient = useSelector((state) => state.auth.client?.id);

  const getUsersClient = useCallback(() => {
    const { getUsers } = userApi();

    getUsers({ client: userClient })
      .then((res) => {
        if (res.status === 200 || res.data.results) {
          setUsers(res.data.results);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [userClient]);

  const getTopicDetails = useCallback(() => {
    const { getTopicDetail } = projectApi();
    getTopicDetail(idTask)
      .then((res) => {
        if (res.status === 200) {
          setTopicTitle(res.data.name);
          setDueDate(res.data.due_at);
          setSelectedLabels(res.data.labels);
          const attachments = [];
          res.data.comments.forEach((comment) => {
            if (comment.attachments.length) {
              comment.attachments.forEach((att) => {
                attachments.push(att);
              });
            }
          });
          setFilesAttachments(attachments);
          setTask(res.data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [idTask]);

  const getLabel = useCallback(() => {
    const { getLabels } = projectApi();
    getLabels()
      .then((res) => {
        if (res.status === 200) {
          setLabels(res.data.results);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  const getUserDetails = useCallback((idUser) => {
    const { getUserDetail } = userApi();
    getUserDetail(idUser)
      .then((res) => {
        if (res.status === 200) {
          setUserDetail(res.data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    if (!task && idTask) {
      getUsersClient();
      getLabel();
      getTopicDetails();
    }
  }, [task, idTask, getTopicDetails, getLabel, getUsersClient]);

  const handleCopyTaskLink = () => {
    const urlLink = `${window.location.origin}/task/detail/${idTask}`;
    navigator.clipboard.writeText(urlLink);
    enqueueSnackbar('Link copied to clipboard' || 'Success', { variant: 'success' });
  };

  const handleTopicUpdate = (data) => {
    const { updateSectionTopic } = projectApi();

    updateSectionTopic(idTask, data)
      .then((res) => {
        if (res.status === 200) {
          getTopicDetails();
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const onBlurTopicTitle = (event) => {
    const title = event.target.value;
    if (title) {
      handleTopicUpdate({ name: title });
    }
  };

  const onChangeTaskAssignee = (event) => {
    const userName = event.target.innerText;
    if (userName) {
      const user = users.filter((u) => u.name === userName);
      if (user) {
        handleTopicUpdate({ assignee: user[0].id });
      }
    }
  };

  const onTopicCompleted = () => {
    const dateTopic = new Date();
    handleTopicUpdate({ completed_at: task.completed_at ? null : dateTopic });
  };

  const onChangeLabels = (event) => {
    const value = event.target.value.slice(-1);
    const isLabelExist = selectedLabels.filter((lbl) => lbl.id === value[0]);

    if (!isLabelExist.length) {
      const getLabelData = labels.filter((lbl) => lbl.id === value[0]);
      const newLabels = [...selectedLabels, getLabelData[0]];
      const uniqueLabels = [...new Map(newLabels.map((item) => [item.name, item])).values()];
      setSelectedLabels(uniqueLabels);
    } else {
      const newLabels = selectedLabels.filter((lbl) => lbl.id !== value[0]);
      setSelectedLabels(newLabels);
    }
  };

  const handleFocusComment = () => {
    setIsFocusedComment(true);
  };

  const handleChangeComment = (event) => {
    const { value } = event.target;
    setIsCommentData(value);
  };

  const handlePostComment = () => {
    if (commentData || filesComment.length) {
      const { createComments } = projectApi();

      createComments({
        topic: idTask,
        creator: isCreator,
        detail: commentData,
        attachments: filesComment,
      })
        .then((res) => {
          if (res.data) {
            setIsCommentData('');
            setIsFocusedComment(false);
            setFilesComment([]);
            getTopicDetails();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else {
      setIsFocusedComment(false);
    }
  };

  const handleRemoveAttachment = (name) => {
    setFilesComment(filesComment.filter((file) => file.name !== name));
  };

  const thumbsComment = filesComment.map((file) => {
    const { name, type } = file;
    const fileExt = name.substring(name.lastIndexOf('.') + 1);
    const fileType = type.substring(0, type.indexOf('/'));
    let html;
    const closeHtml = (
      <div className="absolute top-0 right-1 z-10 cursor-pointer" style={{ color: 'rgba(255, 255, 255, .5)' }}>
        <Close
          fontSize="small"
          color="inherit"
          onClick={() => handleRemoveAttachment(file.name)}
          className="bg-gray-700 bg-opacity-50 border border-gray-300 rounded-full shadow-sm"
        />
      </div>
    );
    if (type.includes('image') || fileType === 'image') {
      html = (
        <div key={file.name} className="relative bg-white p-1 border border-[#eaeaea] rounded-md h-16 min-w-[64px] max-w-[192px] flex items-center justify-center">
          {closeHtml}
          <img src={file.preview} className="w-auto h-full object-fill" alt="" />
        </div>
      );
    } else {
      html = (
        <div key={file.name} className="relative bg-[#eaeaea] py-1 pl-2 pr-8 border border-[#eaeaea] rounded-md h-16 min-w-[64px] max-w-[192px] flex items-center justify-center">
          {closeHtml}
          <div className="flex space-x-1 items-center">
            <Article color="disabled" fontSize="large" />
            <div className="flex flex-col max-w-[112px]">
              <span className="text-ellipsis text-sm whitespace-nowrap overflow-hidden">{name}</span>
              <span className="text-xs text-gray-500">{`${fileExt} File`}</span>
            </div>
          </div>
        </div>
      );
    }
    return html;
  });

  useEffect(() => {
    setIsFocusedComment(false);
  }, []);

  const dropZoneAttachments = useDropzone({
    // accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const { createComments } = projectApi();

      createComments({
        topic: idTask,
        creator: isCreator,
        detail: commentData,
        attachments: acceptedFiles,
      })
        .then((res) => {
          if (res.data) {
            getTopicDetails();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
  });

  const onClickAssignee = (event) => {
    setUserDetail(false);
    setAnchorProfile(null);
    setAnchorAssignee(event.currentTarget);
  };

  const openAssignee = Boolean(anchorAssignee);
  const idAssignee = openAssignee ? 'simple-popper' : undefined;

  const handleOnPaste = (e) => {
    const { files } = e.clipboardData;
    if (files.length) {
      const arrFiles = [];
      for (let index = 0; index < files.length; index += 1) {
        arrFiles.push(Object.assign(files[index], { preview: URL.createObjectURL(files[index]) }));
      }
      setFilesComment(arrFiles);
    }
  };

  const openProfile = Boolean(anchorProfile);
  const idProfile = openProfile ? 'simple-popper' : undefined;
  return (
    <ThemeProvider theme={drawerTheme}>
      <BZHelmet title="Task Detail" description="bz publish task management detail task" />
      <div className={styles.drawerContainer}>
        <div className={styles.drawerCard}>
          <div className={styles.drawerCardTitle}>
            <div className="flex justify-between px-6 items-center h-12 border-b border-gray-200">
              {!task ? (
                <Skeleton width="100%" />
              ) : (
                <>
                  <div>
                    <Button className={[styles.drawerTaskComplete, task.completed_at && 'bg-green-200'].join(' ')} startIcon={<Check fontSize="12" />} onClick={onTopicCompleted}>
                      {task.completed_at ? 'Completed' : 'Mark task as complete'}
                    </Button>
                  </div>
                  <div>
                    <div className="flex space-x-2 items-center">
                      <Tooltip title="Attachments" arrow>
                        <AttachFile fontSize="20" />
                      </Tooltip>
                      <Tooltip title="Subtasks" arrow>
                        <PlaylistAdd fontSize="20" />
                      </Tooltip>
                      <Tooltip title="Copy task" arrow onClick={handleCopyTaskLink}>
                        <LinkIcon fontSize="20" />
                      </Tooltip>
                      <Tooltip title="More actions" arrow>
                        <MoreHoriz fontSize="20" />
                      </Tooltip>
                      <Tooltip title="Close detail" arrow>
                        <Close fontSize="20" />
                      </Tooltip>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.drawerCardContent}>
            {!task ? (
              <div className="flex flex-col items-start w-full pl-3 pr-6 space-y-2">
                <Skeleton width="50%" />
                <Skeleton width="50%" />
                <Skeleton width="50%" />
                <Skeleton width="100%" />
              </div>
            ) : (
              <>
                <div className="flex items-start w-full pl-3 pr-6 mb-4">
                  <div className="w-full">
                    <TextField name="" id="" label="" className={styles.textTitle} defaultValue={topicTitle} multiline onBlur={onBlurTopicTitle} />
                  </div>
                </div>
                <div className="w-full pl-7 pr-6 space-y-4">
                  <div className="flex w-full items-center">
                    <div className="w-1/4 pr-2 text-left">
                      <span className={styles.taskLabel}>Creator</span>
                    </div>
                    <div className="w-3/4 flex items-center">
                      <Avatar className="w-7 h-7 mr-3">
                        <span className="text-xs">{task.creator && formatter(task.creator.name)}</span>
                      </Avatar>
                      <div className="flex flex-col">
                        <span className={styles.taskLabel}>{task.creator?.name}</span>
                        <span className={styles.taskLabel}>{format(new Date(task.created_at), 'MMM d, yyyy')}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="w-1/4 pr-2 text-left">
                      <span className={styles.taskLabel}>Assignee</span>
                    </div>
                    <div className="w-3/4 flex space-x-1">
                      <ButtonBase
                        aria-describedby={idAssignee}
                        onClick={onClickAssignee}
                        onMouseEnter={(event) => {
                          if (task.assignee) {
                            setAnchorProfile(event.currentTarget);
                            if (!userDetail) {
                              getUserDetails(task.assignee.id);
                            }
                          }
                        }}
                        onMouseLeave={() => {
                          setUserDetail(false);
                          setAnchorProfile(null);
                        }}
                      >
                        <div className="border rounded-full border-dashed border-gray-300 w-7 h-7 flex justify-center items-center mr-3">
                          {task.assignee?.name ? <span className="text-xs">{formatter(task.assignee?.name)}</span> : <Person fontSize="small" color="disabled" />}
                        </div>
                        {!anchorAssignee && !task.assignee?.name && <span className={[styles.taskLabel, styles.taskLabelContent].join(' ')}>No assignee</span>}
                        {!anchorAssignee && task.assignee?.name && <span className={[styles.taskLabel, styles.taskLabelContent].join(' ')}>{task.assignee?.name}</span>}
                      </ButtonBase>
                      <ProfileDetail idProfile={idProfile} openProfile={openProfile} anchorProfile={anchorProfile} userDetail={userDetail} />
                      {anchorAssignee && (
                        <Autocomplete
                          id=""
                          sx={{ width: 300, height: 28 }}
                          options={users}
                          autoHighlight
                          getOptionLabel={(option) => option.name}
                          onBlur={() => setAnchorAssignee(null)}
                          onChange={(event) => {
                            onChangeTaskAssignee(event);
                          }}
                          blurOnSelect
                          renderOption={(props, option) => (
                            <Box key={option.name} component="li" {...props}>
                              <div className="w-6 h-6 border rounded-full text-xs flex justify-center items-center mr-2">
                                <span>{formatter(option.name)}</span>
                              </div>
                              <span className="text-ellipsis">{option.name}</span>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              placeholder={task.assignee?.name ? task.assignee.name : 'No assignee'}
                              variant="standard"
                              sx={{
                                '& > div': {
                                  padding: 0,
                                  border: 'none',
                                  outline: 'none',
                                },
                              }}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/4 pr-2 text-left">
                      <span className={styles.taskLabel}>Due date</span>
                    </div>
                    <div className="w-3/4">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={!dueDate ? task.due_at : dueDate}
                          onChange={(newValue) => {
                            handleTopicUpdate({ due_at: newValue });
                          }}
                          renderInput={(params) => <TextField className={styles.datePicker} {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/4 pr-2 text-left">
                      <span className={styles.taskLabel}>Project</span>
                    </div>
                    <div className="w-3/4">
                      <span className={styles.taskLabel}>{task.project?.name}</span>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/4 pr-2 text-left">
                      <span className={styles.taskLabel}>Tags</span>
                    </div>
                    <div className="w-3/4 flex space-x-2">
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={selectedLabels}
                        onChange={onChangeLabels}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Tags</em>;
                          }
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value, index) => (
                                <Chip
                                  key={`${value.id + index}`}
                                  label={value.name}
                                  style={{
                                    background: value.color,
                                    color: '#fff',
                                  }}
                                />
                              ))}
                            </Box>
                          );
                        }}
                        style={{ width: 250 }}
                        MenuProps={{ PaperProps: { style: { width: 250 } } }}
                        inputProps={{ 'aria-label': 'tag' }}
                      >
                        {/* <Box className="flex flex-col space-y-1 w-auto h-40 overflow-y-auto"> */}
                        <MenuItem disabled value="">
                          <em>Tags</em>
                        </MenuItem>
                        {labels.map((label) => (
                          <MenuItem
                            key={label.id}
                            value={label.id}
                            style={{
                              background: label.color,
                              color: '#fff',
                              padding: '.5rem',
                              minHeight: '2rem',
                            }}
                            className={[styles.taskLabel, 'w-min mb-2 flex items-center h-8 rounded-2xl ml-2'].join(' ')}
                          >
                            {label.name}
                          </MenuItem>
                        ))}
                        {/* </Box> */}
                      </Select>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-30 pr-2 text-left mb-1">
                      <span className={styles.taskLabel}>Description</span>
                    </div>
                    <div className="w-full">
                      <TextField
                        name=""
                        id=""
                        label=""
                        className={[styles.textTitle, styles.textTitleDesc].join(' ')}
                        placeholder={task.description || 'Add description'}
                        multiline
                        defaultValue={task.description}
                        onBlur={(e) => {
                          setTimeout(() => {
                            handleTopicUpdate({ description: e.target.value });
                          }, 300);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="pl-7 mt-4 mb-2 flex space-x-2 flex-wrap">
                  {filesAttachments.length > 0 &&
                    filesAttachments.map((file) => (
                      <div key={file.created_at} className="min-w-[60px] max-w-full h-16 flex justify-center overflow-hidden mb-2">
                        <img className="max-w-[160px] h-full" src={file.file_attached} alt="" />
                      </div>
                    ))}

                  <div
                    className="w-[60px] h-[60px] p-2 flex items-center justify-center border border-dashed border-gray-300 rounded-md"
                    {...dropZoneAttachments.getRootProps()}
                    aria-hidden
                  >
                    <input {...dropZoneAttachments.getInputProps()} />
                    <Add />
                  </div>
                </div>

                <div className="w-full bg-[#F9F8F8] overflow-y-auto">
                  {task.comments.length > 0 &&
                    task.comments.map((comment) => <Comment key={comment.id} comment={comment} isUsername={isUsername} getTopicDetails={getTopicDetails} />)}
                </div>
              </>
            )}
          </div>
          <div className={styles.drawerCardComment}>
            <div className="px-4 py-2 w-full flex flex-col">
              {!task ? (
                <Skeleton variant="rectangular" width="100%" height={32} />
              ) : (
                <>
                  <div className="flex w-full" onPaste={handleOnPaste}>
                    <Avatar className="w-8 h-8 drop-shadow-2xl bg-red-300 mr-2">A</Avatar>
                    <motion.div
                      className="w-full flex flex-col border rounded-xl p-2"
                      animate={isFocusedComment ? 'open' : 'closed'}
                      transition={{ type: 'tween' }}
                      variants={variants}
                      initial="closed"
                      // animate="open"
                    >
                      <InputBase
                        placeholder="Ask a question or post an update"
                        multiline
                        className="ml-2 w-full border-0"
                        value={commentData}
                        onFocus={handleFocusComment}
                        // onBlur={handlePostComment}
                        onChange={handleChangeComment}
                        onKeyDown={() => {}}
                      />
                      <aside className="flex flex-wrap my-2 space-x-2">{thumbsComment}</aside>
                      <motion.div className="w-full flex mt-auto" animate={isFocusedComment ? 'open' : 'closed'} transition={{ type: 'spring' }} variants={variantsBtn}>
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <AttachFile />
                        </div>
                        <ButtonBase className="p-2 ml-auto bg-blue-500 text-white rounded-xl" onClick={handlePostComment}>
                          Comment
                        </ButtonBase>
                      </motion.div>
                    </motion.div>
                  </div>
                  <div className="w-full flex justify-between items-center py-2 pl-10">
                    <div className="flex space-x-2 items-center">
                      <span>Collaborator</span>
                      <Avatar className="w-6 h-6">B</Avatar>
                      <Avatar className="w-6 h-6" />
                      <Add fontSize="14" />
                    </div>
                    <Button startIcon={<Notifications fontSize="14" />}>Leave task</Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

import { Autocomplete, Chip, TextField } from '@mui/material';
import React from 'react';
import { SxTextField } from '../../../../Input/Base/BaseAutocompleteValidation';

export default function AutocompleteDependencies({ param, options, values, onChange }) {
  return (
    <Autocomplete
      freeSolo
      multiple
      autoHighlight
      id={`autocomplete-${param}`}
      options={options}
      getOptionLabel={(option) => option && option.name}
      // isOptionEqualToValue={(option, newValue) => {
      //   return option.id === newValue.id;
      // }}
      className="w-full"
      onChange={(event, dataOption) => {
        onChange(param, dataOption);
      }}
      value={values?.length ? values.map((dt) => dt) : []}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <div>
              <span style={{ fontWeight: option.highlight ? 700 : 400 }}>{`${option.id}. ${option.name}`}</span>
            </div>
          </li>
        );
      }}
      renderTags={(value, getTagProps) => {
        return value?.map(
          (option, index) =>
            option && <Chip sx={{ height: 28, borderRadius: 1 }} key={`dep-${option.id}`} variant="outlined" label={`${option.id}. ${option.name}`} {...getTagProps({ index })} />
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="select dependencies"
          label=""
          sx={SxTextField}
          InputProps={{
            ...params.InputProps,
            type: 'search',
          }}
        />
      )}
    />
  );
}

import React from 'react';
import BZHelmet from '../../../utils/BZHelmet';
import BaseMainTitle from '../../../components/BaseMainTitle';
import EmployeeNewForm from '../../../components/Form/HR/EmployeeNewForm';
import usePost from '../../../hooks/usePost';
import BaseBackButton from '../../../components/Button/BaseBackButton';

export default function EmployeeAdd() {
  const { mutate: updateEmployee } = usePost({ url: `employee-management/employees`, validate: ['employees'] });

  const onUpdateEmployee = async (data) => {
    await updateEmployee(data);
  };

  return (
    <>
      <BZHelmet title="Employee Add" description="" content="" />
      <div>
        <BaseMainTitle title="Employee ADD :" />
        <div className="p-5 ">
          <div className="bg-white border p-5 space-y-3 shadow rounded">
            <BaseBackButton />
            <EmployeeNewForm onFormSubmit={onUpdateEmployee} cancel="/hr/employee" />
          </div>
        </div>
      </div>
    </>
  );
}

import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

export default function CustomCheckbox({ defaultChecked = false, label, onChange, labelPlacement, disabled, hidden }) {
  return (
    <FormControlLabel
      hidden={hidden}
      value
      control={<Checkbox onChange={onChange} defaultChecked={defaultChecked} />}
      label={label}
      labelPlacement={labelPlacement}
      disabled={disabled}
    />
  );
}

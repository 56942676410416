import { ReactElement } from 'react';
import Select, { GroupBase, OptionsOrGroups, SingleValue } from 'react-select';
import clsxm from '@/utils/clsxm';
import BaseHelper from '../Base/BaseHelper';
import BaseLabel from '../Base/BaseLabel';
import { SelectedItemType } from '@/types';

type ReactSelectSingleProps = {
  options: OptionsOrGroups<{ name: string; id: string }, GroupBase<{ name: string; id: string }>>;
  defaultValue?: SingleValue<{ name: string; id: string }>;
  getOptionLabel?: (option: { name: string; id: string }) => string;
  getOptionValue?: (option: { name: string; id: string }) => string;
  isSearchable?: boolean;
  errors?: string; // Consider defining a more specific type
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  helperText?: string;
  onChange: (selectedItem: SelectedItemType[]) => void;
};

const ReactSelectMultiNonRHF = ({
  options,
  defaultValue,
  getOptionLabel = (option) => option.name,
  getOptionValue = (option) => option.id,
  isSearchable = true,
  errors,
  isClearable = false,
  isDisabled = false,
  isLoading = false,
  label,
  helperText,
  onChange,
}: ReactSelectSingleProps): ReactElement => {
  const componentsConfig = {
    IndicatorSeparator: () => null,
  };

  return (
    <div className="w-full">
      {label && <BaseLabel value={label} className={clsxm(errors ? 'text-[#D02B20]' : '')} />}

      <Select
        isLoading={isLoading}
        isMulti
        classNamePrefix={errors ? 'react-select-error' : 'react-select'}
        defaultValue={defaultValue}
        menuPlacement="auto"
        hideSelectedOptions={false}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={isDisabled}
        onChange={(newValue: unknown) => {
          onChange(newValue as SelectedItemType[]);
          // setSelected(newValue as SelectedItemType);
        }}
        components={componentsConfig}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder="Select or enter a value"
        // {...field}
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: '4px',
            padding: '7.5px 16px',
            minHeight: 'unset',
          }),
          container: (provided) => ({
            ...provided,
          }),
          valueContainer: (provided) => ({
            ...provided,
            overflow: 'visible',
            color: 'black',
            padding: 0,
            fontWeight: 400,
            fontSize: '0.875rem',
            // height: '24px',
          }),
          multiValue: (provided) => ({
            ...provided,
            color: 'black',
            fontWeight: 400,
            margin: '2px 2px',
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            padding: 0,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            padding: 0,
          }),
          clearIndicator: (prevStyle) => ({
            ...prevStyle,
            padding: 0,
          }),

          placeholder: (provided, state) => ({
            ...provided,
            display: state.hasValue ? 'none' : 'block',
            border: '1px, solid, red',
            position: 'absolute',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
            background: state.hasValue ? 'white' : 'transparent',

            color: !state.hasValue && !state.selectProps.inputValue ? 'rgba(0, 0, 0, 0.42)' : 'transparent',
          }),
          input: (provided) => ({
            ...provided,
            fontSize: '0.875rem',
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 1000,
          }),
        }}
      />

      <BaseHelper helperText={errors || helperText || ''} className={clsxm(errors ? 'text-[#D02B20]' : '')} />
    </div>
  );
};

ReactSelectMultiNonRHF.defaultProps = {
  defaultValue: undefined, // Or your preferred default value
  getOptionLabel: undefined, // It's actually set in destructuring assignment
  getOptionValue: undefined, // It's actually set in destructuring assignment
  isSearchable: true,
  isClearable: false,
  isDisabled: false,
  label: '',
  helperText: '',
  errors: '',
};

export default ReactSelectMultiNonRHF;

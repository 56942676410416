import BaseBackButton from '@/components/Button/BaseBackButton';
import ClientForm from '@/components/Form/Client/ClientForm';
import Website from '@/components/Form/Client/Website';
import useGet from '@/hooks/useGet';
import usePatchOrPut from '@/hooks/usePatchOrPut';
import BZHelmet from '@/utils/BZHelmet';
import { useParams } from 'react-router';

export default function ClientDetail() {
  const { clientId } = useParams();

  const { data: ClientDetail } = useGet({ url: `clients/${clientId}`, validate: `client/${clientId}` });

  const { mutate: patchClient } = usePatchOrPut({
    url: `clients/${clientId}`,
    validate: ['client'],
    method: 'put',
  });

  const onSubmitClient = async (data) => {
    await patchClient(data);
  };

  return (
    <>
      <BZHelmet title="Clients" description="bz publish task management clients" />
      <div className="w-full max-w-[1200px] py-4 px-4  mx-auto">
        <div className="flex justify-between items-center">
          <div className="mr-6 text-5xl font-extrabold leading-[125%]">Clients</div>
          <BaseBackButton navTo="/superuser/clients" text="Back To Client List" />
        </div>

        <div className="mt-8">
          <div className="mb-4 text-xl font-semibold">Informations</div>
          <div className="border p-4">
            <ClientForm defaultValues={ClientDetail?.data} key={ClientDetail?.data?.id} onFormSubmit={onSubmitClient} />
          </div>
        </div>
        <div className="mt-8">
          <div className="mb-4 text-xl font-semibold">Websites</div>
          <div className="border p-4">
            <div className="">
              <Website sites={ClientDetail?.data?.sites || []} />
              {/* ))} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/* eslint-disable */
import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, ButtonBase, CircularProgress, TableCell, TableRow } from '@mui/material';
import { DeleteForever, Edit } from '@mui/icons-material';
import BaseMainTitle from '../../../components/BaseMainTitle';
import { useDepartmentDetail, useUpdateDepartment } from '../../../hooks/useWhistleblowerData';
import BZHelmet from '../../../utils/BZHelmet';
import { handleModal } from '../../../store/slices/modalSlice';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import DepartmentForm from '../../../components/Form/Whistleblower/DepartmentForm';
import ReactSelectSingle from '../../../components/Input/ReactSelectSingle';
import useGet from '../../../hooks/useGet';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import useDelete from '../../../hooks/useDelete';
import usePost from '../../../hooks/usePost';
import RenderResults from '../../../components/RenderResults';
import CustomTable from '../../../components/Table/CustomTable';
import { useQuery } from '@tanstack/react-query';
import { fetchFilterDepartment } from '../../../hooks/useNewHrData';
import useDebounce from '../../../hooks/useDebounce';
import InputContainer from '../../../components/Input/InputContainer';
import EmailReportForm from '../../../components/Form/Whistleblower/EmailReportForm';

const headCellsDepartment = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Department',
  },
  // {
  //   id: 'business_unit',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Business Unit',
  // },
  {
    id: 'business_unit',
    numeric: false,
    disablePadding: false,
    label: 'Business Unit',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    headerAlign: 'center',
    label: 'Action',
  },
];

function ActionCell(props) {
  const { id } = props;
  const dispatch = useDispatch();

  const destroyDepartment = useDelete({
    confText: `modals.department_delete.confirmation_text`,
    confTitle: `modals.department_delete.title`,
    success: 'Department deleted',
    validate: ['Departments'],
  });

  const removeDepartment = () => {
    destroyDepartment(`employee-management/departments/${id}`);
  };

  return (
    <div className="w-full flex space-x-2 justify-center">
      <ButtonBase onClick={() => dispatch(handleModal({ modalId: id, componentName: 'editDepartment' }))} className="bz-btn-tb" type="button">
        <Edit />
      </ButtonBase>
      {/* <ButtonBase className="bz-btn-tb" onClick={removeDepartment}>
          <Delete />
        </ButtonBase> */}
    </div>
  );
}

export default function EmailReport() {
  const { control, watch } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const unitId = watch('business_unit');

  const dispatch = useDispatch();

  const [dataDepartment, setDataDepartment] = useState([]);
  const [unitData, setunitData] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const searchInput = watch('search');

  const debouncedSearchInput = useDebounce(searchInput, 500);
  // const { data: unitData, isSuccess: isUnitLoded } = useBusinessunitData(clientId);

  useGet({
    url: `accounting/business_units`,
    queryString: {
      client: clientId,
      max_size: true,
      search: '',
    },
    validate: 'BusinessUnitsEmail',
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setunitData([{ name: '-- No Selected --', id: '' }, ...listData]);
    },
  });

  let isAutoFetchingDepartment;
  if (modal.componentName === 'editDepartment') {
    isAutoFetchingDepartment = true;
  } else isAutoFetchingDepartment = false;

  const departmentId = modal.modalId;

  const { data: DepartmentDetail } = useDepartmentDetail(departmentId, isAutoFetchingDepartment);
  const { mutate: updateDepartment } = useUpdateDepartment(departmentId, DepartmentDetail?.data);

  const { mutate: postDepartment } = usePost({ url: 'employee-management/departments', validate: ['Departments'] });

  const onPostDepartment = async (postData) => {
    await postDepartment(postData);
  };

  const optionUnit = unitData?.map((item) => ({
    label: `${item?.name} ${item?.code ? `(${item.code})` : ''}`,
    value: item?.id,
  }));

  const sortAlphaUnit = optionUnit?.sort((a, b) => a.label.normalize().localeCompare(b.label.normalize()));

  const queryKey = {
    client: clientId,
    business_unit: unitId,
    search: debouncedSearchInput,
    page,
    page_size: rowsPerPage,
  };

  const { isFetching } = useQuery(['Departments', { queryKey }], fetchFilterDepartment, {
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setCount(e?.data?.count);
      setDataDepartment(listData);
    },
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => dataDepartment, [dataDepartment]);

  const tabelAction = (
    <div className="">
      <div className="bg-gray-100 w-full py-5 px-3  rounded items-center">
        <div className="flex  justify-between">
          {' '}
          <div className="w-full max-w-[300px] ">
            <InputContainer name="search" control={control} label="Search" className="!my-0 bg-white" />
          </div>
          {/* <div className="flex justify-end">
            <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addDepartment' }))} className="bz-btn" type="button" variant="filled">
              ADD Email Reporting Department
            </Button>
          </div> */}
        </div>
      </div>
      <div className="  py-5 px-3 border-b-2 mb-5">
        <div className="font-bold mb-6">Filter</div>
        <div className="w-full max-w-[300px]">
          {sortAlphaUnit && (
            <ReactSelectSingle
              name="business_unit"
              control={control}
              // defaultValue={sortAlphaUnit && sortAlphaUnit.length > 0 && { label: sortAlphaUnit[0]?.label, value: sortAlphaUnit[0]?.value }}
              options={sortAlphaUnit}
              placeholder="Select Business Unit"
            />
          )}{' '}
        </div>
      </div>
    </div>
  );

  function TableCells(props) {
    const { row, labelId, ariaChecked, selected } = props;

    return (
      <TableRow hover tabIndex={-1} key={row.name + row.id}>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row.name}
        </TableCell>

        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row?.business_unit?.name || '-'}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row?.whistleblower_report_recipient || '-'}
        </TableCell>
        <TableCell align="left">
          <ActionCell
            id={row.id}
            // onClick={() => removeEmployee(row.id)}
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div>
      <BZHelmet title="Email Report" description="bz publish  Employee" />
      <BaseMainTitle title="Email Report" />
      <div className="p-5">
        {/* <TabelDepartment dataApi={data} isDataLoad={isDataLoad} setIsDataLoad={setIsDataLoad} headCells={headCellsDepartment} tabelAction={tabelAction} ActionCell={ActionCell} /> */}

        <div className="">
          <div className=" ">{tabelAction}</div>
        </div>

        <CustomTable
          dataApi={data}
          setIsDataLoad={setIsDataLoad}
          isFetching={!isFetching}
          headCells={headCellsDepartment}
          ActionCell={ActionCell}
          TableCells={TableCells}
          page={page - 1}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={count}
        />
      </div>
      <ModalWrapper componentName="editDepartment" header="Edit" maxWidth="sm">
        <EmailReportForm onFormSubmit={updateDepartment} defaultValues={DepartmentDetail?.data} key={DepartmentDetail?.data?.id} unitData={unitData} />
      </ModalWrapper>
      <ModalWrapper componentName="addDepartment" header="Add" maxWidth="sm">
        <EmailReportForm onFormSubmit={onPostDepartment} unitData={unitData} />
      </ModalWrapper>
    </div>
  );
}

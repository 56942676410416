// /* eslint-disable */

import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import CustomTable from '../../../components/Table/CustomTable';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import useDelete from '../../../hooks/useDelete';
import { handleModal } from '../../../store/slices/modalSlice';
import usePost from '../../../hooks/usePost';
import useGet from '../../../hooks/useGet';
import BaseApplyButton from '../../../components/Button/BaseApplyButton';
import BaseDeleteButton from '../../../components/Button/BaseDeleteButton';
import BaseSearchButton from '../../../components/Button/BaseSearchButton';
import RenderResults from '../../../components/RenderResults';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import BaseEditButton from '../../../components/Button/BaseEditButton';
import ArticleKeywordForm from '../../../components/Form/Article/ArticleKeywordForm';
import BZButton from '../../../components/Button/BZButton';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },

  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },

  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

function ActionCell(props) {
  const { onClick, id } = props;
  const dispatch = useDispatch();
  return (
    <div className="w-full flex space-x-2">
      <BaseEditButton onClick={() => dispatch(handleModal({ modalId: id, componentName: 'editKeyword' }))} />
      <BaseDeleteButton onClick={onClick} variant="icon" />
    </div>
  );
}

function TableCells(props) {
  const { row, labelId, ariaChecked, selected } = props;

  const destroyKeyword = useDelete({
    confText: `modals.keyword_delete.confirmation_text`,
    confTitle: `modals.keyword_delete.title`,
    success: 'Keyword deleted',
    validate: ['keywordArticles'],
  });

  const onDestroyKeyword = (id) => {
    destroyKeyword(`keyword_articles/${id}`);
  };
  return (
    <TableRow hover role="checkbox" aria-checked={ariaChecked} tabIndex={-1} key={row.id} selected={selected}>
      <TableCell component="th" id={labelId} scope="row">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">
        <ActionCell id={row.id} onClick={() => onDestroyKeyword(row.id)} />
      </TableCell>
    </TableRow>
  );
}

export default function KeywordTab() {
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const [dataKeyword, setDataKeyword] = useState([]);
  const [searchInput, setSearchInput] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const { isFetching } = useGet({
    url: `keyword_articles/?search=${searchInput}&client=${clientId}&page=${page}&page_size=${rowsPerPage}`,
    validate: 'keywordArticles',
    onSuccess: (e) => {
      setCount(e?.data?.count);
      setDataKeyword(e?.data?.results);
    },
    isEnabled: isDataLoad,
  });

  const { data: keywordDetail } = useGet({
    url: `keyword_articles/${modal?.modalId}`,
    validate: 'keywordArticle',
    isEnabled: modal.componentName === 'editKeyword',
  });

  const onChangeSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const data = useMemo(() => dataKeyword, [dataKeyword]);

  function generateFilterComponent() {
    return (
      <div className="flex space-x-2 items-center">
        <BaseSearchButton onChange={onChangeSearch} setPage={setPage} setIsDataLoad={setIsDataLoad} />

        <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} />
      </div>
    );
  }

  const { mutate: postKeyword } = usePost({ url: 'keyword_articles', validate: ['keywordArticles'] });

  const onPostKeyword = async (postData) => {
    await postKeyword(postData);
  };

  const { mutate: updateKeyword } = usePatchOrPut({ url: `keyword_articles/${modal.modalId}`, validate: ['keywordArticles', 'keywordArticle'] });

  return (
    <div className="p-5">
      <div className="">
        <div className="bg-gray-100 w-full flex py-5 px-3 items-center">
          <div className="ml-auto flex gap-x-3">
            <BZButton variant="secondary" onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addKeyword' }))}>
              Add keyword
            </BZButton>
            <ModalWrapper componentName="addKeyword" header="Add Keyword" maxWidth="sm">
              <ArticleKeywordForm onFormSubmit={onPostKeyword} />
            </ModalWrapper>
            <ModalWrapper componentName="editKeyword" header="Edit Keyword" maxWidth="sm">
              <ArticleKeywordForm onFormSubmit={updateKeyword} defaultValues={keywordDetail?.data} key={keywordDetail?.data?.id} />
            </ModalWrapper>
          </div>
        </div>
        <div className=" py-4 my-4 border-y-2 ">{generateFilterComponent()}</div>
      </div>
      {!isFetching ? (
        <RenderResults
          condition={count > 0}
          component={
            <CustomTable
              dataApi={data}
              setIsDataLoad={setIsDataLoad}
              isFetching={!isFetching}
              headCells={headCells}
              ActionCell={ActionCell}
              TableCells={TableCells}
              page={page - 1}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={count}
            />
          }
        />
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

import React from 'react';
import { Circle, Person, DeviceHub } from '@mui/icons-material';
import Structure from '../pages/HR/Structure/Structure';
import HRStructureDetail from '../pages/HR/Structure/HRStructureDetail';
import EmployeeAdd from '../pages/HR/EmployeeMaster/EmployeeAdd';
// import EmployeeEdit from '../pages/HR/EmployeeMaster/EmployeeEdit';
// import EmployeeDetail from '../pages/HR/EmployeeMaster/EmployeeDetail';
import EmployeeEditMaster from '../pages/HR/EmployeeMaster/EmployeeEdit';
import EmployeeDetailMaster from '../pages/HR/EmployeeMaster/EmployeeDetail';
import EmployeeManagementList from '../pages/HR/EmployeeMaster/EmployeeList';

const hrRoute = [
  {
    name: 'Structure',
    element: <Structure />,
    icon: <DeviceHub />,
    privilege: [15, 99],
    subscriptions: [10],
    path: '/hr/structure',
    isSidebar: true,
  },
  {
    name: 'Structure',
    element: <HRStructureDetail />,
    icon: <Circle />,
    privilege: [15, 99],
    subscriptions: [10],
    path: '/hr/structure/detail/:hrStructureId',
    isSidebar: false,
  },
  {
    name: 'Employee Management',
    element: <EmployeeManagementList />,
    icon: <Person />,
    privilege: [15, 99],
    subscriptions: [10],
    path: '/hr/employee-management',
    isSidebar: true,
  },
  {
    name: 'Employee Add',
    element: <EmployeeAdd />,
    icon: <Person />,
    privilege: [15, 99],
    subscriptions: [10],
    path: '/hr/employee/add',
    isSidebar: false,
  },
  // {
  //   name: 'Employee Edit',
  //   element: <EmployeeEdit />,
  //   icon: <Person />,
  //   privilege: [15, 99],
  //   subscriptions: [10],
  //   path: '/hr/employee/edit/:hrEmployeeId',
  //   isSidebar: false,
  // },
  // {
  //   name: 'Employee Detail',
  //   element: <EmployeeDetail />,
  //   icon: <Person />,
  //   privilege: [15, 99],
  //   subscriptions: [10],
  //   path: '/hr/employee/detail/:hrEmployeeId',
  //   isSidebar: false,
  // },
  // Master
  {
    name: 'Employee Edit',
    element: <EmployeeEditMaster />,
    icon: <Person />,
    privilege: [15, 99],
    subscriptions: [10],
    path: '/hr/employee/:hrEmployeeId/edit',
    isSidebar: false,
  },
  {
    name: 'Employee Detail',
    element: <EmployeeDetailMaster />,
    icon: <Person />,
    privilege: [15, 99],
    subscriptions: [10],
    path: '/hr/employee/:hrEmployeeId/detail',
    isSidebar: false,
  },

  // Master
];

export default hrRoute;

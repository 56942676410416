import axiosInstances from '../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function clientDashboard() {
  const { axiosInstance } = axiosInstances();

  async function getDashboardData(data) {
    const method = 'post';
    const url = `${baseURL}crm/reports/run_reports/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  return { getDashboardData };
}

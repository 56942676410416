/* eslint-disable */
import Close from '@mui/icons-material/Close';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { Controller } from 'react-hook-form';
import { CircularProgress, IconButton } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import usePost from '../../hooks/usePost';
import { useEffect } from 'react';
import usePostExtra from '../../hooks/usePostExtra';
import HandleWatchingState from '../../helper/HandleWatchingState';
import { useState } from 'react';

const { ValueContainer, Placeholder } = components;

function CustomClear(props) {
  const { setValue } = props;

  return (
    <button type="button" onClick={() => setValue(null)}>
      <Close className="h-[20px] w-[20px] text-[#6d6e6f] hover:text-black" />
    </button>
  );
}

function CustomValueContainerError({ children, ...props }) {
  return (
    <ValueContainer {...props}>
      <Placeholder className="text-[#d32f2f]" {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );
}

function CustomValueContainer({ children, ...props }) {
  return (
    <ValueContainer {...props}>
      {/* <Placeholder className="" {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder> */}
      {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );
}

function SingleValue(props) {
  const clientId = useSelector((state) => state.client.activeClient);
  // console.log('🚀 ~ file: ArticleFormNew.jsx:402 ~ SingleValue ~ props', props);

  const [editMode, setEditMode] = useState(false);
  const { mutate: updateCategory } = usePatchOrPut({ url: `keyword_articles/${props.data.value}`, validate: ['articleKeywords'] });

  const { mutate: createCategory } = usePostExtra({
    url: 'keyword_articles',
    validate: ['articleKeywords'],
    onSuccess: (e) => {
      if (e.request.status === 201) {
        props.setValue({ label: e.data.name, value: e.data.id });
      } else {
        ('');
        // enqueueSnackbar('error', { variant: 'error' });
        // renderError({ data: JSON.parse(e.request.response) });
      }
    },
  });

  const watchNewValue = HandleWatchingState(props.data.value);

  useEffect(() => {
    if (props?.data?.__isNew__) {
      createCategory({ name: props.data.label, client: clientId });
    }
  }, [clientId, createCategory, props.data?.__isNew__, props.data.label]);

  return (
    <components.SingleValue {...props}>
      {watchNewValue && (
        <div className=" w-full">
          <input
            className="relative outline-none"
            type="text"
            defaultValue={props.data.label}
            onChange={(e) => props.setValue({ label: e.target.value, value: props.data.value })}
            onKeyDown={(e) => {
              if (e.target.value.length > 0) {
                // important: this will allow the use of backspace in the input
                e.stopPropagation();
              } else if (e.key === 'Enter') {
                // bonus: if value is empty, press enter to delete custom option
                // e.stopPropagation();
                // props.setValue(null);
              }
            }}
          />

          <IconButton onClick={() => updateCategory({ name: props.data.label })} aria-label="delete" size="small" type="button" variant="contained" color="error">
            <Check className="" />
          </IconButton>
        </div>
      )}
    </components.SingleValue>
  );
}

export default function ReactSelectEditable({
  options = [],
  defaultValue = null,
  getOptionLabel,
  getOptionValue,
  placeholder,
  isSearchable,
  errors,
  control,
  name,
  isClearable = false,
  // onCreateOption,
}) {
  const component = {
    ClearIndicator: CustomClear,
    ValueContainer: errors ? CustomValueContainerError : CustomValueContainer,
    SingleValue,
  };

  const handleCreate = (inputValue) => {
    console.log('onCreateOption', inputValue);
  };

  return (
    <div className=" w-full">
      {defaultValue && (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <CreatableSelect
                className={`${errors && 'border-[1px] border-[#d32f2f] rounded-[4px]'}`}
                menuPosition="fixed"
                defaultValue={defaultValue}
                menuPlacement="auto"
                hideSelectedOptions={false}
                options={options}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={(v) => onChange(v?.value || null)}
                components={component}
                isSearchable={isSearchable}
                isClearable={isClearable}
                errors={Boolean(errors)}
                isDisable={false}
                placeholder={placeholder}
                renderValue={(selected) => selected.join(' ')}
                // onInputChange={() => console.log('create')}
                onCreateOption={handleCreate}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    // marginTop: 50,
                    border: '1px, solid, red',
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    overflow: 'visible',
                    border: '1px, solid, red',
                  }),
                  // placeholder: (provided, state) => ({
                  //   ...provided,
                  //   border: '1px, solid, red',
                  //   position: 'absolute',
                  //   top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
                  //   transition: 'top 0.1s, font-size 0.1s',
                  //   fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
                  //   background: 'white',
                  //   margin: '0px 20',
                  // }),
                }}
              />
            );
          }}
        />
      )}
      {!defaultValue && (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <CreatableSelect
                className={`${errors && 'border-[1px] border-[#d32f2f] rounded-[4px]'}`}
                menuPosition="fixed"
                menuPlacement="auto"
                hideSelectedOptions={false}
                options={options}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={(v) => onChange(v?.value || null)}
                components={component}
                isSearchable={isSearchable}
                isClearable={isClearable}
                errors={Boolean(errors)}
                isDisable={false}
                placeholder={placeholder}
                renderValue={(selected) => selected.join(' ')}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    // marginTop: 50,
                    border: '1px, solid, red',
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    overflow: 'visible',
                    border: '1px, solid, red',
                  }),
                  // placeholder: (provided, state) => ({
                  //   ...provided,
                  //   border: '1px, solid, red',
                  //   position: 'absolute',
                  //   top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
                  //   transition: 'top 0.1s, font-size 0.1s',
                  //   fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
                  //   background: 'white',
                  //   margin: '0px 20',
                  // }),
                }}
              />
            );
          }}
        />
      )}
      {errors && <div className="text-[#d32f2f] text-[0.75rem] mt-[4px] border-[#d32f2f]">{errors?.message}</div>}
    </div>
  );
}

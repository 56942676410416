import { createSlice } from '@reduxjs/toolkit';

const crmProfileSlice = createSlice({
  name: 'crmProfile',
  initialState: {
    activeSidePanel: false,
    updateElement: null,
    // other
  },
  reducers: {
    setActiveSidePanel(state, action) {
      const currState = state;
      currState.activeSidePanel = action.payload;
    },
    setUpdateElement(state, action) {
      const currState = state;
      currState.updateElement = action.payload;
    },
  },
});

export const crmProfileActions = crmProfileSlice.actions;
export default crmProfileSlice;

/* eslint-disable */

import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const lib = ['drawing', 'places'];

export default function LocationMap({ defaultValues, setValue, idx, CountriesData, name, watch }) {
  const userLat = watch(`${name}[${idx}][location[latitude]]`);
  const userLong = watch(`${name}[${idx}][location[longitude]]`);
  const [address, setAddress] = useState('');
  const [valueTab, setValueTab] = useState(0);
  const [addCompData, setAddCompData] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
    libraries: lib,
  });

  useEffect(() => {
    if (defaultValues) {
      setValue(`${name}[${idx}][location[latitude]]`, userLat);
      setValue(`${name}[${idx}][location[longitude]]`, userLong);
    } else
      navigator.geolocation.getCurrentPosition((position) => {
        setValue(`${name}[${idx}][location[latitude]]`, position.coords.latitude);
        setValue(`${name}[${idx}][location[longitude]]`, position.coords.longitude);
      });
  }, [defaultValues]);

  const center = {
    lat: +userLat,
    lng: +userLong,
  };
  const markerDragEnd = (event) => {
    // setUserLat(event.latLng.lat());
    // setUserLong(event.latLng.lng());

    setValue(`${name}[${idx}][location[latitude]]`, event.latLng.lat());
    setValue(`${name}[${idx}][location[longitude]]`, event.latLng.lng());
  };

  const autocompleteRef = useRef(null);

  const onPlaceChanged = () => {
    const places = autocompleteRef.current.state.autocomplete.getPlace();
    // console.log("🚀 ~ file: NewLocationForm.jsx:134 ~ onPlaceChanged ~ places:", places)
    const coordinate = places.geometry.location.toJSON();
    const { lat, lng } = coordinate;

    setValue(`${name}[${idx}][location[latitude]]`, lat);
    setValue(`${name}[${idx}][location[longitude]]`, lng);

    const addressComponents = places?.address_components;
    const addressComp = addressComponents?.map((add) => {
      return {
        name: add.long_name,
        types: add.types,
      };
    });
    setAddCompData(addressComp);

    const countriesName = CountriesData?.data?.results?.map((country) => country.iso_code);
    const addressCountry = addressComponents.map((addr) => addr.short_name);
    const foundedCountry = _.intersection(countriesName, addressCountry);
    const countryCode = foundedCountry[0];
    const selectedCountryChange = CountriesData?.data?.results?.find(({ iso_code }) => iso_code === countryCode);
    setTimeout(() => {
      setValue(`${name}[${idx}][location[address]]`, places.formatted_address);
      setValue(`${name}[${idx}][location[country]]`, selectedCountryChange?.id);
    }, 200);
  };

  return (
    <div>
      {' '}
      {isLoaded && center?.lat && center?.lng ? (
        <div className="flex w-full h-[400px] relative">
          <div className="absolute z-50 top-3 left-1/2 transform -translate-x-1/2">
            <Autocomplete onPlaceChanged={onPlaceChanged} ref={autocompleteRef}>
              <div>
                <input
                  className="p-2 "
                  type="text"
                  placeholder="Search Location"
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                  }}
                />
              </div>
            </Autocomplete>
          </div>
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
            {/* Child components, such as markers, info windows, etc. */}
            <Marker position={center} draggable onDragEnd={(event) => markerDragEnd(event)} />
          </GoogleMap>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

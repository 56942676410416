import useTokenBearer from '@/hooks/useTokenBearer';
import axios from 'axios';
import Cookies from 'js-cookie';

const client = axios.create({ baseURL: `https://api-hr.bzpublish.com/api` });
const token = Cookies.get('admin-token');

export default function request(options) {
  client.defaults.headers.common.Authorization = `${useTokenBearer()} ${token}`;

  const onSuccess = (response) => response;
  const onError = (error) => error;
  return client(options).then(onSuccess).catch(onError);
}

import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import { red, green } from '@mui/material/colors';

import serviceMonitoringApi from '../../../../../api/serviceMonitoring';

function ServiceStatus({ children }) {
  const color = {
    online: green[500],
    offline: red[500],
  };

  return <span style={{ color: color[children.toLowerCase()] }}>{children}</span>;
}

function Main() {
  const [serviceList, setServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getAllServices = () => {
      setIsLoading(true);
      serviceMonitoringApi()
        .getAllServiceMonitoring()
        .then((res) => {
          const services = res.data.results.map((el, idx) => ({ id: idx, ...el }));
          setServiceList(services);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
        });
    };

    getAllServices();
  }, []);

  return (
    <div className="p-5">
      <div className="bg-white border p-5 shadow rounded">
        <TableContainer sx={{ maxWidth: 400 }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceList.map((service) => (
                <TableRow key={service.id}>
                  <TableCell>{service.service_name}</TableCell>
                  <TableCell>
                    <ServiceStatus>{service.status}</ServiceStatus>
                  </TableCell>
                </TableRow>
              ))}
              {isLoading && (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Main;

import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Box, Avatar, CircularProgress } from '@mui/material';

export default function SelectAutocomplete({
  options = [],
  optionReset,
  onChange,
  placeHolder,
  label,
  defaultValue,
  variant = 'standard',
  avatar,
  disabled,
  errors,
  isLoading = true
}) {
  useEffect(() => {
    if (options.length > 0 && optionReset) {
      options.push(optionReset);
    }
  }, [options, optionReset]);

  const renderOption = (props, option) => (
    <Box component="li" {...props} key={option.value}>
      {avatar && <Avatar alt="Remy Sharp" src={option.avatar} />}
      <span>{option.name}</span>
    </Box>
  );

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        label={label}
        placeholder={placeHolder}
        variant={variant}
        size="small"
        inputProps={{
          ...params.inputProps,
          autoComplete: 'off'
        }}
        sx={{ '& > div': { padding: 0, border: 'none', outline: 'none' } }}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  };

  const commonProps = {
    options,
    autoHighlight: true,
    getOptionLabel: (option) => option?.name || '',
    isOptionEqualToValue: (option, newValue) => option.value === newValue.value,
    blurOnSelect: true,
    onChange,
    renderOption,
    renderInput,
  };

  const handleChange = (event, newValue) => {
    if (newValue && newValue.value) {
      onChange(event, newValue.value);
    } else {
      onChange(event, newValue);
    }
  };

  return (
    <>
      <Autocomplete
        id="currency-select"
        {...commonProps}
        defaultValue={
          typeof defaultValue === 'string' || typeof defaultValue === "number"
            ? options.find((o) => o.value === defaultValue) || null
            : defaultValue
        }
        value={
          typeof defaultValue === 'string' || typeof defaultValue === "number"
            ? options.find((o) => o.value === defaultValue) || null
            : defaultValue
        }
        onChange={handleChange}
        disabled={disabled}
      />
      {errors && (
        <span className="text-red-600">
          {Array.isArray(errors[label.toLowerCase()]?.message)
            ? errors[label]?.message.map((m) => <span key={m}>{m.toLowerCase()}</span>)
            : errors[label]?.message.replace(label, label?.toLowerCase() || label.toLowerCase())}
        </span>
      )}
    </>
  );
}

// /* eslint-disable */

import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

function MuiCustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div className="bz-tabs" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0 }} className="">
          {children}
        </Box>
      )}
    </div>
  );
}

MuiCustomTabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default MuiCustomTabPanel;

/* eslint-disable */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material/';
import { Close, Save } from '@mui/icons-material';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Link } from 'react-router-dom';
import CardChart from '../../../components/Chart/aCardChart';
import { getStaticPeriod, standardizeInputDate } from '../../../lib/StaticPeriod';
import ClientDashboardApi from '../../../api/clientDashboard';
import styles from './RGLHome.module.css';

const ReactGridLayout = WidthProvider(RGL);

export default function Countries() {

  return (
    <div>
      <p>Berhasilll</p>
    </div>
  )
}

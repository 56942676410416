import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import BaseMainTitle from '@/components/BaseMainTitle';
import BZHelmet from '@/utils/BZHelmet';
import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { ArrowBack, ExpandMore } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Input from '../CRM/redesigning/InputContainerRedesign';
import { InputBase } from '@mui/material';
import { Search } from '@mui/icons-material';
import _ from 'lodash';
import { useDebounce } from 'use-debounce';
import Swal from 'sweetalert2';
import BzThumbnail from '@/assets/simple.png'
import ModalWrapper from '@/components/ui/ModalWrapper';
import { handleModal } from '@/store/slices/modalSlice';
import CollectionUpdateForm from '@/components/Form/ProductManagement/CollectionUpdateForm';
import CancelIcon from '@mui/icons-material/Cancel';

function CategoryDetail() {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const dispatch = useDispatch();
  const activeModal = useSelector((state) => state.modal.modalId);

  const paramProducts = new URLSearchParams(`department__business_unit__client=${clientId}&max_size=true`);
  const paramCollections = new URLSearchParams(`client=${clientId}&max_size=true`);

  const [categoryData, setCategoryData] = useState(null);
  const [productList, setProductList] = useState([]);
  const [pickProducts, setPickProducts] = useState([]);
  const [productMediaShow, setProductMediaShow] = useState(null);
  const [collectionList, setCollectionList] = useState([]);
  const [pickCollections, setPickCollections] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  const [collectionsSearch, setCollectionsSearch] = useState("");
  const [productsSearch, setProductsSearch] = useState("");
  const [collectionsearchValue] = useDebounce(collectionsSearch, 300);
  const [productsearchValue] = useDebounce(productsSearch, 300);

  const [mode, setMode] = useState('');
  useEffect(() => {
    if (categoryId === undefined) {
      setMode('add');
    } else {
      setMode('edit');
    }
  }, [categoryId]);

    useEffect(() => {
    if (activeModal === "") {
      setCollectionsSearch('');
      setProductsSearch('');
      setAllCollections([]);
      setAllProducts([]);
    }
  }, [activeModal]);

  const categoryDetail = useCallback(async () => {
    const { getCategoryDetail } = ProductManagementApi();

    try {
      const getData = await getCategoryDetail(categoryId);
      const data = await getData.data;

      setCategoryData(data);
      setValue("name", data?.name || "");
    } catch (error) {
      console.error(error);
    }
  }, [categoryId]);
  

  useEffect(() => {
    if(mode === "edit") {
      categoryDetail();
    }
  }, [categoryId, clientId, mode]);

  // const getAllCollections = useCallback(async (paramCollections) => {
  //   const { getCollections } = ProductManagementApi();

  //   try {
  //     const getData = await getCollections(paramCollections);
  //     const data = await getData.data;
      
  //     setAllCollections(collectionData === null ? data?.results : data?.results?.filter((dt) => dt.id !== +collectionData.id));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [categoryId, paramCollections]);

  // const getAllProducts = useCallback(async (paramProducts) => {
  //   const { getProductList } = ProductManagementApi();

  //   try {
  //     const getData = await getProductList(paramProducts);
  //     const data = await getData.data;

  //     setAllProducts(data?.results);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [categoryId, paramProducts]);

  // useEffect(() => {
  //   const params = new URLSearchParams(`client=${clientId}&max_size=true`);

  //   if (collectionsearchValue) {
  //     params.set("search", collectionsearchValue.split(" ").join("+"));
  //     getAllCollections(params);
  //   }
  // }, [collectionsearchValue]);

  // useEffect(() => {
  //   const params = new URLSearchParams(`department__business_unit__client=${clientId}&max_size=true`);

  //   if (productsearchValue) {
  //     params.set("search", productsearchValue.split(" ").join("+"));
  //     getAllProducts(params);
  //   }
  // }, [productsearchValue]);

  const handleMediaUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageSrc = URL.createObjectURL(file);

      setProductMediaShow({ file: file, preview: imageSrc });
    }
  };

  const handleRemoveImage = () => {
    setProductMediaShow({ preview: '' });
  };

  const validationSubmit = (list, pick, modalMode) => {
    if (modalMode === "collections") {
      setCollectionList(list);
      setPickCollections(pick);
    }
  
    if (modalMode === "products") {
      setProductList(list);
      setPickProducts(pick);
    }
  
    // if (mode === "edit") {
    //   submitCollection({
    //     name: collectionData?.name,
    //     client: clientId,
    //     products: modalMode === "products" ? pick : pickProducts,
    //     collections: modalMode === "collections" ? pick : pickCollections
    //   });
    // }
  };

  const showErrorAlert = (error) => {
    let errorMessages = "An unexpected error occurred:<br>";
  
    if (error.response && error.response.data) {
      const errorData = error.response.data;
      for (const [field, messages] of Object.entries(errorData)) {
        const formattedMessages = messages.join(', ');
        errorMessages += `<strong>${field}:</strong> ${formattedMessages}<br>`;
      }
    } else {
      errorMessages += `<b>${error.message}</b>`;
    }
  
    Swal.fire({
      title: "Error",
      html: errorMessages,
      icon: "error"
    });
  };

  const submitCategory = async(data) => {
    const { updateCategory, addNewCategory } = ProductManagementApi();

    // const formattedProducts = (data?.products || pickProducts).map(product => product.id);
    // const formattedCollections = (data?.collections || pickCollections).map(collection => collection.id);
  
    const newData = {
      name: data?.name || categoryData?.name,
      client: clientId,
    };
  
    try {
      // const formData = new FormData();
      // for (const key in newData) {
      //   if (Array.isArray(newData[key])) {
      //     newData[key].forEach((item, index) => {
      //       formData.append(`${key}`, item);
      //     });
      //   } else {
      //     formData.append(key, newData[key]);
      //   }
      // }
      if (mode === "add") {
        if (newData.name === "") {
          Swal.fire({
            title: "Error!",
            text: "Name cannot be empty!",
            icon: "error"
          })
          return;
        }

        await addNewCategory(newData).then((result) => {
          const { id } = result?.data;
          Swal.fire({
            title: "Success!",
            text: "Successfully added new category!",
            icon: "success"
          }).then(() => {
            return navigate(`/product-management/categories/detail/${id}`);
          });
        }).catch((error) => {
          showErrorAlert(error);
        });
      } else {
        await updateCategory(categoryId, newData).then((result) => {
          const { id } = result?.data;
          Swal.fire({
            title: "Success!",
            text: "Successfully updated Category!",
            icon: "success"
          }).then(() => {
            // return navigate(`/product-management/collections`);
          });
        }).catch((error) => {
          showErrorAlert(error);
        });
      }
    } catch (error) {
      showErrorAlert(error);
    }
  }

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  return (
    <div className="w-full h-full bg-white">
      <BZHelmet title={mode === "add" ? "Add Category" : "Category Detail"} description="" content="" />
      <BaseMainTitle title={mode === "add" ? "Add Category" : "Category Detail"} />
      <div className='p-5 space-y-6'>
        <div className="w-full flex justify-between">
          <button
            className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
            type="text"
            name="Back to list"
            onClick={() => navigate("/product-management/categories")}
          >
            <ArrowBack />
            Back To List
          </button>
          {/* <div className="flex gap-x-2">
            <button
              className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
              type="text"
              name="Pair"
              onClick={() => {
                getAllProducts(paramProducts);
                dispatch(handleModal({
                  modalId: clientId, 
                  componentName: 'updateProducts', 
                }));
              }}
            >
              Update Products
            </button>
            <button
              className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
              type="text"
              name="Pair"
              onClick={() => {
                getAllCollections(paramCollections);
                dispatch(handleModal({
                  modalId: clientId, 
                  componentName: 'updateCollections', 
                }));
              }}
            >
              Update collections
            </button>
          </div> */}
        </div>
        <form 
          onSubmit={handleSubmit(submitCategory)} >
          <div className="flex gap-x-8">
            <div className="w-full space-y-4">
              <div className="w-1/2 h-fit">
                <InputLabel>Category Name</InputLabel>
                <Input
                  defaultValue={categoryData?.name && categoryData?.name}
                  label="name"
                  name="name"
                  control={control}
                  // disabled={mode === "edit"}
                  // onChangeValue={onChangeValue}
                />
              </div>
            </div>
          </div>
            <div className="flex justify-end border-t mt-6 pt-2">
              <button
                className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
                type="submit"
                name="submit data Category"
              >
                {mode === "add" ? "Add New Category" : "Update Category"}
              </button>
            </div>
        </form>
      </div>
    </div>
  )
}

export default CategoryDetail
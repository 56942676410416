import { Box, Button, Divider, Fade, Modal, Stack, Typography } from '@mui/material';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 768,
  bgcolor: 'background.paper',
  border: '1px solid transparent',
  boxShadow: 24,
  outline: 'none',
  margin: 'auto',
};

export default function BaseModal(props) {
  const { open, close, action, title, children, styles = { width: 480 }, config = { cancel: 'Cancel', save: 'Save' } } = props;

  const onCloseHandler = () => {
    close();
  };
  const onActionHandler = () => {
    action();
  };

  return (
    <Modal open={!!open} onClose={onCloseHandler} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Fade in={!!open}>
        <Box sx={(style, styles)} className="w-[768px] 2xl:w-[60vw] max-h-[90vh] h-auto overflow-y-auto m-auto">
          <div className="sticky top-0 z-10 bg-inherit py-4 px-8">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Divider className="mt-4" />
          </div>
          {children}
          <Divider className="mt-4" />
          <Stack direction="row" spacing={2} className="p-4 pt-0 mt-5 float-right">
            {config.cancel && (
              <Button variant="contained" onClick={onCloseHandler}>
                {config.cancel}
              </Button>
            )}
            {config.save && (
              <Button variant="contained" onClick={onActionHandler}>
                {config.save}
              </Button>
            )}
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
}

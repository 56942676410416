import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Drawer, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import { Close, Link as LinkIcon, MoreHoriz } from '@mui/icons-material/';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import DrawerContext from './DrawerContext';
import DetailContext from '../../../../pages/Task/Projects/DetailContext';
import BoardsContext from '../../../../pages/Task/Boards/BoardsContext';
import MoreDrawerActions from './MoreActions/MoreDrawerActions';
import TabTopicDetail from './Tab/TabTopicDetail';
import TabTopicInformation from './Tab/TabTopicInformation';
import TabTopicSettings from './Tab/TabTopicSettings';
import projectApi from '../../../../api/task/project';
import styles from './DrawerTask.module.css';
import ModalTaskNewRecord from '../../../Modal/Task/ModalTaskReport/ModalTaskNewRecord';
import HandleWatchingState from '@/helper/HandleWatchingState';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" className="w-full h-full" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ px: 0, pb: 2, py: 0, height: '100%', width: '100%' }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.defaultProps = { children: <div /> };

function a11yProps(index) {
  return {
    id: `drawer-tab-${index}`,
    'aria-controls': `drawer-tabpanel-${index}`,
  };
}

const tabItems = ['Topic', 'Information', 'Settings'];

const drawerTheme = createTheme({
  components: {
    MuiDrawer: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({ height: '0', width: '0' }) } },
    MuiBackdrop: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({ background: 'transparent' }) } },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => theme.unstable_sx({}),
        notchedOutline: {
          borderColor: 'rgba(0, 0, 0, 0.23) !important',
          borderWidth: '1px !important',
        },
      },
    },
  },
});

export default function DrawerTask({ open, onClose, idTask }) {
  console.log('🚀 ~ DrawerTask ~ idTask:', idTask);
  console.log('🚀 ~ DrawerTask ~ open:', open);
  const watchIdTask = HandleWatchingState(idTask);
  console.log('🚀 ~ DrawerTask ~ idTask:', idTask);
  const { enqueueSnackbar } = useSnackbar();
  const myContextDetail = useContext(DetailContext);
  const myContext = useContext(BoardsContext);

  const [activeTab, setActiveTab] = useState(0);
  const [iDTask, setIDTask] = useState(false);
  const [task, setTask] = useState(false);
  const [labels, setLabels] = useState([]);

  const [topicTitle, setTopicTitle] = useState(false);
  const [dueDate, setDueDate] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(false);

  const [filesAttachments, setFilesAttachments] = useState([]);
  const [anchorMoreActions, setAnchorMoreActions] = useState(null);

  const [isAddTime, setIsAddTime] = useState(false);

  const onTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const onCloseDrawer = () => {
    setTask(false);
    onClose(false);
  };

  const getTopicDetails = useCallback(() => {
    const { getTopicDetail } = projectApi();
    setTask(false);
    if (idTask) {
      getTopicDetail(idTask)
        .then((res) => {

          if (res.status === 200) {
            setTopicTitle(res.data.name);
            setDueDate(res.data.due_at);
            setSelectedLabels(res.data.labels);
            setSelectedStatus(res.data.section?.id);
            const attachments = [];
            res.data.comments.forEach((comment) => {
              if (comment.attachments.length) {
                comment.attachments.forEach((att) => {
                  attachments.push(att);
                });
              }
            });
            setFilesAttachments(attachments);
            setTask(res.data);
            setIDTask(idTask);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [idTask]);

  const getLabel = useCallback(() => {
    const { getLabels } = projectApi();
    getLabels({ project: myContextDetail.projectId, max_size: true })
      .then((res) => {
        if (res.status === 200) {
          setLabels(res.data.results);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [myContextDetail.projectId]);

  const handleCopyTaskLink = () => {
    const urlLink = `${window.location.origin}/task/detail/${idTask}`;
    navigator.clipboard.writeText(urlLink);
    enqueueSnackbar('Link copied to clipboard' || 'Success', { variant: 'success' });
  };

  const handleMoreActions = (event) => {
    setAnchorMoreActions(event.currentTarget);
  };

  const openMoreActions = Boolean(anchorMoreActions);
  const idMoreActions = openMoreActions ? 'simple-popover' : undefined;

  const handleTopicUpdate = useCallback(
    (data, isRecall = true, id = idTask) => {
      const { updateSectionTopic } = projectApi();

      updateSectionTopic(id, data)
        .then((res) => {
          if (res.status === 200) {
            if (isRecall) {
              getTopicDetails();
            }
            myContext.getProject();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [getTopicDetails, idTask, myContext]
  );

  // useEffect(() => {
  //   if (task && idTask && iDTask !== idTask) {
  //     getLabel();
  //     getTopicDetails();
  //   }
  // }, [open, task, iDTask, idTask, getLabel, getTopicDetails]);

  // useEffect(() => {
  //   if (open && idTask && !task && idTask !== iDTask) {
  //     getLabel();
  //     getTopicDetails();
  //   }
  // }, [open, idTask, task, iDTask, getLabel, getTopicDetails]);

  useEffect(() => {
    if (watchIdTask) {
      getLabel();
      getTopicDetails();
    }
  }, [watchIdTask, getTopicDetails, getLabel]);

  useEffect(() => {
    if (iDTask && !task && !idTask) {
      setIDTask(false);
    }
  }, [iDTask, task, idTask]);

  const context = useMemo(
    () => ({
      idTask,
      topicTitle,
      dueDate,
      selectedLabels,
      selectedStatus,
      filesAttachments,
      task,
      labels,
      getTopicDetails,
      getLabel,
      handleTopicUpdate,
      setIsAddTime,
    }),
    [idTask, topicTitle, dueDate, selectedLabels, selectedStatus, filesAttachments, task, labels, getTopicDetails, getLabel, handleTopicUpdate]
  );

  useEffect(() => {
    if (myContextDetail.trigger.triggerDrawer) {
      getTopicDetails();
      myContextDetail.trigger.setTriggerDrawer(false);
    }
  }, [myContextDetail.trigger, getTopicDetails]);

  return (
    <DrawerContext.Provider value={context}>
      <ThemeProvider theme={drawerTheme}>
        <Drawer anchor="right" open={!!open} onClose={onCloseDrawer} hideBackdrop>
          <div className={styles.drawerContainer}>
            <div className={styles.drawerCard}>
              <div className={styles.drawerCardTitle}>
                <div className="w-full flex justify-between px-6 items-center h-12 border-b border-gray-200">
                  <div className="w-full flex justify-between items-center">
                    <div>
                      <Tabs value={activeTab} onChange={onTabChange} aria-label="drawer tabs" className="bz-tabs">
                        {tabItems && tabItems.length > 0 && tabItems.map((tb, index) => <Tab key={tb} className="p-2" label={tb} {...a11yProps(index)} />)}
                      </Tabs>
                    </div>
                    <div>
                      {task ? (
                        <div className="flex space-x-2 items-center">
                          <Tooltip title="Copy task" arrow onClick={handleCopyTaskLink}>
                            <LinkIcon fontSize="20" className="cursor-pointer" />
                          </Tooltip>
                          <Tooltip title="More actions" arrow onClick={handleMoreActions}>
                            <MoreHoriz fontSize="20" className="cursor-pointer" />
                          </Tooltip>
                          <Tooltip title="Close detail" arrow>
                            <Close fontSize="20" onClick={() => onClose(false)} />
                          </Tooltip>
                        </div>
                      ) : (
                        <Skeleton width="100%" />
                      )}
                    </div>
                    <MoreDrawerActions
                      idTask={iDTask}
                      idMoreActions={idMoreActions}
                      openMoreActions={openMoreActions}
                      anchorMoreActions={anchorMoreActions}
                      onClose={() => setAnchorMoreActions(false)}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full h-full">
                <TabPanel value={activeTab} index={0}>
                  <TabTopicDetail />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  <TabTopicInformation />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                  <TabTopicSettings />
                </TabPanel>
              </div>
            </div>
          </div>
          {isAddTime && <ModalTaskNewRecord show={isAddTime} activeTask={task} callback={getTopicDetails} handleClose={() => setIsAddTime(false)} />}
        </Drawer>
      </ThemeProvider>
    </DrawerContext.Provider>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import { Button, ButtonBase } from '@mui/material';
import { CloudDownload, Add } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import sitemapApi from '../../../../../api/sitemap';
import siteApi from '../../../../../api/site';
import SitemapList from '../SitemapList';
import SubmitSitemap from '../../../../Modal/Settings/Sitemap/SubmitSitemap/SubmitSitemap';

const removeWww = (url) => {
  return url.replace('://www.', '://');
};

function Sitemap() {
  // const TEMPLATE_ID = useSelector((state) => state.content.activeTemplate);
  const SITE_ID = useSelector((state) => state.content.activeSite);

  const [sitemaps, setSitemaps] = useState([]);
  const [siteUrl, setSiteUrl] = useState('');
  const [isDisableSubmitSitemap, setIsDisableSubmitSitemap] = useState(true);
  const [showSubmitSitemap, setShowSubmitSitemap] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const getListSitemap = useCallback(() => {
    sitemapApi()
      .listSitemap(siteUrl)
      .then(
        (response) => {
          setSitemaps(response.result.sitemap ? response.result.sitemap : []);
          setIsDisableSubmitSitemap(false);
          enqueueSnackbar('Sitemaps Fetched Successfully', { variant: 'success' });
        },
        (err) => {
          const errorMessage = err.result.error.message;
          enqueueSnackbar(errorMessage, { variant: 'error' });
        }
      )
      .catch((err) => console.error(err));
  }, [siteUrl, enqueueSnackbar]);

  const loadSitemap = useCallback(() => {
    sitemapApi()
      .logIn()
      .then(getListSitemap)
      .catch((err) => console.error(err));
  }, [getListSitemap]);

  const deleteSitemap = useCallback(
    (feedPath) => {
      const nonWwwUrl = removeWww(siteUrl);
      const nonWwwfeedPath = removeWww(feedPath);
      if (siteUrl.indexOf('://www.') !== -1) {
        sitemapApi()
          .deleteSitemap(nonWwwUrl, nonWwwfeedPath)
          .then(() => {

          });
      }

      return sitemapApi()
        .deleteSitemap(siteUrl, feedPath)
        .then(() => {
          enqueueSnackbar('Sitemap Deleted Successfully', { variant: 'success' });
        })
        .then(getListSitemap);
    },
    [siteUrl, getListSitemap, enqueueSnackbar]
  );

  const submitSitemap = useCallback(
    (feedPath) => {
      const nonWwwUrl = removeWww(siteUrl);
      const nonWwwfeedPath = removeWww(feedPath);
      if (siteUrl.indexOf('://www.') !== -1) {
        sitemapApi().submitSitemap(nonWwwUrl, nonWwwfeedPath);
      }

      return sitemapApi()
        .submitSitemap(siteUrl, feedPath)
        .then(
          () => {
            enqueueSnackbar('Sitemap submitted successfully', { variant: 'success' });
            setShowSubmitSitemap(false);
          },
          (err) => {
            console.error('Execute error', err);
          }
        )
        .then(getListSitemap);
    },
    [enqueueSnackbar, getListSitemap, siteUrl]
  );

  useEffect(() => {
    const getSites = () => {
      siteApi()
        .getSiteDetail(SITE_ID)
        .then((res) => {
          setSiteUrl(res.data.site_url);
          setSitemaps([]);
        });
    };

    getSites();
  }, [SITE_ID]);

  return (
    <>
      <div className="p-5">
        <div className="bg-white border p-5 shadow rounded">
          <div className="flex justify-between">
            <button className="bz-btn" onClick={loadSitemap}>
              <CloudDownload />
              <span className="ml-2">Fetch Sitemap From GSC</span>
            </button>
            <button disabled={isDisableSubmitSitemap} className='bz-btn' onClick={() => setShowSubmitSitemap(true)}>
              <Add />
              <span className="ml-2">Submit Sitemap</span>
            </button>

          </div>
          <SitemapList className="mt-4" rows={sitemaps} deleteSitemap={deleteSitemap} />
        </div>
      </div>

      <SubmitSitemap open={showSubmitSitemap} close={() => setShowSubmitSitemap(false)} submitSitemap={submitSitemap} />
    </>
  );
}

export default Sitemap;

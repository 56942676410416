/* eslint-disable no-unused-expressions */
import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function InputContainer({
  name,
  disabled,
  control,
  type = 'text',
  label,
  showPass,
  showCfPass,
  handleClickShowPassword,
  errors,
  defaultValue,
  hidden,
  config = {},
  onFocus,
  placeholder,
  autoFocus,
  className,
  onChangeValue,
  setState,
}) {
  // console.log(name, defaultValue);
  return (
    <div className={['my-1 z-index-label', hidden && 'hidden'].join(' ')}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        render={({ onChange, field }) => (
          <TextField
            fullWidth
            autoFocus={autoFocus}
            size="small"
            className={className}
            sx={{ textTransform: 'capitalize', marginTop: 0 }}
            // label={label}
            // value={value}
            // defaultValue={defaultValue || ''}
            placeholder={placeholder}
            value={defaultValue}
            onFocus={onFocus}
            hidden={hidden}
            disabled={disabled}
            margin="normal"
            onChange={(event) => {
              onChange(event);
              setState(event.target.value);
              onChangeValue && onChangeValue();
              return event;
            }}
            // error={errors === {} ? '' : Boolean(errors)}
            // helperText={errors?.message}
            /* eslint-disable */ //nested ternary
            type={type === 'password' ? (showPass ?? showCfPass ? 'text' : 'password') : type}
            InputProps={{
              autoComplete: 'new-password',
              endAdornment: type === 'password' && (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                    {showPass ?? showCfPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...field}
            {...config}
          />
        )}
      />
      {errors &&
      (Array.isArray(errors[name]?.message) ? (
        errors[name]?.message.map(m => <span className="text-red-600">{m.toLowerCase()}</span>)
      ) : (
        <span className="text-red-600">
          {errors[name]?.message.replace(name, label?.toLowerCase() || name.toLowerCase())}
        </span>
      ))}
    </div>
  );
}

export default InputContainer;

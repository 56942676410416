import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

import BaseHelper from '../Base/BaseHelper';
import BaseLabel from '../Base/BaseLabel';

export default function CustomCheckboxNew({ name, control, defaultChecked = false, label, onChangeCustom, labelPlacement, helperText, disabled, hidden, ...props }) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <FormControlLabel
            disableTypography
            hidden={hidden}
            control={<Checkbox onChange={Boolean(onChangeCustom) ? (props) => onChangeCustom(props) : onChange} defaultChecked={defaultChecked} />}
            label={<BaseLabel value={label} mb={false} />}
            labelPlacement={labelPlacement}
            disabled={disabled}
          />
        )}
        {...props}
      />
      <BaseHelper helperText={helperText} />
    </>
  );
}

import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { buildYup } from 'schema-to-yup';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { DatePickerFieldRegister } from '../../../components/Input/DatePickerField';
import SelectDynamicField from '../../../components/Input/SelectDynamicField';
import InputDynamicField from '../../../components/Input/InputDynamicField';
import BaseLocation from '../../../components/Modal/Base/BaseLocation';
import BaseInputValidation from '../../../components/Input/Base/BaseInputValidation';
import crmApi from '../../../api/CRM/CRMCustomers';

const { getCRMProjectProfiles, getCRMProfileAttribute, createCRMProject } = crmApi();

const staticSchema = yup.object().shape({ name: yup.string().required('Name is a required field') });

export default function Add() {
  const navigate = useNavigate();
  const activeClient = useSelector((state) => state.client.activeClient);
  const [projectProfile, setProjectProfile] = useState(null);
  const [schema, setSchema] = useState(null);
  const [location, setLocation] = useState(null);
  const [openLocation, setOpenLocation] = useState(null);

  const {
    register,
    trigger,
    getValues,
    setValue,
    resetField,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(staticSchema),
    defaultValues: {},
  });

  const onCloseHandler = () => {
    navigate(-1);
  };

  const { data, isFetching } = useQuery({
    queryKey: ['crm-project-profiles', activeClient],
    queryFn: () => getCRMProjectProfiles({ client: activeClient, max_size: true }),
    refetchOnWindowFocus: false,
  });

  const { data: dataAttribute, isFetching: isFetchingAttribute } = useQuery({
    queryKey: ['crm-profile-attributes', projectProfile],
    enabled: !!projectProfile && !!data,
    queryFn: () => getCRMProfileAttribute({ profile: projectProfile, max_size: true }),
    onSuccess(res) {
      const properties = {};
      const requiredProperties = [];
      const keySchema = [];
      const errorProperties = {};
      res?.forEach((p) => {
        const attr = { ...p.attribute };
        const type = attr.value_type.toLowerCase();

        let usedType = type;
        if (['formula', 'numeric'].includes(type)) {
          usedType = 'number';
        } else if (['option', 'datetime', 'text'].includes(type)) {
          usedType = 'string';
        }
        properties[attr.key_name] = {
          type: usedType,
          required: attr.is_required,
          description: attr.name,
          origin: type,
        };
        if (attr.is_required) {
          requiredProperties.push(attr.key_name);
          errorProperties[attr.key_name] = { required: 'Required field' };
        }
        if (type === 'option') {
          let options = attr.options.join('|');
          if (!attr.is_required) {
            options += '|null';
          }
          properties[attr.key_name].matches = `(${options})`;
        }
        if (type === 'numeric' && !attr.is_required) {
          properties[attr.key_name].default = null;
          properties[attr.key_name].nullable = true;
        }
        if (type === 'formula') {
          properties[attr.key_name].default = null;
          properties[attr.key_name].nullable = true;
        }
        keySchema.push(attr.key_name);
      });

      const newSchema = {
        $schema: 'http://json-schema.org/draft-07/schema#',
        $id: 'http://example.com/person.schema.json',
        title: 'Project',
        description: 'CRM Project',
        type: 'object',
        properties,
        required: requiredProperties,
      };

      setSchema({ schema: newSchema, errorSchema: errorProperties, keys: keySchema });
    },
    refetchOnWindowFocus: false,
  });

  const onMainFormSubmit = async () => {
    const isValid = await trigger(['name']);
    return isValid;
  };

  const onCreateProject = (formData) => {
    createCRMProject(
      {
        name: getValues('name'),
        location: location?.id || '',
        profile: projectProfile,
        attributes: formData,
      },
      'post',
      null
    )
      .then(() => {
        onCloseHandler();
      })
      .catch((err) => console.log(err));
  };

  const pairLocation = (dataLocation) => {
    setValue('location', dataLocation.name || dataLocation.address, { shouldDirty: true });
    setLocation(dataLocation);
    setOpenLocation(false);
  };

  useEffect(() => {
    resetField('name');
    setProjectProfile(null);
    setLocation(null);
  }, [setProjectProfile, resetField]);

  const wName = watch('name');

  return (
    <>
      <Box className="w-full h-full px-6 py-6 space-y-3">
        <Typography id="create-crm-project" className="mb-4" variant="h6" component="h2">
          Project
        </Typography>
        <section>
          <div className="grid grid-cols-6 gap-8">
            <div className="col-span-6">
              <div className="w-full flex gap-x-4">
                <TextField
                  type="text"
                  label="Name"
                  name="name"
                  className="mb-4 w-1/2"
                  required
                  error={errors && errors.name}
                  size="small"
                  {...register('name', { required: true })}
                  InputLabelProps={{ shrink: !!wName }}
                />
                <div className="flex gap-x-4 w-1/2">
                  <BaseInputValidation control={control} name="location" label="Location" errors={errors} config={{ disabled: true }} />
                  <Button variant="contained" size="small" className="w-56 h-10" onClick={() => setOpenLocation(true)}>
                    {location ? 'Update Location' : 'Add Location'}
                  </Button>
                </div>
              </div>

              {isFetching ? (
                <Skeleton />
              ) : (
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="project-profile"
                    sx={{
                      top: '-8px',
                      '&.Mui-focused': { top: -8, background: '#fff' },
                      '&.MuiFormLabel-filled, &.MuiInputLabel-shrink': { top: 0, background: '#fff' },
                    }}
                    shrink={!!projectProfile}
                  >
                    Profile
                  </InputLabel>
                  <Select
                    id="project-profile"
                    value={projectProfile}
                    className="h-10"
                    sx={{ width: '100%', px: 0.2, py: 0.2 }}
                    onChange={(e) => setProjectProfile(e.target.value)}
                    displayEmpty
                  >
                    {data?.length > 0 &&
                      data?.map((p) => (
                        <MenuItem key={p.id} value={p.id}>
                          {p.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              {projectProfile && isFetchingAttribute && <Skeleton />}
              {projectProfile && !isFetchingAttribute && schema && (
                <FormDynamicAttribute
                  attributes={dataAttribute}
                  schema={schema}
                  onCloseHandler={onCloseHandler}
                  onCreateProject={onCreateProject}
                  triggerMain={trigger}
                  onMainFormSubmit={onMainFormSubmit}
                  isEdit={false}
                >
                  {}
                </FormDynamicAttribute>
              )}
            </div>
          </div>
        </section>
        {/* <DevTool control={control} /> */}
      </Box>

      <BaseLocation
        open={openLocation}
        close={() => {
          setLocation(false);
          setOpenLocation(false);
        }}
        action={pairLocation}
      />
    </>
  );
}

function FormField({ form, attribute }) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const { is_required: isRequired, key_name: keyName, multiple_value: isMultiple, name, value_type: valueType } = attribute;
  const optionField = { required: isRequired };
  if (valueType === 'Numeric') {
    optionField.valueAsNumber = true;
  }
  const regField = { ...register(keyName, { required: isRequired }) };

  let html = null;
  switch (valueType) {
    case 'Option':
      html = <SelectDynamicField register={regField} options={attribute.options} name={keyName} label={name} isMultiple={isMultiple} />;
      break;
    case 'Text':
      html = <InputDynamicField name={keyName} label={name} type="text" isRequired={isRequired} register={regField} errors={errors} />;
      break;
    case 'Formula':
      html = <InputDynamicField name={keyName} label={name} type="text" isRequired={isRequired} disabled register={regField} errors={errors} />;
      break;
    case 'Numeric':
      html = <InputDynamicField name={keyName} label={name} type="number" isRequired={isRequired} register={regField} errors={errors} inputProps={{ step: 0.0001 }} />;
      break;
    case 'Datetime':
      html = <DatePickerFieldRegister name={keyName} label={name} isRequired={isRequired} register={regField} setValue={setValue} errors={errors} watch={watch} />;
      break;
    default:
      break;
  }
  return html;
}

function FormDynamicAttribute({ attributes, schema, onCreateProject, onCloseHandler, triggerMain, onMainFormSubmit }) {
  const formSchema = buildYup(schema.schema, { errMessages: schema.errorSchema });
  const defaultValues = {};

  const refInitialize = useRef(true);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
  });
  const onSubmit = async (data) => {
    const isMainValid = await onMainFormSubmit();
    if (!isMainValid) return;
    const valid = await formSchema.isValid(getValues());
    if (valid) {
      onCreateProject(data);
    }
  };

  useEffect(() => {
    if (refInitialize.current) {
      refInitialize.current = false;
      const formulaFields = [];
      Object.entries(schema.schema.properties).forEach((s) => {
        const [key, value] = s;
        if (value.type === 'string' && value.matches) {
          setValue(key, null, { shouldTouch: true });
        } else if (value.type === 'string') {
          if (value.origin === 'datetime') {
            setValue(key, '', { shouldTouch: true });
          } else {
            setValue(key, '', { shouldTouch: true });
          }
        }
        if (value.type === 'number') {
          setValue(key, undefined, { shouldTouch: true });
        }
        if (value.type === 'formula') {
          setValue(key, null, { shouldTouch: true });
          formulaFields.push();
        }
      });
    }
    return () => {
      refInitialize.current = false;
    };
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <div className="grid grid-cols-3 gap-4 mt-4">
          {attributes.map((attr) => {
            const { id, attribute } = attr;
            return (
              <FormField
                key={id}
                form={{
                  register,
                  trigger,
                  handleSubmit,
                  setValue,
                  watch,
                  formState: { errors },
                }}
                attribute={attribute}
              />
            );
          })}
        </div>
        <Divider className="mt-4" />
        <Stack direction="row" spacing={1} className="mt-5 float-right">
          <Button variant="outlined" onClick={onCloseHandler}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              triggerMain();
              trigger();
            }}
          >
            Save
          </Button>
        </Stack>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
}

import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import request from '@/helper/axiosUtil';
import renderError from '@/utils/Error/renderError';

export default function usePatch(props) {
  const { url, validate, method = 'patch' } = props;
  const { enqueueSnackbar } = useSnackbar();
  const qClient = useQueryClient();

  const update = (data) => {
    return request({ url: `/${url}/`, method, data });
  };

  let onToast;

  return useMutation((data) => update(data), {
    onSuccess: (e) => {
      onToast = new Promise((resolve, reject) => {
        if (e.request.status === 200) {
          resolve('Success');
        } else {
          const error = new Error('Request failed');
          error.data = JSON.parse(e.request.response);
          renderError({ data: JSON.parse(e.request.response) });
          reject(error);
        }
      });
    },
    onError: err => {
      if (err.response.data) {
        const d = err.response.data;
        Object.keys(d).forEach((k) => {
          enqueueSnackbar(d[k][0] || 'Error', { variant: 'Error' });
        });
      }
    },
    onSettled: () => {
      validate?.forEach((val) => qClient.invalidateQueries(val));
      if (props.callback && typeof props.callback === 'function') {
        props.callback();
      }
    },
  });
}

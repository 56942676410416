import axiosInstances from '../../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function asset() {
  const { axiosInstance, axiosInstanceF } = axiosInstances();

  async function getAssets(params) {
    const method = 'get';
    const url = `${baseURL}assets/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function uploadAssets(data) {
    const method = 'post';
    const url = `${baseURL}assets/`;
    const response = await axiosInstanceF({
      method,
      url,
      data,
    });
    return response;
  }

  return { getAssets, uploadAssets };
}

import React from 'react';
import { useDispatch } from 'react-redux';
import AddContact from '../../Form/CRM/AddContact';
import ModalWrapper from '../../ui/ModalWrapper';
import { handleModal } from '../../../store/slices/modalSlice';
import css from './ContactSelect.module.css';

function ContactSelectButton({ idxContact, name, title, phone, email, inputData, handleAssignedContact }) {
  let initial = '';
  if (name) {
    initial = name
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  }
  const dispatch = useDispatch();
  return (
    <div className="border rounded-lg">
      <div className={['flex items-center p-3 gap-x-4 justify-between'].join(' ')}>
        <div
          className="mr-3 flex items-center justify-between"
          style={{ backgroundColor: '#E2E2E2', minWidth: '32px', height: '32px', width: '32px', borderRadius: '50%', border: '2px solid #223771' }}
        >
          <p className="mb-0 mx-auto">{initial}</p>
        </div>
        <div className="w-full grid grid-cols-12 items-center">
          <div className={['col-span-6', css.text].join(' ')}>
            <p className={css.name}>{name}</p>
            <p className={css.title}>{title}</p>
          </div>
          <div className={['col-span-6', css.text].join(' ')}>
            <p className={css.phone}>{phone}</p>
            <p className={css.email}>{email}</p>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          className="w-full bg-[#2C6D47] p-2 text-white rounded-lg"
          onClick={() => dispatch(handleModal({ modalId: idxContact, componentName: 'AddNewContact', modalData: inputData }))}
        >
          Assign Contact
        </button>
      </div>
      <ModalWrapper componentName="AddNewContact" header="Add New Contact" maxWidth="sm">
        <AddContact idxContact={idxContact} noSubmitApi handleAssignedContact={handleAssignedContact} />
      </ModalWrapper>
    </div>
  );
}

export default ContactSelectButton;

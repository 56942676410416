import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import team from '../../api/task/team';

export const getUsers = createAsyncThunk('users/setUsersTeam', async (clientId) => {
  const { getUsersTeam } = team();
  const response = await getUsersTeam(clientId, { max_size: true });
  return response.data;
});
export const getTeams = createAsyncThunk('teams/setTeams', async (clientId) => {
  const { getTeam } = team();
  const response = await getTeam(clientId);
  return response.data;
});

const usersTeamSlice = createSlice({
  name: 'users',
  initialState: { users: [], teams: [] },
  reducers: {
    setUsersTeam(state, action) {
      const currState = state;
      currState.users = action.payload;
    },
    setTeams(state, action) {
      const currState = state;
      currState.teams = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const currState = state;
      currState.users = action.payload;
    });
    builder.addCase(getTeams.fulfilled, (state, action) => {
      const currState = state;
      currState.teams = action.payload;
    });
  },
});
export const usersActions = usersTeamSlice.actions;
export default usersTeamSlice;

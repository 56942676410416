const getOffset = (offset, operator) => {
  const currOffset = offset / 60;
  const offsetAbs = Math.abs(currOffset);
  let valOffset;
  if (offsetAbs.toString().length === 1) {
    valOffset = `${operator}0${offsetAbs}:00`;
  }
  if (offsetAbs.toString().length > 1) {
    valOffset = `${operator}${offsetAbs}:00`;
  }
  return valOffset;
};

export default function getTimeZone() {
  const currDate = new Date();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tzOffset = currDate.getTimezoneOffset();
  let timeZoneOffset;
  if (tzOffset > 0) {
    // UTC (-)
    timeZoneOffset = getOffset(tzOffset, '-');
  } else if (tzOffset < 0) {
    // UTC (+)
    timeZoneOffset = getOffset(tzOffset, '+');
  }
  return {
    name: timezone,
    offset: timeZoneOffset,
  };
}

import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import _ from 'lodash';
import RefreshIcon from '@mui/icons-material/Refresh';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import { useProductContext } from '../ProductContext';
import moment from 'moment';
import TabInput from './TabInput';
import TabProjection from './TabProjection';
import TabCostBreakdown from './TabCostBreakdown';

function PricingManagerTab() {
  const {
    currencyOptions,
    isLoadingOptions,
    marketTabValue,
    setMarketTabValue,
    insideMarketTabValue,
    setInsideMarketTabValue,
    marketManagers,
    pricingProjection,
    costBreakdown,
    getAllPricingManagerData,
    pricingManagerCurr,
    setPricingManagerCurr,
  } = useProductContext();

  const currencyOptionsFormatted = useMemo(() =>
    currencyOptions?.map((dt) => ({ name: dt.code, value: dt.id })),
    [currencyOptions]
  );

  // useEffect(() => {
  //   if (pricingManagerCurr) {
  //     getAllPricingManagerData({ currency: pricingManagerCurr });
  //   }
  // }, [pricingManagerCurr]);

  const handleChangeMarketTab = useCallback((_, newValue) => {
    setMarketTabValue(newValue);
  }, [setMarketTabValue]);

  const handleChangeInsideMarketTab = useCallback((_, newValue) => {
    setInsideMarketTabValue(newValue);
  }, [setInsideMarketTabValue]);

  const handleCurrencyChange = useCallback((_, val) => {
    setPricingManagerCurr(val);
  }, [setPricingManagerCurr]);

  const marketTabs = useMemo(() => (
    marketManagers.length > 0 && marketManagers.map((dt) => (
      <Tab key={dt.id} value={dt.market_name} label={dt.market_name} />
    ))
  ), [marketManagers]);

  return (
    <div className='w-full space-y-6 border-t pt-4'>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <Box sx={{ marginBottom: 2 }}>
          <Tabs
            value={marketTabValue}
            onChange={handleChangeMarketTab}
            aria-label="market tabs"
            TabIndicatorProps={{
              sx: {
                backgroundColor: '#2C6D47',
              },
            }}
            sx={{
              '.MuiButtonBase-root': {
                fontWeight: '600',
              },
              '.Mui-selected': {
                color: '#2C6D47!important',
              },
            }}
          >
            {marketTabs}
          </Tabs>
        </Box>
        <div className="space-y-4">
          <form action="" className="w-full">
            <div className='flex gap-4 w-full mb-4'>
              <div className="w-[10%] h-fit">
                <p className="font-semibold mb-[5px]">Currency</p>
                <SelectAutocomplete
                  variant="outlined"
                  options={currencyOptionsFormatted}
                  defaultValue={pricingManagerCurr}
                  onChange={handleCurrencyChange}
                  isLoading={isLoadingOptions}
                />
              </div>
              <div className='h-fit mt-auto mb-2'>
                <RefreshIcon fontSize="medium" className="cursor-pointer" onClick={() => getAllPricingManagerData({ currency: pricingManagerCurr })} />
              </div>
            </div>
          </form>
          <Box sx={{ width: '100%', position: 'relative' }}>
            <Box sx={{ marginBottom: 2 }}>
              <Tabs
                value={insideMarketTabValue}
                onChange={handleChangeInsideMarketTab}
                aria-label="inside market tabs"
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: '#2C6D47',
                  },
                }}
                sx={{
                  '.MuiButtonBase-root': {
                    fontWeight: '600',
                  },
                  '.Mui-selected': {
                    color: '#2C6D47!important',
                  },
                }}
              >
                <Tab value={0} label="Input" />
                <Tab value={1} label="Projection" />
                <Tab value={2} label="Cost Breakdown" />
                <Tab value={3} label="Visualizations" />
              </Tabs>
            </Box>
            {insideMarketTabValue === 0 && <TabInput />}
            {insideMarketTabValue === 1 && pricingProjection !== null && <TabProjection />}
            {insideMarketTabValue === 2 && costBreakdown !== null && <TabCostBreakdown />}
          </Box>
        </div>
      </Box>
    </div>
  );
}

export default PricingManagerTab
import { createSlice } from '@reduxjs/toolkit';

const accountSettingsSlice = createSlice({
  name: 'accountSettings',
  initialState: {
    tableState: {},
  },
  reducers: {
    setTabState(state, action) {
      const currState = state;
      if (action.payload.type !== 'reset') {
        currState.tableState[action.payload.type] = action.payload.value;
      } else {
        currState.tableState = {};
      }
    },
  },
});

export const accountSettingsActions = accountSettingsSlice.actions;
export default accountSettingsSlice;

import { isMobile } from 'react-device-detect';
import CustomerDesktop from './CustomerDesktop';
import CustomerMobile from './CustomerMobile';

export default function Customer() {
  console.log('🚀 ~ isMobile:', isMobile);
  const renderContent = () => {
    if (isMobile) {
      return <CustomerMobile />;
    }
    return <CustomerDesktop />;
  };
  return renderContent();
}

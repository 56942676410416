/* eslint-disable */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { handleModal } from '../../../store/slices/modalSlice';
import ProcurementApi from '../../../api/Procurement/Procurement';
import Select from '../../../components/Input/MuiSelectSingleItem';

function BulkChange({ activeClient, memberIds, getFilterParams, reload, normalizeFilter }) {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const procurementApi = useMemo(() => new ProcurementApi(), []);
  const dispatch = useDispatch();
  const [attributeCategories, setAttributeCategories] = useState([]);
  const [attributeOptions, setAttributeOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});

  useEffect(() => {
    const params = {
      max_size: true,
      client: activeClient,
    };

    procurementApi.getAttributeCategories(params).then((res) => {
      setAttributeCategories(res.data.results);
    });
  }, []);

  const getAllAttributeOptions = useCallback(
    async (attributeIds) => {
      let newAttributeOptions = {};
      let newSelectedOptions = {};
      await attributeIds.reduce(async (promise, attrId) => {
        await promise;
        const res = await procurementApi.getAttributeOptions(attrId);
        newAttributeOptions[attrId] = res.data.results;
        newSelectedOptions[attrId] = '';
      }, Promise.resolve());

      setSelectedOptions(newSelectedOptions);
      setAttributeOptions(newAttributeOptions);
    },
    [procurementApi]
  );

  const handleChangeSelectedOption = (value, attributeId) => {
    let newSelectedOptions = _.cloneDeep(selectedOptions);
    newSelectedOptions[attributeId] = value;

    setSelectedOptions(newSelectedOptions);
  };

  useEffect(() => {
    let attrThatHasOptions = [];
    attributeCategories.forEach((category) => {
      category.attributes.forEach((attr) => {
        if (attr.value_type === 'Option') {
          attrThatHasOptions.push(attr.id);
        }
      });
    });

    getAllAttributeOptions(attrThatHasOptions);
  }, [getAllAttributeOptions, attributeCategories]);

  const submit = async () => {
    const params = getFilterParams();
    const updatedData = Object.keys(selectedOptions)
      .filter((key) => selectedOptions[key])
      .map((key) => ({
        attribute: key,
        attribute_option: selectedOptions[key],
        member_ids: memberIds,
      }));

    try {
      swal({
        title: `Are you sure ?`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let successMessage = '';
          await updatedData.reduce(async (promise, data) => {
            await promise;
            const res = await ProcurementApi.bulkUpdateMembersData(params, data);
            successMessage = res.data?.detail;
          }, Promise.resolve());

          dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
          swal('Success !', `${successMessage}`, 'success');
          normalizeFilter();
          return reload(true);
        } else {
          swal('Failed !', `failed to update`, 'failed');
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    attributeCategories &&
    attributeOptions && (
      <div>
        <form onSubmit={handleSubmit(submit)} className="space-y-4">
          {attributeCategories.map((category) => {
            return (category?.attributes)
              .filter((attribute) => attribute.value_type === 'Option' && !attribute.multiple_value)
              .map((attribute) => {
                const options = (attributeOptions[attribute.id] || []).map((attr) => ({ label: attr.name, value: attr.id }));
                return (
                  <div className="w-full">
                    <Select
                      name={attribute.name}
                      control={control}
                      label={attribute.name}
                      // defaultValue={selectedOptions[attribute.id]}
                      unique_id={attribute.id}
                      options={options}
                      multiple={false}
                      OptionLabel={attribute.name}
                      menuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                      isInputLabel
                      onChangeValue={handleChangeSelectedOption}
                    />
                  </div>
                );
              });
          })}
          <Button className="flex mt-7 ml-auto bg-blue-600" type="submit" name="submit contact" variant="contained">
            Save
          </Button>
        </form>
      </div>
    )
  );
}

export default BulkChange;

import React, { useState, useContext } from 'react';
import { OutlinedInput } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import BoardsContext from '../../../../pages/Task/Boards/BoardsContext';

import styles from './CardTask.module.css';

const taskTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      root: ({ theme }) =>
        theme.unstable_sx({
          '&.Mui-focused fieldset.notchedOutline': {
            border: '2px solid red',
            display: 'none',
          },
        }),
    },
  },
});
export default function CardTaskTemplate() {
  const taskContext = useContext(BoardsContext);
  const [isTitle, seIsTitle] = useState(false);
  const handlerSectionTitleClick = (event) => {
    const text = event.target.value;
    if (text) {
      taskContext.addSectionHandler(text);
    }
    seIsTitle(!isTitle);
  };

  return (
    <ThemeProvider theme={taskTheme}>
      <div className={styles.rootContainer}>
        <div className={[styles.cardContainer, !isTitle && styles.cardContainerNot].join(' ')}>
          <div className={styles.cardSortable}>
            <div className={styles.cardSortableContent}>
              {isTitle ? (
                <div className="w-full">
                  <OutlinedInput
                    autoFocus
                    className={styles.cardInputTitle}
                    placeholder=""
                    size="small"
                    // onChange={handleChangeTitle}
                    onBlur={handlerSectionTitleClick}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handlerSectionTitleClick(e);
                      }
                    }}
                  />
                </div>
              ) : (
                <h3 className={styles.cardTitle}>
                  <button type="button" onClick={handlerSectionTitleClick}>
                    <Add /> Add Sections
                  </button>
                </h3>
              )}
            </div>
          </div>
          {isTitle && <div className={styles.cardContent} />}
        </div>
      </div>
    </ThemeProvider>
  );
}

// /* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { resetAsset } from '../../../store/slices/assetCrudSlice';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import BaseBackButton from '../../../components/Button/BaseBackButton';
import useGet from '../../../hooks/useGet';
import usePatchOrPutExtra from '../../../hooks/usePatchOrPutExtra';
import ArticleFormNew from '../../../components/Form/Article/ArticleFormNew';
import { toastLoading, toastSuccess, toastError } from '../../../constants/ToastConfig';
import renderError from '../../../utils/Error/renderError';

export default function ArticleDetail() {
  const [isArticleData, setIsArticleData] = useState(false);
  const [resIdArticle, setResIdArticle] = useState(null);

  const { articleId } = useParams();
  const dispatch = useDispatch();

  useGet({
    url: `articles/${articleId}/?language=1`,
    validate: 'article',
    onSuccess: (e) => {
      setIsArticleData(e?.data);
    },
  });

  useEffect(() => {
    dispatch(resetAsset());
  }, [dispatch]);

  let onToast;

  const { mutate: updateArticle } = usePatchOrPutExtra({
    url: `articles/${articleId}`,
    validate: ['article'],
    // onMutate: () => {
    //   onToast = toast.loading('Loading  ', toastLoading);
    // },
    onSuccess: (e) => {
      setResIdArticle(e.data.id);
      onToast = new Promise((resolve, reject) => {
        if (e && [200, 201].includes(e.request.status)) {
          setTimeout(() => {
            resolve('Success');
          }, 500);
        } else {
          const error = new Error('Request failed');
          error.data = JSON.parse(e.request.response);
          setTimeout(() => {
            renderError({ data: JSON.parse(e.request.response) });
            reject(error);
          }, 500);
        }
      });

      toast.promise(onToast, {
        pending: {
          render() {
            return 'Loading';
          },
          ...toastLoading,
        },
        success: {
          render({ data }) {
            return ` ${data}`;
          },
          ...toastSuccess,
        },
        error: {
          render() {
            return 'Error';
          },
          ...toastError,
        },
      });
    },
  });

  const onUpdateArticle = async (data) => {
    await updateArticle(data);
  };

  return (
    <>
      <BZHelmet title={isArticleData?.name ?? ' Article'} description="" content="" />
      <div>
        <BaseMainTitle title="Article Detail :" />
        <div className="p-5 ">
          <div className="bg-white border p-5 space-y-3 shadow rounded">
            <BaseBackButton />
            {isArticleData ? (
              <ArticleFormNew defaultValues={isArticleData} key={isArticleData?.id} onFormSubmit={onUpdateArticle} resIdArticle={resIdArticle} setResIdArticle={setResIdArticle} />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { Add, ModeEdit, Delete, Edit, Save, Info, AttachFile, Cancel, Close } from '@mui/icons-material';
import { InputBase, ButtonBase } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
// import { Link } from 'react-router-dom';
import Select from '../../../components/Input/SelectItem';
import BaseMainTitle from '../../../components/BaseMainTitle';
import ReactShortableWrapper from '../../../components/ReactShortableWrapper';
import BZHelmet from '../../../utils/BZHelmet';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import ChangeNameStage from '../../../components/Form/Recruitment/ChangeStageName';
import { handleModal } from '../../../store/slices/modalSlice';
import applicationApi from '../../../api/job/application';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import BaseBackButton from '../../../components/Button/BaseBackButton';
import { toastLoading } from '../../../constants/ToastConfig';
import ApplicationDetailForm from '../../../components/Form/Job/ApplicationDetailForm';
import InvitationForm from '../../../components/Form/Job/InvitationForm';
import OffersForm from '../../../components/Form/Job/OffersForm';
import ApplicantFormDisable from '../../../components/Form/Job/ApplicantFormDisable';
import ApplicationHistory from '../../../components/Modal/Job/ApplicationHistory';
import ApplicantDetail from '../ApplicantDetail';
import AssetsViewer from '../../../components/ui/AssetsViewer/AssetsViewer';
import ApplicationNotes from '../../../components/ui/Job/ApplicationNotes';
import { generateSelectListDefault, getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
// import ReactSelectMultiple from '../../components/Input/ReactSelectMultiple';
import ReactSelectSingle from '../../../components/Input/ReactSelectSingle';


function ApplicationDetail() {
  const { control, watch, setValue } = useForm({});
  const paramID = useParams();
  const activeAdminLanguage = useSelector((state) => state.client.activeAdminLanguage);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const [applicant, setApplicant] = useState([]);
  const [jobPoster, setJobPoster] = useState({});
  const [stages, setStages] = useState([]);
  const [editDetail, setEditDetail] = useState(false);
  const [notes, setNotes] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [resume, setResume] = useState([]);
  const [tempResume, setTempResume] = useState([]);
  const [currentStage, setCurrentStage] = useState(null);
  const [tempStage, setTempStage] = useState(null);
  const [reload, setReload] = useState(true);
  const [isPopoverAttachments, setPopoverAttachments] = useState(false);
  const [counterIdInput, setCounterIdInput] = useState(0);
  const [isFocusedComment, setIsFocusedComment] = useState(false);
  const [commentData, setIsCommentData] = useState('');
  const [filesComment, setFilesComment] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: '*',
    onDrop: (acceptedFiles) => {
      let filteredFiles = acceptedFiles;
      if (filesComment.length) {
        const newFilteredFiles = [];
        acceptedFiles.forEach((file) => !filesComment.some((fl) => fl.name === file.name) && newFilteredFiles.push(file));
        filteredFiles = newFilteredFiles;
      }
      const newFiles = filteredFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
      setFilesComment((prevState) => {
        if (prevState.length) {
          return [...prevState, ...newFiles];
        }
        return newFiles;
      });
    },
  });

  useEffect(() => {
    if (Object.keys(applicant).length !== 0) {
      setNotes(applicant?.notes);
      setAttachments(applicant?.attachments);
      const dataResume = applicant?.applicant?.resume;
      if (dataResume !== null) {
        setResume([dataResume]);
      }
    }
  }, [applicant]);

  useEffect(() => {
    if (attachments) {
      setCounterIdInput(attachments.length);
    }
  }, [attachments]);

  const getApplicant = useCallback(() => {
    const { getApplicantData } = applicationApi();
    return new Promise((resolve, reject) => {
      getApplicantData(paramID)
        .then((res) => {
          if (res.status === 200 && res.data) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, [paramID]);

  const getStagesCrud = useCallback((jobId) => {
    const { getStagesCrud } = applicationApi();
    return new Promise((resolve, reject) => {
      getStagesCrud(jobId)
        .then((res) => {
          if (res.status === 200 && res.data) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);

  const getApplicantDetail = useCallback(async () => {
    const [applicationList] = await Promise.all([getApplicant()]);
    if (applicationList) {
      setApplicant(applicationList);
      setJobPoster(applicationList.job_poster);
    }
  }, [getApplicant]);

  const getStagesData = useCallback(
    async (param, currentStageId) => {
      const [applicationList] = await Promise.all([getStagesCrud(param)]);
      if (applicationList) {
        const { results } = applicationList;
        const filterCurrentStage = results?.filter((val) => val.id === currentStageId);
        setStages(results);
        setCurrentStage(filterCurrentStage);
        setTempStage(filterCurrentStage);
      }
    },
    [getStagesCrud]
  );

  useEffect(() => {
    if (reload) {
      getApplicantDetail();
    }
  }, [paramID.id, activeAdminLanguage, reload]);

  useEffect(() => {
    if (Object.keys(applicant).length !== 0) {
      getStagesData(applicant?.job_poster?.id, applicant?.stage);
    }
    setReload(false);
  }, [applicant]);

  const changeNotes = (event) => {
    // console.log(event)
    setNote(event);
  };

  const deleteAttachment = (id) => {
    setAttachments((prevIndexes) => [...prevIndexes.filter((item, itemId) => itemId !== id)]);
  };

  const deleteResume = () => {
    return resume.length ? setResume([]) : setTempResume([]);
  };

  const handleFileInput = (e) => {
    const files = e.target.files;
    setAttachments((data) => [...data, ...files]);
  };

  const handleFileInputResume = (e) => {
    const file = e.target.files;
    setTempResume([...file]);
  };

  const submitUpdate = async () => {
    const { updateApplicationData, updateApplicantData } = applicationApi();

    const newUpdateData = {
      attach: attachments,
      stage: currentStage[0]?.id,
    };

    try {
      const bodyFormData = new FormData();
      const resumeFormData = new FormData();
      const applicantId = applicant?.applicant?.id;
      // const newResume = tempResume[0]

      for (const [key, val] of Object.entries(newUpdateData)) {
        if (key === 'attach') {
          val.forEach((file, id) => {
            if (file.id) {
              bodyFormData.append(`attachments[${id}]id`, file.id);
            } else {
              bodyFormData.append(`attachments[${id}]file`, file);
            }
          });
        } else {
          bodyFormData.append(key, val);
        }
      }

      const updateApplication = await updateApplicationData(paramID, bodyFormData);

      if (!resume.length) {
        if (tempResume.length) {
          resumeFormData.append(`resume`, tempResume[0]);

          const updateApplicant = await updateApplicantData(applicantId, resumeFormData);
          updateApplicant;
          setTempResume([]);
        } else {
          resumeFormData.append(`resume`, null);

          const updateApplicant = await updateApplicantData(applicantId, { resume: null });
          updateApplicant;
          setTempResume([]);
        }
      }

      return updateApplication, swal('Data Update Successfully!', { icon: 'success' }), setEditDetail(false), setReload(true);
    } catch (error) {
      console.error('error ', error);
    }
  };

  const handlePostNote = () => {
    const { postNewNote } = applicationApi();
    const applicantId = applicant?.id;

    try {
      const bodyFormData = new FormData();
      bodyFormData.append(`application`, applicantId);

      if (commentData || filesComment.length) {
        bodyFormData.append(`note`, commentData);

        if (filesComment.length) {
          filesComment.forEach((file, id) => {
            bodyFormData.append(`attachment`, file);
          });
        }

        const newData = {
          application: applicantId,
          note: commentData,
          attachment: filesComment,
        };

        postNewNote(bodyFormData)
          .then((res) => {
            if (res.data) {
              setIsCommentData('');
              setIsFocusedComment(false);
              setFilesComment([]);
              swal('New Note Has Been Send!', { icon: 'success' });
              return setReload(true);
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      } else {
        setIsFocusedComment(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const activeStage = (e) => {
    return setCurrentStage(stages?.filter((val) => val.id === e));
  };

  function getFileNameAndType(url) {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  const activeButton = (props) => {
    if (props) {
      const { type } = props;

      if (type === 'Interview') {
        return (
          <>
            <button
              type="button"
              className="bg-blue-700 w-[150px] flex justify-center btn rounded border-0 mr-2 px-4 py-2 text-white"
              onClick={() => dispatch(handleModal({ modalId: '', componentName: 'Invitation', modalData: { application: applicant?.id, stage: props?.id } }))}
              disabled={editDetail}
            >
              Send Invitation
            </button>
            <div className="flex items-center">
              <Info
                className="cursor-pointer"
                onClick={() => dispatch(handleModal({ modalId: '', componentName: 'MailingHistory', modalData: [applicant?.interviews, applicant?.offers] }))}
              />
            </div>
          </>
        );
      }

      if (type === 'Offer') {
        return (
          <>
            <button
              type="button"
              className="bg-blue-700 w-[150px] flex justify-center btn rounded border-0 mr-2 px-4 py-2 text-white"
              onClick={() => dispatch(handleModal({ modalId: '', componentName: 'Offers', modalData: applicant?.id }))}
              disabled={editDetail}
            >
              Send Offers
            </button>
            <div className="flex items-center">
              <Info
                className="cursor-pointer"
                onClick={() => dispatch(handleModal({ modalId: '', componentName: 'MailingHistory', modalData: [applicant?.interviews, applicant?.offers] }))}
              />
            </div>
          </>
        );
      }
    } else {
    }
  };

  const activeEditResume = (props) => {
    if (props !== 0) {
      return <Delete className="cursor-pointer" onClick={deleteResume} />;
    }
    return (
      <>
        <input type="file" id="file_resume" name="file_resume" style={{ display: 'none' }} onChange={handleFileInputResume} />
        <label htmlFor="file_resume">
          <Add className="cursor-pointer" />
        </label>
      </>
    );
  };

  const cancelButtonClick = () => {
    return setEditDetail(false), setReload(true);
  };

  const handleFocusComment = () => {
    setIsFocusedComment(!isFocusedComment);
  };

  const handleChangeComment = (event) => {
    const { value } = event.target;
    setIsCommentData(value);
  };

  const handleRemoveAttachment = (name) => {
    setFilesComment(filesComment.filter((file) => file.name !== name));
  };

  const thumbsComment = filesComment.map((file) => {
    const { name, type } = file;
    const fileExt = name.substring(name.lastIndexOf('.') + 1);
    const fileType = type.substring(0, type.indexOf('/'));
    let html;
    const closeHtml = (
      <div className="absolute top-0 right-1 z-10 cursor-pointer" style={{ color: 'rgba(255, 255, 255, .5)' }}>
        <Close
          fontSize="small"
          color="inherit"
          onClick={() => handleRemoveAttachment(file.name)}
          className="bg-gray-700 bg-opacity-50 border border-gray-300 rounded-full shadow-sm"
        />
      </div>
    );
    if (type.includes('image') || fileType === 'image') {
      html = (
        <div key={file.name} className="relative bg-white p-1 border border-[#eaeaea] rounded-md h-16 min-w-[64px] max-w-[192px] flex items-center justify-center">
          {closeHtml}
          <img src={file.preview} className="w-auto h-full object-fill" alt="" loading="lazy" />
        </div>
      );
    } else {
      html = (
        <div key={file.name} className="relative bg-[#eaeaea] py-1 pl-2 pr-8 border border-[#eaeaea] rounded-md h-16 min-w-[64px] max-w-[192px] flex items-center justify-center">
          {closeHtml}
          <div className="flex space-x-1 items-center">
            <Article color="disabled" fontSize="large" />
            <div className="flex flex-col max-w-[112px]">
              <span className="relative overflow-hidden text-ellipsis text-sm whitespace-nowrap">{name}</span>
              <span className="text-xs text-gray-500">{`${fileExt} File`}</span>
            </div>
          </div>
        </div>
      );
    }
    return html;
  });

  let maxSalary;
  let minSalary;
  if (jobPoster) {
    maxSalary = jobPoster.max_salary;
    minSalary = jobPoster.min_salary;
  }

  return (
    applicant &&
    jobPoster && (
      <>
        <BZHelmet title="Application Detail" description="" content="" />
        <BaseMainTitle title="Application Detail" />
        <div className="w-full p-5">
          <div className="w-full border p-5 shadow rounded space-y-5">
            <div className="w-full">
              <BaseBackButton />
            </div>
            <div className="w-full grid grid-cols-2 pb-2 border-b-2 border-black">
              <div className="w-full">
                <div className="flex gap-x-2">
                  <h2 className="font-bold">{applicant?.applicant?.user?.name}</h2>
                  <Info
                    className="cursor-pointer"
                    // please keep modalId: applicant?.applicant?.id
                    onClick={() => dispatch(handleModal({ modalId: applicant?.applicant?.id, componentName: 'DetailForm', modalData: applicant?.applicant }))}
                  />
                </div>
                <p className="text-slate-400">{jobPoster?.article?.article_languages[0]?.title}</p>
              </div>
              <div className="w-full flex justify-end">
                <div className="w-[200px]">
                  {currentStage && (
                    // <Select
                    //   name="Applicant Stage"
                    //   control={control}
                    //   label="Applicant Stage"
                    //   defaultValue={currentStage[0]?.id}
                    //   options={stages?.map((attr) => ({ label: attr.name, value: attr.id }))}
                    //   multiple={false}
                    //   OptionLabel="Applicant Stage"
                    //   disabled={!editDetail}
                    //   menuProps={{ style: { maxHeight: 400 } }}
                    //   onChangeValue={activeStage}
                    //   isInputLabel
                    // />
                    <ReactSelectSingle
                      name="Applicant Stage"
                      control={control}
                      isDisabled={!editDetail}
                      options={getOptionSelectWithKey(stages)}
                      defaultValue={getDefaultSelectObject(watch(`Applicant Stage`) || currentStage[0]?.id, getOptionSelectWithKey(stages))}
                      onChangeValue={activeStage}
                      placeholder="Applicant Stage"
                    />
                  )}
                  <div className="flex mt-2 justify-center">{tempStage && activeButton(...tempStage)}</div>
                </div>
              </div>
            </div>
            <div className="w-full py-4 relative">
              <div className="flex gap-x-4 absolute right-0">
                <button type="button" className="" onClick={() => (!editDetail ? setEditDetail(!editDetail) : submitUpdate())}>
                  {!editDetail ? <Edit /> : <Save color="success" />}
                </button>
                {editDetail && (
                  <button onClick={cancelButtonClick}>
                    <Cancel color="action" />
                  </button>
                )}
              </div>
              <div className="">
                <div className="space-y-4">
                  <div className="space-y-2">
                    <div className="font-bold">Applied On</div>
                    <input className="w-[250px] py-[8.5px] px-[14px] border rounded-md" disabled value={moment(applicant?.apply_date).format('L')} />
                  </div>
                  <div className="space-y-2">
                    <div className="font-bold">Work Location</div>
                    <input className="w-[250px] py-[8.5px] px-[14px] border rounded-md" disabled value={jobPoster && jobPoster.article?.location?.name} />
                  </div>
                  <div className="space-y-2">
                    <div className="font-bold">Range Salary</div>
                    <div className="flex gap-x-4">
                      <input className="w-[250px] py-[8.5px] px-[14px] border rounded-md" disabled value={minSalary && minSalary.toLocaleString('de-DE')} />
                      <div className="flex items-center">
                        <p>-</p>
                      </div>
                      <input className="w-[250px] py-[8.5px] px-[14px] border rounded-md" disabled value={maxSalary && maxSalary.toLocaleString('de-DE')} />
                    </div>
                  </div>
                  <div className="flex gap-x-10">
                    <div className={`font-bold bg-white ${attachments && !editDetail && attachments.length > 2 ? 'w-[96px]' : 'w-[107px]'}`}>Resume</div>
                    <div className="flex gap-x-4 flex-wrap">
                      {resume.length !== 0 && (
                        <a className="flex gap-x-1" href={resume} target="_blank" rel="noreferrer">
                          <AttachFile color="primary" />
                          <p className="font-medium text-[#2196f3]">{getFileNameAndType(resume[0])}</p>
                        </a>
                      )}
                      {tempResume.length !== 0 && (
                        <div className="flex gap-x-1">
                          <AttachFile color="primary" />
                          <p className="font-medium text-[#2196f3]">{tempResume[0]?.name}</p>
                        </div>
                      )}
                      {editDetail && activeEditResume(resume.length !== 0 ? resume.length : tempResume.length)}
                      {!editDetail && resume.length === 0 && <p className="ml-2">-</p>}
                    </div>
                  </div>
                  <div className="w-full flex gap-x-10">
                    <div className="font-bold bg-white w-[107px]">Attachments</div>
                    <div className="">
                      <div className={editDetail ? 'grid gap-y-4' : 'flex gap-4 flex-wrap'}>
                        {attachments &&
                          attachments.map((val, id) => (
                            <div key={val.id} className="flex gap-x-4">
                              <a className="flex gap-x-1" href={val?.file} target="_blank" rel="noreferrer">
                                <AttachFile color="primary" />
                                <p className="font-medium text-[#2196f3]">{val?.file ? getFileNameAndType(val?.file) : val?.name}</p>
                              </a>
                              {editDetail && <Delete className="cursor-pointer" onClick={() => deleteAttachment(id)} />}
                            </div>
                          ))}
                        {editDetail === true && (
                          <>
                            <input type="file" id="file_attach" name="file_attach" style={{ display: 'none' }} onChange={handleFileInput} />
                            <label htmlFor="file_attach">
                              <Add className="cursor-pointer" />
                            </label>
                          </>
                        )}
                        {!editDetail && !attachments.length && <p className="ml-2">-</p>}
                      </div>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="font-bold">Notes</div>
                    <div className="w-full h-[500px] bg-[#F9F8F8] overflow-y-auto">
                      {notes &&
                        notes.length > 0 &&
                        notes.map((note, index) => <ApplicationNotes key={note.id} notes={note} index={index} setPopoverAttachments={setPopoverAttachments} />)}
                    </div>
                    <div className="relative border-t-2">
                      <div className="px-4 py-2 w-full flex flex-col min-h-[127px]">
                        <InputBase
                          placeholder="Give Note Here...."
                          multiline
                          className="ml-2 w-full border-0"
                          value={commentData}
                          onFocus={handleFocusComment}
                          // onBlur={handlePostComment}
                          onChange={handleChangeComment}
                          onKeyDown={() => {}}
                        />
                        <aside className="flex flex-wrap my-2 gap-2">{thumbsComment}</aside>
                        <div className="w-full flex mt-auto">
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <AttachFile />
                          </div>
                          <ButtonBase className="p-2 ml-auto bg-blue-500 text-white rounded-xl" onClick={handlePostNote}>
                            Send Note
                          </ButtonBase>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {
          notes.length && (
            <AssetsViewer
              openAttachments={isPopoverAttachments || (String(isPopoverAttachments) === '0' && '0')}
              assets={notes}
              handleCloseAttachments={() => setPopoverAttachments(false)}
            />
          )
        } */}
        <ModalWrapper componentName="DetailForm" header="Applicant Data" maxWidth="lg">
          {/* <ApplicationDetailForm reload={setReload} /> */}
          <ApplicantDetail isModal={modal?.modalId} cancel={() => dispatch(handleModal({ modalId: '', componentName: '' }))} />
        </ModalWrapper>
        <ModalWrapper componentName="Invitation" header="Send Invitation" maxWidth="sm">
          <InvitationForm reload={setReload} location={jobPoster.article?.location?.name} />
        </ModalWrapper>
        <ModalWrapper componentName="Offers" header="Send Offers" maxWidth="sm">
          <OffersForm reload={setReload} />
        </ModalWrapper>
        <ModalWrapper componentName="MailingHistory" header="Mailing history" maxWidth="lg">
          <ApplicationHistory />
        </ModalWrapper>
      </>
    )
  );
}

export default ApplicationDetail;

/* eslint-disable */
import React, { useContext, useState } from 'react';
import { Box, Button, ButtonBase, Fade, InputBase, Popover } from '@mui/material';
import { Article, KeyboardArrowDown } from '@mui/icons-material';
import { format } from 'date-fns';

// import BoardsContext from '../../../../pages/Task/Boards/BoardsContext';
// import projectApi from '../../../../api/task/project';
// import styles from './DrawerTask.module.css';
import GetAvatar from '../Task/Content/GetAvatar';

function ApplicationNotes({ notes, setPopoverAttachments, index }) {
  let note;

  if (notes.attachment) {
    const fileExt = notes.attachment.substring(notes.attachment.lastIndexOf('.') + 1);
    const fileName = notes.attachment?.substring(notes.attachment.lastIndexOf('/') + 1);
    const imgTypes = ['jpg', 'jpeg', 'png', 'webp', 'svg'];

    if (!imgTypes.includes(fileExt.toLowerCase())) {
      note = (
        <a key={notes.id} href={notes.attachment} target="_blank" rel="noreferrer">
          <div className="relative bg-[#eaeaea] py-1 pl-2 pr-8 border border-[#eaeaea] rounded-md h-16 min-w-[64px] max-w-[192px] flex items-center shadow-md">
            <div className="flex space-x-1 items-center">
              <Article color="disabled" fontSize="large" />
              <div className="flex flex-col max-w-[112px]">
                <span className="text-ellipsis text-sm whitespace-nowrap overflow-hidden">{fileName?.substring(0, fileName.indexOf('.'))}</span>
                <span className="text-xs text-gray-500">{`${fileExt} File`}</span>
              </div>
            </div>
          </div>
        </a>
      );
    } else {
      note = (
        <div key={notes.attachment} onClick={() => setPopoverAttachments(index)} aria-hidden>
          <img className="object-cover h-auto max-h-[480px] max-w-[300px]" src={notes.attachment} alt="" />
        </div>
      );
    }
  }

  return (
    <div key={notes.id} className="flex flex-col pl-7 pr-6 my-4">
      <div className="w-full flex items-center py-4">
        <div className="w-full flex items-center space-x-2">
          <GetAvatar data={notes} onClick={() => {}} subject="user" />
          <span>
            <b>{notes.user.name}</b>
          </span>
          <span>{format(new Date(notes.timestamp), 'MMM d, yyyy')}</span>
        </div>
      </div>
      <div className="relative flex flex-col space-y-2 pl-8">
        {notes.note && <span>{notes.note}</span>}
        {note}
      </div>
    </div>
  );
}

export default ApplicationNotes;

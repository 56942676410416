/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
// import InfiniteScroll from 'react-infinite-scroller';

import { Droppable } from '../../../../utils/Task/DragDrop';

import { ContentTask } from '../ContentTask/ContentTask';

import DetailContext from '../../../../pages/Task/Projects/DetailContext';

import styles from './ContainerTaskVertical.module.css';

// const limit = 10;
export function ContainerTasks({ sectionId, topics }) {
  const [isCreate, setCreate] = useState(false);

  let html;
  if (topics?.length > 0) {
    html = (
      <div className="flex flex-col space-y-2 bg-transparent overflow-x-hidden">
        <Droppable droppableId={`${sectionId}`} type="card">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className={[styles.rootContainerVertical, 'flex flex-col space-y-2 bg-transparent overflow-x-hidden'].join(' ')}>
                {/* <InfiniteScroll
                  pageStart={1}
                  loadMore={slicedTopic}
                  hasMore={topics.length - (currTopics ? currTopics.length : 0) > 0}
                  loader={
                    <div className="w-full flex justify-center items-center py-1">
                      <div className={styles.loader} />
                    </div>
                  }
                  useWindow={false}
                > */}
                {topics.map((card, i) => (
                  <ContentTask
                    cardData={card}
                    key={[card?.id, JSON.stringify(card)].join(' ')}
                    id={card?.id}
                    text={card?.name}
                    indexTopic={i}
                    image={card?.thumbnail}
                    sectionId={sectionId}
                  />
                ))}
                {provided.placeholder}
                {/* </InfiniteScroll> */}
                {isCreate && <ContentTask sectionId={sectionId} isCreate={isCreate} callbackCreateTask={(param) => setCreate(param)} topicsLength={topics.length} />}
                <Button
                  style={{ backgroundColor: '#fff', marginBottom: '16px' }}
                  className={[styles.addTask, 'shadow-md mx-auto mt-2'].join(' ')}
                  startIcon={<Add />}
                  onClick={() => setCreate(true)}
                >
                  Add Task
                </Button>
              </div>
            </div>
          )}
        </Droppable>
      </div>
    );
  } else {
    html = (
      <div className="flex flex-col space-y-2 bg-transparent" style={{ height: 'calc(100vh - 200px)' }}>
        <Droppable droppableId={`${sectionId}`} type="card">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className="flex flex-col">
                <ContentTask sectionId={sectionId} isEmpty isCreate={isCreate} callbackCreateTask={(param) => setCreate(param)} topicsLength={0} />
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
  return html;
}

export const ContainerTaskVertical = React.memo(ContainerTasks);

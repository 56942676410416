import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export default function InputDynamicField({
  name = '',
  type = 'text',
  label = '',
  isRequired,
  register = {},
  errors,
  ToInputProps = {},
  ToInputLabelProps = {},
  form = {},
  originType,
  isEdit = false,
  onPatchProjectAttribute = () => { },
  ...props
}) {
  const [focus, setFocus] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const [_, setInputValue] = useState('');
  const [timer, setTimer] = useState(null);
  const isError = errors && name in errors;

  const isReference = !!props.configuration?.is_reference;
  const { setValue, watch } = form;
  const watchRef = watch(name);

  const valueSameAsRef = props.configuration && props.configuration.default_value && String(props.configuration.default_value) === String(watchRef);

  const inputStyle = { border: 'none' };
  const labelStyle = {};
  if (!focus && isReference && !isError) {
    labelStyle.color = 'rgba(0, 0, 0, 0.6)';
    if (!watchRef) {
      inputStyle.border = '1.5px solid var(--primary)';
      labelStyle.color = 'var(--primary)';
    } else {
      inputStyle.border = '1.5px solid var(--weak)';
      labelStyle.color = 'var(--weak)';
    }

    if (valueSameAsRef) {
      inputStyle.border = '1.5px solid #53a336';
      labelStyle.color = '#53a336';
    }
  }

  const onSetValue = (val) => {
    // console.log({ name, type, val })
    setValue(name, val);
    if (!val) val = null;
    if (val && type === 'number') val = +val;
    onPatchProjectAttribute({ [name]: val });
  };

  const inputChanged = (e) => {
    if (!isEdit) return;
    let val = e.target.value;
    setInputValue(val);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      if (isRequired && !val) {
        form.setError(name, { type: 'custom', message: `${name} is a required field` });
        return;
      } else {
        form.clearErrors(name);
      }
      onSetValue(val);
    }, 1000);

    setTimer(newTimer);
  };

  const onBlur = (e) => {
    const val = e.target.value;
    if (!val) {
      onSetValue(val)
    }
  };

  useEffect(() => {
    if (originType === 'Formula') {
      setDisable(true);
    }
  }, [originType]);

  const activeHandlerOnEditMode = {};
  if (isEdit) {
    activeHandlerOnEditMode.onChange = inputChanged;
    activeHandlerOnEditMode.onBlurCapture = onBlur;
  }

  return (
    <div className="relative col-span-1 w-full h-full">
      {isDisable && originType === 'Formula' && (
        <Button
          className="w-full h-full absolute top-0 left-0 z-10"
          title="Click to input value"
          onClick={() => {
            if (originType === 'Formula') {
              setDisable(false);
            }
          }}
        />
      )}
      <TextField
        type={type}
        label={label}
        error={isError}
        name={name}
        required={isRequired}
        size="small"
        fullWidth
        disabled={isDisable}
        {...register}
        {...props}
        InputProps={{ ...ToInputProps }}
        InputLabelProps={{ ...ToInputLabelProps }}
        title={isReference ? `${label} default value ${props.configuration.default_value}` : props.title || ''}
        sx={{
          '> .MuiInputLabel-root': { backgroundColor: '#fff', ...labelStyle },
          '> .MuiInputBase-root': { ...inputStyle },
          '> .MuiFormHelperText-root': {
            width: 'auto',
            maxWidth: '90%',
            position: 'absolute',
            bottom: '-18px',
            right: 0,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        }}
        {...activeHandlerOnEditMode}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          if (originType === 'Formula') {
            setDisable(true);
          }
          setFocus(false);
        }}
      />
      {props?.showErrorMessage && isError && <div className="text-[#d32f2f] text-[0.75rem] mt-[4px] border-[#d32f2f]">{errors?.message || errors[name]?.message}</div>}
    </div>
  );
}

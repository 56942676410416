import React from 'react';
import BZHelmet from '../../utils/BZHelmet';

export default function AbousUs() {
  return (
    <div>
      <BZHelmet title="About Us" description="bz publish about us" />
      AbousUs
    </div>
  );
}

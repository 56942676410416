import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { Avatar, InputAdornment, InputBase, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, CircularProgress } from '@mui/material';
import { GridView, Search, ViewList } from '@mui/icons-material';
import MuiSelectSingleItem from '@/components/Input/MuiSelectSingleItem';
import _ from 'lodash';
import { handleModal } from '@/store/slices/modalSlice';
import BzThumbnail from '@/assets/simple.png';
import { useForm } from 'react-hook-form';

function AddOnsPair({onProductChange}) {
  const { control, reset, resetField } = useForm();
  const activeClient = useSelector((state) => state.modal.modalId);
  const productData = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(true);
  const [products, setProducts] = useState([]);
  const [selectedProducts, SetSelectedProducts] = useState(productData?.pairComponentIds);
  // const [searchProducts, setSearchProducts] = useState("");
  const [pickProducts, SetPickProducts] = useState(productData?.pairComponents);
  // const [searchValue] = useDebounce(searchProducts, 300);
  const [categoriesOptions, setCategoriesOptions] = useState(productData?.filterOptions?.categoriesOptions || []);
  const [typesOptions, setTypesOptions] = useState(productData?.filterOptions?.typesOptions || []);
  const [collectionsOptions, setCollectionsptions] = useState(productData?.filterOptions?.collectionsOptions || []);

  const [paramProducts, setParamProducts] = useState(new URLSearchParams(`client=${activeClient}&max_size=true`));

  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [collection, setCollection] = useState(null);
  const [searchProducts, setSearchProducts] = useState("");
  const [searchValue] = useDebounce(searchProducts, 300);

  const [isGrid, setIsGrid] = useState(true);

  // useEffect(() => {
  //   const params = new URLSearchParams(`client=${activeClient}&max_size=true`);
  //   if (searchValue) {
  //     params.set("search", searchValue.split(" ").join("+"));
  //   }
  //   setParamProducts(params);
  // }, [searchValue, activeClient]);

  useEffect(() => {
    const params = new URLSearchParams(`client=${activeClient}&max_size=true`);
    
    if (searchValue) {
      params.set("search", searchValue.split(" ").join("+"));
    }
    if (category) {
      params.set("category__name", category);
    }
    if (collection) {
      params.set("collections", collection);
    }
    if (type) {
      params.set("type__name", type);
    }
    
    setParamProducts(params);
    setIsFetching(true);
  }, [collection, type, category, searchValue]);

  const { getProductList } = ProductManagementApi();
  const getAllProducts = useCallback(async (paramProducts) => {
    try {
      const fetchData = await getProductList(paramProducts);
      const response = fetchData.data;
      if (response.count > 0) {
        const { results } = response;
        if (productData?.productId) {
          setProducts(results.filter((dt) => dt.id !== +productData.productId));
        } else {
          setProducts(results);
        }
      } else {
        setProducts([]);
      }
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  }, [paramProducts, productData?.productId]);

  useEffect(() => {
    // if (productData && productData.productId) {
      getAllProducts(paramProducts);
    // }
  }, [paramProducts, getAllProducts, productData]);

  const handleSelectedProducts = (value, productId, productData) => {
    let newSelectedProducts = new Set(selectedProducts);
    let pickProductsClone = _.cloneDeep(pickProducts)

    if (value === true) {
      newSelectedProducts.add(productId);
      pickProductsClone.push({product: productData, quantity: 1});
    } else {
      newSelectedProducts.delete(productId);
      pickProductsClone = pickProductsClone.filter((data) => data.product.id !== productId);
    }
      
    SetPickProducts([...pickProductsClone]);
    SetSelectedProducts([...newSelectedProducts]);
  };

  const onChangeProductQuantity = (value, index) => {
    let pickProductsClone = _.cloneDeep(pickProducts);
    pickProductsClone[index].quantity = parseFloat(value) < 1 ? 1 : parseFloat(value);

    SetPickProducts([...pickProductsClone]);
  }
  
  const onSubmitComponent = () => {
    onProductChange(pickProducts)
    dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
  }

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  return (
    <div className="w-full flex space-x-5 relative" style={{ maxHeight: "850px", overflowY: "hidden" }}>
      <div className="w-[70%] pr-2">
        <div className="w-full flex mb-4 justify-between items-center">
          <div className='flex gap-4 flex-wrap'>
            <div className="w-[14rem]">
              <div>
                <InputLabel>Collection</InputLabel>
              </div>
              <MuiSelectSingleItem
                sx={{ height: '40px', py: 0 }}
                FormControlClasses="h-[2.5rem]"
                name="collection"
                label="collection"
                OptionLabel="collection"
                control={control}
                options={collectionsOptions.map((dt) => ({ label: dt.name, value: dt.id }))}
                defaultValue={collection}
                onChangeValue={(v) => {
                  setCollection(v);
                }}
                isInputLabel
              />
            </div>
            <div className="w-[14rem]">
              <div>
                <InputLabel>Category</InputLabel>
              </div>
              <MuiSelectSingleItem
                sx={{ height: '40px', py: 0 }}
                FormControlClasses="h-[2.5rem]"
                name="category"
                label="category"
                OptionLabel="category"
                control={control}
                options={categoriesOptions.map((dt) => ({ label: dt.name, value: dt.name }))}
                defaultValue={category}
                onChangeValue={(v) => {
                  setCategory(v);
                }}
                isInputLabel
              />
            </div>
            <div className="w-[14rem]">
              <div>
                <InputLabel>Type</InputLabel>
              </div>
              <MuiSelectSingleItem
                sx={{ height: '40px', py: 0 }}
                FormControlClasses="h-[2.5rem]"
                name="type"
                label="type"
                OptionLabel="type"
                control={control}
                options={typesOptions.map((dt) => ({ label: dt.name, value: dt.name }))}
                defaultValue={type}
                onChangeValue={(v) => {
                  setType(v);
                }}
                isInputLabel
              />
            </div>
          </div>
          <Tooltip className='cursor-pointer' title={!isGrid ? 'Show Grid' : 'Show List'}>
            {isGrid ? <ViewList className="scale-125" onClick={() => setIsGrid(false)} />  :  <GridView className="scale-125" onClick={() => setIsGrid(true)} />}
          </Tooltip>
        </div>
        {
          isFetching ? (
            <CircularProgress />
          ): (
            <div className="pr-2 overflow-y-auto max-h-[800px]">
              {
                isGrid ? (
                  <div className="grid grid-cols-3 gap-6 pb-2">
                    {
                      products.length > 0 && 
                        products.map((dt) => (
                          <div key={dt.id} className="w-full border rounded-md shadow-sm">
                            <div className="flex justify-between bg-[#2C6D47] p-4 rounded-t-md">
                              <div className="text-white flex items-center w-full">
                                <input
                                  type="checkbox"
                                  className="h-4 w-4"
                                  id={`location-pair-${dt.id}`}
                                  checked={selectedProducts.includes(dt.id)}
                                  onChange={(e) => {
                                    handleSelectedProducts(e.target.checked, dt.id, dt);
                                  }}
                                />
                                <div className="text-lg ml-2 w-full flex items-center justify-between" htmlFor={`location-pair-${dt.id}`}>
                                  <p>{dt.name}</p>
                                  <p className='text-sm'>ID: {dt.item_number}</p>
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-x-2 min-h-[150px] p-2 justify-center items-center">
                              <img className={`scale-90 max-h-[150px] origin-top-left ${!dt?.media_main && "grayscale"}`} src={dt?.media_main || BzThumbnail} />
                            </div>
                          </div>
                        ))
                    }
                  </div>
                ) : (
                  <div className=''>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center' className='!font-bold'>Item Number</TableCell>
                        <TableCell align='center' className='!font-bold' width={500}>Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                      products.length > 0 && 
                        products.map((dt) => (
                          <TableRow>
                            <TableCell align='left' >
                              <div className="flex items-center gap-x-8">
                                <input
                                  type="checkbox"
                                  className="h-4 w-4"
                                  id={`location-pair-${dt.id}`}
                                  checked={selectedProducts.includes(dt.id)}
                                  onChange={(e) => {
                                    handleSelectedProducts(e.target.checked, dt.id, dt);
                                  }}
                                />
                                <span>{dt?.item_number || "-"}</span>
                              </div>
                            </TableCell>
                            <TableCell align='left'>
                              <div className="flex items-center gap-x-4">
                                <Avatar sx={{ width: 40, height: 40 }} src={dt?.media_main} />
                                <span>{dt?.name || "-"}</span>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                    }
                    </TableBody>
                  </Table>
                </div>
                )
              }
            </div>
          )
        }
      </div>
      <div className="w-[30%] space-y-5">
        <div className="space-y-5">
          <div className="w-full">
            <div>
              <InputLabel>Search Components</InputLabel>
            </div>
            <InputBase
              size="small"
              placeholder="search"
              endAdornment={<Search color="disabled" />}
              onChange={(v) => setSearchProducts(v.target.value)}
              value={searchProducts}
              className="w-full h-[2.5rem] border border-[#BCB9B9] rounded px-4 bg-white hover:border-[#adacac]"
            />
          </div>
          <div className="w-full flex justify-end gap-x-3 pb-4 border-b">
            <button 
              className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white" 
              variant="contained" 
              onClick={onSubmitComponent}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="space-y-3 px-2 pb-2 overflow-y-auto max-h-[680px]">
        {
          pickProducts.length > 0 && 
            pickProducts.map((dt, idx) => (
              <div key={dt.product.id} className="w-full border rounded-md shadow-sm">
                <div className="flex justify-between bg-[#2C6D47] p-4 rounded-md items-center text-white">
                  <div className="flex items-center w-full ">
                    <input
                      type="checkbox"
                      className="h-4 w-4"
                      checked
                      onChange={(e) => {
                        handleSelectedProducts(e.target.checked, dt.product.id, dt);
                      }}
                      id={`location-pair-${dt.id}`}
                    />
                    <div className="text-lg ml-2 flex w-full justify-between items-center" htmlFor={`location-pair-${dt.id}`}>
                      <p>{dt.product.name}</p>
                      <p className='text-sm'>ID: {dt.product.item_number}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default AddOnsPair;
/* eslint-disable */
import { Delete, Edit } from '@mui/icons-material';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import getDefaultValues from '../../helper/getDefaultValues';
import HandleWatchingState from '../../helper/HandleWatchingState';
import { generateSelectListDefault } from '../../lib/OptionsInputHelper';
import { handleModal } from '../../store/slices/modalSlice';

const SelectMultipleContext = createContext();

const { ValueContainer, Placeholder } = components;

function filterArray(arr1, arr2) {
  return arr1?.filter((item) => arr2?.includes(item.value));
}

function Option(props) {
  return (
    <div>
      <components.Option {...props} className="flex ">
        <input className="invisible" type="checkbox" checked={props.isSelected} onChange={() => null} />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
}

export default function ReactSelectMultipleAsync({
  options = [],
  defaultValue = '',
  onChange,
  isMulti = false,
  getOptionLabel,
  getOptionValue,
  placeholder,
  isSearchable,
  customDelete,
  actionDelete,
  componentEditName,
  errors,
  isDisabled = false,
  currValue,
  resetReplaceValue,
  resetThisReplaceValue,
  isCancel,
  setIsCancel,
  isRendered,
  setIsRendered,
}) {
  const dispatch = useDispatch();

  const [isFocused, setIsFocused] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [outSideClick, setOutSideClick] = useState(false);

  function CustomValueContainer({ children, ...props }) {
    // const [setIsFocused] = React.useContext(SelectMultipleContext);
    const watchInterview = HandleWatchingState(JSON.stringify(resetThisReplaceValue));

    // useEffect(() => {
    //   if (pasOpen) {
    //     props.selectProps.onMenuOpen();
    //     setPasOpen(false);
    //   }
    // }, [props.selectProps]);

    useEffect(() => {
      if (!props.selectProps.menuIsOpen && resetThisReplaceValue && options && watchInterview) {
        props.setValue(filterArray(options, resetThisReplaceValue));
        // setIsFocused(false);
      }
    }, [props, watchInterview]);

    useEffect(() => {
      if (props.selectProps.menuIsOpen && resetReplaceValue) {
        setIsFocused(true);
      }
    }, [props]);

    useEffect(() => {
      if (props.selectProps.menuIsOpen && options && watchInterview) {
        setIsFocused(true);
        // resetReplaceValue();
      }
    }, [props, watchInterview]);

    useEffect(() => {
      if (props.selectProps.menuIsOpen) {
        setIsFocused(true);
      }
    }, [props.selectProps.menuIsOpen]);

    useEffect(() => {
      if (outSideClick) {
        props.selectProps.onMenuClose();
      }
    }, [props.selectProps]);

    useEffect(() => {
      if (isCancel) {
        props.setValue(defaultValue || [{ label: '-- No Selected --', value: '' }]);
        setIsCancel(false);
      }
    }, [props]);
    useEffect(() => {
      if (defaultValue && defaultValue.length > 0 && isRendered) {
        props.setValue(defaultValue);
        setIsRendered(false);
      }
    }, [props, isRendered]);
    useEffect(() => {
      if (defaultValue && defaultValue.length === 0 && isRendered) {
        props.setValue([{ label: '-- No Selected --', value: '' }]);
        setIsRendered(false);
      }
    }, [props, isRendered]);

    return (
      <ValueContainer {...props}>
        <Placeholder className={`${errors && 'text-[#d32f2f] '}`} {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
      </ValueContainer>
    );
  }

  function CustomOption(props) {
    return (
      <div>
        <components.Option {...props} className="flex ">
          <input className="invisible" type="checkbox" checked={props.isSelected} onChange={() => null} />
          <label>{props.label}</label>
          <div className="flex space-x-2 ml-auto">
            <div onClick={() => dispatch(handleModal({ modalId: props.value, componentName: componentEditName }))} className="">
              <Edit />
            </div>
            <div onClick={() => actionDelete(props.value)} className="">
              <Delete />
            </div>
          </div>
        </components.Option>
      </div>
    );
  }

  const watchingCurrValue = HandleWatchingState(currValue);

  useEffect(() => {
    if (currValue && !watchingCurrValue && isFocused && resetReplaceValue) {
      resetReplaceValue();
      setIsFocused(false);
    }
  }, [currValue, watchingCurrValue, isFocused, resetReplaceValue]);

  const value = [isFocused, setIsFocused, isOpen, setIsOpen];

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setMenuIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  return (
    <div className=" w-full" ref={selectRef}>
      <SelectMultipleContext.Provider value={value}>
        <Select
          className={`${errors && 'border-[1px] border-[#d32f2f] rounded-[4px]'}`}
          menuPosition="fixed"
          menuPlacement="auto"
          closeMenuOnSelect={!isMulti}
          isDisabled={isDisabled}
          closeMenuOnScroll={true}
          hideSelectedOptions={false}
          options={options}
          blurInputOnSelect
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          onChange={onChange}
          defaultValue={defaultValue}
          components={{ Option: customDelete ? CustomOption : Option, ValueContainer: CustomValueContainer }}
          isSearchable={isSearchable}
          errors={Boolean(errors)}
          isMulti={isMulti}
          isDisable={false}
          placeholder={placeholder}
          renderValue={(selected) => selected.join(' ')}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          styles={{
            container: (provided) => ({
              ...provided,
              // marginTop: 50,
              border: '1px, solid, red',
            }),
            valueContainer: (provided) => ({
              ...provided,
              overflow: 'visible',
              border: '1px, solid, red',
            }),
            placeholder: (provided, state) => ({
              ...provided,
              border: '1px, solid, red',
              position: 'absolute',
              top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
              transition: 'top 0.1s, font-size 0.1s',
              fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
              background: state.hasValue ? 'white' : 'transparent',
              margin: '0px 20',
            }),
          }}
        />

        {errors && <div className="text-[#d32f2f] text-[0.75rem] mt-[4px] border-[#d32f2f]">{errors?.message}</div>}
      </SelectMultipleContext.Provider>
    </div>
  );
}

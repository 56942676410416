import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { DataGrid } from '@mui/x-data-grid';

import projectApi from '../../../../api/task/project';
import getTimeZone from '../../../../utils/Task/getTimeZone';

const generateHTML = (title, content, child) => {
  return (
    <div className="flex space-x-2 w-full items-start">
      <span className="w-44">{title}</span>
      {child || <span className="w-[calc(100%_-_192px)] line-clamp-6">{content}</span>}
    </div>
  );
};

const getMs = (timeValue) => {
  return timeValue.split(':').reduce((acc, cur, i) => {
    let multiplier = 1000;
    const currT = Number(cur) ? Number(cur) : 0;
    if (i === 0) {
      multiplier *= 3600;
      return currT * multiplier + acc;
    }
    if (i === 1) {
      multiplier *= 60;
      return currT * multiplier + acc;
    }
    if (i === 2) {
      multiplier *= 1;
      return currT * multiplier + acc;
    }
    return acc;
  }, 0);
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};

const convertMsToTime = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds %= 60;
  minutes %= 60;

  hours %= 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export default function ModalTaskReport({ activeTask: idTask, onClose }) {
  const [taskReport, setTaskReport] = useState(false);
  const [taskDetail, setTaskDetail] = useState(false);
  const [activeTask, setActiveTask] = useState(false);
  const [isInitialize, setIsInitialize] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleClose = () => {
    onClose();
    setActiveTask(false);
    setTaskDetail(false);
    setTaskReport(false);
  };

  const generateReport = (data) => {
    const rawTotalTime = [];
    const newData = data.map((dt, i) => {
      const isObj = rawTotalTime.findIndex((d) => d?.name === dt.assignee?.name);
      const totTime = dt.total_time?.duration;

      if (isObj === -1) {
        rawTotalTime.push({
          name: dt.assignee?.name,
          time: [totTime],
        });
      } else {
        rawTotalTime[isObj].time.push(totTime);
      }
      return {
        id: i + 1,
        User: dt.assignee,
        Date: format(new Date(dt.time_in), 'dd MMM yyyy'),
        Time: dt.total_time?.duration,
      };
    });
    return { data: newData, time: rawTotalTime };
  };

  const getTaskDetail = useCallback((params) => {
    const { getTopicDetail } = projectApi();
    return new Promise((resolve, reject) => {
      getTopicDetail(params)
        .then((res) => {
          if (res.status === 200 && res.data) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);

  const getTaskReport = useCallback((params) => {
    const { getReportTask } = projectApi();
    return new Promise((resolve, reject) => {
      getReportTask({ ...params, timezone: getTimeZone().name })
        .then((res) => {
          if (res.status === 200 && res.data) {
            resolve(generateReport(res.data));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);

  const getAllData = useCallback(
    async (params) => {
      const [a, b] = await Promise.all([getTaskDetail(params.topic), getTaskReport(params)]);
      if (a && b) {
        setTaskDetail(a);
        setTaskReport(b);
        setActiveTask(params.topic);

        setIsInitialize(true);
      }
    },
    [getTaskDetail, getTaskReport]
  );

  useEffect(() => {
    if (!isInitialize && idTask && !taskReport && activeTask !== idTask) {
      setIsInitialize(true);
      getAllData({ topic: idTask });
    }
  }, [idTask, taskReport, activeTask, isInitialize, getAllData]);

  const getSumTime = (total, num) => {
    return convertMsToTime(getMs(total) + getMs(num));
  };

  const columns = [
    {
      field: 'Date',
      headerName: 'Date',
      headerAlign: 'left',
      minWidth: 132,
    },
    {
      field: 'User',
      headerName: 'Name',
      minWidth: 296,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.User?.name}>
            <span className="truncate">{params.row.User?.name}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'Time',
      headerName: 'Time',
      headerAlign: 'left',
      sortable: false,
      minWidth: 116,
      renderCell: (params) => params.row.Time,
    },
  ];

  let taskExecutors;
  if (taskReport && taskReport.time) {
    const totalTime = [];
    taskExecutors = taskReport.time.map((t, i) => {
      let tTime;
      if (t.time.length) {
        tTime = t.time.reduce(getSumTime, '00:00:00');
        totalTime.push(tTime);
      }
      return (
        <div key={`${i}-${t.name}`} className="w-full h-full flex justify-between items-center">
          <span>{t.name}</span>
          <span>{tTime}</span>
        </div>
      );
    });
    if (totalTime.length) {
      taskExecutors.push(
        <div className="w-full h-full flex justify-between items-center">
          <span className="font-semibold">Total</span>
          <span className="font-semibold">{totalTime.reduce(getSumTime, '00:00:00')}</span>
        </div>
      );
    }
  }

  return (
    taskReport &&
    taskDetail && (
      <Dialog
        open={!!idTask}
        onClose={handleClose}
        sx={{ '& > .MuiDialog-container > .MuiPaper-root': { width: 768, position: 'absolute', top: '5vh', height: '100%', maxHeight: 'calc(100vh - 15vh)', overflowY: 'auto' } }}
      >
        <DialogTitle>Topic {idTask}</DialogTitle>
        <DialogContent dividers>
          <Box className="flex flex-col space-y-2">
            {generateHTML('Title', taskDetail.name)}
            {generateHTML('Status', taskDetail.topic_status?.name || '-')}
            {generateHTML('Project', taskDetail.project?.name)}
            {generateHTML('Deadline', taskDetail.due_at ? format(new Date(taskDetail.due_at), 'dd MMM, yyyy') : '-')}
            {generateHTML('Creation date', taskDetail.created_at ? format(new Date(taskDetail.created_at), 'dd MMM, yyyy') : '-')}
            {generateHTML('Executors / Spent time', '')}
            {generateHTML('', '', <div className="w-[calc(100%_-_192px)]">{taskExecutors}</div>)}
            <Divider />
          </Box>
          <Box className="mt-2 space-y-2">
            <span>Reported Time</span>
            <DataGrid
              autoHeight
              rows={taskReport.data}
              columns={columns}
              pageSize={rowsPerPage}
              onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
              onCellClick={(params) => {
                setActiveTask(params.row.Task);
              }}
              rowsPerPageOptions={[20, 50, 100]}
              className="m-auto max-w-[1200px] w-[100%]"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} size="small" className='bz-btn'>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}

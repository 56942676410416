/* eslint-disable */

import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonBase, Checkbox, CircularProgress, TableCell, TableRow } from '@mui/material';
import CustomTable from '../../components/Table/CustomTable';
import ModalWrapper from '../../components/ui/ModalWrapper';
import useDelete from '../../hooks/useDelete';
import { handleModal } from '../../store/slices/modalSlice';
import usePost from '../../hooks/usePost';
import useGet from '../../hooks/useGet';
import BaseApplyButton from '../../components/Button/BaseApplyButton';
import BaseDeleteButton from '../../components/Button/BaseDeleteButton';
import BaseSearchButton from '../../components/Button/BaseSearchButton';
import RenderResults from '../../components/RenderResults';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import BaseEditButton from '../../components/Button/BaseEditButton';
import BZHelmet from '../../utils/BZHelmet';
import BaseMainTitle from '../../components/BaseMainTitle';
import SectionCategoryForm from '../../components/Form/SettingBz/SectionCategoryForm';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },

  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },

  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

function ActionCell(props) {
  const { onClick, id } = props;
  const dispatch = useDispatch();
  return (
    <div className="flex gap-2 items-center">
      <BaseEditButton onClick={() => dispatch(handleModal({ modalId: id, componentName: 'editCategory' }))} />
      <BaseDeleteButton onClick={onClick} variant="icon" />
    </div>
  );
}

function TableCells(props) {
  const { row, labelId, ariaChecked, selected, handleClick } = props;

  const onDestroySingleWidget = useDelete({
    url: `category_sections/delete_category_sections`,
    confText: `modals.widget_delete.confirmation_text`,
    confTitle: `modals.widget_delete.title`,
    success: 'Category deleted',
    validate: ['categorySections'],
    isSubUrl: false,
  });

  return (
    <TableRow hover role="checkbox" aria-checked={ariaChecked} tabIndex={-1} key={row.id} selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox onClick={(event) => handleClick(event, row.id)} color="primary" checked={ariaChecked} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell>

      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>

      <TableCell align="left">
        <ActionCell id={row.id} onClick={() => onDestroySingleWidget({ bodyData: { category_sections: [row.id] } })} />
      </TableCell>
    </TableRow>
  );
}

export default function SectionCategory() {
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const [dataSection, setDataSection] = useState([]);
  const [searchInput, setSearchInput] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [checkboxSelected, setCheckboxSelected] = useState([]);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const { isFetching } = useGet({
    url: `category_sections/?search=${searchInput}&client=${clientId}&page=${page}&page_size=${rowsPerPage}`,
    validate: 'categorySections',
    onSuccess: (e) => {
      setCount(e?.data?.count);
      setDataSection(e?.data?.results);
    },
    isEnabled: isDataLoad,
  });

  const { data: categoryDetail } = useGet({
    url: `category_sections/${modal?.modalId}`,
    validate: 'categorySection',
    isEnabled: modal.componentName === 'editCategory',
  });

  const onChangeSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const data = useMemo(() => dataSection, [dataSection]);

  function generateFilterComponent() {
    return (
      <div className="flex space-x-2 items-center">
        <BaseSearchButton onChange={onChangeSearch} setPage={setPage} setIsDataLoad={setIsDataLoad} />

        <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} />
      </div>
    );
  }

  const { mutate: postCategory } = usePost({ url: 'category_sections', validate: ['categorySections'] });

  const onPostCategory = async (postData) => {
    await postCategory(postData);
  };

  const { mutate: updateCategory } = usePatchOrPut({ url: `category_sections/${modal.modalId}`, validate: ['categorySections', 'categorySection'] });

  const onDestroyBulkWidget = useDelete({
    url: `category_sections/delete_widgets`,
    confText: `modals.category_delete.confirmation_text`,
    confTitle: `modals.category_delete.title`,
    success: 'Category deleted',
    validate: ['categorySections'],
    bodyData: { widgets: checkboxSelected },
    isSubUrl: false,
  });

  return (
    <>
      <BZHelmet title="Section Category" description="" content="" />
      <div>
        <BaseMainTitle title="Section Category" />
        <div className="p-5">
          <div className="">
            <div className="bg-gray-100 w-full flex py-5 px-3 items-center">
              <div className="ml-auto flex gap-x-3">
                <button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addCategory' }))} className="bz-btn bg-bz-green" type="submit">
                  Add Category
                </button>
                <button className='bz-btn red' disabled={checkboxSelected.length === 0} onClick={() => onDestroyBulkWidget()}>Delete Selected</button>
                <ModalWrapper componentName="addCategory" header="Add Category" maxWidth="sm">
                  <SectionCategoryForm onFormSubmit={onPostCategory} />
                </ModalWrapper>
                <ModalWrapper componentName="editCategory" header="Edit Category" maxWidth="sm">
                  <SectionCategoryForm onFormSubmit={updateCategory} defaultValues={categoryDetail?.data} key={categoryDetail?.data?.id} />
                </ModalWrapper>
              </div>
            </div>
            <div className=" py-4 my-4 border-y-2 ">{generateFilterComponent()}</div>
          </div>
          {/* {!isFetching ? ( */}
          <RenderResults
            condition={count > 0}
            component={
              <CustomTable
                dataApi={data}
                setIsDataLoad={setIsDataLoad}
                headCells={headCells}
                ActionCell={ActionCell}
                isFetching={!isFetching}
                TableCells={TableCells}
                useCheckbox
                page={page - 1}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                checkboxSelected={checkboxSelected}
                count={count}
                setCheckboxSelected={setCheckboxSelected}
              />
            }
          />
          {/* ) : (
            <CircularProgress />
          )} */}
        </div>
      </div>
    </>
  );
}

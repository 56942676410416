/* eslint-disable */

import React, { useMemo, useState } from 'react';
import {
  CardActionArea,
  CardActions,
  styled,
  Icon,
  Typography,
  CardMedia,
  CardContent,
  Card,
  InputBase,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Pagination,
  Button,
} from '@mui/material';
import { InsertPhoto, VideoCameraBack, Search, Share } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { assetDelete, filterDelete, assetPair, assetUnpair } from '../../../store/slices/assetCrudSlice';
import { fetchAllAssets, fetchAssetsType, fetchFilterAssets, fetchAssetsCategory } from '../../../hooks/useAssetsData';

export function renderAssetType(value) {
  let type;
  if (value?.toString() === '1') {
    type = 'Image';
  }
  if (value?.toString() === '2') {
    type = 'External Link';
  }
  if (value?.toString() === '3') {
    type = '360';
  }
  if (value?.toString() === '4') {
    type = 'Video';
  }
  if (value?.toString() === '5') {
    type = 'File';
  }
  return type;
}

const FileIcon = styled(Icon)(() => ({
  fontSize: '126px',
  // height: '126px',
  margin: 'auto',
  color: '#F28C39',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));

export function assetPlaceholder(value) {
  let icon;
  if (value?.toString() === '1') {
    icon = <FileIcon component={InsertPhoto} />;
  }
  if (value?.toString() === '2') {
    icon = <FileIcon component={Share} />;
  }
  if (value?.toString() === '3') {
    icon = '360';
  }
  if (value?.toString() === '4') {
    icon = <FileIcon component={VideoCameraBack} />;
  }
  if (value?.toString() === '5') {
    icon = 'File';
  }
  return icon;
}

export function renderAsset(type, file) {
  const usedType = type?.toString();
  let html;
  if (usedType === '1' || usedType === '3' || ['jpeg', 'png', 'jpg', 'webp', 'gif'].includes(usedType?.toLowerCase())) {
    html = <CardMedia component="img" className="h-[196px] object-cover w-full" image={file} alt="Image" />;
  }
  if (usedType === '2' || usedType === '4') {
    html = (
      <video
        className=" h-[196px] object-cover w-full "
        style={{
          maxWidth: '100%',
          width: '120%',
          height: '',
          margin: '',
        }}
        playsInline
        loop
        muted
        controls
        alt="All the devices"
        src={file}
      />
    );
  }
  if (usedType === '5') {
    html = (
      <div className="w-full h-[196px] flex items-center justify-center">
        <p className="text-2xl">{file?.substring(file?.lastIndexOf('.') + 1)}</p>
      </div>
    );
  }
  return html;
}

export default function AssetCardSingle({ assetsSelectData, assetComponent, onClick, onChangeAsset, cardOnly, typeMode }) {
  const dispatch = useDispatch();
  const [assetsData, setAssetsData] = useState([]);
  const [assetCardData, setAssetCardData] = useState([]);
  const [search, setSearch] = useState('');
  const [assetType, setAssetType] = useState(typeMode || '');
  const [assetCategories, setAssetCategories] = useState(['']);
  const [assetCategoriesData, setAssetCategoriesData] = useState([]);
  const [countAsset, setCountAsset] = useState(0);
  const [page, setPage] = useState(1);

  const clientId = useSelector((state) => state.client.activeClient);

  const handleChangePage = (e, p) => {
    setPage(p);
  };

  const onSearchAsset = (event) => {
    setSearch(event.target.value);
  };

  const onChangeAssetType = (event) => {
    const internal = event.target.value;

    setAssetType(internal);
  };

  const pageSize = 15;
  const count = Math.ceil(countAsset / pageSize);

  useQuery(['assets', { clientId, pageSize, page, assetType, search, assetCategories }], fetchFilterAssets, {
    onSuccess: (e) => {
      setAssetsData(e?.data?.results);
      setCountAsset(e.data.count);
    },
  });

  useQuery(['AssetsAll', { clientId }], fetchAllAssets, {
    onSuccess: (e) => {
      setAssetCardData(e?.data?.results);
    },
  });

  useQuery(['AssetsCategories', { clientId }], fetchAssetsCategory, {
    onSuccess: (e) => {
      setAssetCategoriesData(e?.data?.results);
    },
  });

  const { data: assetTypes } = useQuery(['assetsType'], fetchAssetsType);
  const optionsAssetType = assetTypes?.data?.results?.map((item) => ({
    name: item?.name,
    value: item?.id,
  }));

  const optionsAssetCategories = useMemo(() => {
    return assetCategoriesData?.map((item) => ({
      name: item?.name,
      value: item?.id,
    }));
  }, [assetCategoriesData]);

  const switcherAssets = cardOnly
    ? assetCardData.filter(function (apiData) {
        return assetsSelectData.some(function (pairData) {
          return apiData.id.toString() === pairData.toString(); // id is unnique both array object
        });
      })
    : assetsData;

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(assetDelete(value));
    } else {
      dispatch(filterDelete(value));
    }
  };

  const handlePair = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(assetPair(value));
    } else {
      dispatch(assetUnpair(value));
    }
  };
  // console.log('assetCategories', assetCategories);
  // console.log('optionsAssetCategories', optionsAssetCategories);

  function renderCard() {
    let cardHtml;
    if (assetComponent === 'assetPair') {
      cardHtml = switcherAssets?.map((asset) => (
        <div key={asset?.id} className="w-full">
          <Card className="w-full relative">
            <input type="checkbox" name="lang" value={asset.id} data-obj={JSON.stringify(asset)} onChange={onChangeAsset} className="absolute top-3 left-3 w-5 h-5" />
            <CardActions className="ml-8 gap-x-3 flex items-center">
              <Typography gutterBottom className="" component="div">
                {renderAssetType(asset?.asset_type)}
              </Typography>
            </CardActions>
            <CardActionArea>
              {asset?.asset_file ? <div className="">{renderAsset(asset?.asset_type, asset?.asset_file)}</div> : <div className="">{assetPlaceholder(asset?.asset_type)}</div>}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {asset?.description || 'Description'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  ID : {asset?.id}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      ));
    }
    if (assetComponent === 'assetPairEdit') {
      cardHtml = switcherAssets?.map((asset) => (
        <div key={asset?.id} className="w-2/12">
          <Card className="w-full relative">
            <input type="checkbox" name="lang" value={asset.id} data-obj={JSON.stringify(asset)} onChange={onChangeAsset} className="absolute top-3 left-3 w-5 h-5" />
            <CardActions className="ml-8 gap-x-3 flex items-center">
              <Typography gutterBottom className="" component="div">
                {renderAssetType(asset?.asset_type)}
              </Typography>
            </CardActions>
            <CardActionArea>
              {asset?.asset_file ? <div className="">{renderAsset(asset?.asset_type, asset?.asset_file)}</div> : <div className="">{assetPlaceholder(asset?.asset_type)}</div>}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {asset?.description || 'Description'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  ID : {asset?.id}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      ));
    }
    if (!assetComponent) {
      cardHtml = (
        <div className="flex flex-wrap gap-3 w-full">
          {switcherAssets?.map((asset) => (
            <div key={asset?.id} className="w-2/12">
              <Card className="w-full relative">
                <input type="checkbox" name="lang" value={asset.id} data-obj={JSON.stringify(asset)} onChange={onChangeAsset} className="absolute top-3 left-3 w-5 h-5" />
                {/* <Link to={`/asset/detail/${asset?.id}`}> */}
                <CardActions className="ml-8 gap-x-3 flex items-center">
                  <Typography gutterBottom className="" component="div">
                    {renderAssetType(asset?.asset_type)}
                  </Typography>
                </CardActions>
                <CardActionArea>
                  {asset?.asset_file ? <div className="">{renderAsset(asset?.asset_type, asset?.asset_file)}</div> : <div className="">{assetPlaceholder(asset?.asset_type)}</div>}
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {asset?.description || 'Description'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      ID : {asset?.id}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                {/* </Link> */}
              </Card>
            </div>
          ))}
        </div>
      );
    }
    return cardHtml;
  }

  return (
    <div>
      {cardOnly ? (
        <div className="grid grid-cols-6 gap-3">{renderCard()}</div>
      ) : (
        <div>
          <div className="flex gap-x-3 items-center w-6/12 mb-4">
            <div className="flex flex-col">
              <FormLabel>Search</FormLabel>
              <div className="min-w-[144px] flex gap-x-3 bg-white ">
                <InputBase size="small" placeholder="search" endAdornment={<Search color="disabled" />} onChange={onSearchAsset} className="border border-gray-300 rounded px-4" />
              </div>
            </div>
            <div className="min-w-[250px] w-3/12 bg-white  h-full">
              <FormControl>
                <FormLabel>Asset Type</FormLabel>
                <Select sx={{ minWidth: 250, width: '100%', px: 0.2, py: 0.2 }} value={assetType} onChange={(event) => onChangeAssetType(event)} displayEmpty placeholder="All">
                  <MenuItem value="">-</MenuItem>
                  {optionsAssetType &&
                    optionsAssetType.length > 0 &&
                    optionsAssetType?.map((op) => (
                      <MenuItem key={op.name} value={op.value}>
                        {op.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="min-w-[250px] w-3/12 bg-white  h-full">
              <FormControl>
                <FormLabel>Asset Category</FormLabel>
                <Select
                  sx={{ minWidth: 250, width: '100%', px: 0.2, py: 0.2 }}
                  value={assetCategories}
                  multiple
                  onChange={(event) => setAssetCategories(event.target.value)}
                  displayEmpty
                >
                  {optionsAssetCategories &&
                    optionsAssetCategories.length > 0 &&
                    optionsAssetCategories?.map((op) => (
                      <MenuItem key={op.name} value={op.value}>
                        {op.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {switcherAssets ? renderCard() : 'loading'}
          <div className="my-5 flex">
            <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChangePage} />

            <Button onClick={onClick} className="ml-auto flex" type="button" variant="outlined">
              Pair Assets
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  // renderCard();
}

/* eslint-disable */

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { DevTool } from '@hookform/devtools';

import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';
import getDefaultValues from '../../../helper/getDefaultValues';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
import useGet from '../../../hooks/useGet';
import ReactSelectSingle from '../../Input/ReactSelectSingle';
import FileContatiner from '../../Input/FileContatiner';

function ApplicationForm({ defaultValues, onFormSubmit }) {
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);
  const activeSite = useSelector((state) => state.content.activeSite);

  function getApplicantOption(data) {
    return data?.map((applicant) => {
      return {
        id: applicant,
        name: applicant?.user.name,
      };
    });
  }

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: defaultValues?.name,
    },
  });

  const onSubmit = (data) => {
    console.log('🚀 ~ file: ApplicationForm.jsx:39 ~ onSubmit ~ data', data);
    const formData = new FormData();
    formData.append('applicant', data.applicant.id);
    formData.append('job_poster', data.job_poster);
    formData.append('attachments', data.attachments);
    if (data.attachments && data.attachments.length > 0) {
      data?.attachments?.forEach((file, index) => formData.append(`attachments[${index}]file`, file));
    }

    onFormSubmit(formData);
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  const { data: listApplicants } = useGet({
    url: `applicants`,
    queryString: {
      user__site: activeSite,
      max_size: true,
      //  search: searchInput,
      //  page,
      //  page_size: rowsPerPage,
    },
    validate: 'applicants',
  });

  const { data: listJobs } = useGet({
    url: `job_posters/?language=${1}&article__client=${clientId}&=max_size=true`,
    validate: 'JobPostersApplication',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <ReactSelectSingle
          name="applicant"
          control={control}
          options={getOptionSelectWithKey(getApplicantOption(listApplicants?.data?.results))}
          // defaultValue={getDefaultSelectObject(pageId, getOptionSelectWithKey(listPage?.data?.results))}
          placeholder="Select Applicant"
          isClearable
        />
        <ReactSelectSingle
          name="job_poster"
          control={control}
          options={getOptionSelectWithKey(listJobs?.data?.results)}
          // defaultValue={getDefaultSelectObject(pageId, getOptionSelectWithKey(listPage?.data?.results))}
          placeholder="Select Job"
          isClearable
        />
        <FileContatiner name="attachments" label="Attachments" errors="" control={control} multiple />
        <div className="flex justify-end ml-auto gap-x-2">
          {/* <Button onClick={handleCancel} className="flex" variant="outlined">
            Cancel
          </Button> */}
          <Button className=" flex" type="submit" variant="outlined">
            {defaultValues ? 'Update Application' : 'Create Application'}
          </Button>
        </div>
      </div>
      <DevTool control={control} />
    </form>
  );
}

export default ApplicationForm;

import { ContentCopy } from '@mui/icons-material';
import { Box, Button, Fade, Popover } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';

import BoardsContext from '../../../../../pages/Task/Boards/BoardsContext';

import DuplicateTask from '../../MoreActions/DuplicateTask';
import projectApi from '../../../../../api/task/project';

export default function MoreDrawerActions({ idTask, idMoreActions, openMoreActions, anchorMoreActions, onClose }) {
  const myContext = useContext(BoardsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isDuplicateTask, setIsDuplicateTask] = useState(false);
  const handleDuplicateTask = () => {
    setIsDuplicateTask(!isDuplicateTask);
    onClose();
  };

  const duplicateTask = (data) => {
    const { duplicateTopic } = projectApi();

    duplicateTopic(idTask, data)
      .then((res) => {
        if (res.data) {
          myContext.getProject();
          enqueueSnackbar('Topic duplicated' || 'Success', { variant: 'success' });
          handleDuplicateTask();
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <>
      <Popover
        id={idMoreActions}
        open={openMoreActions}
        anchorEl={anchorMoreActions}
        onClose={() => onClose(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="mt-2"
      >
        <Fade in={openMoreActions}>
          <Box style={{ minWidth: '180px', color: '#fff' }} className="relative flex flex-col py-2 space-y-2 rounded-lg bg-white">
            <Button className="px-4 flex justify-start text-black" startIcon={<ContentCopy />} onClick={handleDuplicateTask}>
              Duplicate task
            </Button>
          </Box>
        </Fade>
      </Popover>
      <DuplicateTask open={isDuplicateTask} close={handleDuplicateTask} duplicateTask={duplicateTask} />
    </>
  );
}

/* eslint-disable */
import CustomCheckboxNew from '@/components/Input/CustomCheckboxNew';
import InputContainer from '@/components/Input/InputContainer';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import projectApi from '../../../api/task/project';
import SelectAutoComplete from '../../../pages/CRM/redesigning/SelectAutocompleteRedesign';
import { handleModal } from '../../../store/slices/modalSlice';
import getTimeZone from '../../../utils/Task/getTimeZone';
momentDurationFormatSetup(moment);

const schema = yup.object().shape({
  time_in: yup.date().required('please fill in the time_in'),
  time_out: yup.date().required('please fill in the time_in').min(yup.ref('time_in')),
});

function TimeFormat({ totalMinutes }) {
  const duration = moment.duration(totalMinutes, 'minutes');

  // Format the duration into "X days, HH:mm:ss"
  // Note: Moment's duration format method requires moment-duration-format plugin
  const formattedDuration = duration.format('HH:mm:ss');

  const splitTime = formattedDuration.split(':');
  console.log('🚀 ~ TimeFormat ~ splitTime:', splitTime.length);
  const formatDay = splitTime.length === 4 ? splitTime[0] : 0;
  const formatHour = splitTime.length === 3 ? splitTime[0] : splitTime.length === 4 ? splitTime[1] : 0;
  const formatMinute = splitTime.length === 3 ? splitTime[1] : splitTime.length === 4 ? splitTime[2] : splitTime[0];

  return (
    <div className="grid grid-cols-2 gap-x-2 w-7/12">
      <div className="flex border w-full h-full text-center px-2 ">
        <span className="mr-1">Hour : </span>
        <p>{formatHour}</p>
      </div>
      <div className="flex border w-full h-full text-center px-2 ">
        <span className="mr-1">Minute : </span>
        <p>{formatMinute}</p>
      </div>
    </div>
  );
}

function ManualLogTime({ isOwner, userList, topic, logWorkingHours, myContextDrawer, activeTask, action, reload }) {
  const checkData = useSelector((state) => state.modal?.modalData);
  console.log('🚀 ~ ManualLogTime ~ checkData:', checkData);
  const user = userList.find((dt) => dt.name === checkData?.assignee.name);

  const start = moment(checkData?.time_in);
  const end = moment(checkData?.time_out);
  const duration = moment.duration(end.diff(start));

  // Constructing the final output

  const defaultValues = {
    time_in: checkData?.time_in || null,
    time_out: checkData?.time_out || null,
    billable_time: checkData?.billable_time || null,
    total_time: Math.ceil(duration.asMinutes()),
    is_billed: checkData?.is_billed,
    is_lock: checkData?.is_lock,
  };
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [FromValue, setFromValue] = useState(checkData?.time_in || null);
  const [member, setMember] = useState('');
  const [ToValue, setToValue] = useState(checkData?.time_out || null);

  // useEffect(() => {
  //   console.log('member ', member);
  // }, [member])

  const onSubmit = (data) => {
    const { logWorkHours } = projectApi();

    try {
      const newData = {
        // assignee: selectedMember[0]?.id || null,
        time_in: data?.time_in.toISOString(),
        time_out: data?.time_out.toISOString(),
        billable_time: data?.billable_time,
        is_billed: data?.is_billed,
        is_lock: data?.is_lock,
        topic: topic,
      };

      const selectedMember = userList?.filter((v) => v.name === member);

      if (selectedMember.length > 0) {
        newData.assignee = selectedMember[0]?.id;
      }

      const currTimezone = getTimeZone();
      newData.timezone = {
        name: currTimezone.name,
        utc_offset: currTimezone.offset,
      };

      if (action === 'create') {
        logWorkHours(newData, false, {}, 'post')
          .then((res) => {
            if (res.status === 201 || res.data) {
              dispatch(handleModal({ modalId: '', componentName: '' }));
              return reload(true);
            }
          })
          .catch((err) => console.error(err));
      } else {
        logWorkHours(newData, checkData?.id, {}, 'patch')
          .then((res) => {
            if (res.status === 200 || res.data) {
              dispatch(handleModal({ modalId: '', componentName: '' }));
              return reload(true);
            }
          })
          .catch((err) => console.error(err));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-2">
        {isOwner && (
          <div className="w-full">
            <InputLabel>Team Member</InputLabel>
            {action === 'edit' ? (
              <SelectAutoComplete
                variant="outlined"
                label="Team Member"
                defaultValue={userList.find((dt) => dt.name === checkData?.assignee.name)}
                onChange={(e, val) => setMember(val.name)}
                options={userList}
              />
            ) : (
              <SelectAutoComplete
                variant="outlined"
                label="Team Member"
                // defaultValue={userList.find((dt) => dt.name === checkData?.assignee.name)}
                onChange={(e, val) => setMember(val.name)}
                options={userList}
              />
            )}
          </div>
        )}
        <div className="w-full">
          <InputLabel>From</InputLabel>

          <Controller
            control={control}
            name={`time_in`}
            defaultValue={moment(defaultValues?.time_in || new Date()).format('YYYY-MM-DDTHH:mm')}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                variant="outlined"
                size="small"
                id="time_in"
                type="datetime-local"
                value={moment(value).format('YYYY-MM-DDTHH:mm')}
                onChange={(date) => {
                  onChange(date);
                  const start = moment(date.target.value);
                  const end = moment(defaultValues?.time_out);
                  const duration = moment.duration(end.diff(start));

                  // Constructing the final output
                  setValue(`total_time`, Math.ceil(duration.asMinutes()));
                }}
                sx={{ width: '100%', '& > .MuiInputBase-root': { fontSize: '14px' } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
          {errors &&
            (Array.isArray(errors['time_in']?.message) ? (
              errors['time_in']?.message.map((m) => <span className="text-red-600">{m.toLowerCase()}</span>)
            ) : (
              <span className="text-red-600">{errors['time_in']?.message.replace('time_in', 'time in'.toLowerCase())}</span>
            ))}
        </div>
        <div className="w-full">
          <InputLabel>To</InputLabel>

          <Controller
            control={control}
            name={`time_out`}
            defaultValue={moment(defaultValues?.time_out || new Date()).format('YYYY-MM-DDTHH:mm')}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                variant="outlined"
                size="small"
                id="time_out"
                type="datetime-local"
                value={moment(value).format('YYYY-MM-DDTHH:mm')}
                onChange={(date) => {
                  onChange(date);
                  const start = moment(watch('time_in'));
                  let newValue = date.target.value;
                  // Ensure newValue is not before minDateTime
                  if (moment(newValue).isBefore(moment(start))) {
                    newValue = start;
                  }
                  const end = moment(newValue);
                  const duration = moment.duration(end.diff(start));
                  // Constructing the final output
                  setValue(`total_time`, Math.ceil(duration.asMinutes()));
                }}
                sx={{ width: '100%', '& > .MuiInputBase-root': { fontSize: '14px' } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
          {errors &&
            (Array.isArray(errors['time_out']?.message) ? (
              errors['time_out']?.message.map((m) => <span className="text-red-600">{m.toLowerCase()}</span>)
            ) : (
              <span className="text-red-600">{errors['time_out']?.message.replace('time_out', 'time out'.toLowerCase())}</span>
            ))}
        </div>
        <div className="flex items-center space-x-4">
          <div className="w-5/12">
            <InputContainer readOnly disabled name="total_time" control={control} label="Total Time" />
          </div>
          <TimeFormat totalMinutes={watch('total_time')} />
        </div>
        <div className="flex items-center space-x-4">
          <div className="w-5/12">
            <InputContainer name="billable_time" control={control} label="Billable Time" />
          </div>
          <TimeFormat totalMinutes={watch('billable_time')} />
        </div>
        <div className="grid grid-cols-2">
          <div className="">
            <CustomCheckboxNew
              helperText={`When checked, ...`}
              name="is_billed"
              control={control}
              label="Is Billed"
              defaultChecked={defaultValues?.is_billed}
              // disabled={isDisableStatus}
            />
          </div>
          <div className="">
            <CustomCheckboxNew helperText={`When checked, ...`} name="is_lock" control={control} label="Is Lock" defaultChecked={defaultValues?.is_lock} />
          </div>
        </div>
        <div className="w-full flex justify-end">
          <button type="submit" className="h-fit flex items-center justify-center text-white p-[8px] bg-[#2C6D47] rounded-xl">
            {action === 'create' ? 'Add' : 'Edit'}
          </button>
        </div>
      </div>
    </form>
  );
}

export default ManualLogTime;

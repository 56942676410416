import React from 'react';

import ContentHeader from './ContentHeader';
import ContentBody from './ContentBody';

import styles from './Content.module.css';

function Content(props) {
  const { children } = props;
  return <div className={styles.contentWrapper}>{children}</div>;
}

export { Content, ContentHeader, ContentBody };
export default Content;

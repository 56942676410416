/* eslint-disable */
import { Delete, Edit } from '@mui/icons-material';
import React from 'react';
import { useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { handleModal } from '../../store/slices/modalSlice';

const Option = (props) => (
  <div>
    <components.Option {...props} className="flex ">
      <input className="invisible" type="checkbox" checked={props.isSelected} onChange={() => null} />
      <label>{props.label}</label>
    </components.Option>
  </div>
);

const { ValueContainer, Placeholder } = components;

export default function ReactSelectMultiple({
  options = [],
  defaultValue = null,
  onChange,
  isMulti = false,
  objectModal,
  getOptionLabel,
  getOptionValue,
  placeholder,
  isSearchable,
  customDelete,
  actionDelete,
  componentEditName,
  errors,
  isDisabled = false,
  floatLabel = true,
  closeMenuOnSelect,
}) {
  const dispatch = useDispatch();

  const CustomValueContainer = ({ children, ...props }) => (
    <ValueContainer {...props}>
      <Placeholder className={`${errors && 'text-[#d32f2f] '}`} {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );

  const CustomOption = (props) => {
    return (
      <div>
        <components.Option {...props} className="flex ">
          <input className="invisible" type="checkbox" checked={props.isSelected} onChange={() => null} />
          <label>{props.label}</label>
          <div className="flex space-x-2 ml-auto">
            <div onClick={() => dispatch(handleModal({ modalId: props.value, componentName: componentEditName }))} className="">
              <Edit />
            </div>
            <div onClick={() => actionDelete(props.value)} className="">
              <Delete />
            </div>
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <div className=" w-full">
      <Select
        className={`${errors && 'border-[1px] border-[#d32f2f] rounded-[4px]'}`}
        menuPosition="fixed"
        menuPlacement="auto"
        closeMenuOnSelect={closeMenuOnSelect || !isMulti}
        isDisabled={isDisabled}
        hideSelectedOptions={false}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={onChange}
        defaultValue={defaultValue}
        components={{ Option: customDelete ? CustomOption : Option, ValueContainer: CustomValueContainer }}
        isSearchable={isSearchable}
        errors={Boolean(errors)}
        isMulti={isMulti}
        floatLabel={floatLabel}
        isDisable={false}
        placeholder={placeholder}
        renderValue={(selected) => selected.join(' ')}
        styles={{
          container: (provided) => ({
            ...provided,
            // marginTop: 50,
            // border: '1px, solid, red',
          }),
          valueContainer: (provided) => ({
            ...provided,
            overflow: 'visible',
            // border: '1px, solid, red',
            padding: "8.5px 14px"
          }),
          placeholder: (provided, state) => ({
            ...provided,
            // border: '1px, solid, red',
            position: 'absolute',
            top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
            background: floatLabel ? (state.hasValue ? 'white' : 'transparent') : !state.hasValue && !state.selectProps.inputValue ? 'white' : 'transparent',
            margin: '0px 20',
            paddingRight: '8px',
            color: floatLabel ? 'black' : !state.hasValue && !state.selectProps.inputValue ? 'black' : 'transparent',
          }),
        }}
      />
      {errors && <div className="text-[#d32f2f] text-[0.75rem] mt-[4px] border-[#d32f2f]">{errors?.message}</div>}
    </div>
  );
}

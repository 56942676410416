/* eslint-disable no-unsafe-optional-chaining */
// /* eslint-disable no-unused-vars */
/* eslint-disable */

import * as React from 'react';
import PropTypes from 'prop-types';
import { InputBase, Box, Button, CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { visuallyHidden } from '@mui/utils';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { DeleteForever, Search } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import swal from 'sweetalert';
import { handleModal, handleWatch } from '../../../store/slices/modalSlice';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import BaseBackButton from '../../../components/Button/BaseBackButton';
import { fetchEmployeeDetail, useAddEmployee, useUpdateEmployee } from '../../../hooks/useWhistleblowerData';
import TeamForm from '../../../components/Form/Whistleblower/TeamForm';
import BZButton from '@/components/Button/BZButton';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'employment_id',
    numeric: false,
    disablePadding: true,
    label: 'Employment ID',
  },
  {
    id: 'first_name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'current_employment.business_unit.id',
    numeric: false,
    disablePadding: true,
    label: 'Unit',
  },
  {
    id: 'current_employment.department.id',
    numeric: false,
    disablePadding: true,
    label: 'Departement',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Action',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function EnhancedTable({ dataApi, isDataLoad }) {
  const dispatch = useDispatch();
  // console.log(modal);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    if (isDataLoad) {
      setTableData(dataApi);
    }
  }, [dataApi, isDataLoad]);
  const employeeId = useSelector((state) => state.modal.watchId);
  const { data: EmployeeDetail } = useQuery(['Employee', { employeeId }], fetchEmployeeDetail);

  const { mutate: addEmployee } = useAddEmployee();

  const { mutate: updateEmployee } = useUpdateEmployee(employeeId, EmployeeDetail?.data);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty tableData?.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData?.length) : 0;

  // const { mutate: updateEmployee } = useUpdateEmployee(employeeId, EmployeeDetail?.data);

  function Switch(props) {
    const { id } = props;

    const [toggle, setToggle] = React.useState(true);
    const toggleClass = ' transform -translate-x-3';
    const useDestroyEmployee = () => {
      // console.log('red', deleteRes);

      return async () => {
        setToggle(false);
        dispatch(handleWatch({ watchId: id }));
        await swal({
          title: 'Are you sure?',
          text: 'Remove from whistleblower team',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            updateEmployee({ is_whistleblower_committee: false });
          } else {
            swal('Canceled');
          }
        });
        dispatch(handleWatch({ watchId: '' }));
      };
    };

    return (
      <div className="flex flex-col justify-center h-fit items-center w-fit ">
        {/*   Switch Container */}

        <button type="button" className="" onClick={useDestroyEmployee(id)}>
          {/* Switch */}
          <DeleteForever />
        </button>
      </div>
    );
  }

  const onSearchEmployee = (event) => {
    setPage(0);
    const val = event.target.value.toLowerCase();
    if (val) {
      const employee = dataApi.filter((t) => {
        if (t.first_name.toLowerCase().includes(val) || t.last_name.toLowerCase().includes(val) || t.id.toString().includes(val)) {
          return t;
        }
        return false;
      });
      if (employee.length) {
        setTableData(employee.slice(0, rowsPerPage));
      } else {
        setTableData([]);
      }
    } else {
      setTableData(dataApi);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <div className="">
        <div className="bg-gray-100 w-full py-5 px-3  rounded items-center">
          <div className="flex">
            <div className="my-3 flex gap-x-3  w-fit">
              <BaseBackButton />
              <InputBase
                size="small"
                placeholder="search"
                endAdornment={<Search color="disabled" />}
                onChange={onSearchEmployee}
                className="border bg-white border-gray-300 rounded px-4"
              />
            </div>

            <div className="ml-auto flex gap-x-3">
              <BZButton variant="secondary" onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addWhistleTeam' }))}>
                Add Member
              </BZButton>
            </div>
          </div>
        </div>
      </div>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableData?.length}
            />
            {tableData?.length && tableData?.length > 0 ? (
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 tableData?.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(tableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover tabIndex={-1} key={row.first_name + row.id}>
                        <TableCell component="th" id={labelId} scope="row" padding="normal">
                          {row?.employment_id}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="normal">
                          {row?.first_name} {row?.last_name}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="normal">
                          {row?.email}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="normal">
                          {row?.current_employment?.business_unit?.name}
                        </TableCell>

                        <TableCell align="left">{row?.current_employment?.department?.name}</TableCell>
                        <TableCell align="left">
                          <Switch id={row?.id} />
                        </TableCell>
                        {/* <TableCell align="left">
                        <ActionCell id={row.id} onClick={() => removeEmployee(row.id)} />
                      </TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody className="relative h-[10vh]">
                {!isDataLoad ? (
                  <CircularProgress />
                ) : (
                  <tr className=" absolute inset-0  flex items-center justify-center text-lg font-bold">
                    <td>No Results</td>
                  </tr>
                )}{' '}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 20, 25]}
          component="div"
          count={tableData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <ModalWrapper componentName="addWhistleTeam" header="Add Member" maxWidth="sm">
        <TeamForm onFormSubmit={addEmployee} defaultValues={EmployeeDetail?.data} key={EmployeeDetail?.data?.id} />
      </ModalWrapper>
    </Box>
  );
}

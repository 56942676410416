import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { Avatar, InputAdornment, InputBase, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, CircularProgress, Select, OutlinedInput, MenuItem, Checkbox } from '@mui/material';
import { GridView, Search, ViewList } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { useParams } from 'react-router';
import { handleModal } from '@/store/slices/modalSlice';
import BzThumbnail from '@/assets/simple.png'
import POSApi from '@/api/pos/posApi';
import Input from '@/pages/CRM/redesigning/InputContainerRedesign';
import InputContainer from '@/components/Input/InputContainer';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchData } from '@/utils/fetchDataFunc';

const validationSchema = yup.object().shape({
  student_id: yup.string().nullable(),
  name: yup.string().nullable(),
  attribute_class: yup.string().nullable(),
  password: yup.string().nullable(),
  confirm_password: yup.string()
    .when('password', {
      is: (password) => password,
      then: (s) => s
        .required('Fill the confirm password')
        .oneOf([yup.ref('password')], 'Passwords must match'),
      otherwise: (s) => s.nullable(),
    }),
});

function UpdateUser({setIsFetchingCustomers, getAllCustomers}) {
  const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const clientId = useSelector((state) => state.client.activeClient);
  const modalData = useSelector((state) => state.modal.modalData);
  const customer = modalData ? modalData.customer : null;
  const { outletId } = useParams();
  const dispatch = useDispatch();
  
  const [showPass, setShowPass] = useState(false);
  const [showCfPass, setShowCfPass] = useState(false);
  const [data, setData] = useState(null);
  const [isFetchingDetail, setIsFetchingDetail] = useState(true)
  
  const customerData = useCallback((params) => fetchData(POSApi().getCustomerDetail, params), [customer]);
  const getCustomerData = useCallback(
    async (params) => {
      const [customer] = await Promise.all([customerData(params)]);
      if (customer) {
        setData(customer);

        setValue("student_id", customer?.member?.on_list_attributes?.student_id || "");
        setValue("name", customer?.member?.name || "");
        setValue("attribute_class", customer?.member?.on_list_attributes?.class || "");
        setValue("user", customer?.user || null);
      }
      setIsFetchingDetail(false);
    },
    [customerData]
  );

  useEffect(() => {
    if(customer) {
      setIsFetchingDetail(true);
      getCustomerData(customer?.id);
    }
  }, [getCustomerData, customer]);

  const InputForm = ({ defaultValue, label, name, type = 'text', disabled, onChangeValue, setState, control, className }) => (
    <div className={`w-full h-fit ${className}`}>
      <p className={`font-semibold `}>{label}</p>
      <Input control={control} defaultValue={defaultValue} label={label} name={name} disabled={disabled} type={type} onChangeValue={onChangeValue} />
    </div>
  );

  const SubmitUpdateUser = async (data) => {
    const { updateCustomerDetail } = POSApi();
    const { user, ...filteredData } = data;
  
    try {
      const response = await updateCustomerDetail(customer?.id, filteredData);

      if (response.status >= 200 && response.status < 300) {
        Swal.fire({
          icon: 'success',
          title: 'Customer data updated successfully!',
        });
        
        const newParams = {
          outlet: outletId,
          max_size: true,
          ordering: 'member__name',
        };
        
        dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
        setIsFetchingCustomers(true);
        await getAllCustomers(newParams);
  
  
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Failed to update customer data. Status: ${response.status}`,
        });
      }
  
    } catch (error) {
      
      const errors = error.response?.data;
  
      if (errors) {
        const errorMessages = Object.entries(errors).map(([key, value]) => {
          return `${key}: <br>- ${value.join('<br>- ')}`;
        }).join('<br><br>');
  
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: errorMessages,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong.',
        });
      }
    }
  }

  return !isFetchingDetail ? (
    <form onSubmit={handleSubmit(SubmitUpdateUser)} className="w-full relative space-y-5" style={{ maxHeight: "1200px", overflowY: "hidden" }}>
      <div className='w-full flex gap-x-4'>
        <InputForm className="col-span-2" defaultValue="" label="Student ID" name="student_id" control={control} />
        <InputForm className="col-span-2" defaultValue="" label="Customer Name" name="name" control={control} />
        <InputForm className="col-span-2" defaultValue="" label="Class" name="attribute_class" control={control} />
      </div>
      <div className="w-full space-y-5">
        {
          data?.user !== null && (
            <>
              <div className='flex justify-center font-semibold'>
                <p>Update Password</p>
              </div>
              <div className="w-full">
                <div className="flex justify-center gap-x-2">
                  <div className='w-[18%] flex items-center font-semibold'>
                    <p>New Password</p>
                  </div>
                  <div className='w-[30%]'>
                    <InputContainer
                      showPass={showPass}
                      handleClickShowPassword={() => setShowPass(!showPass)}
                      type="password"
                      name="password"
                      control={control}
                      // label="Password"
                      errors={errors?.password}
                    />
                  </div>
                </div>
                <div className="flex justify-center gap-x-2">
                  <div className='w-[18%] flex items-center font-semibold'>
                    <p>Confirm Password</p>
                  </div>
                  <div className='w-[30%]'>
                    <InputContainer
                      handleClickShowPassword={() => setShowCfPass(!showCfPass)}
                      type="password"
                      showCfPass={showCfPass}
                      name="confirm_password"
                      control={control}
                      // label="Confirmation Password"
                      errors={errors?.confirm_password}
                    />
                  </div>
                </div>
              </div>
            </>
          )
        }
        <div className="w-full flex justify-end">
        <button
          className="btn bg-[#2C6D47] p-2 text-white rounded-lg border cursor-pointer"
          name="collectionPair"
          type="submit"
        >
          Update Customer Data
        </button>
        </div>
      </div>
    </form>
  ): (
    <CircularProgress />
  )
}

export default UpdateUser
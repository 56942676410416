/* eslint-disable */


import request from '../helper/axiosUtil';

export const fetchTopics = async ({ queryKey }) => {
  const [, { userId }] = queryKey;
  return request({
    url: `/project-management/topics/?project=&creator=&assignee=${userId
      }&type=&section=&max_size=true`
  });
};

export const fetchTopicDetail = async ({ queryKey }) => {
  const [, { topicId }] = queryKey;
  return request({ url: `/project-management/topics/${topicId}/` });
};

export const createTopic = async (data) => request({
  url: '/project-management/topics/',
  method: 'post',
  data,
  headers: { 'Content-Type': 'application/json' },
});

export const createSection = async (data) => request({
  url: '/project-management/sections/',
  method: 'post',
  data,
  headers: { 'Content-Type': 'application/json' },
});


export const createComment = async (data) => request({
  url: '/project-management/comments/',
  method: 'post',
  data,
  headers: { 'Content-Type': 'application/json' },
});

export const updateTopics = async (topicId, data) => request({
  url: `/project-management/topics/${topicId}/`,
  method: 'patch',
  data,
  headers: { 'Content-Type': 'application/json' },
});

export const deleteTeams = async (id) => request({
  url: `/project-management/teams/${id}/`,
  method: 'delete',
});

export const deleteTopics = async (id) => request({
  url: `/project-management/topics/${id}/`,
  method: 'delete',
});

export const fetchTopicAttachments = async ({ queryKey }) => {
  const [, { params }] = queryKey;

  let url = `/project-management/attachments/?`
  Object.entries(params).forEach(([key, value]) => {
    url += `${key}=${value}&`;
  })

  return request({ url });
};

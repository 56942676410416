import React from 'react';

import Tabs from '../../components/ui/Settings/SitePerformances/Tabs';
import BZHelmet from '../../utils/BZHelmet';

export default function SitePerformances() {
  return (
    <div>
      <BZHelmet title="Site Performance" description="bz publish site performance" />
      <Tabs className="bz-tabs" />
    </div>
  );
}

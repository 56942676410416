import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Input, TextField } from '@mui/material';
import { Image } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';

import DetailContext from '../../../../pages/Task/Projects/DetailContext';

export default function TopicTypes({ open, data, onClose, onCreateTopicType, onUpdateTopicType }) {
  const { projectId: project } = useContext(DetailContext);

  const [name, setName] = useState('');
  const [color, setColor] = useState('#000000');
  const [filesIcon, setFilesIcon] = useState(false);
  const { getRootProps: getTTProps, getInputProps: getTTIProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFilesIcon(() => Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) }));
    },
  });

  const handleClose = () => {
    onClose();
    setName('');
    setFilesIcon(false);
    setColor('#000000');
  };

  const actionOnTopic = () => {
    if (name && color) {
      if (data.mode) {
        const dataTopic = {
          project,
          name,
          color,
          file: filesIcon,
        };
        onCreateTopicType(dataTopic);
      }
      const dataTopic = {
        name,
        color,
        file: filesIcon?.name ? filesIcon : null,
      };
      onUpdateTopicType(dataTopic, data.data.id);
      handleClose();
    }
  };

  useEffect(() => {
    if (!data.mode) {
      setName(data?.data.name || '');
      setColor(data?.data.color || '#000000');
      setFilesIcon((data?.data.icon && { preview: data?.data.icon }) || false);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} sx={{ '& > .MuiDialog-container > .MuiPaper-root': { position: 'absolute', top: '25%' } }}>
      <DialogTitle>{data.mode ? 'Create New' : 'Update'} Topic Type</DialogTitle>
      <DialogContent>
        <Box className="flex flex-col space-y-4">
          <div className="flex space-x-4 items-end">
            <TextField autoFocus margin="dense" id="name" value={name} label="Name" fullWidth size="small" onChange={(e) => setName(e.target.value)} />
            <Input type="color" className="w-8 h-8 mb-1" defaultValue={data?.data.color ? data?.data.color : '#000000'} onBlur={(e) => setColor(e.target.value)} />
          </div>
          <Box className="relative flex w-full h-full justify-center items-center border rounded-lg border-gray-300">
            <div {...getTTProps({ className: 'dropzone w-full h-40 flex justify-center items-center' })} aria-hidden>
              {filesIcon ? (
                <label htmlFor="icon-topic-file">
                  <input className="hidden" {...getTTIProps()} />
                  <img className="w-60 h-40 object-contain" src={filesIcon.preview} alt="topic icon" />
                </label>
              ) : (
                <label htmlFor="icon-topic-file" className="flex justify-center items-center">
                  <input className="hidden" {...getTTIProps()} />
                  <IconButton color="primary" aria-label="upload attachment" component="span">
                    <Image />
                  </IconButton>
                </label>
              )}
            </div>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={actionOnTopic}>
          {data.mode ? 'Create' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

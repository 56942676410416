import React from 'react';
import { Link } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
export default function SidebarCRMProject({ dataProjects, onDestroyProject, onClick }) {
  const html =
    dataProjects.length > 0 &&
    dataProjects?.map((pr, i) => (
      <div key={`${pr.name + i}`} className="flex items-center justify-start py-2 seeeeeeeeeeeeetttttttttt">
        {/* <Link to={`/crm/projects/detail/${pr.id}`} className="flex space-y-3 items-center"> */}
        <Link to={`/crm/projects/project`} className="flex space-y-3 items-center" onClick={() => onClick(pr.id)}>
          <div className="ml-14 flex gap-3 items-center">
            {/* <img src={pr.icon} alt="" className="w-4 h-4 object-cover rounded-md" /> */}
            <span className="hover:text-orange-500">{pr.name}</span>
          </div>
        </Link>
        {/* <button type="button" onClick={() => onDestroyProject(pr.id)} className="ml-auto pr-2">
          x
        </button> */}
      </div>
    ));
  return html;
}

import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Controller } from 'react-hook-form';

export default function SelectDynamicField({ name, label, register = {}, options, errors, onPatchProjectAttribute = () => {}, isMultiple, ...props }) {
  const isError = errors && name in errors;
  const { control, clearErrors, setError, setValue } = props.form;

  const isProspectPriority = name === 'prospect_priority';
  let sxStyle = {};
  if (isProspectPriority) {
    sxStyle = { backgroundColor: 'rgb(25, 118, 210, 0.2)' };
  }

  const selectChanged = (e) => {
    const val = e.target.value;
    if (props.isRequired && (!val || (isMultiple && val.length === 0))) {
      setError(name, { type: 'custom', message: `${name} is a required field` });
      return;
    } else {
      clearErrors(name);
    }
    setValue(name, val);
    if (props.isEdit) {
      onPatchProjectAttribute({ [name]: val });
    }
  };

  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={name}
        render={({ field: { value } }) => {
          const activeHandlerOnEditMode = {};
          return (
            <>
              <InputLabel id={name} sx={{ top: '-8px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } }}>
                {label}
              </InputLabel>
              <Select
                sx={sxStyle}
                value={value || props.defaultValue || (isMultiple ? [] : '')}
                style={{ height: 40 }}
                labelId={name}
                id={name}
                name={name}
                label={label}
                error={isError}
                multiple={isMultiple}
                onChange={selectChanged}
                // {...register}
                {...activeHandlerOnEditMode}
              >
                {
                  !isMultiple && (
                    <MenuItem value={null}>-</MenuItem>
                  )
                }
                {options.map((opt) => {
                  let val = opt;
                  let labelOpt = opt;

                  if (typeof opt === 'object') {
                    val = opt.id;
                    labelOpt = opt.name;
                  }
                  return (
                    <MenuItem key={val} value={labelOpt}>
                      {labelOpt}
                    </MenuItem>
                  );
                })}
              </Select>
            </>
          )
        }}
      />
      {props?.showErrorMessage && isError && <div className="text-[#d32f2f] text-[0.75rem] mt-[4px] border-[#d32f2f]">{errors?.message}</div>}
    </FormControl>
  );
}

  /* <FormControl>
<Controller
  control={control}
  name="customer"
  render={({ field: { onChange, value, ref } }) => (
    <>
      <InputLabel sx={{ top: '-5px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } }} id="select-customer">
        Customer
      </InputLabel>
      <Select
        sx={{
          pt: 0.5,
          pb: 1,
          border: 0,
        }}
        labelId="select-customer"
        name="customer"
        ref={ref}
        value={value}
        label="Customer"
        onChange={onChange}
        error={!!errors.customer?.message}
      >
        <MenuItem value="">
          <em>Customer</em>
        </MenuItem>
        {accountingSettingsContext.members &&
          accountingSettingsContext.members.length > 0 &&
          accountingSettingsContext.members.map((acc, index) => (
            <MenuItem key={`${acc.name + index}`} value={acc.id}>
              {acc.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText className="text-red-600">{errors.customer?.message}</FormHelperText>
    </>
  )}
/>
</FormControl> */

/* eslint-disable */

import { Menu } from '@mui/icons-material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { handleSideBar } from '../store/slices/sidebarTaskSlice';
import { isMobile } from 'react-device-detect';

export default function BaseMainTitle({ title, children }) {
  const dispatch = useDispatch();
  return (
    <div className="w-full bg-[#E2E2E2] p-5">
      <div className="flex items-center space-x-3">
        {isMobile && (
          <div className="">
            <button className="" type="button" onClick={() => dispatch(handleSideBar())}>
              <Menu className="text-white" />
            </button>
          </div>
        )}
        {title ? <div className="text-lg font-semibold">{title || ''}</div> : <div className="">{children}</div>}
      </div>
    </div>
  );
}

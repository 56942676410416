/* eslint-disable no-param-reassign */
// /* eslint-disable */

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import locale from 'react-json-editor-ajrm/locale/en';
import BaseMainTitle from '../../components/BaseMainTitle';
import BaseSubmitButton from '../../components/Button/BaseSubmitButton';
import BaseDeleteButton from '../../components/Button/BaseDeleteButton';
import HandleWatchingState from '../../helper/HandleWatchingState';
import useGet from '../../hooks/useGet';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import BZHelmet from '../../utils/BZHelmet';
import usePost from '../../hooks/usePost';
import useDelete from '../../hooks/useDelete';

function getLangWithId(array1, array2) {
  return array2?.filter((item2) => {
    const match = array1?.find((item1) => item1.flag === item2.code);
    if (match) {
      item2.id = match?.id;
    }
    return match;
  });
}

function getLang(array1, array2) {
  return array2?.filter((item2) => {
    const match = array1?.find((item1) => item1.flag !== item2.code);

    return match;
  });
}

export default function LanguageJSON() {
  const [jsonField, setJsonField] = useState([]);
  const [selectedLang, setSelectedLang] = useState(null || { id: 1, name: 'United Kingdom' });
  const [addLang, setAddLang] = useState(false);
  const { data: languages } = useGet({
    url: `languages`,
    validate: 'languages',
  });


  const [selectedCreateLang, setSelectedCreateLang] = useState({ code: '' });

  const watchSelectedLang = HandleWatchingState(selectedLang);
  const { data: languageDetail } = useGet({
    url: `languages/${selectedLang?.id}/`,
    validate: 'language',
    isEnabled: watchSelectedLang,
    onSuccess: (res) => {
      setJsonField(res?.data?.json_field);
    },
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const result = await axios.get('https://unpkg.com/country-flag-emoji-json@1.0.2/json/flag-emojis.pretty.json');
      setData(result.data);
    }
    fetchData();
  }, []);

  const fetchLocalJSON = async () => {
    try {
      const res = await fetch(`/locales/default/translation.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const jsonRes = await res.json();
      setJsonField(jsonRes);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (addLang) {
      fetchLocalJSON();
    }
  }, [addLang]);

  const filteredLang = getLangWithId(languages?.data?.results, data).sort((a, b) => (a.id > b.id ? 1 : -1));

  const { mutate: updateJson } = usePatchOrPut({ url: `languages/${selectedLang?.id}`, validate: ['language'] });

  const onUpdateJson = () => {
    updateJson({ abbreviation: languageDetail?.data?.abbreviation, name: languageDetail?.data?.name, json_field: jsonField });
  };

  const DownloadJsonFile = (jsonLanguage, selectedLanguage) => {
    const fileData = JSON.stringify(jsonLanguage);
    const blob = new Blob([fileData], { type: 'text/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `translation-  ${selectedLanguage.name} .json`;
    link.href = url;
    link.click();
  };

  const handleChangeLanguage = (value) => {

    const filteredLangByCode = getLang(languages?.data?.results, data)?.filter((lang) => lang.code === value);

    setSelectedCreateLang(filteredLangByCode[0]);
  };

  const handleAddLang = () => {
    setAddLang(!addLang);
    if (addLang) {
      setSelectedLang({ id: 1, name: 'United Kingdom' });
    }
  };

  const { mutate: postLanguages } = usePost({ url: 'languages', validate: ['languages'] });

  const onPostLanguages = () => {
    postLanguages({ abbreviation: selectedCreateLang.code, name: selectedCreateLang.name, flag: selectedCreateLang.code, json_field: jsonField });
  };

  const destroyLanguage = useDelete({
    confText: `modals.language_delete.confirmation_text`,
    confTitle: `modals.language_delete.title`,
    success: 'Language deleted',
    validate: ['languages'],
  });

  return (
    <>
      <BZHelmet title="Language JSON" description="" content="" />
      <div>
        <BaseMainTitle title="Language JSON " />
        <div className="p-5 ">
          <div className="bg-white border p-5 shadow rounded">
            <div className="flex flex-wrap gap-3">
              {languages &&
                data &&
                filteredLang?.map((lang) => {
                  return (
                    <button key={lang?.id} onClick={() => setSelectedLang(lang)} type="button" className="bz-btn rounded">
                      <div className="text-lg -mt-1">{lang?.emoji}</div>
                      <div className={selectedLang?.id === lang.id ? 'text-orange-500 font-semibold' : 'text-white '}>{lang?.name}</div>
                    </button>
                  );
                })}

              <button className='bz-btn red' disabled={false} onClick={() => destroyLanguage(`languages/${selectedLang?.id}`)}>Delete Selected Json</button>

              <BaseSubmitButton disabled={false} text="Download Selected Json" onClick={() => DownloadJsonFile(jsonField, selectedLang)} />
            </div>
            <div className="mt-10 flex space-x-2">
              {addLang && (
                <FormControl sx={{ minWidth: '50%' }}>
                  <Select sx={{ width: '100%', px: 0.2, py: 0.2 }} onChange={(e) => handleChangeLanguage(e.target.value)} value={selectedCreateLang.code} displayEmpty autoWidth>
                    <MenuItem disabled value="">
                      <em>Select Language</em>
                    </MenuItem>
                    {getLang(languages?.data?.results, data)?.map((bt) => (
                      <MenuItem key={bt.code} value={bt.code}>
                        {bt.code}-{bt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <Button onClick={() => handleAddLang()} type="button" className="bz-btn">
                {!addLang ? 'Add Language' : 'Cancel'}
              </Button>
            </div>

            <div className="my-5">
              <JSONInput
                id={`a_unique_ ${languageDetail?.data?.id}`}
                locale={locale}
                height="550px"
                width="100%"
                placeholder={watchSelectedLang ? jsonField : [`Loading ${selectedLang?.name}`]}
                onChange={(res) => {
                  setJsonField(res.jsObject);
                }}
              />
            </div>
            {!addLang ? <BaseSubmitButton disabled={false} onClick={onUpdateJson} /> : <BaseSubmitButton onClick={onPostLanguages} disabled={false} text="Add JSON" />}
          </div>
        </div>
      </div>
    </>
  );
}

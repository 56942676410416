import axiosInstances from '../../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function location() {
  const { axiosInstance } = axiosInstances();

  async function getLocations(params) {
    const method = 'get';
    const url = `${baseURL}locations/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getDetailLocation(id) {
    const method = 'get';
    const url = `${baseURL}locations/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  return { getLocations, getDetailLocation };
}

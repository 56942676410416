import React from 'react';
import { Autocomplete, FormControl, FormHelperText, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import Colors from '../../../constants/Colors';

function BaseAutoComplete(props) {
  return <Autocomplete {...props} />;
}

export const SxTextField = (err, isMultiple = false) => {
  return {
    width: '100%',
    '& > div': {
      padding: '0px 6px 0px 6px !important',
      outline: 'none',
    },
    '& > .MuiInputLabel-root': { top: isMultiple ? '0px' : '-8px' },
    '& > .MuiInputBase-root': { padding: isMultiple ? '8px 4px !important' : '0px 6px 0px 6px!important' },
    '& > .Mui-focused': { top: 0 },
    '& > .MuiFormLabel-filled': { top: 0 },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: err && Colors.primaryRed,
      borderWidth: err && 1,
    },
  };
};

function accessNestedObject(path, obj) {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj || null);
}

const getOptionLabel = (option, optionLabel) => {
  let optLabel = option && (optionLabel ? `${option[optionLabel]}` : option);
  if (optionLabel && Array.isArray(optionLabel)) {
    optLabel = accessNestedObject(optionLabel.join('.'), option);
  }
  return optLabel;
};

export default function BaseAutocompleteValidation({
  control,
  name,
  label,
  errors,
  optionLabel,
  callback,
  config = { styles: '', classes: '', multiple: false, disabled: false },
  options,
  ...props
}) {
  //* otherSetting should be put on CONFIG props as ONE object ex: styles
  const { styles, classes, multiple, disabled } = config;

  return (
    <FormControl sx={styles} className={['w-full', classes].join(' ')}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <BaseAutoComplete
                multiple={multiple}
                disabled={disabled}
                name={name}
                options={options}
                autoHighlight
                getOptionLabel={(option) => getOptionLabel(option, optionLabel)}
                isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
                onChange={(event, dataOption) => {
                  onChange(dataOption);
                  if (callback && typeof callback === 'function') {
                    callback(event, dataOption);
                  }
                }}
                value={value || ''}
                error={errors?.name?.message || (errors[name] ? errors[name].message : '') || ''}
                noOptionsText="No options"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    sx={() => SxTextField(!!errors?.name || !!errors[name], !!multiple)}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
                {...props}
              />
              <FormHelperText className="text-red-600">{errors.name?.message || (errors[name] ? errors[name].message : '')}</FormHelperText>
            </>
          );
        }}
      />
    </FormControl>
  );
}

import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Stack, Typography } from '@mui/material';
import _ from 'lodash';

import PageList from '../Table/PageList';
import TestResult from '../Table/TestResult';

import siteApi from '../../../../../api/site';
import pageApi from '../../../../../api/page';
import performanceApi from '../../../../../api/performance';
import CircleMeter from '../../../../Badge/CircleMeter';

const columns = [
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'url', headerName: 'URL', flex: 1, minWidth: 200 },
];

function BiggerCircleMeter({ value }) {
  return <CircleMeter value={value} sx={{ fontSize: 16, width: 36, height: 36 }} className="mx-auto" />;
}

function Manual() {
  const CLIENT_ID = useSelector((state) => state.client.activeClient);
  const SITE_ID = useSelector((state) => state.content.activeSite);
  const TEMPLATE_ID = useSelector((state) => state.content.activeTemplate);

  const { enqueueSnackbar } = useSnackbar();

  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [pageListRows, setPageListRows] = useState({});
  const [testResults, setTestResults] = useState([]);
  const [isRunningTest, setIsRunningTest] = useState(false);
  const [averageResult, setAverageResult] = useState({});

  const [selectedPages, setSelectedPages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAllTestResults = useCallback(() => {
    performanceApi()
      .getPagePeformances({ max_size: true, page__template: selectedTemplate })
      .then((res) => {
        setTestResults(res.data.results);
      });
  }, [selectedTemplate]);

  const deleteResult = (id) => {
    performanceApi()
      .deleteResult(id)
      .then(() => {
        getAllTestResults();
      })
      .catch((err) => console.error(err));
  };

  const runTest = useCallback(() => {
    const data = {
      pages: selectedPages,
      site: selectedSite,
    };

    if (selectedPages.length && selectedSite) {
      setIsRunningTest(true);

      performanceApi()
        .runSitePerformance(data)
        .then(() => {
          enqueueSnackbar('Test successful', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
          getAllTestResults();
          setIsRunningTest(false);
        })
        .catch((err) => {
          setIsRunningTest(false);
          if (err.response) {
            console.log(err.response);
            enqueueSnackbar(err.response?.data?.message || 'Something went wrong', {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            });
          }
        });
    }
  }, [selectedPages, selectedSite, enqueueSnackbar, getAllTestResults]);

  useEffect(() => {
    const getAllSites = (clientId) => {
      siteApi()
        .getAllSitesByClientID(clientId)
        .then((res) => {
          setSites(res.data.sites);
        });
    };

    getAllSites(CLIENT_ID);
  }, [CLIENT_ID]);

  useEffect(() => {
    const getPageList = (params) => {
      pageApi()
        .getPageNames(params)
        .then((res) => {
          const pageListResults = res.data.results?.map((page) => ({
            id: page.id,
            name: page.name,
            url: page.path,
          }));

          setPageListRows(pageListResults);
        });
    };

    if (selectedTemplate) {
      const params = {
        template: selectedTemplate,
        max_size: true,
      };
      getPageList(params);
      getAllTestResults();
    }
  }, [selectedTemplate, getAllTestResults]);

  useEffect(() => {
    setSelectedSite(SITE_ID);
  }, [SITE_ID]);

  useEffect(() => {
    setSelectedTemplate(TEMPLATE_ID);
  }, [TEMPLATE_ID]);

  useEffect(() => {
    if (testResults.length) {
      const average = {};
      const date = new Date(testResults[0].created_at).getTime() - 3600 * 1000 * 24;
      testResults
        .filter((result) => new Date(result.created_at).getTime() > date)
        .forEach((result) => {
          Object.keys(result.result).forEach((key) => {
            if (!average[key]) {
              average[key] = [];
            }
            average[key].push(result.result[key]);
          });
        });

      const calculatedAvg = {};
      Object.keys(average).forEach((key) => {
        calculatedAvg[key] = Math.floor(_.mean(average[key]));
      });
      setAverageResult(calculatedAvg);
      setIsLoading(false);
    }
  }, [testResults]);

  const selectedSiteObject = sites.find((site) => site.id === parseInt(selectedSite, 10));
  const inputLabelStyle = { top: '-10px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } };

  return (
    <div>
      <div className="flex">
        <FormControl sx={{ minWidth: 120 }} className="mr-2">
          <InputLabel sx={inputLabelStyle} id="select-site-label">
            Site
          </InputLabel>
          <Select labelId="select-site-label" id="select-site" value={selectedSite} label="Site" onChange={(e) => setSelectedSite(e.target.value)}>
            {sites.map((site) => (
              <MenuItem key={site.id} value={site.id}>
                {site.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel sx={inputLabelStyle} id="select-template-label">
            Template
          </InputLabel>
          <Select labelId="select-template-label" id="select-template" value={selectedTemplate} label="Template" onChange={(e) => setSelectedTemplate(e.target.value)}>
            {selectedSiteObject?.templates?.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className="grow" sx={{ minWidth: 120, marginTop: '-16px' }}>
          <div className="text-center">
            <p className="font-bold">Average Score</p>
            <Stack className="mt-2 justify-center" direction="row" spacing={6}>
              <div>
                <BiggerCircleMeter value={averageResult.performance} />
                <Typography>Performance</Typography>
              </div>
              <div>
                <BiggerCircleMeter value={averageResult.seo} />
                <Typography>SEO</Typography>
              </div>
              <div>
                <BiggerCircleMeter value={averageResult.accessibility} />
                <Typography>Accessibility</Typography>
              </div>
              <div>
                <BiggerCircleMeter value={averageResult.best_practices} />
                <Typography>Best Practices</Typography>
              </div>
            </Stack>
          </div>
        </FormControl>
      </div>
      <Grid container className="mt-1" spacing={2}>
        <Grid item md="4">
          <PageList rows={pageListRows} columns={columns} onSelectionModelChange={(selectionModel) => setSelectedPages(selectionModel)} />
          <LoadingButton className="mt-3" disabled={selectedPages.length === 0} variant="contained" onClick={runTest} loading={isRunningTest}>
            Run Test
          </LoadingButton>
        </Grid>
        <Grid item md="8">
          <TestResult deleteResult={deleteResult} rows={testResults} isLoading={isLoading} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Manual;

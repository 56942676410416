/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowForward, Edit, Sync, Code, LogoDev, Menu, SyncAlt } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { Button, Tooltip } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { handleModal } from '../../../store/slices/modalSlice';
import ModalWrapper from '../../../components/ui/ModalWrapper';

import SectionForm from '../../../components/Form/Section/SectionForm';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BaseBackButton from '../../../components/Button/BaseBackButton';
import PageSettingForm from '../../../components/Form/Page/PageSettingForm';
import TransferSectionForm from '../../../components/Form/Section/TransferSectionForm';
import useGet from '../../../hooks/useGet';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import usePost from '../../../hooks/usePost';
import useDelete from '../../../hooks/useDelete';
import BaseDeleteButton from '../../../components/Button/BaseDeleteButton';
import PairSectionForm from '../../../components/Form/Section/PairSectionForm';
import BZButton from '@/components/Button/BZButton';

const stringToJson = (str) => {
  return str.replaceAll('True', 'true').replaceAll('False', 'false').replaceAll(`'`, `"`);
};

const transPage = 'modals.section_pair.';

export default function PageDetail() {
  const { t } = useTranslation();
  const { pageId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const modal = useSelector((state) => state.modal);

  const experimentId = useSelector((state) => state.auth.user.id);

  const [jsonData, setJsonData] = useState(null);
  const [bulkSectionDel, setBulkSectionDel] = useState([]);
  const [sectionDetail, setSectionDetail] = useState(null);
  const [expandImage, setExpandImage] = useState({ id: null, open: false });
  const [expandAll, setExpandAll] = useState(false);
  const [listSection, setListSection] = useState([]);

  const [activeExperiment, setActiveExperiment] = useState(false);

  useEffect(() => {
    if (location.hash && location.hash === '#experiment') {
      setActiveExperiment(true);
    }
  }, [location.hash]);

  const handleExpandImage = (id) => {
    setExpandImage({ id, open: !expandImage.open });
    if (expandImage.id === id && expandImage.open === false) {
      setExpandImage({ id: null, open: false });
    } else setExpandImage({ id, open: false });
  };

  const { data: pageDetail } = useGet({
    url: `pages/${pageId}`,
    validate: 'page',
    onSuccess: (e) => {
      setListSection(e?.data?.sections);
    },
  });

  const sectionId = modal.modalId;

  useGet({
    url: `section_catalogs/${sectionId}/`,
    validate: 'meta',
    onSuccess: (e) => {
      const fixedMetadata = stringToJson(e.data.metadata);
      setJsonData(JSON.parse(fixedMetadata));
    },
    isEnabled: modal.componentName === 'generateMeta',
  });

  useGet({
    url: `sections/${sectionId}`,
    validate: 'section',
    onSuccess: (e) => {
      setSectionDetail(e.data);
    },
    isEnabled: modal.componentName === 'editSection' || modal.componentName === 'transferSection',
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const { mutate: updateIndexSections } = usePatchOrPut({
    url: `sections/index_update`,
    validate: ['page'],
  });

  const onUpdateIndexSection = async (dataUpdate) => {
    await updateIndexSections(dataUpdate);
  };

  const handleDragEnd = (result) => {
    const items = reorder(pageDetail?.data?.sections, result.source.index, result.destination.index);
    // setPage(items);
    const section = items;
    setListSection(section);
    onUpdateIndexSection({ section_ids: section.map((p) => p.id) });
  };

  const { mutate: genretMetaData } = usePost({ validate: ['page'], disablePostData: true });

  const onGenerateMeta = async (data) => {
    await genretMetaData({ url: `sections/${data}/generate_metadata` });
  };

  function generateNprops(node) {
    const html = (
      <div title={node.name} className="">
        <div className="flex  right-0 absolute">
          <Button title="Generate Metadata" className="min-w-0 mx-1 w-6 h-6 p-0" size="small" variant="contained" color="success" onClick={() => onGenerateMeta(node.id)}>
            <Sync fontSize="small" />
          </Button>
          <Button
            title="View Metadata"
            className="min-w-0 mx-1 w-6 h-6 p-0"
            size="small"
            variant="contained"
            color="success"
            onClick={() => dispatch(handleModal({ modalId: node.parent, componentName: 'generateMeta' }))}
          >
            <Code fontSize="small" />
          </Button>
          <Button
            // disabled={node.parent === undefined}
            title="To Developer Mode"
            className="min-w-0 mx-1 w-6 h-6 p-0"
            size="small"
            variant="contained"
            color="error"
          >
            <Link to={`/section/developer/${node.id}`}>
              <LogoDev fontSize="small" />
            </Link>
          </Button>
          <Button
            title="Edit Section"
            className="min-w-0 mx-1 w-6 h-6 p-0"
            size="small"
            variant="contained"
            onClick={() => dispatch(handleModal({ modalId: node.id, componentName: 'editSection' }))}
          >
            <Edit fontSize="small" />
          </Button>
          {node.menu && (
            <Button title="Section Menu" className="min-w-0 mx-1 w-6 h-6 p-0" size="small" variant="contained">
              <Link to={`/menu-list/${node.menu}`}>
                <Menu fontSize="small" />
              </Link>
            </Button>
          )}

          <Button title={`To Page ${node.name}`} size="small" variant="contained" className="min-w-0 mx-1 w-6 h-6 p-0">
            <Link to={`/section/detail/${node.id}`}>
              <ArrowForward fontSize="small" />
            </Link>
          </Button>
          {experimentId === 124 && activeExperiment && (
            <Button
              title="Transfer To New Section"
              className="min-w-0 mx-1 w-6 h-6 p-0 !bg-black"
              size="small"
              variant="contained"
              onClick={() => dispatch(handleModal({ modalId: node.id, componentName: 'transferSection' }))}
            >
              <SyncAlt fontSize="small" />
            </Button>
          )}
        </div>
      </div>
    );
    return html;
  }

  const handleDeleteSection = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(bulkSectionDel);
      newSelection.push(parseInt(value, 10));
      setBulkSectionDel(newSelection);
    } else {
      const newSelection = bulkSectionDel;
      const newSubs = newSelection.filter((sub) => parseInt(sub, 10) !== parseInt(value, 10));
      setBulkSectionDel(newSubs);
    }
  };

  const onDestroySection = useDelete({
    url: 'sections/bulk_delete',
    confText: `modals.section_delete.confirmation_text`,
    confTitle: `modals.section_delete.title`,
    success: 'Section deleted',
    validate: ['page'],
    bodyData: { section_ids: bulkSectionDel },
    method: 'patch',
    isSubUrl: false,
  });

  const { mutate: updateSection } = usePatchOrPut({
    url: `sections/${modal.modalId}`,
    validate: ['page'],
  });

  const onUpdateSection = async (data) => {
    await updateSection(data);
  };

  const handleAddSection = () => {
    dispatch(handleModal({ modalId: '', componentName: 'addSection' }));
  };

  const { mutate: updatePageSetting } = usePatchOrPut({
    url: `pages/${pageId}`,
    validate: ['page'],
  });

  return (
    <div>
      <BaseMainTitle title={`Section List : Page (${pageDetail?.data?.name})`} />
      <div className="p-5">
        <div className=" bg-white border p-5 shadow rounded ">
          <div className="bg-gray-100 w-full py-5 px-3 mb-5 rounded flex items-center">
            <BaseBackButton />
            <div className="ml-auto flex gap-x-3">
              <BZButton variant={bulkSectionDel.length === 0 ? 'disabled' : 'danger'} disabled={bulkSectionDel.length === 0} onClick={() => onDestroySection()}>
                Delete selected
              </BZButton>
              <Button onClick={handleAddSection} className="bz-btn text-white" type="submit" variant="filled">
                Add Section
              </Button>
            </div>
          </div>
          <div className=" flex space-x-5">
            <div className="w-9/12">
              <div className="mb-4 p-2 rounded bg-bz-green w-fit text-white">
                <button onClick={() => setExpandAll(!expandAll)} className="font-bold" type="button">
                  {expandAll ? 'Collapse All' : 'Expand All'}
                </button>
              </div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="sections">
                  {(provided) => (
                    <ul className="space-y-5" {...provided.droppableProps} ref={provided.innerRef}>
                      {listSection?.map((sec, index) => (
                        <Draggable key={sec.id} draggableId={`${sec.id}`} value={sec.id} index={index}>
                          {(provid) => (
                            <div ref={provid.innerRef} {...provid.draggableProps} {...provid.dragHandleProps}>
                              <div key={sec.id} className="bg-white border shadow rounded  ">
                                <div className="bg-bz-green w-full text-white p-2 rounded-t relative flex">
                                  <input type="checkbox" name="lang" value={sec.id} className="" onChange={handleDeleteSection} />
                                  <p className="ml-8">{`${index + 1}.  ${sec?.name}`}</p>
                                  <div className="">{generateNprops(sec)}</div>
                                </div>
                                <div className="w-full p-3">
                                  <Tooltip title={!expandAll ? (expandImage.id === sec.id ? 'Collapse Image' : 'Expand Image') : ''}>
                                    <button
                                      className={`${expandImage.id === sec.id || expandAll ? 'h-full' : 'h-10'}  w-full object-cover`}
                                      type="button"
                                      onClick={() => handleExpandImage(sec.id)}
                                    >
                                      <img src={`${sec.screenshot ?? 'https://admin.bzpublish.com/img/bz-publish.svg'}`} alt="" className="h-full  w-full object-cover" />
                                    </button>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="w-3/12">
              <PageSettingForm defaultValues={pageDetail?.data} key={pageDetail?.data?.id} onFormSubmit={updatePageSetting} />
            </div>
          </div>
        </div>
        <ModalWrapper componentName="addSection" header={t(`${transPage}section_pair`)} maxWidth="lg">
          <div className="">
            <PairSectionForm />
          </div>
        </ModalWrapper>
        <ModalWrapper componentName="editSection" header={t('update_section')} maxWidth="sm">
          <SectionForm defaultValues={sectionDetail} key={sectionDetail?.id} onFormSubmit={onUpdateSection} />
        </ModalWrapper>
        <ModalWrapper componentName="generateMeta" header="Meta Data" maxWidth="sm">
          <JSONInput locale={locale} viewOnly height="550px" width="100%" placeholder={jsonData} />
        </ModalWrapper>
        {/* Transfer section Form */}
        <ModalWrapper componentName="transferSection" header="Transfer Section" maxWidth="xl">
          <TransferSectionForm defaultValues={sectionDetail} key={sectionDetail?.id} onFormSubmit={onUpdateSection} sectionList={pageDetail?.data?.sections} />
        </ModalWrapper>
        {/* Transfer section Form */}
      </div>
    </div>
  );
}

/* eslint-disable */

import request from "../helper/axiosUtil";

export const fetchPageList = async ({ queryKey }) => {
  const [, { template }] = queryKey;
  return request({
    url: `/pages/?template=${template}&language=1&max_size=true`
  });
};


export const fetchSectionListWidget = async ({ queryKey }) => {
  const [, { page }] = queryKey;
  return request({
    url: `/sections/?language=1&page__id=${page}&max_size=true`
  });
};


export const fetchSectionList = async ({ queryKey }) => {
  const [, { clientId, page }] = queryKey;
  return request({
    url: `/section_catalogs/?folder_exists=True&client=${clientId}&page_size=9&page=${page}&category=`
  });
};

export const fetchSectionListAll = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({
    url: `/section_catalogs/?folder_exists=True&client=${clientId}&max_size=true`
  });
};



export const fetchEmailContent = async ({ queryKey }) => {
  const [, { clientId, siteId }] = queryKey;
  return request({
    url: `/email_contents/?client=${clientId}&site=${siteId}`
  });
};

export const addSectionBulk = async (data) => request({
  url: '/section_catalogs/bulk_update/',
  method: 'post',
  data,
  // headers: { 'Content-Type': 'application/json' },
});

export const fetchSectionCatalogue = async ({ queryKey }) => {
  const [, { sectionId }] = queryKey;
  return request({
    url: `/section_catalogs/${sectionId}/`
  });
};

export const fetchSectionCatalogueType = async () => {
  return request({
    url: `/section_catalogs/types/`
  });
};

export const sectionDistribute = async (id) => request({
  url: `/section_catalogs/${id}/distribute/`,
  method: 'post',
});

export const createEmailContent = async (data) => request({
  url: '/email_contents/',
  method: 'post',
  data,
  headers: { 'Content-Type': 'application/json' },
});


export const sectionCatBulkDelete = async (data) => request({
  url: `/section_catalogs/bulk_delete/`,
  method: 'post',
  data
});

export const updateEmailContent = async (data) => request({
  url: `/email_contents/${data.id}/`,
  method: 'patch',
  data
});

export const deleteEmailContent = async (id) => request({
  url: `/email_contents/${id}/`,
  method: 'delete',
});

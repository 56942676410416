import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { QueryClient, QueryClientProvider, useQueries, useQuery } from '@tanstack/react-query';

import store from '../../../store/index';
import DetailContext from './DetailContext';

import HeaderTask from '../../../components/Header/HeaderTask/HeaderTask';
import Boards from '../Boards/Boards';
import List from '../Boards/List/List';
import Overview from '../Boards/Overview/Overview';
import Settings from '../Boards/Settings/Settings';
import Files from '../Boards/Files/Files';
import TaskDialogWarning from '../../../components/ui/Task/TaskDialogWarning/TaskDialogWarning';

import projectApi from '../../../api/task/project';
import teamApi from '../../../api/task/team';
import accountingApi from '../../../api/accounting/accounting';
import BZHelmet from '../../../utils/BZHelmet';
import Report from '../Boards/Report/Report';

const tabHeader = [
  {
    id: 'Overview',
    name: 'Overview',
  },
  {
    id: 'Board',
    name: 'Board',
  },
  {
    id: 'List',
    name: 'List',
  },
  {
    id: 'Settings',
    name: 'Settings',
  },
  {
    id: 'Files',
    name: 'Files',
  },
  {
    id: 'Report',
    name: 'Report',
  },
];

const urlModified = (currLoc) => {
  const substrings = currLoc.split('/');
  let activeLoc;
  if (substrings.length === 1) {
    activeLoc = currLoc;
  } else if (substrings.length <= 4) {
    activeLoc = substrings.slice(0, substrings.length).join('/');
  } else {
    activeLoc = substrings.slice(0, -1).join('/');
  }
  return activeLoc;
};

const { getMembers } = accountingApi();
const { getLabels, getTaskProject, getTopics, getTopicPriorities, getTopicStatuses, getTopicTypes } = projectApi();
const { getTeam, getTeamDetail } = teamApi();

const queryClient = new QueryClient({
  defaultOptions: {
    queries:
      // Config
      { refetchOnWindowFocus: false, refetchOnMount: false, refetchOnReconnect: false, retry: true, staleTime: 10 * 60 * 1000 },
  },
});

function DetailQueryProvider({ children }) {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

export default function Detail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeHeaderTabs = useSelector((state) => state.sidebar.activeTabs);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const activeTask = useSelector((state) => state.task.runningTask);
  const triggerWarningR = useSelector((state) => state.task.triggerWarning);
  const triggerRedrawR = useSelector((state) => state.task.triggerRedraw);
  const { projectId, tabs } = useParams();

  const [isRefetch, setRefetch] = useState(false);
  const [htmlContent, setHtmlContent] = useState(<div />);
  const [activeTab, setActiveTab] = useState(0);

  const [currParam, setCurrParam] = useState(projectId);
  const [triggerDrawer, setTriggerDrawer] = useState(false);
  const [triggerRedraw, setTriggerRedraw] = useState(false);
  const [triggerWarning, setTriggerWarning] = useState(false);

  const {
    data: projectData,
    isFetching,
    isFetched,
    refetch: refetchProjectDetail,
  } = useQuery({
    queryKey: ['getTaskProject', { projectId }],
    enabled: !!projectId,
    queryFn: (params) => {
      return getTaskProject(params.queryKey[1].projectId);
    },

    onSuccess: () => {},
    onError: () => {
      // store.dispatch({ type: 'auth/setLogout' });
    },
    refetchOnWindowFocus: false,
  });

  const getProjectDetail = useCallback(() => refetchProjectDetail(), [refetchProjectDetail]);

  const prevTeam = useRef();
  const sections = useRef([]);

  const projects = useMemo(() => projectData?.data, [projectData]);
  sections.current = projectData?.data.sections;
  // let sections = useMemo(() => projectData?.data.sections, [projectData]);
  const tasks = useMemo(() => projectData?.data.sections, [projectData]);
  const teams = useMemo(() => projectData?.data.team, [projectData]);
  const isLoading = !!isFetching;

  const queryResult = useQueries({
    queries: [
      {
        queryKey: ['teamUsers', projectId],
        queryFn: () => getTeamDetail(teams?.id, { max_size: true }, 'get_users'),
        enabled: !!teams && !!projects,
        onSuccess: (res) => {
          prevTeam.current = res.data.id;
        },
        refetchOnWindowFocus: false,
      },
      { queryKey: ['topicTypes', projectId], queryFn: () => getTopicTypes({ project: projectId, max_size: true }), enabled: !!projectId, refetchOnWindowFocus: false },
      { queryKey: ['topicStatuses', projectId], queryFn: () => getTopicStatuses({ project: projectId, max_size: true }), enabled: !!projectId, refetchOnWindowFocus: false },
      { queryKey: ['topicPriorities', projectId], queryFn: () => getTopicPriorities({ project: projectId, max_size: true }), enabled: !!projectId, refetchOnWindowFocus: false },
      {
        queryKey: ['topicList', projectId],
        queryFn: () => getTopics({ project: projectId, max_size: true, is_incomplete: true, id_excluded: projectId }),
        enabled: !!projectId,
        refetchOnWindowFocus: false,
      },
      { queryKey: ['teamMembers', projectId], queryFn: () => getTeam(activeClient, { max_size: true }), enabled: !!activeClient, refetchOnWindowFocus: false },
      { queryKey: ['customers', projectId], queryFn: () => getMembers({ client: activeClient, max_size: true }), enabled: !!activeClient, refetchOnWindowFocus: false },
      { queryKey: ['labelList', projectId], queryFn: () => getLabels({ project: projectId, max_size: true }), enabled: !!projectId, refetchOnWindowFocus: false },
    ],
  });

  const [teamUsers, topicTypes, topicStatuses, topicPriorities, topicList, teamMembers, customers, labelList] = queryResult;

  const { data: usersData } = teamUsers;
  const { data: typesData } = topicTypes;
  const { data: statusesData } = topicStatuses;
  const { data: prioritiesData } = topicPriorities;
  const { data: topicsData } = topicList;
  const { data: teamListData } = teamMembers;
  const { data: customerListData } = customers;
  const { data: labelsData } = labelList;

  const users = useMemo(() => usersData?.data.users, [usersData]);
  const types = useMemo(() => typesData?.data.results, [typesData]);
  const statuses = useMemo(() => statusesData?.data.results, [statusesData]);
  const priorities = useMemo(() => prioritiesData?.data.results, [prioritiesData]);
  const topics = useMemo(() => topicsData?.data.results, [topicsData]);
  const teamList = useMemo(() => teamListData?.data.results, [teamListData]);
  const customerList = useMemo(() => customerListData?.data, [customerListData]);
  const labels = useMemo(() => labelsData?.data.results, [labelsData]);

  useEffect(() => {
    if (teams && prevTeam.current && teams.id !== prevTeam.current) {
      queryResult[0].refetch();
    }
  }, [queryResult, teams, projectId]);

  const dispatchProject = useCallback(
    (actionType) => {
      switch (actionType) {
        case 'GET':
          getProjectDetail();
          return;
        case 'TYPE':
          queryResult[1].refetch();
          getProjectDetail();

          break;
        case 'PRIORITY':
          queryResult[3].refetch();
          getProjectDetail();
          break;
        default:
      }
    },
    [getProjectDetail, queryResult]
  );

  const renderContent = useCallback(
    (tab) => {
      let content;
      switch (tab) {
        case 0:
          content = <Overview />;
          break;
        case 1:
          content = <Boards isTabs={tabs} />;
          break;
        case 2:
          content = <List isTabs={tabs} />;
          break;
        case 3:
          content = <Settings />;
          break;
        case 4:
          content = <Files />;
          break;
        case 5:
          content = <Report projectData={projectData?.data} />;
          break;
        default:
          break;
      }
      setHtmlContent(content);
    },
    [tabs]
  );

  useEffect(() => {
    if (activeTab !== activeHeaderTabs) {
      setActiveTab(activeHeaderTabs);
      renderContent(activeHeaderTabs);
    }
  }, [activeHeaderTabs, activeTab, renderContent]);

  useEffect(() => {
    renderContent(activeTab);
  }, [activeTab, renderContent]);

  const setNewSections = useCallback((newSections) => {
    sections.current = newSections;
    // setSections(newSections);
  }, []);

  const setSections = useCallback((newSections) => {
    sections.current = newSections;
  }, []);

  const setLogTask = useCallback(
    (data) => {
      if (!data) {
        dispatch({ type: 'task/stopRunningTask' });
      } else {
        const newData = { ...data };
        newData.client = activeClient;
        newData.user = activeUser.id;
        dispatch({ type: 'task/setRunningTask', payload: newData });
      }
      // setActiveTask(data);
      getProjectDetail();
    },
    [activeClient, activeUser, dispatch, getProjectDetail]
  );

  const logWorkingHours = useCallback(
    (dataLog, id, params, action, trigger, callback) => {
      const { logWorkHours } = projectApi();
      logWorkHours(dataLog.request, id, params, action)
        .then((res) => {
          if (res.data) {
            if (action !== 'patch') {
              setLogTask({ ...res.data, ...dataLog.state });
            } else {
              setLogTask(false);
            }
            if (callback) {
              callback();
            }
            if (trigger) {
              setTriggerDrawer(true);
            }
          }
        })
        .catch((err) => {
          const res = err.response;
          if (res.status === 400) {
            const dt = res.data?.unfinished_log;
            if (dt) {
              const currActiveTask = {
                project: {
                  name: dt.topic?.project?.name,
                  id: dt.topic?.project?.id,
                },
                task: {
                  name: dt.topic?.name,
                  id: dt.topic?.id,
                },
              };
              const newLog = {
                ...currActiveTask,
                id: dt.id,
                time_in: dt.time_in,
                topic: dt.topic?.id,
                newTopic: dt.topic?.id,
              };
              setLogTask(newLog);
              setTriggerWarning({ ...dataLog.state });
            }
          }
        });
    },
    [setLogTask]
  );

  const handleGoToTask = useCallback(
    (param) => {
      setTriggerWarning(false);
      if (param) {
        navigate(param, { replace: false });
        setTriggerRedraw(true);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (triggerWarningR && !triggerWarning) {
      setTriggerWarning(triggerWarningR);
      dispatch({ type: 'task/setTriggerWarning', action: false });
    }
  }, [triggerWarningR, triggerWarning, dispatch]);
  useEffect(() => {
    if (triggerRedrawR && !triggerRedraw) {
      setTriggerRedraw(triggerRedrawR);
      dispatch({ type: 'task/setTriggerRedraw', action: false });
    }
  }, [triggerRedrawR, triggerRedraw, dispatch]);

  useEffect(() => {
    if (currParam !== projectId) {
      setCurrParam(projectId);
    }
    return () => {
      queryClient.invalidateQueries({ queryKey: [projectId] });
    };
  }, [currParam, projectId]);

  const context = useMemo(
    () => ({
      isRefetch,
      setRefetch,
      isLoading,
      teamList,
      topics,
      users,
      projectId,
      tabs,
      projects,
      tasks,
      sections: sections.current,
      setSections,
      teams,
      customerList,
      dispatchProject,
      setNewSections,
      tabHeader,
      urlModified,
      types,
      labels,
      statuses,
      priorities,
      logWorkingHours,
      activeTask,
      activeTab,
      trigger: {
        triggerDrawer,
        setTriggerDrawer,
        triggerWarning,
        setTriggerWarning,
        triggerRedraw,
        setTriggerRedraw,
      },
      handleGoToTask,
    }),
    [
      isRefetch,
      isLoading,
      teamList,
      topics,
      users,
      projectId,
      tabs,
      projects,
      tasks,
      sections,
      setSections,
      teams,
      customerList,
      dispatchProject,
      setNewSections,
      types,
      labels,
      statuses,
      priorities,
      logWorkingHours,
      activeTask,
      activeTab,
      triggerDrawer,
      setTriggerWarning,
      triggerWarning,
      triggerRedraw,
      handleGoToTask,
    ]
  );
  useLayoutEffect(() => {
    store.dispatch({ type: 'sidebarTask/setActiveHeader', payload: 'project' });
    if (tabs) {
      const regExTest = /\d+/.test(tabs);
      if (regExTest) {
        // param as TOPIC ID
        store.dispatch({ type: 'sidebarTask/setActiveTabs', payload: 1 });
      } else {
        // params as TAB NAME
        const tabIndex = tabHeader.filter((tab) => tab.name.toLowerCase() === tabs.toLowerCase());
        if (String(tabIndex)) {
          store.dispatch({ type: 'sidebarTask/setActiveTabs', payload: tabHeader.indexOf(tabIndex[0]) });
        }
      }
    }
  }, [tabs]);

  return (
    <DetailQueryProvider>
      <DetailContext.Provider value={context}>
        <BZHelmet
          title={`${projects && projects.name ? projects.name : 'Project Detail'}`}
          description={`bz publish task management ${projects && projects.name ? `${projects.name} project` : 'project'}`}
        />
        {isFetched && projects && users ? (
          <>
            <HeaderTask tab={tabHeader} />
            <div className="w-full">{htmlContent}</div>
          </>
        ) : (
          <CircularProgress />
        )}
        <TaskDialogWarning open={!!triggerWarning} setOpen={() => setTriggerWarning(false)} />
      </DetailContext.Provider>
    </DetailQueryProvider>
  );
}

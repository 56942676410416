import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

function BZButton({ variant, onClick, children, type ='button', className="", ...props }) {
  const getButtonStyle = () => {
    switch (variant) {
      case 'primary':
        return 'btn-utils bg-bz-orange text-white hover:text-bz-orange hover:bg-white border border-bz-orange';
      case 'secondary':
        return 'btn-utils bg-bz-green text-white hover:text-bz-green hover:bg-white border border-bz-green';
      case 'danger':
        return 'btn-utils bg-red-700 text-white hover:text-red-700 hover:bg-white border border-red-700';
      case 'accented-primary':
        return '';
      case 'accented-secondary':
        return '';
      case 'fullwidth':
        return 'btn-utils bg-black text-white hover:text-black hover:bg-white border border-black';
      case 'disabled':
        return 'btn-utils bg-[#e7e7e7] text-black/30 border border-[#e7e7e7] pointer-events-none';
      default:
        return 'btn-utils bg-bz-orange text-white hover:text-bz-orange hover:bg-white border border-bz-orange';
    }
  };

  return (
    <button type={type} className={twMerge(getButtonStyle(), className)} onClick={onClick} {...props}>
      {children}
    </button>
  );
}

BZButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'accented-primary', 'accented-secondary', 'disabled', 'fullwidth']),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default BZButton;

import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useQueries } from '@tanstack/react-query';

import AccountingSettingsContext from './AccountingSettingsContext';

import TabAccountGroup from './Tab/AccountGroup';
import TabAccountType from './Tab/AccountType';
import TabAccountCode from './Tab/AccountCode';
import TabCostCenter from './Tab/CostCenter';
import TabPNLBSCategory from './Tab/PNLBSCategory';
import TabPNLBSGroup from './Tab/PNLBSGroup';
import TabCounterPart from './Tab/Counterpart';

import apiAccounting from '../../../api/accounting/accounting';
import BZHelmet from '../../../utils/BZHelmet';

import styles from './Settings.module.css';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.defaultProps = { children: <div /> };

function a11yProps(index) {
  return {
    id: `accounting-tab-${index}`,
    'aria-controls': `accounting-tabpanel-${index}`,
  };
}

const tabItems = ['Account Group', 'Account Type', 'Account Code', 'Cost Center', 'PNL/BS Group', 'PNL/BS Category', 'Counterpart'];

export default function Settings() {
  const dispatch = useDispatch();

  const cl = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const client = activeUser.is_superuser ? activeClient : cl;

  const { getAccountGroups, getBusinessUnits, getMembers, getPaymentTerms, getPNLGroups, getAccountTypes, getPNLCategories } = apiAccounting();
  const [activeTab, setActiveTab] = useState(0);
  const onTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const queryResult = useQueries({
    queries: [
      {
        queryKey: ['accountGroups', client],
        queryFn: () => getAccountGroups({ account_type__client: client, max_size: true, ordering: 'code' }),
        enabled: !!client,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['businessUnits', client],
        queryFn: () => getBusinessUnits({ client, max_size: true }),
        enabled: !!client,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['members', client],
        queryFn: () => getMembers({ client, max_size: true }),
        enabled: !!client,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['paymentTerms', client],
        queryFn: () => getPaymentTerms({ client, max_size: true }),
        enabled: !!client,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['pnlGroups', client],
        queryFn: () => getPNLGroups({ client, max_size: true, ordering: 'code' }),
        enabled: !!client,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['accountTypes', client],
        queryFn: () => getAccountTypes({ client, max_size: true, ordering: 'code' }),
        enabled: !!client,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['pnlCategories', client],
        queryFn: () => getPNLCategories({ client, max_size: true, ordering: 'pnl_bs_group__code' }),
        enabled: !!client,
        refetchOnWindowFocus: false,
      },
    ],
  });

  const [a, b, c, d, e, f, g] = queryResult;
  const { data: aa } = a;
  const { data: bb } = b;
  const { data: cc } = c;
  const { data: dd } = d;
  const { data: ee } = e;
  const { data: ff } = f;
  const { data: gg } = g;

  const accountGroups = useMemo(() => aa?.data?.results, [aa]);
  const businessUnits = useMemo(() => bb?.data?.results, [bb]);
  const members = useMemo(() => cc?.data?.results, [cc]);
  const paymentTerms = useMemo(() => dd?.data?.results, [dd]);
  const pnlGroups = useMemo(() => ee?.data?.results, [ee]);
  const accountTypes = useMemo(() => ff?.data?.results, [ff]);
  const pnlCategories = useMemo(() => gg?.data?.results, [gg]);

  const refetchAccG = queryResult[0].refetch;
  const refetchPnlG = queryResult[4].refetch;
  const refetchAccT = queryResult[5].refetch;
  const refetchPnlC = queryResult[6].refetch;

  const isLoaded = queryResult.every((query) => query.isSuccess);

  const context = useMemo(
    () => ({ accountGroups, businessUnits, members, paymentTerms, pnlGroups, accountTypes, pnlCategories, refetchAccG, refetchPnlG, refetchAccT, refetchPnlC }),
    [accountGroups, businessUnits, members, paymentTerms, pnlGroups, accountTypes, pnlCategories, refetchAccG, refetchPnlG, refetchAccT, refetchPnlC]
  );

  useLayoutEffect(() => {
    dispatch({ type: 'accountSettings/setTabState', payload: { type: 'reset', value: {} } });
  }, [])

  return (
    <AccountingSettingsContext.Provider value={context}>
      <BZHelmet title="Accounting Settings" description="bz publish settings page for accounting services" />
      {isLoaded && (
        <div className="w-full">
          <Box className={styles.container}>
            <div className="w-full flex p-4 shadow-md">
              <h1 className="text-lg font-bold">Accounting Codes</h1>
            </div>
            <div className="mt-4 border-b">
              <Tabs value={activeTab} onChange={onTabChange} aria-label="accounting tabs" className="bz-tabs">
                {tabItems && tabItems.length > 0 && tabItems.map((tb, index) => <Tab key={tb} className="p-4" label={tb} {...a11yProps(index)} />)}
              </Tabs>
            </div>
            <TabPanel value={activeTab} index={0}>
              <TabAccountGroup />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <TabAccountType />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <TabAccountCode />
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              <TabCostCenter />
            </TabPanel>
            <TabPanel value={activeTab} index={4}>
              <TabPNLBSGroup />
            </TabPanel>
            <TabPanel value={activeTab} index={5}>
              <TabPNLBSCategory />
            </TabPanel>
            <TabPanel value={activeTab} index={6}>
              <TabCounterPart />
            </TabPanel>
          </Box>
        </div>
      )}
    </AccountingSettingsContext.Provider>
  );
}

// /* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Pagination, CircularProgress, FormControlLabel, FormControl, Radio, RadioGroup, CardActionArea, CardActions, CardContent, Card, Typography } from '@mui/material';
import { useParams } from 'react-router';
import BaseSubmitButton from '../../Button/BaseSubmitButton';
import BaseSearchButton from '../../Button/BaseSearchButton';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import useGet from '../../../hooks/useGet';
import BaseApplyButton from '../../Button/BaseApplyButton';
import RenderResults from '../../RenderResults';
import InputContainer from '../../Input/InputContainer';
import usePost from '../../../hooks/usePost';

export default function PairSectionForm({ fixBottom = true }) {
  const { control, handleSubmit, setValue } = useForm();
  const { pageId } = useParams();
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);
  const clientId = useSelector((state) => state.client.activeClient);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const [isDataLoad, setIsDataLoad] = useState(true);
  const [valueTab, setValueTab] = useState(0);
  const [sectionPaired, setSectionPaired] = useState(null);

  const [sectionListData, setSectionListData] = useState(null);
  const [countSection, setCountSection] = useState(0);

  const count = Math.ceil(countSection / 9);

  const handleChange = (e, p) => {
    setIsDataLoad(false);
    setTimeout(() => {
      setIsDataLoad(true);
    }, 500);
    setPage(p);
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangeSection = (event) => {
    setSectionPaired(event.target.value);
  };

  const { isFetching } = useGet({
    url: `section_catalogs/?folder_exists=True&page_size=9&page=${page}&category=&client=${clientId}&search=${search}`,
    validate: 'sections',
    onSuccess: (e) => {
      setSectionListData(e.data.results);
      setCountSection(e.data.count);
    },
    isEnabled: isDataLoad,
  });

  const onSearchAsset = (event) => {
    setSearch(event.target.value);
  };

  const { mutate: pairSection } = usePost({ url: 'sections', validate: ['page'] });

  const onPairSection = async (data) => {
    console.log('🚀 ~ file: PairSectionForm.jsx:100 ~ onPairSection ~ data', data);

    await pairSection({ name: data?.name, page: parseInt(pageId, 10), parent: parseInt(sectionPaired, 10), section_languages: data?.section_languages });
  };

  useEffect(() => {
    clientLanguage?.forEach((lang, index) => setValue(`section_languages[${index}][language]`, lang.language.id));
  }, [clientLanguage, setValue]);

  return (
    <div className={fixBottom ? 'mb-20' : ''}>
      <div className="flex gap-x-3 items-center">
        <BaseSearchButton onChange={onSearchAsset} setPage={setPage} setIsDataLoad={setIsDataLoad} />
        <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} />
      </div>
      <div className="flex justify-between">
        {!isFetching ? (
          <RenderResults
            condition={count > 0}
            component={
              <div className="pt-5 w-full">
                <form onSubmit={handleSubmit(onPairSection)}>
                  <InputContainer
                    name="name"
                    control={control}
                    label="name"
                    // errors={errors.name}
                  />
                  <MuiCusotmTabs
                    optionsTab={clientLanguage.map((lang) => {
                      return {
                        name: lang?.language?.name,
                        id: lang?.language?.id,
                      };
                    })}
                    className=""
                    value={valueTab}
                    onChange={handleChangeTab}
                  />
                  {clientLanguage?.map((lang, index) => (
                    <MuiCustomTabPanel key={lang.id} value={valueTab} index={index}>
                      <InputContainer
                        name={`section_languages[${index}][name]`}
                        control={control}
                        label="name"
                        // errors={errors.name}
                      />
                    </MuiCustomTabPanel>
                  ))}
                  <div className="grid grid-cols-3 gap-5 pb-20">
                    {sectionListData?.map((s) => (
                      <div key={s?.id} className="w-full ">
                        <Card className="w-full relative">
                          <FormControl className="px-3">
                            <RadioGroup
                              row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={sectionPaired}
                              onChange={handleChangeSection}
                            >
                              <FormControlLabel value={s.id} control={<Radio />} label={s.name} />
                            </RadioGroup>
                          </FormControl>
                          <CardActions className="ml-8 gap-x-3 flex items-center">
                            <Typography gutterBottom className="" component="div">
                              {/* {sType(s?.s_type)} */}
                            </Typography>
                          </CardActions>
                          <CardActionArea>
                            <CardContent>
                              <div className="w-full p-3">
                                <img src={`${s.screenshot ?? 'https://admin.bzpublish.com/img/bz-publish.svg'}`} alt="" className="w-full h-[10vh] object-cover" />
                              </div>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </div>
                    ))}
                  </div>
                  <div
                    className={
                      fixBottom
                        ? 'flex left-0 h-20 px-5 items-center gap-x-3 mt-6 bottom-0 absolute bg-white w-full overflow-x-hidden border-t justify-between'
                        : 'my-5 flex items-center justify-between'
                    }
                  >
                    <div className=" flex items-center gap-x-5">
                      <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChange} />
                      <div className="">{`Total ${countSection}`}</div>
                    </div>
                    <div className="">
                      <BaseSubmitButton type="submit" disabled={!sectionPaired} />
                    </div>
                  </div>
                </form>
              </div>
            }
          />
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  );
}

export const toastLoading = {
  position: 'bottom-right',
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  draggable: true,
  pauseOnHover: true,
  closeButton: true,
  theme: 'colored',
};

export const toastSuccess = {
  type: 'success',
  isLoading: false,
  autoClose: 2000,
  closeButton: true,
};

export const toastError = {
  type: 'error',
  isLoading: false,
  autoClose: 2000,
  closeButton: true,
};

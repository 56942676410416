/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { CircularProgress } from '@mui/material';

import CardTaskTemplate from '../../../components/ui/Task/CardTask/CardTaskTemplate';
import { ContainerTask } from '../../../components/ui/Task/ContainerTask/ContainerTask';

import BoardsContext from './BoardsContext';
import DetailContext from '../Projects/DetailContext';
import DrawerTask from '../../../components/ui/Task/DrawerTask/DrawerTask';

import projectApi from '../../../api/task/project';
import styles from './Boards.module.css';

export const withRouter = (Components) => {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Components {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
};
class Boards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      getProject: () => {
        this.getProjectDetail();
      },
      addSectionHandler: (text, index) => {
        this.addSection(text, index);
      },
      addTaskHandler: (sectionId, text, index, callback) => {
        this.addTask(sectionId, text, index, callback);
      },
      reorderSectionHandler: (param) => {
        this.setReorderSection(param);
      },
      reorderTaskHandler: (param1, param2, isTerminate, updatedSection) => {
        this.setReorderTask(param1, param2, isTerminate, updatedSection);
      },
      deleteSectionHandler: (param) => {
        this.deleteSection(param);
      },
      deleteTaskHandler: (taskId, sectionId) => {
        this.deleteTask(taskId, sectionId);
      },
      updateTaskHandler: (taskId, task, sectionId) => {
        this.updateTask(taskId, task, sectionId);
      },
      moveTaskHandler: (fromSectionId, toSectionId, idTopic, toIdTopics, updatedSection) => {
        this.setMoveTask(fromSectionId, toSectionId, idTopic, toIdTopics, updatedSection);
      },
      openDrawerHandler: (taskId) => {
        this.openDrawer(taskId);
      },
      projects: [],
      sections: [],
      defaultSections: [],
      tasks: false,
      teams: [],
      users: [],
      drawer: false,
      filterEl: null,
      filters: {
        myTask: false,
        tTypes: [],
        tPriorities: [],
        tLabels: [],
      },

      idProject: null,
    };
  }

  componentDidMount() {
    const { isTabs } = this.props;
    const { projectId, projects, sections, tasks, teams, users } = this.context;
    this.setState({
      defaultSections: sections,
      idProject: projectId,
      projects,
      sections,
      tasks,
      teams,
      users,
      drawer: isTabs ? +isTabs : false,
    });
  }

  componentDidUpdate() {
    const myContext = this.context;
    const { idProject, projects, sections, tasks, teams, drawer } = this.state;
    const { setTriggerCloseDrawer, triggerCloseDrawer } = this.props;
    if (myContext.projects !== projects) {
      this.setState({ projects: myContext.projects });
    }
    if (myContext.sections !== sections) {
      this.setState({ sections: myContext.sections });
    }
    if (myContext.teams !== teams) {
      this.setState({ teams: myContext.teams });
    }
    if (myContext.tasks !== tasks) {
      this.setState({ tasks: myContext.tasks });
    }
    if (myContext.projectId !== idProject) {
      if (this.state.drawer) {
        this.setState({
          idProject: myContext.projectId,
          drawer: false,
        });
      } else {
        this.setState({ idProject: myContext.projectId });
      }
      myContext.dispatchProject('GET');
    }

    if (myContext.trigger.triggerRedraw && myContext.projectId === idProject && !drawer && +myContext.tabs) {
      myContext.trigger.setTriggerRedraw(false);
      this.setState({ drawer: myContext.tabs });
    }

    if (triggerCloseDrawer) {
      if (drawer) {
        this.setState({ drawer: false });
      }
      setTriggerCloseDrawer(false);
    }
  }

  getProjectDetail = () => {
    const { dispatchProject } = this.context;
    dispatchProject('GET');
  };

  addTask = (sectionId, text, index = null, callback = false) => {
    const { dispatchProject } = this.context;
    const { addSectionTopic } = projectApi();
    const { idProject } = this.state;

    const newTopic = {
      project: idProject,
      section: sectionId,
      name: text,
    };

    if (index) {
      newTopic.index = index;
    }

    addSectionTopic(newTopic)
      .then((res) => {
        if (res.data) {
          dispatchProject('GET');
          if (callback && typeof callback === 'function') {
            callback();
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  addSection = (text, index = null) => {
    const { sections } = this.state;
    const { dispatchProject } = this.context;
    const { addProjectSection } = projectApi();
    const { idProject } = this.state;
    const newSection = {
      project: idProject,
      name: text,
    };

    if (index !== null && +index >= 0) {
      newSection.index = index;
    } else {
      newSection.index = sections && sections.length > 0 ? sections.length + 1 : 0;
    }

    addProjectSection(newSection)
      .then((res) => {
        if (res.data) {
          dispatchProject('GET');
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  setReorderSection = (newSection) => {
    if (newSection) {
      const { dispatchProject, setNewSections } = this.context;
      setNewSections(newSection);
      const { idProject } = this.state;
      const { reorderProjectSection } = projectApi();
      const sectionIds = [];
      newSection.forEach((sec) => {
        if (sec && sec.id) {
          sectionIds.push(sec.id);
        }
      });
      if (sectionIds.length > 0) {
        reorderProjectSection(idProject, { section_ids: sectionIds })
          .then((res) => {
            if (res.status === 200) {
              dispatchProject('GET');
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    }
  };

  handleReorderTask = (newTasks, sectionId) => {
    const { dispatchProject } = this.context;
    const { reorderSectionTopic } = projectApi();
    const topicIds = [];
    newTasks.forEach((sec) => {
      topicIds.push(sec.id);
    });
    reorderSectionTopic(sectionId, { topic_ids: topicIds })
      .then((res) => {
        if (res.status === 200) {
          dispatchProject('GET');
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  setReorderTask = (newTasks, sectionId, isTerminate = false, updatedSection = null) => {
    if (!isTerminate) {
      if (updatedSection) {
        this.setState({ sections: updatedSection }, () => {
          this.handleReorderTask(newTasks, sectionId);
        });
      } else {
        const { sections } = this.state;
        const newSection = sections;
        const currSection = newSection.find((s) => +s.id === +sectionId);
        currSection.topics = newTasks;
        const newUpdatedSection = newSection.map((s) => {
          if (+s.id === +currSection.id) {
            return currSection;
          }
          return s;
        });
        this.setState({ sections: newUpdatedSection }, () => {
          this.handleReorderTask(newTasks, sectionId);
        });
      }
    } else {
      this.handleReorderTask(newTasks, sectionId);
    }
  };

  deleteSection = (sectionId) => {
    const { dispatchProject } = this.context;
    const { deleteProjectSection } = projectApi();

    if (sectionId) {
      const { sections } = this.state;
      this.setState({ sections: sections.filter((section) => section.id !== parseInt(sectionId, 10)) });

      deleteProjectSection(sectionId)
        .then((res) => {
          if (res.status === 204 || res.data) {
            dispatchProject('GET');
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  deleteTask = (taskId) => {
    const { dispatchProject } = this.context;
    if (taskId) {
      const { deleteSectionTopic } = projectApi();

      deleteSectionTopic(taskId)
        .then((res) => {
          if (res.status === 204) {
            dispatchProject('GET');
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  setMoveTask = (fromSectionId, toSectionId, idTopic, toIdTopics, newUpdatedSection) => {
    const { dispatchProject } = this.context;
    if (fromSectionId && toSectionId && idTopic && toIdTopics) {
      if (!newUpdatedSection) {
        const { sections } = this.state;
        const newSection = [...sections];
        const fromSection = newSection.find((s) => +s.id === +fromSectionId);
        const newFromSection = fromSection.topics.filter((s) => s.id !== idTopic);
        fromSection.topics = newFromSection;

        const toSection = [...newSection].find((s) => +s.id === +toSectionId);
        toSection.topics = toIdTopics;

        if (+fromSectionId !== +toSectionId) {
          fromSection.topics = newFromSection.filter((t) => +t.id !== +idTopic);
        }

        const updatedSections = [];
        newSection.forEach((sec) => {
          if (+sec.id === +fromSectionId) {
            updatedSections.push(fromSection);
          } else if (+sec.id === +toSectionId) {
            updatedSections.push(toSection);
          } else {
            updatedSections.push(sec);
          }
        });
        this.setState({ sections: updatedSections });
      }

      const { reorderSectionTopic } = projectApi();

      const newIdTopics = [];
      toIdTopics.forEach((topic) => {
        newIdTopics.push(topic.id);
      });
      reorderSectionTopic(toSectionId, { topic_ids: newIdTopics })
        .then((res) => {
          if (res.status === 200) {
            dispatchProject('GET');
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  updateTask = (taskId, newTask, sectionId) => {
    const { dispatchProject } = this.context;
    const { updateSectionTopic } = projectApi();
    const { idProject } = this.state;

    const dataTopic = {
      project: idProject,
      section: sectionId,
      name: newTask.text,
    };

    updateSectionTopic(taskId, dataTopic)
      .then((res) => {
        if (res.data) {
          dispatchProject('GET');
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  openDrawer = (taskId) => {
    const { urlModified } = this.context;
    const { router } = this.props;
    const newPathName = urlModified(router.location.pathname);
    if (taskId) {
      this.setState({ drawer: taskId });
      router.navigate(`${newPathName}/${taskId}`, { replace: false });
    } else {
      router.navigate(`${newPathName}/`, { replace: false });
      this.setState({ drawer: false });
    }
  };

  render() {
    const { sections, tasks, drawer } = this.state;
    const { isSidebarOpen } = this.props;
    const { isLoading } = this.context;

    const rootStyle = [styles.rootContainer];
    if (!isSidebarOpen) {
      rootStyle.push(styles.rootContainerClose);
    }

    return (
      <BoardsContext.Provider value={this.state}>
        {isLoading && (
          <div className="absolute top-1/2 left-1/2 z-10">
            <CircularProgress />
          </div>
        )}
        {sections && tasks && (
          <div className={rootStyle.join(' ')}>
            <div
              role="presentation"
              className={styles.columnContainer}
              onClick={() => {
                if (drawer) {
                  this.openDrawer();
                }
              }}
              aria-hidden
            >
              <ContainerTask />
              <CardTaskTemplate />
            </div>
            <DrawerTask open={!!drawer} idTask={drawer} onClose={() => this.openDrawer(false)} />
          </div>
        )}
      </BoardsContext.Provider>
    );
  }
}

Boards.contextType = DetailContext;

const mapStateToProps = (state) => ({
  isSidebarOpen: state.sidebar.isOpen,
  triggerCloseDrawer: state.task.triggerCloseDrawer,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setTriggerCloseDrawer: (value) => dispatch({ type: 'task/setTriggerCloseDrawer', payload: value }),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Boards));

/* eslint-disable */

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Button, ButtonBase, Card, CardActionArea, CardActions, CardContent, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { DevTool } from '@hookform/devtools';
import * as yup from 'yup';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import { Close } from '@mui/icons-material';
import InputContainer from '../../Input/InputContainer';
import SelectAutocomplete from '../../Input/SelectAutocomplete';
import ImageCropper from './ImageCropper';
import DatePickerField from '../../Input/DatePickerField';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';
import ModalWrapper from '../../ui/ModalWrapper';
import AssetCardSingle, { assetPlaceholder, renderAsset, renderAssetType } from '../../../pages/Content/Asset/AssetCardSingle';
import { handleModal } from '../../../store/slices/modalSlice';
import assetApi from '../../../api/content/asset';
import AssetDetailViewer from './AssetDetailViewer';
import TableAssetUsed from './TableAssetUsed';
import getDefaultValues from '../../../helper/getDefaultValues';
import FileContatiner from '../../Input/FileContatiner';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';
import { generateSelectListDefault } from '../../../lib/OptionsInputHelper';
import renderDate from '../../../helper/renderDate';
import renderCurrentDate from '../../../helper/renderCurrentDate';
import useGet from '../../../hooks/useGet';
import ImageCropperMulti from './ImageCropperMulti';

const headCellsArticle = [
  {
    id: 'page',
    numeric: false,
    disablePadding: true,
    label: 'Page',
  },
  {
    id: 'section',
    numeric: false,
    disablePadding: true,
    label: 'Section',
  },
  {
    id: 'article_id',
    numeric: false,
    disablePadding: false,
    label: 'Article Id',
  },
  {
    id: 'article_title',
    numeric: false,
    disablePadding: false,
    label: 'Article Tite',
  },
];

const headCellsWidget = [
  {
    id: 'page',
    numeric: false,
    disablePadding: true,
    label: 'Page',
  },
  {
    id: 'section',
    numeric: false,
    disablePadding: true,
    label: 'Section',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Widget Id',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Widget Name',
  },
];

const cropConfig = {
  unit: '%',
  x: (100 - 40) / 2,
  y: 0,
  width: 40,
  height: 100,
  aspect: 9 / 16,
};

const setDefaultArr = (arr, key = 'thumbnail') => {
  return arr.map((cl) => {
    return { id: cl.id, [key]: null };
  });
};

function slugify(text) {
  return text
    ?.toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

const getDateAndTimestamp = () => {
  // Create a new Date object
  const currentDate = new Date();

  // Get the full date
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
  const fullDate = currentDate.toLocaleDateString(undefined, dateOptions);

  // Get the timestamp
  const timestampOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
  const timestamp = currentDate.toLocaleTimeString(undefined, timestampOptions);

  const joinStamp = `${fullDate} ${timestamp} `;

  const removedPunctuation = joinStamp.replace(/[,:\s]/g, '');

  // Remove slashes from the string
  const slugifiedString = removedPunctuation.replace(/\//g, '').toLowerCase();

  // Update the state
  // setFullDate(fullDate);
  return slugifiedString;
};


export default function AssetForm({ defaultValues, onFormSubmit, mode = 'add' }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataCrop = useSelector((state) => state.assetCrud.cropData);
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);
  const clientId = useSelector((state) => state.client.activeClient);

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      ...defaultValues,
      document_date: renderDate(defaultValues?.document_date) || renderCurrentDate(defaultValues),
      asset_languages: getDefaultValues(clientLanguage, defaultValues?.asset_languages),
      categories: defaultValues?.categories?.map((cat) => cat.id),
    },
  });

  const [type, setType] = useState(defaultValues?.asset_type?.id || null);
  const [imageToCrop, setImageToCrop] = useState(defaultValues?.asset_file); //* defaultValues?.asset_file
  const [valueColor, setValueColor] = React.useState('Dark');
  const [langThumbnail, setLangThumbnail] = useState(null);

  const [assetSelectedData, setAssetSelectedData] = useState([]);
  const [checked, setChecked] = useState({
    logo: {
      isLogo: defaultValues?.set_logo_opacity || false,
      isMode: defaultValues?.logo_opacity || valueColor,
    },
    language: defaultValues?.has_languages || false,
  });

  const [selectedTabLanguage, setSelectedTabLanguage] = useState(1);

  useEffect(() => {
    setSelectedTabLanguage(clientLanguage[0].language.id);
  }, [clientLanguage]);

  const handleChangeAssetNoLang = (e) => {
    const { value } = e.target;

    setAssetSelectedData(value);
  };

  const { data: assetTypes } = useGet({
    url: `asset_types`,
    queryString: { language: 1 },
    validate: 'assetsType',
  });

  const { data: CategoriesData } = useGet({
    url: `category_assets`,
    queryString: {
      client: clientId,
      max_size: true,
    },
    validate: 'AssetCategories',
  });

  const optionsCategory = CategoriesData?.data?.results?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const handleChange = (event) => {
    setValueColor(event.target.value);
  };

  const handleChangeAssetLang = useCallback(
    (event, index) => {
      let assetsFile;
      if (event.target.value && event.target.value.length > 0) {
        assetsFile = [...assetSelectedData];
        assetsFile[index] = event.target.value;
        setAssetSelectedData(assetsFile);
      }
    },
    [assetSelectedData]
  );

  const fileSize = (size) => {
    if (size === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / k ** i).toFixed(2))} ${sizes[i]}`;
  };

  const fileType = (fileName) => {
    return fileName;
  };

  const createNewThumbAsset = async (data, isLanguage) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('client', clientId);
      formData.append('document_date', new Date().toISOString().substr(0, 10));
      formData.append('has_languages', isLanguage);
      formData.append('asset_type', 1);

      if (langThumbnail && !isLanguage) {
        formData.append('name', langThumbnail.file?.name || 'thumbnail');
        formData.append('asset_file', langThumbnail.file);
      }
      if (isLanguage && Array.isArray(langThumbnail)) {
        langThumbnail.forEach((asset, index) => {
          formData.append(`asset_languages[${index}]description`, asset.file?.name || 'thumbnail');
          formData.append(`asset_languages[${index}]language`, clientLanguage[index]?.language?.id);
          formData.append(`asset_languages[${index}]asset_file`, asset.file);
        });
      }

      const { uploadAssets } = assetApi();
      uploadAssets(formData)
        .then((res) => {
          if (res.status === 201) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.log('err', err);
          reject(err);
        });
    });
  };

  const onSubmit = async (data) => {
    console.log('🚀 ~ file: AssetForm.jsx:247 ~ onSubmit ~ data:', data);
    const formData = new FormData();

    // * checking thumbnail
    if (!checked.language && langThumbnail && langThumbnail.id) {
      formData.append('thumbnail', langThumbnail.id);
    }
    if (!checked.language && langThumbnail && !langThumbnail.id && langThumbnail.file) {
      const newThumb = await createNewThumbAsset(data, checked.language);
      if (newThumb?.id) {
        formData.append('thumbnail', newThumb.id);
      }
    }
    if (checked.language && Array.isArray(langThumbnail)) {
      if (langThumbnail.every((l) => Object.prototype.hasOwnProperty.call(l, 'file'))) {
        const newThumb = await createNewThumbAsset(data, checked.language);
        if (newThumb?.id) {
          formData.append('thumbnail', newThumb.id || '');
        }
      } else {
        formData.append('thumbnail', langThumbnail[0]?.thumbnail?.id || defaultValues?.thumbnail?.id || '');
      }
    }

    formData.append('client', clientId);
    if (checked?.language) {
      data?.asset_languages.forEach((asset, index) => {
        formData.append(`asset_languages[${index}]description`, asset.description || '');
        if (asset?.slug_name) {
          formData.append(`asset_languages[${index}]slug_name`, slugify(asset.slug_name));
        }
        formData.append(`asset_languages[${index}]language`, asset.language);
        if (checked.language) {
          if (+type !== 2 && asset?.asset_file && asset?.asset_file[0] && typeof asset?.asset_file[0] !== 'string') {
            formData.append(`asset_languages[${index}]asset_file`, asset?.asset_file[0]);
          }
          if (+type === 2 && watch(`asset_languages[${index}]asset_link`)) {
            formData.append(`asset_languages[${index}]asset_link`, asset.asset_link);
          }

          if (asset.mobile_unit === '%' && checked.language && type?.toString() === '1') {
            formData.append(`asset_languages[${index}]asset_mobile`, asset?.asset_mobile, `${asset?.description || data?.name}-mobile.png`);
            formData.append(`asset_languages[${index}]mobile_aspect`, '0.5625');
            formData.append(`asset_languages[${index}]mobile_unit`, 'px');
            formData.append(`asset_languages[${index}]mobile_height`, Math.trunc(asset.mobile_height));
            formData.append(`asset_languages[${index}]mobile_width`, Math.trunc(asset.mobile_width));
            formData.append(`asset_languages[${index}]mobile_x`, Math.trunc(asset.mobile_x));
            formData.append(`asset_languages[${index}]mobile_y`, Math.trunc(asset.mobile_y));
          }
        }
      });
    } else {
      data?.asset_languages.forEach((asset, index) => {
        formData.append(`asset_languages[${index}]description`, asset.description || '');
        formData.append(`asset_languages[${index}]language`, asset.language);
      });
    }

    if (data.categories && data.categories.length > 0) {
      data.categories?.forEach((cat) => formData.append('categories', cat));
    }

    if (data.document_date) {
      formData.append('document_date', renderDate(data.document_date) || renderCurrentDate(defaultValues));
    }
    formData.append('name', data?.name);
    if (data?.slug_name) {
      formData.append('slug_name', slugify(data?.slug_name));
    }

    formData.append('has_languages', checked?.language);

    if (data.mobile_unit === '%' && !checked.language) {
      formData.append('asset_mobile', data?.asset_mobile, `${data?.description || data?.name}${getDateAndTimestamp()}-mobile.png`);
      formData.append('mobile_aspect', '0.5625');
      formData.append('mobile_unit', 'px');
      formData.append('mobile_height', Math.trunc(data.mobile_height));
      formData.append('mobile_width', Math.trunc(data.mobile_width));
      formData.append('mobile_x', Math.trunc(data.mobile_x));
      formData.append('mobile_y', Math.trunc(data.mobile_y));
    }

    if (!checked.language && data.asset_file && data.asset_file[0] && typeof data.asset_file[0] !== 'string') {
      formData.append('asset_file', data.asset_file[0]);
    }

    formData.append('asset_type', type || defaultValues?.asset_type?.id);
    if (checked?.logo?.isLogo) {
      formData.append('set_logo_opacity', checked?.logo?.isLogo);
      formData.append('logo_opacity', checked?.logo?.isMode);
    }

    if (data.asset_link) {
      formData.append('asset_link', data.asset_link);
    }

    onFormSubmit(formData);
    // if (!defaultValues) {
    //   reset();
    // }
  };

  const options = assetTypes?.data?.results?.map((item) => ({
    name: item?.name,
    value: item?.id,
  }));

  const edAsset = defaultValues?.asset_type?.id;
  let assetName;
  if (edAsset) {
    const name = options?.filter((u) => u.value === edAsset);
    if (name) {
      const usedName = name[0];
      assetName = usedName;
    }
  }

  const handleCancel = () => {
    navigate(-1);
  };

  const onChangeAssetType = (event) => {
    const projName = event.target.innerText;
    if (projName) {
      const project = options?.filter((u) => u.name === projName);
      if (project) {
        setType(project[0]?.value);
      }
    }
  };

  const stateShowThumbnail = type && type.toString() !== '1';

  useEffect(() => {
    if (clientLanguage && checked.language && (!langThumbnail || (langThumbnail && !Array.isArray(langThumbnail)))) {
      setLangThumbnail(setDefaultArr(clientLanguage));
      setImageToCrop(setDefaultArr(clientLanguage, 'imageCrop'));
    }
  }, [clientLanguage, checked.language, langThumbnail]);

  const usedThumbnail = useCallback(
    (index) => {
      return langThumbnail && Array.isArray(langThumbnail) ? langThumbnail[index]?.thumbnail : langThumbnail;
    },
    [langThumbnail]
  );

  const usedDefaultAsset = useCallback(() => {
    const fileTab = defaultValues?.asset_languages?.filter((item) => item?.language === selectedTabLanguage);
    return defaultValues && defaultValues.has_languages ? fileTab[0] : defaultValues;
  }, [defaultValues, selectedTabLanguage]);

  const defaultValHasLanguage = useMemo(() => {
    return defaultValues && defaultValues.has_languages;
  }, [defaultValues]);

  const renderHTMLAssetFile = useCallback(
    (index) => {
      // Get First asset single Language

      const fileSingle = watch('asset_file');
      const fileSingleFirst = fileSingle && fileSingle.length > 0 && typeof fileSingle[0] !== 'string' ? URL.createObjectURL(fileSingle[0]) : defaultValues?.asset_file;
      const fileSingleFirstDetail = fileSingle && fileSingle.length > 0 && typeof fileSingle[0] !== 'string' && fileSingle[0];

      // const fileSingleMobile = watch('asset_mobile') ;
      // const fileSingleMobileFirst = fileSingleMobile && fileSingleMobile.length > 0 && typeof fileSingleMobile[0] !== 'string' ? URL.createObjectURL(fileSingleMobile[0]) : defaultValues?.asset_mobile;
      // const fileSingleMobileFirstDetail = fileSingleMobile && fileSingleMobile.length > 0 && typeof fileSingleMobile[0] !== 'string' && fileSingleMobile[0];

      // Get First asset single Language end
      // Get First asset multi Language
      const asFileMulti = watch(`asset_languages[${index}]asset_file`);
      const fileMultiFirst = (asFileMulti && asFileMulti.length > 0 && typeof asFileMulti[0] !== 'string' && URL.createObjectURL(asFileMulti[0])) || usedDefaultAsset()?.asset_file;
      const fileMultiFirstDetail = asFileMulti && asFileMulti.length > 0 && typeof asFileMulti[0] !== 'string' && asFileMulti[0];

      let fS;
      let fT;
      let fB;
      let fMd;
      let fSm;
      let fMobile;
      if (checked.language) {
        fS = fileMultiFirstDetail ? fileMultiFirstDetail?.size && fileSize(fileMultiFirstDetail?.size) : defaultValues?.file_size;
        fT = fileMultiFirstDetail ? fileType(fileMultiFirstDetail?.name) : usedDefaultAsset()?.asset_file;
        fB = fileMultiFirstDetail ? fileType(fileMultiFirstDetail?.name) : usedDefaultAsset()?.file_big;
        fMd = fileMultiFirstDetail ? fileType(fileMultiFirstDetail?.name) : usedDefaultAsset()?.file_medium;
        fSm = fileMultiFirstDetail ? fileType(fileMultiFirstDetail?.name) : usedDefaultAsset()?.file_small;
        fMobile = usedDefaultAsset()?.asset_mobile;
      } else {
        fS = fileSingleFirstDetail ? fileSize(fileSingleFirstDetail?.size) : defaultValues?.file_size;
        fT = fileSingleFirstDetail ? fileType(fileSingleFirstDetail?.name) : defaultValues?.asset_file;
        fB = fileSingleFirstDetail ? fileType(fileSingleFirstDetail?.name) : usedDefaultAsset()?.file_big;
        fMd = fileSingleFirstDetail ? fileType(fileSingleFirstDetail?.name) : usedDefaultAsset()?.file_medium;
        fSm = fileSingleFirstDetail ? fileType(fileSingleFirstDetail?.name) : usedDefaultAsset()?.file_small;
        fMobile = usedDefaultAsset()?.asset_mobile;
      }

      return (
        <div className="relative mt-6">
          {type && [2].includes(type) ? (
            <div className="flex">
              <div className="w-6/12">
                <p className="text-lg font-semibold mt-4 mb-2">File Type</p>
                <SelectAutocomplete
                  options={options}
                  name="asset_type"
                  control={control}
                  defaultValue={assetName}
                  placeHolder="Select File Type"
                  variant="outlined"
                  onChange={(event) => {
                    onChangeAssetType(event);
                  }}
                />
                <InputContainer name={checked.language ? `asset_languages[${index}][asset_link]` : 'asset_link'} control={control} label="Asset Url" />
              </div>
              {mode === 'update' && defaultValues && (
                <div className="w-6/12 ml-4">
                  <p className="text-lg font-semibold mb-2">Asset Thumbnail</p>
                  <AssetDetailViewer
                    defaultValues={defaultValues}
                    checked={checked}
                    langThumbnail={langThumbnail}
                    onAddNewImage={onAddNewImage}
                    onAddExistingImage={onAddExistingImage}
                    resetThumbnail={resetThumbnail}
                  />
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="flex">
                <div className="w-6/12">
                  <p className="text-lg font-semibold mt-4 mb-2">File Type</p>

                  <SelectAutocomplete
                    options={options}
                    name="asset_type"
                    control={control}
                    defaultValue={assetName}
                    placeHolder="Select File Type"
                    variant="outlined"
                    onChange={(event) => {
                      onChangeAssetType(event);
                    }}
                  />
                  <p className={`text-lg font-semibold ${!checked.language && 'mt-4'} mb-2`}>Asset Files</p>
                  {!checked.language && (
                    <div className="">
                      <FileContatiner name="asset_file" label="Asset File" errors="" control={control} title={defaultValues?.asset_file} />
                    </div>
                  )}
                  {defaultValues && (
                    <div className="">
                      {checked.language && (
                        <div className="">
                          <InputContainer
                            name={`asset_languages[${index}][slug_name]`}
                            control={control}
                            label="Asset File Name"
                            // errors={errors.name}
                          />

                          <div
                            onClick={() => setValue(`asset_languages[${index}][slug_name]`, slugify(watch(`asset_languages[${index}][slug_name]`)))}
                            className="border p-2 w-fit rounded cursor-pointer"
                          >
                            Slugify Asset File Name
                          </div>
                        </div>
                      )}
                      {!checked.language && (
                        <div className="mt-4">
                          <InputContainer
                            name={'slug_name'}
                            control={control}
                            label="Asset File Name"
                            // errors={errors.name}
                          />
                          <div onClick={() => setValue(`slug_name`, slugify(watch(`slug_name`)))} className="border p-2 w-fit rounded cursor-pointer">
                            Slugify Asset File Name
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="rounded border-2 border-bz-greenLight w-fit p-5 my-3">
                    <div className="text-sm font-semibold">File Information</div>
                    <div className="flex gap-1 text-sm font-semibold">
                      <span className="w-20">Size :</span>
                      <div className="w-fit text-sm font-semibold">{fS}</div>
                    </div>
                    {fileSingleFirstDetail || fileMultiFirstDetail ? (
                      <div className="text-sm font-semibold">Origin :{fT} </div>
                    ) : (
                      <>
                        <div className="flex gap-1 text-sm font-semibold">
                          <span className="w-20">Origin :</span>
                          <a href={fT} target="_blank" className="w-fit text-blue-400 font-normal">
                            {fT}
                          </a>
                        </div>
                        <div className="flex gap-1 text-sm font-semibold">
                          <span className="w-20">Big :</span>
                          <a href={fB} target="_blank" className="w-fit text-blue-400 font-normal">
                            {fB}
                          </a>
                        </div>
                        <div className="flex gap-1 text-sm font-semibold">
                          <span className="w-20">Medium :</span>
                          <a href={fMd} target="_blank" className="w-fit text-blue-400 font-normal">
                            {fMd}
                          </a>
                        </div>
                        <div className="flex gap-1 text-sm font-semibold">
                          <span className="w-20">Small :</span>
                          <a href={fSm} target="_blank" className="w-fit text-blue-400 font-normal">
                            {fSm}
                          </a>
                        </div>
                        <div className="flex gap-1 text-sm font-semibold">
                          <span className="w-20">Mobile :</span>
                          <a href={fMobile} target="_blank" className="w-fit text-blue-400 font-normal">
                            {fMobile}
                          </a>
                        </div>
                      </>
                    )}
                    {/* </div> */}
                  </div>

                  {/* </div> */}
                </div>
                {mode === 'update' && defaultValues && (
                  <div className="w-6/12 ml-4">
                    <p className="text-lg font-semibold mb-2">Asset Thumbnail</p>
                    <AssetDetailViewer
                      defaultValues={defaultValues}
                      checked={checked}
                      valueTab={valueTab}
                      langThumbnail={langThumbnail}
                      onAddNewImage={onAddNewImage}
                      onAddExistingImage={onAddExistingImage}
                      resetThumbnail={resetThumbnail}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {fileSingleFirst && !checked.language && type?.toString() === '1' && (
            <ImageCropper
              setValue={setValue}
              watch={watch}
              defaultValues={defaultValues}
              imageToCrop={defaultValues ? fileSingleFirst : fileSingle && fileSingle.length > 0 ? fileSingle[0] : ''}
            />
          )}
          {fileMultiFirst && checked.language && type?.toString() === '1' && (
            <ImageCropperMulti
              key={index}
              fMobile={fMobile}
              id={index}
              indexLang={index}
              setValue={setValue}
              watch={watch}
              defaultValues={defaultValues}
              imageToCrop={defaultValues ? fileMultiFirst : asFileMulti && asFileMulti.length > 0 ? asFileMulti[0] : ''}
            />
          )}
        </div>
      );
    },
    [usedDefaultAsset, watch, defaultValues?.asset_file, defaultValues?.asset_type?.id, defaultValues?.file_size, checked.language, type, control]
  );

  const resetThumbnail = useCallback(() => {
    if (checked.language) {
      setLangThumbnail(setDefaultArr(clientLanguage));
    } else {
      setLangThumbnail(null);
    }
  }, [checked.language, clientLanguage]);
  const renderThumbPreview = useCallback(
    (index) => {
      const usedThumb = checked.language ? usedThumbnail(index) : usedThumbnail();
      return (
        <div className="my-4">
          <Card className="relative w-[256px]">
            <CardActions className="px-4 gap-x-3 flex items-center justify-between">
              <Typography gutterBottom className="" component="div">
                {renderAssetType(usedThumbnail(index)?.asset_type)}
              </Typography>
              <ButtonBase
                onClick={() => {
                  resetThumbnail();
                }}
              >
                <Close fontSize="12" />
              </ButtonBase>
            </CardActions>
            <CardActionArea>
              {usedThumb?.asset_file ? (
                <div className="">{renderAsset(usedThumb?.asset_type, usedThumb?.asset_file)}</div>
              ) : (
                <div className="">{assetPlaceholder(usedThumb?.asset_type)}</div>
              )}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {usedThumb?.description || 'Description'}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      );
    },
    [checked.language, usedThumbnail, resetThumbnail]
  );

  const onAddExistingImage = useCallback(
    (index) => {
      dispatch(handleModal({ modalId: '', componentName: `assetThumbnail-${index}` }));
    },
    [dispatch]
  );
  const onAddNewImage = useCallback(
    (fl) => {
      if (checked.language && fl) {
        setLangThumbnail((prevState) => {
          const currState = [...prevState];
          const newState = currState.map((cr) => {
            return {
              id: cr.id,
              language: cr.language,
              thumbnail: {
                name: '',
                asset_file: URL.createObjectURL(fl[0]),
                asset_type: fl[0].type.substring(fl[0].type.lastIndexOf('/') + 1),
              },
              file: fl[0],
            };
          });
          return newState;
        });
      } else {
        setLangThumbnail({
          asset_file: URL.createObjectURL(fl[0]),
          file: fl[0],
          asset_type: fl[0].type.substring(fl[0].type.lastIndexOf('/') + 1),
        });
      }
    },
    [checked.language]
  );
  const renderHTMLThumbnail = useCallback(
    (index = 0) => {
      const usedDefaultAss = usedDefaultAsset();
      return (
        <>
          {stateShowThumbnail && (
            <div className="">
              {mode === 'add' && <p className="text-lg font-semibold mt-4 mb-2">Asset Thumbnail</p>}
              {mode === 'edit' && ((defaultValHasLanguage && usedDefaultAss.thumbnail_file) || (!defaultValHasLanguage && usedDefaultAss.thumbnail)) && (
                <div className="my-4">
                  <img src={defaultValHasLanguage ? usedDefaultAss.thumbnail_file : usedDefaultAss.thumbnail} alt="" className="" />
                </div>
              )}

              {mode === 'add' && (
                <div className="space-x-3">
                  <Button variant="outlined" onClick={() => onAddExistingImage(index)}>
                    Add Existing Image
                  </Button>

                  <Button variant="outlined" component="label">
                    Add New Image
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e) => {
                        const fl = e.target.files;
                        onAddNewImage(fl);
                      }}
                    />
                  </Button>
                </div>
              )}
            </div>
          )}
          <ModalWrapper componentName={`assetThumbnail-${index}`} header="Select Asset" maxWidth="lg">
            <AssetCardSingle
              onChangeAsset={(event) => {
                let obj = event.target.getAttribute('data-obj');
                if (obj) {
                  obj = JSON.parse(obj);
                }
                if (checked.language) {
                  handleChangeAssetLang(event, index);
                  setLangThumbnail((prevState) => {
                    const currData = prevState ? [...prevState] : [...setDefaultArr(clientLanguage)];
                    currData[index].thumbnail = obj;
                    return currData;
                  });
                } else {
                  handleChangeAssetNoLang(event);
                  setLangThumbnail(obj);
                }
              }}
              typeMode={1}
              onClick={() => dispatch(handleModal({ modalId: '' }))}
            />
          </ModalWrapper>
          {mode === 'add' &&
            ((!checked.language && langThumbnail) || (checked.language && Array.isArray(langThumbnail) && langThumbnail[0]?.thumbnail)) &&
            renderThumbPreview(index)}
        </>
      );
    },
    [
      checked.language,
      dispatch,
      handleChangeAssetLang,
      langThumbnail,
      stateShowThumbnail,
      clientLanguage,
      renderThumbPreview,
      defaultValHasLanguage,
      mode,
      onAddExistingImage,
      onAddNewImage,
      usedDefaultAsset,
    ]
  );

  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    const languageChanges = clientLanguage[newValue].language.id;
    setValueTab(newValue);
    setSelectedTabLanguage(languageChanges);
  };

  useEffect(() => {
    clientLanguage?.forEach((lang, index) => setValue(`asset_languages[${index}][language]`, lang.language.id));
  }, [clientLanguage, setValue]);

  return (
    <div className="w-full">
      <form className="w-full flex" onSubmit={handleSubmit(onSubmit)}>
        <div className={[mode === 'update' ? 'w-full' : 'w-full'].join(' ')}>
          <InputContainer
            name="name"
            control={control}
            label="Name"
            // errors={errors.name}
          />
          <div className="flex w-full gap-x-3">
            <div className="w-6/12">
              <DatePickerField
                sx={{ marginBottom: '20px', marginTop: '20px' }}
                control={control}
                name="document_date"
                label="Document Date"
                // error={!!errors.datePicker}
                // helperText={errors.datePicker?.message}
                size="small"
                fullWidth
              />
            </div>
            <div className="w-6/12 mt-5">
              <Controller
                name="categories"
                control={control}
                render={({ field: { onChange } }) => (
                  <ReactSelectMultiple
                    options={optionsCategory}
                    defaultValue={generateSelectListDefault(defaultValues?.categories)}
                    placeholder="Select Categories"
                    isMulti
                    onChange={(v) => onChange(v?.map((val) => val.value))}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex flex-col w-fit">
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={checked?.logo?.isLogo}
                  onChange={() => {
                    setChecked((prevState) => ({
                      ...prevState,
                      logo: { ...prevState.logo, isLogo: !checked?.logo?.isLogo },
                    }));
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Logo Override Color"
              labelPlacement="end"
            />
            {checked?.logo?.isLogo && (
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" onChange={handleChange}>
                <FormControlLabel value="Dark" control={<Radio />} label="Dark" />
                <FormControlLabel value="Light" control={<Radio />} label="Light" />
              </RadioGroup>
            )}

            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  // defaultChecked={defaultValues?.has_languages}
                  checked={checked?.language}
                  onChange={() => {
                    setChecked((prevState) => ({
                      ...prevState,
                      language: !checked?.language,
                    }));

                    if (checked?.language) {
                      setLangThumbnail(null);
                      setImageToCrop(null);
                    } else {
                      setLangThumbnail(setDefaultArr(clientLanguage));
                      setImageToCrop(setDefaultArr(clientLanguage, 'imageCrop'));
                    }
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Has Language"
              labelPlacement="end"
            />
          </div>

          {!checked.language && renderHTMLAssetFile()}
          {checked.language && (
            <div className="">
              <p className="text-lg font-semibold mt-4 mb-2">File Type</p>
              <SelectAutocomplete
                options={options}
                name="asset_type"
                control={control}
                defaultValue={assetName}
                placeHolder="Select File Type"
                variant="outlined"
                onChange={(event) => {
                  onChangeAssetType(event);
                }}
              />
            </div>
          )}

          <MuiCusotmTabs
            optionsTab={clientLanguage.map((lang) => {
              return {
                name: lang?.language?.name,
                id: lang?.language?.id,
              };
            })}
            value={valueTab}
            onChange={handleChangeTab}
          />
          {checked.language && (
            <div className="mt-6">
              {clientLanguage?.map((lang, index) => (
                <MuiCustomTabPanel key={lang?.language?.id} value={valueTab} index={index}>
                  {type && ![2].includes(type) && (
                    <FileContatiner
                      id={`asset_languages[${index}][asset_file]`}
                      name={`asset_languages[${index}][asset_file]`}
                      label="Asset File"
                      errors=""
                      control={control}
                      defaultValue={watch(`asset_languages[${index}][asset_file]`)}
                    />
                  )}
                  {renderHTMLAssetFile(index)}
                  <InputContainer name={`asset_languages[${index}][description]`} control={control} label="Description" />
                </MuiCustomTabPanel>
              ))}
            </div>
          )}

          {!checked.language &&
            clientLanguage?.map((lang, index) => (
              <MuiCustomTabPanel key={lang?.language?.id} value={valueTab} index={index}>
                <InputContainer name={`asset_languages[${index}][description]`} control={control} label="Description" />
              </MuiCustomTabPanel>
            ))}

          {renderHTMLThumbnail()}

          <div className="ml-auto flex gap-x-3">
            <Button size="small" onClick={handleCancel} className="bz-btn secondary ml-auto flex" type="button" variant="outlined">
              Cancel
            </Button>
            <Button size="small" className="bz-btn flex" type="submit" variant="outlined">
              {defaultValues ? 'Update Asset' : 'Upload New Asset'}
            </Button>
          </div>
        </div>
        <DevTool control={control} />
      </form>
      {defaultValues && (
        <div className="">
          <div className="pb-5 font-bold text-lg">Asset Used In :</div>
          <div>
            <div className="font-bold">Article</div>
            <TableAssetUsed dataApi={defaultValues?.asset_used_in_article} isDataLoad usedIn="article" headCells={headCellsArticle} />
          </div>
          <div>
            {/* <div className="">Asset Used In</div> */}
            <div className="font-bold">Widget</div>
            <TableAssetUsed dataApi={defaultValues?.asset_used_in_widget} isDataLoad usedIn="widget" headCells={headCellsWidget} />
          </div>
        </div>
      )}
    </div>
  );
}

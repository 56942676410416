import { createSlice } from '@reduxjs/toolkit';

const initialState = { isReactLoading: false, componentName: '', loadingMessage: '' };

const reactLoadingSlice = createSlice({
  name: 'reactLoading',
  initialState,
  reducers: {
    isReactLoading: (state, action) => {
      const currState = state;
      currState.isReactLoading = true;
      currState.componentName = action.payload.componentName;
      currState.loadingMessage = action.payload.loadingMessage;
    },
    closeLoading: (state) => {
      const currState = state;
      currState.isReactLoading = !currState.isReactLoading;
    },
  },
});

export const { isReactLoading, closeLoading } = reactLoadingSlice.actions;

export default reactLoadingSlice.reducer;

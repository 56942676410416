import axiosInstances from '../../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function CRMCustomers() {
  const { axiosInstance } = axiosInstances();

  async function getStagesCrud(params) {
    const method = 'get';
    const url = `${baseURL}application_stages/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function AddNewStage(data, params) {
    const method = 'post';
    const url = `${baseURL}application_stages/?job_poster=${params}`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function DeleteStageById(id) {
    const method = 'delete';
    const url = `${baseURL}application_stages/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function UpdateStage(id, data) {
    const method = 'patch';
    const url = `${baseURL}application_stages/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function UpdateRecruitmentStage(id, data) {
    const method = 'patch';
    const url = `${baseURL}recruitment_stages/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getApplicationList(params) {
    const method = 'get';
    const url = `${baseURL}job_posters/list_with_applications/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getApplicationDetail(params) {
    const method = 'get';
    const url = `${baseURL}applications/${params}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function UpdateStageOrder(params, data) {
    const method = 'put';
    const url = `${baseURL}application_stages/update_indexes/?job_poster=${params}`;
    const response = await axiosInstance({
      method,
      url,
      data: { application_stage_ids: data },
    });
    return response;
  }

  return {
    getStagesCrud,
    getApplicationDetail,
    AddNewStage,
    DeleteStageById,
    UpdateStage,
    getApplicationList,
    UpdateStageOrder,
    UpdateRecruitmentStage,
  };
}

import { Article, KeyboardArrowDown } from '@mui/icons-material';
import { Box, Button, ButtonBase, Fade, Popover } from '@mui/material';
import React, { useState } from 'react';
import clsxm from '../../../../utils/clsxm';

import projectApi from '../../../../api/task/project';

export default function AttachmentFiles({ file, index, setPopoverAttachments, getTopicDetails, isCRM = false, deleteAttachments = () => {} }) {
  const [isHover, setIsHover] = useState(false);
  const [anchorAction, setAnchorAction] = useState(false);

  const handleClickAction = (event) => {
    setAnchorAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorAction(null);
  };

  const handleDeleteAttachment = () => {
    const { deleteAttachment } = projectApi();
    if (file && file.id) {
      setIsHover(false);
      handleCloseAction();
      if (!isCRM) {
        deleteAttachment(file.id)
          .then(() => {
            getTopicDetails();
          })
          .catch((err) => {
            console.log('err', err);
          });
      } else {
        deleteAttachments(file.id);
      }
    }
  };

  const usedFile = file.file_attached || file.file;
  const fileExt = usedFile?.substring(usedFile.lastIndexOf('.') + 1);
  const fileName = usedFile?.substring(usedFile.lastIndexOf('/') + 1);
  const imgTypes = ['jpg', 'jpeg', 'png', 'webp', 'svg'];
  let html;
  if (!imgTypes.includes(fileExt?.toLowerCase())) {
    html = (
      <a key={usedFile} href={usedFile} target="_blank" rel="noreferrer" className="mb-2">
        <div className="relative bg-[#eaeaea] py-1 pl-2 pr-8 border border-[#eaeaea] rounded-md h-16 min-w-[64px] max-w-[192px] flex items-center shadow-md">
          <div className="flex space-x-1 items-center">
            <Article color="disabled" fontSize="large" />
            <div className="flex flex-col max-w-[112px]">
              <span className="text-ellipsis text-sm whitespace-nowrap overflow-hidden">{fileName?.substring(0, fileName.indexOf('.'))}</span>
              <span className="text-xs text-gray-500">{`${fileExt} File`}</span>
            </div>
          </div>
        </div>
      </a>
    );
  } else {
    html = (
      <a key={usedFile} href={usedFile} target="_blank" rel="noreferrer" onClick={() => setPopoverAttachments(index)} aria-hidden className="mb-2">
        <div key={file.created_at || usedFile} className="min-w-[60px] max-w-full h-16 flex justify-center overflow-hidden">
          <img className="max-w-[160px] h-full object-cover" src={usedFile} alt="" loading="lazy" />
        </div>
      </a>
    );
  }

  const openAction = Boolean(anchorAction);
  const idAction = openAction ? 'simple-popover' : undefined;

  return (
    <div
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
        handleCloseAction();
      }}
      className={clsxm("relative", "")}
    >
      {html}
      {isHover && (
        <>
          <ButtonBase
            className="absolute top-1 right-1 bg-white bg-opacity-40 z-10 rounded-md"
            onClick={(e) => {
              handleClickAction(e);
            }}
          >
            <KeyboardArrowDown className="w-6 h-6" />
          </ButtonBase>
          <a href={usedFile}
            download={fileName}
            className="absolute top-0 left-0 w-full h-16 bg-black bg-opacity-25"
            onClick={(e) => {
              if (e.currentTarget) {
                setPopoverAttachments(index);
              }
            }}
            target="_blank" rel="noreferrer"
          />
        </>
      )}
      <Popover
        id={idAction}
        open={openAction}
        anchorEl={anchorAction}
        onClose={handleCloseAction}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ transform: 'translate(-24px, 4px)' }}
      >
        <Fade in={openAction}>
          <Box style={{ minWidth: '180px', color: '#fff' }} className="relative flex flex-col p-2 space-y-2 rounded-lg bg-[#ffffff]">
            <Button className="px-2 flex justify-start text-red-500 text-xs" onClick={handleDeleteAttachment}>
              Delete Attachment
            </Button>
          </Box>
        </Fade>
      </Popover>
    </div>
  );
}

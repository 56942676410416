import React, { Suspense, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, ButtonBase, Chip, CircularProgress } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';

import Attribute from '../../../components/Modal/CRM/Attribute';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import ConfirmDialog from '../../../components/Modal/CRM/ConfirmDialog';
import { handleModal } from '../../../store/slices/modalSlice';
import crmApi from '../../../api/CRM/CRMCustomers';

const renderChips = (arr) => {
  return (
    <div className="w-full h-auto overflow-x-auto flex flex-row flex-wrap gap-2 py-2">
      {arr.map((a) => {
        const bgC = a.configuration?.color || '#fff';
        const isDarkBg = lightOrDark(bgC)
        return (
          <Chip key={`${a.name + a.index}`} label={a.name} style={{ background: bgC, color: isDarkBg ? '#fff' : '#000', border: !isDarkBg ? '1px solid rgba(0, 0, 0, 0.1)' : '' }} />
        )
      })}
    </div>
  );
};

const renderFormat = (obj) => {
  return !!obj && typeof obj === 'object' && Object.keys(obj)?.length ? (
    <div className="flex flex-col gap-2 w-full h-full py-2">
      {Object.keys(obj).map((key) => {
        const keyVal = Array.isArray(obj[key]) ? obj[key].join('|') : obj[key];
        return (
          <div key={key} className="flex gap-2 w-full overflow-hidden my-auto" title={keyVal}>
            <span>{key}: </span>
            <span className="text-ellipsis truncate">{keyVal}</span>
          </div>
        );
      })}
    </div>
  ) : null;
};

const { getCRMProjectAttribute, actionCRMProjectAttribute } = crmApi();

export default function TableAttribute() {
  const dispatch = useDispatch();
  const activeClient = useSelector((state) => state.client.activeClient);
  const isModal = useSelector((state) => state.modal);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmOpen, setConfirmOpen] = useState(null);
  const [attributeSelected, setSelectedAttribute] = useState(null);

  const {
    data: dataAttribute,
    isFetching: isFetchingAttribute,
    refetch,
  } = useQuery({
    queryKey: ['crm-project-attributes', activeClient],
    queryFn: () => getCRMProjectAttribute({ client: activeClient, max_size: true }),
    refetchOnWindowFocus: false,
  });

  const openModalAttribute = () => {
    dispatch(handleModal({ modalId: '', componentName: 'Attribute' }));
  };

  const onDeleteClick = (param) => {
    actionCRMProjectAttribute(param.id, 'delete')
      .then(() => refetch())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!isModal.isOpen && attributeSelected) {
      setSelectedAttribute(null);
    }
  }, [isModal.isOpen, attributeSelected]);

  const renderAction = (dt) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase
          className="bz-btn-tb"
          onClick={() => {
            setSelectedAttribute(dt);
            openModalAttribute();
          }}
        >
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => setConfirmOpen(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'value_type',
      headerName: 'Type',
      maxWidth: 96,
      renderCell: (params) => (
        <div className="flex h-[52px]">
          <span className="m-auto">{params.row.value_type}</span>
        </div>
      ),
    },
    {
      field: 'format',
      headerName: 'Format',
      flex: 1,
      renderCell: (params) => (params.row.format ? renderFormat(params.row.format) : null),
    },
    {
      field: 'is_required',
      headerName: 'Required',
      maxWidth: 96,
    },
    {
      field: 'options',
      headerName: 'Options',
      sortable: false,
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (params.row.options ? renderChips(params.row.options) : null),
    },
    {
      field: 'on_project_list',
      headerName: 'Show',
      maxWidth: 96,
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => renderAction(params.row),
    },
  ];

  return (
    <Suspense fallback={<CircularProgress />}>
      <button type="button" className="flex ml-auto gap-x-2 items-center text-center btn rounded border-0 mb-4 px-4 h-10 bg-[#2C6D47]" onClick={openModalAttribute}>
        <AddIcon className="text-white" />
        <p className="text-white">Add Attribute</p>
      </button>
      <DataGrid
        autoHeight
        rows={dataAttribute || []}
        columns={columns}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        rowsPerPageOptions={[10, 20, 50]}
        loading={isFetchingAttribute}
        getRowHeight={() => 'auto'}
      />
      <ConfirmDialog title="Delete Attribute" open={!!confirmOpen} setOpen={setConfirmOpen} onConfirm={() => onDeleteClick(confirmOpen)}>
        Are you sure you want to delete this attribute?
      </ConfirmDialog>
      <ModalWrapper componentName="Attribute" header={`${!attributeSelected ? 'Add New' : 'Update'} Attribute`} maxWidth="sm">
        <Attribute dataAttribute={dataAttribute} attributeSelected={attributeSelected} refetch={refetch} />
      </ModalWrapper>
    </Suspense>
  );
}

function lightOrDark(color) {
  // Check the format of the color, HEX or RGB?
  let r, g, b
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 0;
  } else {
    return 1;
  }
}

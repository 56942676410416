import React from 'react';
import { Box, Button, Divider, Fade, FormControl, FormHelperText, Modal, Stack, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid transparent',
  boxShadow: 24,
  p: 4,
  outline: 'none',
};

const initialState = { sitemapPath: '' };

const baseSchema = yup.object().shape({ sitemapPath: yup.string().required('Periodicity is a required field').nullable() });

export default function SubmitSitemap(props) {
  const { open, close, submitSitemap } = props;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(baseSchema),
    defaultValues: initialState,
  });

  const onCloseHandler = () => {
    close();
    reset(initialState);
  };

  const onSubmit = (line) => {
    const { sitemapPath } = line;
    submitSitemap(sitemapPath).then((res) => console.log(res));
  };

  return (
    <>
      <Modal open={!!open} onClose={onCloseHandler} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Fade in={!!open}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Sitemap
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
              <div className="flex flex-col space-y-4 mt-4">
                <FormControl>
                  <Controller
                    control={control}
                    name="sitemapPath"
                    render={({ field: { onChange, value, ref } }) => (
                      <>
                        <TextField
                          labelId="text-sitemapPath"
                          inputRef={ref}
                          value={value}
                          label="Sitemap Path"
                          variant="outlined"
                          onChange={onChange}
                          error={!!errors.sitemapPath?.message}
                        />
                        <FormHelperText className="text-red-600">{errors.sitemapPath?.message}</FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
              </div>
              <Divider className="mt-4" />
              <Stack direction="row" spacing={2} className="mt-5 float-right">
                <Button variant="outlined" onClick={onCloseHandler}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </Stack>
            </form>
          </Box>
        </Fade>
      </Modal>
      <DevTool control={control} />
    </>
  );
}

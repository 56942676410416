/* eslint-disable */

import qs from 'qs';
import request from '../helper/axiosUtil';


export const fetchFilterApplication = async ({ queryKey }) => {
  const transQuery = queryKey[1]?.queryKey;
  const url = `/applications${qs.stringify(transQuery, { addQueryPrefix: true })}`;
  return request({ url });
};

export const fetchFilterApplicant = async ({ queryKey }) => {
  const transQuery = queryKey[1]?.queryKey;
  const url = `/applicants${qs.stringify(transQuery, { addQueryPrefix: true })}`;
  return request({ url });
};

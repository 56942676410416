/* eslint-disable */

import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

function InputNumericWithThousandSeparator({
  name,
  disabled,
  control,
  label,
  errors,
  defaultValue = '',
  hidden,
  config = {},
  onFocus,
  placeholder,
  autoFocus,
  className,
  rows,
  setValue,
  readOnly = false,
  maxDecimalPlaces = 2,
}) {
  const formatNominal = (value) => {
    if (value === null || value === undefined) {
      return '';
    }

    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  useEffect(() => {
    if (setValue) setValue();
  }, [setValue]);

  const handleChange = (e, field) => {
    let rawValue = e.target.value.replace(/,/g, '');
    rawValue = rawValue.replace(/[^0-9.]/g, '');

    const parts = rawValue.split('.');
    if (parts[1]?.length > maxDecimalPlaces) {
      parts[1] = parts[1].slice(0, maxDecimalPlaces);
    }

    field.onChange(parts.join('.'));
  };

  return (
    <div className={`z-index-label ${className} ${hidden ? 'hidden' : ''}`}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextField
            fullWidth
            autoFocus={autoFocus}
            className={className}
            size="small"
            sx={{ textTransform: 'capitalize', marginTop: '4px', background: 'white' }}
            value={formatNominal(field.value || '')}
            placeholder={placeholder}
            onFocus={onFocus}
            hidden={hidden}
            disabled={disabled}
            multiline={Boolean(rows)}
            rows={rows}
            margin="normal"
            error={Boolean(errors)}
            helperText={errors?.message}
            onChange={(e) => handleChange(e, field)}
            {...config}
            // {...field}
          />
        )}
      />
    </div>
  );
}

export default InputNumericWithThousandSeparator;

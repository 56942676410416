import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export default function BasePromptDialog(props) {
  const { open, title, content, action, close, buttonText } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    close();
  };

  const handleAction = () => {
    action();
    handleClose();
  };

  useEffect(() => {
    if (open && !isOpen) {
      setIsOpen(open);
    }
  }, [open, isOpen]);

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle className="capitalize">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete &nbsp;
          <strong>{content}?</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className='text-bz-green'>{buttonText?.cancel}</Button>
        <Button onClick={handleAction} autoFocus variant="contained" className='bz-btn'>
          {buttonText?.yes}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BasePromptDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  action: PropTypes.func,
  close: PropTypes.func,
  buttonText: PropTypes.shape({
    cancel: PropTypes.string,
    yes: PropTypes.string,
  }),
};
BasePromptDialog.defaultProps = {
  open: false,
  title: '',
  content: '',
  action: () => {},
  close: () => {},
  buttonText: {
    cancel: 'No',
    yes: 'Yes',
  },
};

import useTokenBearer from '@/hooks/useTokenBearer';
import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';

const baseURL = import.meta.env.VITE_API_URL;
const token = Cookies.get('bzKey');
const axiosInstance = axios.create({
  baseURL,
  headers: {
    Authorization: `${useTokenBearer()} ${token}`,
    'Content-Type': 'application/json',
  },
});

function accounting() {
  // ACCOUNT GROUPS
  async function getAccountGroups(params) {
    const method = 'get';
    const url = `${baseURL}accounting/account_groups/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addAccountGroup(data) {
    const method = 'post';
    const url = `${baseURL}accounting/account_groups/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteAccountGroup(accountGroupId) {
    const method = 'delete';
    const url = `${baseURL}accounting/account_groups/${accountGroupId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function updateAccountGroup(accountGroupId, data) {
    const method = 'patch';
    const url = `${baseURL}accounting/account_groups/${accountGroupId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  // ACCOUNTS TYPE
  async function changeAccountTypes(actions, data, params) {
    const method = 'put';
    const url = `${baseURL}accounting/account_types/${actions}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
      params,
    });
    return response;
  }
  async function getAccountTypes(params) {
    const method = 'get';
    const url = `${baseURL}accounting/account_types/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addAccountType(data) {
    const method = 'post';
    const url = `${baseURL}accounting/account_types/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteAccountType(accountId) {
    const method = 'delete';
    const url = `${baseURL}accounting/account_types/${accountId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function updateAccountType(accountId, data) {
    const method = 'patch';
    const url = `${baseURL}accounting/account_types/${accountId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  // ACCOUNTS
  async function getAccounts(params) {
    const method = 'get';
    const url = `${baseURL}accounting/accounts/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addAccount(data) {
    const method = 'post';
    const url = `${baseURL}accounting/accounts/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteAccount(accountId) {
    const method = 'delete';
    const url = `${baseURL}accounting/accounts/${accountId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function updateAccount(accountId, data) {
    const method = 'patch';
    const url = `${baseURL}accounting/accounts/${accountId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  // COST CENTER
  async function getCostCenters(params) {
    const method = 'get';
    const url = `${baseURL}accounting/cost_centers/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addCostCenter(data) {
    const method = 'post';
    const url = `${baseURL}accounting/cost_centers/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteCostCenter(ccId) {
    const method = 'delete';
    const url = `${baseURL}accounting/cost_centers/${ccId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function updateCostCenter(ccId, data) {
    const method = 'patch';
    const url = `${baseURL}accounting/cost_centers/${ccId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  // COUNTERPARTS
  async function getCounterparts(params) {
    const method = 'get';
    const url = `${baseURL}accounting/counterparts/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addCounterpart(data) {
    const method = 'post';
    const url = `${baseURL}accounting/counterparts/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteCounterpart(cpId) {
    const method = 'delete';
    const url = `${baseURL}accounting/counterparts/${cpId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function updateCounterpart(cpId, data) {
    const method = 'patch';
    const url = `${baseURL}accounting/counterparts/${cpId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  // ACCOUNTING PERIODS
  async function getAccountingPeriods(params) {
    const method = 'get';
    const url = `${baseURL}accounting/accounting_periods/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addAccountingPeriod(data) {
    const method = 'post';
    const url = `${baseURL}accounting/accounting_periods/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteAccountingPeriod(cpId) {
    const method = 'delete';
    const url = `${baseURL}accounting/accounting_periods/${cpId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function updateAccountingPeriod(cpId, data) {
    const method = 'patch';
    const url = `${baseURL}accounting/accounting_periods/${cpId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }
  async function getAccountingPeriodsLStatus(params) {
    const method = 'get';
    const url = `${baseURL}accounting/accounting_periods/list_statuses/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function setAccountingPeriodsOpenClose(financialYear, data) {
    const method = 'put';
    const url = `${baseURL}accounting/accounting_periods/${financialYear}/update_status_bulk/`;
    const response = await axiosInstance({
      method,
      url,
      data
    });
    return response;
  }

  async function getAccountingPeriodStatus(params) {
    const method = 'get';
    const url = `${baseURL}accounting/accper_statuses/`;
    const response = await axiosInstance({
      method,
      url,
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return response;
  }

  // PNL/BS GROUPS
  async function changePNLGroups(actions, data, params) {
    const method = 'put';
    const url = `${baseURL}accounting/pnl_bs_groups/${actions}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
      params,
    });
    return response;
  }
  async function getPNLGroups(params) {
    const method = 'get';
    const url = `${baseURL}accounting/pnl_bs_groups/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addPNLGroup(data) {
    const method = 'post';
    const url = `${baseURL}accounting/pnl_bs_groups/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deletePNLGroup(pnlGroupId) {
    const method = 'delete';
    const url = `${baseURL}accounting/pnl_bs_groups/${pnlGroupId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function updatePNLGroup(pnlGroupId, data) {
    const method = 'patch';
    const url = `${baseURL}accounting/pnl_bs_groups/${pnlGroupId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  // PNL/BS CATEGORIES
  async function getPNLCategories(params) {
    const method = 'get';
    const url = `${baseURL}accounting/pnl_bs_categories/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addPNLCategory(data) {
    const method = 'post';
    const url = `${baseURL}accounting/pnl_bs_categories/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deletePNLCategory(pnlCateId) {
    const method = 'delete';
    const url = `${baseURL}accounting/pnl_bs_categories/${pnlCateId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function updatePNLCategory(pnlCateId, data) {
    const method = 'patch';
    const url = `${baseURL}accounting/pnl_bs_categories/${pnlCateId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  // ACCOUNTING CODES
  async function getAccountCodes(params) {
    const method = 'get';
    const url = `${baseURL}accounting/account_codes/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addAccountCode(data) {
    const method = 'post';
    const url = `${baseURL}accounting/account_codes/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteAccountCode(codeiId) {
    const method = 'delete';
    const url = `${baseURL}accounting/account_codes/${codeiId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function updateAccountCode(codeiId, data) {
    const method = 'patch';
    const url = `${baseURL}accounting/account_codes/${codeiId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  // ACCOUNTING PERIODS
  async function getCurrencies(params) {
    const method = 'get';
    const url = `${baseURL}currencies/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addCurrency(data) {
    const method = 'post';
    const url = `${baseURL}currencies/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteCurrency(cpId) {
    const method = 'delete';
    const url = `${baseURL}currencies/${cpId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function updateCurrency(cpId, data) {
    const method = 'patch';
    const url = `${baseURL}currencies/${cpId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  // BUSINESS UNIT
  async function getBusinessUnits(params) {
    const method = 'get';
    const url = `${baseURL}accounting/business_units/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function getListBusinessUnit(params) {
    const method = 'get';
    const url = `${baseURL}accounting/business_units/list_names/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function getBusinessUnit(idBusinessUnit) {
    const method = 'get';
    const url = `${baseURL}accounting/business_units/${idBusinessUnit}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function addBusinessUnit(data) {
    const method = 'post';
    const url = `${baseURL}accounting/business_units/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }
  async function updateBusinessUnit(idBusinessUnit, data) {
    const method = 'patch';
    const url = `${baseURL}accounting/business_units/${idBusinessUnit}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }
  async function deleteBusinessUnit(idBusinessUnit) {
    const method = 'delete';
    const url = `${baseURL}accounting/business_units/${idBusinessUnit}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  // PAYMENT TERMS
  async function getPaymentTerms(params) {
    const method = 'get';
    const url = `${baseURL}accounting/payment_terms/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  // MEMBERS
  async function getMembers(params) {
    const method = 'get';
    const url = `${baseURL}crm/members/get_names/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  // LINE DETAIL
  async function updateLineDetail(lineId, data) {
    const method = 'patch';
    const url = `${baseURL}accounting/line_details/${lineId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  // FINANCIAL YEARS
  async function getFinancialYears(params) {
    const method = 'get';
    const url = `${baseURL}accounting/financial_years/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }
  async function actionFinancialYears(mode, data, id) {
    let method = 'post';
    if (mode === 'update') {
      method = 'patch'
    }
    let url = `${baseURL}accounting/financial_years/`;
    if (mode === 'update') {
      url += `${id}/`
    }
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }
  async function deleteFinancialYears(id) {
    const method = 'delete';
    const url = `${baseURL}accounting/financial_years/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  // PROJECT IDS
  async function getProjectIds(params) {
    const method = 'get';
    const url = `${baseURL}accounting/project_ids/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  // JOURNAL SOURCES
  async function getJournalSources(params) {
    const method = 'get';
    const url = `${baseURL}accounting/journal_sources/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  // LEDGERS
  async function actionLedgers(mode, data, id) {
    let method = 'post';
    if (mode === 'update') {
      method = 'patch'
    }
    let url = `${baseURL}accounting/ledgers/`;
    if (mode === 'update') {
      url += `${id}/`
    }
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }
  async function deleteLedger(id) {
    const method = 'delete';
    const url = `${baseURL}accounting/ledgers/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  return {
    getAccountGroups,
    addAccountGroup,
    deleteAccountGroup,
    updateAccountGroup,
    changeAccountTypes,
    getAccountTypes,
    addAccountType,
    deleteAccountType,
    updateAccountType,
    getAccounts,
    addAccount,
    deleteAccount,
    updateAccount,
    getCostCenters,
    addCostCenter,
    deleteCostCenter,
    updateCostCenter,
    getCounterparts,
    addCounterpart,
    deleteCounterpart,
    updateCounterpart,
    getPNLGroups,
    addPNLGroup,
    deletePNLGroup,
    updatePNLGroup,
    changePNLGroups,
    getPNLCategories,
    addPNLCategory,
    deletePNLCategory,
    updatePNLCategory,
    getAccountingPeriods,
    addAccountingPeriod,
    deleteAccountingPeriod,
    updateAccountingPeriod,
    getAccountingPeriodStatus,
    getAccountingPeriodsLStatus,
    setAccountingPeriodsOpenClose,
    getCurrencies,
    addCurrency,
    deleteCurrency,
    updateCurrency,
    getBusinessUnits,
    getListBusinessUnit,
    getBusinessUnit,
    addBusinessUnit,
    updateBusinessUnit,
    deleteBusinessUnit,
    getPaymentTerms,
    getMembers,
    getAccountCodes,
    addAccountCode,
    deleteAccountCode,
    updateAccountCode,
    updateLineDetail,
    getFinancialYears,
    actionFinancialYears,
    deleteFinancialYears,
    getProjectIds,
    getJournalSources,
    actionLedgers,
    deleteLedger
  };
}

export default accounting;

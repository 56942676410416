import React, { Suspense, useContext } from 'react';
import DetailContext from '../../Projects/DetailContext';
import TopicList from './TopicList';

export default function List() {
  const detailContext = useContext(DetailContext);
  const { sections } = detailContext;

  return (
    <Suspense fallback={<div>loading</div>}>
      <div className="w-full h-[calc(100vh_-_140px)] overflow-y-auto flex flex-col px-6 py-6">
        <div className="w-full h-6 px-3 flex items-center mb-4">
          <div className="w-2/5 flex border-r font-bold pl-2">
            <div className="w-20 border-r">Type</div>
            <div className="w-auto px-2">Topic</div>
          </div>
          <div className="w-3/5 border-r flex">
            <div className="w-2/5 border-r px-2 font-bold">Status</div>
            <div className="w-1/4 border-r px-2 font-bold">Assignee</div>
            <div className="w-1/3 flex px-2 font-bold">
              <div className="w-1/2 border-r">Due date</div>
              <div className="w-1/2 px-2">Label</div>
            </div>
          </div>
        </div>
        {sections.map((s) => (
          <TopicList key={s.id} section={s} />
        ))}
      </div>
    </Suspense>
  );
}

// /* eslint-disable */
import Close from '@mui/icons-material/Close';
import { Avatar } from '@mui/material';
import React from 'react';

import Select, { components } from 'react-select';
import { Controller } from 'react-hook-form';

const { Placeholder } = components;

function CustomClear(props) {
  const { setValue } = props;

  return (
    <button type="button" onClick={() => setValue(null)}>
      <Close className="h-[20px] w-[20px] text-[#6d6e6f] hover:text-black" />
    </button>
  );
}

function OptionIcon(props) {
  const { label, data, isSelected, ...otherProps } = props;

  return (
    <div>
      <components.Option {...otherProps} className="flex items-center">
        <Avatar alt={label} src={data?.avatar} />
        <input className="invisible" type="checkbox" checked={isSelected} onChange={() => null} />
        <label>{label}</label>
      </components.Option>
    </div>
  );
}

function CustomValueContainer({ children, ...props }) {
  return (
    <components.ValueContainer {...props}>
      <div className="flex items-center space-x-1">
        <Avatar className="ml-1 h-5 w-5" alt={props?.selectProps?.value?.label} src={props?.selectProps?.value?.avatar} />
        <Placeholder
          // className={`${errors && 'text-[#d32f2f] '}`}
          {...props}
          isFocused={props.isFocused}
        >
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
      </div>
    </components.ValueContainer>
  );
}

export default function ReactSelectSingleIcon({
  options = [],
  defaultValue = null,
  getOptionLabel,
  getOptionValue,
  placeholder,
  isSearchable,
  errors,
  control,
  name,
  isClearable = false,
}) {
  const component = {
    Option: OptionIcon,
    ValueContainer: CustomValueContainer,
    ClearIndicator: CustomClear,
  };

  return (
    <div className=" w-full">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <Select
              className={`${errors && 'border-[1px] border-[#d32f2f] rounded-[4px]'}`}
              menuPosition="fixed"
              defaultValue={defaultValue}
              menuPlacement="auto"
              hideSelectedOptions={false}
              options={options}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onChange={(v) => onChange(v.value)}
              components={component}
              isSearchable={isSearchable}
              isClearable={isClearable}
              errors={Boolean(errors)}
              isDisable={false}
              placeholder={placeholder}
              renderValue={(selected) => selected.join(' ')}
              styles={{
                container: (provided) => ({
                  ...provided,
                  // marginTop: 50,
                  border: '1px, solid, red',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  overflow: 'visible',
                  border: '1px, solid, red',
                }),
                placeholder: (provided, state) => ({
                  ...provided,
                  border: '1px, solid, red',
                  position: 'absolute',
                  top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
                  transition: 'top 0.1s, font-size 0.1s',
                  fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
                  background: 'white',
                  margin: '0px 20',
                }),
              }}
            />
          );
        }}
      />
    </div>
  );
}

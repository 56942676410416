import React from 'react';
import { Box, Button, Divider, Fade, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, Stack, Typography } from '@mui/material';
import { useForm, useWatch, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import _ from 'lodash';

import siteScheduleApi from '../../../../../api/siteSchedules';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid transparent',
  boxShadow: 24,
  p: 4,
  outline: 'none',
};

const initialState = { periodicity: '', day: '', at: '', timezone: '' };

const baseSchema = yup.object().shape({
  periodicity: yup.string().required('Periodicity is a required field').nullable(),
  at: yup.number().required('Time is a required field').nullable(),
  timezone: yup.string().required('Timezone is a required field').nullable(),
});

export default function AddSchedule(props) {
  // const { open, close, data } = props;
  const { open, close, pages, site, getAllSiteSchedules } = props;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(baseSchema),
    defaultValues: initialState,
  });

  const selectedPeriodicity = useWatch({ control, name: 'periodicity' });

  if (selectedPeriodicity === 'weekly') {
    baseSchema.shape({ day: yup.number().nullable().optional() });
  }

  const timezoneOffset = () => {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    return offset / 60;
  };

  const getTimezone = () => {
    const timezone = timezoneOffset() * -1;
    return timezone >= 0 ? `+${timezone}` : timezone;
  };

  const onCloseHandler = () => {
    close();
    reset(initialState);
  };

  const onSubmit = (line) => {
    const { periodicity, day, at, timezone } = line;
    const timeOffset = timezoneOffset();
    let cron = ['*', '*', '*', '*', '*']; // [min, hour, day, month, day of week]

    cron[0] = 0;

    if (periodicity && timezone) {
      let hour = null;
      if (timezone === 'local') {
        hour = parseInt(at, 10) + timeOffset;
      } else if (timezone === 'utc') {
        hour = parseInt(at, 10);
      }
      let dayOffset = 0;
      if (hour < 0) {
        hour += 24;
        dayOffset -= 1;
      } else if (hour > 23) {
        hour -= 24;
        dayOffset += 1;
      }

      if (periodicity === 'daily') {
        cron[1] = hour;
      } else if (periodicity === 'weekly') {
        let correctedDay = parseInt(day, 10) + dayOffset;
        if (correctedDay < 0) {
          correctedDay += 7;
        } else if (correctedDay > 6) {
          correctedDay -= 7;
        }

        cron[1] = hour;
        cron[4] = correctedDay;
      }
    }

    cron = cron.join(' ');

    const data = {
      cron,
      pages,
      site,
    };

    siteScheduleApi()
      .addSiteSchedule(data)
      .then(() => {
        onCloseHandler();
        getAllSiteSchedules();
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Modal open={!!open} onClose={onCloseHandler} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Fade in={!!open}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Schedule
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
              <div className="flex flex-col space-y-4 mt-4">
                <FormControl>
                  <Controller
                    control={control}
                    name="periodicity"
                    render={({ field: { onChange, value, ref } }) => (
                      <>
                        <InputLabel sx={{ top: '-5px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } }} id="select-periodicity">
                          Periodicity
                        </InputLabel>
                        <Select
                          sx={{
                            pt: 0.5,
                            pb: 1,
                            border: 0,
                            '& label': { display: 'none' },
                          }}
                          labelId="select-periodicity"
                          inputRef={ref}
                          value={value}
                          label="Periodicity"
                          onChange={onChange}
                          error={!!errors.periodicity?.message}
                        >
                          <MenuItem value="">
                            <em>Periodicity</em>
                          </MenuItem>
                          <MenuItem value="daily">Daily</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                        </Select>
                        <FormHelperText className="text-red-600">{errors.periodicity?.message}</FormHelperText>
                      </>
                    )}
                  />
                </FormControl>

                {selectedPeriodicity === 'weekly' && (
                  <FormControl>
                    <Controller
                      control={control}
                      name="day"
                      render={({ field: { onChange, value, ref } }) => (
                        <>
                          <InputLabel sx={{ top: '-5px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } }} id="select-day">
                            Day
                          </InputLabel>
                          <Select
                            sx={{
                              pt: 0.5,
                              pb: 1,
                              border: 0,
                              '& label': { display: 'none' },
                            }}
                            labelId="select-day"
                            inputRef={ref}
                            value={value}
                            label="Day"
                            onChange={onChange}
                            error={!!errors.day?.message}
                          >
                            <MenuItem value="">
                              <em>Day</em>
                            </MenuItem>
                            <MenuItem value="0">Sunday</MenuItem>
                            <MenuItem value="1">Monday</MenuItem>
                            <MenuItem value="2">Tuesday</MenuItem>
                            <MenuItem value="3">Wednesday</MenuItem>
                            <MenuItem value="4">Thursday</MenuItem>
                            <MenuItem value="5">Friday</MenuItem>
                            <MenuItem value="6">Saturday</MenuItem>
                          </Select>
                          <FormHelperText className="text-red-600">{errors.day?.message}</FormHelperText>
                        </>
                      )}
                    />
                  </FormControl>
                )}

                <FormControl>
                  <Controller
                    control={control}
                    name="timezone"
                    render={({ field: { onChange, value, ref } }) => (
                      <>
                        <InputLabel sx={{ top: '-5px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } }} id="select-at">
                          Timezone
                        </InputLabel>
                        <Select
                          sx={{
                            pt: 0.5,
                            pb: 1,
                            border: 0,
                            '& label': { display: 'none' },
                          }}
                          labelId="select-timezone"
                          inputRef={ref}
                          value={value}
                          label="Timezone"
                          onChange={onChange}
                          error={!!errors.at?.message}
                        >
                          <MenuItem value="">
                            <em>Timezone</em>
                          </MenuItem>
                          <MenuItem value="local">Local ({getTimezone()})</MenuItem>
                          <MenuItem value="utc">UTC</MenuItem>
                        </Select>
                        <FormHelperText className="text-red-600">{errors.timezone?.message}</FormHelperText>
                      </>
                    )}
                  />
                </FormControl>

                <FormControl>
                  <Controller
                    control={control}
                    name="at"
                    render={({ field: { onChange, value, ref } }) => (
                      <>
                        <InputLabel sx={{ top: '-5px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } }} id="select-at">
                          At
                        </InputLabel>
                        <Select
                          sx={{
                            pt: 0.5,
                            pb: 1,
                            border: 0,
                            '& label': { display: 'none' },
                          }}
                          labelId="select-at"
                          inputRef={ref}
                          value={value}
                          label="At"
                          onChange={onChange}
                          error={!!errors.at?.message}
                        >
                          <MenuItem value="">
                            <em>At</em>
                          </MenuItem>
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((e) => (
                            <MenuItem key={e} value={e}>
                              {_.padStart(e, 2, '0')}:00
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText className="text-red-600">{errors.at?.message}</FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
              </div>
              <Divider className="mt-4" />
              <Stack direction="row" spacing={2} className="mt-5 float-right">
                <Button variant="outlined" onClick={onCloseHandler}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </Stack>
            </form>
          </Box>
        </Fade>
      </Modal>
      <DevTool control={control} />
    </>
  );
}

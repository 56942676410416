import moment from 'moment';
import { Chart } from 'react-google-charts';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Box } from '@mui/material';

function colorGenerator(length) {
  const colors = [];
  const max = 255;

  for (let i = 0; i < length; i++) {
    colors.push([`rgba(${Math.floor(Math.random() * max)}, ${Math.floor(Math.random() * max)}, ${Math.floor(Math.random() * max)}, `]);
  }

  return function (opacity) {
    return colors.map((color) => `${color + opacity})`);
  };
}


const defaultColors = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099"]

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

function DashboardDesign({ allSummary, allDepositSummary }) {
  const globalOptions = {
    isStacked: true,
    legend: { position: 'top', alignment: 'center', maxLines: 3 },
    bar: { groupWidth: '75%' },
    chartArea: { width: '72%' },
    colors: defaultColors,
  };

  const generateTooltip = (date, TransactionTypesOrder, item) => {
    const totalSalesAmount = item?.orders.reduce((acc, order) => acc + (order.sales_amount_sum || 0), 0);

    return (
      `<div style="width: 250px; border-radius: 0.25rem; font-size: 14px;">
        <div style="width: 100%; padding: 4px; border-bottom: 1px solid #DDDDDD">
          <p style="font-weight: 600">${date}</p>
        </div>
        <div style="width: 100%; padding: 4px">
          ${TransactionTypesOrder.map((type, idx) => {
            const amount = item?.orders.find(dt => dt.type === type)?.sales_amount_sum || 0;
            const color = defaultColors[idx % defaultColors.length];
            return (
              `<p>
                <span style="display: inline-block; width: 8px; height: 8px; background-color: ${color}; margin-right: 5px;"></span>
                ${type} : <span style="font-weight: bold;">${amount.toLocaleString()}</span>
              </p>`
            );
          }).join('')}
        </div>
        <div style="width: 100%; padding: 4px">
          <p>Total: <span style="font-weight: bold;">${totalSalesAmount.toLocaleString()}</span></p>
        </div>
      </div>`
    )
  }

  //TRANSACTION TYPE
  const transactionTypeData = Array.isArray(allSummary) && allSummary?.length > 0 ? allSummary?.filter((dt) => dt.group === 'Transaction type')?.[0]?.data : [];

  const TransactionTypesOrder =
    transactionTypeData?.length > 0 ? Array.from(new Set(transactionTypeData.flatMap((item) => item.orders.map((order) => order.type)))).filter((dt) => dt !== 'Deposit') : [];

  const transactionTypeChart = [
    ['Date', {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}, ...TransactionTypesOrder],
    ...transactionTypeData.map((item) => {
      const row = [item.serving_date, generateTooltip(item.serving_date, TransactionTypesOrder, item)];
      TransactionTypesOrder.forEach((type) => {
        const order = item.orders.find((order) => order.type === type);
        row.push(order ? order.sales_amount_sum : 0);
      });
      return row;
    }),
  ];

  const transactionTypeOptions = {
    hAxis: { title: '', format: 'yyyy-MM-dd' },
    vAxis: { title: 'Sales Amount' },
    focusTarget: 'category',
    tooltip: { isHtml: true },
    ...globalOptions,
  };

  console.log({transactionTypeOptions});
  
  // ===========================================================

  //PAYMENT TYPE
  const PaymentTypesData = Array.isArray(allSummary) && allSummary?.length > 0 ? allSummary?.find((dt) => dt.group === 'Payment type')?.data : [];

  const paymentTypesOrder = PaymentTypesData?.length > 0 ? Array.from(new Set(PaymentTypesData.flatMap((item) => item.orders.map((order) => order.payment_type__name)))) : [];

  const PaymentTypeChart = [
    ['Date', ...paymentTypesOrder],
    ...PaymentTypesData.map((item) => {
      const row = [item.serving_date];
      paymentTypesOrder.forEach((type) => {
        const order = item.orders.find((order) => order.payment_type__name === type);
        row.push(order ? order.sales_amount_sum : 0);
      });
      return row;
    }),
  ];

  const PaymentTypeOptions = {
    hAxis: { title: '', format: 'yyyy-MM-dd' },
    vAxis: { title: 'Sales Amount' },
    focusTarget: 'category',
    ...globalOptions,
  };
  // ===========================================================

  //CUSTOMER TYPE
  const data = Array.isArray(allSummary) && allSummary?.length > 0 ? allSummary?.find((dt) => dt.group === 'Customer type')?.data : [];

  const normalizedData = data.map((item) => ({
    ...item,
    orders: item.orders.map((order) => ({
      ...order,
      customer__member__attributes__customer_type: order.customer__member__attributes__customer_type || 'on the spot',
    })),
  }));

  const customerTypes =
    normalizedData?.length > 0 ? Array.from(new Set(normalizedData.flatMap((item) => item.orders.map((order) => order.customer__member__attributes__customer_type)))) : [];

  const chartDataCustomer = [
    ['Date', ...customerTypes.map(capitalizeFirstLetter)],
    ...normalizedData.map((item) => {
      const row = [item.serving_date];
      customerTypes.forEach((type) => {
        const order = item.orders.find((order) => order.customer__member__attributes__customer_type === type);
        row.push(order ? order.sales_amount_sum : 0);
      });
      return row;
    }),
  ];

  const optionsCustomer = {
    hAxis: { title: '', format: 'yyyy-MM-dd' },
    vAxis: { title: 'Sales Amount' },
    focusTarget: 'category',
    ...globalOptions,
  };
  // ===========================================================

  // TOPUPS
  const topupData = Array.isArray(allSummary) && allSummary?.length > 0 ? allSummary?.find((dt) => dt.group === 'Non Sales')?.data : [];

  const chartDataTopUp = [
    ['Date', 'Deposit'],
    ...topupData.map((item) => {
      const row = [moment(item.created_date).format('YYYY-MM-DD'), item.sales_amount_sum];
      return row;
    }),
  ];

  const optionsDeposit = {
    hAxis: { title: '', format: 'yyyy-MM-dd' },
    vAxis: { title: 'Deposit Top Ups' },
    focusTarget: 'category',
    ...globalOptions,
  };
  // ===========================================================

  // LINE CHART
  const DepoTopups = Array.isArray(allSummary) && allSummary?.length > 0 ? allSummary?.find((dt) => dt.group === 'Deposit And Topups')?.data : [];

  const chartDepoTopups = [
    ['Date', 'Deposit', 'Top ups'],
    ...DepoTopups.map((item) => {
      const row = [moment(item.created_date).format('YYYY-MM-DD'), item.deposit_sales || 0, item.top_ups || 0];
      return row;
    }),
  ];

  const optionsDepoTopups = {
    hAxis: { title: '', format: 'yyyy-MM-dd' },
    vAxis: { title: 'Sales Amount' },
    focusTarget: 'category',
    curveType: 'function',
    ...globalOptions,
  };

  const formatNominal = (value) => {
    if (value === null || value === undefined) {
      return '';
    }

    const integerValue = Math.floor(Number(value));

    const formattedValue = integerValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedValue;
  };

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 gap-4">
        <div className="border rounded-md shadow-lg h-[400px]">
          {transactionTypeData.length > 0 ? (
            <Chart chartType="ColumnChart" width="100%" height="400px" data={transactionTypeChart} options={transactionTypeOptions} />
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <p>No Transaction</p>
            </div>
          )}
        </div>
        <div className="border rounded-md shadow-lg h-[400px]">
          {topupData.length > 0 ? (
            <Chart chartType="ColumnChart" width="100%" height="400px" data={chartDataTopUp} options={optionsDeposit} />
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <p>No Topup</p>
            </div>
          )}
        </div>
        <div className="border rounded-md shadow-lg h-[400px]">
          {data.length > 0 ? (
            <Chart chartType="ColumnChart" width="100%" height="400px" data={chartDataCustomer} options={optionsCustomer} />
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <p>n/a</p>
            </div>
          )}
        </div>
        <div className="border rounded-md shadow-lg h-[400px]">
          {PaymentTypesData.length > 0 ? (
            <Chart chartType="ColumnChart" width="100%" height="400px" data={PaymentTypeChart} options={PaymentTypeOptions} />
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <p>n/a</p>
            </div>
          )}
        </div>
        <div className="border rounded-md shadow-lg">
          {DepoTopups.length > 0 ? (
            <Chart chartType="LineChart" width="100%" height="400px" data={chartDepoTopups} options={optionsDepoTopups} />
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <p>n/a</p>
            </div>
          )}
        </div>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="!font-bold">
                    Deposit Balances
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Deposits</TableCell>
                  <TableCell align="right">{allDepositSummary && formatNominal(allDepositSummary.total_deposit)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Debt</TableCell>
                  <TableCell align="right">{allDepositSummary && formatNominal(allDepositSummary.total_debt)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Balances</TableCell>
                  <TableCell align="right">{allDepositSummary && formatNominal(allDepositSummary.total_balance)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default DashboardDesign;

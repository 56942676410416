/* eslint-disable */

import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
// import InputContainer from '../../Input/InputContainer';
// import DatePickerField from '../../Input/DatePickerField';
import FileContatiner from '../../Input/FileContatiner';
import { fetchTeams } from '../../../hooks/useTeamsData';
import { handleModal } from '../../../store/slices/modalSlice';
import SelectAutocomplete from '../../Input/SelectAutocomplete';
import accountingApi from '../../../api/accounting/accounting';

import InputContainer from '../../../pages/CRM/redesigning/InputContainerRedesign'
import DatePickerField from '../../../pages/CRM/redesigning/DatePickerFieldRedesign'

const validation = yup
  .object()
  .shape({ team: yup.string().required('Team is a required') })
  .required();

const schema = yupResolver(validation);

export default function ProjectForm({ defaultValues, onFormSubmit, cancel, key }) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, schema });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [team, setTeam] = useState('');
  const [validateTeam, setValidateTeam] = useState(false);
  const [customers, setCustomers] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState('');

  const clientId = useSelector((state) => state.client.activeClient);
  const { data: TeamsData } = useQuery(['teams', { clientId }], fetchTeams);

  const teams = TeamsData?.data?.results?.map((item) => item);
  const edPro = defaultValues?.team?.id;

  const onSubmit = (data) => {
    const formData = new FormData();
    if (!team) {
      setValidateTeam(true);
    }

    formData.append('team', team || edPro);
    formData.append('name', data.name ? data.name : '');
    formData.append('notes', data.notes ? data.notes : '');
    if (selectedCustomer) {
      formData.append('member', selectedCustomer);
    }
    const dueDate = data.due_date ? (data.due_date === defaultValues?.due_date ? defaultValues?.due_date : new Date(data.due_date).toISOString()) : new Date().toISOString();
    formData.append('due_date', dueDate);
    if (data.icon && data.icon[0] && data.icon[0] === 'h') {
      null;
    } else {
      if (data.icon) {
        formData.append('icon', data.icon[0]);
      }
    }
    onFormSubmit(formData);
    reset();
  };
  const options = teams?.map((item) => ({
    name: item?.name,
    value: item?.id,
  }));

  let peopleName;
  if (edPro) {
    const people = options?.filter((u) => u.value === edPro);
    if (people) {
      peopleName = people[0];
    }
  }

  const handleCancel = () => {
    if (cancel) {
      navigate(-1);
    } else dispatch(handleModal({ modalId: '' }));
  };

  const onChangeTaskProject = (event) => {
    const projName = event.target.innerText;
    if (projName) {
      const project = options?.filter((u) => u.name === projName);
      if (project) {
        setTeam(project[0]?.value);
      }
    }
  };

  const getCustomerList = useCallback(() => {
    const { getMembers } = accountingApi();
    getMembers({ client: clientId })
      .then((res) => {
        if (res.status === 200 || res.data) {
          setCustomers(res.data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [clientId]);

  useEffect(() => {
    if (customers && defaultValues && !selectedCustomer) {
      if (defaultValues.member) {
        setSelectedCustomer(defaultValues.member.id);
      }
    }
  }, [customers, defaultValues, selectedCustomer]);

  useLayoutEffect(() => {
    if (!customers) {
      getCustomerList();
    }
  }, [customers, getCustomerList]);

  const optionCustomers =
    customers &&
    customers.length &&
    customers?.map((item) => ({
      name: item?.name,
      value: item?.id,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>
      <div className="w-full">
        <div>
          <p className='font-semibold'>name</p>
          <InputContainer
            name="name"
            control={control}
            label="Name"
            // errors={errors.name}
          />
        </div>
        <div>
          <p className='font-semibold'>notes</p>
          <InputContainer
            name="notes"
            control={control}
            label="Notes"
            // errors={errors.notes}
          />
        </div>
        {peopleName && (
          <div className="mb-2">
            <p className='mb-1 font-semibold'>Select Team</p>
            <SelectAutocomplete
              options={options}
              placeHolder="Select Team"
              variant="outlined"
              defaultValue={peopleName}
              onChange={(event) => {
                onChangeTaskProject(event);
              }}
            />
          </div>
        )}
        {!peopleName && (
          <div className="mb-2">
            <p className='mb-1 font-semibold'>Select Team</p>
            <SelectAutocomplete
              options={options}
              placeHolder="Select Team"
              variant="outlined"
              onChange={(event) => {
                onChangeTaskProject(event);
              }}
            />
            {validateTeam && <span className="absolute h-20 mt-3 text-sm text-red-600">Team is a required</span>}
          </div>
        )}

        <div className="mb-2">
          <p className='mb-1 font-semibold'>Select Customer</p>
          <SelectAutocomplete
            options={optionCustomers || []}
            placeHolder="Select Customer"
            variant="outlined"
            defaultValue={defaultValues && defaultValues.member}
            onChange={(event, dataOption) => {
              setSelectedCustomer(dataOption?.value || '');
            }}
          />
        </div>

        <div className="">
          <p className='font-semibold'>Due Date</p>
          <DatePickerField
            sx={{ marginBottom: '8px' }}
            control={control}
            name="due_date"
            // label="Due Date"
            // error={!!errors.datePicker}
            // helperText={errors.datePicker?.message}
            size="small"
            fullWidth
          />
        </div>

        <div className="">
          <p className='font-semibold'>Icon</p>
          <FileContatiner name="icon" errors="" control={control} />
          <img
            src={defaultValues?.icon ? defaultValues?.icon : 'https://bzpublishassets.blob.core.windows.net/media/user_avatars/2015-cony_1y8CEyI.png'}
            alt=""
            className="w-20 h-20 object-cover mx-auto my-4 rounded-full"
          />
        </div>
        <div className="flex justify-end gap-x-3 mt-8">
          <button onClick={handleCancel} className="min-w-[140px] flex items-center justify-center text-white py-[8px] bg-[#FC581D] rounded-xl" type="button">
            Cancel
          </button>
          <button className="min-w-[140px] flex items-center justify-center text-white py-[8px] bg-[#2C6D47] rounded-xl" type="submit">
            {defaultValues ? 'Update project' : 'Create project'}
          </button>
        </div>
      </div>
    </form>
  );
}

import { Close, Save } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';

import DndProfileSideDrawer from './DndProfileSideDrawer';
import 'react-grid-layout/css/styles.css';
import styles from '../../../assets/css/RGL.module.css';

const ReactGridLayout = WidthProvider(RGL);

function GridAction({ onSave }) {
  const navigate = useNavigate();

  return (
    <div className="absolute -top-12 right-8 flex gap-2">
      <Button variant="outlined" startIcon={<Close />} onClick={() => navigate('/crm/projects/profile')}>
        Cancel
      </Button>
      <Button variant="contained" startIcon={<Save />} onClick={onSave}>
        Save
      </Button>
    </div>
  );
}

const beforeLast = (value, delimiter) => {
  const val = value || '';

  if (delimiter === '') {
    return val;
  }

  const substrings = val.split(delimiter);

  return substrings.length === 1 ? val : substrings.slice(0, -1).join(delimiter);
};

const sortGroupsEl = (arr) => {
  arr.sort((a, b) => a.dimension[0] - b.dimension[0]);
  arr.sort((a, b) => a.dimension[1] - b.dimension[1]);
  return arr;
};

const sortGroupsLayout = (arr) => {
  arr.sort((a, b) => a.x - b.x);
  arr.sort((a, b) => a.y - b.y);
  return arr;
};

export default function DndProfileAttribute(props) {
  const dispatch = useDispatch();
  const updateElement = useSelector((state) => state.crmProfile.updateElement);
  const activeSidePanel = useSelector((state) => state.crmProfile.activeSidePanel);

  const { itemKey = '' } = props;
  const [count, setCount] = useState(0);
  const [activeLayout, setNewLayout] = useState(props.layout || []);
  const [listElement, setListElement] = useState(props.elements || []);
  // const [listConfiguration, setListConfiguration] = useState([]);

  const initialized = useRef(false);
  const isParent = props.isParent;

  const onNestedLayoutChange = (nestedLayout, nestedElement, itmKey) => {
    const itemIndex = activeLayout.findIndex((item) => item.i === itmKey);
    if (itemIndex !== -1) {
      setNewLayout([
        ...activeLayout.slice(0, itemIndex),
        {
          ...activeLayout[itemIndex],
          activeLayout: nestedLayout,
        },
        ...activeLayout.slice(itemIndex + 1),
      ]);
      // This should be change
      const ls = [
        ...listElement.slice(0, itemIndex),
        {
          ...listElement[itemIndex],
          listElement: nestedElement,
        },
        ...listElement.slice(itemIndex + 1),
      ];
      setListElement(ls);
    }
  };

  const getDetailElement = (id, isGroup, item, index, data) => {
    if (listElement) {
      const activeElement = listElement.find((el) => +el.id === +id);

      dispatch({ type: 'crmProfile/setActiveSidePanel', payload: { id, isGroup, activeElement, item, index, data } });
    }
  };

  const onRemoveElement = (i, id, type, groupAttributes) => {
    setNewLayout((state) => state.filter((item) => item.i !== i));
    setListElement((state) => state.filter((item) => String(item.id) !== String(id)));
    props.changeStructure(type, 'remove', id, groupAttributes);
  };

  const generateDOM = () => {
    return activeLayout.map((item, i) => {
      let lblText = item.i;
      let lblId = item.i;
      const isGroup = item.i.startsWith('group|');
      if (!isGroup) {
        const newI = item.i.slice(item.i.indexOf('|') + 1);
        const arrI = newI.split('|');
        lblText = arrI[arrI.length - 1];
        lblId = arrI[arrI.length - 2];
      } else {
        const arrI = item.i.split('|');
        lblText = arrI[arrI.length - 1];
        lblId = arrI[arrI.length - 2];
      }
      const isPlaceholder = lblText.includes('dropping-elem');
      if (isPlaceholder) lblText = 'item';
      const attrElement = listElement?.find((l) => +l.id === +lblId);
      let isAssumption = !isGroup && !!attrElement?.configuration?.is_reference || false;
      const isGroupNameDisplayed = isGroup && !!listElement[i]?.is_displayed;
      return (
        <div
          key={item.i}
          onDoubleClick={(e) => {
            e.stopPropagation();
            getDetailElement(lblId, isGroup, item, i, attrElement);
          }}
          className={[styles.reactGridItem, isGroup ? 'grid-group' : 'grid-attribute', isAssumption ? styles.reference : ''].join(' ')}
        >
          {!isPlaceholder && isGroup && (
            <Close
              className="absolute top-0 right-0 z-20 cursor-pointer"
              onClick={() => {
                const groupAttributes = listElement[i]?.listElement?.length ? listElement[i]?.listElement.map((e) => e.id) : null;
                onRemoveElement(item.i, lblId, 'group', groupAttributes);
              }}
              fontSize="12"
            />
          )}
          {isGroup && (
            <>
              <span className={['w-auto absolute top-0 right-1/2 translate-x-1/2 z-10 text-sm font-bold', !isGroupNameDisplayed && 'opacity-50'].join(' ')}>{lblText}</span>
              <DndProfileAttribute
                layout={item.activeLayout || []}
                elements={listElement[i]?.listElement || []}
                nested
                itemKey={item.i}
                onNestedLayoutChange={onNestedLayoutChange}
                isGroup
                changeStructure={props.changeStructure}
              />
            </>
          )}
          {!isGroup && (
            <div className="h-full flex justify-center items-center">
              <span className="text-center text-xs leading-3 mr-4" data-id={lblId}>
                {lblText}
              </span>
              <Close className="cursor-pointer" onClick={() => onRemoveElement(item.i, lblId, 'attribute')} fontSize="12" />
            </div>
          )}
        </div>
      );
    });
  };

  const onLayoutChange = (newLayout) => {
    let layouts = [...newLayout];
    if (props.isParent) {
      layouts = newLayout.map((l, i) => {
        const objLayout = { ...l };
        if (activeLayout && activeLayout.length && activeLayout[i]?.activeLayout) {
          objLayout.activeLayout = activeLayout[i].activeLayout;
        }
        return objLayout;
      });
    }
    layouts = sortGroupsLayout(layouts);
    setNewLayout(layouts);

    if (props.onNestedLayoutChange) {
      const newListEl = [];
      newLayout.forEach((l, i) => {
        const splitI = l.i.split('|');
        const totalChar = splitI.length;
        const getId = splitI[totalChar - 2];
        if (listElement) {
          const findElement = listElement.find((e) => +e.id === +getId);
          if (findElement) {
            newListEl.push({ ...findElement, dimension: [l.x, l.y, l.w, l.h] });
          }
        }
      });

      const newListElement = sortGroupsEl(newListEl);

      props.onNestedLayoutChange(layouts, newListElement, props.itemKey);
    } else {
      const newLS = [];
      layouts.forEach((l) => {
        const isGr = listElement.find((le) => l.i.includes(le.name));
        if (isGr) {
          newLS.push(isGr);
        }
      });

      setListElement(newLS);
    }
  };

  const onDrop = (layout, layoutItem, _event) => {
    const dataTransfer = _event.dataTransfer?.getData('text/plain');
    if (dataTransfer) {
      const parsedDataTransfer = JSON.parse(dataTransfer);
      const { id, name, type } = parsedDataTransfer;
      const { x, y } = layoutItem;

      if (!id && !name) return;
      if (type === 'group') {
        setCount(count + 1);
        setNewLayout([
          ...activeLayout.filter((l) => l.i.includes('group')),
          {
            i: `group|${count}${itemKey ? `*${itemKey}` : ''}|${id}|${name}`,
            x,
            y,
            w: 12,
            h: 6,
            maxW: 12,
            activeLayout: [],
          },
        ]);
        const currListElement = [...listElement];
        currListElement.push({ ...parsedDataTransfer, configuration: null, dimension: [x, y, 12, 6] });
        setListElement(currListElement);
        props.changeStructure('group', 'add', id);
      }
      if (type === 'attribute') {
        setCount(count + 1);
        setNewLayout([
          ...activeLayout.filter((l) => l.i.includes('group')),
          {
            i: `${count}${itemKey ? `|${itemKey}` : ''}|${id}|${name}`,
            x,
            y,
            w: 4,
            h: 1,
            maxW: 12,
          },
        ]);
        const currListElement = [...listElement];
        currListElement.push({ ...parsedDataTransfer, configuration: null, dimension: [x, y, 4, 1] });
        setListElement(currListElement);
        props.changeStructure('attribute', 'add', id);
      }
    }
  };

  const onResize = (layout, layoutItem) => {
    const currElement = layout.findIndex((el) => el.i === layoutItem.i);
    if (currElement >= 0) {
      const { x, y, w, h } = layout[currElement];

      const currState = [...activeLayout];
      const currObj = { ...currState[currElement] };
      currObj.w = +w;
      currObj.h = +h;
      if (layout[currElement].isStatic) {
        currObj.static = true;
      }
      if (activeLayout[currElement]?.activeLayout) {
        currObj.activeLayout = activeLayout[currElement].activeLayout;
      }
      currState[currElement] = currObj;
      const newL = [...currState];
      setNewLayout(currState);

      const currStateEl = [...listElement];
      const currObjEl = { ...currStateEl[currElement] };
      currObjEl.dimension = [x, y, w, h];
      if (listElement[currElement]?.listElement) {
        currObj.listElement = listElement[currElement].listElement;
      }
      currStateEl[currElement] = currObjEl;
      const newEl = [...currStateEl];
      setListElement(currStateEl);

      if (props.onNestedLayoutChange) {
        props.onNestedLayoutChange(newL, newEl, props.itemKey);
      }
    }
  };

  const onSave = () => {
    if (props.onSave) {
      props.onSave({ activeLayout, listElement });
    }
  };

  useEffect(() => {
    if (props.isParent && props.profileId && props.layout.length && props.elements.length && !activeLayout.length && !initialized.current) {
      initialized.current = true;
      setNewLayout(props.layout);
      setListElement(props.elements);
    }
  }, [activeLayout, props, listElement]);

  useEffect(() => {
    if (updateElement) {
      dispatch({ type: 'crmProfile/setUpdateElement', payload: null });
      const currElement = activeLayout.findIndex((el) => el.i === updateElement.data.i);
      if (currElement >= 0) {
        const { isGroup, isStatic, groupName } = updateElement;
        const currState = [...activeLayout];
        const currObj = { ...currState[currElement] };
        currObj.w = +updateElement.w;
        currObj.h = +updateElement.h;
        currObj.static = !!isStatic;
        if (isGroup) {
          const currIName = beforeLast(currObj.i, '|');
          currObj.i = `${currIName}|${groupName}`;
        }
        currState[currElement] = currObj;
        const newL = [...currState];

        const currStateEl = [...listElement];
        const currObjEl = { ...currStateEl[currElement] };

        currObjEl.is_displayed = !!updateElement.isNameDisplayed;
        if (isGroup) {
          currObjEl.name = groupName;
        }
        if (updateElement.isAssumption) {
          currObjEl.configuration = {
            is_reference: true,
            default_value: +updateElement.assumptionDefaultValue,
          };
        } else {
          currObjEl.configuration = currObjEl.configuration ? { ...currObjEl.configuration, is_reference: false, default_value: null } : null;
        }
        currObjEl.static = !!isStatic;

        currStateEl[currElement] = currObjEl;
        const newEl = [...currStateEl];

        setListElement(currStateEl);
        setNewLayout(currState);

        if (props.onNestedLayoutChange) {
          props.onNestedLayoutChange(newL, newEl, props.itemKey);
        }
      }
    }
  }, [updateElement, dispatch, activeLayout, listElement, props]);

  return (
    <>
      <ReactGridLayout
        onDragStart={(a, b, c, d, e) => {
          e.stopPropagation();
          // console.log(a, b, c, d);
        }}
        layout={activeLayout}
        onLayoutChange={onLayoutChange}
        onDrop={onDrop}
        className={[styles.reactGridLayout, 'layout min-h-full h-auto'].join(' ')}
        rowHeight={32}
        cols={12}
        isDroppable
        margin={props.isParent ? [4, 4] : [16, 20]}
        onResizeStop={(layout, layoutItem) => {
          onResize(layout, layoutItem);
        }}
        verticalCompact={props.isGroup}
      >
        {generateDOM()}
      </ReactGridLayout>
      {isParent && <GridAction onSave={onSave} />}
      {isParent && <DndProfileSideDrawer open={!!activeSidePanel} />}
    </>
  );
}

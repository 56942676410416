/* eslint-disable */
import { AddOutlined, DeleteOutlineOutlined, GridView, ModeEditOutlineOutlined, MoreHoriz as MoreHorizIcon, ViewList } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Switch from '@mui/material/Switch';
import { alpha, createTheme, styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TruncateMarkup from 'react-truncate-markup';
import swal from 'sweetalert';
import { useDebounce } from 'use-debounce';
import BaseSearchButton from '../../components/Button/BaseSearchButton';
import ProjectForm from '../../components/Form/Task/ProjectForm';
import ModalWrapper from '../../components/ui/ModalWrapper';
import request from '../../helper/axiosUtil';
import { deleteProjects, fetchProjectDetail, fetchProjects } from '../../hooks/useProjectsData';
import { handleModal } from '../../store/slices/modalSlice';
import BZHelmet from '../../utils/BZHelmet';
import ProjectList from './Projects/ProjectList';

const theme = createTheme({});

export default function Project() {
  const [widthProject, setWidthProject] = useState('w-6/12');
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const { enqueueSnackbar } = useSnackbar();
  const qClient = useQueryClient();
  const projectId = modal.modalId;
  const dispatch = useDispatch();

  const [topicPerPage, setTopicPerPage] = useState(10);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [checked, setChecked] = useState(false);
  const [isGrid, setIsGrid] = useState(true);
  const [projectIdChangeStatus, setProjectIdChangeStatus] = useState();

  const [searchValue] = useDebounce(search, 300);

  const currentPage = 1;
  const indexOfLastTopic = currentPage * topicPerPage;
  const indexOfFirstTopic = indexOfLastTopic - topicPerPage;

  const queryParams = {
    page: page,
    page_size: isGrid ? (page === 1 && searchValue === '' ? 11 : 12) : topicPerPage,
    search: searchValue,
    ...(checked ? {} : { is_archived: false }),
  };

  const { data: ProjectsData, refetch } = useQuery(['projects', { clientId }, queryParams], fetchProjects);

  const currentTopic = ProjectsData?.data?.results;

  useEffect(() => {
    if (ProjectsData) {
      const totalData = ProjectsData?.data?.count;

      setCount(Math.ceil(totalData / 12));
    }
  }, [ProjectsData]);

  useEffect(() => {
    setPage(1);
    // refetch();
  }, [searchValue, checked]);

  const onSearchAsset = (event) => {
    // setPage(1);
    setSearch(event.target.value);
  };

  let isAutoFetching;
  if (!modal.modalId) {
    isAutoFetching = false;
  } else isAutoFetching = true;
  const { data: ProjectData } = useQuery(['project', { projectId }], fetchProjectDetail, { enabled: isAutoFetching });

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: widthProject ? 'left' : 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: widthProject ? 'left' : 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 185,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': { padding: '4px 0' },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) },
      },
    },
  }));

  const updateProjects = async (data) => request({ url: `/project-management/projects/${projectId || projectIdChangeStatus}/`, method: 'patch', data });
  // const updateProjectsArchiveStatus = async (data) => request({ url: `/project-management/projects/${projectIdChangeStatus}/`, method: 'patch', data });

  const { mutateAsync: updateProject } = useMutation(updateProjects, {
    onSuccess: (e) => {
      if (e.request.status === 200) {
        setProjectIdChangeStatus();
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onUpdateProject = async (data) => {
    await updateProject(data);
    qClient.invalidateQueries('projects');
    qClient.invalidateQueries('project');
    dispatch(handleModal({ modalId: '' }));
  };

  const { mutateAsync: destroyProjects } = useMutation(deleteProjects, {
    onSuccess: () => {
      qClient.invalidateQueries('projects');
    },
  });
  const onDestroyProject = async (id) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        destroyProjects(id);
        qClient.invalidateQueries('projects');
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else {
        swal('Your imaginary file is safe!');
      }
    });
  };

  const handleChange = (e, val) => {
    setPage(val);
  };

  const handleArchiveSatus = (val) => {
    if (val.id) {
      const formData = new FormData();

      formData.append('is_archived', !val.is_archived);
      onUpdateProject(formData);
    }
  };

  const label = { inputProps: { 'aria-label': 'Color switch demo' } };

  return (
    <>
      <BZHelmet title="Projects" description="bz publish task management projects" />
      <div className="w-full max-w-[1200px] py-4 px-4  mx-auto">
        <div className="space-y-8">
          <div className="mr-6 text-5xl font-extrabold leading-[125%]">Projects</div>
          <div name="FILTER-FIELD" className="space-y-4 md:space-y-0 md:flex justify-between ">
            <BaseSearchButton onChange={onSearchAsset} defaultValue={search} label="search" />
            <div className="flex items-center space-x-2">
              <div className="flex space-x-2 justify-end md:justify-normal items-end mb-1">
                <p className="pb-[10px] text-[14px] font-[600] leading-[20px] capitalize">Show Archive</p>
                <Switch {...label} color="warning" checked={checked} onClick={() => setChecked(!checked)} />
              </div>
              <div onClick={() => setPage(1)} className="flex space-x-2 justify-end md:justify-normal items-end mb-1 cursor-pointer">
                <Tooltip title={!isGrid ? 'Show Grid' : 'Show List'}>
                  {!isGrid ? <GridView onClick={() => setIsGrid(true)} /> : <ViewList onClick={() => setIsGrid(false)} />}
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        {isGrid ? (
          <div className="">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-6 gap-6 mt-8">
              {page === 1 && searchValue === '' && (
                <div className="w-full relative">
                  <Link to="/task/project/new/blank" className="space-y-2">
                    <div className="border-[1.5px] border-dashed border-slate-400 flex items-center justify-center rounded-md w-full h-[120px]">
                      <AddOutlined />
                    </div>
                    <div className="text-gray-500 text-center">Create Project</div>
                  </Link>
                </div>
              )}
              {currentTopic?.map((pr) => (
                <div key={pr.id} className="w-full relative">
                  <div className="absolute right-4 top-2 z-50">
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <>
                          <button type="button" {...bindTrigger(popupState)}>
                            <MoreHorizIcon className="w-5 h-fit font-bold text-gray-300 hover:text-black" />
                          </button>
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={popupState.close}>
                              <button
                                type="button"
                                className="w-full flex gap-x-2 items-center justify-start"
                                onClick={() => dispatch(handleModal({ modalId: pr.id, componentName: 'editProject' }))}
                              >
                                <ModeEditOutlineOutlined />
                                <div className="">Edit Project</div>
                              </button>
                            </MenuItem>
                            <MenuItem onClick={popupState.close}>
                              <button type="button" className="w-full flex gap-x-2 items-center justify-start" onClick={() => onDestroyProject(pr.id)}>
                                <DeleteOutlineOutlined />
                                <div className=""> Delete Project</div>
                              </button>
                            </MenuItem>
                            <MenuItem className="" onClick={popupState.close}>
                              <button
                                type="button"
                                className="w-full flex gap-x-2 items-center justify-start"
                                onClick={() => {
                                  setProjectIdChangeStatus(pr.id);
                                  handleArchiveSatus({ id: pr.id, is_archived: pr.is_archived });
                                }}
                              >
                                <Checkbox
                                  // {...label}
                                  size="small"
                                  checked={pr?.is_archived}
                                  disabled
                                  sx={{
                                    padding: '0!important',
                                    marginRight: '2px',
                                    marginLeft: '3px',
                                    '&.Mui-disabled': {
                                      color: 'black',
                                    },
                                  }}
                                />
                                <div className="">Archive</div>
                              </button>
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </PopupState>
                  </div>
                  <Link to={`/task/project/${pr.id}/board`}>
                    <div className="h-full w-full gap-x-4 items-center hover:bg-[#F9F8F8] pb-[16px] space-y-2">
                      <div
                        className={`relative w-full h-[120px] flex items-center justify-center border-[1.5px] border-dashed p-3 rounded-md ${
                          pr?.is_archived ? '' : 'border-slate-400'
                        }`}
                      >
                        <img
                          src={pr.icon ? pr.icon : 'https://bzpublishassets.blob.core.windows.net/media/user_avatars/2015-cony_1y8CEyI.png'}
                          alt=""
                          className={`w-[60px] h-[60px] object-cover ${pr?.is_archived ? 'grayscale-[80%]' : ''}`}
                        />
                      </div>
                      <TruncateMarkup className="space-y-1" lines={3}>
                        <div className=" capitalize text-center">{pr.name}</div>
                      </TruncateMarkup>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <div className="w-full py-4 mx-auto">
              <div className="my-5 flex items-center justify-end gap-x-5">
                <Pagination
                  count={count}
                  page={page}
                  color="primary"
                  size="large"
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                  sx={{
                    '& .Mui-selected': {
                      color: '#2c6d47bf!important',
                      backgroundColor: '#2c6d4726!important',
                      border: '1px solid #2c6d47bf!important',
                    },
                  }}
                />
              </div>
            </div>{' '}
          </div>
        ) : (
          <div className="mt-8">
            <ProjectList
              projects={currentTopic}
              page={page}
              count={ProjectsData?.data?.count}
              rowsPerPage={topicPerPage}
              setRowsPerPage={setTopicPerPage}
              setPage={setPage}
              onDestroyProject={onDestroyProject}
            />
          </div>
        )}
        <ModalWrapper componentName="editProject" header="Edit Project">
          <ProjectForm editMode onFormSubmit={onUpdateProject} defaultValues={ProjectData?.data} key={ProjectData?.data?.id} />
        </ModalWrapper>
      </div>
    </>
  );
}

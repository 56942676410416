import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Divider, Fade, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { list } from 'postcss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: '60vw',
  maxHeight: '90%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid transparent',
  boxShadow: 24,
  p: 4,
};

const schema = yup.object().shape({
  type: yup.string().required('Action is a required field'),
  isType: yup.boolean(),
  units: yup.array().when('isType', {
    is: true,
    then: yup.array().required('Business Unit is a required field').min(1),
    otherwise: yup.array().required('Business Unit is a required field').min(0),
  }),
  note: yup.string().required('Note is a required field').min(1),
});

const accPerStatus = [
  { name: 'Open', label: 'Open' },
  { name: 'Closed', label: 'Close' },
  { name: 'Re-opened', label: 'Re-Open' },
  { name: 'Closed', label: 'Close All' },
  { name: 'Re-opened', label: 'Re-Open All' },
];

export default function AccountPeriods(props) {
  const { open, close, action, bsData, data } = props;
  const [isOpen, setOpen] = useState(false);
  const [actionType, setActionType] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      note: '',
      type: '',
      units: [],
    },
  });

  const onCloseHandler = () => {
    close();
    setOpen(false);
    reset({
      type: '',
      units: [],
    });
  };

  const onSubmit = (dataAct) => {
    if (['Close All', 'Re-Open All'].includes(dataAct.type)) {
      const u = bsData.b.map((b) => b.id);
      setValue('units', u);
      dataAct.units = u;
    }
    if (dataAct.type) {
      dataAct.type = accPerStatus.find((a) => a.label === dataAct.type).name;
    }

    action(dataAct);
    onCloseHandler()
  };

  useEffect(() => {
    if (!isOpen && open) {
      setOpen(true);
    }
  }, [open, isOpen]);

  const isBtnDisabled = !!Object.keys(errors).length;
  const isNew = data?.b?.every((b) => ['', 'new'].includes(b.status?.toLowerCase()));
  const isClose = data?.b?.every((b) => ['', 'closed'].includes(b.status?.toLowerCase()));
  const isROpen = data?.b?.every((b) => ['', 'open'].includes(b.status?.toLowerCase()));

  const statusList = useMemo(() => {
    const l = ['Close', 'Re-Open', 'Close All', 'Re-Open All'];
    if (isNew) {
      return ['Open'];
    }
    if (isClose) {
      return l.filter((s) => !['Close', 'Close All'].includes(s));
    }
    if (isROpen) {
      return l.filter((s) => !['Re-Open'].includes(s));
    }
    return l;
  }, [isNew, isClose, isROpen, data]);

  return (
    <Modal open={open} onClose={onCloseHandler} aria-labelledby="modal-account-group" aria-describedby="modal-account-group" className="w-full">
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" className="">
            Open or Close <br /> Account Periods {bsData.period}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div className="flex flex-col space-y-4">
              <FormControl>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value, ref } }) => (
                    <>
                      <InputLabel
                        sx={{ top: '-5px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } }}
                        id="select-type"
                        className={!!errors.type && 'text-red-500'}
                      >
                        Action
                      </InputLabel>
                      <Select
                        sx={{
                          pt: 0.5,
                          pb: 1,
                          border: 0,
                          '& label': { display: 'none' },
                        }}
                        labelId="select-type"
                        name="type"
                        inputRef={ref}
                        value={value}
                        label="Type"
                        onChange={(e) => {
                          const val = e.target.value;
                          clearErrors('units');
                          setValue('type', val);
                          setValue('isType', !['Close All', 'Re-Open All'].includes(val));
                          setValue('units', []);
                          setActionType(val);
                        }}
                        error={!!errors.type?.message}
                      >
                        <MenuItem value="">
                          <em>Type</em>
                        </MenuItem>
                        {statusList.map((tp, i) => (
                          <MenuItem key={`${tp + i}`} value={tp}>
                            {tp}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="units"
                  render={({ field: { onChange, value, ref } }) => (
                    <>
                      <InputLabel sx={{ top: '-5px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } }} id="select-type">
                        Business Unit
                      </InputLabel>
                      <Select
                        sx={{
                          pt: 0.5,
                          pb: 1,
                          border: 0,
                          '& label': { display: 'none' },
                          '&.Mui-disabled': { background: 'rgb(231, 231, 231, 0.5)' },
                        }}
                        labelId="select-business-units"
                        name="units"
                        inputRef={ref}
                        value={value}
                        label="Business Unit"
                        onChange={onChange}
                        error={!!errors.units?.message}
                        multiple={true}
                        disabled={['Close All', 'Re-Open All'].includes(actionType) || !actionType}
                      >
                        {bsData?.b?.map((b) => (
                          <MenuItem key={`${b.code}`} value={b.id}>
                            {b.code}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="note"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextField value={value} size="small" label="Note" onChange={onChange} multiline maxRows={5} minRows={3} error={!!errors.note?.message} />
                    </>
                  )}
                />
              </FormControl>
            </div>
            <Divider className="mt-4" />
            <Stack direction="row" spacing={2} className="mt-5 float-right">
              <button
                type="button"
                className="flex h-[40px] gap-x-2 items-center text-center rounded-xl border px-4 border-[#2C6D47] hover:!bg-[#2c6d470a]"
                onClick={onCloseHandler}
              >
                <p className="text-[#2C6D47]">Cancel</p>
              </button>
              <button
                type="submit"
                className={twMerge('flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 bg-[#2C6D47]', isBtnDisabled ? 'bg-opacity-30' : '')}
                disabled={isBtnDisabled}
              >
                <p className="text-white">Update</p>
              </button>
            </Stack>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
}

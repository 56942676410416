import React, { useCallback, useEffect, useState, memo, useMemo } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, TablePagination, Box, Paper } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import KeyIcon from '@mui/icons-material/Key';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import ModalWrapper from '@/components/ui/ModalWrapper';
import { handleModal } from '@/store/slices/modalSlice';
import PairCustomerLogin from '@/components/Form/Sales/PairCustomerLogin';
import UpdateUser from '@/components/Form/Sales/UpdateUser';
import Swal from 'sweetalert2';
import POSApi from '@/api/pos/posApi';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import MuiSelectSingleItem from '@/components/Input/MuiSelectSingleItem';
import moment from 'moment';
import { useParams } from 'react-router';
import { fetchData } from '@/utils/fetchDataFunc';

const formatNominal = (value) => {
  if (value === null || value === undefined) {
    return '';
  }
  
  const number = parseFloat(value);
  
  const formattedNumber =
  number % 1 === 0
  ? number.toLocaleString()
  : number
  .toFixed(3)
  .replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1')
  .toLocaleString();
  return formattedNumber;
};  

function OutletDetailCustomersTab() {
  const { control, reset, resetField } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const { outletId } = useParams();
  const dispatch = useDispatch();

  const sortingOptions = [
    {name: 'Name ascending', value: 'member__name'},
    {name: 'Name descending', value: '-member__name'},
    {name: 'Class ascending', value: 'member__attributes__class'},
    {name: 'Class descending', value: '-member__attributes__class'},
    {name: 'Student ID ascending', value: 'member__attributes__student_id'},
    {name: 'Student ID descending', value: '-member__attributes__student_id'},
    {name: 'Last Login descending', value: 'user__last_login'},
    {name: 'Last Login descending', value: '-user__last_login'},
    {name: 'Balance', value: 'balance'},
  ]
  
  const [allOutletCustomers, setAllOutletCustomers] = useState([]);
  const [sortByNegativeBalance, setSortByNegativeBalance] = useState(false);
  const [selectedCustomerForUnpair, setSelectedCustomerForUnpair] = useState([]);  
  const [nonUserOnly, SetNonUserOnly] = useState(false);  
  const [showDebtsOnly, setShowDebtsOnly] = useState(false);
  const [sorting, setSorting] = useState("member__name");
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(true);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState({ customer: 0 });
  

  const [classesList, SetClassesList] = useState([]);
  const ListOfClasses = useCallback((params) => fetchData(POSApi().listOfClassForFilter, params), [outletId]);

  const getAllClasses = useCallback(async (params) => {
    const [classes] = await Promise.all([ListOfClasses(params)]);

    if (classes) {
      SetClassesList(classes)
    }
  }, [ListOfClasses]);

  useEffect(() => {
    if (outletId !== undefined){
      getAllClasses({ outlet: outletId })
    }
  }, [outletId])
  
  const customers = useCallback((params) => fetchData(POSApi().getAllOUtletCustomers, params), [outletId]);
  const getAllCustomers = useCallback(
    async (paramCustomers) => {
      if (!outletId) return;

      const [customerList] = await Promise.all([customers(paramCustomers)]);
      if (customerList) {
        setAllOutletCustomers(customerList.results || []);
        setCount(customerList.count);
      }
      setIsFetchingCustomers(false);
    },
    [customers, outletId]
  );

  useEffect(() => {
    if (outletId !== undefined && isFetchingCustomers === true) {
      setAllOutletCustomers([]);
      const newParams = {
        outlet: outletId,
        ordering: 'member__name',
        page: page,
        page_size: rowsPerPage,
      };
      getAllCustomers(newParams);
    } else {
      setIsFetchingCustomers(false)
    }
  }, [getAllCustomers, outletId, page, rowsPerPage, isFetchingCustomers]);
  

  const sortedOutletCustomers = useMemo(() => (
    sortByNegativeBalance ? [...allOutletCustomers].sort((a, b) => parseFloat(a.balance) - parseFloat(b.balance)) : allOutletCustomers
  ), [allOutletCustomers, sortByNegativeBalance]);

  const handleChangePage = (event, newPage) => {

    const watchNextPage = event.target.outerHTML.includes('KeyboardArrowRightIcon');
    let switchMathPage = watchNextPage ? newPage + 1 : newPage === 0 ? 1 : newPage + 1;

    setPage(switchMathPage);
    setIsFetchingCustomers(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setIsFetchingCustomers(true);
  };

  const formatContact = (contact) => {
    if (contact?.phone && contact?.name) {
      return `${contact.phone} (${contact.name})`;
    } else if (contact?.phone) {
      return contact.phone;
    } else if (contact?.name) {
      return `(${contact.name})`;
    } else {
      return '-';
    }
  };

  const handleClick = useCallback((event, id) => {
    const selectedIndex = selectedCustomerForUnpair.indexOf(id);
    let newSelected = [...selectedCustomerForUnpair];
  
    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
  
    setSelectedCustomerForUnpair(newSelected);
  }, [selectedCustomerForUnpair]);

  const submitUnpairCustomerUser = () => {
    const { unpairCustomerUser } = POSApi();

    try {
      const newData = {
        customer_ids: selectedCustomerForUnpair
      }

      unpairCustomerUser(outletId, newData)
        .then(() => {
            setIsFetchingCustomers(true);
            setSelectedCustomerForUnpair([]);
            const newParams = {
              outlet: outletId,
              ordering: sorting,
              member__attributes__class: selectedClass,
              user__isnull: false,
              page: page,
              page_size: rowsPerPage,
            };
            getAllCustomers(newParams);
            Swal.fire({
              title: 'Success !',
              text: 'Successfully Unpair Customer Login !',
              icon: 'success',
            });
        })
        .catch((err) => {
          console.error(err);
        });
      
    } catch (error) {
      console.error(error)
    }
  }

  const isSelected = (name) => selectedCustomerForUnpair.indexOf(name) !== -1;
  
  const [selectedClass, setSelectedClass] = useState("")

  const handleChange = useCallback((event) => {
    const {value} = event.target;    
    if (value === selectedClass) {
      setSelectedClass("");
    } else {
      setSelectedClass(value);
    }
  }, [selectedClass]);

  useEffect(() => {
    let newParams = {
      outlet: outletId,
      member__attributes__class: selectedClass,
      ordering: sorting,
      page: page,
      page_size: rowsPerPage,
    };

    if (showDebtsOnly === true) {
      newParams["balance__lt"] = 0;
    }

    if (nonUserOnly === true) {
      newParams["user__isnull"] = nonUserOnly;
    }
    
    getAllCustomers(newParams)
  }, [selectedClass, nonUserOnly, sorting, showDebtsOnly]);


  const countPage = Math.ceil(count / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage + 1;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, count);

  return (
    <div>
      <div className="w-full flex justify-end">
        <div className="flex mb-4 items-center gap-x-2">
          <button
            className={`
              flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
              ${selectedCustomerForUnpair.length ? 'bg-[#FC581D] cursor-pointer ' : 'bg-[#FC581D26] cursor-default'}
            `}
            name="unpairCollection"
            type="button"
            disabled={selectedCustomerForUnpair.length === 0 ? true : false}
            onClick={submitUnpairCustomerUser}
          >
            Unpair Customer Login
          </button>
          <button
            className={`
              flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
              ${selectedCustomerForUnpair.length === 0 ? 'bg-[#2c6d47] cursor-pointer ' : 'bg-[#2c6d4723] cursor-default'}
            `}
            name="collectionPair"
            type="button"
            disabled={selectedCustomerForUnpair.length > 0 ? true : false}
            onClick={() =>
              dispatch(
                handleModal({
                  modalId: clientId,
                  componentName: 'pairCustomerLogin',
                  modalData: {
                    outletId: outletId,
                  },
                })
              )
            }
          >
            Pair Customer Login
          </button>
        </div>
      </div>
      <div className="w-full flex">
        <div className="mb-2 flex gap-x-4">
          <div className="w-[15rem]">
            <div>
              <p className="font-bold">Filter By Class</p>
            </div>
            <SelectAutocomplete
              options={classesList.map((dt) => ({ name: dt, value: dt }))}
              defaultValue={selectedClass}
              onChange={(event, newValue) => {
                setSelectedClass(newValue);
              }}
              placeHolder="Select Class"
              variant="outlined"
              isLoading={false}
              sx={{
                minWidth: '100%',
                width: '100%',
                px: 0.2,
                py: 0.6,
                background: 'white',
              }}
              errors={null} 
            />
          </div>
          <div className="w-[15rem]">
            <div>
            <p className="font-bold">Sorting</p>
            </div>
            <MuiSelectSingleItem
              name="sorting"
              label="sorting"
              OptionLabel="sorting"
              initialDisabled={true}
              control={control}
              options={sortingOptions.sort((a, b) => a.name.localeCompare(b.name))}
              defaultValue={sorting}
              onChangeValue={(e) => {
                setSorting(e);
              }}
              isInputLabel
            />
          </div>
          {/* <div>
            <div>
              <p className="font-bold">Negatives First</p>
            </div>
            <Checkbox checked={sortByNegativeBalance} onChange={(e) => {setSortByNegativeBalance(e.target.checked)}} />
          </div> */}
          <div>
            <div>
              <p className="font-bold">Without Login</p>
            </div>
            <Checkbox checked={nonUserOnly} onChange={(e) => SetNonUserOnly(e.target.checked)} />
          </div>
          <div>
            <div>
              <p className="font-bold">Show Debts</p>
            </div>
            <Checkbox checked={showDebtsOnly} onChange={(e) => setShowDebtsOnly(e.target.checked)} />
          </div>
        </div>
      </div>
      {isFetchingCustomers ? (
        <CircularProgress />
      ) : (
        <>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="!font-bold">
                    Student ID
                  </TableCell>
                  <TableCell align="center" className="!font-bold">
                    Name
                  </TableCell>
                  <TableCell align="center" className="!font-bold" width={200}>
                    Class
                  </TableCell>
                  <TableCell align="center" className="!font-bold">
                    Balance
                  </TableCell>
                  <TableCell align="center" className="!font-bold">
                    Contact
                  </TableCell>
                  <TableCell align="center" className="!font-bold" width={150}>
                    Customer Login
                  </TableCell>
                  <TableCell align="center" className="!font-bold" width={200}>
                    Last Login
                  </TableCell>
                  <TableCell align="center" className="!font-bold" width={100}>
                    On Unpair
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedOutletCustomers.length > 0 &&
                  sortedOutletCustomers.map((coll, idx) => {
                    const student_id = coll?.member?.on_list_attributes?.student_id || '-';
                    const name = coll?.member?.name || '-';
                    const className = coll?.member?.on_list_attributes?.class || '-';
                    const balance = coll?.balance || '-';
                    const contact = formatContact(coll?.member?.on_list_attributes?.contact);
                    const isItemSelected = isSelected(coll.id);
                    const labelId = `enhanced-table-checkbox-${idx}`;

                    // row should have red background if balance < 0
                    const rowClass = parseFloat(balance) < 0 ? 'bg-red-200' : '';

                    return (
                      <TableRow key={idx} className={rowClass}>
                        <TableCell align="center">{student_id}</TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{className}</TableCell>
                        <TableCell align="right">{formatNominal(balance)}</TableCell>
                        <TableCell align="right">{contact}</TableCell>
                        <TableCell align="center">
                          <div style={{ width: "fit-content", display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: 12 }}>
                            {
                              coll.user === null ? (
                                <CloseIcon
                                  color="error" 
                                  sx={{ 
                                    stroke: 'currentColor', 
                                    strokeWidth: 1
                                  }} 
                                />
                              ) : (
                                <CheckIcon
                                  sx={{ 
                                    color: "#8FD14F",
                                    stroke: 'currentColor', 
                                    strokeWidth: 1
                                  }} 
                                />
                              )
                            }
                            <div 
                              data-tooltip-id={'tooltip-update-user'} 
                              data-tooltip-content="update user" 
                              data-tooltip-variant="light" 
                              className="flex items-center"
                              style={{ marginLeft: 8 }}
                            >
                              <KeyIcon 
                                className='cursor-pointer'
                                sx={{ 
                                  transform: 'scaleX(-1) rotate(45deg)',
                                  color: "#F47403"
                                }}
                                onClick={() =>
                                  dispatch(
                                    handleModal({
                                      modalId: clientId,
                                      componentName: 'updateCustomerData',
                                      modalData: {
                                        outletId: outletId,
                                        customer: coll
                                      },
                                    })
                                  )
                                }
                              />
                            </div>
                            <ReactTooltip className="!z-[100] absolute w-fit !bg-white shadow-md" id="tooltip-update-user" place="bottom" />
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {coll.user !== null && coll.user.last_login ? moment(coll.user.last_login).format('DD MMMM  YYYY, h:mm:ss a') : ""}
                        </TableCell>
                        <TableCell align="center">
                          {coll.user !== null && <Checkbox  onClick={(event) => handleClick(event, coll.id)} color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <div className="my-5 flex items-center justify-end gap-x-5">
              <TablePagination
                rowsPerPageOptions={[15, 20, 25, 50, 75, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={(e, val) => 
                  handleChangePage(e, val)
                }
                onRowsPerPageChange={(e) => handleChangeRowsPerPage(e)}
                labelDisplayedRows={({ from, to, count }) => {
                  return (
                    <span>
                      <span className="">
                        {' '}
                        Page {page} of {countPage} -
                      </span>{' '}
                      Showing {startIndex} to {endIndex} of {count} {'rows'}
                    </span>
                  );
                }}
              />
            </div>
          </Paper>
        </Box>
        </>
      )}
      <ModalWrapper componentName="pairCustomerLogin" header="Pair Customer Login" maxWidth="lg">
        <PairCustomerLogin formatNominal={formatNominal} formatContact={formatContact} setIsFetchingCustomers={setIsFetchingCustomers} getAllCustomers={getAllCustomers} />
      </ModalWrapper>
      <ModalWrapper componentName="updateCustomerData" header="Update Customer" maxWidth="md">
        <UpdateUser setIsFetchingCustomers={setIsFetchingCustomers} getAllCustomers={getAllCustomers} />
      </ModalWrapper>
    </div>
  );
}

export default OutletDetailCustomersTab
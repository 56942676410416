import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import store from './store';
import AuthProvider from './components/AuthProvider';
import { fallbackErrorRender } from './components/ErrorBoundary/ErrorBoundary';
import './i18n';

import App from './App';
import './assets/css/bz-style.scss';
import './assets/css/tailwind.css';
import './index.css';

const queryClient = new QueryClient({
  // Config
  defaultOptions: { queries: { refetchOnWindowFocus: false, refetchOnMount: false, refetchOnReconnect: false, retry: false, staleTime: 1000 * 60 * 60 * 24 } },
});

const container = document.getElementById('root');
const root = createRoot(container);

const mode = import.meta.env.VITE_MODE;

['development', 'production'].includes(mode) && !['localhost', '127.0.0.1'].includes(window.location.hostname);
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_KEY,
  environment: mode,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [/Each child/i, /validateDOMNesting/i, /NaN/i, /recognize/i, /Maximum call/i, /Request failed with/i, /AxiosError/i],
});

root.render(
  <ErrorBoundary fallbackRender={fallbackErrorRender}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {/* <React.StrictMode> */}
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
          {/* </React.StrictMode> */}
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </ErrorBoundary>
);

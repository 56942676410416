/* eslint-disable */
import React from 'react';
import { useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import CircularProgress from '@mui/material/CircularProgress';
import { handleModal } from '../../../store/slices/modalSlice';

const CustomLoadingIndicator = (props) => (
  <div className='mr-2 flex items-center'>
    <CircularProgress size={20} color="primary" />
  </div>
);

const CustomOption = (props) => (
  <div>
    <components.Option {...props} className="flex ">
      <input className="invisible" type="checkbox" checked={props.isSelected} onChange={() => null} />
      <label>{props.label}</label>
    </components.Option>
  </div>
);


const { ValueContainer, Placeholder } = components;

export default function CustomSelectRedesign({
  options = [],
  defaultValue = null,
  onChange,
  isMulti = false,
  objectModal,
  getOptionLabel,
  getOptionValue,
  placeholder,
  isSearchable,
  customDelete,
  actionDelete,
  componentEditName,
  errors,
  disabled,
  className,
  isLoading,
}) {
  const dispatch = useDispatch();

  return (
    <Select
      className={className}
      menuPosition="fixed"
      menuPlacement="auto"
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      options={options}
      isLoading={isLoading}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onChange={onChange}
      defaultValue={defaultValue}
      value={defaultValue}
      components={{ 
        Option: CustomOption, 
        LoadingIndicator: CustomLoadingIndicator
      }}
      isSearchable={isSearchable}
      isMulti={isMulti}
      isDisable={disabled}
      placeholder={placeholder}
      renderValue={(selected) => selected.join(' ')}
      styles={{
        option: (provided, state) => ({
          ...provided,
          // backgroundColor: state.isFocused ? "rgba(44, 109, 71, 0.30)" : provided.backgroundColor,
          // background: state.isSelected ? "#2C6D47" : provided.background,
          // '&:active': {
          //   background: '#2C6D47',
          //   color: 'white',
          // },
          // '&:focus': {
          //   background: '#2C6D47',
          //   color: 'white',
          // },
        }),
        control: (provided, state) => ({
          ...provided,
          minHeight: '2.5rem',
          // boxShadow: state.isFocused ? '0 0 0 1px #2C6D47' : provided.boxShadow,
          // borderColor: state.isFocused ? '0 0 0 1px #2C6D47' : provided.borderColor,
          // '&:hover': {
          //   borderColor: '#2C6D47'
          // },
        }),
        container: (provided) => ({
          ...provided,
        }),
        valueContainer: (provided) => ({
          ...provided,
          overflow: 'visible',
          display: 'flex',
          alignItems: 'center',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          position: 'absolute',
          top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
          transition: 'top 0.1s, font-size 0.1s',
          fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
          background: 'white',
          margin: '0px 20',
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 10000,
          marginTop: '-10px',
        }),
      }}
    />
  );
}

/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router';
import _ from 'lodash';

import { Face } from '@mui/icons-material';

import { Button, FormControlLabel, FormLabel, FormControl, Radio, RadioGroup, Tooltip, Switch } from '@mui/material';
import InputContainer from '../../Input/InputContainer';
import { fetchGroups } from '../../../hooks/useUsersData';
import { schemaUserCreate, schemaUserUpdate } from './validation';
import useGet from '../../../hooks/useGet';

function filterArray(array1, array2) {
  const desiredArry = array1?.filter((item1) => !array2?.some((item2) => item1?.id === item2?.id));
  return desiredArry?.map((subArr) => subArr.id);
}

function UserForm({ defaultValues, onFormSubmit }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: defaultValues ? schemaUserUpdate : schemaUserCreate });
  const [isActive, setIsActive] = useState(defaultValues?.is_active);
  const [filesAvatar, setFilesAvatar] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [showCfPass, setShowCfPass] = useState(false);
  const [handlePass, setHandlePass] = useState(false);
  const [subsGroup, setSubsGroup] = useState([]);

  const subsActive = useSelector((state) => state.client.clientSubscriptions);
  const clientId = useSelector((state) => state.client.activeClient);
  const userLogin = useSelector((state) => state.auth.user.id);

  const userSubs = useSelector((state) => state.auth.isGroups);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const navigate = useNavigate();

  const subs = subsActive?.map((s) => {
    return `subscription=${s}&`;
  });

  const subId = subs.join('');
  const { data: subsData } = useQuery(['subs', { subId }], fetchGroups);

  const adminIds = subsData?.data.results
    .filter((group) => {
      const firstWord = group.name.split(' ')[0];
      if (firstWord.toLowerCase() === 'admin') {
        return true;
      }
      return false;
    })
    .map((group) => group.id);

  const isAdminUser = (() => {
    return _.intersection(adminIds, userSubs || []).length > 0;
  })();

  const isRegularUser = (() => {
    if (isSuperUser || isAdminUser) {
      return false;
    }
    return true;
  })();



  const isDisableStatus = (() => {
    if (userLogin === defaultValues?.id) {
      return true;
    }
    if (isRegularUser) {
      return true;
    }
    if (isRegularUser) {
      return true;
    }
    return false;
  })();

  const devSubs = subsData?.data?.results.filter(({ id: id1 }) => defaultValues?.groups?.some(({ id: id2 }) => id2 === id1));

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(subsGroup);
      newSelection.push(parseInt(value));
      setSubsGroup(newSelection);
      console.log('🚀 ~ file: UserForm.jsx:97 ~ handleChange ~ newSelection:', newSelection);
    } else {
      const newSelection = subsGroup;
      const newSubs = newSelection.filter((sub) => parseInt(sub) !== parseInt(value));
      console.log('🚀 ~ file: UserForm.jsx:95 ~ handleChange ~ newSubs:', newSubs);

      setSubsGroup(newSubs);
    }
  };

  const handleChangeActive = (event) => {
    setIsActive(event.target.value);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (defaultValues) {
      setSubsGroup(devSubs?.map((sub) => sub.id) || []);
    }
  }, [defaultValues]);

  const onSubmit = async (data) => {
    const desiredArray = filterArray(defaultValues?.groups, devSubs);

    const mergeSubs = defaultValues ? subsGroup.concat(desiredArray) : subsGroup;
    console.log('🚀 ~ file: UserForm.jsx:125 ~ onSubmit ~ mergeSubs:', mergeSubs);
    const formData = new FormData();

    formData.append('client', clientId);
    formData.append('site', 1);
    formData.append('name', data.name ?? '');
    formData.append('email', data.email ?? '');
    formData.append('is_active', isActive);
    if (data.password) {
      formData.append('password', data.password);
      formData.append('confirm_password', data.confirm_password);
    }
    if (filesAvatar) {
      formData.append('avatar', filesAvatar[0]);
    }

    mergeSubs.forEach((gr) => {
      gr && formData.append('groups', gr);
    });

    onFormSubmit(formData);
  };

  const dropZoneAttachments = useDropzone({
    // accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const fileAttacths = Object.assign(acceptedFiles, { preview: URL.createObjectURL(acceptedFiles[0]) });
      setFilesAvatar(fileAttacths);
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-1">
        <div className="flex w-full">
          <div className="w-6/12">
            <div className="w-full">
              <InputContainer name="name" control={control} label="Name" errors={errors.name} />
              <InputContainer name="email" control={control} label="Email" errors={errors.email} />
            </div>

            {/* {errors && <span className="capitalize text-xs text-[#d32f2f] text-left flex justify-start pl-6 pt-2">{errors?.message}</span>} */}

            <table className="table">
              <tbody>
                {!(isSuperUser && userLogin === defaultValues?.id) &&
                  subsData?.data?.results.map(
                    (sub) =>
                      (isSuperUser || isAdminUser || (isRegularUser && userSubs.includes(sub.id))) && (
                        <tr key={sub.id} className="">
                          <th align="left" className="p-3">
                            {sub.name}
                          </th>
                          <td className="flex items-center p-3">
                            <span className=" text-sm font-medium text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                            <label className="relative inline-flex items-center  cursor-pointer">
                              <label className="relative inline-flex items-center  cursor-pointer">
                                <Switch
                                  value={sub.id}
                                  onChange={handleChange}
                                  type="checkbox"
                                  defaultChecked={devSubs.find((ds) => ds.id === sub.id)}
                                  disabled={
                                    defaultValues &&
                                    (isRegularUser ||
                                      (userLogin === defaultValues?.id &&
                                        subsGroup.includes(sub.id) &&
                                        adminIds.includes(sub.id) &&
                                        _.intersection(subsGroup, adminIds).length < 2))
                                  }
                                />
                              </label>
                              <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Active</span>
                            </label>
                          </td>
                        </tr>
                      )
                  )}
              </tbody>
            </table>

            <div>
              <div className="mt-5">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                  <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={isActive} onChange={handleChangeActive}>
                    <div>
                      <div>
                        <FormControlLabel value={true} control={<Radio />} label="Active" disabled={isDisableStatus} />
                        <FormControlLabel value={false} control={<Radio />} label="Inactive" disabled={isDisableStatus} />
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
              {defaultValues ? (
                <div className="">
                  <Button type="button" variant="outlined" className='bz-btn' onClick={() => setHandlePass(!handlePass)}>
                    Update Password
                  </Button>
                  {handlePass && (
                    <div className="w-full space-y-1">
                      <InputContainer
                        showPass={showPass}
                        handleClickShowPassword={() => setShowPass(!showPass)}
                        type="password"
                        name="password"
                        control={control}
                        label="Password"
                        errors={errors.password}
                      />
                      <InputContainer
                        handleClickShowPassword={() => setShowCfPass(!showCfPass)}
                        type="password"
                        showCfPass={showCfPass}
                        name="confirm_password"
                        control={control}
                        label="Confirmation Password"
                        errors={errors.confirm_password}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="w-full space-y-1">
                  <InputContainer
                    showPass={showPass}
                    handleClickShowPassword={() => setShowPass(!showPass)}
                    type="password"
                    name="password"
                    control={control}
                    label="Password"
                    errors={errors.password}
                  />
                  <InputContainer
                    handleClickShowPassword={() => setShowCfPass(!showCfPass)}
                    type="password"
                    showCfPass={showCfPass}
                    name="confirm_password"
                    control={control}
                    label="Confirmation Password"
                    errors={errors.confirm_password}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="relative w-6/12 my-10 flex justify-center">
            <div className=" ">
              <Tooltip title="Attach a file image">
                <div className="" {...dropZoneAttachments.getRootProps()} aria-hidden>
                  {filesAvatar ?? defaultValues ? (
                    <img src={filesAvatar?.preview ?? defaultValues?.avatar} alt="" className="w-40 h-40 rounded-full mb-5 mx-auto" />
                  ) : (
                    <Face className="w-40 h-40 text-[#E98736] mb-5" />
                  )}
                  <input {...dropZoneAttachments.getInputProps()} />
                  <div className="text-center bg-gray-300 py-2 rounded-2xl w-fit px-6">Choose Your Ava</div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="bz-btn secondary" variant="outlined">
            Cancel
          </Button>
          <Button className="bz-btn" type="submit" variant="outlined">
            {defaultValues ? 'Update User' : 'Create User'}
          </Button>
        </div>
      </div>
    </form>
  );
}
export default UserForm;

/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  isOpenDrawer: false,
  incrementSIdebar: 0,
  activeTabs: 0,
  activeSide: null,
  childComponents: null,
  activeHeader: 'base', // *base, project, team
  childItem: { id: '', item: [] },
};

const sidebarTaskSlice = createSlice({
  name: 'sidebarTask',
  initialState,
  reducers: {
    handleActiveSide: (state, action) => {
      const currState = state;
      currState.activeSide = action.payload.name;
      currState.childComponents = action.payload.components;
    },
    handleToggleDrawer: (state, action) => {
      const currState = state;
      currState.isOpenDrawer = !currState.isOpenDrawer;
    },
    handleIncrementSidebar: (state, action) => {
      const currState = state;
      currState.incrementSIdebar = currState.incrementSIdebar + 1;
      // currState.incrementSIdebar = action.payload;
    },
    handleOpenDrawer: (state, action) => {
      const currState = state;
      currState.isOpenDrawer = true;
    },
    handleCloseDrawer: (state, action) => {
      const currState = state;
      currState.isOpenDrawer = false;
      currState.activeSide = null;
    },
    handleSideBar: (state) => {
      const currState = state;
      currState.isOpen = !currState.isOpen;
    },
    handleCloseSideBar: (state) => {
      const currState = state;
      currState.isOpen = false;
    },
    setChildItemRdx: (state, action) => {
      const currState = state;
      currState.childItem = action.payload;
    },
    setActiveHeader: (state, action) => {
      const currState = state;
      currState.activeHeader = action.payload;
    },
    setActiveTabs: (state, action) => {
      const currState = state;
      currState.activeTabs = action.payload;
    },
  },
});

export const {
  handleToggleDrawer,
  handleIncrementSidebar,
  handleOpenDrawer,
  handleCloseDrawer,
  handleCloseSideBar,
  handleActiveSide,
  handleSideBar,
  setActiveHeader,
  setActiveTabs,
} = sidebarTaskSlice.actions;

export default sidebarTaskSlice.reducer;

/* eslint-disable */

import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { useParams } from 'react-router';
import { DevTool } from '@hookform/devtools';
import { handleModal } from '../../../store/slices/modalSlice';
import { useEmployeeData } from '../../../hooks/useHRStructuresData';
import MuiSelectSingleItem from '../../Input/MuiSelectSingleItem';
// import { schemaStructure } from './validationStructure';

function EmployeeStructureForm({ defaultValues, onFormSubmit }) {
  const { hrStructureId } = useParams();
  const dispatch = useDispatch();
  const parentId = useSelector((state) => state.modal.modalId);
  const isModal = useSelector((state) => state.modal);
  const { control, handleSubmit } = useForm({ defaultValues: { employee: defaultValues?.employee?.id } });

  const { data: Employees } = useEmployeeData();
  console.log('🚀 ~ file: EmployeeHRStructureForm.jsx:22 ~ EmployeeStructureForm ~ Employees', Employees);

  const optionEmployees = Employees?.data?.results?.map((item) => ({
    name: item?.employee?.first_name,
    value: item?.employee?.id,
  }));

  console.log(parentId);

  const onSubmit = (data) => {
    console.log;
    const parentSelected = isModal.componentName === 'addChildrenEmpHRStructure' ? parentId : defaultValues?.parent;

    onFormSubmit({ parent: parentSelected, employee: data.employee, structure: hrStructureId });
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <MuiSelectSingleItem
          name="employee"
          label="Select Employee"
          control={control}
          options={optionEmployees ?? []}
          // onClick={onChangePosition}
        />

        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="flex" variant="outlined">
            Cancel
          </Button>
          <Button className=" flex" type="submit" variant="outlined">
            {defaultValues ? 'Update Structure' : 'Create Structure'}
          </Button>
        </div>
      </div>
      <DevTool control={control} />
    </form>
  );
}
export default EmployeeStructureForm;

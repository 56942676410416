/* eslint-disable */
import BaseSearchButton from '@/components/Button/BaseSearchButton';
import useGetFilter from '@/hooks/useGetFilter';
import BZHelmet from '@/utils/BZHelmet';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import ClientList from './ClientList';

export default function Client() {
  const [topicPerPage, setTopicPerPage] = useState(15);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const [searchValue] = useDebounce(search, 300);

  const queryParams = {
    page: page,
    page_size: topicPerPage,
    search: searchValue,
  };

  const { data: ClientsData, refetch } = useGetFilter({
    url: `clients`,
    queryString: queryParams,
    validate: 'clients',
  });

  const currentTopic = ClientsData?.data?.results;
  console.log('🚀 ~ Client ~ ClientsData:', ClientsData);

  useEffect(() => {
    setPage(1);
    // refetch();
  }, [searchValue]);

  const onSearchClient = (event) => {
    // setPage(1);
    setSearch(event.target.value);
  };

  return (
    <>
      <BZHelmet title="Clients" description="bz publish task management clients" />
      <div className="w-full max-w-[1200px] py-4 px-4  mx-auto">
        <div className="space-y-8">
          <div className="mr-6 text-5xl font-extrabold leading-[125%]">Clients</div>
          <div name="FILTER-FIELD" className="space-y-4 md:space-y-0 md:flex justify-between ">
            <BaseSearchButton onChange={onSearchClient} defaultValue={search} label="search" />
          </div>
        </div>

        <div className="mt-8">
          <ClientList
            clients={currentTopic}
            page={page}
            count={ClientsData?.data?.count}
            rowsPerPage={topicPerPage}
            setRowsPerPage={setTopicPerPage}
            setPage={setPage}
            // onDestroyClient={onDestroyClient}
          />
        </div>
      </div>
    </>
  );
}

import React, { useContext, useState } from 'react';
import { Box, Button, ButtonBase, Fade, Popover } from '@mui/material';
import { Article, KeyboardArrowDown } from '@mui/icons-material';
import { format } from 'date-fns';
import parse from 'html-react-parser';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import BoardsContext from '../../../../pages/Task/Boards/BoardsContext';
import projectApi from '../../../../api/task/project';
import styles from './DrawerTask.module.css';
import GetAvatar from '../Content/GetAvatar';
import moment from 'moment';

const isHtml = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

export default function Comment({ comment, isUsername, getTopicDetails, index, setPopoverAttachments, isTopic = true, editorConfigs }) {
  const myContext = useContext(BoardsContext);
  const [anchorAction, setAnchorAction] = useState(false);
  const [commentDetail, setCommentDetail] = useState(comment.detail);
  const [isEditMode, setEditMode] = useState(false);
  

  const handleClickAction = (event) => {
    setAnchorAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorAction(null);
  };

  const openAction = Boolean(anchorAction);
  const idAction = openAction ? 'simple-popover' : undefined;

  const handleDeleteComment = () => {
    const { deleteTopicComment } = projectApi();
    deleteTopicComment(comment.id)
      .then(() => {
        setAnchorAction(null);
        getTopicDetails();
        myContext.getProject();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const toggleEditComment = () => {
    setEditMode(!isEditMode);
    setAnchorAction(null);
  };

  const handleUpdateComment = () => {
    const { updateTopicComment } = projectApi();
    updateTopicComment(comment.id, { detail: commentDetail })
      .then(() => {
        setEditMode(false);
        getTopicDetails();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  let comments;
  if (comment?.attachments?.length > 0) {
    comments = comment.attachments.map((c) => {
      const fileExt = c.file_attached.substring(c.file_attached.lastIndexOf('.') + 1);
      const fileName = c.file_attached?.substring(c.file_attached.lastIndexOf('/') + 1);
      const imgTypes = ['jpg', 'jpeg', 'png', 'webp', 'svg'];
      let html;
      if (!imgTypes.includes(fileExt.toLowerCase())) {
        html = (
          <a key={c.file_attached} href={c.file_attached} target="_blank" rel="noreferrer">
            <div className="relative bg-[#eaeaea] py-1 pl-2 pr-8 border border-[#eaeaea] rounded-md h-16 min-w-[64px] max-w-[192px] flex items-center shadow-md">
              <div className="flex space-x-1 items-center">
                <Article color="disabled" fontSize="large" />
                <div className="flex flex-col max-w-[112px]">
                  <span className="text-ellipsis text-sm whitespace-nowrap overflow-hidden">{fileName?.substring(0, fileName.indexOf('.'))}</span>
                  <span className="text-xs text-gray-500">{`${fileExt} File`}</span>
                </div>
              </div>
            </div>
          </a>
        );
      } else {
        html = (
          <div key={c.file_attached} onClick={() => setPopoverAttachments(index)} aria-hidden>
            <img className="object-cover h-auto max-h-[480px] max-w-[100%]" src={c.file_attached} alt="" />
          </div>
        );
      }
      return html;
    });
  }

  const commentData = isHtml(comment.detail || comment.comment) ? (
    <div className={`${styles.taskNote} ${!isTopic ? "pl-2" : ""}`}>
      {parse(comment.detail || comment.comment)}
    </div>
  ) : (
    <span className={`${styles.taskNote} ${!isTopic ? "pl-2" : ""}`}>
      {comment.detail || comment.comment}
    </span>
  );

  return (
    <div key={comment.id} className={isTopic ? 'flex flex-col pl-7 pr-6 my-4' : 'flex flex-col gap-2'}>
      {isTopic && (
        <div className="w-full flex items-center py-4">
          <div className="w-full flex items-center space-x-2">
            <GetAvatar data={comment} onClick={() => {}} subject="creator" />
            <span>
              <b>{comment?.creator?.name}</b>
            </span>
            {comment.created_at && <span>{format(new Date(comment.created_at), 'MMM d, yyyy')}</span>}
          </div>

          {isUsername === comment.creator.name && (
            <div className="ml-auto">
              <KeyboardArrowDown onClick={handleClickAction} />
            </div>
          )}
        </div>
      )}
      {isEditMode && (
        <div className="flex flex-col ml-8 border border-gray-300 rounded-md">
          {/* <InputBase autoFocus multiline value={commentDetail} onChange={(event) => setCommentDetail(event.target.value)} className="p-2" /> */}
          <CKEditor
            id="comment-update"
            key="comment-update"
            placeholder="Ask a question or post an updates"
            className="p-2"
            editor={Editor}
            config={{ ...editorConfigs, toolbar: { items: [] } }}
            data={commentDetail}
            onChange={(_, editor) => {
              setCommentDetail(editor.getData());
            }}
          />

          <ButtonBase className="ml-auto mt-2 mr-2 mb-2 py-1 px-2 bg-blue-500 text-white text-sm rounded-xl" onClick={handleUpdateComment}>
            Update
          </ButtonBase>
        </div>
      )}
      <div className={isTopic ? 'relative flex flex-col space-y-2 pl-8' : 'flex flex-col mb-4'}>
          {!isTopic && (
            <div className="flex">
              <span className="mr-2">{comment.user.username}</span>
              <span className="mr-2 text-[#6D6E6F]">{moment(comment.timestamp).format('MM/DD/YYYY hh:mm A')}</span>
            </div>
          )}
        {(comment.detail || comment.comment) && !isEditMode && commentData}
        {comments}
      </div>
      <Popover
        id={idAction}
        open={openAction}
        anchorEl={anchorAction}
        onClose={handleCloseAction}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ transform: 'translate(-24px, 4px)' }}
      >
        <Fade in={openAction}>
          <Box style={{ minWidth: '180px', color: '#fff' }} className="relative flex flex-col p-2 space-y-2 rounded-lg bg-[#ffffff]">
            <Button className="px-2 flex justify-start text-black relative" onClick={toggleEditComment}>
              Edit Comment
            </Button>

            <Button className="px-2 flex justify-start text-red-500" onClick={handleDeleteComment}>
              Delete Comment
            </Button>
          </Box>
        </Fade>
      </Popover>
    </div>
  );
}

import request from '../helper/axiosUtil';

export const fetchTeams = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({ url: `/project-management/teams/?client=${clientId}` });
};

export const createTeams = (data) =>
  request({
    url: '/project-management/teams/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const updateTeams = async (id, data) =>
  request({
    url: `/project-management/teams/${id}/`,
    method: 'patch',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const delTeamUsers = (data) =>
  request({
    url: '/project-management/team_users/delete_bulk/',
    method: 'delete',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

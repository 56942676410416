import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';

import DetailContext from '../../../../pages/Task/Projects/DetailContext';

export default function TopicSection({ open, data, sections, onClose, onCreateTopicStatus, onUpdateTopicStatus }) {
  const { projectId: project } = useContext(DetailContext);

  const [name, setName] = useState('');
  const [direction, setDirection] = useState('after');
  const [index, setIndex] = useState(data?.data?.index || 1);
  const [isChanged, setChanged] = useState(false);
  const [isHidden, setHidden] = useState(false);

  const handleClose = () => {
    onClose();
    setName('');
    setIndex(1);
    setDirection('after');
  };

  const actionOnTopic = () => {
    if (name) {
      const indexed = direction === 'after' ? index : index - 1;
      if (data.mode) {
        const dataTopic = {
          project,
          name,
          index: indexed,
          is_hidden: isHidden,
        };
        onCreateTopicStatus(dataTopic);
      } else {
        const dataTopic = { name, is_hidden: isHidden };
        if (isChanged) {
          dataTopic.index = indexed;
        }
        onUpdateTopicStatus(dataTopic, data.data.id);
      }

      handleClose();
    }
  };

  const handleSwitch = (e) => {
    setHidden(e.target.checked);
  };

  useEffect(() => {
    if (!data.mode) {
      setName(data?.data.name || '');
      setHidden(data?.data.is_hidden);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} sx={{ '& > .MuiDialog-container > .MuiPaper-root': { position: 'absolute', top: '25%' } }}>
      <DialogTitle>{data.mode ? 'Create New' : 'Update'} Topic Status</DialogTitle>
      <DialogContent>
        <Box className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-4 items-end">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={name}
              label="Name"
              fullWidth
              size="small"
              onChange={(e) => {
                setName(e.target.value);
                if (!data.mode) {
                  setChanged(true);
                }
              }}
            />
            <FormControl fullWidth size="medium">
              <InputLabel id="select-option">Option</InputLabel>
              <Select
                labelId="select-option"
                id="simple-option"
                value={direction}
                label="Option"
                onChange={(e) => {
                  setDirection(e.target.value);
                  if (!data.mode) {
                    setChanged(true);
                  }
                }}
              >
                <MenuItem value="after">After</MenuItem>
                <MenuItem value="before">Before</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="medium">
              <InputLabel id="select-index">Index</InputLabel>
              <Select labelId="select-index" id="simple-index" value={index} label="Index" onChange={(e) => setIndex(e.target.value)}>
                {sections?.length > 0 &&
                  sections.map((s) => (
                    <MenuItem key={s.id} value={s.index}>
                      {s.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControlLabel
              className="mr-auto ml-0"
              fullWidth
              size="medium"
              value="start"
              control={<Switch color="primary" onChange={handleSwitch} checked={isHidden} />}
              label="Is Hidden"
              labelPlacement="start"
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={actionOnTopic}>
          {data.mode ? 'Create' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/* eslint-disable */

import React from 'react';
import BaseMainTitle from '../../components/BaseMainTitle';
import BZHelmet from '../../utils/BZHelmet';
import TableAplicant from './TableApplicant';

export default function Applicant() {
  return (
    <div>
      <BZHelmet title="Applicant List" description="" />
      <BaseMainTitle title="Applicant List" />
      <div className="p-5">
        <TableAplicant />
      </div>
    </div>
  );
}

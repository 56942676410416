// /* eslint-disable */

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationPage = yup
  .object()
  .shape({
    name: yup.string().required('Name is required'),
    page_languages: yup.array().of(yup.object().shape({ name: yup.string().required('Name is required') })),
  })
  .required();

const schemaPage = yupResolver(validationPage);

export default schemaPage;

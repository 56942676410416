// /* eslint-disable */

import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import CustomTable from '../../../components/Table/CustomTable';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import useDelete from '../../../hooks/useDelete';
import { handleModal } from '../../../store/slices/modalSlice';
import usePost from '../../../hooks/usePost';
import useGet from '../../../hooks/useGet';
import BaseApplyButton from '../../../components/Button/BaseApplyButton';
import BaseDeleteButton from '../../../components/Button/BaseDeleteButton';
import BaseSearchButton from '../../../components/Button/BaseSearchButton';
import RenderResults from '../../../components/RenderResults';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import BaseEditButton from '../../../components/Button/BaseEditButton';
import ArticleCategoryForm from '../../../components/Form/Article/ArticleCategoryForm';
import BZButton from '../../../components/Button/BZButton';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },

  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },

  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

function ActionCell(props) {
  const { onClick, id } = props;
  const dispatch = useDispatch();
  return (
    <div className="w-full flex space-x-2">
      <BaseEditButton onClick={() => dispatch(handleModal({ modalId: id, componentName: 'editCategory' }))} />
      <BaseDeleteButton onClick={onClick} variant="icon" />
    </div>
  );
}

function TableCells(props) {
  const { row, labelId, ariaChecked, selected } = props;

  const destroyCategory = useDelete({
    confText: `modals.category_delete.confirmation_text`,
    confTitle: `modals.category_delete.title`,
    success: 'Category deleted',
    validate: ['categoryArticles'],
  });

  const onDestroyCategory = (id) => {
    destroyCategory(`category_articles/${id}`);
  };
  return (
    <TableRow hover role="checkbox" aria-checked={ariaChecked} tabIndex={-1} key={row.id} selected={selected}>
      <TableCell component="th" id={labelId} scope="row">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">
        <ActionCell id={row.id} onClick={() => onDestroyCategory(row.id)} />
      </TableCell>
    </TableRow>
  );
}

export default function CategoryTab() {
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const [dataCategory, setDataCategory] = useState([]);
  const [searchInput, setSearchInput] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const { isFetching } = useGet({
    url: `category_articles`,
    queryString: {
      search: searchInput,
      client: clientId,
      page,
      page_size: rowsPerPage,
    },
    validate: 'categoryArticles',
    onSuccess: (e) => {
      setCount(e?.data?.count);
      setDataCategory(e?.data?.results);
    },
    isEnabled: isDataLoad,
  });

  const { data: categoryDetail } = useGet({
    url: `category_articles/${modal?.modalId}`,
    validate: 'categoryArticle',
    isEnabled: modal.componentName === 'editCategory',
  });

  const onChangeSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const data = useMemo(() => dataCategory, [dataCategory]);

  function generateFilterComponent() {
    return (
      <div className="flex space-x-2 items-center">
        <BaseSearchButton onChange={onChangeSearch} setPage={setPage} setIsDataLoad={setIsDataLoad} />

        <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} />
      </div>
    );
  }

  const { mutate: postCategory } = usePost({ url: 'category_articles', validate: ['categoryArticles'] });

  const onPostCategory = async (postData) => {
    await postCategory(postData);
  };

  const { mutate: updateCategory } = usePatchOrPut({ url: `category_articles/${modal.modalId}`, validate: ['categoryArticles', 'categoryArticle'] });

  return (
    <div className="p-5">
      <div className="">
        <div className="bg-gray-100 w-full flex py-5 px-3 items-center">
          <div className="ml-auto flex gap-x-3">
            <BZButton variant="secondary" onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addCategory' }))}>
              Add Category
            </BZButton>
            <ModalWrapper componentName="addCategory" header="Add Category" maxWidth="sm">
              <ArticleCategoryForm onFormSubmit={onPostCategory} />
            </ModalWrapper>
            <ModalWrapper componentName="editCategory" header="Edit Category" maxWidth="sm">
              <ArticleCategoryForm onFormSubmit={updateCategory} defaultValues={categoryDetail?.data} key={categoryDetail?.data?.id} />
            </ModalWrapper>
          </div>
        </div>
        <div className=" py-4 my-4 border-y-2 ">{generateFilterComponent()}</div>
      </div>
      {!isFetching ? (
        <RenderResults
          condition={count > 0}
          component={
            <CustomTable
              dataApi={data}
              setIsDataLoad={setIsDataLoad}
              isFetching={!isFetching}
              headCells={headCells}
              ActionCell={ActionCell}
              TableCells={TableCells}
              page={page - 1}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={count}
            />
          }
        />
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

/* eslint-disable */
import React from 'react';
import Select, { components } from 'react-select';

const Option = (props) => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />
      <label>{props.label}</label>
    </components.Option>
  </div>
);

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => (
  <ValueContainer {...props}>
    <Placeholder {...props} isFocused={props.isFocused}>
      {props.selectProps.placeholder}
    </Placeholder>
    {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
  </ValueContainer>
);

export default function SelectMultiItem({ options = [], defaultValue = null, onChange, isMulti = false, getOptionLabel, getOptionValue, placeholder, isSearchable }) {
  return (
    <Select
      menuPosition="fixed"
      menuPlacement="auto"
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onChange={onChange}
      defaultValue={defaultValue}
      components={isMulti && { Option } && { ValueContainer: CustomValueContainer }}
      isSearchable={isSearchable}
      isMulti={isMulti}
      isDisable={false}
      placeholder={placeholder}
      styles={{
        container: (provided) => ({
          ...provided,
          // marginTop: 50,
        }),
        valueContainer: (provided) => ({
          ...provided,
          overflow: 'visible',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          position: 'absolute',
          top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
          transition: 'top 0.1s, font-size 0.1s',
          fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
          background: 'white',
          margin: '0px 20',
        }),
      }}
    />
  );
}

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { InputAdornment, FormControl, TextField, ButtonBase, Box, Popover, Fade, Autocomplete } from '@mui/material';
import { Launch, Save, PriorityHigh } from '@mui/icons-material';
import { getYear } from 'date-fns';
import { twMerge } from 'tailwind-merge';

import BaseInputFormatNumber from '@/components/Input/Base/BaseInputFormatNumber';
import Colors from '../../../constants/Colors';
import TransactionContext from './TransactionContext';

const indexRequired = [0, 1, 6];

const returnedFalse = (field) => {
  return {
    status: false,
    data: false,
    field,
  };
};

const isValueExist = (idx, arr, val, type, field, canEmpty) => {
  if (indexRequired.indexOf(idx) === idx) {
    if (!val || val === '0') {
      return returnedFalse(field);
    }
  }

  if (canEmpty && String(val) === '0') {
    return {
      status: true,
      data: '',
      field,
    };
  }

  let isExist;
  if (type === 'finance') {
    if (val.length === 4) {
      const year = val.substring(0, 2);
      const month = val.substring(2);
      const currYear = getYear(new Date()).toString().substring(0, 2);

      isExist = arr?.filter((itm) => Number(itm.accounting_period.month) === Number(month) && String(itm.accounting_period.year) === String(`${currYear}${year}`));
      if (isExist.length) {
        return {
          status: true,
          data: isExist[0],
          field,
        };
      }
      return returnedFalse(field);
    }
    return returnedFalse(field);
  }
  isExist = arr?.filter((itm) => String(itm.code).toLowerCase() === String(val).toLowerCase());
  if (isExist?.length) {
    return {
      status: true,
      data: isExist[0],
      field,
    };
  }
  return returnedFalse(field);
};

function LineDetailTransaction({ row, index, baseCurr, onChangeDC, setIsLineTransaction, generateLine, lnTransaction, onChangeLNDetail, onUpdateLineDetail, isSubmit }) {
  const transContext = useContext(TransactionContext);

  const [ln, setLn] = useState([]);
  const [line, setLine] = useState();
  const [isLnActivated, setLnActivated] = useState(false);
  const [isLnError, setLnError] = useState({
    status: false,
    data: false,
  });
  const [notifAnchor, setNotifAnchor] = useState(null);

  const onChangeLineTransaction = useCallback(
    (val) => {
      if (val) {
        setLine(val);
        const splitVal = val.split('-');
        if (splitVal.length !== 7) {
          setLnError(true);
          onChangeLNDetail(index, 'status', false, lnTransaction);
        }

        const statusCheck = [];
        splitVal.forEach((v, i) => {
          if (i === 0) {
            statusCheck.push(isValueExist(i, transContext.accounts, v, '', 'Account', 0));
          }
          if (i === 1) {
            statusCheck.push(isValueExist(i, transContext.costCenters, v, '', 'Center', 0));
          }
          if (i === 2) {
            statusCheck.push(isValueExist(i, transContext.counterparts, v, '', 'Counterpart', 1));
          }
          if (i === 3) {
            statusCheck.push(isValueExist(i, transContext.projectIds, v, '', 'Project Id', 1));
          }
          if (i === 4) {
            statusCheck.push({
              status: !!v,
              data: v,
              field: 'Recref',
            });
          }
          if (i === 5) {
            statusCheck.push(isValueExist(i, transContext.accPeriods, v, 'finance', 'Accper', 1));
          }
          if (i === 6) {
            statusCheck.push(isValueExist(i, transContext.currencies, v, '', 'Ledger Currency', 0));
          }
        });

        const canSave = statusCheck.length === 7 && statusCheck.every((stat) => stat.status);
        if (canSave) {
          setLnError({ status: false, data: false });
          const newDataLn = {
            account: statusCheck[0].data,
            center: statusCheck[1].data,
            counterpart: statusCheck[2].data,
            project_id: statusCheck[3].data,
            recref: statusCheck[4].data,
            accper: statusCheck[5].data,
            currency: statusCheck[6].data,
          };
          const newLn = {
            line: val.trim(),
            data: newDataLn,
            id: ln.id,
            status: true,
          };
          generateLine(newLn, 'update');
        } else {
          setLnError({ status: true, data: statusCheck });
          const newLn = {
            line: val,
            data: [],
            id: ln.id,
            status: false,
          };
          generateLine(newLn, 'update');
          // onChangeLNDetail(index, false, lnTransaction);
        }
      } else {
        setLine('');
        setLnError({ status: false, data: false });
        const newLn = {
          line: '',
          id: ln.id,
          status: true,
        };
        generateLine(newLn, 'update');
      }
    },
    [
      generateLine,
      index,
      ln.id,
      lnTransaction,
      onChangeLNDetail,
      transContext.accPeriods,
      transContext.accounts,
      transContext.costCenters,
      transContext.counterparts,
      transContext.currencies,
      transContext.projectIds
    ]
  );

  useEffect(() => {
    if (ln !== row) {
      setLn(row);
      setLine(row.line);
      onChangeLineTransaction(row.line);
    }
  }, [ln, row, onChangeLineTransaction]);

  useEffect(() => {
    if (row.line !== line && !isLnActivated) {
      setLine(row.line);
      setLnActivated(true);
      onChangeLineTransaction(row.line);
    }
  }, [row.line, line, isLnActivated, onChangeLineTransaction]);

  const handleNotifOpen = (event) => {
    setNotifAnchor(event.currentTarget);
  };
  const handleNotifClose = () => {
    setNotifAnchor(null);
  };

  const openNotif = Boolean(notifAnchor);

  const onSave = () => {
    if (isLnError.status) return;
    onUpdateLineDetail(index);
  };
  const isClosed = row.isClosed;

  return (
    <div className="relative">
      {isLnError.status && (
        <Box className="absolute top-2 left-[-2.5rem] h-4 w-4">
          <ButtonBase onMouseEnter={handleNotifOpen} onMouseLeave={handleNotifClose}>
            <div className="animate-ping h-4 w-4 flex justify-center items-center p-1 rounded-full border border-red-500">
              <PriorityHigh fontSize="8" className="text-red-500" />
            </div>
          </ButtonBase>
        </Box>
      )}
      <Popover
        id="notif-err-ln-popover"
        sx={{ pointerEvents: 'none' }}
        open={openNotif}
        anchorEl={notifAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleNotifClose}
        disableRestoreFocus
      >
        <Fade in={openNotif}>
          <Box className="relative flex flex-col p-2 space-y-2 rounded-lg bg-[#ffffff] shadow-lg">
            {isLnError.data &&
              isLnError.data.map((err, i) => {
                if (!err.status) {
                  return (
                    <div key={`${err.field + i}`}>
                      ({i}) - {err.field} - not found
                    </div>
                  );
                }
                return <div key={`${err.field + i}`} />;
              })}
          </Box>
        </Fade>
      </Popover>
      <div className="relative flex w-full space-x-4">
        <FormControl className="w-2/6">
          <TextField
            className="w-full"
            size="small"
            title="Detail"
            placeholder="Detail"
            label=""
            value={line}
            onChange={(e) => onChangeLineTransaction(e.target.value, index)}
            onPaste={(e) => onChangeLineTransaction(e.target.value, index)}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setIsLineTransaction({
                      id: row.id,
                      credit: row.credit,
                      debit: row.debit,
                      line: row.line,
                      rate: parseFloat(row.rate),
                      baseCurrency: row.baseCurrency,
                      balance_amount: row.balance_amount,
                      status: true,
                      data: row.data,
                    });
                    setLnActivated(false);
                  }}
                  className="cursor-pointer"
                  disablePointerEvents={isClosed}
                >
                  <Launch fontSize="14" />
                </InputAdornment>
              ),
            }}
            sx={{
              color: 'yellow',
              '& > .Mui-focused > .MuiOutlinedInput-notchedOutline': { borderWidth: 1 },
            }}
            disabled={isClosed}
          />
        </FormControl>
        <div className="w-4/6 flex space-x-4">
          <TextField
            size="small"
            type="number"
            className="w-1/5"
            title="Debit"
            placeholder="Debit"
            label="Debit"
            defaultValue={row.debit || ''}
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { textAlign: 'right' } }}
            disabled={isClosed}
            onBlur={(e) => onChangeDC('Debit', index, e.target.value, lnTransaction)}
          />
          <TextField
            size="small"
            type="number"
            className="w-1/5"
            title="Credit"
            placeholder="Credit"
            label="Credit"
            defaultValue={row.credit || ''}
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { textAlign: 'right' } }}
            disabled={isClosed}
            onBlur={(e) => onChangeDC('Credit', index, e.target.value, lnTransaction)}
          />
          <TextField
            size="small"
            type="number"
            className="lg:w-1/5 2xl:w-1/5 5xl:w-1/5"
            title="Transaction Amount"
            placeholder="Rate"
            label="Transaction Amount"
            value={row.transaction_amount}
            InputLabelProps={{ shrink: true }}
            disabled={true}
          />
          <TextField
            size="small"
            type="number"
            className="lg:w-1/5 2xl:w-1/5 5xl:w-1/5"
            title="Rate"
            placeholder="Rate"
            label="Rate"
            value={row.rate}
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { textAlign: 'right' } }}
            onChange={(e) => onChangeDC('Rate', index, e.target.value, lnTransaction)}
            disabled={isClosed || (row.data?.currency?.id || baseCurr) === row.baseCurrency}
          />
          <TextField
            value={+row.balance_amount}
            size="small"
            className="lg:w-1/5 2xl:w-1/5 5xl:w-1/5"
            title="Base Amount"
            placeholder="Base Amount"
            label="Base Amount"
            name="BaseAmount"
            id="BaseAmount"
            disabled
            InputLabelProps={{ shrink: true }}
            sx={{
              '& > .MuiInputLabel-root': { color: Math.abs(row.balance_amount) < 0 && Colors.primaryRed },
              '& > .Mui-disabled > .MuiOutlinedInput-notchedOutline': {
                borderColor: Math.abs(row.balance_amount) < 0 && Colors.primaryRed,
                borderWidth: Math.abs(row.balance_amount) < 0 && 2,
              },
            }}
            inputProps={{ style: { textAlign: 'right' } }}
            InputProps={{
              inputComponent: BaseInputFormatNumber
            }}
          />
          {/* <Autocomplete
            multiple={false}
            name="name"
            options={transContext.projectIds}
            autoHighlight
            getOptionLabel={(option) => option && `${option.name}`}
            isOptionEqualToValue={(option, newValue) => option.name === newValue.name}
            onChange={(event, dataOption) => {
              onChangeLNDetail(index, 'project_id', dataOption.name);
            }}
            defaultValue={transContext.projectIds?.find((p) => p.name === row.project_id)}
            noOptionsText="No project id"
            className="w-1/5"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Project id"
                size="small"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          /> */}
          {!isClosed && (
            <ButtonBase onClick={onSave} disabled={isSubmit || !!isLnError.status}>
              <div
                className={twMerge(
                  'flex items-center justify-center p-2 border rounded-md border-gray-300',
                  isSubmit || !!isLnError.status ? 'cursor-not-allowed bg-gray-100' : ''
                )}
              >
                <Save fontSize="14" className="text-gray-500" />
              </div>
            </ButtonBase>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(LineDetailTransaction);

/* eslint-disable */

import React, { useState, useMemo, createContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonBase, CircularProgress, TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { AttachFile, Cancel, Edit, Save, Visibility } from '@mui/icons-material';
import { useForm, useWatch } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import CustomTable from '../../../components/Table/CustomTable';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import useDelete from '../../../hooks/useDelete';
import { handleModal } from '../../../store/slices/modalSlice';
import usePost from '../../../hooks/usePost';
import useGet from '../../../hooks/useGet';
import BaseDeleteButton from '../../../components/Button/BaseDeleteButton';
import BaseSearchButton from '../../../components/Button/BaseSearchButton';
import RenderResults from '../../../components/RenderResults';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import ApplicationForm from '../../../components/Form/Job/ApplicationForm';
import ReactSelectSingle from '../../../components/Input/ReactSelectSingle';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import { fetchFilterApplication } from '../../../hooks/useRecruitmentsData';
import { resetStage } from '../../../store/slices/hr/stageSlice';
import BaseSubmitButton from '../../../components/Button/BaseSubmitButton';
import { handleFilter } from '../../../store/slices/filterComponentSlice';
import BZButton from '@/components/Button/BZButton';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'resume',
    numeric: false,
    disablePadding: false,
    label: 'Resume',
  },
  {
    id: 'stage',
    numeric: false,
    disablePadding: false,
    label: 'Stage',
  },

  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    headerAlign: 'center',
    label: 'Action',
  },
];

const ApplicationContext = createContext();

function getFileNameAndType(url) {
  return url.substring(url.lastIndexOf('/') + 1);
  // var fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
  // return { name: fileName, type: fileType };
}

function ActionCell(props) {
  const { onClick, id, watching } = props;

  const [editable, setEditable, setDataApplication] = React.useContext(ApplicationContext);

  const { mutate: patchStage } = usePatchOrPut({ url: `applications/${editable}`, validate: ['applications', 'application'] });

  const onPatchStage = async () => {
    await patchStage({ stage: watching });
    setEditable(true);
    // setDataApplication([]);
  };

  return (
    <div className="w-full flex space-x-2 justify-center">
      {editable === id && (
        <button onClick={() => setEditable(null)} className="text-orange-500">
          <Cancel />
        </button>
      )}
      {editable === id ? (
        <Button type="button" onClick={onPatchStage} className="bz-btn-tb">
          <Save />
        </Button>
      ) : (
        <ButtonBase onClick={() => setEditable(id)} type="button" className="bz-btn-tb">
          <Edit />
        </ButtonBase>
      )}
      <BaseDeleteButton onClick={onClick} variant="icon" />
    </div>
  );
}

function TableCells(props) {
  const { row, ariaChecked, selected } = props;
  // const clientId = useSelector((state) => state.client.activeClient);

  const { control } = useForm();
  const watchingStage = useWatch({
    name: `stage`,
    control,
  });

  const destroyApplication = useDelete({
    confText: `modals.application_delete.confirmation_text`,
    confTitle: `modals.application_delete.title`,
    success: 'Application deleted',
    validate: ['application'],
  });

  const onDestroyApplication = (id) => {
    destroyApplication(`applications/${id}`);
  };

  const [editable] = React.useContext(ApplicationContext);

  function getIsCurrent(list) {
    const transList = list?.filter((stage) => stage.is_current === true);
    return transList[0]?.id;
  }

  return (
    <TableRow hover role="checkbox" aria-checked={ariaChecked} tabIndex={-1} key={row.id} selected={selected}>
      <TableCell align="left">
        <Link to={`/hr/recruitment/application/show/${row.id}`} className="text-bz-green font-semibold">
          <div className="space-y-1">
            <div className="">{row.applicant.user.name || '-'}</div>
            <div className="font-bold">{row.job_name || '-'}</div>
          </div>
        </Link>
      </TableCell>
      <TableCell align="left">
        <div className="">{row.applicant.user.email || '-'}</div>
      </TableCell>
      <TableCell align="left">
        <div className="">{row.applicant.phone || '-'}</div>
      </TableCell>
      <TableCell align="left">
        {row.applicant?.resume ? (
          <a href={row.applicant?.resume} className="" target="blank">
            <div className="flex space-x-1 items-center">
              <AttachFile />
              <div className="">{getFileNameAndType(row.applicant?.resume)}</div>
            </div>
          </a>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell align="left">
        <div className="min-w-[200px]">
          {editable?.toString() === row.id.toString() ? (
            <ReactSelectSingle
              name="stage"
              control={control}
              isDisabled={!(editable?.toString() === row.id.toString())}
              options={getOptionSelectWithKey(row?.stages)}
              defaultValue={getDefaultSelectObject(getIsCurrent(row?.stages), getOptionSelectWithKey(row?.stages))}
              placeholder="Select Stage"
            />
          ) : (
            <div className="">
              <ReactSelectSingle
                name="stage"
                control={control}
                isDisabled
                options={getOptionSelectWithKey(row?.stages)}
                defaultValue={getDefaultSelectObject(getIsCurrent(row?.stages), getOptionSelectWithKey(row?.stages))}
                placeholder="Select Stage"
              />
            </div>
          )}
        </div>
      </TableCell>
      <TableCell align="left">
        <ActionCell id={row.id} onClick={() => onDestroyApplication(row.id)} watching={watchingStage} />
      </TableCell>
    </TableRow>
  );
}

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

function containsNonNullValues(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value !== null && typeof value !== 'undefined' && !(typeof value === 'string' && value.trim() === '') && !(typeof value === 'object' && !containsNonNullValues(value))) {
      return true;
    }
  }
  return false;
}

function findObjectInArray(array, targetKey) {
  for (let i = 0; i < array.length; i++) {
    const obj = array[i];

    if (typeof obj === 'object' && obj !== null) {
      if (targetKey in obj) {
        return obj[targetKey];
      } else {
        const nestedObj = findObjectInArray(Object.values(obj), targetKey);

        if (nestedObj !== null) {
          return nestedObj;
        }
      }
    }
  }

  return null;
}

export default function TableAplication() {
  const activeSite = useSelector((state) => state.content.activeSite);
  const clientId = useSelector((state) => state.client.activeClient);
  const stage = useSelector((state) => state.stage);
  const allFilterData = useSelector((state) => state.filterComponent);
  const filterApplication = findObjectInArray(allFilterData.filterData, clientId)?.application;

  const { control, watch, setValue, reset } = useForm();

  const dispatch = useDispatch();

  const [dataApplication, setDataApplication] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [stageList, seStageList] = useState([]);
  const [searchInput, setSearchInput] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [isCancel, setIsCancel] = useState(false);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const [editable, setEditable] = React.useState(true);

  const value = [editable, setEditable, setDataApplication, dataApplication, setValue];

  const debouncedSearchInput = useDebounce(searchInput, 500);

  const watchingJob = HandleWatchingState(watch('job'));
  const watchingStage = HandleWatchingState(watch('stage'));

  const forceWatchStage = useWatch({ control, name: 'stage' });

  useEffect(() => {
    if (containsNonNullValues(filterApplication)) {
      setValue('job', filterApplication?.jobPoster);
      setValue('stage', filterApplication?.stage);
    }
  }, [setValue, filterApplication?.jobPoster, filterApplication?.stage]);

  const queryKey = {
    applicant__user__site: activeSite,
    job_poster__article__client: clientId,
    search: debouncedSearchInput,
    job_poster: watch('job'),
    stage__name: watch('stage'),
    page,
    page_size: rowsPerPage,
  };

  const { isFetching } = useQuery(['applications', { queryKey }], fetchFilterApplication, {
    onSuccess: (e) => {
      // if (count === 0 && count !== e?.data?.count) {
      setCount(e?.data?.count);
      setDataApplication(e?.data?.results);
      // }
    },
    refetchOnWindowFocus: false,
  });

  const onChangeSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const data = useMemo(() => dataApplication, [dataApplication]);

  useGet({
    url: `job_posters/?language=${1}&article__client=${clientId}&max_size=true`,
    validate: 'JobPosters',
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setJobList([{ name: '-- No Selected --', id: '' }, ...listData]);
    },
  });

  useGet({
    url: `application_stages/?job_poster=${watch('job') || filterApplication?.jobPoster}`,
    validate: 'JobPosterStage',
    isEnabled: Boolean(watchingJob && watch('job')),
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      const optionStage =
        listData?.map((list) => ({
          id: list.name,
          name: list.name,
        })) || [];
      seStageList([{ name: '-- No Selected --', id: '' }, ...optionStage]);
    },
  });

  useGet({
    url: `application_stages/list_names/?job_poster__article__client=${clientId}`,
    validate: 'JobPosterStageAll',
    isEnabled: Boolean(!watch('job')),
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      const optionStage = listData?.map((list) => ({
        id: list,
        name: list,
      }));
      seStageList([{ name: '-- No Selected --', id: '' }, ...optionStage]);
    },
  });

  function generateFilterComponent() {
    return (
      <div className="">
        {' '}
        <div className="font-bold mb-6">Filter</div>
        <div className="grid grid-cols-3 w-8/12 gap-x-2 ">
          <ReactSelectSingle
            name="job"
            control={control}
            extraAction={() => setValue('stage', '')}
            options={getOptionSelectWithKey(jobList)}
            defaultValue={getDefaultSelectObject(watch('job'), getOptionSelectWithKey(jobList))}
            placeholder="Select Job"
            isCancel={isCancel}
            setIsCancel={setIsCancel}
            resetFunction={() => setValue('stage', '')}
            dispatch={() => {
              if (watchingJob) {
                const obj = findObjectInArray(allFilterData.filterData, clientId);
                dispatch(
                  handleFilter({
                    [clientId]: {
                      ...obj,
                      application: {
                        ...obj?.application,
                        jobPoster: watch('job'),
                      },
                    },
                  })
                );
              }
            }}
            dispatchCondition={watchingJob}
          />

          <ReactSelectSingle
            name="stage"
            control={control}
            options={getOptionSelectWithKey(stageList)}
            defaultValue={getDefaultSelectObject(watch('stage'), getOptionSelectWithKey(stageList))}
            placeholder="Select Stage"
            dispatch={() => {
              if (watchingStage) {
                const obj = findObjectInArray(allFilterData.filterData, clientId);
                dispatch(
                  handleFilter({
                    [clientId]: {
                      ...obj,
                      application: {
                        ...obj.application,
                        stage: watch('stage'),
                      },
                    },
                  })
                );
              }
            }}
            dispatchCondition={watchingStage}
          />
          {containsNonNullValues(filterApplication) && (
            <BaseSubmitButton
              onClick={() => {
                setValue('stage', '');
                setValue('job', '');
                const obj = findObjectInArray(allFilterData.filterData, clientId);
                dispatch(
                  handleFilter({
                    [clientId]: {
                      ...obj,
                      application: {
                        jobPoster: '',
                        stage: '',
                      },
                    },
                  })
                );
              }}
              disabled={false}
              text="Reset Filter"
            />
          )}
        </div>
      </div>
    );
  }

  const { mutate: postApplication } = usePost({ url: 'applications', validate: ['applications'] });

  const onPostApplication = async (postData) => {
    await postApplication(postData);
  };

  return (
    <div className="p-5">
      <div className="">
        <div className="bg-gray-100 w-full flex py-5 px-3 items-center">
          <div className="w-full max-w-[300px] ">
            <BaseSearchButton onChange={onChangeSearch} fullWidth defaultValue={debouncedSearchInput} />
          </div>
          <div className="ml-auto flex gap-x-3">
            <BZButton variant="secondary" onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addApplication' }))}>
              Add Application
            </BZButton>
            <ModalWrapper componentName="addApplication" header="Add Application" maxWidth="sm">
              <ApplicationForm onFormSubmit={onPostApplication} />
            </ModalWrapper>
          </div>
        </div>
        <div className=" py-5  ">{generateFilterComponent()}</div>
      </div>
      <ApplicationContext.Provider value={value}>
        <CustomTable
          dataApi={data}
          // setIsDataLoad={setIsDataLoad}
          // isFetching={!isFetching}
          headCells={headCells}
          ActionCell={ActionCell}
          TableCells={TableCells}
          page={page - 1}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={count}
        />
      </ApplicationContext.Provider>
    </div>
  );
}

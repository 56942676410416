import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { CircularProgress } from '@mui/material';
import ArticleSectionForm from '../../../components/Form/Article/ArticleSectionForm';
import useGet from '../../../hooks/useGet';
import BZHelmet from '../../../utils/BZHelmet';
import BaseMainTitle from '../../../components/BaseMainTitle';
import usePostExtra from '../../../hooks/usePostExtra';
import renderError from '../../../utils/Error/renderError';

export default function ArticleWidgetAdd() {
  const { enqueueSnackbar } = useSnackbar();
  const { widgetId } = useParams();

  const [resIdArticle, setResIdArticle] = useState(null);

  const { data: dataWidget, isSuccess } = useGet({
    url: `widgets/${widgetId}`,
    validate: 'widget',
  });

  const { mutate: postArticle } = usePostExtra({
    url: 'articles',
    validate: ['articles'],
    onSuccess: (e) => {
      if (e.request.status === 201) {
        setResIdArticle(e?.data?.id);
        enqueueSnackbar('success', { variant: 'success' });
        // navigate('../article', { replace: true });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
        renderError({ data: JSON.parse(e.request.response) });
      }
    },
  });

  const onPostArticle = async (data) => {
    await postArticle(data);
  };
  return (
    <>
      <BZHelmet title=" Article" description="" content="" />
      <div>
        <BaseMainTitle title="Article Widget Add :" />
        <div className="p-5 ">
          <div className="bg-white border p-5 shadow rounded">
            {isSuccess ? (
              <ArticleSectionForm onFormSubmit={onPostArticle} resIdArticle={resIdArticle} setResIdArticle={setResIdArticle} widgetData={dataWidget?.data} />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useCallback, useContext, useEffect } from 'react';
import update from 'immutability-helper';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable } from '../../../../utils/Task/DragDrop';

import { CardTask } from '../CardTask/CardTask';

import TaskContext from '../../../../pages/Task/Boards/BoardsContext';
import DetailContext from '../../../../pages/Task/Projects/DetailContext';
import useGetFilter from '@/hooks/useGetFilter';
import HandleWatchingState from '@/helper/HandleWatchingState';
import projectApi from '@/api/task/project';

export function ContainerTasks() {
  const taskContext = useContext(TaskContext);
  const detailContext = useContext(DetailContext);
  const isStatusHidden = useSelector((state) => state.task.isStatusHidden);

  const [cards, setCards] = useState([...taskContext.sections]);

  const findTopic = useCallback((id, topics) => {
    if (id && topics) {
      const topic = topics.find((c) => c && +c.id === +id);
      return {
        topic,
        index: topics.indexOf(topic),
      };
    }
    return false;
  }, []);
  const findCard = useCallback(
    (id) => {
      if (id) {
        const card = cards.find((c) => c && +c.id === +id);
        return {
          card,
          index: cards.indexOf(card),
        };
      }
      return cards;
    },
    [cards]
  );

  useEffect(() => {
    if (taskContext.sections !== cards) {
      setCards(taskContext.sections);
    }
  }, [taskContext.sections, cards]);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (destination && source) {
      const { index: droppableIndexD, droppableId: droppableIdDestination } = destination;
      const { droppableId: droppableIdSource } = source;
      const { type, draggableId } = result;

      if (type && type === 'card' && droppableIdSource && droppableIdDestination) {
        const destId = droppableIdDestination;
        const souId = droppableIdSource;
        const dataSectionTopics = findCard(droppableIdSource);
        const sectionTopicsData = dataSectionTopics.card.topics;
        const cardData = dataSectionTopics.card.topics[source.index];
        if (destId === souId) {
          //* Move Topic Index in Same Section
          if (dataSectionTopics && sectionTopicsData) {
            const topicData = findTopic(draggableId, sectionTopicsData);
            const { index: topicIndex, topic } = topicData;
            const newCardOrder = update(sectionTopicsData, {
              $splice: [
                [topicIndex, 1],
                [droppableIndexD, 0, topic],
              ],
            });
            const currSection = dataSectionTopics.card;
            currSection.topics = newCardOrder;
            const updatedSection = [...cards];
            updatedSection[currSection.index - 1] = currSection;
            setCards(updatedSection);
            taskContext.reorderTaskHandler(newCardOrder, droppableIdSource, false, updatedSection);
          }
        } else {
          //* Move Topic Index Between Section
          const currCards = [...cards];
          const toSectionTopics = currCards.find((s) => +s.id === +destId);
          if (toSectionTopics) {
            toSectionTopics.topics.splice(droppableIndexD, 0, cardData);
            currCards[toSectionTopics.index - 1] = toSectionTopics;
            currCards[dataSectionTopics.card.index - 1].topics = sectionTopicsData.filter((t) => +t.id !== +draggableId);
            setCards(currCards);
            taskContext.moveTaskHandler(souId, destId, draggableId, toSectionTopics.topics, currCards);
          }
        }
      }
      if (type && type === 'section') {
        //* Move Section - Section
        const dataSectionTopics = findCard(draggableId);
        const newCardOrder = update(cards, {
          $splice: [
            [dataSectionTopics.index, 1],
            [droppableIndexD - 1, 0, dataSectionTopics.card],
          ],
        });
        taskContext.reorderSectionHandler(newCardOrder);
      }
    }
  };

  const onDragUpdate = (result) => {
    const { destination, payload } = result;
    if (payload && destination) {
      const { index: droppableIndexD = null } = destination;
      const { type, id } = payload;
      if (type && type === 'section') {
        //* Move Section - Section
        const dataSectionTopics = findCard(id);
        const newCardOrder = update(cards, {
          $splice: [
            [dataSectionTopics.index, 1],
            [droppableIndexD - 1, 0, dataSectionTopics.card],
          ],
        });
        detailContext.setNewSections(newCardOrder);
      }
    }
  };

  const [priorSort, setPriorSort] = useState({ id: null, index: null, order: '' });
  const [incrementNumb, setIncrementNumb] = useState(0);

  const watchPriorId = HandleWatchingState(`${priorSort?.id}-${incrementNumb}`);

  const { isFetching, refetch, data } = useGetFilter({
    url: `project-management/sections/${priorSort?.id}`,
    validate: `priorsort-${priorSort.id}-${incrementNumb}-${priorSort.order}`,
    queryString: {
      order_by: priorSort.order,
    },

    onSuccess: (res) => {
      console.log('🚀 ~ ContainerTasks ~ res:', res);
      let newTop = [...cards];
      newTop[priorSort?.index].topics = res?.data?.topics;
      setCards(newTop);
      handleReorderTask(res?.data?.topics, res?.data.id);
    },

    isEnabled: Boolean(priorSort?.id) && watchPriorId,
  });

  const handleReorderTask = (newTasks, sectionId) => {
    const { reorderSectionTopic } = projectApi();
    const topicIds = [];
    newTasks.forEach((sec) => {
      topicIds.push(sec.id);
    });
    reorderSectionTopic(sectionId, { topic_ids: topicIds })
      .then((res) => {
        if (res.status === 200) {
          //  refetch();
          setTimeout(() => {
            // detailContext.dispatchProject('GET');
            // setCards(cards);
          }, 100);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <Droppable droppableId="section-droppable" type="section" direction="horizontal">
        {(provided) => (
          <>
            <div {...provided.droppableProps} ref={provided.innerRef} className="flex ml-4">
              {cards &&
                cards.map((card, index) =>
                  (isStatusHidden && !card.is_hidden) || !isStatusHidden ? (
                    <CardTask
                      onSortHigh={() => {
                        setIncrementNumb(incrementNumb + 1);

                        setTimeout(() => {
                          setPriorSort({ id: card.id, index: index, order: 'priority__index' });
                        }, 100);
                      }}
                      onSortLow={() => {
                        setIncrementNumb(incrementNumb + 1);

                        setTimeout(() => {
                          setPriorSort({ id: card.id, index: index, order: '-priority__index' });
                        }, 100);
                      }}
                      key={[`section`, card?.id].join(' ')}
                      id={card?.id}
                      text={card?.name}
                      indexTopic={card?.index}
                      findCard={findCard}
                      topics={card.topics}
                    />
                  ) : null
                )}
            </div>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export const ContainerTask = React.memo(ContainerTasks);

export default function editorConfig() {
  const editor = {
    toolbar: {
      items: [
        'heading',
        '|',
        'fontfamily',
        'fontsize',
        '|',
        'alignment',
        '|',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        'subscript',
        'superscript',
        '|',
        'link',
        '|',
        'outdent',
        'indent',
        '|',
        'bulletedList',
        'numberedList',
        'todoList',
        '|',
        'code',
        'codeBlock',
        '|',
        'insertTable',
        '|',
        'uploadImage',
        'importFile',
        'blockQuote',
        '|',
        'undo',
        'redo',
      ],
    },
  };
  return editor;
}

import axiosInstances from '../helper/axiosInstances';

const baseURL = 'https://apimonitor.azurewebsites.net/';

export default function serviceMonitoring() {
  const { axiosInstance } = axiosInstances();

  async function getAllServiceMonitoring(params) {
    const method = 'get';
    const url = `${baseURL}api/http-apimonitor/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  return { getAllServiceMonitoring };
}

import React from 'react';

import clsxm from '@/utils/clsxm';

export default function BaseLabel({ value, mb = true, className }) {
  return (
    <h3
      className={clsxm(
        'text-[0.875rem] font-[600] capitalize leading-[1.4375em] tracking-[0.00938em] text-[#32324D]',
        mb ? 'mb-[4px]' : '',
        className
      )}
    >
      {value}
    </h3>
  );
}

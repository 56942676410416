/* eslint-disable */

import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function InputContainer({
  name,
  disabled,
  control,
  type = 'text',
  label,
  showPass,
  showCfPass,
  handleClickShowPassword,
  errors,
  defaultValue,
  hidden,
  config = {},
  onFocus,
  placeholder,
  autoFocus,
  className,
  rows,
  setValue,
  readOnly = false,
}) {
  useEffect(() => {
    if (setValue) {
      setValue();
    }
  }, [setValue]);

  return (
    <div className={['z-index-label', className, hidden && 'hidden'].join(' ')}>
      {!defaultValue && (
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field }) => {
            return (
              <TextField
                fullWidth
                autoFocus={autoFocus}
                className={className}
                size="small"
                sx={{ textTransform: 'capitalize', marginTop: '4px', background: 'white' }}
                // value={value}
                // defaultValue={defaultValue || ''}
                placeholder={placeholder}
                onFocus={onFocus}
                hidden={hidden}
                disabled={disabled}
                multiline={Boolean(rows)}
                rows={rows}
                margin="normal"
                error={Boolean(errors)}
                helperText={errors?.message}
                /* eslint-disable */ //nested ternary
                type={type === 'password' ? (showPass ?? showCfPass ? 'text' : 'password') : type}
                InputProps={{
                  readOnly: readOnly,
                  autoComplete: 'new-password',
                  endAdornment: type === 'password' && (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                        {showPass ?? showCfPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
                {...config}
              />
            );
          }}
        />
      )}
      {defaultValue && (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <TextField
              fullWidth
              autoFocus={autoFocus}
              className={className}
              size="small"
              sx={{
                // "& .Mui-disabled": {
                //   color: 'black',
                //   "-webkit-text-fill-color" : "black!important",
                // },
                textTransform: 'capitalize', 
                marginTop: '4px', 
                background: 'white' }}
              // value={value}
              // defaultValue={defaultValue || ''}
              placeholder={placeholder}
              onFocus={onFocus}
              hidden={hidden}
              disabled={disabled}
              multiline={Boolean(rows)}
              rows={rows}
              margin="normal"
              error={Boolean(errors)}
              helperText={errors?.message}
              /* eslint-disable */ //nested ternary
              type={type === 'password' ? (showPass ?? showCfPass ? 'text' : 'password') : type}
              InputProps={{
                readOnly: readOnly,
                autoComplete: 'new-password',
                endAdornment: type === 'password' && (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {showPass ?? showCfPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...field}
              {...config}
            />
          )}
        />
      )}
    </div>
  );
}

export default InputContainer;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Slide } from '@mui/material';
import { Menu } from '@mui/icons-material';

import { handleSideBar } from '../../../store/slices/sidebarTaskSlice';

export default function BaseHeaderTask() {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);

  return (
    <div className="flex items-center">
      <Slide direction="right" in={isSidebarOpen} mountOnEnter unmountOnExit>
        <Menu className="mr-4" onClick={() => dispatch(handleSideBar())} />
      </Slide>
      Home
    </div>
  );
}

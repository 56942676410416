/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const parseFilter = localStorage.getItem('filterComponent') ? JSON.parse(localStorage.getItem('filterComponent')) : [];

const initialState = {
  filterData: parseFilter && parseFilter.length > 0 ? parseFilter : [],
};

function pushOrReplaceObjectInArray(arr, newObj) {
  console.log('arr ', arr);
  console.log('newObj ', newObj);
  const key = Object.keys(newObj)[0];

  // Find index of object with matching key
  const index = arr?.findIndex((obj) => obj?.hasOwnProperty(key));

  if (index === -1) {
    // Object not found, push new object to array
    arr?.push(newObj);
  } else {
    // Object found, replace existing object with new object
    arr[index] = newObj;
  }

  return arr;
}

const filterComponentSlice = createSlice({
  name: 'filterComponent',
  initialState,
  reducers: {
    handleFilter: (state, action) => {
      const currState = state;
      // const newObjectFilter = { ...initialState, ...action.payload };
      localStorage.setItem('filterComponent', JSON.stringify(pushOrReplaceObjectInArray(currState.filterData, action.payload)));
      currState.filterData = pushOrReplaceObjectInArray(currState.filterData, action.payload);
    },
    resetFilter: (state) => {
      const currState = state;
      currState.filterData = '';
    },
  },
});

export const { handleFilter, resetFilter } = filterComponentSlice.actions;

export default filterComponentSlice.reducer;

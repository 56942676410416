import axiosInstances from '../../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function notification() {
  const { axiosInstance } = axiosInstances();

  async function getNotificationSubscription(params) {
    const method = 'get';
    const url = `${baseURL}project-management/notification_subscriptions/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function postNotificationSubscription(data) {
    const method = 'post';
    const url = `${baseURL}project-management/notification_subscriptions/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getUserNotificationSubscription(params) {
    const method = 'get';
    const url = `${baseURL}project-management/user_notification_subscriptions/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function postUserNotificationSubscription(data) {
    const method = 'post';
    const url = `${baseURL}project-management/user_notification_subscriptions/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getNotificationTriggers(params) {
    const method = 'get';
    const url = `${baseURL}project-management/notification_triggers/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  return { getNotificationSubscription, postNotificationSubscription, getUserNotificationSubscription, postUserNotificationSubscription, getNotificationTriggers };
}

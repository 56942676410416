// /* eslint-disable */

import React, { useCallback, useState } from 'react';
import { useQueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { DevTool } from '@hookform/devtools';
import { Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { ExpandMore, Delete, ModeEdit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ContentOrder from './ContentOrder/ContentOrder';

import TextForm from '../../components/Form/Widget/TextForm';
import AssetForm from '../../components/Form/Asset/AssetForm';
import { handleModal } from '../../store/slices/modalSlice';
import ModalWrapper from '../../components/ui/ModalWrapper';

import ArticleSectionForm from '../../components/Form/Article/ArticleSectionForm';
import SelectAutocomplete from '../../components/Input/SelectAutocomplete';
import { getOnChangeSelect, getSelectOptionWithKey } from '../../lib/OptionsInputHelper';
import WidgetTableContent from '../../components/WidgetTable/WidgetTableContent';
import BaseMainTitle from '../../components/BaseMainTitle';
import BaseBackButton from '../../components/Button/BaseBackButton';
import BZHelmet from '../../utils/BZHelmet';
import WidgetUpdateForm from '../../components/Form/Section/WidgetUpdateForm';
import useGet from '../../hooks/useGet';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import useDelete from '../../hooks/useDelete';
import usePost from '../../hooks/usePost';
import BaseAssetViewer from '../../components/Viewer/BaseAssetViewer';

export default function SectionDetail() {
  const { control, handleSubmit } = useForm();

  const [sectionDetail, setSectionDetail] = useState(null);
  const [expand, setExpand] = useState({ id: null, open: false });
  const [articleSelected, setArticleSelected] = useState(null);
  const [textSelected, setTextSelected] = useState(null);
  const [imageSelected, setImageSelected] = useState(null);
  const [contentOrder, setContentOrder] = useState([]);
  const [isArticleData, setIsArticleData] = useState(null);
  const [isTextElementData, setIsTextElementData] = useState(null);
  const [switcherId, setSwitcherId] = useState(false);
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);

  const qClient = useQueryClient();
  const dispatch = useDispatch();

  const assetPair = useSelector((state) => state.assetCrud.selected);

  const getElmOrdering = useCallback((data) => {
    if (data?.category_name === 'Article') {
      setContentOrder(data?.widget_articles);
    }
    if (data?.category_name === 'Text') {
      setContentOrder(data?.text_elements);
    }
    if (data?.category_name === 'Media') {
      setContentOrder(data?.widget_assets);
    }
  }, []);

  const resetExpand = () => {
    setArticleSelected(null);
    setTextSelected(null);
    setImageSelected(null);
  };

  const handleSwitchId = () => {
    setSwitcherId(false);
    setTimeout(() => {
      setSwitcherId(true);
    }, 50);
  };

  const handleExpand = (data) => {
    handleSwitchId();
    const getInternalStatus = data?.widget?.is_internal;
    const getId = data?.widget?.id;
    setExpand({ id: getId, open: !expand.open, name: data?.name, status: getInternalStatus });
    if (expand.id === getId && expand.open === false) {
      setExpand({ id: null, open: false, name: data?.name, status: getInternalStatus });
    } else setExpand({ id: getId, open: false, name: data?.name, status: getInternalStatus });
  };

  const { sectionId } = useParams();

  useGet({
    url: `sections/${sectionId}`,
    validate: 'section',
    onSuccess: (e) => {
      setSectionDetail(e.data);
    },
  });

  useGet({
    url: `articles/${articleSelected}/?language=1`,
    validate: 'article',
    onSuccess: (e) => {
      setIsArticleData(e?.data);
    },
    isEnabled: Boolean(articleSelected) && switcherId,
  });

  const widgetId = expand?.id || modal.modalId;

  const { data: dataWidget } = useGet({
    url: `widgets/${widgetId}`,
    validate: 'widget',
    onSuccess: (e) => {
      getElmOrdering(e?.data);
    },
    isEnabled: Boolean(widgetId) && switcherId,
  });

  const sectionWidgetId = modal.modalId;

  const { data: dataPairingWidget } = useGet({
    url: `section_widgets/${sectionWidgetId}`,
    validate: 'sectionWidget',
    isEnabled: Boolean(sectionWidgetId),
  });

  const sectionWidgetIdExternal = expand?.id;
  const { data: dataPairingWidgetExternal } = useGet({
    url: `section_widgets/${sectionWidgetId}`,
    validate: 'sectionWidget',
    isEnabled: Boolean(sectionWidgetIdExternal),
  });

  useGet({
    url: `text_elements/${textSelected}`,
    validate: 'text-elements',
    isEnabled: Boolean(textSelected) && switcherId,
    onSuccess: (e) => {
      setIsTextElementData(e);
    },
  });

  const { data: WidgetsData } = useGet({
    url: `widgets/?client=${clientId}&is_internal=false&category=1&status=Public&max_size=true`,
    validate: 'widgets',
  });

  const optionsWidget = WidgetsData?.data.results;

  const { mutate: postArticle } = usePost({ url: 'articles', validate: ['widget'] });

  const onPostArticle = async (data) => {
    await postArticle(data);
  };

  const { mutate: updateArticle } = usePatchOrPut({ url: `articles/${articleSelected}`, validate: ['article', 'widget'] });

  const onUpdateArticle = async (data) => {
    await updateArticle(data);
  };

  const { mutate: postText } = usePost({ url: 'text_elements', validate: ['widget'] });

  const onPostText = async (data) => {
    await postText(data);
  };

  const { mutate: updateTextElement } = usePatchOrPut({
    url: `text_elements/${textSelected}`,
    validate: ['text-elements', 'widget'],
  });

  const onUpdateTextElement = async (data) => {
    await updateTextElement(data);
  };

  const { data: assetData } = useGet({
    url: `assets/${imageSelected?.id}`,
    validate: 'asset',
    isEnabled: Boolean(imageSelected?.id) && switcherId,
  });

  const { mutate: postAsset } = usePost({ url: 'assets', validate: ['assets'] });

  const onPostAsset = async (data) => {
    await postAsset(data);
  };

  const onDestroyAsset = useDelete({
    url: `widgets/${widgetId}/unpair_assets`,
    confText: `modals.asset_delete.confirmation_text`,
    confTitle: `modals.asset_delete.title`,
    success: 'Asset deleted',
    validate: ['widget'],
    bodyData: { assets: [imageSelected?.id] },
    method: 'patch',
    isSubUrl: false,
  });

  const { mutate: updateAsset } = usePatchOrPut({ url: `assets/${imageSelected?.id}`, validate: ['asset', 'widget'] });

  const onUpdateAsset = async (data) => {
    await updateAsset(data);
  };

  const { mutate: updateSectionWidget } = usePatchOrPut({ url: `section_widgets/${modal.modalId || expand?.id}`, method: 'put', validate: ['section', 'widget'] });

  const onUpdateSectionWidget = async (data) => {
    await updateSectionWidget({
      name: expand?.name,
      section: sectionId,
      widget: data.widget,
    });
  };

  const onUpdateSectionPairWidget = async (data) => {
    await updateSectionWidget(data);
  };

  function renderWidget(value, widgetData) {
    let widget;
    const val = value?.toLowerCase();
    if (val === 'article' && widgetData?.is_internal === true && widgetData?.id === expand?.id) {
      widget = (
        <div className="">
          <div className="flex gap-x-3">
            <button onClick={() => [setArticleSelected(null), handleSwitchId()]} type="button" className={`${!articleSelected ? 'text-orange-500' : 'text-black'}`}>
              Add New
            </button>
            <div className="overflow-x-auto space-x-2 flex">
              {widgetData?.widget_articles?.map((art, i) => (
                <button
                  key={`art-${art.id}`}
                  onClick={() => [setArticleSelected(art?.article?.id), handleSwitchId()]}
                  type="button"
                  className={`${articleSelected?.toString() === art?.article?.id.toString() ? 'text-orange-500 flex-none' : 'text-black flex-none'}`}
                >
                  {`${i + 1} . ${art?.article?.name}`}{' '}
                </button>
              ))}
            </div>
          </div>
          <div className="ml-auto flex justify-end py-4">
            <button
              type="button"
              onClick={() =>
                dispatch(
                  handleModal({
                    componentName: 'contentOrder',
                    modalId: '',
                  })
                )
              }
              className="bg-green-700 px-2 rounded text-white font-bold"
            >
              Order Article
            </button>
          </div>
          {articleSelected ? (
            <ArticleSectionForm defaultValues={isArticleData} key={isArticleData?.id} widgetData={widgetData} articleSelected={articleSelected} onFormSubmit={onUpdateArticle} />
          ) : (
            <ArticleSectionForm widgetData={widgetData} onFormSubmit={onPostArticle} />
          )}
        </div>
      );
    }
    if (val === 'article' && widgetData?.is_internal === false && widgetData?.id === expand?.id) {
      let defWidget;
      if (widgetData) {
        const selected = optionsWidget?.filter((u) => u.id.toString() === widgetData?.id.toString());
        if (selected) {
          // eslint-disable-next-line prefer-destructuring
          defWidget = selected[0];
        }
      }

      widget = (
        <div className="">
          <form action="" onSubmit={handleSubmit(onUpdateSectionWidget)}>
            <Controller
              name="widget"
              control={control}
              render={({ field: { onChange } }) => (
                <SelectAutocomplete
                  label="Collection"
                  placeHolder="Select Collection"
                  options={getSelectOptionWithKey(optionsWidget)}
                  variant="outlined"
                  defaultValue={defWidget}
                  onChange={(e, newValue) => onChange(getOnChangeSelect(newValue))}
                />
              )}
            />
            <Button className="mt-5" type="submit" variant="contained" fill>
              Update Collection
            </Button>
            <DevTool control={control} />
          </form>
        </div>
      );
    }

    if (val === 'text' && widgetData?.id === expand?.id) {
      widget = (
        <div className="">
          <div className="flex gap-x-3">
            <button onClick={() => [setTextSelected(null), handleSwitchId()]} type="button" className={`${!textSelected ? 'text-orange-500' : 'text-black'}`}>
              Add New
            </button>
            <div className="overflow-x-auto space-x-2 flex">
              {widgetData?.text_elements?.map((text, i) => (
                <button
                  key={`${text.id + i}`}
                  onClick={() => [setTextSelected(text?.id), handleSwitchId()]}
                  type="button"
                  className={`${textSelected?.toString() === text?.id.toString() ? 'text-orange-500 flex-none' : 'flex-none text-black'}`}
                >
                  {`${i + 1} . ${text?.name}`}{' '}
                </button>
              ))}
            </div>
          </div>
          <div className="ml-auto flex justify-end py-4">
            <button
              type="button"
              onClick={() =>
                dispatch(
                  handleModal({
                    componentName: 'textOrder',
                    modalId: '',
                  })
                )
              }
              className="bg-green-700 px-2 rounded text-white font-bold"
            >
              Order Text
            </button>
          </div>
          {textSelected ? (
            <TextForm defaultValues={isTextElementData?.data} key={isTextElementData?.data?.id} supportData={widgetData} onFormSubmit={onUpdateTextElement} />
          ) : (
            <TextForm onFormSubmit={onPostText} supportData={widgetData} />
          )}
        </div>
      );
    }
    if (val === 'media' && widgetData?.id === expand?.id) {
      widget = (
        <div>
          <div className="flex flex-col gap-x-8">
            <div className=" space-y-5">
              {!imageSelected ? (
                <div className="h-[300px] bg-gray-100 w-full flex items-center justify-center">
                  <div className="space-y-3 ">
                    <div className="text-center"> Add New Asset by filling the form </div>
                    <div className="text-center"> OR</div>
                    <button
                      onClick={() => {
                        dispatch(
                          handleModal({
                            componentName: 'assetPairSectionDetail',
                            modalId: '',
                          })
                        );
                      }}
                      type="button"
                      className="bg-bz-green text-white px-3 text-lg rounded justify-center flex mx-auto"
                    >
                      Add Existing Asset
                    </button>
                  </div>
                </div>
              ) : (
                <div className="h-[300px] bg-gray-100 w-full flex items-center justify-center relative">
                  <button onClick={() => onDestroyAsset()} className="rounded absolute right-5 top-5 bg-white" type="button">
                    <Delete className="text-red-500" />
                  </button>
                  <img src={imageSelected?.asset_file} className="w-full h-full object-cover" alt="" />
                </div>
              )}
              <div>
                {imageSelected && (
                  <div className="flex">
                    <button
                      onClick={() =>
                        dispatch(
                          handleModal({
                            componentName: 'assetPairSectionDetail',
                            modalId: '',
                          })
                        )
                      }
                      type="button"
                      className="bg-bz-green text-white px-3 text-lg rounded justify-center flex mx-auto"
                    >
                      Add Existing Asset
                    </button>
                    <button
                      onClick={() => [setImageSelected(null), handleSwitchId()]}
                      type="button"
                      className="bg-green-600 text-white px-3 text-lg rounded justify-center flex mx-auto"
                    >
                      Add New
                    </button>
                  </div>
                )}
              </div>
              <div className="overflow-x-auto w-full flex gap-x-2 ">
                {widgetData?.widget_assets?.map((wid) => (
                  <div className="flex-none" key={wid?.id}>
                    <button
                      onClick={() => [setImageSelected(wid?.asset), handleSwitchId()]}
                      type="button"
                      className="border border-black p-1"
                      // className={`${textSelected?.toString() === text?.id.toString() ? 'text-orange-500' : 'text-black'}`}
                    >
                      <img src={wid?.asset?.asset_file} className="w-28 h-28" alt="" />
                      <div className="bg-orange-500 text-white font-bold text-lg">{wid?.asset?.id}</div>
                    </button>
                  </div>
                ))}
              </div>
              <div className="ml-auto flex justify-end py-4">
                <button
                  type="button"
                  onClick={() =>
                    dispatch(
                      handleModal({
                        componentName: 'mediaOrder',
                        modalId: '',
                      })
                    )
                  }
                  className="bg-bz-green px-2 rounded text-white font-bold"
                >
                  Media Order
                </button>
              </div>
            </div>
            <div className="">
              {imageSelected ? <AssetForm defaultValues={assetData?.data} key={assetData?.data?.id} onFormSubmit={onUpdateAsset} /> : <AssetForm onFormSubmit={onPostAsset} />}
            </div>
          </div>
        </div>
      );
    }
    if (value?.toString() === '4') {
      widget = 'Video';
    }
    if (value?.toString() === '5') {
      widget = 'File';
    }

    if (val === 'table' && widgetData?.id === expand?.id) {
      widget = <WidgetTableContent data={widgetData} setSectionDetail={setSectionDetail} />;
    }
    return widget;
  }

  const { mutate: pairAsset } = usePatchOrPut({ url: `widgets/${widgetId}/pair_assets`, validate: ['widget'] });

  const onAssetPair = async (data) => {
    await pairAsset(data);
  };

  const orderChanges = (data) => {
    setContentOrder(data);
  };

  const { mutate: updateArticleOrder } = usePatchOrPut({ url: `widgets/${widgetId}/update_widgetarticle_index`, validate: ['widget'] });
  const { mutate: updateMediaOrder } = usePatchOrPut({ url: `widgets/${widgetId}/update_widgetasset_index`, validate: ['widget'] });
  const { mutate: updateTextOrder } = usePatchOrPut({ url: `widgets/${widgetId}/update_text_element_indexes`, method: 'put', validate: ['widget'] });

  // eslint-disable-next-line consistent-return
  const onSubmitOrder = () => {
    if (modal.componentName === 'contentOrder') {
      return updateArticleOrder({ widget_articles: contentOrder?.map((art) => art.id) });
    }
    if (modal.componentName === 'textOrder') {
      return updateTextOrder({ text_ids: contentOrder?.map((art) => art.id) });
    }
    if (modal.componentName === 'mediaOrder') {
      return updateMediaOrder({ widget_assets: contentOrder?.map((art) => art.id) });
    }
  };

  return (
    <QueryClientProvider client={qClient}>
      <BZHelmet title="Section Detail" description="bz publish section detail" />
      <div>
        <BaseMainTitle title={`Section Detail : Section (${sectionDetail?.name})`} />
        <div className="p-5">
          <div className=" bg-white border p-5 shadow rounded">
            <div className="bg-gray-100 w-full py-5 px-3 mb-5 rounded">
              <BaseBackButton />
            </div>
            <div className="flex flex-col space-y-4">
              {sectionDetail?.section_widgets?.map((sec) => (
                <div key={sec.id} className="">
                  <div key={`widget-${sec.id}`}>
                    <div className="bg-bz-green flex p-3">
                      <div className="flex space-x-3 items-center">
                        <div className="text-white">{sec.name}</div>
                        {/* <div onClick={() => dispatch(handleModal({ modalId: sec?.id, componentName: 'editWidgetPair' }))} className={`text-white flex z-[10000] `}>
                          <Widgets />
                        </div> */}
                        <button
                          type="button"
                          onClick={() => dispatch(handleModal({ modalId: sec?.id, componentName: 'editWidgetPair' }))}
                          className="text-white flex z-[10] cursor-pointer"
                        >
                          <ModeEdit />
                        </button>
                      </div>
                      <div className="ml-auto px-4 flex gap-x-3">
                        <div className="text-white">{sec.widget.category_name}</div>

                        <button
                          type="button"
                          onClick={() => {
                            resetExpand();
                            handleExpand(sec);
                          }}
                          className={`text-white flex z-[10] cursor-pointer `}
                        >
                          <ExpandMore />
                        </button>
                      </div>
                    </div>

                    {/* render external widget */}
                    {expand?.id?.toString() === sec.id.toString() && (
                      <div className="m-3">{renderWidget(sec.widget.category_name, dataPairingWidgetExternal?.data?.widget, sec.widget)}</div>
                    )}

                    {/* render internal widget */}
                    {expand?.id?.toString() === sec.widget.id?.toString() && <div className="m-3">{renderWidget(sec.widget.category_name, dataWidget?.data, sec.widget)}</div>}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <ModalWrapper componentName="contentOrder" header="Article Order" maxWidth="lg">
            <div className="">
              <DndProvider backend={HTML5Backend}>
                <ContentOrder data={contentOrder} orderChanges={orderChanges} elmType="article" />
              </DndProvider>
              <div className="flex space-x-3 justify-end">
                <button onClick={onSubmitOrder} className="bg-blue-400 p-2 rounded text-white font-bold" type="button">
                  Apply Order
                </button>
              </div>
            </div>
          </ModalWrapper>
          <ModalWrapper componentName="textOrder" header="Text Order" maxWidth="lg">
            <div className="">
              <DndProvider backend={HTML5Backend}>
                <ContentOrder data={contentOrder} orderChanges={orderChanges} elmType="text" />
              </DndProvider>
              <div className="flex space-x-3 justify-end">
                <button onClick={onSubmitOrder} className="bg-blue-400 p-2 rounded text-white font-bold" type="button">
                  Apply Order
                </button>
              </div>
            </div>
          </ModalWrapper>
          <ModalWrapper componentName="mediaOrder" header="Media Order" maxWidth="lg">
            <div className="">
              <DndProvider backend={HTML5Backend}>
                <ContentOrder data={contentOrder} orderChanges={orderChanges} elmType="media" />
              </DndProvider>
              <div className="flex space-x-3 justify-end">
                <button onClick={onSubmitOrder} className="bg-blue-400 p-2 rounded text-white font-bold" type="button">
                  Apply Order
                </button>
              </div>
            </div>
          </ModalWrapper>
          <ModalWrapper componentName="assetPairSectionDetail" header="Select Widget Asset" maxWidth="lg">
            {/* <AssetCardSingle onChangeAsset={handleChangeAsset} onClick={() => onAssetPair(assetSelectedData)} /> */}
            <BaseAssetViewer handleClick={() => onAssetPair({ assets: assetPair?.map((asset) => asset.id) })} />
          </ModalWrapper>
          <ModalWrapper componentName="editWidgetPair" header="Update Widget Pair Name" maxWidth="sm">
            <WidgetUpdateForm defaultValues={dataPairingWidget?.data} key={dataPairingWidget?.data?.id} onFormSubmit={onUpdateSectionPairWidget} updateType="name" />
          </ModalWrapper>
        </div>
      </div>
    </QueryClientProvider>
  );
}

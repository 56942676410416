/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import chroma from 'chroma-js';
import { Box, Input, Popover } from '@mui/material';

const createOption = (label, value, color) => {
  return {
    color,
    label,
    value,
  };
};

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data?.color || '#fff');
    return {
      ...styles,
      backgroundColor: isDisabled ? undefined : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : undefined,
      color: isDisabled ? '#ccc' : isSelected ? (chroma.contrast(color, 'white') > 2 ? 'white' : 'black') : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? data.color : color.alpha(0.3).css()) : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => {
    return {
      ...styles,
      color: data.color,
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

const createArrayOptions = (arr) => {
  const newArr = arr.map((lbl) => {
    return createOption(lbl.name, lbl.id, lbl.color);
  });
  return newArr;
};

export default function Creatable(props) {
  const { labels, selectedLabels, postLabel, handleTopicUpdate } = props;
  const selectRef = useRef();

  const [options, setOptions] = useState(false);
  const [value, setValue] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [staggeredValue, setStaggeredValue] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (newValue) => {
    // actionMeta available props
    setValue(newValue);
    const newLabels = newValue.map((val) => val.value);
    handleTopicUpdate({ labels: newLabels.length > 0 ? newLabels : [] });
  };

  const handleCreate = (inputValue) => {
    setStaggeredValue({
      color: '',
      label: inputValue,
      value: inputValue,
    });
    setAnchorEl(selectRef.current);
    setIsLoading(false);
  };

  const handleClose = () => {
    // setOptions([...options, staggeredValue]);
    // setValue(staggeredValue);
    postLabel(staggeredValue);
    setAnchorEl(null);
    setStaggeredValue(null);
  };

  useEffect(() => {
    if (!options && labels && labels.length && labels !== options) {
      const defaultOptions = createArrayOptions(labels);
      const selectedOptions = createArrayOptions(selectedLabels);

      setOptions(defaultOptions);
      setValue(selectedOptions);
    }
  }, [labels, options, selectedLabels]);

  useEffect(() => {
    if (labels.length !== options.length) {
      const defaultOptions = createArrayOptions(labels);
      setOptions(defaultOptions);
    }
  }, [labels, options]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {options && (
        <Box ref={selectRef}>
          <CreatableSelect
            className="min-w-[250px] w-auto min-h-8 h-auto"
            isMulti
            isClearable
            isDisabled={isLoading}
            isLoading={isLoading}
            onChange={handleChange}
            onCreateOption={handleCreate}
            options={options}
            value={value}
            styles={colourStyles}
          />
        </Box>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box>
          <Input
            type="color"
            className="w-8 h-8"
            onChange={(e) => {
              const nValue = staggeredValue;
              nValue.color = e.target.value || '#fff';
              setStaggeredValue(nValue);
            }}
          />
        </Box>
      </Popover>
    </>
  );
}

/* eslint-disable */

import React, { useState } from 'react';
import { useParams } from 'react-router';
import LocationForm from '@/components/Form/Location/LocationForm';
import BaseBackButton from '@/components/Button/BaseBackButton';
import BaseDeleteButton from '@/components/Button/BaseDeleteButton';
import BaseMainTitle from '@/components/BaseMainTitle';
import BZHelmet from '@/utils/BZHelmet';
import useGet from '@/hooks/useGet';
import usePatchOrPut from '@/hooks/usePatchOrPut';
// import BusinessUnitForm from '@/components/Form/Whistleblower/BusinessUnitForm';
import useDelete from '@/hooks/useDelete';
import HandleWatchingState from '@/helper/HandleWatchingState';
import BusinessUnitForm from '@/components/Form/Settings/BusinessUnitForm';
import SettingBusinessUnitForm from '@/components/Form/Settings/SettingBusinessUnitForm';

export default function UnitDetailSetting() {
  const [locationData, setLocationData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { businessUnitId } = useParams();

  const watchingUnit = HandleWatchingState(businessUnitId);

  const { isFetching } = useGet({
    url: `accounting/business_units/${businessUnitId}/retrieve_with_locations`,
    validate: 'BusinessUnit',
    isEnabled: watchingUnit,
    onSuccess: (e) => {
      setIsLoaded(false);
      setLocationData(e.data);
      setIsLoaded(true);
      // setTimeout(() => {
      //   setIsLoaded(false);
      // }, 500);
    },
  });

  const { mutate: updateLocation } = usePatchOrPut({ url: `accounting/business_units/${businessUnitId}/update_with_locations`, validate: ['BusinessUnit'] });

  const onUpdatelocation = async (data) => {
    await updateLocation(data);
  };

  const destroyUnit = useDelete({
    confText: `Confirm Delete Business Unit`,
    confTitle: `Are you sure want to delete this business unit ?`,
    success: 'Business Unit deleted',
    validate: ['BusinessUnit'],
    navTo: '/settings/business-units',
  });

  const onDestroyUnit = (id) => {
    destroyUnit(`accounting/business_units/${businessUnitId}`);
  };
  return (
    <>
      <BZHelmet title="Business Unit Detail" description="" content="" />
      <div>
        <BaseMainTitle title="Business Unit Detail :" />
        <div className="p-5 ">
          <div className="my-5 flex items-center justify-between">
            <BaseBackButton navTo="/settings/business-units" />
            <BaseDeleteButton onClick={onDestroyUnit} variant="text" text="Delete Business Unit" />
            {/* <div className="p-2 border-x border-t font-semibold">Business Unit Detail</div> */}
          </div>
          <div className="bg-white border p-5 shadow rounded">
            <SettingBusinessUnitForm defaultValues={locationData} key={locationData?.id} onFormSubmit={onUpdatelocation} isLoaded={isLoaded} />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, TextField } from '@mui/material';
import DetailContext from '../../../../pages/Task/Projects/DetailContext';

export default function Label({ open, data, onClose, onCreateLabel, onUpdateLabel }) {
  const { projectId: project } = useContext(DetailContext);

  const [name, setName] = useState('');
  const [color, setColor] = useState('#000000');

  const handleClose = () => {
    onClose();
    setName('');
    setColor('#000000');
  };

  const actionOnLabel = () => {
    if (name && color) {
      if (data.mode) {
        const dataColor = {
          project,
          name,
          color,
        };
        onCreateLabel(dataColor);
      }
      const dataColor = {
        name,
        color,
      };
      onUpdateLabel(dataColor, data.data.id);
      handleClose();
    }
  };

  useEffect(() => {
    if (!data.mode) {
      setName(data?.data.name || '');
      setColor(data?.data.color || '#000000');
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} sx={{ '& > .MuiDialog-container > .MuiPaper-root': { position: 'absolute', top: '25%' } }}>
      <DialogTitle>{data.mode ? 'Create New' : 'Update'} Label</DialogTitle>
      <DialogContent>
        <Box className="flex space-x-4 items-end">
          <TextField autoFocus margin="dense" id="name" value={name} label="Name" fullWidth size="small" onChange={(e) => setName(e.target.value)} />
          <Input type="color" className="w-8 h-8 mb-1" defaultValue={data?.data.color ? data?.data.color : '#0000000'} onBlur={(e) => setColor(e.target.value)} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={actionOnLabel}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import { DataGrid } from '@mui/x-data-grid';
import { IconButton, Tooltip, Link, CircularProgress } from '@mui/material';
import CircleMeter from '../../../../../Badge/CircleMeter';
import SuccessStatus from '../../../../../Badge/SuccessStatus';

const adjustRows = (rows) => {
  return rows.map((row) => ({
    id: row.id,
    created_at: new Date(row.created_at).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }),
    site: row.site.name,
    page: row.page.name,
    template_name: row.page.template_name,
    path: row.path,
    performance: row.result.performance,
    seo: row.result.seo,
    accessibility: row.result.accessibility,
    best_practices: row.result.best_practices,
    file: row.file,
    is_success: row.is_success,
    action: row.id,
  }));
};

const cellTooltip = (params) => (
  <Tooltip className="truncate" title={params.value}>
    <p>{params.value}</p>
  </Tooltip>
);

const linkTooltip = (params) => (
  <Tooltip className="truncate" title={params.value}>
    <Link href={params.value} target="_blank" rel="noreferrer">
      {params.value}
    </Link>
  </Tooltip>
);

const noRow = () => (
  <div className="w-100 h-100 flex items-center justify-center">
    <span>No rows</span>
  </div>
);
const compLoading = () => (
  <div className="w-full h-full flex flex-col items-center justify-center">
    <CircularProgress />
  </div>
);

export default function TestResult({ rows, deleteResult, ...props }) {
  const columns = [
    { field: 'created_at', headerName: 'Time', width: 200 },
    { field: 'page', headerName: 'Page Name', width: 120, renderCell: cellTooltip },
    {
      field: 'performance',
      headerName: 'Performance',
      type: 'number',
      width: 100,
      align: 'center',
      renderCell: (params) => <CircleMeter value={params.value} />,
    },
    { field: 'seo', headerName: 'SEO', type: 'number', width: 60, align: 'center', renderCell: (params) => <CircleMeter value={params.value} /> },
    { field: 'accessibility', headerName: 'Accessibility', type: 'number', width: 100, align: 'center', renderCell: (params) => <CircleMeter value={params.value} /> },
    { field: 'best_practices', headerName: 'Best Practices', type: 'number', width: 110, align: 'center', renderCell: (params) => <CircleMeter value={params.value} /> },
    { field: 'path', headerName: 'URL', flex: 1, minWidth: 150, renderCell: cellTooltip },
    {
      field: 'file',
      headerName: 'Result File',
      flex: 1,
      minWidth: 150,
      renderCell: linkTooltip,
    },
    { field: 'is_success', headerName: 'Status', width: 100, renderCell: (params) => <SuccessStatus value={params.value} /> },
    {
      field: 'action',
      headerName: '',
      width: 55,
      align: 'center',
      renderCell: (params) => (
        <IconButton onClick={() => deleteResult(params.value)} color="error" size="small">
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const adjustedRows = adjustRows(rows);
  return (
    <div style={{ height: 650, width: '100%' }}>
      <DataGrid rows={adjustedRows} columns={columns} pageSize={10} rowsPerPageOptions={[10]} {...props} components={{ NoRowsOverlay: props.isLoading ? compLoading : noRow }} />
    </div>
  );
}

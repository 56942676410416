/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { DevTool } from '@hookform/devtools';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import Cookies from 'js-cookie';

import { yupResolver } from '@hookform/resolvers/yup';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InputContainer from '../../Input/InputContainer';
import DatePickerField from '../../Input/DatePickerField';
import { handleModal } from '../../../store/slices/modalSlice';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';
import ModalWrapper from '../../ui/ModalWrapper';
import { generateSelectListDefault, generateSelectObjectDefault, getDefaultSelectObject, getOptionSelectWithKey, getSelectOptionWithIcon } from '../../../lib/OptionsInputHelper';
import CustomUploadAdapterPlugin from '../../../utils/Editor/CustomUploadAdapterPlugin';
import editorConfig from '../../../helper/editorConfig';
import BaseAssetViewer from '../../Viewer/BaseAssetViewer';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import UnpairAssetButton from '../../ui/Alert/UnpairAssetButton';
import AssetCardNew from '../../Viewer/AssetCardNew';
import useGet from '../../../hooks/useGet';
import ReactSelectSingle from '../../Input/ReactSelectSingle';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';
import getDefaultValues from '../../../helper/getDefaultValues';
import { removeEmptyProperties } from '../../../helper/arrayHandler';
import renderDate from '../../../helper/renderDate';
import renderCurrentDate from '../../../helper/renderCurrentDate';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import ReactSelectSingleIcon from '../../Input/ReactSelectSingleICon';
import useDelete from '../../../hooks/useDelete';
import FieldArrKey from './FieldArrKey';
import BaseLocationViewer from '../../Viewer/LocationCard';
import { resetAsset } from '../../../store/slices/assetCrudSlice';

const validation = yup
  .object()
  .shape({ handleStatus: yup.string().required('Team is a required') })
  .required();

const schema = yupResolver(validation);

const lib = ['drawing', 'places'];

const containerStyle = {
  width: '100%',
  height: '400px',
};

const articleTypes = [
  {
    name: 'Simple Article',
    id: 'Simple Article',
  },
  {
    name: 'Article',
    id: 'Article',
  },
  {
    name: 'Full Article',
    id: 'Full Article',
  },
];

const editorConfigs = {
  extraPlugins: [CustomUploadAdapterPlugin],
  toolbar: {
    items: [
      'heading',
      '|',
      'fontfamily',
      'fontsize',
      '|',
      'alignment',
      '|',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'strikethrough',
      'underline',
      'subscript',
      'superscript',
      '|',
      'link',
      '|',
      'outdent',
      'indent',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      '|',
      'code',
      'codeBlock',
      '|',
      'insertTable',
      '|',
      'uploadImage',
      'importFile',
      'blockQuote',
      '|',
      'undo',
      'redo',
    ],
  },
  fontColor: {
    colorPicker: {
      // Use 'hex' format for output instead of 'hsl'.
      format: 'hex',
    },
  },
  image: {
    styles: {
      options: ['inline', 'alignLeft', 'alignRight', 'alignCenter', 'alignBlockLeft', 'alignBlockRight', 'block', 'side'],
    },
    resizeOptions: [
      {
        name: 'resizeImage:original',
        label: 'Original',
        value: null,
      },
      {
        name: 'resizeImage:50',
        label: '50% page width',
        value: '50',
      },
      {
        name: 'resizeImage:75',
        label: '75% page width',
        value: '75',
      },
    ],
    toolbar: [
      'imageTextAlternative',
      'toggleImageCaption',
      'linkImage',
      '|',
      'imageStyle:inline',
      'imageStyle:wrapText',
      'imageStyle:breakText',
      'imageStyle:side',
      '|',
      'resizeImage',
    ],
    insert: {
      integrations: ['insertImageViaUrl'],
    },
  },
  // fontColor: {
  //   colorPicker: {
  //     // Use 'hex' format for output instead of 'hsl'.
  //     format: 'hex',
  //   },
  // },
  link: {
    addTargetToExternalLinks: true,
    decorators: [
      {
        mode: 'manual',
        label: 'External Link',
        attributes: {
          target: '_blank',
        },
      },
    ],
  },
};

const optionsStatus = [
  {
    name: 'Drafted',
    id: 'Drafted',
  },
  {
    name: 'Published',
    id: 'Published',
  },
];

export default function ArticleSectionForm({ defaultValues, onFormSubmit, widgetData, articleSelected, resIdArticle, setResIdArticle }) {
  const clientId = useSelector((state) => state.client.activeClient);
  const assetPair = useSelector((state) => state.assetCrud.selected);
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);
  const site = useSelector((state) => state.client.activeDetailClient.sites[0].id);
  const templateId = Cookies.get('bzaTemplate');

  const dispatch = useDispatch();
  const [valueTab, setValueTab] = useState(0);

  const [assetData, setAssetData] = useState([]);
  const [assetQuality, setAssetQuality] = useState({
    state: 'ori',
    file: '',
  });
  const [attachLocation, setAttachLocation] = useState(null);
  const [selectedTabLanguage, setSelectedTabLanguage] = useState(clientLanguage[0].id);

  const [valueUrl, setValueUrl] = React.useState((defaultValues && defaultValues?.article_languages[0]?.page ? 'internal' : 'external') || 'internal');

  const [editorInstance, setEditorInstance] = useState('');

  const insertImage = (imageUrl) => {
    //here, it is only a prompt but it could be any function that is managed in React
    editorInstance.model.change((writer) => {
      const imageElement = writer.createElement('imageBlock', {
        src: imageUrl,
      });
      // Inserting the image in the current selection location.
      editorInstance.model.insertContent(imageElement, editorInstance.model.document.selection);
    });
  };

  const handleQuality = (quality) => {
    //here, it is only a prompt but it could be any function that is managed in React
    setAssetQuality(quality);
  };

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      keyword: defaultValues?.article_keywords?.map((item) => ({
        keywordId: item?.id,
        keyword: item?.keyword,
        article: item?.article,
        language: item?.language,
      })),
      name: defaultValues?.name,
      type: defaultValues?.type,
      date: defaultValues?.date,
      expiry_date: defaultValues?.expiry_date,
      publish_date: defaultValues?.publish_date,
      status: defaultValues?.status,
      author: defaultValues?.author?.id,
      widgets: defaultValues?.widgets?.map((w) => w.id),
      categories: defaultValues?.categories?.map((cat) => cat.id),
      page: defaultValues?.article_languages[0]?.page,
      section: defaultValues?.article_languages[0]?.section,
      article_languages: getDefaultValues(clientLanguage, defaultValues?.article_languages),
    },
    schema,
  });

  const pageId = watch('page');
  const sectionId = watch('section');

  const statusPage = HandleWatchingState(pageId);

  const handleChangeUrl = (event) => {
    setValueUrl(event.target.value);
  };

  useGet({
    url: `assets/?client=${clientId}&max_size=true`,
    validate: 'assetsMax',
    onSuccess: (e) => {
      setAssetData(e?.data?.results);
    },
  });

  const selectedAssets = assetData.filter((apiData) => assetPair.some((pairData) => apiData.id.toString() === pairData.toString()));

  const defAssets = defaultValues?.article_assets?.map((def) => def?.asset);

  const { data: CategoriesData } = useGet({
    url: `category_articles`,
    queryString: {
      client: clientId,
      max_size: true,
    },
    validate: 'categoriesArticle',
  });

  const { data: AuthorsData } = useGet({
    url: `authors`,
    queryString: {
      site,
      max_size: true,
    },
    validate: 'authors',
  });

  const { data: listPage } = useGet({ url: `pages/?template=${templateId}&language=1&max_size=true`, validate: 'AllPages' });

  const { data: listSection } = useGet({
    url: `pages/${pageId}`,
    validate: 'sections',
    isEnabled: statusPage,
  });

  const handleChangeTab = (event, newValue) => {
    setSelectedTabLanguage(clientLanguage[newValue].id);
    setValueTab(newValue);
  };

  const optionsCategory = CategoriesData?.data?.results?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const optionsWidgets = [
    {
      label: widgetData?.name,
      value: widgetData?.id,
    },
  ];

  const { mutate: pairingAssets } = usePatchOrPut({ url: `articles/${articleSelected}/pair_assets`, validate: ['article', 'section'] });

  const onPairAssets = async (data) => {
    await pairingAssets(data);
  };

  const devLang = [];

  defaultValues?.article_languages?.map((l) => {
    devLang.push(l);
    return devLang;
  });

  const onSubmit = (data) => {
    onFormSubmit({
      type: data?.type,
      article_languages: removeEmptyProperties(data.article_languages, ['path']),
      location: attachLocation?.id ?? defaultValues?.location?.id ?? null,
      name: data.name || data?.article_languages[0]?.title,
      client: clientId,
      author: data.author,
      categories: data.categories,
      date: renderDate(data.date) || renderCurrentDate(defaultValues),
      status: data.status,
      widgets: [widgetData.id],
      article_assets: assetPair.length > 0 ? assetPair.map((asset) => asset.id) : selectedAssets.map((asset) => asset.id),
    });
    // reset();
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_MAP_KEY,
    libraries: lib,
  });

  const onDestroyArticle = useDelete({
    url: `articles/delete_articles`,
    confText: `modals.article_delete.confirmation_text`,
    confTitle: `modals.article_delete.title`,
    success: 'Article deleted',
    validate: ['articles', 'widget', 'section'],
    isSubUrl: false,
  });

  const selectedArticleType = watch('type');

  useEffect(() => {
    if (!defaultValues) {
      setValue(`type`, 'Full Article');
    }
  }, [defaultValues, setValue]);

  useEffect(() => {
    clientLanguage?.forEach((lang, index) => setValue(`article_languages[${index}][page]`, pageId));
    clientLanguage?.forEach((lang, index) => setValue(`article_languages[${index}][section]`, sectionId));
    clientLanguage?.forEach((lang, index) => setValue(`article_languages[${index}][language]`, lang.language.id));
  }, [clientLanguage, pageId, sectionId, setValue]);

  const editorRef = useRef();

  useEffect(() => {
    if (editorRef.current) {
      const editorInstance = editorRef.current.editor;
      editorInstance.model.change((writer) => {
        const element = writer.createElement('elementName');
        writer.addClass('className', element);
        writer.append(element, editorInstance.model.document.getRoot());
      });
    }
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full space-y-4">
          <div className="w-6/12 mt-5">
            <ReactSelectSingle
              name="type"
              control={control}
              options={getOptionSelectWithKey(articleTypes)}
              defaultValue={getDefaultSelectObject(defaultValues?.type || watch('type'), getOptionSelectWithKey(articleTypes))}
              placeholder="Select Type"
            />
          </div>

          <div className="flex w-full gap-x-3">
            {selectedArticleType === 'Full Article' && (
              <div className="w-6/12">
                <DatePickerField sx={{ marginBottom: '20px', marginTop: '20px' }} control={control} name="date" label="Date" size="small" fullWidth />
              </div>
            )}
            <div className="w-6/12 mt-5">
              <ReactSelectSingle
                name="status"
                control={control}
                options={getOptionSelectWithKey(optionsStatus)}
                defaultValue={getDefaultSelectObject(defaultValues?.status, getOptionSelectWithKey(optionsStatus))}
                placeholder="Select Status"
              />
            </div>
          </div>

          {selectedArticleType === 'Full Article' && (
            <div className="flex  gap-x-3 ">
              <div className="w-6/12">
                <ReactSelectSingleIcon
                  name="author"
                  control={control}
                  options={getSelectOptionWithIcon(AuthorsData?.data?.results)}
                  defaultValue={generateSelectObjectDefault(defaultValues?.author)}
                  placeholder="Select Author"
                  variant="icon"
                />
              </div>
              <div className="w-6/12 bg-white">
                <Controller
                  name="widgets"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <ReactSelectMultiple
                      options={optionsWidgets}
                      defaultValue={generateSelectListDefault([widgetData])}
                      placeholder="Select Collections"
                      isMulti
                      onChange={(v) => onChange(v?.map((val) => val.value))}
                      isDisabled
                    />
                  )}
                />
              </div>
            </div>
          )}

          {selectedArticleType === 'Full Article' && (
            <div className="flex gap-x-3">
              <div className="w-6/12">
                <InputContainer
                  name="name"
                  control={control}
                  label="Name"
                  // errors={errors.name}
                />
              </div>
              <div className="w-6/12 bg-white mt-5">
                <Controller
                  name="categories"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <ReactSelectMultiple
                      options={optionsCategory}
                      defaultValue={generateSelectListDefault(defaultValues?.categories)}
                      placeholder="Select Categories"
                      isMulti
                      onChange={(v) => onChange(v?.map((val) => val.value))}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <MuiCusotmTabs
            optionsTab={clientLanguage.map((lang) => {
              return {
                name: lang?.language?.name,
                id: lang?.language?.id,
              };
            })}
            value={valueTab}
            onChange={handleChangeTab}
          />
          {clientLanguage?.map((lang, index) => (
            <MuiCustomTabPanel key={lang.id} value={valueTab} index={index}>
              <div className=" ">
                <InputContainer name={`article_languages[${index}][title]`} control={control} label="Title" />
                {selectedArticleType === 'Full Article' && (
                  <InputContainer
                    name={`article_languages[${index}][summary]`}
                    control={control}
                    label="Summary"
                    // errors={errors.summary}
                  />
                )}
                <div className="typo-style">
                  <Controller
                    name={`article_languages[${index}][content]`}
                    control={control}
                    render={({ field: { value } }) => (
                      <CKEditor
                        id="editor"
                        key={lang.id}
                        editor={Editor}
                        config={{
                          ...editorConfigs,
                          importFile: {
                            handleVisibleDialog: (e) => e && dispatch(handleModal({ modalId: '', componentName: 'assetArticleNew' })),
                          },
                        }}
                        data={value || ''}
                        onChange={(event, editor) => {
                          const editorData = editor.getData();
                          setValue(`article_languages[${index}][content]`, editorData);
                        }}
                        onReady={(editor) => {
                          setEditorInstance(editor);
                          editorRef.current = editor;
                        }}
                        // ref={editorRef}
                      />
                    )}
                  />
                </div>
              </div>

              {selectedArticleType === 'Full Article' && (
                <>
                  <div className="">
                    <InputContainer name={`article_languages[${index}][description]`} control={control} label="Seo Description" />
                  </div>
                  <div className="">
                    <div className="font-bold">Keyword</div>
                    <div className="pt-5">
                      {clientLanguage?.map((lang) => {
                        return (
                          <div key={lang?.language?.id} className={lang.language.id === selectedTabLanguage ? 'block space-y-2' : 'hidden'}>
                            <div className="font-bold">{lang?.language?.name}</div>
                            <FieldArrKey
                              index={lang?.language?.id}
                              defaultValues={defaultValues}
                              language={lang?.language.id}
                              resIdArticle={resIdArticle}
                              setResIdArticle={setResIdArticle}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </MuiCustomTabPanel>
          ))}

          {['Article', 'Full Article'].includes(selectedArticleType) && (
            <>
              <div className="flex flex-col space-y-4">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Url Type</FormLabel>
                  <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={valueUrl} onChange={handleChangeUrl}>
                    <FormControlLabel value="internal" control={<Radio />} label="Internal" />
                    <FormControlLabel value="external" control={<Radio />} label="External" />
                  </RadioGroup>
                </FormControl>
                {valueUrl === 'internal' && (
                  <div className="">
                    <div className="">
                      <ReactSelectSingle
                        name="page"
                        control={control}
                        options={getOptionSelectWithKey(listPage?.data?.results)}
                        defaultValue={pageId && getDefaultSelectObject(pageId, getOptionSelectWithKey(listPage?.data?.results))}
                        placeholder="Select Page"
                        isClearable
                      />
                    </div>

                    <div className="mt-7">
                      <ReactSelectSingle
                        name="section"
                        control={control}
                        options={getOptionSelectWithKey(listSection?.data?.sections)}
                        defaultValue={sectionId && getDefaultSelectObject(sectionId, getOptionSelectWithKey(listSection?.data?.sections))}
                        placeholder="Select Section"
                        isClearable
                      />
                    </div>
                  </div>
                )}
                {valueUrl === 'external' && (
                  <div className="">
                    {clientLanguage?.map((lang, index) => (
                      <InputContainer key={lang.language.id} name={`article_languages[${index}][path]`} control={control} label={`Url ${lang.language.name}`} />
                    ))}
                  </div>
                )}
              </div>

              <div className="!mt-6">
                {defaultValues?.location && isLoaded && !attachLocation && (
                  <div>
                    <div className="w-full mb-5">
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{ lat: parseFloat(defaultValues?.location.latitude), lng: parseFloat(defaultValues?.location.longitude) }}
                        zoom={10}
                        options={{
                          scrollwheel: false,
                        }}
                      >
                        <Marker position={{ lat: parseFloat(defaultValues?.location.latitude), lng: parseFloat(defaultValues?.location.longitude) }} />
                      </GoogleMap>
                      <div>{defaultValues?.location?.address}</div>
                    </div>
                  </div>
                )}

                {attachLocation && (
                  <div>
                    <div className="w-full mb-5">
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{ lat: parseFloat(attachLocation.latitude), lng: parseFloat(attachLocation.longitude) }}
                        zoom={10}
                        options={{
                          scrollwheel: false,
                        }}
                      >
                        <Marker position={{ lat: parseFloat(attachLocation.latitude), lng: parseFloat(attachLocation.longitude) }} />
                      </GoogleMap>
                      <div>{attachLocation?.address}</div>
                    </div>
                  </div>
                )}
                {defaultValues?.location ? (
                  <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'locationArticle' }))} className="" type="button" variant="outlined">
                    Change Location
                  </Button>
                ) : (
                  <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'locationArticle' }))} className="" type="button" variant="outlined">
                    Add Location
                  </Button>
                )}
                <ModalWrapper componentName="locationArticle" header="Select Location" maxWidth="lg">
                  <BaseLocationViewer
                    onClick={() => dispatch(handleModal({ modalId: '' }))}
                    attachLocation={attachLocation}
                    setAttachLocation={setAttachLocation}
                    handleClick={() => dispatch(handleModal({ modalId: '' }))}
                  />
                </ModalWrapper>
              </div>
            </>
          )}

          {['Article', 'Full Article'].includes(selectedArticleType) && (
            <>
              <div className="ml-auto flex gap-x-3">
                <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'assetArticle' }))} className="ml-auto flex" type="button" variant="outlined">
                  Add New Asset
                </Button>
                {defaultValues ? <UnpairAssetButton isApi url={`articles/${articleSelected}/unpair_assets`} validate={['article']} /> : <UnpairAssetButton />}
              </div>

              <div className=" border-dashed min-h-[160px] border-4 p-3 gap-3 flex flex-wrap">
                {defaultValues ? (
                  <AssetCardNew assetsData={defAssets} assetComponent="assetPairEditMenu" />
                ) : (
                  assetPair.map((asset) => <AssetCardNew assetsData={asset} key={asset?.id} assetComponent="assetPairMenu" />)
                )}
              </div>
            </>
          )}

          <div className=" justify-end flex gap-x-3">
            {articleSelected && (
              <Button onClick={() => onDestroyArticle({ bodyData: { articles: [articleSelected] } })} className="" type="button" variant="outlined">
                Delete
              </Button>
            )}
            <Button className="" type="submit" variant="outlined">
              {defaultValues ? 'Update Article' : 'Create Article'}
            </Button>
          </div>
        </div>
      </form>
      <DevTool control={control} />
      <ModalWrapper componentName="assetArticle" header="Add Asset" maxWidth="lg">
        <div className="">
          <BaseAssetViewer handleClick={defaultValues ? () => onPairAssets({ assets: assetPair?.map((asset) => asset.id) }) : () => dispatch(handleModal({ modalId: '' }))} />
        </div>
      </ModalWrapper>
      <ModalWrapper componentName="assetArticleNew" header="Add Asset" maxWidth="lg">
        <div className="">
          <BaseAssetViewer
            assetComponent="single"
            setAssetQuality={(e) => handleQuality(e)}
            assetQuality={assetQuality}
            handleClick={() => {
              const myArray = assetPair;
              const latestElement = myArray.length === 1 ? myArray[0] : myArray[myArray.length - 1];
              insertImage(assetQuality?.file || latestElement?.thumbnail || latestElement?.asset_file);
              dispatch(resetAsset());
              dispatch(handleModal({ modalId: '' }));
            }}
          />
        </div>
      </ModalWrapper>
    </div>
  );
}

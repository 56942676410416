/* eslint-disable */
import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import BaseMainTitle from '../../../components/BaseMainTitle';
import { fetchEmployee, fetchEmployeeTeam, fetchWhistleblowers, useWhistleblowersData } from '../../../hooks/useWhistleblowerData';
import BZHelmet from '../../../utils/BZHelmet';
import TableEmployee from './TableEmployee';

export default function Employee() {
  const clientId = useSelector((state) => state.client.activeClient);
  const [dataEmployee, setDataEmployee] = useState([]);
  const [isDataLoad, setIsDataLoad] = useState(false);
  const isTeam = true;
  useQuery(['Employees', { clientId, isTeam }], fetchEmployeeTeam, {
    onSuccess: (e) => {
      setDataEmployee(e?.data?.results);
      setIsDataLoad(true);
    },
  });

  const data = useMemo(() => dataEmployee, [dataEmployee]);

  console.log(data);

  return (
    <div>
      <BZHelmet title="Employee" description="bz publish  Employee" />
      <BaseMainTitle title="Employee" />
      <div className="p-5">
        <TableEmployee dataApi={data} isDataLoad={isDataLoad} setIsDataLoad={setIsDataLoad} />
      </div>
    </div>
  );
}

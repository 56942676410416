/* eslint-disable max-len */
import { Button, Checkbox, Chip, CircularProgress, ClickAwayListener, FormControlLabel, FormGroup } from '@mui/material';
import React, { Suspense, useEffect, useState } from 'react';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import ProcurementApi from '../../api/Procurement/Procurement';
import { Item } from '@radix-ui/react-accordion';

const markerIcon = (pin, d, markerColor) => ({
  path: faLocationDot.icon[4],
  // fillColor: pin.requestedMarkerId === d.id ? '#E74C3C' : markerColor,
  fillColor: markerColor,
  anchor: new window.google.maps.Point(faLocationDot.icon[0] / 2, faLocationDot.icon[1]),
  fillOpacity: pin.requestedMarkerId === d.id ? 1 : 0.9,
  strokeWeight: 0.3,
  scale: 0.075,
});

const ContainerFilter = styled.div`
  opacity: ${(props) => (props.open ? '1' : '0')};
  max-height: ${(props) => (props.open ? '100%' : '0')};
  overflow: hidden;
  padding-bottom: 16px;
  padding: ${(props) => (props.open ? '16px 0px 0px 0px' : '0')};
  transition: all 0.1s;
`;

const mapLib = ['drawing', 'places'];

const { getCRMAttributeFilter, getCRMProjects, getCRMProjectProfiles, getCountries, getAllCustomerLocations } = ProcurementApi();

export default function VendorLocations() {
  const activeClient = useSelector((state) => state.client.activeClient);

  const [activeMarker, setActiveMarker] = useState(null);
  const [pin, setPin] = useState({});
  const [filters, setFilters] = useState(null);
  const [openFilter, setOpenFilter] = useState(null);
  const [centerPosition, setCenterPosition] = useState(null);
  const attrProfile = 'project_profile';
  const attrCountry = 'country';
  const [projectProfileFilter] = useState([]);
  const [customersLocation, setCustomersLocation] = useState([]);

  const [mapPosition, setMapPosition] = useState({ lat: 40.748817, lng: -73.985428 });
  const [mapZoom, setMapZoom] = useState(13);

  // console.log("customersLocation ", customersLocation);

  useEffect(() => {
    const handleResize = () => {
      setMapPosition({ lat: 40.748817, lng: -73.985428 });
      setMapZoom(13);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_MAP_KEY,
    libraries: mapLib,
  });

  useQuery({
    queryKey: ['crm-customer-locations', activeClient, filters],
    enabled: !!activeClient,
    queryFn: () => getAllCustomerLocations({ client: activeClient, ...filters }),
    onSuccess: (res) => {
      // console.log({res});
      setCustomersLocation(res.data);
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  // const { data: customerLocations, isRefetching } = useQuery({
  //   queryKey: ['crm-customer-locations'],
  //   queryFn: () => getAllCustomerLocations({ client: activeClient }),
  //   onSuccess: (data) => {
  //     console.log({ data });
  //     console.log("activeClient ", activeClient);
  //   },
  //   // refetchOnWindowFocus: false,
  // });

  const filterProject = (data, key, filter = []) => {
    const getProperty = (obj, prop) => {
      const props = prop.split('.');
      let value = obj;
      for (let i = 0; i < props.length; i++) {
        if (value != null) {
          value = value[props[i]];
        } else {
          break;
        }
      }

      return value;
    };

    const list = [];

    if (filter.length > 0) {
      data?.map((item) => {
        const propertyValue = getProperty(item, key);
        if (propertyValue != null && filter.indexOf(propertyValue) != -1) {
          list.push(item);
        }
      });
      return list;
    } else {
      return data;
    }
  };

  const { data: dataFilter } = useQuery({
    queryKey: ['crm-project-attribute-filter'],
    queryFn: () => getCRMAttributeFilter({ attribute__client: activeClient, type: 'Filter' }),
    onSuccess: (data) => {
      // console.log({ data });
    },
    // refetchOnWindowFocus: false,
  });

  // https://api.bzpublish.com/countries/
  const { data: dataCountry } = useQuery({
    queryKey: ['countries'],
    queryFn: () => getCountries({ max_size: true }),
    onSuccess: (data) => {
      // console.log({ data });
    },
    refetchOnWindowFocus: false,
  });

  // const defaultCenter = { lat: 0, lng: 0 };
  let locations = null;

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      setActiveMarker(null);
    } else {
      setActiveMarker(marker);
    }
  };

  const handleChangePin = (id) => {
    if (pin?.requestedMarkerId === id) {
      setPin({});
    } else {
      setPin({ requestedMarkerId: id });
    }
  };

  const handleFilter = (attr, val, id) => {
    if (filters) {
      setFilters((state) => {
        const isAttrExist = attr in state;
        const currState = state;
        let selectedAttr = {};
        if (isAttrExist) {
          if (val) {
            selectedAttr = currState[attr]?.length ? currState[attr].push(id) : (currState[attr] = [id]);
          } else {
            selectedAttr[attr] = currState[attr].filter((a) => a !== id);
          }
        } else {
          selectedAttr[attr] = [id];
        }
        return { ...state, ...selectedAttr };
      });
    } else {
      const f = { [attr]: [id] };
      setFilters(f);
    }
  };

  if (customersLocation) {
    locations = customersLocation?.map((dt) => {
      const markerColor = '#E74C3C';

      console.log('dt ', dt);
      console.log('activeClient ', activeClient);

      return (
        <Marker
          key={dt?.id}
          animation={window.google.maps.Animation.DROP}
          position={{
            lat: parseFloat(dt?.latitude),
            lng: parseFloat(dt?.longitude),
          }}
          icon={markerIcon(pin, dt, markerColor)}
          title={String(dt?.name)}
          onClick={() => [handleActiveMarker(dt?.id), handleChangePin(dt?.id)]}
        >
          {activeMarker === dt?.id && (
            <InfoWindow onCloseClick={() => [setActiveMarker(null), setPin({})]}>
              <div className="w-[296px] h-full px-2 py-2 space-y-4">
                {dt?.member?.name && renderInfoBlock('Company Name', dt?.member?.name)}
                {renderInfoBlock('Location Name', dt?.name)}

                {Object.entries(dt?.member).map(renderMemberInfoBlock)}
                {renderInfoBlock('Address', dt?.address)}
              </div>
            </InfoWindow>
          )}
        </Marker>
      );
    });
  }

  function renderInfoBlock(label, value) {
    return (
      <div key={label} className="grid gap-1">
        <p className="font-bold">{label}</p>
        <p className="font-normal">{value || '-'}</p>
      </div>
    );
  }

  function renderMemberInfoBlock(entry) {
    const [key, value] = entry;
    return (
      key !== 'id' &&
      key !== 'name' && (
        <div key={key} className="grid gap-1">
          <p className="font-bold">{key}</p>
          {renderMemberValue(value)}
        </div>
      )
    );
  }

  function renderMemberValue(value) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        return value.map((data, index) => (
          <p key={index} className="font-normal">
            {data}
          </p>
        ));
      } else {
        return <p className="font-normal">-</p>;
      }
    } else {
      return <p className="font-normal">{value || '-'}</p>;
    }
  }

  useEffect(() => {
    if (customersLocation.length > 0 && !centerPosition) {
      const totalLat = customersLocation.reduce((acc, customer) => acc + parseFloat(customer.latitude), 0);
      const totalLng = customersLocation.reduce((acc, customer) => acc + parseFloat(customer.longitude), 0);

      const centerLat = (totalLat / customersLocation.length).toFixed(6);
      const centerLng = (totalLng / customersLocation.length).toFixed(6);

      const shiftAmount = 20;
      const shiftedCenterLng = (parseFloat(centerLng) - shiftAmount).toFixed(6);

      const newCenterPosition = { lat: +centerLat, lng: +shiftedCenterLng };
      setCenterPosition(newCenterPosition);
    }
  }, [setCenterPosition, customersLocation, centerPosition]);

  return (
    isLoaded && (
      <Suspense fallback={<CircularProgress />}>
        {/* {usedData && ( */}
        <ClickAwayListener onClickAway={() => {}}>
          <div className="relative d-flex flex-column mt-4" style={{ width: '100%' }}>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: 'calc(100vh - 500px)' }}
              center={centerPosition}
              zoom={3}
              className="border-2"
              options={{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                // zoom:3,
                // minZoom: 2,
                // maxZoom: 28,
                // restriction: {
                //   latLngBounds: {
                //     north: 85.0 ,
                //     south: -80.0,
                //     west: -2.0,
                //     east: 2.0,
                //   },}
              }}
            >
              {locations}
            </GoogleMap>

            {/* {dataFilter && ( */}
            <section className="py-4 pr-4 bg-white bg-opacity-90 rounded-sm absolute z-[1] top-0 left-0">
              <div className="relative min-w-48 min-h-8 h-full flex flex-col">
                <div className="pl-4">
                  <button type="button" onClick={() => setOpenFilter(!openFilter)} className="flex justify-between items-center w-full">
                    <span className="font-bold">Filter</span>
                    {!openFilter ? <KeyboardDoubleArrowDown className="" /> : <KeyboardDoubleArrowUp className="" />}
                  </button>
                </div>

                <ContainerFilter open={!!openFilter} className="flex flex-col">
                  <div className="flex max-h-80">
                    {dataFilter?.map((f, i) => {
                      return (
                        <div key={f.attribute.name} className="flex flex-col overflow-y-auto">
                          <span className="font-bold mb-2 px-4">{f.attribute.name}</span>
                          <div className="flex flex-col flex-wrap gap-1">
                            {f?.attribute?.options.map((o) => {
                              const bg = o.configuration?.color || '#000000';
                              const checked = !!filters && !!filters[f.attribute.key_name] && !!filters[f.attribute.key_name].includes(o.name);
                              return (
                                <FormGroup key={o.name} className="flex px-4">
                                  <FormControlLabel
                                    control={<Checkbox name={o.name} checked={checked} onChange={(e) => handleFilter(f.attribute.key_name, e.target.checked, o.name)} />}
                                    label={<Chip label={o.name} className="bg-opacity-70 cursor-pointer" sx={{ backgroundColor: bg, fontWeight: '700' }} />}
                                  />
                                </FormGroup>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                    <div className="overflow-y-auto">
                      <span className="font-bold mb-2 px-4 ">Main Location</span>
                      <FormGroup className="flex px-4">
                        <FormControlLabel
                          control={<Checkbox name="main locations" />}
                          label={<Chip label="main locations" className="bg-opacity-70 cursor-pointer" sx={{ backgroundColor: Item.color, fontWeight: '700' }} />}
                        />
                      </FormGroup>
                    </div>

                    {/* <div className='overflow-y-auto' >
                    <span className="font-bold mb-2 px-4 ">Project Profile</span>

                    {dataFilterProfile?.map((o, i) => {
                      o.color = '#00000';
                      const checked = !!filters && !!filters[attrProfile] && !!filters[attrProfile].includes(o.name);
                      return (
                        <FormGroup key={i} className="flex px-4">
                          <FormControlLabel
                            control={<Checkbox name={o.name} checked={checked} onChange={(e) => handleFilter(attrProfile, e.target.checked, o.name)} />}
                            label={<Chip label={o.name} className="bg-opacity-70 cursor-pointer" sx={{ backgroundColor: Item.color, fontWeight: '700' }} />}
                          />
                        </FormGroup>
                      )
                    }
                    )}
                  </div> */}

                    {/* country */}
                    <div className="overflow-y-auto">
                      <span className="font-bold mb-2 px-4 ">Country</span>
                      {dataCountry?.data?.results?.map((o, i) => {
                        o.color = '#00000';
                        const checked = !!filters && !!filters[attrCountry] && !!filters[attrCountry].includes(o.id);
                        return (
                          <FormGroup key={i} className="flex px-4">
                            <FormControlLabel
                              control={<Checkbox name={o.name} checked={checked} onChange={(e) => handleFilter(attrCountry, e.target.checked, o.id)} />}
                              label={<Chip label={o.name} className="bg-opacity-70 cursor-pointer" sx={{ backgroundColor: Item.color, fontWeight: '700' }} />}
                            />
                          </FormGroup>
                        );
                      })}
                    </div>
                  </div>
                  <hr className="my-2" />
                  <Button variant="text" className="ml-auto" onClick={() => setFilters(null)}>
                    Clear
                  </Button>
                </ContainerFilter>
              </div>
            </section>
          </div>
        </ClickAwayListener>
        {/* )} */}
      </Suspense>
    )
  );
}

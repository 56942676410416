import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Button, ButtonBase, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import DetailContext from '../../../../pages/Task/Projects/DetailContext';
import getTimeZone from '../../../../utils/Task/getTimeZone';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': { padding: theme.spacing(2) },
  '& .MuiDialogActions-root': { padding: theme.spacing(1) },
}));

const getMs = (timeValue) => {
  return timeValue.split(':').reduce((acc, cur, i) => {
    let multiplier = 1000;
    const currT = Number(cur) ? Number(cur) : 0;
    if (i === 0) {
      multiplier *= 3600;
      return currT * multiplier + acc;
    }
    if (i === 1) {
      multiplier *= 600;
      return currT * multiplier + acc;
    }
    if (i === 2) {
      multiplier *= 60;
      return currT * multiplier + acc;
    }
    return acc;
  }, 0);
};
function CustomDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

CustomDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

CustomDialogTitle.defaultProps = { children: <div /> };

function CustomContent({ title, config = { type: 'text', text: '', link: '' } }) {
  const { type, text, link } = config;
  let content = <span className="w-[calc(100%_-_128px)]">{text}</span>;
  if (type === 'link') {
    content = (
      <Link to={link} className="text-blue-500">
        Go to task
      </Link>
    );
  }
  return (
    <div className="w-full flex">
      <span className="w-32 capitalize">{title}</span>
      {content}
    </div>
  );
}

export default function TaskDialogWarning({ open, setOpen }) {
  const dispatch = useDispatch();
  const myContextDetail = useContext(DetailContext);
  const runningTask = useSelector((state) => state.task.runningTask);
  const [updatedStoppedTime, setUpdatedStoppedTime] = useState(new Date());
  const [isUpdate, setIsUpdate] = useState(false);

  const handleClose = () => {
    setUpdatedStoppedTime(new Date());
    setIsUpdate(false);
    setOpen(false);
  };

  // const handleGoToTask = (param) => {
  //   myContextDetail.trigger.setTriggerWarning(false);
  //   if (param) {
  //     navigate(param, { replace: false });
  //     myContextDetail.trigger.setTriggerRedraw(true);
  //   }
  // };

  const handleAction = (param) => {
    if (runningTask) {
      const dataLog = { topic: runningTask.task?.id };
      const dataLogNew = { topic: myContextDetail.trigger.triggerWarning?.task?.id };

      const activeTask = { project: { ...runningTask.project }, task: { ...runningTask.task } };
      const activeTaskNew = { ...myContextDetail.trigger.triggerWarning };
      const currDate = new Date();
      const currTimezone = getTimeZone();
      const tz = {
        name: currTimezone.name,
        utc_offset: currTimezone.offset,
      };
      dataLog.timezone = tz;
      dataLogNew.timezone = tz;

      dataLog.time_out = currDate.toISOString();
      if (param === 'limit') {
        const tOut = new Date(runningTask.time_in);
        dataLog.time_out = new Date(Math.abs(tOut.getTime() + getMs(myContextDetail?.projects?.assignee_log_limit))).toISOString();
      }
      if (param === 'update-limit') {
        dataLog.time_out = new Date(updatedStoppedTime).toISOString();
      }
      myContextDetail.logWorkingHours({ request: dataLog, state: activeTask }, runningTask?.id, {}, 'patch', false, () => {
        myContextDetail.logWorkingHours({ request: dataLogNew, state: activeTaskNew }, myContextDetail.trigger.triggerWarning?.task?.id, {}, 'post', false, () => {
          myContextDetail.trigger.setTriggerDrawer(true);
          myContextDetail.trigger.setTriggerWarning(false);
        });
      });
    }
  };

  const goToLink = `/task/project/${runningTask?.project?.id}/${runningTask?.task?.id}`;
  let goToHtml = (
    // <Link onClick={myContextDetail.handleGoToTask} to={goToLink} className="text-blue-500 mx-1">
    <ButtonBase
      onClick={() => {
        dispatch({ type: 'task/setTriggerCloseDrawer', payload: true });
        myContextDetail.handleGoToTask(goToLink);
      }}
      to={goToLink}
      className="text-blue-500 mx-1"
    >
      Go to task
    </ButtonBase>
  );
  if (myContextDetail.trigger.triggerWarning?.project?.id === runningTask?.project?.id) {
    goToHtml = (
      <ButtonBase
        onClick={() => {
          dispatch({ type: 'task/setTriggerCloseDrawer', payload: true });
          myContextDetail.handleGoToTask(goToLink);
        }}
        className="text-blue-500 mx-1"
      >
        Go to task
      </ButtonBase>
    );
  }

  let limitWarning;
  let limitTime;
  let limitType = {
    single: 'minute',
    multiple: 'minutes',
    divider: 60,
  };

  if (runningTask && runningTask.time_in) {
    const startTimer = new Date(runningTask.time_in).getTime();
    const currTimer = new Date().getTime();
    const tSec = Math.floor(Math.abs(currTimer - startTimer) / 1000);
    const lType = myContextDetail?.projects?.assignee_log_limit?.substring(0, 1);
    if (+lType > 0) {
      limitType = {
        single: 'hour',
        multiple: 'hours',
        divider: 3600,
      };
    }

    const tToday = new Date(`${format(new Date(), 'yyyy-MM-dd')} 00:00:00`).getTime();
    const tTodayLimit = new Date(`${format(new Date(), 'yyyy-MM-dd')} ${myContextDetail?.projects?.assignee_log_limit}`).getTime();
    limitTime = Math.floor(Math.floor(Math.abs(tTodayLimit - tToday) / 1000) / limitType.divider);
    const tHours = Math.floor(tSec / limitType.divider);

    if (tHours > limitTime) {
      limitWarning = true;
    }
  }

  return (
    open && (
      <CustomDialog sx={{ color: 'red', '& > .MuiDialog-container > .MuiPaper-root': { maxWidth: 496 } }} onClose={handleClose} aria-labelledby="warning-dialog-task" open={open}>
        <CustomDialogTitle id="warning-dialog-task" onClose={handleClose}>
          {isUpdate && 'Set time'}
          {!isUpdate && runningTask && 'Warning'}
        </CustomDialogTitle>
        {!isUpdate && runningTask && (
          <>
            <DialogContent dividers>
              <Typography gutterBottom>
                <b>You have already working on another task:</b>
              </Typography>
              <CustomContent title="project" config={{ type: 'text', text: `${runningTask?.project?.name} - ${`(${runningTask?.project?.id})`}` }} />
              <CustomContent title="task" config={{ type: 'text', text: `${runningTask?.task?.name} - ${`(${runningTask?.task?.id})`}` }} />
              <CustomContent title="start" config={{ type: 'text', text: format(new Date(runningTask?.time_in), 'dd-MMMM-yyyy HH:mm:ss') }} />
              {limitWarning && (
                <div className="mt-4">
                  <Typography gutterBottom className="text-[14px]">
                    <b>
                      You still have task that is still running for more than {limitTime} {limitTime > 1 ? limitType.multiple : limitType.single}
                    </b>
                    <br />
                    you can start this task by:
                    <br />
                    1. edit your stopped time
                    <br />
                    2. without editing your stopped time, the stopped time will follow your project time limit
                  </Typography>
                </div>
              )}
            </DialogContent>
            <DialogActions className="flex justify-between">
              {goToHtml}
              {!limitWarning && (
                <Button variant="contained" autoFocus onClick={handleAction} className="text-xs">
                  Start Task
                </Button>
              )}
              {limitWarning && (
                <div className="flex space-x-2">
                  <Button variant="contained" autoFocus onClick={() => setIsUpdate(true)} className="text-xs">
                    Start Task &nbsp; <span className="text-[9px] lowercase">(edit time)</span>
                  </Button>
                  <Button variant="contained" autoFocus onClick={() => handleAction('limit')} className="text-xs">
                    Start Task
                  </Button>
                </div>
              )}
            </DialogActions>
          </>
        )}
        {isUpdate && (
          <>
            <DialogContent dividers>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  ampm={false}
                  ampmInClock={false}
                  minDate={() => {
                    const tOut = new Date(runningTask?.time_in);
                    return new Date(tOut.getTime + 1);
                  }}
                  value={updatedStoppedTime}
                  onChange={(newValue) => {
                    const tOut = new Date(runningTask?.time_in).getTime();
                    const tIn = new Date(newValue).getTime();
                    if (tIn < tOut) {
                      return;
                    }
                    setUpdatedStoppedTime(newValue);
                  }}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </LocalizationProvider>
            </DialogContent>
            <DialogActions className="flex ml-auto space-x-2">
              <Button variant="outlined" onClick={handleClose} className="text-xs">
                Cancel
              </Button>
              <Button variant="contained" autoFocus onClick={() => handleAction('update-limit')} className="text-xs">
                Confirm
              </Button>
            </DialogActions>
          </>
        )}
      </CustomDialog>
    )
  );
}

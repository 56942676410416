import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';

export default function ReactShortableWrapper({ list, setList, onPatch, children, className, animation, ghostClass }) {
  const [onReorder, setOnReorder] = useState(false);
  const [isChange, setIsChange] = useState(false);
  useEffect(() => {
    if (onReorder && isChange) {
      if (onPatch) {
        onPatch();
      }
      setOnReorder(false);
      setIsChange(false);
    }
  }, [onPatch, onReorder, isChange]);

  function arraysNotSame(arr1, arr2) {
    return JSON.stringify(arr1) !== JSON.stringify(arr2); // arrays are the same if all corresponding elements are the same
  }
  return (
    list && (
      <ReactSortable
        ghostClass={ghostClass}
        animation={animation}
        swap
        list={list}
        setList={(newState) => {
          if (arraysNotSame(list, newState)) {
            console.log('same');
            setList(newState);
            setIsChange(true);
          }
        }}
        onEnd={() => setOnReorder(true)}
        className={className}
      >
        {children}
      </ReactSortable>
    )
  );
}

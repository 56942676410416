import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Divider, FormControl, FormControlLabel, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import BaseAutocompleteValidation from '../../Input/Base/BaseAutocompleteValidation';
import BaseInputValidation from '../../Input/Base/BaseInputValidation';
import { handleModal } from '../../../store/slices/modalSlice';
import crmApi from '../../../api/CRM/CRMCustomers';

const { getCRMProjectProfiles, createCRMProfileGroups, actionCRMProfileGroups } = crmApi();
const staticSchema = yup.object().shape({
  profile: yup.object().required('Profile is a required field'),
  // profile: yup.object().nullable(),
  name: yup.string().required('Name is a required field'),
});

export default function AttributeGroup({ groupSelected, refetch }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isModal = useSelector((state) => state.modal);
  const activeClient = useSelector((state) => state.client.activeClient);

  const {
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(staticSchema),
    defaultValues: {},
  });

  const { data: dataProfile } = useQuery({
    queryKey: ['crm-project-profiles'],
    queryFn: () => getCRMProjectProfiles({ client: activeClient, max_size: true }),
    onSuccess(res) {
      if (groupSelected && groupSelected.profile) {
        const selectedProfile = res.find((r) => r.id === groupSelected.profile);
        if (selectedProfile) {
          setValue('profile', selectedProfile);
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  const { data: dataGroupProfileAttribute } = useQuery({
    queryKey: ['crm-project-profile-group', groupSelected?.id],
    queryFn: () => actionCRMProfileGroups(groupSelected.id, 'get'),
    enabled: !!groupSelected,
    refetchOnWindowFocus: false,
  });

  const onCloseModal = () => {
    dispatch(handleModal({ modalId: '', componentName: '' }));
  };

  const onSubmit = (dataForm) => {
    const data = { ...dataForm };
    if (dataForm?.profile?.id) {
      data.profile = dataForm.profile.id;
    }
    if (!groupSelected) {
      createCRMProfileGroups(data)
        .then(() => {
          onCloseModal();
          refetch();
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.response?.data?.details ? err.response?.data?.details[0] : 'Something went wrong', { variant: 'Error' });
        });
    } else {
      actionCRMProfileGroups(groupSelected.id, 'patch', data)
        .then(() => {
          onCloseModal();
          refetch();
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.response?.data?.details ? err.response?.data?.details[0] : 'Something went wrong', { variant: 'Error' });
        });
    }
  };

  useEffect(() => {
    if (groupSelected) {
      const { name, index, is_displayed: isDisplayed } = groupSelected;
      setValue('name', name);
      setValue('index', index);
      setValue('is_displayed', isDisplayed);
    }
  }, [groupSelected, setValue]);

  useEffect(() => {
    if (!isModal.isOpen) {
      reset({
        name: '',
        index: '',
        is_displayed: '',
      });
    }
  }, [isModal.isOpen, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <BaseAutocompleteValidation
        control={control}
        name="profile"
        label="Profile Attribute"
        errors={errors}
        options={dataProfile || []}
        optionLabel="name"
        config={{ classes: 'h-10' }}
      />
      <BaseInputValidation control={control} name="name" label="Name" errors={errors} required />
      <BaseInputValidation control={control} name="index" label="Index" errors={errors} required />
      <FormControl>
        <Controller
          control={control}
          name="is_displayed"
          render={({ field: { onChange, value, ref } }) => (
            <FormControlLabel className="h-8" control={<Checkbox onChange={onChange} checked={value || false} inputRef={ref} />} label="Displayed" />
          )}
        />
      </FormControl>
      {dataGroupProfileAttribute?.data?.profile_attributes && (
        <div>
          <span className="font-bold">Profile Attributes</span>
          {dataGroupProfileAttribute.data.profile_attributes.map((attr, i) => {
            return (
              <div key={attr.id} className="flex gap-2 w-full">
                <span className="w-4">{i + 1}</span>
                <span>{attr.attribute.name}</span>
              </div>
            );
          })}
        </div>
      )}

      <Divider />
      <Stack direction="row" spacing={1} className="ml-auto">
        <Button variant="outlined" onClick={() => dispatch(handleModal({ modalId: '', componentName: '' }))}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Save
        </Button>
      </Stack>
    </form>
  );
}

import React, { useState } from 'react'
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Box, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
  Download as DownloadIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  ArrowBack,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import ModalWrapper from '@/components/ui/ModalWrapper';
import OutletPairCollections from '@/components/Form/Sales/OutletPairCollections';
import { handleModal } from '@/store/slices/modalSlice';
import PairOperators from '@/components/Form/Sales/PairOperators';

function OperatorsTab({outletEmployees, SelectForm, POSApi, OutletDetail, outletId}) {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch();

  const [outletCollectionChange, setOutletCollectionChange] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isAllSelectedEmployees, setIsAllSelectedEmployees] = useState(false);

  const handleSelectAllClickEmployees = (event) => {    
    if (selectedEmployees.length === outletEmployees.length) {
      setIsAllSelectedEmployees(false);
      setSelectedEmployees([]);
      return;
    } else {
      const newSelecteds = outletEmployees?.map((n) => n.id);
      setIsAllSelectedEmployees(true);
      setSelectedEmployees(newSelecteds);
      return;
    }
  };

  const handleClickEmployees = (event, id) => {
    const selectedIndex = selectedEmployees.indexOf(id);
    let newSelected = [...selectedEmployees];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedEmployees(newSelected);
  };

  const submitEmployees = (data) => {
    const { updateOutletData } = POSApi();

    const outletEmployeesIds = outletEmployees.length > 0 ? outletEmployees?.map((dt) => dt.id) : [];

    const EmployeesData = {
      employees: [...outletEmployeesIds, ...data]
    }

    try {
      updateOutletData(outletId, EmployeesData)
      .then(() => {
        dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
        OutletDetail();
        Swal.fire({
          title: 'Success !',
          text: 'Successfully paired selected Employees !',
          icon: 'success',
        });
      })
      .catch((err) => {
        console.error(err);
      });
    } catch (error) {
      console.error(error)
    }
  }

  const unpairSelectedEmployees = () => {
    const { updateOutletData } = POSApi();

    const outletEmployeesIds = outletEmployees.length > 0 ? outletEmployees.map((dt) => dt.id) : [];
    const filteredEmployeesIds = outletEmployeesIds.filter(id => !selectedEmployees.includes(id));

    const EmployeesData = {
      employees: filteredEmployeesIds
    };
    
    try {
      updateOutletData(outletId, EmployeesData)
      .then(() => {
        OutletDetail();
        setSelectedEmployees([]);
        Swal.fire({
          title: 'Success !',
          text: 'Successfully unpaired selected Employees !',
          icon: 'success',
        });
      })
      .catch((err) => {
        console.error(err);
      });
    } catch (error) {
      console.error(error)
    }
  }

  const isSelectedEmployees = (name) => selectedEmployees.indexOf(name) !== -1;

  return (
    <>
      <div className="w-full flex justify-end mb-4 items-center gap-x-2">
        <button
          className={`
              flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
              ${selectedEmployees.length ? 'bg-[#FC581D] cursor-pointer ' : 'bg-[#FC581D26] cursor-default'}
            `}
          name="unpairCollection"
          type="button"
          disabled={selectedEmployees.length === 0 ? true : false}
          onClick={unpairSelectedEmployees}
        >
          Remove Operators
        </button>
        <button
          className="btn bg-[#2C6D47] p-2 text-white rounded-lg border cursor-pointer"
          name="manageOperators"
          type="button"
          onClick={() =>
            dispatch(
              handleModal({
                modalId: clientId,
                componentName: 'manageOperators',
                modalData: {
                  outletId: outletId,
                  outletEmployeesIds: outletEmployees.length > 0 ? outletEmployees?.map((dt) => dt.id) : [],
                }
              })
            )
          }
        >
          Manage Operators
        </button>
      </div>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selectedEmployees.length > 0 && selectedEmployees.length < outletEmployees.length}
                    checked={outletEmployees.length > 0 && selectedEmployees.length === outletEmployees.length}
                    onClick={handleSelectAllClickEmployees}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  Name
                </TableCell>
                <TableCell align="center" className="!font-bold" width={800}>
                  Email
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {outletEmployees.length > 0 && outletEmployees.map((coll, idx) => {
                const employeeName = coll.first_name + " " + coll.last_name;
                const employeeEmail = coll.email;
                const isItemSelected = isSelectedEmployees(coll.id);
                const labelId = `enhanced-table-checkbox-${idx}`;

                return (
                  <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={coll.id} selected={isItemSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox onClick={(event) => handleClickEmployees(event, coll.id)} color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                    </TableCell>
                    <TableCell align="left">{employeeName || '-'}</TableCell>
                    <TableCell align="left">{employeeEmail || '-'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Box>
      <ModalWrapper componentName="manageOperators" header="Manage Outlet Operators" maxWidth="xl">
        <PairOperators submitEmployees={submitEmployees} />
      </ModalWrapper>
    </>
  )
}

export default OperatorsTab
import React, { useCallback, useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Autocomplete, Box, Button, ButtonBase, TextField, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers';
import { utils, writeFileXLSX } from 'xlsx';
import { DataGrid } from '@mui/x-data-grid';

import { format } from 'date-fns';
import getTimeZone from '../../../utils/Task/getTimeZone';
import accountingApi from '../../../api/accounting/accounting';
import projectApi from '../../../api/task/project';
import userApi from '../../../api/user';
import ModalTaskReport from '../../../components/Modal/Task/ModalTaskReport/ModalTaskReport';
import BZHelmet from '../../../utils/BZHelmet';
import ModalTaskStopTime from '../../../components/Modal/Task/ModalTaskReport/ModalTaskStopTime';
import moment from 'moment';

function formatDuration(totalMinutes) {
  const duration = moment.duration(totalMinutes, 'minutes');
  const formattedDuration = duration.format('HH:mm:ss');

  const splitTime = formattedDuration.split(':');
  const formatHour = splitTime.length === 3 ? splitTime[0] : splitTime.length === 4 ? splitTime[1] : 0;
  const formatMinute = splitTime.length === 3 ? splitTime[1] : splitTime.length === 4 ? splitTime[2] : splitTime[0];

  return ` ${formatHour.toString().padStart(2, '0')}:${formatMinute.toString().padStart(2, '0')}`;
}

function TimeFormat({ totalMinutes }) {
  return (
    <div>
      <p className="text-center"> {totalMinutes}</p>
      <p className="text-xs">{formatDuration(totalMinutes)}</p>
    </div>
  );
}

export default function TaskReport() {
  const activeClient = useSelector((state) => state.client.activeClient);

  const [defaultData, setDefaultData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [activeTask, setActiveTask] = useState(false);
  const [taskSelector, setTaskSelector] = useState(false);
  const [filter, setFilter] = useState({
    dateFrom: new Date(Date.now() - 604800000),
    dateTo: new Date(),
    customer: '',
    project: '',
    user: '',
  });
  const [isInitialize, setIsInitialize] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const generateReport = (data) => {
    return data.map((dt, i) => {
      const totalTime = dt.total_time?.duration?.split('.').slice(0, -1).join('.');
      const timeIn = dt.time_in ? new Date(dt.time_in) : new Date();
      const timeStart = dt.time_in ? new Date(dt.time_in) : new Date();
      return {
        id: i + 1,
        Customer: dt.topic?.project?.customer,
        Project: dt.topic?.project?.name,
        Task: dt.topic?.id,
        Type: dt.topic?.topic_type,
        Title: dt.topic?.name,
        User: dt.assignee?.name,
        Status: dt.section,
        Date: format(timeIn, 'yyyy-MM-dd'),
        Start: format(timeStart, 'kk:mm:ss'),
        Timezone: `${dt.timezone?.name}(${dt.timezone?.utc_offset})`,
        Time: totalTime,
        Minutes: dt.total_time?.minutes,
        BillableTime: dt?.billable_time,
      };
    });
  };

  const exportExcel = () => {
    const ws = utils.json_to_sheet(reportData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    const dtF = format(new Date(filter.dateFrom), 'ddMMM');
    const dtT = format(new Date(filter.dateTo), 'ddMMM');
    writeFileXLSX(wb, `report-${dtF}-${dtT}.xlsx`);
  };

  const getReport = useCallback((data) => {
    const { getReportTask } = projectApi();
    getReportTask(data)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setReportData(generateReport(res.data));
          setDefaultData(res.data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  const handleGenerateReport = () => {
    const dtFilter = { timezone: getTimeZone().name };
    const { dateFrom, dateTo, customer, project, user } = filter;
    if (dateFrom) {
      dtFilter.datetime_from = dateFrom;
    }
    if (dateTo) {
      dtFilter.datetime_to = dateTo;
    }
    if (customer) {
      dtFilter.customer = customer.id;
    }
    if (project) {
      dtFilter.project = project.id;
    }
    if (user) {
      dtFilter.user = user.id;
    }
    getReport(dtFilter);
  };

  const getCustomerList = useCallback(
    (params = { client: activeClient }) => {
      const { getMembers } = accountingApi();
      return new Promise((resolve, reject) => {
        getMembers(params)
          .then((res) => {
            if (res.status === 200 && res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [activeClient]
  );

  const getProjectList = useCallback(
    (params = {}) => {
      const usedParam = { client: activeClient, max_size: true };
      const { getProjectTasks } = projectApi();
      return new Promise((resolve, reject) => {
        getProjectTasks({ ...usedParam, ...params })
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              if (Object.keys(params).length === 0) {
                resolve(res.data.results);
              } else {
                setProjects(res.data.results);
              }
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [activeClient]
  );

  const getUserList = useCallback(
    (params = { client: activeClient, max_size: true }) => {
      const { getUsers } = userApi();
      return new Promise((resolve, reject) => {
        getUsers(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [activeClient]
  );

  const getAllData = useCallback(async () => {
    const [a, b, c] = await Promise.all([getCustomerList(), getProjectList(), getUserList()]);
    if (a && b && c) {
      setCustomers(a);
      setProjects(b);
      setUsers(c);
      setIsInitialize(true);
    }
  }, [getCustomerList, getProjectList, getUserList]);

  useEffect(() => {
    if (!isInitialize) {
      getAllData();
    }
  }, [isInitialize, getAllData]);

  const onChangeFilter = (param, value) => {
    if (param === 'customer') {
      if (filter.project) {
        setFilter((prevState) => {
          return {
            ...prevState,
            project: '',
          };
        });
      }
      setProjects([]);
      getProjectList({ member: value.id });
    }
    setFilter((prevState) => {
      return {
        ...prevState,
        [param]: value,
      };
    });
  };

  const columns = [
    {
      field: 'Customer',
      headerName: 'Customer',
      headerAlign: 'left',
      align: 'left',
      minWidth: 144,
      renderCell: (params) => (
        <Tooltip title={params.row.Customer}>
          <span className="truncate">{params.row.Customer || '-'}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Project',
      headerName: 'Project',
      headerAlign: 'left',
      minWidth: 188,
    },
    {
      field: 'Task',
      headerName: 'Task',
      minWidth: 60,
    },
    {
      field: 'Title',
      headerName: 'Title',
      minWidth: 308,
      renderCell: (params) => (
        <Tooltip title={params.row.Title}>
          <span className="truncate">{params.row.Title}</span>
        </Tooltip>
      ),
    },
    {
      field: 'User',
      headerName: 'User',
      minWidth: 176,
      renderCell: (params) => (
        <Tooltip title={params.row.Title}>
          <span className="truncate">{params.row.User}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Status',
      headerName: 'Status',
      minWidth: 112,
      renderCell: (params) => params.row.Status,
    },
    {
      field: 'Date',
      headerName: 'Date',
      minWidth: 112,
      renderCell: (params) => params.row.Date,
    },
    {
      field: 'Start',
      headerName: 'Start Time',
      minWidth: 112,
      renderCell: (params) => params.row.Start,
    },
    {
      field: 'Timezone',
      headerName: 'Time Zone',
      minWidth: 156,
      renderCell: (params) => params.row.Timezone,
    },
    {
      field: 'Time',
      headerName: 'Time',
      headerAlign: 'left',
      sortable: false,
      minWidth: 60,
      renderCell: (params) => params.row.Time,
    },
    {
      field: 'Minutes',
      headerName: 'Minutes',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      minWidth: 48,
      renderCell: (params) => params.row.Minutes,
    },
    {
      field: 'BillableTime',
      headerName: 'Billable Time',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      minWidth: 48,
      renderCell: (params) => {
        return <TimeFormat totalMinutes={params.row.BillableTime || 0} />;
      },
    },
    {
      field: '',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      minWidth: 32,
      renderCell: (params) => (
        <ButtonBase
          className="p-2 rounded-md border border-solid border-gray-300"
          onClick={(e) => {
            e.stopPropagation();
            setTaskSelector(params.row);
          }}
        >
          <Edit fontSize="14" />
        </ButtonBase>
      ),
    },
  ];

  return (
    <>
      <BZHelmet title="Task Management Report" description="bz publish task management report" />
      <div className="w-full h-full">
        <div className="w-full flex p-4 shadow-md">
          <h1 className="text-lg font-bold">Task Management Report</h1>
        </div>
        <div className="w-full p-8 relative flex flex-col space-y-5">
          <div className="w-full flex space-x-4">
            <div className="w-1/4">
              <Autocomplete
                multiple={false}
                name="customer"
                options={customers}
                autoHighlight
                getOptionLabel={(option) => option && `${option.name}`}
                isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
                onChange={(event, dataOption) => {
                  onChangeFilter('customer', dataOption);
                }}
                value={filter.customer}
                noOptionsText="No customer"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </div>
            <div className="w-1/4">
              <Autocomplete
                multiple={false}
                name="project"
                options={projects}
                autoHighlight
                getOptionLabel={(option) => option && `${option.name}`}
                isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
                onChange={(event, dataOption) => {
                  onChangeFilter('project', dataOption);
                }}
                value={filter.project}
                noOptionsText="No project"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </div>
            <div className="w-1/4">
              <Autocomplete
                multiple={false}
                name="user"
                options={users}
                autoHighlight
                getOptionLabel={(option) => option && `${option.name}`}
                isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
                onChange={(event, dataOption) => {
                  onChangeFilter('user', dataOption);
                }}
                value={filter.user}
                noOptionsText="No user"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex space-x-4">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date From"
                closeOnSelect
                value={filter.dateFrom}
                onChange={(newValue) => {
                  onChangeFilter('dateFrom', newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date to"
                closeOnSelect
                value={filter.dateTo}
                onChange={(newValue) => {
                  onChangeFilter('dateTo', newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
            <Button variant="contained" size="small" onClick={handleGenerateReport}>
              Generate report
            </Button>
            {reportData && (
              <Button variant="contained" size="small" className="ml-auto" onClick={exportExcel}>
                Export Excel
              </Button>
            )}
          </div>
        </div>
        {reportData && (
          <>
            <div className="w-full flex-flex-col p-4 space-y-4">
              <Box className="w-full h-auto px-4 pt-4 mb-4">
                <DataGrid
                  autoHeight
                  rows={reportData}
                  columns={columns}
                  pageSize={rowsPerPage}
                  onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                  onCellClick={(params) => {
                    setActiveTask(params.row.Task);
                  }}
                  rowsPerPageOptions={[20, 50, 100]}
                  className="m-auto max-w-[1710px] w-[100%]"
                  sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' } }}
                />
              </Box>
            </div>
            {activeTask && <ModalTaskReport activeTask={activeTask} onClose={() => setActiveTask(false)} />}
          </>
        )}
      </div>
      {taskSelector ? (
        <ModalTaskStopTime
          activeTask={taskSelector}
          allData={defaultData}
          callback={() => {
            setReportData(null);
            setDefaultData(null);
            handleGenerateReport();
          }}
          handleClose={() => setTaskSelector(false)}
        />
      ) : null}
    </>
  );
}

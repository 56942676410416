/* eslint-disable */
import React from 'react';
// import ContactSelectButton from './ContactSelectButton';

import css from './ContactSelect.module.css';

// export { ContactSelectButton };

function ContactSelect({idx, name, title, phone, email, contactId, contacts, selectedContacts, handleSelectedContact, authorize, isDetailPage}) {
  console.log({isDetailPage});
  let initial = '';
  if (name) {
    initial = name
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  }
  const selectedContact = selectedContacts?.filter((selectedContact) => selectedContact);
  const contactList = contacts?.filter((contact) => !selectedContact.includes(contact.id) || contact.id === contactId);
  return (
    <div className="border rounded-lg bg-white">
      <div className={['flex items-center p-3 gap-x-4 justify-between'].join(' ')}>
        <div
          className="mr-3 flex items-center justify-between"
          style={{ backgroundColor: '#E2E2E2', minWidth: '32px', height: '32px', width: '32px', borderRadius: '50%', border: '2px solid #2C6D47' }}
        >
          <p className="mb-0 mx-auto">{initial}</p>
        </div>
        <div className="w-full flex flex-col gap-y-2 4xl:gap-y-0 4xl:flex-row items-center">
          <div className={['w-full', css.text].join(' ')}>
            <p className={css.name}>{name}</p>
            <p className={css.title}>{title}</p>
          </div>
          <div className={['w-full', css.text].join(' ')}>
            <p className={css.phone}>{phone}</p>
            <p className={css.email}>{email}</p>
          </div>
        </div>
      </div>
      {
        authorize === false && (
          <div className="w-full">
            <select
              value={isDetailPage === true ? "new-contact" : contactId}
              className={['w-full form-control mt-2', css.select].join(' ')}
              onChange={(e) => {
                handleSelectedContact(e.target.value, idx);
              }}
            >
              <option value={null}>--- Please select contact ---</option>
              {
                isDetailPage === true && (
                  <option value={"new-contact"}>--- New Contact ---</option>
                )
              }
              {contactList.map((contact) => (
                <option value={contact.id}>
                  {contact.name}, {contact.position} - {contact.phone}
                </option>
              ))}
            </select>
          </div>
        )
      }
    </div>
  );
}

export default ContactSelect;

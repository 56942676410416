/* eslint-disable */

import request from '../helper/axiosUtil';

export const fetchUsers = async ({ queryKey }) => {
  const [, { clientId, isUserAdmin }] = queryKey;
  const setIsUserAdmin = isUserAdmin === true || isUserAdmin === false ? `&site__is_admin=${isUserAdmin}` : '';
  return request({ url: `/users/?client=${clientId}&max_size=true${setIsUserAdmin}` });
};

export const fetchUserDetail = async ({ queryKey }) => {
  const [, { userId }] = queryKey;
  return request({ url: `/users/${userId}/` });
};

export const fetchGroups = async ({ queryKey }) => {
  const [, { subId }] = queryKey;
  return request({ url: `/groups/?${subId}max_size=true` });
};

export const fetchSubscriptions = async () => {
  return request({ url: `/subscriptions/` });
};

export const createUsers = (data) =>
  request({
    url: '/users/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const updateUsers = async (id, data) =>
  request({
    url: `/project-management/teams/${id}/`,
    method: 'patch',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const deleteUsers = async (id) =>
  request({
    url: `/users/${id}/`,
    method: 'delete',
  });

/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { Add, ModeEdit, Delete, Edit, Save, Cancel, KeyboardArrowRight } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
// import { Link } from 'react-router-dom';
import Select from '../../components/Input/SelectItem';
import BaseMainTitle from '../../components/BaseMainTitle';
import ReactShortableWrapper from '../../components/ReactShortableWrapper';
import BZHelmet from '../../utils/BZHelmet';
import ModalWrapper from '../../components/ui/ModalWrapper';
import ChangeNameStage from '../../components/Form/Recruitment/ChangeStageName';
import { handleModal } from '../../store/slices/modalSlice';
import StagesApi from '../../api/recruitmenStage/StagesApi';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import { toastLoading } from '../../constants/ToastConfig';
import { useNavigate } from 'react-router';
import { handleStage } from '../../store/slices/hr/stageSlice';
import { generateSelectListDefault, getDefaultSelectObject, getOptionSelectWithKey } from '../../lib/OptionsInputHelper';
import ReactSelectMultiple from '../../components/Input/ReactSelectMultiple';
import ReactSelectSingle from '../../components/Input/ReactSelectSingle';
import useWindowSize from '../../hooks/useWindowSize';
import { CircularProgress } from '@mui/material';

function Stages() {
  const { control, watch, setValue } = useForm({});
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const clientId = useSelector((state) => state.client.activeClient);

  const [applications, setApplications] = useState([]);
  const [list, setListData] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [reload, setReload] = useState(true);
  const [onReorder, setOnReorder] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [removeArrow, setRemoveArrow] = useState(8);

  // const [offerStage, setOfferStage] = useState(null);
  // const [acceptedStage, setAcceptedStage] = useState(null);



  useEffect(() => {
    if (windowSize > 1680) {
      setRemoveArrow(8);
    } else {
      setRemoveArrow(4);
    }
  }, [windowSize]);

  useEffect(() => {
    const ChangeStageIndex = () => {
      const { UpdateStageOrder } = StagesApi();
      const indexes = tempList[0];
      const jobId = tempList[1];
      let onToast;

      try {
        UpdateStageOrder(jobId, indexes)
          .then((res) => {
            onToast = toast.loading('Loading  ', toastLoading);
            if (res.status === 200 || res.data) {
              toast.update(onToast, {
                render: 'Success',
                type: 'success',
                isLoading: false,
                autoClose: 100,
                closeButton: true,
              });
              return setReload(true);
            }
          })
          .catch((err) => {
            console.error('error', err);
          });
      } catch (error) {
        console.error('error', error);
      }
    };

    if (onReorder === true) {
      ChangeStageIndex();
      setOnReorder(false);
    }
  }, [onReorder]);

  const getStagesCrud = useCallback((jobId) => {
    const { getStagesCrud } = StagesApi();
    return new Promise((resolve, reject) => {
      getStagesCrud(jobId)
        .then((res) => {
          if (res.status === 200 && res.data) {
            resolve(res.data);
            // setListData((value) => [...value, res.data.results]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);

  const getApplicationList = useCallback((params = { article__client: clientId }) => {
    const { getApplicationList } = StagesApi();
    return new Promise((resolve, reject) => {
      getApplicationList(params)
        .then((res) => {
          if (res.status === 200 && res.data) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);

  const getApplicationsData = useCallback(async () => {
    const [applicationList] = await Promise.all([getApplicationList()]);
    if (applicationList) {
      const appListData = applicationList.results?.map((val) => ({ ...val, disabled: true }));
      setApplications(appListData);
    }
  }, [getApplicationList]);

  const getStagesData = useCallback(
    async (applications) => {
      // const [stagesCrud] = await Promise.all([getStagesCrud(jobId)]);
      const res = await Promise.all(applications.map(async (data) => getStagesCrud({ job_poster: data?.id })));
      if (res.length) {
        const newData = res.map((r) => r.results);
        setListData(newData);
      }
    },
    [getStagesCrud]
  );

  useEffect(() => {
    if (reload) {
      getApplicationsData();
    }
  }, [reload, getApplicationsData]);

  useEffect(() => {
    if (applications) {
      getStagesData(applications);
    }
    setReload(false);
  }, [getStagesData, applications]);

  const navigate = useNavigate();

  const handleNavigateStage = ({ jobPoster, stage }) => {
    dispatch(handleStage({ jobPoster, stage }));
    navigate('/hr/recruitment/applications');
  };

  return (
    <>
      <BZHelmet title="Recruitment Stages" description="" content="" />
      <BaseMainTitle title="Recruitment Stages" />
      {list.length === applications.length ? (
        applications?.map((stagesList, id) => {
          const options = list[id];
          const Interview = options.filter((v) => v.type === 'Interview') ?? [];
          const Offer = options.filter((v) => v.type === 'Offer') ?? [];
          const Accepted = options.filter((v) => v.type === 'Accepted') ?? [];



          return (
            <div key={stagesList.id} className="w-full px-5 py-7 flex gap-x-4 border">
              <div className="w-[220px] flex items-center border-r">
                <div className="flex flex-col cursor-pointer" onClick={() => navigate(`/hr/recruitment/job-poster/detail/${stagesList?.id}`)}>
                  <h2 className="font-bold">{stagesList?.name}</h2>
                  <p className="text-slate-400">{stagesList?.location ? stagesList?.location?.name : '-'}</p>
                </div>
              </div>
              <div key={id} className="w-full space-y-4 col-span-4">
                <div className="h-full w-full grid grid-cols-4 4xl:grid-cols-8 gap-y-4 bg-white">
                  {list[id]?.map((sec, listId, row) => {
                    const totalApplicants = stagesList.application_stages[listId]?.application_count;
                    return (
                      <div key={sec.id} className="w-full flex gap-x-1">
                        <div className="border rounded relative w-full h-[90px] cursor-pointer" onClick={() => handleNavigateStage({ jobPoster: sec?.job_poster, stage: sec?.id })}>
                          <div
                            className={`h-[3.7rem] ${
                              sec.type !== 'Default' ? 'bg-bz-greenLight' : 'bg-bz-green'
                            } relative p-2 rounded-t text-white font-bold flex justify-center items-center`}
                          >
                            <div className="h-fit gap-x-3 flex justify-center items-center">
                              <p>{listId + 1}.</p>
                              <p>{`${sec.name}`}</p>
                            </div>
                          </div>
                          <div className="p-1 text-white font-bold flex justify-center bg-yellow-400 rounded-b">
                            <p>{totalApplicants}</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          {listId + 1 !== removeArrow && listId + 1 !== row.length ? <KeyboardArrowRight className="scale-105" /> : <div className="w-[22px]" />}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <CircularProgress />
      )}
      <ModalWrapper componentName="ChangeStageName" header="Change Stage Name" maxWidth="sm">
        <ChangeNameStage reload={setReload} />
      </ModalWrapper>
    </>
  );
}

export default Stages;

// /* eslint-disable */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MenuForm from '../../../components/Form/Menu/MenuForm';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import { handleModal } from '../../../store/slices/modalSlice';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import useGet from '../../../hooks/useGet';
import usePost from '../../../hooks/usePost';
import useDelete from '../../../hooks/useDelete';
import BZButton from '../../../components/Button/BZButton';

export default function Menu() {
  const TEMPLATE_ID = useSelector((state) => state.content.activeTemplate);

  const [menuData, setMenuData] = useState([]);
  const [bulkMenuDel, setBulkMenuDel] = useState([]);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(bulkMenuDel);
      newSelection.push(Number(value));
      setBulkMenuDel(newSelection);
    } else {
      const newSelection = bulkMenuDel;
      const newSubs = newSelection.filter((sub) => Number(sub) !== Number(value));
      setBulkMenuDel(newSubs);
    }
  };

  useGet({
    url: `menus/?max_size=true&template=${TEMPLATE_ID}`,
    validate: 'menus',
    onSuccess: (e) => {
      setMenuData(e?.data?.results);
    },
  });

  const { mutate: postMenu } = usePost({ url: 'menus', validate: ['menus'] });

  const onPostMenu = async (data) => {
    await postMenu(data);
  };

  const onDestroyMenu = useDelete({
    url: 'menus/delete_bulk',
    confText: `modals.menu_delete.confirmation_text`,
    confTitle: `modals.menu_delete.title`,
    success: 'Menu deleted',
    validate: ['menus'],
    bodyData: { menu_ids: bulkMenuDel },
    isSubUrl: false,
  });

  return (
    <>
      <BZHelmet title="Menu List" description="" content="" />
      <div>
        <BaseMainTitle title="Menu List" />
        <div className="p-5">
          <div className=" bg-white border p-5 shadow rounded">
            <div className="bg-gray-100 w-full py-5 px-3 mb-5 rounded">
              <div className="ml-auto justify-end flex gap-x-3">
                <BZButton variant={bulkMenuDel.length === 0 ? 'disabled' : 'danger'} onClick={() => onDestroyMenu()}>
                  Delete selected
                </BZButton>
                <BZButton variant="secondary" onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addMenu' }))}>
                  Add menu
                </BZButton>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-3">
              {menuData?.map((menu) => (
                <div key={menu.id} className="bg-white border shadow rounded relative">
                  <input type="checkbox" name="lang" value={menu.id} onChange={handleChange} className="absolute top-3 left-3 w-5 h-5" />
                  <Link to={`/menu-list/${menu.id}`}>
                    <div className="bg-bz-green w-full text-white p-2 ">
                      <p className="ml-8">{menu?.name}</p>
                    </div>
                    <div className="w-full h-12" />
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <ModalWrapper componentName="addMenu" header="Add Menu" maxWidth="sm">
            <MenuForm onFormSubmit={onPostMenu} />
          </ModalWrapper>
        </div>
      </div>
    </>
  );
}

import { useDispatch } from 'react-redux';
import CustomerDetail from '../../../pages/CRM/Customers/CustomerDetail';
import { handleModal } from '../../../store/slices/modalSlice';

function AddPartner({ callback }) {
  const dispatch = useDispatch();
  return (
    <div>
      <CustomerDetail isModal={true} formLabelAction='Add New Partner' callback={() => {
        callback();
        dispatch(handleModal({ modalId: '', componentName: '' }))
      }} />
    </div>
  );
}

export default AddPartner;

/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import * as yup from 'yup';
import _ from 'lodash';
import TruncateMarkup from 'react-truncate-markup';
import { useDebounce } from 'use-debounce';

// MUI Components & Icons
import { 
  Accordion, 
  AccordionDetails, 
  AccordionSummary, 
  Button, 
  Tabs, 
  Tab, 
  Box, 
  Paper,
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  Tooltip,
  InputBase,
  CircularProgress
} from '@mui/material';
import { 
  ArrowBack, 
  ExpandMore, 
  Cancel as CancelIcon, 
  Download as DownloadIcon, 
  Edit as EditIcon, 
  ViewList,
  GridView,
  Add as AddIcon,
  Search
} from '@mui/icons-material';

// Google Maps
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

// Redux Actions
import { handleModal } from '../../store/slices/modalSlice';

// API Imports
import ProcurementApi from '../../api/Procurement/Procurement';
import AccountingApi from '../../api/accounting/accounting';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';

// Custom Components
import Input from './redesigning/InputContainerRedesign';
import Select from '../../components/Input/MuiSelectSingleItem';
// import Select from '../../components/Input/SelectItem';
import DatePickerField from './redesigning/DatePickerFieldRedesign';
import ModalWrapper from '../../components/ui/ModalWrapper';
import CollectionForm from '../../components/Form/Article/CollectionForm';
import AddNote from '../../components/Form/Procurement/AddNote';
import ViewHistory from '../../components/Modal/Procurement/ViewHistory';
import EditHistory from '../../components/Form/Procurement/EditHistory';
import CustomSelect from './redesigning/CustomSelectRedesign';
import ContactSelectButton from '../../components/Input/ContactSelect/ContactSelectButton';
import ContactSelect from '../../components/Input/ContactSelect/ContactSelect';
import LocationPair from '../../components/Form/Procurement/LocationPair';
import AddNewLocation from '../../components/Form/Procurement/AddNewLocation';
import BaseMainTitle from '../../components/BaseMainTitle';
import BZHelmet from '../../utils/BZHelmet';
import BzThumbnail from '@/assets/simple.png'
import MuiSelectSingleItem from '../../components/Input/MuiSelectSingleItem';

const schema = yup.object().shape({
  countries: yup.array().required('must input at least 1 country'),
  name: yup.string().required(),
});

const containerStyle = {
  width: '100%',
  height: '220px',
};

const lib = ['drawing', 'places'];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function VendorDetail() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCKqMs4hmNgoQtmIUsd7diBKHj6UV610Jg',
    libraries: lib,
  });

  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname.split('/');
  const paramID = useParams();
  const dispatch = useDispatch();

  const activeUser = useSelector((state) => state.auth.user);
  const isSuperUser = activeUser.is_superuser;
  const userGroup = activeUser.groups?.map((a) => +a.id) || [];
  // const isOnlyUser = !(isSuperUser && userGroup.includes(8));
  const isOnlyUser = isSuperUser === true ? false : userGroup.includes(17) ? true : false;

  const [mode, setMode] = useState('');
  const [companiesData, setCompaniesData] = useState(null);
  
  const [companiesLocation, setCompaniesLocation] = useState([]);
  const [attributeCategories, setAttributeCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);
  const [companyDocuments, setCompanyDocuments] = useState([]);
  const [assignedContacts, setAssignedContacts] = useState([]);
  const [companiesContacts, setCompaniesContacts] = useState([]);
  const [histories, setHistories] = useState([]);
  const [historyData, setHistoryData] = useState();
  const [projectList, setProjectList] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [checkedDocuments, setCheckedDocuments] = useState([]);
  const [attributesOptions, setAttributesOptions] = useState([]);
  const [disabledButtonDelete, setDisabledButtonDelete] = useState(true);
  const [map, setMap] = useState(null);
  const [NoteModalStatus, setNoteModalStatus] = useState('');
  const [noteId, setNoteId] = useState(0);
  const [selectedMainLocation, SetSelectedMainLocation] = useState('');
  const [selectedLocations, SetSelectedLocations] = useState([]);

  const [companyLogo, setCompanyLogo] = useState();
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [businessUnitList, setBusinessUnitList] = useState(null)
  const [selectedbusinessUnit, setSelectedBusinessUnit] = useState([])

  const [notes, setNotes] = useState([]);
  const [reloadNotes, setReloadNotes] = useState(true);
  const [reloadHistories, setReloadHistories] = useState(true);
  // const [reloadLocationsAdd, setReloadLocationsAdd] = useState(false)
  const [reloadLocations, setReloadLocations] = useState(false);

  const [vendorProductList, SetVendorProductList] = useState([]);
  const [isFetchingvendorProductList, SetIsFetchingProductList] = useState(true);  
  const [searchProduct, setSearchProduct] = useState("");
  const [searchValueProduct] = useDebounce(searchProduct, 300);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  
  useEffect(() => {
    if (!Object.entries(checkedDocuments).length) {
      setDisabledButtonDelete(true);
    } else {
      setDisabledButtonDelete(false);
    }
  }, [checkedDocuments]);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const getAllCountries = useCallback(async () => {
    const { getCountries } = ProcurementApi();

    try {
      const getData = await getCountries({ max_size: true });
      const data = await getData.data.results;
      setCountries(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getBusinessUnitList = useCallback(async () => {
    const { getListBusinessUnit } = AccountingApi();

    try {
      const getData = await getListBusinessUnit({ client: clientId, max_size: true });
      const data = await getData.data;
      setBusinessUnitList(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const memberDetail = useCallback(async () => {
    const { getMemberDetail } = ProcurementApi();
    let attributeValues = [];
    let companydocs = [];
    let id = paramID?.vendorId;

    try {
      const getData = await getMemberDetail(id);
      const data = await getData.data;
      const attributeCat = data?.attribute_categories;
      const companyId = data?.id

      attributeCat?.forEach((category, idxCategory) => {
        let attrs = [];
        category.attributes.forEach((attribute, idxAttribute) => {
          if (attribute.value_type === 'File') {
            companydocs.push(attribute?.member_attribute?.attribute_values);
          }
          attrs.push(attribute.member_attribute?.attribute_values);
        });
        attributeValues.push(attrs);
      });

      setCompaniesData(data);
      setCompanyLogo({ logoPreview: data.logo });
      setCompaniesLocation(data?.vendor_locations);
      // setAttributeCategories(attributeCat);
      setAttributeValues(data.documents);
      setCompanyDocuments(data.documents);

      if (companyId) {
        getContacts(companyId)
        getAssignedContacts(companyId)
      }
    } catch (error) {
      console.error(error);
    }
  }, [paramID]);

  const getAllAttributeOptions = useCallback(async () => {
    const { getAttributeOptions, getAttributeCategories } = ProcurementApi();
    let attributesCat = [];
    let AllAttributeOptions = [];

    try {
      const getAttributeCat = await getAttributeCategories({ max_size: true, client: clientId });
      const attributeCatdata = await getAttributeCat.data.results;
      attributesCat.push(...attributeCatdata);

      attributeCatdata?.forEach((category) => {
        category?.attributes?.forEach(async (attr, idx) => {
          if (attr.value_type === 'Option') {
            let customParams = {};
            if (attr.name === 'Membership type') {
              customParams.ordering = undefined;
            }
            const getData = await getAttributeOptions(attr.id, customParams);
            const data = await getData.data.results;
            setAttributesOptions((attributesOptions) => ({ ...attributesOptions, [attr.key_name]: data }));
          }
        });
      });
    } catch (error) {
      console.error(error);
    }

    setAttributeCategories(attributesCat);
  }, []);

  const getAssignedContacts = useCallback(async (id) => {
    const { getAllAssignedContacts } = ProcurementApi();

    try {
      const getData = await getAllAssignedContacts({ vendor: id, client: clientId });
      const data = await getData.data.results;
      setAssignedContacts(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getContacts = useCallback(async (id) => {
    const { getAllContacts } = ProcurementApi();

    try {
      const getData = await getAllContacts({ vendor: id, max_size: true, ordering: 'name' });
      const data = await getData.data.results;
      setCompaniesContacts(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getAllHistories = useCallback(async () => {
    const { getAllHistoriesByMemberId, getAllvendor_attributes } = ProcurementApi();
    const nameAttributes = ['Date joined', 'Membership type', 'Membership status'];
    const historiesData = [];
    const attributes = [];
    const objectAttributes = {};

    try {
      const resAttributes = await getAllvendor_attributes({ max_size: true });
      resAttributes.data?.results.forEach((attribute) => {
        objectAttributes[attribute.id] = attribute;
        const nameAttributesIdx = nameAttributes.indexOf(attribute.name);
        if (nameAttributesIdx > -1) {
          attributes[nameAttributesIdx] = attribute;
        }
      });
      await attributes.reduce(async (promise, attribute) => {
        await promise;
        const res = await getAllHistoriesByMemberId(paramID?.vendorId, { max_size: true, ordering: '-id', attribute: attribute.id });
        historiesData.push(res.data.results);
      }, Promise.resolve());
    } catch (error) {
      console.error(error);
    }

    setHistories(historiesData);
    setAttributes(objectAttributes);
  }, []);

  const getAllNotes = useCallback(async () => {
    const { getAllNotesByMemberId } = ProcurementApi();

    try {
      const getData = await getAllNotesByMemberId(paramID?.vendorId);
      const data = await getData.data.results;

      setNotes(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getLocations = useCallback(async () => {
    const { getAllLocations } = ProcurementApi();

    try {
      const params = paramID && paramID?.vendorId ? { vendor: paramID?.vendorId, client: clientId } : { client: clientId };

      const getData = await getAllLocations(params);
      const data = await getData.data.results;
      const locations = await data
        .filter((location) => selectedLocations.includes(parseInt(location.id)))
        .map((location) => {
          return {
            ...location,
            is_main: location.id === selectedMainLocation,
          };
        });
      setCompaniesLocation(mode === 'add' ? locations : data);
    } catch (error) {
      console.error(error);
    }
  }, [selectedLocations, mode]);

  useEffect(() => {
    if (url.includes('detail')) {
      setMode('edit');
    } else {
      setMode('add');
      setCompaniesData([]);
    }
  }, [paramID?.vendorId]);

  useEffect(() => {
    if (url.includes('detail')) {
      setReloadLocations(true);
    }
  }, []);

  useEffect(() => {
    if (mode === "add") {
      getAssignedContacts()
    } else {
      SetSelectedLocations([])
      SetSelectedMainLocation("") 
    }
  }, [mode]);

  useEffect(() => {
    if (paramID?.vendorId) {
      memberDetail();
    }
  }, [paramID?.vendorId]);

  useEffect(() => {
    getAllAttributeOptions();
    getAllCountries();
    getBusinessUnitList();
  }, [paramID?.vendorId]);

  useEffect(() => {
    if (reloadLocations) {
      getLocations();
    }
    setReloadLocations(false);
  }, [reloadLocations]);

  useEffect(() => {
    if (reloadNotes) {
      getAllNotes();
    }
    setReloadNotes(false);
  }, [reloadNotes]);

  useEffect(() => {
    if (reloadHistories) {
      getAllHistories();
    }
    setReloadHistories(false);
  }, [reloadHistories]);

  const getAllProductList = useCallback(async () => {
    const { getProductList } = ProductManagementApi();

    try {
      const getData = await getProductList({
        vendors: paramID?.vendorId, 
        search: searchValueProduct, 
        max_size: true,
        category__name: selectedCategory,
        ordering: "name"
      });
      const data = await getData.data.results;
      SetVendorProductList(data || []);
      SetIsFetchingProductList(false);
    } catch (error) {
      console.error(error);
    }
  }, [paramID?.vendorId, searchValueProduct, selectedCategory]);

  useEffect(() => {
    SetIsFetchingProductList(true);
    getAllProductList()
  }, [paramID?.vendorId, searchValueProduct, selectedCategory]);

  const getAllCategoriesByClient = useCallback(async () => {
    const { getCategories } = ProductManagementApi();

    try {
      const getData = await getCategories({client: clientId, max_size: true});
      const data = await getData.data.results;
      setCategoryOptions(data || []);
    } catch (error) {
      console.error(error);
    }
  }, [clientId]);

  useEffect(() => {
    getAllCategoriesByClient()
  }, [clientId, getAllCategoriesByClient]);

  const { register, handleSubmit, control, errors, setValue } = useForm();

  const handleCheckedDocuments = (event, id, idxAttr) => {
    const isChecked = event.target.checked;

    let newCheckedDocuments = _.cloneDeep(checkedDocuments);

    // let setChecked = new Set(newCheckedDocuments);

    if (isChecked) {
      newCheckedDocuments.push(id);
    } else {
      let index = newCheckedDocuments.indexOf(id);
      if (index !== -1) {
        newCheckedDocuments.splice(index, 1);
      }
    }

    // newCheckedDocuments = [...setChecked];

    setCheckedDocuments(newCheckedDocuments);
  };

  const deleteDocuments = () => {
    let newAttributeValues = _.cloneDeep(attributeValues);
    const checkedFile = checkedDocuments;

    const remainFiles = companyDocuments.filter((item) => !checkedFile.includes(item.id));

    // newAttributeValues[idxCategory][idxAttr] = remainFiles;

    // setAttributeValues(newAttributeValues);
    setCheckedDocuments([])
    setCompanyDocuments(remainFiles);
  };

  const handleFileUpload = (event) => {
    let newAttributeValues = _.cloneDeep(companyDocuments);

    for (let i = 0; i < event.target.files.length; i++) {
      newAttributeValues.push({ id: '', file: event.target.files[i] });
    }
    setCompanyDocuments(newAttributeValues);
    // setAttributeValues(newAttributeValues);
  };

  const handleSelectedContact = (contactId, idx) => {
    let newAssignedContacts = _.cloneDeep(assignedContacts);
    let selectedContact = companiesContacts.filter((contact) => contact.id === parseInt(contactId));

    if (selectedContact.length) {
      selectedContact = selectedContact[0];
      newAssignedContacts[idx].contact = selectedContact;
    } else {
      newAssignedContacts[idx].contact = null;
    }

    setAssignedContacts(newAssignedContacts);
  };

  const handleAssignedContact = (data, id) => {
    let newAssignedContacts = _.cloneDeep(assignedContacts);

    data.contact_title = assignedContacts[id]?.id;
    newAssignedContacts[id].contact = data;

    setAssignedContacts(newAssignedContacts);
  };

  const handleRemoveLogo = () => {
    setCompanyLogo({ logo: '' });
  };

  const handleChangeLogo = async (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageSrc = URL.createObjectURL(file);

      setCompanyLogo({ logo: file, logoPreview: imageSrc });
    }
  };

  const validationSubmit = (data) => {
    let isError = false;

    if (!data.name || !data.countries?.length) {
      isError = true;
    }

    try {
      attributeCategories?.forEach((attributeCategory) => {
        attributeCategory?.attributes && attributeCategory?.attributes?.forEach((attr) => {
          if (attr.is_required) {
            const { key_name } = attr;
            const attrData = data[key_name];
            if (attr.value_type === 'String' && attrData !== '') {
              isError = true;
            } else if (attr.value_type === 'Option') {
              if ((Array.isArray(attrData) && !attrData.length) || !attrData) {
                isError = true;
              }
            }
          }
        });
      });
    } catch (error) {
      console.error("error ", error);
      isError = true;
    }

    if (isError) {
      swal('Error!', 'The required fields (*) cannot be blank.', 'error');
    } else {
      SubmitData(data);
    }
  };

  const SubmitLocation = (memberId) => {
    const { bulkUpdateLocation } = ProcurementApi();

    const data = companiesLocation?.map((selectedLocation) => {
      return {
        vendor: memberId,
        id: selectedLocation?.id,
        is_main: selectedMainLocation === selectedLocation?.id,
      };
    });

    bulkUpdateLocation([...data])
      .then(() => {
        swal('Success !', 'You successfully add new member !', 'success').then(() => {
          navigate('/vendor/detail/' + memberId);
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const SubmitData = async (data) => {
    const { addMemberDetail, updateMemberDetail, bulkUpdateAssignedContacts, addContact } = ProcurementApi();
    const bodyFormData = new FormData();
    data.client = parseInt(clientId);

    const isCustomerIdEmpty = !paramID?.vendorId;
    const allAttributes = attributeCategories.flatMap((category) => category.attributes);
    const allAttributeKeys = allAttributes.length > 0 ? allAttributes.map((attr) => attr?.key_name) : [];

    const filteredData = Object.entries(data)
      .filter(([key]) => allAttributeKeys.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    const valuesNotInAttributes = Object.entries(data)
      .filter(([key]) => !allAttributeKeys.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    const mappingresult = Object.keys(filteredData).reduce((result, key) => {
      result[key] = filteredData[key];
      return result;
    }, {});

    const countriesValue = valuesNotInAttributes.countries;

    const fixedFieldsData = {
      attributes: JSON.stringify(mappingresult),
      ...valuesNotInAttributes,
      countries: countriesValue[0]?.value ? countriesValue.map((v) => v.value) : countriesValue,
    };
    if (companiesData?.member_id) {
      fixedFieldsData.member_id = companiesData.member_id;
    }

    if (companyLogo !== undefined) {
      if ('logo' in companyLogo) {
        fixedFieldsData.logo = companyLogo?.logo;
      } else {
        fixedFieldsData.logoPreview = companyLogo?.logoPreview;
      }
    }

    Object.entries(fixedFieldsData).forEach(([key, value]) => {
      Array.isArray(value) ? value.forEach((val) => bodyFormData.append(key, val)) : bodyFormData.append(key, value);
    });

    if (companyDocuments?.length) {
      Array.from(companyDocuments).forEach((file, idx) => {
        const fileId = file.id || '';
        const fileValue = file.file;

        if (file.id) {
          bodyFormData.append(`documents[${idx}]id`, fileId);
        } else {
          if (fileValue) {
            bodyFormData.append(`documents[${idx}]file`, fileValue);
          }
        }
      });
    }

    if (isCustomerIdEmpty) {
      bodyFormData.append(`client`, clientId);
      // assignedContacts.forEach((assignedContact, idxAssignedContact) => {
      //   const { contact } = assignedContact;
      //   if (contact) {
      //     Object.entries(contact).forEach(([key, value]) => {
      //       if (key !== 'member') {
      //         bodyFormData.append(`contacts[${idxAssignedContact}]${key}`, value);
      //       }
      //     });
      //   }
      // });

      try {
        const result = await addMemberDetail(bodyFormData);
        const memberId = result.data?.id;
        
        const dataWithContact = assignedContacts.filter(item => item.contact);

        await Promise.all(dataWithContact.map(async (assignedContact) => {
          const contactData = { ...assignedContact.contact, member: memberId };
          try {
            await addContact(contactData);
            console.log('Contact added successfully');
          } catch (addContactError) {
            console.error('Error adding contact:', addContactError);
          }
        }));


        SubmitLocation(memberId);
      } catch (err) {
        swal('Error!', 'Failed!', 'error');
        console.error(err);
      }
    } else {
      try {
        await updateMemberDetail(paramID?.vendorId, bodyFormData);
        swal('Success!', 'You successfully updated member!', 'success').then(() => {
          navigate('/procurement/vendor');
        });
      } catch (err) {
        swal('Error!', 'Error when updating member...', 'error');
        console.error(err);
      }

      const assignedContactToSubmit = assignedContacts
        .map((assignedContact) => {
          const id = assignedContact.contact ? assignedContact.contact.id : null;
          const contact_title = assignedContact.id;
          return { id, contact_title };
        })
        .filter((e) => e.id);

      const arrAssignedContactIds = assignedContactToSubmit.map((e) => e.id);
      const nullContacts = companiesContacts
        .filter((contact) => !arrAssignedContactIds.includes(contact.id) && contact.contact_title)
        .map((contact) => ({ id: contact.id, contact_title: null }));

      const updatedAssignedContacts = [...assignedContactToSubmit, ...nullContacts];

      try {
        await bulkUpdateAssignedContacts(updatedAssignedContacts);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getFilename = (url, options) => {
    url = _.isString(url) ? url : url.name;

    const filename = url.split('/');
    if (options?.onlyExtension) {
      const ext = filename[filename.length - 1].split('.');
      return '.' + ext[ext.length - 1];
    }
    return filename[filename.length - 1];
  };

  const openModalCreateLocation = () => {
    dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
    return dispatch(handleModal({ modalId: clientId, componentName: 'addNewLocation', modalData: paramID?.vendorId }));
  };

  const backtoLocationPair = () => {
    dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
    return dispatch(handleModal({ modalId: clientId, componentName: 'locationPair', modalData: paramID?.vendorId }));
  };

  const collectionSelected = companiesData?.countries;
  const resultsCountries = countries?.filter(({ value: name }) => collectionSelected?.some(({ id: id2 }) => id2 === name));

  const getBlankImageSrc = () => {
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=';
  };

  const ChangeCompaniesData = (value, stateName) => {
    let newFixedFieldsData = _.cloneDeep(companiesData);

    if (stateName === 'countries') {
      value = value.map((val) => ({ id: val.value, name: val.label }));
    }

    newFixedFieldsData[stateName] = value;
  };

  const ChangeCountries = (value) => {
    let newFixedFieldsData = _.cloneDeep(companiesData);

    value = value.map((val) => ({ id: val.value, name: val.label }));

    newFixedFieldsData['countries'] = value;
    return setCompaniesData(newFixedFieldsData);
  };

  const requiredStar = <span className="text-red-500">*</span>;

  const InputLabel = ({ children, customClass }) => <p className={`font-semibold ${customClass}`}>{children}</p>;

  const PhoneInput = () => (
    <div className="w-full">
      <InputLabel>Phone</InputLabel>
      <Input
        control={control}
        defaultValue={companiesData?.phone === 'null' ? '' : companiesData?.phone}
        label="Phone"
        name="phone"
        onChangeValue={ChangeCompaniesData}
        setState={setCompaniesData}
      />
    </div>
  )

  const CompanyNameInput = () => (
    <div className="w-full">
      <InputLabel>Vendor Name{requiredStar}</InputLabel>
      <Input
        control={control}
        defaultValue={companiesData?.name}
        // label={['Company Name', requiredStar]}
        name="name"
        onChangeValue={ChangeCompaniesData}
        setState={setCompaniesData}
      />
    </div>
  )

  const CountriesInput = () => (
    <div className="w-full mb-2">
    <InputLabel>Countries{requiredStar}</InputLabel>
    {companiesData?.countries ? (
      <Controller
        name="countries"
        control={control}
        defaultValue={companiesData?.countries.map((item) => ({ label: item.name, value: item.id }))}
        render={({ field: { onChange, value } }) => (
          <CustomSelect
            className="mt-[4px] bg-white"
            options={countries?.map((item) => ({ label: item.name, value: item.id }))}
            // placeholder={['Countries', requiredStar]}
            defaultValue={value}
            isMulti
            onChange={(v) => {
              onChange(v);
              ChangeCountries(v);
            }}
          />
        )}
      />
    ) : (
      <Controller
        name="countries"
        control={control}
        render={({ field: { onChange } }) => (
          <CustomSelect
            options={countries?.map((item) => ({ label: item.name, value: item.id }))}
            className="mt-[4px] bg-white"
            // placeholder={['Countries', requiredStar]}
            defaultValue={selectedCountries}
            isMulti
            onChange={(v) => {
              onChange(v);
              setSelectedCountries(v);
            }}
          />
        )}
      />
    )}
  </div>
  )

  const BusinessUnitInput = () => {
    const options = (businessUnitList || []).map((attr) => ({
      label: attr.name,
      value: attr.id,
    }));

    const defaultValue = (() => {
      if (mode !== 'add') {
        if (options.length === 1) {
          return [options[0]?.value];
        } else if (companiesData?.business_units) {
          return companiesData.business_units.map((data) => data.id);
        }
      }
      return [];
    })();
    
    return (
      <div className="w-full">
        <InputLabel customClass="mb-[4px]">Business Unit</InputLabel>
        <Select
          name="business_units"
          control={control}
          label="Business Unit"
          defaultValue={defaultValue || []}
          options={options}
          multiple
          OptionLabel="Business Unit"
          menuProps={{
            style: {
              maxHeight: 400,
            },
          }}
          isInputLabel
          disabled={options.length === 1}
        />
      </div>
    );
  }
  
  const vendorData = attributeCategories && (
    <form className={mode !== 'add' ? 'w-full space-y-4 col-span-9' : 'w-full grid space-y-4 col-span-9'}>
      <Paper elevation={0} className="rounded-lg border-[1.5px]">
        <div className="p-4 border rounded-b-md ">
          <div className="w-full FOR-COMPANY-PROFILE mb-1">
            <div className="flex gap-x-4 gap-y-1 items-center">
              {/* boundary */}
              <div className="w-full grid grid-cols-2 gap-x-4">
                <div className="flex w-full gap-x-4">
                  <div className="w-[170px] h-[130px] relative mt-2">
                    {companyLogo?.logoPreview && (
                      <CancelIcon className="absolute right-[2px] top-[2px] cursor-pointer" sx={{ color: 'red', height: '20px', width: '20px' }} onClick={handleRemoveLogo} />
                    )}
                    <label className="bg-white">
                      <img
                        src={companyLogo?.logoPreview || getBlankImageSrc()}
                        alt=""
                        style={{ width: '100%', height: '135px', objectFit: 'contain', cursor: 'pointer', border: '1px solid black' }}
                        className="bg-white"
                      />
                      <input type="file" style={{ display: 'none' }} name="uploaded_logo" accept="image/*" onChange={(e) => handleChangeLogo(e)} />
                    </label>
                  </div>
                  <div className="w-full">
                    {mode !== 'add' && companiesData?.vendor_id && (
                      <>
                        <div className="w-full">
                          <InputLabel>ID</InputLabel>
                          <Input control={control} defaultValue={companiesData?.vendor_id ? companiesData?.vendor_id : ""} label="ID" type="text" name="ID" disabled={true} />
                        </div>
                        <CompanyNameInput />
                      </>
                    )}
                    
                    {mode === 'add' && (
                      <>                      
                        <CompanyNameInput />
                        <BusinessUnitInput />
                      </>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <CountriesInput />
                  {mode !== 'add' && businessUnitList?.length > 0 ? <BusinessUnitInput /> : ""}
                </div>
              </div>
              {/* boundary */}
            </div>
            <div className={`w-full grid ${businessUnitList !== null && businessUnitList.length > 0 ? 'grid-cols-3' : 'grid-cols-2'} gap-x-4`}>
              <div className={`w-full`}>
                <InputLabel>Company Address</InputLabel>
                <Input
                  control={control}
                  defaultValue={companiesData?.address}
                  label="Company Address"
                  name="address"
                  onChangeValue={ChangeCompaniesData}
                  setState={setCompaniesData}
                />
              </div>
              <div className={`w-full`}>
                <InputLabel>Email</InputLabel>
                <Input control={control} defaultValue={companiesData?.email} label="Email" name="email" onChangeValue={ChangeCompaniesData} setState={setCompaniesData} />
              </div>
              {businessUnitList !== null && businessUnitList.length > 0 ? <PhoneInput /> : ''}
            </div>
            <div className="w-full grid grid-cols-3 gap-x-4">
              <div className={`w-full`}>
                <InputLabel>Website</InputLabel>
                <Input
                  control={control}
                  defaultValue={companiesData?.website}
                  label="websiter"
                  name="website"
                  onChangeValue={ChangeCompaniesData}
                  setState={setCompaniesData}
                />
              </div>
              <div className={`w-full`}>
                <InputLabel>Tax Number</InputLabel>
                <Input
                  control={control}
                  defaultValue={companiesData?.tax_number}
                  label="Tax Number"
                  name="tax_number"
                  onChangeValue={ChangeCompaniesData}
                  setState={setCompaniesData}
                />
              </div>
              <div className={`w-full`}>
                <InputLabel>{'Organization Number (NIB)'}</InputLabel>
                <Input
                  control={control}
                  defaultValue={companiesData?.organization_number}
                  label="Organization Number (NIB)"
                  name="organization_number"
                  onChangeValue={ChangeCompaniesData}
                  setState={setCompaniesData}
                />
              </div>
            </div>
          </div>
          <div className="w-full FOR-ATTRIBUTE-CATEGORY">
            <div className="w-full grid grid-cols-2 gap-x-4 gap-y-1">
              {attributeCategories.length > 0 && attributeCategories?.map((val, idxCategory) => {
                return val?.attributes?.map((data, idx) => {
                  const value = data?.member_attribute?.attribute_values;
                  const matchedAttribute = companiesData?.attributes ? companiesData?.attributes?.find((attrData) => attrData.name === data.name) : {};

                  if (data.value_type == 'Date') {
                    return (
                      <div className="w-full">
                        <InputLabel>{data.is_required ? [data.name, requiredStar] : data.name}</InputLabel>
                        <DatePickerField
                          sx={{ marginBottom: '8px', marginTop: '4px', background: 'white' }}
                          size="small"
                          fullWidth
                          name={data.key_name}
                          control={control}
                          className="mt-[4px]"
                          // label={data.is_required ? [data.name, requiredStar] : data.name}
                          defaultValue={matchedAttribute?.value ? matchedAttribute?.value : null}
                        />
                      </div>
                    );
                  }
                  if (data.value_type === 'String' ?? 'Number') {
                    return (
                      <div className="w-full">
                        <InputLabel>{data.is_required ? [data.name, requiredStar] : data.name}</InputLabel>
                        <Input
                          name={data.key_name}
                          control={control}
                          // label={data.is_required ? [data.name, requiredStar] : data.name}
                          type={data.value_type === 'Number' ? 'Number' : ''}
                          defaultValue={matchedAttribute?.value ? matchedAttribute?.value : ''}
                        />
                      </div>
                    );
                  }
                  if (data.value_type === 'Option') {
                    const options = (attributesOptions[matchedAttribute?.key ? matchedAttribute?.key : data.key_name] || []).map((attr) => ({
                      label: attr.name,
                      value: attr.name,
                    }));

                    return (
                      <div className="w-full">
                        <InputLabel customClass="mb-[4px]">{data.is_required ? [data.name, requiredStar] : data.name}</InputLabel>
                        <Select
                          name={data.key_name}
                          control={control}
                          label={data.name}
                          defaultValue={matchedAttribute?.value ? matchedAttribute?.value : []}
                          options={options}
                          multiple={matchedAttribute?.multiple_value ? matchedAttribute?.multiple_value : data?.multiple_value}
                          OptionLabel={data.name}
                          menuProps={{
                            style: {
                              maxHeight: 400,
                            },
                          }}
                          isInputLabel
                        />
                      </div>
                    );
                  }
                });
              })}
            </div>
          </div>
          <div className="w-full mt-4 pt-6 mr-4 border-t-2">
            {isLoaded ? (
              <div className="w-full">
                <div className="w-full pr-4 flex flex-col gap-y-2 pb-4">
                  <InputLabel>Location :</InputLabel>
                  <div className="w-full grid grid-cols-2 gap-6">
                    {companiesLocation?.length !== 0 &&
                      companiesLocation?.map((loc) => {
                        return (
                          <div className="w-full">
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={{ lat: parseFloat(loc?.latitude), lng: parseFloat(loc?.longitude) }}
                              zoom={14}
                              // onLoad={onLoad}
                              onUnmount={onUnmount}
                            >
                              <Marker position={{ lat: parseFloat(loc?.latitude), lng: parseFloat(loc?.longitude) }} />
                            </GoogleMap>
                            <div className="min-h-[85px] flex flex-col gap-y-1 border-x border-b p-2 rounded-md shadow-sm">
                              <p className="italic text-[#f16b26] font-semibold">{loc.is_main ? 'Main Location' : ''}</p>
                              <p className="text-sm text-slate-500">{loc?.address}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                {
                  isOnlyUser === false &&
                    <div className="w-[50%] pr-4">
                      <button
                        className="w-full bg-[#2C6D47] p-2 text-white rounded-lg"
                        type="button"
                        onClick={() => dispatch(handleModal({ modalId: clientId, componentName: 'locationPair', modalData: paramID?.vendorId }))}
                      >
                        Set Locations
                      </button>
                    </div>
                }
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="w-full mt-4 pt-6 mr-4 border-t-2">
            <div className="flex w-full">
              <div className="w-full pr-4 flex flex-col gap-y-2">
                <div className="flex">
                  <InputLabel>Documents uploaded :</InputLabel>
                  {
                    isOnlyUser === false && (
                      <button
                        className={
                          !disabledButtonDelete
                            ? 'btn bg-red-600 p-2 text-white rounded-lg border ml-auto cursor-pointer'
                            : 'btn bg-red-200 p-2 text-white rounded-lg border ml-auto'
                        }
                        onClick={() => deleteDocuments()}
                        // disabled={isDisabledDeleteDocuments(idxAttr)}
                        disabled={disabledButtonDelete}
                        name="update document list"
                        type="button"
                      >
                        Delete
                      </button>
                    )
                  }
                </div>
                <div className="grid grid-cols-1 2xl:grid-cols-2 gap-2">
                  {(companyDocuments || []).map((item) => {
                    const filename = item.file ? getFilename(item.file) : '';
                    const extension = item.file ? getFilename(item.file, { onlyExtension: true }) : '';

                    return (
                      <div className="w-full px-2">
                        <div className="w-full flex gap-y-1 gap-x-3 border-x border p-2 rounded-md shadow-sm">
                          {
                            isOnlyUser === false &&
                            <input
                              className="mr-3"
                              type="checkbox"
                              value={item.id}
                              checked={checkedDocuments.includes(item.id)}
                              onChange={(e) => handleCheckedDocuments(e, item.id)}
                            />
                          }
                          <div className="w-full 2xl:w-[55%] font-bold flex items-center" style={{ color: '#707070' }}>
                            {filename}
                          </div>
                          <div className="w-full flex items-center justify-between">
                            <div style={{ color: '#707070' }}>{extension}</div>
                            <a
                              href={item.file}
                              download
                              className="btn btn-link flex items-center justify-center"
                              style={{ backgroundColor: '#E2E2E2', height: '32px', width: '32px', borderRadius: '50%' }}
                            >
                              <DownloadIcon color="primary" />
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {
                  isOnlyUser === false && (
                    <div className="mt-4 w-6/12">
                      <label className="w-full bg-[#2C6D47] p-2 text-white rounded-lg cursor-pointer flex justify-center">
                        <input type="file" style={{ display: 'none' }} name="uploaded_documents" multiple onChange={(e) => handleFileUpload(e)} />
                        Add new documents
                      </label>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </Paper>
      <Paper elevation={0} className="w-full h-fit rounded-lg border-[1.5px]">
        <div className="border rounded-t-md p-4 font-bold uppercase">
          <h2>Contact</h2>
        </div>
        <div className="w-full p-4 border rounded-b-md grid grid-cols-1 2xl:grid-cols-2 gap-x-24 gap-y-8">
          {assignedContacts?.map((assignedContact, idxContact) => {
            const { contact } = assignedContact;
            if (mode === 'add') {
              return (
                <div key={assignedContact.id} className="">
                  <InputLabel className="pl-2 mb-2">{assignedContact.name}</InputLabel>
                  <ContactSelectButton
                    idxContact={idxContact}
                    name={contact?.name}
                    title={contact?.position}
                    phone={contact?.phone}
                    email={contact?.email}
                    inputData={contact}
                    handleAssignedContact={handleAssignedContact}
                  />
                </div>
              );
            } else {
              return (
                <div key={assignedContact.id} className="">
                  <InputLabel className="pl-2 mb-2">{assignedContact.name}</InputLabel>
                  <ContactSelect
                    idx={idxContact}
                    name={contact?.name}
                    title={contact?.position}
                    phone={contact?.phone}
                    email={contact?.email}
                    contactId={contact?.id}
                    contacts={companiesContacts}
                    selectedContacts={assignedContacts.map((c) => c.contact?.id)}
                    handleSelectedContact={handleSelectedContact}
                    authorize={isOnlyUser}
                  />
                </div>
              );
            }
          })}
        </div>
      </Paper>
      {
        isOnlyUser === false && (
          <div className="flex justify-end">
            <button
              className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
              type="submit"
              name="submit data company"
              onClick={handleSubmit(validationSubmit)}
            >
              {mode !== 'add' ? 'Update Vendor Data' : 'Add New Vendor'}
            </button>
          </div>
        )
      }
    </form>
  );

  const customFeatures = (
    <div className="col-span-3">
      <div className="w-full mb-4">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore className="" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="border-b-2 bg-white min-h-[57px]"
            sx={{
              '&.Mui-expanded': {
                minHeight: '57px!important',
              },
            }}
          >
            <h1 className="font-bold">History</h1>
          </AccordionSummary>
          <AccordionDetails className="border-t-[1.5px] border-[#E5E7EB] py-5">
            <div className="w-full max-h-[400px] overflow-auto">
              {histories &&
                histories
                  ?.filter((unEmptyData) => unEmptyData.length)
                  .map((history) => {
                    return (
                      <div className="w-full border rounded-md">
                        <div className="border-b p-2 font-medium">{attributes[history[0]?.attribute]?.name}</div>
                        {history?.map((val) => {
                          return (
                            <div className="mb-2 flex flex-col bg-slate-300 mt-2 mx-2 rounded-sm p-4 gap-y-4">
                              <div className={['mb-1'].join(' ')} style={{ fontWeight: '500' }}>
                                <span className="badge badge-pill border border-dark p-1 rounded-sm">{val.action}</span>
                              </div>
                              <div className="font-medium">{val.description}</div>
                              <div className="flex justify-between" style={{ fontSize: '12px', fontWeight: '500' }}>
                                <div>{new Date(val.timestamp).toLocaleString('en-US', { dateStyle: 'long', timeStyle: 'short', hour12: true })}</div>
                                {
                                  isOnlyUser == false && (
                                    <EditIcon
                                      className="cursor-pointer"
                                      onClick={() => {
                                        dispatch(handleModal({ modalId: '', componentName: 'EditHistory' }));
                                        setHistoryData(val);
                                      }}
                                    />
                                  )
                                }
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
            </div>
            <div className="w-full mt-2 px-4">
              <button type="button" className="w-full bg-[#2C6D47] p-2 text-white rounded-lg" onClick={() => dispatch(handleModal({ modalId: '', componentName: 'ViewHistory' }))}>
                View More
              </button>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="w-full mb-4">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore className="" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="border-b-2 bg-white min-h-[57px]"
            sx={{
              '&.Mui-expanded': {
                minHeight: '57px!important',
              },
            }}
          >
            <h1 className="font-bold">Notes List</h1>
          </AccordionSummary>
          <AccordionDetails className="border-t-[1.5px] border-[#E5E7EB] py-5">
            <div className="w-full max-h-[400px] overflow-auto">
              {notes &&
                notes.map((val, idx) => {
                  return (
                    <div className={idx % 2 === 0 ? 'w-full mb-2 py-2 px-5 rounded-sm bg-[#bedcee]' : 'w-full mb-2 py-2 px-5 rounded-sm bg-[#ead2b5]'}>
                      <div className="flex flex-col gap-y-2">
                        <div className={idx % 2 === 0 ? 'flex justify-between py-2 border-b-2 border-[#a1d0ed]' : 'flex justify-between py-2 border-b-2 border-[#e7c5a0]'}>
                          <h2 className="font-medium">{val.context}</h2>
                          {
                            isOnlyUser === false && (
                              <EditIcon
                                className="cursor-pointer"
                                onClick={() => {
                                  dispatch(handleModal({ modalId: '', componentName: 'AddNote', modalData: 'edit' }));
                                  setNoteModalStatus('edit');
                                  setNoteId(val.id);
                                }}
                              />
                            )
                          }
                        </div>
                        <div>
                          <div>
                            <h2 className="font-medium">Contacted Person :</h2>
                            <p className="px-2">{val.contacted_person || '-'}</p>
                          </div>
                          <div>
                            <h2 className="font-medium">Reason :</h2>
                            <p className="px-2">{val.reason || '-'}</p>
                          </div>
                          <div>
                            <h2 className="font-medium">Result :</h2>
                            <p className="px-2">{val.result || '-'}</p>
                          </div>
                        </div>
                        <div className={idx % 2 === 0 ? 'text-sm font-semibold py-2 border-t-2 border-[#a1d0ed]' : 'text-sm font-semibold py-2 border-t-2 border-[#e7c5a0]'}>
                          <p>{val.date}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {
              isOnlyUser === false && (
                <div className="w-full mt-4 px-4">
                  <button
                    type="button"
                    className="w-full bg-[#2C6D47] p-2 text-white rounded-lg"
                    onClick={() => {
                      dispatch(handleModal({ modalId: '', componentName: 'AddNote', modalData: 'add' }));
                      setNoteModalStatus('add');
                    }}
                  >
                    Add Note
                  </button>
                </div>
              )
            }
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );

  const [isGrid, setIsGrid] = useState(true);
  
  const relatedProduct = (
    <Paper elevation={0} className="rounded-lg border-[1.5px]">
      <div className="p-4 border rounded-b-md space-y-4">
        <div className='w-full flex justify-between'>
          <div className="w-[70%] flex gap-4 flex-wrap">
            <div className="w-[14rem]">
              <div>
                <p className="font-bold">Search</p>
              </div>
              <InputBase
                size="small"
                placeholder="search"
                endAdornment={<Search color="disabled" />}
                onChange={(v) => setSearchProduct(v.target.value)}
                value={searchProduct}
                className="w-full h-[2.5rem] border border-[#BCB9B9] rounded px-4 bg-white hover:border-[#adacac]"
              />
            </div>
            <div className="w-[14rem]">
              <div>
                <p className="font-bold">Category</p>
              </div>
              <MuiSelectSingleItem
                sx={{ height: '40px', py: 0 }}
                FormControlClasses="h-[2.5rem]"
                name="category"
                label="category"
                OptionLabel="category"
                control={control}
                options={categoryOptions.map((dt) => ({ label: dt.name, value: dt.name }))}
                defaultValue={selectedCategory}
                onChangeValue={(v) => {
                  setSelectedCategory(v);
                }}
                isInputLabel
              />
            </div>
          </div>
          <div className="w-[30%] space-x-2 flex justify-end items-center">
            <Link
              to={`/vendor/${paramID.vendorId}/product/add`}
              className="flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 cursor-pointer whitespace-nowrap"
              style={{ backgroundColor: '#2C6D47' }}
            >
              <AddIcon className="text-white" />
              <p className="text-white">Add New</p>
            </Link>
            <Tooltip className="cursor-pointer">
              {isGrid ? <ViewList className="scale-125" onClick={() => setIsGrid(false)} /> : <GridView className="scale-125" onClick={() => setIsGrid(true)} />}
            </Tooltip>
          </div>
        </div>
        {
          !isFetchingvendorProductList ?
            vendorProductList.length > 0 ? (
              isGrid ? (
                <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-6 gap-6 mt-4'>
                  {
                    vendorProductList?.map((row, index) => {
                      return (
                        <div key={row.id} className="w-full relative">
                          <Link to={`/vendor/${paramID.vendorId}/product/detail/${row.id}`} className="text-black">
                            <div className="h-full w-full gap-x-4 items-center hover:bg-[#F9F8F8] border border-transparent rounded-md pb-[16px] space-y-2 group">
                              <div
                                className={`relative w-full h-[15vh] flex items-center justify-center border-black border-[1.5px] border-dashed p-3 rounded-md `}
                              >
                                <img
                                  src={row?.media_main || BzThumbnail}
                                  alt=""
                                  className={`object-contain w-full h-full aspect-[3/4] ${!row?.media_main && "grayscale"}`}
                                />
                              </div>
                              <TruncateMarkup className="space-y-1" lines={2}>
                                <div className="capitalize text-center font-medium group-hover:text-[#2C6D47] group-hover:font-semibold">{row?.name}</div>
                              </TruncateMarkup>
                            </div>
                          </Link>
                        </div>
                      )
                    })
                  }
                </div>
              ): (
                <div className='border'>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" className="!font-bold" width={150}>Product Id</TableCell>
                        <TableCell align="center" className="!font-bold">Name</TableCell>
                        <TableCell align="center" className="!font-bold">Currency</TableCell>
                        <TableCell align="center" className="!font-bold">Category</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                      vendorProductList?.map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <TableCell align="center">{row.item_number || ''}</TableCell>
                            <TableCell align="left">
                              <Link to={`/vendor/${paramID.vendorId}/product/detail/${row.id}`} className="text-[#2C6D47] font-semibold">
                                {row.name}
                              </Link>
                            </TableCell>
                            <TableCell align="left">{row.Currency || '-'}</TableCell>
                            <TableCell align="left">{row.category?.name || '-'}</TableCell>
                          </TableRow>
                        )
                      })
                    }
                    </TableBody>
                  </Table>
                </div>
              )
            ) : (
              <p>-</p>
            )
          :
          (
            <CircularProgress />
          )
        }
      </div>
    </Paper>
  )
  
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    memberDetail && (
      <div className="w-full h-full ">
        <BZHelmet title={mode !== 'add' ? 'Vendor Detail' : 'Add Vendor'} description="" content="" />
        {/* <div className="bg-gray-100 w-full flex justify-between py-5 px-3 my-5 rounded items-center text-[20px] font-sans"> */}
        <BaseMainTitle title={mode !== 'add' ? 'Vendor Detail' : 'Add Vendor'} />
        <div className="p-5">
          <div className="mb-4">
            <button
              onClick={() => navigate('/procurement/vendor')}
              className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
              type="button"
            >
              <ArrowBack />
              Back
            </button>
          </div>
          {mode === 'add' ? (
            <div className="w-full">
              {vendorData}
            </div>
          ) : (
            <Box sx={{ width: '100%', position: 'relative' }}>
              <Box sx={{ marginBottom: 2 }}>
                <Tabs
                  value={tabValue}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: '#2C6D47',
                    },
                  }}
                  sx={{
                    '.MuiButtonBase-root': {
                      fontWeight: '600',
                    },
                    '.Mui-selected': {
                      color: '#2C6D47!important',
                    },
                  }}
                >
                  <Tab value={0} label="Vendor" {...a11yProps(0)} />
                  <Tab value={1} label="Products" {...a11yProps(1)} />
                </Tabs>
              </Box>
              {
                tabValue === 0 && (
                  <div className="grid grid-cols-12 gap-x-2">
                    {vendorData}
                    {customFeatures}
                  </div>
                )
              }
              {
                tabValue === 1 && (
                  <div className="w-full">
                    {relatedProduct}
                  </div>
                )
              }
            </Box>
          )}
        </div>
        <ModalWrapper componentName="AddNote" header="Add Note" maxWidth="sm">
          <AddNote status={NoteModalStatus} memberId={paramID?.vendorId} reload={setReloadNotes} noteId={noteId} />
        </ModalWrapper>
        <ModalWrapper componentName="ViewHistory" header="View History" maxWidth="lg">
          <ViewHistory memberId={paramID?.vendorId} />
        </ModalWrapper>
        <ModalWrapper componentName="EditHistory" header="Edit History" maxWidth="sm">
          <EditHistory data={historyData} reload={setReloadHistories} />
        </ModalWrapper>
        <ModalWrapper componentName="locationPair" header="Pair Location" maxWidth="xl">
          <LocationPair
            mode={mode === 'add'}
            submit={openModalCreateLocation}
            reload={setReloadLocations}
            StateLocations={selectedLocations}
            StateMainLocation={selectedMainLocation}
            SetStateLocations={SetSelectedLocations}
            SetStateMainLocation={SetSelectedMainLocation}
          />
        </ModalWrapper>
        <ModalWrapper componentName="addNewLocation" header="New Location" maxWidth="sm">
          <AddNewLocation backtoLocationPair={backtoLocationPair} />
        </ModalWrapper>
      </div>
    )
  );
}

/* eslint-disable */

import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { DevTool } from '@hookform/devtools';

import { Link, useParams } from 'react-router-dom';
import InputContainer from '../../Input/InputContainer';
import LocationArrField from '../Location/LocationArrField';

function BusinessUnitForm({ defaultValues, onFormSubmit, isLoaded, close }) {
  // console.log('defaultValues ', defaultValues)

  const { businessUnitId } = useParams();
  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: defaultValues?.name,
      business_unit_locations:
        defaultValues?.business_unit_locations?.map((loc) => ({
          ...loc,
          location: {
            ...loc?.location,
            country: loc?.location?.country?.id,
          },
        })) || [],
    },
  });
  const clientId = useSelector((state) => state.client.activeClient);

  const onSubmit = (data) => {
    const newAssignLoc = data?.business_unit_locations?.map((obj) => {
      return {
        ...obj,
        location: {
          ...obj.location,
          client: clientId,
        },
        business_unit: businessUnitId,
      };
    });

    onFormSubmit({
      client: clientId,

      name: data.name,
      contact: data.contact,
      business_unit_locations: newAssignLoc?.filter((loc) => loc) || [],
    });
  };

  const findMainIndex = useCallback((arr) => {
    return arr?.findIndex((element) => element?.is_main);
  }, []);

  const getExp = watch('business_unit_locations') || [];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        {/* <InputContainer name="code" control={control} label="Code" errors={errors.name} /> */}
        <InputContainer name="name" control={control} label="Name" errors={errors.name} />
        {/* <NewLocationForm /> */}
        {/* Fill hook-form data */}
        <div className="">
          {getExp?.map((location, idx) => {
            return (
              <InputContainer
                key={idx}
                name={`business_unit_locations[${idx}][location[name]]`}
                control={control}
                label="Name"
                hidden
                defaultValue={watch(`business_unit_locations[${idx}][location[name]]`)}
                // errors={errors.name}
              />
            );
          })}
        </div>
        {/* Fill hook-form data */}

        <LocationArrField
          name="business_unit_locations"
          control={control}
          isMainIdx={findMainIndex(defaultValues?.business_unit_locations)}
          defaultValues={defaultValues}
          setValue={setValue}
          register={register}
          unregister={unregister}
          watch={watch}
          rows={2}
          isLocationLoad={isLoaded}
        />
        <div className="flex justify-end ml-auto gap-x-2">
          <Button className="bz-btn secondary" variant="outlined">
            {close ? <div onClick={close()}>Cancel</div> : <Link to="/hr/business-unit"> Cancel</Link>}
          </Button>
          <Button className="bz-btn" type="submit" variant="outlined">
            {defaultValues ? 'Update Business Unit' : 'Create Business Unit'}
          </Button>
        </div>
      </div>
      <DevTool control={control} />
    </form>
  );
}
export default BusinessUnitForm;

import axiosInstances from '../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function section() {
  const { axiosInstance } = axiosInstances();

  async function getEmployees(params) {
    const method = 'get';
    const url = `${baseURL}employees/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getUsers(params) {
    const method = 'get';
    const url = `${baseURL}users/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function getUserDetail(userId, params) {
    const method = 'get';
    const url = `${baseURL}users/${userId}`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  return { getEmployees, getUsers, getUserDetail };
}

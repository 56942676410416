import React, { useEffect, useRef } from 'react';
import { Close } from '@mui/icons-material';
import { Button, Checkbox, Drawer, FormControl, FormControlLabel, TextField, ThemeProvider, createTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const staticSchema = yup.object().shape({
  isAssumption: yup.boolean(),
  assumptionDefaultValue: yup
    .string()
    .nullable()
    .when('isAssumption', {
      is: true,
      then: yup.string().required('Must enter default value'),
    }),
});

const drawerTheme = createTheme({ components: { MuiBackdrop: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({ background: 'transparent' }) } } } });

export default function DndProfileSideDrawer({ open }) {
  const dispatch = useDispatch();
  const activeSidePanel = useSelector((state) => state.crmProfile.activeSidePanel);

  const initialize = useRef(false);

  const { handleSubmit, setValue, control, watch, reset } = useForm({
    resolver: yupResolver(staticSchema),
    defaultValues: { isAssumption: false },
  });

  const onCloseDrawer = () => {
    reset();
    dispatch({ type: 'crmProfile/setActiveSidePanel', payload: false });
    initialize.current = false;
  };

  useEffect(() => {
    if (activeSidePanel && activeSidePanel.item && activeSidePanel.data && !initialize.current) {
      initialize.current = true;
      const { w, h, static: isStatic } = activeSidePanel.item;
      const { configuration, name: groupName, is_displayed: isDisplayed } = activeSidePanel.data;
      const { isGroup } = activeSidePanel;
      setValue('w', w);
      setValue('h', h);
      setValue('isStatic', isStatic);
      setValue('groupName', groupName);
      setValue('isGroup', !!isGroup);
      if (isGroup) {
        setValue('isNameDisplayed', !!isDisplayed);
      }
      if (configuration && !isGroup) {
        if (configuration.is_reference) {
          setValue('assumptionDefaultValue', configuration.default_value);
          setValue('isAssumption', configuration.is_reference);
        }
      }
    }
  });

  const onSubmit = (data) => {
    dispatch({ type: 'crmProfile/setUpdateElement', payload: { ...data, data: activeSidePanel.item } });
    onCloseDrawer();
  };

  const { isGroup } = activeSidePanel;

  const isAssumption = watch('isAssumption');
  return (
    <ThemeProvider theme={drawerTheme}>
      <Drawer anchor="right" open={!!open} onClose={onCloseDrawer}>
        <div className="relative w-[296px] h-full p-4">
          <Close onClick={onCloseDrawer} className="absolute top-2 right-2 z-10 cursor-pointer" />
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-100 h-full gap-4">
            <div className="flex flex-col gap-2 pb-4 mt-4 border-b border-slate-300">
              <span className="font-bold">{isGroup ? 'Group' : 'Attribute'}</span>
              <FormControl>
                <Controller
                  control={control}
                  name="groupName"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value || ''}
                      inputRef={ref}
                      label="Name"
                      size="small"
                      type="text"
                      placeholder=""
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="flex flex-col gap-2 pb-4 border-b border-slate-300">
              <span className="font-bold">Size</span>
              <div className="flex row gap-4 items-center ">
                <Controller
                  control={control}
                  name="w"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value || ''}
                      inputRef={ref}
                      label="W"
                      size="small"
                      type="number"
                      placeholder=""
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ inputProps: { min: '0', max: '12', step: '4' } }}
                    />
                  )}
                />
                <span>X</span>
                <Controller
                  control={control}
                  name="h"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value || ''}
                      inputRef={ref}
                      label="H"
                      size="small"
                      type="number"
                      placeholder=""
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ inputProps: { min: '0', max: '12', step: '1' } }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 pb-4 border-b border-slate-300">
              <span className="font-bold">Static</span>
              <FormControl>
                <Controller
                  control={control}
                  name="isStatic"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel className="h-8" control={<Checkbox onChange={onChange} checked={value || false} inputRef={ref} />} label="Set as Static" />
                  )}
                />
              </FormControl>
            </div>
            {isGroup && (
              <div className="flex flex-col gap-2 pb-4 border-b border-slate-300">
                <span className="font-bold">Display Name</span>
                <FormControl>
                  <Controller
                    control={control}
                    name="isNameDisplayed"
                    render={({ field: { onChange, value, ref } }) => (
                      <FormControlLabel className="h-8" control={<Checkbox onChange={onChange} checked={value || false} inputRef={ref} />} label="Visibility" />
                    )}
                  />
                </FormControl>
              </div>
            )}
            {!isGroup && (
              <div className="flex flex-col gap-2 pb-4 border-b border-slate-300">
                <span className="font-bold">Assumption</span>
                <FormControl>
                  <Controller
                    control={control}
                    name="isAssumption"
                    render={({ field: { onChange, value, ref } }) => (
                      <FormControlLabel className="h-8" control={<Checkbox onChange={onChange} checked={value || false} inputRef={ref} />} label="Set as Assumption" />
                    )}
                  />
                </FormControl>
                {isAssumption && (
                  <Controller
                    control={control}
                    name="assumptionDefaultValue"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField onBlur={onBlur} onChange={onChange} value={value || ''} inputRef={ref} label="Default Value" size="small" type="text" required />
                    )}
                  />
                )}
              </div>
            )}
            <Button type="submit" variant="contained" className="mt-auto">
              Save
            </Button>
          </form>
        </div>
      </Drawer>
    </ThemeProvider>
  );
}

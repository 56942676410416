import _ from 'lodash';
import { useLocation } from 'react-router-dom';

export function PathHandler() {
  const location = useLocation();
  const splitPath = location.pathname.split('/');
  const currentPath = location.pathname;
  const removeLastPath = _.initial(splitPath);
  const lastPath = _.last(splitPath);
  const currentPathWithoutLast = removeLastPath.join('/');
  const hash = location?.hash;

  return { lastPath, currentPathWithoutLast, hash, currentPath };
}

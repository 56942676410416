/* eslint-disable */

import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';
import DatePickerField from '../../Input/DatePickerField';
import CustomRadioGroup from '../../Input/CustomRadioGroup';
import { DevTool } from '@hookform/devtools';
import CreatableSelect from 'react-select/creatable';
import { Add, AttachFile, Clear, DeleteForever, Download } from '@mui/icons-material';
import SimpleInputFieldArr from '../../Input/SimpleInputFieldArr';
import FileContatiner from '../../Input/FileContatiner';
import { deepOrange } from '@mui/material/colors';
import { Avatar, IconButton } from '@mui/material';
import TextAreaContainer from '../../Input/TextAreaContainer';
import { useNavigate } from 'react-router';
import schemaApplicant from './validationApplicant';
import ReactSelectCreateable from '../../Input/ReactSelectCreateable';
import useGet from '../../../hooks/useGet';
import renderDate from '../../../helper/renderDate';
import { generateSelectListDefault, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
import ApplExpFieldArr from './ApplExpFieldArr';
import ApplEduFieldArr from './ApplEduFieldArr';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';

function getFileNameAndType(url) {
  return url?.substring(url?.lastIndexOf('/') + 1);
  
}

const optionGender = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
];

function ApplicantForm({ defaultValues, onFormSubmit, cancel, isModal }) {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);
  const activeSite = useSelector((state) => state.content.activeSite);
  const template = useSelector((state) => state.content.activeTemplate);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    getValues,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...defaultValues,
      spoken_languages: defaultValues?.spoken_languages?.map((spoke) => spoke?.id),
    },
    resolver: schemaApplicant,
  });

  const { data: languagesData } = useGet({
    url: `languages`,
    queryString: {
      // client: clientId,
      max_size: true,
      // language: activeLang,
    },
    validate: 'languages',
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    if (defaultValues?.user?.id) {
      formData.append('user.id', defaultValues.user.id);
    }
    formData.append('user.email', data.user.email);
    formData.append('user.name', data.user.name);
    formData.append('user.site', activeSite);
    formData.append('user.client', clientId);
    data?.spoken_languages?.forEach((data, i) => {
      formData.append(`spoken_languages`, data);
    });
    if (data.user.avatar && data.user.avatar[0] && typeof data.user.avatar[0] !== 'string') {
      formData.append('user.avatar', data.user.avatar[0]);
    } else if (defaultValues?.user.avatar && data.user.avatar) {
      null;
    } else formData.append('user.avatar', '');

    if (data.resume && data.resume[0] && typeof data.resume[0] !== 'string') {
      formData.append('resume', data.resume[0]);
    } else if (defaultValues?.resume && data?.resume) {
      null;
    } else formData.append('resume', '');

    formData.append('phone', data.phone);
    formData.append('address', data.address);
    formData.append('gender', data?.gender || 'Male');
    formData.append('birth_date', renderDate(data.birth_date) || '');

    if (data.skills && data.skills.length > 0) {
      formData.append(`skills`, JSON.stringify(data.skills.filter((skill) => skill !== null)));
    } else {
      formData.append('skills', null);
    }
    if (data.experiences && data.experiences.length > 0) {
      data.experiences.forEach((exp, idx) => {
        formData.append(`experiences[${idx}]id`, exp?.id);
        formData.append(`experiences[${idx}]title`, exp?.title);
        formData.append(`experiences[${idx}]company`, exp?.company);
        formData.append(`experiences[${idx}]description`, exp?.description);
        formData.append(`experiences[${idx}]duration`, exp?.duration);
        if (exp?.start_date) {
          formData.append(`experiences[${idx}]start_date`, renderDate(exp?.start_date));
        } else formData.append(`experiences[${idx}]start_date`, '');
        if (exp?.end_date) {
          formData.append(`experiences[${idx}]end_date`, renderDate(exp?.end_date));
        } else formData.append(`experiences[${idx}]end_date`, '');
      });
    }

    if (data.educations && data.educations.length > 0) {
      data.educations.forEach((exp, idx) => {
        formData.append(`educations[${idx}]id`, exp?.id);
        formData.append(`educations[${idx}]title`, exp?.title);
        formData.append(`educations[${idx}]school`, exp?.school);
        formData.append(`educations[${idx}]degree`, exp?.degree);
        formData.append(`educations[${idx}]description`, exp?.description);
        formData.append(`educations[${idx}]duration`, exp?.duration);
        if (exp?.start_date) {
          formData.append(`educations[${idx}]start_date`, renderDate(exp?.start_date));
        } else formData.append(`educations[${idx}]start_date`, '');
        if (exp?.end_date) {
          formData.append(`educations[${idx}]end_date`, renderDate(exp?.end_date));
        } else formData.append(`educations[${idx}]end_date`, '');
      });
    }
    onFormSubmit(formData);
  };

  const handleCancel = () => {
    // dispatch(handleModal({ id: '' }));
    if (isModal) {
      cancel();
    } else navigate(cancel);
  };

  const ava = watch('user[avatar]');
  const fileresume = watch('resume');
  console.log('🚀 ~ file: ApplicantForm.jsx:97 ~ ApplicantForm ~ fileresume', fileresume);

  function renderAva() {
    const createAva = ava && typeof ava === 'string' ? defaultValues?.user?.avatar : URL.createObjectURL(ava[0]);
    return createAva;
  }

  const { data: SkillsData } = useGet({
    url: `applicants/list_skills`,
    queryString: {
      user__client: clientId,
      // max_size: true,
    },
    validate: 'skillsApplicant',
  });
  console.log('🚀 ~ file: ApplicantForm.jsx:130 ~ ApplicantForm ~ SkillsData:', SkillsData);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-2 ">
        <div className="w-full gap-x-3 grid grid-cols-2">
          <div className="space-y-4">
            <div className="w-full flex space-x-3 relative">
              <FileContatiner
                name="user[avatar]"
                label="Avatar"
                errors=""
                control={control}
                watchValue={ava}
                title={getFileNameAndType(ava ? renderAva() : '')}
                custom
                customPaceholder={
                  <div className="w-full ">
                    <Avatar
                      src={ava ? renderAva() : ''}
                      alt="photo preview"
                      className="w-full h-full object-cover  max-w-[300px] max-h-[300px] border p-2"
                      // sx={{ bgcolor: deepOrange[500] }}
                      variant="square"
                    />
                  </div>
                }
              />
              {ava && (
                <div className="absolute left-[310px] top-[30px]">
                  <IconButton onClick={() => setValue('user[avatar]', null)} aria-label="delete" size="small" type="button" variant="contained" color="error">
                    <DeleteForever className="" />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          <div className="w-full gap-x-3 grid grid-cols-2">
            <div className="space-y-4">
              <div className="w-full">
                {defaultValues?.resume ? (
                  <div className="space-y-4">
                    <div className="font-bold">Resume</div>
                    {fileresume && fileresume[0].name ? (
                      <div className="flex space-x-3">
                        <div className="flex space-x-1 items-center">
                          <AttachFile />
                          <div className="">{getFileNameAndType(fileresume && fileresume[0].name)}</div>
                        </div>
                        <div className="flex space-x-1 items-center">
                          <IconButton onClick={() => setValue('resume', null)} aria-label="delete" size="small" type="button" variant="contained" color="error">
                            <DeleteForever className="" />
                          </IconButton>
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        {fileresume && (
                          <div className="flex space-x-3">
                            <a href={defaultValues?.resume} className="" target="blank">
                              <div className="flex space-x-1 items-center">
                                <AttachFile />
                                <div className="">{getFileNameAndType(defaultValues?.resume)}</div>
                              </div>
                            </a>
                            <div className="flex space-x-1 items-center">
                              <IconButton onClick={() => setValue('resume', null)} aria-label="delete" size="small" type="button" variant="contained" color="error">
                                <DeleteForever className="" />
                              </IconButton>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="flex space-x-1 items-center border border-blue-400 rounded w-fit p-1 hover:bg-blue-400 hover:text-white cursor-pointer max-w-[180px] justify-center">
                      <FileContatiner
                        name="resume"
                        // label="Resume"
                        errors=""
                        control={control}
                        custom
                        customPaceholder={
                          <div className="flex space-x-1 items-center rounded  p-1  ">
                            <Download />
                            <div className="">{fileresume ? 'Change Resume' : 'Upload Resume'}</div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <div className="font-bold">Resume</div>
                    {fileresume && fileresume[0].name && (
                      <div className="flex space-x-3">
                        <div className="flex space-x-1 items-center">
                          <AttachFile />
                          <div className="">{getFileNameAndType(fileresume && fileresume[0].name)}</div>
                        </div>
                        <div className="flex space-x-1 items-center">
                          <IconButton onClick={() => setValue('resume', null)} aria-label="delete" size="small" type="button" variant="contained" color="error">
                            <DeleteForever className="" />
                          </IconButton>
                        </div>
                      </div>
                    )}
                    <FileContatiner
                      name="resume"
                      // label="Resume"
                      errors=""
                      control={control}
                      custom
                      customPaceholder={
                        <div className="flex space-x-1 items-center border border-blue-400 rounded w-fit p-1 hover:bg-blue-400 hover:text-white cursor-pointer">
                          <Download />
                          <div className="">Upload Resume</div>
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-x-3">
          <div className="w-6/12">
            <InputContainer name="user[name]" control={control} label="Name" errors={errors?.user?.name} className="" />
          </div>
          <div className="w-6/12">
            <InputContainer name="user[email]" control={control} label="Email" errors={errors?.user?.email} />
          </div>
        </div>
        <div className="flex w-full gap-x-3">
          <div className="w-6/12">
            <InputContainer name="phone" control={control} label="Phone" errors={errors.name} />
          </div>
          <div className="w-6/12">
            <TextAreaContainer name="address" control={control} label="Address" errors={errors.email} rows={2} />
          </div>
        </div>
        <div className="w-full gap-x-3 grid grid-cols-2">
          <div className="w-full">
            <DatePickerField
              sx={{ marginBottom: '0px' }}
              control={control}
              name="birth_date"
              label="Birth Date"
              size="small"
              fullWidth
              // defaultValue={defaultValues?.birth_date || ''}
            />
          </div>
          <div className="w-full">
            <Controller
              name="gender"
              control={control}
              render={({ field: { onChange } }) => <CustomRadioGroup label=" Gender" options={optionGender} defaultValue={defaultValues?.gender || 'Male'} onChange={onChange} />}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className=" w-full gap-x-5 ">
            <div className="font-bold pb-3">Spoken languages</div>
            <div className=" bg-white">
              <Controller
                name="spoken_languages"
                control={control}
                render={({ field: { onChange } }) => (
                  <ReactSelectMultiple
                    options={languagesData?.data?.results?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    defaultValue={generateSelectListDefault(defaultValues?.spoken_languages)}
                    placeholder="Select Languages"
                    isMulti
                    onChange={(v) => onChange(v?.map((val) => val.value))}
                  />
                )}
              />
            </div>
          </div>
          <div className=" w-full gap-x-5 ">
            <div className="font-bold pb-3">Skills</div>
            <ReactSelectCreateable
              name="skills"
              control={control}
              options={getOptionSelectWithKey(
                SkillsData?.data?.map((sk) => ({
                  name: sk,
                  id: sk,
                }))
              )}
              defaultValue={generateSelectListDefault(
                defaultValues?.skills?.map((sk) => ({
                  name: sk,
                  id: sk,
                }))
              )}
              placeholder="Select Skills"

            />
          </div>
        </div>
        <div className="w-full">
          <div className="font-bold pb-3">Education</div>
          <ApplEduFieldArr
            name="educations"
            control={control}
            defaultValues={defaultValues}
            setValue={setValue}
            register={register}
            unregister={unregister}
            watch={watch}
            // label="Educations"
            classNameInput="w-full"
            errors={errors}
          />
        </div>
        <div className="w-full">
          <div className="font-bold pb-3">Experiences</div>

          <ApplExpFieldArr
            name="experiences"
            defaultValues={defaultValues}
            control={control}
            setValue={setValue}
            register={register}
            unregister={unregister}
            watch={watch}
            // label="Educations"
            classNameInput="w-full"
            errors={errors}
          />
        </div>

        <div className="flex justify-end ml-auto gap-x-2">
          {cancel && (
            <Button onClick={handleCancel} className="flex" variant="outlined">
              Cancel
            </Button>
          )}
          <Button className=" flex" type="submit" variant="outlined">
            {defaultValues ? 'Update Applicant' : 'Create Applicant'}
          </Button>
        </div>
      </div>
      <DevTool control={control} />
    </form>
  );
}
export default ApplicantForm;

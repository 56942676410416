import { useCallback, useMemo, useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { Box, ButtonBase } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import ModalLedgers from '@/components/Modal/Accounting/Legers';
import BasePromptDialog from '@/components/ui/Dialog/BasePromptDialog';
import BZHelmet from '@/utils/BZHelmet';

import accountingApi from '@/api/accounting/accounting';
import reportApi from '@/api/accounting/report';
import styles from './Settings.module.css';

const { getLedgers } = reportApi();
const { getBusinessUnits, actionLedgers, deleteLedger } = accountingApi();

export default function Ledgers() {
  const { enqueueSnackbar } = useSnackbar();

  const client = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const usedClient = activeUser.is_superuser ? activeClient : client;

  const [isModalLedgers, setModalLedgers] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [modalData, setModalData] = useState('');
  const [isModalDelete, setModalDelete] = useState(false);

  const queryResult = useQueries({
    queries: [
      {
        queryKey: ['businessUnits', usedClient],
        queryFn: () => getBusinessUnits({ client: usedClient, max_size: true }),
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
    ],
  });

  const [businessUnits] = queryResult;
  const { data: bUData } = businessUnits;

  const isLoaded = queryResult.every((query) => query.isSuccess);

  const {
    refetch,
    isFetching,
    data: d,
  } = useQuery({
    queryKey: ['ledgers', usedClient],
    enabled: !!usedClient && !!isLoaded,
    queryFn: () => getLedgers({ business_unit__client: usedClient, ordering: 'code', max_size: true }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
  });

  const ledgers = d?.data?.results || [];

  const onEditClick = (data) => {
    setModalData(data);
    setModalType('update');
    setModalLedgers(true);
  };
  const onDeleteClick = (data) => {
    setModalData(data);
    setModalDelete(true);
  };

  const handleActionSubmit = (mode, data) => {
    if (data) {
      actionLedgers(mode, data, modalData?.id)
        .then((res) => {
          if (res.status === 201 || res.data) {
            refetch();
            enqueueSnackbar(`Ledger ${mode}ed` || 'Success', { variant: 'success' });
          }
        })
        .catch((err) => {
          if (err.response.data) {
            const d = err.response.data;
            Object.keys(d).forEach((k) => {
              enqueueSnackbar(d[k][0] || 'Error', { variant: 'Error' });
            });
          }
        });

      setModalData(false);
    }
  };

  const onConfirmDelete = () => {
    if (modalData && modalData.id) {
      deleteLedger(modalData.id)
        .then((res) => {
          if (res.status === 204) {
            refetch();
            enqueueSnackbar(`Ledger deleted` || 'Success', { variant: 'success' });
          }
        })
        .catch((err) => {
          if (err.response.data) {
            const d = err.response.data;
            Object.keys(d).forEach((k) => {
              enqueueSnackbar(d[k][0] || 'Error', { variant: 'Error' });
            });
          }
        });
    }
  };

  const renderAction = useCallback((dt) => {
    return (
      <Box className="flex gap-2">
        <ButtonBase className="bz-btn-tb" onClick={() => onEditClick(dt)}>
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => onDeleteClick(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  }, []);

  const columns = [
    {
      field: 'business_unit',
      headerName: 'Business Unit',
      flex: 1,
      renderCell: (params) => params.row.business_unit?.code,
    },
    {
      field: 'code',
      headerName: 'Code',
      maxWidth: 96,
    },
    {
      field: 'name',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => renderAction(params.row, params),
    },
  ];

  return (
    <>
      <BZHelmet title="Ledgers" description="bz publish ledgers" />
      <Box className={styles.container}>
        <div className="w-full flex p-4 shadow-md">
          <h1 className="text-lg font-bold">Ledgers</h1>
        </div>
        <div className="mt-4">
          <div className="w-full flex px-4 pt-4">
            <button
              type="button"
              className="bz-btn ml-auto"
              onClick={() => {
                setModalType('create');
                setModalLedgers(true);
              }}
            >
              <p className="">Add New Ledger</p>
            </button>
          </div>
          <div className="w-full h-full px-4 pt-4 mb-4">
            <DataGrid autoHeight loading={!isLoaded || isFetching} rows={ledgers} columns={columns} rowsPerPageOptions={[10, 20, 50]} className={`m-auto max-w-5xl w-[100%]`} />
          </div>
        </div>
      </Box>
      <ModalLedgers
        open={isModalLedgers}
        close={() => {
          setModalLedgers(false);
          setModalType(false);
          setModalData(false);
        }}
        action={handleActionSubmit}
        type={modalType}
        data={modalData}
        businessUnits={bUData?.data?.results || []}
      />
      <BasePromptDialog
        open={isModalDelete}
        title="Delete"
        content={modalData?.name}
        action={onConfirmDelete}
        close={() => {
          setModalDelete(false);
          setTimeout(() => {
            setModalData(false);
          }, 300);
        }}
      />
    </>
  );
}

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Divider, Fade, FormControl, FormHelperText, Modal, Stack, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import AccountingSettingsContext from '../../../../pages/Accounting/Settings/AccountingSettingsContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid transparent',
  boxShadow: 24,
  p: 4,
  minWidth: '448px',
};

const schema = yup.object().shape({
  account_type: yup.object().nullable().required('Account Type is a required field'),
  pnl_bs_category: yup.object().nullable().required('PNL Category is a required field'),
  code: yup.string().required('Code is a required field'),
  name: yup.string().required('Name is a required field'),
  description: yup.string().nullable(),
});

export default function PNLCategory({ open, close, action, type, data }) {
  const accountingSettingsContext = useContext(AccountingSettingsContext);

  const [isOpen, setOpen] = useState(false);
  const [mode, setMode] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      account_type: '',
      pnl_bs_category: '',
      code: '',
      name: '',
      description: '',
    },
  });

  const onCloseHandler = () => {
    close();
    setOpen(false);
    reset({
      account_type: '',
      pnl_bs_category: '',
      code: '',
      name: '',
      description: '',
    });
    setTimeout(() => {
      setMode(false);
    }, 300);
  };

  const onSubmit = (dataPnl) => {
    const dataAccount = {
      account_type: dataPnl.account_type?.id,
      pnl_bs_category: dataPnl.pnl_bs_category?.id,
      code: dataPnl.code,
      name: dataPnl.name,
      description: dataPnl.description,
    };
    action(mode, dataAccount);
    onCloseHandler();
  };

  useEffect(() => {
    if (!mode && type) {
      setMode(type);
    }
  }, [mode, type]);

  const setDefaultState = useCallback(() => {
    if (!mode && type) {
      setMode(type);
    }
    if (data) {
      setValue('account_type', data?.account_type?.id && accountingSettingsContext.accountTypes.filter((aT) => aT.id === data.account_type?.id)[0]);
      setValue('pnl_bs_category', data?.pnl_bs_category?.id && accountingSettingsContext.pnlCategories.filter((aS) => aS.id === data.pnl_bs_category?.id)[0]);
      setValue('code', data?.code);
      setValue('name', data?.name);
      setValue('description', data?.description);
    }
  }, [mode, type, data, setValue, accountingSettingsContext.accountTypes, accountingSettingsContext.pnlCategories]);

  useEffect(() => {
    if (!isOpen && open && data) {
      setMode(false);
      setOpen(true);
      setDefaultState();
    }
  }, [open, setDefaultState, isOpen, data]);

  return (
    <Modal open={open} onClose={onCloseHandler} aria-labelledby="modal-account-group" aria-describedby="modal-account-group" className="w-full">
      <Fade in={open}>
        <Box sx={style} className="w-1/4 max-w-lg">
          <Typography variant="h6" component="h2" className="capitalize">
            {mode && mode} Account Code
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div className="flex flex-col space-y-4">
              <FormControl>
                <Controller
                  control={control}
                  name="code"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      label="Code"
                      size="small"
                      error={!!errors.code?.message}
                      helperText={errors.code?.message}
                      placeholder=""
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      label="Name"
                      size="small"
                      error={!!errors.name?.message}
                      helperText={errors.name?.message}
                      placeholder=""
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      label="Description"
                      size="small"
                      error={!!errors.description?.message}
                      helperText={errors.description?.message}
                      placeholder=""
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="account_type"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Autocomplete
                        name="account_type"
                        options={accountingSettingsContext.accountTypes}
                        autoHighlight
                        getOptionLabel={(option) => option && `${option.account_group?.code} - ${option.name}`}
                        isOptionEqualToValue={(option, newValue) => option?.id === newValue?.id}
                        onChange={(event, dataOption) => {
                          onChange(dataOption);
                        }}
                        value={value}
                        error={errors?.account_type?.message || ''}
                        noOptionsText="No options"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Account Type"
                            sx={{
                              '& > div': {
                                padding: '0px 6px 0px 6px !important',
                                outline: 'none',
                              },
                              '& > .MuiInputLabel-root': { top: '-8px' },
                              '& > .Mui-focused': { top: 0 },
                              '& > .MuiFormLabel-filled': { top: 0 },
                            }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                          />
                        )}
                      />
                      <FormHelperText className="text-red-600">{errors.account_type?.message}</FormHelperText>
                    </>
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="pnl_bs_category"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Autocomplete
                        name="pnl_bs_category"
                        options={accountingSettingsContext.pnlCategories}
                        autoHighlight
                        getOptionLabel={(option) => option && `${option.pnl_bs_group?.code} - ${option.name}`}
                        isOptionEqualToValue={(option, newValue) => option?.id === newValue?.id}
                        onChange={(event, dataOption) => {
                          onChange(dataOption);
                        }}
                        value={value}
                        error={errors?.pnl_bs_category?.message || ''}
                        noOptionsText="No options"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="PNL/BS Category"
                            sx={{
                              '& > div': {
                                padding: '0px 6px 0px 6px !important',
                                outline: 'none',
                              },
                              '& > .MuiInputLabel-root': { top: '-8px' },
                              '& > .Mui-focused': { top: 0 },
                              '& > .MuiFormLabel-filled': { top: 0 },
                            }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                          />
                        )}
                      />
                      <FormHelperText className="text-red-600">{errors.pnl_bs_category?.message}</FormHelperText>
                    </>
                  )}
                />
              </FormControl>
            </div>
            <Divider className="mt-4" />
            <Stack direction="row" spacing={2} className="mt-5 float-right">
              <button type="button" className="bz-btn secondary" onClick={onCloseHandler}>
                <p className="text-[#2C6D47]">Cancel</p>
              </button>
              <button type="submit" className="bz-btn">
                <p>
                  {mode && mode === 'create' && 'Add'}
                  {mode && mode === 'update' && 'Update'}
                </p>
              </button>
              {/* <Button variant="outlined" onClick={onCloseHandler}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                {mode && mode === 'create' && 'Add'}
                {mode && mode === 'update' && 'Update'}
              </Button> */}
            </Stack>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
}

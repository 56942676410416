// /* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { DevTool } from '@hookform/devtools';

import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import getDefaultValues from '../../../helper/getDefaultValues';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';

function ArticleCategoryForm({ defaultValues, onFormSubmit }) {
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);

  const [valueTab, setValueTab] = useState(0);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: defaultValues?.name,
      category_languages: getDefaultValues(clientLanguage, defaultValues?.category_languages),
    },
  });

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const onSubmit = (data) => {
    console.log('🚀 ~ file: ArticleCategoryForm.jsx:34 ~ onSubmit ~ data:', data);
    onFormSubmit({ name: data.name, client: clientId, category_languages: data?.category_languages });
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  useEffect(() => {
    clientLanguage?.forEach((lang, index) => setValue(`category_languages[${index}][language]`, lang.language.id));
  }, [clientLanguage, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <InputContainer name="name" control={control} label="Name" value="" />
        <MuiCusotmTabs
          optionsTab={clientLanguage.map((lang) => {
            return {
              name: lang?.language?.name,
              id: lang?.language?.id,
            };
          })}
          value={valueTab}
          onChange={handleChangeTab}
        />
        {clientLanguage?.map((lang, index) => (
          <MuiCustomTabPanel key={lang.id} value={valueTab} index={index}>
            <InputContainer name={`category_languages[${index}][name]`} control={control} label="Name" />
          </MuiCustomTabPanel>
        ))}
        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="bz-btn secondary" variant="outlined">
            Cancel
          </Button>
          <Button className="bz-btn" type="submit" variant="outlined">
            {defaultValues ? 'Update Category' : 'Create Category'}
          </Button>
        </div>
      </div>
      <DevTool control={control} />
    </form>
  );
}

export default ArticleCategoryForm;

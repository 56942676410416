import React from 'react';
import { Circle } from '@mui/icons-material';

import SuperUser from '../pages/Superuser/Superuser';
import SuperUserAdd from '../pages/Superuser/SuperuserAdd';
import SuperUserList from '../pages/Superuser/SuperuserList';
import ClientDetail from '@/pages/Superuser/Client/ClientDetail';
import ClientAdd from '@/pages/Superuser/Client/ClientAdd';
import Client from '@/pages/Superuser/Client/Client';

const superUserRoute = [
  {
    name: 'Superuser',
    element: <Client />,
    icon: <Circle />,
    privilege: [3, 4, 5, 6, 7, 1, 2, 5, 8, 10, 12, 13, 17],
    subscriptions: [1, 2, 5, 8, 10],
    path: '/superuser/clients',
    isSidebar: true,
  },
  {
    name: 'Superuser',
    element: <ClientDetail />,
    icon: <Circle />,
    privilege: [3, 4, 5, 6, 7, 1, 2, 5, 8, 10, 12, 13, 17],
    subscriptions: [1, 2, 5, 8, 10],
    path: '/superuser/clients/:clientId/detail',
    isSidebar: true,
  },
  {
    name: 'Superuser',
    element: <ClientAdd />,
    icon: <Circle />,
    privilege: [99],
    subscriptions: [1, 2, 5],
    path: '/superuser/clients/add',
    isSidebar: true,
  },
  {
    name: 'Superuser',
    element: <SuperUser />,
    icon: <Circle />,
    privilege: [99, 5, 7, 8, 12],
    subscriptions: [1, 2, 5],
    path: '/superadmin',
    isSidebar: true,
  },
  {
    name: 'Superuser Add',
    element: <SuperUserAdd />,
    privilege: [99],
    subscriptions: [1, 2, 5],
    path: '/superadmin/users/add',
  },
  {
    name: 'Superuser List',
    element: <SuperUserList />,
    privilege: [99],
    subscriptions: [1, 2, 5],
    path: '/superadmin/user/:userid',
  },
];

export default superUserRoute;

/* eslint-disable */

import { Edit } from '@mui/icons-material';
import { Box, Button, Divider, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import Swal from 'sweetalert2';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import withReactContent from 'sweetalert2-react-content';

import { useSelector, useDispatch } from 'react-redux';
import BaseModal from '../../components/Modal/Base/BaseModal';
import BZHelmet from '../../utils/BZHelmet';
import BaseDeleteButton from '../../components/Button/BaseDeleteButton';
import useDelete from '../../hooks/useDelete';
import HandleWatchingState from '../../helper/HandleWatchingState';
import useGet from '../../hooks/useGet';
import usePostExtra from '../../hooks/usePostExtra';
import TemplateForm from '../../components/Form/SettingBz/TemplateForm';
import ModalWrapper from '../../components/ui/ModalWrapper';
import { handleModal } from '../../store/slices/modalSlice';
import usePatchOrPutExtra from '../../hooks/usePatchOrPutExtra';
import renderError from '../../utils/Error/renderError';

const MySwal = withReactContent(Swal);

const labelStyle = css`
  font-size: 12px;
  line-height: 18px;
  color: #6d6e6f;
  max-width: 140px;
  overflow: hidden;
  padding-top: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;

function CardContent({ title, children, isFull, styles = '' }) {
  return (
    <div className={['flex space-x-2', styles].join(' ')}>
      {!isFull ? (
        <>
          <div className="w-1/4 pr-2 text-left">
            <span css={labelStyle}>{title}</span>
          </div>
          <div className="w-3/4 flex items-center">{children}</div>
        </>
      ) : (
        <div className="w-full flex items-center">{children}</div>
      )}
    </div>
  );
}

function postFilesTemplateNew(dataTemplate) {
  const data = new FormData();

  if (dataTemplate?.context_zip_file) {
    data.append('context_zip_file', dataTemplate?.context_zip_file);
  }
  if (dataTemplate?.env_preview_file) {
    data.append('env_preview_file', dataTemplate?.env_preview_file);
  }
  if (dataTemplate?.env_publish_file) {
    data.append('env_publish_file', dataTemplate?.env_publish_file);
  }
  if (dataTemplate?.package_json_file) {
    data.append('package_json_file', dataTemplate?.package_json_file);
  }
  if (dataTemplate?.tailwind_config_file) {
    data.append('tailwind_config_file', dataTemplate?.tailwind_config_file);
  }

  return data;
}

function fetchTemplate(dataTemplate) {
  const data = new FormData();

  data.append('site', dataTemplate.site);
  data.append('name', dataTemplate.name);
  data.append('ga_code', dataTemplate.ga_code);
  data.append('use_tailwind', dataTemplate.use_tailwind);

  return data;
}

export default function Template() {
  const dispatch = useDispatch();
  const activeSite = useSelector((state) => state.content.activeSite);
  const activeClient = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);

  const [isInitialize, setIsInitialize] = useState(false);
  const [templates, setTemplates] = useState(false);
  const [files, setFiles] = useState(false);
  const [currSite, setCurrSite] = useState(false);
  const [isAllowFile, setIsAllowFile] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [modalData, setModalData] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [templateFileUpload, setTemplateFileUpload] = useState(null);

  const watchingSite = HandleWatchingState(activeSite);
  const watchingClient = HandleWatchingState(activeClient);

  useGet({
    url: `templates`,
    queryString: { site: activeSite },
    validate: 'templates',
    onSuccess: (res) => {
      setTemplates(res.data.results);
    },
    isEnabled: watchingSite,
  });

  useGet({
    url: `clients/${activeClient}/read_files`,
    validate: 'clientFiles',
    onSuccess: (res) => {
      setFiles(res.data);
    },
    isEnabled: watchingClient,
  });

  const { mutate: postTemplate } = usePostExtra({
    url: `templates`,
    validate: ['clientFiles', 'templates'],
    onSuccess: (res) => {
      if (res.request.status === 201) {
        setIsAllowFile(true);
      } else {
        setIsAllowFile(false);
        MySwal.fire({
          title: `Error`,
          html: `<div style='color:red'>Template Not Uploaded</div>`,
        });
        renderError(res);
      }
    },
  });

  const { mutate: postFilesTemplate } = usePostExtra({
    url: `clients/${activeClient}/upload_files`,
    validate: ['clientFiles', 'templates'],
    onSuccess: (res) => {
      setTemplateFileUpload(null);
      setIsAllowFile(false);
      if (res.request.status === 200) {
        MySwal.fire({
          title: `Success`,
          html: `<div style='color:blue'>Template Uploaded</div>`,
        });
      } else {
        MySwal.fire({
          title: `Error`,
          html: `<div style='color:red'>Template Not Uploaded</div>`,
        });
      }
    },
  });

  const { mutate: patchTemplate } = usePatchOrPutExtra({
    url: `templates/${modalData?.id || modal.modalId}`,
    validate: ['clientFiles', 'templates'],
    onSuccess: (res) => {
      if (res.request.status === 200) {
        setIsAllowFile(true);
      } else {
        setIsAllowFile(false);
        MySwal.fire({
          title: `Error`,
          html: `<div style='color:red'>Template Not Uploaded</div>`,
        });
      }
    },
  });

  const destroyTemplate = useDelete({
    confText: `modals.template_delete.confirmation_text`,
    confTitle: `modals.template_delete.title`,
    success: 'Template deleted',
    validate: ['clientFiles', 'templates'],
  });

  const handleActionSubmit = (mode, data) => {
    setTemplateFileUpload(data.files);
    MySwal.fire({
      title: `Uploading Template `,
      html: `<div>Please Wait</div>`,
      buttons: false,
      didOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: false,
    });
    if (modalData) {
      patchTemplate(fetchTemplate(data));
    } else postTemplate(fetchTemplate(data));
  };

  useEffect(() => {
    if (!isInitialize && activeSite) {
      setIsInitialize(true);
      setCurrSite(activeSite);
    }
  }, [isInitialize, activeSite]);

  useEffect(() => {
    if (!isInitialize && templates && currSite !== activeSite) {
      setIsInitialize(true);
      setCurrSite(activeSite);
    }
  }, [isInitialize, templates, currSite, activeSite]);

  useEffect(() => {
    if (templateFileUpload && isAllowFile) {
      postFilesTemplate(postFilesTemplateNew(templateFileUpload));
    }
  }, [templateFileUpload, isAllowFile, postFilesTemplate]);

  return (
    <div>
      <BZHelmet title="Templates" description="bz publish templates" />
      <div className="w-full flex p-4 shadow-md">
        <h1 className="text-lg font-bold">Template </h1>
      </div>

      <div className="w-full h-full px-6 py-6">
        <div>
          <div className="flex space-x-4 justify-end">
            <Button
              onClick={() => {
                setModalType('create');
                dispatch(handleModal({ componentName: 'template', modalId: '' }));
              }}
              className="bz-btn"
              type="submit"
              variant="filled"
            >
              Add Template
            </Button>
          </div>
          <Divider className="my-6" />

          {!templates ? (
            <div className="flex flex-col items-start w-full pl-3 pr-6 space-y-2">
              <Skeleton width="50%" />
              <Skeleton width="25%" />
              <Skeleton width="25%" />
              <Skeleton width="25%" />
            </div>
          ) : (
            <>
              <div className="">
                <CardContent title={files && files.length > 0 ? 'Files' : 'No Template'} styles="w-1/2 max-w-[580px] mb-4 shadow-lg rounded-md p-4">
                  <div className="w-full flex flex-col space-y-2 rounded-md">
                    {files &&
                      files.length > 0 &&
                      files.map((itm, i) => {
                        const isExist = itm.content && (!Array.isArray(itm.content) || (Array.isArray(itm.content) && itm.content.length > 0));

                        return (
                          <CardContent key={`${itm.id}-${itm.filename}`} title={itm.filename} isFull>
                            <div className="flex gap-x-4 items-center">
                              <span>
                                {itm.filename} - <b>{itm.content && isExist ? 'Exist' : 'Not Exist'}</b>
                              </span>
                              {isExist && i === 0 && (
                                <Button onClick={() => setSelectedFile(JSON.parse(itm.content))} className="text-bz-green text-xs p-0 flex">
                                  Preview
                                </Button>
                              )}
                            </div>
                          </CardContent>
                        );
                      })}
                  </div>
                </CardContent>
              </div>
              <div className="flex flex-wrap gap-4">
                {templates &&
                  templates.length > 0 &&
                  templates.map((item) => {
                    return (
                      <Box key={item.id} className="shadow-md rounded-md min-w-[480px]">
                        <div className="bg-bz-green text-white px-4 py-2 flex items-center justify-between rounded-t-md">
                          <div className="">{item.name}</div>
                          <div className="flex gap-2 items-center justify-end">
                            <BaseDeleteButton variant="icon" onClick={() => destroyTemplate(`templates/${item.id}`)} />
                            <button
                              title="Edit Template"
                              className="bz-btn-tb"
                              size="small"
                              onClick={() => {
                                setModalData(item);
                                setModalType('update');
                                dispatch(handleModal({ componentName: 'template', modalId: item.id }));
                              }}
                            >
                              <Edit fontSize="small" />
                            </button>
                          </div>
                        </div>
                        <div className="p-4">
                          <CardContent title="GA CODE">
                            <span>{item.ga_code}</span>
                          </CardContent>
                          <CardContent title="Use Tailwind">
                            <span>{item.use_tailwind.toString().toUpperCase()}</span>
                          </CardContent>
                        </div>
                      </Box>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </div>

      <ModalWrapper componentName="template" header={modalType !== 'update' ? 'Add Template' : 'Update Template'} maxWidth="sm">
        <TemplateForm
          action={handleActionSubmit}
          type={modalType}
          data={modalType === 'update' ? modalData : ''}
          close={() => {
            setModalData(false);
            dispatch(handleModal({ componentName: '' }));
          }}
        />
      </ModalWrapper>

      <BaseModal open={!!selectedFile} close={() => setSelectedFile(null)} config={{ cancel: 'close' }}>
        <JSONInput locale={locale} viewOnly height="550px" width="100%" placeholder={selectedFile} />
      </BaseModal>
    </div>
  );
}

/* eslint-disable */

import React, { useState, useMemo, createContext, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Button, Checkbox, CircularProgress, TableCell, TableRow } from '@mui/material';
import { Add, ModeEdit, Delete, Edit, Save, Info, AttachFile, Cancel, Close } from '@mui/icons-material';
import CustomTable from '../../components/Table/CustomTable';
import ModalWrapper from '../../components/ui/ModalWrapper';
import JobKeywordForm from '../../components/Form/Job/JobKeywordForm';
import useDelete from '../../hooks/useDelete';
import { handleModal } from '../../store/slices/modalSlice';
import usePost from '../../hooks/usePost';
import useGet from '../../hooks/useGet';
import BaseApplyButton from '../../components/Button/BaseApplyButton';
import BaseDeleteButton from '../../components/Button/BaseDeleteButton';
import BaseSearchButton from '../../components/Button/BaseSearchButton';
import RenderResults from '../../components/RenderResults';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import BaseEditButton from '../../components/Button/BaseEditButton';
import BaseAutocompleteValidation from '../../components/Input/Base/BaseAutocompleteValidation';
import CustomSelect from '../../components/Input/CustomSelect';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'ledger_currencies',
    numeric: false,
    disablePadding: false,
    label: 'currencies',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

const CurrenciesContext = createContext();

export default function CurrenciesTab() {
  const {     
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch, 
  } = useForm();

  const dispatch = useDispatch();

  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const site = useSelector((state) => state.content.activeSite);

  const [dataJob, setDataJob] = useState([]);
  const [currencies, setCurrencies] = useState(false);
  const [searchInput, setSearchInput] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [checkboxSelected, setCheckboxSelected] = useState([]);
  const [editable, setEditable] = useState(true);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [isEdit, setIsEdit] = useState(null);

  useGet({
    url: `accounting/business_units/?client=${clientId}&max_size=true`,
    validate: 'data_currencies',
    onSuccess: (e) => {
      const data = e?.data?.results
      setCount(e?.data?.count);
      setDataJob(data);
      setValue('ledgerCurrencies', data)
    },
    isEnabled: isDataLoad,
  });

  const { data: dataCurrencies, isFetching } = useGet({
    url: `currencies/`,
    validate: 'currencies',
  });

  const { data: widgetDetail } = useGet({
    url: `keywords/${modal?.modalId}`,
    validate: 'widget',
    isEnabled: modal.componentName === 'editKeyword',
  });

  // const value = [editable, setEditable, dataJob, currencies, control, errors];

  const onChangeSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const data = useMemo(() => dataJob, [dataJob]);

  function generateFilterComponent() {
    return (
      <div className="flex space-x-2 items-center">
        <BaseSearchButton onChange={onChangeSearch} setPage={setPage} setIsDataLoad={setIsDataLoad} />

        <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} />
      </div>
    );
  }

  const onDestroyBulkWidget = useDelete({
    url: `keywords`,
    confText: `modals.widget_delete.confirmation_text`,
    confTitle: `modals.widget_delete.title`,
    success: 'Keyword deleted',
    validate: ['keywords'],
    bodyData: { keywords: checkboxSelected },
    isSubUrl: false,
  });

  function ActionCell(props) {
    const { onClick, id, watch } = props;

    const dispatch = useDispatch();

    const setReset = () => {
      return (
        setIsEdit(null),
        reset({ledgerCurrencies: dataJob})
      )
    }

    const { mutate: updateBUCurrencies } = usePatchOrPut({ url: `accounting/business_units/${id}`, validate: ['BusinessUnit'] });

    const onUpdateBUCurrencies = async (data) => {
      const dataUpdate = {
        ledger_currencies: data.ledger_currencies.map((led) => led.id)
      }
      
      setIsEdit(null)

      await updateBUCurrencies(dataUpdate);
    };

    return (
      <div className="flex gap-x-4">
        {
          isEdit === id ? (
            // <Button color='secondary' onClick={() => onUpdateBUCurrencies(watch)}>Save</Button>
            <Save className='cursor-pointer' color="success" onClick={() => onUpdateBUCurrencies(watch)} />
          ) : (
            // <Button onClick={() => setIsEdit(id)} variant="contained">Edit</Button>
            <Edit className='cursor-pointer' onClick={() => setIsEdit(id)}  />
          )
        }
        {isEdit === id && (
          // <Button onClick={setReset} color='secondary'>
          //   Cancel
          // </Button>
          <Cancel className='cursor-pointer' color="action" onClick={setReset} />
        )}
      </div>
    );
  }
  
  function TableCells(props) {
    const { row, labelId, ariaChecked, selected, index, handleClick, key } = props;
  
    const onDestroySingleWidget = useDelete({
      confText: `modals.widget_delete.confirmation_text`,
      confTitle: `modals.widget_delete.title`,
      success: 'Keyword deleted',
      validate: ['keywords'],
    });

    const getRowIndex = watch(`ledgerCurrencies`).findIndex((data) => data.id === row.id);

    return row && (
      <TableRow hover role="checkbox" aria-checked={ariaChecked} tabIndex={-1} key={row.id} selected={selected}>
        <TableCell component="th" id={labelId} scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">
          {/* {row.name} */}
          <BaseAutocompleteValidation
            control={control}
            name={`ledgerCurrencies[${getRowIndex}].ledger_currencies`}
            label="Currencies"
            errors={errors}
            options={dataCurrencies?.data?.results}
            optionLabel="code"
            config={{ classes: '3xl:w-2/4', multiple: true, disabled: isEdit === row?.id ? false : true}}
          />
          {/* <Controller
            name="ledger_currencies"
            control={control}
            render={({ field: { onChange } }) => (
              <CustomSelect
                options={currencies && currencies?.map((item) => ({ label: item.code, value: item.id }))}
                placeholder="Currencies"
                defaultValue={row && (row?.ledger_currencies)?.map((item) => ({ label: item.code, value: item.id }))}
                isMulti
                onChange={(v) => onChange(v?.map((val) => val.value))}
              />
            )}
          /> */}
        </TableCell>
        <TableCell align="left">
          <ActionCell id={row.id} onClick={() => onDestroySingleWidget(`keywords/${row.id}`)} watch={watch(`ledgerCurrencies[${getRowIndex}]`)} />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div className="p-5">
      <div className="">
      </div>
      {/* <CurrenciesContext.Provider value={value}> */}
        {!isFetching ? (
          <RenderResults
            condition={count > 0}
            component={
              <CustomTable
                dataApi={data}
                setIsDataLoad={setIsDataLoad}
                isFetching={!isFetching}
                headCells={headCells}
                ActionCell={ActionCell}
                TableCells={TableCells}
                // useCheckbox
                page={page - 1}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                checkboxSelected={checkboxSelected}
                count={count}
                setCheckboxSelected={setCheckboxSelected}
              />
            }
          />
        ) : (
          <CircularProgress />
        )}
      {/* </CurrenciesContext.Provider> */}
    </div>
  );
}

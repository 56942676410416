import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, ButtonBase, Fade, Popover, TextField } from '@mui/material';
import { ArrowDownward, ArrowDropDown, ArrowForward, Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import InputContainer from '../Input/InputContainer';

const sxTextField = () => {
  return {
    width: 72,
    '& > div': { padding: '0px 6px 0px 6px !important' },
    '& > div > input': { padding: '4px 12px' },
  };
};

const schema = yup.object().shape({});
function RenderItem(props) {
  const { dt, id, index, title, onValChange, onDeleteHeaders, indexChild, onMouseEnter } = props;
  return (
    <div key={id} className="relative flex items-center border rounded-sm">
      <input
        data-id={id}
        style={{ width: 160 }}
        defaultValue={dt}
        onBlur={(e) => onValChange(title, e.target.value, index, indexChild)}
        placeholder={title}
        className={['pl-3 pr-3 py-1 h-6 focus-visible:outline-none focus:border focus:rounded-sm focus:border-black', title === 'headers' && 'pr-4'].join(' ')}
        onFocus={() => {
          onMouseEnter(title === 'headers' ? 'col' : 'row', index, indexChild);
        }}
      />
      {title === 'headers' && index > 0 && (
        <ButtonBase className="h-6 px-1 absolute top-0 right-0 z-10 cursor-pointer shadow-sm" onClick={() => onDeleteHeaders(index)}>
          <Close fontSize="18" style={{ color: '#6d6e6f' }} className="" />
        </ButtonBase>
      )}
    </div>
  );
}

const generateIcons = (param) => {
  const colA = [2, 5, 8];
  const rowA = [4, 5, 6];
  const boxStyle = 'w-2 h-2 border';
  const boxStyleC = 'w-[10px] h-[10px] border bg-gray-100';
  const elements = [];
  const el = param === 'col' ? colA.map(String) : rowA.map(String);

  for (let index = 0; index < 9; index += 1) {
    if (el.includes(String(index + 1))) {
      elements.push(<div className={boxStyleC} />);
    } else {
      elements.push(<div className={boxStyle} />);
    }
  }
  return <div className="grid grid-cols-3 w-fit items-center justify-center place-items-center mr-1">{elements}</div>;
};

export default function WidgetTable({ data: currData, activeTab, idxTab, setTableContent }) {
  const [data, setData] = useState(null);
  const [activeCell, setActiveCell] = useState(null);
  const [tableSize, setTableSize] = useState({
    col: 1,
    row: 1,
  });
  const [anchorAction, setAnchorAction] = useState({
    param: '',
    el: null,
  });

  const { control, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: '',
    reValidateMode: 'onChange',
  });

  const onValueChange = useCallback(
    (param, value, index, indexChild) => {
      const currState = data;
      const newData = currState[param];
      if (param === 'headers') {
        newData[index] = value;
      }
      if (param === 'content') {
        newData[index][indexChild] = value;
      }
      setTableContent((prevState) => {
        const newState = [...prevState];
        newState[idxTab] = currState;
        return newState;
      });
    },
    [data, idxTab, setTableContent]
  );

  const onAddValue = (param) => {
    const stateName = param === 'row' ? 'headers' : 'content';
    const currState = { ...data };
    const newData = currState[stateName];
    if (stateName === 'headers') {
      const newCols = [...currState.content];
      for (let index = 0; index < newCols.length; index += 1) {
        newCols[index][newCols[index].length] = '';
      }
      newData[newData.length] = '';
      currState.content = newCols;
    } else {
      const newCols = [];
      for (let index = 0; index < tableSize.row; index += 1) {
        newCols.push('');
      }
      newData[newData.length] = newCols;
    }
    setTableContent((prevState) => {
      const newState = [...prevState];
      newState[idxTab] = currState;
      return newState;
    });
  };

  const increaseTable = (param) => {
    setTableSize((prevState) => {
      const val = prevState[param] === 1 ? prevState[param] : prevState[param] + 1;
      return {
        ...prevState,
        [param]: val,
      };
    });
    onAddValue(param);
  };

  const onDeleteHeader = (index) => {
    const currState = { ...data };
    const currContent = [...currState.content];
    const currHeader = [...currState.headers];
    currHeader.splice(index, 1);
    currContent.forEach((c, i) => {
      currContent[i].splice(index, 1);
    });
    currState.headers = currHeader;
    currState.content = currContent;
    setTableContent((prevState) => {
      const newState = [...prevState];
      newState[idxTab] = currState;
      return newState;
    });
  };

  const onDeleteRow = useCallback(
    (index) => {
      const currState = { ...data };
      const currContent = [...currState.content];
      currContent.splice(index, 1);

      currState.content = currContent;
      setTableContent((prevState) => {
        const newState = [...prevState];
        newState[idxTab] = currState;
        return newState;
      });
    },
    [data, idxTab, setTableContent]
  );

  const onContentChange = (param, value) => {
    setTableContent((prevState) => {
      const newState = [...prevState];
      newState[idxTab][param] = value;
      return newState;
    });
  };

  const onMouseDown = useCallback((param, index, indexChild) => {
    setActiveCell({ param, pos: [index, indexChild] });
  }, []);

  const onModifyCell = (where) => {
    if (activeCell) {
      const { param, pos } = activeCell;

      const currState = { ...data };
      const currHeaders = [...currState.headers];
      const currContents = [...currState.content];

      if (activeCell)
        switch (where) {
          case 'row-above': {
            const newRows = [];
            for (let index = 0; index < currHeaders.length; index += 1) {
              newRows.push('');
            }
            if (pos[0] === 0) {
              currContents.unshift(newRows);
            } else {
              currContents.splice(pos[0], 0, newRows);
            }
            break;
          }
          case 'row-below': {
            const newRows = [];
            for (let index = 0; index < currHeaders.length; index += 1) {
              newRows.push('');
            }
            if (param === 'col') {
              currContents.unshift(newRows);
            } else {
              currContents.splice(pos[0] + 1, 0, newRows);
            }
            break;
          }
          case 'col-left':
            if (pos[1] === 0) {
              currHeaders.unshift('');
              currContents.forEach((c) => {
                c.unshift('');
              });
            } else {
              currHeaders.splice(pos[1], 0, '');
              currContents.forEach((c) => {
                c.splice(pos[1], 0, '');
              });
            }
            break;
          case 'col-right':
            currHeaders.splice(pos[1] + 1, 0, '');
            currContents.forEach((c) => {
              c.splice(pos[1] + 1, 0, '');
            });
            break;
          default:
            break;
        }
      currState.headers = currHeaders;
      currState.content = currContents;

      setTableContent((prevState) => {
        const newState = [...prevState];
        newState[idxTab] = currState;
        return newState;
      });
      setAnchorAction(null);
    }
  };

  useEffect(() => {
    if (activeTab.current !== idxTab) {
      console.log('set to primary state');
    }
  }, [activeTab, idxTab]);

  useEffect(() => {
    if ((currData && !data && currData) || (currData && data && data !== currData)) {
      setTableSize({
        col: currData.content.length,
        row: currData.headers.length,
      });
      setValue('name', currData.name);
      setValue('description', currData.description);
      setData(currData);
    }
  }, [data, currData, setValue]);

  const renderItem = useCallback((dt, key, index, title, onValChange, onDeleteHeaders, indexChild, onMouseEnter) => {
    return (
      <RenderItem dt={dt} key={key} index={index} title={title} onValChange={onValChange} onDeleteHeaders={onDeleteHeaders} indexChild={indexChild} onMouseEnter={onMouseEnter} />
    );
  }, []);

  const dataContent = useMemo(() => {
    return data?.content?.map((dt, index) => {
      const childItems = [];
      if (dt.length) {
        dt.forEach((el, i) => {
          childItems.push(renderItem(el, `row-child-${Math.random()}`, index, 'content', onValueChange, {}, i, onMouseDown));
        });
      }
      return (
        <div key={`row-content-${index + 1}`} className="relative flex flex-row">
          {childItems.length > 0 && childItems}
          {childItems.length > 0 && dt.length && (
            <ButtonBase className="h-6 px-1 cursor-pointer shadow-sm" onClick={() => onDeleteRow(index)}>
              <Close fontSize="18" style={{ color: '#6d6e6f' }} className="" />
            </ButtonBase>
          )}
        </div>
      );
    });
  }, [data?.content, renderItem, onValueChange, onMouseDown, onDeleteRow]);

  const rowIc = generateIcons('row');
  const colIc = generateIcons('col');

  const openAction = Boolean(anchorAction?.el);
  const idAction = openAction ? 'simple-popover' : undefined;

  return (
    <section className="flex flex-col space-y-4">
      <div className="w-full overflow-hidden flex flex-col">
        {data && (
          <>
            <div className="flex flex-col space-y-4 mb-6">
              <div className="flex flex-col space-y-4">
                <div className="flex items-center space-x-2">
                  <span>Size</span>{' '}
                  <TextField
                    type="number"
                    value={tableSize.row}
                    variant="outlined"
                    size="small"
                    onChange={(e) => increaseTable('row', e.target.value)}
                    sx={() => sxTextField()}
                    InputProps={{ inputProps: { min: 1 } }}
                    disabled
                  />
                  <span>X</span>
                  <TextField
                    type="number"
                    value={tableSize.col}
                    variant="outlined"
                    size="small"
                    onChange={(e) => increaseTable('col', e.target.value)}
                    sx={() => sxTextField()}
                    InputProps={{ inputProps: { min: 1 } }}
                    disabled
                  />
                </div>
                <div className="flex space-x-2">
                  <Button variant="contained" className="h-6 w-fit" startIcon={<ArrowForward />} onClick={() => increaseTable('row')}>
                    Add Row
                  </Button>
                  <Button variant="contained" className="h-6 w-fit" startIcon={<ArrowDownward />} onClick={() => increaseTable('col')}>
                    Add Column
                  </Button>
                </div>
              </div>
            </div>
            <InputContainer
              name="name"
              control={control}
              defaultValue={getValues('name')}
              label="Name"
              config={{
                onChange: (e) => {
                  setValue('name', e.target.value);
                  onContentChange('name', e.target.value);
                },
              }}
            />
            <InputContainer
              name="description"
              control={control}
              defaultValue={getValues('description')}
              label="Description"
              config={{
                onChange: (e) => {
                  setValue('description', e.target.value);
                  onContentChange('description', e.target.value);
                },
              }}
            />
            {activeCell && (
              <Fade in={!!activeCell}>
                <Box className="relative p-2 shadow-md flex flex-row items-center space-x-4 w-fit">
                  <ButtonBase className="flex space-x-2 px-1 justify-between btn-row" onClick={(e) => setAnchorAction({ param: 'row', el: e.currentTarget })}>
                    <div className="w-[60px] h-8 flex items-center justify-center border rounded-sm border-gray-300 hover:bg-gray-100">
                      {rowIc}
                      <ArrowDropDown fontSize="12" style={{ color: '#6d6e6f' }} />
                    </div>
                  </ButtonBase>
                  {anchorAction && (
                    <Popover
                      id={idAction}
                      open={openAction}
                      anchorEl={anchorAction.el}
                      onClose={() => setAnchorAction(null)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Fade in={openAction}>
                        <Box className="relative flex flex-col py-2 rounded-lg bg-white">
                          {anchorAction.param === 'row' && (
                            <>
                              <Button
                                disabled={activeCell?.param === 'col'}
                                className="flex space-x-2 px-3 justify-between hover:bg-gray-100"
                                onClick={() => onModifyCell('row-above')}
                              >
                                <span>Insert row above</span>
                              </Button>
                              <Button className="flex space-x-2 px-3 justify-between hover:bg-gray-100" onClick={() => onModifyCell('row-below')}>
                                <span>Insert row below</span>
                              </Button>
                            </>
                          )}
                          {anchorAction.param === 'col' && (
                            <>
                              <Button size="small" className="flex space-x-2 px-3 py-0 justify-between hover:bg-gray-100" onClick={() => onModifyCell('col-left')}>
                                <span>Insert column left</span>
                              </Button>
                              <Button size="small" className="flex space-x-2 px-3 justify-between hover:bg-gray-100" onClick={() => onModifyCell('col-right')}>
                                <span>Insert column right</span>
                              </Button>
                            </>
                          )}
                          <div style={{ borderBottom: '1px solid rgb(0,0,0, .1)' }} />
                          <Button size="small">Cancel</Button>
                        </Box>
                      </Fade>
                    </Popover>
                  )}
                  <ButtonBase className="flex space-x-2 px-1 justify-between btn-col" onClick={(e) => setAnchorAction({ param: 'col', el: e.currentTarget })}>
                    <div className="w-[60px] h-8 flex items-center justify-center border rounded-sm border-gray-300 hover:bg-slate-100">
                      {colIc}
                      <ArrowDropDown fontSize="12" style={{ color: '#6d6e6f' }} />
                    </div>
                  </ButtonBase>
                </Box>
              </Fade>
            )}
            <div className="tb-section-widget mt-4 overflow-x-auto">
              <div className="w-full flex flex-col">
                <div className="tb-widget-headers w-full flex">
                  {data.headers?.map((dt, index) => {
                    return renderItem(dt, `col-${dt}-${index}`, index, 'headers', onValueChange, onDeleteHeader, 0, onMouseDown);
                  })}
                </div>

                <div className="tb-widget-content flex flex-col">{dataContent}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

/* eslint-disable */
import { Box, Checkbox, CircularProgress, IconButton, Pagination, PaginationItem } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTheme } from '@emotion/react';

function descendingComparator(a, b, orderBy) {
  const valueA = getValue(a, orderBy);
  const valueB = getValue(b, orderBy);

  if (valueB < valueA) return -1;
  if (valueB > valueA) return 1;
  return 0;
}

function getValue(obj, path) {
  const keys = path.split('.');
  let value = obj;

  for (const key of keys) {
    value = value ? value[key] : '-';
  }

  return value;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, useCheckbox } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {useCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              // disabled
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        )}
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell?.isCenter ? "center" : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
            width={headCell.width || ""}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  dataApi,
  headCells,
  tabelAction,
  TableCells,
  useCheckbox,
  checkboxSelected = [],
  setCheckboxSelected,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  count,
  setIsDataLoad,
  isFetching,
  handleRequestSortApi,
  ordeIsAsc,
  orderQuery,
  name,
  disablePerPage = false,
  usePagination = true,
  MultiplePagination = false,
}) {
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState(checkboxSelected);

  React.useEffect(() => {
    setSelected(checkboxSelected);
  }, [checkboxSelected])

  const tableData = dataApi || [];

  const handleRequestSortNonApi = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked === true) {
      const newSelecteds = tableData?.map((n) => n.id);
      setSelected(newSelecteds);
      setCheckboxSelected(newSelecteds);
      return;
    }
    setSelected([]);
    setCheckboxSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1)
      ];
    }
    setSelected(newSelected);
    setCheckboxSelected(newSelected);
  };

  const handleRefetch = () => {
    setIsDataLoad(false);
    setTimeout(() => {
      setIsDataLoad(true);
    }, 100);
  };

  const handleChangePage = (event, newPage) => {
    const watchNextPage = event.target.outerHTML.includes('KeyboardArrowRightIcon');
    let switchMathPage = watchNextPage ? newPage + 1 : newPage === 0 ? 1 : newPage + 1;

    setPage(switchMathPage);
    handleRefetch();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleRefetch();
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const countPage = Math.ceil(count / rowsPerPage);
  const startIndex = page * rowsPerPage + 1;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, count);

  function TablePaginationMultiActions(props) {    
    const theme = useTheme();
    const { count, rowsPerPage, onPageChange } = props;

    const middlePage = Math.floor((count / rowsPerPage) / 2);    
  
    const handleFirstPageButtonClick = (event) => {
      if (page > 0 && page > middlePage) {
        onPageChange(event, middlePage);
      } else {
        onPageChange(event, 0);
      }
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
      if (page < lastPage && page < middlePage) {
        onPageChange(event, middlePage);
      } else {
        onPageChange(event, lastPage);
      }
    };

    const handlePageChange = (event, newPage) => {
      setPage(newPage);
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <Pagination 
          count={countPage} 
          page={page + 1} 
          onChange={handlePageChange} 
          renderItem={(item) => (
            <PaginationItem
              {...item}
              onClick={
                item.type === "first"
                  ? handleFirstPageButtonClick
                  : item.type === "last"
                  ? handleLastPageButtonClick
                  : item.onClick
              }
            />
          )}
          showFirstButton
          showLastButton
        />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      {tabelAction}
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead
              numSelected={selected.length}
              order={ordeIsAsc || order}
              orderBy={orderQuery || orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSortApi || handleRequestSortNonApi}
              rowCount={tableData?.length}
              headCells={headCells}
              useCheckbox={useCheckbox}
            />
            {tableData?.length && tableData?.length > 0 ? (
              <TableBody>
                {stableSort(tableData, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row?.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableCells
                      labelId={labelId}
                      key={row?.id || index}
                      row={row}
                      index={index}
                      ariaChecked={isItemSelected}
                      selected={isItemSelected}
                      handleClick={handleClick}
                      checkboxSelected={checkboxSelected}
                    />
                  );
                })}
              </TableBody>
            ) : (
              <TableBody className="relative h-[10vh]">
                {!isFetching ? (
                  <CircularProgress />
                ) : (
                  <tr className=" absolute inset-0  flex items-center justify-center text-lg font-bold">
                    <td>No Results</td>
                  </tr>
                )}{' '}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {
          usePagination && (
            <TablePagination
              rowsPerPageOptions={!disablePerPage ? [15, 20, 25] : []}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => {
                return (
                  <span>
                    <span className="">
                      {' '}
                      Page {page + 1} of {countPage} -
                    </span>{' '}
                    Showing {startIndex} to {endIndex} of {count} {name || 'rows'}
                  </span>
                );
              }}
              ActionsComponent={MultiplePagination ? TablePaginationMultiActions : undefined}
            />
          )
        }
      </Paper>
    </Box>
  );
}

// /* eslint-disable */

import React, { useState } from 'react';
import { Search, ModeEdit } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { CardActionArea, FormControl, Select, MenuItem, CardActions, CardContent, Card, Button, Pagination, InputBase, Typography } from '@mui/material';
import { fetchWidgetsSetings } from '../../hooks/useStructureData';

import ModalWrapper from '../../components/ui/ModalWrapper';
import { handleModal } from '../../store/slices/modalSlice';
import WidgetForm from '../../components/Form/Widget/WidgetForm';
import BaseMainTitle from '../../components/BaseMainTitle';
import BZHelmet from '../../utils/BZHelmet';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import useGet from '../../hooks/useGet';
import useDelete from '../../hooks/useDelete';

const options = [
  {
    name: 'All',
    value: '',
  },
  {
    name: 'Internal',
    value: true,
  },
  {
    name: 'External',
    value: false,
  },
];

export default function Widget() {
  const [page, setPage] = useState(1);
  const [countSection, setCountSection] = useState(0);
  const [search, setSearch] = useState('');
  const [isInternal, setIsInternal] = useState('');

  const [widgetListData, setWidgetListData] = useState(null);
  const [listDelWidget, setListDelWidget] = useState([]);

  const pageSize = 20;

  const count = Math.ceil(countSection / pageSize);

  const dispatch = useDispatch();

  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const template = useSelector((state) => state.content.activeTemplate);

  const handleChange = (e, p) => {
    setPage(p);
    // AssetsData.jump(p);
  };

  const handleDeleteWidget = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(listDelWidget);
      newSelection.push(parseInt(value, 10));
      setListDelWidget(newSelection);
    } else {
      const newSelection = listDelWidget;
      const newSubs = newSelection.filter((sub) => parseInt(sub, 10) !== parseInt(value, 10));
      setListDelWidget(newSubs);
    }
  };

  const onSearchAsset = (event) => {
    // setIsInternal(true);
    setSearch(event.target.value);
  };

  const getExternalWidget = !isInternal ? clientId : '';
  const getInternalWidget = isInternal === true || isInternal === '' ? template : '';

  useQuery(['widgets', { pageSize, page, search, getExternalWidget, getInternalWidget, isInternal }], fetchWidgetsSetings, {
    onSuccess: (e) => {
      setWidgetListData(e.data.results);
      setCountSection(e.data.count);
    },
  });

  const onChangeInternalType = (event) => {
    const internal = event.target.value;

    setIsInternal(internal);
  };

  const onDestroyBulkWidget = useDelete({
    url: `widgets/delete_widgets`,
    confText: `modals.widget_delete.confirmation_text`,
    confTitle: `modals.widget_delete.title`,
    success: 'Collection deleted',
    validate: ['widgets'],
    bodyData: { widgets: listDelWidget },
    isSubUrl: false,
  });

  const { data: widgetDetail } = useGet({
    url: `widgets/${modal?.modalId}`,
    validate: 'widget',
    isEnabled: Boolean(modal?.modalId),
  });

  const { mutate: onUpdateSectionWidget } = usePatchOrPut({ url: `widgets/${modal.modalId}`, validate: ['widgets', 'widgetsAll'] });

  return (
    <div>
      <BZHelmet title="Widget" description="bz publish widget" />
      <BaseMainTitle title="Widget List" />
      <div className="p-5">
        <div className=" bg-white border p-5 shadow rounded">
          <div className="bg-gray-100 w-full py-5 px-3 rounded">
            <div className="flex items-center">
              <div className="ml-auto flex gap-x-3">
                <button onClick={() => onDestroyBulkWidget()} className="ml-auto bz-btn red">
                  Delete Selected
                </button>
              </div>
            </div>
          </div>
          <div>
            {' '}
            <div className="my-3 flex gap-x-3 bg-white ">
              <InputBase size="small" placeholder="search" endAdornment={<Search color="disabled" />} onChange={onSearchAsset} className="border border-gray-300 rounded px-4" />
              <div className="min-w-[250px] w-3/12 bg-white  h-full">
                <FormControl>
                  <Select sx={{ minWidth: 192, width: '100%', px: 0.2, py: 0.2 }} value={isInternal} onChange={(event) => onChangeInternalType(event)} displayEmpty>
                    <MenuItem disabled value="">
                      <em>Widget Type</em>
                    </MenuItem>
                    {options &&
                      options.length > 0 &&
                      options?.map((op) => (
                        <MenuItem key={op.name} value={op.value}>
                          {op.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-5 mb-20">
              {widgetListData?.map((s) => (
                <div key={s?.id} className="w-full">
                  <Card className="w-full relative  bg-white">
                    <CardActions className={` gap-x-3 flex items-center  ${s?.is_internal ? 'bg-bz-orange' : 'bg-bz-green'}`}>
                      <input type="checkbox" onChange={handleDeleteWidget} name="lang" value={s.id} className=" w-5 h-5" />
                      <Typography gutterBottom className="text-white font-semibold" component="div">
                        {s.name}
                      </Typography>
                      <button onClick={() => dispatch(handleModal({ modalId: s.id, componentName: 'editWidgetSetting' }))} className="absolute right-3" type="button">
                        <ModeEdit className="text-white" />
                      </button>
                    </CardActions>
                    <Link to={`/widget/detail/${s?.id}`}>
                      <CardActions className="ml-8 gap-x-3 flex items-center" />
                      <CardActionArea>
                        <CardContent>
                          <div className="w-full p-3">
                            <img src={`${s.screenshot ?? 'https://admin.bzpublish.com/img/bz-publish.svg'}`} alt="" className="w-full" />
                          </div>
                        </CardContent>
                        <div className="px-4 py-2">Category : {s.category_name} </div>
                      </CardActionArea>
                    </Link>
                    <div className="bg-gray-400 text-white font-bold text-center">{`ID : ${s?.id}`}</div>
                  </Card>
                </div>
              ))}
            </div>
            <div className="flex items-center space-x-4">
              <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChange} />
              <div className="">{`Total ${countSection}`}</div>
            </div>
          </div>
        </div>
        <ModalWrapper componentName="editWidgetSetting" header="Update Widget" maxWidth="sm">
          <WidgetForm defaultValues={widgetDetail?.data} key={widgetDetail?.data.id} isWidget limit onFormSubmit={onUpdateSectionWidget} />
        </ModalWrapper>
      </div>
    </div>
  );
}

/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button, TextField } from '@mui/material/';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import swal from 'sweetalert';
import InputContainerJP from '../../Input/InputContainerJP';
import CustomRadioGroup from '../../Input/CustomRadioGroup';
import SelectAutocomplete from '../../Input/SelectAutocomplete';
import applicationApi from '../../../api/job/application';
import { handleModal } from '../../../store/slices/modalSlice';
import application from '../../../api/job/application';
import Select from '../../Input/SelectItem';

const OffersHeadCells = [
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Delivery Date',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Salary Offer',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Deadline',
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'User',
  },
];

const InterviewsHeadCells = [
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Delivery Date',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Interviewer',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Interviews Date',
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'User',
  },
];

function ApplicationHistory() {
  const currentData = useSelector((state) => state.modal.modalData);

  console.log('currentData ', currentData);

  return (
    <div className="w-full relative">
      {currentData?.map((val, id) => {
        let header = id === 0 ? InterviewsHeadCells : OffersHeadCells;

        return (
          <div key={id} className={id === 0 ? '' : 'mt-8'}>
            <div className="mb-4">
              <p className="font-semibold text-[20px]">{id === 0 ? 'Interview History' : 'Offer History'}</p>
            </div>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <TableHead>
                  <TableRow>
                    {header.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {val.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const timestamp = new Date(row.timestamp).toLocaleString('en-US', { dateStyle: 'long', timeStyle: 'short', hour12: true });
                    // const date_time = row.date_time ? new Date(row.date_time).toLocaleString('en-US', { dateStyle: 'long', timeStyle: 'short', hour12: true }) : null;

                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {timestamp}
                        </TableCell>
                        <TableCell align="left">{row.interviewer ? row.interviewer : row.salary_offer}</TableCell>
                        <TableCell align="left">{row.date_time ? row.date_time : row.deadline}</TableCell>
                        <TableCell align="left">{row.user ?? ''}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      })}
    </div>
  );
}

export default ApplicationHistory;

import { useMemo, useState } from 'react';
import { ButtonBase, Box, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import ModalCostCenter from '../../../../components/Modal/Accounting/Settings/CostCenter';
import BasePromptDialog from '../../../../components/ui/Dialog/BasePromptDialog';

import { toastError } from '@/constants/ToastConfig';

import accountingApi from '../../../../api/accounting/accounting';

const { getCostCenters } = accountingApi();

export default function TabCostCenter() {
  const dispatch = useDispatch();
  const accSettingsFilter = useSelector((state) => state.accountSettings.tableState);
  const cl = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const client = activeUser.is_superuser ? activeClient : cl;

  const [isModalCostCenters, setModalCostCenters] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [modalData, setModalData] = useState('');
  const [isModalDelete, setModalDelete] = useState(false);

  const tableState = useMemo(() => (accSettingsFilter.costCenter ? JSON.parse(accSettingsFilter.costCenter) : {}), [accSettingsFilter]);

  const {
    isFetching,
    isFetched,
    data: cC,
    refetch,
  } = useQuery({
    queryKey: ['cost-center', client],
    enabled: !!client,
    queryFn: () => getCostCenters({ business_unit__client: client, max_size: true, ordering: 'code' }),
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
  });

  const costCenters = useMemo(() => {
    const cc =
      cC?.data?.results.map((a) => {
        return { ...a, department_name: a.department?.name || '-' };
      }) || [];
    return cc;
  }, [cC]);

  const handleActionSubmit = (mode, data) => {
    if (data) {
      const { addCostCenter, updateCostCenter } = accountingApi();
      if (mode === 'create') {
        addCostCenter(data)
          .then((res) => {
            if (res.status === 201 || res.data) {
              refetch();
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
      if (mode === 'update') {
        updateCostCenter(modalData.id, data)
          .then((res) => {
            if (res.status === 201 || res.data) {
              refetch();
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
      setModalData(false);
    }
  };

  const onEditClick = (data) => {
    setModalData(data);
    setModalType('update');
    setModalCostCenters(true);
  };

  const onDeleteClick = (data) => {
    setModalData(data);
    setModalDelete(true);
  };

  const onConfirmDelete = () => {
    const { deleteCostCenter } = accountingApi();

    if (modalData && modalData.id) {
      deleteCostCenter(modalData.id)
        .then((res) => {
          if (res.status === 204) {
            refetch();
          }
        })
        .catch((err) => {
          const res = err.response;
          if (res && res.data?.details?.length)
            toast(res.data.details[0], {
              ...toastError,
            });
        });
    }
  };

  const renderAction = (dt) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase className="bz-btn-tb" onClick={() => onEditClick(dt)}>
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => onDeleteClick(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const columns = useMemo(
    () => [
      {
        field: 'business_unit',
        headerName: 'Business Unit',
        flex: 1,
        renderCell: (params) => params.row.business_unit?.code || '-',
      },
      {
        field: 'code',
        headerName: 'Code',
        flex: 1,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        renderCell: (params) => (
          <Tooltip title={params.row.description}>
            <span className="truncate">{params.row.description}</span>
          </Tooltip>
        ),
      },
      {
        field: 'department_name',
        headerName: 'Department',
        flex: 1,
      },
      {
        field: 'id',
        headerName: 'Action',
        sortable: false,
        headerAlign: 'center',
        renderCell: (params) => renderAction(params.row),
      },
    ],
    [costCenters]
  );

  return (
    <div>
      <div className="w-full flex px-4 pt-4">
        <button
          type="button"
          className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 ml-auto"
          style={{ backgroundColor: '#2C6D47' }}
          onClick={() => {
            setModalType('create');
            setModalCostCenters(true);
          }}
        >
          <p className="text-white">Add New Cost Center</p>
        </button>
        {/* <Button
          variant="contained"
          className="ml-auto"
          onClick={() => {
            setModalType('create');
            setModalCostCenters(true);
          }}
        >
          Add New Account
        </Button> */}
      </div>
      <div className="w-full flex flex-col p-4 justify-center overflow-hidden">
        {isFetched && (
          <Box className="w-full h-full px-4 pt-4 mb-4">
            <DataGrid
              autoHeight
              loading={isFetching}
              rows={costCenters || []}
              columns={columns}
              rowsPerPageOptions={[10, 20, 50]}
              className="m-auto max-w-[1200px] w-[100%]"
              initialState={Object.keys(tableState).length ? { ...tableState, pagination: { ...tableState.pagination } } : { pagination: { page: 0, pageSize: 20 } }}
              onStateChange={(state) => {
                dispatch({ type: 'accountSettings/setTabState', payload: { type: 'costCenter', value: JSON.stringify(state) } });
              }}
            />
          </Box>
        )}
      </div>
      <ModalCostCenter
        open={isModalCostCenters}
        close={() => {
          setModalCostCenters(false);
          setModalType(false);
          setModalData(false);
        }}
        action={handleActionSubmit}
        type={modalType}
        data={modalData}
      />
      <BasePromptDialog
        open={isModalDelete}
        title="Delete"
        content={modalData?.name}
        action={onConfirmDelete}
        close={() => {
          setModalDelete(false);
          setTimeout(() => {
            setModalData(false);
          }, 300);
        }}
      />
    </div>
  );
}

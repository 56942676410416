/* eslint-disable */

import { Add, Clear, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import useGet from '../../../hooks/useGet';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
import DatePickerField from '../../Input/DatePickerField';
import InputContainer from '../../Input/InputContainer';
import ReactSelectSingle from '../../Input/ReactSelectSingle';

const optionEmptype = [
  {
    id: 'Contract',
    name: 'Contract',
  },
  {
    id: 'Full-time',
    name: 'Full-time',
  },
  {
    id: 'Internship',
    name: 'Internship',
  },
  {
    id: 'External Consultant',
    name: 'External Consultant',
  },
];

export default function EmpHistoryFieldArr({
  name,
  defaultValues,
  control,
  register,
  setValue,
  unregister,
  watch,
  label,
  classNameInput,
  rows,
  component,
  disable,
  removeButton,
  errors,
}) {
  const getExp = watch(name) || [];

  const [activeIdx, setActiveIdx] = useState(0);

  const clientId = useSelector((state) => state.client.activeClient);

  const { data: UnitList } = useGet({
    url: `accounting/business_units/?client=${clientId}&max_size=true`,
    validate: 'units',
  });

  const watchUnit = HandleWatchingState(watch(`${name}[${activeIdx}][business_unit]`));
  const { data: DepartementList } = useGet({
    url: `employee-management/departments/?client=${clientId}&max_size=true&business_unit=${watch(`${name}[${activeIdx}][business_unit]`) || ''}`,
    validate: 'departments',
    isEnabled: watchUnit,
  });

  const handleActiveAccord = (idx) => {
    setActiveIdx(idx);
  };
  // const fields = getExp.filter((his) => his !== null || his !== '');
  // console.log('🚀 ~ file: EmpHistoryFieldArr.jsx:58 ~ EmpHistoryFieldArr ~ fields:', fields);

  // const watchfields = HandleWatchingState(getExp);

  // useEffect(() => {
  //   if (watchfields) {
  //     setValue(name, fields);
  //   }
  // }, [watchfields, fields, setValue, name]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
    shouldUnregister: false,
  });

  return (
    <div>
      {' '}
      <ul className="grid grid-cols-2 items-center gap-5">
        {fields.map((history, idx) => (
          <li onClick={() => handleActiveAccord(idx)} className=" " key={`${name}-${idx + 1}`}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMore className="text-white" />} aria-controls="panel1a-content" id="panel1a-header" className="bg-blue-600 text-white mt-3">
                <div>{`Employment history ${idx + 1} `}</div>
              </AccordionSummary>
              <AccordionDetails className="py-5 relative w-full  px-4 py-6 border rounded flex flex-col gap-y-5">
                <div className="w-full grid grid-cols-2 gap-3">
                  <div className="bg-white">
                    <InputContainer name={`${name}[${idx}][position]`} control={control} label="Job" className="!m-0 p-0" />
                  </div>

                  <ReactSelectSingle
                    name={`${name}[${idx}][employment_type]`}
                    control={control}
                    options={getOptionSelectWithKey(optionEmptype)}
                    defaultValue={getDefaultSelectObject(watch(`${name}[${idx}][employment_type]`), getOptionSelectWithKey(optionEmptype))}
                    placeholder="Select Employment Type
"
                  />
                </div>
                <div className="w-full grid grid-cols-2 gap-3 ">
                  <ReactSelectSingle
                    name={`${name}[${idx}][business_unit]`}
                    control={control}
                    options={getOptionSelectWithKey(UnitList?.data?.results)}
                    defaultValue={getDefaultSelectObject(watch(`${name}[${idx}][business_unit]`), getOptionSelectWithKey(UnitList?.data?.results))}
                    placeholder="Select Business Unit"
                    errors={errors?.employment_histories && errors?.employment_histories.length > 0 && errors?.employment_histories[idx]?.business_unit}
                  />

                  <ReactSelectSingle
                    name={`${name}[${idx}][department]`}
                    control={control}
                    options={getOptionSelectWithKey(DepartementList?.data?.results)}
                    defaultValue={getDefaultSelectObject(watch(`${name}[${idx}][department]`), getOptionSelectWithKey(DepartementList?.data?.results))}
                    placeholder="Select Department"
                    errors={errors?.employment_histories && errors?.employment_histories.length > 0 && errors?.employment_histories[idx]?.department}
                  />
                </div>
                <div className="w-full grid grid-cols-2 gap-3 !z-0">
                  <div className="bg-white">
                    <DatePickerField sx={{ marginBottom: '0px' }} control={control} name={`${name}[${idx}][start_date]`} label="Start Date" size="small" fullWidth />
                  </div>
                  <div className="bg-white">
                    <DatePickerField sx={{ marginBottom: '0px' }} control={control} name={`${name}[${idx}][end_date]`} label="End Date" size="small" fullWidth />
                  </div>
                </div>

                <div className="flex justify-end">
                  <button onClick={() => remove(idx)} title="Delete history" className="" type="button">
                    <Clear className="ml-5 rounded border-2  text-red-400" />
                  </button>

                  {fields.length - 1 === idx && (
                    <button
                      className=" w-fit"
                      title="Add history"
                      type="button"
                      onClick={() => append({ position: '', employment_type: '', business_unit: '', department: '', start_date: null, end_date: null })}
                    >
                      <Add className="ml-5 rounded border-2 text-blue-400" />
                    </button>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </li>
        ))}
      </ul>
      {fields.length === 0 && (
        <button
          className=""
          title="Add history"
          type="button"
          onClick={() => append({ position: '', employment_type: '', business_unit: '', department: '', start_date: null, end_date: null })}
        >
          <Add className="ml-5 rounded border-2 text-blue-400" />
        </button>
      )}
    </div>
  );
}

/* eslint-disable */

import React from 'react';
import { Controller } from 'react-hook-form';
import classnames from 'classnames';

export default function FileContatiner({ name, control, label, errors, id, defaultValue, custom, customPaceholder, multiple, watchValue, title }) {
  return custom ? (
    <div className="relative w-full ">
      <label htmlFor={name} className="font-bold pb-3">
        {label}
      </label>
      <div>
        <Controller
          name={name}
          id={id}
          control={control}
          defaultValue={defaultValue}
          errors={errors}
          render={({ field: { onChange } }) => (
            <div className="w-[300px]  relative cursor-pointer">
              <input
                // errors={errors.photo}
                title={title}
                name={name}
                id={id}
                type="file"
                className={classnames('w-full h-full z-[100] opacity-0 absolute', errors && 'border-[#d32f2f]')}
                onChange={(event) => onChange(event.currentTarget.files)}
              />
              <div className=" cursor-pointer">{customPaceholder}</div>
            </div>
          )}
        />
      </div>
      {errors && <span className="capitalize text-xs text-[#d32f2f] text-left flex justify-start pl-6 pt-2">{errors?.message}</span>}
    </div>
  ) : (
    <div className="relative w-full ">
      <div>
        <Controller
          name={name}
          id={id}
          control={control}
          defaultValue={defaultValue}
          errors={errors}
          render={({ field: { onChange } }) => (
            <input
              // errors={errors.photo}
              title={title}
              name={name}
              id={id}
              type="file"
              className={classnames('input-file-tailwind w-full rounded border border-gray-400 ', errors && 'border-[#d32f2f]')}
              onChange={(event) => onChange(Array.from(event.currentTarget.files).map((file) => file))}
              // onChange={(event) => onChange(console.log(event))}
              multiple={multiple}
            />
          )}
        />
        <label htmlFor={name} className="file-label">
          {label}
        </label>
      </div>
      {errors && <span className="capitalize text-xs text-[#d32f2f] text-left flex justify-start pl-6 pt-2">{errors?.message}</span>}
    </div>
  );
}

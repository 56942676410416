import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, ButtonBase, Divider, FormControl, Popover, Stack, TextField } from '@mui/material';
import { Edit, Person as PersonIcon } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import DetailContext from '../../Projects/DetailContext';
import formatterInitialName from '../../../../helper/formatterInitialName';
import BaseAutocompleteValidation from '../../../../components/Input/Base/BaseAutocompleteValidation';
import teamApi from '../../../../api/task/team';
import projectApi from '../../../../api/task/project';

const schema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  notes: yup.string().nullable(),
  team: yup.object().nullable().required('Team is a required field'),
  member: yup.object().nullable(),
});

const generateValues = (val) => {
  const isWhiteSpace = val.indexOf(' ') >= 0;
  const valTime = isWhiteSpace ? val.substring(val.lastIndexOf(' ') + 1) : val;
  const valDay = isWhiteSpace ? Number(val.split(' ')[0]) : 0;
  let valMonth;
  let valDays;
  let periods = 'time';
  valMonth = 0;
  valDays = valDay;
  if (valDay && valDay > 30) {
    const valDiv = valDay / 30;
    valMonth = Math.floor(valDiv);
    valDays = +valDay - valMonth * 30;
    periods = valMonth ? 'month' : 'day';
  } else if (valDay && valDay < 30) {
    periods = 'day';
  }
  return {
    period: periods,
    currValues: {
      m: valMonth.toString(),
      d: valDays.toString(),
      t: valTime.substring(0, valTime.indexOf(':') + 3),
    },
  };
};

export default function Overview() {
  const myContextDetail = useContext(DetailContext);
  const { projects } = myContextDetail;
  const { formatter } = formatterInitialName();
  const acvUser = useSelector((state) => state.auth?.user);
  const [team, setTeam] = useState(false);
  const [anchorUser, setAnchorUser] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [activeUserRole, setActiveUserRole] = useState(null);
  const [isOwner, setIsOwner] = useState();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      project_id: '',
      notes: '',
      team: '',
      member: '',
      time: '00:00',
    },
  });

  const getProjectTeamDetail = useCallback(() => {
    const { getTeamDetail } = teamApi();
    getTeamDetail(projects.team?.id)
      .then((res) => {
        if (res.status === 200 || res.data) {
          setTeam(res.data);
          setActiveUserRole(res.data?.team_users?.find((data) => data.user.id === acvUser.id))
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [projects.team?.id]);

  useEffect(() => {
    if (projects.team) {
      getProjectTeamDetail();
    }
  }, [projects.team, getProjectTeamDetail]);

  useEffect(() => {
    if (activeUserRole !== null) {
      setIsOwner(activeUserRole?.role?.name === 'Owner')
    }
  }, [activeUserRole]);

  const handleUnAssignTeamUser = () => {
    const { deleteTeamUsers } = teamApi();

    if (activeUser) {
      const teamUser = [];
      teamUser.push(activeUser);
      deleteTeamUsers({ team_user_ids: teamUser })
        .then(() => {
          getProjectTeamDetail();
          setActiveUser(false);
          setAnchorUser(false);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const onSubmit = (data) => {
    const { name, project_id, member, notes, team: newTeam, time: newTime } = data;
    const newData = {
      member: member?.id,
      name,
      project_id,
      notes,
      team: newTeam?.id,
      assignee_log_limit: `${newTime}:00`,
    };
    const { updateTaskProject } = projectApi();
    updateTaskProject(projects.id, newData)
      .then((res) => {
        if (res.status === 200 || res.data) {
          myContextDetail.dispatchProject('GET');
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
    setIsEditMode(false);
  };

  const openUser = Boolean(anchorUser);
  const idUser = openUser ? 'simple-popover' : undefined;

  useEffect(() => {
    if (!isInitialized && projects) {
      setValue('name', projects.name);
      setValue('project_id', projects.project_id);
      setValue('notes', projects.notes);
      setValue('team', projects.team);
      setValue('member', projects.member);
      setValue('time', generateValues(projects.assignee_log_limit).currValues.t || '00:00');
      setIsInitialized(true);
    }
  }, [isInitialized, projects, setValue]);

  return (
    <div className="w-full h-full px-6 py-6 space-y-3">
      <p className="text-lg font-bold mb-4">Project Information</p>
      <div className="flex flex-row">
        <p className="w-20 mr-2">Name</p>
        <p>{projects?.name}</p>
      </div>
      <div className="flex flex-row">
        <p className="w-20 mr-2">Note</p>
        <p>{projects?.notes}</p>
      </div>
      <div className="flex flex-row">
        <p className="w-20 mr-2">Owner</p>
        <p>{projects?.owner?.name}</p>
      </div>
      <div>
        <p className="text-lg font-bold my-3">Project Team</p>
        <div className="flex flex-wrap">
          <div className="flex space-x-2 mr-4 mt-2 items-center px-3 py-2 border border-gray-300 rounded-xl">
            <div className="w-10 h-10 border border-dashed rounded-full text-xs flex justify-center items-center mr-1">
              <PersonIcon color="disabled" />
            </div>
            <div className="flex flex-col">
              <span className="text-gray-500">Add member</span>
            </div>
          </div>
          {team &&
            team.team_users &&
            team.team_users.length > 0 &&
            team.team_users.map((tm) => (
              <ButtonBase
                key={tm.user.name}
                className="flex mr-4 mt-2 items-center px-3 py-2 border-gray-300 rounded-xl cursor-pointer"
                onClick={(event) => {
                  setActiveUser(tm.id);
                  setAnchorUser(event.currentTarget);
                }}
                sx={{ border: 1 }}
              >
                <div className="w-10 h-10 border rounded-full text-xs flex justify-center items-center mr-1">
                  <span>{formatter(tm.user.name)}</span>
                </div>
                <div className="flex flex-col">
                  <span>{tm.user.name}</span>
                  <span className="text-xs text-gray-300">{tm.role.name}</span>
                </div>
              </ButtonBase>
            ))}
          <Popover
            id={idUser}
            open={openUser}
            anchorEl={anchorUser}
            onClose={() => {
              setActiveUser(false);
              setAnchorUser(false);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{ sx: { boxShadow: 1 } }}
          >
            <Box className="px-4 py-2 flex flex-col space-y-2 border border-gray-300 rounded-md shadow-lg">
              <ButtonBase
                onClick={() => {
                  handleUnAssignTeamUser(activeUser);
                }}
              >
                Delete user
              </ButtonBase>
            </Box>
          </Popover>
        </div>
      </div>
      <div>
        <div className="flex space-x-4 items-center mb-4">
          <p className="text-lg font-bold">Project Settings</p>
          {isOwner === true && <Edit className="w-6 h-6 border rounded-full p-1 mt-1" fontSize="18" onClick={() => setIsEditMode(true)} />}
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-4 w-96 flex flex-col space-y-4">
          <FormControl>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  label="Name"
                  size="small"
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                  placeholder=""
                  disabled={!isEditMode}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              control={control}
              name="project_id"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  label="Code"
                  size="small"
                  error={!!errors.project_id?.message}
                  helperText={errors.project_id?.message}
                  placeholder=""
                  disabled={!isEditMode}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              control={control}
              name="notes"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  label="Notes"
                  size="small"
                  error={!!errors.notes?.message}
                  helperText={errors.notes?.message}
                  placeholder=""
                  disabled={!isEditMode}
                />
              )}
            />
          </FormControl>
          <BaseAutocompleteValidation
            control={control}
            name="team"
            label="Team"
            errors={errors}
            options={myContextDetail.teamList || []}
            optionLabel="name"
            config={{ classes: '', multiple: false, disabled: !isEditMode }}
          />
          <BaseAutocompleteValidation
            control={control}
            name="member"
            label="Customer"
            errors={errors}
            options={myContextDetail.customerList || []}
            optionLabel="name"
            config={{ classes: '', multiple: false, disabled: !isEditMode }}
          />
          <FormControl>
            <Controller
              control={control}
              name="time"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  label="Time limit"
                  size="small"
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                  error={!!errors.time?.message}
                  helperText={errors.time?.message}
                  placeholder=""
                  disabled={!isEditMode}
                />
              )}
            />
          </FormControl>

          <Divider className="mt-4" />
          {isEditMode && (
            <Stack direction="row" spacing={2} className="w-full mt-5 justify-end">
              <Button variant="outlined" onClick={() => setIsEditMode(false)}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Update
              </Button>
            </Stack>
          )}
        </form>
      </div>
    </div>
  );
}

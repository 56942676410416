import { Delete } from '@mui/icons-material';
import { Autocomplete, Box, Button, ButtonBase, FormControl, TextField, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { utils, writeFileXLSX } from 'xlsx';

import projectApi from '@/api/task/project';
import userApi from '@/api/user';
import BZButton from '@/components/Button/BZButton';
import MuiSelectSingleItem from '@/components/Input/MuiSelectSingleItem';
import { toastSuccess } from '@/constants/ToastConfig';
import HandleWatchingState from '@/helper/HandleWatchingState';
import request from '@/helper/axiosUtil';
import useGet from '@/hooks/useGet';
import usePostExtra from '@/hooks/usePostExtra';
import { getSelectOptionWithKey } from '@/lib/OptionsInputHelper';
import BZHelmet from '@/utils/BZHelmet';
import renderError from '@/utils/Error/renderError';
import ForceRerender from '@/utils/ForceRerender';
import getTimeZone from '@/utils/Task/getTimeZone';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import moment from 'moment';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as yup from 'yup';
import showSwalErrorr from '@/utils/swalError';
import DetailContext from '../../Projects/DetailContext';

const MySwal = withReactContent(Swal);

export default function Report() {
  const { projectId } = useParams();
  const myContextDetail = useContext(DetailContext);
  const { projects } = myContextDetail;
  console.log('🚀 ~ Report ~ projects:', projects);
  const location = useLocation();

  const [topicsData, setTopicsData] = useState([]);

  const { data: projectData } = useGet({
    url: `project-management/projects/${projectId}`,
    validate: `projek-${projectId}-${location.key}`,

    onSuccess: (res) => {
      const topicSections = res.data.sections;
      let getTopict = [];
      topicSections.map((tp) => getTopict.push(tp.topics));

      setTopicsData(getTopict.flat());
    },
  });

  const [teamsData, setTeamsData] = useState([]);
  useGet({
    url: `project-management/teams/${projectData?.data?.team?.id}`,
    validate: `teams-${projectData?.data?.team?.id}-${location.key}`,
    isEnabled: Boolean(projectData?.data?.team?.id),
    onSuccess: (res) => {
      const topicSections = res.data.team_users;
      let getTopict = [];
      topicSections.map((tp) => getTopict.push(tp.user));

      setTeamsData(getTopict.flat());
    },
  });

  const activeClient = useSelector((state) => state.client.activeClient);

  const [defaultData, setDefaultData] = useState(null);
  const [reportData, setReportData] = useState(null);

  const [users, setUsers] = useState([]);
  const [activeTask, setActiveTask] = useState(false);
  const [incrementQuery, setIncrementQuery] = useState(1);
  const [taskSelector, setTaskSelector] = useState(false);
  const [filter, setFilter] = useState({
    dateFrom: new Date(Date.now() - 604800000),
    dateTo: new Date(),
    customer: '',
    project: '',
    user: '',
  });
  const [isInitialize, setIsInitialize] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteIds, setDeleteIds] = useState([]);
  const [editTabel, setEditTabel] = useState(false);

  function formatDurationTime(duration) {
    if (duration && duration.includes('.')) {
      const parts = duration.split(':');
      if (parts.length === 3 && duration.includes('.')) {
        const seconds = parts[2].split('.')[0];
        return `${parts[0]}:${parts[1]}:${seconds}`;
      } else return duration;
    } else return duration;
  }

  const generateReport = (data) => {
    return data.map((dt, i) => {
      const totalTime = formatDurationTime(dt.total_time?.duration);
      const timeIn = dt.time_in ? new Date(dt.time_in) : new Date();
      const timeStart = dt.time_in ? new Date(dt.time_in) : new Date();
      const timeStop = dt.time_out ? new Date(dt.time_out) : new Date();
      return {
        id: i + 1,
        idx: i + 1,
        LogId: dt.id,
        IsEdit: false,
        IsCreate: false,
        Customer: dt.topic?.project?.customer,
        Project: dt.topic?.project?.name,
        Task: dt.topic?.id,
        topic: dt.topic?.id,
        Type: dt.topic?.topic_type,
        Title: dt.topic?.name,
        User: dt.assignee?.name,
        assignee: dt.assignee?.id,
        Status: dt.section,
        Date: format(timeIn, 'yyyy-MM-dd'),
        Start: format(timeStart, 'kk:mm:ss'),
        time_in: timeIn,
        time_out: timeStop,
        topicActiveIndex: false,
        Stop: format(timeStop, 'kk:mm:ss'),
        Timezone: `${dt.timezone?.name}(${dt.timezone?.utc_offset})`,
        Time: totalTime,
        Minutes: dt.total_time?.minutes,
        BillableTime: dt?.billable_time,
      };
    });
  };

  const timeNew = new Date();

  const currTimezone = getTimeZone();

  const dataNew = () => ({
    idx: 0,
    id: '',
    IsEdit: false,
    IsCreate: true,
    Customer: projectData?.data?.member?.name,
    Project: projectData?.data?.name,
    Task: '',
    Type: '',
    Title: '',
    topic: '',
    User: '',
    assignee: '',
    Status: '',
    Date: format(timeNew, 'yyyy-MM-dd'),
    Start: format(timeNew, 'yyyy-MM-ddTHH:mm'),
    // time_in: format(timeNew, 'yyyy-MM-ddTHH:mm'),
    Stop: format(timeNew, 'yyyy-MM-ddTHH:mm'),
    Timezone: `${currTimezone?.name}(${currTimezone?.offset})`,
    Time: 0,
    Minutes: 0,
    BillableTime: null,
  });

  const formSchema = {
    topic: yup.string().required('Topic is required'),
    assignee: yup.string().required('User is required'),
  };

  const schema = yup.object().shape({
    report: yup.array().of(yup.object().shape(formSchema)),
  });

  // use forms
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    getValues,
    register,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  // appen field prices
  const { append, fields, remove, insert } = useFieldArray({
    control,
    name: 'report',
  });

  const exportExcel = () => {
    const removeUnusedObject = reportData.map((obj) => {
      // Create a new object without the 'id' property
      const { id, idx, LogId, IsCreate, IsEdit, time_in, time_out, topic, topicActiveIndex, assignee, ...newObj } = obj;
      return newObj;
    });

    const finalReport = removeUnusedObject;
    const ws = utils.json_to_sheet(finalReport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    const dtF = format(new Date(filter.dateFrom), 'ddMMM');
    const dtT = format(new Date(filter.dateTo), 'ddMMM');
    writeFileXLSX(wb, `report-${dtF}-${dtT}.xlsx`);
  };

  const getReport = useCallback((data) => {
    const { getReportTask } = projectApi();
    getReportTask(data)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setReportData(generateReport(res.data));
          setDefaultData(res.data);
          setValue('report', generateReport(res.data));
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  const handleGenerateReport = () => {
    const dtFilter = { timezone: getTimeZone().name };
    const { dateFrom, dateTo, customer, project, user } = filter;
    if (dateFrom) {
      dtFilter.datetime_from = dateFrom;
    }
    if (dateTo) {
      dtFilter.datetime_to = dateTo;
    }
    dtFilter.customer = projects?.member?.id || projectData?.data?.member?.id;
    dtFilter.project = projects?.id || projectData?.data?.id;
    if (user) {
      dtFilter.user = user.id;
    }
    getReport(dtFilter);
    setTaskSelector(false);
    setDeleteIds([]);
  };

  const getUserList = useCallback(
    (params = { client: activeClient, max_size: true }) => {
      const { getUsers } = userApi();
      return new Promise((resolve, reject) => {
        getUsers(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [activeClient]
  );

  const getAllData = useCallback(async () => {
    const [a] = await Promise.all([getUserList()]);
    if (a) {
      setUsers(a);
      setIsInitialize(true);
    }
  }, [getUserList]);

  useEffect(() => {
    if (!isInitialize) {
      getAllData();
    }
  }, [isInitialize, getAllData]);

  const onChangeFilter = (param, value) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        [param]: value,
      };
    });
  };

  const currentTopicVal = getValues('report')?.filter((r) => r?.topicActiveIndex);
  const currentTopicIdx = currentTopicVal && currentTopicVal.length > 0 && currentTopicVal[0]?.idx;
  const topicId = watch(`report[${currentTopicIdx}].topic`);

  const watchingQuery = HandleWatchingState(JSON.stringify([topicId, incrementQuery]));
  const watchingLength = HandleWatchingState(getValues('report') && getValues('report').length > 0 && getValues('report').length);

  useGet({
    url: `project-management/topics/${topicId}`,
    validate: `projek-${topicId}-${incrementQuery}`,
    isEnabled: Boolean(topicId) && watchingQuery,
    onSuccess: (res) => {
      const topicSections = res.data.section.name;
      setValue(`report[${currentTopicIdx}].Status`, topicSections);
      // setValue(`report[${currentTopicIdx}].Task`, res.data.id);
      setValue(`report[${currentTopicIdx}].Title`, res.data.name);
    },
  });

  const { mutate: postLog } = usePostExtra({
    url: `project-management/assignee_logs/process_bulk`,
    validate: ['report'],
    onSuccess: (res) => {
      console.log('🚀 ~ Report ~ res:', res);
      setEditTabel(false);
      if (res.request.status === 200) {
        toast('Success', {
          ...toastSuccess,
        });
        handleGenerateReport();
      } else {
        handleGenerateReport();
        showSwalErrorr({
          text: res?.message,
        });
      }
    },
  });

  const onSubmit = (data) => {
    postLog(
      getValues('report').map((key) => ({
        id: key.LogId,
        time_in: new Date(key.time_in),
        time_out: new Date(key.time_out),
        topic: key.topic,
        assignee: key.assignee,
        billable_time: key.BillableTime,
        timezone: { name: currTimezone.name, utc_offset: currTimezone.offset },
      }))
    );
  };

  const deleteLogs = useMutation(
    async (keys) => {
      try {
        const results = await Promise.allSettled(
          keys.map((key) =>
            request({
              url: `/project-management/assignee_logs/${key}/`,
              method: 'delete',
            })
          )
        );
        toast('Success', {
          ...toastSuccess,
        });
        handleGenerateReport();
        return results;
      } catch (error) {
        // Depending on your setup, you might want to throw the error, return it, or handle it differently
        throw error;
      }
    },
    {
      onSuccess: (res) => {
        console.log('🚀 ~ Report ~ res:', res);
      },
    }
  );

  const onUpdateLogs = () => {
    onSubmit();
    if (deleteIds && deleteIds.length > 0) {
      deleteLogs.mutateAsync(deleteIds);
    }
  };

  const columns = [
    {
      field: 'Customer',
      headerName: 'Customer',
      headerAlign: 'left',
      align: 'left',
      // minWidth: 144,
      renderCell: (params) => (
        <Tooltip title={params.row.Customer}>
          <span className="truncate">{params.row.Customer || '-'}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Project',
      headerName: 'Project',
      headerAlign: 'left',
      // minWidth: 188,
    },
    {
      field: 'Task',
      headerName: 'Task',
      minWidth: 60,
      renderCell: (params) => (
        <Tooltip title={params.row.Task}>
          <span className="truncate">{params.row.topic || params.row.Task}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Title',
      // flex: 1,
      headerName: 'Title',
      // minWidth: 'max-content',

      minWidth: 200,
      renderCell: (params) => {
        return editTabel ? (
          // <Tooltip title={params.row.Title}>
          <FormControl className="w-full">
            <MuiSelectSingleItem
              name={`report[${params.row.idx}].topic`}
              id={`report[${params.row.idx}].topic`}
              key={`report[${params.row.idx}].topic`}
              label="Select Topic"
              control={control}
              options={getSelectOptionWithKey(topicsData)}
              defaultValue={watch(`report[${params.row.idx}].topic`)}
              errors={errors?.report && errors?.report[params.row.idx]?.topic?.message}
              onFocus={() => {
                setIncrementQuery(incrementQuery + 1);
                getValues('report').forEach((rp, index) => {
                  if (params.row.idx === index) {
                    setValue(`report[${params.row.idx}].topicActiveIndex`, true);
                  } else setValue(`report[${index}].topicActiveIndex`, false);
                });
                setValue('report', getValues('report'));
              }}
            />
          </FormControl>
        ) : (
          // </Tooltip>
          <Tooltip title={params.row.Title}>
            <span className="truncate">{params.row.Title}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'User',
      // flex: 1,
      headerName: 'User',
      minWidth: 200,
      renderCell: (params) => {
        return editTabel ? (
          // <Tooltip title={params.row.assignee || params.row.User}>
          <FormControl className="w-full">
            <MuiSelectSingleItem
              name={`report[${params.row.idx}].assignee`}
              id={`report[${params.row.idx}].assignee`}
              label="Select User"
              control={control}
              options={getSelectOptionWithKey(teamsData)}
              defaultValue={params.row.assignee}
              errors={errors?.report && errors?.report[params.row.idx]?.assignee?.message}
              onFocus={(e, val) => {
                setValue(`report[${params.row.idx}].User`, e.target?.textContent);
              }}
            />
          </FormControl>
        ) : (
          // </Tooltip>
          <Tooltip title={params.row.User}>
            <span className="truncate">{params.row.User}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Status',
      headerName: 'Status',
      // minWidth: 112,
      renderCell: (params) => params.row.Status,
    },
    {
      field: 'Date',
      headerName: 'Date',
      // minWidth: 112,
      renderCell: (params) => params.row.Date,
    },
    {
      field: 'Start',
      headerName: 'Start Time',
      // minWidth: 220,
      minWidth: 220,
      renderCell: (params) =>
        editTabel ? (
          <Controller
            control={control}
            name={`report[${params.row.idx}].time_in`}
            defaultValue={moment(params.row.time_in || new Date()).format('YYYY-MM-DDTHH:mm')}
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                variant="standard"
                size="small"
                id="time_in"
                type="datetime-local"
                value={moment(value).format('YYYY-MM-DDTHH:mm')}
                onChange={(date) => {
                  onChange(date);
                  const start = moment(date.target.value);
                  const end = moment(params.row.time_out);
                  const duration = moment.duration(end.diff(start));
                  const days = duration.days();
                  const hours = duration.hours();
                  const minutes = duration.minutes();
                  const seconds = duration.seconds();

                  // Formatting hours, minutes, and seconds to ensure two digits
                  const formattedHours = hours.toString().padStart(2, '0');
                  const formattedMinutes = minutes.toString().padStart(2, '0');
                  const formattedSeconds = seconds.toString().padStart(2, '0');

                  // Constructing the final output
                  const formattedDuration = `${days} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
                  setValue(`report[${params.row.idx}].Time`, formattedDuration);
                  setValue(`report[${params.row.idx}].Minutes`, Math.ceil(duration.asMinutes()));
                  setValue(`report`, getValues('report'));
                }}
                sx={{ width: '100%', '& > .MuiInputBase-root': { fontSize: '14px' } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        ) : (
          <Tooltip title={params.row.Start}>
            <span className="truncate">{params.row.Start}</span>
          </Tooltip>
        ),
    },
    {
      field: 'Stop',
      headerName: 'Stop Time',
      minWidth: 220,
      renderCell: (params) => {
        return editTabel ? (
          <ForceRerender
            condition={watchingLength}
            component={
              <Controller
                control={control}
                name={`report[${params.row.idx}].time_out`}
                defaultValue={moment(watch(`report[${params.row.idx}].time_out`) || new Date()).format('YYYY-MM-DDTHH:mm')}
                render={({ field: { onChange, value, ref } }) => (
                  <TextField
                    variant="standard"
                    size="small"
                    error={moment(params.row.time_out).isBefore(moment(params.row.time_in))}
                    helperText={moment(params.row.time_out).isBefore(moment(params.row.time_in)) ? 'Time out should last longer than time in.' : ''}
                    id="time_out"
                    type="datetime-local"
                    value={moment(value).format('YYYY-MM-DDTHH:mm')}
                    onChange={(date) => {
                      onChange(date);
                      const start = moment(params.row.time_in);
                      let newValue = date.target.value;
                      // Ensure newValue is not before minDateTime
                      if (moment(newValue).isBefore(moment(start))) {
                        newValue = start;
                      }
                      const end = moment(newValue);
                      const duration = moment.duration(end.diff(start));
                      const days = duration.days();
                      const hours = duration.hours();
                      const minutes = duration.minutes();
                      const seconds = duration.seconds();

                      // Formatting hours, minutes, and seconds to ensure two digits
                      const formattedHours = hours.toString().padStart(2, '0');
                      const formattedMinutes = minutes.toString().padStart(2, '0');
                      const formattedSeconds = seconds.toString().padStart(2, '0');

                      // Constructing the final output
                      const formattedDuration = `${days} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
                      setValue(`report[${params.row.idx}].Time`, formattedDuration);
                      setValue(`report[${params.row.idx}].Minutes`, Math.ceil(duration.asMinutes()));
                      setValue(`report`, getValues('report'));
                    }}
                    sx={{ width: '100%', '& > .MuiInputBase-root': { fontSize: '14px' } }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            }
          />
        ) : (
          <Tooltip title={params.row.Stop}>
            <span className="truncate">{params.row.Stop}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Timezone',
      headerName: 'Time Zone',
      minWidth: 156,
      renderCell: (params) => params.row.Timezone,
    },
    {
      field: 'Time',
      headerName: 'Time',
      headerAlign: 'left',
      sortable: false,
      minWidth: 60,
      renderCell: (params) => params.row.Time,
    },
    {
      field: 'Minutes',
      headerName: 'Minutes',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      minWidth: 48,
      renderCell: (params) => params.row.Minutes,
    },
    {
      field: 'BillableTime',
      headerName: 'Billable Time',
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      minWidth: 48,
      renderCell: (params) => {
        return editTabel ? (
          <div className="">
            <Controller
              control={control}
              name={`report[${params.row.idx}].BillableTime`}
              // defaultValue={`report[${params.row.idx}].BillableTime`}
              render={({ field: { onChange, value, ref } }) => (
                <TextField
                  variant="standard"
                  size="small"
                  id="BillableTime"
                  type="number"
                  value={value}
                  onChange={onChange}
                  // helperText={formatDuration(watch(`report[${params.row.idx}].BillableTime`))}
                  sx={{ width: '100%', '& > .MuiInputBase-root': { fontSize: '14px' } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {/* <p className="text-xs">{formatDuration(params.row.BillableTime || 0)}</p> */}
          </div>
        ) : (
          <div>
            <p className="text-center"> {params.row.BillableTime || 0}</p>
            {/* <p className="text-xs">{formatDuration(params.row.BillableTime || 0)}</p> */}
          </div>
        );
      },
    },
    {
      field: '',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      minWidth: 32,
      renderCell: (params) => (
        <div className="space-x-2">
          {/* {params.row.Task && (
            <Link to={`/task/project/${projectId}/${params.row.Task}`}>
              <ButtonBase className="p-2 rounded-md border border-solid border-gray-300">
                <Visibility fontSize="14" />
              </ButtonBase>
            </Link>
          )} */}
          <ButtonBase
            className="p-2 rounded-md border border-solid border-gray-300"
            onClick={(e) => {
              MySwal.fire({
                title: 'Delete',
                html: 'Delete',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Delete`,
                denyButtonText: `Don't save`,
                confirmButtonColor: '#C62828',
                cancelButtonColor: '#8bc34a',
              }).then((willDelete) => {
                if (willDelete.isConfirmed) {
                  remove(params.row.idx);
                  if (params.row.LogId) {
                    let collectIds = deleteIds;
                    collectIds.push(params.row.LogId);
                    setDeleteIds(collectIds);
                  }
                } else {
                  ('');
                }
              });
            }}
          >
            <Delete fontSize="14" />
          </ButtonBase>
        </div>
      ),
    },
  ];

  return (
    <>
      <BZHelmet title="Task Management Report" description="bz publish task management report" />
      <div className="w-full h-full">
        <div className="w-full p-8 relative flex flex-col space-y-5">
          <div className="w-full flex space-x-4"></div>

          <div className="flex space-x-4">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date From"
                closeOnSelect
                value={filter.dateFrom}
                onChange={(newValue) => {
                  onChangeFilter('dateFrom', newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date to"
                closeOnSelect
                value={filter.dateTo}
                onChange={(newValue) => {
                  onChangeFilter('dateTo', newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
            <div className="w-1/4">
              <Autocomplete
                multiple={false}
                name="user"
                options={users}
                autoHighlight
                getOptionLabel={(option) => option && `${option.name}`}
                isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
                onChange={(event, dataOption) => {
                  onChangeFilter('user', dataOption);
                }}
                value={filter.user}
                noOptionsText="No user"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </div>
            <button className="h-fit flex items-center justify-center text-white p-[8px] bg-[#2C6D47] rounded-xl" onClick={handleGenerateReport}>
              Generate report
            </button>
            {reportData && (
              <button className="h-fit flex items-center justify-center text-white p-[8px] bg-[#2C6D47] rounded-xl" onClick={exportExcel}>
                Export Excel
              </button>
            )}
          </div>
        </div>
        {reportData && (
          <>
            <div className="w-full flex-flex-col p-4 space-y-4">
              <Box className="w-full h-auto px-4 pt-4 mb-4 mx-auto max-w-[1710px] w-[100%]">
                <div className="flex items-center justify-end mb-4">
                  {' '}
                  <BZButton
                    variant="secondary"
                    onClick={() => {
                      if (editTabel) {
                        setEditTabel(!editTabel);
                        handleGenerateReport();
                      } else setEditTabel(!editTabel);
                    }}
                  >
                    {editTabel ? 'RESET' : 'EDIT'}
                  </BZButton>
                </div>
                <ForceRerender
                  condition={watchingLength}
                  component={
                    <DataGrid
                      autoHeight
                      disableRowSelectionOnClick
                      disableSelectionOnClick
                      rows={getValues('report').map((p, idx) => ({ ...p, idx: idx, id: `${idx}` })) || []}
                      columns={columns}
                      pageSize={rowsPerPage}
                      onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                      onCellClick={(params) => {
                        setActiveTask(params.row.Task);
                      }}
                      rowsPerPageOptions={[20, 50, 100]}
                      className="m-auto max-w-[1710px] w-[100%]"
                      sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' } }}
                    />
                  }
                />

                <div className="flex space-x-2">
                  <Button
                    className="bg-gray-100 mt-2 px-8 text-sm"
                    onClick={() => {
                      insert(0, dataNew());
                      setValue(
                        'report',
                        getValues('report').map((rp, idx) => ({ ...rp, idx, topicActiveIndex: false }))
                      );
                      setTimeout(() => {
                        setValue('report[0]', dataNew());
                        setEditTabel(true);
                      }, 100);
                    }}
                  >
                    Add Data +{' '}
                  </Button>

                  <Button className="bg-gray-100 mt-2 px-8 text-sm" onClick={handleSubmit(onUpdateLogs)}>
                    SAVE
                  </Button>
                </div>
              </Box>
            </div>
            {/* {activeTask && <ModalTaskReport activeTask={activeTask} onClose={() => setActiveTask(false)} />} */}
          </>
        )}
      </div>
      {/* {taskSelector ? (
        <ModalTaskStopTime
          activeTask={taskSelector}
          allData={defaultData}
          callback={() => {
            setReportData(null);
            setDefaultData(null);
            handleGenerateReport();
          }}
          handleClose={() => setTaskSelector(false)}
        />
      ) : null} */}
    </>
  );
}

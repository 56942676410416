/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import Input from '@/pages/CRM/redesigning/InputContainerRedesign';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import InputNumericWithThousandSeparator from '@/components/Input/InputNumericWithThousandSeparator';
import POSApi from '@/api/pos/posApi';
import Swal from 'sweetalert2';
import { handleModal } from '@/store/slices/modalSlice';
import CustomSelectRedesign from '@/pages/CRM/redesigning/CustomSelectRedesign';
import { fetchData } from '@/utils/fetchDataFunc';

const schema = yup.object().shape({
  name: yup.string().nullable(),
  value: yup.string().nullable(),
  type: yup.string().nullable(),
});

function OutletDiscountForm({getOutletDiscounts, outletId}) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const clientId = useSelector((state) => state.client.activeClient);
  const discountdata = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();
  const mode = discountdata?.mode || "add";
  const discountId = discountdata?.data?.id || null;

  const [customerTypeList, setCustomerTypeList] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);  

  const customerTypes = useCallback((params = outletId) => fetchData(POSApi().getListCustomerType, params), [outletId]);

  const getCustomerTypes = useCallback(async () => {
    try {
      const [types] = await Promise.all([customerTypes()]);
      if (types) {        
        setCustomerTypeList(types.map((dt) => ({ label: dt, value: dt })));
        setIsLoadingOptions(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingOptions(false);
    }
  }, [customerTypes]);

  useEffect(() => {
    getCustomerTypes()
  }, [outletId]);
  
  useEffect(() => {
    if (discountdata?.data) {      
      setValue("name", discountdata?.data?.name || "");
      setValue("type", discountdata?.data?.type || "");
      setValue("value", discountdata?.data?.value || "");
      setValue("customer_types", discountdata?.data?.customer_types.map((dt) => ({ label: dt, value: dt })) || []);
    }
  }, [discountdata, discountdata?.data])

  const discountTypeOptions = [
    {name: "Percentage", value: "Percentage"},
    {name: "Fixed price", value: "Fixed price"}
  ]

  const submitDiscount = async (data) => {
    const { addNewDiscount, updateDiscount } = POSApi();

    const newData = {
      ...data,
      customer_types: data.customer_types.map((dt) => dt.value),
      outlet: outletId
    }
    
  
    try {
      if (mode === "add") {
        addNewDiscount(newData)
        .then(() => {
            getOutletDiscounts(outletId);
            dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
            Swal.fire({
              title: 'Success !',
              text: 'Successfully added new Discount!',
              icon: 'success',
            });
        })
        .catch((err) => {
          console.error(err);
        });
      } else {
        updateDiscount(discountId, newData)
        .then(() => {
            getOutletDiscounts(outletId);
            dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
            Swal.fire({
              title: 'Success !',
              text: 'Successfully updated Discount Data!',
              icon: 'success',
            });
        })
        .catch((err) => {
          console.error(err);
        });
      }

    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while processing your request. Please try again later.',
        icon: 'error',
      });
      console.error('Error submitting discount:', error);
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(submitDiscount)}>
      <div className={`w-full h-fit col-span-2`}>
        <p className={`font-semibold `}>Discount Name</p>
        <Input control={control} label="Discount Name" name="name" type="text" />
      </div>
      <div className="w-full h-fit mb-2">
        <p className="font-semibold mb-[5px]">Discount Type</p>
        <Controller
          name="type"
          control={control}
          defaultValue={null}
          render={({ field }) => {
            return (
              <SelectAutocomplete
                variant="outlined"
                options={discountTypeOptions}
                defaultValue={field.value || null}
                onChange={(e, val) => field.onChange(val)}
                isLoading={false}
              />
            );
          }}
        />
      </div>
      <div className={`w-full h-fit col-span-2`}>
        <p className={`font-semibold `}>Discount Value</p>
        <InputNumericWithThousandSeparator control={control} defaultValue="" label="Discount Value" name="value" type="text" maxDecimalPlaces={3} />
      </div>
      <div className={`w-full h-fit col-span-2`}>
        <p className={`font-semibold `}>Customer Types</p>
        <Controller
          name="customer_types"
          control={control}
          defaultValue={discountdata?.data?.customer_types.map((dt) => ({ label: dt, value: dt }))}
          render={({ field: { onChange, value } }) => {
            console.log({value});
            
            return (
              <CustomSelectRedesign
                className="mt-[4px] bg-white"
                options={customerTypeList}
                defaultValue={value}
                isMulti
                onChange={(v) => {
                  onChange(v);
                }}
                isLoading={isLoadingOptions}
              />
            );
          }}
        />
      </div>
      <div className="w-full flex justify-end">
        <button className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white" type="submit" name="Discount">
          {mode === "add" ? "Add Discount" : "Edit Discount"}
        </button>
      </div>
    </form>
  );
}

export default OutletDiscountForm;

import React from 'react';

export default function BaseApplyButton({ setPage, setIsDataLoad, text = ' APPLY' }) {
  const handleApply = () => {
    setPage(1);
    setIsDataLoad(false);
    setTimeout(() => {
      setIsDataLoad(true);
    }, 500);
  };
  return (
    <button onClick={() => handleApply()} className="bz-btn">
      {text}
    </button>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = { message: '' };

const crudMessageSlice = createSlice({
  name: 'crudMessage',
  initialState,
  reducers: {
    crudUpdate: (state, action) => {
      const currState = state;
      currState.message = action.payload.message;
    },
    crudCreate: (state, action) => {
      const currState = state;
      currState.message = action.payload.message;
    },
    crudDelete: (state, action) => {
      const currState = state;
      currState.message = action.payload.message;
    },
  },
});

export const { crudUpdate, crudCreate, crudDelete } = crudMessageSlice.actions;

export default crudMessageSlice.reducer;

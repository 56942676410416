import axiosInstances from '../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function widget() {
  const { axiosInstance } = axiosInstances();

  async function getWidgets() {
    const method = 'get';
    const url = `${baseURL}widgets/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function postWidgetTable(data) {
    const method = 'post';
    const url = `${baseURL}table_contents/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateWidgetTable(id, data) {
    const method = 'patch';
    const url = `${baseURL}table_contents/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteWidgetTable(id) {
    const method = 'delete';
    const url = `${baseURL}table_contents/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  return { getWidgets, postWidgetTable, updateWidgetTable, deleteWidgetTable };
}

import { compareAsc, differenceInHours, format } from 'date-fns';

export default function checkDueDate(dateComing) {
  const compareDates = (dt) => {
    if (dt) {
      const today = new Date();
      const selectedDate = new Date(dt);

      const y1 = format(today, 'yyyy');
      const m1 = format(today, 'M');
      const d1 = format(today, 'dd');
      const h1 = format(today, 'hh');
      const mm1 = format(today, 'mm');

      const y2 = format(selectedDate, 'yyyy');
      const m2 = format(selectedDate, 'M');
      const d2 = format(selectedDate, 'dd');
      const h2 = format(selectedDate, 'hh');
      const mm2 = format(selectedDate, 'mm');

      const dt1 = new Date(y1, m1, d1);
      const dt2 = new Date(y2, m2, d2);

      const dd1 = new Date(y1, m1, d1, h1, mm1);
      const dd2 = new Date(y2, m2, d2, h2, mm2);
      let res = compareAsc(dt1, dt2);
      if (+res === 0 && d1 !== d2 && +d1 < +d2) {
        res = 1
      }
      if (+res === -1 && +d2 === (+d1 + 1)) {
        res = differenceInHours(dd1, dd2);
      }
      return res;
    }
    return undefined;
  };
  return compareDates(dateComing);
}

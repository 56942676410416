import axios from 'axios';

const baseURL = import.meta.env.VITE_API_URL;
const axiosInstance = axios.create({
  baseURL,
  headers: { 'Content-Type': 'multipart/form-data' },
});

function authSSO() {
  async function signinSSO(data) {
    const method = 'post';
    const url = `${baseURL}login/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }
  async function signoutSSO(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.post(
      `${baseURL}logout/`,
      {
        token,
        client_id: 'W08vGPLmig1Jyzktk8dsnIjsJNPfcbkr1CbRcZVc',
        client_secret: 'WaBVvKswzHJ8vzlaIvPqVvAJrpNlbYWEaZj8IwxPkLLJCTPGtQuivQap17679bgZeP8J3zzK2XlDZN86D3xCP5PtRL5aJcqc1Z3WVmTY4DgcOoTmFW3iLjQhqIVUJrVj',
      },
      { headers }
    );
    return response;
  }

  return {
    signinSSO,
    signoutSSO,
  };
}

export default authSSO;

// /* eslint-disable */

import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import request from '../helper/axiosUtil';
import { resetAsset } from '../store/slices/assetCrudSlice';
import { handleModal, handleModalNested } from '../store/slices/modalSlice';
import renderError from '../utils/Error/renderError';
import { toastError, toastLoading, toastSuccess } from '../constants/ToastConfig';

export default function usePatchOrPut({ url, validate, method = 'patch', onSuccess }) {
  const qClient = useQueryClient();
  const dispatch = useDispatch();

  const update = (data) => {
    return request({ url: `/${url}/`, method, data });
  };

  let onToast;

  return useMutation((data) => update(data), {
    // onMutate: () => {},
    onSuccess: (e) => {
      console.log("🚀 ~ returnuseMutation ~ e:", e)
      onToast = new Promise((resolve, reject) => {
        if (e.request.status === 200) {
          if (onSuccess) {
            onSuccess();
          }
          setTimeout(() => {
            resolve('Success');
          }, 500);
        } else {
          const error = new Error('Request failed');
          error.data = JSON.parse(e.request.response);
          setTimeout(() => {
            renderError({ data: JSON.parse(e.request.response) });
            reject(error);
          }, 500);
        }
      });
      toast.promise(onToast, {
        pending: {
          render() {
            return 'Loading';
          },
          ...toastLoading,
        },
        success: {
          render({ data }) {
            return ` ${data}`;
          },
          ...toastSuccess,
        },
        error: {
          render() {
            return 'Error';
          },
          ...toastError,
        },
      });
    },
    onError: () => {
      // qClient.setQueryData([validate, context.newData.id], context.previousData);
    },
    onSettled: () => {
      validate?.forEach((val) => qClient.invalidateQueries(val));
      dispatch(handleModal({ modalId: '' }));
      dispatch(handleModalNested({ modalIdNested: '' }));
      dispatch(resetAsset());
    },
  });
}

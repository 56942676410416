/* eslint-disable */
import { AddOutlined } from '@mui/icons-material';
import { Avatar, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomTable from '../../../components/Table/CustomTable';

const headCellsDepartment = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: true,
    label: 'Address',
  },

  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
];

function convertToTimeAgo(dateString) {
  return (
    <div className="">
      <div className="">{moment(dateString).format('YYYY-MM-DD')}</div>
      <div className="text-xs">{moment(dateString).fromNow()}</div>
    </div>
  );
}

export default function ClientsList({ clients, page, setPage, count, rowsPerPage, setRowsPerPage }) {
  const [isDataLoad, setIsDataLoad] = useState(true);

  const data = useMemo(() => clients, [clients]);

  function TableCells(props) {
    const { row, labelId, ariaChecked, selected } = props;

    return (
      <TableRow className={row.is_archived ? 'bg-gray-100' : ''} hover tabIndex={-1} key={row.name + row.id}>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          <Link className="text-blue-400 flex " to={`/superuser/clients/${row.id}/detail`}>
            {row?.icon ? (
              <div className="flex items-center space-x-2">
                <Avatar src={row.icon} />
                <span>{row.name}</span>
              </div>
            ) : (
              row.name
            )}
            {row.is_archived ? <div className="ml-4 font-bold border-black border text-xs  rounded p-0.5 text-black">Archived</div> : ''}
          </Link>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="normal">
          {row?.owner ? row?.owner?.name : '-'}
        </TableCell>

        <TableCell component="th" id={labelId} scope="row" padding="normal" size="small" className="max-w-[200px]">
          {row?.member ? <div className="font-bold"> {row?.member.name}</div> : '-'}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div className="">
      {' '}
      <div className="w-full relative">
        <Link className="border-[1.5px] border-dashed border-slate-400 flex flex-col items-center justify-center rounded-md w-full h-[80px] mb-8" to="/superuser/clients/add">
          <div>
            <AddOutlined />
          </div>
          <div className="text-gray-500 text-center">Create Clients</div>
        </Link>
      </div>
      <CustomTable
        dataApi={data}
        setIsDataLoad={setIsDataLoad}
        // isFetching={!isFetching}
        headCells={headCellsDepartment}
        TableCells={TableCells}
        page={page - 1}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
        disablePerPage={true}
      />
    </div>
  );
}

/* eslint-disable */

import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function InputContainer({
  name,
  disabled,
  control,
  type = 'text',
  label,
  showPass,
  showCfPass,
  handleClickShowPassword,
  errors,
  defaultValue = '',
  hidden,
  config = {},
  onFocus,
  placeholder,
  autoFocus,
  className,
  rows,
  setValue,
  readOnly = false,
}) {
  useEffect(() => {
    if (setValue) setValue();
  }, [setValue]);

  const isPasswordField = type === 'password';
  const actualType = isPasswordField && (showPass || showCfPass) ? 'text' : type;

  return (
    <div className={`z-index-label ${className} ${hidden ? 'hidden' : ''}`}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextField
            fullWidth
            autoFocus={autoFocus}
            className={className}
            size="small"
            sx={{ textTransform: 'capitalize', marginTop: '4px', background: 'white' }}
            placeholder={placeholder}
            onFocus={onFocus}
            hidden={hidden}
            disabled={disabled}
            multiline={Boolean(rows)}
            rows={rows}
            margin="normal"
            error={Boolean(errors)}
            helperText={errors?.message}
            type={actualType}
            InputProps={{
              readOnly,
              autoComplete: 'new-password',
              endAdornment: isPasswordField && (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                    {showPass || showCfPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...field}
            {...config}
          />
        )}
      />
    </div>
  );
}

export default InputContainer;

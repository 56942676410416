import axiosInstances from '../../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function application() {
  const { axiosInstance } = axiosInstances();

  async function getApplicantData(param) {
    const method = 'get';
    const url = `${baseURL}applications/${param?.id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function updateApplicationData(param, data) {
    const method = 'patch';
    const url = `${baseURL}applications/${param?.id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updateApplicantData(id, data) {
    const method = 'patch';
    const url = `${baseURL}applicants/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function getStagesCrud(param) {
    const method = 'get';
    const url = `${baseURL}application_stages/?job_poster=${param}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getCountries(params) {
    const method = 'get';
    const url = `${baseURL}countries/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function postInterviewData(data) {
    const method = 'post';
    const url = `${baseURL}application_interviews/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function postOffersData(data) {
    const method = 'post';
    const url = `${baseURL}application_offers/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function postNewNote(data) {
    const method = 'post';
    const url = `${baseURL}application_notes/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  return {
    getApplicantData,
    updateApplicationData,
    updateApplicantData,
    getStagesCrud,
    getCountries,
    postInterviewData,
    postOffersData,
    postNewNote,
  };
}

import { Box, Button, Divider, Fade, FormControlLabel, Modal, Pagination, Radio, RadioGroup, Skeleton, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import locationApi from '../../../api/content/location';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 768,
  bgcolor: 'background.paper',
  border: '1px solid transparent',
  boxShadow: 24,
  outline: 'none',
};

const containerStyle = {
  width: '100%',
  height: '15vh',
};

export default function BaseLocation(props) {
  const { open, close, action, defaultLocation } = props;
  const activeClient = useSelector((state) => state.client.activeClient);
  const { getLocations } = locationApi();

  const [dataLocations, setDataLocations] = useState(false);
  const [locations, setLocations] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(false);
  const [canSelected, setCanSelected] = useState(true);
  const [libraries] = useState(['drawing', 'places']);

  // pagination
  const [rowsPerPage] = useState(6);
  const [countPage, setCountPage] = useState(1);
  const [page, setPage] = useState(1);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_MAP_KEY,
    libraries,
  });

  const onCloseHandler = () => {
    setCanSelected(false);
    close();
  };
  const onActionHandler = () => {
    const loc = locations.filter((l) => +l.id === +selectedLocation);
    if (loc.length) {
      action(loc[0]);
    }
  };

  const getLocationList = useCallback(
    (params) => {
      getLocations(params)
        .then((res) => {
          if (res.status === 200 && res.data.results) {
            setDataLocations(res.data.results);
            setLocations(res.data.results.slice(0, rowsPerPage));
            setCountPage(Math.ceil(res.data.count / rowsPerPage));
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [getLocations, rowsPerPage]
  );

  const handleChangePage = (event, value) => {
    if (value > page) {
      setLocations(dataLocations.slice(page * rowsPerPage, (page + 1) * rowsPerPage));
    } else {
      setLocations(dataLocations.slice((value - 1) * rowsPerPage, value * rowsPerPage));
    }
    setPage(value);
  };

  useLayoutEffect(() => {
    if (!locations) {
      setSelectedLocation(false);
      getLocationList({ client: activeClient, max_size: true });
    }
  }, [locations, getLocationList, activeClient, defaultLocation]);

  useEffect(() => {
    if (locations && defaultLocation && canSelected) {
      setSelectedLocation(defaultLocation.id);
      setCanSelected(false);
    }
  }, [locations, defaultLocation, canSelected]);

  return (
    <Modal open={!!open} onClose={onCloseHandler} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Fade in={!!open}>
        <Box sx={style} className="w-[768px] 2xl:w-[60vw] max-h-[90vh] h-auto overflow-y-auto">
          <div className="sticky top-0 z-10 bg-inherit py-4 px-8">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Locations
            </Typography>
            <Divider className="mt-4" />
          </div>
          <div className="w-full h-full flex flex-wrap px-4 justify-center gap-6">
            {isLoaded &&
              locations &&
              locations.map((loc) => (
                <div key={`location${loc.id}`} className="2xl:w-[45%]">
                  <div className="bg-blue-400 w-full py-1 px-3 relative">
                    <RadioGroup
                      row
                      className="text-white"
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={selectedLocation}
                      onChange={(e) => setSelectedLocation(e.target.value)}
                    >
                      <FormControlLabel value={loc.id} control={<Radio className="text-white" />} label={loc?.name} />
                    </RadioGroup>
                  </div>
                  <GoogleMap mapContainerStyle={containerStyle} center={{ lat: parseFloat(loc?.latitude), lng: parseFloat(loc?.longitude) }} zoom={10}>
                    <Marker position={{ lat: parseFloat(loc?.latitude), lng: parseFloat(loc?.longitude) }} />
                  </GoogleMap>
                </div>
              ))}
            {locations && locations.length > 0 && (
              <div className="w-full flex justify-end p-4">
                <Stack spacing={2}>
                  <Pagination count={countPage} page={page} onChange={handleChangePage} />
                </Stack>
              </div>
            )}
            {!isLoaded && !locations && (
              <div className="w-full h-full flex flex-wrap px-4 justify-center gap-6">
                <Skeleton className="2xl:w-[45%]" />
                <Skeleton className="2xl:w-[45%]" />
                <Skeleton className="2xl:w-[45%]" />
                <Skeleton className="2xl:w-[45%]" />
              </div>
            )}
          </div>
          <Divider className="mt-4" />
          <Stack direction="row" spacing={2} className="p-4 pt-0 mt-5 float-right">
            <Button variant="outlined" onClick={onCloseHandler}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onActionHandler}>
              Save
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
}

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function ProtectedRoute({ isAllowed, redirectPath = '/', children }) {
  console.log('🚀 ~ ProtectedRoute ~ children:', children);
  console.log('🚀 ~ ProtectedRoute ~ isAllowed:', isAllowed);
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
}
export default ProtectedRoute;

/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { Button, CircularProgress } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import * as yup from 'yup';
import Input from '@/pages/CRM/redesigning/InputContainerRedesign';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import InputNumericWithThousandSeparator from '@/components/Input/InputNumericWithThousandSeparator';
import POSApi from '@/api/pos/posApi';
import Swal from 'sweetalert2';
import { handleModal } from '@/store/slices/modalSlice';
import moment from 'moment';
import { getDateFull } from '@/helper/getDateFull';

function ShiftSummary() {
  const clientId = useSelector((state) => state.client.activeClient);
  const shiftData = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();

  const keySummary = [
    { label: 'Total Sales', key: 'sales_summary' },
    { label: 'Total Void', key: 'void_summary' },
    { label: 'Total Topup', key: 'top_up_summary' },
    { label: 'Total Pay Out', key: 'cash_out_summary' },
    { label: 'Other', key: 'other_transactions_summary' },
  ];

  const keyCashFlow = [
    { label: 'Starting Cash', key: 'starting_cash', type: 'start' },
    { label: 'Cash Received', key: 'received_cash', type: 'read' },
    { label: 'Cash Out', key: 'cash_out', type: 'stop', content: 'number' },
    { label: 'Remaining in Cash Drawer', key: 'drawer_cash', type: 'stop', content: 'number' },
    { label: 'Variance Starting Cash', key: 'starting_cash_variance', type: 'read' },
    { label: 'Yesterdays Variance', key: 'yesterday_ending_variance', type: 'read' },
    { label: 'Variance Write Off', key: 'variance_write_off', type: 'stop', content: 'number' },
    { label: 'Variance Ending Cash', key: 'ending_cash_variance', type: 'read' },
    { label: 'Write Off reason', key: 'note', type: 'stop', content: 'text', required: true, parentRequired: 'ending_cash_variance' },
  ];

  const [summary, setSummary] = useState(null);
  const [isLoadingGetReport, setIsLoadingGetReport] = useState(false);
  
	const [nominals, setNominals] = useState({
		starting_cash: 0,
		cash_out: 0,
		drawer_cash: 0,
		variance_write_off: 0,
		note: '',
		ending_cash_variance: 0,
	});
  
  const getShiftSummary = async (shiftId) => {
    const { getOutletShiftDetail } = POSApi();

    try {
      setIsLoadingGetReport(true);

      await getOutletShiftDetail(shiftId).then((res) => {
        if(res.status === 200) {
          console.log({res});
          
          
          setSummary(res.data);
        }
      });
    } catch (error) {
      console.log(error);
      console.error(error);
    } finally {
      setIsLoadingGetReport(false);
    }
  };

  useEffect(() => {
    if(shiftData?.shiftId) {
      getShiftSummary(shiftData.shiftId);
    }
  }, [shiftData]);

  const onChange = (key, value) => {
		setNominals((prevState) => {
			return { ...prevState, [key]: value };
		});
	};
  
  const nf = (number) => {
    return new Intl.NumberFormat('id-ID', {
      minimumFractionDigits: 0,
      roundingIncrement: 1,
    }).format(number);
  };

  const ContentShiftHTML = ({ label, value, child, classValueContainer = '' }) => {
    return (
      <div className='flex justify-between text-xl'>
        <span className={`${child ? 'pl-4 ' : 'font-bold'}`}>{label}</span>
        <span className={`${!child && 'font-bold'} ${parseInt(value) < 0 && "text-red-500"} ${classValueContainer}`}>{value}</span>
      </div>
    );
  }

  const ContentSummary = ({ label, object }) => {
    return (
      <>
        <ContentShiftHTML label={label} value={object?.length ? nf(Math.abs(object.reduce((a, b) => a + b.total_sales_amount, 0))) : 0} />
        {object?.length > 0 &&
          object.map((s) => (
            <div key={s.id}>
              <ContentShiftHTML key={s.name} label={s.name} value={nf(Math.abs(s.total_sales_amount))} child={true} />
            </div>
          ))}
      </>
    );
  };  
  
  const res = {}
  
  return isLoadingGetReport ? (
    <CircularProgress />
  ) : (
    <div className="relative flex flex-col w-full gap-2">
      {
        summary && (
          <>
            <ContentShiftHTML label='Operator' value={summary?.operator?.first_name} />
            <ContentShiftHTML label='Sarting Shift' value={getDateFull(new Date(summary?.started_at))} />
            <ContentShiftHTML label='Starting Cash' value={summary?.starting_cash} />
            {keySummary.map((p) => (
							<ContentSummary key={p.label} label={p.label} object={summary[p.key]} />
						))}
            <ContentShiftHTML label='Cash Flow' value='' />
            {keyCashFlow.map((p) => {
							let type = p.type;
							let html;
							if (['read', 'start'].includes(type)) {
								let val = summary[p.key];
								res[p.key] = +val;
								if (p.key === 'ending_cash_variance') {
									const val1 = res.cash_out + res.drawer_cash - res.starting_cash - res.received_cash;
									const val2 = res.starting_cash_variance + res.yesterday_ending_variance + res.variance_write_off;
									val = val1 + val2;
									if (nominals.ending_cash_variance !== val) {
										onChange('ending_cash_variance', val)
									}
								}
								return <ContentShiftHTML key={p.label} label={p.label} value={nf(val)} child={true} />;
							}
							if (type === 'stop') {
								const valT = nominals[p.key] || summary[p.key];
								res[p.key] = +valT;

								const isText = p.content === 'text';

									return (
										<ContentShiftHTML
											classValueContainer={isText ? 'text-right' : ''}
											key={p.label}
											label={p.label}
											value={isText ? summary[p.key] : nf(summary[p.key])}
											child={true}
										/>
									);
							}
							return html;
						})}
          </>
        )
      }
    </div>
  )
}

export default ShiftSummary;
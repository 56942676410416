import React, { useRef, Suspense } from 'react';
import { SnackbarProvider } from 'notistack';
import Slide from '@mui/material/Slide';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import { Close } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';

import SiteRoute from './router/Router';

const queryClient = new QueryClient();

export default function App() {
  const nRef = useRef();
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<CircularProgress />}>
        <SnackbarProvider
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          TransitionComponent={Slide}
          ref={nRef}
          action={(key) => (
            <button onClick={() => nRef.current.closeSnackbar(key)}>
              <Close />
            </button>
          )}
        >
          <ToastContainer />
          <SiteRoute />
        </SnackbarProvider>
      </Suspense>
    </QueryClientProvider>
  );
}

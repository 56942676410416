/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const crmProjectFilter = createSlice({
  name: 'crmProjectFilter',
  initialState: {
    filterOffTable: { on_project_list: true, max_size: true, ordering: '-id', search: '', profile: '', allData: true },
    filterModels: { items: [] },
    sortModel: [],
    visibilityColumns: sessionStorage.getItem('visibilityColumns')
      ? JSON.parse(sessionStorage.getItem('visibilityColumns'))
      : {
          name: true,
          location: true,
        },
  },
  reducers: {
    setFilterOnTable: (state, action) => {
      const currState = state;
      currState.filterOffTable = action.payload;
    },
    setFilterModels: (state, action) => {
      const currState = state;
      currState.filterModels = action.payload;
    },
    setSortModels: (state, action) => {
      const currState = state;
      currState.sortModel = action.payload;
    },
    setColumnVisible: (state, action) => {
      const currState = state;
      currState.visibilityColumns = action.payload;
      sessionStorage.setItem('visibilityColumns', JSON.stringify(action.payload));
    },
    resetCrmProjectState: (state) => {
      state.filterOffTable = { on_project_list: true, max_size: true, ordering: '-id', search: '', profile: '', allData: true };
      state.filterModels = { items: [] };
      state.sortModel = [];
      state.visibilityColumns = {
        name: true,
        location: true,
      };
      sessionStorage.removeItem('visibilityColumns');
    },
  },
});

export const crmProjectFilterActions = crmProjectFilter.actions;
export default crmProjectFilter;

/* eslint-disable */

import { DevTool } from '@hookform/devtools';
import { useQueries, useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Face } from '@mui/icons-material';

import CustomCheckboxNew from '@/components/Input/CustomCheckboxNew';
import ModalWrapper from '@/components/ui/ModalWrapper';
import { handleModal } from '@/store/slices/modalSlice';
import clsxm from '@/utils/clsxm';
import { Button, CircularProgress, Skeleton, Switch, Tooltip } from '@mui/material';

import { useDispatch } from 'react-redux';
import useGet, { CustomFetch } from '../../../hooks/useGet';
import { fetchGroups } from '../../../hooks/useUsersData';
import InputContainer from '../../Input/InputContainer';
import { schemaUserCreate, schemaUserUpdate } from './validation';
import BZButton from '@/components/Button/BZButton';

function filterArray(array1, array2) {
  const desiredArry = array1?.filter((item1) => !array2?.some((item2) => item1?.id === item2?.id));
  return desiredArry?.map((subArr) => subArr.id);
}

function UserFormSSO({ defaultValues, onFormSubmit }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useSelector((state) => state.modal);
  const subsActive = useSelector((state) => state.client.clientSubscriptions);
  const clientId = useSelector((state) => state.client.activeClient);
  const userLogin = useSelector((state) => state.auth.user.id);
  const { userId } = useParams();

  const userSubs = useSelector((state) => state.auth.isGroups);

  const isSuperUser = useSelector((state) => state.auth.isSuperUser);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues, resolver: defaultValues ? schemaUserUpdate : schemaUserCreate });
  const [isActive, setIsActive] = useState(defaultValues?.is_active);
  const [filesAvatar, setFilesAvatar] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [showCfPass, setShowCfPass] = useState(false);
  const [handlePass, setHandlePass] = useState(false);
  const [subsGroup, setSubsGroup] = useState([]);
  const [dataApplications, setDataApplications] = useState([]);
  const [newAppAccess, setNewAppAccess] = useState([]);
  const [mergedAllSubs, setMergedAllSubs] = useState([]);
  const [apiAdminIds, setApiAdminIds] = useState([]);

  const subs = subsActive?.map((s) => {
    return `subscription=${s}&`;
  });

  const subId = subs.join('');
  const { data: subsData } = useQuery(['subs', { subId }], fetchGroups, {
    onSuccess: (res) => {
      const apiAdminIdss = res.data.results.filter((group) => group.name.toLowerCase().startsWith('admin')).map((group) => group.id);
      setApiAdminIds(apiAdminIdss);
    },
  });

  const results = useQueries({
    queries: subsActive?.map((sec) => ({
      queryKey: [`section-customer-${sec}`],
      queryFn: async () =>
        CustomFetch({
          url: `subscriptions/${sec}`,
        }),

      // staleTime: Infinity,
    })),
  });

  const isLoaded = results?.every((res) => res?.data?.data?.id);

  const adminIdsNew = defaultValues?.groups.filter((group) => group.name.toLowerCase().startsWith('admin')).map((group) => group.id);

  useEffect(() => {
    if (isLoaded && apiAdminIds && apiAdminIds.length > 0) {
      const cekIsAdminUser = _.intersection(apiAdminIds, userSubs || []);

      const updateGroups = (groups, groupIds, isSuperUser, idxAdmin, idxManiputale) => {
        groups.forEach((group, idx) => {
          group.gr_admin = isSuperUser ? false : true;
          group.gr_manipulate = _.intersection(groupIds, cekIsAdminUser).length > 0;
          if (idxAdmin && !idxAdmin.includes(idx)) {
            group.gr_admin = false;
          }
        });
      };

      const newArray = _.map(results, (item) => ({
        name: item.data.data.name,
        groups: item.data.data.groups,
        groupIds: item.data.data.groups.map((gr) => gr?.id),
        idxAdmin: item.data.data.groups.map((group, idx) => group.name.split(' ')[0].toLowerCase() === 'admin' && idx),
      }));

      newArray.forEach((data) => {
        updateGroups(data.groups, data.groupIds, isSuperUser, data?.idxAdmin, data?.idxManiputale);
      });

      const allSubs = newArray.map((data) => ({
        name: data.name,
        groups: data.groups,
        is_admin: _.intersection(data.groupIds, adminIdsNew || []).length > 0,
      }));

      setMergedAllSubs(allSubs);
    }
  }, [isLoaded, apiAdminIds]);

  // console.log(newArray);

  const adminIds = subsData?.data.results
    .filter((group) => {
      const firstWord = group.name.split(' ')[0];
      if (firstWord.toLowerCase() === 'admin') {
        return true;
      }
      return false;
    })
    .map((group) => group.id);

  const isAdminUser = (() => {
    return _.intersection(adminIds, userSubs || []).length > 0;
  })();

  const isRegularUser = (() => {
    if (isSuperUser || isAdminUser) {
      return false;
    }
    return true;
  })();

  const isDisableStatus = (() => {
    if (userLogin === defaultValues?.id) {
      return true;
    }
    if (isRegularUser) {
      return true;
    }
    if (isRegularUser) {
      return true;
    }
    return false;
  })();

  const devSubs = subsData?.data?.results.filter(({ id: id1 }) => defaultValues?.groups?.some(({ id: id2 }) => id2 === id1));

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(subsGroup);
      newSelection.push(parseInt(value));
      setSubsGroup(newSelection);
    } else {
      const newSelection = subsGroup;
      const newSubs = newSelection.filter((sub) => parseInt(sub) !== parseInt(value));

      setSubsGroup(newSubs);
    }
  };

  const handleChangeActive = (event) => {
    setIsActive(event.target.value);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (defaultValues) {
      setSubsGroup(devSubs?.map((sub) => sub.id) || []);
    }
  }, [defaultValues]);

  const onSubmit = async (data) => {
    const desiredArray = filterArray(defaultValues?.groups, devSubs);

    const mergeSubs = defaultValues ? subsGroup.concat(desiredArray) : subsGroup;

    const filterAccess = newAppAccess.filter((acc) => acc?.checked);
    const formData = new FormData();

    formData.append('client', clientId);
    formData.append('site', 1);
    formData.append('name', data.name ?? '');
    formData.append('email', data.email ?? '');
    formData.append('is_active', data.is_active);

    if (data.password) {
      formData.append('password', data.password);
      formData.append('confirm_password', data.confirm_password);
    }
    if (filesAvatar) {
      formData.append('avatar', filesAvatar[0]);
    }

    mergeSubs.forEach((gr) => {
      gr && formData.append('groups', gr);
    });
    if (modal.componentName) {
      onFormSubmit({
        // ...data,
        // groups: mergeSubs,
        client: data.client,
        application_access: filterAccess.map((app) => ({ application_id: app?.id, site_id: 1, client_id: clientId, is_active: app?.checked, group_list: app.group_client })),
      });
    } else onFormSubmit(formData);
  };

  const dropZoneAttachments = useDropzone({
    // accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const fileAttacths = Object.assign(acceptedFiles, { preview: URL.createObjectURL(acceptedFiles[0]) });
      setFilesAvatar(fileAttacths);
    },
  });

  const { isFetching } = useGet({
    url: `oauth_applications/show_client_groups_all/?client_instance=${clientId}&user_id=${userId}`,
    validate: 'oauth_applications',
    isEnabled: Boolean(modal.componentName),
    onSuccess: (res) => {
      setDataApplications(res.data);
    },
  });

  const applicationIdIntoGroup = dataApplications;
  const updatedAccess = [...dataApplications];

  const handleChangeAppsGroup = (e, index, idx, val) => {
    const { checked } = e.target;

    updatedAccess[index].group_client[idx] = { ...val, checked: checked };

    const filterAccess = updatedAccess.map((acc) => ({ ...acc, group_client: acc?.group_client?.filter((gr) => gr.checked) }));

    setNewAppAccess(filterAccess);
  };

  const handleChangeAppsStatus = (e, index, val) => {
    const { checked } = e.target;

    updatedAccess[index].checked = checked;

    const filterAccess = updatedAccess.filter((acc) => acc?.checked);
    const lastFilterAccess = filterAccess.map((acc) => ({ ...acc, group_client: acc?.group_client?.filter((gr) => gr.checked) }));

    setNewAppAccess(lastFilterAccess);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
        <div className="space-y-1">
          <div className="flex w-full">
            <div className="w-full">
              <div className="flex space-x-2.5">
                {' '}
                <div className=" ">
                  <Tooltip title="Attach a file image">
                    <div className="" {...dropZoneAttachments.getRootProps()} aria-hidden>
                      {filesAvatar ?? defaultValues ? (
                        <img src={filesAvatar?.preview ?? defaultValues?.avatar} alt="" className="w-40 h-40  mb-5 mx-auto" />
                      ) : (
                        <Face className="w-40 h-40 text-[#E98736] mb-5" />
                      )}
                      <input {...dropZoneAttachments.getInputProps()} />
                    </div>
                  </Tooltip>
                </div>
                <div className="w-8/12">
                  <InputContainer name="name" control={control} label="Name" errors={errors.name} />
                  <InputContainer name="email" control={control} label="Email" errors={errors.email} />
                  <div className="grid grid-cols-2 ">
                    <div className="">
                      <CustomCheckboxNew
                        helperText={`When checked, this user will be active`}
                        name="is_active"
                        control={control}
                        label="Active"
                        defaultChecked={defaultValues?.is_active}
                        disabled={isDisableStatus}
                      />
                    </div>
                    <div className="">
                      <CustomCheckboxNew
                        helperText={`When checked, this user will be superuser`}
                        name="is_active"
                        control={control}
                        label="SuperUser"
                        defaultChecked={defaultValues?.is_superuser}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-[45px]">
                {defaultValues ? (
                  <div className="">
                    <Button type="button" variant="outlined" className="bz-btn" onClick={() => setHandlePass(!handlePass)}>
                      Update Password
                    </Button>
                    {handlePass && (
                      <div className="w-6/12 space-y-1">
                        <InputContainer
                          showPass={showPass}
                          handleClickShowPassword={() => setShowPass(!showPass)}
                          type="password"
                          name="password"
                          control={control}
                          label="Password"
                          errors={errors.password}
                        />
                        <InputContainer
                          handleClickShowPassword={() => setShowCfPass(!showCfPass)}
                          type="password"
                          showCfPass={showCfPass}
                          name="confirm_password"
                          control={control}
                          label="Confirmation Password"
                          errors={errors.confirm_password}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="w-6/12 space-y-1">
                    <InputContainer
                      showPass={showPass}
                      handleClickShowPassword={() => setShowPass(!showPass)}
                      type="password"
                      name="password"
                      control={control}
                      label="Password"
                      errors={errors.password}
                    />
                    <InputContainer
                      handleClickShowPassword={() => setShowCfPass(!showCfPass)}
                      type="password"
                      showCfPass={showCfPass}
                      name="confirm_password"
                      control={control}
                      label="Confirmation Password"
                      errors={errors.confirm_password}
                    />
                  </div>
                )}
              </div>
              {mergedAllSubs && mergedAllSubs.length > 0 ? (
                <div className="grid grid-cols-2 gap-[16px] mt-[32px]">
                  {mergedAllSubs.map((sub, idx) => {
                    const { name, groups } = sub;

                    return (
                      <div className="p-[24px] border">
                        <div className="uppercase text-center mb-[8px] font-semibold">{name}</div>
                        {groups?.map((gr) => (
                          <table className="table w-full p-[16px] border">
                            <tbody>
                              <tr key={gr.id} className="">
                                <th align="left" className="p-3">
                                  {gr.name}
                                </th>
                                <td className="flex items-center p-3 justify-end">
                                  <span className=" text-sm font-medium text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                                  <label className="relative inline-flex items-center  cursor-pointer">
                                    <label className="relative inline-flex items-center  cursor-pointer">
                                      <Switch
                                        value={gr.id}
                                        onChange={handleChange}
                                        className="bz-switch"
                                        type="checkbox"
                                        defaultChecked={devSubs.find((ds) => ds.id === gr.id)}
                                        disabled={
                                          userLogin === defaultValues?.id
                                            ? isRegularUser
                                              ? true
                                              : isSuperUser
                                              ? false
                                              : (userSubs.includes(gr.id) && gr.gr_admin) || !gr.gr_manipulate
                                            : isSuperUser
                                            ? false
                                            : !gr.gr_manipulate
                                        }
                                      />
                                    </label>
                                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Active</span>
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ))}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <CircularProgress />
              )}
              {defaultValues && (
                <div className="border border-black p-[24px] mt-[45px] w-6/12">
                  <div className="flex items-center justify-between">
                    <div className="font-bold text-3xl">Application Access</div>

                    <BZButton
                      variant="primary"
                      onClick={() => {
                        setDataApplications([]);
                        dispatch(handleModal({ modalId: '', componentName: 'editAccess' }));
                      }}
                    >
                      Edit Access
                    </BZButton>
                  </div>
                  <div className="grid grid-cols-2 mt-[16px]">
                    {defaultValues?.application_access.map((app) => {
                      const { group_list, application_name } = app;
                      return (
                        <div className="">
                          <h3 className="font-bold text-xl">{application_name}</h3>
                          {group_list?.map((gr) => {
                            const { name, id } = gr;
                            return <div className="">{name}</div>;
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            {/* <div className="relative w-6/12 my-10 flex justify-center"></div> */}
          </div>
          <div className="flex justify-end ml-auto gap-x-2">
            <Button onClick={handleCancel} className="bz-btn secondary" variant="outlined">
              Cancel
            </Button>
            <Button className="bz-btn" type="submit" variant="outlined">
              {defaultValues ? 'Update User' : 'Create User'}
            </Button>
          </div>
        </div>
        <ModalWrapper componentName="editAccess" header="Edit Access" maxWidth="sm">
          {!isFetching ? (
            <div className="space-y-[16px]">
              {' '}
              {applicationIdIntoGroup?.map((app, index) => {
                const { group_client, name, checked: checkedApp, server_status, client_name } = app;
                return (
                  <div className={clsxm(' ', server_status === 'online' ? '' : 'cursor-not-allowed ')}>
                    <div
                      className={clsxm(
                        'rounded border p-[16px] ',
                        server_status === 'online' ? 'border-green-500 bg-green-500/20' : 'border-gray-400 bg-gray-100 pointer-events-none '
                      )}
                    >
                      <div className="flex justify-between items-center ">
                        <div className="flex space-x-3 items-center">
                          <h3 className="font-bold text-2xl">{name}</h3>
                          {isSuperUser && <p className="text-sm">{`( ${client_name} )`}</p>}
                        </div>
                        <h3 className={clsxm('font-bold ', server_status === 'online' ? 'text-green-500' : 'text-gray-400')}>{server_status}</h3>
                      </div>
                      <CustomCheckboxNew
                        key={app.id}
                        id={app.id}
                        helperText={`When checked, the ${name} app will be available to this user`}
                        name="checked"
                        control={control}
                        label="Active"
                        defaultChecked={checkedApp}
                        onChangeCustom={(e) => handleChangeAppsStatus(e, index, app)}
                        disabled={isDisableStatus}
                      />
                      {group_client?.map((gr, idx) => {
                        const { name: groupName, id, application_id, checked } = gr;
                        return (
                          <div className={clsxm('flex mt-[16px]')}>
                            <div className="w-[200px]">{groupName}</div>

                            <Switch
                              disabled={isDisableStatus || !checkedApp}
                              value={`${id}-${application_id}`}
                              defaultChecked={checked}
                              onChange={(e) => handleChangeAppsGroup(e, index, idx, gr)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              <div className="flex items-center justify-end">
                {isDisableStatus ? (
                  <BZButton variant="secondary" onClick={() => dispatch(handleModal({ modalId: '' }))}>
                    Close Save
                  </BZButton>
                ) : (
                  <BZButton variant="secondary" onClick={handleSubmit(onSubmit)}>
                    Save
                  </BZButton>
                )}
              </div>
            </div>
          ) : (
            <div className="">
              <div className="grid grid-cols-2 gap-[200px] mb-[16px]">
                <Skeleton />
                <Skeleton />
              </div>
              <Skeleton count={10} />
            </div>
          )}
        </ModalWrapper>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
}
export default UserFormSSO;

/* eslint-disable */

import React from 'react';
import { Controller } from 'react-hook-form';
import { TextareaAutosize } from '@mui/material';

function TextAreaContainer({ name, disabled, control, label, errors, defaultValue, hidden, config = {}, onFocus, placeholder, autoFocus, className, rows }) {
  // console.log(name, defaultValue);
  return (
    <div className={['my-1 z-index-label', hidden && 'hidden'].join(' ')}>
      <div className="font-bold pb-1">{label}</div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        render={({ field }) => (
          <TextareaAutosize
            fullWidth
            autoFocus={autoFocus}
            className={`block p-2.5 w-full text-sm text-gray-900  rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500    ${className}`}
            sx={{ textTransform: 'capitalize' }}
            label={label}
            minRows={3}
            // value={value}
            // defaultValue={defaultValue || ''}
            placeholder={placeholder}
            onFocus={onFocus}
            hidden={hidden}
            disabled={disabled}
            multiline={Boolean(rows)}
            // rows={rows}
            margin="normal"
            error={Boolean(errors)}
            helperText={errors?.message}
            /* eslint-disable */ //nested ternary

            {...field}
            {...config}
          />
        )}
      />
    </div>
  );
}

export default TextAreaContainer;

import React, { useState } from 'react';
import { Box, Button, Fade, Popover } from '@mui/material';
import { Add, ArrowBackIos, ArrowForwardIos, Close, Download, Fullscreen, Remove } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { format } from 'date-fns';

const Div = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: '0 0 auto',
  borderBottom: '1px solid #424244',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 22,
  color: '#f5f4f3',
  height: 62,
});

const DivInfo = styled('div')({
  flex: '1 1 0',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  minWidth: 1,
});

const DivControls = styled('div')({
  alignItems: 'center',
  textAlign: 'center',
  border: '1px solid #ffffff',
  borderRadius: 4,
  boxSizing: 'border-box',
  display: 'flex',
  height: 32,
});

const DivActions = styled('div')({
  alignItems: 'center',
  borderLeft: '0',
  flex: '1 1 0',
  display: 'flex',
  justifyContent: 'flex-end',
  height: 60,
  width: 'unset',
});

const DivCarousels = styled('div')({
  position: 'relative',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'row',
  minHeight: 1,
});

const DivPagination = styled('div')({
  display: 'flex',
  flex: '0 0 auto',
  alignSelf: 'center',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  margin: '0 48',
});

function PrevEl() {
  const swiper = useSwiper();
  return <ArrowBackIos fontSize="64" className="w-8 h-8 absolute top-1/2 left-16 z-[1000] text-[#f5f4f3] cursor-pointer" onClick={() => swiper.slidePrev()} />;
}

function NextEl() {
  const swiper = useSwiper();
  return <ArrowForwardIos fontSize="64" className="w-8 h-8 absolute top-1/2 right-16 z-[1000] text-[#f5f4f3] cursor-pointer" onClick={() => swiper.slideNext()} />;
}

const downloadAsset = (assSelected) => {
  if (assSelected) {
    const anchor = document.createElement('a');
    anchor.href = assSelected.file_attached || assSelected.file_upload;
    anchor.download = `attachment-${assSelected.id}`;
    anchor.target = '_blank';
    anchor.click();
  }
};

const isImageType = (file) => {
  if (file) {
    const fileName = file.substring(file.lastIndexOf('/') + 1);
    const fileExt = file.substring(file.lastIndexOf('.') + 1);
    const imgTypes = ['jpg', 'jpeg', 'png', 'webp', 'svg'];
    return {
      isImage: imgTypes.includes(fileExt.toLowerCase()),
      ext: fileExt,
      name: fileName,
    };
  }
  return false;
};

function RenderSwiperImagePagination(props) {
  const swiper = useSwiper();
  const { assets: asset, index } = props;
  if (asset) {
    const onClickImage = (idx) => {
      swiper.slideTo(idx);
    };
    const htmlSwiper =
      asset.length > 0 &&
      asset.map((a, i) => {
        let styleDefault;
        if (i !== index) {
          styleDefault = { opacity: '.5' };
        }
        const fileType = isImageType(a.file_attached || a.file_upload);
        let html;
        if (fileType.isImage) {
          html = (
            <img
              loading="lazy"
              src={a.file_attached || a.file_upload}
              alt=""
              style={{ imageOrientation: 'from-image' }}
              className="w-full h-full object-cover m-auto swiper-zoom-target"
            />
          );
        } else {
          html = (
            <div className="border border-white w-full h-full flex justify-center items-center relative my-auto">
              <p className="text-sm truncate text-[#f5f4f3]">{fileType.ext} file</p>
            </div>
          );
        }
        return (
          <div key={`swiper-pagination-${a.file_attached || a.file_upload}`} className="swiper-zoom-container">
            <button type="button" style={styleDefault} className="h-11 w-16 flex items-center" onClick={() => onClickImage(i)}>
              {html}
            </button>
          </div>
        );
      });
    return (
      <div style={{ zIndex: 1000 }} className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full h-36">
        <div className="w-full flex justify-center bg-transparent">
          <div className="flex space-x-4 justify-center items-center p-4 rounded-md bg-black bg-opacity-30">{htmlSwiper}</div>
        </div>
      </div>
    );
  }
  return <div />;
}

export default function AssetsViewer(props) {
  const { assets, openAttachments, handleCloseAttachments } = props;
  const [indexSwiper, setIndexSwiper] = useState(openAttachments || 0);

  const onClosePopover = () => {
    handleCloseAttachments();
  };

  const currAssetPreview = () => {
    if (assets && assets.length) {
      return isImageType(assets[indexSwiper]?.file_attached || assets[indexSwiper]?.file_upload);
    }
    return '-';
  };

  return (
    <Popover
      open={!!openAttachments}
      onClose={handleCloseAttachments}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 0, left: 0 }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      marginThreshold={0}
      sx={{
        maxHeight: '100vh',
        '& > div.MuiPaper-root': {
          top: '0 !important',
          left: '0 !important',
          maxHeight: '100vh',
          maxWidth: '100vw',
          backgroundColor: 'rgb(37,38,40)',
          borderRadius: 'unset',
        },
      }}
    >
      <Fade in={!!openAttachments || String(openAttachments) === '0'} timeout={1}>
        <Box style={{ width: '100vw', height: '100vh', flex: '1 1 auto' }} className="relative flex flex-col overflow-hidden">
          <Div>
            <DivInfo>
              <div className="flex flex-col mx-6 min-w-[1px] flex-auto">
                <div className="truncate text-[#f5f4f3] text-sm">{currAssetPreview().name}</div>
                <span className="truncate text-[#a2a0a2] text-sm">{assets[indexSwiper]?.created_at && format(new Date(assets[indexSwiper]?.created_at), 'MMM d, yyyy')}</span>
              </div>
            </DivInfo>
            <DivControls>
              <Remove className="mx-2 cursor-pointer" />
              <span>100%</span> <Add className="mx-2 cursor-pointer" /> <Fullscreen className="mx-2 cursor-pointer" />
            </DivControls>
            <DivActions>
              <Button className="px-4 flex justify-start text-[#f5f4f3]" startIcon={<Download />} onClick={() => downloadAsset(assets[indexSwiper])}>
                Download
              </Button>
              <div className="border-l border-[#424244] h-full flex justify-center items-center" onClick={onClosePopover} aria-hidden>
                <Button className="justify-center text-center text-[#f5f4f3]" sx={{ '& > .MuiButton-startIcon': { margin: 0 } }} startIcon={<Close />} />
              </div>
            </DivActions>
          </Div>
          <DivCarousels>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              rewind
              allowSlideNext
              allowSlidePrev
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={(swiperr) => setIndexSwiper(swiperr.activeIndex)}
              zoom={{ maxRatio: 5 }}
              initialSlide={openAttachments}
            >
              {assets.length > 1 && (
                <>
                  <PrevEl />
                  <NextEl />
                </>
              )}
              {assets.length > 0 &&
                assets.map((a, i) => {
                  const fileType = isImageType(a.file_attached || a.file_upload);
                  let htmlSwiper;
                  if (fileType.isImage) {
                    htmlSwiper = (
                      <img
                        loading="lazy"
                        key={`${a.file_attached || a.file_upload + i}`}
                        src={a.file_attached || a.file_upload}
                        alt=""
                        style={{ imageOrientation: 'from-image' }}
                        className="w-full h-full object-contain m-auto"
                      />
                    );
                  } else {
                    htmlSwiper = (
                      <div key={`${a.file_attached || a.file_upload + i}`} className="flex flex-col justify-center items-center text-center text-white space-y-4">
                        <p className="text-xl">We are not able to preview this file </p>
                        <p className="text-lg">{fileType.name}</p>
                        <Button size="small" color="primary" aria-label="download file" variant="outlined" onClick={() => downloadAsset(a)}>
                          Download
                        </Button>
                      </div>
                    );
                  }
                  return (
                    <SwiperSlide key={a.file_attached || a.file_upload}>
                      <div className="w-full h-full flex justify-center items-center">{htmlSwiper}</div>
                    </SwiperSlide>
                  );
                })}
              <RenderSwiperImagePagination assets={assets} index={indexSwiper} />
            </Swiper>
          </DivCarousels>
          <DivPagination />
        </Box>
      </Fade>
    </Popover>
  );
}

/* eslint-disable */

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Divider, FormControl, FormControlLabel, Input, Stack, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

const schema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  ga_code: yup.string().nullable(),
});

const initialState = {
  name: '',
  ga_code: '',
};

const initialFiles = {
  context_zip_file: null,
  env_preview_file: null,
  env_publish_file: null,
  tailwind_config_file: null,
  package_json_file: null,
};

function HtmlFiles({ id, field, name, accept, callback, prevState }) {
  return (
    <label htmlFor={id} className="text-gray-700">
      {name}
      <Input
        className="w-full"
        accept={accept}
        id={id}
        type="file"
        onChange={(e) => {
          callback({ ...prevState, [field]: e.target.files[0] });
        }}
      />
    </label>
  );
}

export default function TemplateForm(props) {
  const activeSite = useSelector((state) => state.content.activeSite);

  const { open, close, data, type, action } = props;
  const [isOpen, setOpen] = useState(false);
  const [mode, setMode] = useState(false);
  const [useTailwind, setUseTailwind] = useState(false);
  // const [gaCode, setGaCode] = useState('');
  const [files, setFiles] = useState(initialFiles);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
    reValidateMode: 'onChange',
  });

  const onCloseHandler = () => {
    // reset(initialState);
    // close();
    setFiles(initialFiles);
    setOpen(false);
    reset({
      name: '',
      ga_code: '',
    });
  };

  const onSubmit = (temp) => {
    console.log('temp di line 88 ', temp);
    const dataTemplate = {};
    dataTemplate.site = activeSite;
    dataTemplate.name = temp.name;
    dataTemplate.ga_code = temp.ga_code;
    dataTemplate.files = files;
    dataTemplate.use_tailwind = useTailwind;
    action(mode, dataTemplate);
    onCloseHandler();
  };

  const setDefaultState = useCallback(() => {
    if (!mode && type) {
      setMode(type);
    }
    if (data) {
      console.log('data line 102', data);
      setValue('name', data?.name);
      setValue('ga_code', data?.ga_code);
      setValue('useTailwind', data?.use_tailwind);
      // setGaCode(data?.ga_code);
      setUseTailwind(data?.use_tailwind);
    }
  }, [mode, type, data, setValue]);

  useEffect(() => {
    if (!isOpen && open && data) {
      setMode(false);
      setOpen(true);
      setDefaultState();
    }
  }, [open, setDefaultState, isOpen, data]);

  useEffect(() => {
    if (type && type !== mode) {
      setMode(type);
      setDefaultState();
    }
  }, [type, mode, setDefaultState]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
      <div className="flex flex-col space-y-4 mt-4">
        <FormControl>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  label="Name"
                  size="small"
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                  placeholder=""
                />
              );
            }}
          />
        </FormControl>
        <FormControl>
          <Controller
            control={control}
            name="ga_code"
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return <TextField onBlur={onBlur} onChange={onChange} value={value} inputRef={ref} label="GA Code" size="small" placeholder="" />;
            }}
          />
        </FormControl>
        <FormControlLabel control={<Checkbox checked={useTailwind} onChange={(e) => setUseTailwind(e.target.checked)} name="useTailwind" />} label="Use Tailwind" />
        {mode === 'update' && (
          <>
            <HtmlFiles id="context-file" field="context_zip_file" name="Context" accept=".zip" callback={setFiles} prevState={files} />
            <HtmlFiles id="env-preview-file" field="env_preview_file" name="Env Preview" accept="file/*" callback={setFiles} prevState={files} />
            <HtmlFiles id="env-publish-file" field="env_publish_file" name="Env Publish" accept="file/*" callback={setFiles} prevState={files} />
            <HtmlFiles id="tailwind-file" field="tailwind_config_file" name="Tailwind Config" accept=".js,.json" callback={setFiles} prevState={files} />
            <HtmlFiles id="package-file" field="package_json_file" name="Package Json" accept=".json" callback={setFiles} prevState={files} />
          </>
        )}
      </div>
      <Divider className="mt-4" />
      <Stack direction="row" spacing={2} className="mt-5 float-right">
        <Button variant="outlined" onClick={( ) => close()}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Save
        </Button>
      </Stack>
    </form>
  );
}

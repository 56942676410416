import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField,
  Box, 
  CircularProgress
} from '@mui/material';
import {
  ArrowBack,
} from '@mui/icons-material';
import BZHelmet from '@/utils/BZHelmet';
import BaseMainTitle from '@/components/BaseMainTitle';
import CustomTable from '@/components/Table/CustomTable'
import Swal from 'sweetalert2';
import _ from 'lodash';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import POSApi from '@/api/pos/posApi';
import { fetchData } from '@/utils/fetchDataFunc';
import { DatePickerFieldWithoutController } from '@/components/Input/DatePickerField';
import AccountingApi from '../../api/accounting/accounting';
import { DataGrid } from '@mui/x-data-grid';
import getTimeZone from '../../utils/Task/getTimeZone';
import { utils, writeFileXLSX } from 'xlsx';
import moment from 'moment';

function OutletSalesReports() {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const { outletId } = useParams();
  const dispatch = useDispatch();

  const typeOptions = [
    {name: "On the spot", value: "On the spot"},
    {name: "Customer App", value: "Customer App"},
    {name: "Deposit", value: "Deposit"},
    {name: "Void", value: "Void"},
    {name: "Cash Out", value: "Cash Out"},
    {name: "Cancel", value: "Cancel"},
  ]

  const [outletList, setOultetList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState(null);
  const [transactionTypes, setTransactionTypes] = useState([]);
  
  const [isLoadingOutlet, setIsLoadingOutlet] = useState(true);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [startPeriod, setStartPeriod] = useState(null);
  const [endPeriod, setEndPeriod] = useState(null);
  const [businessUnitList, setBusinessUnitList] = useState([]);
  const [selectedBU, setSelectedBU] = useState(null);
  
  const [report, setReport] = useState(null);
  const [isLoadingGetReport, setIsLoadingGetReport] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(true);

  const formatToLocal = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString();
  };

  useLayoutEffect(() => {
    const dateNow = new Date();
    const startDate = new Date(dateNow);
    startDate.setDate(dateNow.getDate() - 30);
    setStartPeriod(startDate);
    setEndPeriod(dateNow);
  }, []);  

  useEffect(() => {
    if (selectedOutlet || selectedBU || startPeriod || endPeriod) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [selectedOutlet, selectedBU, startPeriod, endPeriod]);
  
  const getBusinessUnitList = useCallback(async () => {
    const { getListBusinessUnit } = AccountingApi();

    try {
      const getData = await getListBusinessUnit({ client: clientId, max_size: true });
      const data = await getData.data;
      
      setBusinessUnitList(data);
      // setSelectedBU(data[0]?.id);
      // setValue('businessUnit', data[0]?.id || '');
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getBusinessUnitList()
  }, [clientId]);
  
  const getAllOutletList = useCallback((params) => fetchData(POSApi().getOutletList, params), [clientId]);
  const getOutlets = useCallback(
    async (paramOutlets) => {
      const [outlets] = await Promise.all([getAllOutletList(paramOutlets)]);

      if (outlets) {
        setOultetList(outlets?.results);
      } 
      
      setIsLoadingOutlet(false) 
    },
    [getAllOutletList]
  );

  useEffect(() => {
    getOutlets({department__business_unit__client: clientId, ordering: "name"})
  }, [clientId]);

  const fetchAllPaymentType = useCallback((params) => fetchData(POSApi().getAllPaymentTypeNames, params), [selectedOutlet, selectedBU]);

  const getAllPaymentTypes = useCallback(
    async (params) => {
      const [paymentTypes] = await Promise.all([fetchAllPaymentType(params)]);
      if (paymentTypes) {
        const uniquePaymentTypes = Array.from(new Set(paymentTypes));
        
        setPaymentTypeList(uniquePaymentTypes);
      }
      setIsLoadingPayment(false);
    },
    [fetchAllPaymentType]
  );

  const handleOutletSelect = (val) => {
    if (val === null) {
      setSelectedOutlet(null);
      return;
    }
  
    setSelectedOutlet(val);
    if(paymentTypeList === null) {
      setIsLoadingPayment(true);
      getAllPaymentTypes({outlet: selectedOutlet, ordering: "name"});
    }
  };

  const handleBusinessUnitSelect = (val) => {
    if (val === null) {
      setSelectedBU(null);
      return;
    }
  
    setSelectedBU(val);
    setIsLoadingPayment(true);
    getAllPaymentTypes({outlet__department__business_unit: selectedBU, ordering: "name"});
  };

  const onFilterStartDate = (event, key) => {
    const parsedDate = new Date(event);    
  
    if (event === null) {
      return key === "startPeriod" ? setStartPeriod(event) : setEndPeriod(event);
    }
  
    if (parsedDate.getFullYear() >= 2010 && !isNaN(parsedDate)) {
      if (key === "startPeriod") {
        parsedDate.setHours(0, 0, 0, 0);
        setStartPeriod(parsedDate);
      }
      if (key === "endPeriod") {
        parsedDate.setHours(23, 59, 59, 999);
        setEndPeriod(parsedDate);
      }
    } else {
      console.log('Tanggal tidak valid atau tahun kurang dari 2010, update dilewati');
      return;
    }
  };

  const generateData = async (dt) => {
    const { getSalesReport } = POSApi();
    const { period, businessUnit, ...anotherDt } = dt;
    const newDt = { ...anotherDt, business_unit: businessUnit || "" };    
    
    if (startPeriod) {
      newDt.start = moment(startPeriod).format("YYYY-MM-DD");
    }
  
    if (endPeriod) {
      newDt.end = moment(endPeriod).format("YYYY-MM-DD");
    }

    if (newDt.business_unit === "" && !newDt.outlet) {
      newDt.client = clientId
    }

    try {
      setIsLoadingGetReport(true);

      await getSalesReport(newDt).then((rp) => {
        if(rp.status === 200) {
          const theReport = rp.data.map((val, idx) => {
            const { date } = val.order;
            const [year, month, day] = date.split('-');

            return ({
              id: idx,
              business_unit_code : val.order.outlet.business_unit.code,
              business_unit_name : val.order.outlet.business_unit.name,
              outlet_code : val.order.outlet.code,
              outlet_name : val.order.outlet.name,
              sales_date : date,
              year,
              month,
              day,
              bill_number: val.order.number,
              item_code: val.product.item_code,
              item_name: val.product.name,
              item_category: val.product.category,
              customer_name: val.order.customer?.member.name || "",
              payment_type: val.order.payment_type,
              transaction_type: val.order.type,
              unit_price: val.unit_price,
              qty: val.quantity,
              price_before_discount: val.price_before_discount,
              discount: val.discount_amount,
              vat: val.tax,
              price: val.price,
              add_ons: val.add_ons,
              add_ons_amount: val.add_ons_amount,
              total_sales_amount: val.sales_amount
            }
          )})
          setReport(theReport);

          if (theReport.length > 0) {
            setIsExportButtonDisabled(false)
          }
        }
      });
    } catch (error) {
      console.log(error);
      console.error(error);
    } finally {
      setIsLoadingGetReport(false);
    }
  };

  const exportExcel = () => {
    const processedReport = report.map(({ id, ...rest }) => {
      const newObj = {};
      for (let key in rest) {
        const newKey = key.replace(/_/g, ' ');
        if (Array.isArray(rest[key])) {
          newObj[newKey] = rest[key].join(', ');
        } else {
          newObj[newKey] = rest[key];
        }
      }
      return newObj;
    });
  
    const ws = utils.json_to_sheet(processedReport);
    
    const columnWidths = [
      { wch: 10 },  // Lebar kolom business_unit_code
      { wch: 15 },  // Lebar kolom business_unit_name
      { wch: 15 },  // Lebar kolom outlet_code
      { wch: 15 },  // Lebar kolom outlet_name
      { wch: 15 },  // Lebar kolom sales_date
      { wch: 15 },  // Lebar kolom bill_number
      { wch: 15 },  // Lebar kolom item_number
      { wch: 15 },  // Lebar kolom item_category
      { wch: 8 },  // Lebar kolom unit_price
      { wch: 8 },  // Lebar kolom qty
      { wch: 8 },  // Lebar kolom discount
      { wch: 8 },  // Lebar kolom vat
      { wch: 8 },  // Lebar kolom total_sales_price
    ];
  
    ws['!cols'] = columnWidths;
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
  
    writeFileXLSX(wb, `report.xlsx`);
  };

  const formatNominal = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
  
    const integerValue = Math.floor(Number(value)); 
  
    const formattedValue = integerValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    return formattedValue;
  };

  function AddOnsCell(props) {
    const { data } = props;
    
    return data.length > 0 && (
      <ul className="">
        {data?.map((col) => (
          <li key={col} className="text-xs list-disc">
            {col || ""}
          </li>
        ))}
      </ul>
    );
  }
  
  const columns = [
    {
      field: 'business_unit_code',
      headerName: 'Business Unit Code',
      headerAlign: 'left',
      align: 'left',
      minWidth: 80,
      renderCell: (params) => params.row.bussines_unit_code,
    },
    {
      field: 'business_unit_name',
      headerName: 'Business Unit Name',
      headerAlign: 'left',
      minWidth: 80,
      renderCell: (params) => params.row.bussines_unit_name,
    },
    {
      field: 'outlet_code',
      headerName: 'Outlet Code',
      headerAlign: 'left',
      renderCell: (params) => params.row.outlet_code,
    },
    {
      field: 'outlet_name',
      headerName: 'Outlet Name',
      headerAlign: 'left',
      renderCell: (params) => params.row.outlet_name,
    },
    {
      field: 'sales_date',
      headerName: 'Sales Date',
      headerAlign: 'left',
      renderCell: (params) => params.row.sales_date,
    },
    {
      field: 'bill_number',
      headerName: 'Bill Number',
      headerAlign: 'left',
      renderCell: (params) => params.row.bill_number,
    },
    {
      field: 'item_code',
      headerName: 'Item Code',
      headerAlign: 'left',
      renderCell: (params) => params.row.item_code,
    },
    {
      field: 'item_name',
      headerName: 'Item Name',
      headerAlign: 'left',
      renderCell: (params) => params.row.item_name,
    },
    {
      field: 'item_category',
      headerName: 'Item Category',
      headerAlign: 'left',
      renderCell: (params) => params.row.item_category,
    },
    {
      field: 'customer_name',
      headerName: 'Customer Name',
      headerAlign: 'left',
      renderCell: (params) => params.row.customer_name,
    },
    {
      field: 'payment_type',
      headerName: 'Payment Type',
      headerAlign: 'left',
      renderCell: (params) => params.row.payment_type,
    },
    {
      field: 'transaction_type',
      headerName: 'Transaction Type',
      headerAlign: 'left',
      renderCell: (params) => params.row.transaction_type,
    },
    {
      field: 'unit_price',
      headerName: 'Unit Price',
      headerAlign: 'left',
      align: 'right',
      renderCell: (params) => formatNominal(params.row.unit_price),
    },
    {
      field: 'qty',
      headerName: 'Qty',
      headerAlign: 'left',
      align: 'right',
      renderCell: (params) => formatNominal(params.row.qty),
    },
    {
      field: 'price_before_discount',
      headerName: 'Price B. Discount',
      headerAlign: 'left',
      align: 'right',
      renderCell: (params) => formatNominal(params.row.price_before_discount),
    },
    {
      field: 'discount',
      headerName: 'Discount',
      headerAlign: 'left',
      align: 'right',
      renderCell: (params) => formatNominal(params.row.discount),
    },
    {
      field: 'vat',
      headerName: 'Vat',
      headerAlign: 'left',
      align: 'right',
      renderCell: (params) => formatNominal(params.row.vat),
    },
    {
      field: 'price',
      headerName: 'Price',
      headerAlign: 'left',
      align: 'right',
      renderCell: (params) => formatNominal(params.row.price),
    },
    {
      field: 'add_ons',
      headerName: 'Add Ons',
      headerAlign: 'left',
      align: 'left',
      minWidth: 100,
      renderCell: (params) => (
        <div className="px-4 h-full grid items-center">
          <AddOnsCell data={params.row.add_ons} />
        </div>
      ),
    },
    {
      field: 'add_ons_amount',
      headerName: 'Add Ons Amount',
      headerAlign: 'left',
      align: 'right',
      renderCell: (params) => formatNominal(params.row.add_ons_amount),
    },
    {
      field: 'total_sales_amount',
      headerName: 'Total Sales Amount',
      headerAlign: 'left',
      align: 'right',
      renderCell: (params) => formatNominal(params.row.total_sales_amount),
    },
  ];
  
  return (
    <div className="w-full h-full bg-white">
      <BZHelmet title="Sales Report" description="" content="" />
      <BaseMainTitle title="Sales Orders" />
      <div className="w-full p-5 relative space-y-8">
        <form className="w-full relative flex flex-wrap gap-4" onSubmit={handleSubmit(generateData)}>
          <div className="w-[25rem] h-fit ">
            <p className="font-semibold mb-[5px]">Business Unit</p>
            <Controller
              name="businessUnit"
              control={control}
              defaultValue={selectedBU}
              render={({ field }) => {
                return (
                  <SelectAutocomplete
                    variant="outlined"
                    options={businessUnitList?.map((dt) => ({ name: dt.name, value: dt.id }))}
                    defaultValue={field.value || null}
                    onChange={(e, val) => {
                      field.onChange(val);
                      handleBusinessUnitSelect(val);
                    }}
                    isLoading={isLoadingOutlet}
                  />
                );
              }}
            />
          </div>
          <div className="w-[25rem] h-fit ">
            <p className="font-semibold mb-[5px]">Outlet</p>
            <Controller
              name="outlet"
              control={control}
              defaultValue={null}
              render={({ field }) => {
                return (
                  <SelectAutocomplete
                    variant="outlined"
                    options={outletList?.map((dt) => ({ name: dt.name, value: dt.id }))}
                    defaultValue={field.value || null}
                    onChange={(e, val) => {
                      field.onChange(val);
                      handleOutletSelect(val);
                    }}
                    isLoading={isLoadingOutlet}
                  />
                );
              }}
            />
          </div>
          <div className="w-[25rem] h-fit ">
            <p className="font-semibold mb-[5px]">Payment Type</p>
            <Controller
              name="payment_type"
              control={control}
              defaultValue={null}
              render={({ field }) => {
                return (
                  <SelectAutocomplete
                    variant="outlined"
                    options={paymentTypeList?.map((dt) => ({ name: dt, value: dt }))}
                    defaultValue={field.value || null}
                    onChange={(e, val) => field.onChange(val)}
                    isLoading={isLoadingPayment}
                    disabled={paymentTypeList === null}
                  />
                );
              }}
            />
          </div>
          <div className="w-[25rem] h-fit ">
            <p className="font-semibold mb-[5px]">Transaction Type</p>
            <Controller
              name="type"
              control={control}
              defaultValue={null}
              render={({ field }) => {
                return (
                  <SelectAutocomplete
                    variant="outlined"
                    options={typeOptions}
                    defaultValue={field.value || null}
                    onChange={(e, val) => field.onChange(val)}
                    isLoading={false}
                  />
                );
              }}
            />
          </div>
          <div className="w-fit flex gap-4">
            <div className="w-fit">
              <p className="font-bold mb-[5px]">Start Period</p>
              <DatePickerFieldWithoutController
                value={startPeriod}
                onChange={(newValue) => onFilterStartDate(newValue, "startPeriod")}
                renderInput={(params) => {
                  return <TextField 
                  sx={{ width: "auto" }} 
                  size="small"
                  {...params} 
                  />;
                }}
                componentsProps={{ actionBar: { actions: ['clear'] } }}
                minDate={new Date(2010, 0, 1)}
              />
            </div>
            <div className="w-fit">
              <p className="font-bold mb-[5px]">End Period</p>
              <DatePickerFieldWithoutController
                value={endPeriod}
                onChange={(newValue) => onFilterStartDate(newValue, "endPeriod")}
                renderInput={(params) => {
                  return <TextField 
                  sx={{ width: "auto" }}
                  size="small"
                  {...params} 
                  />;
                }}
                componentsProps={{ actionBar: { actions: ['clear'] } }}
                minDate={new Date(2010, 0, 1)}
              />
            </div>
            <button
              className={`
                flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white h-[40px] mt-auto
                ${!isButtonDisabled ? 'bg-[#2C6D47] cursor-pointer ' : 'bg-[#2c6d473e] cursor-default'}
              `}
              name="generateReport"
              type="submit"
              disabled={isButtonDisabled}
            >
              Generate Report
            </button>
            <button
              className={`
                flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white h-[40px] mt-auto
                ${!isExportButtonDisabled ? 'bg-[#2C6D47] cursor-pointer ' : 'bg-[#2c6d473e] cursor-default'}
              `}
              name="exportToExcel"
              type="button"
              onClick={exportExcel}
              disabled={isExportButtonDisabled}
            >
              Excel Export
            </button>
          </div>
        </form>
        {
          isLoadingGetReport && (
            <CircularProgress />
          )
        }
        {
          report && !isLoadingGetReport && (
            <Box className="w-full h-fit overflow-x-auto">
              <DataGrid
                autoHeight
                rows={report}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: rowsPerPage,
                    },
                  },
                }}
                onPageSizeChange={(newPageSize) => setRowsPerPage(Number(newPageSize))}
                pageSizeOptions={[20, 50, 100]}
                disableRowSelectionOnClick
                className="m-auto w-fit"
              />
            </Box>
          )
        }
      </div>
    </div>
  )
}

export default OutletSalesReports
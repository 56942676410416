/* eslint-disable */
import React, { useState, useMemo, useEffect } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import toTitle from 'to-title';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, ArcElement, Legend } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, ArcElement, BarElement, Tooltip, Legend, ChartDataLabels);

function colorGenerator(length) {
  const colors = [];
  const max = 255;

  for (let i = 0; i < length; i++) {
    colors.push([`rgba(${Math.floor(Math.random() * max)}, ${Math.floor(Math.random() * max)}, ${Math.floor(Math.random() * max)}, `]);
  }

  return function (opacity) {
    return colors.map((color) => `${color + opacity})`);
  };
}

function CardChart(props) {
  const [typeChart, setTypeChart] = useState('');
  const [isShowingValue, setIsShowingValue] = useState(false);
  const charts = props.typeChart;
  const colors = useMemo(() => colorGenerator(props.data?.length), [props.data?.length]);

  const trimmedLabels = props.labels.map(label => {
    // Split berdasarkan koma dan ambil bagian pertama
    const trimmed = label.split(',')[0].trim();

    return trimmed;
});

  useEffect(() => {
    if (charts) {
      setTypeChart(charts[0]);
    }
  }, []);

  useEffect(() => {
    if (props.typeChart && props.typeChart.length) {
      setTypeChart(props.typeChart[0]);
    }
  }, [props.typeChart]);

  return (
    <div className={['text-center', props.className].join(' ')}>
      <div className={`py-2 pb-4 border-b-2 font-semibold text-base cursor-move ${props.draggableHandle}`}>{props.title}</div>
      <div className='w-full h-fit relative'>
        {charts.length > 1 && (
          <ToggleButtonGroup color="primary" value={typeChart} className="mb-3 border-b-2" exclusive onChange={(e) => setTypeChart(e.currentTarget.value)}>
            {charts.map((chart, idx) => (
              <ToggleButton
                key={idx}
                id={`chart-${idx}`}
                value={chart}
              >
                {chart.replace("_chart", "")}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}

        <div className="flex items-center px-4 top-2">
          <FormControlLabel control={<Switch checked={isShowingValue} onChange={() => { setIsShowingValue(!isShowingValue) }} />} label="Show Value" />
        </div>
      </div>

        <div style={{ width: '100%', height: '60%' }}>
          {typeChart === 'pie_chart' && (
            <Pie
              options={{
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    display: isShowingValue,
                    color: 'black',
                    formatter(value) {
                      const sum = (props.data).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                      // return value !== 0 ? `${value} (${((value / sum) * 100).toFixed(2)}%)` : ' ';
                      return value !== 0 ? `${value}` : ' ';
                    },
                    font: { size: '16px' },
                    anchor: 'end',
                    align: 'start',
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const value = context.raw;
                        const sum = props.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        const percentage = ((value / sum) * 100).toFixed(2) + '%';
                        return `${value} (${percentage})`;
                      },
                    }
                  }
                },
              }}
              data={{
                labels: props.labels,
                datasets: [
                  {
                    data: props.data,
                    backgroundColor: colors(0.2),
                    borderColor: colors(1),
                    borderWidth: 1,
                  },
                ],
              }}
            />
          )}

          {typeChart === 'bar_chart' && (
            <Bar
              style={props.chartStyle?.bar}
              options={{
                maintainAspectRatio: false,
                layout: {
                  padding: {
                    left: 20,
                    right: 20,
                    top: 20,
                    bottom: 20,
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      maxTicksLimit: 10,
                      autoSkip: true,
                    },
                  },
                },
                plugins: {
                  legend: { display: false },
                  datalabels: {
                    formatter(value) {
                      const sum = props.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                      return value !== 0 ? `${value} (${((value / sum) * 100).toFixed(2)}%)` : ' ';
                    },
                    display: isShowingValue,
                    color: 'black',
                    font: { size: '16px' },
                    anchor: 'end',
                    align: 'end',
                    clamp: true,
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const value = context.raw;
                        const sum = props.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        const percentage = ((value / sum) * 100).toFixed(2) + '%';
                        return `${value} (${percentage})`;
                      },
                    },
                  },
                },
              }}
              data={{
                labels: trimmedLabels,
                datasets: [
                  {
                    data: props.data,
                    backgroundColor: props.data.length > 4 ? ['rgba(255, 99, 132, 0.2)'] : colors(0.2),
                    borderColor: props.data.length > 4 ? ['rgba(255, 99, 132, 1)'] : colors(1),
                    borderWidth: 1,
                  },
                ],
              }}
            />
          )}
        </div>
    </div>
  );
}

export default CardChart;

import { Button, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';
import { DateRange, LocalOffer } from '@mui/icons-material';
import React, { useContext, useState } from 'react';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import DetailContext from '../../Projects/DetailContext';
import projectApi from '../../../../api/task/project';
import checkDueDate from '../../../../helper/checkDueDate';
import GetAvatar from '../../../../components/ui/Task/Content/GetAvatar';
import AssigneeTask from '../../../../components/ui/Task/Content/AssigneeTask';
import PopTopicAutocomplete from '../../../../components/ui/Task/ContentTask/PopTopicAutocomplete';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

const checkDueStatus = (dateComing) => {
  if (dateComing) {
    const status = checkDueDate(dateComing);
    if (status === 1) {
      return {
        style: 'border-red-500 text-red-500',
        label: 'overdue',
        status,
      };
    }
    if (status === -1) {
      return {
        style: 'border-blue-500 text-blue-500',
        label: 'few more days',
        status,
      };
    }
    if (status === 0) {
      return {
        style: 'border-green-500 text-green-500',
        label: 'today',
        status,
      };
    }
  }

  return {
    style: 'border-gray-500 text-gray-500',
    label: 'overdue',
    status: '',
  };
};

export default React.memo(function Topic({ section, topic }) {
  const activeUser = useSelector((state) => state.auth.user);

  const { id: idSection } = section;
  const { id: idTopic, name: nameTopic } = topic;
  const detailContext = useContext(DetailContext);
  const { sections, dispatchProject } = detailContext;

  const [selectedStatus, setSelectedStatus] = useState(idSection);
  const [dueAt, setDueAt] = useState(topic?.due_at);
  const [anchorDate, setAnchorDate] = useState(false);
  const [anchorAssignee, setAnchorAssignee] = useState(null);
  const [anchorTType, setAnchorTType] = useState(null);

  const handleReorderTask = (newTasks, sectionId) => {
    const { reorderSectionTopic } = projectApi();
    const topicIds = [];
    newTasks.forEach((sec) => {
      topicIds.push(sec.id);
    });
    reorderSectionTopic(sectionId, { topic_ids: topicIds })
      .then((res) => {
        if (res.status === 200) {
          dispatchProject('GET');
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const setReorderTask = (newTasks, sectionId, isTerminate = false) => {
    if (!isTerminate) {
      const newSection = sections;
      const currSection = newSection.filter((s) => s.id === sectionId);
      currSection[0].topics = newTasks;
      handleReorderTask(newTasks, sectionId);
    }
    handleReorderTask(newTasks, sectionId);
  };

  const onChangeTaskStatus = (value, currTopic) => {
    if (value) {
      const selectedSection = sections.filter((sec) => sec.id === value);
      if (selectedSection && selectedSection.length) {
        const existTopic = currTopic;

        const topicsDestination = [...selectedSection[0].topics];
        topicsDestination.push(existTopic);

        setReorderTask(topicsDestination, value, true);
        setSelectedStatus(value);
      }
    }
  };

  const handleTopicUpdate = (data) => {
    const { updateSectionTopic } = projectApi();

    updateSectionTopic(topic.id, data)
      .then((res) => {
        setAnchorDate(false);
        if (res.status === 200) {
          detailContext.dispatchProject('GET');
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const onAssigneeChange = (event, dataOption) => {
    if (event && dataOption) {
      handleTopicUpdate({ assignee: dataOption.id });
    }
    if (event && !dataOption) {
      handleTopicUpdate({ assignee: null });
    }
    setAnchorAssignee(null);
  };

  const handleAssignee = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorAssignee(event.currentTarget);
  };

  const onTopicTypeChange = (event, dataOption) => {
    if (event && dataOption) {
      handleTopicUpdate({ topic_type: dataOption.id });
    }
    if (event && !dataOption) {
      handleTopicUpdate({ topic_type: null });
    }
    setAnchorTType(null);
  };

  const handleTopicType = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorTType(event.currentTarget);
  };

  const dueStatus = checkDueStatus(topic?.due_at);

  const openAssignee = Boolean(anchorAssignee);
  const idAssignee = openAssignee ? 'simple-popover' : undefined;

  const openTType = Boolean(anchorTType);
  const idTType = openTType ? 'simple-popover' : undefined;

  return (
    <div key={`topic${idTopic}`} className="w-full relative overflow-x-hidden h-12 flex items-center">
      <div className="w-2/5 flex border-r border-y px-3 py-2 h-[42px] space-x-2">
        <div className="w-20">
          <LightTooltip title={topic.topic_type?.name || 'Topic type'} placement="bottom">
            <Button
              style={{ color: topic.topic_type?.color }}
              className="w-full p-0 truncate text-xs text-left border border-solid rounded-full border-gray-300 bg-opacity-50 normal-case text-bz-greenLight"
              onClick={handleTopicType}
            >
              {topic.topic_type?.name || 'Type'}
            </Button>
          </LightTooltip>
          <PopTopicAutocomplete
            config={{ label: 'Topic type', placeholder: 'type' }}
            id={idTType}
            open={openTType}
            anchor={anchorTType}
            setAnchor={setAnchorTType}
            onChange={onTopicTypeChange}
            cardData={topic}
            field="topic_type"
            options={detailContext.types}
          />
        </div>
        <div className="w-full px-2 text-left overflow-x-hidden">
          <LightTooltip title={nameTopic}>
            <p className="truncate">{nameTopic}</p>
          </LightTooltip>
        </div>
      </div>
      <div className="w-3/5 border-y px-3 py-1 h-[42px] flex">
        <div className="w-2/5">
          <Select
            labelId="select-status-label"
            id="select-status"
            displayEmpty
            value={selectedStatus}
            onChange={(e) => onChangeTaskStatus(e.target.value, topic)}
            input={<OutlinedInput />}
            style={{ width: 250 }}
            MenuProps={{ PaperProps: { style: { width: 250 } } }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem disabled value="">
              <em>Status</em>
            </MenuItem>
            {sections.map((sec, i) => (
              <MenuItem key={`${sec.name + i}`} value={sec.id} className="px-4 py-2">
                {sec.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        {/* <div className="w-1/3 flex items-center pl-2 pr-4">{getAvatar(topic, () => {}, true)}</div> */}
        <div className="w-1/4 flex items-center pl-2 pr-4">
          <GetAvatar data={topic} onClick={handleAssignee} isName />
          <AssigneeTask
            idAssignee={idAssignee}
            openAssignee={openAssignee}
            anchorAssignee={anchorAssignee}
            assigneeList={detailContext.users || []}
            topicData={topic}
            config={{
              activeUser,
              setAnchorAssignee,
              onAssigneeChange,
            }}
          />
        </div>
        <div className="w-1/3 flex items-center">
          <div className="w-1/2 pl-4 px-2">
            <div
              onClick={() => {
                setAnchorDate(true);
              }}
              aria-hidden
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label=""
                  value={dueAt}
                  open={anchorDate}
                  closeOnSelect
                  disableOpenPicker
                  onClose={() => {
                    setAnchorDate(false);
                  }}
                  // onClick={(event) => {
                  //   if (event && event.stopPropagation) event.stopPropagation();
                  // }}
                  onChange={(newValue) => {
                    setDueAt(newValue);
                    handleTopicUpdate({ due_at: newValue });
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <div className="flex items-center">
                      <div ref={inputRef} {...inputProps}>
                        <Button style={{ minWidth: 24, minHeight: 24 }} className={['flex items-center py-0.5 px-1 border border-dashed rounded-full', dueStatus?.style].join(' ')}>
                          {topic.due_at ? (
                            <LightTooltip title={dueStatus.label} placement="bottom">
                              <span style={{ fontSize: 10 }} className={['text', dueStatus?.style].join(' ')}>
                                {dueStatus?.status === 0 ? dueStatus.label : format(new Date(topic.due_at), 'MMM d')}
                              </span>
                            </LightTooltip>
                          ) : (
                            <DateRange fontSize="24" style={{ color: 'var(--weak)' }} />
                          )}
                        </Button>
                      </div>
                      {InputProps?.endAdornment}
                    </div>
                  )}
                  PaperProps={{ sx: { backgroundColor: '#fff' } }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="w-1/2 pl-4 pr-2 flex space-x-2">
            <div className="flex space-x-2">
              {topic.labels &&
                topic.labels.length > 0 &&
                topic.labels.map((lbl) => (
                  <LightTooltip key={`${lbl.name + lbl.color}`} title={lbl.name} placement="bottom">
                    <LocalOffer fontSize="14" style={{ color: lbl.color }} />
                  </LightTooltip>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

import React, { Suspense, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, ButtonBase, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

function ConfirmDialog(props) {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)} color="inherit">
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function TableContact({ rows, onEditClick, onDeleteClick, role }) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmOpen, setConfirmOpen] = useState(null);

  rows?.map((item) => {
    item.memberName = item?.member?.name;
    let projectNames = ``;
    if (item?.project_names?.length > 0) {
      item?.project_names.map((names, index) => {
        projectNames += `${names}${((item?.project_names?.length ?? 0) - 1) == index || item?.project_names?.length == 1 ? '' : ','
          }`;
      })
    }

    item.projectNames = projectNames;
    return item;
  })
  const { isSuperUser, userGroup } = role;

  const renderAction = (dt) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase className="bz-btn-tb" onClick={() => onEditClick(dt)}>
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => setConfirmOpen(dt)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'position',
      headerName: 'Position',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'memberName',
      headerName: 'Company',
      flex: 1,
    },
    {
      field: 'projectNames',
      headerName: 'Project Names',
      flex: 1,
      // renderCell: (params) => {
      //   if (params.value != '') {

      //     // return <div className='h-9 overflow-y-scroll' dangerouslySetInnerHTML={{ __html: params.value }} />
      //     return params.value
      //   }
      // }
    },
  ];

  if (isSuperUser || [8, 10].some((g) => userGroup.includes(g))) {
    columns.push({
      field: 'id',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return renderAction(params.row)
      },
    });
  }

  return (
    <Suspense fallback={<CircularProgress />}>
      <DataGrid autoHeight rows={rows} columns={columns} pageSize={rowsPerPage} onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)} rowsPerPageOptions={[10, 20, 50]} />
      <ConfirmDialog title="Delete Contact" open={!!confirmOpen} setOpen={setConfirmOpen} onConfirm={() => onDeleteClick(confirmOpen)}>
        Are you sure you want to delete this contact?
      </ConfirmDialog>
    </Suspense>
  );
}

import React from 'react';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { fetchProjectDetail } from '../../../hooks/useProjectsData';
import ProjectForm from '../../../components/Form/Task/ProjectForm';
import request from '../../../helper/axiosUtil';
import listUp from '../../../assets/Listup.png';
import listdown from '../../../assets/Listdown.png';

export default function EditProject() {
  const { projectId } = useParams();
  const qClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data: ProjectData } = useQuery(['project', { projectId }], fetchProjectDetail);
  // console.log(ProjectData);

  const updateProjects = async (data) =>
    request({
      url: `/project-management/projects/${projectId}/`,
      method: 'patch',
      data,
      headers: { 'Content-Type': 'application/json' },
    });

  const { mutateAsync: updateProject } = useMutation(updateProjects, {
    onSuccess: (e) => {
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onUpdateProject = async (data) => {
    await updateProject(data);
    qClient.invalidateQueries('project');
  };
  return (
    <div className="w-full flex items-center justify-center h-[calc(100vh_-_80px)] ">
      <div className="drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] max-w-[900px] bg-[#101A5F]">
        <div className="">
          <img src={listUp} alt="" className="" />
        </div>
        <div className="max-w-[600px] bg-white p-8 mx-auto rounded-lg">
          <div className="">Edit Project</div>
          <ProjectForm onFormSubmit={onUpdateProject} defaultValues={ProjectData?.data} key={ProjectData?.data?.id} />
        </div>
        <img src={listdown} alt="" className="" />
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { Box, Checkbox, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
  Download as DownloadIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  ArrowBack,
} from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import ModalWrapper from '@/components/ui/ModalWrapper';
import OutletPairCollections from '@/components/Form/Sales/OutletPairCollections';
import { handleModal } from '@/store/slices/modalSlice';
import POSApi from '@/api/pos/posApi';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { convertImgToBase64, convertNormalImgToBase64, pdfHeader } from '@/utils/Pdf/pdfHeader';
import { pdfFooter } from '@/utils/Pdf/pdfFooter';
import logo from '@/assets/logo.png';
import generateAutoTableConfig from '@/utils/Pdf/generateAutoTableConfig';

const { getAllProductsByCollections } = POSApi();

function CollectionsTab({ outletCollections, SelectForm, POSApi, OutletDetail, outletId, outletData }) {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const activeClient = useSelector((state) => state.client.activeDetailClient);
  const dispatch = useDispatch();

  // Schedule options
  const scheduleOptions = [
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' },
    { label: 'Sunday', value: 'Sunday' },
  ];

  const [outletCollectionChange, setOutletCollectionChange] = useState(null);
  const [selected, setSelected] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const SubmitUnpairCollectionById = () => {
    const { unpairCollectionById, bulkUnpairCollections } = POSApi();

    try {
      if (selected.length > 1) {
        const dataSelected = {
          outlet_collection_ids: selected,
        };
        bulkUnpairCollections(dataSelected)
          .then(() => {
            OutletDetail();
            setSelected([]);
            Swal.fire({
              title: 'Success !',
              text: 'Successfully unpaired selected Collections !',
              icon: 'success',
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        unpairCollectionById(selected[0])
          .then(() => {
            OutletDetail();
            setSelected([]);
            Swal.fire({
              title: 'Success !',
              text: 'Successfully unpaired Collection !',
              icon: 'success',
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateOneOutletSchedule = () => {
    const { updateOutletCollectionScheduleById } = POSApi();

    const newData = {
      configuration: outletCollectionChange.configuration,
    };

    try {
      updateOutletCollectionScheduleById(outletCollectionChange.id, newData)
        .then(() => {
          OutletDetail();
          setOutletCollectionChange(null);
          Swal.fire({
            title: 'Success !',
            text: 'Successfully update Collection Schedule !',
            icon: 'success',
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const SubmitPairCollections = (listCollection, Schedules) => {
    const { bulkPairCollections } = POSApi();

    const newData = listCollection.map((collectionId) => ({
      outlet: +outletId,
      collection: collectionId,
      configuration: Schedules.length > 0 ? { week_days: Schedules } : { week_days: [] },
    }));

    try {
      bulkPairCollections(newData)
        .then((res) => {
          if (res.status === 200) {
            OutletDetail();
            Swal.fire({
              title: 'Success !',
              text: 'Successfully unpaired Collection !',
              icon: 'success',
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectAllClick = (event) => {
    if (selected.length === outletCollections.length) {
      setIsAllSelected(false);
      setSelected([]);
      return;
    } else {
      const newSelecteds = outletCollections?.map((n) => n.id);
      setIsAllSelected(true);
      setSelected(newSelecteds);
      return;
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const handleChangeSchedule = (event) => {
    setOutletCollectionChange((dt) => {
      return {
        ...dt,
        configuration: {
          ...dt.configuration,
          week_days: event.target.value,
        },
      };
    });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const createPdfExport = async (source) => {
    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
      compress: true,
    });
    const logo = activeClient.logo;
    const name = activeClient.name;

    await pdfHeader(doc, { logo, name, selected, outletCollections, outletData });

    const pageWidth = doc.internal.pageSize.getWidth();
    // const textX = (pageWidth - doc.getTextWidth(outletData.name)) / 2;
    // doc.text(outletData.name, textX, 39);

    // const oCollections = selected.map((id) => outletCollections.find((o) => o.id === id)?.collection?.name || '')
    // const textOC = oCollections?.join(', ')
    // const textOColl = (pageWidth - doc.getTextWidth(textOC)) / 2;
    // doc.text(textOC, textOColl, 44);

    const imgThumbnail = await convertImgToBase64(logo);

    let limit = 4;
    const cellW = pageWidth / 4 - 5;
    const cellH = 30;
    let baseTopY = 10;
    const baseLeftX = 15;

    let currIdx = 1;
    let currIdY = 1;
    for (const d of source) {
      const thumbnail = d.thumbnail;
      let imgItem = imgThumbnail;
      if (thumbnail) {
        const imgData = await convertImgToBase64(thumbnail);
        imgItem = imgData;
      }

      const { url, printedW, printedH } = imgItem;

      const marginX = (currIdx - 1) * cellW + baseLeftX;
      const marginY = baseTopY + currIdY * cellH + currIdY * 15;
      doc.addImage(url, 'PNG', marginX + (((cellW - 5) - printedW) / 2), marginY, printedW, printedH, '', 'FAST');
      doc.text(d.name, marginX, marginY + 35, { maxWidth: cellW - 10 });

      if (currIdx === limit) {
        currIdx = 1;
        if (currIdY === 5) {
          doc.addPage('a4', 'p');
          await pdfHeader(doc, { logo, name, selected, outletCollections, outletData });
          currIdY = 1;
          currIdx = 1;
        } else {
          currIdY++;
        }
      } else {
        currIdx++;
      }
    }
    source.forEach(async (d, i) => {});

    pdfFooter(doc);
    doc.save(`${outletData?.name}_Menu.pdf`);
    setIsLoading(false);
  };

  const generatePDFMenu = () => {
    if (!selected.length) return;
    setIsLoading(true);
    getAllProductsByCollections({ outlet_collections: selected, max_size: true, ordering: 'name' })
      .then((res) => {
        const { data } = res;
        if (data && res.status === 200) {
          // createPdfExport(data.results.slice(0, 4));
          createPdfExport(data.results);
        }
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <>
      <div className="w-full flex justify-end mb-4 items-center gap-x-2">
        <button
          className={`flex items-center justify-center btn ${selected.length ? 'bg-[#2C6D47]' : 'bg-[rgba(44,109,71,0.7)]'} p-2 text-white rounded-lg border cursor-pointer`}
          name=""
          disabled={!selected.length}
          onClick={generatePDFMenu}
        >
          Generate PDF Menu
          {isLoading && <CircularProgress className="ml-2" size={18} sx={{ color: '#fff' }} />}
        </button>
        <button
          className={`
              flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
              ${selected.length ? 'bg-[#FC581D] cursor-pointer ' : 'bg-[#FC581D26] cursor-default'}
            `}
          name="unpairCollection"
          type="button"
          disabled={selected.length === 0 ? true : false}
          onClick={SubmitUnpairCollectionById}
        >
          Unpair Selected
        </button>
        <button
          className="btn bg-[#2C6D47] p-2 text-white rounded-lg border cursor-pointer"
          name="collectionPair"
          type="button"
          onClick={() =>
            dispatch(
              handleModal({
                modalId: clientId,
                componentName: 'collectionPair',
                modalData: {
                  outletId: outletId,
                  pairComponentIds: outletCollections.length ? outletCollections.map((dt) => dt.collection.id) : [],
                },
              })
            )
          }
        >
          Add Product Collection
        </button>
      </div>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < outletCollections.length}
                    checked={outletCollections.length > 0 && selected.length === outletCollections.length}
                    onClick={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  Collection
                </TableCell>
                <TableCell align="center" className="!font-bold" width={800}>
                  Schedule
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {outletCollections.length > 0 &&
                outletCollections.map((coll, idx) => {
                  const collectionName = coll.collection.name;
                  const weekDays = coll?.configuration?.week_days || [];
                  const isEditing = outletCollectionChange?.id === coll.id;
                  const defaultWeekDays = isEditing ? outletCollectionChange.configuration?.week_days : weekDays;
                  const isItemSelected = isSelected(coll.id);
                  const labelId = `enhanced-table-checkbox-${idx}`;

                  return (
                    <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={coll.id} selected={isItemSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox onClick={(event) => handleClick(event, coll.id)} color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                      </TableCell>
                      <TableCell align="left">{collectionName || ''}</TableCell>
                      <TableCell align="left" className="flex space-x-2 items-center">
                        <SelectForm
                          defaultValue={defaultWeekDays}
                          options={scheduleOptions}
                          onChangeValue={handleChangeSchedule}
                          name="schedule"
                          control={control}
                          multiple={true}
                          disabled={!isEditing}
                        />
                        <div className="flex gap-x-2 w-[60px]">
                          {outletCollectionChange?.id !== coll.id ? (
                            <EditIcon
                              className="cursor-pointer"
                              onClick={() => {
                                setOutletCollectionChange(coll);
                              }}
                            />
                          ) : (
                            <>
                              <SaveIcon
                                color="success"
                                className="cursor-pointer"
                                onClick={() => {
                                  updateOneOutletSchedule();
                                }}
                              />
                              <CancelIcon
                                className="cursor-pointer text-red-500"
                                onClick={() => {
                                  setOutletCollectionChange(null);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </Box>
      <ModalWrapper componentName="collectionPair" header="Prodect Collection List" maxWidth="xl">
        <OutletPairCollections onSubmit={SubmitPairCollections} />
      </ModalWrapper>
    </>
  );
}

export default CollectionsTab;

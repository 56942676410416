import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, Box } from '@mui/material';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const getColorByDay = (value, maxValue) => {
  const percentage = value / maxValue;
  const redValue = value > 0 ? Math.round(180 * percentage) : 0;

  return `rgb(255, ${255 - redValue}, ${255 - redValue})`;
};

const formatNominal = (value) => {
  if (value === null || value === undefined) {
    return '';
  }

  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

function DashboardHeatmap({ allSummary }) {
  const [mainTabValue, setMainTabValue] = useState('On the spot');

  const handleChangeMainTabs = (_, newValue) => {
    setMainTabValue(newValue);
  };

  const currentData = allSummary?.find((item) => item?.group === mainTabValue) || null;

  const datas = {};
  const hours = Array.from({ length: 24 }, (_, i) => `${String(i).padStart(2, '0')}:00`);
  const daysCount = daysOfWeek.length;

  hours.forEach((hour) => {
    datas[hour] = Array(daysCount).fill(0);
  });

  if (currentData && currentData.data) {
    currentData.data.forEach((dayData) => {
      const dayIndex = daysOfWeek.indexOf(dayData.created_day);
      dayData.orders.forEach((order) => {
        const hourKey = `${String(order.created_hour).padStart(2, '0')}:00`;
        datas[hourKey][dayIndex] = order.order_count;
      });
    });
  }

  let max = 0;
  for (const hour in datas) {
    const values = datas[hour];
    const hourMax = Math.max(...values);
    if (hourMax > max) {
      max = hourMax;
    }
  }

  return (
    <div>
      <div>
        <Box sx={{ width: '100%', position: 'relative' }}>
          <Box sx={{ marginBottom: 2 }}>
            <Tabs
              value={mainTabValue}
              onChange={handleChangeMainTabs}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: '#2C6D47',
                },
              }}
              sx={{
                '.MuiButtonBase-root': {
                  fontWeight: '600',
                },
                '.Mui-selected': {
                  color: '#2C6D47!important',
                },
              }}
            >
              <Tab value="On the spot" label="PoS" />
              <Tab value="Customer App" label="Customer App" />
            </Tabs>
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={150}></TableCell>
                {daysOfWeek.map((day) => (
                  <TableCell key={day} align="center" style={{ fontWeight: 'bold' }}>
                    {day}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(datas).map((time) => (
                <TableRow key={time}>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    style={{
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                    sx={{ padding: '5px' }}
                  >
                    {time}
                  </TableCell>
                  {datas[time].map((value, index) => {
                    return (
                      <TableCell
                        key={index}
                        align="center"
                        style={{
                          backgroundColor: getColorByDay(value, max),
                          color: 'black',
                        }}
                        sx={{ padding: '5px' }}
                      >
                        {formatNominal(value)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default DashboardHeatmap;

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const showSwalError = ({ text }) => {
    withReactContent(Swal).fire({
        icon: "error",
        title: "Oops...",
        text: text,
    })
}

export default showSwalError;
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { handleModal, handleModalNested } from '../../store/slices/modalSlice';
import { taskAdd } from '../../store/slices/taskCrudSlice';
import { resetAsset } from '../../store/slices/assetCrudSlice';

export default function ModalWrapper({ isOpen, header, children, maxWidth, isHeader, componentName, isNested, extraClose, dialogContentSx }) {
  const isModal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [onClose, setOnClose] = useState(false);
  useEffect(() => {
    if (onClose) {
      isNested ? dispatch(handleModalNested({ modalIdNested: '', componentNameNested: '' })) : dispatch(handleModal({ modalId: '', componentName: '' }));
      if (Boolean(extraClose)) {
        extraClose();
      }
      dispatch(taskAdd({ name: '', project: '', section: '' }));
      dispatch(resetAsset());
      setOnClose(!onClose);
    }
  }, [onClose]);

  return (
    <Dialog
      open={(isModal.isOpen && isModal.componentName === componentName) || (isModal.isOpenNested && isModal.componentNameNested === componentName)}
      maxWidth={maxWidth}
      fullWidth
      className="top-[40px] "
      sx={{ zIndex: 1300 }}
    >
      <DialogTitle className={`${isHeader === false ? 'hidden' : 'block'}`}>
        <div style={{ display: 'flex' }}>
          {header}
          <button onClick={() => setOnClose(true)} type="button" className="absolute right-8">
            <CloseIcon />
          </button>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
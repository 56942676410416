import { Box, Button, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Edit } from '@mui/icons-material';

import BaseInputValidation from '../../Input/Base/BaseInputValidation';
import ModalWrapper from '../../ui/ModalWrapper';

import BaseNewLocation from '../../Modal/Base/BaseNewLocation';
import { handleModal } from '../../../store/slices/modalSlice';
import crmApi from '../../../api/CRM/CRMCustomers';
import AddContact from './AddContact';
import AddPartner from './AddPartner';

const { getCRMProjectProfiles } = crmApi();

const labelStyle = {
  top: '-8px',
  '&.Mui-focused': { top: -8, background: '#fff' },
  '&.MuiFormLabel-filled, &.MuiInputLabel-shrink': { top: 0, background: '#fff' },
};

export default function StaticAttribute({ form, state, action }) {
  const activeClient = useSelector((s) => s.client.activeClient);
  const dispatch = useDispatch();
  const { control, errors, register, watch } = form;
  // eslint-disable-next-line no-unused-vars
  const { dataContact, projectContact, projectProfile, location, openLocation, isEdit, dataBusinessUnit, projectBusinessUnit, projectPartner, dataMembers } = state;
  const { handleChangeContact, refetchContact, setOpenLocation, setOpenLocationSelector, setProjectProfile, pairLocation, setProjectBusinessUnit, handleChangePartners, refetchPartner } = action;

  const { data, isFetching } = useQuery({
    queryKey: ['crm-project-profiles'],
    queryFn: () => getCRMProjectProfiles({ client: activeClient, max_size: true, ordering: 'name' }),
    refetchOnWindowFocus: false,
  });

  const renderActionLocation = (
    <div className={["flex gap-2", isEdit && location ? 'flex-col-reverse items-start ml-4' : ''].join(' ')}>
      <button type="button" className="w-36 flex h-10 items-center text-center btn btn-primary rounded-xl border-0 justify-center bg-[#2C6D47]" onClick={() => setOpenLocationSelector(true)}>
        <p className="text-white">Select Location</p>
      </button>
      <button 
        type="button" 
        className="w-28 flex h-10 items-center text-center btn btn-primary rounded-xl border-0 justify-center bg-[#2C6D47]" 
        onClick={() => {
          setOpenLocation('');
          dispatch(handleModal({ modalId: '', componentName: 'BaseNewLocation' }));
        }}
      >
        <p className="text-white">Add New</p>
      </button>
      {/* <Button variant="contained" size="small" className="w-36 h-10" onClick={() => setOpenLocation(true)}>
        
      </Button> */}
      {/* <Button
        variant="contained"
        size="small"
        className={["w-28 h-10", isEdit && location ? 'w-36' : ''].join(' ')}
        onClick={() => {
          setOpenLocation('');
          dispatch(handleModal({ modalId: '', componentName: 'BaseNewLocation' }));
        }}
      >
        Add New
      </Button> */}
    </div>
  );
  const wName = watch('name');
  const latitude = useMemo(() => location ? parseFloat(location.latitude) : 0, [location]);
  const longitude = useMemo(() => location ? parseFloat(location.longitude) : 0, [location]);

  return (
    <>
      <div className="w-full flex gap-x-4 mb-4">
        <TextField
          type="text"
          label="Name"
          name="name"
          className="w-1/2"
          required
          error={errors && errors.name}
          size="small"
          {...register('name', { required: true })}
          InputLabelProps={{ shrink: !!wName }}
        />
        <div className="flex gap-x-4 w-1/2">
          <BaseInputValidation control={control} name="location" label="Location" errors={errors} config={{ disabled: true }} />
          {isEdit && !location && renderActionLocation}
          {!isEdit && renderActionLocation}
        </div>
      </div>
      {isEdit && location && (latitude !== 0 || longitude !== 0) && (
        <div className="relative flex w-full mb-4">
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '20vh' }}
            center={{ lat: latitude, lng: longitude }}
            zoom={10}
            className="border-2 "
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
              disableDoubleClickZoom: true
            }}
          >
            <IconButton
              aria-label="edit location"
              color="default"
              className="absolute top-2 right-2 border border-solid border-gray-300 rounded-md bg-white hover:bg-white hover:opacity-90"
              onClick={() => {
                setOpenLocation('edit');
                dispatch(handleModal({ modalId: '', componentName: 'BaseNewLocation' }));
              }}
            >
              <Edit className="" />
            </IconButton>
            <Marker position={{ lat: latitude, lng: longitude }} />
          </GoogleMap>
          {renderActionLocation}
        </div>
      )}
      <div className="w-full flex gap-x-4 mb-4">
        {!dataBusinessUnit ? (
          <Skeleton className="w-1/2 h-10" />
        ) : (
          <FormControl fullWidth className="w-1/2">
            <InputLabel htmlFor="project-profile" sx={labelStyle} shrink={!!projectBusinessUnit}>
              Business Unit
            </InputLabel>
            <Select
              id="project-profile"
              value={projectBusinessUnit}
              className="h-10"
              sx={{ width: '100%', px: 0.2, py: 0.2 }}
              onChange={(e) => setProjectBusinessUnit(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">-</MenuItem>
              {dataBusinessUnit.data?.length > 0 &&
                dataBusinessUnit.data?.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {!dataMembers ? (
          <Skeleton className="w-1/2 h-10" />
        ) : (
          <div className="w-1/2 flex gap-4">
            <FormControl fullWidth>
              <InputLabel htmlFor="project-profile" sx={labelStyle} shrink={!!projectPartner?.length}>
                Partners
              </InputLabel>
              <Select
                id="project-partners"
                multiple
                value={projectPartner}
                className="min-h-[2.5rem] h-auto"
                sx={{ width: '100%', px: 0.2, py: 0.2 }}
                onChange={handleChangePartners}
                displayEmpty
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      return <Chip key={value.id} label={value.name} className="h-6" />;
                    })}
                  </Box>
                )}
              >
                {dataMembers.data?.results?.length > 0 &&
                  dataMembers.data.results?.map((p) => (
                    <MenuItem key={p.id} value={p}>
                      {p.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <IconButton
              aria-label="add partner"
              color="success"
              className="border border-solid border-gray-300 rounded-md bg-white"
              onClick={() => dispatch(handleModal({ modalId: '', componentName: 'AddNewPartner' }))}
            >
              <AddIcon />
            </IconButton>
          </div>
        )}
      </div>

      <div className="w-full flex gap-x-4">
        {isFetching ? (
          <Skeleton />
        ) : (
          <FormControl fullWidth className="w-1/2">
            <InputLabel htmlFor="project-profile" sx={labelStyle} shrink={!!projectProfile}>
              Profile
            </InputLabel>
            <Select
              id="project-profile"
              value={projectProfile}
              className="h-10"
              sx={{ width: '100%', px: 0.2, py: 0.2 }}
              onChange={(e) => setProjectProfile(e.target.value)}
              displayEmpty
              disabled={!!isEdit}
            >
              {data?.length > 0 &&
                data?.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <div className="w-1/2 flex gap-4">
          {!isEdit &&
            <>
              <FormControl fullWidth>
                <InputLabel htmlFor="project-profile" sx={labelStyle} shrink={!!projectContact?.length}>
                  Contact
                </InputLabel>
                <Select
                  id="project-contacts"
                  multiple
                  value={projectContact}
                  className="min-h-[2.5rem] h-auto"
                  sx={{ width: '100%', px: 0.2, py: 0.2 }}
                  onChange={handleChangeContact}
                  displayEmpty
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => {
                        return <Chip key={value.id} label={value.name} className="h-6" />;
                      })}
                    </Box>
                  )}
                >
                  {dataContact?.length > 0 &&
                    dataContact?.map((p) => (
                      <MenuItem key={p.id} value={p}>
                        {p.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <IconButton
                aria-label="add contact"
                color="success"
                className="border border-solid border-gray-300 rounded-md bg-white"
                onClick={() => dispatch(handleModal({ modalId: '', componentName: 'AddNewContact' }))}
              >
                <AddIcon />
              </IconButton>
            </>
          }
        </div>
      </div>

      <ModalWrapper componentName="BaseNewLocation" header={openLocation === 'edit' ? 'Edit Location' : 'Add New Location'} maxWidth="sm">
        <BaseNewLocation pairLocation={pairLocation} type={openLocation} defaultLocation={location} />
      </ModalWrapper>
      {!isEdit &&
        <ModalWrapper componentName="AddNewContact" header="Add New Contact" maxWidth="sm">
          <AddContact activeClient={activeClient} reload={refetchContact} type="CRM Project" />
        </ModalWrapper>
      }
      <ModalWrapper componentName="AddNewPartner" header="Add New Partner" maxWidth="">
        <AddPartner activeClient={activeClient} callback={refetchPartner} type="CRM Project" />
      </ModalWrapper>
    </>
  );
}

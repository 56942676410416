import axiosInstances from '../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function section() {
  const { axiosInstance } = axiosInstances();

  async function getPages(params) {
    const method = 'get';
    const url = `${baseURL}pages/?template=${params.template}&language=${params.language}&max_size=${params.max_size}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getPageDetail(pageId) {
    const method = 'get';
    const url = `${baseURL}pages/${pageId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function getPageStylesCatalogSetting() {
    const method = 'get';
    const url = `${baseURL}page_styles/catalog/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function getPageNames(params) {
    const method = 'get';
    const url = `${baseURL}pages/get_page_names/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addPage(datas = {}) {
    const data = {
      parent: datas.parent,
      template: datas.template,
      has_params: datas.has_params,
      page_languages: datas.page_languages,
      name: datas.name,
      page_type: datas.page_type,
      param_qty: datas.param_qty,
      widgets: datas.widgets,
    };

    const method = 'post';
    const url = `${baseURL}pages/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function updatePage(pageId, datas = {}) {
    const data = {
      parent: datas.parent,
      template: datas.template,
      has_params: datas.has_params,
      page_languages: datas.page_languages,
      name: datas.name,
      page_type: datas.page_type,
      param_qty: datas.param_qty,
      widgets: datas.widgets,
    };

    const method = 'patch';
    const url = `${baseURL}pages/${pageId}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deletePage(params) {
    const method = 'delete';
    const url = `${baseURL}pages/${params}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  return {
    getPages,
    getPageDetail,
    getPageStylesCatalogSetting,
    getPageNames,
    addPage,
    updatePage,
    deletePage,
  };
}

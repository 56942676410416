import React, { useEffect, useState } from 'react';
import { Box, ButtonBase, Input } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';

import projectApi from '../../../../api/task/project';
import ModalLabel from '../../../../components/Modal/Task/ModalSetting/Label';

export default function LabelSetting({ data, getLabels }) {
  const [rows, setRows] = useState(false);
  const [open, setOpen] = useState({
    status: false,
    mode: true,
    data: false,
  });

  const { addLabel, updateLabel, deleteLabel } = projectApi();
  const onCreateLabel = (value) => {
    if (value) {
      addLabel(value)
        .then(() => {
          getLabels();
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const onChangeLabel = (value, id) => {
    if (id && value) {
      updateLabel(id, value)
        .then(() => {
          getLabels();
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const onEditClick = (dataActive) => {
    setOpen({
      status: true,
      mode: false,
      data: dataActive,
    });
  };

  const onDeleteClick = (id) => {
    if (id) {
      deleteLabel(id)
        .then(() => {
          getLabels();
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const renderInput = (value) => {
    return <Input type="color" className="w-8 h-8" value={value} disabled />;
  };

  const renderAction = (dt) => {
    return (
      <Box>
        <ButtonBase className="bz-btn-tb" onClick={() => onEditClick(dt)}>
          <Edit fontSize="24" />
        </ButtonBase>
        <ButtonBase className="bz-btn-tb" onClick={() => onDeleteClick(dt.id)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  useEffect(() => {
    if (!rows && data) {
      setRows(data);
    }
  }, [rows, data]);

  useEffect(() => {
    if (rows && rows !== data) {
      setRows(data);
    }
  }, [rows, data]);

  const columns = [
    { field: 'name', headerName: 'Name', minWidth: 212 },
    {
      field: 'color',
      headerName: 'Color',
      description: 'This column has a color getter and is not sortable.',
      sortable: false,
      width: 96,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderInput(params.row.color, params.row.id),
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      width: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderAction(params.row),
    },
  ];

  const handleClickOpen = () => {
    setOpen({
      status: true,
      mode: true,
      data: false,
    });
  };

  const handleClose = () => {
    setOpen({
      status: false,
      mode: true,
      data: false,
    });
  };
  return (
    <div className="w-full h-full flex flex-col justify-center">
      <Box style={{ height: `calc((${rows.length > 10 ? 10 : rows.length} * 58px) + 100px)` }} className="w-[400px] h-[40rem] flex-flex-col space-y-4 m-auto">
        <button type="button" className="flex ml-auto rounded-xl bg-[#2C6D47] text-white px-5 py-2" onClick={handleClickOpen}>
          Create new Label
        </button>
        {rows && <DataGrid rows={rows || []} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />}
      </Box>
      <ModalLabel open={open.status} data={open} onClose={handleClose} onCreateLabel={onCreateLabel} onUpdateLabel={onChangeLabel} />
    </div>
  );
}

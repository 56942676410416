import React from 'react';
import { Person, Sports, Report, People, Work, AccountTree, Circle } from '@mui/icons-material';
import jobRoute from './JobRoute';
// import stagesRoute from './StagesRoute';
// import HumanResources from '../pages/HR/HumanResources';
// import Structure from '../pages/HR/Structure/Structure';
// import HRStructureDetail from '../pages/HR/Structure/HRStructureDetail';
import EmployeeAdd from '../pages/HR/EmployeeMaster/EmployeeAdd';

import Employee from '../pages/HR/Whistleblower/Employee';
import ReportWistle from '../pages/HR/Whistleblower/ReportWistle';
// import EmployeeDetail from '../pages/HR/Whistleblower/EmployeeDetail';
import ReportWhistleDetail from '../pages/HR/Whistleblower/ReportWhistleDetail';
import EmployeeEditMaster from '../pages/HR/EmployeeMaster/EmployeeEdit';
import EmployeeDetailMaster from '../pages/HR/EmployeeMaster/EmployeeDetail';
// import EmployeeListNew from '../pages/HR/EmployeeMaster/EmployeeListNew';
import EmployeeManagementListMaster from '../pages/HR/EmployeeMaster/EmployeeList';
import DepartmentList from '../pages/HR/Department/DepartmentList';
import UnitList from '../pages/HR/BusinessUnit/UnitList';
import EmailReport from '../pages/HR/Whistleblower/EmailReport';
import UnitAdd from '../pages/HR/BusinessUnit/UnitAdd';
import UnitDetail from '../pages/HR/BusinessUnit/UnitDetail';
import HumanResources from '@/pages/HR/HumanResources';

const hrRoute = [
  {
    name: 'Dashboard',
    element: <HumanResources />,
    icon: <Circle />,
    privilege: [15, 18, 99],
    subscriptions: [10],
    path: '/hr/dashboard',
    isSidebar: true,
  },
  {
    name: 'Business Unit',
    element: <UnitList />,
    icon: <AccountTree />,
    privilege: [15, 18, 99],
    subscriptions: [10],
    path: '/hr/business-unit',
    isSidebar: true,
  },
  {
    name: 'Business Unit',
    element: <UnitAdd />,
    icon: <AccountTree />,
    privilege: [15, 18, 99],
    subscriptions: [10],
    path: '/hr/business-unit/add',
    isSidebar: false,
  },
  {
    name: 'Business Unit',
    element: <UnitDetail />,
    icon: <AccountTree />,
    privilege: [15, 18, 99],
    subscriptions: [10],
    path: '/hr/business-unit/:businessUnitId/edit',
    isSidebar: false,
  },
  {
    name: 'Department',
    element: <DepartmentList />,
    icon: <AccountTree />,
    privilege: [15, 18, 99],
    subscriptions: [10],
    path: '/hr/department',
    isSidebar: true,
  },
  {
    name: 'Employee',
    element: <EmployeeManagementListMaster />,
    icon: <Person />,
    privilege: [15, 18, 99],
    subscriptions: [10],
    path: '/hr/employee',
    isSidebar: true,
  },
  {
    name: 'Employee Add',
    element: <EmployeeAdd />,
    icon: <Person />,
    privilege: [15, 18, 99],
    subscriptions: [10],
    path: '/hr/employee/add',
    isSidebar: false,
  },

  // Master
  {
    name: 'Employee Edit',
    element: <EmployeeEditMaster />,
    icon: <Person />,
    privilege: [15, 18, 99],
    subscriptions: [10],
    path: '/hr/employee/:hrEmployeeId/edit',
    isSidebar: false,
  },
  {
    name: 'Employee Detail',
    element: <EmployeeDetailMaster />,
    icon: <Person />,
    privilege: [15, 18, 99],
    subscriptions: [10],
    path: '/hr/employee/:hrEmployeeId/detail',
    isSidebar: false,
  },

  // Master
  {
    name: 'Recruitment',
    icon: <Work />,
    privilege: [3, 5, 99],
    subscriptions: [2],
    child: jobRoute,
    isSidebar: true,
  },
  {
    name: 'Whistleblower System',
    // element: <ReportAccounting />,
    icon: <Sports />,
    privilege: [99, 15],
    subscriptions: [10],
    // path: '/whistleblower',
    child: [
      {
        name: 'Team',
        element: <Employee />,
        icon: <People />,
        privilege: [99, 15],
        subscriptions: [10],
        path: '/hr/whistleblower/team',
        isSidebar: true,
      },

      {
        name: 'Report',
        element: <ReportWistle />,
        icon: <Report />,
        privilege: [99, 15],
        subscriptions: [10],
        path: '/hr/whistleblower/report',
        isSidebar: true,
      },
      {
        name: 'Report Detail',
        element: <ReportWhistleDetail />,
        // icon: <Report />,
        privilege: [99, 15],
        subscriptions: [10],
        path: '/hr/whistleblower/report/:reportId',
        isSidebar: false,
      },
      {
        name: 'Email Report',
        element: <EmailReport />,
        icon: <Report />,
        privilege: [99, 15],
        subscriptions: [10],
        path: '/hr/whistleblower/email-report',
        isSidebar: true,
      },
    ],
    isSidebar: true,
  },
];

export default hrRoute;

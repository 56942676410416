/* eslint-disable */

import { useQueries, useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Button, Switch } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchGroups } from '../../../hooks/useUsersData';
import InputContainer from '../../Input/InputContainer';
import { useWatch } from 'react-hook-form';
import HandleWatchingState from '@/helper/HandleWatchingState';
import { CustomFetch } from '@/hooks/useGet';

function UserForm({ defaultValues, onFormSubmit, control, setValue, errors }) {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(defaultValues?.is_active);
  const [filesAvatar, setFilesAvatar] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [showCfPass, setShowCfPass] = useState(false);
  const [handlePass, setHandlePass] = useState(false);
  const [subsGroup, setSubsGroup] = useState([]);

  const [mergedAllSubs, setMergedAllSubs] = useState([]);

  const [apiAdminIds, setApiAdminIds] = useState([]);

  const subsActive = useSelector((state) => state.client.clientSubscriptions);
  const clientId = useSelector((state) => state.client.activeClient);
  const userLogin = useSelector((state) => state.auth.user.id);

  const userSubs = useSelector((state) => state.auth.isGroups);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const navigate = useNavigate();

  const subs = subsActive?.map((s) => {
    return `subscription=${s}&`;
  });

  const subId = subs.join('');
  const { data: subsData } = useQuery(['subs', { subId }], fetchGroups, {
    onSuccess: (res) => {
      const apiAdminIdss = res.data.results.filter((group) => group.name.toLowerCase().startsWith('admin')).map((group) => group.id);
      setApiAdminIds(apiAdminIdss);
    },
  });

  const results = useQueries({
    queries: subsActive?.map((sec) => ({
      queryKey: [`section-customer-${sec}`],
      queryFn: async () =>
        CustomFetch({
          url: `subscriptions/${sec}`,
        }),

      // staleTime: Infinity,
    })),
  });

  const isLoaded = results?.every((res) => res?.data?.data?.id);

  const adminIdsNew = defaultValues?.groups.filter((group) => group.name.toLowerCase().startsWith('admin')).map((group) => group.id);

  useEffect(() => {
    if (isLoaded && apiAdminIds && apiAdminIds.length > 0) {
      const cekIsAdminUser = _.intersection(apiAdminIds, userSubs || []);

      const updateGroups = (groups, groupIds, isSuperUser, idxAdmin, idxManiputale) => {
        groups.forEach((group, idx) => {
          group.gr_admin = isSuperUser ? false : true;
          group.gr_manipulate = _.intersection(groupIds, cekIsAdminUser).length > 0;
          if (idxAdmin && !idxAdmin.includes(idx)) {
            group.gr_admin = false;
          }
        });
      };

      const newArray = _.map(results, (item) => ({
        name: item.data.data.name,
        groups: item.data.data.groups,
        groupIds: item.data.data.groups.map((gr) => gr?.id),
        idxAdmin: item.data.data.groups.map((group, idx) => group.name.split(' ')[0].toLowerCase() === 'admin' && idx),
      }));

      newArray.forEach((data) => {
        updateGroups(data.groups, data.groupIds, isSuperUser, data?.idxAdmin, data?.idxManiputale);
      });

      const allSubs = newArray.map((data) => ({
        name: data.name,
        groups: data.groups,
        is_admin: _.intersection(data.groupIds, adminIdsNew || []).length > 0,
      }));

      setMergedAllSubs(allSubs);
    }
  }, [isLoaded, apiAdminIds]);

  // console.log(newArray);

  const adminIds = subsData?.data.results
    .filter((group) => {
      const firstWord = group.name.split(' ')[0];
      if (firstWord.toLowerCase() === 'admin') {
        return true;
      }
      return false;
    })
    .map((group) => group.id);

  const isAdminUser = (() => {
    return _.intersection(adminIds, userSubs || []).length > 0;
  })();

  const isRegularUser = (() => {
    if (isSuperUser || isAdminUser) {
      return false;
    }
    return true;
  })();

  const devSubs = subsData?.data?.results.filter(({ id: id1 }) => defaultValues?.groups?.some(({ id: id2 }) => id2 === id1));

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(subsGroup);
      newSelection.push(parseInt(value));
      setSubsGroup(newSelection);
      setValue('user_data.groups', newSelection);
    } else {
      const newSelection = subsGroup;
      const newSubs = newSelection.filter((sub) => parseInt(sub) !== parseInt(value));

      setSubsGroup(newSubs);
      setValue('user_data.groups', newSubs);
    }
  };

  useEffect(() => {
    if (defaultValues) {
      setSubsGroup(devSubs?.map((sub) => sub.id) || []);
    }
  }, [defaultValues]);

  const { first_name, last_name, email, user_data } = useWatch({ control });

  const nameVal = first_name ? `${first_name} ${last_name || ''}` : '';
  const watchNameVal = HandleWatchingState(nameVal);
  const watchEmailVal = HandleWatchingState(nameVal);

  useEffect(() => {
    if (nameVal) setValue('user_data.name', nameVal);
  }, [watchNameVal]);
  useEffect(() => {
    if (email && !user_data?.email) setValue('user_data.email', email);
  }, [watchEmailVal]);

  return (
    <div className="flex w-full">
      <div className="w-full">
        <div className="flex space-x-2.5">
          <div className="w-full space-y-4">
            <InputContainer name="user_data.name" control={control} label="Name" readOnly helperText="auto generated by employee first name and last name" />
            <InputContainer name="user_data.email" control={control} label="Email" readOnly helperText="auto generated by employee email" />
          </div>
        </div>

        <div className="w-full space-y-1 mt-[24px]">
          {defaultValues ? (
            <div className="">
              <Button type="button" variant="outlined" className="bz-btn" onClick={() => setHandlePass(!handlePass)}>
                Update Password
              </Button>
              {handlePass && (
                <div className="w-full space-y-1">
                  <InputContainer
                    showPass={showPass}
                    handleClickShowPassword={() => setShowPass(!showPass)}
                    type="password"
                    name="user_data.password"
                    control={control}
                    label="Password"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="w-full space-y-1">
              <InputContainer
                showPass={showPass}
                handleClickShowPassword={() => setShowPass(!showPass)}
                type="password"
                name="user_data.password"
                control={control}
                label="Password"
              />
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-[16px] mt-[32px]">
          {mergedAllSubs.map((sub, idx) => {
            const { name, groups } = sub;

            return (
              <div className="p-[24px] border">
                <div className="uppercase text-center mb-[8px] font-semibold">{name}</div>
                {groups?.map((gr) => (
                  <table className="table w-full p-[16px] border">
                    <tbody>
                      <tr key={gr.id} className="">
                        <th align="left" className="p-3">
                          {gr.name}
                        </th>
                        <td className="flex items-center p-3 justify-end">
                          <span className=" text-sm font-medium text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                          <label className="relative inline-flex items-center  cursor-pointer">
                            <label className="relative inline-flex items-center  cursor-pointer">
                              <Switch
                                value={gr.id}
                                onChange={handleChange}
                                type="checkbox"
                                className="bz-switch"
                                defaultChecked={devSubs.find((ds) => ds.id === gr.id)}
                                disabled={
                                  userLogin === defaultValues?.id
                                    ? isRegularUser
                                      ? true
                                      : isSuperUser
                                      ? false
                                      : (userSubs.includes(gr.id) && gr.gr_admin) || !gr.gr_manipulate
                                    : isSuperUser
                                    ? false
                                    : !gr.gr_manipulate
                                }
                              />
                            </label>
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Active</span>
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default UserForm;

/* eslint-disable */

import { InputBase } from '@mui/material';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';

function InputBaseContainer({
  name,
  disabled,
  control,
  type = 'text',
  label,
  showPass,
  showCfPass,
  handleClickShowPassword,
  errors,
  defaultValue,
  hidden,
  config = {},
  onFocus,
  placeholder,
  autoFocus,
  className,
  rows,
  setValue,
  readOnly = false,
  fullWidth,
}) {
  useEffect(() => {
    if (setValue) {
      setValue();
    }
  }, [setValue]);

  return (
    <div className={` bg-white ${fullWidth ? 'w-full' : ''} `}>
      {label && <div className="mb-[10px] text-[14px] font-[600] leading-[20px] capitalize">{label}</div>}
      {!defaultValue && (
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field }) => {
            return (
              <InputBase
                fullWidth
                autoFocus={autoFocus}
                // className={className}
                className={`border border-gray-300 rounded-[11px] px-4 bg-white h-[48px] ${fullWidth ? 'w-full' : ''}`}
                size="small"
                sx={{ textTransform: 'capitalize' }}
                label={label}
                // value={value}
                // defaultValue={defaultValue || ''}
                placeholder={placeholder}
                onFocus={onFocus}
                hidden={hidden}
                disabled={disabled}
                multiline={Boolean(rows)}
                rows={rows}
                margin="normal"
                error={Boolean(errors)}
                helperText={errors?.message}
                {...field}
                {...config}
              />
            );
          }}
        />
      )}
      {defaultValue && (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <InputBase
              fullWidth
              autoFocus={autoFocus}
              // className={className}
              className={`border border-gray-300 rounded-[11px] px-4 bg-white h-[48px] ${fullWidth ? 'w-full' : ''}`}
              size="small"
              sx={{ textTransform: 'capitalize' }}
              label={label}
              // value={value}
              // defaultValue={defaultValue || ''}
              placeholder={placeholder}
              onFocus={onFocus}
              hidden={hidden}
              disabled={disabled}
              multiline={Boolean(rows)}
              rows={rows}
              margin="normal"
              error={Boolean(errors)}
              helperText={errors?.message}
              {...field}
              {...config}
            />
          )}
        />
      )}
    </div>
  );
}

export default InputBaseContainer;

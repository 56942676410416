import React, { useState } from 'react'
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Box, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
  Download as DownloadIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  ArrowBack,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import ModalWrapper from '@/components/ui/ModalWrapper';
import OutletPairCollections from '@/components/Form/Sales/OutletPairCollections';
import { handleModal } from '@/store/slices/modalSlice';
import PairOperators from '@/components/Form/Sales/PairOperators';
import POSApi from '@/api/pos/posApi';
import OutletHomeScreenIndexing from '@/components/Form/Sales/OutletHomeScreenIndexing';

function HomePosTab({collData, getHomePosData, outletId,  collectionIds}) {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch();
  
  const submitIndexing = (indexingData) => {
    const { updateIndexingHomePos } = POSApi();

    try {
      updateIndexingHomePos(outletId, indexingData)
      .then((res) => {
        if (res.status === 200) {
          getHomePosData();
          dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
          Swal.fire({
            title: 'Success !',
            text: 'Successfully Update Indexing !',
            icon: 'success',
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <div className="w-full flex justify-end mb-4 items-center gap-x-2">
        <button
          className="btn bg-[#2C6D47] p-2 text-white rounded-lg border cursor-pointer"
          name="ManageHomePos"
          type="button"
          onClick={() =>
            dispatch(
              handleModal({
                modalId: clientId,
                componentName: 'ManageHomePos',
                modalData: {
                  outletId: outletId,
                  pairComponentIds: collData.length ? collData.map((dt) => ({ id: dt.id, index: dt.index })) : [],
                  outletHomePos: collData,
                },
              })
            )
          }
        >
          Manage Home POS
        </button>
      </div>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className="!font-bold" width={50}>
                  Index
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  Name
                </TableCell>
                <TableCell align="center" className="!font-bold">
                  Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {collData.length > 0 &&
                collData.map((coll, idx) => {
                  const name = coll?.product?.name || coll?.collection?.name;
                  const type = coll?.collection ? 'collection' : 'product';

                  return (
                    <TableRow>
                      <TableCell align="center" className="font-semibold">{coll?.index}</TableCell>
                      <TableCell align="left">{name || ''}</TableCell>
                      <TableCell align="left">{type}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </Box>
      <ModalWrapper componentName="ManageHomePos" header="Manage Outlet Home Screen Products" maxWidth="xl">
        <OutletHomeScreenIndexing submitIndexing={submitIndexing} collectionIds={collectionIds} />
      </ModalWrapper>
    </>
  )
}

export default HomePosTab
/* eslint-disable */

import { useState, useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useSnackbar } from 'notistack';
import qs from 'qs';

import request from '../helper/axiosUtil';
import { handleModal } from '../store/slices/modalSlice';



// New Query

export const fetchFilterLocation = async ({ queryKey }) => {
  const transQuery = queryKey[1]?.queryKey;
  const url = `/locations${qs.stringify(transQuery, { addQueryPrefix: true })}`;
  return request({ url });
};

// New Query

const fetchWidgetJob = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({ url: `/widgets/?client=${clientId}&max_size=true&category=5&internal=False` });
};

export const useWidgetJobData = (clientId) => {
  return useQuery(['WidgetJobs', { clientId }], fetchWidgetJob);
};

const fetchJobPosters = async ({ queryKey }) => {
  const [, { lang, clientId, company, position, widget, currency, typeJob, search }] = queryKey;
  return request({
    url: `/job_posters/?language=${lang}&article__client=${clientId}&page_size=10&page=1&company=${company}&position=${position}&job_type=${typeJob}&status=Published&search=${search}&currency=${currency}&widget=${widget}&order=-article__date`,
  });
};

export const useJobPostersData = (lang, clientId, company, position, widget, currency, typeJob, search) => {
  return useQuery(['JobPosters', { lang, clientId, company, position, widget, currency, typeJob, search }], fetchJobPosters);
};

const fetchJobPosterDetail = ({ queryKey }) => {
  const jobPosterId = queryKey[1]; // destructured jobPosterId param passed as prop where queryKey[1] is the id
  return request({ url: `/job_posters/${jobPosterId}/` });
};

export const useJobPosterDetail = (jobPosterId) => {
  const queryClient = useQueryClient();
  return useQuery(['JobPosters', jobPosterId], fetchJobPosterDetail, {
    initialData: () => {
      const collection = queryClient.getQueryData('JobPosters')?.data?.find((collections) => collections.id === parseInt(jobPosterId, 10));

      if (collection) {
        return { data: collection };
      }
      return undefined;
    },
  });
};

export const deleteJob = async (id) =>
  request({
    url: `/job_posters/${id}/`,
    method: 'delete',
  });

const createJobPosters = async (data) =>
  request({
    url: '/job_posters/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const useAddJobPosters = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(createJobPosters, {
    /** Optimistic Update Start */
    onSuccess: (e) => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries('JobPosters');
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      queryClient.invalidateQueries('JobPosters'); // invalidating the unique key's cached data and refetches
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('JobPosters', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('JobPosters');
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const updateJobPosters = (id, data) => {
  return request({ url: `/job_posters/${id}/`, method: 'patch', data });
};

export const useUpdateJobPosters = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((data) => updateJobPosters(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['JobPosters', newData.id]);
      const previousData = queryClient.getQueryData(['JobPosters', newData.id]);
      queryClient.setQueryData(['JobPosters', newData.id], newData);
      return { previousData, newData };
    },
    onSuccess: (e) => {
      // Invalidates cache and refetch
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      // queryClient.invalidateQueries('AssetCategories');
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['JobPosters', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['JobPosters', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};

const createWidgetJob = async (data) =>
  request({
    url: '/widgets/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const useAddWidgetJob = () => {
  const queryClient = useQueryClient();

  return useMutation(createWidgetJob, {
    /** Optimistic Update Start */
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries('WidgetJobs'); // invalidating the unique key's cached data and refetches
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('WidgetJobs', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('WidgetJobs');
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const fetchWidgetDetail = ({ queryKey }) => {
  const dataId = queryKey[1]; // destructured dataId param passed as prop where queryKey[1] is the id
  return request({ url: `/widgets/${dataId}/` });
};

export const useWidgetDetail = (dataId) => {
  const queryClient = useQueryClient();
  const dataModal = useSelector((state) => state.modal);

  return useQuery(['WidgetJobs', dataId], fetchWidgetDetail, {
    initialData: () => {
      const collection = queryClient.getQueryData('WidgetJobs')?.data?.find((collections) => collections.id === parseInt(dataId, 10));

      if (collection) {
        return { data: collection };
      }
      return undefined;
    },
    enabled: dataModal.componentName === 'EditJobCollection',
  });
};

const updateWidgetJob = (id, data) => {
  return request({ url: `/widgets/${id}/`, method: 'patch', data });
};

export const useUpdateWidgetJob = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((data) => updateWidgetJob(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['WidgetJobs', newData.id]);
      const previousData = queryClient.getQueryData(['WidgetJobs', newData.id]);
      queryClient.setQueryData(['WidgetJobs', newData.id], newData);
      return { previousData, newData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['WidgetJobs', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['WidgetJobs', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};

const deleteWidget = async (dataId) =>
  request({
    url: `/widgets/${dataId}`,
    method: 'delete',
  });

const useDeleteWidget = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(deleteWidget, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else swal('Error');
      // console.log('data', _data);
      // dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('WidgetJobs');
    },
    onSettled: () => {
      queryClient.invalidateQueries('WidgetJobs');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyWidget = (id) => {
  const { mutate: deleteWid } = useDeleteWidget(id);
  const dispatch = useDispatch();

  // console.log('red', deleteRes);

  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteWid(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    // dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};

const fetchPositions = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({ url: `/positions/?client=${clientId}&max_size=true` });
};

export const usePositionsData = (clientId) => {
  return useQuery(['Positions', { clientId }], fetchPositions);
};

const fetchPositionDetail = ({ queryKey }) => {
  const dataId = queryKey[1]; // destructured dataId param passed as prop where queryKey[1] is the id
  return request({ url: `/positions/${dataId}/` });
};

export const usePositionDetail = (dataId) => {
  const queryClient = useQueryClient();
  const dataModal = useSelector((state) => state.modal);

  return useQuery(['Positions', dataId], fetchPositionDetail, {
    initialData: () => {
      const collection = queryClient.getQueryData('Positions')?.data?.find((collections) => collections.id === parseInt(dataId, 10));

      if (collection) {
        return { data: collection };
      }
      return undefined;
    },
    enabled: dataModal.componentName === 'EditJobPosition',
  });
};

const createPosition = async (data) =>
  request({
    url: '/positions/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const useAddPosition = () => {
  const queryClient = useQueryClient();

  return useMutation(createPosition, {
    /** Optimistic Update Start */
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries('Positions'); // invalidating the unique key's cached data and refetches
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('Positions', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('Positions');
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const updateJobPosition = (id, data) => {
  return request({ url: `/positions/${id}/`, method: 'patch', data });
};

export const useUpdateJobPosition = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((data) => updateJobPosition(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['Positions', newData.id]);
      const previousData = queryClient.getQueryData(['Positions', newData.id]);
      queryClient.setQueryData(['Positions', newData.id], newData);
      return { previousData, newData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['Positions', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['Positions', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};

const deletePosition = async (dataId) =>
  request({
    url: `/positions/${dataId}`,
    method: 'delete',
  });

const useDeletePosition = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(deletePosition, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else swal('Error');
      // console.log('data', _data);
      // dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('Positions');
    },
    onSettled: () => {
      queryClient.invalidateQueries('Positions');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyPosition = (id) => {
  const { mutate: deleteWid } = useDeletePosition(id);
  const dispatch = useDispatch();

  // console.log('red', deleteRes);

  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteWid(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    // dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};

const fetchComapnies = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({ url: `/companies/?client=${clientId}&max_size=true` });
};

export const useCompaniesData = (clientId) => {
  return useQuery(['Companies', { clientId }], fetchComapnies);
};

const fetchCompanyDetail = ({ queryKey }) => {
  const dataId = queryKey[1]; // destructured dataId param passed as prop where queryKey[1] is the id
  return request({ url: `/companies/${dataId}/` });
};

export const useCompanyDetail = (dataId) => {
  const queryClient = useQueryClient();
  const dataModal = useSelector((state) => state.modal);

  return useQuery(['Companies', dataId], fetchCompanyDetail, {
    initialData: () => {
      const collection = queryClient.getQueryData('Companies')?.data?.find((collections) => collections.id === parseInt(dataId, 10));

      if (collection) {
        return { data: collection };
      }
      return undefined;
    },
    enabled: dataModal.componentName === 'EditJobCompany',
  });
};

const createCompany = async (data) =>
  request({
    url: '/companies/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const useAddCompany = () => {
  const queryClient = useQueryClient();

  return useMutation(createCompany, {
    /** Optimistic Update Start */
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries('Companies'); // invalidating the unique key's cached data and refetches
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('Companies', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('Companies');
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const updateJobCompany = (id, data) => {
  return request({ url: `/companies/${id}/`, method: 'patch', data });
};

export const useUpdateJobCompany = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((data) => updateJobCompany(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['Companies', newData.id]);
      const previousData = queryClient.getQueryData(['Companies', newData.id]);
      queryClient.setQueryData(['Companies', newData.id], newData);
      return { previousData, newData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['Companies', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['Companies', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};

const deleteCompany = async (dataId) =>
  request({
    url: `/companies/${dataId}`,
    method: 'delete',
  });

const useDeleteCompany = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(deleteCompany, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else swal('Error');
      // console.log('data', _data);
      // dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('Companies');
    },
    onSettled: () => {
      queryClient.invalidateQueries('Companies');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyCompany = (id) => {
  const { mutate: deleteComp } = useDeleteCompany(id);
  const dispatch = useDispatch();

  // console.log('red', deleteRes);

  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteComp(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    // dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};

const fetchEducations = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({ url: `/educations/?client=${clientId}&max_size=true` });
};

export const useEducationsData = (clientId) => {
  return useQuery(['Educations', { clientId }], fetchEducations);
};

const fetchEducationDetail = ({ queryKey }) => {
  const dataId = queryKey[1]; // destructured dataId param passed as prop where queryKey[1] is the id
  return request({ url: `/educations/${dataId}/` });
};

export const useEducationDetail = (dataId) => {
  const queryClient = useQueryClient();
  const dataModal = useSelector((state) => state.modal);

  return useQuery(['Educations', dataId], fetchEducationDetail, {
    initialData: () => {
      const collection = queryClient.getQueryData('Educations')?.data?.find((collections) => collections.id === parseInt(dataId, 10));

      if (collection) {
        return { data: collection };
      }
      return undefined;
    },
    enabled: dataModal.componentName === 'EditJobEducation',
  });
};

const createEducation = async (data) =>
  request({
    url: '/educations/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const useAddEducation = () => {
  const queryClient = useQueryClient();

  return useMutation(createEducation, {
    /** Optimistic Update Start */
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries('Educations'); // invalidating the unique key's cached data and refetches
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('Educations', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('Educations');
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const updateJobEducation = (id, data) => {
  return request({ url: `/educations/${id}/`, method: 'patch', data });
};

export const useUpdateJobEducation = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((data) => updateJobEducation(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['Educations', newData.id]);
      const previousData = queryClient.getQueryData(['Educations', newData.id]);
      queryClient.setQueryData(['Educations', newData.id], newData);
      return { previousData, newData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['Educations', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['Educations', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};

const deleteEducation = async (dataId) =>
  request({
    url: `/educations/${dataId}`,
    method: 'delete',
  });

const useDeleteEducation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteEducation, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else swal('Error');
      // console.log('data', _data);
      // dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('Educations');
    },
    onSettled: () => {
      queryClient.invalidateQueries('Educations');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyEducation = (id) => {
  const { mutate: deleteEdu } = useDeleteEducation(id);

  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteEdu(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    // dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};

const fetchKeywords = async ({ queryKey }) => {
  const [, { site }] = queryKey;
  return request({ url: `/keywords/?site=${site}&max_size=true` });
};

export const useKeywordsData = (site) => {
  return useQuery(['Keywords', { site }], fetchKeywords);
};

const createKeyword = async (data) =>
  request({
    url: '/keywords/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const useAddKeyword = () => {
  const queryClient = useQueryClient();

  return useMutation(createKeyword, {
    /** Optimistic Update Start */
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries('Keywords'); // invalidating the unique key's cached data and refetches
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('Keywords', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('Keywords');
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const deleteKeyword = async (dataId) =>
  request({
    url: `/keywords/${dataId}`,
    method: 'delete',
  });

const useDeleteKeyword = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(deleteKeyword, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else swal('Error');
      // console.log('data', _data);
      // dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('Keywords');
    },
    onSettled: () => {
      queryClient.invalidateQueries('Keywords');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyKeyword = (id) => {
  const { mutate: deleteKey } = useDeleteKeyword(id);
  const dispatch = useDispatch();

  // console.log('red', deleteRes);

  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteKey(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    // dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};

const fetchCurrencies = async (id) => {
  return request({ url: `/accounting/business_units/${id ? id : 1}` });
};

export const useCurrenciesData = (id) => {
  return useQuery(['Currencies'], fetchCurrencies(id), {enabled: Boolean(id)});
};

const fetchJobTypes = async () => {
  return request({ url: '/job_types/?max_size=true' });
};

export const useJobTypesData = () => {
  return useQuery(['JobTypes'], fetchJobTypes);
};

const fetchJobTypeDetail = ({ queryKey }) => {
  const dataId = queryKey[1]; // destructured dataId param passed as prop where queryKey[1] is the id
  return request({ url: `/job_types/${dataId}/` });
};

export const useJobTypeDetail = (dataId) => {
  const queryClient = useQueryClient();
  const dataModal = useSelector((state) => state.modal);

  return useQuery(['JobTypes', dataId], fetchJobTypeDetail, {
    initialData: () => {
      console.log(queryClient.getQueryData('JobTypes'));
      const collection = queryClient.getQueryData('JobTypes')?.data?.results?.find((collections) => collections.id === parseInt(dataId, 10));

      if (collection) {
        return { data: collection };
      }
      return undefined;
    },
    enabled: dataModal.componentName === 'EditJobType',
  });
};

const createJobType = async (data) =>
  request({
    url: '/job_types/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const useAddJobType = () => {
  const queryClient = useQueryClient();

  return useMutation(createJobType, {
    /** Optimistic Update Start */
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries('JobTypes'); // invalidating the unique key's cached data and refetches
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('JobTypes', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('JobTypes');
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const updateJobJobType = (id, data) => {
  return request({ url: `/job_types/${id}/`, method: 'patch', data });
};

export const useUpdateJobJobType = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((data) => updateJobJobType(id, data), {
    onMutate: async (newData) => {
      // dispatch(isReactLoading());

      await queryClient.cancelQueries(['JobTypes', newData.id]);
      const previousData = queryClient.getQueryData(['JobTypes', newData.id]);
      queryClient.setQueryData(['JobTypes', newData.id], newData);
      return { previousData, newData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['JobTypes', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['JobTypes', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};

const deleteJobType = async (dataId) =>
  request({
    url: `/job_types/${dataId}`,
    method: 'delete',
  });

const useDeleteJobType = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteJobType, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else swal('Error');
      // console.log('data', _data);
      // dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('JobTypes');
    },
    onSettled: () => {
      queryClient.invalidateQueries('JobTypes');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyJobType = (id) => {
  const { mutate: deleteEdu } = useDeleteJobType(id);

  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteEdu(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    // dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};

const fetchLocations = async ({ queryKey }) => {
  const [, { lang, clientId }] = queryKey;
  return request({ url: `/locations/?lang=${lang}&client=${clientId}&max_size=true` });
};

export const useLocationsData = (lang, clientId) => {
  return useQuery(['Locations', { lang, clientId }], fetchLocations);
};

const fetchAuthors = async ({ queryKey }) => {
  const [, { site }] = queryKey;
  return request({ url: `/authors/?site=${site}&max_size=true` });
};

export const useAuthorsData = (site) => {
  return useQuery(['Authors', { site }], fetchAuthors);
};

const fetchApplicants = async ({ queryKey }) => {
  const [, { site }] = queryKey;
  return request({ url: `/applicants/?user__site=1&max_size=true` });
};

export const useApplicantsData = (site) => {
  return useQuery(['Applicants', { site }], fetchApplicants);
};

const fetchApplicantDetail = async ({ queryKey }) => {
  const [, { applicantId }] = queryKey;
  return request({ url: `/applicants/${applicantId}` });
};

export const useApplicantDetail = (applicantId) => {
  return useQuery(['Applicants', { applicantId }], fetchApplicantDetail);
};

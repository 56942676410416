import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Box, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { handleModal } from '@/store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ModalWrapper from '@/components/ui/ModalWrapper';
import OutletDiscountForm from '@/components/Form/Sales/OutletDiscountForm';
import POSApi from '@/api/pos/posApi';
import Swal from 'sweetalert2';

function DiscountsTab({ dataDiscounts, outletId, getOutletDiscounts }) {
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch();

  const formatNominal = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
  
    const integerValue = Math.floor(Number(value));
  
    const formattedValue = integerValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    return formattedValue;
  };

  const deleteItem = async(data) => {
    const { deleteDiscountById } = POSApi();

    try {
      await deleteDiscountById(data.id);
      getOutletDiscounts(outletId);
      Swal.fire({
        title: 'Success!',
        text: 'Successfully Deleted Discount!',
        icon: 'success',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Error When Try to Delete Discount.',
        icon: 'error',
      });
      console.error(error)
    }
  }

  const handleDeleteClick = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete this discount?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2C6D47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem(data);
      }
    });
  };

  return (
    <>
      <div className="w-full flex justify-end mb-4 items-center gap-x-2">
        <button
          className="btn bg-[#2C6D47] p-2 text-white rounded-lg border cursor-pointer"
          name="AddNewDiscount"
          type="button"
          onClick={() => 
            dispatch(handleModal({
              modalId: clientId, 
              componentName: 'discountsForm', 
              modalData: { 
                outletId,
                mode: "add"
              }
            }))
          }  
        >
          Add New Discount
        </button>
      </div>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className="!font-bold">
                  Name
                </TableCell>
                <TableCell align="center" className="!font-bold" width={500}>
                  Type
                </TableCell>
                <TableCell align="center" className="!font-bold" width={300}>
                  Value
                </TableCell>
                <TableCell align="center" className="!font-bold" width={100} />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataDiscounts.length > 0 &&
                dataDiscounts.map((coll, idx) => {
                  return (
                    <TableRow>
                      {/* <TableCell align="left">{coll?.name || '-'}</TableCell> */}
                      <TableCell align="left">
                        <div
                          className="w-fit cursor-pointer text-[#2C6D47] font-semibold"
                          onClick={() => 
                            dispatch(handleModal({
                              modalId: clientId, 
                              componentName: 'discountsForm', 
                              modalData: { 
                                outletId,
                                mode: "edit",
                                data: coll
                              }
                            }))
                          }
                        >
                          {coll?.name || '-'}
                        </div>
                      </TableCell>
                      <TableCell align="left">{coll?.type || '-'}</TableCell>
                      <TableCell align="right">{formatNominal(coll?.value) || '-'}</TableCell>
                      <TableCell align="center">
                        <DeleteIcon className='cursor-pointer' onClick={() => handleDeleteClick(coll)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </Box>
      <ModalWrapper componentName="discountsForm" header="Discount Form" maxWidth="sm">
        <OutletDiscountForm getOutletDiscounts={getOutletDiscounts} outletId={outletId} />
      </ModalWrapper>
    </>
  );
}

export default DiscountsTab;

import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth/authSlice';
import clientSlice from './slices/clientSlice';
import contentSlice from './slices/content/contentSlice';
import languageSlice from './slices/languageSlice';
import sectionSlice from './slices/content/sectionSlice';
import sidebar from './slices/sidebarTaskSlice';
import usersTeamSlice from './slices/usersTeamSlice';
import modal from './slices/modalSlice';
import stage from './slices/hr/stageSlice';
import filterComponent from './slices/filterComponentSlice';
import accountingSlice from './slices/accounting/accountingSlice';
import taskSlice from './slices/task/taskSlice';
import crud from './slices/taskCrudSlice';
import crudMessage from './slices/content/crudMessageSlice';
import assetCrud from './slices/assetCrudSlice';
import locationCrud from './slices/locationCrudSlice';
import loading from './slices/reactLoadingSlice';
import { authApi } from './slices/hr/authApi';
import authReducer from './slices/hr/authReducer';
import transactionSlice from './slices/accounting/transactionFilter';
import accountSettingsSlice from './slices/accounting/accountSettingsFilter';
import crmProfileSlice from './slices/crm/crmProfile';
import CrmFilter from './slices/crm/crmFilter';
import crmProjectFilter from './slices/crm/crmProjectFilter';
import productListFilter from './slices/ProductManagement/productlistFilter';
import vendorFilter from './slices/procurement/vendorFilter';
import currencySlice from './slices/currencySlice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    client: clientSlice.reducer,
    content: contentSlice.reducer,
    lang: languageSlice.reducer,
    section: sectionSlice.reducer,
    users: usersTeamSlice.reducer,
    teams: usersTeamSlice.reducer,
    CrmFilter: CrmFilter.reducer,
    crmProjectFilter: crmProjectFilter.reducer,
    crmProfile: crmProfileSlice.reducer,
    productListFilter: productListFilter.reducer, 
    VendorFilter: vendorFilter.reducer, 
    sidebar,
    stage,
    filterComponent,
    modal,
    crudMessage,
    task: taskSlice.reducer,
    acc: accountingSlice.reducer,
    crud,
    assetCrud,
    locationCrud,
    loading,
    // layout,
    authReducer,
    currency: currencySlice,
    transaction: transactionSlice.reducer,
    accountSettings: accountSettingsSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
  },
});

export default store;

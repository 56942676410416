/* eslint-disable */

import React, { useState, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Checkbox, CircularProgress, TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import MuiSelectSingleItem from '../../../components/Input/MuiSelectSingleItem';
import CustomTable from '../../../components/Table/CustomTable';
import { getDefaultSelectObject, getOptionSelectWithKey, getSelectOptionWithKey } from '../../../lib/OptionsInputHelper';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import CollectionForm from '../../../components/Form/Article/CollectionForm';
import useDelete from '../../../hooks/useDelete';
import usePost from '../../../hooks/usePost';
import useGet from '../../../hooks/useGet';
import BaseApplyButton from '../../../components/Button/BaseApplyButton';
import BaseDeleteButton from '../../../components/Button/BaseDeleteButton';
import BaseSearchButton from '../../../components/Button/BaseSearchButton';
import RenderResults from '../../../components/RenderResults';
import { fetchFilterArticle } from '../../../hooks/useCmsData';
import { useQuery } from '@tanstack/react-query';
import ReactSelectSingle from '../../../components/Input/ReactSelectSingle';
import { handleFilter } from '../../../store/slices/filterComponentSlice';
import { useDispatch } from 'react-redux';
import BZButton from '../../../components/Button/BZButton';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'author',
    numeric: false,
    disablePadding: false,
    label: 'Author',
  },
  {
    id: 'collection',
    numeric: false,
    disablePadding: false,
    label: 'Collection',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Category',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

function ActionCell(props) {
  const { onClick } = props;
  return <BaseDeleteButton onClick={onClick} variant="icon" />;
}

function CategoryCell(props) {
  const { data } = props;
  return (
    <ul className="">
      {data?.map((cat) => (
        <li key={cat} className="text-xs list-disc">
          {cat}
        </li>
      ))}
    </ul>
  );
}

function CollectionCell(props) {
  const { data } = props;
  return (
    <ul className="">
      {data?.map((col) => (
        <li key={col} className="text-xs list-disc">
          {col}
        </li>
      ))}
    </ul>
  );
}

function NameCell(props) {
  const { name, id } = props;
  const TEMPLATE_ID = useSelector((state) => state.content.activeTemplate);
  return (
    <div className="">
      <Link to={`/article/detail/${id}?template=${TEMPLATE_ID}`} className="text-bz-green font-semibold">
        {name}
      </Link>
    </div>
  );
}

function TableCells(props) {
  const { row, labelId, ariaChecked, selected, handleClick } = props;

  const onDestroyArticle = useDelete({
    url: `articles/delete_articles`,
    confText: `modals.article_delete.confirmation_text`,
    confTitle: `modals.article_delete.title`,
    success: 'Article deleted',
    validate: ['articles'],
    isSubUrl: false,
  });

  return (
    <TableRow hover role="checkbox" aria-checked={ariaChecked} tabIndex={-1} key={row.id} selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox onClick={(event) => handleClick(event, row.id)} color="primary" checked={ariaChecked} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">
        <NameCell name={row.name} id={row.id} />
      </TableCell>
      <TableCell align="left">{row.author_name}</TableCell>
      <TableCell align="left">
        <CollectionCell data={row?.widget_names} />
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }} align="left">
        <CategoryCell data={row?.category_names} />
      </TableCell>

      <TableCell align="left">
        <ActionCell id={row.id} onClick={() => onDestroyArticle({ bodyData: { articles: [row.id] } })} />
      </TableCell>
    </TableRow>
  );
}

function replaceQueryOrder(property) {
  let query = property;
  if (property === 'category') {
    query = 'categories';
  }
  if (property === 'collection') {
    query = 'widgets';
  }
  if (property === 'author') {
    query = 'author__name';
  }

  return query;
}

function findObjectInArray(array, targetKey) {
  for (let i = 0; i < array.length; i++) {
    const obj = array[i];

    if (typeof obj === 'object' && obj !== null) {
      if (targetKey in obj) {
        return obj[targetKey];
      } else {
        const nestedObj = findObjectInArray(Object.values(obj), targetKey);

        if (nestedObj !== null) {
          return nestedObj;
        }
      }
    }
  }

  return null;
}
function containsNonNullValues(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value !== null && typeof value !== 'undefined' && !(typeof value === 'string' && value.trim() === '') && !(typeof value === 'object' && !containsNonNullValues(value))) {
      return true;
    }
  }
  return false;
}

export default function ArticleTab() {
  const { control, watch, setValue } = useForm();
  const site = useSelector((state) => state.client.activeDetailClient.sites[0].id);
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);
  const allFilterData = useSelector((state) => state.filterComponent);

  const [dataArticle, setDataArticle] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  console.log('🚀 ~ file: ArticleTab.jsx:199 ~ ArticleTab ~ searchInput:', searchInput);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [checkboxSelected, setCheckboxSelected] = useState([]);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [orderQuery, setOrderQuery] = useState('');
  const [ordeIsAsc, setOrdeIsAsc] = useState('');

  const handleRequestSortApi = (event, property) => {
    console.log('🚀 ~ file: CustomTable.jsx:129 ~ handleRequestSortApi ~ property:', property);
    // const isAsc = orderBy === property && order === 'asc';
    // setOrder(isAsc ? 'desc' : 'asc');
    // setOrderBy(property);

    const isAsc = orderQuery === property && ordeIsAsc === 'desc';

    setOrdeIsAsc(isAsc && isAsc !== 'asc' ? 'asc' : 'desc');
    setOrderQuery(`${property}`);
  };

  const { data: authors } = useGet({
    url: `authors/?site=${site}&max_size=true`,
    validate: 'authorsMax',
  });

  const { data: WidgetsData } = useGet({
    url: `widgets/?is_internal=false&client=${clientId}&max_size=true&category=1`,
    validate: 'widgetsMax',
  });

  const { data: CategoriesData } = useGet({
    url: `category_articles/?client=${clientId}&max_size=true`,
    validate: 'categoryArticleMAx',
  });

  const authorId = watch('author');
  const widgetId = watch('widget');
  const categoryId = watch('category');

  const filterArticle = findObjectInArray(allFilterData.filterData, clientId)?.article;
  console.log('🚀 ~ file: ArticleTab.jsx:231 ~ ArticleTab ~ filterArticle:', filterArticle);

  useEffect(() => {
    if (containsNonNullValues(filterArticle)) {
      setValue('widget', filterArticle?.widget);
      setValue('author', filterArticle?.author);
      setValue('category', filterArticle?.category);
      //  setValue('category', filterArticle?.category);
      setValue('page', filterArticle?.page);
      if (filterArticle?.page) {
        setPage(filterArticle?.page);
      }
    }
  }, [setValue, filterArticle?.type, filterArticle?.category, filterArticle?.page]);

  // const { isFetching } = useGet({
  //   url: `articles`,
  //   queryString: {
  //     widgets__is_internal: false,
  //     search: searchInput,
  //     author: authorId,
  //     client: clientId,
  //     page: page,
  //     page_size: rowsPerPage,
  //     widgets: widgetId,
  //     categories: categoryId,
  //   },
  //   validate: 'articles',
  //   onSuccess: (e) => {
  //     setCount(e?.data?.count);
  //     setDataArticle(e?.data?.results);
  //   },
  //   isEnabled: isDataLoad,
  // });

  const queryKey = {
    widgets__is_internal: false,
    search: filterArticle?.search || searchInput,
    author: authorId,
    client: clientId,
    page: page,
    page_size: rowsPerPage,
    widgets: widgetId,
    categories: categoryId,
    ordering: ordeIsAsc === 'desc' ? `-${replaceQueryOrder(orderQuery)}` : replaceQueryOrder(orderQuery),
  };

  console.log('queryKey ', queryKey);

  const { isFetching } = useQuery(['articles', { queryKey }], fetchFilterArticle, {
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setCount(e?.data?.count);
      setDataArticle(listData);
    },
    refetchOnWindowFocus: false,
  });

  const onChangeSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const data = useMemo(() => dataArticle, [dataArticle]);

  function generateFilterComponent() {
    return (
      <div className="flex space-x-2 items-center">
        <div className="w-[200px] bg-white ">
          <ReactSelectSingle
            name="author"
            label="author"
            control={control}
            options={getOptionSelectWithKey(authors?.data?.results && authors?.data?.results.length > 0 ? [{ name: '-- No Selected --', id: '' }, ...authors?.data?.results] : [])}
            defaultValue={getDefaultSelectObject(
              watch('author'),
              getOptionSelectWithKey(authors?.data?.results && authors?.data?.results.length > 0 ? [{ name: '-- No Selected --', id: '' }, ...authors?.data?.results] : [])
            )}
            placeholder="Select author"
            floatLabel={false}
            extraAction={() => {
              const obj = findObjectInArray(allFilterData.filterData, clientId);
              dispatch(
                handleFilter({
                  [clientId]: {
                    ...obj,
                    article: {
                      ...obj?.article,
                      author: watch('author'),
                      page: 1,
                    },
                  },
                })
              );
            }}
          />
        </div>
        <div className="w-[200px]  bg-white ">
          <ReactSelectSingle
            name="widget"
            label="widget"
            control={control}
            floatLabel={false}
            options={getOptionSelectWithKey(
              WidgetsData?.data?.results && WidgetsData?.data?.results.length > 0 ? [{ name: '-- No Selected --', id: '' }, ...WidgetsData?.data?.results] : []
            )}
            defaultValue={getDefaultSelectObject(
              watch('widget'),
              getOptionSelectWithKey(
                WidgetsData?.data?.results && WidgetsData?.data?.results.length > 0 ? [{ name: '-- No Selected --', id: '' }, ...WidgetsData?.data?.results] : []
              )
            )}
            placeholder="Select widget"
            extraAction={() => {
              const obj = findObjectInArray(allFilterData.filterData, clientId);
              dispatch(
                handleFilter({
                  [clientId]: {
                    ...obj,
                    article: {
                      ...obj?.article,
                      widget: watch('widget'),
                      page: 1,
                    },
                  },
                })
              );
            }}
          />
        </div>
        <div className="w-[200px] bg-white ">
          {/* <MuiSelectSingleItem name="category" label="Select Category" control={control} options={getSelectOptionWithKey(CategoriesData?.data?.results)} /> */}

          <ReactSelectSingle
            name="category"
            label="category"
            control={control}
            floatLabel={false}
            options={getOptionSelectWithKey(
              CategoriesData?.data?.results && CategoriesData?.data?.results.length > 0 ? [{ name: '-- No Selected --', id: '' }, ...CategoriesData?.data?.results] : []
            )}
            defaultValue={getDefaultSelectObject(
              watch('category'),
              getOptionSelectWithKey(
                CategoriesData?.data?.results && CategoriesData?.data?.results.length > 0 ? [{ name: '-- No Selected --', id: '' }, ...CategoriesData?.data?.results] : []
              )
            )}
            placeholder="Select category"
            extraAction={() => {
              const obj = findObjectInArray(allFilterData.filterData, clientId);
              dispatch(
                handleFilter({
                  [clientId]: {
                    ...obj,
                    article: {
                      ...obj?.article,
                      category: watch('category'),
                      page: 1,
                    },
                  },
                })
              );
            }}
          />
        </div>
        <BaseSearchButton
          handlePersistSearch={(e) => {
            console.log('🚀 ~ file: ArticleTab.jsx:405 ~ generateFilterComponent ~ e:', e);
            setIsDataLoad(false);
            setTimeout(() => {
              setIsDataLoad(true);
            }, 500);
            setPage(1);
            const obj = findObjectInArray(allFilterData.filterData, clientId);

            dispatch(
              handleFilter({
                [clientId]: {
                  ...obj,
                  article: {
                    ...obj?.article,
                    search: e.target.value,
                    page: 1,
                  },
                },
              })
            );
          }}
          label="search"
          onChange={onChangeSearch}
          setPage={setPage}
          defaultValue={filterArticle?.search}
          setIsDataLoad={setIsDataLoad}
        />

        {/* <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} /> */}
      </div>
    );
  }

  const { mutate: postCollection } = usePost({ url: 'widgets', validate: ['widgets'] });

  const onPostCollection = async (postData) => {
    await postCollection(postData);
  };

  const onDestroyArticle = useDelete({
    url: `articles/delete_articles`,
    confText: `modals.article_delete.confirmation_text`,
    confTitle: `modals.article_delete.title`,
    success: 'Article deleted',
    validate: ['articles'],
    bodyData: { articles: checkboxSelected },
    isSubUrl: false,
  });

  return (
    <div className="p-5">
      <div className="">
        <div className="bg-gray-100 w-full flex py-5 px-3 items-center">
          <div className="ml-auto flex gap-x-3">
            {/* <BaseDeleteButton disabled={checkboxSelected.length === 0} onClick={() => onDestroyArticle()} /> */}
            <BZButton variant={checkboxSelected.length === 0 ? 'disabled' : 'danger'} onClick={() => onDestroyArticle()}>
              Delete selected
            </BZButton>
            <Link to="/article/add">
              <BZButton variant="secondary">Add article</BZButton>
            </Link>
            <ModalWrapper componentName="addCollection" header="Add Collection" maxWidth="sm">
              <CollectionForm onFormSubmit={onPostCollection} />
            </ModalWrapper>
          </div>
        </div>
        <div className=" py-4 my-4 border-y-2 ">{generateFilterComponent()}</div>
      </div>

      <CustomTable
        dataApi={data}
        setIsDataLoad={setIsDataLoad}
        isFetching={!isFetching}
        headCells={headCells}
        ActionCell={ActionCell}
        TableCells={TableCells}
        useCheckbox
        handleRequestSortApi={handleRequestSortApi}
        page={page - 1}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        checkboxSelected={checkboxSelected}
        count={count}
        setCheckboxSelected={setCheckboxSelected}
        ordeIsAsc={ordeIsAsc}
        orderQuery={orderQuery}
        name="Articles"
        handlePersistPage={(p) => {
          setIsDataLoad(false);
          setTimeout(() => {
            setIsDataLoad(true);
          }, 500);
          setPage(p);
          const obj = findObjectInArray(allFilterData.filterData, clientId);
          dispatch(
            handleFilter({
              [clientId]: {
                ...obj,
                article: {
                  ...obj?.article,
                  page: p,
                },
              },
            })
          );
        }}
      />
    </div>
  );
}

/* eslint-disable */

import React from 'react';
import { useNavigate, useParams } from 'react-router';
import AssetForm from '../../../components/Form/Asset/AssetForm';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BaseBackButton from '../../../components/Button/BaseBackButton';
import BZHelmet from '../../../utils/BZHelmet';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import useGet from '../../../hooks/useGet';

export default function AssetDetail() {
  const navigate = useNavigate();
  const { assetId } = useParams();
  const { data: assetData } = useGet({
    url: `assets/${assetId}`,
    validate: 'asset',
  });

  const { mutate: updateAsset } = usePatchOrPut({
    url: `assets/${assetId}`,
    validate: ['asset', 'assets'],
  });

  const onUpdateAsset = async (data) => {
    await updateAsset(data);
    // navigate('../asset', { replace: true });
  };

  return (
    <>
      <BZHelmet title="Asset Detail" description="" content="" />
      <div>
        <BaseMainTitle title="Asset Detail" />
        <div className="p-5 space-y-5">
          <BaseBackButton navTo="/asset" />
          <div className="flex gap-x-3 w-full bg-white border p-5 shadow rounded">
            <AssetForm defaultValues={assetData?.data} key={assetData?.data?.id} onFormSubmit={onUpdateAsset} mode="update" />
            {/* <div className="w-4/12">{renderAsset(assetData?.data?.asset_type?.id)}</div>
            <div className=" w-8/12"></div> */}
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, ClickAwayListener, Fade, FormControl, Popover, TextField, Tooltip } from '@mui/material';
import { AccessTime, Add, CheckCircle, ContentCopy, DateRange, Delete, LocalOffer, Message, MoreHoriz, Visibility } from '@mui/icons-material';

import { tooltipClasses } from '@mui/material/Tooltip';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import TaskContext from '../../../../pages/Task/Boards/BoardsContext';
import DetailContext from '../../../../pages/Task/Projects/DetailContext';
import PopTopicAutocomplete from './PopTopicAutocomplete';
import AssigneeTask from '../Content/AssigneeTask';
import GetAvatar from '../Content/GetAvatar';
import DuplicateTask from '../MoreActions/DuplicateTask';
import TimerTask from '../TimerTask/TimerTask';

import { Draggable } from '../../../../utils/Task/DragDrop';
import checkDueDate from '../../../../helper/checkDueDate';
import getTimeZone from '../../../../utils/Task/getTimeZone';
import projectApi from '../../../../api/task/project';
import styles from './ContentTask.module.css';

const contentTaskTheme = createTheme({
  components: {
    MuiPopover: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({}) } },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            // background: 'transparent',
            boxShadow: 'none',
          }),
      },
    },
  },
});

const isImage = (file) => {
  const fileExt = file.substring(file.lastIndexOf('.') + 1);
  const imgTypes = ['jpg', 'jpeg', 'png', 'webp', 'svg'];
  let isImg;
  if (imgTypes.includes(fileExt.toLowerCase())) {
    isImg = true;
  }
  return isImg;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? 'rgba(255,255,255, .5)' : '#ffffff',
  ...draggableStyle,
});

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

const checkDueStatus = (dateComing) => {
  if (dateComing) {
    const status = checkDueDate(dateComing);
    if (status === 1) {
      return {
        style: 'border-red-500 text-red-500',
        label: 'overdue',
        status,
      };
    }
    if (status === -1) {
      return {
        style: 'border-blue-500 text-blue-500',
        label: 'few more days',
        status,
      };
    }
    if (status === 0) {
      return {
        style: 'border-green-500 text-green-500',
        label: 'today',
        status,
      };
    }
    if (status < -1) {
      return {
        style: 'border-orange-500 text-orange-500',
        label: 'tomorrow',
        status,
      };
    }
  }

  return {
    style: 'border-gray-500 text-gray-500',
    label: 'overdue',
    status: '',
  };
};

export default function ContentTasks({ cardData, id, text, indexTopic, image, sectionId, isEmpty, isCreate, topicsLength, callbackCreateTask }) {
  // const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const taskContext = useContext(TaskContext);
  const myContextDetail = useContext(DetailContext);
  const activeUser = useSelector((state) => state.auth.user);

  const [assigneeList, setAssigneeList] = useState(false);
  const [anchorAction, setAnchorAction] = useState(false);
  const [mouseHover, setMouseHover] = useState(false);
  const [anchorAssignee, setAnchorAssignee] = useState(null);
  const [anchorDate, setAnchorDate] = useState(false);
  const [dueAt, setDueAt] = useState(cardData?.due_at);
  const [anchorTType, setAnchorTType] = useState(null);
  const [anchorPriority, setAnchorTPriority] = useState(null);
  const [isDuplicateTask, setIsDuplicateTask] = useState(false);

  const inputTitleRef = useRef(null);

  const handleClickAction = (event) => {
    setMouseHover(true);
    setAnchorAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorAction(null);
  };

  const openAction = Boolean(anchorAction);
  const idAction = openAction ? 'simple-popover' : undefined;

  const handleMouseHover = (param) => {
    if (param === 'in') {
      setMouseHover(true);
    } else {
      setMouseHover(false);
      setAnchorDate(false);
    }
  };

  const handleTopicUpdate = (data) => {
    const { updateSectionTopic } = projectApi();

    updateSectionTopic(id, data)
      .then((res) => {
        if (res.status === 200) {
          myContextDetail.dispatchProject('GET');
          setAnchorAssignee(null);
          setAnchorDate(false);
          setAnchorTType(false);
          setAnchorTPriority(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleMessage = () => {};
  const handleAssignee = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorAssignee(event.currentTarget);
  };
  const handleDate = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorDate(true);
  };
  const handleTopicType = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorTType(event.currentTarget);
  };
  const handleTopicPriority = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorTPriority(event.currentTarget);
  };
  const onAssigneeChange = (event, dataOption) => {
    if (event && dataOption) {
      handleTopicUpdate({ assignee: dataOption.id });
    }
    if (event && !dataOption) {
      handleTopicUpdate({ assignee: null });
    }
  };
  const onTopicTypeChange = (event, dataOption) => {
    if (event && dataOption) {
      handleTopicUpdate({ topic_type: dataOption.id });
    }
    if (event && !dataOption) {
      handleTopicUpdate({ topic_type: null });
    }
  };
  const onTopicPriorityChange = (event, dataOption) => {
    if (event && dataOption) {
      handleTopicUpdate({ priority: dataOption.id });
    }
    if (event && !dataOption) {
      handleTopicUpdate({ priority: null });
    }
  };

  const handleDuplicateTask = () => {
    handleCloseAction();
    setIsDuplicateTask(!isDuplicateTask);
  };
  const onDuplicateTask = (data) => {
    const { duplicateTopic } = projectApi();

    duplicateTopic(id, data)
      .then((res) => {
        if (res.data) {
          myContextDetail.dispatchProject('GET');
          enqueueSnackbar('Topic duplicated' || 'Success', { variant: 'success' });
          handleDuplicateTask();
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const onCreateTask = (title) => {
    taskContext.addTaskHandler(sectionId, title, topicsLength ? topicsLength + 1 : null, () => callbackCreateTask(false));
  };

  const openDrawerHandler = (e, idTask) => {
    if (e && e.stopPropagation) e.stopPropagation();
    // if (e.target !== e.currentTarget) {
    //   return;
    // }
    taskContext.openDrawerHandler(idTask);
  };

  const onActionLog = (e, param) => {
    if (e && e.stopPropagation) e.stopPropagation();
    const activeTask = {
      project: {
        name: myContextDetail.projects?.name,
        id: myContextDetail.projects?.id,
      },
      task: {
        name: cardData.name,
        id: cardData.id,
      },
    };
    // dispatch({ type: 'task/setActiveTask' });
    const dataLog = { topic: id };
    const currTimezone = getTimeZone();
    dataLog.timezone = {
      name: currTimezone.name,
      utc_offset: currTimezone.offset,
    };
    if (param === 'start') {
      if (myContextDetail.activeTask) {
        // set warning
        handleCloseAction();
        myContextDetail.trigger.setTriggerWarning({ ...activeTask, newTopic: cardData.id });
        return;
      }
      dataLog.time_in = new Date().toISOString();
      myContextDetail.logWorkingHours({ request: dataLog, state: activeTask }, false, {}, 'post');
    } else if (param === 'stop') {
      if (myContextDetail.activeTask) {
        dataLog.time_out = new Date().toISOString();
        myContextDetail.logWorkingHours({ request: dataLog, state: activeTask }, myContextDetail.activeTask.id, {}, 'patch', true);
      }
    }
    setAnchorAction(null);
  };

  const openAssignee = Boolean(anchorAssignee);
  const idAssignee = openAssignee ? 'simple-popover' : undefined;

  useEffect(() => {
    if (myContextDetail.users) {
      setAssigneeList(myContextDetail.users);
    }
  }, [myContextDetail.users]);

  const dueStatus = checkDueStatus(cardData?.due_at);

  const openTType = Boolean(anchorTType);
  const idTType = openTType ? 'simple-popover' : undefined;
  const openTPriority = Boolean(anchorPriority);
  const idTPriority = openTPriority ? 'simple-popover' : undefined;

  const isTaskRunning = String(myContextDetail?.activeTask?.topic) === String(id);

  return (
    <ThemeProvider theme={contentTaskTheme}>
      {!isCreate && !isEmpty && (
        <Draggable index={indexTopic} key={JSON.stringify(cardData)} draggableId={`${id}`} payload={{ id, index: indexTopic, sectionId, type: 'card', cardData }}>
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                snapshot={{ ...snapshot }}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={['rounded-lg', cardData.completed_at && styles.rootContainerCompleted].join(' ')}
                onMouseEnter={() => handleMouseHover('in')}
                onMouseLeave={() => handleMouseHover()}
                style={{ ...getItemStyle(snapshot.isDragging, provided.draggableProps.style), cursor: 'pointer' }}
              >
                <div className={[isTaskRunning && styles.rootContainerActive].join(' ')}>
                  <div className={[styles.rootContainer, 'shadow-md'].join(' ')}>
                    <div className="relative">
                      {mouseHover && (
                        <button type="button" className={styles.taskAction} onClick={handleClickAction}>
                          <MoreHoriz fontSize="small" />
                        </button>
                      )}
                      <Popover
                        id={idAction}
                        open={openAction}
                        anchorEl={anchorAction}
                        onClose={handleCloseAction}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        sx={{ transform: 'translate(-32px, 4px)' }}
                      >
                        <Fade in={openAction}>
                          <Box style={{ minWidth: '180px', color: '#fff' }} className="relative flex flex-col py-2 space-y-2 rounded-lg bg-white">
                            <Button
                              className="px-4 flex justify-start text-black"
                              startIcon={<AccessTime />}
                              onClick={(e) => onActionLog(e, 'start')}
                              disabled={myContextDetail.activeTask?.task?.id === id}
                            >
                              Start working
                            </Button>

                            <Button className="px-4 flex justify-start text-black" startIcon={<ContentCopy />} onClick={handleDuplicateTask}>
                              Duplicate task
                            </Button>
                            <Button
                              className="px-4 flex justify-start text-black"
                              startIcon={<Visibility />}
                              onClick={(e) => {
                                handleCloseAction();
                                openDrawerHandler(e, id);
                              }}
                            >
                              View task
                            </Button>
                            <div style={{ borderBottom: '1px solid rgb(0,0,0, .1)' }} />
                            <Button
                              className="px-4 flex justify-start text-red-600"
                              startIcon={<Delete />}
                              color="error"
                              onClick={() => taskContext.deleteTaskHandler(id, sectionId)}
                              disabled={myContextDetail.activeTask?.task?.id === id}
                            >
                              Delete
                            </Button>
                          </Box>
                        </Fade>
                      </Popover>
                    </div>
                    <div className="relative flex flex-col space-y-3" onClick={(e) => id && openDrawerHandler(e, id)} aria-hidden="true">
                      {image && isImage(image) && (
                        <div className={styles.taskImage}>
                          <div>
                            <img src={image} alt="" loading="lazy" />
                          </div>
                        </div>
                      )}
                      <div className="flex space-x-4">
                        <span className="text-left">
                          <Tooltip title="Mark task complete">
                            <CheckCircle fontSize="15" className="mb-1 mr-2" style={{ color: !cardData.completed_at ? 'var(--weak)' : 'var(--success)' }} />
                          </Tooltip>
                          {text}
                        </span>
                      </div>
                      <div className="flex space-x-2">
                        {cardData.labels &&
                          cardData.labels.length > 0 &&
                          cardData.labels.map((lbl) => (
                            <Tooltip key={`${lbl.name + lbl.color}`} title={lbl.name}>
                              <LocalOffer fontSize="14" style={{ color: lbl.color }} />
                            </Tooltip>
                          ))}
                      </div>
                      <div className="w-full flex items-center justify-between">
                        <div className="flex space-x-2 items-center">
                          <GetAvatar data={cardData} onClick={handleAssignee} />
                          <AssigneeTask
                            idAssignee={idAssignee}
                            openAssignee={openAssignee}
                            anchorAssignee={anchorAssignee}
                            assigneeList={assigneeList || []}
                            topicData={cardData}
                            config={{
                              activeUser,
                              setAnchorAssignee,
                              onAssigneeChange,
                            }}
                          />
                          <div
                            onClick={(event) => {
                              handleDate(event);
                            }}
                            aria-hidden
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label=""
                                value={dueAt}
                                open={anchorDate}
                                closeOnSelect
                                disableOpenPicker
                                onClose={() => {
                                  setAnchorDate(false);
                                }}
                                onClick={(event) => {
                                  if (event && event.stopPropagation) event.stopPropagation();
                                }}
                                onChange={(newValue) => {
                                  setDueAt(newValue);
                                  handleTopicUpdate({ due_at: newValue });
                                }}
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                  <div className="flex items-center">
                                    <div ref={inputRef} {...inputProps}>
                                      <Button
                                        style={{ minWidth: 24, minHeight: 24 }}
                                        className={['flex items-center py-0.5 px-1 border border-dashed rounded-full', dueStatus.style].join(' ')}
                                      >
                                        {cardData.due_at ? (
                                          <LightTooltip title={dueStatus.label} placement="bottom">
                                            <span style={{ fontSize: 10 }} className={['text', dueStatus.style].join(' ')}>
                                              {dueStatus.status === 0 ? dueStatus.label : format(new Date(cardData.due_at), 'MMM d')}
                                            </span>
                                          </LightTooltip>
                                        ) : (
                                          <DateRange fontSize="24" style={{ color: 'var(--weak)' }} />
                                        )}
                                      </Button>
                                    </div>
                                    {InputProps?.endAdornment}
                                  </div>
                                )}
                                PaperProps={{ sx: { backgroundColor: '#fff' } }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="flex space-x-2 items-center">
                          <div className="flex items-center space-x-1">
                            <span style={{ fontSize: 10 }} className="text-gray-500 mb-0.5 capitalize">
                              {cardData.total_comment}
                            </span>
                            <Message fontSize="12" style={{ color: 'var(--weak)' }} onClick={handleMessage} />
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex justify-between space-x-2">
                        <LightTooltip title="Topic type" placement="bottom">
                          <Button
                            style={{ color: cardData.topic_type?.color }}
                            className="w-auto px-2 py-0 truncate text-xs text-left border border-solid rounded-full border-gray-300 bg-opacity-50 normal-case text-bz-greenLight"
                            onClick={handleTopicType}
                          >
                            {cardData.topic_type?.name || 'Type'}
                          </Button>
                        </LightTooltip>
                        <PopTopicAutocomplete
                          config={{ label: 'Topic type', placeholder: 'type' }}
                          id={idTType}
                          open={openTType}
                          anchor={anchorTType}
                          setAnchor={setAnchorTType}
                          onChange={onTopicTypeChange}
                          cardData={cardData}
                          field="topic_type"
                          options={myContextDetail.types}
                        />
                        <LightTooltip title="Topic priority" placement="bottom">
                          <Button
                            className="w-auto px-2 py-0 truncate text-xs text-left border border-solid rounded-full border-gray-300 normal-case text-bz-greenLight"
                            onClick={handleTopicPriority}
                          >
                            {cardData.priority?.name || 'Priority'}
                          </Button>
                        </LightTooltip>
                        <PopTopicAutocomplete
                          config={{ label: 'Topic priority', placeholder: 'priority' }}
                          id={idTPriority}
                          open={openTPriority}
                          anchor={anchorPriority}
                          setAnchor={setAnchorTPriority}
                          onChange={onTopicPriorityChange}
                          cardData={cardData}
                          field="priority"
                          options={myContextDetail.priorities}
                        />
                      </div>
                      {isTaskRunning && (
                        <Button variant="contained" className="bz-btn py-[6px] rounded w-auto flex justify-between hover:bg-bz-green hover:text-bz-green" onClick={(e) => onActionLog(e, 'stop')}>
                          Stop
                          <TimerTask />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Draggable>
      )}
      {!!isCreate && (
        <ClickAwayListener
          onClickAway={() => {
            if (inputTitleRef.current) {
              onCreateTask(inputTitleRef.current);
              inputTitleRef.current = null;
            } else {
              callbackCreateTask(false);
            }
          }}
        >
          <div className={[styles.rootContainer, 'shadow-md'].join(' ')}>
            <div className="relative flex flex-col space-y-3" aria-hidden="true">
              <div className="flex space-x-4">
                <div className="relative flex items-center text-left whitespace-break-spaces">
                  <Tooltip title="Mark task complete">
                    <CheckCircle fontSize="15" className="mr-2" style={{ color: !cardData?.completed_at ? 'var(--weak)' : 'var(--success)' }} />
                  </Tooltip>
                  <FormControl>
                    <TextField
                      multiline
                      autoFocus
                      className={styles.taskCreateTitle}
                      placeholder="write task name here"
                      label=""
                      onChange={(e) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        inputTitleRef.current = e.target.value;
                      }}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="w-full flex items-center justify-between">
                <div className="flex space-x-2 items-center">
                  <GetAvatar data={cardData} />
                  <AssigneeTask
                    idAssignee={idAssignee}
                    openAssignee={openAssignee}
                    anchorAssignee={anchorAssignee}
                    assigneeList={assigneeList || []}
                    topicData={cardData}
                    config={{
                      activeUser,
                      setAnchorAssignee,
                      onAssigneeChange,
                    }}
                  />
                  <div
                    onClick={(event) => {
                      handleDate(event);
                    }}
                    aria-hidden
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label=""
                        value={dueAt}
                        open={anchorDate}
                        closeOnSelect
                        disableOpenPicker
                        onClose={() => {}}
                        onClick={(event) => {
                          if (event && event.stopPropagation) event.stopPropagation();
                        }}
                        onChange={() => {}}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <div className="flex items-center">
                            <div ref={inputRef} {...inputProps}>
                              <Button
                                style={{ minWidth: 24, minHeight: 24 }}
                                className={['flex items-center py-0.5 px-1 border border-dashed rounded-full', dueStatus.style].join(' ')}
                              >
                                <DateRange fontSize="24" style={{ color: 'var(--weak)' }} />
                              </Button>
                            </div>
                            {InputProps?.endAdornment}
                          </div>
                        )}
                        PaperProps={{ sx: { backgroundColor: '#fff' } }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="flex space-x-2 items-center">
                  <div className="flex items-center space-x-1">
                    <span style={{ fontSize: 10 }} className="text-gray-500 mb-0.5 capitalize">
                      0
                    </span>
                    <Message fontSize="12" style={{ color: 'var(--weak)' }} />
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-between space-x-2">
                <LightTooltip title="Topic type" placement="bottom">
                  <Button
                    style={{ color: cardData?.topic_type?.color }}
                    className="w-auto px-2 py-0 truncate text-xs text-left border border-solid rounded-full border-gray-300 bg-opacity-50 normal-case"
                  >
                    Type
                  </Button>
                </LightTooltip>
                <LightTooltip title="Topic priority" placement="bottom">
                  <Button className="w-auto px-2 py-0 truncate text-xs text-left border border-solid rounded-full border-gray-300 normal-case">Priority</Button>
                </LightTooltip>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}

      {!isCreate && isEmpty && (
        <div style={{ height: 'calc(100vh - 200px)' }} className={['mt-2'].join(' ')} onMouseEnter={() => handleMouseHover('in')} onMouseLeave={() => handleMouseHover()}>
          <Button style={{ backgroundColor: '#fff' }} className={[styles.addTask, 'shadow-md'].join(' ')} startIcon={<Add />} onClick={() => callbackCreateTask(true)}>
            Add Task
          </Button>
        </div>
      )}

      <DuplicateTask open={isDuplicateTask} close={handleDuplicateTask} duplicateTask={onDuplicateTask} />
    </ThemeProvider>
  );
}

export const ContentTask = React.memo(ContentTasks);

import React from 'react';
import { ClosedCaption, Extension, FormatAlignLeft, LocalOffer, Tag, MiscellaneousServices } from '@mui/icons-material';

import SectionCatalogue from '../pages/SettingsBZ/SectionCatalogue';
import SectionCategory from '../pages/SettingsBZ/SectionCategory';
import SectionDetail from '../pages/SettingsBZ/SectionDetail';
import SectionDeveloper from '../pages/SettingsBZ/SectionDeveloper';
import Template from '../pages/SettingsBZ/Template';
import TemplateDetail from '../pages/SettingsBZ/TemplateDetail';
import Widget from '../pages/SettingsBZ/Widget';
import WidgetDetail from '../pages/SettingsBZ/WidgetDetail';
import LanguageJSON from '../pages/SettingsBZ/LanguageJSON';
import ServiceMonitoring from '../pages/SettingsBZ/ServiceMonitoring';

const settingsBZRoute = [
  {
    name: 'Section Catalogue',
    element: <SectionCatalogue />,
    icon: <FormatAlignLeft />,
    privilege: [99, 6],
    subscriptions: [1],
    path: '/section/catalog',
    isSidebar: true,
  },
  {
    name: 'Section Detail',
    element: <SectionDetail />,
    privilege: [99, 1, 4, 6],
    subscriptions: [1],
    path: '/section/detail/:sectionId',
  },
  {
    name: 'Section New Detail',
    element: <SectionDetail />,
    privilege: [99, 1, 4, 6],
    subscriptions: [1],
    path: '/section/newdetail/:sectionId',
  },
  // {
  //   name: 'Widget Detail',
  //   element: <WidgetDetail />,
  //   privilege: [99, 6],
  //   subscriptions: [1],
  //   path: '/widget/detail/:widgetId',
  // },
  {
    name: 'Widget Detail',
    element: <WidgetDetail />,
    privilege: [99, 6],
    subscriptions: [1],
    path: '/widget/detail/:widgetId/?section_widget=:sectionId',
  },
  {
    name: 'Section Developer',
    element: <SectionDeveloper />,
    privilege: [99, 6],
    subscriptions: [1],
    path: '/section/developer/:sectionId',
  },
  {
    name: 'Section Category',
    element: <SectionCategory />,
    icon: <LocalOffer />,
    privilege: [99, 6],
    subscriptions: [1],
    path: '/section-categories',
    isSidebar: true,
  },
  {
    name: 'Widget',
    element: <Widget />,
    icon: <Extension />,
    privilege: [99, 6],
    subscriptions: [1],
    path: '/widgets',
    isSidebar: true,
  },
  {
    name: 'Template',
    element: <Template />,
    icon: <Tag />,
    privilege: [99, 6],
    subscriptions: [1],
    path: '/template',
    isSidebar: true,
  },
  {
    name: 'Template Detail',
    element: <TemplateDetail />,
    privilege: [99, 6],
    subscriptions: [1, 2],
    path: '/template/detail/:templateId',
  },
  {
    name: 'Language JSON',
    element: <LanguageJSON />,
    icon: <ClosedCaption />,
    privilege: [99, 6],
    subscriptions: [1],
    path: '/language-json',
    isSidebar: true,
  },
  {
    name: 'Service Monitoring',
    element: <ServiceMonitoring />,
    icon: <MiscellaneousServices />,
    privilege: [99],
    subscriptions: [1],
    path: '/service-monitoring',
    isSidebar: true,
  },
];

export default settingsBZRoute;

import axiosInstances from '../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function performance() {
  const { axiosInstance } = axiosInstances();

  async function getPagePeformances(params) {
    const method = 'get';
    const url = `${baseURL}performances/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function runSitePerformance(data) {
    // @params -> data
    // {
    //   pages: [139],
    //   site: 9
    // }

    const method = 'post';
    const url = `${baseURL}performances/run/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteResult(id) {
    const method = 'delete';
    const url = `${baseURL}performances/${id}`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  return { getPagePeformances, runSitePerformance, deleteResult };
}

import React from 'react';
import { useDispatch } from 'react-redux';
import { Info } from '@mui/icons-material';
import { handleModal } from '../../../store/slices/modalSlice';

export default function DroppableElement({ structure, data, label, type, viewDetail, modalName }) {
  const dispatch = useDispatch();
  const isDisabled = structure?.length && structure.includes(+data.id);
  return (
    <div
      className={['relative w-[150px] text-center bg-[#fdd] border my-[10px] p-[10px]', isDisabled ? 'cursor-not-allowed opacity-70' : 'cursor-grab'].join(' ')}
      draggable={!isDisabled}
      // eslint-disable-next-line react/no-unknown-property
      unselectable="on"
      onDragStart={(e) => (!isDisabled ? e.dataTransfer.setData('text/plain', JSON.stringify({ ...data, type })) : {})}
    >
      {label}
      {!!viewDetail && (
        <Info
          className="absolute z-10 top-1 right-1 cursor-pointer"
          onClick={() => {
            dispatch(handleModal({ modalId: '', componentName: modalName }));
            viewDetail(data);
          }}
          fontSize="14"
        />
      )}
    </div>
  );
}

/* eslint-disable */

import { useEffect } from 'react';

export function useDebounceEffect(fn, waitTime) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn();
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, [fn, waitTime]);
}

/* eslint-disable */

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Divider, FormControl, FormControlLabel, Input, Stack, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { DevTool } from '@hookform/devtools';
import InputContainer from '@/components/Input/InputContainer';
import { Delete } from '@mui/icons-material';
import useDelete from '@/hooks/useDelete';
import TemplateContext from './TemplateContext';

const schema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  ga_code: yup.string().nullable(),
});

// const initialState = {
//   name: '',
//   ga_code: '',
// };

const initialFiles = {
  context_zip_file: null,
  env_preview_file: null,
  env_publish_file: null,
  tailwind_config_file: null,
  package_json_file: null,
};

function HtmlFiles({ id, field, name, accept, callback, prevState }) {
  return (
    <label htmlFor={id} className="text-gray-700">
      {name}
      <Input
        className="w-full"
        accept={accept}
        id={id}
        type="file"
        onChange={(e) => {
          callback({ ...prevState, [field]: e.target.files[0] });
        }}
      />
    </label>
  );
}

export default function TemplateForm(props) {
  const activeSite = useSelector((state) => state.content.activeSite);
  const [valueTab, setValueTab] = useContext(TemplateContext);

  const { defaultValues, type, action } = props;
  console.log('🚀 ~ TemplateForm ~ defaultValues:', defaultValues);

  const [useTailwind, setUseTailwind] = useState(false);
  // const [gaCode, setGaCode] = useState('');
  const [files, setFiles] = useState(initialFiles);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues, ...initialFiles },
    reValidateMode: 'onChange',
  });

  const onSubmit = (temp) => {
    console.log('temp di line 88 ', temp);
    const dataTemplate = {};
    dataTemplate.site = activeSite;
    dataTemplate.name = temp.name;
    dataTemplate.ga_code = temp.ga_code;
    dataTemplate.files = files;
    dataTemplate.use_tailwind = useTailwind;
    action(type, dataTemplate);
  };

  const destroyTemplate = useDelete({
    confText: 'Confirm Delete Template',
    confTitle: 'Are you sure want to delete this template ?',
    success: 'Template deleted',
    validate: ['templates'],
  });

  const onDestroyTemplate = (id) => {
    destroyTemplate(`templates/${id}`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
      <div className="flex flex-col space-y-4 mt-4">
        <InputContainer name="name" control={control} label="Name" />
        <InputContainer name="ga_code" control={control} label="GA CODE" />
        <FormControlLabel control={<Checkbox checked={useTailwind} onChange={(e) => setUseTailwind(e.target.checked)} name="useTailwind" />} label="Use Tailwind" />
        {type === 'update' && (
          <>
            <HtmlFiles id="context-file" field="context_zip_file" name="Context" accept=".zip" callback={setFiles} prevState={files} />
            <HtmlFiles id="env-preview-file" field="env_preview_file" name="Env Preview" accept="file/*" callback={setFiles} prevState={files} />
            <HtmlFiles id="env-publish-file" field="env_publish_file" name="Env Publish" accept="file/*" callback={setFiles} prevState={files} />
            <HtmlFiles id="tailwind-file" field="tailwind_config_file" name="Tailwind Config" accept=".js,.json" callback={setFiles} prevState={files} />
            <HtmlFiles id="package-file" field="package_json_file" name="Package Json" accept=".json" callback={setFiles} prevState={files} />
          </>
        )}
      </div>
      <div className="mt-4 space-x-2">
        <Button type="submit" variant="contained">
          {defaultValues ? 'Update Template' : 'Create Template'}
        </Button>

        {defaultValues && (
          <Button
            className=""
            onClick={() => onDestroyTemplate(defaultValues?.id)}
            variant="contained"
            color="error"
            // disabled={isSameTemplate}
            startIcon={<Delete />}
          >
            Delete Template
          </Button>
        )}
      </div>
      {/* <DevTool control={control} /> */}
    </form>
  );
}

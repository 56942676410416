import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';

import { Box, ButtonBase } from '@mui/material';
import { Delete } from '@mui/icons-material';
import DetailContext from '../../../../../pages/Task/Projects/DetailContext';
import DrawerContext from '../DrawerContext';
import AutocompleteDependencies from './AutocompleteDependencies';
import styles from '../DrawerTask.module.css';

const calculateData = (cate, arr) => {
  if (arr && arr.length > 0) {
    return arr.map((itm, i) => {
      return {
        category: (i === 0 && cate) || '',
        id: itm.id,
        type: '',
        name: itm.name,
        description: itm.name,
        dueDate: itm.due_at,
        assigned: '',
      };
    });
  }
  return [];
};

export default function TabTopicInformation() {
  const myContextDetail = useContext(DetailContext);
  const myContextDrawer = useContext(DrawerContext);
  const { task } = myContextDrawer;

  const [isInitialized, setIsInitialized] = useState(false);
  const [currTask, setCurrTask] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);
  const [isDep, setIsDep] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const setDefaultData = useCallback((value) => {
    const dependentsData = calculateData('Dependents', value.dependents);
    const dependenciesData = calculateData('Dependencies', value.dependencies);
    setData([...dependentsData, ...dependenciesData]);
  }, []);

  useEffect(() => {
    if (!isInitialized && task) {
      setDefaultData(task);
      setCurrTask(task);
      setIsInitialized(true);
    }
  }, [isInitialized, task, setDefaultData]);

  useEffect(() => {
    if (isInitialized && currTask && currTask !== task) {
      setDefaultData(task);
      setCurrTask(task);
      setIsInitialized(false);
    }
  }, [isInitialized, currTask, task, setDefaultData]);

  const onChange = (param, option) => {
    const newDep = option.map((opt) => opt.id);
    const currDep = task[param.toLowerCase()]?.map((d) => d.id) || [];
    currDep.push(newDep[0]);
    const dep = { [param.toLowerCase()]: currDep };
    myContextDrawer.handleTopicUpdate(dep);
    setIsInitialized(false);
    setIsDep(false);
  };

  const onDeleteEstimation = (id) => {
    const newDep = [];
    task.dependencies?.forEach((d) => +d.id !== +id && newDep.push(d.id));
    myContextDrawer.handleTopicUpdate({ dependencies: newDep });
    setIsInitialized(false);
  };

  const renderAction = (id) => {
    return (
      <Box className="w-full flex space-x-2 justify-center">
        <ButtonBase className="bz-btn-tb" onClick={() => onDeleteEstimation(id)}>
          <Delete fontSize="24" />
        </ButtonBase>
      </Box>
    );
  };

  const dependentColumns = [
    {
      field: 'id',
      headerName: 'Task ID',
      width: 72,
      renderCell: (params) => <span className="truncate">{params.row.id}</span>,
    },
    {
      field: 'topic_type__name',
      headerName: 'Type',
    },
    {
      field: 'name',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'due_at',
      headerName: 'Due date',
      renderCell: (params) => <span className="truncate">{params.row.due_at ? format(new Date(params.row.due_at), 'MMM d, yyyy') : ''}</span>,
      width: 112,
    },
    {
      field: 'assignee__name',
      headerName: 'Assigned',
    },
    {
      field: '',
      headerName: 'Action',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => renderAction(params.row.id),
    },
  ];

  return (
    <div className="pl-3 pr-6 py-4">
      <div className="w-full pl-2 pr-0 space-y-4">
        {/* <div className="flex w-full items-center">
          <div className="w-1/4 pr-2 text-left">
            <span className={styles.taskLabel}>Dependencies</span>
          </div>
          <div className="w-3/4 flex items-center">
            <AutocompleteDependencies param="Dependencies" options={myContextDetail.topics} values={task.dependencies} onChange={onChange} />
          </div>
        </div> */}
        <div className="flex flex-col w-full items-center">
          <div className="w-full flex pr-2">
            <div className="w-1/4 flex flex-col text-left">
              <span className={styles.taskLabel}>Dependents</span>
            </div>
            {!task.dependents ||
              (!task.dependents.length && (
                <div className="w-3/4 flex flex-col text-left">
                  <span className={styles.taskLabel}>no dependent</span>
                </div>
              ))}
          </div>
          <div className="w-full flex items-center mt-4">
            {task.dependents && task.dependents?.length > 0 && (
              <DataGrid
                autoHeight
                rows={task.dependents}
                columns={dependentColumns.slice(0, -1)}
                pageSize={rowsPerPage}
                onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
                sx={{ width: '400px' }}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col w-full items-center">
          <div className="w-full flex pr-2">
            <div className="w-1/4 flex flex-col text-left">
              <span className={styles.taskLabel}>Dependencies</span>
            </div>
            <div className="w-3/4 flex flex-col text-left">
              {!task.dependencies ||
                (!task.dependencies.length && (
                  <div className="w-3/4 flex flex-col text-left mb-2">
                    <span className={styles.taskLabel}>no dependencies</span>
                  </div>
                ))}
              <button
                type="button"
                className={['w-auto flex items-center justify-center text-white p-[8px] bg-[#2C6D47] rounded-xl', task.dependencies?.length ? 'ml-auto' : 'mr-auto'].join(' ')}
                onClick={() => {
                  if (!isDep) {
                    setIsDep(true);
                  }
                }}
              >
                Add Dependencies
              </button>
              {isDep && (
                <div className="mt-4">
                  <AutocompleteDependencies param="Dependencies" options={myContextDetail.topics} onChange={onChange} />
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex items-center mt-4">
            {task.dependencies && task.dependencies?.length > 0 && (
              <DataGrid
                autoHeight
                rows={task.dependencies}
                columns={dependentColumns}
                pageSize={rowsPerPage}
                onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
                sx={{ width: '400px' }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

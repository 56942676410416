import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';
import { useLocation, useNavigate, useParams } from 'react-router';

import Table from './Table';
import TableContact from './TableContact';
import Locations from './Locations';
import TableAttribute from './TableAttribute';
import TableProfile from './TableProfile';
import TableAttributeGroups from './TableAttributeGroups';
import BaseMainTitle from '../../../components/BaseMainTitle';
import CreateCRMProject from '../../../components/Modal/CRM/CreateCRMProject';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import AddContact from '../../../components/Form/CRM/AddContact';
import EditContact from '../../../components/Form/CRM/EditContact';

import BZHelmet from '../../../utils/BZHelmet';
import { handleModal } from '../../../store/slices/modalSlice';
import crmApi from '../../../api/CRM/CRMCustomers';
import TableFile from './TableFile';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" className="w-full h-full" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ px: 0, pb: 2, py: 0, height: '100%', width: '100%' }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.defaultProps = { children: <div /> };

function a11yProps(index) {
  return {
    id: `drawer-tab-${index}`,
    'aria-controls': `drawer-tabpanel-${index}`,
  };
}
const mapLib = ['drawing', 'places'];

const tabItems = [
  { name: 'project', groups: [8, 10, 17] },
  { name: 'contacts', groups: [8, 10, 17] },
  { name: 'locations', groups: [8, 10, 17] },
  { name: 'attributes', groups: [8] },
  { name: 'groups', groups: [8] },
  { name: 'profile', groups: [8] },
  { name: 'file', groups: [8] },
];

const urlModified = (currLoc) => {
  const substrings = currLoc.split('/');
  let activeLoc;
  if (substrings.length === 1) {
    activeLoc = currLoc;
  } else if (substrings.length <= 3) {
    activeLoc = substrings.slice(0, substrings.length).join('/');
  } else {
    activeLoc = substrings.slice(0, -1).join('/');
  }
  return activeLoc;
};
const { getCRMProjects, deleteCRMProject, getAllContacts, deleteContact } = crmApi();

export default function List() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { tabName } = useParams();
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);

  const [isCreate, setIsCreate] = useState(false);
  const [isEditContact, setIsEditContact] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_MAP_KEY,
    libraries: mapLib,
  });

  const { data, refetch } = useQuery({
    queryKey: ['crm-projects', activeClient],
    enabled: !!activeClient,
    queryFn: () => getCRMProjects({ profile__client: activeClient, ordering: '-id', on_project_list: true, max_size: true }),
    onSuccess: () => {},
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 0,
  });

  const { data: dataContact, refetch: refetchContact } = useQuery({
    queryKey: ['crm-projects-contact'],
    enabled: !!activeClient,
    queryFn: () => getAllContacts({ client: activeClient, max_size: true, ordering: 'name' }),
    onSuccess: () => {},
    onError: (error) => {
      console.log(error);
    },
  });

  const onDeleteClick = (param) => {
    if (activeTab === 1 && param) {
      deleteContact(param.id)
        .then(() => refetchContact())
        .catch((err) => console.log(err));
    }
  };

  const onEditContactClick = (param) => {
    if (param) {
      setIsEditContact(param.id);
      dispatch(handleModal({ modalId: '', componentName: 'EditContact' }));
    }
  };

  const onTabChange = (event, newValue) => {
    const tabText = event.target.innerText.toLowerCase();
    const currLoc = location.pathname;
    const activeLoc = urlModified(currLoc);
    navigate(`${activeLoc}/${tabText}`, { replace: false });
    setActiveTab(newValue);
  };

  useEffect(() => {
    const findIndexTab = tabItems.findIndex((tb) => tb.name === tabName);
    if (tabName && findIndexTab >= 0) {
      if (activeTab !== findIndexTab) {
        setActiveTab(findIndexTab);
      }
    }
  }, [tabName, activeTab]);

  const aC = activeUser;
  const isSuperUser = aC.is_superuser;
  const userGroup = aC.groups?.map((a) => +a.id) || [];
  return (
    <>
      {isLoaded && (
        <React.Suspense fallback={null}>
          {/* {location.pathname.includes('/crm/projects') && <RGLTheme />} */}
          <BZHelmet title="Projects" description="" content="" />
          <BaseMainTitle title="Projects" />
          <section className="w-full p-5 relative">
            <Tabs value={activeTab} onChange={onTabChange} aria-label="crm list tabs" className="bz-tabs">
              {tabItems &&
                tabItems.length > 0 &&
                tabItems
                  .filter((t) => isSuperUser || t.groups.some((tg) => userGroup.includes(+tg)))
                  .map((tb, index) => <Tab key={tb.name} className="p-2" label={tb.name} {...a11yProps(index)} />)}
            </Tabs>
            <div className="relative w-full flex">
              {activeTab === 0 && (
                <div className="absolute right-0 top-[1.7rem] z-10">
                  <button
                    type="button"
                    className="hidden h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 bg-[#2C6D47] 5xl:flex"
                    onClick={() => setIsCreate(true)}
                  >
                    <AddIcon className="text-white" />
                    <p className="text-white">Add Project</p>
                  </button>
                </div>
              )}
              {activeTab === 1 && (
                <button
                  type="button"
                  className="flex ml-auto gap-x-2 items-center text-center btn rounded border-0 mb-4 px-4 h-10 bg-[#2C6D47]"
                  onClick={() => dispatch(handleModal({ modalId: '', componentName: 'AddNewContact' }))}
                >
                  <AddIcon className="text-white" />
                  <p className="text-white">Add Contact</p>
                </button>
              )}
            </div>
            <TabPanel value={activeTab} index={0}>
              <Table onDeleteClick={onDeleteClick} role={{ isSuperUser, userGroup }} setIsCreate={setIsCreate} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <TableContact
                rows={dataContact?.data?.results || []}
                onEditClick={onEditContactClick}
                onDeleteClick={onDeleteClick}
                refetch={refetchContact}
                role={{ isSuperUser, userGroup }}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <Locations data={data} />
            </TabPanel>
            {/* <TabPanel value={activeTab} index={3}>
              Assumption
            </TabPanel> */}
            <TabPanel value={activeTab} index={3}>
              <TableAttribute />
            </TabPanel>
            <TabPanel value={activeTab} index={4}>
              <TableAttributeGroups />
            </TabPanel>
            <TabPanel value={activeTab} index={5}>
              <TableProfile />
            </TabPanel>
            <TabPanel value={activeTab} index={6}>
              <TableFile />
            </TabPanel>
          </section>
          {isCreate && (
            <CreateCRMProject
              open={isCreate}
              isEdit={false}
              dataContact={dataContact?.data?.results || []}
              refetchContact={refetchContact}
              close={() => {
                setIsCreate(false);
              }}
              refetch={refetch}
            />
          )}
        </React.Suspense>
      )}
      <ModalWrapper componentName="AddNewContact" header="Add New Contact" maxWidth="sm">
        <AddContact activeClient={activeClient} reload={refetchContact} type="CRM Project" />
      </ModalWrapper>
      <ModalWrapper componentName="EditContact" header="Edit Contact" maxWidth="sm">
        <EditContact contactId={isEditContact} reload={refetchContact} activeClient={activeClient} />
      </ModalWrapper>
    </>
  );
}

import axiosInstances from '../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function siteSchedules() {
  const { axiosInstance } = axiosInstances();

  async function getAllSiteSchedules(params) {
    const method = 'get';
    const url = `${baseURL}site_schedules/`;
    const response = await axiosInstance({
      method,
      url,
      params,
    });
    return response;
  }

  async function addSiteSchedule(data) {
    // @params -> data
    // {
    //   cron: '* * * * *'
    //   pages: [139],
    //   site: 9
    // }

    const method = 'post';
    const url = `${baseURL}site_schedules/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteSchedule(id) {
    const method = 'delete';
    const url = `${baseURL}site_schedules/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  return { getAllSiteSchedules, addSiteSchedule, deleteSchedule };
}

/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'js-cookie';
import { FormControlLabel, FormLabel, FormControl, Radio, RadioGroup, Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DevTool } from '@hookform/devtools';
import { ExpandMore } from '@mui/icons-material';
import { handleModal, handleModalNested } from '../../../store/slices/modalSlice';
// import { fetchMenuWidgets } from '../../../hooks/useMenusData';

import InputContainer from '../../Input/InputContainer';
import SelectAutocomplete from '../../Input/SelectAutocomplete';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
import editorConfig from '../../../helper/editorConfig';
import ModalWrapper from '../../ui/ModalWrapper';
import BaseAssetViewer from '../../Viewer/BaseAssetViewer';
import UnpairAssetButton from '../../ui/Alert/UnpairAssetButton';
import AssetCardNew from '../../Viewer/AssetCardNew';
import useGet from '../../../hooks/useGet';
import ReactSelectSingle from '../../Input/ReactSelectSingle';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';
import getDefaultValues from '../../../helper/getDefaultValues';
import { removeEmptyProperties } from '../../../helper/arrayHandler';
import schemaMenuDetail from './validationMenuDetail';

const menuTheme = createTheme({
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 1,
            height: '48px',
            '&.Mui-expanded': {
              minHeight: '48px',
              height: '48px',
            },
          }),
        expandIconWrapper: ({ theme }) =>
          theme.unstable_sx({
            color: '#c2c7d0',
            '&.Mui-expanded': { background: 'var(--weak)' },
          }),
      },
    },
    MuiAccordionDetails: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({ padding: '8px 0px 16px' }) } },
  },
});

export default function MenuDetailForm({ defaultValues, onFormSubmit }) {
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);
  const modal = useSelector((state) => state.modal);
  const { menuId } = useParams();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      page: defaultValues?.item_languages[0]?.page,
      section: defaultValues?.item_languages[0]?.section,
      item_languages: getDefaultValues(clientLanguage, defaultValues?.item_languages),
      article: { article_languages: getDefaultValues(clientLanguage, defaultValues?.article?.article_languages) },
    },
    resolver: schemaMenuDetail,
  });

  const assetPair = useSelector((state) => state.assetCrud.selected);
  const dispatch = useDispatch();

  const [menuWidgetId, setMenuWidgetId] = useState(null);

  const pageId = watch('page');
  const sectionId = watch('section');

  const statusPage = HandleWatchingState(pageId);

  const [isDraft, setIsDraft] = React.useState(defaultValues?.is_draft ?? false);
  const [valueUrl, setValueUrl] = React.useState((defaultValues && defaultValues?.item_languages[0]?.page ? 'internal' : 'external') || 'internal');
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const templateId = Cookies.get('bzaTemplate');

  const { data: listPage } = useGet({ url: `pages/?template=${templateId}&language=1&max_size=true`, validate: 'AllPages' });

  const articleId = defaultValues?.article?.id;
  const { data: articletData } = useGet({
    url: `articles/${articleId}/?language=1`,
    validate: 'article',
    isEnabled: Boolean(articleId),
  });

  const defAssets = articletData?.data?.article_assets?.map((def) => def?.asset);

  const { data: listSection } = useGet({
    url: `pages/${pageId}`,
    validate: 'sections',
    isEnabled: statusPage,
  });

  // const { data: menuWidget } = useQuery(['widgets'], fetchMenuWidgets);

  const { data: menuWidget, isFetching } = useGet({
    url: `widgets/?is_internal=false&client=${clientId}&category=1`,
    validate: 'widgets',
  });


  const handleChangeWidget = (event) => {
    setIsDraft(event.target.value);
  };

  const handleChangeUrl = (event) => {
    setValueUrl(event.target.value);
  };

  const onSubmit = (data) => {


    let parent;
    if (modal.componentName === 'addChildMenuDetail') {
      parent = modal.modalId;
    }

    setValue(`article[name]`, data?.item_languages[0].name);

    onFormSubmit({
      is_draft: isDraft,
      item_languages: removeEmptyProperties(data.item_languages, ['path']),
      article: Object.assign(data?.article, {
        name: data?.item_languages[0].name,
        article_assets: assetPair.map((asset) => asset?.id),
      }),
      category: 1,
      parent,
      urlType: valueUrl,
      menu: menuId,
      widget: null,
    });
    reset();
  };

  const optionReset = { name: 'NO', value: null };

  // const optionsWidget = menuWidget?.data?.results?.map((item) => ({
  //   name: item?.name,
  //   value: item?.id,
  // }));

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  const onChangeMenuWidget = (event) => {
    const widgetInput = event.target.innerText;
    if (widgetInput) {
      const widgetSelected = optionsWidget?.filter((u) => u.name === widgetInput);
      if (widgetSelected) {
        setMenuWidgetId(widgetSelected[0]?.value);
      }
    }
  };

  const { mutate: pairingAssets } = usePatchOrPut({ url: `articles/${articleId}/pair_assets`, validate: ['article'] });

  const onPairAssets = async (data) => {
    await pairingAssets(data);
  };

  useEffect(() => {
    clientLanguage?.forEach((lang, index) => setValue(`item_languages[${index}][language]`, lang.language.id));
    clientLanguage?.forEach((lang, index) => setValue(`item_languages[${index}][page]`, pageId));
    clientLanguage?.forEach((lang, index) => setValue(`item_languages[${index}][section]`, sectionId));
    clientLanguage?.forEach((lang, index) => setValue(`article[article_languages[${index}][language]]`, lang.language.id));
  }, [clientLanguage, setValue, pageId, sectionId]);

  return (
    <ThemeProvider theme={menuTheme}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          <MuiCusotmTabs
            optionsTab={clientLanguage.map((lang) => {
              return {
                name: lang?.language?.name,
                id: lang?.language?.id,
              };
            })}
            className=""
            value={valueTab}
            onChange={handleChangeTab}
          />

          {clientLanguage?.map((lang, index) => (
            <MuiCustomTabPanel key={lang.id} value={valueTab} index={index}>
              <InputContainer name={`item_languages[${index}][name]`} control={control} label="Name" errors={errors?.item_languages?.[index]?.name} />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`detail-${lang.id}`} id={`detail-${lang.id}`}>
                  <Typography>Detail Content</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <InputContainer name={`article[article_languages[${index}][title]]`} control={control} label="Title" />
                  <InputContainer name={`article[article_languages[${index}][description]]`} control={control} label="Description" />
                  <div className="typo-style">
                    <Controller
                      name={`article[article_languages[${index}][content]]`}
                      control={control}
                      render={({ field: { value } }) => (
                        <CKEditor
                          id="editor"
                          key={lang.id}
                          editor={Editor}
                          config={editorConfig}
                          data={value || ''}
                          onChange={(event, editor) => {
                            const editorData = editor.getData();
                            setValue(`article[article_languages[${index}][content]]`, editorData);
                          }}
                        />
                      )}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </MuiCustomTabPanel>
          ))}
          <div className="ml-auto flex gap-x-3 mt-5">
            <Button onClick={() => dispatch(handleModalNested({ modalId: '', componentNameNested: 'addAssetMenu' }))} className="ml-auto bz-btn secondary flex" type="button" variant="outlined">
              Add New Asset
            </Button>
            {defaultValues ? <UnpairAssetButton isApi url={`articles/${articleId}/unpair_assets`} validate={['article']} /> : <UnpairAssetButton />}
          </div>
          <div className=" border-dashed min-h-[160px] border-4 p-3 gap-3 flex flex-wrap my-4">
            {defaultValues ? (
              <AssetCardNew assetsData={defAssets} assetComponent="assetPairEditMenu" />
            ) : (
              assetPair.map((asset) => <AssetCardNew assetsData={asset} key={asset?.id} assetComponent="assetPairMenu" />)
            )}
          </div>
          <div className="">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Url Type</FormLabel>
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={valueUrl} onChange={handleChangeUrl}>
                <FormControlLabel value="internal" control={<Radio />} label="Internal" />
                <FormControlLabel value="external" control={<Radio />} label="External" />
              </RadioGroup>
            </FormControl>
            {valueUrl === 'internal' && (
              <div className="">
                <div className="">
                  <ReactSelectSingle
                    name="page"
                    control={control}
                    options={getOptionSelectWithKey(listPage?.data?.results)}
                    defaultValue={getDefaultSelectObject(pageId, getOptionSelectWithKey(listPage?.data?.results))}
                    placeholder="Select Page"
                    isClearable
                  />
                </div>

                <div className="mt-7">
                  <ReactSelectSingle
                    name="section"
                    control={control}
                    options={getOptionSelectWithKey(listSection?.data?.sections)}
                    defaultValue={getDefaultSelectObject(sectionId, getOptionSelectWithKey(listSection?.data?.sections))}
                    placeholder="Select Section"
                    isClearable
                  />
                </div>
              </div>
            )}
            {valueUrl === 'external' && (
              <div className="">
                {clientLanguage?.map((lang, index) => (
                  <InputContainer key={lang.language.id} name={`item_languages[${index}][path]`} control={control} label={`Url ${lang.language.name}`} />
                ))}
              </div>
            )}
          </div>
          {/* <div className="mt-7">
            <SelectAutocomplete
              options={optionsWidget}
              name="menu_type"
              control={control}
              optionReset={optionReset}
              placeHolder="Select Widget"
              variant="outlined"
              onChange={(event) => {
                onChangeMenuWidget(event);
              }}
            />
          </div> */}
          <div className="mt-5">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Publicity</FormLabel>
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={isDraft} onChange={handleChangeWidget}>
                <FormControlLabel value={false} control={<Radio />} label="Publish" />
                <FormControlLabel value control={<Radio />} label="Draft" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="ml-auto flex gap-x-3 mt-7">
            <Button onClick={handleCancel} className="bz-btn secondary ml-auto" type="button" variant="outlined">
              Cancel
            </Button>
            <Button className="bz-btn" type="submit" variant="outlined">
              {`${defaultValues ? 'UPDATE Menu Item' : ' Add Menu Item'}`}
            </Button>
          </div>
        </div>
      </form>
      <DevTool control={control} />

      <ModalWrapper componentName="addAssetMenu" header="Add Asset" maxWidth="lg" isNested>
        <div className="">
          <BaseAssetViewer
            handleClick={defaultValues ? () => onPairAssets({ assets: assetPair?.map((asset) => asset.id) }) : () => dispatch(handleModalNested({ modalIdNested: '' }))}
          />
        </div>
      </ModalWrapper>
    </ThemeProvider>
  );
}

/* eslint-disable */

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Sidebar.module.css';

export default function SideLink({ child, style }) {
  const location = useLocation();
  const activeRoute = child.path === location.pathname ? styles.activeRoute : '';
  return (
    <div key={child?.path || child.name} className="flex border-b border-b-[#F4F1EC] !m-0 py-3 hover:text-[#f16b26]">
      <Link to={child.path} key={child.name} className={[activeRoute, style || 'pl-12'].join(' ')}>
        {/* {child.icon} */}
        <span className="ml-2">{child.name}</span>
      </Link>
    </div>
  );
}

/* eslint-disable */

import React from 'react';
import AssetForm from '../../../components/Form/Asset/AssetForm';
import usePost from '../../../hooks/usePost';
import BZHelmet from '../../../utils/BZHelmet';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BaseBackButton from '../../../components/Button/BaseBackButton';

export default function AssetAdd() {
  const { mutate: postAsset } = usePost({ url: 'assets', validate: ['assets'], navTo: '../asset' });

  const onPostAsset = async (data) => {
    await postAsset(data);
  };
  return (
    <>
      <BZHelmet title="Asset Add" description="" content="" />
      <div>
        <BaseMainTitle title="Asset Add" />
        <div className="p-5 space-y-5">
          <BaseBackButton navTo="/asset" />
          <div className="flex gap-x-3 w-full bg-white border p-5 shadow rounded">
            <AssetForm onFormSubmit={onPostAsset} />
          </div>
        </div>
      </div>
    </>
  );
}

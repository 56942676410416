/* eslint-disable */

import { Button, FormControl, FormGroup, Grid, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

// import Subscription from '../Subscription';
import FileContatiner from '@/components/Input/FileContatiner';
import InputContainer from '@/components/Input/InputContainer';
import usePatchOrPut from '@/hooks/usePatchOrPut';
import { Delete, DeleteForever } from '@mui/icons-material';
import { PiFlowerTulipThin } from 'react-icons/pi';
import Subscription from './Subscription';
import useDelete from '@/hooks/useDelete';
import { useParams } from 'react-router';

function getFileNameAndType(url) {
  return url?.substring(url?.lastIndexOf('/') + 1);
}

// Component
function ClientForm({ defaultValues, onFormSubmit }) {
  const { clientId } = useParams();
  const clientSubscriptions = useSelector((state) => state.client.clientSubscriptions || []);

  const authClient = useSelector((state) => state.auth.client.id);
  const isSameClient = String(authClient) === clientId;

  const { register, handleSubmit, watch, setValue, control } = useForm({
    defaultValues,
  });

  useEffect(() => {
    setValue('subscriptions', clientSubscriptions, { shouldDirty: true });
  }, [clientSubscriptions, setValue]);

  const favicon = watch('favicon');

  function renderFavicon() {
    const createFavicon = favicon && typeof favicon === 'string' ? defaultValues?.favicon : URL.createObjectURL(favicon[0]);
    return createFavicon;
  }

  const HandleFav = () => {
    setValue('favicon', null);
  };

  const logo = watch('logo');

  function renderLogo() {
    const createFavicon = logo && typeof logo === 'string' ? defaultValues?.logo : URL.createObjectURL(logo[0]);
    return createFavicon;
  }

  const HandleLogo = () => {
    setValue('logo', null);
  };

  const onSubmit = (data) => {
    console.log('🚀 ~ file: Client.jsx:27 ~ onSubmit ~ data', data);
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('address', data.address);
    formData.append('phone', data.phone);

    data.subscriptions?.forEach((subscription) => {
      formData.append(`subscriptions`, subscription);
    });

    if (data.logo && data.logo[0] && typeof data.logo[0] !== 'string') {
      formData.append('logo', data.logo[0]);
    } else if (defaultValues?.logo && data.logo) {
      null;
    } else formData.append('logo', '');

    if (data.favicon && data.favicon[0] && typeof data.favicon[0] !== 'string') {
      formData.append('favicon', data.favicon[0]);
    } else if (defaultValues?.favicon && data.favicon) {
      null;
    } else formData.append('favicon', '');

    onFormSubmit(formData);
  };

  const destroyClient = useDelete({
    confText: 'Confirm Delete Client',
    confTitle: 'Are you sure want to delete this client ?',
    success: 'Client deleted',
    validate: ['clients'],
  });

  const onDestroyClient = (id) => {
    destroyClient(`clients/${id}`);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2  gap-x-6">
          <div className="">
            <Grid container className="mb-6">
              <Grid item xs={6}>
                <FileContatiner
                  name="logo"
                  label="Logo"
                  className="!w-[150px]"
                  errors=""
                  control={control}
                  title={getFileNameAndType(logo ? renderLogo() : '')}
                  custom
                  customPaceholder={
                    <div className="max-w-[150px] max-h-[150px] relative">
                      {logo ? (
                        <img src={renderLogo()} alt="" className="w-full h-full object-cover   border p-2" />
                      ) : (
                        <PiFlowerTulipThin className="w-full h-full object-cover   border p-2" />
                      )}
                      {logo && (
                        <div className="absolute left-[calc(100%+10px)] top-[0px]">
                          <IconButton onClick={HandleLogo} aria-label="delete" size="small" type="button" variant="contained" color="error">
                            <DeleteForever className="" />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FileContatiner
                  name="favicon"
                  label="Favicon"
                  className="!w-[150px]"
                  errors=""
                  control={control}
                  title={getFileNameAndType(favicon ? renderFavicon() : '')}
                  custom
                  customPaceholder={
                    <div className="max-w-[150px] max-h-[150px] relative">
                      {favicon ? (
                        <img src={renderFavicon()} alt="" className="w-full h-full object-cover   border p-2" />
                      ) : (
                        <PiFlowerTulipThin className="w-full h-full object-cover   border p-2" />
                      )}
                      {favicon && (
                        <div className="absolute left-[calc(100%+10px)] top-[0px]">
                          <IconButton onClick={HandleFav} aria-label="delete" size="small" type="button" variant="contained" color="error">
                            <DeleteForever className="" />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  }
                />
              </Grid>
            </Grid>
            <FormGroup className="mb-4">
              <FormControl>
                <InputContainer control={control} label="Name" name="name" />
              </FormControl>
            </FormGroup>
            <FormGroup className="mb-4">
              <FormControl>
                <InputContainer control={control} label="Address" name="address" />
              </FormControl>
            </FormGroup>
            <FormGroup className="mb-4">
              <FormControl>
                <InputContainer control={control} label="Phone" name="phone" />
              </FormControl>
            </FormGroup>
          </div>
          <Subscription clientSubscriptions={clientSubscriptions} register={register} />
        </div>
        <div className="mt-4 space-x-2">
          <Button variant="contained" type="submit">
            {defaultValues ? 'Update Client' : 'Create Client'}
          </Button>
          {defaultValues && (
            <Button className="" onClick={() => onDestroyClient(defaultValues?.id)} variant="contained" color="error" disabled={isSameClient} startIcon={<Delete />}>
              Delete Client
            </Button>
          )}
        </div>
      </form>

      {/* <DevTool control={control} /> */}
    </>
  );
}

export default ClientForm;

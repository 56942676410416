/* eslint-disable */

import qs from 'qs';
import request from '../helper/axiosUtil';
import removeEmptyValuesFromObject from '../helper/objectHandler';


export const fetchFilterArticle = async ({ queryKey }) => {
  const transQuery = removeEmptyValuesFromObject(queryKey[1]?.queryKey);
  const url = `/articles/${qs.stringify(transQuery, { addQueryPrefix: true })}`;
  return request({ url });
};



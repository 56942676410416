/* eslint-disable */
import { useSnackbar } from 'notistack';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import request from '../helper/axiosUtil';
import { handleModal } from '../store/slices/modalSlice';

export const fetchHRStructures = async () => {
  return request({ url: `employee-management/structures` });
};

export const fetchHRStructureDetail = ({ queryKey }) => {
  const [, { hrStructureId }] = queryKey; // destructured hrStructureId param passed as prop where queryKey[1] is the id
  return request({ url: `employee-management/structures/${hrStructureId}/` });
};

const createHRStructure = async (data) => request({
  url: 'employee-management/structures/',
  method: 'post',
  data,
  headers: { 'Content-Type': 'application/json' },
});

export const useAddHRStructure = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(createHRStructure, {
    /** Optimistic Update Start */
    onSuccess: (e) => {

      // Invalidates cache and refetch
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      // Invalidates cache and refetch
      queryClient.invalidateQueries("HRStructures")
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('HRStructures', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('HRStructures');
      dispatch(handleModal({ modalId: '' }));
    },
    // onSuccess
    /** Optimistic Update End */
  });
};

const deleteHRStructure = async (dataId) =>
  request({
    url: `employee-management/structures/${dataId}`,
    method: 'delete',
  });

const useDeleteHRStructure = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteHRStructure, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else
        swal('Error');
      // console.log('data', _data);
      // dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('HRStructures');
    },
    onSettled: () => {
      queryClient.invalidateQueries('HRStructures');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyHRStructure = (id) => {
  const { mutate: deleteWid } = useDeleteHRStructure(id);

  // console.log('red', deleteRes);

  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteWid(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    // dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};


const fetchEmployee = async () => {
  return request({ url: `/employee-management/employee_structures/` });
};

export const useEmployeeData = () => {
  return useQuery(['Employees'], fetchEmployee)
};


const createEmployeeHRStructure = async (data) => request({
  url: 'employee-management/employee_structures/',
  method: 'post',
  data,
  headers: { 'Content-Type': 'application/json' },
});

export const useAddEmployeeHRStructure = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(createEmployeeHRStructure, {
    /** Optimistic Update Start */
    onSuccess: (e) => {
      // Invalidates cache and refetch
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      queryClient.invalidateQueries('TreeHRStructures');
    },
    onError: (_err, _newTodo, context) => {
      queryClient.invalidateQueries('TreeHRStructures');
    },
    onSettled: () => {
      queryClient.invalidateQueries('TreeHRStructures');
      dispatch(handleModal({ modalId: '' }));
    },
    // onSuccess
    /** Optimistic Update End */
  });
};


const deleteEmployeeHRStructure = async (dataId) =>
  request({
    url: `employee-management/employee_structures/${dataId}`,
    method: 'delete',
  });

const useDeleteEmployeeHRStructure = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteEmployeeHRStructure, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else
        swal('Error');
      // console.log('data', _data);
      // dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('TreeHRStructures');
    },
    onSettled: () => {
      queryClient.invalidateQueries('TreeHRStructures');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyEmployeeHRStructure = (id) => {
  const { mutate: deleteWid } = useDeleteEmployeeHRStructure(id);

  // console.log('red', deleteRes);

  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteWid(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    // dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};

export const fetchEmpHRStructureDetail = ({ queryKey }) => {
  const [, { empHRStructureId }] = queryKey; // destructured empHRStructureId param passed as prop where queryKey[1] is the id
  return request({ url: `employee-management/employee_structures/${empHRStructureId}/` });
};

// /* eslint-disable */

import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import request from '../helper/axiosUtil';
import { resetAsset } from '../store/slices/assetCrudSlice';
import { handleModal } from '../store/slices/modalSlice';
import renderError from '../utils/Error/renderError';
import { toastError, toastLoading, toastSuccess } from '../constants/ToastConfig';

export default function usePost({ url, validate, disablePostData, navTo }) {
  const qClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCreate = (data) => {
    return request({
      url: `/${disablePostData ? data?.url : url}/`,
      method: 'post',
      data,
      headers: { 'Content-Type': 'application/json' },
    });
  };

  let onToast;

  return useMutation((data) => onCreate(data), {
    /** Optimistic Update Start */
    // onMutate: () => {},
    onSuccess: (e) => {
      onToast = new Promise((resolve, reject) => {
        if (e && [200, 201].includes(e.request.status)) {
          setTimeout(() => {
            resolve('Success');
          }, 500);
          if (navTo) {
            navigate(navTo, { replace: true });
          }
        } else {
          const error = new Error('Request failed');
          error.data = JSON.parse(e.request.response);
          setTimeout(() => {
            renderError({ data: JSON.parse(e.request.response) });
            reject(error);
          }, 500);
        }
      });

      toast.promise(onToast, {
        pending: {
          render() {
            return 'Loading';
          },
          ...toastLoading,
        },
        success: {
          render({ data }) {
            return ` ${data}`;
          },
          ...toastSuccess,
        },
        error: {
          render() {
            return 'Error';
          },
          ...toastError,
        },
      });
    },
    onError: () => {
      // qClient.setQueryData(validate, context.previousData);
    },
    onSettled: () => {
      validate?.forEach((val) => qClient.invalidateQueries(val));
      dispatch(handleModal({ modalId: '' }));
      dispatch(resetAsset());
    },
    // onSuccess
    /** Optimistic Update End */
  });
}

import { Autocomplete, Avatar, Box, Fade, Popover } from '@mui/material';
import React from 'react';
import formatterInitialName from '../../../../helper/formatterInitialName';
import TextAutoComplete from './TextAutoComplete';

export default function PopTopicAutocomplete({ id, open, anchor, setAnchor, onChange, cardData, config, field, options }) {
  const { formatter } = formatterInitialName();

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchor}
      onClose={(event) => {
        if (event && event.stopPropagation) event.stopPropagation();
        setAnchor(null);
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      className="-ml-6"
      PaperProps={{ sx: { backgroundColor: 'transparent' } }}
    >
      <Fade in={open}>
        <div className="relative bg-transparent min-w-[400px] h-full overflow-y-hidden">
          <Box
            className="flex relative flex-col py-4 space-y-2 border border-gray-300 rounded-lg bg-[#ffffff]"
            onClick={(event) => {
              if (event && event.stopPropagation) event.stopPropagation();
            }}
          >
            <div className="pl-4 pr-4 flex flex-col relative">
              <div className="flex items-center">
                <Autocomplete
                  name="autocomplete_assignee"
                  options={options || []}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, newValue) => option?.id === newValue?.id}
                  onChange={(event, dataOption) => {
                    onChange(event, dataOption);
                  }}
                  value={cardData[field]}
                  noOptionsText="not found"
                  renderOption={(props, option) => (
                    <Box key={option.name} component="li" {...props}>
                      {option.icon && <Avatar sx={{ width: 20, height: 20 }} src={option.icon} className="border rounded-full border-gray-300" />}
                      {!option.icon && option.color && <Box sx={{ width: 20, height: 20, backgroundColor: option.color }} className="border rounded-ful" />}
                      {!option.icon && !option.color && (
                        <div className="w-5 h-5 border rounded-full text-xs flex justify-center items-center">
                          <span>{option.name ? formatter(option.name) : ''}</span>
                        </div>
                      )}
                      <span className="text-ellipsis truncate ml-2">{option.name}</span>
                    </Box>
                  )}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextAutoComplete params={params} label={config.label} placeholder={config.placeholder} />}
                />
              </div>
            </div>
          </Box>
        </div>
      </Fade>
    </Popover>
  );
}

import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';

export default function BZHelmet({ title, description, content }) {
  const client = useSelector((state) => state.client.activeDetailClient);
  const bzTitle = import.meta.env.VITE_TITLE_APP;

  let contentTitle = `${title} | ${bzTitle}`;
  if (client) {
    contentTitle = `${title} | ${client.name} - ${bzTitle}`;
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{contentTitle}</title>
        <meta name={description} content={content} />
      </Helmet>
    </HelmetProvider>
  );
}

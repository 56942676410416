/* eslint-disable */
import Close from '@mui/icons-material/Close';
import React, { createContext, useEffect, useState } from 'react';

import Select, { components } from 'react-select';
import { Controller } from 'react-hook-form';
import HandleWatchingState from '../../helper/HandleWatchingState';

const SelectMultipleContext = createContext();

const { ValueContainer, Placeholder } = components;

function CustomClear(props) {
  const { setValue, getValue, selectProps, innerProps } = props;
  const transValue = getValue();
  return transValue[0]?.value !== null ? (
    <button
      type="button"
      onClick={() => {
        setValue([{ label: '-', value: null }]);
        // selectProps.onMenuOpen();
      }}
    >
      <Close className="h-[20px] w-[20px] text-[#6d6e6f] hover:text-black" />
    </button>
  ) : null;
}

function CustomValueContainerError({ children, ...props }) {
  const [isFocused, setIsFocused] = React.useContext(SelectMultipleContext);
  useEffect(() => {
    if (props.selectProps.menuIsOpen) {
      setIsFocused(true);
    }
  }, [props.selectProps.menuIsOpen, setIsFocused]);
  return (
    <ValueContainer {...props}>
      <Placeholder className="text-[#d32f2f]" {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );
}

function CustomValueContainer({ children, ...props }) {
  const [isFocused, setIsFocused] = React.useContext(SelectMultipleContext);
  useEffect(() => {
    if (props.selectProps.menuIsOpen) {
      setIsFocused(true);
    }
  }, [props.selectProps.menuIsOpen, setIsFocused]);
  return (
    <ValueContainer {...props}>
      <Placeholder className="" {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );
}

export default function ReactSelectSingleHR({
  options = [],
  defaultValue = { label: '-', value: null },
  optionReset = { label: '-', value: null },
  getOptionLabel,
  getOptionValue,
  placeholder,
  isSearchable,
  errors,
  control,
  name,
  isClearable = false,
  isDisabled = false,
  isCancel,
  setIsCancel,
  resetFunction,
  extraAction,
  onChangeValue,
  currValue,
  replaceValue,
  resetReplaceValue,
  floatLabel = true,
  dispatch,
  dispatchCondition,
  label,
}) {
  const [isFocused, setIsFocused] = React.useState(false);

  const value = [isFocused, setIsFocused];

  const component = {
    ClearIndicator: CustomClear,
    IndicatorSeparator: () => null,
    ValueContainer: errors ? CustomValueContainerError : CustomValueContainer,
  };

  const watchingCurrValue = HandleWatchingState(currValue);

  useEffect(() => {
    if (isFocused && resetReplaceValue && !watchingCurrValue) {
      resetReplaceValue();
    }
  }, [isFocused, resetReplaceValue, watchingCurrValue]);

  useEffect(() => {
    if (isCancel) {
      resetFunction();
      setIsCancel(false);
    }
  }, [isCancel]);

  useEffect(() => {
    if (dispatchCondition) {
      dispatch();
    }
  }, [dispatchCondition]);

  // console.log(defaultValue, 'defaultValue');

  const watchDef = HandleWatchingState(defaultValue && defaultValue.value);

  const RenderReactSelectSingle = () => {
    return (
      <div className=" w-full">
        {defaultValue && defaultValue.value && (
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Select
                  closeMenuOnSelect={false}
                  className={`${errors && 'border-[1px] border-[#d32f2f] rounded-[11px]'}`}
                  menuPosition="fixed"
                  defaultValue={defaultValue}
                  menuPlacement="auto"
                  hideSelectedOptions={false}
                  options={options}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue}
                  isDisabled={isDisabled}
                  onChange={(v) => {
                    onChange(v?.value || null);
                    if (extraAction) {
                      extraAction();
                    }
                    if (onChangeValue) {
                      onChangeValue(v?.value);
                    }
                  }}
                  components={component}
                  isSearchable={isSearchable}
                  isClearable={isClearable}
                  errors={Boolean(errors)}
                  placeholder={placeholder}
                  renderValue={(selected) => selected.join(' ')}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '11px',
                    }),
                    container: (provided) => ({
                      ...provided,
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      overflow: 'visible',
                      height: '48px',
                      color: 'black',
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: 'absolute',
                      top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
                      transition: 'top 0.1s, font-size 0.1s',

                      background: floatLabel ? (state.hasValue ? 'white' : 'transparent') : !state.hasValue && !state.selectProps.inputValue ? 'white' : 'transparent',
                      margin: '0px 20',
                      borderRadius: '3px',
                      color: floatLabel ? 'black' : !state.hasValue && !state.selectProps.inputValue ? 'black' : 'transparent',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: 'black',
                      fontSize: '14px',
                      fontWeight: 600,
                    }),
                  }}
                />
              );
            }}
          />
        )}
        {defaultValue && !defaultValue.value && (
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Select
                  closeMenuOnSelect={false}
                  className={`${errors && 'border-[1px] border-[#d32f2f] rounded-[11px]'}`}
                  menuPosition="fixed"
                  defaultValue={defaultValue}
                  menuPlacement="auto"
                  hideSelectedOptions={false}
                  options={options}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue}
                  isDisabled={isDisabled}
                  onChange={(v) => {
                    onChange(v?.value || null);
                    if (extraAction) {
                      extraAction();
                    }
                    if (onChangeValue) {
                      onChangeValue(v?.value);
                    }
                  }}
                  components={component}
                  isSearchable={isSearchable}
                  isClearable={isClearable}
                  errors={Boolean(errors)}
                  placeholder={placeholder}
                  renderValue={(selected) => selected.join(' ')}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '11px',
                    }),
                    container: (provided) => ({
                      ...provided,
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      overflow: 'visible',
                      height: '48px',
                      color: 'black',
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: 'absolute',
                      top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
                      transition: 'top 0.1s, font-size 0.1s',

                      background: floatLabel ? (state.hasValue ? 'white' : 'transparent') : !state.hasValue && !state.selectProps.inputValue ? 'white' : 'transparent',
                      margin: '0px 20',
                      borderRadius: '3px',
                      color: floatLabel ? 'black' : !state.hasValue && !state.selectProps.inputValue ? 'black' : 'transparent',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: 'black',
                      fontSize: '14px',
                      fontWeight: 600,
                    }),
                  }}
                />
              );
            }}
          />
        )}
        {!defaultValue && (
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Select
                  closeMenuOnSelect={false}
                  className={`${errors && 'border-[1px] border-[#d32f2f] rounded-[11px]'}`}
                  menuPosition="fixed"
                  menuPlacement="auto"
                  hideSelectedOptions={false}
                  options={options}
                  // defaultValue={defaultValue}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue}
                  isDisabled={isDisabled}
                  onChange={(v) => {
                    onChange(v?.value || null);
                    if (extraAction) {
                      extraAction();
                    }
                    if (onChangeValue) {
                      onChangeValue(v?.value);
                    }
                  }}
                  components={component}
                  isSearchable={isSearchable}
                  isClearable={isClearable}
                  errors={Boolean(errors)}
                  placeholder={placeholder}
                  renderValue={(selected) => selected.join(' ')}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: '11px',
                    }),
                    container: (provided) => ({
                      ...provided,
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      overflow: 'visible',
                      height: '48px',
                      color: 'black',
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: 'absolute',
                      top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
                      transition: 'top 0.1s, font-size 0.1s',

                      background: floatLabel ? (state.hasValue ? 'white' : 'transparent') : !state.hasValue && !state.selectProps.inputValue ? 'white' : 'transparent',
                      margin: '0px 20',
                      borderRadius: '3px',
                      color: floatLabel ? 'black' : !state.hasValue && !state.selectProps.inputValue ? 'black' : 'transparent',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: 'black',
                      fontSize: '14px',
                      fontWeight: 600,
                    }),
                  }}
                />
              );
            }}
          />
        )}

        {errors && <div className="text-[#d32f2f] text-[0.75rem] mt-[4px] border-[#d32f2f]">{errors?.message}</div>}
      </div>
    );
  };

  return (
    <div className="">
      {label && <div className="mb-[10px] text-[14px] font-[600] leading-[20px] capitalize">{label}</div>}
      <SelectMultipleContext.Provider value={value}>
        {!watchDef && RenderReactSelectSingle()}
        {watchDef && RenderReactSelectSingle()}
      </SelectMultipleContext.Provider>
    </div>
  );
}

/* eslint-disable */

import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChatBubble, MailOutline, PersonAddAlt, WorkOutlineOutlined, QueryBuilderOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ScrollContainer from 'react-indiana-drag-scroll';
import Avatar from '@mui/material/Avatar';
import { Button, Collapse } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LayersIcon from '@mui/icons-material/Layers';
import Paper from '@mui/material/Paper';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import swal from 'sweetalert';
import { getTeams } from '../../store/slices/usersTeamSlice';
import TeamForm from '../../components/Form/Task/TeamForm';
import useWindowDimensions from '../../helper/useWindowDimensions';
import { useMousePos } from '../../helper/useMousePos';
import HeadersTask from '../../components/Header/HeaderTask/HeaderTask';
import { fetchTeams, createTeams, deleteTeams } from '../../hooks/useTeamsData';
import request from '../../helper/axiosUtil';
import { deleteProjects } from '../../hooks/useProjectsData';
import store from '../../store/index';
import { projectUpdate } from '../../store/slices/taskCrudSlice';
import InviteUser from '../../components/Modal/Task/ModalTeam/InviteUser';

import teamApi from '../../api/task/team';
import BZHelmet from '../../utils/BZHelmet';

export default function Team() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const clientId = useSelector((state) => state.client.activeClient);
  const { data: TeamsData } = useQuery(['teams', { clientId }], fetchTeams);
  // console.log('team', TeamsData);
  const qClient = useQueryClient();

  const [childAccordion, setChildAccordion] = useState('');
  const [editTeamId, setEditTeamId] = useState('');
  const [avaCard, setAvacard] = useState('');
  const [sendUpdate, setSendUpdate] = useState(false);
  // const { height } = useWindowDimensions();
  // const { posY } = useMousePos();
  const [posCard, setPosCard] = useState(0);

  /* eslint-disable */
  const _intervalRef = useRef(null);
  const [startCounter, setStartCounter] = useState(false);
  const [counter, setCounter] = useState(0);
  // console.log(counter);

  const [isInvite, setIsInvite] = useState(false);

  useLayoutEffect(() => {
    store.dispatch({ type: 'sidebarTask/setActiveHeader', payload: 'base' });
  }, []);

  // useEffect(() => {
  //   if (startCounter) {
  //     _intervalRef.current = setInterval(() => {
  //       setCounter((counter) => counter + 1);
  //     }, 250);
  //   } else {
  //     clearInterval(_intervalRef.current);
  //   }
  //   return () => clearInterval(_intervalRef.current);
  // }, [startCounter]);

  // let isUpCard;
  // if (height - posCard < 400) {
  //   isUpCard = true;
  // }

  const handleChildAccd = (id) => {
    setChildAccordion(id);
  };

  useEffect(() => {
    dispatch(getTeams(clientId));
  }, [dispatch, clientId]);

  let datTeam;
  TeamsData?.data?.results?.map((te) => {
    if (te.id === editTeamId) {
      datTeam = te;
    }
    return datTeam;
  });

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: { bgcolor: stringToColor(name) },
      children: `${name.split(' ')[0][0]}`,
    };
  }

  function cardAvatar(name) {
    return (
      <div style={{ backgroundColor: stringToColor(name) }} className="h-[200px] items-center flex justify-center text-3xl text-white uppercase font-semibold">
        {`${name?.split(' ')[0][0]}`}
      </div>
    );
  }

  const { mutateAsync: submitTeam } = useMutation(createTeams, {
    onSuccess: (e) => {
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onSubmitTeam = async (data) => {
    await submitTeam(data);
    qClient.invalidateQueries('teams');
  };

  const updateTeams = async (data) =>
    request({
      url: `/project-management/teams/${editTeamId}/`,
      method: 'patch',
      data: {
        client: clientId,
        name: data.name,
        team_users: data.team_users,
      },
      headers: { 'Content-Type': 'application/json' },
    });

  const { mutateAsync: updateTeam } = useMutation(updateTeams, {
    onSuccess: (e) => {
      qClient.invalidateQueries('teams');
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
        dispatch(projectUpdate());
      } else {
        enqueueSnackbar('success', { variant: 'success' });
      }
    },
  });

  const onUpdateTeam = async (data) => {
    // console.log('dat', data)
    await updateTeam(data);
    setEditTeamId('');
    qClient.invalidateQueries('teams');
  };

  const { mutateAsync: destroyTeams } = useMutation(deleteTeams, {
    onSuccess: () => {
      qClient.invalidateQueries('teams');
    },
  });
  const onDestroyTeam = async (id) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        destroyTeams(id);
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else {
        swal('Your imaginary file is safe!');
      }
    });
  };

  const { mutateAsync: destroyProjects } = useMutation(deleteProjects, {
    onSuccess: () => {
      qClient.invalidateQueries('teams');
    },
  });
  const onDestroyProject = async (id) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        destroyProjects(id);
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else {
        swal('Your imaginary file is safe!');
      }
    });
  };

  const handleInviteUser = () => {
    setIsInvite(!isInvite);
  };

  const onInviteUser = (data) => {
    const { team, email } = data;
    const { inviteTeams } = teamApi();
    inviteTeams(team, { email, language: 1 })
      .then((res) => {
        if (res.status === 200 || res.data) {
          enqueueSnackbar('Invite sent' || 'Success', { variant: 'success' });
          handleInviteUser();
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.response?.data?.non_field_errors[0], { variant: 'error' });
      });
  };

  return (
    <>
      <BZHelmet title="Teams" description="bz publish task management teams" />
      <HeadersTask />
      <div className="p-4 ">
        <div className="max-w-[1200px] mx-auto flex gap-x-3">
            <Paper className="w-[600px] h-full p-4">
              <div className="min-h-[50px] border-b-[2px] pb-2 flex justify-between">
                <h2 className='text-xl font-extrabold h-fit mt-2'>Teams</h2>
                {/* <div className="flex items-center border rounded-md p-1 gap-x-2">
                  <ChatBubble className="w-4 h-4" />
                  <div className="text-sm">Send messages</div>
                </div> */}
              </div>
              <div className="pt-4">
                {TeamsData?.data?.results?.map((t) => (
                  <div key={t.id} className="">
                    {' '}
                    <div className={` hover:bg-gray-50 ${t?.users?.length === 0 && 'pl-6'}`}>
                      <div className="flex items-center px-2">
                        <div className="flex items-center">
                          {t?.users?.length > 0 && (
                            <button type="button" onClick={() => handleChildAccd(childAccordion === t.id ? '' : t.id)} className="text-black h-fit">
                              <ArrowDropDownIcon className={` ${childAccordion === t.id ? '-rotate-90' : ''}`} />
                            </button>
                          )}
                          <Typography className=" pl-1 text-black text-sm">{t.name}</Typography>
                        </div>
                        <div className="flex items-center ml-auto gap-x-2">
                          <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                              <>
                                <button type="button" {...bindTrigger(popupState)}>
                                  <MoreHorizIcon className="w-5 h-fit font-bold text-gray-300 hover:text-white" />
                                </button>
                                <Menu {...bindMenu(popupState)}>
                                  <MenuItem onClick={popupState.close}>
                                    <button type="button" onClick={() => setEditTeamId(t.id)}>
                                      Edit Team
                                    </button>
                                  </MenuItem>
                                  <MenuItem onClick={popupState.close}>
                                    <button type="button" onClick={() => onDestroyTeam(t.id)}>
                                      Delete Team
                                    </button>
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </PopupState>
                          <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                              <>
                                <button type="button" {...bindTrigger(popupState)}>
                                  <AddIcon className="w-5 h-fit font-bold text-gray-300 hover:text-white" />
                                </button>
                                <Menu {...bindMenu(popupState)}>
                                  <div className="pl-4 text-xs text-gray-600 capitalize">Create project</div>
                                  <MenuItem onClick={popupState.close}>
                                    <div className="flex gap-x-2">
                                      <LayersIcon />
                                      <Link to={`/task/team/${t.id}/project/new/blank`}>Blank Project</Link>
                                    </div>
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </PopupState>
                        </div>
                      </div>
                    </div>
                    <Collapse in={childAccordion === t.id} className="py-2 relative">
                      <ScrollContainer className=" flex gap-x-2 px-6 overflow-hidden">
                        {t?.users?.map((user) => (
                          <div
                            key={user.id}
                            // onMouseEnter={() => [setStartCounter(true), setAvacard(user.id), setPosCard(posY)]}
                            // onMouseLeave={() => [setStartCounter(false), setCounter(0)]}
                            className=""
                          >
                            <Avatar className="w-6 h-6" {...stringAvatar(user?.name)} />
                            {user.id === avaCard && counter > 2 && (
                              <div className={`absolute w-[250px]  left-0 pt-4 z-50  ${isUpCard && '-translate-y-[108%]'}`}>
                                <div className="bg-white rounded-lg shadow-md h-[470px] overflow-auto">
                                  {cardAvatar(user.name)}
                                  <div className="px-2 py-4 text-xl">
                                    <div>{user.name}</div>
                                    <div className="py-4 space-y-2">
                                      <div className="flex items-center gap-x-2">
                                        <WorkOutlineOutlined className="text-gray-500 w-5 h-5" />
                                        <div className="text-sm text-gray-500">Webdev</div>
                                      </div>
                                      <div className="flex items-center gap-x-2">
                                        <MailOutline className="text-gray-500 w-5 h-5" />
                                        <div className="text-sm text-gray-500">{user.email}</div>
                                      </div>
                                      <div className="flex items-center gap-x-2">
                                        <QueryBuilderOutlined className="text-gray-500 w-5 h-5" />
                                        <div className="text-sm text-gray-500">local time</div>
                                      </div>
                                    </div>
                                    <div className="bg-[#F9F8F8] px-4 py-4 absolute bottom-0 w-full left-0">
                                      <div className="space-y-2">
                                        <div className="text-sm text-center p-1 border rounded-md">Send message</div>
                                        <div className="text-sm text-center p-1 border rounded-md">View task</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </ScrollContainer>
                      <div className="">
                        {t?.projects.map((project) => (
                          <div key={project.id} className="flex justify-between my-2 px-5 hover:bg-gray-50">
                            <div className="flex items-center gap-x-2 py-1">
                              <img src={project.icon} alt="" className="w-8 h-8" />
                              <div className="">{project.name}</div>
                            </div>
                            <PopupState variant="popover" popupId="demo-popup-menu">
                              {(popupState) => (
                                <>
                                  <button type="button" {...bindTrigger(popupState)}>
                                    <MoreHorizIcon className="w-5 h-fit font-bold text-gray-300 hover:text-white" />
                                  </button>
                                  <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={popupState.close}>
                                      <Link to={`/task/team/${t.id}/project/${project.id}/edit`}>Edit Project</Link>
                                    </MenuItem>
                                    <MenuItem onClick={popupState.close}>
                                      <button type="button" onClick={() => onDestroyProject(project.id)}>
                                        Delete Project
                                      </button>
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                            </PopupState>
                          </div>
                        ))}
                      </div>
                    </Collapse>
                  </div>
                ))}
              </div>
            </Paper>
            <Paper className="w-full h-fit p-4">
              <div className="w-full">
                <div className="min-h-[50px] border-b-[2px] pb-2 flex justify-between">
                  <h2 className='text-xl font-extrabold h-fit mt-2'>{editTeamId ? 'Edit' : 'Create'}</h2>
                  <button className="ml-auto min-w-[140px] flex items-center justify-center text-white py-[8px] bg-[#2C6D47] rounded-xl" onClick={handleInviteUser} startIcon={<PersonAddAlt />}>
                    Invite User
                  </button>
                </div>
                <TeamForm
                  onClick={() => setEditTeamId('')}
                  onFormSubmit={editTeamId ? onUpdateTeam : onSubmitTeam}
                  defaultValues={datTeam}
                  key={datTeam?.id}
                  updateStatus={sendUpdate}
                />
              </div>
            </Paper>
        </div>
      </div>
      <InviteUser open={isInvite} data={TeamsData?.data?.results} onClose={handleInviteUser} action={onInviteUser} />
    </>
  );
}

// /* eslint-disable */

import BZButton from '@/components/Button/BZButton';
import BaseDeleteButton from '@/components/Button/BaseDeleteButton';
import BaseEditButton from '@/components/Button/BaseEditButton';
import BaseSearchButton from '@/components/Button/BaseSearchButton';
import CustomTable from '@/components/Table/CustomTable';
import useDelete from '@/hooks/useDelete';
import useGetFilter from '@/hooks/useGetFilter';
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },

  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'last-login',
    numeric: false,
    disablePadding: false,
    label: 'Last Login',
  },

  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    headerAlign: 'center',
    label: 'Action',
  },
];

function ActionCell(props) {
  const { onClick, id } = props;
  return (
    <div className="w-full flex space-x-2 justify-center">
      <Link to={`/user/detail/${id}`}>
        <BaseEditButton />
      </Link>
      <BaseDeleteButton onClick={onClick} variant="icon" />
    </div>
  );
}

function TableCells(props) {
  const { row, labelId, ariaChecked, selected } = props;

  const destroyUser = useDelete({
    confText: `modals.user_delete.confirmation_text`,
    confTitle: `modals.user_delete.title`,
    success: 'User deleted',
    validate: ['users'],
  });

  const onDestroyUser = (id) => {
    destroyUser(`users/${id}`);
  };
  return (
    <TableRow hover role="checkbox" aria-checked={ariaChecked} tabIndex={-1} key={row.id} selected={selected}>
      <TableCell component="th" id={labelId} scope="row">
        {row?.name || '-'}
      </TableCell>
      <TableCell align="left">{row?.email || '-'}</TableCell>
      <TableCell align="left">{row?.last_login ? moment(row?.last_login).format('YYYY-MM-DD') : '-'}</TableCell>
      <TableCell align="left">
        <ActionCell id={row?.id} onClick={() => onDestroyUser(row?.id)} />
      </TableCell>
    </TableRow>
  );
}

export default function TableUser() {
  const clientId = useSelector((state) => state.client.activeClient);

  const [dataUser, setDataUser] = useState([]);
  const [searchInput, setSearchInput] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const { isFetching } = useGetFilter({
    url: `users`,
    queryString: {
      search: searchInput,
      client: clientId,
      page,
      page_size: rowsPerPage,
    },
    validate: 'users',
    onSuccess: (e) => {
      setCount(e?.data?.count);
      setDataUser(e?.data?.results);
    },
    isEnabled: isDataLoad,
  });

  const onChangeSearch = (event) => {
    setPage(1);
    setSearchInput(event.target.value);
  };

  const data = useMemo(() => dataUser, [dataUser]);

  function generateFilterComponent() {
    return (
      <div className="flex space-x-2 items-center">
        <BaseSearchButton onChange={onChangeSearch} setPage={setPage} setIsDataLoad={setIsDataLoad} />

        {/* <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} /> */}
      </div>
    );
  }

  return (
    <div className="p-5">
      <div className="">
        <div className="bg-gray-100 w-full flex py-5 px-3 items-center">
          <div className="ml-auto flex gap-x-3">
            <Link to="/user/add">
              <BZButton variant="secondary">Add User</BZButton>
            </Link>
          </div>
        </div>
        <div className=" py-4 my-4 border-y-2 ">{generateFilterComponent()}</div>
      </div>
      <CustomTable
        dataApi={data}
        setIsDataLoad={setIsDataLoad}
        isFetching={!isFetching}
        headCells={headCells}
        ActionCell={ActionCell}
        TableCells={TableCells}
        page={page - 1}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    </div>
  );
}

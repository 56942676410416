/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import InputContainer from '../../Input/InputContainer';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';
import { getUsers } from '../../../store/slices/usersTeamSlice';
import { delTeamUsers } from '../../../hooks/useTeamUsersData';
import { projectUpdate, projectEdit } from '../../../store/slices/taskCrudSlice';

function TeamForm({ defaultValues, onFormSubmit, onClick }) {
  const { register, control, handleSubmit } = useForm({ defaultValues });
  const clientId = useSelector((state) => state.client.activeClient);
  const isUser = useSelector((state) => state.auth.user);
  const isProjUp = useSelector((state) => state.crud.isSuccess);
  const isEditData = useSelector((state) => state.crud.editData);
  const dispatch = useDispatch();
  const qClient = useQueryClient();
  const userLogin = isUser.id;

  const user = useSelector((state) => state.users);

  const [valTeamUsers, setValTeamUsers] = useState([]);

  const destroyTUser = [];
  isEditData?.map((des) => {
    destroyTUser.push(des?.id);
    return destroyTUser;
  });

  const optionsVal = [];

  valTeamUsers?.map((item) => {
    if (item?.user?.id) {
      optionsVal.push(item?.user?.id);
    } else optionsVal.push(item?.user);
    return optionsVal;
  });

  useEffect(() => {
    dispatch(getUsers(clientId));
  }, [dispatch, clientId]);

  const { mutateAsync: destroyTeamUser } = useMutation(delTeamUsers, {
    onSuccess: () => {
      dispatch(projectUpdate());
      qClient.invalidateQueries('teams');
    },
  });

  async function delTeamUs() {
    await destroyTeamUser({ team_user_ids: destroyTUser });
  }

  useEffect(() => {
    if (isProjUp) {
      delTeamUs();
    }
  }, []);

  const options = user?.users?.results?.map((item) => ({
    label: item?.name,
    value: {
      user: item?.id,
      role: 3,
    },
  }));

  const filterOptCreate = [];
  options?.map((fil) => {
    if (fil.value.user) {
      filterOptCreate.push(fil);
    }
    return filterOptCreate;
  });

  const filterOptEdit = [];
  options?.map((fil) => {
    if (fil.value.user && fil.value.role !== 1) {
      filterOptEdit.push(fil);
    }
    return filterOptEdit;
  });

  const defValUsers = defaultValues?.team_users?.map((item) => ({
    team_user: item.id,
    label: item?.user?.name,
    value: {
      user: item?.user?.id,
      role: 3,
    },
  }));

  const def = defValUsers?.map((d) => d.value);
  const onSubmit = (data) => {
    console.log(data?.team_users);
    const fillTusers = data?.team_users?.filter((value) => {
      return !def?.some((item) => value.user === item.user);
    });

    const fillTemus = defaultValues?.team_users?.filter((value) => {
      return !data?.team_users?.some((item) => value.user.id === item.user);
    });

    const fillTeam = data?.team_users?.map((item) => ({
      user: item?.user?.id,
      role: 3,
    }));

    const filterOptCreate = [];
    data?.team_users?.map((fil) => {
      if (fil.user !== userLogin) {
        filterOptCreate.push(fil);
      }
      return filterOptCreate;
    });

    const lastFill = JSON.stringify(fillTeam) === JSON.stringify(def);
    dispatch(projectEdit({ editData: lastFill ? [] : fillTemus }));

    setValTeamUsers(data?.team_users);

    if (lastFill) {
      onFormSubmit({ name: data.name, client: clientId });
    } else onFormSubmit({ name: data.name, client: clientId, team_users: defaultValues ? fillTusers : filterOptCreate });
  };

  useEffect(() => {
    register('team_users');
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input {...register('client')} type="hidden" name="client_id" value={clientId} />
      <div className="w-full space-y-4">
        <InputContainer
          name="name"
          control={control}
          label="Name"
          // errors={errors.name}
        />
        <div className="relative  w-full  ">
          <Controller
            name="team_users"
            control={control}
            render={({ field: { onChange } }) => (
              <ReactSelectMultiple options={filterOptCreate} placeholder="Users" defaultValue={defValUsers} isMulti onChange={(v) => onChange(v?.map((val) => val.value))} />
            )}
          />
        </div>
        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={onClick} className={` ${defaultValues ? 'flex' : 'hidden'} `} variant="outlined">
            Cancel
          </Button>
          <button className="ml-auto min-w-[140px] flex items-center justify-center text-[#2C6D47] py-[8px] bg-white border rounded-xl border-[#2C6D47]" type="submit">
            {defaultValues ? 'Update Team' : 'Create Team'}
          </button>
        </div>
      </div>
    </form>
  );
}
export default TeamForm;

// /* eslint-disable */
/* eslint-disable no-unused-vars */

import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import request from '../helper/axiosUtil';
import { resetAsset } from '../store/slices/assetCrudSlice';
import { handleModal } from '../store/slices/modalSlice';
import renderError from '../utils/Error/renderError';

export default function usePatchOrPutExtra({ url, validate, method = 'patch', disableId, onSuccess, onMutate }) {
  const qClient = useQueryClient();
  const dispatch = useDispatch();

  const update = (data) => {
    return request({ url: `/${url}/`, method, data });
  };

  return useMutation((data) => update(data), {
    onMutate,
    onSuccess,
    onError: (err, newData, context) => {
      // qClient.setQueryData([validate, context.newData.id], context.previousData);
    },
    onSettled: () => {
      validate?.forEach((val) => qClient.invalidateQueries(val));
      dispatch(handleModal({ modalId: '' }));
      dispatch(resetAsset());
    },
  });
}

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export function exportToExcel(apiData, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(apiData);

  // console.log("XLSX.utils ", XLSX.utils);
  // console.log("apiData ", apiData);
  // console.log("ws ", ws);

  // format cell
  Object.keys(apiData[0]).forEach((col, colIndex) => {
    // console.log("col ", col);
    if (typeof apiData[0][col] === 'number') {
      XLSX.utils.cell_set_number_format(ws, XLSX.utils.encode_cell({ r: 0, c: colIndex }), '#,##0.00');
    }
  });

  apiData.forEach((row, rowIndex) => {
  // console.log("row ", row);
  // console.log("Object.keys(row) ", Object.keys(row));
    Object.keys(row).forEach((col, colIndex) => {
      if (typeof row[col] === 'number') {
        XLSX.utils.cell_set_number_format(ws, XLSX.utils.encode_cell({ r: rowIndex + 1, c: colIndex }), '#,##0.00');
      }
    });
  });

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName + fileExtension);
}

// export function exportToExcel(apiData, fileName) {
//   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//   const fileExtension = '.xlsx';

//   const ws = XLSX.utils.json_to_sheet(apiData);
//   const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
//   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//   const data = new Blob([excelBuffer], { type: fileType });
//   console.log("ws ", ws);
//   console.log("wb ", wb);
//   console.log("excelBuffer ", excelBuffer);
//   console.log("data ", data);

//   FileSaver.saveAs(data, fileName + fileExtension);
// }

export function exportToExcelMultiple(apiDatas, sheetNames, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const wb = { Sheets: {}, SheetNames: sheetNames };

  sheetNames.forEach((sheetName, idx) => {
    wb.Sheets[sheetName] = XLSX.utils.json_to_sheet(apiDatas[idx]);
  });

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

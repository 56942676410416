/* eslint-disable */

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validation = yup
  .object()
  .shape({
    name: yup.string().required('Name is required'),
  })
  .required();

const schemaSection = yupResolver(validation);

export { schemaSection };

/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import usePostExtra from '../../../hooks/usePostExtra';
import { resetAsset } from '../../../store/slices/assetCrudSlice';
import renderError from '../../../utils/Error/renderError';
import ArticleFormNew from '../../../components/Form/Article/ArticleFormNew';
import BZHelmet from '../../../utils/BZHelmet';
import BaseMainTitle from '../../../components/BaseMainTitle';

export default function ArticleAdd() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [resIdArticle, setResIdArticle] = useState(null);

  useEffect(() => {
    dispatch(resetAsset());
  }, [dispatch]);

  const { mutate: postArticle } = usePostExtra({
    url: 'articles',
    validate: ['articles'],
    onSuccess: (e) => {
      if (e.request.status === 201) {
        setResIdArticle(e?.data?.id);
        enqueueSnackbar('success', { variant: 'success' });
        // navigate('../article', { replace: true });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
        renderError({ data: JSON.parse(e.request.response) });
      }
    },
  });

  const onPostArticle = async (data) => {
    await postArticle(data);
  };
  return (
    <>
      <BZHelmet title="Article Add" description="" content="" />
      <div>
        <BaseMainTitle title="Article Add " />
        <div className="p-5 ">
          <div className="bg-white border p-5 shadow rounded">
            <ArticleFormNew onFormSubmit={onPostArticle} resIdArticle={resIdArticle} setResIdArticle={setResIdArticle} />
          </div>
        </div>
      </div>
    </>
  );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import page from '../../../api/page';
import section from '../../../api/section';
import user from '../../../api/user';
import widget from '../../../api/widget';

export const getAllWidgets = createAsyncThunk('client/setAllWidget', async () => {
  const { getWidgets } = widget();
  const response = await getWidgets();
  return response.data;
});

export const getAllPages = createAsyncThunk('client/setAllPages', async () => {
  const { getPages } = page();
  const response = await getPages();
  return response.data;
});

export const getAllArticleCategories = createAsyncThunk('client/setAllArticleCategories', async () => {
  const { getArticleCategories } = section();
  const response = await getArticleCategories();
  return response.data;
});

export const getAllSections = createAsyncThunk('client/setAllSections', async () => {
  const { getSections } = section();
  const response = await getSections();
  return response.data;
});

export const getAllEmployees = createAsyncThunk('client/setAllEmployees', async () => {
  const { getEmployees } = user();
  const response = await getEmployees();
  return response.data;
});

const sectionSlice = createSlice({
  name: 'content',
  initialState: {
    isLoaded: {},
    widgetList: [],
    pagesList: [],
    categoryList: [],
    employeeList: [],
  },
  reducers: {
    setActiveSite(state, action) {
      const currState = state;
      currState.activeSite = action.payload;
      Cookies.set('bzaSite', action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllWidgets.fulfilled, (state, action) => {
      const currState = state;
      currState.widgetList = action.payload;
    });
  },
});

export const contentActions = sectionSlice.actions;
export default sectionSlice;

import ClientForm from '@/components/Form/Client/ClientForm';
import usePost from '@/hooks/usePost';
import BZHelmet from '@/utils/BZHelmet';

export default function ClientAdd() {
  const { mutate: postClient } = usePost({
    url: `clients`,
    validate: ['clients'],
  });

  const onSubmitClient = async (data) => {
    await postClient(data);
  };

  return (
    <>
      <BZHelmet title="Clients" description="bz publish task management clients" />
      <div className="w-full max-w-[1200px] py-4 px-4  mx-auto">
        <div className="space-y-8">
          <div className="mr-6 text-5xl font-extrabold leading-[125%]">Clients</div>
        </div>

        <div className="mt-8">
          <div className="mb-4 text-xl font-semibold">Informations</div>
          <div className="border p-4">
            <ClientForm onFormSubmit={onSubmitClient} />
          </div>
        </div>
      </div>
    </>
  );
}

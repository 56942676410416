import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, Slide, Tabs, Tab, Tooltip } from '@mui/material';
import { ArrowDropDown, Menu, StarBorder } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { handleSideBar, setActiveTabs } from '../../../store/slices/sidebarTaskSlice';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const teamHeaderTaskTheme = createTheme({
  components: {
    MuiTabs: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({ minHeight: 28 }) } },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            textTransform: 'capitalize',
            minHeight: 32,
            height: 32,
            minWidth: 'auto',
            marginRight: '1rem',
          }),
      },
    },
  },
});

export default function BaseHeaderTask() {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [value, setValue] = useState(useSelector((state) => state.sidebar.activeTabs) || 0);

  const handleActiveTabs = (event, newValue) => {
    setValue(newValue);
    dispatch(setActiveTabs(newValue));
  };

  return (
    <ThemeProvider theme={teamHeaderTaskTheme}>
      <div style={{ flex: '1 1 auto' }} className="w-full flex flex-1 justify-between border-r border-gray-300 pr-4">
        <Slide direction="right" in={!isSidebarOpen} mountOnEnter unmountOnExit>
          <Menu className="my-auto mr-2" onClick={() => dispatch(handleSideBar())} />
        </Slide>
        <div className="flex flex-1 flex-col items-start">
          <div className="flex items-center space-x-2 mt-2">
            <span>Teams Name</span>
            <ArrowDropDown />
            <StarBorder />
          </div>
          <div style={{ flex: '1 0 auto' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleActiveTabs} aria-label="basic tabs example">
                <Tab className="p-0" label="Overview" {...a11yProps(0)} />
                <Tab className="p-0" label="Other" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </div>
        </div>
        <Tooltip title="Manage Team Member">
          <div className="flex items-center space-x-2">
            <div className="flex -space-x-1">
              <Avatar className="w-8 h-8 drop-shadow-2xl bg-red-300">A</Avatar>
              <Avatar className="w-8 h-8 drop-shadow-2xl bg-blue-300">B</Avatar>
              <Avatar className="w-8 h-8 drop-shadow-2xl bg-yellow-300">C</Avatar>
            </div>
            <span>3</span>
          </div>
        </Tooltip>
      </div>
    </ThemeProvider>
  );
}

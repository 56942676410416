// /* eslint-disable */

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationApplicant = yup
  .object()
  .shape({
    user: yup.object().shape({
      name: yup.string().required('Name is required'),
      email: yup.string().required('Email is required'),
    }),
  })
  .required();

const schemaApplicant = yupResolver(validationApplicant);

export default schemaApplicant;

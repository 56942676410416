import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import InputContainer from '@/components/Input/InputContainer';
import { Add, Clear } from '@mui/icons-material';
import HandleWatchingState from '@/helper/HandleWatchingState';
import { Collapse, TextField } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import ForceRerender from '@/utils/ForceRerender';

export default function SiteDomainList({ control, watch, setValue, getValues, defaultValues, errors }) {
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'site_domains',
  });

  const watchLength = HandleWatchingState(fields && fields.length > 0 ? fields : 0);

  return (
    <Box className="">
      <div className="">Domain</div>
      <TransitionGroup>
        {fields?.map((item, idx) => (
          <Collapse key={idx}>
            <div className="relative">
              <ForceRerender
                condition={watchLength}
                component={
                  <div>
                    <Controller
                      control={control}
                      name={`site_domains[${idx}].domId`}
                      render={({ field: { onChange, value, ref } }) => {
                        return (
                          <TextField
                            hidden
                            // variant="standard"
                            size="small"
                            value={value}
                            onChange={onChange}
                            sx={{ width: '100%', '& > .MuiInputBase-root': { fontSize: '14px' } }}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name={`site_domains[${idx}].domain`}
                      render={({ field: { onChange, value, ref } }) => {
                        return (
                          <TextField
                            // variant="standard"
                            helperText={errors?.site_domains && errors?.site_domains[idx]?.domain?.message}
                            size="small"
                            value={value}
                            onChange={onChange}
                            sx={{ width: '100%', '& > .MuiInputBase-root': { fontSize: '14px' } }}
                          />
                        );
                      }}
                    />
                  </div>
                }
              />

              <button
                title="Delete Keyword"
                className="absolute bg-white -right-3 top-0  z-10"
                type="button"
                onClick={() => {
                  remove(idx);
                }}
              >
                <Clear className="border-2 rounded-full text-red-400" />
              </button>
            </div>
          </Collapse>
        ))}
      </TransitionGroup>

      <button className="pl-3" type="button" title="Add Keyword" onClick={() => append({ domain: '' })}>
        <Add className="ml-5 rounded border-2 text-blue-400" />
      </button>
    </Box>
  );
}

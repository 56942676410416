/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { buildYup } from 'schema-to-yup';
import RGL, { WidthProvider } from 'react-grid-layout';
// import { DevTool } from '@hookform/devtools';

import DynamicAttributeField from './DynamicAttributeField';

import 'react-grid-layout/css/styles.css';
import styles from './DynamicAttribute.module.css';

const ReactGridLayout = WidthProvider(RGL);

const colorNotes = [
  {
    name: 'attribute normal',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  {
    name: 'attribute contains reference value',
    color: 'var(--primary)',
  },
  {
    name: 'attribute contains reference value and replaced by user/system',
    color: 'var(--weak)',
  },
  {
    name: 'attribute contains reference value, replaced by user and replaced value same as reference value',
    color: '#53a336',
  },
];

export const groupByKeyValue = (key, args) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const newObjectsByKeyValue = objectsByKeyValue;
    let value;
    if (!args) {
      value = obj[key];
    } else {
      value = obj[args][key];
    }
    newObjectsByKeyValue[value] = (newObjectsByKeyValue[value] || []).concat(obj);
    return newObjectsByKeyValue;
  }, {});

function FormGridLayout(props) {
  const [activeLayout, setNewLayout] = useState(props.layout || []);
  const [listElement, setListElement] = useState(props.elements || []);

  const onNestedLayoutChange = (nestedLayout, nestedElement, itmKey) => {
    const itemIndex = activeLayout.findIndex((item) => item.i === itmKey);
    if (itemIndex !== -1) {
      setNewLayout([
        ...activeLayout.slice(0, itemIndex),
        {
          ...activeLayout[itemIndex],
          activeLayout: nestedLayout,
        },
        ...activeLayout.slice(itemIndex + 1),
      ]);
      // This should be change
      setListElement([
        ...listElement.slice(0, itemIndex),
        {
          ...listElement[itemIndex],
          listElement: nestedElement,
        },
        ...listElement.slice(itemIndex + 1),
      ]);
    }
  };
  const generateDOM = () => {
    return activeLayout.map((item, i) => {
      let lblText = item.i;
      const isGroup = item.i.startsWith('group|');
      if (!isGroup) {
        const newI = item.i.slice(item.i.indexOf('|') + 1);
        const arrI = newI.split('|');
        lblText = arrI[arrI.length - 1];
      } else {
        const arrI = item.i.split('|');
        lblText = arrI[arrI.length - 1];
      }
      const isPlaceholder = lblText.includes('dropping-elem');
      if (isPlaceholder) lblText = 'item';
      const isGroupNameDisplayed = isGroup && !!listElement[i]?.is_displayed;
      let attributeField = null;
      if (!isGroup) {
        const findAttribute = props.attributes.find((at) => at.attribute.key_name === listElement[i]?.key_name);
        if (!findAttribute) return null;
        const { id, attribute, configuration } = findAttribute;
        attributeField = (
          <DynamicAttributeField key={id} form={props.form} isEdit={props.isEdit} attributeValue={listElement[i]} attribute={attribute} configuration={configuration} />
        );
      }
      return (
        <div key={item.i} className={[styles.dataGridItem].join(' ')}>
          <div className={isGroup ? 'pt-6 border-t border-slate-300' : ''}>
            {isGroup && (
              <>
                {isGroupNameDisplayed && <p className={['w-auto absolute -top-0 right-1/2 translate-x-1/2 z-10 text-lg font-bold'].join(' ')}>{lblText}</p>}
                <FormGridLayout
                  layout={item.activeLayout}
                  nested
                  itemKey={item.i}
                  elements={listElement[i].attributes}
                  attributes={props.attributes}
                  form={props.form}
                  isEdit={props.isEdit}
                  onNestedLayoutChange={onNestedLayoutChange}
                />
              </>
            )}
            {!isGroup && attributeField}
          </div>
        </div>
      );
    });
  };

  const onLayoutChange = (newLayout) => {
    let layouts = [...newLayout];
    if (props.isParent) {
      layouts = newLayout.map((l, i) => {
        const objLayout = { ...l };
        if (activeLayout && activeLayout.length && activeLayout[i]?.activeLayout) {
          objLayout.activeLayout = activeLayout[i].activeLayout;
        }
        return objLayout;
      });
    }

    setNewLayout(layouts);
    if (props.onNestedLayoutChange) {
      props.onNestedLayoutChange(layouts, listElement, props.itemKey);
    }
  };

  return (
    <ReactGridLayout layout={activeLayout} onLayoutChange={onLayoutChange} className={props.isParent ? styles.gridContainer : ''} autoSize rowHeight={32} cols={12}>
      {generateDOM()}
    </ReactGridLayout>
  );
}

export default function DynamicAttribute({
  attributes,
  schema,
  onCreateProject,
  onPatchProjectAttribute,
  onCloseHandler,
  triggerMain,
  onMainFormSubmit,
  isEdit,
  gridLayout,
  children,
}) {
  
  const formSchema = buildYup(schema.schema, { errMessages: schema.errorSchema });
  const defaultValues = {};
  
  const refInitialize = useRef(true);
  
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    trigger,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    // defaultValues,
  });
  
  const onSubmit = async (data, param = null) => {
    const isMainValid = await onMainFormSubmit();
    if (!isMainValid) return;
    const valid = await formSchema.isValid(getValues());
    if (valid) {
      onCreateProject(data, param);
    }
  };

  useEffect(() => {
    if (refInitialize.current) {
      refInitialize.current = false;
      const formulaFields = [];
      const populateDataEdit = {};
      const isDataEdit = isEdit && isEdit.groups?.length;
      if (isDataEdit) {
        isEdit.groups.forEach((gr) => {
          gr.attributes.forEach((a) => {
            populateDataEdit[a.key_name] = a;
          });
        });
      }

      Object.entries(schema.schema.properties).forEach((s) => {
        const [key, value] = s;
        let attrVal = '';
        if (isDataEdit) {
          attrVal =
            !!populateDataEdit[key] && !!populateDataEdit[key]?.value && typeof populateDataEdit[key].value === 'object'
              ? populateDataEdit[key]?.value?.value ? populateDataEdit[key]?.value?.value : populateDataEdit[key]?.value
              : populateDataEdit[key]?.value || '';
        }
        
        if (value.type === 'array') {
          if (typeof attrVal === 'string') {
            attrVal = [attrVal];
          }
          setValue(key, isDataEdit ? attrVal || [] : [], { shouldTouch: true });
        }
        if (value.type === 'string' && value.matches) {
          setValue(key, isDataEdit ? attrVal || null : null, { shouldTouch: true });
        } else if (value.type === 'string') {
          if (value.origin === 'datetime') {
            const dateValue = isDataEdit && attrVal ? new Date(attrVal) : null;
            setValue(key, isDataEdit ? dateValue : '', { shouldTouch: true });
          } else {
            setValue(key, isDataEdit ? attrVal : '', { shouldTouch: true });
          }
        }
        if (value.type === 'number') {
          if (value.origin === 'formula') {
            setValue(key, null, { shouldTouch: true });
          } else {
            setValue(key, isDataEdit ? attrVal || null : undefined, { shouldTouch: true });
          }
        }
        if (value.type === 'formula') {
          // setValue(key, null, { shouldTouch: true });
          setValue(key, isDataEdit ? attrVal : null, { shouldTouch: true });
          formulaFields.push();
        }
      });
    }
    return () => {
      refInitialize.current = false;
    };
  });

  const isEditMode = isEdit && isEdit.groups?.length;
  let arrGrouped = attributes;
  if (!isEdit) {
    const groupBy = groupByKeyValue('row_index');
    arrGrouped = groupBy(attributes);
  }
  return (
    <>
      {/* <DevTool control={control} /> */}
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        {
          !isEdit &&
            Object.keys(arrGrouped)?.map((attr) => {
              const groupElements = arrGrouped[attr];
              const groupElement = groupElements
                .sort((a, b) => a.dimension[0] - b.dimension[0])
                .sort((a, b) => a.dimension[1] - b.dimension[1])
                .sort((a, b) => a.column_index - b.column_index)
                .map((g) => {
                  const { id, attribute, configuration, dimension: d } = g;
                  return (
                    <div key={id} className="relative flex" style={{ gridColumnStart: d[0] + 1, gridColumnEnd: d[0] + 1 + d[2] }}>
                      <DynamicAttributeField
                        form={{
                          register,
                          trigger,
                          handleSubmit,
                          setValue,
                          setError,
                          clearErrors,
                          watch,
                          formState: { errors },
                          control,
                        }}
                        isEdit={isEdit}
                        attribute={attribute}
                        configuration={configuration}
                      />
                    </div>
                  );
                });
              return (
                <div key={attr} className="grid grid-cols-12 gap-4 mb-6 pt-6 border-t border-dashed border-slate-300">
                  {groupElement}
                </div>
              );
            })
          // </ReactGridLayout>
        }
        {isEditMode &&
          isEdit.groups.map((gr, i) => {
            let attributeElements = null;
            if (gr.attributes.length) {
              const currAttributes = [...gr.attributes];
              attributeElements = gr.attributes
                .sort((a, b) => a.dimension[0] - b.dimension[0])
                .sort((a, b) => a.dimension[1] - b.dimension[1])
                .map((g) => {                  
                  const findAttribute = attributes.find((at) => at.attribute.key_name === g.key_name);
                  if (!findAttribute) return null;
                  const { id, attribute, configuration, dimension: d } = findAttribute;
                  return (
                    <div key={id} className="relative flex" style={{ gridColumnStart: d[0] + 1, gridColumnEnd: d[0] + 1 + d[2] }}>
                      <DynamicAttributeField
                        form={{
                          register,
                          trigger,
                          handleSubmit,
                          setValue,
                          setError,
                          clearErrors,
                          watch,
                          formState: { errors },
                          control,
                        }}
                        isEdit={isEdit}
                        attributeValue={g}
                        attribute={attribute}
                        configuration={configuration}
                        onPatchProjectAttribute={onPatchProjectAttribute}
                      />
                    </div>
                  );
                });
            }
            return (
              <div key={`${gr.name}`} className="flex flex-col gap-2 mb-6 pt-6 border-t border-dashed border-slate-300">
                {gr.is_displayed && <span className="font-bold text-lg mx-auto mb-4">{gr.name}</span>}
                <div className="grid grid-cols-12 grid-rows-1 gap-4">{attributeElements}</div>
              </div>
            );
          })}
        {/* {isEditMode > 0 && attributes && (
          <div className="flex flex-col gap-4">
            {isEdit.groups.map((g) => {
              // const maxRow = g.attributes ? g.attributes.reduce((prev, current) => (prev.row_index > current.row_index ? prev : current)) : 0;
              // const maxColumn = g.attributes ? g.attributes.reduce((prev, current) => (prev.column_index > current.column_index ? prev : current)) : 0;
              let attr = [];
              if (g.attributes?.length) {
                const groupBy = groupByKeyValue('row_index');
                const arrGrouped = groupBy(g.attributes);
                attr = Object.keys(arrGrouped)?.map((a) => {
                  if (!arrGrouped[a].length) return null;
                  const contentGroupByColumn = arrGrouped[a].map((c) => {
                    const findAttribute = attributes.find((at) => at.attribute.key_name === c.key_name);
                    if (!findAttribute) return null;
                    const { id, attribute, configuration } = findAttribute;
                    return (
                      <DynamicAttributeField
                        key={id}
                        form={{
                          register,
                          trigger,
                          handleSubmit,
                          setValue,
                          watch,
                          formState: { errors },
                        }}
                        isEdit={isEdit}
                        attributeValue={c}
                        attribute={attribute}
                        configuration={configuration}
                      />
                    );
                  });
                  return (
                    <div key={`${g.name}-${a}`} className="grid grid-cols-4 gap-2">
                      {contentGroupByColumn}
                    </div>
                  );
                });
                // attr = g.attributes.map((a) => {
                //   const findAttribute = attributes.find((at) => at.attribute.key_name === a.key_name);
                //   if (!findAttribute) return null;
                //   const { id, attribute, configuration } = findAttribute;
                //   return (
                //     <DynamicAttributeField
                //       key={id}
                //       form={{
                //         register,
                //         trigger,
                //         handleSubmit,
                //         setValue,
                //         watch,
                //         formState: { errors },
                //       }}
                //       isEdit={isEdit}
                //       attributeValue={a}
                //       attribute={attribute}
                //       configuration={configuration}
                //     />
                //   );
                // });
              }
              return (
                <div key={g.id} className="flex flex-col gap-4 pt-4 border-t border-slate-300">
                  {g.is_displayed && <p className="font-bold text-lg m-auto">{g.name}</p>}
                  {attr}
                </div>
              );
            })}
          </div>
        )} */}
        {/* {isEditMode > 0 && attributes && (
          <div className="w-full min-h-full h-auto pt-2 pb-4">
            <FormGridLayout
              layout={gridLayout}
              elements={isEdit.groups}
              attributes={attributes}
              form={{
                register,
                trigger,
                handleSubmit,
                setValue,
                watch,
                formState: { errors },
              }}
              isEdit={isEdit}
              isParent
            />
          </div>
        )} */}
        {children}
        <Divider className={isEditMode ? 'mt-8' : 'mt-8 mb-2'} />
        <Stack direction="row" spacing={1} className="mt-5 float-right">
          <Button variant="outlined" color='success' className="rounded-xl" onClick={onCloseHandler}>
            Cancel
          </Button>
          <button 
            type="submit" 
            className="w-36 flex h-10 items-center text-center btn btn-primary rounded-xl border-0 justify-center bg-[#2C6D47]" 
            onClick={() => {
              triggerMain();
              trigger();
            }}
          >
            <p className="text-white">Save and Close</p>
          </button>
          {/* <Button
            type="submit"
            variant="contained"
            color='success' 
            className="rounded-xl"
            
          >
            
          </Button> */}
          {isEditMode && (
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                handleSubmit(onSubmit)('no-redirect');
                triggerMain();
                trigger();
              }}
            >
              Save
            </Button>
          )}
        </Stack>
      </form>
      <div className="flex flex-wrap gap-2 pb-8">
        <span className="mt-1">note:</span>
        <div className="flex flex-col mt-2">
          {colorNotes.map((c) => (
            <div key={c.name} className="flex items-center gap-2">
              <div className="w-4 h-4 rounded-md border" style={{ background: c.color }} />
              <span>{c.name}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

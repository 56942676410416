/* eslint-disable */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material/';
import { Close, Save } from '@mui/icons-material';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Link } from 'react-router-dom';
import CardChart from '../../../components/Chart/aCardChart';
import { getStaticPeriod, standardizeInputDate } from '../../../lib/StaticPeriod';
import ClientDashboardApi from '../../../api/clientDashboard';
import styles from './RGLHome.module.css';

const ReactGridLayout = WidthProvider(RGL);

export default function BilateralChambers() {
  const now = new Date();
  const today = moment();
  const { getDashboardData } = ClientDashboardApi();
  const clientId = useSelector((state) => state.client.activeClient);

  const [dashboardData, setDashboardData] = useState([]);
  const [gridLayout, setGridLayout] = useState([]);
  const [staticPeriod, setStaticPeriod] = useState('1');
  const [dataMerged, setDataMerged] = useState([]);
  const [period, setPeriod] = useState({ start_date: today.year(2017).format('YYYY-MM-DD'), end_date: today.format('YYYY-MM-DD'), isNow: true });

  useEffect(() => {
    const timezoneOffset = new Date().getTimezoneOffset();
    let data = {
      client: clientId,
      group: 8,
      start_date: standardizeInputDate(period.start_date, timezoneOffset),
      end_date: standardizeInputDate(period.end_date, timezoneOffset, period.isNow),
    };

    const getData = async () => {
      const response = await getDashboardData(data);
      const responseData = response;
      setDashboardData(responseData?.data.slice(0, 8));
    };

    getData();
  }, [clientId, period]);

  const generateDataGrid = () => {
    const newGridLayout = dashboardData.map((gr, i) => {
      const { dimension: d, profile_attributes: attr } = gr;
      const objGroup = {
        w: d[2] > 12 ? 12 : d[2],
        h: d[3],
        x: d[0],
        y: d[1],
        i: `${gr.name}`,
        maxW: 12,
        moved: false,
        static: false,
      };
      if (attr?.length) {
        objGroup.gridLayout = attr.map((a, idx) => {
          const { dimension: dim } = a;
          return {
            w: dim[2] > 12 ? 12 : dim[2],
            h: dim[3],
            x: dim[0],
            y: dim[1],
            i: `${gr.name}`,
            moved: false,
            static: false,
            maxW: 12,
          };
        });
      }
      return objGroup;
    });

    return setGridLayout(newGridLayout);
  };

  useEffect(() => {
    if (dashboardData.length > 0) {
      generateDataGrid();
    }
  }, [dashboardData]);

  console.log('gridLayout ', gridLayout);
  console.log('dashboardData ', dashboardData);

  useEffect(() => {
    setPeriod(getStaticPeriod(staticPeriod));
  }, [staticPeriod]);

  const generateDOM = () => {
    return gridLayout.map((item, i) => {
      let lblText = item.i;
      let lblId = item.i;
      const isGroup = item.i;
      if (!isGroup) {
        const newI = item.i.slice(item.i.indexOf('|') + 1);
        const arrI = newI.split('|');
        lblText = arrI[arrI.length - 1];
        lblId = arrI[arrI.length - 2];
      } else {
        const arrI = item.i.split('|');
        lblText = arrI[arrI.length - 1];
        lblId = arrI[arrI.length - 2];
      }
      const isPlaceholder = lblText.includes('dropping-elem');
      if (isPlaceholder) lblText = 'item';

      const { data, name, configuration } = dashboardData[i];
      const { display_types } = configuration;

      console.log(`data dashboardData[${i}] `, data);
      console.log(`name dashboardData[${i}] `, name);
      console.log(`configuration dashboardData[${i}] `, configuration);

      const typeChart = display_types && display_types.filter((item) => item.includes('_chart'));
      const chartTypeCheck = display_types && display_types.some((item) => item.includes('_chart'));

      const newfinalData = {
        ...dashboardData[i],
        data: [],
      };

      if (typeof data[0] !== 'object') {
        const checkking = dashboardData && dashboardData?.filter((item) => data.includes(item.id));

        checkking.forEach((section, idx) => {
          const totalCount = section?.data && section?.data.length > 1 ? section?.data.reduce((acc, curr) => acc + curr.count, 0) : section?.data[0]?.count;
          newfinalData.data.push({ variable: section?.name, count: totalCount });
        });
      }

      return (
        <div key={item.i} className={["w-full h-full flex gap-5 relative", styles.reactGridItem, isGroup ? 'grid-group' : 'grid-attribute'].join(' ')}>
          {/* {!isPlaceholder && isGroup && <Close className="absolute top-0 right-0 z-20 cursor-pointer" onClick={() => onRemoveElement(item.i, lblId)} fontSize="12" />} */}
          {typeof data[0] === 'object' ? (
            <>
              {display_types && display_types.includes('list') && (
                <TableContainer className={`h-auto shadow-none ${chartTypeCheck ? 'min-w-3/6' : 'w-full'}`} component={Paper} sx={{ height: 'fit-content' }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="font-semibold text-base">{name}</TableCell>
                        <TableCell className="font-semibold text-base" align="right">
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="p-0" colSpan={2}>
                          {data?.map((report, idx) => (
                            <Accordion key={report.variable + idx} className="w-full">
                              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                <Typography>{report.variable}</Typography>
                                <Typography className="ml-auto">{report.count}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="h-full pb-4 ml-3 flex flex-col gap-y-2">
                                  {report?.members.map((member) => (
                                    <Link to={`/customers/detail/${member.member__id}`}>
                                      <div key={member.member__id} className="hover:text-sky-600">
                                        {member.member__name}
                                      </div>
                                    </Link>
                                  ))}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {chartTypeCheck && (
                  <CardChart
                    className="w-full h-full col-span-2 relative"
                    title={name}
                    labels={data?.map((item) => item.variable)}
                    data={data?.map((item) => item.count)}
                    typeChart={typeChart}
                  />
              )}
            </>
          ) : (
            newfinalData?.data.length > 1 && (
              <CardChart
                className="h-full w-full col-span-2 relative"
                title={name}
                labels={newfinalData?.data.map((item) => item.variable)}
                data={newfinalData?.data.map((item) => item.count)}
                typeChart={typeChart}
              />
            )
          )}
        </div>
      );
    });
  };

  // const onResize = (layout, layoutItem) => {
  //   const currElement = layout.findIndex((el) => el.i === layoutItem.i);
  //   if (currElement >= 0) {
  //     const { x, y, w, h } = layout[currElement];

  //     const currState = [...gridLayout];
  //     const currObj = { ...currState[currElement] };
  //     currObj.w = +w;
  //     currObj.h = +h;
  //     if (layout[currElement].isStatic) {
  //       currObj.static = true;
  //     }
  //     if (gridLayout[currElement]?.gridLayout) {
  //       currObj.gridLayout = gridLayout[currElement].gridLayout;
  //     }
  //     currState[currElement] = currObj;
  //     const newL = [...currState];
  //     setNewLayout(currState);

  //     const currStateEl = [...listElement];
  //     const currObjEl = { ...currStateEl[currElement] };
  //     currObjEl.dimension = [x, y, w, h];
  //     if (listElement[currElement]?.listElement) {
  //       currObj.listElement = listElement[currElement].listElement;
  //     }
  //     currStateEl[currElement] = currObjEl;
  //     const newEl = [...currStateEl];
  //     setListElement(currStateEl);

  //     if (props.onNestedLayoutChange) {
  //       props.onNestedLayoutChange(newL, newEl, props.itemKey);
  //     }
  //   }
  // };

  return (
    gridLayout.length !== 0 && (
      <div className="w-full bg-white py-[45px] px-5">
        <div className="w-full mb-4">
          <div className="flex">
            <div className="flex flex-col mr-4">
              <label className="font-semibold">Period</label>
              <select value={staticPeriod} className="h-[30px] border border-black rounded-sm" onChange={(e) => setStaticPeriod(e.target.value)}>
                <option value="1">This year</option>
                <option value="2">Past year</option>
                <option value="3">Past 6 months</option>
                <option value="4">Past 3 months</option>
                <option value="5">Past month</option>
                <option value="6">Past 2 years</option>
                <option value="7">All time</option>
                <option value="custom">Custom</option>
              </select>
            </div>

            <div className="flex flex-col mr-4">
              <label className="font-semibold">From</label>
              <input
                disabled={staticPeriod !== 'custom'}
                type="date"
                value={period.start_date || ''}
                onChange={(e) => setPeriod({ ...period, start_date: e.target.value })}
                className={['h-[30px] px-1', staticPeriod === 'custom' ? 'border border-black rounded-sm' : ''].join(' ')}
              />
            </div>
            <div className="flex flex-col mr-4">
              <label className="font-semibold">To</label>
              <input
                disabled={staticPeriod !== 'custom'}
                type="date"
                value={period.end_date}
                onChange={(e) => setPeriod({ ...period, end_date: e.target.value })}
                className={['h-[30px] px-1', staticPeriod === 'custom' ? 'border border-black rounded-sm' : ''].join(' ')}
              />
            </div>
          </div>
        </div>
        <div style={{ maxWidth: '100%', height: 'auto' }}>
          <ReactGridLayout
            onDragStart={(a, b, c, d, e) => {
              e.stopPropagation();
              // console.log(a, b, c, d);
            }}
            layout={gridLayout}
            // onLayoutChange={onLayoutChange}
            // onDrop={onDrop}
            className={[styles.reactGridLayout, 'layout min-h-full h-auto'].join(' ')}
            rowHeight={32}
            // rowHeight={50}
            cols={12}
            width={100}
            isBounded
            // isDroppable
            // margin={props.isParent ? [4, 4] : [4, 4]}
            // onResizeStop={(layout, layoutItem) => {
            //   onResize(layout, layoutItem);
            // }}
            // verticalCompact={!!props.isParent}
          >
            {generateDOM()}
          </ReactGridLayout>
        </div>
      </div>
    )
  )
}


import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Box, Button, Chip, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import reportApi from '../../../api/accounting/report';
import accountingApi from '../../../api/accounting/accounting';

import styles from './Report.module.css';
import BZHelmet from '../../../utils/BZHelmet';
import { uniqueId } from 'lodash';
import { FilterList } from '@mui/icons-material';
// import pnlReport from './report.json';

const nf = new Intl.NumberFormat('id-ID', {
  // style: 'decimal',
  // currency: 'IDR',
  minimumFractionDigits: 0,
  roundingIncrement: 1,
});

// const emptyCol = (text) => `<td className="w-full h-full border-black border-b px-4">${text}</td>`;
// const colSpan = (name, row = 1) => `<td rowspan="${row}">${name}</td>`;

const genTd = (content, style = '', config = { isBold: false, isTitle: false }) => {
  const { isBold, isTitle } = config;
  const fs = isBold ? '13px' : '14px';
  const fc = !isTitle && content?.toString().includes('-') ? 'color: red;' : 'color: black;';
  if (content) {
    const tdContent = isBold ? `<b>${content}</b>` : content;
    return `<td style="font-size: ${fs}; ${fc} ${style}">${tdContent}</td>`;
  }
  return `<td style="font-size: ${fs}; ${style}">&nbsp;</td>`;
};

const getMonth = (month) => (month.toString().length !== 1 ? month : `0${month}`);

export default function ReportPnl() {
  const cl = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const client = activeUser.is_superuser ? activeClient : cl;

  const activeBusinessUnit = useSelector((state) => state.acc.activeBusinessUnit);

  const { getAccountingReport, getFinancialYears, getDetailAccountingReports, getLedgers } = reportApi();
  const { getCostCenters, getAccountingPeriods } = accountingApi();

  const [dataTableView, setDataTableView] = useState(false);
  const [dataReport, setDataReport] = useState(false);
  const [accountingPeriods, setAccountingPeriods] = useState(false);
  const [financialYears, setFinancialYears] = useState(false);
  const [accReports, setAccReports] = useState(false);
  const [costCenters, setCostCenters] = useState(false);
  const [ledgers, setLedgers] = useState(false);

  const [selectedFinancialYears, setSelectedFinancialYears] = useState('');
  const [selectedLedgerType, setSelectedLedgerType] = useState('');
  const [selectedCostCenters, setSelectedCostCenters] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getFinancialYearList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getFinancialYears(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getFinancialYears]
  );

  const getCostCenterList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getCostCenters(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getCostCenters]
  );

  const getAccountingPeriodList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getAccountingPeriods(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getAccountingPeriods]
  );

  const getLedgerList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getLedgers(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getLedgers]
  );

  const getAllData = useCallback(async () => {
    const [a, b, c, d] = await Promise.all([
      getFinancialYearList({ client: client, ordering: '-year' }),
      getCostCenterList({ business_unit: activeBusinessUnit }),
      getLedgerList({ business_unit: activeBusinessUnit }),
    ]);
    if (a && b && c) {
      const fy = a.filter((y) => y.year === new Date().getFullYear())[0] || a[a.length - 1] || '';
      setFinancialYears(a);
      // setSelectedFinancialYears(a.filter((y) => y.year === new Date().getFullYear())[0]?.id || a[a.length - 1][0]?.id || '');
      setSelectedFinancialYears(fy ? fy.id : '');
      setCostCenters(b);
      setSelectedCostCenters(b.map((cc) => cc.id));
      setLedgers(c);
      setSelectedLedgerType(c[0]?.id);
      const acp = await getAccountingPeriodList({ financial_year__client: client, financial_year: fy.id, max_size: true });
      setAccountingPeriods(acp);
      setIsLoaded(true);
    }
  }, [getFinancialYearList, getCostCenterList, getAccountingPeriodList, getLedgerList, client, activeBusinessUnit]);

  useEffect(() => {}, []);

  useLayoutEffect(() => {
    if (!isLoaded && !financialYears) {
      getAllData();
    }
  }, [isLoaded, getAllData, financialYears]);

  const generateLine = (aPnlGroup, bAccType, cCategory, dPnlCategory, periods, style) => {
    const data = {
      id: uniqueId(),
      aPnlGroup,
      bAccType,
      cCategory,
      dPnlCategory,
      ...periods,
      style,
    };
    return data;
  };

  const generateSum = (period, sums, isTwoVar, isDivider = true, operator = '-', multiplication = 1) => {
    const data = {};
    const dataTemp = {};
    period.forEach((per) => {
      let isSum;
      if (operator === '+') {
        isSum = sums.length > 0 ? sums.map((item) => item[per]).reduce((prev, curr) => Number(prev) + Number(curr)) : 0;
      } else {
        isSum = sums.length > 0 ? sums.map((item) => item[per]).reduce((prev, curr) => Number(prev) - Number(curr)) : 0;
      }
      if (isTwoVar) {
        dataTemp[per] = isSum;
      }
      if (isDivider) {
        data[per] = genTd(nf.format(isSum * multiplication), 'text-align: right; border-bottom: solid black', { isBold: true });
      } else {
        data[per] = genTd(nf.format(isSum * multiplication), 'text-align: right;', { isBold: true });
      }
    });
    return { data, dataTemp };
  };

  const generateReportView = useCallback(() => {
    const headPeriod = [`YTD-${financialYears.filter((y) => y.id === selectedFinancialYears)[0]?.year}`];
    accountingPeriods.forEach((ap) => {
      headPeriod.push(`${ap.year}-${getMonth(ap.month)}`);
    });

    const emptyPeriod = {};
    const emptyPeriodNull = {};
    headPeriod.forEach((per, i) => {
      emptyPeriod[headPeriod[i]] = '';
      emptyPeriodNull[headPeriod[i]] = '0';
    });

    const reports = [];
    const realReports = [];

    const tempGrossResult = [];
    const tempTaxResult = [];
    const tdStyleBb = 'border-bottom: solid black';
    const tdWithBb = genTd('', 'border-bottom: solid black');
    let otherSum;
    let callbackWhenHaveTax = null;
    dataReport.forEach((dt, index) => {
      const isTax = [dt.code.toLowerCase(), dt.name.toLowerCase()].some((t) => t === 'tax');
      const tempSumResult = [];
      const tempSumTaxResult = [];
      const totalAccTypes = dt.account_types.length - 1;
      dt.account_types.forEach((accType, idxAccType) => {
        const multiplication = accType.is_sign_reversed ? -1 : 1;
        const totalPnlBsCate = accType.pnl_bs_categories.length - 1;
        const tempSumSummary = [];
        const tempSumTaxSummary = [];
        accType.pnl_bs_categories.forEach((pnl, idx) => {
          const eoAT = { text: '', obj: {} };
          if (totalPnlBsCate === idx && totalAccTypes !== idxAccType) {
            eoAT.text = 'border-bottom: solid black';
            eoAT.obj = { borderBottom: 'solid black' };
          }
          const dataModified = {};
          const dataOriginal = {};
          headPeriod.forEach((per, i) => {
            if (i === 0) {
              dataModified[headPeriod[i]] = pnl.ytd_sum ? nf.format(pnl.ytd_sum * multiplication) : '0' || '';
              dataOriginal[headPeriod[i]] = pnl.ytd_sum ? pnl.ytd_sum : 0 || 0;
            } else {
              const dtAcc = pnl.accounting_periods.filter((pn) => per === `${pn.year}-${getMonth(pn.month)}`);
              dataModified[headPeriod[i]] = dtAcc.length ? nf.format(dtAcc[0].sum * multiplication) || '0' : '0';
              dataOriginal[headPeriod[i]] = dtAcc.length ? dtAcc[0].sum || 0 : 0;
            }
          });
          const isBold = idx === 0;
          const pnlG = isBold ? dt.name : '';
          const accT = isBold ? accType.name || accType.code : '';
          const titleStyle = { isBold, isTitle: true };
          const contentStyle = { isBold, isTitle: false };

          if (idx < totalPnlBsCate) {
            // normal line
            if (!isTax) {
              tempSumSummary.push(generateLine(genTd(pnlG), genTd(accT), accT, genTd(pnl.name), { ...dataOriginal }));
              realReports.push(dataOriginal);
              reports.push(
                generateLine(genTd(pnlG, '', titleStyle), genTd(accT, '', titleStyle), accT, genTd(pnl.name, '', { isBold: false, isTitle: true }), { ...dataModified })
              );
            } else {
              tempSumTaxSummary.push(generateLine(genTd(pnlG), genTd(accT), accT, genTd(pnl.name), { ...dataOriginal }));
            }
          } else if (idx === totalPnlBsCate) {
            // last line
            if (!isTax) {
              reports.push(
                // eslint-disable-next-line prettier/prettier, max-len
                generateLine(
                  genTd(pnlG, eoAT.text, contentStyle),
                  genTd(accT, eoAT.text, titleStyle),
                  accT,
                  genTd(pnl.name, eoAT.text, { isBold: false, isTitle: true }),
                  { ...dataModified },
                  { style: eoAT.obj }
                )
              );
              realReports.push(dataOriginal);
              tempSumSummary.push(generateLine(genTd(pnlG), genTd(accT), accT, genTd(pnl.name), { ...dataOriginal }));
            } else {
              tempSumTaxSummary.push(generateLine(genTd(pnlG), genTd(accT), accT, genTd(pnl.name), { ...dataOriginal }));
            }

            const dataGen = generateSum(headPeriod, !isTax ? tempSumSummary : tempSumTaxSummary, true, totalPnlBsCate !== 0, '+', multiplication);

            if (!isTax) {
              tempSumResult.push(generateLine(genTd(), genTd(), accT, genTd('Total'), { ...dataGen.dataTemp }));
            } else {
              tempSumTaxResult.push(generateLine(genTd(), genTd(), accT, genTd('Total'), { ...dataGen.dataTemp }));
            }
            if (totalPnlBsCate !== 0) {
              realReports.push(dataGen.dataTemp);
              reports.push(generateLine(tdWithBb, tdWithBb, accT, genTd('Total', 'border-bottom: solid black', { isBold: true }), { ...dataGen.data }));
            }
          }
        });
      });
      const dataGen = generateSum(headPeriod, !isTax ? tempSumResult : tempSumTaxResult, true, true, '+');
      const dataSum = dataGen.data;
      const dataGross = dataGen.dataTemp;
      if (!isTax) {
        tempGrossResult.push(dataGross);
      } else {
        tempTaxResult.push(dataGross);
      }

      if (!isTax) {
        realReports.push(dataGross);
        reports.push(generateLine(genTd(dt.name, tdStyleBb, { isBold: true }), tdWithBb, genTd(), genTd(`Result`, tdStyleBb, { isBold: true }), { ...dataSum }));
      }
      const dataGenGross = generateSum(headPeriod, tempGrossResult, true, true, '+');
      const dataGrossSum = dataGenGross.dataTemp;
      if (isTax) {
        otherSum = generateSum(headPeriod, tempGrossResult, true, false, '+');
        // const taxSum = generateSum(headPeriod, tempGrossResult, true, true, '+');
        // reports.push(generateLine(genTd(`Total`, tdStyleBb, { isBold: true }), tdWithBb, tdWithBb, genTd(`Result`, tdStyleBb, { isBold: true }), { ...taxSum.data }));
      }
      // if (isTax || dataReport.length - 1 === index) {
      if (isTax) {
        callbackWhenHaveTax = () => {
          const taxResult = { ...tempTaxResult[tempTaxResult.length - 1] };
          const newTax = {};
          Object.entries(taxResult).forEach((entry) => {
            const [key, value] = entry;
            // newTax[key] = typeof value === 'number' && Math.sign(value) === -1 ? value * -1 : value;
            newTax[key] = typeof value === 'number' && Math.sign(value) === -1 ? value : value;
          });
          reports.push(generateLine(genTd(), genTd(), genTd(), genTd(), { ...emptyPeriod }));
          realReports.push(emptyPeriod);
          reports.push(generateLine(genTd(`Gross Results`, '', { isBold: true }), genTd(), genTd(), genTd(), { ...otherSum.data }));
          realReports.push(otherSum.dataTemp);
          reports.push(generateLine(genTd(`Tax`, '', { isBold: true }), genTd(), genTd(), genTd(), { ...newTax }));
          realReports.push(newTax);
          const dataGenNet = generateSum(headPeriod, [{ ...otherSum.dataTemp }, { ...taxResult }], true, true, '+');
          reports.push(generateLine(genTd(`Net Result`, tdStyleBb, { isBold: true }), tdWithBb, tdWithBb, tdWithBb, { ...dataGenNet.data }));
          realReports.push(dataGenNet.dataTemp);
        };
      } else {
        // reports.push(generateLine(genTd(), genTd(), genTd(), genTd(), { ...emptyPeriod }));
        // realReports.push(emptyPeriod);
        // reports.push(generateLine(genTd(`GGross Result`, '', { isBold: true }), genTd(), genTd(), genTd(), dataGrossSum ? { ...dataGrossSum } : { ...tempGrossResult[0] }));
        // realReports.push(dataGrossSum ? dataGrossSum : tempGrossResult[0]);
        // reports.push(generateLine(genTd(`Tax`, '', { isBold: true }), genTd(), genTd(), genTd(), { ...emptyPeriodNull }));
        // realReports.push(emptyPeriodNull);
        // const dataGenNet = generateSum(headPeriod, [dataGrossSum ? { ...dataGrossSum } : { ...tempGrossResult[0] }, { ...emptyPeriodNull }], true);
        // reports.push(generateLine(genTd(`Net Result`, tdStyleBb, { isBold: true }), tdWithBb, tdWithBb, tdWithBb, { ...dataGenNet.data }));
        // realReports.push(dataGenNet.dataTemp);
      }
      if (dataReport.length - 1 === index && callbackWhenHaveTax && typeof callbackWhenHaveTax === 'function') {
        callbackWhenHaveTax();
      }
      // }
      // reports.push(generateLine(genTd(), genTd(), genTd(), genTd(), { ...emptyPeriod })); *Empty Cell divider per-group/type
    });

    const columns = [
      {
        field: 'aPnlGroup',
        headerName: 'P&L Group',
        headerAlign: 'center',
        align: 'left',
        minWidth: 144,
      },
      {
        field: 'bAccType',
        headerName: 'Account Type',
        headerAlign: 'left',
        align: 'left',
        minWidth: 256,
      },
      {
        field: 'dPnlCategory',
        headerName: 'Name',
        headerAlign: 'left',
        align: 'left',
        minWidth: 256,
      },
    ];
    headPeriod.forEach((per) => {
      columns.push({
        field: per,
        headerName: per,
        headerAlign: 'center',
        align: 'right',
        minWidth: 144,
        renderCell: (params) => (params.row[per] ? nf.format(params.row[per]) : ''),
      });
    });
    if (reports.length && columns.length) {
      setDataTableView({
        columns,
        reports,
        realReports,
      });
      setIsLoading(false);
    }
  }, [dataReport, accountingPeriods, financialYears, selectedFinancialYears]);

  const getDetailAccountingReport = useCallback(
    (idFinancialYear, data) => {
      getAccountingReport(idFinancialYear, data)
        .then((res) => {
          if (res.status === 200 && res.data) {
            setDataReport(res.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [getDetailAccountingReports]
  );

  const generatePNLReport = useCallback(() => {
    setIsLoading(true);
    setDataTableView(false);
    setDataReport(false);
    if (selectedFinancialYears && selectedLedgerType) {
      const data = {
        summation_type: 'Profit and Loss',
        cost_centers: selectedCostCenters,
        ledger: selectedLedgerType,
      };
      getDetailAccountingReport(selectedFinancialYears, data);
    } else {
      setIsLoading(false);
    }
  }, [getDetailAccountingReport, selectedCostCenters, selectedFinancialYears, selectedLedgerType]);

  const onChangeFinancialYear = async (value) => {
    if (value) {
      setDataReport(false);
      setDataTableView(false);
      setSelectedFinancialYears(value);
      const acp = await getAccountingPeriodList({ financial_year__client: client, financial_year: value, max_size: true });
      setAccountingPeriods(acp);
    }
  };

  const onFilter = (data) => {
    if (data.costCenters) {
      setSelectedCostCenters(data.costCenters);
    }
  };

  useEffect(() => {
    if (isLoaded && !dataReport && !isLoading) {
      generatePNLReport();
    }
  }, [isLoaded, dataReport, isLoading, generatePNLReport]);

  useEffect(() => {
    if (dataReport && accountingPeriods) {
      generateReportView();
    }
  }, [dataReport, accountingPeriods, generateReportView]);

  return (
    <div>
      <BZHelmet title="Profit and Loss" description="bz publish profit and loss report" />
      <div className="w-full flex p-4 shadow-md">
        <h1 className="text-lg font-bold">P&L Report</h1>
      </div>
      {isLoaded && (
        <Box className="w-full h-full p-8 relative space-y-8">
          <div className="flex gap-4 flex-wrap">
            <FormControl size="small">
              <InputLabel id="select-account">Financial Year</InputLabel>
              <Select
                sx={{ p: 0.5, border: 0, width: 112 }}
                labelId="select-financial-year"
                id="select-financial-year"
                name="financialYear"
                value={selectedFinancialYears}
                label="Financial year"
                placeholder="Financial year"
                onChange={(event) => {
                  onChangeFinancialYear(event.target.value);
                }}
                className="h-12"
              >
                <MenuItem value="">
                  <em>year</em>
                </MenuItem>
                {financialYears.map((acc) => (
                  <MenuItem key={acc.id} value={acc.id}>
                    {acc.year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-ledger">Ledger</InputLabel>
              <Select
                sx={{ p: 0.5, border: 0, minWidth: 256 }}
                labelId="select-ledger-type"
                id="select-ledger-type"
                name="ledgerType"
                value={selectedLedgerType}
                label="Ledger"
                placeholder="Ledger Type"
                onChange={(event) => {
                  setSelectedLedgerType(event.target.value);
                }}
                className="h-12"
              >
                <MenuItem value="">
                  <em>type</em>
                </MenuItem>
                {ledgers.map((led) => (
                  <MenuItem key={led.id} value={led.id}>
                    {led.code} - {led.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/*<FormControl size="small">
              <InputLabel id="select-account">Cost Centers</InputLabel>
              <Select
                multiple
                sx={{ p: 0.5, border: 0, minWidth: 256 }}
                labelId="select-cost-center"
                id="select-cost-center"
                name="costCenter"
                value={selectedCostCenters}
                label="Cost Centers"
                placeholder="Cost Centers"
                onChange={(event) => {
                  setSelectedCostCenters(event.target.value);
                }}
                renderValue={(selected) => {
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} className="text-xs" label={costCenters.filter((cc) => cc.id === value)[0].code} />
                      ))}
                    </Box>
                  );
                }}
              >
                <MenuItem value="">
                  <em>cost center</em>
                </MenuItem>
                {costCenters.map((acc) => (
                  <MenuItem key={acc.id} value={acc.id}>
                    {acc.code} - {acc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <button
              className="bz-btn my-auto relative"
              onClick={(e) => {
                setIsModalFilter(true);
              }}
            >
              {selectedCostCenters?.length && <span className="absolute top-0 right-1">{selectedCostCenters.length}</span>}
              <FilterList fontSize="medium" />
            </button>
            <div className="flex items-center space-x-4">
              {selectedLedgerType && (
                <button
                  type="button"
                  className="flex h-full gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                  style={{ backgroundColor: '#2C6D47' }}
                  onClick={() => generatePNLReport()}
                >
                  <p className="text-white">Generate Report</p>
                </button>
              )}
            </div>
          </div>
          {isLoading || dataReport.length === 0 ? (
            <div style={{ transform: 'translate(50%, 50%)' }} className="w-full absolute t-0 left-0">
              <CircularProgress size={48} className={!isLoading ? 'opacity-0' : ''} />
              <p className={[isLoading && 'animate-bounce', 'mt-4 -ml-4'].join(' ')}> {isLoading ? 'please wait' : 'no data found'}</p>
            </div>
          ) : (
            <div />
          )}
          {!isLoading && !dataReport && <p className="w-full text-center py-4">No data</p>}
          {dataTableView && (
            <div className="w-fit h-full flex justify-center pb-8 pr-8 mx-auto">
              <table className={styles.pnlTable}>
                {/* <thead> */}
                <tbody>
                  <tr>
                    {dataTableView.columns.map((th) => (
                      <th key={th.headerName} style={{ borderBottom: 'solid', textAlign: 'center', fontSize: 14 }}>
                        {th.headerName}
                      </th>
                    ))}
                  </tr>
                  {/* </thead> */}
                  {/* <tbody> */}
                  {dataTableView.reports
                    .filter((_, index) => {
                      const realValue = dataTableView.realReports[index];
                      const isValidAllValue = Object.values(realValue).every((v) => v === 0);
                      if (!isValidAllValue) return true;
                    })
                    .map((tr) => {
                      const col = dataTableView.columns;
                      return (
                        <tr key={tr.id} className={styles.pntTblTr}>
                          {col.map((c, i) => {
                            let td = <td key={`${tr.id + c.field + i}`}>&nbsp;</td>;
                            const data = tr[c.field];
                            if (i <= 1 && data) {
                              td = parse(data);
                            } else if (i === 2 && data) {
                              td = parse(data);
                            } else {
                              let tdHtml = data;
                              if (tdHtml?.toString().includes('<b>')) {
                                td = parse(data);
                              } else {
                                const genTdHtml = (html, style = {}) => (
                                  <td key={`${tr.id + c.field + i}`} style={{ textAlign: 'right', fontSize: 14, ...style }}>
                                    {html}
                                  </td>
                                );

                                if (data) {
                                  let tdStyle = '';
                                  if (tr.style) {
                                    tdStyle = { ...tr.style.style };
                                  }
                                  const isNumber = typeof data === 'number';
                                  tdHtml = isNumber ? nf.format(data) : data;
                                  const isNeg = Math.sign(data);
                                  if (isNumber && isNeg === -1) {
                                    const newStyle = tdStyle && Object.keys(tdStyle).length ? { color: 'red', ...tdStyle } : { color: 'red' };
                                    td = genTdHtml(tdHtml, newStyle);
                                  } else if (!isNumber && data.toString().includes('</td>')) {
                                    td = parse(data);
                                  } else {
                                    let newStyle = tdStyle;
                                    if (tdHtml.includes('-')) {
                                      newStyle = tdStyle && Object.keys(tdStyle).length ? { color: 'red', ...tdStyle } : Object.assign({ color: 'red' });
                                    }
                                    td = genTdHtml(tdHtml, newStyle);
                                  }
                                } else {
                                  td = genTdHtml('');
                                }
                              }
                            }
                            return td;
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </Box>
      )}
    </div>
  );
}

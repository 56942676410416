/* eslint-disable */

import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { DevTool } from '@hookform/devtools';
import { fetchEmployeeDetail, fetchEmployees, fetchEmployeeTeam, useUpdateEmployee } from '../../../hooks/useWhistleblowerData';
import { useQuery } from '@tanstack/react-query';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';
import ReactSelectSingle from '../../Input/ReactSelectSingle';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
import { useParams } from 'react-router';

const role = [
  { name: 'Recruiter', id: 'Recruiter' },
  { name: 'Interviewer', id: 'Interviewer' },
];

function RecTeamForm({ defaultValues, onFormSubmit }) {
  const { jobPosterId } = useParams();

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      employee: defaultValues?.employee?.id,
      role: defaultValues?.role,
      job_poster: defaultValues?.job_poster,
    },
  });
  const dispatch = useDispatch();
  console.log(defaultValues);
  const clientId = useSelector((state) => state.client.activeClient);
  const employeeId = watch('employee');

  const [dataEmployee, setDataEmployee] = useState([]);
  console.log('🚀 ~ file: RecTeamForm.jsx:36 ~ RecTeamForm ~ dataEmployee:', dataEmployee);
  const [isDataLoad, setIsDataLoad] = useState(false);

  const queryKey = {
    client: clientId,
    search: '',
  };

  useQuery(['EmployeesTeam', { queryKey }], fetchEmployees, {
    onSuccess: (e) => {
      setDataEmployee(e?.data?.results);
      setIsDataLoad(true);
    },
  });

  // const { data: EmployeeDetail } = useQuery(['Employee', { employeeId }], fetchEmployeeDetail);

  // console.log(EmployeeDetail);

  // const { mutate: updateEmployee } = useUpdateEmployee(employeeId, EmployeeDetail?.data);

  const onSubmit = (data) => {
    onFormSubmit({ employee: data?.employee, role: data?.role, job_poster: jobPosterId });
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  const optionsTeam = dataEmployee?.map((item) => ({
    name: `${item?.first_name} ${item?.last_name}`,
    id: item?.id,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full space-y-4">
        <div className="w-full ">
          <ReactSelectSingle
            name={`employee`}
            control={control}
            options={getOptionSelectWithKey(optionsTeam)}
            defaultValue={getDefaultSelectObject(defaultValues?.employee?.id, getOptionSelectWithKey(optionsTeam))}
            placeholder="Employee"
          />
        </div>
        <div className="w-full">
          {' '}
          <ReactSelectSingle
            name={`role`}
            control={control}
            options={getOptionSelectWithKey(role)}
            defaultValue={getDefaultSelectObject(defaultValues?.role, getOptionSelectWithKey(role))}
            placeholder="Role"
          />
        </div>
        <div className="flex justify-end ml-auto gap-x-2">
          <Button onClick={handleCancel} className="flex" variant="outlined">
            Cancel
          </Button>
          <Button className=" flex" type="submit" variant="outlined">
            {defaultValues ? 'Update Team' : 'Add Team'}
          </Button>
        </div>
      </div>
      <DevTool control={control} />
    </form>
  );
}
export default RecTeamForm;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import project from '../../../api/task/project';

export const logWorkingHours = createAsyncThunk('task/setLogWorkingHours', async (values) => {
  const { dataLog, id, params, action, trigger, callback } = values;
  const { logWorkHours } = project();
  const response = await logWorkHours(dataLog.request, id, params, action);
  return { data: response.data, action, trigger, callback };
});

const taskSlice = createSlice({
  name: 'task',
  initialState: {
    runningTask: Cookies.get('bztAct') && JSON.parse(Cookies.get('bztAct')),
    triggerWarning: false,
    triggerRedraw: false,
    triggerCloseDrawer: false,
    promptTaskLimit: false,
    isStatusHidden: true,
  },
  reducers: {
    setRunningTask(state, action) {
      const currState = state;
      currState.runningTask = action.payload;
      Cookies.set('bztAct', JSON.stringify(action.payload));
    },
    stopRunningTask(state) {
      const currState = state;
      currState.runningTask = false;
      Cookies.remove('bztAct');
    },
    setTriggerWarning(state, action) {
      const currState = state;
      currState.triggerWarning = action.payload;
    },
    setTriggerRedraw(state, action) {
      const currState = state;
      currState.triggerRedraw = action.payload;
    },
    promptTaskLimit(state, action) {
      const currState = state;
      currState.promptTaskLimit = action.payload;
    },
    setTriggerCloseDrawer(state, action) {
      const currState = state;
      currState.triggerCloseDrawer = action.payload;
    },
    setIsStatusHide(state, action) {
      const currState = state;
      currState.isStatusHidden = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logWorkingHours.fulfilled, (state, action) => {
      const currState = state;
      const { action: act, trigger, callback } = action.payload;
      if (act === 'patch') {
        currState.runningTask = false;
        Cookies.remove('bztAct');
        currState.triggerRedraw = true;
        currState.triggerWarning = false;
      }
      if (callback) {
        callback();
      }
      if (trigger) {
        currState.triggerRedraw = trigger;
      }
    });
  },
});

export const langActions = taskSlice.actions;
export default taskSlice;

/* eslint-disable */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import ArticleTab from './ArticleTab';
import CollectionTab from './CollectionTab';
import AuthorTab from './AuthorTab';
import CategoryTab from './CategoryTab';
import KeywordTab from './KeywordTab';

export default function Article() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <BZHelmet title=" Article" description="" content="" />
      <div>
        <BaseMainTitle title="Article" />
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList className='bz-tabs' onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Article" value="1" />
                <Tab label="Collection" value="2" />
                <Tab label="Category" value="3" />
                <Tab label="Author" value="4" />
                <Tab label="Keyword" value="5" />
              </TabList>
            </Box>
            <TabPanel className="!p-0" value="1">
              <ArticleTab />
            </TabPanel>
            <TabPanel className="!p-0" value="2">
              <CollectionTab />
            </TabPanel>
            <TabPanel className="!p-0" value="3">
              <CategoryTab />
            </TabPanel>
            <TabPanel className="!p-0" value="4">
              <AuthorTab />
            </TabPanel>
            <TabPanel className="!p-0" value="5">
              <KeywordTab />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </>
  );
}

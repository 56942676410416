import React, { useContext, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { format } from 'date-fns';
import NotificationContext from './NotificationContext';
import DetailContext from '../../../Projects/DetailContext';
import notificationApi from '../../../../../api/task/notification';

const tabTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '& .MuiList-root': {
              height: 196,
              overflowY: 'auto',
            },
          }),
      },
    },
  },
});

function BoxContext({ title, children }) {
  return (
    <div className="flex flex-row w-[678px] m-auto justify-start">
      <span className="w-[204px] mr-4">{title}</span>
      {children}
    </div>
  );
}

const daysM = [...Array(30)].map((_, i) => i + 1);

export default function ProjectNotification() {
  const detailContext = useContext(DetailContext);
  const myContext = useContext(NotificationContext);
  const { notifSubs, notifTrigger } = myContext;
  const [notificationSubs, setNotificationSubs] = useState(null);
  const [selectedNotif, setSelectedNotif] = useState(null);
  const [configuration, setConfiguration] = useState({
    section: '',
    interval: new Date(),
    due_task: {
      day: 0,
      time: new Date(),
    },
    cut_off: new Date(),
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeConfiguration = (param, value) => {
    if (Array.isArray(param)) {
      setConfiguration((prevState) => {
        return {
          ...prevState,
          [param[0]]: {
            ...prevState[param[0]],
            [param[1]]: value,
          },
        };
      });
    } else {
      setConfiguration((prevState) => {
        return {
          ...prevState,
          [param]: value,
        };
      });
    }
  };

  const onAddNotification = () => {
    let configurations = null;
    if (selectedNotif) {
      const { due_task: dueTask, section, cut_off: cutOff } = configuration;
      switch (selectedNotif) {
        case 1:
          configurations = null;
          break;
        case 2:
          configurations = null;
          break;
        case 3:
          configurations = {
            days: +dueTask.day,
            hours: +format(dueTask.time, 'HH'),
            minutes: +format(dueTask.time, 'm'),
          };
          break;
        case 4:
          configurations = { section_id: section };
          break;
        case 5:
          configurations = {
            cron_schedule: `${+format(cutOff, 'm') || '*'} ${+format(cutOff, 'HH') || '*'} * * *`,
            send_when_no_update: false,
          };
          break;
        default:
          break;
      }
      const data = {
        project: +detailContext.projectId,
        trigger: selectedNotif,
        configuration: configurations,
      };
      const { postNotificationSubscription } = notificationApi();
      postNotificationSubscription(data)
        .then((res) => {
          if (res.status === 201 || res.data) {
            myContext.getNotifSubs('set');
            setSelectedNotif(null);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const columns = [
    {
      field: 'trigger',
      headerName: 'Task Type',
      headerAlign: 'center',
      align: 'center',
      minWidth: 256,
      renderCell: (params) => params.row.trigger.name,
    },
    {
      field: 'configuration',
      headerName: 'Configuration',
      headerAlign: 'center',
      align: 'center',
      minWidth: 256,
      renderCell: (params) => {
        const config = params.row.configuration;
        if (!config) {
          return '-';
        }
        const arrItems = [];
        Object.keys(config).forEach((key) => {
          arrItems.push(
            <span className="text-xs">
              <b>{key}:</b> {config[key].toString()}
            </span>
          );
        });

        return <div className="flex flex-col">{arrItems}</div>;
      },
    },
  ];

  useEffect(() => {
    if (!notificationSubs && notifSubs && notifSubs !== notificationSubs) {
      setNotificationSubs(notifSubs);
    }
  }, [notifSubs, notificationSubs]);
  useEffect(() => {
    if (notificationSubs && notifSubs && notificationSubs.length !== notifSubs.length) {
      setNotificationSubs(notifSubs);
    }
  }, [notifSubs, notificationSubs]);
  return (
    <ThemeProvider theme={tabTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="relative w-full h-full">
          <div className="m-auto max-w-[524px] w-[100%] flex flex-col space-y-4 border border-dashed px-4 py-4 justify-center">
            <BoxContext title="Notification trigger">
              <Select sx={{ width: 256, px: 0.2, py: 0.2 }} value={selectedNotif || ''} onChange={(e) => setSelectedNotif(e.target.value)} displayEmpty>
                <MenuItem value="">-</MenuItem>
                {notifTrigger &&
                  notifTrigger.map((nt) => (
                    <MenuItem key={nt.name} value={nt.id}>
                      {nt.name}
                    </MenuItem>
                  ))}
              </Select>
            </BoxContext>
            {[4].includes(selectedNotif) && (
              <BoxContext title="Section">
                <Select sx={{ width: 256, px: 0.2, py: 0.2 }} value={configuration.section || ''} onChange={(e) => handleChangeConfiguration('section', e.target.value)}>
                  <MenuItem value="">-</MenuItem>
                  {detailContext.sections &&
                    detailContext.sections.map((s) => (
                      <MenuItem key={s.name} value={s.id}>
                        {s.name}
                      </MenuItem>
                    ))}
                </Select>
              </BoxContext>
            )}

            {[3].includes(selectedNotif) && (
              <BoxContext title="Due task">
                <div className="flex items-center space-x-2">
                  <FormControl size="small">
                    <InputLabel id="select-day" sx={{ backgroundColor: 'white' }} InputLabelProps={{ shrink: true }}>
                      day
                    </InputLabel>
                    <Select
                      labelId="select-day"
                      value={configuration.due_task.day}
                      onChange={(e) => handleChangeConfiguration(['due_task', 'day'], e.target.value)}
                      sx={{
                        width: 64,
                        height: 40,
                      }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value={0}>0</MenuItem>
                      {daysM.map((_, i) => (
                        <MenuItem key={`${i + 1}`} value={i + 1}>
                          {i + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TimePicker
                    ampm={false}
                    openTo="hours"
                    views={['hours', 'minutes']}
                    inputFormat="HH:mm"
                    mask="__:__"
                    label="time"
                    value={configuration.due_task.time}
                    onChange={(newValue) => {
                      handleChangeConfiguration(['due_task', 'time'], newValue);
                    }}
                    orientation="landscape"
                    renderInput={(params) => <TextField size="small" {...params} sx={{ width: 184 }} InputLabelProps={{ shrink: true }} />}
                  />
                </div>
              </BoxContext>
            )}
            {[5].includes(selectedNotif) && (
              <BoxContext title="Cut off time">
                <TimePicker
                  ampm={false}
                  openTo="hours"
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  label=""
                  value={configuration.cut_off}
                  onChange={(newValue) => {
                    handleChangeConfiguration('cut_off', newValue);
                  }}
                  orientation="landscape"
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </BoxContext>
            )}
            {[3, 4].includes(selectedNotif) && (
              <BoxContext title="Interval">
                <TimePicker
                  ampm={false}
                  openTo="hours"
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  label=""
                  value={configuration.interval}
                  onChange={(newValue) => {
                    handleChangeConfiguration('interval', newValue);
                  }}
                  orientation="landscape"
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
              </BoxContext>
            )}
            <button
              disabled={!selectedNotif}
              type="button"
              className={`flex ml-auto rounded-xl ${selectedNotif ? 'bg-[#2C6D47]' : 'bg-[#2c6d474d]'} text-white px-5 py-2`}
              onClick={onAddNotification}
            >
              Add
            </button>
          </div>
          {notificationSubs && (
            <Box className="w-full h-full my-4">
              <DataGrid
                autoHeight
                rows={notificationSubs || []}
                columns={columns}
                pageSize={rowsPerPage}
                onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
                className="m-auto max-w-[524px] w-[100%]"
              />
            </Box>
          )}
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

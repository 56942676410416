import useGet from '@/hooks/useGet';
import { Add } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { useContext } from 'react';
import SiteForm from './SiteForm';
import SiteContext from './SiteContext';

export default function Website({ data, sites }) {
  const [valueTab, setValueTab] = React.useState(0);
  const lastTab = sites && sites.length;

  const ContextSiteValue = [valueTab, setValueTab, lastTab];

  const { data: SiteDetail } = useGet({
    url: `sites/${sites[valueTab]?.id}`,
    validate: `sites/${sites[valueTab]?.id}`,
    isEnabled: sites && sites.length > 0 && valueTab !== sites.length + 1,
    onSuccess: (e) => {
      console.log('🚀 ~ SiteForm ~ e:', e);
    },
  });

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  return (
    <SiteContext.Provider value={ContextSiteValue}>
      <div>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={valueTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                {sites?.map((st, idx) => (
                  <Tab label={st.name} value={idx} />
                ))}
                <Tab icon={<Add />} iconPosition="start" label="ADD SITE" value={sites && sites.length > 0 ? sites.length + 1 : 0} />
              </TabList>
            </Box>

            <TabPanel className="!p-0" value={valueTab}>
              {valueTab !== sites.length + 1 ? <SiteForm defaultValues={SiteDetail?.data} key={SiteDetail?.data?.id} /> : <SiteForm showTemplate={false} />}
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </SiteContext.Provider>
  );
}

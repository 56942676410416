import { gapi } from 'gapi-script';

gapi.load('client:auth2', () => {
  gapi.auth2.init({ client_id: '974721150096-94umf044gi0qicst64992ln34aj3abhd.apps.googleusercontent.com' });
});

function sitemap() {
  function isSignedIn() {
    return gapi.auth2.getAuthInstance().isSignedIn.get();
  }

  async function logIn() {
    // if (!this.isSignedIn()) {
    return this.authenticate().then(this.loadClient);
    // }
  }

  async function authenticate() {
    // eslint-disable-next-line no-return-await
    return await gapi.auth2
      .getAuthInstance()
      .signIn({ scope: 'https://www.googleapis.com/auth/webmasters https://www.googleapis.com/auth/webmasters.readonly' })
      .then(
        () => {
          console.log('Sign-in successful');
        },
        (err) => {
          console.error('Error signing in', err);
        }
      );
  }

  async function loadClient() {
    // gapi.client.setApiKey("YOUR_API_KEY");
    return gapi.client.load('https://content.googleapis.com/discovery/v1/apis/searchconsole/v1/rest').then(
      () => {
        console.log('GAPI client loaded for API');
      },
      (err) => {
        console.error('Error loading GAPI client for API', err);
      }
    );
  }

  function submitSitemap(siteUrl, feedpath, customParams) {
    return gapi.client.webmasters.sitemaps.submit({
      siteUrl,
      feedpath,
      ...customParams,
    });
  }

  function listSitemap(siteUrl, customParams) {
    return gapi.client.webmasters.sitemaps.list({
      siteUrl,
      ...customParams,
    });
  }

  function getSitemap(siteUrl, feedpath, customParams) {
    return gapi.client.webmasters.sitemaps
      .get({
        siteUrl,
        feedpath,
        ...customParams,
      })
      .then(
        (response) => {
          // Handle the results here (response.result has the parsed body).
          console.log('Response', response);
        },
        (err) => {
          console.error('Execute error', err);
        }
      );
  }

  function deleteSitemap(siteUrl, feedpath, customParams) {
    return gapi.client.webmasters.sitemaps
      .delete({
        siteUrl,
        feedpath,
        ...customParams,
      })
      .then(
        () => {
          // Handle the results here (response.result has the parsed body).
          // console.log("Response", response);
        },
        (err) => {
          console.error('Execute error', err);
        }
      );
  }

  function queryAnalytics(siteUrl, startDate, endDate, options) {
    return gapi.client.webmasters.searchanalytics
      .query({
        siteUrl,
        resource: {
          ...options,
          startDate,
          endDate,
        },
      })
      .then(
        (response) => {
          // Handle the results here (response.result has the parsed body).
          console.log('Response', response);
        },
        (err) => {
          console.error('Execute error', err);
        }
      );
  }

  return {
    isSignedIn,
    logIn,
    authenticate,
    loadClient,
    submitSitemap,
    listSitemap,
    getSitemap,
    deleteSitemap,
    queryAnalytics,
  };
}

export default sitemap;

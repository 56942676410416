import React from 'react';
import { Autocomplete, Box, ButtonBase, Fade, Popover, TextField } from '@mui/material';

import formatterInitialName from '../../../../helper/formatterInitialName';

export default function AssigneeTask({ idAssignee, openAssignee, anchorAssignee, assigneeList, topicData, config }) {
  const { activeUser, setAnchorAssignee, onAssigneeChange } = config;
  const { formatter } = formatterInitialName();

  return (
    <Popover
      id={idAssignee}
      open={openAssignee}
      anchorEl={anchorAssignee}
      onClose={(event) => {
        if (event && event.stopPropagation) event.stopPropagation();
        setAnchorAssignee(null);
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      className="-ml-6"
      PaperProps={{ sx: { backgroundColor: 'transparent' } }}
    >
      <Fade in={openAssignee}>
        <div className="relative bg-transparent min-w-[480px] h-full overflow-y-hidden">
          <Box
            className="flex relative flex-col py-4 space-y-2 border border-gray-300 rounded-lg bg-[#ffffff]"
            onClick={(event) => {
              if (event && event.stopPropagation) event.stopPropagation();
            }}
          >
            <div className="pl-4 pr-4 flex flex-col relative">
              <div className="flex items-center">
                <Autocomplete
                  name="autocomplete_assignee"
                  options={assigneeList || []}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, newValue) => option?.id === newValue?.id}
                  onChange={(event, dataOption) => {
                    onAssigneeChange(event, dataOption);
                  }}
                  value={topicData?.assignee}
                  noOptionsText="no user found"
                  renderOption={(props, option) => (
                    <Box key={option.name} component="li" {...props}>
                      <div className="w-6 h-6 border rounded-full text-xs flex justify-center items-center mr-2">
                        <span>{formatter(option.name)}</span>
                      </div>
                      <span className="text-ellipsis truncate">{option.name}</span>
                    </Box>
                  )}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assignee"
                      placeholder="Name"
                      sx={{
                        height: 32,
                        '& > div': {
                          padding: '0px 6px 0px 6px !important',
                          outline: 'none',
                        },
                        '& > .MuiInputLabel-root': {
                          top: 0,
                          fontSize: 14,
                        },
                        '& > .Mui-focused': { top: 0 },
                        '& > .MuiFormLabel-filled': { top: 0 },
                        '& > .MuiOutlinedInput-root': {
                          fontSize: 14,
                          '& > .MuiAutocomplete-input': { padding: '7.5px 48px 7.5px 6px' },
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
                {activeUser?.id !== topicData?.assignee?.id && (
                  <>
                    <span className="mx-2">or</span>
                    <ButtonBase
                      sx={{
                        width: 192,
                        border: '1px solid rgba(11, 11, 11, .1)',
                        borderRadius: 2,
                        paddingX: 0,
                        paddingY: 0.5,
                      }}
                      onClick={(e) => onAssigneeChange(e, { id: activeUser.id })}
                    >
                      Assign to me
                    </ButtonBase>
                  </>
                )}
              </div>
            </div>
          </Box>
        </div>
      </Fade>
    </Popover>
  );
}

// /* eslint-disable */

import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CardActionArea, CardActions, Typography, CardContent, Card, Button } from '@mui/material';
import { Delete, ModeEdit } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';

import { handleModal } from '../../store/slices/modalSlice';
import ModalWrapper from '../../components/ui/ModalWrapper';
import TextForm from '../../components/Form/Widget/TextForm';
import BZHelmet from '../../utils/BZHelmet';
import WidgetForm from '../../components/Form/Widget/WidgetForm';
import BaseAssetViewer from '../../components/Viewer/BaseAssetViewer';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import useGet from '../../hooks/useGet';
import AssetCardNew from '../../components/Viewer/AssetCardNew';
import UnpairAssetButton from '../../components/ui/Alert/UnpairAssetButton';
import useDelete from '../../hooks/useDelete';
import usePost from '../../hooks/usePost';

export default function WidgetDetail() {
  const [widgetDetail, setWidgetDetail] = useState(null);
  const [articleSelected, setArticleSelected] = useState([]);

  const [isTextElementData, setIsTextElementData] = useState(null);

  const dispatch = useDispatch();
  const { widgetId } = useParams();

  const modal = useSelector((state) => state.modal);
  const assetPair = useSelector((state) => state.assetCrud.selected);

  const handleChangeArticle = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(articleSelected);
      newSelection.push(parseInt(value, 10));
      setArticleSelected(newSelection);
    } else {
      const newSelection = articleSelected;
      const newSubs = newSelection.filter((sub) => parseInt(sub, 10) !== parseInt(value, 10));
      setArticleSelected(newSubs);
    }
  };

  useGet({
    url: `widgets/${widgetId}`,
    validate: 'widget',
    // isEnabled: Boolean(sectionId),
    onSuccess: (e) => {
      setWidgetDetail(e.data);
    },
  });

  useGet({
    url: `text_elements/${modal.modalId}`,
    validate: 'textElements',
    isEnabled: modal.componentName === 'editTextSectionWidget',
    onSuccess: (e) => {
      setIsTextElementData(e);
    },
  });

  const destroyMenuItems = useDelete({
    confText: `modals.text_delete.confirmation_text`,
    confTitle: `modals.text_delete.title`,
    success: `Text deleted`,
    validate: ['widget'],
  });

  const onDestroyTextEl = (id) => {
    destroyMenuItems(`text_elements/${id}`);
  };

  const onUnpairArticle = useDelete({
    url: `widgets/${widgetId}/unpair_articles`,
    confText: `modals.article_delete.confirmation_text`,
    confTitle: `modals.article_delete.title`,
    success: 'Article deleted',
    validate: ['widget'],
    isSubUrl: false,
    method: 'patch',
  });

  const { mutate: pairingAssets } = usePatchOrPut({ url: `widgets/${widgetId}/pair_assets`, validate: ['widget'] });

  const onPairAssets = async (data) => {
    await pairingAssets(data);
  };

  const { mutate: postText } = usePost({ url: 'text_elements', validate: ['widget'] });

  const onPostText = async (data) => {
    await postText(data);
  };

  function renderAction(value, widgetData) {
    let widget;
    if (value?.toLowerCase() === 'article') {
      widget = (
        <div className="ml-auto flex gap-x-3">
          <Button onClick={() => onUnpairArticle({ bodyData: { articles: articleSelected } })} className="ml-auto flex bg-red-600 text-white h-fit" type="button" variant="filled">
            Delete Selected
          </Button>
          <Link to={`/widgets/detail/${widgetData?.id}/article/add`}>
            <Button className="bg-blue-600 text-white h-fit" type="submit" variant="filled">
              Add New
            </Button>{' '}
          </Link>
        </div>
      );
    }
    if (value?.toLowerCase() === 'text') {
      widget = (
        <div className="ml-auto flex gap-x-3">
          <Button
            onClick={() => dispatch(handleModal({ componentName: 'addTextSectionWdiget', modalId: '' }))}
            className="bg-blue-600 text-white h-fit"
            type="submit"
            variant="filled"
          >
            Add New Text
          </Button>{' '}
          <ModalWrapper componentName="addTextSectionWdiget" header="Add Text " maxWidth="md">
            <TextForm onFormSubmit={onPostText} supportData={widgetData} />
          </ModalWrapper>
        </div>
      );
    }
    if (value?.toLowerCase() === 'media') {
      widget = (
        <div className="ml-auto flex gap-x-3">
          <UnpairAssetButton disabled={assetPair.length === 0} isApi url={`widgets/${widgetId}/unpair_assets`} validate={['widget']} variant="contained" color="error" />
          <Button
            onClick={() => dispatch(handleModal({ componentName: 'assetPairSectionWdiget', modalId: '' }))}
            className="bg-blue-600 text-white h-fit"
            type="submit"
            variant="filled"
          >
            Add New
          </Button>{' '}
          <ModalWrapper componentName="assetPairSectionWdiget" header="Select Widget Asset" maxWidth="lg">
            <BaseAssetViewer handleClick={() => onPairAssets({ assets: assetPair?.map((asset) => asset.id) })} />
          </ModalWrapper>
        </div>
      );
    }
    // if (value?.toString() === '4') {
    //   widget = 'Video';
    // }
    // if (value?.toString() === '5') {
    //   widget = 'File';
    // }
    return widget;
  }

  function renderWidget(value, widgetData) {
    let widget;
    if (value?.toLowerCase() === 'article') {
      widget = (
        <div className="grid grid-cols-4 gap-5">
          {widgetData?.widget_articles?.map((art) => (
            <div key={art?.id} className="">
              <Card className="w-full relative">
                <CardActions className="gap-x-3 flex items-center">
                  <input type="checkbox" name="lang" onChange={handleChangeArticle} value={art.id} className=" w-5 h-5" />
                  <Link to={`/widgets/detail/${art.widget}/article/${art?.article?.id}`}>
                    <button type="button">{art?.article?.name}</button>
                  </Link>
                </CardActions>
                <CardActionArea>
                  <CardContent>{art?.article?.status}</CardContent>
                </CardActionArea>
              </Card>
            </div>
          ))}
        </div>
      );
    }
    if (value?.toLowerCase() === 'text') {
      widget = (
        <div className="grid grid-cols-6 gap-5">
          {widgetData?.text_elements?.map((text) => (
            <div key={text?.id} className="">
              <Card className="w-full relative">
                <CardActions className="gap-x-3 flex items-center">
                  {/* <input type="checkbox" name="lang" value={text.id} className=" w-5 h-5" /> */}
                  <button type="button">{text?.name}</button>
                  <div className="ml-auto flex items-center gap-x-2">
                    <button onClick={() => onDestroyTextEl(text.id)} type="button" className="text-lg font-semibold">
                      <Delete className="w-5 h-5" />
                    </button>
                    <button onClick={() => dispatch(handleModal({ modalId: text.id, componentName: 'editTextSectionWidget' }))} type="button" className="text-lg font-semibold">
                      <ModeEdit className="w-5 h-5" />
                    </button>
                  </div>
                </CardActions>
                <CardActionArea>
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {text?.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          ))}
        </div>
      );
    }
    if (value?.toLowerCase() === 'media') {
      widget = (
        <div className="fhd:grid-cols-8 hd:grid-cols-6 lg:grid-cols-5 grid-cols-3 grid gap-3 w-full">
          <AssetCardNew assetsData={widgetData?.widget_assets} assetComponent="widgetDetailAssets" />
        </div>
      );
    }
    if (value?.toString() === '4') {
      widget = 'Video';
    }
    if (value?.toString() === '5') {
      widget = 'File';
    }
    return widget;
  }

  const { mutate: onUpdateTextElement } = usePatchOrPut({ url: `text_elements/${modal.modalId}`, validate: ['textElements', 'widget'] });

  const { mutate: onUpdateSectionWidget } = usePatchOrPut({ url: `widgets/${widgetId}`, validate: ['widget'] });

  return (
    <>
      <BZHelmet title="Widget Detail" description="bz publish widget detail" />
      <div className="p-5">
        <div className=" bg-white border p-5 shadow rounded">
          <div className="bg-gray-100 w-full py-5 px-3 my-5 rounded">
            <div className="flex items-center">
              <div className="">{`Widget Name : ${widgetDetail?.name} ${widgetDetail?.id} `}</div>
              <button onClick={() => dispatch(handleModal({ modalId: widgetId, componentName: 'editWidgetSetting' }))} className="pl-3" type="button">
                <ModeEdit className="" />
              </button>
              {renderAction(widgetDetail?.category_name, widgetDetail)}
            </div>
          </div>
          <div className="space-y-5">{renderWidget(widgetDetail?.category_name, widgetDetail)}</div>
        </div>
        <ModalWrapper componentName="editTextSectionWidget" header="Edit Text" maxWidth="sm">
          <TextForm defaultValues={isTextElementData?.data} key={isTextElementData?.data?.id} onFormSubmit={onUpdateTextElement} />
        </ModalWrapper>
        <ModalWrapper componentName="editWidgetSetting" header="Update Widget" maxWidth="sm">
          <WidgetForm defaultValues={widgetDetail} key={widgetDetail?.id} isWidget onFormSubmit={onUpdateSectionWidget} />
        </ModalWrapper>
      </div>
    </>
  );
}

export const gcd = (a, b) => {
  if (b === 0) {
    return a;
  }
  return gcd(b, a % b);
};

const getFixedSize = (nW, sW, lW, nH, sH, lH, currRatio) => {
  const usedSw = Number(sW.toFixed(2));
  const usedSh = Number(sH.toFixed(2));
  const usedRatio = Number(currRatio.toFixed(2));
  const usedLW = Number(lW.toFixed(2));
  const usedLH = Number(lH.toFixed(2));

  const w = Number((nW / usedSw).toFixed(2));
  const h = Number((nH / usedSh).toFixed(2));
  const isHitLimit = w > usedLW || h > usedLH;
  if (currRatio >= 0 && isHitLimit) {
    return getFixedSize(nW, usedSw + usedRatio, usedLW, nH, usedSh + usedRatio, usedLH, usedRatio - 0.1);
  } else if (currRatio <= 0 && isHitLimit) {
    return getFixedSize(nW, usedSw + 0.1, usedLW, nH, usedSh + 0.1, usedLH, usedRatio);
  }

  return {
    sW,
    sH,
    w,
    h,
  };
};

export async function convertNormalImgToBase64(image) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL('image/png', 1.0);

      resolve({
        url: dataURL,
        canvas,
      });
    };
    // img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(image);
    img.src = image;
    img.onerror = function () {
      reject(new Error('Failed to load image'));
    };
  });
}

function getSizeBasedRatio(size, max) {
  let resize_factor = size.height / (size.width / max.width) <= max.height ? size.width / max.width : size.height / max.height;

  return { w: size.width / resize_factor, h: size.height / resize_factor };
}

export async function convertImgToBase64(image) {
  const limitW = 42.5;
  const limitH = 30;
  return new Promise((resolve, reject) => {
    const img = new Image();
    // img.crossOrigin = 'anonymous';
    img.setAttribute('crossOrigin', 'anonymous');

    let w;
    let h;
    let printedW;
    let printedH;
    let baseRatio = 1.4166796296296293; // Cell Width : Cell Height
    let ratio = baseRatio; // Cell Width : Cell Height
    img.onload = function () {
      const nw = this.naturalWidth;
      const nh = this.naturalHeight;
      ratio = gcd(nw, nh);

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = w = nw;
      canvas.height = h = nh;

      const printed = getSizeBasedRatio({ width: nw, height: nh }, { width: limitW, height: limitH });
      printedW = printed.w;
      printedH = printed.h;

      // ctx.drawImage(img, 0, 0);
      ctx.drawImage(this, 0, 0, nw, nh);

      const dataURL = canvas.toDataURL('image/jpeg', 0.3);

      resolve({
        url: dataURL,
        canvas,
        w,
        h,
        printedW,
        printedH,
        ratio,
      });
    };
    // img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(image);
    img.src = image;
    img.onerror = function () {
      reject(new Error('Failed to load image'));
    };
  });
}

export async function pdfHeader(doc, obj) {
  const imgLogo = await convertNormalImgToBase64(obj.logo);

  const { url } = imgLogo;

  // Header
  const cW = 42;
  const cH = 20;
  const x = 30;
  const pageWidth = doc.internal.pageSize.getWidth();

  const marginX = (pageWidth - cW) / 2;

  if (imgLogo) {
    doc.addImage(url, 'JPEG', marginX, 10, cW, cH, '', 'FAST');
  }

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(10);
  const textClient = obj?.name || '';
  const textX = (pageWidth - doc.getTextWidth(textClient)) / 2;
  doc.text(textClient, textX, 34);

  const oName = obj.outletData?.name || '-'
  const textOutlet = (pageWidth - doc.getTextWidth(oName)) / 2;
  doc.text(oName, textOutlet, 39);

  const oCollections = obj.selected.map((id) => obj.outletCollections.find((o) => o.id === id)?.collection?.name || '');
  const textOC = oCollections?.join(', ');
  const textOColl = (pageWidth - doc.getTextWidth(textOC)) / 2;
  doc.text(textOC, textOColl, 44);

  doc.line(x, 50, pageWidth - x, 50);
}

import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, Stack, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DevTool } from '@hookform/devtools';

import BaseAutocompleteValidation from '../../../Input/Base/BaseAutocompleteValidation';

const schema = yup.object().shape({
  email: yup.string().required('email is a required field').email('Email not valid'),
  team: yup.object().required('team is a required field').nullable(),
});

export default function InviteUser({ open, data, onClose, action }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      team: '',
      email: '',
    },
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = (email) => {
    if (email.email && email.team) {
      action({
        email: email.email,
        team: email.team.id,
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} sx={{ '& > .MuiDialog-container > .MuiPaper-root': { position: 'absolute', top: '25%' } }}>
      <DialogTitle>Invite User</DialogTitle>
      <DialogContent>
        <Box className="flex flex-col space-y-4">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4">
            <BaseAutocompleteValidation control={control} name="team" label="team" errors={errors} options={data} optionLabel="name" config={{ classes: 'mt-2' }} />
            <FormControl variant="outlined">
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    label="Email"
                    variant="outlined"
                    size="small"
                    error={!!errors.email?.message}
                    helperText={errors.email?.message}
                    placeholder="example: user@mail.com"
                  />
                )}
              />
            </FormControl>
            <Divider />
            <Stack direction="row" spacing={2} className="ml-auto">
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Invite
              </Button>
            </Stack>
          </form>
        </Box>
      </DialogContent>
      <DevTool control={control} />
    </Dialog>
  );
}

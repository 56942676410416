/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selected: [],
};

const locationCrudSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    selectLocationSingle: (state, action) => {
      const currState = state;
      const newPayload = JSON.parse(action.payload);
      currState.selected = newPayload;
    },
    selectLocationList: (state, action) => {
      const currState = state;
      const newPayload = JSON.parse(action.payload);
      currState?.selected.push(newPayload);
    },
    unSelectLocationList: (state, action) => {
      const currState = state;
      const newPayload = JSON.parse(action.payload);
      const newData = currState?.selected.filter((location) => {
        return location.id.toString() !== newPayload.id.toString()
      })
      currState.selected = newData;
    },

    resetLocation: () => {
      return {
        ...initialState
      }
    },

  },
});

export const { resetLocation, selectLocationList, unSelectLocationList, selectLocationSingle } = locationCrudSlice.actions;

export default locationCrudSlice.reducer;

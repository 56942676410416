import { useState } from 'react';
import {
  Box,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: '60vw',
  maxHeight: '90%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid transparent',
  boxShadow: 24,
  p: 4,
};

export default function TransactionSheets(props) {
  const { open, close, action, data, onChanges } = props;
  const [sheet, setSheet] = useState(0);

  const onCloseHandler = () => {
    close();
  };

  const handleChange = (event) => {
    const val = +event.target.value;
    onChanges(val)
    setSheet(val);
  };

  const onSubmit = () => {
    action(sheet);
    setSheet(0)
  };

  return (
    <Modal open={open} onClose={onCloseHandler} aria-labelledby="modal-account-group" aria-describedby="modal-account-group" className="w-full">
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" className="capitalize">
            Select Sheets
          </Typography>
          <div className="mt-4">
            <FormControl>
              <RadioGroup
                className="flex flex-col space-y-4"
                aria-labelledby="sheets-controlled-radio-buttons-group"
                name="sheets-controlled-radio-buttons-group"
                value={sheet}
                onChange={handleChange}
              >
                {data && data.map((d, i) => {
                  return <FormControlLabel value={i} control={<Radio />} label={d} />;
                })}
              </RadioGroup>
            </FormControl>
            <Divider className="mt-4" />
            <Stack direction="row" spacing={2} className="mt-5 float-right">
              <button type="button" className="bz-btn secondary" onClick={onCloseHandler}>
                <p className="text-[#2C6D47]">Cancel</p>
              </button>
              <button className="bz-btn" onClick={onSubmit}>
                <p>Add</p>
              </button>
            </Stack>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

import useGetFilter from '@/hooks/useGetFilter';
import clsxm from '@/utils/clsxm';
import OrganizationChart from '@dabeng/react-orgchart';
import { CircularProgress } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function getHighestLevel(data) {
  return _.max(_.map(_.flattenDeep(data), 'level'));
}

export default function EmpOrgChart({ buData }) {
  const [dataUser, setDataUser] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const { isFetching, isSuccess } = useGetFilter({
    url: `accounting/business_units/${buData.id}/department_hierarchy/`,
    validate: `department_hierarchy-${buData.id}`,
    onSuccess: (res) => {
      setIsLoaded(true);
      setDataUser(res?.data);
    },
  });

  function mapEmployeesToChartNew(department) {
    const employees = [];

    function recurse(dept, level = 0) {
      if (!dept) return;
      if (dept.employees) {
        employees.push(dept.employees.map((emp) => ({ ...emp, department_name: dept.department_name, level: level + 1 })));
      }
      if (dept.children) {
        dept.children.forEach((child) => recurse(child, level + 1));
      }
    }

    recurse(department);
    return _.flattenDeep(employees);
  }

  const transformedChart = dataUser[0]?.chart?.map(([from, to]) => [from === null ? '1000000' : from, to].reverse()) || [];
  const idToNameMap = _.keyBy(mapEmployeesToChartNew(dataUser[0]), 'id');
  const resultTrNew = transformedChart.map((pair) => pair.map((id) => idToNameMap[id]));
  const employees = {};
  _.forEach(resultTrNew, (pair) => {
    _.forEach(pair, (employee) => {
      if (!employees[employee.id]) {
        employees[employee.id] = { ...employee, children: [], depth: 0 };
      }
    });
  });

  const childToParentMap = {};
  _.forEach(resultTrNew, (pair) => {
    const [child, parent] = pair;
    employees[parent.id].children.push(employees[child.id]);
    childToParentMap[child.id] = parent.id;
  });

  const setDepth = (employee, depth) => {
    employee.depth = depth;
    _.forEach(employee.children, (child) => {
      setDepth(child, depth + 1);
    });
  };

  const rootParents = _.difference(_.keys(employees), _.keys(childToParentMap));

  const nestedStructure = rootParents.map((rootId) => {
    const rootEmployee = employees[rootId];
    setDepth(rootEmployee, 1);
    return rootEmployee;
  });

  const calculateLevelHeight = (getHighestLevel(resultTrNew) + 2) * 90;

  const levelStyleSimp = ['bg-[#FC581D]  !text-white', 'bg-[#FC581D] text-white  ', 'bg-[#F4F1EC]  text-black', 'bg-[#E2E2E2]  text-black'];

  return dataUser[0]?.employees?.length > 0 ? (
    <div
    //  style={{ height: `${calculateLevelHeight}px` }}
     >
      <OrganizationChart
        datasource={{ name: buData.name, children: nestedStructure }}
        pan={true}
        collapsible={false}
        zoom={true}
        NodeTemplate={({ nodeData }) => {
          return (
            <div
              className={clsxm(
                'w-[16rem] bg-[#2C6D47] min-h-[80px] text-white p-[.5rem] rounded flex space-x-[1rem] items-center justify-center text-sm',
                levelStyleSimp[nodeData.depth]
              )}
            >
              {nodeData?.level && (
                <img src={nodeData?.avatar || 'https://bzpublishassets.blob.core.windows.net/media/assets/people.svg'} alt="" className="w-[40px] h-[40px] rounded-full" />
              )}
              <div className="">
                <div className="">{nodeData?.name}</div>
                <div>{nodeData?.position ? `${nodeData?.position} - ${nodeData?.department_name}` : nodeData?.department_name}</div>
              </div>
            </div>
          );
        }}
      />
    </div>
  ) : isFetching ? (
    <div className="w-full flex items-center justify-center">
      <CircularProgress />
    </div>
  ) : (
    dataUser &&
    dataUser.length > 0 &&
    dataUser[0].employees &&
    dataUser[0].employees.length === 0 && (
      <div className="text-center">
        <div>Please add employees to the top departments in this business unit to generate the chart.</div>
        <div>{`Specifically, add employees to the ${dataUser[0]?.department_name} department.`}</div>
        <div>
          Please go to the{' '}
          <Link to="/hr/employee" className="text-blue-600 underline">
            employee list.
          </Link>
        </div>
      </div>
    )
  );
}

// /* eslint-disable */

import React, { useState } from 'react';
import { CardActionArea, CardActions, CardContent, Card, Button, Pagination, FormControl, RadioGroup, FormControlLabel, Radio, InputBase } from '@mui/material';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Search } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import InputContainer from '../../components/Input/InputContainer';
import { fetchWidgetsAllByClient } from '../../hooks/useStructureData';

export default function SectionWidgetModal({ defaultValues, onFormSubmit }) {
  const { control, handleSubmit, reset } = useForm({ defaultValues });

  // const template = useSelector((state) => state.content.activeTemplate);

  const clientId = useSelector((state) => state.client.activeClient);

  const [page, setPage] = useState(1);
  const [countSection, setCountSection] = useState(0);
  const [search, setSearch] = useState('');

  const [widgetPaired, setWidgetPaired] = useState(null);

  const [widgetListData, setWidgetListData] = useState(null);
  const pageSize = 20;

  const count = Math.ceil(countSection / pageSize);

  const { sectionId } = useParams();

  const modal = useSelector((state) => state.modal);

  const handleChange = (e, p) => {
    setPage(p);
    // AssetsData.jump(p);
  };

  const handleChangeWidget = (event) => {
    setWidgetPaired(event.target.value);
  };

  const onSearchAsset = (event) => {
    setSearch(event.target.value);
  };

  let isAutoFetchingWidget;
  if (modal.componentName === 'pairWidget' || modal.componentName === 'editWidget') {
    isAutoFetchingWidget = true;
  } else isAutoFetchingWidget = false;
  useQuery(['widgetsAll', { pageSize, page, search, clientId }], fetchWidgetsAllByClient, {
    onSuccess: (e) => {
      setWidgetListData(e.data.results);
      setCountSection(e.data.count);
    },
    enabled: isAutoFetchingWidget,
  });

  const onSubmit = (data) => {
    onFormSubmit({ name: data.name, section: sectionId, widget: widgetPaired });
    reset();
  };

  return (
    <div>
      {' '}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <InputContainer
            name="name"
            control={control}
            label="Name"
            // errors={errors.name}
          />
        </div>
        <div className="my-3 flex gap-x-3 bg-white ">
          <InputBase size="small" placeholder="search" endAdornment={<Search color="disabled" />} onChange={onSearchAsset} className="border border-gray-300 rounded px-4" />
        </div>
        <div className="grid grid-cols-4 gap-5 mb-20">
          {widgetListData?.map((s) => (
            <div key={s?.id} className="w-full">
              <Card className={`w-full relative ${widgetPaired && widgetPaired.toString() === s.id.toString() ? 'bg-[#E98736]' : 'bg-white'} `}>
                <FormControl className="px-3">
                  <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={widgetPaired} onChange={handleChangeWidget}>
                    <FormControlLabel value={s.id} control={<Radio />} label={s.name + s.id} />
                  </RadioGroup>
                </FormControl>
                <Link to={`/s/detail/${s?.id}`}>
                  <CardActions className="ml-8 gap-x-3 flex items-center" />
                  <CardActionArea>
                    <CardContent>
                      <div className="w-full p-3">
                        <img src={`${s.screenshot ?? 'https://admin.bzpublish.com/img/bz-publish.svg'}`} alt="" className="w-full" />
                      </div>
                    </CardContent>
                    <div className="px-4 py-2">Category : {s.category_name} </div>
                  </CardActionArea>
                </Link>
              </Card>
            </div>
          ))}
        </div>
        <div className="flex left-0 h-20 px-5 items-center gap-x-3 mt-6 bottom-0 absolute bg-white w-full overflow-x-hidden border-t">
          <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChange} />
          <div className="">{`Total ${countSection}`}</div>
          <Button className="bg-blue-600 text-white w-fit h-fit ml-auto " type="submit" variant="filled">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

import { Delete } from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import { twMerge } from 'tailwind-merge';

export default function BaseDeleteButton({ onClick, text = 'Delete Selected', variant = 'text', disabled, className = '', ...props }) {
  return variant === 'text' ? (
    <ButtonBase disabled={disabled} onClick={onClick} className={twMerge(' bz-btn red !bg-bz-red ', className)} type="button" variant="contained" color="error">
      {text}
    </ButtonBase>
  ) : (
    <ButtonBase className={twMerge(' bz-btn red !bg-bz-red ', className)} onClick={onClick} disabled={disabled} aria-label="delete" {...props}>
      <Delete />
    </ButtonBase>
  );
}

/* eslint-disable */

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationMenu = yup
  .object()
  .shape({
    name: yup.string().required('Name is required'),
  })
  .required();

const schemaMenu = yupResolver(validationMenu);

export { schemaMenu };

/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import swal from 'sweetalert';
import InputContainerJP from '../../Input/InputContainerJP';
import SelectAutocomplete from '../../Input/SelectAutocomplete';
import StagesApi from '../../../api/recruitmenStage/StagesApi';
import { handleModal } from '../../../store/slices/modalSlice';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ReactSelectSingle from '../../Input/ReactSelectSingle';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';

const schema = yup.object().shape({
  name: yup.string().max(200).required(),
});

const type = [
  { name: 'Default', id: 'Default' },
  { name: 'Interview', id: 'Interview' },
  { name: 'Offer', id: 'Offer' },
  { name: 'Accepted', id: 'Accepted' },
  { name: 'CV Bank', id: 'CV Bank' },
];

function ChangeNameStage({ reload, defaultValues, onFormSubmit, isApplicationStage }) {
  const dispatch = useDispatch();
  const currentData = useSelector((state) => state.modal.modalData);
  console.log('🚀 ~ file: ChangeStageName.jsx:32 ~ ChangeNameStage ~ currentData:', currentData);
  const clientId = useSelector((state) => state.client.activeClient);
  const { jobPosterId } = useParams();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { name: currentData?.name, type: currentData?.list?.type }, resolver: yupResolver(schema) });

  const saveName = (name) => {
    const { UpdateStage } = StagesApi();

    try {
      UpdateStage(currentData.stageId, name)
        .then((res) => {
          if (res.status === 200) {
            dispatch(handleModal({ modalId: '', componentName: '' }));
            swal('New Contact Added Successfully!', { icon: 'success' });
            return reload(true);
          }
        })
        .catch((err) => {
          console.error('error', err);
        });
    } catch (err) {
      console.error('error ', err);
    }
  };

  const onSubmit = (data) => {
    const isApplicationStageData = isApplicationStage ? { job_poster: jobPosterId, type: 'Default' } : '';
    onFormSubmit({ name: data.name, client: clientId, ...isApplicationStageData, type: data.type });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit ? onSubmit : saveName)}>
      <InputContainerJP defaultValue={currentData && currentData.name} name="name" label="name" control={control} errors={errors} register={register} />
      <div className="w-full">
        {' '}
        <ReactSelectSingle
          name={`type`}
          control={control}
          options={getOptionSelectWithKey(type)}
          defaultValue={getDefaultSelectObject(currentData?.list?.type, getOptionSelectWithKey(type)) || { label: 'Default', value: 'Default' }}
          placeholder="Type"
        />
      </div>
      <Button className="flex mt-7 ml-auto bg-blue-600" type="submit" name="submit contact" variant="contained">
        Save
      </Button>
    </form>
  );
}

export default ChangeNameStage;

import React from 'react';
import { Table, TableBody, TableRow, TableCell, Switch, TableHead } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchSubscriptions } from '@/hooks/useUsersData';

function Subscription({ className, clientSubscriptions, register }) {
  const subscriptionsQuery = useQuery(['subscriptions'], fetchSubscriptions, { refetchOnWindowFocus: false });
  return (
    <Table className={className}>
      <TableHead>
        <TableRow>
          <TableCell>Module subscription</TableCell>
          <TableCell>Set status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {subscriptionsQuery.data?.data?.results?.map((subscription) => (
          <TableRow key={subscription.id}>
            <TableCell>{subscription.name}</TableCell>
            <TableCell>
              <span>Inactive</span>
              <Switch value={subscription.id} defaultChecked={clientSubscriptions?.includes(subscription.id)} name="subscriptions" {...register('subscriptions')} />
              <span>Active</span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default Subscription;

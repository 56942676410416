const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getDateFull = (date) => {
  if (!date) return '-';
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'full',
    timeStyle: 'long',
    timeZone: tz,
  }).format(new Date(date));
};

export function pdfFooter(doc) {
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageCount = doc.getNumberOfPages();

  const x = 30;
  for (var i = 0; i < pageCount; i++) {
    doc.setPage(i);
    doc.text(`Exported: ${getDateFull(new Date())}`, x, pageHeight - 10);
  }
}

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, FormControlLabel, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

const BaseDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': { background: 'rgba(0, 0, 0, 0.5)' },
  '& .MuiDialogContent-root': { padding: theme.spacing(2) },
}));

export default function DuplicateTask({ open, close, duplicateTask }) {
  const [value, setValue] = useState({ comments: false });

  const handleClose = () => {
    close();
  };

  const handleDuplicateTask = () => {
    duplicateTask({ comments: value.comments });
    handleClose();
  };

  const handleChange = (val, state) => {
    setValue({ [state]: val });
  };
  return (
    <BaseDialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle>Duplicate Task</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>Include</DialogContentText>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={value.comments} onChange={(e) => handleChange(e.target.checked, 'comments')} inputProps={{ 'aria-label': 'controlled' }} />}
            label="Comments and attachments"
            className="w-fit"
          />
        </FormGroup>
      </DialogContent>
      <Divider className="mb-4" />
      <DialogActions className="mb-2">
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleDuplicateTask}>
          Duplicate
        </Button>
      </DialogActions>
    </BaseDialog>
  );
}

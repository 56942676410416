import axiosInstances from '../helper/axiosInstances';

const baseURL = import.meta.env.VITE_API_URL;

export default function section() {
  const { axiosInstance } = axiosInstances();

  async function getSections() {
    const method = 'get';
    const url = `${baseURL}clients/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  return { getSections };
}

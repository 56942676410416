/* eslint-disable */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TruncateMarkup from 'react-truncate-markup';
import { styled, alpha } from '@mui/material/styles';
import {
  MoreHoriz as MoreHorizIcon,
  Lock as LockIcon,
  CheckCircleOutline,
  Check,
  KeyboardArrowDown,
  PeopleAltOutlined,
  AddOutlined,
  StarOutlineRounded,
  InsertLinkRounded,
  ModeEditOutlineOutlined,
  DateRangeOutlined,
} from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Tooltip, TextField } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'notistack';
import swal from 'sweetalert';
import TooltipWrapper from '../../../components/ui/Tooltip/TooltipWrapper';
import { getUsers } from '../../../store/slices/usersTeamSlice';
import { fetchProjects, fetchProjectDetail } from '../../../hooks/useProjectsData';
import { fetchTopics, createTopic, deleteTopics } from '../../../hooks/useTopicsData';
import { handleModal } from '../../../store/slices/modalSlice';
import request from '../../../helper/axiosUtil';
import { taskAdd } from '../../../store/slices/taskCrudSlice';
import AddTask from './AddTask';
import AssignTaskToPeople from './AssignTaskToPeople';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import ProjectForm from '../../../components/Form/Task/ProjectForm';
import formatterInitialName from '../../../helper/formatterInitialName';
import { shortText } from '../../../lib/StringHelper';
import BZHelmet from '../../../utils/BZHelmet';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

const StyledTabs = styled((props) => <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />)({});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  '& .MuiTabs-indicatorSpan :hover': { backgroundColor: '#28292B' },
  marginRight: 20,
  padding: 0,
  minWidth: 0,
  minHeight: 0,
  color: '#858586',
  '&.Mui-selected': { color: '#28292B' },
  '&.Mui-focusVisible': { backgroundColor: 'rgba(100, 95, 228, 0.32)' },
  '&:hover': {
    color: '#28292B',
    opacity: 1,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div className=" py-4 " role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0 }} className="">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TaskHome() {
  const clientId = useSelector((state) => state.auth.client.id);
  const user = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { formatter } = formatterInitialName();
  const userId = useSelector((state) => state.auth.user.id);
  const userName = useSelector((state) => state.auth.user.name);
  const userData = useSelector((state) => state.auth.user);
  const inputTask = useSelector((state) => state.crud.taskData);
  const modal = useSelector((state) => state.modal);
  const qClient = useQueryClient();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getUsers(clientId));
  }, [dispatch, clientId]);
  const [value, setValue] = useState(0);
  const [widthPrior, setWidthPrior] = useState('w-6/12');
  const [widthProject, setWidthProject] = useState('w-6/12');
  const [widthPeople, setWidthPeople] = useState('w-full');
  const [completedId, setCompletedId] = useState({ id: null, inComplete: false });
  const [onFocusSection, setOnFocusSection] = useState(false);
  const [handleTopicId, setHandleTopicId] = useState(null);
  const [linkOnTopicId, setLinkOnTopicId] = useState(null);
  const [toggleDueDate, setToggleDueDate] = useState(null);
  const [sendPostTopic, setSendPostTopic] = useState(false);
  const [isFavProj, setIsFavProj] = useState({ id: '', isFavorite: null });

  const [topicPerPage, setTopicPerPage] = useState(10);
  const currentPage = 1;
  const indexOfLastTopic = currentPage * topicPerPage;
  const indexOfFirstTopic = indexOfLastTopic - topicPerPage;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCopyProjectLink = (id) => {
    const urlLink = `${window.location.origin}/task/project/${id}/board`;
    navigator.clipboard.writeText(urlLink);
    enqueueSnackbar('Link copied to clipboard' || 'Success', { variant: 'success' });
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: widthPrior ? 'left' : 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: widthPrior ? 'left' : 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 185,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': { padding: '4px 0' },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) },
      },
    },
  }));

  const { data: ProjectsData, isSuccess: isProjectsLoad } = useQuery(['projects', { clientId, max_size: true }, { max_size: true }], fetchProjects);

  const { data: TopicsData } = useQuery(['topics', { userId }], fetchTopics);

  const projectId = inputTask.project || modal.modalId;
  let isAutoFetching;
  if (!projectId) {
    isAutoFetching = false;
  } else isAutoFetching = true;
  const { data: ProjectData } = useQuery(['project', { projectId }], fetchProjectDetail, { enabled: isAutoFetching });


  const upComing = [];
  const overDue = [];
  const complete = [];
  TopicsData?.data?.results?.map((top) => {
    const yesterday = ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date());
    const dueDate = new Date(top?.due_at);
    if ((dueDate >= yesterday && top?.completed_at === null) || (top?.completed_at === null && !top?.due_at)) {
      upComing.push(top);
    }

    if (top?.due_at && dueDate < yesterday && !top?.completed_at) {
      overDue.push(top);
    }
    if (top?.completed_at) {
      complete.push(top);
    }

    return [upComing, overDue, complete];
  });

  // const currentTopic = upComing.slice(indexOfFirstTopic, indexOfLastTopic);
  const currentTopic = upComing;
  const [isFav, setIsFav] = useState(false);

  const ProjData = [];

  if (isProjectsLoad) {
    ProjectsData?.data?.results.map((data) => {
      ProjData.push(data);
      return ProjData;
    });
  }

  const cekFav = isFav ? _.orderBy(ProjData, 'is_favorite', 'desc') : ProjData;

  const updateTopics = async (data) =>
    request({
      url: `/project-management/topics/${completedId?.id || handleTopicId || toggleDueDate}/`,
      method: 'patch',
      data,
      headers: { 'Content-Type': 'application/json' },
    });

  const { mutateAsync: updateTopic } = useMutation(updateTopics, {
    onSuccess: () => {
      qClient.invalidateQueries('topics');
      setCompletedId(null);
    },
  });

  const onUpdateTopic = async (data) => {
    await updateTopic(data);
  };

  const updateProjects = async (data) =>
    request({
      url: `/project-management/projects/${isFavProj?.id || projectId}/`,
      method: 'patch',
      data,
      headers: { 'Content-Type': 'application/json' },
    });

  const { mutateAsync: updateProject } = useMutation(updateProjects, {
    onSuccess: () => {
      qClient.invalidateQueries('projects');
      qClient.invalidateQueries('project');
      setIsFavProj(null);
      dispatch(handleModal({ modalId: '' }));
    },
  });

  const onUpdateProject = async (data) => {
    await updateProject(data);
  };

  const { mutateAsync: postTopic } = useMutation(createTopic, {
    onSuccess: (e) => {
      setHandleTopicId(e.data.id);
      qClient.invalidateQueries('topics');
      dispatch(taskAdd({ name: '', project: '', section: '' }));
      dispatch(handleModal({ modalId: '' }));
      setSendPostTopic(false);
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onPostTopic = async (data) => {
    await postTopic(data);
  };

  useEffect(() => {
    if (completedId?.id) {
      const date = new Date();
      onUpdateTopic({ completed_at: completedId?.inComplete ? null : date });
    }
    if (handleTopicId) {
      onUpdateTopic({ assignee: userId });
    }
  }, [completedId, handleTopicId]);

  useEffect(() => {
    if (sendPostTopic) {
      onPostTopic({ name: inputTask.name, project: inputTask.project, section: inputTask.section });
    }
  }, [sendPostTopic]);

  const enterKeyName = (e) => {
    setOnFocusSection(true);
    if (e.key === 'Enter' && e.target.value !== '') {
      dispatch(handleModal({ componentName: 'steptask' }));
    }
  };

  const setFaveProj = (id, isFavorite) => {
    setIsFavProj({ id, isFavorite });
  };

  useEffect(() => {
    if (isFavProj?.id) {
      onUpdateProject({ is_favorite: isFavProj.isFavorite });
    }
  }, [isFavProj?.id]);

  let topicLink;
  if (linkOnTopicId) {
    const linkId = TopicsData?.data?.results.filter((u) => u.id === linkOnTopicId);
    if (linkId) {
      topicLink = linkId[0]?.project?.id + '/board';
    }
  }

  useEffect(() => {
    if (topicLink) {
      navigate(`/task/project/${topicLink}`);
    }
  }, [topicLink]);

  const { mutateAsync: destroyTopics } = useMutation(deleteTopics, {
    onSuccess: () => {
      qClient.invalidateQueries('topics');
    },
  });
  const onDestroyTopic = async (id) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        destroyTopics(id);
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else {
        swal('Your imaginary file is safe!');
      }
    });
  };

  return (
    <>
      <BZHelmet title="Dashboard Task" description="bz publish dashboard task management" />
      <div className="w-full bg-white p-4 border-b shadow-md">
        <div className="justify-center flex bg-white">
          <div className="text-center">
            <div>{moment().format('dddd, MMMM DD')}</div>
            <div className="text-4xl">{`Good ${moment().format('a') === 'am' ? ' Morning' : 'Afternoon'}, ${userName}`}</div>
            {/* <div className="grid grid-cols-3 bg-[#F9F8F8] py-3 mt-4 rounded-3xl gap-x-10 px-4">
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <>
                    <button type="button" {...bindTrigger(popupState)}>
                      <span className="text-sm">My week</span>
                      <KeyboardArrowDown className="w-4" />
                    </button>
                    <StyledMenu className="" {...bindMenu(popupState)}>
                      <MenuItem onClick={popupState.close}>
                        <button type="button" className="w-full flex items-center">
                          {widthPrior === 'w-6/12' && <Check />}
                          <div className={widthPrior !== 'w-6/12' ? 'ml-8' : ''}>My Week</div>
                        </button>
                      </MenuItem>
                      <MenuItem onClick={popupState.close}>
                        <button type="button" className="w-full flex items-center">
                          {widthPrior === 'w-full' && <Check />}
                          <div className={widthPrior !== 'w-full' ? 'ml-8' : ''}>My Month</div>
                        </button>
                      </MenuItem>
                    </StyledMenu>
                  </>
                )}
              </PopupState>
              <div className="">
                <TooltipWrapper arrow tooltip="Completed">
                  <div className="flex items-center">
                    <Check className="" />
                    <div className="text-sm items-center flex gap-x-1">
                      <span className="text-2xl">{complete.length}</span>
                      Task completed
                    </div>
                  </div>
                </TooltipWrapper>
              </div>
              <div className="">
                <div>
                  <TooltipWrapper arrow tooltip="Collaborators">
                    <div className="flex items-center">
                      <PeopleAltOutlined className="pr-1" />
                      <div className="text-sm items-center flex gap-x-1">
                        <span className="text-2xl">0</span>
                        collaborators
                      </div>
                    </div>
                  </TooltipWrapper>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="max-w-[1200px] mx-auto">
          <div className="space-y-8">
            <div className={` gap-4 mt-8 ${widthPrior === 'w-6/12' ? 'flex flex-row' : 'flex flex-col'}`}>
              <Paper elevation={0} className={`${widthPrior} h-[calc(100vh-220px)]  border-[1.5px] rounded-md border-gray-300 hover:border-black group py-4`}>
                <div className="flex items-center px-6 ">
                  {userData?.avatar ? (
                    <img src={userData?.avatar} alt="" className="w-10 h-10 rounded-full" />
                  ) : (
                    <img
                      src="https://d3ki9tyy5l5ruj.cloudfront.net/obj/3d4665c7cf119dc9dc38232301b18fa68b9bb17c/avatar.svg"
                      alt=""
                      className="border border-dashed hover:border-[#A6DFFB] rounded-full"
                    />
                  )}
                  <div className="ml-4 items-center flex gap-x-2 text-xl">
                    <div className="">My Tasks</div>
                    <LockIcon className="w-3 text-[#6D6E6F] " />
                  </div>
                  <div className="ml-auto p-1 border border-gray-400 rounded-xl hidden group-hover:block">
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <>
                          <button type="button" {...bindTrigger(popupState)}>
                            <MoreHorizIcon className="hover:bg-[#F8F7F7] rounded-md" />
                          </button>
                          <StyledMenu className="" {...bindMenu(popupState)}>
                            <MenuItem onClick={popupState.close}>
                              <button onClick={() => setWidthPrior('w-6/12')} type="button" className="w-full flex items-center">
                                {widthPrior === 'w-6/12' && <Check />}
                                <div className={widthPrior !== 'w-6/12' ? 'ml-8' : ''}>Half size</div>
                              </button>
                            </MenuItem>
                            <MenuItem onClick={popupState.close}>
                              <button onClick={() => setWidthPrior('w-full')} type="button" className="w-full flex items-center">
                                {widthPrior === 'w-full' && <Check />}
                                <div className={widthPrior !== 'w-full' ? 'ml-8' : ''}>Full size</div>
                              </button>
                            </MenuItem>
                          </StyledMenu>
                        </>
                      )}
                    </PopupState>
                  </div>
                </div>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <StyledTabs className="bz-tabs ml-[90px] " value={value} onChange={handleChange} aria-label="basic tabs example">
                      <StyledTab className="capitalize" label="Upcoming" {...a11yProps(0)} />
                      <StyledTab className="capitalize" label={`Overdue ${overDue.length > 0 ? overDue.length : ''}`} {...a11yProps(1)} />
                      <StyledTab className="capitalize" label="Completed" {...a11yProps(2)} />
                    </StyledTabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <div className=" mt-4 ">
                      <div className="flex px-6 gap-x-4 mb-2 items-center">
                        <CheckCircleOutline className="text-[#6D6E6F] hover:text-[#84B8A0] hover:bg-[#EAFAF4] rounded-full" />
                        <div className="w-full">
                          <TextField
                            name=""
                            id=""
                            label=""
                            className="w-full outline-none border-none"
                            placeholder="Add Task"
                            onFocus={() => {
                              setOnFocusSection(true);
                            }}
                            onMouseLeave={() => {
                              setOnFocusSection(false);
                            }}
                            // multiline
                            variant="standard"
                            onBlur={(e) => dispatch(taskAdd({ name: e.target.value }))}
                            onKeyPress={enterKeyName}
                          />
                          {onFocusSection && <div className="absolute text-gray-400 bg-white border p-3 z-50">Press enter to add task </div>}
                          <AddTask projectsData={ProjectsData} projectData={ProjectData} />
                        </div>
                      </div>
                      <div className="overflow-y-auto overflow-x-hidden h-[calc(100vh-400px)] px-6 pb-10">
                        {currentTopic.map((up) => (
                          <div key={up.id} className="flex border-y border-gray-300 items-center gap-x-4 relative">
                            <Tooltip title="Mark Task Complete" arrow>
                              <CheckCircleOutline onClick={() => setCompletedId({ id: up.id })} className="text-[#6D6E6F] hover:text-[#84B8A0] hover:bg-[#EAFAF4] rounded-full" />
                            </Tooltip>
                            <div title={up?.project?.name} className="rounded-full">
                              {up?.project?.icon ? (
                                <img src={up?.project?.icon} alt="" className="w-4 h-4 object-cover " />
                              ) : (
                                <div className="w-4 h-4 border  text-lg flex justify-center items-center " style={{ backgroundColor: stringToColor(up?.project?.name) }}>
                                  <span>{formatter(up?.project?.name)}</span>
                                </div>
                              )}
                            </div>
                            <button type="button" onClick={() => setLinkOnTopicId(up.id)} className="flex  py-2 w-full">
                              <div className="text-left">{shortText(up.name, 42)}</div>
                            </button>

                            {up?.due_at ? (
                              <div className="">
                                <button type="button" onClick={() => setToggleDueDate(up.id)} className="flex  py-2 ">
                                  <div className={`absolute right-8 text-xs bg-green-400 px-1 rounded-md ${toggleDueDate === up.id && 'hidden'}`}>
                                    {moment(up.due_at).format('dddd, MMMM DD')}
                                  </div>
                                </button>
                                {toggleDueDate === up.id && (
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      value={up?.due_at}
                                      onChange={(newValue) => {
                                        onUpdateTopic({ due_at: newValue });
                                      }}
                                      renderInput={(params) => <TextField variant="standard" className="" {...params} />}
                                    />
                                  </LocalizationProvider>
                                )}
                              </div>
                            ) : (
                              <div className="">
                                <button title="Add due date" type="button" onClick={() => setToggleDueDate(up.id)} className="flex  py-2 -mr-1">
                                  <div className={` text-xs  rounded-md  ${toggleDueDate === up.id && 'hidden'}`}>
                                    <DateRangeOutlined />
                                  </div>
                                </button>
                                {toggleDueDate === up.id && (
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      // value={!dueDate ? task.due_at : dueDate}
                                      onChange={(newValue) => {
                                        onUpdateTopic({ due_at: newValue });
                                      }}
                                      renderInput={(params) => <TextField variant="standard" className="border-none" {...params} />}
                                    />
                                  </LocalizationProvider>
                                )}
                              </div>
                            )}
                            {/* <button type="button" onClick={() => onDestroyTopic(up.id)} className="flex  py-2 ">
                            <div className="">X</div>
                          </button> */}
                          </div>
                        ))}
                        {/* {upComing.length >= 10 && (
                          <div className="">
                            {currentTopic.length < indexOfLastTopic ? (
                              <button type="button" onClick={() => setTopicPerPage(topicPerPage - 10)} className="mt-4 text-gray-500">
                                Show less
                              </button>
                            ) : (
                              <button type="button" onClick={() => setTopicPerPage(topicPerPage + 10)} className="mt-4 text-gray-500">
                                Show more
                              </button>
                            )}
                          </div>
                        )} */}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div className={`flex ${overDue.length > 0 ? 'items-start' : 'items-center'} h-[290px] justify-center`}>
                      {overDue.length > 0 ? (
                        <div className="overflow-y-auto h-[calc(100vh-400px)] px-6 w-full mt-4">
                          {overDue.map((over) => (
                            <div key={over.id} className="flex border-y border-gray-300 items-center gap-x-4 relative">
                              <Tooltip title="Mark Task Complete" arrow>
                                <CheckCircleOutline
                                  onClick={() => setCompletedId({ id: over.id })}
                                  className="text-[#6D6E6F] hover:text-[#84B8A0] hover:bg-[#EAFAF4] rounded-full"
                                />
                              </Tooltip>
                              <button type="button" onClick={() => setLinkOnTopicId(over.id)} className="flex  py-2 w-full">
                                <div className="">{shortText(over.name, 42)}</div>
                              </button>

                              <div className="">
                                <button type="button" onClick={() => setToggleDueDate(over.id)} className="flex  py-2 ">
                                  <div className={`absolute right-8 text-xs bg-red-400 px-1 rounded-md ${toggleDueDate === over.id && 'hidden'}`}>
                                    {moment(over.due_at).format('dddd, MMMM DD')}
                                  </div>
                                </button>
                                {toggleDueDate === over.id && (
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      value={over?.due_at}
                                      onChange={(newValue) => {
                                        onUpdateTopic({ due_at: newValue });
                                      }}
                                      renderInput={(params) => <TextField variant="standard" className="" {...params} />}
                                    />
                                  </LocalizationProvider>
                                )}
                              </div>
                              <button type="button" onClick={() => onDestroyTopic(over.id)} className="flex  py-2 ">
                                <div className="">X</div>
                              </button>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="space-y-8">
                          <img
                            src="https://d3ki9tyy5l5ruj.cloudfront.net/obj/7efbfa8b351b071fff8d85a23b9901e8fb1c84c9/large-checkmark-with-three-colorful-bubbles.svg"
                            alt=""
                            className="w-[192px]"
                          />
                          <div className="text-[#6D6E6F]">You don&apos;t have any task here</div>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <div className="relative">
                      <div className={`flex ${complete.length > 0 ? 'items-start' : 'items-center'} h-[290px] justify-center`}>
                        {complete.length > 0 ? (
                          <div className="overflow-y-auto h-[calc(100vh-400px)] px-6 w-full mt-4">
                            {complete.map((comp) => (
                              <div key={comp.id} className="flex border-y border-gray-300 items-center gap-x-4">
                                <Tooltip title="Mark Task Incomplete" arrow>
                                  <CheckCircleOutline
                                    onClick={() => setCompletedId({ id: comp.id, inComplete: true })}
                                    className=" text-[#84B8A0] bg-[#EAFAF4] hover:text-black hover:bg-white rounded-full"
                                  />
                                </Tooltip>
                                <button type="button" onClick={() => setLinkOnTopicId(comp.id)} className="flex  py-2 w-full">
                                  <div className="text-left">{shortText(comp.name, 42)}</div>
                                </button>
                                {comp?.due_at ? (
                                  <div className="">
                                    <button type="button" onClick={() => setToggleDueDate(comp.id)} className="flex  py-2 ">
                                      <div className={`  text-xs bg-green-400 px-1 rounded-md ${toggleDueDate === comp.id && 'hidden'}`}>
                                        {moment(comp.due_at).format('dddd, MMMM DD')}
                                      </div>
                                    </button>
                                    {toggleDueDate === comp.id && (
                                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                          value={comp?.due_at}
                                          onChange={(newValue) => {
                                            onUpdateTopic({ due_at: newValue });
                                          }}
                                          renderInput={(params) => <TextField variant="standard" className="" {...params} />}
                                        />
                                      </LocalizationProvider>
                                    )}
                                  </div>
                                ) : (
                                  <div className="">
                                    <button type="button" onClick={() => setToggleDueDate(comp.id)} className="flex  py-2 -mr-1">
                                      <div title="Add due date" className={` text-xs rounded-md  ${toggleDueDate === comp.id && 'hidden'}`}>
                                        <DateRangeOutlined />
                                      </div>
                                    </button>
                                    {toggleDueDate === comp.id && (
                                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                          // value={!dueDate ? task.due_at : dueDate}
                                          onChange={(newValue) => {
                                            onUpdateTopic({ due_at: newValue });
                                          }}
                                          renderInput={(params) => <TextField variant="standard" className="border-none" {...params} />}
                                        />
                                      </LocalizationProvider>
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="space-y-8 max-w-sm ">
                            <img
                              src="https://d3ki9tyy5l5ruj.cloudfront.net/obj/50544816a11896f9695930a69c8899a7eda8a7ff/two-completed-tasks.svg"
                              alt=""
                              className="w-[192px] mx-auto"
                            />
                            <div className="text-[#6D6E6F] text-center">Your completed tasks will appear here, so you can reference them later.</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                </Box>
              </Paper>
              <Paper elevation={0} className={` ${widthProject} h-[calc(100vh-220px)] relative border-[1.5px] rounded-md border-gray-300 hover:border-black group py-4`}>
                <div className="px-8 flex h-[28px]">
                  <div className="mr-6 text-xl">Projects</div>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <>
                        <button type="button" className="h-fit" {...bindTrigger(popupState)}>
                          <span className="text-sm">Recent</span>
                          <KeyboardArrowDown className="w-4" />
                        </button>
                        <StyledMenu className="" {...bindMenu(popupState)}>
                          <MenuItem onClick={popupState.close}>
                            <button onClick={() => setIsFav(false)} type="button" className="w-full flex items-center">
                              {!isFav ? <Check /> : null}
                              <div className={!isFav ? '' : 'ml-8'}>Last Seen</div>
                            </button>
                          </MenuItem>
                          <MenuItem onClick={popupState.close}>
                            <button onClick={() => setIsFav(true)} type="button" className="w-full flex items-center">
                              {isFav ? <Check /> : null}
                              <div className={isFav ? '' : 'ml-8'}>Favorite</div>
                            </button>
                          </MenuItem>
                        </StyledMenu>
                      </>
                    )}
                  </PopupState>
                  <div className="absolute right-4 p-1 border border-gray-400 rounded-xl hidden active:block group-hover:block">
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <>
                          <button type="button" {...bindTrigger(popupState)}>
                            <MoreHorizIcon className="hover:bg-[#F8F7F7] rounded-md" />
                          </button>
                          <StyledMenu className="" {...bindMenu(popupState)}>
                            <MenuItem onClick={popupState.close}>
                              <button onClick={() => setWidthProject('w-6/12')} type="button" className="w-full flex items-center">
                                {widthProject === 'w-6/12' && <Check />}
                                <div className={widthProject !== 'w-6/12' ? 'ml-8' : ''}>Half size</div>
                              </button>
                            </MenuItem>
                            <MenuItem onClick={popupState.close}>
                              <button onClick={() => setWidthProject('w-full')} type="button" className="w-full flex items-center">
                                {widthProject === 'w-full' && <Check />}
                                <div className={widthProject !== 'w-full' ? 'ml-8' : ''}>Full size</div>
                              </button>
                            </MenuItem>
                          </StyledMenu>
                        </>
                      )}
                    </PopupState>
                  </div>
                </div>
                <div className="px-8 pt-4 h-[calc(100vh-300px)] overflow-y-auto">
                  <Link to="/task/project/new/blank">
                    <div className="flex gap-x-4 items-center">
                      <div className="border border-dashed w-fit p-3 rounded-md">
                        <AddOutlined />
                      </div>
                      <div className="text-gray-500">Create Project</div>
                    </div>
                  </Link>
                  {cekFav?.map((pro) => (
                    <div key={pro.id} className="relative group">
                      <div className="absolute right-4 p-1  border-gray-400 rounded-xl ">
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <>
                              <button type="button" {...bindTrigger(popupState)}>
                                <MoreHorizIcon className="hover:bg-[#F8F7F7] rounded-md" />
                              </button>
                              <StyledMenu className="" {...bindMenu(popupState)}>
                                {!pro.is_favorite && (
                                  <MenuItem onClick={popupState.close}>
                                    <button onClick={() => setFaveProj(pro.id, true)} type="button" className="w-full flex items-center">
                                      <StarOutlineRounded />
                                      <div className="">Add to favorites</div>
                                    </button>
                                  </MenuItem>
                                )}
                                <MenuItem onClick={popupState.close}>
                                  <button
                                    onClick={() => dispatch(handleModal({ modalId: pro.id, componentName: 'editProjectHome' }))}
                                    type="button"
                                    className="w-full flex items-center justify-start"
                                  >
                                    <ModeEditOutlineOutlined />
                                    <div className="">Edit Project</div>
                                  </button>
                                </MenuItem>
                                <MenuItem onClick={popupState.close}>
                                  <button onClick={() => handleCopyProjectLink(pro.id)} type="button" className="w-full flex items-center">
                                    <InsertLinkRounded />
                                    <div className="">Copy Link Project</div>
                                  </button>
                                </MenuItem>
                              </StyledMenu>
                            </>
                          )}
                        </PopupState>
                      </div>
                      {pro.is_favorite && (
                        <div className="absolute -left-3 -top-3">
                          <Tooltip title="Remove Favorite" arrow>
                            <button type="button" onClick={() => setFaveProj(pro.id, false)}>
                              <StarOutlineRounded className="text-blue-400  " />
                            </button>
                          </Tooltip>
                        </div>
                      )}
                      <Link to={`/task/project/${pro.id}/board`}>
                        <div className="flex gap-x-4 items-center mt-4 hover:bg-[#F9F8F8]">
                          <div className="border border-dashed w-fit p-3 rounded-md">
                            {pro.icon ? (
                              <img src={pro.icon} alt="" className="w-8 h-8 object-cover" />
                            ) : (
                              <div className="w-8 h-8 border rounded-full text-lg flex justify-center items-center " style={{ backgroundColor: stringToColor(pro.name) }}>
                                <span>{formatter(pro.name)}</span>
                              </div>
                            )}
                          </div>
                          <div className="space-y-1">
                            <TruncateMarkup lines={3}>
                              <div className="capitalize pr-12">{pro.name}</div>
                            </TruncateMarkup>
                            <div className="text-gray-500 capitalize text-sm">{pro.notes}</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </Paper>
            </div>
            {/* <div className={` ${widthPeople} h-[calc(100vh-220px)] relative border rounded-md border-gray-300 hover:border-black group py-4 group`}>
              {' '}
              <div className="px-8 flex">
                <div className="mr-6 text-xl">People</div>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <button type="button" className="h-fit" {...bindTrigger(popupState)}>
                        <span className="text-sm">Recent</span>
                        <KeyboardArrowDown className="w-4" />
                      </button>
                      <StyledMenu className="" {...bindMenu(popupState)}>
                        <MenuItem onClick={popupState.close}>
                          <button type="button" className="w-full flex items-center">
                            {widthPrior === 'w-6/12' && <Check />}
                            <div className={widthPrior !== 'w-6/12' ? 'ml-8' : ''}>My Week</div>
                          </button>
                        </MenuItem>
                        <MenuItem onClick={popupState.close}>
                          <button type="button" className="w-full flex items-center">
                            {widthPrior === 'w-full' && <Check />}
                            <div className={widthPrior !== 'w-full' ? 'ml-8' : ''}>My Month</div>
                          </button>
                        </MenuItem>
                      </StyledMenu>
                    </>
                  )}
                </PopupState>
                <div className="absolute right-4 p-1 border border-gray-400 rounded-xl hidden group-hover:block">
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <>
                        <button type="button" className="h-fit" {...bindTrigger(popupState)}>
                          <MoreHorizIcon className="hover:bg-[#F8F7F7] rounded-md" />
                        </button>
                        <StyledMenu className="" {...bindMenu(popupState)}>
                          <MenuItem onClick={popupState.close}>
                            <button onClick={() => setWidthPeople('w-6/12')} type="button" className="w-full flex items-center">
                              {widthPeople === 'w-6/12' && <Check />}
                              <div className={widthPeople !== 'w-6/12' ? 'ml-8' : ''}>Half size</div>
                            </button>
                          </MenuItem>
                          <MenuItem onClick={popupState.close}>
                            <button onClick={() => setWidthPeople('w-full')} type="button" className="w-full flex items-center">
                              {widthPeople === 'w-full' && <Check />}
                              <div className={widthPeople !== 'w-full' ? 'ml-8' : ''}>Full size</div>
                            </button>
                          </MenuItem>
                        </StyledMenu>
                      </>
                    )}
                  </PopupState>
                </div>
              </div>
              <div className="h-full flex items-center px-8">
                <Swiper spaceBetween={2} slidesPerView={4}>
                  {user?.users?.results?.map((u) => (
                    <SwiperSlide key={u.id}>
                      <div className="rounded-lg border py-[48px] px-[16px] h-[280px] w-[220px] flex justify-center items-center">
                        <div className="">
                          <img
                            src="https://bzpublishassets.blob.core.windows.net/media/user_avatars/2015-cony_1y8CEyI.png"
                            alt=""
                            className="w-16 h-16 rounded-full mx-auto mb-4"
                          />
                          <div className="text-center capitalize">{u.name}</div>
                          <div className="w-[140px] text-center text-sm text-gray-500">Assign a task to start collaborating</div>
                          <div className="flex items-center">
                            <button
                              type="button"
                              onClick={() => dispatch(taskAdd({ assignee: u.id }))}
                              className="text-center text-sm text-gray-500 border rounded-md w-fit px-2 mx-auto mt-4"
                            >
                              Assign task
                            </button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div> */}
          </div>
        </div>
        {/* {inputTask.assignee && (
          <div className="w-[600px] h-[600px] bottom-0 right-10 border border-gray-400 rounded-t-lg z-50 fixed bg-white  ">
            <AssignTaskToPeople projectsData={ProjectsData} projectData={ProjectData} />{' '}
          </div>
        )} */}
        <ModalWrapper componentName="editProjectHome" header="Edit Project">
          <ProjectForm onFormSubmit={onUpdateProject} defaultValues={ProjectData?.data} key={ProjectData?.data?.id} />
        </ModalWrapper>
      </div>
    </>
  );
}

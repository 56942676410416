import { createSlice } from '@reduxjs/toolkit';

const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    filter: { from: null, to: null, search: '', accperFrom: '', accperTo: '' },
  },
  reducers: {
    setFilter(state, action) {
      const currState = state;
      if (action.payload.type !== 'reset') {
        currState.filter[action.payload.type] = action.payload.value;
      } else {
        currState.filter = { from: null, to: null, search: '', accperFrom: '', accperTo: '' };
      }
    },
  },
});

export const transactionActions = transactionSlice.actions;
export default transactionSlice;

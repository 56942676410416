/* eslint-disable */

import React, { useState } from 'react';
import { Button, InputBase, Tooltip, CardActionArea, CardActions, Card, CardContent, Typography, Pagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Sync, Edit, ArrowBack, Search } from '@mui/icons-material';
import { fetchSectionList, addSectionBulk, fetchSectionCatalogue, fetchSectionListAll, sectionCatBulkDelete, sectionDistribute } from '../../hooks/useSettingsBzData';
import ModalWrapper from '../../components/ui/ModalWrapper';
import { handleModal } from '../../store/slices/modalSlice';
import SectionCatalogueForm from '../../components/Form/SettingBz/SectionCatalogueForm';
import request from '../../helper/axiosUtil';
import BaseBackButton from '../../components/Button/BaseBackButton';
import BaseMainTitle from '../../components/BaseMainTitle';
import BZHelmet from '../../utils/BZHelmet';

const MySwal = withReactContent(Swal);

export default function SectionCatalogue() {
  const [page, setPage] = useState(1);
  const [sectionListData, setSectionListData] = useState(null);
  const [bulkSectionDel, setBulkSectionDel] = useState([]);

  const [countSection, setCountSection] = useState(0);
  const [files, setFiles] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  const [expandImage, setExpandImage] = useState({ id: null, open: false });
  const dispatch = useDispatch();

  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const qClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleExpandImage = (id) => {
    setExpandImage({ id, open: !expandImage.open });
    if (expandImage.id === id && expandImage.open === false) {
      setExpandImage({ id: null, open: false });
    } else setExpandImage({ id, open: false });
  };

  const handleChange = (e, p) => {
    setPage(p);
  };

  const uploadFileHandler = (event) => {
    setFiles(event.target.files);
  };

  const handleSectionDel = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(bulkSectionDel);
      newSelection.push(parseInt(value));
      setBulkSectionDel(newSelection);
    } else {
      const newSelection = bulkSectionDel;
      const newSubs = newSelection.filter((sub) => parseInt(sub) !== parseInt(value));
      setBulkSectionDel(newSubs);
    }
  };

  const count = Math.ceil(countSection / 9);

  const { data: SectionsData } = useQuery(['sections', { clientId, page }], fetchSectionList, {
    onSuccess: (e) => {
      setSectionListData(e.data.results);
      setCountSection(e.data.count);
    },
  });

  const { data: SectionsDataAll } = useQuery(['sectionsAll', { clientId }], fetchSectionListAll);

  let isAutoFetching;
  const sectionId = modal.modalId;
  if (modal.componentName === 'editSectionCatalogue') {
    isAutoFetching = true;
  } else isAutoFetching = false;
  const { data: SectionData } = useQuery(['section', { sectionId }], fetchSectionCatalogue, { enabled: isAutoFetching });

  const { mutateAsync: postPage } = useMutation(addSectionBulk, {
    onMutate: async (newData) => {
      dispatch(handleModal({ id: '' }));

      const dataForm = [...files];
      const mapData = dataForm?.map((form) => form);
      const result = mapData.map((item, index) => {
        return `<li key={${index}}>${item.name}</li>`;
      });

      MySwal.fire({
        title: 'Upload Section',
        html: `<ul>${result.join('<br/>')}</ul>`,
        buttons: false,
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
    },
    onSuccess: (e) => {
      MySwal.close();
      const result = [];
      e?.data?.map((li) => {
        return result.push(`<li ${li?.status === 200 ? "style='color:blue'" : "style='color:red'"}>" ${li.message} "</li>`);
      });

      MySwal.fire({
        title: 'Upload Section',
        html: `<ul>${result.join('<br/>')}</ul>`,
      });

      qClient.invalidateQueries('sections');
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onPostPage = (data) => {
    const formData = new FormData();
    formData.append('client', clientId);
    formData.append('catalog_type', 'section');
    [...files].forEach((file) => formData.append('zip_files', file, file.name));

    postPage(formData);
  };

  const { mutateAsync: distSection } = useMutation(sectionDistribute, {
    onSuccess: (e) => {
      qClient.invalidateQueries('assets');
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onDistSection = (id) => {
    distSection(id);
  };

  const { mutateAsync: sectionCatDelete } = useMutation(sectionCatBulkDelete, {
    onSuccess: (e) => {
      qClient.invalidateQueries('sections');
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      dispatch(handleModal({ id: '' }));
    },
  });

  const onSectionCatDelete = async () => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        sectionCatDelete({ catalogs_ids: bulkSectionDel });
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else {
        swal('Your imaginary file is safe!');
      }
    });
  };

  const updateCatalogs = async (data) => request({ url: `/section_catalogs/${sectionId}/`, method: 'patch', data });

  const { mutateAsync: updateCatalog } = useMutation(updateCatalogs, {
    onSuccess: (e) => {
      qClient.invalidateQueries('section');
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      dispatch(handleModal({ id: '' }));
    },
  });

  const onUpdateCatalog = async (data) => {
    await updateCatalog(data);
  };

  const onSearchSection = (event) => {
    // setPage(0);
    setIsSearch(true);
    const val = event.target.value.toLowerCase();
    if (val) {
      const sectionFilter = SectionsDataAll?.data?.results.filter((t) => {
        if (t.folder_name.toLowerCase().includes(val) || t.name.toLowerCase().includes(val)) {
          return t;
        }
        return false;
      });
      if (sectionFilter.length) {
        setSectionListData(sectionFilter.slice(0));
      } else {
        setSectionListData([]);
        setIsSearch(false);
      }
    } else {
      setSectionListData(SectionsData?.data?.results);
      setIsSearch(false);
    }
  };

  return (
    <div>
      <BZHelmet title="Section Catalogue" description="bz publish section catalogue" />
      <BaseMainTitle title="Section Catalogue" />
      <div className="p-5">
        <div className=" bg-white border p-5 shadow rounded">
          <div className="bg-gray-100 w-full py-5 px-3 rounded flex items-center">
            <BaseBackButton />
            <div className="flex ml-auto gap-x-3">
              <button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addSectionBulk' }))} className="bz-btn" variant="filled">
                Add Multiple Section
              </button>
              <button onClick={() => onSectionCatDelete()} className="bz-btn red" type="button" variant="filled">
                Delete Selected
              </button>

              <ModalWrapper componentName="addSectionBulk" header="Add Multiple Section" maxWidth="sm">
                <div className="flex justify-between">
                  <input type="file" multiple onChange={uploadFileHandler} />
                  <Button
                    onClick={() => onPostPage({ zip_files: files })}
                    className={`${files.length === 0 ? 'bg-gray-200' : 'bg-bz-green'} 'text-white '`}
                    type="button"
                    variant="filled"
                    disabled={files.length === 0}
                  >
                    Submit
                  </Button>
                </div>
              </ModalWrapper>
            </div>
          </div>
          <div className="my-3 flex gap-x-3 bg-white w-fit">
            <InputBase size="small" placeholder="search" endAdornment={<Search color="disabled" />} onChange={onSearchSection} className="border border-gray-300 rounded px-4" />
          </div>
          <div className="grid grid-cols-3 gap-5">
            {sectionListData?.map((s) => (
              <div key={s?.id} className="w-full">
                <Card className="w-full relative">
                  <div className="">
                    <div className="flex p-4 items-center">
                      <input type="checkbox" name="lang" value={s.id} onChange={handleSectionDel} className="w-5 h-5 mr-2" />
                      <div className="">{s.name}</div>
                      <div className="flex items-center ml-auto">
                        <button
                          title="Update this item"
                          className="bz-btn min-w-0 mx-1 w-6 h-6 p-0 rounded-md"
                          size="small"
                          onClick={() => dispatch(handleModal({ modalId: s.id, componentName: 'editSectionCatalogue' }))}
                        >
                          <Edit fontSize="small" />
                        </button>
                        <Button onClick={() => onDistSection(s.id)} title="Distribute" className="bz-btn min-w-0 mx-1 w-6 h-6 p-0 rounded-md" size="small" type="button">
                          <Sync />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <CardActions className="ml-8 gap-x-3 flex items-center">
                    <Typography gutterBottom className="" component="div">
                      {/* {sType(s?.s_type)} */}
                    </Typography>
                  </CardActions>
                  <CardActionArea>
                    <CardContent>
                      <div className="w-full p-3">
                        <Tooltip title={expandImage.id === s.id ? 'Collapse Image' : 'Expand Image'}>
                          <img
                            onClick={() => handleExpandImage(s.id)}
                            src={`${s.screenshot ?? 'https://admin.bzpublish.com/img/bz-publish.svg'}`}
                            alt=""
                            className={`${expandImage.id === s.id ? 'h-full' : 'h-10'}  w-full object-cover`}
                          />
                        </Tooltip>
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            ))}
          </div>
          {!isSearch && (
            <div className="flex items-center gap-x-3 mt-6">
              <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChange} />
              <div className="">{`Total ${countSection}`}</div>
            </div>
          )}
        </div>
        <ModalWrapper componentName="editSectionCatalogue" header="Edit Section" maxWidth="sm">
          <SectionCatalogueForm defaultValues={SectionData?.data} key={SectionData?.data?.id} onFormSubmit={onUpdateCatalog} />
        </ModalWrapper>
      </div>
    </div>
  );
}

/* eslint-disable */

import React, { useEffect } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import HRStructureForm from '../../../components/Form/HR/HRStructureForm';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import request from '../../../helper/axiosUtil';
import { fetchHRStructureDetail, fetchHRStructures, useAddHRStructure, useDestroyHRStructure } from '../../../hooks/useHRStructuresData';
import { handleModal } from '../../../store/slices/modalSlice';
import BaseMainTitle from '../../../components/BaseMainTitle';

export default function Structure() {
  const navigate = useNavigate();
  // const isLogin = useSelector((state) => state.authReducer);
  // useEffect(() => {
  //   if (!isLogin.adminToken) {
  //     navigate('/hr/login');
  //   }
  // }, [isLogin.adminToken]);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const hrStructureId = useSelector((state) => state.modal.modalId);
  const { data: Structures } = useQuery(['HRStructures'], fetchHRStructures);

  const { mutate: addStructure } = useAddHRStructure();

  const onPostStructure = async (data) => {
    await addStructure(data);
  };

  let isAutoFetching;
  if (hrStructureId) {
    isAutoFetching = true;
  } else isAutoFetching = false;

  const { data: StructureDetail } = useQuery(['HRStructure', { hrStructureId }], fetchHRStructureDetail, { enabled: isAutoFetching });

  const updateStructures = async (data) => request({ url: `employee-management/structures/${hrStructureId}/`, method: 'patch', data });

  const { mutateAsync: updateStructure } = useMutation(updateStructures, {
    onSuccess: (e) => {
      // Invalidates cache and refetch
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      queryClient.invalidateQueries('HRStructures');
    },
    onError: () => {
      queryClient.invalidateQueries('HRStructures');
    },
    onSettled: () => {
      queryClient.invalidateQueries('HRStructures');
      dispatch(handleModal({ modalId: '' }));
    },
    // onSuccess
    /** Optimistic Update End */
  });

  const onUpdateStructure = async (data) => {
    await updateStructure(data);
  };

  const removeStructure = useDestroyHRStructure();

  return (
    <div>
      <BaseMainTitle title={`Structure`} />
      <div className="p-5">
        <div className=" bg-white border p-5 shadow rounded">
          <div className="bg-gray-100 w-full py-5 px-3 my-5 rounded">
            <div className="justify-end flex gap-x-3">
              {/* <button type="button" className="ml-auto flex bg-red-600 text-white p-2 rounded">
              Delete Selected Structure
            </button> */}
              <button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addStructure' }))} type="button" className="bg-blue-600 text-white p-2 rounded">
                Add Structure
              </button>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-3">
            {Structures?.data?.results?.map((str) => (
              <div key={str.id} className="bg-white border shadow rounded relative">
                {/* <input type="checkbox" name="lang" value={str.id} onChange={handleChange} className="absolute top-3 left-3 w-5 h-5" /> */}
                <div className="absolute top-2 right-3 flex">
                  <button type="button" className=" w-6 h-6 p-0 " onClick={() => dispatch(handleModal({ modalId: str.id, componentName: 'editStructure' }))}>
                    <Edit fontSize="small" className="text-white" />
                  </button>
                  <button type="button" className=" w-6 h-6 p-0 " onClick={() => removeStructure(str.id)}>
                    <Delete fontSize="small" className="text-white" />
                  </button>
                </div>
                <Link to={`/hr/structure/detail/${str.id}`}>
                  <div className="bg-blue-600 w-full text-white p-2 ">
                    <p className="ml-8">{str?.name}</p>
                  </div>
                  <div className="w-full h-12" />
                </Link>
              </div>
            ))}
          </div>
        </div>
        <ModalWrapper componentName="addStructure" header="Add Structure" maxWidth="sm">
          <HRStructureForm onFormSubmit={onPostStructure} />
        </ModalWrapper>
        <ModalWrapper componentName="editStructure" header="Add Structure" maxWidth="sm">
          <HRStructureForm onFormSubmit={onUpdateStructure} defaultValues={StructureDetail?.data} key={StructureDetail?.data?.id} />
        </ModalWrapper>
      </div>
    </div>
  );
}

/* eslint-disable */

import { FolderOutlined } from '@mui/icons-material';
import moment from 'moment';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BaseBackButton from '../../../components/Button/BaseBackButton';
import { fetchWhistleblowerDetail } from '../../../hooks/useWhistleblowerData';
import BZHelmet from '../../../utils/BZHelmet';

export default function ReportWhistleDetail() {
  const { reportId } = useParams();
  const { data: ReportDetail, isSuccess } = useQuery(['whistleblower', { reportId }], fetchWhistleblowerDetail);
  return (
    <>
      <BZHelmet title="Whistleblower Detail" description="" content="" />
      <div>
        <BaseMainTitle title="Whistleblower Detail" />
        <div className="p-5 space-y-5">
          <BaseBackButton />
          <div className=" space-y-3 w-full bg-white border p-5 shadow rounded">
            <div className="border-b-4 ">
              <div className="pb-4 ">
                <div className="font-bold">Reporter Information </div>
                <div className="font-bold">{`Name : ${ReportDetail?.data?.whistleblower_name}`}</div>
                <div className="font-bold">{`Organization : ${ReportDetail?.data?.whistleblower_organization}`}</div>
                <div className="font-bold">{`Contact : ${ReportDetail?.data?.whistleblower_contact}`}</div>
              </div>
            </div>
            <div className="font-bold">
              Allegation : <span className="font-normal">{ReportDetail?.data?.allegation}</span>{' '}
            </div>
            <div className="font-bold">
              Reported Department : <span className="font-normal">{ReportDetail?.data?.reported_department?.name}</span>
            </div>
            <div className="font-bold">
              Reported Business Unit : <span className="font-normal">{ReportDetail?.data?.reported_department?.business_unit?.name}</span>
            </div>
            <div className="font-bold">Reported Employees :</div>
            <div className="">
              <table id="customers" className="table-auto w-full">
                <thead>
                  <tr className="">
                    <th className="border-2 border-white px-4 py-2 text-white bg-[#18467f] font-medium text-left">Name</th>
                  </tr>
                </thead>
                <tbody className="border-2 border-white px-4 py-2  font-medium text-left">
                  {ReportDetail?.data?.reported_names?.map((emp, i) => (
                    <tr key={emp} className="border-2 border-white px-4 py-2 bg-[#F2F5F6] font-medium text-left ">
                      <td className={`  border-2 border-white px-4 py-2 bg-[#F2F5F6] font-medium text-left  `}>{emp}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="">
              <div className="font-bold">Supporting Documents :</div>
              <div className="flex flex-wrap space-4"></div>
              {ReportDetail?.data?.supporting_documents?.map((doc, i) => (
                <div key={doc.id} className="w-[150px] h-[150px]">
                  <a href={doc?.document} target="_blank" rel="noopener noreferrer" className="w-fit">
                    <div className={`relative w-full h-full`}>
                      <FolderOutlined className="w-[150px] h-[150px] absolute text-gray-400" />
                      <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 uppercase font-bold">
                        {doc?.document.substring(doc?.document.lastIndexOf('.') + 1)}
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
            <div className="font-bold">Reported Date : {moment(ReportDetail?.data.timestamp).format('dddd, MMMM DD')}</div>
          </div>
        </div>
      </div>
    </>
  );
}

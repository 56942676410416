import React, { ReactElement, useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import Select, {
  ClearIndicatorProps,
  GroupBase,
  OptionsOrGroups,
  SingleValue,
} from 'react-select';

import clsxm from '@/utils/clsxm';
import BaseHelper from '../Base/BaseHelper';
import BaseLabel from '../Base/BaseLabel';
import { SelectedItemType } from '@/types';

type ReactSelectSingleNonRHFProps = {
  options: OptionsOrGroups<
    { name: string; id: string },
    GroupBase<{ name: string; id: string }>
  >;
  defaultValue?: SingleValue<{ name: string; id: string }>;
  getOptionLabel?: (option: { name: string; id: string }) => string;
  getOptionValue?: (option: { name: string; id: string }) => string;
  isSearchable?: boolean;
  errors?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  floatLabel?: boolean;
  label?: string;
  helperText?: string;
  onChange: (selectedItem: SelectedItemType) => void;
};

const CustomClear: React.FC<
  ClearIndicatorProps<{ name: string; id: string }>
> = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { clearValue } = props;
  return (
    <button
      type="button"
      onClick={clearValue}
      className="cursor-pointer"
      aria-label="Clear selection"
    >
      <IoMdClose className="h-[20px] w-[20px] text-[#6d6e6f] hover:text-black" />
    </button>
  );
};

const ReactSelectSingleNonRHF = ({
  options,
  defaultValue,
  getOptionLabel = (option) => option.name,
  getOptionValue = (option) => option.id,
  isSearchable = true,
  errors,
  isClearable = false,
  isDisabled = false,
  floatLabel = false,
  label,
  helperText,
  onChange,
}: ReactSelectSingleNonRHFProps): ReactElement => {
  const componentsConfig = {
    ClearIndicator: CustomClear,
    IndicatorSeparator: () => null,
  };

  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <div className="w-full">
      {label && (
        <BaseLabel
          value={label}
          className={clsxm(errors ? 'text-[#D02B20]' : '')}
        />
      )}

      <Select
        isMulti={false}
        classNamePrefix={errors ? 'react-select-error' : 'react-select'}
        value={selected || null}
        menuPlacement="auto"
        hideSelectedOptions={false}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={isDisabled}
        onChange={(newValue: unknown) => {
          onChange(newValue as SelectedItemType);
          setSelected(newValue as SelectedItemType);
        }}
        components={componentsConfig}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder="Select or enter a value"
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: '4px',
            padding: '9px 16px',
            minHeight: 'unset',
          }),
          container: (provided) => ({
            ...provided,
            // zIndex: 1000,
          }),
          valueContainer: (provided) => ({
            ...provided,
            overflow: 'visible',

            padding: 0,
            fontWeight: 400,
            fontSize: '0.875rem',
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            transition: 'top 0.1s, font-size 0.1s',
            margin: 0,
            fontWeight: 400,
            fontSize: '0.875rem',

            background: 'transparent',

            color:
              floatLabel || (!state.hasValue && !state.selectProps.inputValue)
                ? `rgba(0, 0, 0, 0.42)`
                : 'transparent',
          }),
          singleValue: (provided) => ({
            ...provided,

            fontWeight: 400,
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            padding: 0,
          }),
          input: (provided) => ({
            ...provided,

            fontSize: '0.875rem',
            fontWeight: 400,
            padding: 0,
            margin: 0,
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 1000,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: '21px',
          }),
        }}
      />

      <BaseHelper
        helperText={errors || helperText || ''}
        className={clsxm(errors ? 'text-[#D02B20]' : '')}
      />
    </div>
  );
};

ReactSelectSingleNonRHF.defaultProps = {
  defaultValue: null, // Or your preferred default value
  getOptionLabel: undefined, // It's actually set in destructuring assignment
  getOptionValue: undefined, // It's actually set in destructuring assignment
  isSearchable: true,
  isClearable: false,
  isDisabled: false,
  floatLabel: false,
  label: '',
  helperText: '',
  errors: '',
};

export default ReactSelectSingleNonRHF;

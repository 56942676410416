/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import swal from 'sweetalert';
import InputContainerJP from '../../Input/InputContainerJP';
import CustomRadioGroup from '../../Input/CustomRadioGroup';
import SelectAutocomplete from '../../Input/SelectAutocomplete';
import applicationApi from '../../../api/job/application';
import { handleModal } from '../../../store/slices/modalSlice';
import application from '../../../api/job/application';
import Select from '../../Input/SelectItem';

function InvitationForm({location, reload}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const dispatch = useDispatch();
  const currentData = useSelector((state) => state.modal.modalData)

  const onSubmit = (data) => {
    const { postInterviewData } = applicationApi();

    try {
      const newInterviewData = {
        ...currentData,
        ...data
      }

      postInterviewData(newInterviewData)
      .then((res) => {
        if (res.status === 201 || res.data) {
          dispatch(handleModal({ modalId: '', componentName: '' }));
          swal('Interview Schedule Successfully Sent', { icon: 'success' });
          return reload(true);
        }
      })
      .catch((err) => {
        console.error('error', err);
      });
    } catch (error) {
      console.error('error', error);
    }
  }

  return (
    <form className='space-y-4' onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-2">
        <div className="font-bold">Location :</div>
        <input type="text" value={location} disabled className="w-full py-[8.5px] px-[14px] border rounded-md" />
      </div>
      <div className="space-y-2">
        <div className="font-bold">Time :</div>
        <input type="text" className="w-full py-[8.5px] px-[14px] border rounded-md" name='date_time' {...register(`date_time`)} />
      </div>
      <div className="space-y-2">
        <div className="font-bold">Interviewer :</div>
        <input type="text" className="w-full py-[8.5px] px-[14px] border rounded-md" name='interviewer' {...register(`interviewer`)} />
      </div>
      <div className="space-y-2">
        <div className="font-bold">Message :</div>
        <textarea
          id="message"
          name='message' 
          {...register(`message`)}
          rows="4"
          className='block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
        />
      </div>
      <Button className="flex mt-7 ml-auto bg-blue-600" type="submit" name="submit contact" variant="contained">
        Send
      </Button>
    </form>
  );
}

export default InvitationForm;

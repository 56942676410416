import React, { useContext, useRef, useState } from 'react';
import { Box, Button, ClickAwayListener, Fade, OutlinedInput, Popover, Tooltip } from '@mui/material';
import { Add, ArrowForwardIos, ArrowForward, ArrowBack, Delete, MoreHoriz, Segment, LowPriority, SwapVert } from '@mui/icons-material';
import { Draggable } from '../../../../utils/Task/DragDrop';

import TaskContext from '../../../../pages/Task/Boards/BoardsContext';
import { ContainerTaskVertical } from '../ContainerTaskVertical/ContainerTaskVertical';

import projectApi from '../../../../api/task/project';
import styles from './CardTask.module.css';

export function CardTasks({ id, text, indexTopic, topics, onSortHigh, onSortLow }) {
  const taskContext = useContext(TaskContext);
  const [anchorAction, setAnchorAction] = useState(false);
  const [anchorSort, setAnchorSort] = useState(false);
  const [anchorSection, setAnchorSection] = useState(false);
  const [isTitle, seIsTitle] = useState(false);
  const [isShortPrior, setIsShortPrior] = useState(false);

  const sectionTitle = useRef(null);

  const handlerSectionTitleClick = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    const titleTopic = sectionTitle.current;
    if (titleTopic) {
      const { updateProjectSection } = projectApi();

      updateProjectSection(id, {
        id,
        name: titleTopic,
      })
        .then((res) => {
          if (res.status === 200) {
            taskContext.getProject();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
      sectionTitle.current = null;
    }
    seIsTitle(!isTitle);
  };

  const handleClickAction = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorAction(null);
  };

  const openAction = Boolean(anchorAction);
  const idAction = openAction ? 'simple-popover' : undefined;

  const handleClickSort = (event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorSort(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorSort(null);
  };

  const openSort = Boolean(anchorSort);
  const idSort = openSort ? 'simple-popover' : undefined;

  const handleClickSection = (event) => {
    setAnchorSection(event.currentTarget);
  };

  const handleCloseSection = () => {
    setAnchorSection(null);
    setAnchorAction(null);
  };

  const openSection = Boolean(anchorSection);
  const idSection = openSection ? 'simple-popover' : undefined;

  const addSectionHandler = (index) => {
    taskContext.addSectionHandler('Untitled', index);
    handleCloseSection();
  };

  return (
    <div className={styles.rootContainer}>
      <Draggable droppableId={`${id}`} index={indexTopic} draggableId={`${id}`} payload={{ id, indexTopic, type: 'section' }}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} snapshot={{ ...snapshot }} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className={styles.cardContainer}>
              <div className={styles.cardSortable}>
                <div className={styles.cardSortableContent}>
                  {isTitle ? (
                    <ClickAwayListener onClickAway={handlerSectionTitleClick}>
                      <OutlinedInput
                        autoFocus
                        className={styles.cardInputTitle}
                        placeholder={text}
                        defaultValue={text}
                        size="small"
                        onChange={(e) => {
                          sectionTitle.current = e.target.value;
                        }}
                      />
                    </ClickAwayListener>
                  ) : (
                    <h3 className={styles.cardTitle}>
                      <button type="button" onClick={handlerSectionTitleClick}>
                        {text}
                      </button>
                    </h3>
                  )}
                  <div className="flex items-center space-x-1">
                    <Add
                      onClick={(e) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        const sec = taskContext.sections.filter((s) => s.id === id);
                        if (sec.length) {
                          const sTopics = sec[0].topics.length;
                          taskContext.addTaskHandler(id, '', sTopics + 1);
                        }
                      }}
                    />
                    <div>
                      <Tooltip title="More Actions">
                        <MoreHoriz onClick={handleClickAction} />
                      </Tooltip>
                      <Popover
                        id={idAction}
                        open={openAction}
                        anchorEl={anchorAction}
                        onClose={handleCloseAction}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        sx={{ transform: 'translate(-24px, 4px)' }}
                      >
                        <Fade in={openAction}>
                          <Box style={{ minWidth: '180px', color: '#fff' }} className="relative flex flex-col p-2 space-y-2 rounded-lg bg-[#ffffff]">
                            <Button
                              onClick={handleClickSection}
                              // onMouseLeave={handleCloseSection}
                              className="px-2 flex justify-start text-black relative"
                              startIcon={<Segment />}
                              endIcon={<ArrowForwardIos className="w-4 h-4 -mr-4 absolute top-2.5 right-4" />}
                            >
                              Add Section
                            </Button>

                            <Button className="px-2 flex justify-start text-black" startIcon={<Delete />} onClick={() => taskContext.deleteSectionHandler(id)}>
                              Delete
                            </Button>
                          </Box>
                        </Fade>
                      </Popover>
                      <Popover
                        id={idSection}
                        open={openSection}
                        anchorEl={anchorSection}
                        onClose={handleCloseSection}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        sx={{ transform: 'translate(8px, -36px)' }}
                      >
                        <Fade in={openSection}>
                          <Box style={{ minWidth: '180px', color: '#fff' }} className="relative flex flex-col p-2 space-y-2 rounded-lg bg-[#ffffff]">
                            <Button className="px-2 flex justify-start text-black" startIcon={<ArrowBack />} onClick={() => addSectionHandler(indexTopic - 1)}>
                              Add section to left
                            </Button>
                            <Button className="px-2 flex justify-start text-black" startIcon={<ArrowForward />} onClick={() => addSectionHandler(indexTopic)}>
                              Add section to right
                            </Button>
                          </Box>
                        </Fade>
                      </Popover>
                      <Tooltip title="Sort By Priority">
                        <SwapVert onClick={handleClickSort} />
                      </Tooltip>
                      <Popover
                        id={idSort}
                        open={openSort}
                        anchorEl={anchorSort}
                        onClose={handleCloseSort}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        sx={{ transform: 'translate(-24px, 4px)' }}
                      >
                        <Fade in={openSort}>
                          <Box style={{ minWidth: '180px', color: '#fff' }} className="relative flex flex-col p-2 space-y-2 rounded-lg bg-[#ffffff]">
                            <Button
                              className="px-2 flex justify-start items-center text-black relative !text-[0.7rem]"
                              startIcon={<LowPriority className="rotate-180" />}
                              // endIcon={<Checkbox checked={isShortPrior} inputProps={{ 'aria-label': 'controlled' }} className="w-4 h-4 right-0 absolute top-1.5 " />}
                              onClick={() => {
                                setIsShortPrior(!isShortPrior);
                                onSortHigh();
                              }}
                            >
                              SORT FROM HIGHER
                            </Button>
                            <Button
                              className="px-2 flex justify-start items-center text-black relative !text-[0.7rem]"
                              startIcon={<LowPriority className="" />}
                              // endIcon={<Checkbox checked={isShortPrior} inputProps={{ 'aria-label': 'controlled' }} className="w-4 h-4 right-0 absolute top-1.5 " />}
                              onClick={() => {
                                setIsShortPrior(!isShortPrior);
                                onSortLow();
                              }}
                            >
                              SORT FROM LOWER
                            </Button>
                          </Box>
                        </Fade>
                      </Popover>
                      <Popover
                        id={idSection}
                        open={openSection}
                        anchorEl={anchorSection}
                        onClose={handleCloseSection}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        sx={{ transform: 'translate(8px, -36px)' }}
                      >
                        <Fade in={openSection}>
                          <Box style={{ minWidth: '180px', color: '#fff' }} className="relative flex flex-col p-2 space-y-2 rounded-lg bg-[#ffffff]">
                            <Button className="px-2 flex justify-start text-black" startIcon={<ArrowBack />} onClick={() => addSectionHandler(indexTopic - 1)}>
                              Add section to left
                            </Button>
                            <Button className="px-2 flex justify-start text-black" startIcon={<ArrowForward />} onClick={() => addSectionHandler(indexTopic)}>
                              Add section to right
                            </Button>
                          </Box>
                        </Fade>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ContainerTaskVertical sectionId={id} topics={topics} />
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    </div>
  );
}

export const CardTask = React.memo(CardTasks);

// /* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Avatar } from '@mui/material';
import { DevTool } from '@hookform/devtools';
import { deepOrange } from '@mui/material/colors';
import InputContainer from '../../Input/InputContainer';
import { handleModal } from '../../../store/slices/modalSlice';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import FileContatiner from '../../Input/FileContatiner';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';
import getDefaultValues from '../../../helper/getDefaultValues';

function AuthorForm({ defaultValues, onFormSubmit }) {
  const dispatch = useDispatch();
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);
  console.log('🚀 ~ file: AuthorForm.jsx:19 ~ AuthorForm ~ clientLanguage', clientLanguage);
  const site = useSelector((state) => state.client.activeDetailClient.sites[0].id);

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      name: defaultValues?.name,
      author_languages: getDefaultValues(clientLanguage, defaultValues?.author_languages),
      avatar: defaultValues?.avatar,
    },
  });

  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append('site', site);
    formData.append('name', data.name);
    data?.author_languages.forEach((author, index) => {
      formData.append(`author_languages[${index}]name`, author?.name);
      formData.append(`author_languages[${index}]language`, author?.language);
    });

    if (data.avatar && data.avatar[0] && typeof data.avatar[0] !== 'string') {
      formData.append('avatar', data.avatar[0]);
    }

    onFormSubmit(formData);
  };

  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  const ava = watch('avatar');

  function renderAva() {
    const createAva = ava && typeof ava === 'string' ? defaultValues?.avatar : URL.createObjectURL(ava[0]);
    return createAva;
  }

  useEffect(() => {
    clientLanguage?.forEach((lang, index) => setValue(`author_languages[${index}][language]`, lang.language.id));
  }, [clientLanguage, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full  flex space-x-10 items-center">
        <Avatar src={ava ? renderAva() : ''} alt="photo preview" sx={{ width: '200px', height: '200px', bgcolor: deepOrange[500] }} variant="square" />
        <div className="w-full space-y-4">
          <InputContainer name="name" control={control} label="Name" />
          <MuiCusotmTabs
            optionsTab={clientLanguage.map((lang) => {
              return {
                name: lang?.language?.name,
                id: lang?.language?.id,
              };
            })}
            value={valueTab}
            onChange={handleChangeTab}
          />
          {clientLanguage?.map((lang, index) => (
            <MuiCustomTabPanel key={lang?.id} value={valueTab} index={index}>
              <InputContainer
                name={`author_languages[${index}][name]`}
                control={control}
                label="Name"
                // errors={errors.name}
              />
            </MuiCustomTabPanel>
          ))}
          <FileContatiner name="avatar" label="Avatar" errors="" control={control} />
          <div className="flex justify-end ml-auto gap-x-2">
            <Button onClick={handleCancel} className="flex" variant="outlined">
              Cancel
            </Button>
            <Button className=" flex" type="submit" variant="outlined">
              {defaultValues ? 'Update Author' : 'Create Author'}
            </Button>
          </div>
        </div>
      </div>
      <DevTool control={control} />
    </form>
  );
}
export default AuthorForm;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jobPoster: null,
  stage: null,
};

const stageSlice = createSlice({
  name: 'stage',
  initialState,
  reducers: {
    handleStage: (state, action) => {
      const currState = state;
      currState.jobPoster = action.payload.jobPoster;
      currState.stage = action.payload.stage;
    },
    resetStage: (state) => {
      const currState = state;
      currState.jobPoster = null;
      currState.stage = null;
    },
  },
});

export const { handleStage, resetStage } = stageSlice.actions;

export default stageSlice.reducer;

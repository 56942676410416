import { Box, InputBase, MenuItem, TextField, Select, FormControl, InputLabel, ButtonBase, Tooltip, CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Search } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, subYears } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { DatePickerFieldWithoutController } from '@/components/Input/DatePickerField';
import AssetsViewer from '../../../components/ui/AssetsViewer/AssetsViewer';
import BZHelmet from '../../../utils/BZHelmet';
import { exportToExcel } from '@/helper/exportToExcel';
import apiTransactions from '../../../api/accounting/transactions';
import apiAccounting from '../../../api/accounting/accounting';
import styles from './Transaction.module.css';

const transactionTheme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            px: 1,
            py: 0.25,
            padding: 0,
            border: 0,
            borderRadius: 1,
            background: '#f1f1f1',
            color: '#000',
            boxShadow: 'none',
            '&.Mui-expanded': {
              margin: 0,
              padding: 0,
            },
          }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 1,
            height: '48px',
            '&.Mui-expanded': {
              color: '#000',
              background: '#f1f1f1',
              minHeight: '48px',
              height: '48px',
            },
          }),
        expandIconWrapper: ({ theme }) =>
          theme.unstable_sx({
            background: '#f1f1f1',
            color: '#000',
            '&.Mui-expanded': {
              color: '#000',
              background: '#f1f1f1',
            },
          }),
      },
    },
    MuiAccordionDetails: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({ padding: '8px 0px 16px' }) } },
  },
});

const createDataTransaction = (data) => {
  const dtTransaction = [];
  data.forEach((dt) => {
    const transaction = { ...dt };
    const lnDetail = dt.line_details;
    if (lnDetail.length) {
      lnDetail.forEach((lnd) => {
        const newTrans = { ...transaction };
        newTrans.line_details = lnd;
        newTrans.id = `${dt.id}-${lnd.id}`;
        newTrans.transaction = dt.id;
        dtTransaction.push(newTrans);
      });
    } else {
      dtTransaction.push(transaction);
    }
  });

  return dtTransaction;
};

const nf = new Intl.NumberFormat('id', {
  // style: 'currency',
  // currency: 'IDR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  roundingIncrement: 1,
});

const nf4 = new Intl.NumberFormat('id', {
  style: 'decimal',
  // currency: 'IDR',
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
  roundingIncrement: 5,
});

const nf0 = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default function Transaction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cl = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const client = activeUser.is_superuser ? activeClient : cl;
  const activeBusinessUnit = useSelector((state) => state.acc.activeBusinessUnit);
  const transactionFilter = useSelector((state) => state.transaction.filter);
  const bU = useSelector((state) => state.acc.activeBusinessUnitDetail);

  const { getTransactions } = apiTransactions();
  const { getAccountingPeriods } = apiAccounting();

  const [businessUnit, setBusinessUnit] = useState(false);
  const [dataTransactions, setDataTransactions] = useState(false);
  const [transactions, setTransactions] = useState(false);
  const [listAccper, setListAccper] = useState([]);
  const [isPopoverAttachments, setPopoverAttachments] = useState(false);

  const [isRequested, setIsRequested] = useState(true);

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const saveFilter = (type, value) => {
    if (type === 'from') {
      dispatch({ type: 'transaction/setFilter', payload: { type: 'from', value } });
    } else {
      dispatch({ type: 'transaction/setFilter', payload: { type: 'to', value } });
    }
  };

  const { from, to, search, accperFrom, accperTo } = transactionFilter;
  const activateReset = !Object.values(transactionFilter).some((v) => !!v === true);

  const onFilterDatePeriod = (value, type) => {
    saveFilter(type, value || null);
  };

  const onFilterAccPer = (value, key) => {
    dispatch({ type: 'transaction/setFilter', payload: { type: key, value } });
  };

  const onSearchTransaction = (event) => {
    const val = event.target.value.toLowerCase();
    if (val) {
      dispatch({ type: 'transaction/setFilter', payload: { type: 'search', value: val } });
    } else {
      dispatch({ type: 'transaction/setFilter', payload: { type: 'search', value: '' } });
    }
  };

  const onPreviewAssets = (assets) => {
    if (assets) {
      setPopoverAttachments(assets);
    } else {
      setPopoverAttachments(false);
    }
  };

  const getTransactionList = useCallback(
    (params = { ledger__business_unit: activeBusinessUnit }) => {
      getTransactions(params)
        .then((res) => {
          if (res.status === 200 && res.data.results) {
            const dataTransaction = createDataTransaction(res.data.results);
            setDataTransactions(dataTransaction);
            setTransactions(dataTransaction);
          }
        })
        .catch(() => {});
    },
    [getTransactions, activeBusinessUnit]
  );

  const getAccperList = useCallback(
    (params) => {
      getAccountingPeriods(params)
        .then((res) => {
          if (res.status === 200 && res.data.results) {
            const data = res.data.results;
            data.sort((a, b) => {
              if (a.year !== b.year) return b.year - a.year;
              return b.month - a.month;
            });
            setListAccper(data);
          }
        })
        .catch((err) => {});
    },
    [getAccountingPeriods]
  );

  const applyReset = () => {
    setTransactions(false);
    dispatch({ type: 'transaction/setFilter', payload: { type: 'reset' } });
    getTransactionList();
  };

  const applyFunc = useCallback(
    (params = {}) => {
      setTransactions(false);
      const filterParams = {
        ledger__business_unit: activeBusinessUnit,
        search,
        ...params,
        max_size: true,
      };
      const isDateFrom = !isRequested ? from : subYears(new Date(), 1);
      const isDateTo = !isRequested ? to : new Date();
      if (isDateFrom) filterParams.date__gte = isDateFrom;
      if (isDateTo) filterParams.date__lte = isDateTo;
      if (accperFrom) filterParams.linedetail__accounting_period__gte = accperFrom;
      if (accperTo) filterParams.linedetail__accounting_period__lte = accperTo;
      getTransactionList(filterParams);
    },
    [activeBusinessUnit, getTransactionList, from, to, accperFrom, accperTo, search, isRequested]
  );

  useLayoutEffect(() => {
    if (!dataTransactions && activeBusinessUnit && isRequested) {
      setIsRequested(false);
      getAccperList({ financial_year__client: client, max_size: true, ordering: '-year' });
      applyFunc();
      setBusinessUnit(activeBusinessUnit);
    }
  }, [applyFunc, getAccperList, client, dataTransactions, rowsPerPage, activeBusinessUnit, isRequested]);

  useEffect(() => {
    if (activeBusinessUnit && businessUnit && activeBusinessUnit !== businessUnit) {
      getTransactionList();
      setBusinessUnit(activeBusinessUnit);
    }
  }, [activeBusinessUnit, businessUnit, getTransactionList]);

  useEffect(() => {
    if (!activeBusinessUnit && !transactions) {
      setTransactions([]);
    }
  }, [activeBusinessUnit, transactions]);

  const renderSupportingDoc = (row) => {
    const { supporting_documents: docs, number: transNumber } = row;
    // const sDoc = docs?.map((doc) => doc.code);
    const jNumber =
      docs.length > 0 ? (
        <ButtonBase className="px-1 py-0 border rounded-sm bg-gray-300" onClick={() => onPreviewAssets(docs)}>
          {transNumber}
        </ButtonBase>
      ) : (
        <span>{transNumber}</span>
      );
    return jNumber;
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Date',
      minWidth: 96,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => `${format(new Date(params.row.date), 'yyyy-MM-dd')}`,
    },
    {
      field: 'number',
      headerName: 'Journal number',
      description: 'Journal number',
      sortable: false,
      width: 144,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderSupportingDoc(params.row),
    },
    {
      field: 'description',
      headerName: 'Descriptions',
      sortable: true,
      width: 208,

      renderCell: (params) => (
        <Tooltip title={params.row.description}>
          <span className="truncate">{params.row.description}</span>
        </Tooltip>
      ),
    },
    {
      field: 'line_details.account',
      headerName: 'Account',
      sortable: true,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => `${params.row.line_details.account?.code}`,
    },
    {
      field: 'line_details.cost_center',
      headerName: 'Center',
      sortable: true,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => `${params.row.line_details.cost_center?.code}`,
    },
    {
      field: 'line_details.counterpart',
      headerName: 'Counterpart',
      sortable: true,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => `${params.row.line_details.counterpart?.code || ''}`,
    },
    {
      field: 'line_details.reconciliation_reference',
      headerName: 'Recref',
      sortable: true,

      renderCell: (params) => {
        const recref = params.row.line_details.reconciliation_reference;
        return `${recref && recref !== '0' ? recref : ''}`;
      },
    },
    {
      field: 'line_details.accounting_period',
      headerName: 'Accper',
      sortable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => `${params.row.line_details.accounting_period}`,
    },
    {
      field: 'line_details.debit',
      headerName: 'Debit',
      sortable: true,
      headerAlign: 'center',
      align: 'right',
      minWidth: 164,
      renderCell: (params) => `${nf.format(params.row.line_details.debit)}`,
    },
    {
      field: 'line_details.credit',
      headerName: 'Credit',
      sortable: true,
      headerAlign: 'center',
      align: 'right',
      minWidth: 164,
      renderCell: (params) => `${nf.format(params.row.line_details.credit)}`,
    },
    {
      field: 'line_details.ledger_currency',
      headerName: 'LE_CCD',
      sortable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => `${params.row.line_details.ledger_currency?.code || ''}`,
    },
    {
      field: 'line_details.base_currency_rate',
      headerName: 'Base Currency Rate',
      description: 'Base Currency Rate',
      sortable: true,
      headerAlign: 'center',
      align: 'right',
      minWidth: 164,
      renderCell: (params) => `${nf4.format(params.row.line_details.base_currency_rate)}`,
    },
    {
      field: 'line_details.base_amount',
      headerName: 'Base Amount',
      sortable: true,
      headerAlign: 'center',
      align: 'right',
      minWidth: 164,
      renderCell: (params) => `${nf.format(params.row.line_details.base_amount) || ''}`,
    },
  ];

  // EXPORT TO EXCEL
  function getFormattedTransactionHistoryExcel(data) {
    // console.log("data transaction ", data[4]);
    return data.map((transaction) => {
      const dataDebet = nf.format(transaction.line_details?.debit);
      const dataCredit = nf.format(transaction.line_details?.credit);
      const dataCurrency = nf0.format(transaction.line_details.base_currency_rate);
      const dataAmount = nf.format(transaction.line_details.base_amount);

      return {
        Date: format(new Date(transaction.date), 'yyyy-MM-dd'),
        'Journal Number': transaction?.number,
        Descriptions: transaction?.description,
        Account: transaction?.line_details?.account?.code,
        Center: transaction.line_details?.cost_center?.code,
        Counterpart: transaction.line_details?.counterpart?.code || '',
        'Project Code': transaction.line_details?.project_id?.code || '',
        Recref: transaction.line_details?.reconciliation_reference || '',
        Accper: transaction.line_details?.accounting_period,
        Debit: nf.format(transaction.line_details?.debit),
        Credit: nf.format(transaction.line_details?.credit),
        LE_CCD: transaction.line_details?.ledger_currency?.code,
        'Base Currency Rate': nf4.format(transaction.line_details.base_currency_rate),
        'Transaction Amount': nf.format(transaction.line_details.transaction_amount) || '',
        'Base Amount': nf.format(transaction.line_details.base_amount) || '',
        'Account Name': transaction?.line_details?.account?.name || '',
        'Account Type': transaction?.line_details?.account?.account_type?.name || '',
        'PNL BS Group': transaction?.line_details?.account?.pnl_bs_category?.pnl_bs_group?.name || '',
        'PNL BS Category': transaction?.line_details?.account?.pnl_bs_category?.name || '',
      };
    });
  }

  function exportTransactionHistory(contacts) {
    const data = getFormattedTransactionHistoryExcel(contacts);

    let fName = `${bU.code || bU.name}_ACT`;
    if (accperFrom) {
      const accP = listAccper.find((a) => accperFrom === a.id);
      if (accP) {
        const aY = accP?.year?.toString().substring(2, 4);
        const aM = accP?.month?.toString();
        fName += `_from_${aY}-${aM.length === 1 ? `0${aM}` : aM}`;
      }
    }
    if (accperTo) {
      const accP = listAccper.find((a) => accperTo === a.id);
      if (accP) {
        const aY = accP?.year?.toString().substring(2, 4);
        const aM = accP?.month?.toString();
        fName += `_to_${aY}-${aM.length === 1 ? `0${aM}` : aM}`;
      }
    }
    if (from) {
      const fD = format(new Date(from), 'yyyy-MM-dd');
      fName += `_${fD}`;
    }
    if (to) {
      const tD = format(new Date(to), 'yyyy-MM-dd');
      fName += `_${tD}`;
    }

    exportToExcel(data, fName);
  }

  return (
    <ThemeProvider theme={transactionTheme}>
      <BZHelmet title="Transaction" description="bz publish transaction" />
      <div className="w-full h-[calc(100vh_-_72px)] mb-4 overflow-y-auto">
        <Box className={[styles.container, ''].join(' ')}>
          <div className="w-full flex p-4 shadow-md">
            <h1 className="text-lg font-bold">General Ledger Drilldown</h1>
          </div>
          <div>
            <Box className="w-full flex justify-between gap-4 pt-4 mt-2 mb-6">
              <div className="flex flex-wrap gap-2">
                <InputBase
                  size="small"
                  placeholder="search"
                  endAdornment={<Search color="disabled" />}
                  value={search}
                  onChange={onSearchTransaction}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSearchTransaction(e);
                      applyFunc({ search: e.target.value?.toLowerCase() ?? '' });
                    }
                  }}
                  className="border border-gray-300 rounded-full px-4"
                />
                <div className="relative w-auto flex flex-wrap gap-2">
                  <DatePickerFieldWithoutController
                    label="Date from"
                    value={from}
                    onChange={(newValue) => onFilterDatePeriod(newValue, 'from')}
                    renderInput={(params) => {
                      return <TextField sx={{ width: 156 }} size="small" {...params} />;
                    }}
                    componentsProps={{ actionBar: { actions: ['clear'] } }}
                  />
                  <DatePickerFieldWithoutController
                    label="Date to"
                    value={to}
                    onChange={(newValue) => onFilterDatePeriod(newValue, 'to')}
                    renderInput={(params) => {
                      return <TextField sx={{ width: 156 }} size="small" {...params} />;
                    }}
                    componentsProps={{ actionBar: { actions: ['clear'] } }}
                  />
                  <FormControl size="small">
                    <InputLabel id="select-account">Accper from</InputLabel>
                    <Select
                      sx={{ p: 0.5, border: 0, width: 144 }}
                      labelId="select-accperFilter"
                      id="select-accperFilter"
                      name="accperFilter"
                      value={accperFrom}
                      label="Accper from"
                      placeholder="Accper from"
                      onChange={(event) => {
                        onFilterAccPer(event.target.value, 'accperFrom');
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 'calc(100% - 40rem)!important',
                            marginTop: '5px',
                          },
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>Accper from</em>
                      </MenuItem>
                      {listAccper.map((acc) => (
                        <MenuItem key={acc.id} value={acc.id}>
                          {acc.year} - {String(acc.month).length === 1 ? `0${acc.month}` : acc.month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl size="small">
                    <InputLabel id="select-account">Accper to</InputLabel>
                    <Select
                      sx={{ p: 0.5, border: 0, width: 144 }}
                      labelId="select-accperFilter"
                      id="select-accperFilter"
                      name="accperFilter"
                      value={accperTo}
                      label="Accper to"
                      placeholder="Accper to"
                      onChange={(event) => {
                        onFilterAccPer(event.target.value, 'accperTo');
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 'calc(100% - 40rem)!important',
                            marginTop: '5px',
                          },
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>Accper to</em>
                      </MenuItem>
                      {listAccper.map((acc) => (
                        <MenuItem key={acc.id} value={acc.id}>
                          {acc.year} - {String(acc.month).length === 1 ? `0${acc.month}` : acc.month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <button
                    type="button"
                    className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                    style={{ backgroundColor: '#2C6D47' }}
                    onClick={() => applyFunc()}
                  >
                    <p className="text-white">Apply</p>
                  </button>
                  <button
                    type="button"
                    className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                    style={{ backgroundColor: '#2C6D47', opacity: activateReset ? 0.4 : 1 }}
                    onClick={applyReset}
                    disabled={activateReset}
                  >
                    <p className="text-white">Reset</p>
                  </button>
                  <button
                    type="button"
                    className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                    style={{ backgroundColor: '#2C6D47', opacity: transactions.length === 0 ? 0.4 : 1 }}
                    onClick={() => exportTransactionHistory(transactions)}
                    disabled={transactions.length > 0 ? false : true}
                  >
                    <p className="text-white">Export To Excel</p>
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 justify-end">
                <button
                  type="button"
                  className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                  style={{ backgroundColor: '#2C6D47' }}
                  onClick={() => navigate('/transaction/import')}
                >
                  <p className="text-white">Import Transaction</p>
                </button>
                <button
                  type="button"
                  className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                  style={{ backgroundColor: '#2C6D47' }}
                  onClick={() => navigate('/transaction/create')}
                >
                  <p className="text-white">Add New Transaction</p>
                </button>
              </div>
            </Box>
            <div className="w-full h-full">
              {transactions ? (
                <DataGrid
                  autoHeight
                  rows={transactions}
                  columns={columns}
                  pageSize={rowsPerPage}
                  onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50]}
                  className="m-auto max-w-[1716px] w-[100%]"
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  onCellClick={(params) => {
                    navigate(`/transaction/detail/${params.row.transaction}`);
                  }}
                />
              ) : (
                <div className="flex w-full">
                  <CircularProgress className="m-auto" />
                </div>
              )}
            </div>
          </div>
        </Box>
        <AssetsViewer openAttachments={!!isPopoverAttachments} assets={isPopoverAttachments} handleCloseAttachments={() => setPopoverAttachments(false)} />
      </div>
    </ThemeProvider>
  );
}

import React from 'react';
import Main from '../../components/ui/SettingsBZ/ServiceMonitoring/Main';
import BZHelmet from '../../utils/BZHelmet';

export default function ServiceMonitoring() {
  return (
    <>
      <BZHelmet title="Service Monitoring" description="bz publish service monitoring" />
      <Main />
    </>
  );
}

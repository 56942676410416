// /* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import Collapse from '@mui/material/Collapse';
import { TextField } from '@mui/material';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import SelectAutocomplete from '../../../components/Input/SelectAutocomplete';
import request from '../../../helper/axiosUtil';
import { createTopic, createSection } from '../../../hooks/useTopicsData';
import { handleModal } from '../../../store/slices/modalSlice';
import { taskAdd } from '../../../store/slices/taskCrudSlice';

export default function AddTask({ projectsData, projectData }) {
  const dispatch = useDispatch();
  const qClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const inputTask = useSelector((state) => state.crud.taskData);
  const userId = useSelector((state) => state.auth.user.id);

  const [sendPostTopic, setSendPostTopic] = useState(false);
  const [handleTopicId, setHandleTopicId] = useState(null);
  const [handleCreateSection, setHandleCreateSection] = useState(false);
  const [onFocusSection, setOnFocusSection] = useState(false);

  const { mutateAsync: postSection } = useMutation(createSection, {
    onSuccess: (e) => {
      dispatch(taskAdd({ section: e.data.id }));
      setSendPostTopic(true);
      qClient.invalidateQueries('topics');
      dispatch(handleModal({ modalId: '' }));
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onPostSection = async (data) => {
    await postSection(data);
  };

  const updateTopics = async (data) =>
    request({
      url: `/project-management/topics/${handleTopicId}/`,
      method: 'patch',
      data,
      headers: { 'Content-Type': 'application/json' },
    });

  const { mutateAsync: updateTopic } = useMutation(updateTopics, {
    onSuccess: () => {
      qClient.invalidateQueries('topics');
      dispatch(taskAdd({ name: '', project: '', section: '' }));
    },
  });

  const onUpdateTopic = useCallback(
    async (data) => {
      await updateTopic(data);
    },
    [updateTopic]
  );
  useEffect(() => {
    if (handleTopicId) {
      onUpdateTopic({ assignee: userId });
    }
  }, [handleTopicId, onUpdateTopic, userId]);

  const { mutateAsync: postTopic } = useMutation(createTopic, {
    onSuccess: (e) => {
      setHandleTopicId(e.data.id);
      qClient.invalidateQueries('topics');
      dispatch(handleModal({ modalId: '' }));
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onPostTopic = useCallback(
    async (data) => {
      await postTopic(data);
    },
    [postTopic]
  );

  useEffect(() => {
    if (sendPostTopic) {
      onPostTopic({ name: inputTask.name, project: inputTask.project, section: inputTask.section });
    }
  }, [sendPostTopic, onPostTopic, inputTask]);

  const enterSection = (e) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      onPostSection({ name: e.target.value, project: inputTask?.project });
    }
  };

  const ProjData = [];

  if (projectsData) {
    projectsData?.data?.results.map((data) => {
      ProjData.push(data);
      return ProjData;
    });
  }

  const projDetail = [];
  if (projectData) {
    projectData?.data?.sections.map((data) => {
      projDetail.push(data);
      return projDetail;
    });
  }

  const options = ProjData?.map((item) => ({
    name: item?.name,
    value: item?.id,
  }));

  const optionDetail = projDetail?.map((item) => ({
    name: item?.name,
    value: item?.id,
  }));

  const onChangeTaskProject = (event) => {
    const userName = event.target.innerText;
    if (userName) {
      const assignee = options.filter((u) => u.name === userName);
      if (assignee) {
        dispatch(taskAdd({ project: assignee[0]?.value }));
      }
    }
  };

  const onChangeTaskSection = (event) => {
    const sectionName = event.target.innerText;
    if (sectionName) {
      const section = optionDetail.filter((u) => u.name === sectionName);
      if (section) {
        dispatch(taskAdd({ section: section[0]?.value }));
      }
    }
  };

  return (
    <ModalWrapper header="Add task" maxWidth="sm" componentName="steptask">
      {/* <TopicForm /> */}
      <div className="space-y-10">
        <div className="">
          <div className="">Description</div>
          <div className="text-3xl">{inputTask?.name}</div>
        </div>
        <div className="">
          <div className="">Assign To Existing Project</div>
          <SelectAutocomplete
            options={options}
            onChange={(event) => {
              onChangeTaskProject(event);
            }}
          />
        </div>
        {inputTask?.project && projDetail.length > 0 && (
          <div className="">
            <div className="">Assign To Section</div>
            <SelectAutocomplete
              options={optionDetail}
              onChange={(event) => {
                onChangeTaskSection(event);
              }}
            />
          </div>
        )}
        {inputTask?.section && (
          <button onClick={() => setSendPostTopic(true)} className="px-1 text-blue-400 underline" type="button">
            Send
          </button>
        )}

        {inputTask?.project && projDetail.length === 0 && (
          <div className="space-y-5">
            <div>
              There is no section, for this project.
              <button onClick={() => setHandleCreateSection(true)} className="px-1 text-blue-400 underline" type="button">
                Create
              </button>
              Section?
            </div>
            <Collapse in={handleCreateSection}>
              <div className="w-full">
                <TextField
                  name=""
                  id=""
                  label=""
                  className="w-full outline-none border-none"
                  placeholder="Section Name"
                  multiline
                  variant="standard"
                  onFocus={() => {
                    setOnFocusSection(true);
                  }}
                  // onBlur={(e) => dispatch(taskAdd({ name: e.target.value }))}
                  onKeyPress={enterSection}
                />
                {onFocusSection && <div className="text-gray-400">Press enter to create section and asign task </div>}
              </div>
            </Collapse>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
}

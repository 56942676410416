/* eslint-disable */

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Cookies from 'js-cookie';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import moment from 'moment';
import InputContainer from '../../Input/InputContainer';
import DatePickerField from '../../Input/DatePickerField';
import { handleModal } from '../../../store/slices/modalSlice';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';
import ModalWrapper from '../../ui/ModalWrapper';

import { generateSelectListDefault, generateSelectObjectDefault, getDefaultSelectObject, getOptionSelectWithKey, getSelectOptionWithIcon } from '../../../lib/OptionsInputHelper';
import CustomUploadAdapterPlugin from '../../../utils/Editor/CustomUploadAdapterPlugin';

import editorConfig from '../../../helper/editorConfig';
import BaseAssetViewer from '../../Viewer/BaseAssetViewer';
import UnpairAssetButton from '../../ui/Alert/UnpairAssetButton';
import AssetCardNew from '../../Viewer/AssetCardNew';
import ReactSelectSingle from '../../Input/ReactSelectSingle';
import useGet from '../../../hooks/useGet';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import renderDate from '../../../helper/renderDate';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import MuiCusotmTabs from '../../ui/Tab/MuiCustomTabs';
import MuiCustomTabPanel from '../../ui/Tab/MuiCustomTabPanel';
import getDefaultValues from '../../../helper/getDefaultValues';
import ReactSelectSingleIcon from '../../Input/ReactSelectSingleICon';
import { removeEmptyProperties } from '../../../helper/arrayHandler';
import schemaArticle from './validationArticle';
import FieldArrKey from './FieldArrKey';
import BaseLocationViewer from '../../Viewer/LocationCard';

const lib = ['drawing', 'places'];

const containerStyle = {
  width: '100%',
  height: '400px',
};

const editorConfigs = {
  extraPlugins: [CustomUploadAdapterPlugin],
  toolbar: { ...editorConfig.toolbar },
  link: {
    addTargetToExternalLinks: true,
    decorators: [
      {
        mode: 'manual',
        label: 'External Link',
        attributes: {
          target: '_blank',
        },
      },
    ],
  },
};

const optionsStatus = [
  {
    name: 'Drafted',
    id: 'Drafted',
  },
  {
    name: 'Published',
    id: 'Published',
  },
];

export default function ArticleFormNew({ defaultValues, onFormSubmit, articleSelected, resIdArticle, setResIdArticle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const templateId = Cookies.get('bzaTemplate');
  const { articleId } = useParams();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_MAP_KEY,
    libraries: lib,
  });

  const clientId = useSelector((state) => state.client.activeClient);

  const assetPair = useSelector((state) => state.assetCrud.selected);
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);

  const site = useSelector((state) => state.client.activeDetailClient.sites[0].id);
  const activeAdminLanguage = useSelector((state) => state.client.activeAdminLanguage);

  const [valueTab, setValueTab] = useState(0);

  const [attachLocation, setAttachLocation] = useState(null);
  const [valueUrl, setValueUrl] = useState((defaultValues && defaultValues?.article_languages[0]?.page ? 'internal' : 'external') || 'internal');

  const [selectedTabLanguage, setSelectedTabLanguage] = useState(1);

  useEffect(() => {
    setSelectedTabLanguage(clientLanguage[0].language.id);
  }, [clientLanguage]);

  const groupedArray = defaultValues?.article_keywords.reduce((acc, cur) => {
    const key = cur.language.id;
    const group = acc.find((g) => g[0].language.id === key);
    if (group) {
      group.push(cur);
    } else {
      acc.push([cur]);
    }
    return acc;
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      keyword: groupedArray,
      name: defaultValues?.name,
      date: defaultValues?.date || moment(new Date()).format('YYYY-MM-DD'),
      expiry_date: defaultValues?.expiry_date,
      publish_date: defaultValues?.publish_date,
      status: defaultValues?.status,
      author: defaultValues?.author?.id,
      widgets: defaultValues?.widgets?.map((w) => w.id),
      categories: defaultValues?.categories?.map((cat) => cat.id),
      page: defaultValues?.article_languages[0]?.page,
      section: defaultValues?.article_languages[0]?.section,
      article_languages: getDefaultValues(clientLanguage, defaultValues?.article_languages),
    },
    resolver: schemaArticle,
  });

  const defAssets = defaultValues?.article_assets?.map((def) => def?.asset);
  const activeLang = activeAdminLanguage?.id;

  const pageId = watch('page');
  const sectionId = watch('section');

  const statusPage = HandleWatchingState(pageId);

  const { data: CategoriesData } = useGet({
    url: `category_articles`,
    queryString: {
      client: clientId,
      max_size: true,
      language: activeLang,
    },
    validate: 'categoriesArticle',
  });

  const { data: AuthorsData } = useGet({
    url: `authors`,
    queryString: {
      site,
      max_size: true,
    },
    validate: 'authors',
  });

  const { data: WidgetsData } = useGet({
    url: `widgets`,
    queryString: {
      is_internal: false,
      client: clientId,
      category: 1,
      status: 'Public',
      max_size: true,
      language: activeLang,
    },
    validate: 'widgetsArticle',
  });

  const { data: listPage } = useGet({ url: `pages/?template=${templateId}&language=1&max_size=true`, validate: 'AllPages' });

  const { data: listSection } = useGet({
    url: `pages/${pageId}`,
    validate: 'sections',
    isEnabled: statusPage,
  });

  const handleChangeTab = (event, newValue) => {
    const languageChanges = clientLanguage[newValue].language.id;
    setValueTab(newValue);
    setSelectedTabLanguage(languageChanges);
  };

  const optionsCategory = CategoriesData?.data?.results?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const optionsWidgets = WidgetsData?.data?.results?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const handleCancel = () => {
    navigate(-1);
  };

  const handleChangeUrl = (event) => {
    setValueUrl(event.target.value);
  };

  const { mutate: pairingAssets } = usePatchOrPut({ url: `articles/${articleSelected || articleId}/pair_assets`, validate: ['article'] });

  const onPairAssets = async (data) => {
    await pairingAssets(data);
  };

  const onSubmit = async (data) => {
    onFormSubmit({
      article_languages: removeEmptyProperties(data.article_languages, ['path']),
      location: attachLocation?.id || defaultValues?.location?.id,
      name: data.name,
      client: clientId,
      author: data.author,
      categories: data.categories,
      publish_date: renderDate(data.publish_date),
      expiry_date: renderDate(data.expiry_date),
      date: renderDate(data.date),
      status: data.status,
      widgets: data.widgets,
      article_assets: assetPair.map((asset) => asset.id),
    });
  };

  useEffect(() => {
    clientLanguage?.forEach((lang, index) => setValue(`article_languages[${index}][page]`, pageId));
    clientLanguage?.forEach((lang, index) => setValue(`article_languages[${index}][section]`, sectionId));
    clientLanguage?.forEach((lang, index) => setValue(`article_languages[${index}][language]`, lang.language.id));
  }, [clientLanguage, pageId, sectionId, setValue]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full space-y-4">
          <div className="flex w-full gap-x-3">
            <div className="w-6/12">
              <DatePickerField sx={{ marginBottom: '20px' }} control={control} name="publish_date" label="Publish Date" size="small" fullWidth />
            </div>
            <div className="w-6/12 ">
              <DatePickerField sx={{ marginBottom: '20px' }} control={control} name="expiry_date" label="Expiry Date" size="small" fullWidth />
            </div>
          </div>
          <div className="flex w-full gap-x-3">
            <div className="w-6/12">
              <DatePickerField sx={{ marginBottom: '20px' }} control={control} name="date" label="Date" size="small" fullWidth defaultValue={defaultValues?.date} />
            </div>
            <div className="w-6/12 ">
              <ReactSelectSingle
                name="status"
                control={control}
                options={getOptionSelectWithKey(optionsStatus)}
                defaultValue={getDefaultSelectObject(defaultValues?.status, getOptionSelectWithKey(optionsStatus))}
                placeholder="Select Status"
                errors={errors.status}
              />
            </div>
          </div>
          <div className="flex  gap-x-3 ">
            <div className="w-6/12">
              <ReactSelectSingleIcon
                name="author"
                control={control}
                options={getSelectOptionWithIcon(AuthorsData?.data?.results)}
                defaultValue={generateSelectObjectDefault(defaultValues?.author)}
                placeholder="Select Author"
                variant="icon"
                errors={errors.author}
              />
            </div>
            <div className="w-6/12 bg-white">
              <Controller
                name="widgets"
                control={control}
                render={({ field: { onChange } }) => (
                  <ReactSelectMultiple
                    options={optionsWidgets}
                    defaultValue={generateSelectListDefault(defaultValues?.widgets)}
                    placeholder="Select Collections"
                    errors={errors.widgets}
                    isMulti
                    onChange={(v) => onChange(v?.map((val) => val.value))}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex gap-x-3">
            <div className="w-6/12">
              <InputContainer name="name" control={control} label="Name" errors={errors.name} />
            </div>
            <div className="w-6/12 bg-white mt-5">
              <Controller
                name="categories"
                control={control}
                render={({ field: { onChange } }) => (
                  <ReactSelectMultiple
                    options={optionsCategory}
                    defaultValue={generateSelectListDefault(defaultValues?.categories)}
                    placeholder="Select Categories"
                    isMulti
                    onChange={(v) => onChange(v?.map((val) => val.value))}
                  />
                )}
              />
            </div>
          </div>

          <MuiCusotmTabs
            optionsTab={clientLanguage.map((lang) => {
              return {
                name: lang?.language?.name,
                id: lang?.language?.id,
              };
            })}
            value={valueTab}
            onChange={handleChangeTab}
          />
          {clientLanguage?.map((lang, index) => (
            <MuiCustomTabPanel key={lang.id} value={valueTab} index={index}>
              <div className=" ">
                <InputContainer name={`article_languages[${index}][title]`} control={control} label="Title" />
                <InputContainer
                  name={`article_languages[${index}][summary]`}
                  control={control}
                  label="Summary"
                  // errors={errors.summary}
                />
                <div className="typo-style">
                  <Controller
                    name={`article_languages[${index}][content]`}
                    control={control}
                    render={({ field: { value } }) => (
                      <CKEditor
                        id="editor"
                        key={lang.id}
                        editor={Editor}
                        config={editorConfigs}
                        data={value || ''}
                        onChange={(event, editor) => {
                          const editorData = editor.getData();
                          setValue(`article_languages[${index}][content]`, editorData);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-5 border-2 rounded my-5">
                <div className="font-bold text-lg">SEO</div>
                <div className="">
                  <InputContainer name={`article_languages[${index}][description]`} control={control} label="Description" />
                </div>
                <div className="">
                  <div className="font-bold">Keyword</div>
                  <div className="pt-5 w-full">
                    <FieldArrKey
                      index={lang?.language?.id}
                      defaultValues={defaultValues}
                      language={lang?.language.id}
                      resIdArticle={resIdArticle}
                      setResIdArticle={setResIdArticle}
                    />
                  </div>
                </div>
              </div>
            </MuiCustomTabPanel>
          ))}

          <div className="flex flex-col space-y-4">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Url Type</FormLabel>
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={valueUrl} onChange={handleChangeUrl}>
                <FormControlLabel value="internal" control={<Radio />} label="Internal" />
                <FormControlLabel value="external" control={<Radio />} label="External" />
              </RadioGroup>
            </FormControl>
            {valueUrl === 'internal' && (
              <div className="">
                <div className="">
                  <ReactSelectSingle
                    name="page"
                    control={control}
                    options={getOptionSelectWithKey(listPage?.data?.results)}
                    defaultValue={pageId && getDefaultSelectObject(pageId, getOptionSelectWithKey(listPage?.data?.results))}
                    placeholder="Select Page"
                    isClearable
                  />
                </div>

                <div className="mt-7">
                  <ReactSelectSingle
                    name="section"
                    control={control}
                    options={getOptionSelectWithKey(listSection?.data?.sections)}
                    defaultValue={sectionId && getDefaultSelectObject(sectionId, getOptionSelectWithKey(listSection?.data?.sections))}
                    placeholder="Select Section"
                    isClearable
                  />
                </div>
              </div>
            )}

            {valueUrl === 'external' && (
              <div className="">
                {clientLanguage?.map((lang, index) => (
                  <InputContainer key={lang.language.id} name={`article_languages[${index}][path]`} control={control} label={`Url ${lang.language.name}`} />
                ))}
              </div>
            )}
          </div>

          <div className="">
            {defaultValues?.location && isLoaded && !attachLocation && (
              <div>
                <div className="w-full mb-5">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{ lat: parseFloat(defaultValues?.location.latitude), lng: parseFloat(defaultValues?.location.longitude) }}
                    zoom={10}
                    options={{
                      scrollwheel: false,
                    }}
                  >
                    <Marker position={{ lat: parseFloat(defaultValues?.location.latitude), lng: parseFloat(defaultValues?.location.longitude) }} />
                  </GoogleMap>
                  <div>{defaultValues?.location?.address}</div>
                </div>
              </div>
            )}

            {attachLocation && (
              <div>
                <div className="w-full mb-5">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{ lat: parseFloat(attachLocation.latitude), lng: parseFloat(attachLocation.longitude) }}
                    zoom={10}
                    options={{
                      scrollwheel: false,
                    }}
                  >
                    <Marker position={{ lat: parseFloat(attachLocation.latitude), lng: parseFloat(attachLocation.longitude) }} />
                  </GoogleMap>
                  <div>{attachLocation?.address}</div>
                </div>
              </div>
            )}

            {defaultValues?.location || attachLocation ? (
              <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'locationArticle' }))} className="bz-btn secondary" type="button" variant="outlined">
                Change Location
              </Button>
            ) : (
              <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'locationArticle' }))} className="bz-btn secondary" type="button" variant="outlined">
                Add Location
              </Button>
            )}
            <ModalWrapper componentName="locationArticle" header="Select Location" maxWidth="lg">
              <BaseLocationViewer handleClick={() => dispatch(handleModal({ modalId: '' }))} attachLocation={attachLocation} setAttachLocation={setAttachLocation} />
            </ModalWrapper>
          </div>
          <div className="ml-auto flex gap-x-3">
            <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'assetArticle' }))} className="ml-auto flex" type="button" variant="outlined">
              Add New Asset
            </Button>
            {defaultValues ? <UnpairAssetButton isApi url={`articles/${articleId}/unpair_assets`} validate={['article']} /> : <UnpairAssetButton />}
          </div>

          <div className=" border-dashed min-h-[160px] border-4 p-3 gap-3 flex flex-wrap">
            {defaultValues ? (
              <AssetCardNew assetsData={defAssets} assetComponent="assetPairEditMenu" />
            ) : (
              assetPair.map((asset) => <AssetCardNew assetsData={asset} key={asset?.id} assetComponent="assetPairMenu" />)
            )}
          </div>

          <div className="ml-auto flex gap-x-3">
            <Button onClick={handleCancel} className="bz-btn secondary ml-auto" type="button" variant="outlined">
              Cancel
            </Button>
            <Button className="bz-btn" type="submit" variant="outlined">
              {defaultValues ? 'Update Article' : 'Create Article'}
            </Button>
          </div>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
      <ModalWrapper componentName="assetArticle" header="Add Asset" maxWidth="lg">
        <div className="">
          <BaseAssetViewer handleClick={defaultValues ? () => onPairAssets({ assets: assetPair?.map((asset) => asset.id) }) : () => dispatch(handleModal({ modalId: '' }))} />
        </div>
      </ModalWrapper>
    </div>
  );
}

import React from 'react';
import Main from '../../components/ui/Settings/Sitemap/Main';
import BZHelmet from '../../utils/BZHelmet';

export default function Sitemap() {
  return (
    <>
      <BZHelmet title="Sitemap" description="bz publish sitemap" />
      <Main />
    </>
  );
}

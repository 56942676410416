import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Button, FormControl, MenuItem, Select, Stack, TextareaAutosize } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';

import BaseInputValidation from '../../Input/Base/BaseInputValidation';
import { handleModal } from '../../../store/slices/modalSlice';
import CRMApi from '../../../api/CRM/CRMCustomers';

const schema = yup.object().shape({
  name: yup.string().max(200).required(),
  address: yup.string().required(),
});

const containerStyle = {
  width: '100%',
  height: '100%',
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  padding: 12px;
  border-radius: 4px 4px 4px 4px;
  // color: ${theme.palette.mode === 'dark' ? '#afb8c1' : '#24292f'};
  background: ${theme.palette.mode === 'dark' ? '#24292f' : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? '#424a53' : '#d0d7de'};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? '#24292f' : '#f6f8fa'};

  &:hover {
    border-color: ${'#3399FF'};
  }

  &:focus {
    border-color: ${'#3399FF'};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? '#007FFF' : '#b6daff'};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
const { getCountries, addLocation, updateLocation } = CRMApi();

const mapLib = ['drawing', 'places'];

export default function BaseNewLocation({ pairLocation, type, defaultLocation }) {
  const dispatch = useDispatch();
  const activeClient = useSelector((state) => state.client.activeClient);
  const [onLoad, setOnLoad] = useState(null);
  const [centerMap, setCenterMap] = useState({ lat: 0, lng: 0 });
  const [selectedCountry, setSelectedCountry] = useState('');

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_MAP_KEY,
    libraries: mapLib,
  });

  const { data: countries } = useQuery({
    queryKey: ['all-countries'],
    queryFn: () => getCountries(),
    refetchOnWindowFocus: false,
  });

  const allCountries = countries?.data?.results || [];

  const onClose = () => {
    dispatch(handleModal({ modalId: '', componentName: '' }));
  };

  const handleChangePlace = () => {
    const currPlace = onLoad.getPlace();

    let newSelectedCountry = {};
    const addressComponents = currPlace?.address_components;

    if (addressComponents) {
      const countriesName = allCountries.map((country) => country.iso_code);
      const addressCountry = addressComponents.map((address) => address.short_name);
      const foundedCountry = _.intersection(countriesName, addressCountry);
      const countryCode = foundedCountry.length ? foundedCountry[0] : '';
      newSelectedCountry = allCountries.find(({ iso_code: isoCode }) => isoCode === countryCode)?.id || '';
    }
    setSelectedCountry(newSelectedCountry);
    setValue('country', newSelectedCountry);
    setValue('address', currPlace?.formatted_address);
    setValue('latitude', currPlace?.geometry.location.lat());
    setValue('longitude', currPlace?.geometry.location.lng());
  };

  const handleChangeMarker = (event) => {
    setValue('latitude', event.latLng.lat());
    setValue('longitude', event.latLng.lng());
  };

  const onSubmit = (data) => {
    if (type === 'edit') {
      updateLocation(defaultLocation.id, data)
        .then((res) => {
          if (res?.data) {
            pairLocation(res.data);
          }
          onClose();
          reset();
        })
        .catch((err) => console.log(err));
    } else {
      addLocation({ client: activeClient, ...data })
        .then((res) => {
          if (res?.data) {
            pairLocation(res.data);
          }
          onClose();
          reset();
        })
        .catch((err) => console.log(err));
    }
  };

  const lat = watch('latitude') || centerMap.lat;
  const lng = watch('longitude') || centerMap.lng;

  useEffect(() => {
    if (+lat !== centerMap.lat || +lng !== centerMap.lng) {
      setCenterMap({ lat: +lat, lng: +lng });
    }
  }, [lat, lng, centerMap]);

  useEffect(() => {
    if (type === 'edit' && defaultLocation) {
      if (defaultLocation.country) {
        setSelectedCountry(defaultLocation.country.id);
        setValue('country', defaultLocation.country.id);
      }
      setValue('name', defaultLocation.name);
      setValue('address', defaultLocation.address);
      setValue('latitude', defaultLocation.latitude);
      setValue('longitude', defaultLocation.longitude);
    } else {
      reset();
    }
  }, [type, defaultLocation]);

  const InputLabel = ({ children, customClass }) => <p className={`font-semibold ${customClass}`}>{children}</p>;

  return (
    isLoaded && (
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 pb-2">
        <div className="w-full">
          <InputLabel>Name</InputLabel>
          <BaseInputValidation control={control} name="name" label="" errors={errors} required />
        </div>
        <div className="w-full h-[296px]">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={centerMap}
            zoom={6}
            // onLoad={(e) => setOnLoad(e)}
            onRightClick={(e) => handleChangeMarker(e)}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
              // styles: mapStyles,
              disableDoubleClickZoom: true
            }}
            onDblClick={(e) => handleChangeMarker(e)}
          >
            <Marker position={centerMap} />
            <Autocomplete className="z-20" onLoad={(e) => setOnLoad(e)} onPlaceChanged={(e) => handleChangePlace(e)}>
              <input
                className="border border-transparent shadow-sm text-sm text-ellipsis z-20 w-[50%] h-[2.5rem] rounded box-border px-[12px] absolute top-[.6rem] left-[40%]"
                id="loc-search-place"
                type="text"
                placeholder="Search place"
              />
            </Autocomplete>
            {lat > 0 || (lng > 0 && <Marker position={centerMap} />)}
          </GoogleMap>
        </div>
        <div className="w-full">
          <InputLabel>Latitude</InputLabel>
          <BaseInputValidation control={control} name="latitude" label="" errors={errors} required />
        </div>
        <div className="w-full">
          <InputLabel>Longitude</InputLabel>
          <BaseInputValidation control={control} name="longitude" label="" errors={errors} required />
        </div>
        <div className="w-full">
          <InputLabel>Address *</InputLabel>
          <StyledTextarea name="address" label="" placeholder="" {...register('address', { required: true })} minRows={3} />
        </div>
        
        <FormControl size="small">
          <InputLabel id="country" sx={{ background: '#fff' }}>
            Country
          </InputLabel>
          <Select
            name="country"
            value={selectedCountry}
            isSearchable={false}
            placeholder="-- select country --"
            onChange={(e) => {
              setSelectedCountry(e.target.value);
            }}
            style={{ height: 40 }}
          >
            <MenuItem value="">-</MenuItem>
            {allCountries?.map((opt) => (
              <MenuItem key={opt.name} value={opt.id}>
                {opt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <hr className="mt-2 mb-0" />
        <Stack direction="row" spacing={1} className="ml-auto">
          <Button variant="outlined" color="success" className="rounded-xl" onClick={onClose}>
            Cancel
          </Button>
          <button type="submit" className="px-4 bg-[#2C6D47] rounded-xl">
            <p className="text-white">{type === 'edit' ? 'Update' : 'Save'}</p>
          </button>
          {/* <Button type="submit" variant="contained">
            {type === 'edit' ? 'Update' : 'Save'}
          </Button> */}
        </Stack>
      </form>
    )
  );
}

/* eslint-disable */

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleMap, Marker } from '@react-google-maps/api';
import Pagination from '@mui/material/Pagination';
import { CircularProgress, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import useGet from '../../hooks/useGet';
import BaseApplyButton from '../Button/BaseApplyButton';
import BaseSubmitButton from '../Button/BaseSubmitButton';
import BaseSearchButton from '../Button/BaseSearchButton';
import { selectLocationList, selectLocationSingle, unSelectLocationList } from '../../store/slices/locationCrudSlice';

const containerStyle = {
  width: '100%',
  height: '200px',
};

const optionsMap = {
  scrollwheel: false,
};

export default function BaseLocationSingleViewer({ handleSubmit, handleCancel, fixBottom = true }) {
  const dispatch = useDispatch();
  const [dataLocations, setDataLocations] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const [isDataLoad, setIsDataLoad] = useState(true);
  const [countLocation, setCountLocation] = useState(0);
  const pageSize = 15;
  const count = Math.ceil(countLocation / pageSize);

  const locationPair = useSelector((state) => state.locationCrud.selected);
  const clientId = useSelector((state) => state.client.activeClient);

  const urlLocations = `locations/?client=${clientId}&page_size=${pageSize}&page=${page}&search=${search}&business_unit__isnull=false`;

  const handleChangeLocation = (e) => {
    const { value, checked } = e.target;
    console.log('🚀 ~ file: BaseLocationSingleViewer.jsx:41 ~ handleChangeLocation ~ checked:', checked);
    console.log('🚀 ~ file: BaseLocationSingleViewer.jsx:41 ~ handleChangeLocation ~ value:', value);
    if (checked) {
      dispatch(selectLocationSingle(value));
    } else {
      dispatch(resetLocation());
    }
  };

  const handleChangePage = (e, p) => {
    setIsDataLoad(false);
    setTimeout(() => {
      setIsDataLoad(true);
    }, 500);
    setPage(p);
  };

  const { isFetching } = useGet({
    url: urlLocations,
    validate: 'locations',
    onSuccess: (e) => {
      setDataLocations(e?.data?.results);
      setCountLocation(e.data.count);
      // dispatch(resetLocation());
    },
    isEnabled: isDataLoad,
  });

  const onSearchLocation = (event) => {
    setSearch(event.target.value);
  };

  return (
    <div className={fixBottom ? 'mb-20' : ''}>
      <div className=" py-4 my-4 border-y-2 ">
        <div className="flex gap-x-3 items-center">
          <BaseSearchButton onChange={onSearchLocation} setPage={setPage} setIsDataLoad={setIsDataLoad} />
          <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} />
        </div>
      </div>
      <div className="">
        {/* <div className="">{isFetching ? 'Reload Data' : ''}</div> */}
        {dataLocations && dataLocations.length > 0 ? (
          <div className="grid grid-cols-3 gap-3 w-full pt-5">
            {' '}
            {dataLocations?.map((data) => (
              <div key={data.id} className="w-full p-3 border rounded">
                <div className="w-full">
                  <div className="bg-blue-400 w-full py-1 px-3 relative flex gap-x-3">
                    {/* <input type="checkbox" name="lang" value={JSON.stringify(data)} onChange={handleChangeLocation} className=" w-5 h-5" />
                    <div className="text-white">{data?.name}</div> */}

                    <RadioGroup
                      row
                      className="text-white"
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={JSON.stringify(locationPair)}
                      onChange={handleChangeLocation}
                    >
                      <FormControlLabel value={JSON.stringify(data)} control={<Radio className="text-white" />} label={data?.name} />
                    </RadioGroup>
                  </div>
                </div>
                <div className="w-full">
                  <GoogleMap mapContainerStyle={containerStyle} center={{ lat: parseFloat(data?.latitude), lng: parseFloat(data?.longitude) }} zoom={10} options={optionsMap}>
                    {/* Child components, such as markers, info windows, etc. */}
                    <Marker position={{ lat: parseFloat(data?.latitude), lng: parseFloat(data?.longitude) }} />
                  </GoogleMap>
                </div>
              </div>
            ))}
            <div
              className={
                fixBottom
                  ? 'flex left-0 h-20 px-5 items-center gap-x-3 mt-6 bottom-0 absolute bg-white w-full overflow-x-hidden border-t justify-between'
                  : 'my-5 flex items-center justify-between'
              }
            >
              <div className=" flex items-center gap-x-5">
                <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChangePage} />
                <div className="">{`Total ${countLocation}`}</div>
              </div>
              <div className="flex gap-x-3 items-center">
                {handleCancel && <BaseSubmitButton text="Cancel" onClick={handleCancel} disabled={false} />}
                {handleSubmit && <BaseSubmitButton text="pair locations" onClick={handleSubmit} disabled={!Boolean(locationPair)} />}
              </div>
            </div>
          </div>
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  );
}

import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { createUsers } from '../../hooks/useUsersData';
import UserForm from '../../components/Form/User/UserForm';
import BZHelmet from '../../utils/BZHelmet';
import renderError from '../../utils/Error/renderError';
import UserFormSSO from '@/components/Form/User/UserFormSSO';

export default function UserAdd() {
  const qClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const isSSO = JSON.parse(localStorage.getItem('isSSO'));

  const { mutateAsync: submitUser } = useMutation(createUsers, {
    onSuccess: (res) => {
      if (res.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
        navigate(-1);
      } else {
        enqueueSnackbar('error', { variant: 'error' });
        renderError({ data: JSON.parse(res.request.response) });
      }
    },
  });

  const onSubmitUser = async (data) => {
    await submitUser(data);
    qClient.invalidateQueries('users');
  };
  return (
    <>
      <BZHelmet title="User Create" description="bz publish create user" />
      <div className="p-5 ">
        <div className="bg-white border p-5 shadow rounded">{isSSO ? <UserFormSSO onFormSubmit={onSubmitUser} /> : <UserForm onFormSubmit={onSubmitUser} />}</div>
      </div>
    </>
  );
}

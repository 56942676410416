import { useQueries, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { read, utils } from 'xlsx';
import { DataGrid } from '@mui/x-data-grid';
import { uniqueId } from 'lodash';
import { format, isValid, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

import BZHelmet from '@/utils/BZHelmet';

import report from '@/api/accounting/report';
import accounting from '@/api/accounting/accounting';
import transactions from '@/api/accounting/transactions';
import TransactionSheets from '@/components/Modal/Accounting/TransactionSheets';

const { getLedgers } = report();
const { getAccountCodes, getCostCenters, getCounterparts, getAccountingPeriodStatus, getCurrencies } = accounting();
const { importTransactions } = transactions();

const ItemError = () => {
  return (
    <span class="absolute flex h-3 w-3 top-1 right-1">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-bz-red opacity-75"></span>
      <span class="relative inline-flex rounded-full h-3 w-3 bg-bz-red"></span>
    </span>
  );
};

export default function ImportTransaction() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const client = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const usedClient = activeUser.is_superuser ? activeClient : client;
  const activeBusinessUnit = useSelector((state) => state.acc.activeBusinessUnit);

  const [selectedLedger, setSelectedLedger] = useState(null);
  const [dataTransactions, setDataTransactions] = useState(null);
  const [dataFilterTransactions, setDataFilterTransactions] = useState(null);

  const [fileTransaction, setFileTransactions] = useState(null);
  const [isErrorDataTransactions, setErrorDataTransactions] = useState(false);
  const [viewIndexSheet, setViewIndexSheet] = useState(false);
  const [viewSheets, setViewSheets] = useState(false);
  const [isTransactionFilter, setIsTransactionFilter] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { data: ledger } = useQuery({
    queryKey: ['ledgers', usedClient, activeBusinessUnit],
    enabled: !!usedClient && !!activeBusinessUnit,
    queryFn: () => getLedgers({ max_size: true, business_unit: activeBusinessUnit }),
    onSuccess: (res) => {
      if (res.data.results) {
        setSelectedLedger(res.data.results[0].id);
      }
    },
    refetchOnWindowFocus: false,
  });

  const queryResult = useQueries({
    queries: [
      {
        queryKey: ['accountCodes', usedClient],
        queryFn: () => getAccountCodes({ client: usedClient, max_size: true, ordering: 'code' }),
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['costCenters', usedClient, activeBusinessUnit],
        queryFn: () => getCostCenters({ business_unit: activeBusinessUnit, ordering: 'code' }),
        enabled: !!usedClient && !!activeBusinessUnit,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['counterparts', usedClient],
        queryFn: () => getCounterparts({ client: usedClient, max_size: true, ordering: 'code' }),
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['accountingPeriods', usedClient],
        queryFn: () => getAccountingPeriodStatus({ status__in: ['Open', 'Re-opened'], ledger: selectedLedger || 1, max_size: true }),
        enabled: !!usedClient && !!selectedLedger,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['currencies', usedClient],
        queryFn: () => getCurrencies({ client: usedClient, max_size: true, ordering: 'name' }),
        enabled: !!usedClient,
        refetchOnWindowFocus: false,
      },
    ],
  });

  const [a, b, c, d, e] = queryResult;
  const { data: accData } = a;
  const { data: ccData } = b;
  const { data: cpData } = c;
  const { data: acpData } = d;
  const { data: currencyData } = e;

  const accounts = useMemo(() => accData?.data?.results, [accData]);
  const costCenters = useMemo(() => ccData?.data?.results, [ccData]);
  const counterparts = useMemo(() => cpData?.data?.results, [cpData]);
  const accPeriods = useMemo(() => acpData?.data?.results, [acpData]);
  const currencies = useMemo(() => currencyData?.data?.results, [currencyData]);

  const isLoaded = queryResult.every((query) => query.isSuccess);
  // account, center, accper, currency, rate

  const onReadFile = (file) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const workbook = read(reader.result, { type: 'buffer' });

      const worksheetName = workbook.SheetNames[viewIndexSheet || 0];
      const worksheet = workbook.Sheets[worksheetName];
      const dataXlsx = utils.sheet_to_json(worksheet);

      const headers = utils.sheet_to_json(worksheet, { header: 1, defval: '' })[0];

      const rowKey = [
        'Account',
        'Center',
        'Counterpart',
        'Accper',
        'LE_CCD',
        'Base Currency Rate',
        'Journal Number',
        'Descriptions',
        'Recref',
        'Debit',
        'Credit',
        'LE_CCD',
        'Date',
        'Base Amount',
      ];
      const checkHeader = rowKey.every((k) => headers.includes(k));
      const headerErrors = rowKey.filter((k) => !headers.includes(k));

      if (!checkHeader) {
        setErrorDataTransactions(true);
        setDataTransactions([]);
        setDataFilterTransactions([]);
        setViewSheets(false);
        setViewIndexSheet(false);
        enqueueSnackbar('There is an error on your file', { variant: 'error' });
        if (headerErrors.length) {
          headerErrors.forEach((e) => {
            enqueueSnackbar(`Please check column ${e}`, { variant: 'error' });
          });
        }
      } else {
        let isDataError = false;

        const reFormatData = dataXlsx.map((d, i) => {
          const transactions = {};
          const lnTransactions = {};
          transactions.id = uniqueId();
          if (!('Counterpart' in d)) lnTransactions.Counterpart = '';
          Object.keys(d).forEach((key) => {
            const newKey = key.trimStart().trimEnd();
            const k = key;
            if (['Date', 'Journal Number', 'Descriptions'].includes(k)) {
              transactions[newKey] = d[k];
            } else {
              switch (k) {
                case 'Account':
                  let errorAcc = false;
                  const acc = accounts.find((a) => String(a.code) === String(d['Account']));
                  if (!acc) {
                    errorAcc = 'Account code not found';
                    isDataError = true;
                    transactions['error'] = true;
                  }
                  lnTransactions[newKey] = acc ? { ...acc, error: errorAcc } : { code: d[k] || '', error: errorAcc };
                  break;
                case 'Center':
                  let errorCc = false;
                  const cc = costCenters.find((c) => {
                    const isCc = String(c.code) === String(d['Center']);
                    return isCc;
                  });
                  if (!cc) {
                    errorCc = 'Cost center not found';
                    isDataError = true;
                    transactions['error'] = true;
                  }

                  lnTransactions[newKey] = cc ? { ...cc, error: errorCc } : { code: d[k] || '', error: errorCc };
                  break;
                case 'Counterpart':
                  let errorCp = false;
                  const cp = counterparts.find((c) => String(c.code) === String(d['Counterpart']))
                  if (!!d[k] && !cp) {
                    errorCp = 'Counterpart not found';
                    isDataError = true;
                    transactions['error'] = true;
                  }
                  // lnTransactions[newKey] = counterparts.find((c) => String(c.code) === String(d['Counterpart'])) || d[k];
                  lnTransactions[newKey] = cp ? { ...cp, error: errorCp } : { code: d[k] || '', error: errorCp };
                  break;
                case 'Accper':
                  let activePeriod = '';
                  let error = false;
                  const acp = accPeriods.find((c) => {
                    const acp = c.accounting_period;
                    const ap = `${String(acp.year).substring(2)}${String(acp.month).length === 1 ? `0${acp.month}` : acp.month}`;
                    const isPeriod = ap === String(d['Accper']);
                    if (isPeriod) {
                      activePeriod = ap;
                    }
                    return isPeriod;
                  });
                  if (!acp) {
                    error = 'Accounting Period not found or inactive';
                    isDataError = true;
                    transactions['error'] = true;
                  }
                  lnTransactions[newKey] = acp ? { ...acp, period: activePeriod, error } : { period: d[k] || '', error };

                  break;
                case 'LE_CCD':
                  lnTransactions[newKey] = currencies.find((c) => String(c.code) === String(d['LE_CCD'])) || d[k];

                  break;
                default:
                  lnTransactions[newKey] = d[k];
                  break;
              }
            }
          });
          return { ...transactions, line_details: lnTransactions };
        });
        setViewSheets(false);
        setViewIndexSheet(false);
        setErrorDataTransactions(isDataError);
        setDataTransactions(reFormatData);
        // setDataFilterTransactions(reFormatData);
        setDataFilterTransactions(reFormatData.filter((t) => !!t.error));
      }
    };
  };

  const {
    getRootProps,
    getInputProps,
    open: openDialog,
  } = useDropzone({
    noClick: true,
    multiple: false,
    onDrop: async (acceptedFiles) => {
      let selectedFile = acceptedFiles[0];

      let reader = new FileReader();
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = () => {
        const workbook = read(reader.result, { type: 'buffer' });

        if (typeof viewIndexSheet !== 'number' && workbook.SheetNames.length > 1) {
          setViewSheets(workbook.SheetNames);
          setFileTransactions(selectedFile);
        } else {
          onReadFile(selectedFile);
          setFileTransactions(selectedFile);
        }
      };
    },
  });

  const submitTransaction = () => {
    if (!fileTransaction || isErrorDataTransactions || !selectedLedger || isLoading) return;
    setIsLoading(true);
    importTransactions(selectedLedger, fileTransaction)
      .then(() => {
        enqueueSnackbar('New transaction added' || 'Success', { variant: 'success' });
        setIsLoading(false);
        navigate('/transaction');
      })
      .catch((err) => {
        const er = err.response;
        setIsLoading(false);
        if (er && typeof er !== 'string' && Object.keys(er.data).length && !er.data.includes('html')) {
          Object.keys(er.data).forEach((e) => {
            if (er.data[e]?.length) {
              enqueueSnackbar(`${e} - ${er.data[e]}` || `${er.status} - ${er.statusText}` || 'Error', { variant: 'error' });
            }
          });
        } else {
          enqueueSnackbar(`${er.status} - ${er.statusText}`, { variant: 'error' });
        }
      });
  };

  const filterTransaction = () => {
    let transactions = [];
    if (!isTransactionFilter) {
      transactions = [...dataTransactions].filter((t) => !!t.error);
    } else {
      transactions = [...dataTransactions];
    }

    setDataFilterTransactions(transactions);
    setIsTransactionFilter(!isTransactionFilter);
  };

  const columns = [
    {
      field: 'Date',
      headerName: 'Date',
      minWidth: 96,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => {
        const currDate = params.row.Date;
        return isValid(new Date(currDate)) ? `${format(new Date(currDate), 'yyyy-MM-dd')}` : 'Invalid Date';
      },
    },
    {
      field: 'Journal Number',
      headerName: 'Journal number',
      description: 'Journal number',
      sortable: false,
      width: 144,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'Descriptions',
      headerName: 'Descriptions',
      sortable: true,
      minWidth: 114,
      flex: 1,

      renderCell: (params) => (
        <Tooltip title={params.row.Descriptions}>
          <span className="truncate">{params.row.Descriptions || ''}</span>
        </Tooltip>
      ),
    },
    {
      field: 'line_details.Account',
      headerName: 'Account',
      sortable: true,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => {
        const err = params.row.line_details.Account?.error;
        const dt = <span title={err || ''}>{`${params.row.line_details.Account?.code || ''}`}</span>;
        if (err) {
          return (
            <>
              <ItemError />
              {dt}
            </>
          );
        }
        return dt;
      },
      cellClassName: (params) => {
        if (params.row.line_details.Account?.error) {
          return 'relative flex !border-b-bz-red !border-b border border-bz-red';
        }
      },
    },
    {
      field: 'line_details.Center',
      headerName: 'Center',
      sortable: true,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => {
        const err = params.row.line_details.Center?.error;
        const dt = <span title={err || ''}>{`${params.row.line_details.Center?.code || ''}`}</span>;
        if (err) {
          return (
            <>
              <ItemError />
              {dt}
            </>
          );
        }
        return dt;
      },
      cellClassName: (params) => {
        if (params.row.line_details.Center?.error) {
          return 'relative flex !border-b-bz-red !border-b border border-bz-red';
        }
      },
    },
    {
      field: 'line_details.Counterpart',
      headerName: 'Counterpart',
      sortable: true,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => `${params.row.line_details.Counterpart?.code || ''}`,
    },
    {
      field: 'line_details.Recref',
      headerName: 'Recref',
      sortable: true,

      renderCell: (params) => {
        const recref = params.row.line_details.Recref;
        return `${recref && recref !== '0' ? recref : ''}`;
      },
    },
    {
      field: 'line_details.Accper',
      headerName: 'Accper',
      sortable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const err = params.row.line_details.Accper?.error;
        const dt = <span title={err || ''}>{`${params.row.line_details.Accper?.period || params.row.line_details.Accper || ''}`}</span>;
        if (err) {
          return (
            <>
              <ItemError />
              {dt}
            </>
          );
        }
        return dt;
      },
      cellClassName: (params) => {
        if (params.row.line_details.Accper?.error) {
          return 'relative flex !border-b-bz-red !border-b border border-bz-red';
        }
      },
    },
    {
      field: 'line_details.Debit',
      headerName: 'Debit',
      sortable: true,
      headerAlign: 'center',
      align: 'right',
      minWidth: 164,
      flex: 1,
      renderCell: (params) => `${params.row.line_details.Debit || ''}`,
    },
    {
      field: 'line_details.Credit',
      headerName: 'Credit',
      sortable: true,
      headerAlign: 'center',
      align: 'right',
      minWidth: 164,
      flex: 1,
      renderCell: (params) => `${params.row.line_details.Credit || ''}`,
    },
    {
      field: 'line_details.LE_CCD',
      headerName: 'LE_CCD',
      sortable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => `${params.row.line_details.LE_CCD?.code || ''}`,
    },
    {
      field: `line_details['Base Currency Rate']`,
      headerName: 'Base Currency Rate',
      description: 'Base Currency Rate',
      sortable: true,
      headerAlign: 'center',
      align: 'right',
      minWidth: 164,
      flex: 1,
      renderCell: (params) => `${params.row.line_details['Base Currency Rate'] || ''}`,
    },
    {
      field: `line_details['Base Amount']`,
      headerName: 'Base Amount',
      sortable: true,
      headerAlign: 'center',
      align: 'right',
      minWidth: 164,
      flex: 1,
      renderCell: (params) => `${params.row.line_details['Base Amount'] || ''}`,
    },
  ];

  const ledgers = ledger?.data?.results || [];
  return (
    <>
      <BZHelmet title="Transaction Import" description="bz publish import transaction" />
      <div className="w-full h-full flex flex-col flex-1 p-8 gap-4">
        <h1 className="text-2xl font-bold mb-8">Import Transaction</h1>
        <div className="relative w-auto flex ml-auto">
          <FormControl size="small">
            <InputLabel
              id="select-ledger"
              shrink={!!selectedLedger}
              sx={{
                top: '0px',
                '&.Mui-focused': { top: -16, background: '#fff' },
                '&.MuiInputLabel-shrink': { top: 0, background: '#fff' },
              }}
            >
              Ledger
            </InputLabel>
            <Select
              sx={{ p: 0.5, border: 0 }}
              labelId="select-ledger"
              id="select-ledger"
              name="ledger"
              value={selectedLedger}
              label="Ledger"
              placeholder="Ledger"
              onChange={(event) => {
                setSelectedLedger(event.target.value);
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 'calc(100% - 40rem)!important',
                    marginTop: '5px',
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>Ledger</em>
              </MenuItem>
              {ledgers.map((led) => (
                <MenuItem key={led.id} value={led.id}>
                  {led.code} - {led.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {!dataFilterTransactions && (
          <div className="mx-auto">
            {!isLoaded && <CircularProgress />}
            {isLoaded && (
              <div {...getRootProps()}>
                <button className="bz-btn" onClick={openDialog}>
                  <input {...getInputProps()} />
                  Import File
                </button>
              </div>
            )}
          </div>
        )}
        {dataFilterTransactions && (
          <div className="flex relative">
            <button className="bz-btn" onClick={filterTransaction}>
              {!isTransactionFilter ? 'Show Only Transaction Error' : 'Show All Transaction'}
            </button>
            <div className="ml-auto flex gap-2">
              <div {...getRootProps()}>
                <button className="bz-btn" onClick={openDialog}>
                  <input {...getInputProps()} />
                  Import File
                </button>
              </div>
              <button className="bz-btn" onClick={submitTransaction} disabled={isErrorDataTransactions || !fileTransaction || isLoading}>
                Submit
              </button>
            </div>
            {isLoading && (
              <div className="absolute z-10 left-1/2 top-28 flex justify-center">
                <CircularProgress />
              </div>
            )}
          </div>
        )}
        {dataFilterTransactions && (
          <DataGrid
            rows={dataFilterTransactions}
            columns={columns}
            rowsPerPageOptions={[10, 20, 50]}
            className="m-auto w-full"
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            localeText={{ noRowsLabel: !isTransactionFilter ? 'No rows' : 'No error transaction found' }}
          />
        )}
      </div>
      {!!viewSheets && (
        <TransactionSheets
          open={!!viewSheets}
          close={() => {
            setViewSheets(false);
            setViewIndexSheet(false);
            setFileTransactions(null);
          }}
          action={() => onReadFile(fileTransaction)}
          onChanges={setViewIndexSheet}
          data={viewSheets}
        />
      )}
    </>
  );
}

/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Button, InputBase, Tooltip, CardActionArea, CardActions, Card, CardContent, Typography, Pagination } from '@mui/material';
import { fetchSectionCatalogue, fetchSectionList } from '../../../hooks/useSettingsBzData';
import { handleModalNested } from '../../../store/slices/modalSlice';
import ModalWrapper from '../../ui/ModalWrapper';
import { SyncAlt } from '@mui/icons-material';
import { deleteSection, updateIndexSection, usePairSection, useUpdateSectionWidget } from '../../../hooks/useStructureData';
import request from '../../../helper/axiosUtil';

export default function TransferSectionForm({ defaultValues, onFormSubmit, sectionList }) {
  // console.log(defaultValues);
  const dispatch = useDispatch();
  const qClient = useQueryClient();
  const clientId = useSelector((state) => state.client.activeClient);
  const [page, setPage] = useState(1);
  const [sectionListData, setSectionListData] = useState(null);
  const [expandImage, setExpandImage] = useState({ id: null, open: false });
  const [countSection, setCountSection] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [isSelectedSection, setIsSelectedSection] = useState(null);

  const count = Math.ceil(countSection / 9);

  useQuery(['sections', { clientId, page }], fetchSectionList, {
    onSuccess: (e) => {
      setSectionListData(e.data.results);
      setCountSection(e.data.count);
    },
  });

  let isAutoFetching;
  const sectionId = isSelectedSection;
  if (sectionId) {
    isAutoFetching = true;
  } else isAutoFetching = false;
  const { data: SectionData } = useQuery(['section', { sectionId }], fetchSectionCatalogue, { enabled: isAutoFetching });

  const handleExpandImage = (id) => {
    setExpandImage({ id, open: !expandImage.open });
    if (expandImage.id === id && expandImage.open === false) {
      setExpandImage({ id: null, open: false });
    } else setExpandImage({ id, open: false });
  };

  const handleChangePage = (e, p) => {
    setPage(p);
  };

  const handleSetSection = (data) => {
    setIsSelectedSection(data);
  };

  const reorderSection = sectionList?.map((sec) => sec?.id);

  const { mutate: pairSection, data: pairedSectionData } = usePairSection();

  const useUpdateSecWid = useMutation((datas) => {
    return (
      Promise.allSettled(
        datas?.map((data) =>
          request({
            url: `/section_widgets/${data.id}/`,
            method: 'put',
            data: {
              name: data?.name,
              section: pairedSectionData?.data?.id,
              widget: data?.widget?.id,
            },
          })
        )
      ),
      {
        onSuccess: () => {
          destroySections({ section_ids: [defaultValues?.id] });
        },
      }
    );
  });

  const { mutateAsync: updateIndexSections } = useMutation(updateIndexSection, {
    onSuccess: (e) => {
      qClient.invalidateQueries('pageSection');
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onUpdateIndexSection = async (data) => {
    await updateIndexSections(data);
  };

  const { mutateAsync: destroySections } = useMutation(deleteSection, {
    onSuccess: async () => {
      await qClient.invalidateQueries('pageSection');
    },
  });

  useEffect(() => {
    if (pairedSectionData) {
      reorderSection.splice(reorderSection.indexOf(defaultValues?.id), 1, pairedSectionData?.data?.id);

      useUpdateSecWid.mutateAsync(defaultValues?.section_widgets);
      onUpdateIndexSection({ section_ids: reorderSection });
    }
  }, [pairedSectionData]);

  const onPairSection = (data) => {
    const section_languages = defaultValues?.section_languages.map((sec, curr) => {
      return {
        language: sec?.language,
        title: sec?.title,
        name: sec?.name,
      };
    });

    pairSection({
      name: defaultValues?.name,
      menu: defaultValues?.menu,
      page: defaultValues?.page,
      parent: isSelectedSection,
      section_languages,
      section_type: defaultValues?.section_type,
      is_draft: defaultValues?.is_draft,
    });
  };

  return (
    <div>
      <div className="flex  gap-5 ">
        <div className="w-5/12   space-y-5">
          <div className="uppercase font-bold">Current Section</div>
          <div className="border-4 p-4 h-[630px] border-blue-500 rounded w-full space-y-5">
            <div className=" text-center font-semibold">{defaultValues?.name}</div>
            <img src={defaultValues?.screenshot} alt="" className="w-full h-[285px] object-cover shadow rounded" />
            <div className="uppercase font-bold">Section Widgets </div>
            <div className="flex flex-wrap gap-2">
              {defaultValues?.section_widgets?.map((sw) => {
                const { id, name } = sw;
                return (
                  <div className="border-2 rounded p-2 ">
                    <div className="">{`ID : ${id}`}</div>
                    <div className="max-w-[150px]">{`Name : ${name}`}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-2/12 !h-full items-center justify-center ">
          <div className="absolute top-1/2 translate-y-1/2 left-1/2 -translate-x-1/2">
            <button onClick={onPairSection} type="button" className="space-x-3 items-center bg-">
              <span className="">Transfer</span>
              <SyncAlt />
            </button>
          </div>
        </div>
        <div className="w-5/12   space-y-5">
          <div className="uppercase font-bold">New Section</div>
          <div className="border-4 p-4 h-[630px] border-green-500 rounded w-full space-y-5">
            <div className=" text-center font-semibold">{SectionData?.data?.name}</div>
            <img src={SectionData?.data?.screenshot || 'https://admin.bzpublish.com/img/bz-publish.svg'} alt="" className="w-full h-[285px] object-cover shadow rounded" />
            <button type="button" onClick={() => dispatch(handleModalNested({ modalIdNested: '', componentNameNested: 'chooseSection' }))} className="">
              Choose Section
            </button>
          </div>
        </div>
      </div>
      <ModalWrapper componentName="chooseSection" header="Choose Section" maxWidth="lg" isNested>
        <div className="grid grid-cols-3 gap-5">
          {sectionListData?.map((s) => (
            <div onClick={() => handleSetSection(s?.id)} key={s?.id} className="w-full ">
              <Card className="w-full relative">
                <div className={[isSelectedSection === s?.id ? 'bg-green-600 text-white' : 'bg-white text-black', 'p-2'].join(' ')}>
                  {/* <input type="checkbox" name="lang" value={s.id} onChange={handleSectionDel} className="w-5 h-5 mr-2" /> */}
                  <div className="">{s.name}</div>
                </div>
                <CardActions className="ml-8 gap-x-3 flex items-center">
                  <Typography gutterBottom className="" component="div">
                    {/* {sType(s?.s_type)} */}
                  </Typography>
                </CardActions>
                <CardActionArea>
                  <CardContent className="">
                    <div className="w-full p-3">
                      <Tooltip title={expandImage.id === s.id ? 'Collapse Image' : 'Expand Image'}>
                        <img
                          onClick={() => handleExpandImage(s.id)}
                          src={`${s.screenshot ?? 'https://admin.bzpublish.com/img/bz-publish.svg'}`}
                          alt=""
                          className={`${expandImage.id === s.id ? 'h-full' : 'h-10'}  w-full object-cover`}
                        />
                      </Tooltip>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between gap-x-3 mt-6">
          <div className="flex items-center  gap-x-3">
            <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChangePage} />
            <div className="">{`Total ${countSection}`}</div>
          </div>
          <button
            onClick={() => dispatch(handleModalNested({ modalIdNested: '', componentNameNested: '' }))}
            type="button"
            className="bg-blue-400 p-2 text-white font-bold rounded"
          >
            Submit
          </button>
        </div>
      </ModalWrapper>
    </div>
  );
}

/* eslint-disable */

import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import request from '../helper/axiosUtil';
import { handleModal } from '../store/slices/modalSlice';
import qs from 'qs';

export const fetchAssets = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({
    url: `/assets/?client=${clientId}&asset_type=&has_languages=&max_size=true`
  });
};

export const fetchFilterAssets = async ({ queryKey }) => {
  const [, { clientId, pageSize, page, assetType, search, assetCategories }] = queryKey;
  let url = `/assets/?client=${clientId}&page_size=${pageSize}&page=${page}&asset_type=${assetType}&search=${search}`
  const categories = assetCategories?.filter(Boolean)
  if (categories && categories.length) {
    const category = categories.map((c) => `&categories=${c}`)
    url = `${url}${category.join('')}`
  }
  return request({
    url
  });
};


export const fetchFilterAssetsNew = async ({ queryKey }) => {
  const transQuery = queryKey[1]?.queryKey;
  const url = `/assets/${qs.stringify(transQuery, { addQueryPrefix: true, arrayFormat: 'repeat' })}`;
  return request({
    url
  });
};


export const fetchAllAssets = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({
    url: `/assets/?client=${clientId}&max_size=true`
  });
};

export const fetchAssetsType = async () => request({
  url: `/asset_types/?language=1`,
});

export const fetchAssetsCategory = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({
    url: `/category_assets/?client=${clientId}&max_size=true`,
  })
};

export const fetchAssetDetail = async ({ queryKey }) => {
  const [, { assetId }] = queryKey;
  return request({ url: `/assets/${assetId}/` });
};

export const createAsset = async (data) => request({
  url: '/assets/',
  method: 'post',
  data,
  headers: { 'Content-Type': 'application/json' },
});


export const deleteAssets = async (data) => request({
  url: `/assets/delete_assets/`,
  method: 'delete',
  data
});


export const fetchCategory = async ({ queryKey }) => {
  const [, { clientId }] = queryKey;
  return request({ url: `/category_assets/?max_size=true&client=${clientId}` });
};



const createCategory = async (data) =>
  request({
    url: '/category_assets/',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
  });

export const useAddCategory = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(createCategory, {
    /** Optimistic Update Start */
    onSuccess: (e) => {
      //Invalidates cache and refetch
      if (e.request.status === 201) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      //queryClient.invalidateQueries('AssetCategories');
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData('AssetCategories', context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('AssetCategories');
    },
    //onSuccess
    /** Optimistic Update End */
  });
};

const fetchCategoryDetail = ({ queryKey }) => {
  const dataId = queryKey[1];//destructured dataId param passed as prop where queryKey[1] is the id
  return request({ url: `/category_assets/${dataId}/` });
};

export const useCategoryDetail = (dataId) => {
  const queryClient = useQueryClient();
  const dataModal = useSelector((state) => state.modal);


  return useQuery(['AssetCategories', dataId], fetchCategoryDetail, {
    initialData: () => {
      const collection = queryClient.getQueryData('AssetCategories')?.data?.find((collections) => collections.id === parseInt(dataId, 10));

      if (collection) {
        return { data: collection };
      }
      return undefined;
    },
    enabled: dataModal.componentName === 'editArtCategory',
  });
};


const updateCategory = (id, data) => {
  return request({ url: `/category_assets/${id}/`, method: 'patch', data });
};

export const useUpdateCategory = (id) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation((data) => updateCategory(id, data), {
    onMutate: async (newData) => {
      //dispatch(isReactLoading());

      await queryClient.cancelQueries(['AssetCategories', newData.id]);
      const previousData = queryClient.getQueryData(['AssetCategories', newData.id]);
      queryClient.setQueryData(['AssetCategories', newData.id], newData);
      return { previousData, newData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(['AssetCategories', context.newData.id], context.previousData);
    },
    onSettled: (newData) => {
      queryClient.invalidateQueries(['AssetCategories', newData.id]);
      dispatch(handleModal({ modalId: '' }));
    },
  });
};


const deleteCategory = async (dataId) =>
  request({
    url: `/category_assets/${dataId}`,
    method: 'delete',
  });

const useDeleteCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCategory, {
    onSuccess: (_data) => {
      if (_data.status === 204) {
        swal('Poof! Your imaginary file has been deleted!', { icon: 'success' });
      } else
        swal('Error');
      //console.log('data', _data);
      //dispatch(isDeleteOn({ statusCode: _data.status, statusMessage: _data.message }));
      queryClient.invalidateQueries('AssetCategories');
    },
    onSettled: () => {
      queryClient.invalidateQueries('AssetCategories');
    },
    onError: (err) => {
      console.log('err', err);
    },
  });
};

export const useDestroyCategory = (id) => {
  const { mutate: deleteCat } = useDeleteCategory(id);


  return async (idData) => {
    await swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCat(idData);
      } else {
        swal('Your imaginary file is safe!');
      }
    });
    //dispatch(isDeleteOn({ statusCode: '', statusMessage: '' }));
  };
};

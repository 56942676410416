import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import BaseInputValidation from '../../Input/Base/BaseInputValidation';
import { handleModal } from '../../../store/slices/modalSlice';
import BaseAutocompleteValidation from '../../Input/Base/BaseAutocompleteValidation';
import crmApi from '../../../api/CRM/CRMCustomers';

const { getCRMProfileAttribute, actionCRMProjectDetailProfile, createCRMProjectProfile } = crmApi();
const staticSchema = yup.object().shape({ name: yup.string().required('Name is a required field') });

export default function AttributeProfile({ profileSelected, refetch }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const activeClient = useSelector((state) => state.client.activeClient);
  const isModal = useSelector((state) => state.modal);

  const [attributes, setAttributes] = useState(null);

  const {
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(staticSchema),
    defaultValues: {},
  });

  useQuery({
    queryKey: ['crm-project-profiles-detail', profileSelected?.id],
    enabled: !!profileSelected,
    queryFn: () => actionCRMProjectDetailProfile(profileSelected?.id, 'get'),
    onSuccess: (res) => {
      if (res.data) {
        setValue('name', res.data.name);
        // setValue('attributes', res.data.attributes || []);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { data: dataAttribute, isFetched } = useQuery({
    queryKey: ['crm-profile-attributes', 'crm-project-profiles-detail', profileSelected?.id],
    enabled: !!profileSelected,
    queryFn: () => getCRMProfileAttribute({ client: activeClient, max_size: true }),
    onSuccess: (res) => {
      setAttributes(res || []);
      setValue('attributes', res || []);
    },
    refetchOnWindowFocus: false,
  });

  const onCloseModal = () => {
    dispatch(handleModal({ modalId: '', componentName: '' }));
  };

  const onSubmit = (dataForm) => {
    const data = { ...dataForm, client: activeClient };
    if (dataForm?.profile?.id) {
      data.profile = dataForm.profile.id;
    }
    if (!profileSelected) {
      createCRMProjectProfile(data)
        .then(() => {
          enqueueSnackbar('Successfully create new profile', { variant: 'Success' });
          refetch();
          onCloseModal();
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.response?.data?.details ? err.response?.data?.details[0] : 'Something went wrong', { variant: 'Error' });
        });
    }
  };

  useEffect(() => {
    if (profileSelected) {
      const { name } = profileSelected;
      setValue('name', name);
    }
  }, [profileSelected, setValue]);

  useEffect(() => {
    if (!isModal.isOpen) {
      setAttributes(null);
      reset({
        // config
        name: '',
        attributes: '',
      });
    }
  }, [isModal.isOpen, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="h-full flex flex-col gap-4">
      <BaseInputValidation control={control} name="name" label="Name" errors={errors} required />
      {isFetched && attributes && (
        <BaseAutocompleteValidation
          control={control}
          name="attributes"
          label="Attributes"
          errors={errors}
          options={dataAttribute || []}
          optionLabel={['attribute', 'name']}
          config={{ multiple: true, classes: 'min-h-10' }}
        />
      )}
      <Divider />
      <Stack direction="row" spacing={1} className="ml-auto">
        <Button variant="outlined" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Save
        </Button>
      </Stack>
    </form>
  );
}

import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import _ from 'lodash';
import { useProductContext } from '../ProductContext';

function formatNominal(number, fixedDecimal = true) {
  if (isNaN(number)) {
    return '-';
  }

  const formattedNumber = fixedDecimal ? Number(number).toFixed(2) : number.toString();
  return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function TabProjection() {
  const { pricingProjection, isFetchingProjection, bulkProcessProjectionAddOns, pricingManagerCurr, marketManagers } = useProductContext();

  const costsType = useMemo(
    () => [
      { name: 'Total Item Cost', value: 'item_cost' },
      { name: 'Contingency', value: 'contingency_cost' },
      { name: 'Overhead', value: 'overhead_cost' },
      { name: 'Profit', value: 'profit_value' },
      { name: 'Total', value: 'system_total' },
      { name: 'Selling Price', value: 'selling_price' },
    ],
    []
  );

  const [addOnsData, setAddOnsData] = useState([]);
  const [date, setDate] = useState(0);
  const [isDataChange, setIsDataChange] = useState(false);

  useEffect(() => {
    setDate(pricingProjection.product_price.length);
  }, [pricingProjection?.product_price]);

  useEffect(() => {
    if (isFetchingProjection) {
      setAddOnsData([]);
    } else if (pricingProjection?.add_on_prices) {
      const sortedAddOns = pricingProjection.add_on_prices.map((addOn) => {
        return {
          ...addOn,
          prices: addOn.prices.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA;
          }),
        };
      });
      setAddOnsData(sortedAddOns || []);
    }
    setIsDataChange(false);
  }, [isFetchingProjection, pricingProjection]);

  const changingPrice = (event, idx, addOnsId, addOnsName) => {
    const value = event;
    const cloneData = _.cloneDeep(addOnsData);
    const specificData = cloneData.find((dt) => dt.add_on_name === addOnsName) || null;

    if (specificData) {
      if (!specificData.prices) {
        specificData.prices = [];
      }

      const newPriceEntry = pricingProjection?.product_price.map((dt, id) => {
        const matchedPrice = specificData.prices.find((price) => price?.date === dt.date) || null;

        return {
          price: matchedPrice ? matchedPrice.price.toString() : '',
          date: dt.date,
          change: false,
          ...(matchedPrice?.id ? { id: matchedPrice.id } : {}),
          ...(matchedPrice?.change ? { change: matchedPrice.change } : { change: false }),
        };
      });

      newPriceEntry.forEach((entry, index) => {
        if (index <= idx) {
          entry.price = value;
          entry.change = true;
        }
      });

      specificData.prices = newPriceEntry;
      specificData.change = true;

      const updatedAddOns = cloneData.map((item) => (item.add_on_name === addOnsName ? specificData : item));

      setAddOnsData(updatedAddOns);
      setIsDataChange(true);
    }
  };

  console.log({ addOnsData });

  const onChecking = () => {
    const cloneData = _.cloneDeep(addOnsData);
    // console.log({cloneData});

    const specificData = cloneData.filter((dt) => dt?.change) || [];
    // const { prices } = specificData;
    // const slicingPrices = prices.slice(0, priceId + 1);
    // console.log({specificData});

    bulkProcessAddsOns(specificData);
  };

  const bulkProcessAddsOns = async (data) => {
    console.log({ data });

    const dataPrices = data.map((dt) => {
      const sortedPrices = dt.prices.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });

      const filteredPrices = [];
      let hasValidPrice = false;
      let tempPrice = '';

      for (const item of sortedPrices) {
        const isEmpty = item.price === '';
        item.product_add_on = dt.add_on_id;
        item.market_manager = marketManagers.find((dt) => dt.market_name === pricingProjection?.market_manager_name)?.id;
        // item.market_manager = pricingProjection?.market_manager_name?.
        item.currency = pricingManagerCurr;

        if (isEmpty) {
          if (!hasValidPrice) {
            continue; //lewati item yg bersangkutan
          } else {
            item.price = tempPrice;
          }
        }
        if (!isEmpty) {
          hasValidPrice = true;
          tempPrice = item.price;
        }

        filteredPrices.push(item);
      }

      const cleanedPrices = filteredPrices.map((price) => {
        const { change, date, ...rest } = price;
        return { ...rest, start_date: date };
      });

      return {
        ...dt,
        prices: cleanedPrices,
      };
    });

    try {
      const newData = dataPrices.flatMap((item) => item.prices);

      bulkProcessProjectionAddOns(newData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="space-y-4">
      {!isFetchingProjection ? (
        <div className="overflow-x-auto space-y-4">
          <Table className="border">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="!font-bold" width={500}>
                  Costs
                </TableCell>
                {pricingProjection?.product_price.map((dt, idx) => (
                  <TableCell key={idx} align="center" className="!font-bold">
                    {dt.date}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {costsType.map((dt, idx) => (
                <TableRow className={`${dt.value === 'selling_price' ? 'font-bold' : ''} ${dt.value === 'system_total' && 'italic'}`} key={idx}>
                  <TableCell align="center" className={`${dt.value === 'selling_price' ? 'font-bold' : ''} ${dt.value === 'system_total' && 'italic'}`}>
                    {dt.name}
                  </TableCell>
                  {pricingProjection?.product_price.map((ppro, idx) => (
                    <TableCell key={idx} className={`${dt.value === 'selling_price' ? 'font-bold' : ''} ${dt.value === 'system_total' && 'italic'}`} align="right">
                      {formatNominal(ppro[dt.value])}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {addOnsData?.length > 0 && (
            <Table className="border" style={{ marginTop: '1rem' }}>
              <TableBody>
                <TableRow>
                  <TableCell align="center" className="!font-bold">
                    Add-Ons
                  </TableCell>
                  {date > 0 && <TableCell align="center" colSpan={date} />}
                </TableRow>
                {addOnsData.map((dt, idx) => (
                  <TableRow key={idx}>
                    <TableCell align="center" width={500}>
                      {dt.add_on_name}
                    </TableCell>
                    {pricingProjection?.product_price.map((ss, i) => {
                      const matchedPrice = dt.prices.find((price) => price?.date === ss.date);

                      return (
                        <TableCell key={i} align="right">
                          <TextField
                            value={matchedPrice ? matchedPrice.price : ''}
                            InputProps={{
                              inputProps: { style: { textAlign: 'right' } },
                            }}
                            size="small"
                            onChange={(e) => changingPrice(e.target.value, i, idx, dt.add_on_name)}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <div className="w-full flex justify-end">
            <button
              className={`
                  flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white h-[40px]
                  ${isDataChange ? 'bg-[#2C6D47] cursor-pointer ' : 'bg-[#2c6d473e] cursor-default'}
                `}
              name="manageOperators"
              type="button"
              disabled={!isDataChange}
              onClick={() => onChecking()}
            >
              Update
            </button>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default TabProjection;

/* eslint-disable */

import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Button, Checkbox, CircularProgress, TableCell, TableRow } from '@mui/material';
import CustomTable from '../../components/Table/CustomTable';
import ModalWrapper from '../../components/ui/ModalWrapper';
import useDelete from '../../hooks/useDelete';
import { handleModal } from '../../store/slices/modalSlice';
import usePost from '../../hooks/usePost';
import useGet from '../../hooks/useGet';
import BaseApplyButton from '../../components/Button/BaseApplyButton';
import BaseDeleteButton from '../../components/Button/BaseDeleteButton';
import BaseSearchButton from '../../components/Button/BaseSearchButton';
import RenderResults from '../../components/RenderResults';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import BaseEditButton from '../../components/Button/BaseEditButton';
import RecTeamForm from '../../components/Form/Recruitment/RecTeamForm';
import { deepOrange } from '@mui/material/colors';
import { Link } from 'react-router-dom';

const headCells = [
  // {
  //   id: 'id',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'ID',
  // },

  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'avatar',
    numeric: false,
    disablePadding: false,
    label: 'Avatar',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Role',
  },

  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

function ActionCell(props) {
  const { onClick, id } = props;
  const dispatch = useDispatch();
  return (
    <div className="">
      <BaseEditButton onClick={() => dispatch(handleModal({ modalId: id, componentName: 'editRecTeam' }))} />
      <BaseDeleteButton onClick={onClick} variant="icon" />
    </div>
  );
}

function AvatarCell(props) {
  const { avatar } = props;
  return <Avatar src={avatar} alt="photo preview" sx={{ bgcolor: deepOrange[500] }} variant="square" />;
}

function TableCells(props) {
  const { row, labelId, ariaChecked, selected, handleClick } = props;

  const destroyTeam = useDelete({
    confText: `modals.team_delete.confirmation_text`,
    confTitle: `modals.team_delete.title`,
    success: 'Team deleted',
    validate: ['teamArticles'],
  });

  const onDestroySingleTeam = (id) => {
    destroyTeam(`recruitment_teams/${id}`);
  };

  return (
    <TableRow hover role="checkbox" aria-checked={ariaChecked} tabIndex={-1} key={row.id} selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox onClick={(event) => handleClick(event, row.id)} color="primary" checked={ariaChecked} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell> */}

      {/* <TableCell component="th" id={labelId} scope="row" align="">
        <Link className="text-blue-600" to={`/hr/employee/edit/${row.employee.id}`}>
          {row.employee.id}
        </Link>
      </TableCell> */}
      <TableCell align="left">
        <Link className="text-blue-600" to={`/hr/employee/${row.employee.id}/detail`}>
          {' '}
          {row.employee.name}
        </Link>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        <AvatarCell avatar={row.employee.avatar} />
      </TableCell>
      <TableCell align="left">{row.role}</TableCell>

      <TableCell align="left">
        <ActionCell id={row.id} onClick={() => onDestroySingleTeam(row.id)} />
      </TableCell>
    </TableRow>
  );
}

export default function TeamTab() {
  const clientId = useSelector((state) => state.client.activeClient);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const [dataJob, setDataJob] = useState([]);
  const [searchInput, setSearchInput] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const [checkboxSelected, setCheckboxSelected] = useState([]);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const { isFetching } = useGet({
    url: `recruitment_teams/?search=${searchInput}&client=${clientId}&max_size=true`,
    validate: 'recTeams',
    onSuccess: (e) => {
      setCount(e?.data?.count);
      setDataJob(e?.data?.results);
    },
    isEnabled: isDataLoad,
  });

  const { data: recTeamDetail } = useGet({
    url: `recruitment_teams/${modal?.modalId}`,
    validate: 'widget',
    isEnabled: modal.componentName === 'editRecTeam',
  });

  console.log(recTeamDetail);

  const onChangeSearch = (event) => {
    setSearchInput(event.target.value);
    setIsDataLoad(false);
    const timer = setTimeout(() => {
      setIsDataLoad(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const data = useMemo(() => dataJob, [dataJob]);

  function generateFilterComponent() {
    return (
      <div className="flex space-x-2 items-center">
        <BaseSearchButton onChange={onChangeSearch} setPage={setPage} setIsDataLoad={setIsDataLoad} />

        <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} />
      </div>
    );
  }

  const { mutate: postRecTeam } = usePost({ url: 'recruitment_teams', validate: ['recTeams'] });

  const onPostRecTeam = async (postData) => {
    await postRecTeam(postData);
  };

  const { mutate: updateRecTeam } = usePatchOrPut({ url: `recruitment_teams/${modal.modalId}`, validate: ['recTeams', 'widget'] });

  return (
    <div className="p-5">
      <div className="">
        <div className="bg-gray-100 w-full flex py-5 px-3 items-center">
          <div className="ml-auto flex gap-x-3">
            {/* <BaseDeleteButton disabled={checkboxSelected.length === 0} onClick={() => onDestroyBulkTeam()} /> */}
            <Button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addRecTeam' }))} className="bg-green-600 text-white" type="submit" variant="filled">
              ADD Team
            </Button>
            <ModalWrapper componentName="addRecTeam" header="Add Team" maxWidth="sm">
              <RecTeamForm onFormSubmit={onPostRecTeam} />
            </ModalWrapper>
            <ModalWrapper componentName="editRecTeam" header="Edit Team" maxWidth="sm">
              <RecTeamForm onFormSubmit={updateRecTeam} defaultValues={recTeamDetail?.data} key={recTeamDetail?.data?.id} />
            </ModalWrapper>
          </div>
        </div>
        <div className=" py-4 my-4 border-y-2 ">{generateFilterComponent()}</div>
      </div>
      {!isFetching ? (
        <RenderResults
          condition={count > 0}
          component={
            <CustomTable
              dataApi={data}
              setIsDataLoad={setIsDataLoad}
              isFetching={!isFetching}
              headCells={headCells}
              ActionCell={ActionCell}
              TableCells={TableCells}
              // useCheckbox
              page={page - 1}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              checkboxSelected={checkboxSelected}
              count={count}
              setCheckboxSelected={setCheckboxSelected}
            />
          }
        />
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

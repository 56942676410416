import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Divider, Fade, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';

import AccountingSettingsContext from '../../../../pages/Accounting/Settings/AccountingSettingsContext';
import api from '@/api/HR/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid transparent',
  boxShadow: 24,
  p: 4,
};

const schema = yup.object().shape({
  businessUnit: yup.string().required('Business Unit is a required field'),
  department: yup.string(),
  code: yup.string().required('Code is a required field'),
  name: yup.string().required('Name is a required field'),
  description: yup.string().required('Description is a required field'),
});

const { getDepartment } = api();

export default function ModalCostCenter(props) {
  const accountingSettingsContext = useContext(AccountingSettingsContext);

  const { open, close, action, type, data } = props;
  const [isOpen, setOpen] = useState(false);
  const [mode, setMode] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      businessUnit: '',
      department: '',
      code: '',
      name: '',
      description: '',
    },
  });

  const onCloseHandler = () => {
    close();
    setOpen(false);
    reset({
      businessUnit: '',
      code: '',
      name: '',
      description: '',
    });
    setTimeout(() => {
      setMode(false);
    }, 300);
  };

  const onSubmit = (dataAcc) => {
    const dataAccount = {
      business_unit: dataAcc.businessUnit,
      department: dataAcc.department,
      code: dataAcc.code,
      name: dataAcc.name,
      description: dataAcc.description,
    };
    action(mode, dataAccount);
    onCloseHandler();
  };

  useEffect(() => {
    if (!mode && type) {
      setMode(type);
    }
  }, [mode, type]);

  const setDefaultState = useCallback(() => {
    if (!mode && type) {
      setMode(type);
    }
    if (data) {
      setValue('businessUnit', data?.business_unit?.id);
      setValue('department', data?.department?.id);
      setValue('code', data?.code);
      setValue('name', data?.name);
      setValue('description', data?.description);
    }
  }, [mode, type, data, setValue]);

  useEffect(() => {
    if (!isOpen && open && data) {
      setMode(false);
      setOpen(true);
      setDefaultState();
    }
  }, [open, setDefaultState, isOpen, data]);

  const b = watch('businessUnit');
  const bU = useMemo(() => b, [b]);

  const { data: d } = useQuery({
    queryKey: ['departments', bU],
    enabled: !!bU,
    queryFn: () => getDepartment({ business_unit: bU, max_size: true }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 0,
  });
  const departments = useMemo(() => d?.data?.results, [d]) || [];

  return (
    <Modal open={open} onClose={onCloseHandler} aria-labelledby="modal-account-group" aria-describedby="modal-account-group" className="w-full">
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" className="capitalize">
            {mode && mode} Cost Center
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div className="flex flex-col space-y-4">
              <FormControl>
                <Controller
                  control={control}
                  name="businessUnit"
                  render={({ field: { onChange, value, ref } }) => (
                    <>
                      <InputLabel sx={{ top: '-5px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } }} id="select-businessUnit">
                        Business Unit
                      </InputLabel>
                      <Select
                        sx={{
                          pt: 0.5,
                          pb: 1,
                          border: 0,
                        }}
                        labelId="select-businessUnit"
                        name="businessUnit"
                        ref={ref}
                        value={value}
                        label="Center"
                        onChange={onChange}
                        error={!!errors.businessUnit?.message}
                      >
                        <MenuItem value="">
                          <em>Center</em>
                        </MenuItem>
                        {accountingSettingsContext.businessUnits &&
                          accountingSettingsContext.businessUnits.length > 0 &&
                          accountingSettingsContext.businessUnits.map((acc, index) => (
                            <MenuItem key={`${acc.code + index}`} value={acc.id}>
                              {acc.name}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText className="text-red-600">{errors.businessUnit?.message}</FormHelperText>
                    </>
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="department"
                  render={({ field: { onChange, value, ref } }) => (
                    <>
                      <InputLabel sx={{ top: '-5px', '&.Mui-focused': { top: 0 }, '&.MuiFormLabel-filled': { top: 0 } }} id="select-businessUnit">
                        Department
                      </InputLabel>
                      <Select
                        sx={{
                          pt: 0.5,
                          pb: 1,
                          border: 0,
                        }}
                        labelId="select-department"
                        name="department"
                        ref={ref}
                        value={value}
                        label="Department"
                        onChange={onChange}
                        error={!!errors.department?.message}
                      >
                        <MenuItem value="">
                          <em>department</em>
                        </MenuItem>
                        {departments.length > 0 &&
                          departments.map((dep, index) => (
                            <MenuItem key={`${dep.id + dep.name}`} value={dep.id}>
                              {dep.name}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText className="text-red-600">{errors.department?.message}</FormHelperText>
                    </>
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="code"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      label="Code"
                      size="small"
                      error={!!errors.code?.message}
                      helperText={errors.code?.message}
                      placeholder=""
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      label="Name"
                      size="small"
                      error={!!errors.name?.message}
                      helperText={errors.name?.message}
                      placeholder=""
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      label="Description"
                      size="small"
                      error={!!errors.description?.message}
                      helperText={errors.description?.message}
                      placeholder=""
                    />
                  )}
                />
              </FormControl>
            </div>
            <Divider className="mt-4" />
            <Stack direction="row" spacing={2} className="mt-5 float-right">
              <button type="button" className="bz-btn secondary" onClick={onCloseHandler}>
                <p className="text-[#2C6D47]">Cancel</p>
              </button>
              <button type="submit" className="bz-btn">
                <p>
                  {mode && mode === 'create' && 'Add'}
                  {mode && mode === 'update' && 'Update'}
                </p>
              </button>
              {/* <Button variant="outlined" onClick={onCloseHandler}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                {mode && mode === 'create' && 'Add'}
                {mode && mode === 'update' && 'Update'}
              </Button> */}
            </Stack>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
}

import { AssignmentInd, AutoGraph, Circle, Dashboard, Group, Groups, HeadsetMic, Layers, Paid, Public } from '@mui/icons-material';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import OutletList from '@/pages/PointOfSale/OutletList';
import OutletDetail from '@/pages/PointOfSale/OutletDetail';
import NewOutletDetail from '@/pages/PointOfSale/NewOutletDetail';
import OutletSalesReports from '@/pages/PointOfSale/OutletSalesReports';


const SalesRoute = [
  {
    name: 'POS - Outlet Detail',
    element: <NewOutletDetail />,
    privilege: [99, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/pos/outlets/detail/:outletId',
  },
  {
    name: 'Add New Outlet',
    element: <NewOutletDetail />,
    privilege: [99, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/pos/outlets/add',
    isSidebar: false,
  },
  // {
  //   name: 'Sales Report',
  //   element: <OutletSalesReports />,
  //   privilege: [99, 5, 7, 8, 9, 10, 17],
  //   subscriptions: [5],
  //   path: '/sales/report',
  //   isSidebar: true,
  // },
  {
    name: 'POS',
    // element: <Reports />,
    icon: <AutoGraph />,
    privilege: [99, 5, 7, 8, 9, 10, 17],
    subscriptions: [5],
    path: '/pos',
    child: [
      {
        name: 'Dashboard',
        // element: <ReportsDashboard attributeReports={[]} />,
        icon: <Dashboard />,
        privilege: [99, 5, 7, 8, 9, 10, 17],
        subscriptions: [5],
        path: '/pos/dashboard',
        isSidebar: true,
      },
      {
        name: 'Outlets',
        element: <OutletList />,
        icon: <PointOfSaleIcon />,
        privilege: [99, 5, 7, 8, 9, 10, 17],
        subscriptions: [5],
        path: '/pos/outlets',
        isSidebar: true,
      },
      {
        name: 'Sales Orders',
        element: <OutletSalesReports />,
        privilege: [99, 5, 7, 8, 9, 10, 17],
        subscriptions: [5],
        path: '/pos/report',
        isSidebar: true,
      },
    ],
    isSidebar: true,
  },
];

export default SalesRoute;
/* eslint-disable */

import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';

import 'react-image-crop/dist/ReactCrop.css';
import HandleWatchingState from '../../../helper/HandleWatchingState';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCropper({ imageToCrop, setValue, watch, setCroppedImage, defaultValues }) {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const blobUrlRef = useRef('');
  const [crop, setCrop] = useState();

  const [completedCrop, setCompletedCrop] = useState();

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);
  const [watchDrag, setWatchDrag] = useState(false);
  const [originWidth, setOriginWidth] = useState(null);
  const [originHeight, setOriginHeight] = useState(null);
  const [originMobileWidth, setOriginMobileWidth] = useState(null);
  const [originMobileHeight, setOriginMobileHeight] = useState(null);
  const [isCropMobile, setIsCropMobile] = useState(false);
  const [isOriginalImage, setIsOriginalImage] = useState(false || watch('asset_mobile'));

  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (!defaultValues && imageToCrop) {

      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(imageToCrop);
    } else setImgSrc(imageToCrop);
  }, [defaultValues, imageToCrop]);

  function onImageLoad(e) {

    const { width, height } = e.currentTarget;
    if (defaultValues) {
      setCrop({
        unit: 'px',
        x: defaultValues?.mobile_x,
        y: defaultValues?.mobile_y,
        width: defaultValues?.mobile_width,
        height: defaultValues?.mobile_height,
      });
      setCompletedCrop({
        unit: 'px',
        x: defaultValues?.mobile_x,
        y: defaultValues?.mobile_y,
        width: defaultValues?.mobile_width,
        height: defaultValues?.mobile_height,
      });
      setOriginWidth(e.target.naturalWidth);
      setOriginHeight(e.target.naturalHeight);
    } else {
      setCrop(centerAspectCrop(e.target.naturalWidth, e.target.naturalHeight, aspect));
      setOriginWidth(e.target.naturalWidth);
      setOriginHeight(e.target.naturalHeight);
    }
  }

  function onImageLoadMobile(e) {

    const { width, height } = e.currentTarget;

    setOriginMobileWidth(e.target.naturalWidth);
    setOriginMobileHeight(e.target.naturalHeight);
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist');
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob');
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);


      // blob.name = 'mobile.png';
      // if(crop.unit === completedCrop.unit){
      //   setValue('mobile_unit', "px");
      // } else {
      //   setValue('mobile_unit', "%");
      // }
      setValue('asset_mobile', blob);
      setValue('mobile_height', Math.trunc(completedCrop.height));
      setValue('mobile_width', Math.trunc(completedCrop.width));
      setValue('mobile_x', Math.trunc(completedCrop.x));
      setValue('mobile_y', Math.trunc(completedCrop.y));

      setWatchDrag(false);
    });
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        if (crop.unit !== completedCrop.unit && watchDrag) {
          setValue(`mobile_unit`, '%');
        }
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
        if (imgSrc && crop.unit !== completedCrop.unit && watch(`mobile_unit`) !== 'px' && watchDrag) {
          onDownloadCropClick();

        }
      }
    },
    100,
    [completedCrop, scale, rotate, imgSrc, onDownloadCropClick]
  );

  const watcihngAssetmobile = HandleWatchingState(defaultValues?.asset_mobile);
  useEffect(() => {
    if (watcihngAssetmobile) {
      setOriginMobileHeight(null);
      setOriginMobileWidth(null);
      setTimeout(() => {
        setOriginMobileHeight(defaultValues?.mobile_height);
        setOriginMobileWidth(defaultValues?.mobile_width);
      }, 100);
    }
  }, [watcihngAssetmobile]);

  return (
    <div className="">
      {/* <button type="button" onClick={() => setIsCropMobile(!isCropMobile)} className="px-4 py-2 border-2 mb-5 rounded border-teal-600">
        Crop Asset For Mobile
      </button> */}
      <div className="flex gap-3 items-center ">
        <div className="">{defaultValues?.asset_mobile ? 'Recrop Asset For Mobile ? ' : 'Crop Asset For Mobile ?'} </div>
        <div className="flex">
          <button type="button" onClick={() => setIsCropMobile(true)} className={['px-4 py-2 border-2 rounded-l ', isCropMobile ? 'bg-bz-greenLight' : 'bg-gray-300'].join(' ')}>
            Yes
          </button>
          <button type="button" onClick={() => setIsCropMobile(false)} className={['px-4 py-2 border-2 rounded-r ', !isCropMobile ? 'v' : 'bg-gray-300'].join(' ')}>
            No
          </button>
        </div>
      </div>

      <div className="">
        <div className="flex space-x-3 my-3 font-bold">
          <div className="">Desktop</div>
          <PersonalVideoIcon />
        </div>
        {!isCropMobile && (
          <div className="">
            <img alt="Crop me" src={imgSrc} />
          </div>
        )}
        <div className="">
          {isCropMobile && (
            <div className="">
              <div className="overflow-x-auto w-full overflow-y-hidden">
                {' '}
                <div className="" style={{ width: `${originWidth}px`, height: `${originHeight}px` }}>
                  <ReactCrop
                    onDragEnd={(e) => setWatchDrag(true)}
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    crossOrigin="anonymous"
                  >
                    <img ref={imgRef} crossOrigin="anonymous" alt="Crop me" src={imgSrc} style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} onLoad={onImageLoad} />
                  </ReactCrop>
                </div>
              </div>
            </div>
          )}
          <div className="flex space-x-3 my-3 font-bold">
            <div className="">Mobile</div>
            <PersonalVideoIcon />
          </div>
          <div className="flex flex-col space-y-3">
            {defaultValues && (
              <div className="">
                {isCropMobile && <div className="my-2 text-xs">Old Croping</div>}
                <div className="w-full overflow-x-auto overflow-y-hidden">
                  {originMobileHeight && originMobileWidth ? (
                    <div className="" style={{ width: `${originMobileWidth}px`, height: `${originMobileHeight}px` }}>
                      <img src={defaultValues?.asset_mobile} alt="" className="w-full h-full object-contain" />
                    </div>
                  ) : (
                    <div className="" style={{ width: `${originMobileWidth}px`, height: `${originMobileHeight}px` }}>
                      <img src={defaultValues?.asset_mobile} alt="" className="w-full h-full object-contain" onLoad={onImageLoadMobile} />
                    </div>
                  )}
                </div>
              </div>
            )}{' '}
            {!!completedCrop && isCropMobile && (
              <div>
                {defaultValues && <div className="my-2 text-xs">New Croping</div>}
                <div className="w-full overflow-x-auto overflow-y-hidden">
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      // border: '1px solid black',
                      objectFit: 'contain',
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

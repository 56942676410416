import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Skeleton, Typography } from '@mui/material';
import { Add, ExpandMore } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import BaseInputValidation from '../../Input/Base/BaseInputValidation';
import DroppableElement from '../../ui/CRM/DroppableElement';
import { handleModal } from '../../../store/slices/modalSlice';

export default function DndProfileSidebar({ form, group, attribute, structure }) {
  const dispatch = useDispatch();

  const { control, errors } = form;
  const { isFetchingGroup, dataGroup, setSelectedGroup } = group;
  const { isFetchingAttribute, dataAttribute, setSelectedAttribute } = attribute;

  return (
    <div className="relative w-auto max-w-[396px] flex flex-col pb-4 gap-4">
      <BaseInputValidation control={control} name="name" label="Profile Name" errors={errors} required />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="group-content" id="group-header">
          <Typography>Groups</Typography>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col h-auto max-h-[380px] overflow-x-hidden overflow-y-auto">
          {isFetchingGroup && <Skeleton />}
          {!isFetchingGroup &&
            dataGroup?.length > 0 &&
            dataGroup.map((gr) => {
              return (
                <DroppableElement
                  key={`group-${gr.id}`}
                  structure={structure?.group}
                  data={gr}
                  label={gr.name}
                  type="group"
                  viewDetail={setSelectedGroup}
                  modalName="AttributeGroup"
                />
              );
            })}
          {!isFetchingGroup && !dataGroup?.length > 0 && <span className="m-auto">no group data</span>}
          {!isFetchingGroup && (
            <Button
              variant="contained"
              className="w-full h-8 mt-4 m-auto"
              startIcon={<Add />}
              onClick={() => {
                dispatch(handleModal({ modalId: '', componentName: 'AttributeGroup' }));
              }}
            >
              Group
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="attribute-content" id="attribute-header">
          <Typography>Attribute</Typography>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col h-auto max-h-[380px] overflow-x-hidden overflow-y-auto">
          {/* eslint-disable-next-line no-nested-ternary */}
          {isFetchingAttribute ? (
            <Skeleton />
          ) : dataAttribute?.length > 0 ? (
            dataAttribute.map((attr) => {
              return (
                <DroppableElement
                  key={`attr-${attr.id}`}
                  structure={structure?.attribute}
                  data={attr}
                  label={attr.name}
                  type="attribute"
                  viewDetail={setSelectedAttribute}
                  modalName="Attribute"
                />
              );
            })
          ) : (
            <span className="m-auto">no attribute data</span>
          )}
          {!isFetchingAttribute && (
            <Button
              variant="contained"
              className="w-full h-8 mt-4 m-auto"
              startIcon={<Add />}
              onClick={() => {
                dispatch(handleModal({ modalId: '', componentName: 'Attribute' }));
              }}
            >
              Attribute
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

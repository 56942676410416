/* eslint-disable */

import request from '../helper/axiosUtil';

export const updateSite = async (id, data) => request({
  url: `/sites/${id}/`,
  method: 'patch',
  data,
  headers: { 'Content-Type': 'application/json' },
});

export const deleteSite = async (id) => request({
  url: `/sites/${id}/`,
  method: 'delete',
  headers: { 'Content-Type': 'application/json' },
});
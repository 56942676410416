import { yupResolver } from '@hookform/resolvers/yup';
import { Delete, DeleteForever } from '@mui/icons-material';
import { Button, FormControl, FormGroup, IconButton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
// import { DevTool } from '@hookform/devtools';

import FileContatiner from '@/components/Input/FileContatiner';
import InputContainer from '@/components/Input/InputContainer';
import AddSites from '@/components/Modal/SuperUser/AddSites/AddSites';
import useDelete from '@/hooks/useDelete';
import { updateSite } from '@/hooks/useSitesData';
import { DevTool } from '@hookform/devtools';
import { PiFlowerTulipThin } from 'react-icons/pi';
import SiteDomainList from './SiteDomainList';
import TemplateList from './TemplateList';
import usePostExtra from '@/hooks/usePostExtra';
import { useParams } from 'react-router';
import SiteContext from './SiteContext';

function getFileNameAndType(url) {
  return url?.substring(url?.lastIndexOf('/') + 1);
}

// Yup Schema
const schema = yup.object().shape({
  api_url: yup.string(),
  name: yup.string().nullable(),
  site_url: yup.string().nullable(),
  site_domains: yup.array().of(yup.object().shape({ id: yup.number().nullable(), domain: yup.string().required('Domain cannot be blank'), site: yup.number() })),
});

// Styling
const styleDisabled = { '& .Mui-disabled': { backgroundColor: '#F5F5F5' } };

// Component
function SiteForm({ defaultValues, showTemplate = true }) {
  const { clientId } = useParams();

  const [valueTab, setValueTab, lastTab] = useContext(SiteContext);

  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues, site_domains: defaultValues?.site_domains.map((dom) => ({ ...dom, domId: dom?.id })) },
  });

  console.log('🚀 ~ SiteForm ~ errors:', errors);
  const [toggleFav, setToggleFav] = useState(false);

  const activeSiteDetail = useSelector((state) => state.content.activeSiteDetail);
  const authClient = useSelector((state) => state.auth.client.id);
  const qClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const isSameClient = parseInt(authClient, 10) === clientId;

  const { mutateAsync: updateSiteMutate } = useMutation((data) => updateSite(activeSiteDetail?.id, data), {
    onSuccess: (e) => {
      qClient.invalidateQueries('site');
      if (e.request.status === 200) {
        enqueueSnackbar('Site updated successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Something went wrong', { variant: 'Error' });
      }
    },
  });
  const { mutateAsync: updateSiteDomainMutate } = useMutation((data) => updateSite(activeSiteDetail?.id, data), {
    onSuccess: (e) => {
      qClient.invalidateQueries('site');
      if (e.request.status === 200) {
        // enqueueSnackbar('Site updated successfully', { variant: 'success' });
      } else {
        // enqueueSnackbar('Something went wrong', { variant: 'Error' });
      }
    },
  });

  const onUpdateSiteDomain = async (data) => {
    await updateSiteDomainMutate(data);
    qClient.invalidateQueries('site');
  };
  const onUpdateSite = async (data) => {
    await updateSiteMutate(data);
    qClient.invalidateQueries('site');
  };

  const { mutate: postSite } = usePostExtra({
    url: `sites`,
    validate: ['sites'],
    onSuccess: (res) => {
      if (res.request.status === 201) {
        setValueTab(lastTab);
        enqueueSnackbar('Site updated successfully', { variant: 'success' });
      } else {
        renderError(res);
      }
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();

    if (data.favicon && data.favicon[0] && typeof data.favicon[0] !== 'string') {
      formData.append('favicon', data.favicon[0]);
    } else if (defaultValues?.favicon && data.favicon) {
      null;
    } else formData.append('favicon', '');

    // if (data.site_domains && data.site_domains.length > 0) {
    //   data.site_domains.forEach((dom, idx) => {
    //     formData.append(`site_domains[${idx}]domain`, dom?.domain);
    //     formData.append(`site_domains[${idx}]site`, defaultValues?.id);
    //     if (dom?.domId) {
    //       formData.append(`site_domains[${idx}]id`, dom?.domId);
    //     }
    //   });
    // }

    formData.append('name', data?.name || '');
    formData.append('site_url', data?.site_url || '');
    formData.append('api_url', data?.api_url || '');
    formData.append('client', clientId);

    if (defaultValues) {
      onUpdateSite(formData);
    }
    if (!defaultValues) {
      postSite(formData);
    }

    const newSiteDomains = data?.site_domains.map((dom) => ({
      id: dom?.domId,
      site: defaultValues?.id,
      domain: dom.domain,
    }));

    if (JSON.stringify(defaultValues?.site_domains) !== JSON.stringify(newSiteDomains) && defaultValues) {
      onUpdateSiteDomain({ site_domains: newSiteDomains });
    }
  };

  const favicon = watch('favicon');

  function renderFavicon() {
    const createFavicon = favicon && typeof favicon === 'string' ? defaultValues?.favicon : URL.createObjectURL(favicon[0]);
    return createFavicon;
  }

  const HandleFav = () => {
    setValue('favicon', null);
    setToggleFav(!toggleFav);
  };

  const destroySite = useDelete({
    confText: 'Confirm Delete Site',
    confTitle: 'Are you sure want to delete this site ?',
    success: 'Site deleted',
    validate: ['sites'],
    extraAction: () => {
      setValueTab(0);
    },
  });

  const onDestroySite = (id) => {
    destroySite(`sites/${id}`);
  };

  return (
    <>
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-6">
            <div className="grid grid-cols-2 gap-6 ">
              <div className="">
                <div className="w-4/12 flex justify-center items-center ">
                  <div className="w-full flex space-x-3 relative">
                    <FileContatiner
                      name="favicon"
                      label="Favicon"
                      className="!w-[150px]"
                      errors=""
                      control={control}
                      title={getFileNameAndType(favicon ? renderFavicon() : '')}
                      custom
                      customPaceholder={
                        <div className="max-w-[150px] max-h-[150px] relative">
                          {favicon ? (
                            <img src={renderFavicon()} alt="" className="w-full h-full object-cover   border p-2" />
                          ) : (
                            <PiFlowerTulipThin className="w-full h-full object-cover   border p-2" />
                          )}
                          {favicon && (
                            <div className="absolute left-[calc(100%+10px)] top-[0px]">
                              <IconButton onClick={HandleFav} aria-label="delete" size="small" type="button" variant="contained" color="error">
                                <DeleteForever className="" />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      }
                    />
                  </div>
                </div>
                <FormGroup className="mb-4">
                  <FormControl>
                    <InputContainer control={control} sx={styleDisabled} label="Site Name" variant="outlined" name="name" />
                  </FormControl>
                </FormGroup>
                <FormGroup className="mb-4">
                  <FormControl>
                    <InputContainer control={control} sx={styleDisabled} label="API URL" variant="outlined" name="api_url" />
                  </FormControl>
                </FormGroup>
                <FormGroup className="mb-4">
                  <FormControl>
                    <InputContainer control={control} sx={styleDisabled} label="Site URL" variant="outlined" name="site_url" />
                  </FormControl>
                </FormGroup>
              </div>
              {defaultValues && <SiteDomainList control={control} watch={watch} setValue={setValue} getValues={getValues} errors={errors} defaultValues={defaultValues} />}
            </div>
            <Button type="submit" variant="contained">
              {defaultValues ? 'Update Site' : 'Create Site'}
            </Button>{' '}
            {defaultValues && (
              <Button className="" onClick={() => onDestroySite(defaultValues?.id)} variant="contained" color="error" startIcon={<Delete />}>
                Delete site
              </Button>
            )}
          </div>
        </form>
        {showTemplate && defaultValues && <TemplateList defaultValuesSite={defaultValues} templates={defaultValues?.templates} />}

        <DevTool control={control} />
      </div>
    </>
  );
}

export default SiteForm;

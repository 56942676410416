import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, Skeleton, Stack, TextField } from '@mui/material';
import { Add, AttachFile } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import TransactionContext from './TransactionContext';
import LineTransaction from '../../../components/Modal/Accounting/LineTransaction';
import apiAccounting from '@/api/accounting/accounting';
import apiTransaction from '@/api/accounting/transactions';
import apiReport from '@/api/accounting/report';

import LineDetail from './LineDetail';
import BZHelmet from '../../../utils/BZHelmet';

const getLineTransactions = (baseCurr) => {
  return [
    {
      id: Math.floor(Math.random() * 1000000000),
      credit: 0,
      debit: 0,
      line: '',
      rate: '1',
      transaction_amount: 0,
      baseCurrency: baseCurr,
      balance_amount: 0,
      name: '',
      status: true,
    },
  ];
};
const detailTransaction = [{ date: format(new Date(), 'yyyy-MM-dd'), description: '', source: '', supportingDocuments: null, journalNumber: null, line_details: [] }];

const getBalanceAmount = (type, debt, rate, cred, decimal) => {
  const cxr = Number(cred) * Number(rate).toFixed(5);
  const dxr = Number(debt) * Number(rate).toFixed(5);
  if (type.toLowerCase() === 'balance') {
    return Number((dxr - cxr).toFixed(decimal));
  }
  return Number((cxr - dxr).toFixed(decimal));
};
const getTransactionAmount = (type, debt, cred, decimal) => {
  const cxr = Number(cred);
  const dxr = Number(debt);
  if (type.toLowerCase() === 'balance') {
    return Number(dxr - cxr);
  }
  return Number(cxr - dxr);
};

export default function NewTransaction() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const client = useSelector((state) => state.auth.client.id);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeUser = useSelector((state) => state.auth.user);
  const usedClient = activeUser.is_superuser ? activeClient : client;
  const activeBusinessUnit = useSelector((state) => state.acc.activeBusinessUnit);
  const baseCurr = useSelector((state) => state.acc.activeBusinessUnitDetail?.base_currency?.id);

  const { getAccountCodes, getCostCenters, getCounterparts, getAccountingPeriods, getAccountingPeriodStatus, getCurrencies, getProjectIds, getJournalSources } = apiAccounting();
  const { addTransaction } = apiTransaction();
  const { getLedgers } = apiReport();

  const [businessUnit, setBusinessUnit] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const [costCenters, setCostCenters] = useState(false);
  const [counterparts, setCounterparts] = useState(false);
  const [accPeriods, setAccPeriods] = useState(false);
  const [currencies, setCurrencies] = useState(false);
  const [projectIds, setProjectIds] = useState(false);
  const [journalSources, setJournalSources] = useState(false);
  const [toFixDecimal, setToFixedDecimal] = useState(0);

  // const [totalTransactions, setTotalTransactions] = useState(0);
  const [transaction, setTransaction] = useState(detailTransaction);
  const [filesAttachment, setFilesAttachment] = useState([]);
  const [lineTransaction, setLineTransaction] = useState(false);
  const [isLineTransaction, setIsLineTransaction] = useState(false);

  const [isRequested, setIsRequested] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: '*',
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFilesAttachment(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
    },
  });

  const onChangeDC = useCallback((type, index, value, ln) => {
    const currLnTransaction = [...ln];
    const selectedLnTransaction = currLnTransaction[index];

    const accType = selectedLnTransaction.data?.account?.account_type;
    const accGrType = accType?.account_group_type || 'Balance';
    if (currLnTransaction) {
      if (type === 'Debit') {
        selectedLnTransaction.debit = value;
        selectedLnTransaction.transaction_amount = getTransactionAmount(accGrType, value, selectedLnTransaction.credit, toFixDecimal);
        selectedLnTransaction.balance_amount = getBalanceAmount(accGrType, value, selectedLnTransaction.rate, selectedLnTransaction.credit, toFixDecimal);
        setLineTransaction(currLnTransaction);
      }
      if (type === 'Credit') {
        selectedLnTransaction.credit = value;
        selectedLnTransaction.transaction_amount = getTransactionAmount(accGrType, selectedLnTransaction.debit, value, toFixDecimal);
        selectedLnTransaction.balance_amount = getBalanceAmount(accGrType, selectedLnTransaction.debit, selectedLnTransaction.rate, value, toFixDecimal);
        setLineTransaction(currLnTransaction);
      }
      if (type === 'Rate') {
        selectedLnTransaction.rate = value;
        selectedLnTransaction.balance_amount = getBalanceAmount(accGrType, selectedLnTransaction.debit, value, selectedLnTransaction.credit, toFixDecimal);
        setLineTransaction(currLnTransaction);
      }
    }
  }, []);

  const onChangeLNDetail = (index, key, value) => {
    const currLnTransaction = [...lineTransaction];
    const selectedLnTransaction = currLnTransaction[index];
    if (currLnTransaction) {
      selectedLnTransaction[key] = value;
      setLineTransaction(currLnTransaction);
    }
  };

  const onRemoveLn = (lnId) => {
    const currLnTransaction = [...lineTransaction];
    const newLnTransaction = currLnTransaction.filter((ln) => ln.id !== lnId);
    setLineTransaction(newLnTransaction);
  };

  const handleAddNewLine = () => {
    const newTrans = [];
    for (let index = 0; index < lineTransaction.length; index += 1) {
      newTrans.push(lineTransaction[index]);
    }
    const rndID = Math.floor(Math.random() * 1000000000);
    newTrans.push({ id: rndID });
    // setTotalTransactions(newTrans);
    setLineTransaction([...lineTransaction, { id: rndID, debit: 0, credit: 0, line: '', rate: '1', baseCurrency: baseCurr, balance_amount: 0, status: true }]);
  };

  const onCancelHandler = () => {
    setLineTransaction(false);
    navigate('/transaction', { replace: true });
  };

  const handleGenerateLine = useCallback(
    (lnTransaction) => {
      const { line, data, id, rate, status } = lnTransaction;
      if (!lineTransaction.length) return;
      const currLnTransaction = [...lineTransaction];
      const currLn = currLnTransaction.filter((ln) => ln.id === id);
      if (currLn.length) {
        const ln = currLn[0];
        ln.line = line;
        ln.data = data;
        ln.rate = rate || ln.rate || '';
        ln.status = status;
        const isBalance = data?.account?.account_type?.account_group_type?.toLowerCase() === 'balance';
        const bAmount = isBalance ? Number(ln.debit) - Number(ln.credit) : Number(ln.credit) - Number(ln.debit);
        ln.transaction_amount = bAmount;
        ln.balance_amount = bAmount * (rate || ln.rate || 1);
        const newLnTransactions = lineTransaction.map((obj) => {
          if (id === obj.id) {
            return ln;
          }
          return obj;
        });
        setLineTransaction(newLnTransactions);
      }
    },
    [lineTransaction]
  );

  const onActionHandler = () => {
    setIsLoading(true);
    const lnTransaction = { ...transaction };
    lnTransaction[0].line_details = lineTransaction;
    lnTransaction[0].attachments = filesAttachment;

    addTransaction(lnTransaction[0])
      .then((res) => {
        if (res.status === 201 || res.data) {
          enqueueSnackbar('New transaction added' || 'Success', { variant: 'success' });
          onCancelHandler();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        const er = err.response;
        if (er && typeof er !== 'string' && Object.keys(er.data).length && !er?.data?.includes('html')) {
          Object.keys(er.data).forEach((e) => {
            if (er.data[e]?.length) {
              enqueueSnackbar(`${e} - ${er.data[e]}` || `${er.status} - ${er.statusText}` || 'Error', { variant: 'error' });
            }
          });
        } else {
          enqueueSnackbar(`${er.status} - ${er.statusText}`, { variant: 'error' });
        }
      });
  };

  useEffect(() => {
    if (!lineTransaction) {
      const lnTransactions = getLineTransactions(baseCurr);
      setLineTransaction(lnTransactions);
    }
  }, [lineTransaction, baseCurr]);

  useEffect(() => {
    if (!transaction) {
      setTransaction([]);
    }
  }, [transaction]);

  const { data: ledgers } = useQuery({
    queryKey: ['ledgers', usedClient, activeBusinessUnit],
    enabled: !!usedClient && !!activeBusinessUnit,
    queryFn: () => getLedgers({ max_size: true, business_unit: activeBusinessUnit }),
    onSuccess: (res) => {
      if (res.data.results) {
        setToFixedDecimal(res.data.results[0].business_unit.decimal_digits);
      }
    },
    refetchOnWindowFocus: false,
  });

  const getAccountList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getAccountCodes(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getAccountCodes]
  );

  const getCostCenterList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getCostCenters(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getCostCenters]
  );

  const getCounterpartList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getCounterparts(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getCounterparts]
  );

  const getAccountingPeriodList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getAccountingPeriodStatus(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              const data = res.data.results;
              data.sort((a, b) => {
                if (a.accounting_period.year !== b.accounting_period.year) return b.year - a.year;
                return b.accounting_period.month - a.accounting_period.month;
              });
              setAccPeriods(data);
              resolve(data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getAccountingPeriodStatus]
  );

  const getCurrencyList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getCurrencies(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              setCurrencies(res.data.results);
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getCurrencies]
  );

  const getProjectIdList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getProjectIds(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getCurrencies]
  );

  const getJournalList = useCallback(
    (params) => {
      return new Promise((resolve, reject) => {
        getJournalSources(params)
          .then((res) => {
            if (res.status === 200 && res.data.results) {
              resolve(res.data.results);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [getCurrencies]
  );

  const getAllData = useCallback(async () => {
    const [a, b, c, d, e, f, g] = await Promise.all([
      getAccountList({ max_size: true, ordering: 'code' }),
      getCostCenterList({ business_unit: activeBusinessUnit, max_size: true, ordering: 'code' }),
      getCounterpartList({ max_size: true, ordering: 'code' }),
      getAccountingPeriodList({ status__in: ['Open', 'Re-opened'], ledger: 1, max_size: true }),
      // getAccountingPeriods({ financial_year__client: usedClient, accperstatus__status__not: 'Closed', accperstatus__ledger: 1, max_size: true }),
      getCurrencyList({ max_size: true, ordering: 'name' }),
      getProjectIdList({ client: usedClient, max_size: true, ordering: 'name' }),
      getJournalList({ client: usedClient, max_size: true, ordering: 'name' }),
    ]);
    // const isFinish = [a.status, b.status, c.status, d.status, e.status].every((itm) => itm === 'fulfilled');
    if (a && b && c && d && e && f && g) {
      setAccounts(a.sort((x, y) => Number(x.code) - Number(y.code)));
      setCostCenters(b);
      setCounterparts(c);
      setAccPeriods(d);
      setCurrencies(e);
      setProjectIds(f);
      setJournalSources(g);
    }
  }, [getAccountList, getCostCenterList, getCounterpartList, getAccountingPeriodList, getCurrencyList, usedClient, activeBusinessUnit]);

  useEffect(() => {
    if (!accounts && !costCenters && !counterparts && !accPeriods && !currencies && isRequested) {
      setIsRequested(false);
      getAllData();
    }
  }, [getAllData, accounts, costCenters, counterparts, accPeriods, currencies, isRequested]);

  useEffect(() => {
    if (accounts && costCenters && counterparts && accPeriods && currencies) {
      setIsLoaded(true);
    }
  }, [getAllData, accounts, costCenters, counterparts, accPeriods, currencies]);

  useLayoutEffect(() => {
    if (!businessUnit && activeBusinessUnit) {
      setBusinessUnit(activeBusinessUnit);
    }
  }, [businessUnit, activeBusinessUnit]);

  useEffect(() => {
    if (businessUnit && activeBusinessUnit && businessUnit !== activeBusinessUnit) {
      const getCC = getCostCenterList({ business_unit: activeBusinessUnit });
      if (getCC) {
        setBusinessUnit(activeBusinessUnit);
      }
    }
  }, [businessUnit, activeBusinessUnit, getCostCenterList]);

  const context = useMemo(
    () => ({
      accounts,
      costCenters,
      counterparts,
      accPeriods,
      currencies,
      baseCurr,
      projectIds,
    }),
    [accounts, costCenters, counterparts, accPeriods, currencies, baseCurr, projectIds]
  );

  const onChangeTransaction = (key, value) => {
    if (value) {
      const currTransaction = { ...transaction };
      currTransaction[0][key] = value;
      setTransaction(currTransaction);
    }
  };

  const onBlurDocument = (value) => {
    if (value) {
      const currTransaction = { ...transaction };
      currTransaction[0].supportingDocuments = value;
      currTransaction[0].journalNumber = value;
      setTransaction(currTransaction);
    }
  };

  useLayoutEffect(() => {
    setLineTransaction(false);
  }, []);

  const sumDebet = lineTransaction && lineTransaction?.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue.debit), 0).toFixed(2);
  const sumCredit = lineTransaction && lineTransaction?.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue.credit), 0).toFixed(2);
  const isLineDetailPassed = lineTransaction && lineTransaction.every((stat) => stat.status);

  const a = !(isLineDetailPassed && sumCredit === sumDebet);
  const b = lineTransaction.length === 1 && !lineTransaction[0]?.line;

  let isSubmit;
  if ((!a && b) || (a && !b)) {
    isSubmit = true;
  } else if (!a && !b) {
    // allow submit
    isSubmit = false;
  }

  return (
    <TransactionContext.Provider value={context}>
      <BZHelmet title="Create Transaction" description="bz publish create transaction" />
      {isLoading && (
        <div className="w-full h-full absolute t-0 left-0 bg-black bg-opacity-25 z-10">
          <div style={{ transform: 'translate(50%, 50%)' }} className="w-full h-full">
            <CircularProgress size={48} className={!isLoading ? 'opacity-0' : ''} />
            <p className={[isLoading && 'animate-bounce', 'mt-4 -ml-4'].join(' ')}>please wait</p>
          </div>
        </div>
      )}
      {!isLoaded && (
        <div className="w-full h-[calc(100vh_-_72px)] overflow-y-auto flex flex-col space-y-2 p-4">
          <Skeleton className="w-1/2 w h-12" />
          <div className="w-full flex flex-row">
            <div className="w-3/4 flex flex-col mr-8">
              <Skeleton className="h-12" />
              <Skeleton className="h-12" />
              <Skeleton className="h-12" />
            </div>
            <div className="w-1/4 flex flex-col">
              <Skeleton className="w-full h-full" />
            </div>
          </div>
        </div>
      )}
      {isLoaded && (
        <div className="relative w-full h-full p-8 overflow-y-auto">
          <h1 className="text-2xl font-bold mb-8">New Transaction</h1>
          <div className="w-full flex space-x-4 border p-4 border-gray-300 rounded-lg">
            <div className="flex flex-col w-full space-y-4">
              <div className="flex w-full space-x-2 justify-between">
                <TextField
                  type="date"
                  className="w-1/4"
                  defaultValue={format(new Date(), 'yyyy-MM-dd')}
                  size="small"
                  label="Date"
                  onChange={(e) => onChangeTransaction('date', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField className="w-1/4" size="small" label="Journal Number" onBlur={(e) => onBlurDocument(e.target.value)} />
                {/* <FormControl size="small">
                  <InputLabel id="select-journal">Source*</InputLabel>
                  <Select
                    sx={{ p: 0.5, border: 0, minWidth: 256 }}
                    labelId="select-journal-source"
                    id="select-journal-source"
                    name="journalSource"
                    // value={}
                    label="Source"
                    placeholder="Source"
                    onChange={(event) => {
                      onChangeTransaction('source', event.target.value);
                    }}
                    className="h-10"
                    required
                  >
                    {journalSources?.map((s) => (
                      <MenuItem key={s.id} value={s.id}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <div className="flex w-2/4">
                  <Box className="relative flex w-full justify-center items-center border rounded-lg border-gray-300">
                    <div {...getRootProps({ className: 'dropzone w-full flex justify-center' })}>
                      {filesAttachment.length > 0 ? (
                        <label htmlFor="icon-button-file">
                          <input {...getInputProps()} />
                          <div className="flex space-x-2 items-center justify-center overflow-x-auto">
                            {filesAttachment.map((file) => {
                              let html;
                              if (file.type.includes('image')) {
                                html = <img className="w-12 h-6 object-contain" src={file.preview} alt="project icon" />;
                              } else {
                                html = (
                                  <div className="bg-gray-300 bg-opacity-10 px-1">
                                    <span>{file.name}</span>
                                  </div>
                                );
                              }
                              return (
                                <div key={file} className="p-1 border border-gray-300 rounded-md">
                                  {html}
                                </div>
                              );
                            })}
                          </div>
                        </label>
                      ) : (
                        <label htmlFor="icon-button-file" className="flex justify-center items-center">
                          <input {...getInputProps()} />
                          <IconButton color="default" aria-label="upload attachment" component="span">
                            <AttachFile />
                          </IconButton>
                          <span className="text-gray-500">Attach File</span>
                        </label>
                      )}
                      {filesAttachment.length > 0 && (
                        <span className="absolute -bottom-4 right-0 text-xs text-gray-700">
                          {filesAttachment.length} {filesAttachment.length === 1 ? 'file' : 'files'}
                        </span>
                      )}
                    </div>
                  </Box>
                </div>
              </div>
              <div className="w-full">
                <TextField
                  className="w-full"
                  size="small"
                  title="Description"
                  placeholder="Description"
                  label="Description"
                  onBlur={(e) => onChangeTransaction('description', e.target.value)}
                />
              </div>
              {lineTransaction &&
                lineTransaction.length &&
                lineTransaction.map((t, i) => (
                  <LineDetail
                    key={t.id}
                    row={t}
                    index={i}
                    lnTransaction={lineTransaction}
                    baseCurr={baseCurr}
                    onChangeDC={onChangeDC}
                    setIsLineTransaction={setIsLineTransaction}
                    removeLn={() => onRemoveLn(t.id)}
                    generateLine={handleGenerateLine}
                    onChangeLNDetail={onChangeLNDetail}
                  />
                ))}
              <div className="relative flex w-full space-x-2">
                <div className="w-2/6">
                  <button
                    type="button"
                    className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 w-fit"
                    style={{ backgroundColor: '#2C6D47' }}
                    onClick={() => handleAddNewLine()}
                  >
                    <Add className="text-white" />
                    <p className="text-white">Add another line</p>
                  </button>
                  {/* <Button variant="contained" startIcon={<Add />} onClick={() => handleAddNewLine()}>
                    Add another line
                  </Button> */}
                </div>
                <div className="w-4/6 flex space-x-2 justify-start">
                  <TextField className="w-1/5" value={sumDebet} size="small" label="Total Debit" disabled />
                  <TextField className="w-1/5" value={sumCredit} size="small" label="Total Credit" disabled />
                  {[0, 1, 2].map((d) => (
                    <div key={d} className="w-1/5" />
                  ))}
                  {lineTransaction?.length > 1 && <div className="w-[33px]" />}
                </div>
              </div>
            </div>
          </div>
          <Stack direction="row" spacing={2} className="mt-5 float-right">
            <button
              type="button"
              className="flex h-[40px] gap-x-2 items-center text-center rounded-xl border px-4 border-[#2C6D47] hover:!bg-[#2c6d470a]"
              onClick={onCancelHandler}
            >
              <p className="text-[#2C6D47]">Cancel</p>
            </button>
            {/* <Button
              className="border-[#2C6D47] text-[#2C6D47] hover:!border-[#2C6D47]"
              variant="outlined"
              onClick={onCancelHandler}
            >
              Cancel
            </Button> */}
            <button
              type="button"
              className={`flex h-[40px] ${!isSubmit ? 'bg-[#2C6D47]' : 'bg-[#2c6d4726]'} gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4`}
              onClick={onActionHandler}
              disabled={isSubmit}
            >
              <p className="text-white">Submit</p>
            </button>
            {/* <Button variant="contained" onClick={onActionHandler} disabled={isSubmit}>
              Submit
            </Button> */}
          </Stack>
          <LineTransaction open={isLineTransaction} close={() => setIsLineTransaction(false)} data={isLineTransaction} transaction={lineTransaction} action={handleGenerateLine} />
        </div>
      )}
    </TransactionContext.Provider>
  );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import client from '../../../api/client';
import site from '../../../api/site';

export const getActiveSiteDetail = createAsyncThunk('client/setActiveDetailSite', async (siteId) => {
  const { getSiteDetail } = client();
  const response = await getSiteDetail(siteId);
  return response.data;
});

export const getActiveTemplateStructure = createAsyncThunk('client/setActiveTemplateStructure', async (templateId) => {
  const { getTemplateStructure } = site();
  const response = await getTemplateStructure(templateId, { language: localStorage.getItem('bzLangId') });
  return response.data;
});

const contentSlice = createSlice({
  name: 'content',
  initialState: {
    activeSite: Cookies.get('bzaSite'),
    activeSiteDetail: false,
    activeTemplate: Cookies.get('bzaTemplate') && Cookies.get('bzaTemplate') !== 'false' ? Number(Cookies.get('bzaTemplate')) : null,
    activeTemplateStructure: false,
    templateList: false,
  },
  reducers: {
    setActiveSite(state, action) {
      const currState = state;
      currState.activeSite = action.payload;
      Cookies.set('bzaSite', action.payload);
    },
    setActiveTemplate(state, action) {
      const currState = state;
      currState.activeTemplate = action.payload;
      Cookies.set('bzaTemplate', action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveSiteDetail.fulfilled, (state, action) => {
      const currState = state;
      currState.activeSiteDetail = action.payload;
      currState.templateList = action.payload.templates;
      const aT = action.payload.templates?.length ? action.payload.templates[0].id : null;
      if (aT) {
        currState.activeTemplate = aT;
        getActiveTemplateStructure(aT);
      }
    });
    builder.addCase(getActiveTemplateStructure.fulfilled, (state, action) => {
      const currState = state;
      const newFormat = action.payload?.pages?.map((item) => ({
        ...item,
        expanded: true,
      }));
      currState.activeTemplateStructure = {
        treeData: newFormat,
        name: action.payload.name,
      };
    });
  },
});

export const contentActions = contentSlice.actions;
export default contentSlice;

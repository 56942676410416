import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Switch, IconButton, Collapse, TextareaAutosize, Button, TextField, CircularProgress, InputAdornment, Paper, Box } from '@mui/material';
import BZHelmet from '@/utils/BZHelmet';
import BaseMainTitle from '@/components/BaseMainTitle';
import Input from '../CRM/redesigning/InputContainerRedesign';
import Swal from 'sweetalert2';
import _ from 'lodash';
import CustomTable from '@/components/Table/CustomTable';
import CustomSelectRedesign from '../CRM/redesigning/CustomSelectRedesign';
import { fetchData } from '@/utils/fetchDataFunc';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import SelectAutocomplete from '@/components/Input/ProductManagement/SelectAutoComplete';
import { Link } from 'react-router-dom';


function VATProfiles() {
  const { control, handleSubmit, reset, resetField } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch();

  const [vatProfiles, setVatProfiles] = useState([]);
  const [count, setcount] = useState(0);
  const [Categories, setCategories] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [checkboxSelected, setCheckboxSelected] = useState([]);
  const [checkboxSelectedProdCatVat, setCheckboxSelectedProdCatVat] = useState([]);
  const [vatRates, setVatRates] = useState([]);  
  const [productCategoryVat, setProductCategoryVat] = useState([]);
  
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [mode, setMode] = useState('edit');
  const [selectedProfile, setSelectedProfile] = useState(null);  
  const [selectedVatRates, setSelectedVatRates] = useState([]);  
  
  const [newVatRates, setNewVatRates] = useState([]);
  const [addNewVatRates, setAddNewVatNew] = useState(false);
  const [isAddNewProfile, setIsAddNewProfile] = useState(null);

  const getCategoryList = useCallback((params = { client: clientId, max_size: true }) => fetchData(ProductManagementApi().getCategories, params), [clientId]);

  const getCategories = useCallback(
    async () => {
      const [categories] = await Promise.all([getCategoryList()]);

      if (categories) {
        setCategories(categories?.results);
      }
    },
    [getCategoryList]
  );

  useEffect(() => {
    getCategories()
  }, [clientId]);

  const productCatVat = useCallback((params) => fetchData(ProductManagementApi().getProductCategoryVat, params), []);

  const getProductCatVat = useCallback(async (id) => {
    try {
      const [rate] = await Promise.all([productCatVat({rate__profile: id})]);
      if (rate) {
        const { results } = rate;

        setProductCategoryVat(results);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingOptions(false);
    }
  }, [productCatVat]);

  // useEffect(() => {
  //   getProductCatVat();
  // }, [getProductCatVat]);

  const vatRateList = useCallback(
    (params) => {
      return fetchData(ProductManagementApi().getVatRates, params);
    },
    []
  );

  const getVatRateList = useCallback(
    async (id) => {
      try {
        const [rate] = await Promise.all([vatRateList({ profile: id })]); 
        if (rate) {
          const { results } = rate;
          setVatRates(results);
          setIsLoadingOptions(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoadingOptions(false);
      }
    },
    [vatRateList]
  );

  const vatProfileList = useCallback((params) => fetchData(ProductManagementApi().getVatProfiles, params), [clientId]);

  const getVatProfileList = useCallback(
    async (param) => {
      const [vat] = await Promise.all([vatProfileList(param)]);

      if (vat) {
        if (vat?.results.length === 0) {
          setIsAddNewProfile(true);
        } else {
          setIsAddNewProfile(false);
        }

        setVatProfiles(vat?.results);

        if (!selectedProfile && vat?.results?.length > 0) {
          setSelectedProfile(vat?.results[0]);
          getVatRateList(vat?.results[0]?.id);
          getProductCatVat(vat?.results[0]?.id);
        }
      }

      setIsFetching(false);

      return vat?.results;
    },
    [vatProfileList]
  );

  useEffect(() => {
    getVatProfileList({ client: clientId });
  }, [clientId]);

  const handleClickVatRates = (_, id) => {
    const selectedIndex = selectedVatRates.indexOf(id);
    let newSelected = [...selectedVatRates];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedVatRates(newSelected);
  };

  const handleSelectAllVatRates = () => {    
    if (selectedVatRates.length === vatRates.length) {
      setSelectedVatRates([]);
      return;
    } else {
      const newSelecteds = vatRates?.map((n) => n.id);
      setSelectedVatRates(newSelecteds);
      return;
    }
  }

  const handleClickVatProfiles = (event, id) => {
    const selectedIndex = checkboxSelected.indexOf(id);
    let newSelected = [...checkboxSelected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setCheckboxSelected(newSelected);
  };

  const handleSelectAllClickVatProfiles = () => {
    if (checkboxSelected.length === vatProfiles.length) {
      setCheckboxSelected([]);
      return;
    } else {
      const newSelecteds = vatProfiles?.map((n) => n.id);
      setCheckboxSelected(newSelecteds);
      return;
    }
  };  

  const isSelectedInput = (name) => selectedVatRates.indexOf(name) !== -1;
  const isItemSelectedProfiles = (name) => checkboxSelected.indexOf(name) !== -1;

  const handleError = async (error) => {    
    if (error.response && error.response.data) {
      const responseData = error.response.data;
  
      if (Array.isArray(responseData.errors) && responseData.errors.length > 0) {
        const firstErrorObj = responseData.errors[0];
        console.log({firstErrorObj});
        
  
        await Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: `${firstErrorObj.error}`,
        });
      } 
      else if (responseData.errors) {
        const firstError = responseData.errors[0];
        await Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error: ${firstError.error}`,
        });
      }
      else {
        await Swal.fire({
          icon: "error",
          title: "Unknown Error",
          text: "Something went wrong.",
        });
      }
    } else {
      await Swal.fire({
        icon: "error",
        title: "Unknown Error",
        text: "Something went wrong.",
      });
    }
  }

  const handleChangeSelectedProfiles = async () => {
    const { updateVatProfileName, addNewVatProfile } = ProductManagementApi();

    try {

      if(!isAddNewProfile) {
        const isProfileNameChanges = selectedProfile?.profile_name === selectedProfile?.profile_name_new;
        
        if(!isProfileNameChanges) {
          const {profile_name_new, ...rest} = selectedProfile;
  
          const newName = {
            ...rest,
            profile_name: profile_name_new
          }
  
          await updateVatProfileName(selectedProfile.id, newName);
          getVatProfileList({ client: clientId }).then((profiles) => {            
            if (profiles?.length) {
              const updatedProfile = profiles.find(profile => profile.id === selectedProfile.id);
              
              if (updatedProfile) {
                setSelectedProfile(updatedProfile);
              }
            }
          });
          Swal.fire({
            title: 'Success !',
            text: 'Successfully Change Profile Name !',
            icon: 'success',
          });
        }
      } else {
        const newData = {
          profile_name: selectedProfile?.profile_name_new,
          client: clientId
        }

        await addNewVatProfile(newData);
        const profiles = await getVatProfileList({ client: clientId });
        
        if (profiles?.length) {
          const createdProfile = profiles.find(profile => profile.profile_name === selectedProfile?.profile_name_new);          
          
          if (createdProfile) {
            setSelectedProfile(createdProfile);
            getVatRateList(createdProfile?.id);
            getProductCatVat(createdProfile?.id);
          }
        }

        setIsAddNewProfile(false);
  
        Swal.fire({
          title: 'Success!',
          text: 'Successfully Added New VAT Profile!',
          icon: 'success',
        });
      }
    } catch (error) {
      handleError(error)
    }
  }

  // VAT RATES ALL FUNCTION
  const handleAddNewVatRatesButton = (val) => {
    if (val === true) {
      setAddNewVatNew(true);
      setNewVatRates([...newVatRates, { id: newVatRates.length + 1, profile: selectedProfile?.id, rate_name: "", rate_value: "" }]);
    } else {
      setAddNewVatNew(false);
      setNewVatRates([]);
    }
  }

  const addNewVatRatesDimension = () => {
    setNewVatRates([...newVatRates, { id: newVatRates.length + 1, profile: selectedProfile?.id, rate_name: "", rate_value: "" }]);
  };

  const removeLastVatRatesDimension = () => {
    if (newVatRates.length > 1) {
      setNewVatRates(newVatRates.slice(0, -1));
    }
  };

  const handleVatRatesDimensionChange = (index, field, event) => {    
    const newVatRatesDimensions = [...newVatRates];
    newVatRatesDimensions[index][field] = event;
    setNewVatRates(newVatRatesDimensions);
  };

  const handleBulkAddNew = async () => {
    const { bulkProcessVatRates } = ProductManagementApi();
  
    Swal.fire({
      title: 'Deleting...',
      icon: 'info',
      text: 'Please wait while we delete the VAT Profiles.',
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      const newData = newVatRates.filter((dt) => dt.rate_name !== "")?.map((dt) => {
        const { id, rate_value, ...rest } = dt;
        return {
          ...rest,
          rate_value: rate_value ? rate_value : "0"
        };
      });
  
      const combinedData = [...vatRates, ...newData];
  
      await bulkProcessVatRates(combinedData);
  
      setNewVatRates([]);
      setAddNewVatNew(false);
      getVatRateList(selectedProfile?.id);
  
      Swal.fire({
        title: 'Success !',
        text: 'Successfully Added New Vat Rates !',
        icon: 'success',
      });
  
    } catch (error) {
      handleError(error);
    }
  };

  const DeleteVatProfilesDatabyId = () => {
    const { bulkDeleteVatProfiles } = ProductManagementApi();
  
    Swal.fire({
      title: 'Deleting...',
      icon: 'info',
      text: 'Please wait while we delete the VAT Profiles.',
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    try {
      const idList = {
        "ids": checkboxSelected
      };
  
      bulkDeleteVatProfiles(idList)
        .then(async() => {
          setSelectedProfile(null);
          setCheckboxSelected([]);
          const updatedProfiles = await getVatProfileList({ client: clientId });
          if (updatedProfiles?.length > 0) {
            setSelectedProfile(updatedProfiles[0]);
            getVatRateList(updatedProfiles[0]?.id);
            getProductCatVat(updatedProfiles[0]?.id);
          }
  
          Swal.fire({
            title: 'Success!',
            text: 'Successfully Deleted VAT Profiles!',
            icon: 'success',
          });
        })
        .catch((err) => {
          console.error(err);
          Swal.fire({
            title: 'Error!',
            text: 'Failed to delete VAT Profiles.',
            icon: 'error',
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const DeleteVatRatesDatabyId = () => {
    const { bulkDeleteVatRates } = ProductManagementApi();

    Swal.fire({
      title: 'Deleting...',
      icon: 'info',
      text: 'Please wait while we delete the VAT Rates.',
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const idList = {
        "ids": selectedVatRates
      }
      
      bulkDeleteVatRates(idList)
        .then((res) => {
          console.log({res});
            getVatRateList(selectedProfile?.id);
            setSelectedVatRates([]);
            Swal.fire({
              title: 'Success !',
              text: 'Successfully Deleted VAT Profiles !',
              icon: 'success',
            });
        })
        .catch((err) => {
          console.error(err);
          });
    } catch (error) {
      console.error(error);
    }
  };

  const handleProductCatVat = async (catId, rate) => {
    try {
      const newData = {
        category: catId,
        rate: rate || null,
      };
  
      setProductCategoryVat((prevData) => {
        const existingCategoryIndex = prevData.findIndex(item => item.category === catId);
  
        if (existingCategoryIndex !== -1) {
          const updatedData = [...prevData];
          updatedData[existingCategoryIndex] = { ...updatedData[existingCategoryIndex], rate: rate };
          submitVatProductCategories(updatedData[existingCategoryIndex], "edit");
          return updatedData;
        } else {
          submitVatProductCategories(newData, "add");
          return [...prevData, newData];
        }
      });
  
      console.log({ newData });
    } catch (error) {
      handleError(error);
    }
  };
  
  const submitVatProductCategories = async (data, action = "add") => {
    const { addNewProductCategoryVat, updateProductCategoryVat } = ProductManagementApi();
  
    try {
      if (action === "add") {
        await addNewProductCategoryVat(data);
        Swal.fire({
          title: 'Success!',
          text: 'Successfully added new VAT product category!',
          icon: 'success',
        });
        console.log("Added new VAT product category:", data);
      } else if (action === "edit") {
        await updateProductCategoryVat(data?.id, data);
        Swal.fire({
          title: 'Success!',
          text: 'Successfully added new VAT product category!',
          icon: 'success',
        });
        console.log("Edited existing VAT product category:", data);
      }
  
      if (selectedProfile?.id) {
        await getProductCatVat(selectedProfile.id);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleChangeVatRateData = (value, key, id) => {  
    const updatedVatRates = [...vatRates];
  
    const index = updatedVatRates.findIndex(rate => rate.id === id);
  
    if (index !== -1) {
      updatedVatRates[index] = {
        ...updatedVatRates[index],
        [key]: value
      };
  
      setVatRates(updatedVatRates);
    } else {
      console.warn(`No VAT rate found with id ${id}`);
    }
  };

  const updateVatRates = async (vatRateId) => {
    const { updateVatRatesById } = ProductManagementApi();
  
    const selectedRate = vatRates.find(rate => rate.id === vatRateId);
  
    if (!selectedRate) {
      Swal.fire({
        title: 'Error!',
        text: 'VAT Rate not found.',
        icon: 'error',
      });
      return;
    }
  
    try {
      Swal.fire({
        title: 'Updating...',
        icon: 'info',
        text: 'Please wait while we update the VAT Rate.',
        didOpen: () => {
          Swal.showLoading();
        },
      });
  
      const response = await updateVatRatesById(selectedRate.id, selectedRate);
      console.log({ response });
  
      await getVatRateList(selectedProfile?.id);
      setSelectedVatRates([]);
  
      Swal.fire({
        title: 'Success!',
        text: 'Successfully updated VAT Rate!',
        icon: 'success',
      });
  
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update VAT Rate. Please try again.',
        icon: 'error',
      });
    }
  };

  const handleNewProfiles = () => {
    setCheckboxSelected([]);
    setSelectedProfile(null);
    setVatRates([]);
    setProductCategoryVat([]);
    setIsAddNewProfile(true);
  }

  return (
    <div className="w-full h-full bg-white">
      <BZHelmet title="VAT Profile List" description="" content="" />
      <BaseMainTitle title="VAT Profile List" />

      <div className="p-4">
        <div className="flex font-semibold capitalize">
          <div className="w-[30%] border-b-2 px-2 pb-1 flex items-center space-x-2">
            <Checkbox
              inputProps={{ "aria-label": "select all Market" }}
              indeterminate={
                checkboxSelected.length > 0 &&
                checkboxSelected.length < vatProfiles.length
              }
              checked={
                vatProfiles.length > 0 &&
                checkboxSelected.length === vatProfiles.length
              }
              onClick={handleSelectAllClickVatProfiles}
            />
            <span>Profiles</span>
          </div>
          <div className="w-[70%] border-b-2 px-2 pb-1 flex items-center">
            <p>Detail</p>
          </div>
        </div>
        <div className="flex h-[80vh]">
          <div className="w-[30%] border-r-2 pt-4">
            <ul className="">
              {vatProfiles.length > 0 &&
                vatProfiles.map((vat) => (
                  <li key={vat?.id} className="flex items-center space-x-2 p-2">
                    <Checkbox
                      color="primary"
                      onClick={(event) => handleClickVatProfiles(event, vat.id)}
                      inputProps={{ "aria-labelledby": vat.id }}
                      checked={isItemSelectedProfiles(vat.id)}
                    />
                    <div className=''>
                      <p className={`cursor-pointer ${selectedProfile?.id === vat.id && "text-[#FC581D]"}`} onClick={() => {
                        setSelectedProfile(vat);
                        getVatRateList(vat.id);
                        getProductCatVat(vat.id);
                        setIsAddNewProfile(false);
                      }}>
                        {vat?.profile_name}
                      </p>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="flex justify-end gap-2 mt-4 px-2">
              <button
                className={`
                  flex gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
                  ${checkboxSelected.length ? 'bg-[#FC581D] cursor-pointer' : 'bg-[#FC581D26] cursor-default'}
                `}
                disabled={checkboxSelected.length === 0}
                onClick={DeleteVatProfilesDatabyId}
              >
                Delete
              </button>
              <button 
                className="flex h-[40px] items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
                style={{ backgroundColor: '#2C6D47' }}
                onClick={() => handleNewProfiles()}
                disabled={selectedProfile?.profile_name_new === ""}
              >
                Add
              </button>
            </div>
          </div>
          {
            !isFetching && (
              <div className="w-[70%] px-2 pt-4">
                <div className="space-y-4 w-[65%]">
                  <div className={`w-full h-fit col-span-2`}>
                    <p className={`font-semibold `}>Name</p>
                    <TextField
                      className="w-full"
                      id="outlined-basic"
                      value={
                        selectedProfile ? "profile_name_new" in selectedProfile 
                        ? selectedProfile.profile_name_new 
                        : selectedProfile?.profile_name
                        : ""
                      }
                      variant="outlined"
                      onChange={(e) => {
                        const { value } = e.target;
                          setSelectedProfile((vat) => ({ ...vat, profile_name_new: value }));
                      }}
                      onBlur={() => {
                        if (isAddNewProfile) return;
                        handleChangeSelectedProfiles();
                      }}
                      onKeyDown={(e) => {
                        if (isAddNewProfile) return;
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleChangeSelectedProfiles();
                        }
                      }}
                    />
                  </div>
                  {
                    isAddNewProfile && (
                      <div className="w-full flex gap-2 justify-end">
                          <button
                            type="submit"
                            name="submit contact"
                            className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                            style={{ backgroundColor: '#FC581D' }}
                            onClick={() => {
                              setSelectedProfile(vatProfiles[0]);
                              getVatRateList(vatProfiles[0].id);
                              getProductCatVat(vatProfiles[0].id);
                              setIsAddNewProfile(false);
                            }}
                          >
                            <p className="text-white">Cancel</p>
                          </button>
                          <button
                            type="submit"
                            name="submit contact"
                            className={`
                              flex gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
                              ${
                                selectedProfile && selectedProfile?.profile_name_new 
                                  ? 'bg-[#2C6D47] cursor-pointer' 
                                  : 'bg-[#2c6d4767] cursor-default'
                              }
                            `}
                            onClick={() => handleChangeSelectedProfiles()}
                            disabled={selectedProfile === null || selectedProfile?.profile_name_new === ""}
                          >
                            <p className="text-white">Add New</p>
                          </button>
                      </div>
                    )
                  }
                  {
                    !isAddNewProfile && (
                      <>
                        <div className="space-y-2">
                          <Box sx={{ width: '100%' }}>                  
                            <Paper sx={{ width: '100%', mb: 2 }}>
                              <Table className='border'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        color="primary"
                                        indeterminate={selectedVatRates.length > 0 && selectedVatRates.length < vatRates.length}
                                        checked={vatRates.length > 0 && selectedVatRates.length === vatRates.length}
                                        onClick={handleSelectAllVatRates}
                                        inputProps={{ 'aria-label': 'select all desserts' }}
                                        disabled={addNewVatRates}
                                      />
                                    </TableCell>
                                    <TableCell align='center' className='!font-bold'>name</TableCell>
                                    <TableCell align='center' className='!font-bold' width={150}>rate</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {
                                    vatRates.length > 0
                                      && vatRates.map((dt) => {
                                        const isItemSelected = isSelectedInput(dt.id);
                                        const labelId = `enhanced-table-checkbox-${dt.id}`;

                                        return (
                                          <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={dt.id} selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                              <Checkbox onClick={(event) => handleClickVatRates(event, dt.id)} color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} disabled={addNewVatRates} />
                                            </TableCell>
                                            <TableCell align="center">
                                              <TextField
                                                value={dt?.rate_name || ''}
                                                size="small"
                                                fullWidth
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  handleChangeVatRateData(value, "rate_name", dt.id);
                                                }}
                                                onBlur={() => updateVatRates(dt.id)}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    updateVatRates(dt.id);
                                                  }
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell align="center">
                                            <TextField
                                                value={dt?.rate_value || ''}
                                                size="small"
                                                fullWidth
                                                InputProps={{
                                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                  inputProps: { style: { textAlign: 'right' } }
                                                }}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  if (!isNaN(value) || value === "") {
                                                    handleChangeVatRateData(value, "rate_value", dt.id);
                                                  }
                                                }}
                                                onBlur={() => updateVatRates(dt.id)}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    updateVatRates(dt.id);
                                                  }
                                                }}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })
                                  }
                                  {
                                    addNewVatRates && newVatRates.map((nv, idx) => (
                                      <TableRow>
                                        <TableCell>{""}</TableCell>
                                        <TableCell align="center">
                                          <TextField
                                            value={nv?.rate_name || ''}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                              const value = e.target.value;                                  
                                              handleVatRatesDimensionChange(idx, "rate_name", value)
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell align="center">
                                        <TextField
                                            value={nv?.rate_value || ''}
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                              inputProps: { style: { textAlign: 'right' } }
                                            }}
                                            onChange={(e) => {
                                              const value = e.target.value;       
                                              if (!isNaN(value) || value === "") {
                                                handleVatRatesDimensionChange(idx, "rate_value", value)
                                              }                           
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  }
                                </TableBody>
                              </Table>
                            </Paper>
                          </Box>
                          <div className="w-full flex gap-2 justify-end">
                            {
                              !addNewVatRates ? (
                                <>
                                  <button
                                    className={`
                                      flex gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
                                      ${selectedVatRates.length ? 'bg-[#FC581D] cursor-pointer' : 'bg-[#FC581D26] cursor-default'}
                                    `}
                                    disabled={selectedVatRates.length === 0}
                                    onClick={DeleteVatRatesDatabyId}
                                  >
                                    Delete
                                  </button>
                                  <button
                                    type="submit"
                                    name="submit contact"
                                    className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                                    style={{ backgroundColor: '#2C6D47' }}
                                    onClick={() => handleAddNewVatRatesButton(true)}
                                  >
                                    <p className="text-white">Add</p>
                                  </button>
                                </>
                              ): (
                                <>
                                  <button
                                    type="submit"
                                    name="submit contact"
                                    className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                                    style={{ backgroundColor: '#FC581D' }}
                                    onClick={() => handleAddNewVatRatesButton(false)}
                                  >
                                    <p className="text-white">Cancel</p>
                                  </button>
                                  <button
                                    className={`
                                      flex gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 text-white
                                      ${newVatRates.length > 1 ? 'bg-[#FC581D] cursor-pointer' : 'bg-[#FC581D26] cursor-default'}
                                    `}
                                    name="unpairCollection"
                                    type="button"
                                    disabled={newVatRates.length <= 1}
                                    onClick={removeLastVatRatesDimension}
                                  >
                                    Remove New Last Row
                                  </button>
                                  <button
                                    className="btn bg-[#2C6D47] p-2 text-white rounded-lg border cursor-pointer"
                                    name="manageOperators"
                                    type="button"
                                    onClick={addNewVatRatesDimension}
                                  >
                                    Add New Row
                                  </button>
                                  <button
                                    type="submit"
                                    name="submit contact"
                                    className="flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
                                    style={{ backgroundColor: '#2C6D47' }}
                                    onClick={handleBulkAddNew}
                                  >
                                    <p className="text-white">Submit New</p>
                                  </button>
                                </>
                              )
                            }

                          </div>
                        </div>
                        <div className="mt-4">
                          <Box sx={{ width: '100%' }}>                
                            <Paper sx={{ width: '100%', mb: 2 }}>
                              <Table className='border'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align='center' className='!font-bold'>Product Categories</TableCell>
                                    <TableCell align='center' className='!font-bold' width={500}>Rate Type</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {
                                    Categories.length > 0
                                      && Categories.map((dt) => {
                                        return (
                                          <TableRow>
                                            <TableCell align="left">{dt?.name}</TableCell>
                                            <TableCell align="center">
                                              <SelectAutocomplete
                                                variant="outlined"
                                                placeHolder="Select Rate Type"
                                                options={vatRates.map((dt) => ({name: `${dt?.rate_name} (${dt?.rate_value}%)`, value: dt?.id}))}
                                                defaultValue={productCategoryVat.find((pc) => pc.category === dt?.id)?.rate || ""}
                                                onChange={(event, value) => {
                                                  handleProductCatVat(dt?.id, value);
                                                }}
                                                isLoading={false}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })
                                  }
                                </TableBody>
                              </Table>
                            </Paper>
                          </Box>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default VATProfiles;

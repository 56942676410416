/* eslint-disable no-multi-assign */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable consistent-return */
import React from 'react';
import Cookies from 'js-cookie';
import assetApi from '../../api/content/asset';

const baseURL = import.meta.env.VITE_API_URL;

export class MyUploadAdapter extends React.Component {
  constructor(props) {
    super(props);

    this.loader = props;
    this.config = props.config;
    this.url = `${baseURL}assets/`;
  }

  upload() {
    return new Promise((resolve, reject) => {
      this.sendRequest(resolve, reject);
    });
  }

  sendRequest(resolve, reject) {
    const { uploadAssets } = assetApi();
    const { loader } = this;

    const data = new FormData();
    this.loader.file.then((result) => {
      data.append('client', Cookies.get('bzaClient'));
      data.append('name', 'Image');
      data.append('has_languages', false);
      data.append('asset_type', 1);
      data.append('asset_file', result);

      uploadAssets(data)
        .then((res) => {
          resolve({ default: res.data.asset_file });
          loader.uploadTotal = 1;
          loader.uploaded = 1;
        })
        .catch((err) => {
          const genericErrorText = `Couldn't upload file: ${loader.file.name}.`;
          const { response } = err;
          return reject(response && response.error ? response.error.message : genericErrorText);
        });
    });
  }
}

export default function CustomUploadAdapterPlugin(editor) {
  const edt = editor;
  edt.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

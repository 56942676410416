import { ExpandMore, VisibilityOff } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import React, { useState } from 'react';
import Topic from './Topic';

export default React.memo(function TopicStatus({ section }) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion expanded={expanded === section.name} onChange={handleChange(section.name)} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary aria-controls="panel1d-content" expandIcon={<ExpandMore color="#c2c7d0" />}>
        <div className="w-full flex items-center">
          <span className="w-1/6">
            {section.name} {section.is_hidden ? <VisibilityOff className="ml-4" fontSize="14" /> : null}
          </span>
          <span className="ml-4 text-center">{`(${section.topics.length})`}</span>
        </div>
      </AccordionSummary>
      {section.topics.length > 0 && (
        <AccordionDetails>
          <Box className="w-full">
            {section.topics?.map((topic) => {
              return <Topic key={topic.id} section={section} topic={topic} />;
            })}
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
});

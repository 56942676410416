import { Mail, Person } from '@mui/icons-material';
import { Box, Fade, Popper } from '@mui/material';
import React from 'react';

export default function ProfileDetail({ idProfile, openProfile, anchorProfile, userDetail }) {
  return (
    <Popper id={idProfile} open={openProfile} anchorEl={anchorProfile} placement="bottom" sx={{ zIndex: 10000 }}>
      <Fade in={openProfile}>
        <Box className="border rounded-md border-gray-300 shadow-md p-4 bg-white">
          {userDetail ? (
            <div className="flex flex-col justify-center items-center">
              <div className="w-16 h-16 text-6xl flex items-center justify-center mb-4">
                {userDetail.avatar && <img className="h-16 w-16 object-cover border border-gray-100 rounded-full" src={userDetail.avatar} alt="" />}
                {!userDetail.avatar && <Person fontSize="inherit" color="disabled" className="m-auto" />}
              </div>
              <div className="flex flex-col justify-start">
                <div className="flex space-x-1">
                  <span className="text-lg font-bold">{userDetail.name}</span>
                </div>
                <div className="flex space-x-1 items-center">
                  <Mail fontSize="small" color="disabled" />
                  <span className="text-gray-300 text-ellipsis">{userDetail.email}</span>
                </div>
              </div>
            </div>
          ) : (
            <div>Loading</div>
          )}
        </Box>
      </Fade>
    </Popper>
  );
}

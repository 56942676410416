/* eslint-disable */

import clsxm from '@/utils/clsxm';
import { AddBoxOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { blueGrey } from '@mui/material/colors';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Avatar, Select, MenuItem, FormControl, ButtonBase } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactSelectSingle from '../../../components/Input/ReactSelectSingle';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import useDelete from '../../../hooks/useDelete';
import useGet from '../../../hooks/useGet';
import { fetchFilterDepartmentNew, fetchFilterEmployee } from '../../../hooks/useNewHrData';
import { getDefaultSelectObject, getOptionSelectWithKey } from '../../../lib/OptionsInputHelper';
import { handleFilter } from '../../../store/slices/filterComponentSlice';
import DeleteButton from './assets/deleteButton.svg';
import EditButton from './assets/editButton.svg';
import { Tooltip } from 'react-tooltip';
import { utils, writeFileXLSX } from 'xlsx';
import { format } from 'date-fns';

const optStatus = [
  { id: '', name: '--No Selected--' },
  { id: 'true', name: 'Active' },
  { id: 'false', name: 'Non Active' },
];

function containsNonNullValues(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value !== null && typeof value !== 'undefined' && !(typeof value === 'string' && value.trim() === '') && !(typeof value === 'object' && !containsNonNullValues(value))) {
      return true;
    }
  }
  return false;
}

function findObjectInArray(array, targetKey) {
  for (let i = 0; i < array.length; i++) {
    const obj = array[i];

    if (typeof obj === 'object' && obj !== null) {
      if (targetKey in obj) {
        return obj[targetKey];
      } else {
        const nestedObj = findObjectInArray(Object.values(obj), targetKey);

        if (nestedObj !== null) {
          return nestedObj;
        }
      }
    }
  }

  return null;
}

export default function EmployeeList() {
  const { control, setValue, watch } = useForm();

  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.activeClient);

  const allFilterData = useSelector((state) => state.filterComponent);
  const filterEmployee = findObjectInArray(allFilterData.filterData, clientId)?.employee;

  const watchingUnit = HandleWatchingState(watch('unit') || true);
  const watchingDepartment = HandleWatchingState(watch('department'));

  const [data, setData] = useState([]);
  const [allBusinessunit, setAllBusinessunit] = useState([]);
  const [allDepartement, setAllDepartement] = useState([]);
  const businessunitSelected = watch('unit') || '';
  const departementSelected = watch('department') || '';
  const [isCancel, setIsCancel] = useState(false);
  const [count, setCount] = useState(0);

  const apiRef = useGridApiRef();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  useEffect(() => {
    if (!businessunitSelected && !filterEmployee?.unit) {
      setIsCancel(true);
    }
  }, [businessunitSelected, filterEmployee?.unit]);

  useEffect(() => {
    setValue('is_active', 'true');
  }, []);

  useEffect(() => {
    if (containsNonNullValues(filterEmployee)) {
      setValue('unit', filterEmployee?.unit);
      setValue('department', filterEmployee?.department);
    }
  }, [setValue, filterEmployee?.unit, filterEmployee?.department]);

  const queryKey = {
    client: clientId,
    employment_histories__department__business_unit: businessunitSelected,
    current_department: departementSelected,
    // max_size: true,
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
    is_active: watch('is_active'),
  };

  const { isFetching } = useQuery(['employees', { queryKey }], fetchFilterEmployee, {
    onSuccess: (res) => {
      setCount(res?.data?.count);
      const listData = res?.data?.results || res?.data;
      // setCount(e?.data?.count);
      setData(listData);
    },
    refetchOnWindowFocus: false,
  });

  useGet({
    url: `accounting/business_units`,
    queryString: {
      max_size: true,
      client: clientId,
    },
    validate: 'units',
    onSuccess: (res) => {
      setAllBusinessunit([{ name: '-- No Selected --', id: '' }, ...res.data.results]);
    },
  });

  const queryKeyDep = {
    client: clientId,
    max_size: true,
    business_unit: businessunitSelected,
  };

  useQuery(['Departments', queryKeyDep], fetchFilterDepartmentNew, {
    onSuccess: (e) => {
      const listData = e?.data?.results || e?.data;
      setAllDepartement([{ name: '-- No Selected --', id: '' }, ...listData]);
    },
    refetchOnWindowFocus: false,
  });

  const destroyEmployee = useDelete({
    confText: `Are you sure want to delete this Employee ?`,
    confTitle: `Confirm Delete Employee`,
    success: 'Employee deleted',
    validate: ['employees'],
  });

  const onDestroyEmployee = (id) => {
    destroyEmployee(`employee-management/employees/${id}`);
  };

  const columns = [
    {
      field: 'business_unit',
      headerClassName: 'super-app-theme--header',
      headerName: 'Business Unit',
      width: 200,
      valueGetter: (val) => val?.row.current_employment?.department.business_unit.name,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (val) => {
        return <div className="w-[100%] text-center">{val?.row.current_employment?.department.business_unit.name || '-'}</div>;
      },
    },
    {
      field: 'department',
      headerClassName: 'super-app-theme--header',
      headerName: 'Department',
      width: 200,
      valueGetter: (val) => val?.row.current_employment?.department.name,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (val) => {
        return <div className="w-[100%] text-center">{val?.row.current_employment?.department.name || '-'}</div>;
      },
    },
    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      minWidth: 60,
      // maxWidth: 300,
      flex: 1,
      headerAlign: 'left',
      sortable: false,
      renderCell: (val) => {
        return (
          <>
            <Link id={`tooltip-id-${val?.row.id}`} to={`/hr/employee/${val?.row.id}/detail`}>
              <div className="flex items-center text-bz-green font-semibold">{val?.row.id}</div>
            </Link>
            <Tooltip className="!z-[1000]" anchorSelect={`#tooltip-id-${val?.row.id}`} place="top" positionStrategy="fixed">
              {`Employee ID : ${val?.row.id}`}
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'first_name',
      headerClassName: 'super-app-theme--header',
      headerName: 'Name',
      minWidth: 200,
      // maxWidth: 300,
      flex: 1,
      headerAlign: 'left',
      sortable: false,
      renderCell: (val) => {
        return (
          <Link to={`/hr/employee/${val?.row.id}/detail`}>
            <div className="flex items-center text-bz-green font-semibold">
              <Avatar sx={{ bgcolor: blueGrey[500], height: 25, width: 25, marginRight: 1 }} aria-label="recipe">
                {val?.row.avatar ? (
                  <img src={val?.row.avatar} alt="profile" className="object-cover h-[30px] w-[30px]" />
                ) : (
                  <AccountCircleIcon className="object-cover h-[37px] w-[37px]" />
                )}
              </Avatar>
              {val?.row.first_name} {val?.row.last_name}
            </div>
          </Link>
        );
      },
    },
    {
      field: 'job',
      headerClassName: 'super-app-theme--header',
      headerName: 'Job Title',
      width: 200,
      valueGetter: (val) => val?.row.current_employment?.position,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (val) => {
        return <div className="w-[100%] text-center">{val?.row.current_employment?.position || '-'}</div>;
      },
    },

    {
      field: 'employment_type',
      headerClassName: 'super-app-theme--header',
      headerName: 'Employment Type',
      width: 200,
      valueGetter: (val) => val?.row.current_employment?.employment_type,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderCell: (val) => {
        return <div className="w-[100%] text-center">{val?.row.current_employment?.employment_type || '-'}</div>;
      },
    },
    {
      field: 'hired_date',
      headerClassName: 'super-app-theme--header',
      headerName: 'Date Joined',
      width: 200,
      disableColumnMenu: true,
      headerAlign: 'center',
      sortable: false,
      renderCell: (val) => {
        return (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            {val?.row.hired_date}
          </div>
        );
      },
    },
    {
      field: 'status',
      headerClassName: 'super-app-theme--header',
      headerName: 'Status',
      width: 110,
      valueGetter: (val) => (val?.row.is_active ? 'Active' : 'Non Active'),
      sortable: false,
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: (val) => {
        return (
          <div className="flex items-center  justify-center relative h-full">
            <span className={clsxm('h-[20px] w-full    ', val?.row.is_active ? 'bg-[#c8ebc8]' : 'bg-[#e6afaf]')}>
              <div className="absolute top-0 left-1/2 -translate-x-1/2">{val?.row.is_active ? 'Active' : 'Non active'}</div>
            </span>
          </div>
        );
      },
    },
    {
      field: 'action',
      headerClassName: 'super-app-theme--header',
      headerName: 'Action',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (val) => {
        return (
          <div key={val} className="w-full flex space-x-2 justify-center">
            <Link to={`/hr/employee/${val?.row.id}/edit`} className="bz-btn-tb">
              <Edit fontSize="24" />
            </Link>
            <ButtonBase className="bz-btn-tb" onClick={() => onDestroyEmployee(val?.row.id)}>
              <Delete fontSize="24" />
            </ButtonBase>
          </div>
        );
      },
    },
  ];

  const memoizedEmpData = useMemo(() => data, [data]);

  const generateReport = (data) => {
    return data.map((dt, i) => {
      const hiredDate = dt.hired_date ? new Date(dt.hired_date) : new Date();
      return {
        ['Business Unit']: dt.current_employment?.department?.business_unit?.name,
        Department: dt.current_employment?.department?.name,
        ID: dt.employment_id,
        ['Name']: dt.last_name ? `${dt.first_name} ${dt.last_name}` : dt.first_name,
        ['Job Title']: dt.current_employment?.position,
        ['Employment Type']: dt.current_employment?.employment_type,
        ['Date Joined']: hiredDate,
        Status: dt.is_active ? 'Active' : 'Non Avtive',
        // ['Last Name']: dt.last_name,
        // Email: dt.email,
        // Gender: dt.gender,
        // Marital: dt.marital_status,
        // Phone: dt.phone,
        // Address: dt.address,
      };
    });
  };

  const exportExcel = () => {
    const removeUnusedObject = generateReport(memoizedEmpData).map((obj) => {
      // Create a new object without the 'id' property
      const { ...newObj } = obj;
      return newObj;
    });

    const finalReport = removeUnusedObject;
    const ws = utils.json_to_sheet(finalReport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    const dtT = format(new Date(), 'ddMMM');
    writeFileXLSX(wb, `employee-${dtT}.xlsx`);
  };

  return (
    <div>
      <Box
        sx={{
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        <Link to="/hr/employee/add" className="bz-btn w-fit ml-auto my-5">
          <AddBoxOutlined />
          <div>Add Employee</div>
        </Link>
        <div className="flex justify-between pb-[30px]">
          <div className="pt-[6px] font-bold">{memoizedEmpData?.length} Employee</div>
          <div className="flex space-x-4 items-center">
            <div className="flex gap-4 items-center">
              {/* <div className=" font-bold">Business Unit</div> */}
              <button className="h-fit flex items-center justify-center text-white p-[8px] bg-[#2C6D47] rounded-xl" onClick={exportExcel}>
                Export Excel
              </button>

              <div className="min-w-[250px]">
                <ReactSelectSingle
                  name="unit"
                  control={control}
                  options={getOptionSelectWithKey(allBusinessunit)}
                  defaultValue={getDefaultSelectObject(filterEmployee?.unit, getOptionSelectWithKey(allBusinessunit))}
                  placeholder="Select Business Unit"
                  dispatch={() => {
                    if (watchingUnit) {
                      const obj = findObjectInArray(allFilterData.filterData, clientId);
                      dispatch(
                        handleFilter({
                          [clientId]: {
                            ...obj,
                            employee: {
                              ...obj?.employee,
                              unit: watch('unit'),
                            },
                          },
                        })
                      );
                    }
                  }}
                  dispatchCondition={watchingUnit}
                />
              </div>
            </div>
            <div className="flex gap-4 items-center">
              {/* <div className=" font-bold">Departement</div> */}
              <div className="min-w-[250px]">
                <ReactSelectSingle
                  name="department"
                  control={control}
                  options={getOptionSelectWithKey(allDepartement)}
                  placeholder="Select Department"
                  // floatLabel={false}
                  isDisabled={!businessunitSelected}
                  defaultValue={getDefaultSelectObject(watch(`department`) || departementSelected, getOptionSelectWithKey(allDepartement))}
                  isCancel={isCancel}
                  setIsCancel={setIsCancel}
                  resetFunction={() => setValue('department', '')}
                  dispatch={() => {
                    if (watchingDepartment) {
                      const obj = findObjectInArray(allFilterData.filterData, clientId);
                      dispatch(
                        handleFilter({
                          [clientId]: {
                            ...obj,
                            employee: {
                              ...obj?.employee,
                              department: watch('department'),
                            },
                          },
                        })
                      );
                    }
                  }}
                  dispatchCondition={watchingDepartment}
                />
              </div>
            </div>
            <div className="flex gap-4 items-center">
              {/* <div className=" font-bold">Departement</div> */}
              <div className="min-w-[250px]">
                <ReactSelectSingle
                  name="is_active"
                  control={control}
                  options={getOptionSelectWithKey(optStatus)}
                  defaultValue={getDefaultSelectObject('true', getOptionSelectWithKey(optStatus))}
                  placeholder="Select Status"
                />
              </div>
            </div>
          </div>
        </div>

        <DataGrid
          autoHeight
          rows={memoizedEmpData}
          columns={columns}
          rowCount={count}
          // pageSize={50}
          onPageChange={(e, val) => {}}
          // initialState={{
          //   pagination: { paginationModel: { pageSize: 100} },
          // }}
          paginationModel={paginationModel}
          // pagination={true}
          checkboxSelection
          paginationMode="server"
          disableSelectionOnClick
          // experimentalFeatures={{ newEditingApi: true }}
          className="flex justify-center z-0"
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 20, 50, 100]}

          // rowsPerPageOptions={[265, 50, 1600]}
        />
      </Box>
    </div>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import getTimeZone from '../../../../utils/Task/getTimeZone';
import projectApi from '../../../../api/task/project';

export default function ModalTaskStopTime({ allData, activeTask, callback, handleClose }) {
  const [task, setTask] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [updatedStoppedTime, setUpdatedStoppedTime] = useState(false);

  const getTaskDetail = useCallback(
    (topicId) => {
      const { getTopicDetail } = projectApi();
      getTopicDetail(topicId)
        .then((res) => {
          if (res.status === 200) {
            setTask(res.data);
            setSelectedRow(allData[activeTask.id - 1]);
            setUpdatedStoppedTime(allData[activeTask.id - 1].time_out);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [activeTask, allData]
  );

  const onUpdate = () => {
    const currDate = new Date(updatedStoppedTime);
    const currTimezone = getTimeZone();

    const dataLog = {
      topic: activeTask.Task,
      time_out: currDate.toISOString(),
    };
    dataLog.timezone = {
      name: currTimezone.name,
      utc_offset: currTimezone.offset,
    };

    const { logWorkHours } = projectApi();
    logWorkHours(dataLog, selectedRow.id, {}, 'patch')
      .then((res) => {
        if (res.data) {
          callback();
          handleClose();
        }
      })
      .catch((err) => console.log(err.response));
  };

  useEffect(() => {
    if (!task && !!activeTask) {
      getTaskDetail(activeTask.Task);
    }
  });

  return (
    <Dialog
      open={!!activeTask}
      onClose={handleClose}
      sx={{ '& > .MuiDialog-container > .MuiPaper-root': { width: 567, position: 'absolute', top: '20vh', height: 'auto', overflowY: 'auto' } }}
    >
      <DialogTitle>{activeTask.Title}</DialogTitle>
      <DialogContent dividers>
        <Box className="flex flex-row justify-between">
          <div className="w-50">
            <span>Start Time: {activeTask.Start}</span>
          </div>
          <div className="w-50">
            <span>
              Spent Time: {activeTask.Time} / {`${activeTask.Minutes} min`}
            </span>
          </div>
        </Box>
        <Box className="flex flex-col mt-2 space-y-2">
          <span>Modify Stopped Time</span>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              ampm={false}
              ampmInClock={false}
              minDate={() => {
                const tOut = new Date(selectedRow?.time_in);
                return new Date(tOut.getTime + 1);
              }}
              value={updatedStoppedTime}
              onChange={(newValue) => {
                const tIn = new Date(selectedRow?.time_in).getTime();
                const tOut = new Date(newValue).getTime();
                if (tIn > tOut) {
                  return;
                }
                setUpdatedStoppedTime(newValue);
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} size="small" className='bz-btn secondary'>
          Cancel
        </Button>
        <Button variant="contained" onClick={onUpdate} size="small" className='bz-btn'>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/* eslint-disable */
import React, { useState, useEffect } from 'react';
// import dayjs from 'dayjs';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { TextField, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import ProcurementApi from '../../../api/Procurement/Procurement';
import { handleModal } from '../../../store/slices/modalSlice';

function EditHistory({ data, reload }) {
  const ProcurementApi = new ProcurementApi();
  const dispatch = useDispatch();
  const [value, setValue] = useState(data.timestamp);

  const handleChange = (newValue) => {
    setValue(newValue.toISOString());
  };
  // const convertedUtcDateTime = moment(value).format('YYYY-MM-DDTHH:mm:ss[Z]');

  // console.log('edit history value ', value.$d);
  // console.log('convertedUtcDateTime ', convertedUtcDateTime);
  // console.log('edit history value ', value);
  // console.log('data', data);
  // console.log('data id', data.id);

  const submitData = () => {
    const newTimestamp = value;
    const updatedata = {
      timestamp: newTimestamp,
    };
    ProcurementApi
      .updateHistory(data.id, updatedata)
      .then(() => {
        swal('Success !', 'You successfully edit history !', 'success');
        dispatch(handleModal({ modalId: '', componentName: '' }));
        reload(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteHistory = () => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this history!',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ProcurementApi
          .deleteHistory(data.id)
          .then((res) => {
            dispatch(handleModal({ modalId: '', componentName: '' }));
            reload(true);
            swal('Success !', 'You successfully deleted history !', 'success');
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  return (
    <div className="w-full flex flex-col gap-y-8">
      <div className="grid gap-y-2">
        <h2 className="font-medium">description :</h2>
        <p className="px-2">{data.description}</p>
      </div>
      <div className="grid gap-y-4">
        <h2 className="font-medium">Timestamp :</h2>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker label="Date&Time picker" value={value} onChange={handleChange} renderInput={(params) => <TextField {...params} />} />
        </LocalizationProvider>
      </div>
      <div className="flex justify-between pt-4 border-t">
        <div>
          <IconButton aria-label="delete" size="large" onClick={deleteHistory}>
            <DeleteIcon color="error" />
          </IconButton>
        </div>
        <div>
          <button className="w-full bg-[#2C6D47] p-2 text-white rounded-lg" variant="contained" onClick={submitData}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditHistory;

/* eslint-disable */

import { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { useParams } from 'react-router';
import BaseMainTitle from '../../../components/BaseMainTitle';
import useGet from '../../../hooks/useGet';
import usePatchOrPut from '../../../hooks/usePatchOrPut';
import BZHelmet from '../../../utils/BZHelmet';
// import BusinessUnitForm from '../../../components/Form/Whistleblower/BusinessUnitForm';
import BZButton from '@/components/Button/BZButton';
import ReactSelectMultiNonRHF from '@/components/Input/ReactSelectMultiNonRHF';
import ReactSelectSingleNonRHF from '@/components/Input/ReactSelectSingleNonRHF';
import usePostExtra from '@/hooks/usePostExtra';
import clsxm from '@/utils/clsxm';
import numbro from 'numbro';
import { LuArrowRightLeft } from 'react-icons/lu';
import { useSelector } from 'react-redux';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import { setCurrSource } from '@/store/slices/currencySlice';
import { useDispatch } from 'react-redux';

const generateDateTicks = (startDate, endDate, intervalDays) => {
  const ticks = [];
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + intervalDays)) {
    ticks.push(new Date(d));
  }
  return ticks;
};
const Rates = [
  { name: 'BI Rates', id: 'BI' },
  { name: 'KP Rates', id: 'KP' },
];

const customFormatNumber = (number) => {
  // First format the number without grouping for decimals
  const formatted = numbro(number).format({
    // mantissa: 7, // Total decimal places to keep, can be adjusted as needed
    thousandSeparated: true,
  });

  // Replace the first comma with your desired format
  return formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.', ',');
};

export default function CurrencySettings() {
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch();

  let dateFromDef = new Date();
  let dateToDef = new Date();

  dateToDef.setMonth(dateToDef.getMonth() - 3);

  const formattedDateFromDef = dateFromDef.toISOString().split('T')[0];
  const formattedDate3mDef = dateToDef.toISOString().split('T')[0];

  const [fromSelected, setFromSelected] = useState(null);
  const [toSelected, setToSelected] = useState(null);
  const [source, setSource] = useState(Rates[1]);
  const [paramsDate, setParamsDate] = useState({ date_from: formattedDate3mDef, date_to: formattedDateFromDef });
  const [ratesChart, setRatesChart] = useState([]);
  const [ratesOptionList, setRatesOptionList] = useState([]);
  const [pairedRatesOptionSubmit, setPairedRatesOptionSubmit] = useState([]);
  const [pairedRatesOptionCurrent, setPairedRatesOptionCurrent] = useState([]);
  const [noRates, setNoRates] = useState(true);
  const [options, setOptions] = useState(null);
  // 1m,3m,6m,ytd,1y,2y,5y,all
  const [rangeDate, setRangeDate] = useState('3m');

  const boothSelected = toSelected && fromSelected;
  const { businessUnitId } = useParams();

  const { data: optionRates, isFetching } = useGet({
    url: `currencies/?max_size=true&ordering=code`,
    validate: `currencies`,
    onSuccess: (res) => {
      if (res.status == 200) {
        setRatesOptionList(
          res?.data?.results.map((rate) => ({
            name: rate.code,
            id: rate.id,
          }))
        );
      } else {
        // setNoRates(true);
        // renderError({ data: JSON.parse(e.request.response) });
      }
    },
  });

  useGet({
    url: `currencies/?client=${clientId}&max_size=true&ordering=code`,
    validate: `currencies-${clientId}`,
    onSuccess: (res) => {
      if (res.status == 200) {
        setPairedRatesOptionSubmit(
          res?.data?.results.map((rate) => ({
            name: rate.code,
            id: rate.id,
          }))
        );
        setPairedRatesOptionCurrent(
          res?.data?.results.map((rate) => ({
            name: rate.code,
            id: rate.id,
          }))
        );
      } else {
        // setNoRates(true);
        // renderError({ data: JSON.parse(e.request.response) });
      }
    },
  });

  const createTooltip = (date, rates) => {
    const formattedRates = parseFloat(rates.toFixed(5));
    // return `Tooltip for rate ${(date, rates)}`;
    return ` <div class="p-2 flex flex-col !w-full min-w-[150px] text-[14px]">
            <p class="font-black">${date}</p>
            ${fromSelected?.name ? `<p class="text-blgue-400 flex">${fromSelected?.name ? `${fromSelected?.name} : ${1}` : 1} </p>` : ''}
            ${
              toSelected?.name
                ? `<p class="text-blue-600 flex">${toSelected?.name ? `${toSelected?.name} : ${customFormatNumber(formattedRates)}` : customFormatNumber(formattedRates)} </p>`
                : ''
            }
          </div>`;
  };

  const watchOptionRates = HandleWatchingState(JSON.stringify([source, toSelected, fromSelected, paramsDate]));
  const { mutate: postArticle } = usePostExtra({
    url: 'product-management/exchange-rates/lookup',
    validate: [`product-management/exchange-rates/lookup/-${JSON.stringify([source, toSelected, fromSelected, paramsDate])}`],
    onSuccess: (res) => {
      // console.log("🚀 ~ CurrencySettings ~ res:", res)
      if (res.status == 200) {
        setNoRates(false);
        let data = res.data;
        data[0].push({ type: 'string', role: 'tooltip', p: { html: true } });

        for (let i = 1; i < data.length; i++) {
          data[i].push(createTooltip(data[i][0], data[i][1]));
        }

        setRatesChart(data);
        // setRatesChart(res.data);
        setOptions({
          // title: `/ NOK - Currency Rate`,
          series: {
            1: { curveType: 'function' },
          },
          tooltip: {
            title: 'tes',
            trigger: 'axis',
            valueFormatter: (value) => `value`,
          },
          // vAxis: { format: 'decimal' },
          pattern: '#%',
          fractionDigits: 5,
          legend: { position: 'top' },
          chartArea: {
            width: '75%',
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              axisLabel: {
                formatter: function (value, index) {
                  return value.slice(0, 5);
                },
              },
              // data: truckTurnaroundTimes.map((item) => item.hour),
            },
          ],
          width: '100%',
          yAxis: { title: 'abc', format: '#,###%', pattern: '#%', fractionDigits: 5 },
          vAxis: { 0: { title: 'abc', format: '0,0000', pattern: '#%', fractionDigits: 5 } },
          hAxis: {
            title: 'Datea',
            format: '#,###%',
            pattern: '#%',
            fractionDigits: 5,
            // ticks: generateDateTicks(
            //   moment.tz(paramsDate.date_from, 'Asia/Jakarta').toDate(), // Safe access for start date
            //   moment.tz(paramsDate.date_to, 'Asia/Jakarta').toDate(), // Safe access for start date
            //   1
            // ),
          },
          pointSize: 2,
        });
        // navigate('../article', { replace: true });
      } else {
        setNoRates(true);
        // renderError({ data: JSON.parse(e.request.response) });
      }
    },
  });

  const onPostArticle = async (data) => {
    let paramsData = {
      source: source?.id,
      currency_from: 'null',
      currency_to: 'null',
    };
    if (paramsDate?.date_from && paramsDate?.date_to) {
      paramsData = { ...paramsData, date_from: paramsDate?.date_from, date_to: paramsDate?.date_to };
    }
    if (fromSelected?.name && toSelected?.name) {
      paramsData = { ...paramsData, currency_from: fromSelected?.name, currency_to: toSelected?.name };
    }
    await postArticle(paramsData);
  };

  useEffect(() => {
    onPostArticle();
  }, [watchOptionRates]);

  const { mutate: updateSubCurrencies } = usePatchOrPut({ url: `clients/${clientId}/set_currencies`, validate: [`currencies-${clientId}`], method: 'put' });

  const onUpdateSubCurrencies = async () => {
    setPairedRatesOptionCurrent(pairedRatesOptionSubmit);
    updateSubCurrencies({
      currency_ids: pairedRatesOptionSubmit?.map((rate) => rate.id),
    });
    setFromSelected(null);
    setToSelected(null);
  };

  // const options = {
  //   curveType: 'function',
  //   legend: { position: 'bottom' },
  // };

  function handleSelectRangeDate(item) {
    let dateFrom = new Date();
    let dateTo = new Date();

    switch (item) {
      case '1m':
        dateFrom.setMonth(dateFrom.getMonth() - 1);
        break;
      case '3m':
        dateFrom.setMonth(dateFrom.getMonth() - 3);
        break;
      case '6m':
        dateFrom.setMonth(dateFrom.getMonth() - 6);
        break;
      case 'ytd':
        dateFrom = new Date(dateTo.getFullYear(), 0, 1); // Start of the year
        break;
      case '1y':
        dateFrom.setFullYear(dateFrom.getFullYear() - 1);
        break;
      case '2y':
        dateFrom.setFullYear(dateFrom.getFullYear() - 2);
        break;
      case '5y':
        dateFrom.setFullYear(dateFrom.getFullYear() - 5);
        break;
      case 'all':
        setParamsDate({
          date_from: '',
          date_to: '',
        });
        break;
      default:
        filterPeriod = '';
    }

    const formattedDateFrom = dateFrom.toISOString().split('T')[0];
    const formattedDateTo = dateTo.toISOString().split('T')[0];

    if (item !== 'all') {
      setParamsDate({
        date_from: formattedDateFrom,
        date_to: formattedDateTo,
      });
    }
    setRangeDate(item);
  }

  const optii = {
    // title: `/ NOK - Currency Rate`,

    tooltip: { isHtml: true },
    fractionDigits: 5,
    legend: { position: 'top' },
    chartArea: {
      width: '75%',
    },
    width: '100%',
    hAxis: {
      title: 'Date',
    },
    pointSize: 2,
  };

  return (
    <>
      <BZHelmet title="Currency Settings" description="" content="" />
      <div>
        <BaseMainTitle title="Currency Settings" />
        <div className="p-5 ">
          <div className="my-5 flex items-center justify-between">{/* <BaseBackButton /> */}</div>
          <div className="bg-white border p-5 shadow rounded">
            <div className="flex w-full">
              <div className="max-w-[350px] w-full space-y-[30px] z-[101] relative ">
                <div className="">Exchange Rate Source</div>
                <div className="">Subscribed Currencies</div>
              </div>
              <div className="w-[calc(100%-350px)] ">
                <div className="max-w-[200px] w-full z-[102] relative">
                  {' '}
                  <ReactSelectSingleNonRHF
                    // label="Source"
                    // isLoading={isFetching}
                    defaultValue={source}
                    options={Rates}
                    onChange={(val) => {
                      setSource(val);
                      dispatch(setCurrSource(val));
                    }}
                  />
                </div>
                <div className="max-w-[430px] w-full mt-[20px] z-[101] relative ">
                  {' '}
                  <ReactSelectMultiNonRHF
                    // id={String(!isFetching)}
                    key={String(pairedRatesOptionCurrent?.length)}
                    isClearable
                    defaultValue={pairedRatesOptionCurrent}
                    options={ratesOptionList}
                    onChange={(val) => {
                      setPairedRatesOptionSubmit(val);
                    }}
                  />
                  <BZButton onClick={onUpdateSubCurrencies} className="mt-[16px] ml-auto">
                    Update
                  </BZButton>
                </div>
                <div className="mt-[70px]">
                  <div className="flex space-x-2 w-full items-center relative z-[100]">
                    <div className="max-w-[200px] w-full">
                      {' '}
                      <ReactSelectSingleNonRHF
                        label="From"
                        isClearable
                        options={pairedRatesOptionCurrent}
                        defaultValue={fromSelected}
                        onChange={(val) => {
                          setFromSelected(val);
                        }}
                      />
                    </div>
                    <button
                      onClick={() => {
                        const fromSwitch = toSelected;
                        const toSwitch = fromSelected;
                        if (boothSelected) {
                          setFromSelected(fromSwitch);
                          setToSelected(toSwitch);
                        }
                      }}
                    >
                      <LuArrowRightLeft className={clsxm(boothSelected ? 'text-blue-400' : 'text-gray-300 cursor-not-allowed')} />
                    </button>
                    <div className="max-w-[200px] w-full">
                      {' '}
                      <ReactSelectSingleNonRHF
                        label="To"
                        isClearable
                        options={pairedRatesOptionCurrent}
                        defaultValue={toSelected}
                        onChange={(val) => {
                          setToSelected(val);
                        }}
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="flex flex-wrap w-[250px] mt-[32px] gap-x-2 text-sm mb-2 relative z-[99]">
                      {['1m', '3m', '6m', 'ytd', '1y', '2y', '5y', 'all'].map((item) => (
                        <div
                          key={item}
                          onClick={() => handleSelectRangeDate(item)}
                          className={`${item == rangeDate ? 'border-b-2 border-black' : ''} hover:cursor-pointer hover:bg-gray-100 p-[0.4px]`}
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                    {!noRates ? (
                      <div className=" border-2 -myt-[40px]">
                        <Chart chartType="LineChart" width="100%" height="400px" data={ratesChart} options={optii} />
                      </div>
                    ) : (
                      <div>No Rates Found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Colors = {
  primary: '#FF7800',
  primaryText: '#0D0D0D',
  secondaryText: 'rgba(13, 13, 13, 0.6)',
  tertiaryText: 'rgba(13, 13, 13, 0.4)',
  primaryRed: 'rgb(214, 60, 60)',
  primaryOrange: '#FF7800',
  borderPrimary: 'rgba(13, 13, 13, 0.1)',
};

export default Colors;

// /* eslint-disable */

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationArticle = yup
  .object()
  .shape({
    name: yup.string().required('Name is required'),
    status: yup.string().required('Status is required'),
    author: yup.string().required('Author is required'),
    widgets: yup.array().min(1).required('Widgets at least 1 required'),
  })
  .required();

const schemaArticle = yupResolver(validationArticle);

export default schemaArticle;

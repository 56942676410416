// /* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { resetAsset } from '../../../store/slices/assetCrudSlice';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import BaseBackButton from '../../../components/Button/BaseBackButton';
import useGet from '../../../hooks/useGet';
import usePatchOrPutExtra from '../../../hooks/usePatchOrPutExtra';
import ArticleSectionForm from '../../../components/Form/Article/ArticleSectionForm';

export default function ArticleWidgetDetail() {
  const { articleId, widgetId } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(resetAsset());
  }, [dispatch]);

  const [isArticleData, setIsArticleData] = useState(false);
  const [resIdArticle, setResIdArticle] = useState(false);

  useGet({
    url: `articles/${articleId}/?language=1`,
    validate: 'article',
    onSuccess: (e) => {
      setIsArticleData(e?.data);
    },
  });

  const { data: dataWidget, isSuccess } = useGet({
    url: `widgets/${widgetId}`,
    validate: 'widget',
  });

  const { mutate: updateArticle } = usePatchOrPutExtra({
    url: `articles/${articleId}`,
    validate: ['article'],
    onSuccess: (e) => {
      setResIdArticle(true);
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onUpdateArticle = async (data) => {
    await updateArticle(data);
  };

  return (
    <>
      <BZHelmet title={isArticleData?.name ?? ' Article'} description="" content="" />
      <div>
        <BaseMainTitle title="Article Widget Detail :" />
        <div className="p-5 ">
          <div className="bg-white border p-5 space-y-3 shadow rounded">
            <BaseBackButton />
            {isArticleData && isSuccess ? (
              <ArticleSectionForm
                defaultValues={isArticleData}
                key={isArticleData?.id}
                onFormSubmit={onUpdateArticle}
                resIdArticle={resIdArticle}
                setResIdArticle={setResIdArticle}
                widgetData={dataWidget?.data}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

// /* eslint-disable */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Pagination, Radio, FormControlLabel, RadioGroup, CircularProgress } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';
import useGet from '../../hooks/useGet';
import RenderResults from '../RenderResults';
import BaseSubmitButton from '../Button/BaseSubmitButton';
import BaseSearchButton from '../Button/BaseSearchButton';
import BaseApplyButton from '../Button/BaseApplyButton';

const containerStyle = {
  width: '100%',
  height: '200px',
};

export default function BaseLocationViewer({ attachLocation, setAttachLocation, handleClick }) {
  const [dataLocations, setDataLocations] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const [isDataLoad, setIsDataLoad] = useState(true);
  const [countLocation, setCountLocation] = useState(0);
  const pageSize = 15;
  const count = Math.ceil(countLocation / pageSize);

  const clientId = useSelector((state) => state.client.activeClient);

  const urlLocations = `locations/?client=${clientId}&page_size=${pageSize}&page=${page}&search=${search}`;

  const handleChange = (e, p) => {
    setIsDataLoad(false);
    setTimeout(() => {
      setIsDataLoad(true);
    }, 500);
    setPage(p);
  };

  const { isFetching } = useGet({
    url: urlLocations,
    validate: 'assets',
    onSuccess: (e) => {
      setDataLocations(e?.data?.results);
      setCountLocation(e.data.count);
      // dispatch(resetLocation());
    },
    isEnabled: isDataLoad,
  });

  const onSearchLocation = (event) => {
    setSearch(event.target.value);
  };

  const handleChangeLocation = (event) => {
    setAttachLocation(JSON.parse(event.target.value));
  };

  return (
    <div className="p-5">
      <div className="">
        <div className="bg-gray-100 w-full flex py-5 px-3 my-5 rounded items-center">
          <div className="my-3 flex gap-x-3 items-center w-fit">
            <BaseSearchButton onChange={onSearchLocation} setPage={setPage} setIsDataLoad={setIsDataLoad} />
            <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} />
          </div>
        </div>
      </div>

      <div className="">
        {!isFetching ? (
          <RenderResults
            condition={count > 0}
            component={
              <div className="pt-5">
                <div className="grid grid-cols-3 gap-3 w-full">
                  {dataLocations.map((data) => (
                    <div key={data.id} className="w-full p-3 border rounded">
                      <div className="w-full">
                        <div className="bg-blue-400 w-full py-1 px-3 relative">
                          <RadioGroup
                            row
                            className="text-white"
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={JSON.stringify(attachLocation)}
                            onChange={handleChangeLocation}
                          >
                            <FormControlLabel value={JSON.stringify(data)} control={<Radio className="text-white" />} label={data?.name} />
                          </RadioGroup>
                          {/* <p className="text-white text-lg font-semibold">{data?.name}</p> */}
                        </div>
                      </div>
                      <div className="w-full">
                        <GoogleMap mapContainerStyle={containerStyle} center={{ lat: parseFloat(data?.latitude), lng: parseFloat(data?.longitude) }} zoom={10}>
                          {/* Child components, such as markers, info windows, etc. */}
                          <Marker position={{ lat: parseFloat(data?.latitude), lng: parseFloat(data?.longitude) }} />
                        </GoogleMap>
                      </div>
                    </div>
                  ))}
                </div>
                <div className=" flex items-center gap-x-5 mt-5">
                  <Pagination count={count} size="large" page={page} variant="outlined" shape="rounded" onChange={handleChange} />
                  <div className="">{`Total ${countLocation}`}</div>
                  <div className="ml-auto">{handleClick && <BaseSubmitButton text="pair locations" onClick={handleClick} disabled={false} />}</div>
                </div>
              </div>
            }
          />
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  );
}

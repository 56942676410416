import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const languageSlice = createSlice({
  name: 'lang',
  initialState: {
    languagesList: false,
    activeLanguage: Cookies.get('bzLang'),
  },
  reducers: {
    setLanguageList(state, action) {
      const currState = state;
      currState.languagesList = action.payload;
    },
    changeActiveLanguage(state, action) {
      console.log(state, action.payload.template);
    },
  },
});

export const langActions = languageSlice.actions;
export default languageSlice;

/* eslint-disable */

import { DevTool } from '@hookform/devtools';
import { CircularProgress, Radio, Switch } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BaseMainTitle from '../../components/BaseMainTitle';
import MuiSelectSingleItem from '../../components/Input/MuiSelectSingleItem';
import { toastError, toastLoading, toastSuccess } from '../../constants/ToastConfig';
import HandleWatchingState from '../../helper/HandleWatchingState';
import useGet from '../../hooks/useGet';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import usePatchOrPutExtra from '../../hooks/usePatchOrPutExtra';
import { getSelectOptionWithKey } from '../../lib/OptionsInputHelper';
import store from '../../store';
import { getActiveClientDetail } from '../../store/slices/clientSlice';
import BZHelmet from '../../utils/BZHelmet';

function changeActiveById(id, array) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].language.id === id) {
      array[i].is_default = !array[i].is_default;
    } else array[i].is_default = false;
  }
  return array;
}

export default function Language() {
  const dispatch = useDispatch();
  const { control, watch } = useForm();
  const CLIENT_ID = useSelector((state) => state.client.activeClient);
  const SITE_ID = useSelector((state) => state.content.activeSite);

  const [admLangGroup, setAdmLangGroup] = useState([]);

  const NEWSITE = HandleWatchingState(watch('site') || SITE_ID);

  const { data: languages, isSuccess: langLoaded } = useGet({
    url: `languages`,
    validate: 'languagesall',
  });
  // console.log('🚀 ~ file: Language.jsx:44 ~ Language ~ languages:', JSON.stringify(languages.data.results));

  const {
    data: site,
    isSuccess: siteLoaded,
    isFetching: isFetchingSite,
  } = useGet({
    url: `sites/${watch('site') || SITE_ID}`,
    validate: 'site',
    isEnabled: Boolean(NEWSITE),
  });

  const {
    data: client,
    isSuccess: clientLoaded,
    isFetching,
  } = useGet({
    url: `clients/${CLIENT_ID}`,
    validate: 'client',
    onSuccess: (e) => {
      setAdmLangGroup(e?.data?.languages.map((lang) => lang?.id));
    },
    isEnabled: Boolean(NEWSITE),
  });

  const { mutate: pairWebLang } = usePatchOrPut({ url: `sites/${SITE_ID}/pair_languages`, validate: ['site'] });

  const onPairWebLang = async (data) => {
    await pairWebLang(data);
  };

  const { mutate: unPairWebLang } = usePatchOrPut({ url: `sites/${SITE_ID}/unpair_languages`, validate: ['site'] });

  const onUnPairWebLang = async (data) => {
    await unPairWebLang(data);
  };
  let onToastAdmin;
  const { mutate: unPairWebAdmin } = usePatchOrPutExtra({
    url: `clients/${CLIENT_ID}`,
    validate: ['site', 'client'],
    onMutate: () => {
      onToastAdmin = toast.loading('Loading  ', toastLoading);
    },
    onSuccess: (e) => {
      if (e.request.status === 200) {
        console.log('🚀 ~ file: Language.jsx:106 ~ Language ~ e', e);
        setTimeout(() => {
          toast.update(onToastAdmin, {
            render: 'Success',
            ...toastSuccess,
          });
        }, 500);
        dispatch(getActiveClientDetail(CLIENT_ID));
      } else {
        setTimeout(() => {
          toast.update(onToastAdmin, {
            render: 'Error',
            ...toastError,
          });
        }, 500);
      }
    },
  });

  const { mutate: setIsDefault } = usePatchOrPut({ url: `sites/${SITE_ID}`, validate: ['site'] });

  const onUnPairWebAdmin = async (data) => {
    await unPairWebAdmin(data);
  };

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      onPairWebLang({ languages: [value] });
    } else {
      onUnPairWebLang({ languages: [value] });
    }
  };

  const handleChangeAdmLang = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      const newSelection = Array.from(admLangGroup);
      newSelection.push(parseInt(value, 10));
      setAdmLangGroup(newSelection);
      onUnPairWebAdmin({ languages: newSelection });
    } else {
      const newSelection = admLangGroup;
      const newLang = newSelection.filter((sub) => parseInt(sub, 10) !== parseInt(value, 10));

      setAdmLangGroup(newLang);
      onUnPairWebAdmin({ languages: newLang });
    }
  };

  const siteData = site?.data?.site_languages;

  const getDefaultLang = siteData?.find((siteL) => siteL.is_default === true);

  function findValueInArray(value, arr) {
    return siteData.some((obj) => obj.language.id === value);
  }

  const handleRadio = (id) => {
    const patchIsDefault = changeActiveById(id, siteData);

    setIsDefault({
      site_languages: patchIsDefault.map((site) => ({
        id: site.id,
        is_default: site.is_default,
      })),
    });
  };

  return (
    <>
      <BZHelmet title="Languages" description="bz publish languages" />
      <div>
        <BaseMainTitle title="Languages" />
        <div className="p-5">
          <div className="w-6/12 pr-2.5">
            <MuiSelectSingleItem
              name="site"
              label="Select Website "
              control={control}
              options={getSelectOptionWithKey(client?.data?.sites)}
              extraLabel="website"
              defaultValue={SITE_ID || watch('site')}
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <div className="w-full">
              <table className="table w-full">
                <thead>
                  <tr className="">
                    <th className="text-left py-4">Website Languages</th>
                    <th className="py-4">Set Active Language</th>
                    <th className="py-4"> Is Default</th>
                  </tr>
                </thead>
                <tbody className="">
                  {langLoaded && siteLoaded && !isFetching && !isFetchingSite ? (
                    languages?.data?.results.map((lang) => (
                      <tr key={lang.id} className="border">
                        <th align="left" className="p-3">
                          {lang.name}
                        </th>
                        <td className="flex items-center justify-center p-3">
                          <span className=" text-sm font-medium text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                          <label className="relative inline-flex items-center  cursor-pointer">
                            <label className="relative inline-flex items-center  cursor-pointer">
                              <Switch
                                value={lang.id}
                                onChange={handleChange}
                                className='bz-switch'
                                type="checkbox"
                                defaultChecked={Boolean(site?.data?.site_languages?.find((siteL) => siteL.language.id === lang.id))}
                              />
                            </label>
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Active</span>
                          </label>
                        </td>
                        <td align="center" className="">
                          <Radio
                            onChange={() => handleRadio(lang.id)}
                            defaultChecked={Boolean(getDefaultLang?.language?.id === lang.id)}
                            name="radio-buttons"
                            className='bz-radio'
                            inputProps={{ 'aria-label': 'A' }}
                            disabled={!findValueInArray(lang.id)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <CircularProgress />
                  )}
                </tbody>
              </table>
            </div>
            <div className="w-full">
              <table className="table w-full">
                <thead>
                  <tr className="">
                    <th className="text-left py-4">Admin Languages</th>
                    <th className="py-4">Set Active Language</th>
                  </tr>
                </thead>
                <tbody className="">
                  {langLoaded &&
                    clientLoaded &&
                    languages?.data?.results.map((lang) => (
                      <tr key={lang.id} className="border">
                        <th align="left" className="p-3">
                          {lang.name}
                        </th>
                        <td className="flex items-center justify-center p-3">
                          <span className=" text-sm font-medium text-gray-900 dark:text-gray-300 mr-3">Inactive</span>
                          <label className="relative inline-flex items-center  cursor-pointer">
                            <label className="relative inline-flex items-center  cursor-pointer">
                              <Switch
                                value={lang.id}
                                onChange={handleChangeAdmLang}
                                className='bz-switch'
                                type="checkbox"
                                defaultChecked={Boolean(client?.data?.languages?.find((clientL) => clientL.id === lang.id))}
                              />
                            </label>
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Active</span>
                          </label>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <DevTool control={control} />
    </>
  );
}

/* eslint-disable */
import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

export default function CustomRadioGroup({ options = [], defaultValue = null, onChange, label, className, errors, name, disableClass }) {
  return (
    <FormControl className={disableClass}>
      <FormLabel id="demo-radio-buttons-group-label" className="font-bold text-black">
        {label}
      </FormLabel>
      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name={name} onChange={onChange} defaultValue={defaultValue}>
        {options.map((opt) => (
          <FormControlLabel key={opt?.value} value={opt?.value} control={<Radio className={className} />} label={opt?.label} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import NotificationContext from './NotificationContext';
import notificationApi from '../../../../../api/task/notification';

const tabTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '& .MuiList-root': {
              height: 196,
              overflowY: 'auto',
            },
          }),
      },
    },
  },
});

function BoxContext({ title, children }) {
  return (
    <div className="flex flex-row w-[678px] m-auto justify-start">
      <span className="w-[204px] mr-4">{title}</span>
      {children}
    </div>
  );
}

const daysM = [...Array(30)].map((_, i) => i + 1);

export default function UserNotification() {
  const activeUser = useSelector((state) => state.auth.user);
  const myContext = useContext(NotificationContext);
  const { userSubs, notifSubs } = myContext;
  const [notificationSubs, setNotificationSubs] = useState(null);
  const [selectedSubs, setSelectedSubs] = useState(null);
  const [configuration, setConfiguration] = useState({
    interval: {
      day: 0,
      time: new Date(),
    },
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeConfiguration = (param, value) => {
    if (Array.isArray(param)) {
      setConfiguration((prevState) => {
        return {
          ...prevState,
          [param[0]]: {
            ...prevState[param[0]],
            [param[1]]: value,
          },
        };
      });
    } else {
      setConfiguration((prevState) => {
        return {
          ...prevState,
          [param]: value,
        };
      });
    }
  };

  useEffect(() => {
    if (!notificationSubs && userSubs && userSubs !== notificationSubs) {
      setNotificationSubs(userSubs);
    }
  }, [userSubs, notificationSubs]);
  useEffect(() => {
    if (notificationSubs && userSubs && notificationSubs.length !== userSubs.length) {
      setNotificationSubs(userSubs);
    }
  }, [userSubs, notificationSubs]);

  const onAddNotification = () => {
    const { postUserNotificationSubscription } = notificationApi();
    const { interval } = configuration;
    const { day, time } = interval;
    let selectedDay = day;
    if (day.length === 1) {
      selectedDay = `0${day}`;
    }
    postUserNotificationSubscription({
      user: activeUser.id,
      subscription: selectedSubs,
      interval: `${selectedDay} ${format(time, 'HH:mm:ss')}`,
    })
      .then((res) => {
        if (res.status === 201 || res.data) {
          myContext.getUserSubs('set');
          setSelectedSubs(null);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const columns = [
    {
      field: 'subscription.trigger',
      headerName: 'Task Type',
      headerAlign: 'center',
      align: 'center',
      minWidth: 256,
      renderCell: (params) => params.row.subscription.trigger.name,
    },
    {
      field: 'subscription.configuration',
      headerName: 'Configuration',
      headerAlign: 'center',
      align: 'center',
      minWidth: 256,
      renderCell: (params) => {
        const config = params.row.subscription.configuration;
        if (!config) {
          return '-';
        }
        const arrItems = [];
        Object.keys(config).forEach((key) => {
          arrItems.push(
            <span className="text-xs">
              <b>{key}:</b> {config[key].toString()}
            </span>
          );
        });

        return <div className="flex flex-col">{arrItems}</div>;
      },
    },
    {
      field: 'interval',
      headerName: 'Interval',
      headerAlign: 'center',
      align: 'center',
      minWidth: 256,
      renderCell: (params) => params.row.interval,
    },
  ];

  return (
    <ThemeProvider theme={tabTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="relative w-full h-full">
          <div className="m-auto max-w-[906px] w-[100%] flex flex-col space-y-4 border border-dashed px-4 py-4 justify-center">
            <BoxContext title="Notification trigger">
              <Select sx={{ width: 256, px: 0.2, py: 0.2 }} value={selectedSubs || ''} onChange={(e) => setSelectedSubs(e.target.value)} displayEmpty>
                <MenuItem value="">-</MenuItem>
                {notifSubs &&
                  notifSubs.map((nt) => (
                    <MenuItem key={nt.name} value={nt.id}>
                      {nt.trigger.name}
                    </MenuItem>
                  ))}
              </Select>
            </BoxContext>
            <BoxContext title="Interval">
              <div className="flex items-center space-x-2">
                <FormControl size="small">
                  <InputLabel id="select-day" sx={{ backgroundColor: 'white' }} InputLabelProps={{ shrink: true }}>
                    day
                  </InputLabel>
                  <Select
                    labelId="select-day"
                    value={configuration.interval.day}
                    onChange={(e) => handleChangeConfiguration(['interval', 'day'], e.target.value)}
                    sx={{
                      width: 64,
                      height: 40,
                    }}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    {daysM.map((_, i) => (
                      <MenuItem key={`${i + 1}`} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TimePicker
                  ampm={false}
                  openTo="hours"
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  label="time"
                  value={configuration.interval.time}
                  onChange={(newValue) => {
                    handleChangeConfiguration(['interval', 'time'], newValue);
                  }}
                  orientation="landscape"
                  renderInput={(params) => <TextField size="small" {...params} InputLabelProps={{ shrink: true }} />}
                />
              </div>
            </BoxContext>
            <button
              disabled={!selectedSubs}
              type="button"
              className={`flex ml-auto rounded-xl ${selectedSubs ? 'bg-[#2C6D47]' : 'bg-[#2c6d474d]'} text-white px-5 py-2`}
              onClick={onAddNotification}
            >
              Add
            </button>
          </div>
          {notificationSubs && (
            <Box className="w-full h-full my-4">
              <DataGrid
                autoHeight
                rows={notificationSubs || []}
                columns={columns}
                pageSize={rowsPerPage}
                onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
                className="m-auto max-w-[906px] w-[100%]"
              />
            </Box>
          )}
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

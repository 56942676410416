// /* eslint-disable */

import React, { useState } from 'react';
import { useParams } from 'react-router';
import { CircularProgress } from '@mui/material';
import BaseMainTitle from '../../components/BaseMainTitle';
import BZHelmet from '../../utils/BZHelmet';
import useGet from '../../hooks/useGet';
import ApplicantForm from '../../components/Form/Job/ApplicantForm';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import BaseBackButton from '../../components/Button/BaseBackButton';

export default function ApplicantEdit() {
  const [isApplicantData, setIsApplicantData] = useState(false);

  const { applicantId } = useParams();

  useGet({
    url: `applicants/${applicantId}/?language=1`,
    validate: 'applicant',
    onSuccess: (e) => {
      setIsApplicantData(e?.data);
    },
  });

  const { mutate: updateApplicant } = usePatchOrPut({ url: `applicants/${applicantId}`, method: 'put', validate: ['applicant'] });

  const onUpdateApplicant = async (data) => {
    await updateApplicant(data);
  };

  return (
    <>
      <BZHelmet title={isApplicantData?.name ?? ' Applicant'} description="" content="" />
      <div>
        <BaseMainTitle title="Applicant Detail :" />
        <div className="p-5 ">
          <div className="bg-white border p-5 space-y-3 shadow rounded">
            <BaseBackButton />
            {isApplicantData ? (
              <ApplicantForm defaultValues={isApplicantData} key={isApplicantData?.id} onFormSubmit={onUpdateApplicant} cancel="/hr/recruitment/applicants" />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

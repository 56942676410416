import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, Select, MenuItem, CircularProgress, InputAdornment, OutlinedInput } from '@mui/material';

export default function MuiSelectSingleItem({
  name,
  control,
  options,
  label,
  OptionLabel,
  onFocus,
  multiple,
  initialDisabled = false,
  defaultValue,
  onChangeValue,
  extraLabel,
  disabled = false,
  setValue,
  watch,
  resetFunction,
  unique_id,
  isCancel,
  setIsCancel,
  errors,
  isLoading = false,
}) {
  useEffect(() => {
    if (isCancel) {
      resetFunction();
      setIsCancel(false);
    }
  }, [isCancel, resetFunction, setIsCancel]);
  
  return (
    <FormControl fullWidth>
      {extraLabel && <div className="pb-2 font-bold capitalize">{extraLabel}</div>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { value, onChange, ref } }) => (
          <FormControl fullWidth>
            <Select
              className="w-full h-full"
              onFocus={onFocus}
              onChange={(event) => {
                onChange(event.target.value);
                onChangeValue && onChangeValue(event.target.value, unique_id);
              }}
              value={value !== undefined ? value : defaultValue}
              displayEmpty
              ref={ref}
              multiple={multiple}
              inputProps={{ 'aria-label': 'Without label' }}
              disabled={disabled}
              endAdornment={
                isLoading && (
                  <InputAdornment position="end" className='pr-[2.50rem]'>
                    <CircularProgress size={20} />
                  </InputAdornment>
                )
              }
              input={
                <OutlinedInput
                  sx={{
                    minWidth: '100%',
                    width: '100%',
                    px: 0.2,
                    py: 0.6,
                    background: 'white',
                  }}
                />
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 'calc(100vh - 500px)',
                    overflowY: 'auto',
                  },
                },
              }}
            >
              {!initialDisabled && (
                <MenuItem disabled={multiple} key="" value="">
                  <em className={errors ? 'text-red-600  ' : 'text-[#A2A2A2]'}>
                    {errors || label}
                  </em>
                </MenuItem>
              )}
              {options.map((opt) => (
                <MenuItem key={opt.value || opt.id} value={opt.value || opt.id}>
                  {opt.name || opt.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </FormControl>
  );
}

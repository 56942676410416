import { useSelector } from 'react-redux';
import axiosInstances, { axInstance } from '../helper/axiosInstances';
import useTokenBearer from '@/hooks/useTokenBearer';

const baseURL = import.meta.env.VITE_API_URL;

export default function client() {
  const { axiosInstance } = axiosInstances();

  async function getAllClient(param) {
    const method = 'get';
    const url = `${baseURL}clients/?max_size=true&ordering=name`;
    let headers;
    if (param?.key) {
      headers = {
        Authorization: `${useTokenBearer()} ${param.key}`,
        'Content-Type': 'application/json',
      };
    }
    const instance = await axInstance({
      method,
      url,
      headers,
    });
    const response = await instance.get(url);
    return response;
  }
  async function getClientDetail(clientId) {
    const method = 'get';
    const url = `${baseURL}clients/${clientId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }
  async function getSiteDetail(siteId) {
    const method = 'get';
    const url = `${baseURL}sites/${siteId}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  async function updateClient(id, data = {}) {
    const method = 'put';
    const url = `${baseURL}clients/${id}/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function createClient(data = {}) {
    const method = 'post';
    const url = `${baseURL}clients/`;
    const response = await axiosInstance({
      method,
      url,
      data,
    });
    return response;
  }

  async function deleteClient(id) {
    const method = 'delete';
    const url = `${baseURL}clients/${id}/`;
    const response = await axiosInstance({
      method,
      url,
    });
    return response;
  }

  return { getAllClient, getClientDetail, getSiteDetail, updateClient, createClient, deleteClient };
}

import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Autocomplete, Box, Button, Divider, Fade, FormControl, FormHelperText, Modal, Stack, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';

import TransactionContext from '../../../pages/Accounting/Transaction/TransactionContext';
import Colors from '../../../constants/Colors';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid transparent',
  boxShadow: 24,
  p: 4,
  outline: 'none',
};

const sxTextField = (err) => {
  return {
    '& > div': {
      padding: '0px 6px 0px 6px !important',
      outline: 'none',
    },
    '& > .MuiInputLabel-root': { top: '-8px' },
    '& > .Mui-focused': { top: 0 },
    '& > .MuiFormLabel-filled': { top: 0 },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: err && Colors.primaryRed,
      borderWidth: err && 1,
    },
  };
};

const initialState = {
  account: '',
  center: '',
  counterpart: '',
  recref: '',
  accper: '',
  currency: '',
  rate: '',
};

const slugRegex = /^$|^[a-zA-Z0-9\s]+$/;
// const slugRegex = /^$|^[a-zA-Z0-9\-\s]+$/;
const schema = yup.object().shape({
  account: yup.object().required('Account is a required field').nullable(),
  center: yup.object().required('Cost Center is a required field').nullable(),
  counterpart: yup.object().nullable(),
  //   .matches(/[^-]+$/, 'Wrongggg')
  recref: yup
    .string()
    // .matches(/^$|^[a-zA-Z0-9]+$/, 'Special character not allowed')
    .matches(slugRegex, 'Special character not allowed')
    .nullable(),

  accper: yup.object().required('Accper is a required field').nullable(),
  currency: yup.object().required('Ledger Currency is a required field').nullable(),
  rate: yup.string().required('Base Currency Rate is a required field'),
});

export default function LineTransaction(props) {
  const transContext = useContext(TransactionContext);
  const { open, close, data, transaction, action } = props;
  const baseCurr = useSelector((state) => state.acc.activeBusinessUnitDetail?.base_currency?.id);
  const ledgerCurr = useSelector((state) => state.acc.activeBusinessUnitDetail?.ledger_currencies || []);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialState,
    reValidateMode: 'onChange',
  });

  const onCloseHandler = () => {
    // reset(initialState);
    close();
    reset({
      account: '',
      center: '',
      counterpart: '',
      recref: '',
      accper: '',
      currency: '',
      rate: '',
    });
  };

  const onSubmit = (line) => {
    const { account, center, counterpart, project_id, recref, accper, currency, rate } = line;
    const ap = accper.accounting_period ? accper.accounting_period : accper
    const newAccPer =
      accper &&
      `${String(ap.year).substring(2)}${
        String(ap.month).length === 1 ? `0${ap.month}` : ap.month
      }`;
    const newLineTransaction = `${account.code || '0'}-${center.code || '0'}-${counterpart?.code || '0'}-${project_id?.code || '0'}-${recref || '0'}-${newAccPer || '0'}-${currency.code || currency.name}`;
    const dataFiltered = transaction.filter((trans) => trans.id === data);
    const lnDetail = {
      account,
      center,
      counterpart,
      recref,
      accper,
      currency,
    };
    const dataParam = { line: newLineTransaction, id: data, data: lnDetail, rate, status: true }

    if (dataFiltered.length) {
      action(dataParam, 'update');
    } else {
      action(dataParam, 'update');
    }
    onCloseHandler();
  };

  const isExist = useCallback(
    (splitData, state) => {
      if (splitData) {
        setValue(state, splitData);
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (open && transaction.length && data) {
      const dataFiltered = transaction.find((trans) => trans.id === data);
      if (dataFiltered && dataFiltered.line && dataFiltered.data) {
        const splittedData = dataFiltered.line.split('-');
        const states = ['account', 'center', 'counterpart', 'project_id', 'recref', 'accper', 'currency'];
        const dtF = dataFiltered.data;

        for (let index = 0; index < splittedData.length; index += 1) {
          let defaultData;
          if (index === 0) {
            defaultData = dtF.account;
          }
          if (index === 1) {
            defaultData = dtF.center;
          }
          if (index === 2) {
            defaultData = dtF.counterpart;
          }
          if (index === 3) {
            defaultData = dataFiltered.project_id;
          }
          if (index === 4) {
            defaultData = String(dtF.recref);
          }
          if (index === 5) {
            defaultData = dtF.accper;
          }
          if (index === 6) {
            defaultData = dtF.currency;
          }

          if (defaultData) {
            isExist(defaultData, states[index]);
          }
        }
        isExist(dataFiltered.rate, 'rate');
      }
    }
  }, [open, transaction, data, transContext, isExist]);

  useEffect(() => {
    isExist(1, 'rate');
  }, [isExist]);

  const onShown = () => {
    setTimeout(() => {
      const currCurr = getValues('currency');
      if (!currCurr && ledgerCurr.length && baseCurr) {
        const defaultCurr = ledgerCurr.find((c) => c.id === baseCurr) || '';
        setValue('currency', defaultCurr);
        setValue('rate', 1);
      }
    }, 300);
  };

  return (
    <>
      <Modal
        open={!!open}
        onClose={onCloseHandler}
        onTransitionEnter={onShown}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={!!open}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Line Transaction
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
              <div className="flex flex-col space-y-4 mt-4">
                <FormControl>
                  <Controller
                    control={control}
                    name="account"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Autocomplete
                          name="account"
                          options={transContext.accounts}
                          autoHighlight
                          getOptionLabel={(option) => option && `${option.code} - ${option.name}`}
                          isOptionEqualToValue={(option, newValue) => option?.id === newValue?.id}
                          onChange={(event, dataOption) => {
                            onChange(dataOption);
                          }}
                          value={value || ''}
                          error={errors?.account?.message || ''}
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Account Code"
                              sx={() => sxTextField(!!errors?.account)}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-red-600">{errors.account?.message}</FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Controller
                    control={control}
                    name="center"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Autocomplete
                          name="center"
                          options={transContext.costCenters}
                          autoHighlight
                          getOptionLabel={(option) => option && `${option.code} - ${option.name}`}
                          isOptionEqualToValue={(option, newValue) => option?.id === newValue?.id}
                          onChange={(event, dataOption) => {
                            onChange(dataOption);
                          }}
                          value={value || ''}
                          error={errors?.center?.message || ''}
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Center"
                              sx={() => sxTextField(!!errors?.center)}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-red-600">{errors.center?.message}</FormHelperText>
                      </>
                    )}
                  />
                </FormControl>

                <FormControl>
                  <Controller
                    control={control}
                    name="counterpart"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Autocomplete
                          name="counterpart"
                          options={transContext.counterparts}
                          autoHighlight
                          getOptionLabel={(option) => option && `${option.code} - ${option.name}`}
                          isOptionEqualToValue={(option, newValue) => option?.id === newValue?.id}
                          onChange={(event, dataOption) => {
                            onChange(dataOption);
                          }}
                          value={value || ''}
                          error={errors?.counterpart?.message || ''}
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Counterpart"
                              sx={() => sxTextField(!!errors?.counterpart)}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-red-600">{errors.counterpart?.message}</FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Controller
                    control={control}
                    name="project_id"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Autocomplete
                          name="project_id"
                          options={transContext.projectIds}
                          autoHighlight
                          getOptionLabel={(option) => option && `${option.code} - ${option.name}`}
                          isOptionEqualToValue={(option, newValue) => option?.id === newValue?.id}
                          onChange={(event, dataOption) => {
                            onChange(dataOption);
                          }}
                          value={value || ''}
                          error={errors?.project_id?.message || ''}
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Project Id"
                              sx={() => sxTextField(!!errors?.project_id)}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-red-600">{errors.project_id?.message}</FormHelperText>
                      </>
                    )}
                  />
                </FormControl>

                <FormControl>
                  <Controller
                    control={control}
                    name="recref"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        inputRef={ref}
                        label="Recref"
                        size="small"
                        error={!!errors.recref?.message}
                        helperText={errors.recref?.message}
                        placeholder=""
                      />
                    )}
                  />
                </FormControl>

                <FormControl>
                  <Controller
                    control={control}
                    name="accper"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Autocomplete
                          name="accper"
                          options={transContext.accPeriods}
                          autoHighlight
                          getOptionLabel={(option) => {
                            const opt = option.accounting_period;
                            const optMonth = opt ? opt.month : option.month
                            return (
                              option &&
                              `${opt? opt.year : option.year} - ${
                                optMonth?.toString().length !== 1 ? optMonth : `0${optMonth}`
                              }`
                            );
                          }}
                          isOptionEqualToValue={(option, newValue) => option?.accounting_period?.id === newValue?.accounting_period?.id}
                          onChange={(event, dataOption) => {
                            onChange(dataOption);
                          }}
                          value={value || ''}
                          error={errors?.accper?.message || ''}
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Accper"
                              sx={() => sxTextField(!!errors?.accper)}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-red-600">{errors.accper?.message}</FormHelperText>
                      </>
                    )}
                  />
                </FormControl>

                <FormControl>
                  <Controller
                    control={control}
                    name="currency"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Autocomplete
                          name="currency"
                          options={ledgerCurr}
                          autoHighlight
                          getOptionLabel={(option) => option && `${option.code || option.name}`}
                          isOptionEqualToValue={(option, newValue) => option?.id === newValue?.id}
                          onChange={(event, dataOption) => {
                            onChange(dataOption);
                            setValue('rate', dataOption.id === baseCurr ? 1 : '');
                          }}
                          value={value || ''}
                          error={errors?.currency?.message || ''}
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Ledger Currency"
                              sx={() => sxTextField(!!errors?.currency)}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-red-600">{errors.currency?.message}</FormHelperText>
                      </>
                    )}
                  />
                </FormControl>

                <FormControl>
                  <Controller
                    control={control}
                    name="rate"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        inputRef={ref}
                        label="Rate"
                        size="small"
                        error={!!errors.rate?.message}
                        helperText={errors.rate?.message}
                        placeholder=""
                      />
                    )}
                  />
                </FormControl>
              </div>
              <Divider className="mt-4" />
              <Stack direction="row" spacing={2} className="mt-5 float-right">
                <Button variant="outlined" onClick={onCloseHandler} className="bz-btn secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" className="bz-btn">
                  Save
                </Button>
              </Stack>
            </form>
          </Box>
        </Fade>
      </Modal>
      <DevTool control={control} />
    </>
  );
}

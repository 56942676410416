import useTokenBearer from '@/hooks/useTokenBearer';
import axiosInstances, { axInstance } from '../helper/axiosInstances';
const { axiosInstance } = axiosInstances();
const baseURL = import.meta.env.VITE_API_URL;

// function language() {
//   async function getAllLanguage(param) {
//     const method = 'get';
//     const url = `${baseURL}languages/`;
//     let headers;
//     if (param?.key) {
//       headers = {
//         Authorization: `${useTokenBearer()} ${param.key}`,
//         'Content-Type': 'application/json',
//       };
//     }
//     const instance = await axInstance({
//       method,
//       url,
//       headers,
//     });
//     const response = await instance.get(url);
//     return response;
//   }

//   return { getAllLanguage };
// }

// export default language;
async function getAllLanguage() {
  const method = 'get'
  const url = `${baseURL}languages/`

  const response = await axiosInstance({
    method,
    url,
  })



  return response;
}
const language = {
  getAllLanguage
}
export default language

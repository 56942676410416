/* eslint-disable */

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, FormLabel, FormControl } from '@mui/material';
import Radio from '@mui/material/Radio';
import { DevTool } from '@hookform/devtools';
import Cookies from 'js-cookie';
import RadioGroup from '@mui/material/RadioGroup';
import InputContainer from '../../Input/InputContainer';
import SelectAutocomplete from '../../Input/SelectAutocomplete';
import { handleModal } from '../../../store/slices/modalSlice';
import { generateSelectDefaultWithKey, getSelectOptionWithKey, getOnChangeSelect, getOptionSelectWithKey, getDefaultSelectObject } from '../../../lib/OptionsInputHelper';
import useGet from '../../../hooks/useGet';
import HandleWatchingState from '../../../helper/HandleWatchingState';
import ReactSelectSingle from '../../Input/ReactSelectSingle';

export default function WidgetForm({ defaultValues, onFormSubmit, developerMode, isWidget, limit = false }) {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  const dispatch = useDispatch();
  const template = useSelector((state) => state.content.activeTemplate);
  const clientLanguage = useSelector((state) => state.content.activeSiteDetail.site_languages);

  const clientId = useSelector((state) => state.client.activeClient);

  const [valueWidget, setValueWidget] = React.useState(defaultValues?.is_internal ?? false);
  const [valueUrl, setValueUrl] = useState(true);
  const [pageOn, setPageOn] = useState(null);
  const page = pageOn ?? defaultValues?.urls[0]?.page;

  const pageId = watch('page');
  const sectionId = watch('section');

  const statusPage = HandleWatchingState(pageId);

  const { data: widgetTypes } = useGet({
    url: `category_widgets`,
    validate: 'categoryWidgets',
  });

  const templateId = Cookies.get('bzaTemplate');

  const { data: listPage } = useGet({ url: `pages/?template=${templateId}&language=1&max_size=true`, validate: 'AllPages' });

  const { data: listSection } = useGet({
    url: `pages/${pageId}`,
    validate: 'sections',
    isEnabled: statusPage,
  });

  const handleChangeWidget = (event) => {
    setValueWidget(event.target.value);
  };

  const handleChangeUrl = (event) => {
    if (event.target.value === 'true') {
      setValueUrl(true);
    } else setValueUrl(false);
  };

  const devLang = [];

  defaultValues?.urls?.map((l) => {
    devLang.push(l);
    return devLang;
  });
  const handleCancel = () => {
    dispatch(handleModal({ id: '' }));
  };

  const onChangePage = (newValue) => {
    const inputPage = newValue;
    getOnChangeSelect(newValue);
    if (inputPage) {
      setPageOn(inputPage?.value);
    }
  };

  const onSubmit = (data) => {
    // const urls
    const urls = clientLanguage.reduce((acc, curr) => {
      const inputLang = devLang.find((dl, index) => dl.language === curr.id);
      const output = {};
      output.language = curr.language.id;
      output.page = page ?? defaultValues?.urls[0]?.page ?? null;
      output.section = data.section ?? defaultValues?.urls[0]?.section ?? null;
      output.path = data[`path-${curr.id}`] || inputLang?.path || null;
      if (defaultValues) {
        output.id = inputLang?.id;
      }

      return [...acc, output];
    }, []);
    onFormSubmit({ name: data.name, template, category: data.category ?? defaultValues?.category, client: clientId, is_internal: valueWidget, urls });
    reset();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          <InputContainer
            name="name"
            control={control}
            label="Name"
            // errors={errors.name}
          />

          <div className="">
            <Controller
              name="category"
              control={control}
              render={({ field: { onChange } }) => (
                <SelectAutocomplete
                  options={getSelectOptionWithKey(widgetTypes?.data?.results)}
                  disabled={limit}
                  placeHolder="Select Category"
                  variant="outlined"
                  defaultValue={generateSelectDefaultWithKey(defaultValues?.category, getSelectOptionWithKey(widgetTypes?.data?.results))}
                  onChange={(e, newValue) => onChange(getOnChangeSelect(newValue))}
                />
              )}
            />
          </div>

          <div className="">
            <div className="mt-5">
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Widget Type</FormLabel>
                <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={valueWidget} onChange={handleChangeWidget}>
                  <FormControlLabel value control={<Radio />} label="Internal" disabled={limit} />
                  <FormControlLabel value={false} control={<Radio />} label="External" disabled={limit} />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="mt-5">
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Url Type</FormLabel>
                <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={valueUrl} onChange={handleChangeUrl}>
                  <FormControlLabel value control={<Radio />} label="Internal" />
                  <FormControlLabel value={false} control={<Radio />} label="External" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              {valueUrl === true && (
                <div className="space-y-7 mt-7">
                  <ReactSelectSingle
                    name="page"
                    control={control}
                    options={getOptionSelectWithKey(listPage?.data?.results)}
                    defaultValue={getDefaultSelectObject(pageId, getOptionSelectWithKey(listPage?.data?.results))}
                    placeholder="Select Page"
                    isClearable
                  />

                  <ReactSelectSingle
                    name="section"
                    control={control}
                    options={getOptionSelectWithKey(listSection?.data?.sections)}
                    defaultValue={getDefaultSelectObject(sectionId, getOptionSelectWithKey(listSection?.data?.sections))}
                    placeholder="Select Section"
                    isClearable
                  />
                </div>
              )}
              {valueUrl === false && (
                <div className="">
                  {clientLanguage?.map((lang, index) => (
                    <InputContainer key={lang.id} name={`path-${lang.id}`} control={control} label={`Url ${lang.name}`} defaultValue={devLang[index]?.path} />
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="ml-auto flex gap-x-3 mt-7">
            <Button onClick={handleCancel} className="ml-auto flex" type="button" variant="outlined">
              Cancel
            </Button>
            <Button className="flex" type="submit" variant="outlined">
              <div>{defaultValues ? <div>{isWidget ? 'Update Widget' : 'Update Collection'}</div> : <div>{isWidget ? 'Add Widget' : 'Add Collection'}</div>}</div>
            </Button>
          </div>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
}

/* eslint-disable */
import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import CircleIcon from '@mui/icons-material/Circle';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { GridView, Search, ViewList } from '@mui/icons-material';
import { Checkbox, InputBase, TableCell, TableRow, Tooltip } from '@mui/material';
import swal from 'sweetalert';
import moment from 'moment';
import _ from 'lodash';
import { handleModal } from '../../store/slices/modalSlice';
import { crmFilterActions } from '../../store/slices/crm/crmFilter';
import SelectItem from '../../components/Input/SelectItem';
import MuiSelectSingleItem from '../../components/Input/MuiSelectSingleItem';
import CustomSelectItem from '../../components/Input/CustomSelect';
import BZHelmet from '@/utils/BZHelmet';
import BaseMainTitle from '@/components/BaseMainTitle';
import CustomTable from '@/components/Table/CustomTable'
import { fetchData } from '@/utils/fetchDataFunc';

import POSApi from '@/api/pos/posApi';

const headCells = [
  {
    id: 'code',
    numeric: false,
    disablePadding: true,
    label: 'Outlet Code',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'department',
    numeric: false,
    disablePadding: false,
    label: 'Department',
  },
];

function CollectionCell(props) {
  const { data } = props;
  
  return data.length > 0 && (
    <ul className="">
      {data?.map((col) => (
        <li key={col} className="text-xs list-disc">
          {col?.name || ""}
        </li>
      ))}
    </ul>
  );
}

function NameCell(props) {
  const { name, id } = props;
  const TEMPLATE_ID = useSelector((state) => state.content.activeTemplate);
  return (
    <div className="">
      <Link to={`/pos/outlets/detail/${id}`} className="text-bz-green font-semibold">
        {name}
      </Link>
    </div>
  );
}

function TableCells(props) {
  const { row, labelId, ariaChecked, selected, handleClick } = props;

  return (
    <TableRow hover role="checkbox" key={row?.id}>
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={ariaChecked} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="2" width={200}>
        {row?.code}
      </TableCell>
      <TableCell align="left">
        <NameCell name={row?.name || "-"} id={row?.id} />
      </TableCell>
      <TableCell align="left">
        { row?.department?.name ? row?.department?.name : ""}
      </TableCell>
    </TableRow>
  );
}

function OutletList() {
  const { control, reset, resetField } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch();

  const [outlets, setOutlets] = useState([]);
  const [counts, setCounts] = useState(0);
  const [isFetching, setIstFetching] = useState(true);
  const [orderQuery, setOrderQuery] = useState('');
  const [ordeIsAsc, setOrdeIsAsc] = useState('');
  const [checkboxSelected, setCheckboxSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [paramOutlets, setParamOutlets] = useState(new URLSearchParams(`page=${page}&page_size=${rowsPerPage}&department__business_unit__client=${clientId}&ordering=name`));

  useEffect(() => {
    const params = new URLSearchParams(`page=${page}&page_size=${rowsPerPage}&department__business_unit__client=${clientId}&ordering=name`);
    if (params.toString() !== paramOutlets.toString()) {
      setParamOutlets(params);
    }
  }, [page, rowsPerPage, clientId]);

  const getAllOutletList = useCallback((params) => fetchData(POSApi().getOutletList, params), [clientId, paramOutlets]);

  const getOutlets = useCallback(
    async (paramOutlets) => {
      const [outlets] = await Promise.all([getAllOutletList(paramOutlets)]);

      if (outlets) {
        setOutlets(outlets?.results);
        setCounts(outlets?.count);
      } 
      
      setIstFetching(false) 
    },
    [getAllOutletList]
  );

  useEffect(() => {
    setIstFetching(true)
    getOutlets(paramOutlets)
  }, [paramOutlets]);

  const DeleteDatabyId = () => {
    const { deleteOneCollection, bulkDeleteCollections } = ProductManagementApi();

  };

  return (
    <div className="w-full h-full bg-white">
      <BZHelmet title="Outlet List" description="" content="" />
      <BaseMainTitle title="Outlet List" />
      <div className="w-full p-5 relative">
        <div className="flex items-end pb-2 justify-end">
          {/* <button
            type="button"
            className={`
              flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5
              // ${checkboxSelected.length ? "bg-[#FC581D] cursor-pointer " : "bg-[#FC581D26] cursor-default"}
            `}
            onClick={DeleteDatabyId}
            disabled={checkboxSelected.length ? false : true}
          >
            <p className="text-white">Delete Selected</p>
          </button> */}
          <Link
            to="/pos/outlets/add"
            className="flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 cursor-pointer"
            style={{ backgroundColor: '#2C6D47' }}
          >
            <AddIcon className="text-white" />
            <p className="text-white">Add New Outlet</p>
          </Link>
        </div>
        <div className="w-full relative">
          <div className="mt-4">
            <CustomTable
              dataApi={outlets}
              isFetching={!isFetching}
              headCells={headCells}
              TableCells={TableCells}
              count={counts}
              page={page - 1}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              checkboxSelected={checkboxSelected}
              setCheckboxSelected={setCheckboxSelected}
              ordeIsAsc={ordeIsAsc}
              orderQuery={orderQuery}
              name="Outlet List"
              useCheckbox
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OutletList
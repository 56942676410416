import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import accountingApi from '../../../api/accounting/accounting';

export const getActiveBusinessUnitDetail = createAsyncThunk('client/setActiveDetailBusinessUnit', async (businessUnitId) => {
  const { getBusinessUnit } = accountingApi();
  const response = await getBusinessUnit(businessUnitId);
  return response.data;
});

const accountingSlice = createSlice({
  name: 'acc',
  initialState: {
    activeBusinessUnit: Cookies.get('bzaBU'),
    activeBusinessUnitDetail: false,
  },
  reducers: {
    setActiveBusinessUnit(state, action) {
      const currState = state;
      currState.activeBusinessUnit = action.payload;
      Cookies.set('bzaBU', action.payload);
    },
    setActiveBusinessUnitDetail(state, action) {
      const currState = state;
      currState.activeBusinessUnitDetail = action.payload;
    },
    changeParam2(state, action) {
      console.log(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveBusinessUnitDetail.fulfilled, (state, action) => {
      const currState = state;
      currState.activeBusinessUnitDetail = action.payload;
    });
  },
});

export const langActions = accountingSlice.actions;
export default accountingSlice;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSuccess: false,
  editData: [],
  taskData: {
    name: '',
    project: '',
    section: '',
    assignee: '',
    due_at: '',
  },
  commentData: {
    topic: '',
    creator: '',
    detail: '',
    attachments: [],
  },
};

const crudSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    projectUpdate: (state) => {
      const currState = state;
      currState.isSuccess = !currState.isSuccess;
    },
    projectEdit: (state, action) => {
      const currState = state;
      currState.editData = action.payload.editData;
    },
    taskAdd: (state, action) => {
      const currState = state;
      currState.taskData = { ...currState.taskData, ...action.payload };
    },
    taskComment: (state, action) => {
      const currState = state;
      currState.commentData = { ...currState.commentData, ...action.payload };
    },
  },
});

export const { projectUpdate, projectEdit, taskAdd, taskComment } = crudSlice.actions;

export default crudSlice.reducer;

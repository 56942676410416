/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable */

import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import OrganizationChart from '@dabeng/react-orgchart';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { fetchEmpHRStructureDetail, fetchHRStructureDetail, useAddEmployeeHRStructure, useDestroyEmployeeHRStructure } from '../../../hooks/useHRStructuresData';
import request from '../../../helper/axiosUtil';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import EmployeeHRStructureForm from '../../../components/Form/HR/EmployeeHRStructureForm';
import { handleModal } from '../../../store/slices/modalSlice';

export default function HRStructureDetail() {
  const { hrStructureId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const empHRStructureId = useSelector((state) => state.modal.modalId);
  const dispatch = useDispatch();

  const [treeData, setTreeData] = useState();

  const removeEmployeeHRStructure = useDestroyEmployeeHRStructure();

  function MyNode({ nodeData }) {
    return (
      <div>
        <div className="fullname">{nodeData?.employee?.job}</div>
        <div className="bg-blue-400">
          <div className="text-white">{nodeData?.employee?.first_name}</div>
          <div className="flex justify-center bg-orange-400">
            <button onClick={() => dispatch(handleModal({ modalId: parseInt(nodeData?.id?.replace(/n/g, ''), 10), componentName: 'addChildrenEmpHRStructure' }))} type="button">
              <Add className="text-white" />
            </button>
            <button onClick={() => dispatch(handleModal({ modalId: parseInt(nodeData?.id?.replace(/n/g, ''), 10), componentName: 'editEmpHRStructure' }))} type="button">
              <Edit className="text-white" />
            </button>
            <button onClick={() => removeEmployeeHRStructure(parseInt(nodeData?.id?.replace(/n/g, ''), 10))} type="button">
              <Delete className="text-white" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  useQuery(['TreeHRStructures', { hrStructureId }], fetchHRStructureDetail, {
    onSuccess: (e) => {
      const applyStringId = (arr) => {
        return arr.map((val) => {
          if (val?.children) {
            return {
              ...val,
              id: `n${val?.id}`,
              children: applyStringId(val?.children),
            };
          }
          return { ...val, id: `n${val?.id}` };
        });
      };
      const dataTree = e?.data;
      const result = applyStringId([dataTree?.employee_structure]);

      setTreeData(result[0]);
    },
  });

  const applyNormalId = (arr) => {
    return arr?.map((val) => {
      if (val?.children) {
        return {
          structure: parseInt(hrStructureId, 10),
          employee: parseInt(val?.employee?.id, 10),
          id: parseInt(val?.id?.replace(/n/g, ''), 10),
          children: applyNormalId(val?.children),
        };
      }
      return {
        id: parseInt(val?.id?.replace(/n/g, ''), 10),
        structure: parseInt(hrStructureId, 10),
        employee: parseInt(val?.employee?.id, 10),
      };
    });
  };

  const updateTreeHRStructure = async (data) => request({ url: `employee-management/structures/${hrStructureId}/reorder_structure/`, method: 'patch', data });

  const { mutateAsync: patchTreeHRStructure } = useMutation(updateTreeHRStructure, {
    onSuccess: (e) => {
      queryClient.invalidateQueries('TreeHRStructures');

      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
    },
  });

  const onUpdateHRStructure = async (data) => {
    const trans = await applyNormalId([data]);
    await patchTreeHRStructure(trans[0]);
  };

  const { mutate: addEmployeeHRStructure } = useAddEmployeeHRStructure();

  const onPostEmployeeHRStructure = async (data) => {
    await addEmployeeHRStructure(data);
  };

  const updateEmpHRStructure = async (data) => request({ url: `employee-management/employee_structures/${empHRStructureId}/`, method: 'patch', data });

  const { mutateAsync: patchEmpHRStructure } = useMutation(updateEmpHRStructure, {
    onSuccess: (e) => {
      // Invalidates cache and refetch
      if (e.request.status === 200) {
        enqueueSnackbar('success', { variant: 'success' });
      } else {
        enqueueSnackbar('error', { variant: 'error' });
      }
      queryClient.invalidateQueries('TreeHRStructures');
    },
    onError: () => {
      queryClient.invalidateQueries('TreeHRStructures');
    },
    onSettled: () => {
      queryClient.invalidateQueries('TreeHRStructures');
      dispatch(handleModal({ modalId: '' }));
    },
    // onSuccess
    /** Optimistic Update End */
  });

  const onUpdateEmpHRStructure = async (data) => {
    await patchEmpHRStructure(data);
  };

  let isAutoFetchingEmpDetail;
  if (empHRStructureId) {
    isAutoFetchingEmpDetail = true;
  } else isAutoFetchingEmpDetail = false;

  const { data: EmpHRStructureDetail } = useQuery(['EmpHRStructure', { empHRStructureId }], fetchEmpHRStructureDetail, { enabled: isAutoFetchingEmpDetail });

  return (
    <div>
      <div className="flex justify-end mr-3">
        <button type="button" onClick={() => onUpdateHRStructure(treeData)} className="bg-blue-600 w-fit text-white p-2 rounded-lg">
          Update HRStructure
        </button>
      </div>
      <div className="">
        {treeData?.employee ? (
          <OrganizationChart chartClass="myChart" datasource={treeData} pan zoom draggable NodeTemplate={MyNode} />
        ) : (
          <div className="mx-auto flex justify-center">
            <button onClick={() => dispatch(handleModal({ modalId: '', componentName: 'addTopEmpHRStructure' }))} className="bg-green-300 text-white p-2 rounded" type="button">
              Create Top Leader
            </button>
          </div>
        )}
      </div>
      <ModalWrapper componentName="addTopEmpHRStructure" header="Add Top HRStructure" maxWidth="sm">
        <EmployeeHRStructureForm onFormSubmit={onPostEmployeeHRStructure} />
      </ModalWrapper>
      <ModalWrapper componentName="addChildrenEmpHRStructure" header="Add Children HRStructure" maxWidth="sm">
        <EmployeeHRStructureForm onFormSubmit={onPostEmployeeHRStructure} />
      </ModalWrapper>
      <ModalWrapper componentName="editEmpHRStructure" header="Edit Children HRStructure" maxWidth="sm">
        <EmployeeHRStructureForm onFormSubmit={onUpdateEmpHRStructure} defaultValues={EmpHRStructureDetail?.data} key={EmpHRStructureDetail?.data?.id} />
      </ModalWrapper>
    </div>
  );
}

/* eslint-disable */
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import HandleWatchingState from '../helper/HandleWatchingState';
// import useRequest from './useRequest';
import store from '@/store';
import useRequest from './useRequest';

export default function useGetFilter({ url, validate, isEnabled = true, onSuccess, queryString, token }) {
  const { request } = useRequest();
  const watchingUrl = HandleWatchingState(
    `/${url}${qs.stringify(queryString, {
      addQueryPrefix: true,
      arrayFormat: 'repeat',
    })}`
  );

  const fetchDetail = async () => {
    const response = await request({
      url: `/${url}${qs.stringify(queryString, {
        addQueryPrefix: true,
        arrayFormat: 'repeat',
      })}`,
      token,
    });

    if (response?.response?.status === 401 || response?.response?.status === 0) {
      store.dispatch({ type: 'auth/setLogout' });
      store.dispatch({ type: 'client/setActiveClient', payload: false });
      store.dispatch({ type: 'lang/setLanguageList', payload: false });
    }
    return response;
  };

  return useQuery({
    queryKey: [validate],
    queryFn: fetchDetail,
    onSuccess,
    enabled: watchingUrl && isEnabled,
    refetchOnWindowFocus: false,
  });
}

import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { Image } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';

import DetailContext from '../../../../pages/Task/Projects/DetailContext';

export default function TopicPriority({ open, data, onClose, onCreateTopicPriority, onUpdateTopicPriority }) {
  const { projectId: project } = useContext(DetailContext);

  const [name, setName] = useState('');
  const [index, setIndex] = useState('');
  const [filesIcon, setFilesIcon] = useState(false);
  const { getRootProps: getTTProps, getInputProps: getTTIProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFilesIcon(() => Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) }));
    },
  });

  const handleClose = () => {
    onClose();
    setName('');
    setIndex('');
    setFilesIcon(false);
  };

  const actionOnTopic = () => {
    if (name) {
      if (data.mode) {
        const dataTopic = {
          project,
          name,
          index,
          file: filesIcon,
        };
        onCreateTopicPriority(dataTopic);
      }
      const dataTopic = {
        name,
        index,
        file: filesIcon?.name ? filesIcon : null,
      };
      onUpdateTopicPriority(dataTopic, data.data.id);
      handleClose();
    }
  };

  useEffect(() => {
    if (!data.mode) {
      setName(data?.data.name || '');
      setIndex(data?.data.index || '');
      setFilesIcon((data?.data.icon && { preview: data?.data.icon }) || false);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} sx={{ '& > .MuiDialog-container > .MuiPaper-root': { position: 'absolute', top: '25%' } }}>
      <DialogTitle>{data.mode ? 'Create New' : 'Update'} Topic Priority</DialogTitle>
      <DialogContent>
        <Box className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-4 items-end">
            <TextField autoFocus margin="dense" id="name" value={name} label="Name" fullWidth size="small" onChange={(e) => setName(e.target.value)} />
            <TextField autoFocus margin="dense" id="index" value={index} label="Index" fullWidth size="small" onChange={(e) => setIndex(e.target.value)} />
          </div>
          <Box className="relative flex w-full h-full justify-center items-center border rounded-lg border-gray-300">
            <div {...getTTProps({ className: 'dropzone w-full h-40 flex justify-center items-center' })} aria-hidden>
              {filesIcon ? (
                <label htmlFor="icon-topic-priority-file">
                  <input className="hidden" {...getTTIProps()} />
                  <img className="w-60 h-40 object-contain" src={filesIcon.preview} alt="topic icon" />
                </label>
              ) : (
                <label htmlFor="icon-topic-priority-file" className="flex justify-center items-center">
                  <input className="hidden" {...getTTIProps()} />
                  <IconButton color="primary" aria-label="upload attachment" component="span">
                    <Image />
                  </IconButton>
                </label>
              )}
            </div>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={actionOnTopic}>
          {data.mode ? 'Create' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/* eslint-disable */
import { Box, Checkbox, CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import * as React from 'react';

function descendingComparator(a, b, orderBy) {
  const valueA = getValue(a, orderBy);
  const valueB = getValue(b, orderBy);

  if (valueB < valueA) return -1;
  if (valueB > valueA) return 1;
  return 0;
}

function getValue(obj, path) {
  const keys = path.split('.');
  let value = obj;

  for (const key of keys) {
    value = value ? value[key] : '-';
  }

  return value;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, useCheckbox } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell?.isCenter ? 'center' : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
            width={headCell.width || ''}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  dataApi,
  headCells,
  tabelAction,
  TableCells,

  isFetching,
  ordeIsAsc,
  orderQuery,
}) {
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const tableData = dataApi || [];

  return (
    <Box sx={{ width: '100%' }}>
      {tabelAction}
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead order={ordeIsAsc || order} orderBy={orderQuery || orderBy} rowCount={tableData?.length} headCells={headCells} />
            {tableData?.length && tableData?.length > 0 ? (
              <TableBody>
                {stableSort(tableData, getComparator(order, orderBy)).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return <TableCells labelId={labelId} key={row?.id || index} row={row} index={index} />;
                })}
              </TableBody>
            ) : (
              <TableBody className="relative h-[10vh]">
                {!isFetching ? (
                  <CircularProgress />
                ) : (
                  <tr className=" absolute inset-0  flex items-center justify-center text-lg font-bold">
                    <td>No Results</td>
                  </tr>
                )}{' '}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

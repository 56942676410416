import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  modalId: '',
  componentName: '',
  modalData: '',
  watchId: '',

  isOpenNested: false,
  modalIdNested: '',
  componentNameNested: '',
  modalDataNested: '',
  watchIdNested: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    handleModal: (state, action) => {
      const currState = state;
      currState.isOpen = !currState.isOpen;
      currState.modalId = action.payload.modalId;
      currState.componentName = action.payload.componentName;
      currState.modalData = action.payload.modalData;
    },
    handleCloseModal: (state, action) => {
      const currState = state;
      currState.isOpen = false;
      currState.modalId = action.payload.modalId;
      currState.componentName = action.payload.componentName;
      currState.modalData = action.payload.modalData;
    },
    handleOpenModal: (state, action) => {
      const currState = state;
      currState.isOpen = true;
      currState.modalId = action.payload.modalId;
      currState.componentName = action.payload.componentName;
      currState.modalData = action.payload.modalData;
    },
    handleModalNested: (state, action) => {
      const currState = state;
      currState.isOpenNested = !currState.isOpenNested;
      currState.modalIdNested = action.payload.modalIdNested;
      currState.componentNameNested = action.payload.componentNameNested;
      currState.modalDataNested = action.payload.modalDataNested;
    },
    handleWatch: (state, action) => {
      const currState = state;
      currState.watchId = action.payload.watchId;
    },
  },
});

export const { handleModal, handleWatch, handleModalNested, handleCloseModal, handleOpenModal } = modalSlice.actions;

export default modalSlice.reducer;

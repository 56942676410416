/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { GoogleMap, Marker, Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import * as yup from 'yup';
import swal from 'sweetalert';
import _ from 'lodash';
import InputContainerJP from '../../Input/InputContainerJP';
import SelectAutocomplete from '../../../pages/CRM/redesigning/SelectAutocompleteRedesign';
import CRMApi from '../../../api/CRM/CRMCustomers';
import { handleModal } from '../../../store/slices/modalSlice';

const schema = yup.object().shape({
  company: yup.object().nullable(),
  honorific: yup.string().nullable(),
  name: yup.string().max(200).required(),
  position: yup.string().nullable(),
  phone: yup.string().nullable(),
  email: yup.string().email().nullable(),
  linkedin: yup.string().nullable(),
  location_address: yup.string(),
  location_name: yup.string().when('location_address', {
    is: (address) => address != null && address !== '',
    then: yup.string().required('Location name is required if the address is filled in'),
    otherwise: yup.string().nullable()
  })
});

const mapLib = ['drawing', 'places'];

function AddContact({ idxContact, activeClient, reload, noSubmitApi, handleAssignedContact, ...props }) {
  const dispatch = useDispatch();
  const inputData = useSelector((state) => state.modal.modalData);
  const modalId = useSelector((state) => state.modal.modalId);
  const clientId = useSelector((state) => state.client.activeClient);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm({ 
    resolver: yupResolver(schema) });

  const [members, setMembers] = useState([]);
  const [selectcompany, setSelectCompany] = useState([]);
  const [honor, setHonor] = useState("");
  const [datas, setDatas] = useState({});

  // For Location
  const [allCountries, setAllCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [latitude, setLatitude] = useState(inputData?.location?.latitude || -6.1741);
  const [longitude, setLongitude] = useState(inputData?.location?.longitude || 106.8296);
  const [onLoad, setOnLoad] = useState(null);

  const HonorificList = [
    { name: 'Mr.', id: 0 },
    { name: 'Mrs.', id: 1 },
    { name: 'Ms.', id: 2 },
    { name: 'Dr.', id: 3 },
    { name: 'Prof.', id: 4 },
    { name: 'H.E.', id: 5 },
  ];
  const honorificValue = HonorificList.filter((v) => v.name === inputData?.honorific);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_MAP_KEY,
    libraries: mapLib,
  });

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const center = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  };

  const getAllCountries = async () => {
    const { getCountries } = CRMApi();

    try {
      const fetchData = await getCountries({ max_size: true });
      const response = await fetchData.data.results;
      const countriesData = response;

      setAllCountries(countriesData);
      if(inputData?.location) {
        setSelectedCountry(countriesData.find((country) => country.id === inputData?.location?.country))
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeMarker = (event) => {
    setLatitude(event.latLng.lat());
    setLongitude(event.latLng.lng());
  };

  useEffect(() => {
    getAllCountries();
  }, []);

  const handleChangePlace = (place) => {
    place = onLoad.getPlace();

    let selectedCountry = {};
    const addressComponents = place?.address_components;

    if (addressComponents) {
      const countriesName = allCountries.map((country) => country.iso_code);
      const addressCountry = addressComponents.map((address) => address.short_name);
      const foundedCountry = _.intersection(countriesName, addressCountry);
      const countryCode = foundedCountry.length ? foundedCountry[0] : '';
      selectedCountry = allCountries.find(({ iso_code }) => iso_code === countryCode);
    }

    setSelectedCountry(selectedCountry);
    setAddress(place?.formatted_address);
    setLatitude(place?.geometry.location.lat());
    setLongitude(place?.geometry.location.lng());
    setValue('location_address', place?.formatted_address || '');
  };

  const getMembers = useCallback(async () => {
    const { getAllMembersName } = CRMApi();

    try {
      const fetchData = await getAllMembersName({ client: activeClient, max_size: true });
      const response = fetchData.data;

      setMembers(response);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (activeClient) {
      getMembers();
    }
  }, []);

  const onSubmit = (data) => {
    const { addContact } = CRMApi();
    const company = members?.filter((v) => v.name === selectcompany);
    const { location_name, location_address, ...restData } = data;
    const newData = {
      ...restData,
      ...(!noSubmitApi && {
        member: company[0]?.id || null,
      }),
      honorific: honor,
      contact_title: null,
      ...(location_address !== "" && {
        location: {
          client: clientId,
          country: selectedCountry.id,
          name: location_name,
          address: location_address,
          latitude: latitude,
          longitude: longitude,
        },
      })
    };

    console.log({data});
    console.log({newData});

    try {
      if (!noSubmitApi) {
        if (props.type === 'CRM Project') newData.client = activeClient;
        addContact(newData)
          .then((res) => {
            if (res.status === 201 || res.data) {
              dispatch(handleModal({ modalId: '', componentName: '' }));
              swal('New Contact Added Successfully!', { icon: 'success' });
              return reload(true);
            }
          })
          .catch((err) => {
            console.error('error', err);
          });
      } else {
        // const newData = {
        //   ...data,
        //   honorific: honor,
        //   contact_title: undefined,
        // };
        dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
        handleAssignedContact(newData, modalId);
        // setDatas(newData)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  return (
    isLoaded && (
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full">
            <div className="w-full grid gap-y-3">
              {!noSubmitApi && (!props.type || (props.type && props.type !== 'CRM Project'))  && (
                <div>
                  <InputLabel>Member</InputLabel>
                  <SelectAutocomplete variant="outlined" label="company" options={members} onChange={(e, val) => setSelectCompany(val.name)} />
                </div>
              )}
              <div>
                <InputLabel>Honorific</InputLabel>
                <SelectAutocomplete
                  defaultValue={inputData && honorificValue[0]}
                  variant="outlined"
                  label="honorific"
                  errors={errors}
                  register={register}
                  options={HonorificList}
                  onChange={(e, val) => setHonor(val.name)}
                />
              </div>
            </div>
            <div className="w-full">
              <InputLabel>Contact Name</InputLabel>
              <InputContainerJP defaultValue={inputData && inputData.name} name="name" label="name" control={control} errors={errors} register={register} />
            </div>
            <div className="w-full">
              <InputLabel>Position</InputLabel>
              <InputContainerJP defaultValue={inputData && inputData.position} name="position" label="position" control={control} errors={errors} register={register} />
            </div>
            <div className="w-full">
              <InputLabel>Phone</InputLabel>
              <InputContainerJP defaultValue={inputData && inputData.phone} name="phone" label="phone" control={control} errors={errors} register={register} />
            </div>
            <div className="w-full">
              <InputLabel>Email</InputLabel>
              <InputContainerJP defaultValue={inputData && inputData.email} name="email" label="email" control={control} errors={errors} register={register} />
            </div>
            <div className="w-full">
              <InputLabel>Linkedin</InputLabel>
              <InputContainerJP name="linkedin" label="linkedin" control={control} errors={errors} register={register} />
            </div>
            <div className="mt-3" style={{ height: '200px' }}>
              <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15} mapTypeId="roadmap" onLoad={(e) => setOnLoad(e)} onRightClick={(e) => handleChangeMarker(e)}>
                <Autocomplete className="z-20" onLoad={(e) => setOnLoad(e)} onPlaceChanged={(e) => handleChangePlace(e)}>
                  <input
                    className="border border-transparent shadow-sm text-sm text-ellipsis z-20 w-[50%] h-[2.5rem] rounded box-border px-[12px] absolute top-[.6rem] left-[40%]"
                    id="loc-search-place"
                    type="text"
                    placeholder="Search place"
                  />
                </Autocomplete>
                <Marker position={center} />
              </GoogleMap>
            </div>
            <div className="mt-4 space-y-2">
              <div className="space-y-2">
                <InputLabel>Location Name</InputLabel>
                <InputContainerJP name="location_name" defaultValue={inputData?.location && inputData.location?.name} label="Location Name" control={control} errors={errors} register={register} />
              </div>
              <div className="space-y-2">
                <InputLabel>Address</InputLabel>
                <Controller
                  name="location_address"
                  control={control}
                  defaultValue={inputData?.location ? inputData.location?.address : ''}
                  render={({ field: {onChange, value} }) => (
                    <textarea
                      type="text"
                      className="w-full h-[60px] border rounded-md shadow-sm p-2"
                      value={value}
                      onChange={(e) => onChange(e)}
                    />
                  )}
                />
                {errors.location_address && (
                    <p className="text-red-600">{errors.location_address.message}</p>
                )}
              </div>
              <div className="space-y-2">
                <InputLabel>Country</InputLabel>
                <Select
                  options={allCountries?.map((item) => ({ label: item.name, value: item.id }))}
                  value={!selectedCountry.length ? { label: selectedCountry.name, value: selectedCountry.id } : []}
                  isSearchable={false}
                  placeholder="-- select country --"
                  menuPosition="fixed"
                  menuPlacement="auto"
                  onChange={(v) => {
                    const selected = allCountries.find(({ id }) => id === parseInt(v.value));
                    setSelectedCountry(selected);
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: '2.5rem',
                    }),
                    container: (provided) => ({
                      ...provided,
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      overflow: 'visible',
                      display: 'flex',
                      alignItems: 'center',
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: 'absolute',
                      top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
                      transition: 'top 0.1s, font-size 0.1s',
                      fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
                      background: 'white',
                      margin: '0px 20',
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 10000,
                      marginTop: '-10px',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            name="submit contact"
            className="flex h-[40px] mt-7 ml-auto gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4"
            style={{ backgroundColor: '#2C6D47' }}
          >
            <p className="text-white">Add</p>
          </button>
        </form>
      </div>
    )
  );
}

export default AddContact;

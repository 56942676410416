// /* eslint-disable */

import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BaseMainTitle from '../../../components/BaseMainTitle';
import BZHelmet from '../../../utils/BZHelmet';
import useDelete from '../../../hooks/useDelete';
import BaseAssetViewer from '../../../components/Viewer/BaseAssetViewer';
import BZButton from '../../../components/Button/BZButton';

export default function Asset() {
  const bulkDeleteAsset = useSelector((state) => state.assetCrud.selected);
  console.log('🚀 ~ file: Asset.jsx:15 ~ Asset ~ bulkDeleteAsset', bulkDeleteAsset);

  const onDestroyAsset = useDelete({
    url: `assets/delete_assets`,
    confText: `modals.asset_delete.confirmation_text`,
    confTitle: `modals.asset_delete.title`,
    success: 'Assets deleted',
    validate: ['assets'],
    bodyData: { assets: bulkDeleteAsset.map((asset) => asset.id) },
    // method: 'patch',
    isSubUrl: false,
  });

  return (
    <>
      <BZHelmet title="Asset List" description="" content="" />
      <div>
        <BaseMainTitle title="Asset List" />
        <div className="p-5">
          <div className="bg-gray-100 w-full py-5 px-3 rounded flex items-center">
            <div className="ml-auto flex gap-x-3">
              <BZButton variant={bulkDeleteAsset.length === 0 ? 'disabled' : 'danger'} onClick={() => onDestroyAsset()}>
                Delete selected
              </BZButton>
              <Link to="/asset/add">
                <BZButton variant="secondary">Add asset</BZButton>
              </Link>
            </div>
          </div>
          <BaseAssetViewer fixBottom={false} />
        </div>
      </div>
    </>
  );
}

/* eslint-disable */

import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonBase, CircularProgress, TableCell, TableRow } from '@mui/material';
import CustomTable from '../../components/Table/CustomTable';
import ModalWrapper from '../../components/ui/ModalWrapper';
import useDelete from '../../hooks/useDelete';
import { handleModal } from '../../store/slices/modalSlice';
import usePost from '../../hooks/usePost';
import useGet from '../../hooks/useGet';
import BaseApplyButton from '../../components/Button/BaseApplyButton';
import BaseDeleteButton from '../../components/Button/BaseDeleteButton';
import BaseSearchButton from '../../components/Button/BaseSearchButton';
import RenderResults from '../../components/RenderResults';
import usePatchOrPut from '../../hooks/usePatchOrPut';
import BaseEditButton from '../../components/Button/BaseEditButton';
import { Link } from 'react-router-dom';
import { AttachFile, Download, Edit, Visibility } from '@mui/icons-material';
import { useForm, useWatch } from 'react-hook-form';
import FileContatiner from '../../components/Input/FileContatiner';
import { useQuery } from '@tanstack/react-query';
import { fetchFilterApplicant } from '../../hooks/useRecruitmentsData';

function getFileNameAndType(url) {
  return url.substring(url.lastIndexOf('/') + 1);
  // var fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
  // return { name: fileName, type: fileType };
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'resume',
    numeric: false,
    disablePadding: true,
    label: 'Resume',
  },

  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    headerAlign: 'center',
    label: 'Action',
  },
];

function ActionCell(props) {
  const { onClick, id } = props;
  const dispatch = useDispatch();
  return (
    <div key={id} className="w-full flex space-x-2 justify-center">
      <Link to={`/hr/recruitment/applicant/detail/${id}`} className="bz-btn-tb">
        <Edit fontSize="24" />
      </Link>
      <BaseDeleteButton onClick={onClick} variant="icon" />
    </div>
  );
}

function TableCells(props) {
  const { row, labelId, ariaChecked, selected } = props;

  const { control, handleSubmit, setValue, register, watch, getValues, unregister } = useForm();
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.modal);

  const fileResume = useWatch({ name: 'resume', control });

  const destroyApplicant = useDelete({
    confText: `modals.applicant_delete.confirmation_text`,
    confTitle: `modals.applicant_delete.title`,
    success: 'Applicant deleted',
    validate: ['applicant'],
  });

  const onDestroyApplicant = (id) => {
    destroyApplicant(`applicants/${id}`);
  };

  const { mutate: patchApplicantResume } = usePatchOrPut({ url: `applicants/${modal?.modalId}`, validate: ['applicants', 'applicant'] });

  const onPatchApplicantResume = async () => {
    const formData = new FormData();
    formData.append('resume', fileResume[0]);
    await patchApplicantResume(formData);
  };

  return (
    <TableRow hover role="checkbox" aria-checked={ariaChecked} tabIndex={-1} key={row.id} selected={selected}>
      <TableCell align="left">
        {' '}
        <Link to={`/hr/recruitment/applicant/show/${row?.id}`} className="text-bz-green font-semibold">
          {row.user.name}
        </Link>
      </TableCell>
      <TableCell align="left">{row.user.email || '-'}</TableCell>
      <TableCell align="left">
        {row.resume ? (
          <a href={row.resume} className="" target="blank">
            <div className="flex space-x-1 items-center">
              <AttachFile />
              <div className="">{getFileNameAndType(row?.resume)}</div>
            </div>
          </a>
        ) : (
          <div
            onClick={() => dispatch(handleModal({ modalId: row.id, componentName: 'uploadResume' }))}
            className="bz-btn secondary w-fit cursor-pointer"
          >
            <Download />
            <div className="">Upload Resume</div>
          </div>
        )}
      </TableCell>
      <TableCell align="left">
        <ActionCell id={row.id} onClick={() => onDestroyApplicant(row.id)} />
      </TableCell>
      <ModalWrapper componentName="uploadResume" header="Upload Resume" maxWidth="sm">
        <div className="space-y-2">
          <FileContatiner name="resume" label="Resume" errors="" control={control} />
          <Button onClick={onPatchApplicantResume} type="button" variant="contained">
            Upload
          </Button>
        </div>
      </ModalWrapper>
    </TableRow>
  );
}

export default function TableAplicant() {
  const clientId = useSelector((state) => state.client.activeClient);
  const activeSite = useSelector((state) => state.content.activeSite);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const [dataApplicant, setDataApplicant] = useState([]);
  const [searchInput, setSearchInput] = useState([]);
  const [count, setCount] = useState(0);
  const [isDataLoad, setIsDataLoad] = useState(true);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  // const { isFetching } = useGet({
  //   url: `applicants`,
  //   queryString: {
  //     user__site: activeSite,
  //     search: searchInput,
  //     page,
  //     page_size: rowsPerPage,
  //   },
  //   validate: 'applicants',
  //   onSuccess: (e) => {
  //     setCount(e?.data?.count);
  //     setDataApplicant(e?.data?.results);
  //   },
  //   isEnabled: isDataLoad,
  // });

  const queryKey = {
    user__site: activeSite,
    search: searchInput,
    page,
    page_size: rowsPerPage,
  };

  const { isFetching } = useQuery(['applicants', { queryKey }], fetchFilterApplicant, {
    onSuccess: (e) => {
      setCount(e?.data?.count);
      setDataApplicant(e?.data?.results);
    },
    refetchOnWindowFocus: false,
  });

  const { data: applicantDetail } = useGet({
    url: `applicants/${modal?.modalId}`,
    validate: 'applicantArticle',
    isEnabled: modal.componentName === 'editApplicant',
  });

  const onChangeSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const data = useMemo(() => dataApplicant, [dataApplicant]);

  function generateFilterComponent() {
    return (
      <div className="flex space-x-2 items-center">
        <BaseSearchButton onChange={onChangeSearch} setPage={setPage} setIsDataLoad={setIsDataLoad} />

        {/* <BaseApplyButton setPage={setPage} setIsDataLoad={setIsDataLoad} /> */}
      </div>
    );
  }

  const { mutate: postApplicant } = usePost({ url: 'applicants', validate: ['applicant'] });

  const onPostApplicant = async (postData) => {
    await postApplicant(postData);
  };

  const { mutate: updateApplicant } = usePatchOrPut({ url: `applicants/${modal.modalId}`, validate: ['applicant', 'applicantArticle'] });

  return (
    <div className="p-5">
      <div className="">
        <div className="bg-gray-100 w-full flex py-5 px-3 items-center">
          <div className="ml-auto flex gap-x-3">
            <Link to="/hr/recruitment/applicant/add">
              <Button className="bz-btn" type="submit" variant="filled">Add Applicant</Button>
            </Link>
            {/* <ModalWrapper componentName="addApplicant" header="Add Applicant" maxWidth="sm">
              <ArticleApplicantForm onFormSubmit={onPostApplicant} />
            </ModalWrapper>
            <ModalWrapper componentName="editApplicant" header="Edit Applicant" maxWidth="sm">
              <ArticleApplicantForm onFormSubmit={updateApplicant} defaultValues={applicantDetail?.data} key={applicantDetail?.data?.id} />
            </ModalWrapper> */}
          </div>
        </div>
        <div className=" py-4 my-4 border-y-2 ">{generateFilterComponent()}</div>
      </div>

      <CustomTable
        dataApi={data}
        setIsDataLoad={setIsDataLoad}
        isFetching={!isFetching}
        headCells={headCells}
        ActionCell={ActionCell}
        TableCells={TableCells}
        page={page - 1}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
    </div>
  );
}
